export const PROXY_EMPLOYEES = [
    2,      //Patryk - testowe konto
    3,      //Krzysiek
    62,     //Konrad
    129,    //Kinga
    238,    //Patryk
    240,    //Renia
    417,    //Maria
    299,    //Kasia
    460,    //Ola
    587,    //Beata
    597,    //Karol
    688,    //Magda W.
    773,    //Magda M.
]