/* eslint-disable import/no-anonymous-default-export */
import arrayObjectIndexOf from "../helpers/indexOfObject"
import isIterable from "../helpers/isIterable"
import moment from 'moment'


export const ReportsConst = {
    START_DATE_PICKER_SELECT_DAY: 'START_DATE_PICKER_SELECT_DAY',
    END_DATE_PICKER_SELECT_DAY: 'END_DATE_PICKER_SELECT_DAY',
    GET_DOCUMENTS: 'GET_DOCUMENTS',
    GET_DOCUMENTS_WHIT_LIST_STATUS_TIMEOUT: 'GET_DOCUMENTS_WHIT_LIST_STATUS_TIMEOUT',
    GET_DOCUMENTS_WHIT_LIST_STATUS: 'GET_DOCUMENTS_WHIT_LIST_STATUS',
    UPDATE_DOWNLOADED_DOC_DATA: 'UPDATE_DOWNLOADED_DOC_DATA',
    UPDATE_DOWNLOADED_PAYMENT_ORDERS: 'UPDATE_DOWNLOADED_PAYMENT_ORDERS',
    DELETE_FILE_FROM_REPORT: 'DELETE_FILE_FROM_REPORT',
    MARK_DOC_AS_PAID: 'MARK_DOC_AS_PAID',
    DOC_ADDITIONAL_ACCEPTANCE: 'DOC_ADDITIONAL_ACCEPTANCE',
    MARK_DOC_CAN_BE_ACCOUNTED: 'MARK_DOC_CAN_BE_ACCOUNTED',
    MARK_DOC_AS_ACCOUNTED: 'MARK_DOC_AS_ACCOUNTED',
    SORT_DOCS: 'SORT_DOCS',
    SEND_GET_DOCUMENTS_REQUEST: 'SEND_GET_DOCUMENTS_REQUEST',
    SET_WAITING_FOR_WHITE_LIST_RESPONSE: 'SET_WAITING_FOR_WHITE_LIST_RESPONSE',
    GET_REPORT_XLSX: 'GET_REPORT_XLSX',
    MARK_DOCS_IN_REPORT: 'MARK_DOCS_IN_REPORT',
    MARK_DOCS_IN_ZIP: 'MARK_DOCS_IN_ZIP',
    MARK_DOCS_BANK_TRANSFER_FILE: 'MARK_DOCS_BANK_TRANSFER_FILE',
    MARK_DOCS_WAPRO_XML: 'MARK_DOCS_WAPRO_XML',
    MARK_DOCS_SYMFONIA_XML: 'MARK_DOCS_SYMFONIA_XML',
    BULK_TAGS_UPDATE: 'BULK_TAGS_UPDATE',
    SEND_GET_MY_REPORTS_REQUEST: 'SEND_GET_MY_REPORTS_REQUEST',
    GET_ALL_MINE_REPORTS: 'GET_ALL_MINE_REPORTS',
    SEND_GET_MY_ZIPS_REQUEST: 'SEND_GET_MY_ZIPS_REQUEST',
    GET_ALL_MINE_ZIPS: 'GET_ALL_MINE_ZIPS',
    SEND_GET_MY_TRANSFER_FILES_REQUEST: 'SEND_GET_MY_TRANSFER_FILES_REQUEST',
    GET_ALL_MINE_TRANSFER_FILES: 'GET_ALL_MINE_TRANSFER_FILES',
    SEND_GET_MY_ACCOUNTING_FILES_REQUEST: 'SEND_GET_MY_ACCOUNTING_FILES_REQUEST',
    GET_ALL_MINE_ACCOUNTING_FILES: 'GET_ALL_MINE_ACCOUNTING_FILES',
    DELETE_REPORT: 'DELETE_REPORT',
    DELETE_ZIP: 'DELETE_ZIP',
    DELETE_BANK_TRANSFER_FILE: 'DELETE_BANK_TRANSFER_FILE',
    DELETE_ACCOUNTING_FILE: 'DELETE_ACCOUNTING_FILE',
    GET_REPORT_BY_ID: 'GET_REPORT_BY_ID',
    GET_TRANSFER_FILE_BY_ID: 'GET_TRANSFER_FILE_BY_ID',
    SORT_REPORTS: 'SORT_REPORTS',
    SORT_ZIPS: 'SORT_ZIPS',
    SORT_TRANSFER_FILES: 'SORT_TRANSFER_FILES',
    SORT_ACCOUNTING_FILES: 'SORT_ACCOUNTING_FILES',

    DELETE_PROJECT: 'DELETE_PROJECT',
    LOGOUT: 'LOGOUT',
}

const initialState = {
    docsDownloadedForRaport: [],
    waitingForWhiteListStatus: false,
    reportDocsProjectId: -1,
    reportsStartDate: { display: null, value: null },
    reportsEndDate: { display: null, value: null },
    alreadyPaidDocs: [],

    previousGeneratedReportsLoaded: false,
    previousGeneratedReports: [],
    reportsSortField: 'created',
    reportsSortOrder: 'desc',

    previousGeneratedZipLoaded: false,
    previousGeneratedZip: [],
    zipsSortField: 'created',
    zipsSortOrder: 'desc',

    previousGeneratedBankTransferFilesLoaded: false,
    previousGeneratedBankTransferFiles: [],
    banktransferFilesSortField: 'created',
    banktransferFilesSortOrder: 'desc',

    previousGeneratedAccountingFilesLoaded: false,
    previousGeneratedAccountingFiles: [],
    accountingFileSortField: 'created',
    accountingFileSortOrder: 'desc'
}

function dynamicComparer(key, order = 'asc') {
    return function (a, b) {
        if (!a.hasOwnProperty(key) && !b.hasOwnProperty(key)) {
            return 0;
        } else if (!a.hasOwnProperty(key)) {
            return 1
        } else if (!b.hasOwnProperty(key)) {
            return -1
        }

        const varA = (typeof a[key] === 'string') ?
            a[key].toUpperCase() : a[key];
        const varB = (typeof b[key] === 'string') ?
            b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (!varA) {
            comparison = -1
        } else if (!varB) {
            comparison = 1
        } else if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }
        return (
            (order === 'desc') ? (comparison * -1) : comparison
        );
    };
}

export default (state = initialState, action) => {
    switch (action.type) {

        case ReportsConst.START_DATE_PICKER_SELECT_DAY:
            return { ...state, ...{ reportsStartDate: action.day } }

        case ReportsConst.END_DATE_PICKER_SELECT_DAY:
            return { ...state, ...{ reportsEndDate: action.day } }

        case ReportsConst.GET_DOCUMENTS:
            if (action.projectId === state.reportDocsProjectId) {
                // if ((state.waitingForWhiteListStatus && action.withWhiteListStatus) || (!state.waitingForWhiteListStatus && !action.withWhiteListStatus)) {
                var documentsAvalibleForReport = 0;

                if (action.docs) {
                    action.docs.forEach(d => {
                        if (d.canPay) documentsAvalibleForReport += 1
                    })
                    var sortedDocuments = action.docs.sort(dynamicComparer('importDate', 'desc'))
                    sortedDocuments = action.docs.sort(dynamicComparer('alreadyPaid', 'asc'))
                } else {
                    var sortedDocuments = []
                }
                return { ...state, ...{ docsDownloadedForRaport: [...sortedDocuments], sendGetDocumentsRequest: false, gotDocumentsResponse: true, documentsAvalibleForReport: documentsAvalibleForReport, docsSortField: 'alreadyPaid', docsSortOrder: 'asc', downloadedDocsType: action.docType, alreadyPaidDocs: [], waitingForWhiteListStatus: false } }
                // } else return state
            } else return state

        case ReportsConst.GET_DOCUMENTS_WHIT_LIST_STATUS_TIMEOUT:
            var docsDownloadedForRaport = state.docsDownloadedForRaport
            var docsCount = docsDownloadedForRaport.length
            for (var i = 0; i < docsCount; i++) {
                docsDownloadedForRaport[i].accountStatus = 'timeout'
                docsDownloadedForRaport[i].status = 'The government VAT API is temporarily unavailable'
            }
            return { ...state, ...{ docsDownloadedForRaport: [...docsDownloadedForRaport] } }

        case ReportsConst.GET_DOCUMENTS_WHIT_LIST_STATUS:
            return { ...state, ...{ docsDownloadedForRaport: [...action.docsWithStatus] } }

        case ReportsConst.UPDATE_DOWNLOADED_DOC_DATA:
            var prevDocsDownloadedForRaport = state.docsDownloadedForRaport
            if (isIterable(prevDocsDownloadedForRaport)) {
                if (action.docType !== 'EXPENSES') {
                    prevDocsDownloadedForRaport.forEach(doc => {
                        if (doc.id === action.docId && ((doc.type && doc.type.toUpperCase() === action.docType) || (doc.docType && doc.docType.toUpperCase() === action.docType))) {
                            if (doc.alreadyInBankTransferFile && !doc.bankTransferFileName) {
                                if (action.sellerAddress && action.sellerCountry && action.swift && action.accountNumber && !doc.alreadyPaid) {
                                    doc.alreadyInBankTransferFile = false
                                }
                            }
                            doc.issueDate = action.issueDate
                            doc.dueDate = action.dueDate
                            doc.price = action.price ? action.price.slice(0, -2) : ""
                            doc.tax = action.tax ? action.tax.slice(0, -2) : ""
                            doc.netto = action.netto ? action.netto.slice(0, -2) : ""
                            doc.currency = action.currency
                            doc.priceNumber = parseFloat(action.price)
                            doc.nettoNumber = parseFloat(action.netto)
                            doc.vatId = action.sellerVatId
                            doc.company = action.counterparty
                            doc.comment = action.comment
                            doc.tags = action.tags
                            doc.counterparty = action.seller
                            doc.number = action.number

                            if (doc.type === 'DOC') {
                                if (action?.waproData?.magType) {
                                    if (!action?.waproData.magType || !action?.waproData.fakirType || !action?.waproData.fakirDictionary) {
                                        doc.notAvailableForWaproMsg = 'This document does not have all the required fields for WAPRO export'
                                        doc.availableForWaproExport = false
                                        doc.missingWaproFields = true
                                    } else {
                                        doc.missingWaproFields = false
                                        if (!doc.accountingFileId && !doc.accounted) {
                                            doc.notAvailableForWaproMsg = ''
                                            doc.availableForWaproExport = true
                                        }
                                    }
                                } else if (doc.availableForWaproExport) {
                                    doc.notAvailableForWaproMsg = 'This document does not have all the required fields for WAPRO export'
                                    doc.availableForWaproExport = false
                                    doc.missingWaproFields = true
                                }
                            }
                        }
                    })
                } else {
                    action.docs.forEach(doc => {
                        var expenseIndex = arrayObjectIndexOf(prevDocsDownloadedForRaport, doc.id, "id")

                        if (expenseIndex > -1) {
                            prevDocsDownloadedForRaport[expenseIndex].comment = doc.comment
                            prevDocsDownloadedForRaport[expenseIndex].currency = doc.currency
                            prevDocsDownloadedForRaport[expenseIndex].price = doc.price
                            prevDocsDownloadedForRaport[expenseIndex].priceNumber = parseFloat(doc.price)
                            prevDocsDownloadedForRaport[expenseIndex].issueDate = doc.issue_date
                            prevDocsDownloadedForRaport[expenseIndex].docNumber = doc.doc_number
                            prevDocsDownloadedForRaport[expenseIndex].tags = doc.tags
                        }
                    })
                }
            }
            return { ...state, ...{ docsDownloadedForRaport: prevDocsDownloadedForRaport } }

        case ReportsConst.UPDATE_DOWNLOADED_PAYMENT_ORDERS:
            var prevDocsDownloadedForRaport = state.docsDownloadedForRaport
            if (isIterable(prevDocsDownloadedForRaport)) {
                prevDocsDownloadedForRaport.forEach(doc => {
                    if (doc.id === action.transfer.id && doc.type === 'PAYMENT_ORDER') {
                        doc.title = action.transfer.title
                        doc.accountNumber = action.transfer.account_number
                        doc.price = action.transfer.amount_to_pay ? action.transfer.amount_to_pay.slice(0, -2) : ""
                        doc.priceNumber = parseFloat(action.transfer.amount_to_pay)
                        doc.currency = action.transfer.currency
                        doc.recipient = action.transfer.recipient
                        doc.comment = action.transfer.comment
                        doc.tags = action.transfer.tags
                        doc.dueDate = moment(action.transfer.due_date).format('YYYY-MM-DD')
                        doc.alreadyInBankTransferFile = (action.transfer.bank_transfers_file_id || action.transfer.paid_by || action.transfer.currency !== 'PLN') ? true : false
                    }
                })
            }
            return { ...state, ...{ docsDownloadedForRaport: prevDocsDownloadedForRaport } }

        case ReportsConst.DELETE_FILE_FROM_REPORT:
            if (state.docsDownloadedForRaport && Array.isArray(state.docsDownloadedForRaport) && state.docsSortField && state.docsSortOrder) {
                var docsLoaded = state.docsDownloadedForRaport
                docsLoaded = docsLoaded.filter(e => e.fileId !== action.id)
                return { ...state, ...{ docsDownloadedForRaport: docsLoaded } }
            } else {
                return state
            }

        case ReportsConst.SEND_GET_DOCUMENTS_REQUEST:
            return { ...state, ...{ docsDownloadedForRaport: [], sendGetDocumentsRequest: true, gotDocumentsResponse: false, documentsAvalibleForReport: 0, reportDocsProjectId: action.projectId } }

        case ReportsConst.SET_WAITING_FOR_WHITE_LIST_RESPONSE:
            return { ...state, ...{ waitingForWhiteListStatus: action.waiting } }

        case ReportsConst.GET_REPORT_XLSX:
            return { ...state, ...{ xlsxReportData: action.data, generatingXlsxReport: action.state } }

        case ReportsConst.MARK_DOCS_IN_REPORT:
            var docsLoaded = state.docsDownloadedForRaport

            docsLoaded.forEach(doc => {
                if (action.docs.find(d => d.id === doc.id && d.type === doc.type)) {
                    doc.alreadyInReport = action.mark
                    doc.reportName = action.reportName
                }
            })

            return { ...state, ...{ docsDownloadedForRaport: docsLoaded } }

        case ReportsConst.MARK_DOCS_IN_ZIP:
            var docsLoaded = state.docsDownloadedForRaport

            docsLoaded.forEach(doc => {
                console.log(doc.type)
                console.log(doc.docType)
                if (doc.type === 'PAYMENT_ORDER' || doc.docType === 'PAYMENT_ORDER') {
                    console.log('EQUALS')
                    if (action.paymentOrders.includes(doc.fileId)) {
                        doc.wasExported = action.mark
                        doc.zipId = action.zipId
                    }
                } else if (action.files.includes(doc.fileId)) {
                    console.log('FOUND FILE')
                    doc.wasExported = action.mark
                    doc.zipId = action.zipId
                }
            })

            return { ...state, ...{ docsDownloadedForRaport: docsLoaded } }

        case ReportsConst.MARK_DOCS_BANK_TRANSFER_FILE:
            var docsLoaded = state.docsDownloadedForRaport

            docsLoaded.forEach(doc => {
                if (action.docs.find(d => d.id === doc.id && d.type === doc.type)) {
                    doc.alreadyInBankTransferFile = action.mark
                    doc.bankTransferFileName = action.fileName
                }
            })
            return { ...state, ...{ docsDownloadedForRaport: docsLoaded } }

        case ReportsConst.MARK_DOCS_WAPRO_XML:
            var docsLoaded = state.docsDownloadedForRaport

            docsLoaded.forEach(doc => {
                if (action.docs.find(d => d.id === doc.id && d.type === doc.type)) {
                    doc.availableForWaproExport = !action.mark
                    doc.waproXMLFileName = action.reportName
                    doc.accountingFileId = action.reportId
                    doc.accounted = action.markAsAccounted ? true : doc.accounted

                    if (action.mark) {
                        doc.notAvailableForWaproMsg = 'This document has already been exported to WAPRO'
                        doc.missingWaproFields = false
                    }
                }
            })

            return { ...state, ...{ docsDownloadedForRaport: docsLoaded } }

        case ReportsConst.MARK_DOCS_SYMFONIA_XML:
            var docsLoaded = state.docsDownloadedForRaport

            console.log(action.docs)
            docsLoaded.forEach(doc => {
                if (action.docs.find(d => d.id === doc.id && d.type === doc.type)) {
                    doc.missingSymfoniaFields = action.mark
                    doc.symfoniaXMLFileName = action.reportName
                }
            })

            return { ...state, ...{ docsDownloadedForRaport: docsLoaded } }

        case ReportsConst.BULK_TAGS_UPDATE:
            var docsLoaded = [...state.docsDownloadedForRaport]
            docsLoaded.forEach(doc => {
                if (action.isPaymentOrder && doc.type === 'PAYMENT_ORDER') {
                    var updatedDoc = action.updatedFiles.find(d => d.id === doc.id)
                    if (updatedDoc) {
                        doc.tags = updatedDoc.newTags
                    }
                } else {
                    var updatedDoc = action.updatedFiles.find(d => d.id === doc.fileId)
                    if (updatedDoc) {
                        doc.tags = updatedDoc.newTags
                    }
                }
            })

            return { ...state, ...{ docsDownloadedForRaport: docsLoaded } }

        case ReportsConst.MARK_DOC_AS_PAID:
            var currentAlreadyPaidDocs = isIterable(state?.alreadyPaidDocs) ? [...state.alreadyPaidDocs] : []
            var docsDownloadedForRaport = state.docsDownloadedForRaport

            if (docsDownloadedForRaport.length > 0) {
                if (action.mark) {
                    var docIndex = arrayObjectIndexOf(docsDownloadedForRaport, action.fileId, "fileId")
                    console.log(docIndex)
                    if (docIndex > -1) {
                        currentAlreadyPaidDocs.push(action.id)
                        docsDownloadedForRaport[docIndex].paymentMethod = action.method ? action.method.toUpperCase() : ''
                        docsDownloadedForRaport[docIndex].alreadyPaid = true
                        docsDownloadedForRaport[docIndex].paymentDate = moment().format('YYYY-MM-DD')
                        docsDownloadedForRaport[docIndex].paidBy = ''
                    }
                } else {
                    var docIndex = arrayObjectIndexOf(docsDownloadedForRaport, action.fileId, "fileId")
                    console.log(docIndex)
                    if (docIndex > -1) {
                        currentAlreadyPaidDocs = currentAlreadyPaidDocs.filter(e => e !== action.id)
                        docsDownloadedForRaport[docIndex].paymentMethod = action.method ? action.method.toUpperCase() : ''
                        docsDownloadedForRaport[docIndex].alreadyPaid = false
                        docsDownloadedForRaport[docIndex].paymentDate = ''
                        docsDownloadedForRaport[docIndex].paidBy = ''
                    }
                }
                return { ...state, ...{ alreadyPaidDocs: currentAlreadyPaidDocs, docsDownloadedForRaport: docsDownloadedForRaport } }
            } else {
                return state
            }

        case ReportsConst.DOC_ADDITIONAL_ACCEPTANCE:
            var docsDownloadedForRaport = state.docsDownloadedForRaport
            if (action.accept) {
                var docIndex = arrayObjectIndexOf(docsDownloadedForRaport, action.id, "id")
                if (docIndex > -1) {
                    docsDownloadedForRaport[docIndex].requiredAcceptancesCount -= 1
                    docsDownloadedForRaport[docIndex].requiredAcceptances = docsDownloadedForRaport[docIndex].requiredAcceptances.filter(e => e?.user_id && e?.user_id !== action.userId)
                    if (docsDownloadedForRaport[docIndex].requiredAcceptancesCount <= 0) {
                        docsDownloadedForRaport[docIndex].canPay = true
                        docsDownloadedForRaport[docIndex].alreadyInBankTransferFile = false
                        docsDownloadedForRaport[docIndex].notAvailableForBankTransfersMag = ''
                    }
                }
            } else {
                var docIndex = arrayObjectIndexOf(docsDownloadedForRaport, action.id, "id")
                if (docIndex > -1) {
                    docsDownloadedForRaport[docIndex].requiredAcceptances.push({ user_id: action.userId })
                    if (docsDownloadedForRaport[docIndex].requiredAcceptances.length !== 0) {
                        docsDownloadedForRaport[docIndex].canPay = false
                        docsDownloadedForRaport[docIndex].alreadyInBankTransferFile = true
                        docsDownloadedForRaport[docIndex].notAvailableForBankTransfersMag = 'Addition acceptance required'
                    }
                }
            }
            return { ...state, ...{ docsDownloadedForRaport: docsDownloadedForRaport } }

        case ReportsConst.MARK_DOC_CAN_BE_ACCOUNTED:
            var docsDownloadedForRaport = state.docsDownloadedForRaport
            var docIndex = arrayObjectIndexOf(docsDownloadedForRaport, action.fileId, "fileId")
            if (docIndex > -1) {
                docsDownloadedForRaport[docIndex].canBeAccounted = action.canBeAccounted
            }
            return { ...state, ...{ docsDownloadedForRaport: docsDownloadedForRaport } }

        case ReportsConst.MARK_DOC_AS_ACCOUNTED:
            var docsDownloadedForRaport = state.docsDownloadedForRaport
            var docIndex = arrayObjectIndexOf(docsDownloadedForRaport, action.fileId, "fileId")
            if (docIndex > -1) {
                docsDownloadedForRaport[docIndex].accounted = action.accounted
            }
            return { ...state, ...{ docsDownloadedForRaport: docsDownloadedForRaport } }

        case ReportsConst.SORT_DOCS:
            var docsLoaded = state.docsDownloadedForRaport
            var { docsSortField, docsSortOrder } = state

            if (docsSortField === action.name) {
                if (docsSortOrder === 'asc') {
                    docsSortOrder = 'desc'
                } else {
                    docsSortOrder = 'asc'
                }
            } else {
                docsSortField = action.name
                docsSortOrder = 'asc'
            }
            docsLoaded.sort(dynamicComparer(docsSortField, docsSortOrder))

            return { ...state, ...{ docsDownloadedForRaport: docsLoaded, docsSortField: docsSortField, docsSortOrder: docsSortOrder } }

        case ReportsConst.SEND_GET_MY_REPORTS_REQUEST:
            return { ...state, ...{ previousGeneratedReportsLoaded: false } }

        case ReportsConst.GET_ALL_MINE_REPORTS:
            var sortedDocuments = action.reports.sort(dynamicComparer('created', 'desc'))
            return { ...state, ...{ previousGeneratedReportsLoaded: true, previousGeneratedReports: sortedDocuments, reportsSortField: 'created', reportsSortOrder: 'desc' } }

        case ReportsConst.SEND_GET_MY_ZIPS_REQUEST:
            return { ...state, ...{ previousGeneratedZipLoaded: false } }

        case ReportsConst.GET_ALL_MINE_ZIPS:
            var sortedDocuments = action.zips.sort(dynamicComparer('created', 'desc'))
            return { ...state, ...{ previousGeneratedZipLoaded: true, previousGeneratedZip: sortedDocuments, zipsSortField: 'created', zipsSortOrder: 'desc' } }

        case ReportsConst.SEND_GET_MY_TRANSFER_FILES_REQUEST:
            return { ...state, ...{ previousGeneratedBankTransferFilesLoaded: false } }

        case ReportsConst.GET_ALL_MINE_TRANSFER_FILES:
            var sortedDocuments = []
            if (action.transferFiles.length > 0) {
                sortedDocuments = action.transferFiles.sort(dynamicComparer('created', 'desc'))
            }
            return { ...state, ...{ previousGeneratedBankTransferFilesLoaded: true, previousGeneratedBankTransferFiles: sortedDocuments, banktransferFilesSortField: 'created', banktransferFilesSortOrder: 'desc' } }

        case ReportsConst.SEND_GET_MY_ACCOUNTING_FILES_REQUEST:
            return { ...state, ...{ previousGeneratedAccountingFilesLoaded: false } }

        case ReportsConst.GET_ALL_MINE_ACCOUNTING_FILES:
            var sortedDocuments = []
            if (action.files.length > 0) {
                sortedDocuments = action.files.sort(dynamicComparer('created', 'desc'))
            }
            return { ...state, ...{ previousGeneratedAccountingFilesLoaded: true, previousGeneratedAccountingFiles: sortedDocuments, accountingFileSortField: 'created', accountingFileSortOrder: 'desc' } }

        case ReportsConst.DELETE_REPORT:
            var previousGeneratedReports = state.previousGeneratedReports.filter(f => f.id !== action.fileId)
            var docsLoaded = state.docsDownloadedForRaport

            docsLoaded.forEach(doc => {
                if (doc.reportName === action.reportName) {
                    doc.alreadyInReport = action.mark
                    doc.reportName = action.reportName
                }
            })

            return { ...state, ...{ previousGeneratedReports: previousGeneratedReports, docsDownloadedForRaport: docsLoaded } }

        case ReportsConst.DELETE_ZIP:
            var previousGeneratedZip = state.previousGeneratedZip.filter(f => f.id !== action.fileId)
            var docsLoaded = state.docsDownloadedForRaport

            docsLoaded.forEach(doc => {
                if (doc.zipId === action.fileId) {
                    doc.wasExported = false
                    doc.zipId = null
                }
            })

            return { ...state, ...{ previousGeneratedZip: previousGeneratedZip, docsDownloadedForRaport: docsLoaded } }

        case ReportsConst.DELETE_BANK_TRANSFER_FILE:
            var previousGeneratedBankTransferFiles = state.previousGeneratedBankTransferFiles.filter(f => f.id !== action.fileId)
            var docsLoaded = state.docsDownloadedForRaport

            docsLoaded.forEach(doc => {
                if (doc.bankTransferFileName === action.bankTransferFileName) {
                    doc.alreadyInBankTransferFile = false
                    doc.bankTransferFileName = null
                    doc.notAvailableForBankTransfersMag = ''
                }
            })
            return { ...state, ...{ previousGeneratedBankTransferFiles: previousGeneratedBankTransferFiles, docsDownloadedForRaport: docsLoaded } }

        case ReportsConst.DELETE_ACCOUNTING_FILE:
            var previousGeneratedAccountingFiles = state.previousGeneratedAccountingFiles.filter(f => f.id !== action.fileId)
            var docsLoaded = state.docsDownloadedForRaport

            docsLoaded.forEach(doc => {
                if (doc.accountingFileId === action.fileId) {
                    doc.accountingFileId = null
                }
            })

            return { ...state, ...{ previousGeneratedAccountingFiles: previousGeneratedAccountingFiles, docsDownloadedForRaport: docsLoaded } }

        case ReportsConst.GET_REPORT_BY_ID:
            var curentReports = [...state.previousGeneratedReports]
            var reportIndex = arrayObjectIndexOf(curentReports, action.report.id, "id");

            if (reportIndex === -1) {
                curentReports.push(action.report)
            }

            var sortedDocuments = curentReports.sort(dynamicComparer('created', 'desc'))

            return { ...state, ...{ previousGeneratedReports: sortedDocuments, reportsSortField: 'created', reportsSortOrder: 'desc', previousGeneratedReportsLoaded: true } }

        case ReportsConst.GET_TRANSFER_FILE_BY_ID:
            var curentReports = [...state.previousGeneratedBankTransferFiles]
            var reportIndex = arrayObjectIndexOf(curentReports, action.file.id, "id");

            if (reportIndex === -1) {
                curentReports.push(action.file)
            }

            var sortedDocuments = curentReports.sort(dynamicComparer('created', 'desc'))

            return { ...state, ...{ previousGeneratedBankTransferFiles: sortedDocuments, banktransferFilesSortField: 'created', banktransferFilesSortOrder: 'desc', previousGeneratedBankTransferFilesLoaded: true } }

        case ReportsConst.SORT_REPORTS:
            var reportsLoaded = [...state.previousGeneratedReports]
            var { reportsSortField, reportsSortOrder } = state

            if (reportsSortField === action.name) {
                if (reportsSortOrder === 'asc') {
                    reportsSortOrder = 'desc'
                } else {
                    reportsSortOrder = 'asc'
                }
            } else {
                reportsSortField = action.name
                reportsSortOrder = 'asc'
            }
            reportsLoaded.sort(dynamicComparer(reportsSortField, reportsSortOrder))

            return { ...state, ...{ previousGeneratedReports: reportsLoaded, reportsSortField: reportsSortField, reportsSortOrder: reportsSortOrder } }

        case ReportsConst.SORT_ZIPS:
            var zipssLoaded = [...state.previousGeneratedZip]
            var { zipsSortField, zipsSortOrder } = state

            if (zipsSortField === action.name) {
                if (zipsSortOrder === 'asc') {
                    zipsSortOrder = 'desc'
                } else {
                    zipsSortOrder = 'asc'
                }
            } else {
                zipsSortField = action.name
                zipsSortOrder = 'asc'
            }
            zipssLoaded.sort(dynamicComparer(zipsSortField, zipsSortOrder))

            return { ...state, ...{ previousGeneratedZip: zipssLoaded, zipsSortField: zipsSortField, zipsSortOrder: zipsSortOrder } }

        case ReportsConst.SORT_TRANSFER_FILES:
            var filesLoaded = [...state.previousGeneratedBankTransferFiles]
            var { banktransferFilesSortField, banktransferFilesSortOrder } = state

            if (banktransferFilesSortField === action.name) {
                if (banktransferFilesSortOrder === 'asc') {
                    banktransferFilesSortOrder = 'desc'
                } else {
                    banktransferFilesSortOrder = 'asc'
                }
            } else {
                banktransferFilesSortField = action.name
                banktransferFilesSortOrder = 'asc'
            }
            filesLoaded.sort(dynamicComparer(banktransferFilesSortField, banktransferFilesSortOrder))

            return { ...state, ...{ previousGeneratedBankTransferFiles: filesLoaded, banktransferFilesSortField: banktransferFilesSortField, banktransferFilesSortOrder: banktransferFilesSortOrder } }

        case ReportsConst.SORT_ACCOUNTING_FILES:
            var filesLoaded = [...state.previousGeneratedAccountingFiles]
            var { accountingFileSortField, accountingFileSortOrder } = state

            if (accountingFileSortField === action.name) {
                if (accountingFileSortOrder === 'asc') {
                    accountingFileSortOrder = 'desc'
                } else {
                    accountingFileSortOrder = 'asc'
                }
            } else {
                accountingFileSortField = action.name
                accountingFileSortOrder = 'asc'
            }
            filesLoaded.sort(dynamicComparer(accountingFileSortField, accountingFileSortOrder))

            return { ...state, ...{ previousGeneratedAccountingFiles: filesLoaded, accountingFileSortField: accountingFileSortField, accountingFileSortOrder: accountingFileSortOrder } }

        case ReportsConst.DELETE_PROJECT:
            var docsDownloadedForRaport = state.docsDownloadedForRaport

            if (docsDownloadedForRaport.length > 0) {
                var firstDoc = docsDownloadedForRaport[0]
                if (firstDoc.projectId === action.projectId) {
                    docsDownloadedForRaport = []
                }
            }

            return { ...state, ...{ docsDownloadedForRaport: docsDownloadedForRaport } }

        case ReportsConst.LOGOUT:
            return {
                initialState
            }


        default:
            return state
    }
}