import html2canvas from "html2canvas";

export const makeScreenshot = async (selector) => {
  return new Promise((resolve, reject) => {
    const node = document.querySelector(selector);

    html2canvas(node).then((canvas) => {
      const pngUrl = canvas.toDataURL();
      resolve(pngUrl);
    });
  });
};
