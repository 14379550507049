import React, { Component } from 'react'
import '../css/AnimatedCheckmark.css'

export class AnimatedCheckmark extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        return (
            <div class="checkmark__wrapper">
                <svg class="checkmark_svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                    <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
            </div>
        )
    }
}

export default AnimatedCheckmark