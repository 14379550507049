import arrayObjectIndexOf from "../helpers/indexOfObject"
import { sortUploadedFiles, findIndex } from "../helpers/sortFiles"
import { mapDocDataForPreview } from '../helpers/pdfPreviewHelpers'
import moment from 'moment'

function sortInvoiceProperties(props) {
    var propsToReturn = []

    if (props.find(e => e.name === 'sellerVatId')) propsToReturn.push(props.find(e => e.name === 'sellerVatId'))
    if (props.find(e => e.name === 'sellerName')) propsToReturn.push(props.find(e => e.name === 'sellerName'))
    if (props.find(e => e.name === 'sellerCode')) {
        console.log('!!!!!!!!!!!!!!!!!')
        console.log(props.find(e => e.name === 'sellerCode'))
        propsToReturn.push(props.find(e => e.name === 'sellerCode'))
    } else {
        propsToReturn.push({
            name: 'sellerCode',
            text: '',
            offset: null,
            title: 'Seller Code',
            possition: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 0
            }
        })
    }
    if (props.find(e => e.name === 'address')) propsToReturn.push(props.find(e => e.name === 'address'))
    if (props.find(e => e.name === 'countryCode')) {
        propsToReturn.push(props.find(e => e.name === 'countryCode'))
    } else {
        propsToReturn.push({
            name: 'countryCode',
            text: '',
            offset: null,
            title: 'Country Code',
            possition: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 0
            }
        })
    }
    if (props.find(e => e.name === 'buyerVatId')) propsToReturn.push(props.find(e => e.name === 'buyerVatId'))
    if (props.find(e => e.name === 'buyerName')) propsToReturn.push(props.find(e => e.name === 'buyerName'))
    if (props.find(e => e.name === 'invoiceNumber')) propsToReturn.push(props.find(e => e.name === 'invoiceNumber'))
    if (props.find(e => e.name === 'issueDate')) propsToReturn.push(props.find(e => e.name === 'issueDate'))
    if (props.find(e => e.name === 'saleDate')) {
        propsToReturn.push(props.find(e => e.name === 'saleDate'))
    } else {
        propsToReturn.push({
            ...props.find(e => e.name === 'issueDate'),
            ...{ name: "saleDate", title: "Sale Date" }
        })
    }
    if (props.find(e => e.name === 'dueDate')) propsToReturn.push(props.find(e => e.name === 'dueDate'))
    if (props.find(e => e.name === 'netto')) propsToReturn.push(props.find(e => e.name === 'netto'))
    if (props.find(e => e.name === 'brutto')) propsToReturn.push(props.find(e => e.name === 'brutto'))
    if (props.find(e => e.name === 'currencyRate')) propsToReturn.push(props.find(e => e.name === 'currencyRate'))
    else propsToReturn.push({
        name: "currencyRate", title: "Currency rate", text: null, possition: null
    })
    if (props.find(e => e.name === 'price')) propsToReturn.push(props.find(e => e.name === 'price'))
    if (props.find(e => e.name === 'tax')) propsToReturn.push(props.find(e => e.name === 'tax'))
    if (props.find(e => e.name === 'bankData')) propsToReturn.push(props.find(e => e.name === 'bankData'))
    if (props.find(e => e.name === 'swift')) propsToReturn.push(props.find(e => e.name === 'swift'))

    return propsToReturn
}

export const FileConst = {
    MARK_PROJECTS_TO_LOAD: 'MARK_PROJECTS_TO_LOAD',
    GET_FILES: 'GET_FILES',
    SWITCH_DIR: 'SWITCH_DIR',
    GET_FILES_REQUEST: 'GET_FILES_REQUEST',
    SORT_UPLOADED_FIlES: 'SORT_UPLOADED_FIlES',
    GET_FILES_TO_VERIFI: 'GET_FILES_TO_VERIFI',
    GET_FILES_TO_VERIFI_REQUEST: 'GET_FILES_TO_VERIFI_REQUEST',
    SORT_FIlES_TO_VERIFI: 'SORT_FIlES_TO_VERIFI',
    SEARCH_FILES_TO_VERIFY: 'SEARCH_FILES_TO_VERIFY',
    FILTER_FILES_TO_VERIFY: 'FILTER_FILES_TO_VERIFY',
    LOAD_NEW_FILE_TO_VERIFY_DATA: 'LOAD_NEW_FILE_TO_VERIFY_DATA',
    PARSED_PDF_FILE: 'PARSED_PDF_FILE',
    OCR_FOR_AREA: 'OCR_FOR_AREA',
    LOAD_NEW_OCR: 'LOAD_NEW_OCR',
    CHANGE_OCR_VALUE: 'CHANGE_OCR_VALUE',
    SET_OCR_NEW_COUNTERPARTY: 'SET_OCR_NEW_COUNTERPARTY',
    GET_COMPANY_BY_VATID: 'GET_COMPANY_BY_VATID',
    MARK_AREA_PROCESSING: 'MARK_AREA_PROCESSING',
    MARK_AREA_PROCESSED: 'MARK_AREA_PROCESSED',
    CLEAR_DATA_READ_BY_OCR_FRAME: 'CLEAR_DATA_READ_BY_OCR_FRAME',
    LOAD_GENERATED_OCR: 'LOAD_GENERATED_OCR',
    CACHE_FILE: 'CACHE_FILE',
    REMOVE_FILE_FROM_CACHE: 'REMOVE_FILE_FROM_CACHE',
    CHANGE_FILE_STATUS: 'CHANGE_FILE_STATUS',
    CHANGE_FILE_STATUS_TO_NOT_ACCEPTED: 'CHANGE_FILE_STATUS_TO_NOT_ACCEPTED',
    CHANGE_FILE_STATUS_TO_ACCEPTED: 'CHANGE_FILE_STATUS_TO_ACCEPTED',
    MARK_FILE_AS_PAID: 'MARK_FILE_AS_PAID',
    MARK_SALE_INVOICE_PAYMENT: 'MARK_SALE_INVOICE_PAYMENT',
    UPDATE_SALE_INVOICE_KSEF_STATUS: 'UPDATE_SALE_INVOICE_KSEF_STATUS',
    GET_OCR_MODEL: 'GET_OCR_MODEL',
    GET_FILE_BODY: 'GET_FILE_BODY',
    LOADING_SCREEN: 'LOADING_SCREEN',
    TOGGLE_PREVIEW_FOR_UNPAID_DOCS: 'TOGGLE_PREVIEW_FOR_UNPAID_DOCS',
    TOGGLE_PREVIEW_FOR_FULL_TEXT_SEARCH: 'TOGGLE_PREVIEW_FOR_FULL_TEXT_SEARCH',
    TOGGLE_PREVIEW_FOR_ACTIONS: 'TOGGLE_PREVIEW_FOR_ACTIONS',
    GET_PREVIEV_DATA: 'GET_PREVIEV_DATA',
    UPDATE_PREVIEV_FILE: 'UPDATE_PREVIEV_FILE',
    WAIT_FOR_LINKED_DOCS: 'WAIT_FOR_LINKED_DOCS',
    GET_LINKED_DOCS: 'GET_LINKED_DOCS',
    TOGGLE_LINKED_DOCS: 'TOGGLE_LINKED_DOCS',
    OPEN_SIDE_BY_SIDE_PREVIEW: 'OPEN_SIDE_BY_SIDE_PREVIEW',
    CLOSE_SIDE_BY_SIDE_PREVIEW: 'CLOSE_SIDE_BY_SIDE_PREVIEW',
    ADD_NEW_ATTACHMENT: 'ADD_NEW_ATTACHMENT',
    REMOVE_ATTACHMENT: 'REMOVE_ATTACHMENT',
    UPDATE_PREVIEV_DATA: 'UPDATE_PREVIEV_DATA',
    TOGGLE_PREVIEW: 'TOGGLE_PREVIEW',
    RESTORE_DOCUMENT: 'RESTORE_DOCUMENT',
    DELETE_FILE: 'DELETE_FILE',
    DELETE_FILE_PERMANENT: 'DELETE_FILE_PERMANENT',
    DELETE_UNVERIFIED_FILE: 'DELETE_UNVERIFIED_FILE',
    INVOICE_EXISTS: 'INVOICE_EXISTS',
    CLOSE_EXISTING_INVOICE_MODAL: 'CLOSE_EXISTING_INVOICE_MODAL',
    MAKE_DOC_BACKUP: 'MAKE_DOC_BACKUP',
    LOAD_DOC_BUCKUP: 'LOAD_DOC_BUCKUP',
    EDIT_DOC_INPUT: 'EDIT_DOC_INPUT',
    EDIT_PDF_BACKUP: 'EDIT_PDF_BACKUP',
    SET_DOC_TYPE: 'SET_DOC_TYPE',
    SET_EXPENSE_OWNER: 'SET_EXPENSE_OWNER',

    TOGGLE_PROJECT_FILES: 'TOGGLE_PROJECT_FILES',
    HIDE_ALL_PROJECT_FILES: 'HIDE_ALL_PROJECT_FILES',
    HIDE_REFRESH_OCR_POPUP: 'HIDE_REFRESH_OCR_POPUP',
    HIDE_ACCEPTED_OCR_POPUP: 'HIDE_ACCEPTED_OCR_POPUP',
    OCR_SCREEN_NOTIFICATION_ABOUT_DELETED_FILE: 'OCR_SCREEN_NOTIFICATION_ABOUT_DELETED_FILE',
    OCR_SCREEN_NOTIFICATION_ABOUT_MOVED_FILE: 'OCR_SCREEN_NOTIFICATION_ABOUT_MOVED_FILE',
    SHOW_REFRESH_OCR_POPUP_WITH_ERROR: 'SHOW_REFRESH_OCR_POPUP_WITH_ERROR',
    SHOW_ACCEPTED_OCR_POPUP_WITH_ERROR: 'SHOW_ACCEPTED_OCR_POPUP_WITH_ERROR',

    CHANGE_FILE_ACCEPTNCE_STEP: 'CHANGE_FILE_ACCEPTNCE_STEP',
    CHANGE_FILE_TO_VERIFI_ACCEPTNCE_STEP: 'CHANGE_FILE_TO_VERIFI_ACCEPTNCE_STEP',

    SET_FILE_TO_VERIFI_HIGH_PRIORITY: 'SET_FILE_TO_VERIFI_HIGH_PRIORITY',
    SET_FILE_HIGH_PRIORITY: 'SET_FILE_HIGH_PRIORITY',

    GET_COMPANY_GENERATED_INVOICES: 'GET_COMPANY_GENERATED_INVOICES',
    GENERATED_NEW_INVOICE: 'GENERATED_NEW_INVOICE',
    DELETE_GENERATED_INVOICE: 'DELETE_GENERATED_INVOICE',
    ACCEPT_GENERATED_SALE_INVOICE: 'ACCEPT_GENERATED_SALE_INVOICE',
    UNLOCK_GENERATED_SALE_INVOICE: 'UNLOCK_GENERATED_SALE_INVOICE',
    SEND_SALE_INVOICE_TO_KSEF: 'SEND_SALE_INVOICE_TO_KSEF',

    LOAD_NEW_FILE_NAMES: 'LOAD_NEW_FILE_NAMES',
    DELETE_PROJECT: 'DELETE_PROJECT-FILES',
    LOGOUT: 'LOGOUT',
    SORT_INVOICE: 'SORT_INVOICE'
}

const initialState = {
    files: [],
    uploadedFiles: [],
    filesToVerifiLoaded: [],
    filesToVerifySortOrder: 'desc',
    filesToVerifySortField: 'name',
    unpaidDocsPreviewIsOpen: false,
    fullTextSearchPreviewIsOpen: false,
    actionsPreviewIsOpen: false,
    pdfPreviewData: {
        body: null,
        name: null,
        saveChangesError: false,
    },
    pdfPreviewOpen: false,
    previewClickFromChatNotification: false,
    backupPdfPreviewData: null,
    loadingScreen: false,
    returnedOCRData: {},
    processingData: [],
    dataReadWithOcrFrame: [],
    processedData: [],
    existingInvoice: null,
    similarInvoices: { files: [], linkedFiles: {} },
    showRefreshOcrPopup: false,
    showRefreshOcrPopupError: false,
    refreshOcrPopupUserName: '',
    showAcceptedOcrPopup: false,
    showAcceptedOcrPopupError: false,
    counterpartyLinkedDocs: {},
    showSideBySidePreview: {
        show: false,
    },
    loadedInvoices: [],
    invoicesSortField: 'issue_date',
    invoiceSortOrder: 'desc'

}

function dynamicComparer(key, order = 'asc') {
    return function (a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            return 0;
        }

        const varA = (typeof a[key] === 'string') ?
            a[key].toUpperCase() : a[key] || "";
        const varB = (typeof b[key] === 'string') ?
            b[key].toUpperCase() : b[key] || "";

        var comparison = 0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }
        return (
            (order == 'desc') ? (comparison * -1) : comparison
        );
    };
}

export default (state = initialState, action) => {
    switch (action.type) {

        case FileConst.MARK_PROJECTS_TO_LOAD:
            var filesToVerifiLoaded = JSON.parse(JSON.stringify(state.filesToVerifiLoaded))

            var projectsCount = filesToVerifiLoaded.length
            for (var i = 0; i < projectsCount; i++) {
                if (filesToVerifiLoaded[i].loaded) {
                    filesToVerifiLoaded[i].reloadFiles = true
                }
            }

            return { ...state, ...{ filesToVerifiLoaded: filesToVerifiLoaded } }

        case FileConst.GET_FILES_REQUEST:
            var currentFiles = state.uploadedFiles
            var projectIndex = arrayObjectIndexOf(currentFiles, action.projectId, "project");
            if (projectIndex === -1) {
                currentFiles.push({
                    project: action.projectId,
                    files: [],
                    sortField: "path",
                    sortOrder: "desc",
                    sendRequest: true,
                    extended: false
                })
            }

            return { ...state, ...{ uploadedFiles: currentFiles } }

        case FileConst.SWITCH_DIR:
            var currentFiles = state.uploadedFiles
            var projectIndex = arrayObjectIndexOf(currentFiles, action.projectId, "project");
            console.log(projectIndex)
            if (projectIndex > -1) {
                currentFiles[projectIndex].currentDir = action.currentDir
            }
            return { ...state, ...{ uploadedFiles: currentFiles } }

        case FileConst.GET_FILES:
            var currentFiles = [...state.uploadedFiles]
            if (action.projectId !== 0) {
                var projectIndex = arrayObjectIndexOf(currentFiles, action.projectId, "project");
                console.log(projectIndex)
                if (projectIndex > -1) {
                    if ((action.files && action.files.length > 1) || currentFiles[projectIndex].files.length === 0) {
                        currentFiles[projectIndex].files = action.files
                    } else {
                        var fileToPush = action.files[0]
                        var fileIndex = findIndex(currentFiles[projectIndex].files, currentFiles[projectIndex].files.length, fileToPush)
                        if (fileIndex !== -1) {
                            if (currentFiles[projectIndex].files[fileIndex] && currentFiles[projectIndex].files[fileIndex].id === fileToPush.id) currentFiles[projectIndex].files[fileIndex] = fileToPush
                            else {
                                currentFiles[projectIndex].files.splice(fileIndex, 0, fileToPush);
                            }
                        }
                    }
                    currentFiles[projectIndex].loaded = true
                    currentFiles[projectIndex].currentDir = action.currentDir || currentFiles[projectIndex].currentDir
                } else {
                    currentFiles.push({
                        project: action.projectId,
                        files: action.files,
                        sortField: "path",
                        sortOrder: "desc",
                        currentDir: action.currentDir,
                        sendRequest: true,
                        loaded: true,
                        extended: false,
                    })

                    var projectIndex = arrayObjectIndexOf(currentFiles, action.projectId, "project");
                }

                projectIndex = arrayObjectIndexOf(currentFiles, action.projectId, "project");
            } else {
                var currentProjectCount = currentFiles.length
                action.files.forEach(project => {
                    var foundProject = false
                    for (var i = 0; i < currentProjectCount; i++) {
                        if (currentFiles[i].project === project.projectId) {
                            currentFiles[i].files = project.files
                            // .sort(dynamicComparer("path", "desc"))
                            foundProject = true
                        }
                    }

                    if (!foundProject) {
                        currentFiles.push({
                            project: project.projectId,
                            files: project.files,
                            // .sort(dynamicComparer("path", "desc")),
                            sortField: "path",
                            sortOrder: "desc",
                            currentDir: action.currentDir,
                            sendRequest: true,
                            loaded: true,
                            extended: false,
                        })
                    }
                })
            }

            currentFiles = sortUploadedFiles(currentFiles)
            return { ...state, ...{ uploadedFiles: currentFiles } }

        case FileConst.SORT_UPLOADED_FIlES:
            var currentFiles = state.uploadedFiles
            var projectIndex = arrayObjectIndexOf(currentFiles, action.projectId, "project");
            if (projectIndex > -1) {
                if (currentFiles[projectIndex].sortField === action.name) {
                    if (currentFiles[projectIndex].sortOrder === 'asc') {
                        currentFiles[projectIndex].sortOrder = 'desc'
                    } else {
                        currentFiles[projectIndex].sortOrder = 'asc'
                    }
                } else {
                    currentFiles[projectIndex].sortField = action.name
                    currentFiles[projectIndex].sortOrder = 'asc'
                }
                currentFiles[projectIndex].files.sort(dynamicComparer(currentFiles[projectIndex].sortField, currentFiles[projectIndex].sortOrder))
            }

            return { ...state, ...{ uploadedFiles: currentFiles } }

        case FileConst.OCR_FOR_AREA:
            var currentReturnedOCRData = { ...state.returnedOCRData }


            var propertyIndex = arrayObjectIndexOf(currentReturnedOCRData.props, action.prop, "name");

            if (propertyIndex > -1) {
                currentReturnedOCRData.props[propertyIndex] = {
                    name: action.prop,
                    text: (action.prop === "dueDate" || action.prop === "issueDate" ? action.text.replace(/\s|(\r\n)+|\r+|\n+|\t+/gm, ' ').replace(/[^a-zA-Z0-9-.,\/\\]/gmi, '').replace(/\./gmi, '-').replace(/\,/gmi, '-').replace(/\s/gmi, '-').replace(/\//gmi, '-').replace(/\\/gmi, '-') : action.text),
                    offset: action.offset,
                    title: action.title,
                    possition: action.possition
                }
            } else {
                currentReturnedOCRData.props.unshift({
                    name: action.prop,
                    text: (action.prop === "dueDate" || action.prop === "issueDate" ? action.text.replace(/\s|(\r\n)+|\r+|\n+|\t+/gm, ' ').replace(/[^a-zA-Z0-9-.,\/\\]/gmi, '').replace(/\./gmi, '-').replace(/\,/gmi, '-').replace(/\s/gmi, '-').replace(/\//gmi, '-').replace(/\\/gmi, '-') : action.text),
                    offset: action.offset,
                    title: action.title,
                    possition: action.possition
                })
            }

            if (action.prop === 'brutto' && action.text) {
                propertyIndex = arrayObjectIndexOf(currentReturnedOCRData.props, 'price', "name")

                if (propertyIndex > -1) {
                    currentReturnedOCRData.props[propertyIndex].text = action.text
                } else {
                    currentReturnedOCRData.props.unshift({
                        name: 'price',
                        text: action.text,
                        offset: action.offset,
                        title: 'Price',
                        possition: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 0
                        }
                    })
                }
            } else if (action.prop === "sellerVatId") {
                if (action.companyName) {
                    propertyIndex = arrayObjectIndexOf(currentReturnedOCRData.props, 'sellerName', "name");
                    if (propertyIndex > -1) {
                        currentReturnedOCRData.props[propertyIndex] = {
                            name: 'sellerName',
                            text: action.companyName,
                            offset: action.offset,
                            title: 'Seller Name',
                            possition: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 0
                            }
                        }
                    } else {
                        currentReturnedOCRData.props.unshift({
                            name: 'sellerName',
                            text: action.companyName,
                            offset: action.offset,
                            title: 'Seller Name',
                            possition: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 0
                            }
                        })
                    }

                    currentReturnedOCRData.defaultData.sellerName = action.companyName
                }

                if (action.companyCode) {
                    propertyIndex = arrayObjectIndexOf(currentReturnedOCRData.props, 'sellerCode', "name");
                    if (propertyIndex > -1) {
                        currentReturnedOCRData.props[propertyIndex] = {
                            name: 'sellerCode',
                            text: action.companyCode,
                            offset: action.offset,
                            title: 'Seller Code',
                            possition: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 0
                            }
                        }
                    } else {
                        currentReturnedOCRData.props.unshift({
                            name: 'sellerCode',
                            text: action.companyCode,
                            offset: action.offset,
                            title: 'Seller Code',
                            possition: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 0
                            }
                        })
                    }

                    currentReturnedOCRData.defaultData.sellerCode = action.companyCode
                }

                if (action.sellerAddress) {
                    propertyIndex = arrayObjectIndexOf(currentReturnedOCRData.props, 'address', "name");
                    if (propertyIndex > -1) {
                        currentReturnedOCRData.props[propertyIndex] = {
                            name: 'address',
                            text: action.sellerAddress,
                            offset: action.offset,
                            title: 'Seller Address',
                            possition: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 0
                            }
                        }
                    } else {
                        currentReturnedOCRData.props.unshift({
                            name: 'address',
                            text: action.sellerAddress,
                            offset: action.offset,
                            title: 'Seller Address',
                            possition: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 0
                            }
                        })
                    }

                    propertyIndex = arrayObjectIndexOf(currentReturnedOCRData.props, 'countryCode', "name");
                    if (propertyIndex > -1) {
                        currentReturnedOCRData.props[propertyIndex] = {
                            name: 'countryCode',
                            text: 'PL',
                            offset: action.offset,
                            title: 'Country Code',
                            possition: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 0
                            }
                        }
                    } else {
                        currentReturnedOCRData.props.unshift({
                            name: 'countryCode',
                            text: 'PL',
                            offset: action.offset,
                            title: 'Country Code',
                            possition: {
                                x1: 0,
                                y1: 0,
                                x2: 0,
                                y2: 0
                            }
                        })
                    }
                }

                if (action.lastSellerAccount) {
                    currentReturnedOCRData.defaultData.sellerAccount = action.lastSellerAccount
                }

                currentReturnedOCRData.newCounterparty = action.newCounterparty

                if (action.text && action.text.length > 2) {
                    if (action.text.charAt(0) !== action.text.charAt(0).toLowerCase() && action.text.charAt(1) !== action.text.charAt(1).toLowerCase()) {
                        var countryCode = action.text.substring(0, 2)

                        if (countryCode !== 'NI') {
                            propertyIndex = arrayObjectIndexOf(currentReturnedOCRData.props, 'countryCode', "name");
                            if (propertyIndex > -1) {
                                currentReturnedOCRData.props[propertyIndex] = {
                                    name: 'countryCode',
                                    text: countryCode,
                                    offset: null,
                                    title: 'Country Code',
                                    possition: {
                                        x1: 0,
                                        y1: 0,
                                        x2: 0,
                                        y2: 0
                                    }
                                }
                            } else {
                                currentReturnedOCRData.props.unshift({
                                    name: 'countryCode',
                                    text: countryCode,
                                    offset: null,
                                    title: 'Country Code',
                                    possition: {
                                        x1: 0,
                                        y1: 0,
                                        x2: 0,
                                        y2: 0
                                    }
                                })
                            }
                        }
                    }
                }
            }


            var currentProcessingData = [...state.processingData]
            currentProcessingData = currentProcessingData.filter(e => e !== action.prop)

            var currentProcessedData = [...state.processedData]
            var currentDataReadWithOcrFrame = [...state.dataReadWithOcrFrame]
            if (action.text) {
                if (!currentProcessedData.includes(action.prop)) currentProcessedData.push(action.prop)
                if (!currentDataReadWithOcrFrame.includes(action.prop)) currentDataReadWithOcrFrame.push(action.prop)
            }
            if (action.companyName) {
                currentProcessedData.push('sellerName')
                currentDataReadWithOcrFrame.push('sellerName')
            }
            if (action.sellerAddress) {
                currentProcessedData.push('address')
                currentDataReadWithOcrFrame.push('address')
            }

            return { ...state, ...{ returnedOCRData: currentReturnedOCRData, processingData: currentProcessingData, processedData: currentProcessedData, dataReadWithOcrFrame: currentDataReadWithOcrFrame } }

        case FileConst.LOAD_NEW_OCR:
            var currentReturnedOCRData = { ...state.returnedOCRData }

            action.ocr.forEach(prop => {
                var propIndex = arrayObjectIndexOf(currentReturnedOCRData.props, prop.name, "name");
                if (propIndex > -1) {
                    currentReturnedOCRData.props[propIndex].possition = prop.possition
                    currentReturnedOCRData.props[propIndex].text = prop.text
                } else if (prop.name === 'bank') {
                    propIndex = arrayObjectIndexOf(currentReturnedOCRData.props, 'bankData', "name");
                    if (propIndex > -1) {
                        currentReturnedOCRData.props[propIndex].possition = prop.possition
                        currentReturnedOCRData.props[propIndex].text = prop.text
                    }
                } else if (prop.name === 'date') {
                    propIndex = arrayObjectIndexOf(currentReturnedOCRData.props, 'dueDate', "name");
                    if (propIndex > -1) {
                        currentReturnedOCRData.props[propIndex].possition = prop.possition
                        currentReturnedOCRData.props[propIndex].text = prop.text
                    }
                }
            })

            var currentProcessedData = [...state.processedData]
            action.ocr.forEach(prop => {
                if (prop.text) {
                    if (prop.name === 'date') {
                        if (!currentProcessedData.includes('dueDate')) currentProcessedData.push('dueDate')
                    } else if (prop.name === 'bank') {
                        if (!currentProcessedData.includes('bankData')) currentProcessedData.push('bankData')
                    } else if (!currentProcessedData.includes(prop.name)) currentProcessedData.push(prop.name)
                }
            })


            return { ...state, ...{ returnedOCRData: currentReturnedOCRData, processedData: currentProcessedData } }

        case FileConst.CHANGE_OCR_VALUE:
            if (!action.value) {
                action.value = ''
            }
            var currentReturnedOCRData = { ...state.returnedOCRData }

            if (action.ocrType === 'select') {
                var propertyIndex = arrayObjectIndexOf(currentReturnedOCRData.props, action.prop, "name");

                if (propertyIndex > -1) {
                    console.log(currentReturnedOCRData.props[propertyIndex])
                    if (action.prop !== "dueDate" && action.prop !== "issueDate") currentReturnedOCRData.props[propertyIndex].text = action.value

                    else currentReturnedOCRData.props[propertyIndex].text = action.value.replace(/\s|(\r\n)+|\r+|\n+|\t+/gm, ' ').replace(/\./gmi, '-').replace(/\,/gmi, '-').replace(/\s/gmi, '-').replace(/\//gmi, '-').replace(/\\/gmi, '-')
                }
            } else if (action.ocrType === 'manual') {
                console.log('ZGŁASZAM SIE w FILE REDUCER', action.prop)
                var propertyIndex = arrayObjectIndexOf(currentReturnedOCRData.manualProps, action.prop, "name");

                if (propertyIndex > -1) {
                    console.log(currentReturnedOCRData.manualProps[propertyIndex])
                    if (action.prop !== "dueDate" && action.prop !== "issueDate") currentReturnedOCRData.manualProps[propertyIndex].text = action.value

                    else currentReturnedOCRData.manualProps[propertyIndex].text = action.value.replace(/\s|(\r\n)+|\r+|\n+|\t+/gm, ' ').replace(/\./gmi, '-').replace(/\,/gmi, '-').replace(/\s/gmi, '-').replace(/\//gmi, '-').replace(/\\/gmi, '-')
                }
            } else if (action.docType === 'AGREEMENT') {
                var propertyIndex = arrayObjectIndexOf(currentReturnedOCRData.agreementProps, action.prop, "name");

                if (propertyIndex > -1) {
                    if (action.prop !== 'date') currentReturnedOCRData.agreementProps[propertyIndex].text = action.value
                    else currentReturnedOCRData.agreementProps[propertyIndex].text = action.value.replace(/\s|(\r\n)+|\r+|\n+|\t+/gm, ' ').replace(/\./gmi, '-').replace(/\,/gmi, '-').replace(/\s/gmi, '-').replace(/\//gmi, '-').replace(/\\/gmi, '-')
                    currentReturnedOCRData.agreementProps[propertyIndex].possition = action.possition
                }
            } else if (action.docType === 'INCOME') {
                var propertyIndex = arrayObjectIndexOf(currentReturnedOCRData.incomeProps, action.prop, "name");

                if (propertyIndex > -1) {
                    if (action.prop !== 'date') currentReturnedOCRData.incomeProps[propertyIndex].text = action.value
                    else currentReturnedOCRData.incomeProps[propertyIndex].text = action.value.replace(/\s|(\r\n)+|\r+|\n+|\t+/gm, ' ').replace(/\./gmi, '-').replace(/\,/gmi, '-').replace(/\s/gmi, '-').replace(/\//gmi, '-').replace(/\\/gmi, '-')
                    currentReturnedOCRData.incomeProps[propertyIndex].possition = action.possition
                }
            } else if (action.docType === 'CORRESPONDENCE') {
                var propertyIndex = arrayObjectIndexOf(currentReturnedOCRData.correspondenceProps, action.prop, "name");

                if (propertyIndex > -1) {
                    if (action.prop !== 'date') currentReturnedOCRData.correspondenceProps[propertyIndex].text = action.value
                    else currentReturnedOCRData.correspondenceProps[propertyIndex].text = action.value.replace(/\s|(\r\n)+|\r+|\n+|\t+/gm, ' ').replace(/\./gmi, '-').replace(/\,/gmi, '-').replace(/\s/gmi, '-').replace(/\//gmi, '-').replace(/\\/gmi, '-')
                    currentReturnedOCRData.correspondenceProps[propertyIndex].possition = action.possition
                }
            } else if (action.docType === 'CORPORATE') {
                var propertyIndex = arrayObjectIndexOf(currentReturnedOCRData.corporateProps, action.prop, "name");

                if (propertyIndex > -1) {
                    if (action.prop !== 'date') currentReturnedOCRData.corporateProps[propertyIndex].text = action.value
                    else currentReturnedOCRData.corporateProps[propertyIndex].text = action.value.replace(/\s|(\r\n)+|\r+|\n+|\t+/gm, ' ').replace(/\./gmi, '-').replace(/\,/gmi, '-').replace(/\s/gmi, '-').replace(/\//gmi, '-').replace(/\\/gmi, '-')
                    currentReturnedOCRData.corporateProps[propertyIndex].possition = action.possition
                }
            } else if (action.docType === 'OTHER') {
                var propertyIndex = arrayObjectIndexOf(currentReturnedOCRData.otherProps, action.prop, "name");

                if (propertyIndex > -1) {
                    if (action.prop !== 'date') currentReturnedOCRData.otherProps[propertyIndex].text = action.value
                    else currentReturnedOCRData.otherProps[propertyIndex].text = action.value.replace(/\s|(\r\n)+|\r+|\n+|\t+/gm, ' ').replace(/\./gmi, '-').replace(/\,/gmi, '-').replace(/\s/gmi, '-').replace(/\//gmi, '-').replace(/\\/gmi, '-')
                    currentReturnedOCRData.otherProps[propertyIndex].possition = action.possition
                }
            } else if (action.docType === 'PROTOCOL') {
                var propertyIndex = arrayObjectIndexOf(currentReturnedOCRData.protocolProps, action.prop, "name");

                if (propertyIndex > -1) {
                    if (action.prop !== 'date') currentReturnedOCRData.protocolProps[propertyIndex].text = action.value
                    else currentReturnedOCRData.protocolProps[propertyIndex].text = action.value.replace(/\s|(\r\n)+|\r+|\n+|\t+/gm, ' ').replace(/\./gmi, '-').replace(/\,/gmi, '-').replace(/\s/gmi, '-').replace(/\//gmi, '-').replace(/\\/gmi, '-')
                    currentReturnedOCRData.protocolProps[propertyIndex].possition = action.possition
                }
            }

            return { ...state, ...{ returnedOCRData: currentReturnedOCRData } }

        case FileConst.SET_OCR_NEW_COUNTERPARTY:
            var currentReturnedOCRData = { ...state.returnedOCRData }

            currentReturnedOCRData.newCounterparty = action.fromNewCounterparty
            return { ...state, ...{ returnedOCRData: currentReturnedOCRData } }

        case FileConst.GET_COMPANY_BY_VATID:
            var currentReturnedOCRData = { ...state.returnedOCRData }

            if (action.method === 'select') {
                var propertyIndex = arrayObjectIndexOf(currentReturnedOCRData.props, 'sellerName', "name")
                if (propertyIndex > -1) {
                    currentReturnedOCRData.props[propertyIndex].text = action.companyName
                }
            } else {
                var propertyIndex = arrayObjectIndexOf(currentReturnedOCRData.manualProps, 'sellerName', "name")
                if (propertyIndex > -1) {
                    currentReturnedOCRData.manualProps[propertyIndex].text = action.companyName
                }
            }

            return { ...state, ...{ returnedOCRData: currentReturnedOCRData } }

        case FileConst.MARK_AREA_PROCESSING:
            var currentProcessingData = [...state.processingData]
            if (!currentProcessingData.includes(action.prop)) currentProcessingData.push(action.prop)

            var currentProcessedData = [...state.processedData]
            currentProcessedData = currentProcessedData.filter(e => e !== action.prop)

            var currentDataReadWithOcrFrame = [...state.dataReadWithOcrFrame]
            currentDataReadWithOcrFrame = currentDataReadWithOcrFrame.filter(e => e !== action.prop)

            return { ...state, ...{ processingData: currentProcessingData, processedData: currentProcessedData, dataReadWithOcrFrame: currentDataReadWithOcrFrame } }

        case FileConst.MARK_AREA_PROCESSED:
            var currentProcessingData = [...state.processingData]
            currentProcessingData = currentProcessingData.filter(e => e !== action.prop)

            var currentProcessedData = [...state.processedData]
            if (action.hasText) currentProcessedData.push(action.prop)

            var currentDataReadWithOcrFrame = [...state.dataReadWithOcrFrame]
            if (action.hasText) currentDataReadWithOcrFrame.push(action.prop)

            return { ...state, ...{ processingData: currentProcessingData, processedData: currentProcessedData, dataReadWithOcrFrame: currentDataReadWithOcrFrame } }

        case FileConst.CLEAR_DATA_READ_BY_OCR_FRAME:
            return { ...state, ...{ dataReadWithOcrFrame: [] } }

        case FileConst.PARSED_PDF_FILE:
            var filesToVerifiLoaded = [...state.filesToVerifiLoaded]
            var projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, action.projectId, "project");

            if (projectIndex > -1) {
                var fileIndex = arrayObjectIndexOf(filesToVerifiLoaded[projectIndex].files, action.name, "name")
                if (fileIndex > -1) {
                    filesToVerifiLoaded[projectIndex].files[fileIndex].imageData = action.image;
                    filesToVerifiLoaded[projectIndex].files[fileIndex].status = action.status;
                    filesToVerifiLoaded[projectIndex].files[fileIndex].ocrData = action.ocr;
                    filesToVerifiLoaded[projectIndex].files[fileIndex].defaultData = action.defaultData;
                    filesToVerifiLoaded[projectIndex].files[fileIndex].dbId = action.dbId;
                    filesToVerifiLoaded[projectIndex].files[fileIndex].ftvId = action.ftvId;
                    filesToVerifiLoaded[projectIndex].files[fileIndex].language = action.language;
                    filesToVerifiLoaded[projectIndex].files[fileIndex].docType = action.docType;
                    filesToVerifiLoaded[projectIndex].files[fileIndex].currency = action.currency;
                    filesToVerifiLoaded[projectIndex].files[fileIndex].paymentMethod = action.paymentMethod;
                    filesToVerifiLoaded[projectIndex].files[fileIndex].paymentType = action.paymentType;
                    filesToVerifiLoaded[projectIndex].files[fileIndex].fromNewCounterparty = action.fromNewCounterparty;
                    filesToVerifiLoaded[projectIndex].files[fileIndex].expenseOcr = action.expenseOcr;
                    filesToVerifiLoaded[projectIndex].files[fileIndex].expenseOwnerId = action.expenseOwnerId;
                    filesToVerifiLoaded[projectIndex].files[fileIndex].expenseOwnerName = action.expenseOwnerName;
                    filesToVerifiLoaded[projectIndex].files[fileIndex].agreementProps = action.agreementProps;
                    filesToVerifiLoaded[projectIndex].files[fileIndex].correspondenceProps = action.correspondenceProps;
                    filesToVerifiLoaded[projectIndex].files[fileIndex].corporateProps = action.corporateProps;
                    filesToVerifiLoaded[projectIndex].files[fileIndex].incomeProps = action.incomeProps;
                    filesToVerifiLoaded[projectIndex].files[fileIndex].otherProps = action.otherProps;
                    filesToVerifiLoaded[projectIndex].files[fileIndex].protocolProps = action.protocolProps;
                    filesToVerifiLoaded[projectIndex].files[fileIndex].uploadDate = action.uploadDate;
                    filesToVerifiLoaded[projectIndex].files[fileIndex].userThatUploaded = action.userThatUploaded;
                    filesToVerifiLoaded[projectIndex].files[fileIndex].uploadMethod = action.uploadMethod;
                    filesToVerifiLoaded[projectIndex].files[fileIndex].comment = action.comment || '';
                    filesToVerifiLoaded[projectIndex].files[fileIndex].tags = action.tags || '';
                    filesToVerifiLoaded[projectIndex].files[fileIndex].locked_for_devex = action.locked_for_devex
                    filesToVerifiLoaded[projectIndex].files[fileIndex].locked_for_devex_by = action.locked_for_devex_by
                    filesToVerifiLoaded[projectIndex].files[fileIndex].used_ocr_model = action.used_ocr_model
                } else {
                    filesToVerifiLoaded[projectIndex].files.push({
                        ftvId: action.ftvId,
                        dbId: action.dbId,
                        name: action.name,
                        imageData: action.image,
                        status: action.status,
                        ocrData: action.ocr,
                        language: action.language,
                        manualOcr: action.manualOcr,
                        expenseOcr: action.expenseOcr,
                        defaultData: action.defaultData,
                        docType: action.docType,
                        currency: action.currency,
                        paymentMethod: action.paymentMethod,
                        paymentType: action.paymentType,
                        fromNewCounterparty: action.fromNewCounterparty,
                        expenseOwnerId: action.expenseOwnerId,
                        expenseOwnerName: action.expenseOwnerName,
                        agreementProps: action.agreementProps,
                        correspondenceProps: action.correspondenceProps,
                        corporateProps: action.corporateProps,
                        incomeProps: action.incomeProps,
                        otherProps: action.otherProps,
                        protocolProps: action.protocolProps,
                        uploadDate: action.uploadDate,
                        userThatUploaded: action.userThatUploaded,
                        uploadMethod: action.uploadMethod,
                        comment: action.comment || '',
                        tags: action.tags || '',
                        locked_for_devex: action.locked_for_devex || '',
                        locked_for_devex_by: action.locked_for_devex_by || '',
                        used_ocr_model: action.used_ocr_model
                    })
                }

                filesToVerifiLoaded[projectIndex].reloadFiles = false

            } else {
                filesToVerifiLoaded.push({
                    project: action.projectId,
                    files: [
                        {
                            ftvId: action.ftvId,
                            dbId: action.dbId,
                            name: action.name,
                            imageData: action.image,
                            status: action.status,
                            ocrData: action.ocr,
                            language: action.language,
                            manualOcr: action.manualOcr,
                            expenseOcr: action.expenseOcr,
                            defaultData: action.defaultData,
                            docType: action.docType,
                            currency: action.currency,
                            paymentMethod: action.paymentMethod,
                            paymentType: action.paymentType,
                            fromNewCounterparty: action.fromNewCounterparty,
                            expenseOwnerId: action.expenseOwnerId,
                            expenseOwnerName: action.expenseOwnerName,
                            agreementProps: action.agreementProps,
                            correspondenceProps: action.correspondenceProps,
                            corporateProps: action.corporateProps,
                            incomeProps: action.incomeProps,
                            otherProps: action.otherProps,
                            protocolProps: action.protocolProps,
                            uploadDate: action.uploadDate,
                            userThatUploaded: action.userThatUploaded,
                            uploadMethod: action.uploadMethod,
                            comment: action.comment || '',
                            tags: action.tags || ''
                        }
                    ],
                    notAcceptedCount: 0,
                    reloadFiles: false
                })
            }
            return { ...state, ...{ filesToVerifiLoaded: filesToVerifiLoaded } }

        case FileConst.CACHE_FILE:
            var filesToVerifiLoaded = [...state.filesToVerifiLoaded]
            var projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, action.projectId, "project");

            if (projectIndex > -1) {
                var fileIndex = arrayObjectIndexOf(filesToVerifiLoaded[projectIndex].files, action.name, "name")
                if (fileIndex > -1) {
                    if (filesToVerifiLoaded[projectIndex].files[fileIndex].status !== "processing") {
                        filesToVerifiLoaded[projectIndex].files[fileIndex].imageData = action.image;
                        filesToVerifiLoaded[projectIndex].files[fileIndex].status = action.status;
                        filesToVerifiLoaded[projectIndex].files[fileIndex].projectId = action.projectId
                        filesToVerifiLoaded[projectIndex].files[fileIndex].size = action.size
                        filesToVerifiLoaded[projectIndex].files[fileIndex].docType = action.docType
                    }
                } else {
                    filesToVerifiLoaded[projectIndex].files.push({
                        name: action.name,
                        imageData: action.image,
                        status: action.status,
                        projectId: action.projectId,
                        size: action.size,
                        docType: action.docType
                    })
                }
            } else {
                filesToVerifiLoaded.push({
                    project: action.projectId,
                    files: [
                        {
                            name: action.name,
                            imageData: action.image,
                            status: action.status,
                            projectId: action.projectId,
                            size: action.size,
                            docType: action.docType
                        }
                    ],
                })
            }

            projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, action.projectId, "project")
            if (projectIndex > -1 && filesToVerifiLoaded[projectIndex].files) {
                filesToVerifiLoaded[projectIndex].files.sort(dynamicComparer(state.filesToVerifySortField, state.filesToVerifySortOrder))
            }

            return { ...state, ...{ filesToVerifiLoaded: filesToVerifiLoaded } }

        case FileConst.REMOVE_FILE_FROM_CACHE:
            var filesToVerifiLoaded = [...state.filesToVerifiLoaded]
            var projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, action.projectId, "project");

            if (projectIndex > -1) {
                filesToVerifiLoaded[projectIndex].files = filesToVerifiLoaded[projectIndex].files.filter(f => f.name !== action.name)
            }

            return { ...state, ...{ filesToVerifiLoaded: filesToVerifiLoaded } }

        case FileConst.CHANGE_FILE_STATUS:
            var filesToVerifiLoaded = [...state.filesToVerifiLoaded]
            var filesLoaded = [...state.uploadedFiles]

            var projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, action.projectId, "project");

            console.log(action.dbId)
            if (projectIndex > -1) {
                var fileIndex = arrayObjectIndexOf(filesToVerifiLoaded[projectIndex].files, action.dbId, "dbId")
                if (fileIndex > -1) {
                    var statusToDelete = filesToVerifiLoaded[projectIndex].files[fileIndex].status
                }

                filesToVerifiLoaded[projectIndex].files = filesToVerifiLoaded[projectIndex].files.filter(function (value, index, arr) {
                    return value.dbId !== action.dbId
                });
            }

            var projectIndex = arrayObjectIndexOf(filesLoaded, action.projectId, "project");

            if (projectIndex > -1) {
                var fileIndex = arrayObjectIndexOf(filesLoaded[projectIndex].files, action.dbId, 'id')
                console.log(fileIndex)
                if (fileIndex > -1) {
                    console.log(filesLoaded[projectIndex].files[fileIndex])
                    filesLoaded[projectIndex].files[fileIndex].acceptance_step = action.status === 'DONE' ? 4 : 3
                    filesLoaded[projectIndex].files[fileIndex].ocr_status = action.status
                    filesLoaded[projectIndex].files[fileIndex].path = action.filePath || filesLoaded[projectIndex].files[fileIndex].path
                    filesLoaded[projectIndex].files[fileIndex].name = action.fileName || filesLoaded[projectIndex].files[fileIndex].name
                    filesLoaded[projectIndex].files[fileIndex].tags = action.tags || filesLoaded[projectIndex].files[fileIndex].tags
                }
            }

            return { ...state, ...{ uploadedFiles: filesLoaded, filesToVerifiLoaded: filesToVerifiLoaded } }

        case FileConst.CHANGE_FILE_STATUS_TO_NOT_ACCEPTED:
            var filesToVerifiLoaded = JSON.parse(JSON.stringify(state.filesToVerifiLoaded))
            var filesLoaded = [...state.uploadedFiles]

            var projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, action.projectId, "project");
            console.log(projectIndex)
            if (projectIndex > -1) {
                var fileIndex = arrayObjectIndexOf(filesToVerifiLoaded[projectIndex].files, action.dbId, 'dbId')

                if (fileIndex > -1) {

                    var statusToDelete = filesToVerifiLoaded[projectIndex].files[fileIndex].status

                    filesToVerifiLoaded[projectIndex].files[fileIndex].status = action.fileToVerifi.status
                    filesToVerifiLoaded[projectIndex].files[fileIndex].ocrData = JSON.parse(action.fileToVerifi.ocr_data)
                    filesToVerifiLoaded[projectIndex].files[fileIndex].manualOcr = JSON.parse(action.fileToVerifi.manual_ocr)
                    filesToVerifiLoaded[projectIndex].files[fileIndex].expenseOcr = JSON.parse(action.fileToVerifi.expense_ocr)
                    filesToVerifiLoaded[projectIndex].files[fileIndex].currency = action.fileToVerifi.currency
                    filesToVerifiLoaded[projectIndex].files[fileIndex].comment = action.fileToVerifi.comment
                    filesToVerifiLoaded[projectIndex].files[fileIndex].tags = action.fileToVerifi.tags
                    filesToVerifiLoaded[projectIndex].files[fileIndex].paymentMethod = action.fileToVerifi.payment_method
                    filesToVerifiLoaded[projectIndex].files[fileIndex].paymentType = action.fileToVerifi.payment_type
                    filesToVerifiLoaded[projectIndex].files[fileIndex].language = action.fileToVerifi.language
                } else {
                    filesToVerifiLoaded[projectIndex].files.push({
                        ftvId: action.fileToVerifi.id,
                        dbId: action.fileToVerifi.file_id,
                        projectId: action.fileToVerifi.project_id,
                        name: action.fileToVerifi.name,
                        imageData: action.fileToVerifi.image_data,
                        status: action.fileToVerifi.status,
                        ocrData: JSON.parse(action.fileToVerifi.ocr_data),
                        docType: action.fileToVerifi.doc_type,
                        size: action.fileToVerifi.size,
                        language: action.fileToVerifi.language,
                        paymentMethod: action.fileToVerifi.payment_method,
                        paymentType: action.fileToVerifi.payment_type,
                        currency: action.fileToVerifi.currency,
                        comment: action.fileToVerifi.comment,
                        manualOcr: JSON.parse(action.fileToVerifi.manual_ocr),
                        expenseOcr: JSON.parse(action.fileToVerifi.expense_ocr)
                    })

                    filesToVerifiLoaded[projectIndex].notAcceptedCount = filesToVerifiLoaded[projectIndex].notAcceptedCount + 1
                }
            }

            projectIndex = arrayObjectIndexOf(filesLoaded, action.projectId, "project");
            if (projectIndex > -1) {
                fileIndex = arrayObjectIndexOf(filesLoaded[projectIndex].files, action.dbId, 'id')
                if (fileIndex > -1) {
                    filesLoaded[projectIndex].files[fileIndex] = action.file
                } else {
                    filesLoaded[projectIndex].files.push(action.file)
                }
            }

            return { ...state, ...{ uploadedFiles: filesLoaded, filesToVerifiLoaded: filesToVerifiLoaded, showRefreshOcrPopup: action.showRefreshOcrPopup, refreshOcrPopupUserName: action.refreshOcrPopupUserName } }

        case FileConst.CHANGE_FILE_STATUS_TO_ACCEPTED:
            var filesToVerifiLoaded = [...state.filesToVerifiLoaded]
            var filesLoaded = [...state.uploadedFiles]

            var projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, action.projectId, "project");
            if (projectIndex > -1) {
                filesToVerifiLoaded[projectIndex].files = filesToVerifiLoaded[projectIndex].files.filter(e => e.dbId !== action.dbId)

                filesToVerifiLoaded[projectIndex].notAcceptedCount = filesToVerifiLoaded[projectIndex].notAcceptedCount - 1
            }

            projectIndex = arrayObjectIndexOf(filesLoaded, action.projectId, "project");
            if (projectIndex > -1) {
                fileIndex = arrayObjectIndexOf(filesLoaded[projectIndex].files, action.dbId, 'id')
                if (fileIndex > -1) {
                    filesLoaded[projectIndex].files[fileIndex] = action.file
                } else {
                    filesLoaded[projectIndex].files.push(action.file)
                }
            }

            return { ...state, ...{ uploadedFiles: filesLoaded, filesToVerifiLoaded: filesToVerifiLoaded, showAcceptedOcrPopup: action.showAcceptedOcrPopup, refreshOcrPopupUserName: action.refreshOcrPopupUserName } }

        case FileConst.MARK_FILE_AS_PAID:
            var filesLoaded = [...state.uploadedFiles]

            projectIndex = arrayObjectIndexOf(filesLoaded, action.projectId, "project");
            if (projectIndex > -1) {
                fileIndex = arrayObjectIndexOf(filesLoaded[projectIndex].files, action.id, 'id')
                if (fileIndex > -1) {
                    filesLoaded[projectIndex].files[fileIndex].ocr_status = action.mark ? 'DONE' : 'INPROGRESS'
                    filesLoaded[projectIndex].files[fileIndex].acceptance_step = action.mark ? 4 : 3
                }
            }
            return { ...state, ...{ uploadedFiles: filesLoaded } }

        case FileConst.MARK_SALE_INVOICE_PAYMENT:
            var loadedInvoices = state.loadedInvoices
            var companyIndex = arrayObjectIndexOf(loadedInvoices, action.companyId, 'companyId')
            if (companyIndex > -1) {
                var monthIndex = arrayObjectIndexOf(loadedInvoices[companyIndex].invoices, action.month, 'directory')
                if (monthIndex > -1) {
                    var docIndex = arrayObjectIndexOf(loadedInvoices[companyIndex].invoices[monthIndex].docs, action.id, 'id')
                    if (docIndex > -1) {
                        loadedInvoices[companyIndex].invoices[monthIndex].docs[docIndex].payment_method = action.method
                        loadedInvoices[companyIndex].invoices[monthIndex].docs[docIndex].payment_status = action.method ? 'paid' : 'unpaid'
                    }
                }
            }
            return { ...state, ...{ loadedInvoices: loadedInvoices } }

        case FileConst.UPDATE_SALE_INVOICE_KSEF_STATUS:
            var loadedInvoices = state.loadedInvoices
            var companyIndex = arrayObjectIndexOf(loadedInvoices, action.companyId, 'companyId')
            if (companyIndex > -1) {
                var monthIndex = arrayObjectIndexOf(loadedInvoices[companyIndex].invoices, action.month, 'directory')
                if (monthIndex > -1) {
                    var docIndex = arrayObjectIndexOf(loadedInvoices[companyIndex].invoices[monthIndex].docs, action.id, 'id')
                    if (docIndex > -1) {
                        loadedInvoices[companyIndex].invoices[monthIndex].docs[docIndex].ksef_status = action.ksefStatus
                        loadedInvoices[companyIndex].invoices[monthIndex].docs[docIndex].ksef_doc_number = action.ksefNumber
                    }
                }
            }
            return { ...state, ...{ loadedInvoices: loadedInvoices } }


        case FileConst.GET_FILES_TO_VERIFI_REQUEST:
            var filesToVerifiLoaded = JSON.parse(JSON.stringify(state.filesToVerifiLoaded))

            var projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, action.projectId, "project");
            if (projectIndex === -1) {
                filesToVerifiLoaded.push({
                    project: action.projectId,
                    files: [],
                    sendRequest: true,
                    loaded: false,
                })

                return { ...state, ...{ filesToVerifiLoaded: filesToVerifiLoaded } }
            } else {
                filesToVerifiLoaded[projectIndex].loaded = false
                filesToVerifiLoaded[projectIndex].reloadFiles = false

                return { ...state, ...{ filesToVerifiLoaded: filesToVerifiLoaded } }
            }

        case FileConst.GET_FILES_TO_VERIFI:
            var filesToVerifiLoaded = [...state.filesToVerifiLoaded]

            var projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, action.projectId, "project");
            if (projectIndex > -1) {
                action.files.forEach((file) => {
                    if (arrayObjectIndexOf(filesToVerifiLoaded[projectIndex].files, file.id, "ftvId") === -1) {
                        filesToVerifiLoaded[projectIndex].files.push({
                            ftvId: file.id,
                            dbId: file.file_id,
                            projectId: file.project_id,
                            name: file.name,
                            imageData: file.image_data,
                            status: file.status,
                            docType: file.doc_type,
                            size: file.size,
                            language: file.language,
                            paymentMethod: file.payment_method,
                            paymentType: file.payment_type,
                            currency: file.currency,
                            comment: file.comment,
                            doc_description: file.doc_description,
                            tags: file.tags,
                            ocrData: JSON.parse(file.ocr_data),
                            manualOcr: JSON.parse(file.manual_ocr),
                            expenseOcr: JSON.parse(file.expense_ocr),
                            defaultData: JSON.parse(file.default_data),
                            agreementProps: file.agreement_data ? JSON.parse(file.agreement_data) : [],
                            correspondenceProps: file.correspondence_data ? JSON.parse(file.correspondence_data) : [],
                            corporateProps: file.corporate_data ? JSON.parse(file.corporate_data) : [],
                            incomeProps: file.income_data ? JSON.parse(file.income_data) : [],
                            otherProps: file.other_data ? JSON.parse(file.other_data) : [],
                            protocolProps: file.protocol_data ? JSON.parse(file.protocol_data) : [],
                            uploadMethod: file.upload_method,
                            uploadDate: file.created,
                            userThatUploaded: file.user_that_uploaded,
                            userThatVerified: file.user_that_verified,
                            userThatSetType: file.user_that_set_type,
                            expenseOwnerId: file.expense_owner,
                            expenseOwnerName: file.expense_owner_name,
                            fromNewCounterparty: file.from_new_counterparty,
                            priority: file.priority,
                            agreementId: file.agreement_id,
                            emailToSendPaymentConfirmation: file.email_to_send_payment_confirmation,
                            wl_project_code: file.wl_project_code,
                            wl_technology: file.wl_technology,
                            wl_devex_type_1: file.wl_devex_type_1,
                            wl_devex_type_2: file.wl_devex_type_2,
                            wl_transaction_description_pl: file.wl_transaction_description_pl,
                            wl_transaction_description_en: file.wl_transaction_description_en,
                            locked_for_devex: file.locked_for_devex,
                            locked_for_devex_by: file.locked_for_devex_by,
                            used_ocr_model: file.used_ocr_model,
                            costSplit: file.costSplit || [],
                            mpkSplitWineAvenue: file.mpkSplitWineAvenue || [],
                        })
                    }
                })

                filesToVerifiLoaded[projectIndex].loaded = true
                filesToVerifiLoaded[projectIndex].reloadFiles = false

            } else {
                var files = []
                action.files.forEach((file) => {
                    files.push({
                        ftvId: file.id,
                        dbId: file.file_id,
                        projectId: file.project_id,
                        name: file.name,
                        imageData: file.image_data,
                        status: file.status,
                        docType: file.doc_type,
                        size: file.size,
                        language: file.language,
                        paymentMethod: file.payment_method,
                        paymentType: file.paymeny_type,
                        currency: file.currency,
                        comment: file.comment,
                        doc_description: file.doc_description,
                        tags: file.tags,
                        ocrData: JSON.parse(file.ocr_data),
                        manualOcr: JSON.parse(file.manual_ocr),
                        expenseOcr: JSON.parse(file.expense_ocr),
                        defaultData: JSON.parse(file.default_data),
                        uploadMethod: file.upload_method,
                        uploadDate: file.created,
                        userThatUploaded: file.user_that_uploaded,
                        userThatVerified: file.user_that_verified,
                        userThatSetType: file.user_that_set_type,
                        fromNewCounterparty: file.from_new_counterparty,
                        expenseOwnerId: file.expense_owner,
                        expenseOwnerName: file.expense_owner_name,
                        agreementId: file.agreement_id,
                        emailToSendPaymentConfirmation: file.email_to_send_payment_confirmation,
                        wl_project_code: file.wl_project_code,
                        wl_technology: file.wl_technology,
                        wl_devex_type_1: file.wl_devex_type_1,
                        wl_devex_type_2: file.wl_devex_type_2,
                        wl_transaction_description_pl: file.wl_transaction_description_pl,
                        wl_transaction_description_en: file.wl_transaction_description_en,
                        locked_for_devex: file.locked_for_devex,
                        locked_for_devex_by: file.locked_for_devex_by,
                        used_ocr_model: file.used_ocr_model,
                        costSplit: file.costSplit || [],
                        mpkSplitWineAvenue: file.mpkSplitWineAvenue || [],
                    })
                })
                filesToVerifiLoaded.push({
                    project: action.projectId,
                    files: files,
                    sendRequest: true,
                    loaded: true,
                    reloadFiles: false,
                })

                var projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, action.projectId, "project");
            }

            projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, action.projectId, "project");
            filesToVerifiLoaded[projectIndex].files.sort(dynamicComparer(state.filesToVerifySortField, state.filesToVerifySortOrder))

            return { ...state, ...{ filesToVerifiLoaded: filesToVerifiLoaded } }

        case FileConst.LOAD_GENERATED_OCR:
            var filesToVerifiLoaded = [...state.filesToVerifiLoaded]
            var currentReturnedOCRData = {}
            var currentProcessingData = []
            var currentProcessedData = []

            var projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, action.project, "project");
            if (projectIndex > -1) {
                var fileIndex = arrayObjectIndexOf(filesToVerifiLoaded[projectIndex].files, action.file, "dbId")

                if (fileIndex > -1) {
                    const fileInfo = filesToVerifiLoaded[projectIndex].files[fileIndex]

                    if (fileInfo) {
                        var props = []
                        var loadedProps = []
                        var currentProp = {}
                        var manualProps = []
                        fileInfo.ocrData.forEach(property => {
                            currentProp.text = property.text
                            currentProp.possition = property.possition
                            switch (property.name) {
                                case "seller":
                                    currentProp.name = "sellerData"
                                    currentProp.title = "Seller Data"
                                    break
                                case "sellerVatId":
                                    currentProp.name = "sellerVatId"
                                    currentProp.title = "Seller VAT-ID"
                                    break
                                case "sellerName":
                                    currentProp.name = "sellerName"
                                    currentProp.title = "Seller Name"
                                    break
                                case "sellerCode":
                                    currentProp.name = "sellerCode"
                                    currentProp.title = "Seller Code"
                                    break
                                case "buyer":
                                    currentProp.name = "buyerData"
                                    currentProp.title = "Buyer Data"
                                    break
                                case "buyerVatId":
                                    currentProp.name = "buyerVatId"
                                    currentProp.title = "Buyer VAT-ID"
                                    break
                                case "buyerName":
                                    currentProp.name = "buyerName"
                                    currentProp.title = "Buyer Name"
                                    break
                                case "bank":
                                    currentProp.name = "bankData"
                                    currentProp.title = "Account Number"
                                    break
                                case "date":
                                    currentProp.name = "dueDate"
                                    currentProp.title = "Due Date"
                                    break
                                case "price":
                                    currentProp.name = "price"
                                    currentProp.title = "Price"
                                    break
                                case "currencyRate":
                                    currentProp.name = "currencyRate"
                                    currentProp.title = "Currency rate"
                                    break
                                case "brutto":
                                    currentProp.name = "brutto"
                                    currentProp.title = "Gross"
                                    break
                                case "netto":
                                    currentProp.name = "netto"
                                    currentProp.title = "Net"
                                    break
                                case "tax":
                                    currentProp.name = "tax"
                                    currentProp.title = "VAT"
                                    break
                                case "invoiceNumber":
                                    currentProp.name = "invoiceNumber"
                                    currentProp.title = "Document Number"
                                    break
                                case "issueDate":
                                    currentProp.name = "issueDate"
                                    currentProp.title = "Issue Date"
                                    break
                                case "saleDate":
                                    currentProp.name = "saleDate"
                                    currentProp.title = "Sale Date"
                                    break
                                case "nip": {
                                    currentProp.name = "nip"
                                    currentProp.title = "Seller Vat ID"
                                    break
                                }
                                case "address": {
                                    currentProp.name = "address"
                                    currentProp.title = "Seller Address"
                                    break
                                }
                                case "swift": {
                                    currentProp.name = "swift"
                                    currentProp.title = "Seller\'s Bank SWIFT/BIC"
                                    break
                                }
                                case "countryCode": {
                                    currentProp.name = "countryCode"
                                    currentProp.title = "Country Code"
                                    if (!currentProp.text) currentProp.text = fileInfo.currency === 'PLN' ? 'PL' : ''
                                    break
                                }
                                default:
                                    break
                            }
                            if (property.name) {
                                props.push(currentProp)
                                if (property.text) loadedProps.push(currentProp.name)
                            }
                            currentProp = {}

                            if (property.text) {
                                if (property.name === 'date') {
                                    if (!currentProcessedData.includes('dueDate')) currentProcessedData.push('dueDate')
                                } else if (property.name === 'bank') {
                                    if (!currentProcessedData.includes('bankData')) currentProcessedData.push('bankData')
                                } else if (!currentProcessedData.includes(property.name)) currentProcessedData.push(property.name)
                            }
                        })
                        manualProps.push({
                            name: 'sellerName',
                            title: 'Seller Name',
                            text: fileInfo.manualOcr ? fileInfo.manualOcr.sellerName || fileInfo.manualOcr.selerData : fileInfo.defaultData.sellerName
                        })
                        manualProps.push({
                            name: 'sellerVatId',
                            title: 'Seller Code',
                            text: fileInfo.manualOcr ? fileInfo.manualOcr.sellerCode || fileInfo.manualOcr.sellerVatId : fileInfo.defaultData.sellerCode
                        })
                        manualProps.push({
                            name: 'sellerCode',
                            title: 'Seller Code',
                            text: fileInfo.manualOcr ? fileInfo.manualOcr.sellerCode || fileInfo.manualOcr.sellerVatId : fileInfo.defaultData.sellerCode
                        })
                        manualProps.push({
                            name: 'address',
                            title: 'Seller Address',
                            text: fileInfo.manualOcr ? fileInfo.manualOcr.address : ''
                        })
                        manualProps.push({
                            name: 'countryCode',
                            title: 'Country Code',
                            text: fileInfo.manualOcr ? fileInfo.manualOcr.countryCode : fileInfo.currency === 'PLN' ? 'PL' : ''
                        })
                        manualProps.push({
                            name: 'buyerName',
                            title: 'Buyer Name',
                            text: fileInfo.manualOcr ? fileInfo.manualOcr.buyerName || fileInfo.manualOcr.buyerData : fileInfo.defaultData.buyerName
                        })
                        manualProps.push({
                            name: 'buyerVatId',
                            title: 'Buyer VAT-ID',
                            text: fileInfo.manualOcr ? fileInfo.manualOcr.buyerVatId : fileInfo.defaultData.buyerVatId
                        })
                        manualProps.push({
                            name: 'invoiceNumber',
                            title: 'Document Number',
                            text: fileInfo.manualOcr ? fileInfo.manualOcr.invoiceNumber : ''
                        })
                        manualProps.push({
                            name: 'issueDate',
                            title: 'Issue Date',
                            text: fileInfo.manualOcr ? fileInfo.manualOcr.issueDate : ''
                        })
                        manualProps.push({
                            name: 'saleDate',
                            title: 'Sale Date',
                            text: fileInfo.manualOcr ? fileInfo.manualOcr.saleDate || fileInfo.manualOcr.issueDate : ''
                        })
                        manualProps.push({
                            name: 'dueDate',
                            title: 'Due Date',
                            text: fileInfo.manualOcr ? fileInfo.manualOcr.dueDate : ''
                        })
                        manualProps.push({
                            name: 'netto',
                            title: 'Net',
                            text: fileInfo.manualOcr ? fileInfo.manualOcr.netto : ''
                        })
                        manualProps.push({
                            name: 'brutto',
                            title: 'Gross',
                            text: fileInfo.manualOcr ? fileInfo.manualOcr.brutto : ''
                        })
                        manualProps.push({
                            name: 'currencyRate',
                            title: 'Currency rate',
                            text: fileInfo.manualOcr ? fileInfo.manualOcr.currencyRate : ''
                        })
                        manualProps.push({
                            name: 'price',
                            title: 'Amount to pay',
                            text: fileInfo.manualOcr ? fileInfo.manualOcr.price : ''
                        })
                        manualProps.push({
                            name: 'tax',
                            title: 'VAT',
                            text: fileInfo.manualOcr ? fileInfo.manualOcr.tax : ''
                        })
                        manualProps.push({
                            name: 'bankData',
                            title: 'Account Number',
                            text: fileInfo.manualOcr ? fileInfo.manualOcr.accountNumber || fileInfo.manualOcr.bankData : ''
                        })
                        manualProps.push({
                            name: 'swift',
                            title: "Seller\'s Bank SWIFT/BIC",
                            text: fileInfo.manualOcr ? fileInfo.manualOcr.address : ''
                        })
                        currentReturnedOCRData = {
                            file: action.file,
                            props: sortInvoiceProperties(props),
                            manualProps: manualProps,
                            agreementProps: fileInfo.agreementProps,
                            language: fileInfo.language,
                            newCounterparty: fileInfo.fromNewCounterparty,
                            agreementId: fileInfo.agreementId,
                            correspondenceProps: fileInfo.correspondenceProps,
                            corporateProps: fileInfo.corporateProps,
                            incomeProps: fileInfo.incomeProps,
                            otherProps: fileInfo.otherProps,
                            protocolProps: fileInfo.protocolProps,
                            defaultData: fileInfo.defaultData
                        }
                    }
                }
            }

            return { ...state, ...{ returnedOCRData: currentReturnedOCRData, processingData: currentProcessingData, processedData: currentProcessedData, dataReadWithOcrFrame: [], filesToVerifiLoaded: filesToVerifiLoaded } }

        case FileConst.SORT_FIlES_TO_VERIFI:
            var { filesToVerifySortField, filesToVerifySortOrder } = state
            var filesToVerifiLoaded = [...state.filesToVerifiLoaded]
            if (filesToVerifySortField === action.name) {
                filesToVerifySortOrder === 'asc' ? filesToVerifySortOrder = 'desc' : filesToVerifySortOrder = 'asc'
            } else {
                filesToVerifySortField = action.name
                filesToVerifySortOrder = 'asc'
            }
            filesToVerifiLoaded.forEach(projectGroup => {
                projectGroup.files.sort(dynamicComparer(filesToVerifySortField, filesToVerifySortOrder))
            })

            return { ...state, ...{ filesToVerifiLoaded: filesToVerifiLoaded, filesToVerifySortField: filesToVerifySortField, filesToVerifySortOrder: filesToVerifySortOrder } }

        case FileConst.SEARCH_FILES_TO_VERIFY:
            var filesToVerifiLoaded = [...state.filesToVerifiLoaded]
            var projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, action.projectId, "project");
            if (projectIndex > -1) {
                filesToVerifiLoaded[projectIndex].searchField = action.value
            }
            return { ...state, ...{ filesToVerifiLoaded: filesToVerifiLoaded } }

        case FileConst.FILTER_FILES_TO_VERIFY:
            var filesToVerifiLoaded = [...state.filesToVerifiLoaded]
            var projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, action.projectId, "project");
            if (projectIndex > -1) {
                filesToVerifiLoaded[projectIndex].filter = action.value
            }
            return { ...state, ...{ filesToVerifiLoaded: filesToVerifiLoaded } }

        case FileConst.LOAD_NEW_FILE_TO_VERIFY_DATA:
            var filesToVerifiLoaded = [...state.filesToVerifiLoaded]
            var projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, action.projectId, "project");
            console.log(projectIndex)
            if (projectIndex > -1) {
                var fileIndex = arrayObjectIndexOf(filesToVerifiLoaded[projectIndex].files, action.id, "ftvId");
                console.log(fileIndex)
                if (fileIndex > -1) {
                    filesToVerifiLoaded[projectIndex].files[fileIndex] = {
                        ...filesToVerifiLoaded[projectIndex].files[fileIndex],
                        ...action.newData
                    }

                    console.log(filesToVerifiLoaded[projectIndex].files[fileIndex])

                    return { ...state, ...{ filesToVerifiLoaded: filesToVerifiLoaded } }
                }
            }

            return state

        case FileConst.GET_OCR_MODEL:
            return { ...state, ...{ ocrModel: action.model } }

        case FileConst.GET_FILE_BODY:
            return { ...state, ...{ fileBody: { name: action.fileName, body: action.fileBody } } }

        case FileConst.TOGGLE_PREVIEW:
            return { ...state, ...{ pdfPreviewOpen: action.status } }

        case FileConst.TOGGLE_PREVIEW_FOR_UNPAID_DOCS:
            return { ...state, ...{ unpaidDocsPreviewIsOpen: action.show } }

        case FileConst.TOGGLE_PREVIEW_FOR_FULL_TEXT_SEARCH:
            return { ...state, ...{ fullTextSearchPreviewIsOpen: action.show } }

        case FileConst.TOGGLE_PREVIEW_FOR_ACTIONS:
            return { ...state, ...{ actionsPreviewIsOpen: action.show } }

        case FileConst.GET_PREVIEV_DATA:
            var fileIndex = -1;
            var currentFiles = [...state.uploadedFiles]
            var projectIndex = arrayObjectIndexOf(currentFiles, action.projectId, "project");
            if (projectIndex > -1) {
                fileIndex = arrayObjectIndexOf(currentFiles[projectIndex].files, action.fileId, "id")
            }

            return {
                ...state,
                ...{
                    pdfPreviewData: {
                        projectId: action.projectId,
                        fileIndex: fileIndex,
                        id: action.fileId,
                        name: action.fileName,
                        status: action.fileStatus,
                        docType: action.docType,
                        linkedFiles: action.linkedFiles || [],
                        costSplit: action.costSplit || [],
                        mpkSplitWineAvenue: action.mpkSplitWineAvenue || [],
                        ...mapDocDataForPreview(action.docData, action.docType),
                    },
                    pdfPreviewOpen: true,
                    previewClickFromChatNotification: action.previewClickFromChatNotification,
                    pdfPreviewDataBody: action.fileBody,
                    counterpartyLinkedDocs: {
                        fileId: action.fileId,
                        loading: true
                    }
                }
            }

        case FileConst.UPDATE_PREVIEV_FILE:
            return {
                ...state, ...{
                    pdfPreviewDataBody: action.data
                }
            }

        case FileConst.WAIT_FOR_LINKED_DOCS:
            return {
                ...state, ...{
                    counterpartyLinkedDocs: {
                        fileId: action.fileId,
                        loading: true
                    }
                }
            }

        case FileConst.GET_LINKED_DOCS:
            var currentLinkedDocs = { ...state.counterpartyLinkedDocs }

            if (currentLinkedDocs.fileId === action.fileId && action.counterpartyLinkedDocs.costs) {
                return {
                    ...state, ...{
                        counterpartyLinkedDocs: {
                            fileId: action.fileId,
                            loading: false,
                            ...action.counterpartyLinkedDocs,
                            costsExtended: false,
                            incomesExtended: false,
                            agreementsExtended: false,
                            correspondenceExtended: false,
                            corporateExtended: false,
                            otherDocsExtended: false,
                            attachmentsExtended: false,
                        }
                    }
                }
            } else {
                return {
                    ...state, ...{
                        counterpartyLinkedDocs: {
                            fileId: action.fileId,
                            loading: false,
                            costs: [],
                            incomes: [],
                            agreements: [],
                            correspondence: [],
                            // corporate: [],
                            otherDocs: [],
                            attachedFiles: [],
                            costsExtended: false,
                            incomesExtended: false,
                            agreementsExtended: false,
                            correspondenceExtended: false,
                            corporateExtended: false,
                            otherDocsExtended: false,
                            attachmentsExtended: false,
                        }
                    }
                }
            }

        case FileConst.TOGGLE_LINKED_DOCS:
            var currentLinkedDocs = { ...state.counterpartyLinkedDocs }

            if (action.docType === 'COSTS') {
                currentLinkedDocs.costsExtended = !currentLinkedDocs.costsExtended
            } else if (action.docType === 'EXPENSES') {
                currentLinkedDocs.expensesExtended = !currentLinkedDocs.expensesExtended
            } else if (action.docType === 'INCOMES') {
                currentLinkedDocs.incomesExtended = !currentLinkedDocs.incomesExtended
            } else if (action.docType === 'AGREEMENTS') {
                currentLinkedDocs.agreementsExtended = !currentLinkedDocs.agreementsExtended
            } else if (action.docType === 'CORRESPONCENCE') {
                currentLinkedDocs.correspondenceExtended = !currentLinkedDocs.correspondenceExtended
            } else if (action.docType === 'OTHER') {
                currentLinkedDocs.otherDocsExtended = !currentLinkedDocs.otherDocsExtended
            } else if (action.docType === 'ATTACHMENTS') {
                currentLinkedDocs.attachmentsExtended = !currentLinkedDocs.attachmentsExtended
            }

            return {
                ...state, ...{
                    counterpartyLinkedDocs: currentLinkedDocs
                }
            }

        case FileConst.ADD_NEW_ATTACHMENT:
            var currentLinkedDocs = { ...state.counterpartyLinkedDocs }
            var attachmentIndex = arrayObjectIndexOf(currentLinkedDocs.attachedFiles, action.attachmentInstance.name, "name")
            if (attachmentIndex > -1) {
                currentLinkedDocs.attachedFiles[attachmentIndex] = action.attachmentInstance
            } else {
                currentLinkedDocs.attachedFiles.push(action.attachmentInstance)
            }
            return {
                ...state, ...{
                    counterpartyLinkedDocs: currentLinkedDocs
                }
            }
        case FileConst.REMOVE_ATTACHMENT:
            var currentLinkedDocs = { ...state.counterpartyLinkedDocs }
            if (action.attachmentId > -1) {
                currentLinkedDocs.attachedFiles = currentLinkedDocs.attachedFiles.filter(a => a.id !== action.attachmentId)
            } else {
                currentLinkedDocs.attachedFiles = currentLinkedDocs.attachedFiles.filter(a => a.name !== action.attachmentInstance.name)
            }
            return {
                ...state, ...{
                    counterpartyLinkedDocs: currentLinkedDocs
                }
            }

        case FileConst.OPEN_SIDE_BY_SIDE_PREVIEW:
            return {
                ...state, ...{
                    showSideBySidePreview: {
                        show: true,
                        fileName: action.fileName,
                        fileBody: action.fileBody,
                        fileId: action.fileId || null
                    }
                }
            }

        case FileConst.CLOSE_SIDE_BY_SIDE_PREVIEW:
            return {
                ...state, ...{
                    showSideBySidePreview: {
                        show: false
                    }
                }
            }

        case FileConst.UPDATE_PREVIEV_DATA:
            var { pdfPreviewData, uploadedFiles, files } = state

            if (action.file) {
                var projectIndex = arrayObjectIndexOf(uploadedFiles, parseInt(action.projectId), 'project')
                var fileIndex = -1
                if (projectIndex > -1) {
                    fileIndex = arrayObjectIndexOf(uploadedFiles[projectIndex].files, action.file.id, 'id')
                    if (fileIndex > -1) {
                        uploadedFiles[projectIndex].files[fileIndex] = action.file
                    }
                }

                fileIndex = arrayObjectIndexOf(files, action.file.id, 'id')

                if (fileIndex > -1) {
                    files[fileIndex] = action.file
                }
            }

            return {
                ...state, ...{
                    uploadedFiles: uploadedFiles,
                    files: files,
                }
            }

        case FileConst.MAKE_DOC_BACKUP:
            return {
                ...state,
                ...{
                    backupPdfPreviewData: JSON.parse(JSON.stringify(state.pdfPreviewData))
                }
            }

        case FileConst.LOAD_DOC_BUCKUP:
            return {
                ...state,
                ...{
                    pdfPreviewData:
                    {
                        ...state.pdfPreviewData,
                        ...state.backupPdfPreviewData
                    },
                    backupPdfPreviewData: null
                }
            }

        case FileConst.EDIT_DOC_INPUT:
            if (action.docType !== 'EXPENSES') {
                var prevPdfData = { ...state.pdfPreviewData }
                if (action.field === 'waproData') {
                    prevPdfData.waproData = action.value
                } else if (action.field.startsWith('symfoniaData.')) {
                    var fieldName = action.field.replace('symfoniaData.', '')
                    prevPdfData.symfoniaData[fieldName] = action.value
                } else {
                    prevPdfData[action.field] = action.value
                }

                return { ...state, ...{ pdfPreviewData: prevPdfData } }
            } else {
                var prevPdfData = { ...state.pdfPreviewData }
                if (!['mpkSplitWineAvenue'].includes(action.field)) {
                    prevPdfData.docs[action.docId][action.field] = action.value
                } else {
                    prevPdfData[action.field] = action.value
                }
                return { ...state, ...{ pdfPreviewData: prevPdfData } }
            }

        case FileConst.EDIT_PDF_BACKUP:
            var backupPdfPreviewData = { ...state.backupPdfPreviewData }
            backupPdfPreviewData[action.field] = action.value

            return { ...state, ...{ backupPdfPreviewData: backupPdfPreviewData } }

        case FileConst.RESTORE_DOCUMENT:
            var uploadedFiles = [...state.uploadedFiles]

            var projectIndex = arrayObjectIndexOf(uploadedFiles, action.projectId, "project");
            var fileIndex = -1
            if (projectIndex > -1) {
                fileIndex = arrayObjectIndexOf(uploadedFiles[projectIndex].files, action.id, "id")
                if (fileIndex > -1) {
                    uploadedFiles[projectIndex].files[fileIndex].deleted = false
                    uploadedFiles[projectIndex].files[fileIndex].deleted_date = null
                    uploadedFiles[projectIndex].files[fileIndex].path = action.file.path
                }
            }

            var { pdfPreviewData } = state

            if (pdfPreviewData && pdfPreviewData.id && pdfPreviewData.id === action.id) {
                pdfPreviewData.deleted = false
                pdfPreviewData.deletedDate = null
                pdfPreviewData.deletedBy = ''
            }

            return { ...state, ...{ uploadedFiles: uploadedFiles, pdfPreviewData: pdfPreviewData } }

        case FileConst.DELETE_FILE:
            if (state.uploadedFiles && Array.isArray(state.uploadedFiles) && state.pdfPreviewData) {
                var uploadedFiles = state.uploadedFiles ? [...state.uploadedFiles] : []
                var projectIndex = arrayObjectIndexOf(uploadedFiles, action.projectId, "project");
                var fileIndex = -1
                if (projectIndex > -1) {
                    fileIndex = arrayObjectIndexOf(uploadedFiles[projectIndex].files, action.id, "id")
                    if (fileIndex > -1) {
                        uploadedFiles[projectIndex].files[fileIndex].deleted = true
                        uploadedFiles[projectIndex].files[fileIndex].deleted_date = action.file.deleted_date
                        uploadedFiles[projectIndex].files[fileIndex].path = action.file.path
                    }
                }

                var pdfPreviewData = state.pdfPreviewData || {}
                if (pdfPreviewData && pdfPreviewData.id && pdfPreviewData.id === action.id) {
                    pdfPreviewData.deleted = true
                    pdfPreviewData.deletedDate = action.file.deleted_date
                    pdfPreviewData.deletedBy = action.deletedBy
                }

                return { ...state, ...{ uploadedFiles: uploadedFiles, pdfPreviewData: pdfPreviewData } }
            } else {
                return state
            }

        case FileConst.DELETE_FILE_PERMANENT:
            var uploadedFiles = [...state.uploadedFiles]

            var projectIndex = arrayObjectIndexOf(uploadedFiles, action.projectId, "project");
            if (projectIndex > -1) {
                uploadedFiles[projectIndex].files = uploadedFiles[projectIndex].files?.filter(e => e.id !== action.id)
            }
            return { ...state, ...{ files: state.files?.filter(e => e.id !== action.id), pdfPreviewOpen: false, pdfPreviewData: {}, uploadedFiles: uploadedFiles } }

        case FileConst.DELETE_UNVERIFIED_FILE:
            var filesToVerifiLoaded = []
            if (Array.isArray(state.filesToVerifiLoaded)) filesToVerifiLoaded = [...state.filesToVerifiLoaded]
            var projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, action.projectId, "project");

            if (projectIndex > -1) {
                filesToVerifiLoaded[projectIndex].files = filesToVerifiLoaded[projectIndex].files?.filter(e => e.dbId !== action.id) || []
            }

            return { ...state, ...{ filesToVerifiLoaded: filesToVerifiLoaded } }

        case FileConst.INVOICE_EXISTS:
            if (action.similar) {
                return { ...state, ...{ similarInvoices: { files: action.files, linkedFiles: action.linkedFiles } } }
            } else {
                return { ...state, ...{ existingInvoice: { projectId: action.project, projectCode: action.projectCode, file: action.file, id: action.id, linkedFiles: action.linkedFiles } } }
            }

        case FileConst.CLOSE_EXISTING_INVOICE_MODAL:
            return { ...state, ...{ existingInvoice: null, similarInvoices: { files: [], linkedFiles: {} } } }

        case FileConst.LOADING_SCREEN:
            return { ...state, ...{ loadingScreen: action.loadingScreen } }

        case FileConst.CHANGE_FILE_ACCEPTNCE_STEP:
            var uploadedFiles = [...state.uploadedFiles]

            var projectIndex = arrayObjectIndexOf(uploadedFiles, action.projectId, "project");

            if (projectIndex > -1) {
                var fileIndex = arrayObjectIndexOf(uploadedFiles[projectIndex].files, action.fileId, "id");
                if (fileIndex > - 1) {
                    uploadedFiles[projectIndex].files[fileIndex].acceptance_step = action.step
                    if (action.step === 2) {
                        uploadedFiles[projectIndex].files[fileIndex].ocr_status = "IN_PROGRESS"
                    }

                    return { ...state, ...{ uploadedFiles: uploadedFiles } }
                } else {
                    return state
                }
            } else {
                return state
            }

        case FileConst.CHANGE_FILE_TO_VERIFI_ACCEPTNCE_STEP:
            var filesToVerifiLoaded = [...state.filesToVerifiLoaded]

            var projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, action.projectId, "project");
            if (projectIndex > -1) {
                var fileIndex = arrayObjectIndexOf(filesToVerifiLoaded[projectIndex].files, action.ftvId, "ftvId");
                if (fileIndex > - 1) {
                    filesToVerifiLoaded[projectIndex].files[fileIndex].ocrData = action.ocrData
                    filesToVerifiLoaded[projectIndex].files[fileIndex].manualOcr = action.manualOcr || filesToVerifiLoaded[projectIndex].files[fileIndex].manualOcr
                    filesToVerifiLoaded[projectIndex].files[fileIndex].expenseOcr = action.expensesOcr || filesToVerifiLoaded[projectIndex].files[fileIndex].expenseOcr
                    filesToVerifiLoaded[projectIndex].files[fileIndex].expenseOcr = action.expensesOcr || filesToVerifiLoaded[projectIndex].files[fileIndex].expenseOcr
                    filesToVerifiLoaded[projectIndex].files[fileIndex].expenseOcr = action.expensesOcr || filesToVerifiLoaded[projectIndex].files[fileIndex].expenseOcr
                    filesToVerifiLoaded[projectIndex].files[fileIndex].expenseOcr = action.expensesOcr || filesToVerifiLoaded[projectIndex].files[fileIndex].expenseOcr
                    filesToVerifiLoaded[projectIndex].files[fileIndex].expenseOcr = action.expensesOcr || filesToVerifiLoaded[projectIndex].files[fileIndex].expenseOcr
                    filesToVerifiLoaded[projectIndex].files[fileIndex].comment = action.comment
                    filesToVerifiLoaded[projectIndex].files[fileIndex].tags = action.tags
                    filesToVerifiLoaded[projectIndex].files[fileIndex].currency = action.currency
                    filesToVerifiLoaded[projectIndex].files[fileIndex].paymentMethod = action.paymentMethod
                    filesToVerifiLoaded[projectIndex].files[fileIndex].paymentType = action.paymentType
                    filesToVerifiLoaded[projectIndex].files[fileIndex].priority = action.priority
                    if (action.step === 2) {
                        filesToVerifiLoaded[projectIndex].files[fileIndex].status = "IN_PROGRESS"
                    }

                    return { ...state, ...{ filesToVerifiLoaded: filesToVerifiLoaded } }
                } else {
                    return state
                }
            } else {
                return state
            }

        case FileConst.SET_EXPENSE_OWNER:
            var filesToVerifiLoaded = [...state.filesToVerifiLoaded]
            var projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, action.projectId, "project");
            if (projectIndex > -1) {
                var fileIndex = arrayObjectIndexOf(filesToVerifiLoaded[projectIndex].files, action.fileId, "dbId");
                if (fileIndex > -1) {
                    filesToVerifiLoaded[projectIndex].files[fileIndex].expenseOwnerId = action.ownerId
                    filesToVerifiLoaded[projectIndex].files[fileIndex].expenseOwnerName = action.ownerName
                    return { ...state, ...{ filesToVerifiLoaded: filesToVerifiLoaded } }
                } else {
                    return state
                }
            } else {
                return state
            }

        case FileConst.SET_DOC_TYPE:
            var filesToVerifiLoaded = [...state.filesToVerifiLoaded]
            var projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, action.projectId, "project");
            if (projectIndex > -1) {
                var fileIndex = arrayObjectIndexOf(filesToVerifiLoaded[projectIndex].files, action.fileId, "dbId");
                if (fileIndex > -1) {
                    filesToVerifiLoaded[projectIndex].files[fileIndex].docType = action.docType
                    filesToVerifiLoaded[projectIndex].files[fileIndex].userThatSetType = action.user
                    return { ...state, ...{ filesToVerifiLoaded: filesToVerifiLoaded } }
                } else {
                    return state
                }
            } else {
                return state
            }


        case FileConst.TOGGLE_PROJECT_FILES:
            var uploadedFiles = [...state.uploadedFiles]

            var projectIndex = arrayObjectIndexOf(uploadedFiles, action.projectId, "project");

            if (projectIndex > -1) {
                if (action.extend !== undefined) {
                    uploadedFiles[projectIndex].extended = action.extend
                } else {
                    uploadedFiles[projectIndex].extended = !uploadedFiles[projectIndex].extended
                }
                return { ...state, ...{ uploadedFiles: uploadedFiles } }
            } else {
                uploadedFiles.push({
                    project: action.projectId,
                    files: [],
                    sortField: "path",
                    sortOrder: "desc",
                    currentDir: '',
                    sendRequest: true,
                    loaded: false,
                    extended: action.extend !== undefined ? action.extend : false,
                })
                return { ...state, ...{ uploadedFiles: uploadedFiles } }
            }

        case FileConst.HIDE_ALL_PROJECT_FILES:
            var uploadedFiles = [...state.uploadedFiles]

            uploadedFiles.forEach(p => {
                p.extended = false
            })
            return { ...state, ...{ uploadedFiles: uploadedFiles } }

        case FileConst.SHOW_REFRESH_OCR_POPUP_WITH_ERROR:
            return { ...state, ...{ showRefreshOcrPopup: true, refreshOcrPopupUserName: action.refreshOcrPopupUserName, showRefreshOcrPopupError: true } }

        case FileConst.HIDE_REFRESH_OCR_POPUP:
            return { ...state, ...{ showRefreshOcrPopup: false, refreshOcrPopupUserName: '', showRefreshOcrPopupError: false } }

        case FileConst.SHOW_ACCEPTED_OCR_POPUP_WITH_ERROR:
            return { ...state, ...{ showAcceptedOcrPopup: true, refreshOcrPopupUserName: action.refreshOcrPopupUserName, showAcceptedOcrPopupError: true } }

        case FileConst.HIDE_ACCEPTED_OCR_POPUP:
            return { ...state, ...{ showAcceptedOcrPopup: false, refreshOcrPopupUserName: '', showAcceptedOcrPopupError: false } }

        case FileConst.OCR_SCREEN_NOTIFICATION_ABOUT_DELETED_FILE:
            return { ...state, ...{ showDeleteOcrPopup: action.show, deleteOcrPopupUserName: action.user } }

        case FileConst.OCR_SCREEN_NOTIFICATION_ABOUT_MOVED_FILE:
            return { ...state, ...{ showMovedOcrPopup: action.show, moveOcrPopupUserName: action.user } }

        case FileConst.LOGOUT:
            return {
                files: [],
                uploadedFiles: [],
                filesToVerifiLoaded: [],
                pdfPreviewData: {
                    body: null,
                    name: null,
                },
                pdfPreviewOpen: false,
                loadingScreen: false,
                returnedOCRData: {},
                processingData: [],
                processedData: [],
                existingInvoice: null,
                similarInvoices: { files: [], linkedFiles: {} },
            }

        case FileConst.DELETE_PROJECT:
            var files = [...state.files]
            var filesToVerifiLoaded = [...state.filesToVerifiLoaded]
            var { pdfPreviewData, pdfPreviewDataBody, pdfPreviewOpen, backupPdfPreviewData } = state
            var uploadedFiles = [...state.uploadedFiles]

            files = files.filter(f => f.project_id !== action.projectId)
            filesToVerifiLoaded = filesToVerifiLoaded.filter(e => e.project !== action.project)
            uploadedFiles = uploadedFiles.filter(e => e.project !== action.project)

            if (pdfPreviewData && pdfPreviewData.projectId === action.projectId) {
                pdfPreviewData = {
                    body: null,
                    name: null,
                    saveChangesError: false,
                }
                pdfPreviewDataBody = null
                pdfPreviewOpen = false
                backupPdfPreviewData = null
            }

            return { ...state, ...{ files: files, filesToVerifiLoaded: filesToVerifiLoaded, uploadedFiles: uploadedFiles, pdfPreviewData: pdfPreviewData, pdfPreviewDataBody: pdfPreviewDataBody, pdfPreviewOpen: pdfPreviewOpen, backupPdfPreviewData: backupPdfPreviewData } }

        case FileConst.LOAD_NEW_FILE_NAMES:
            var uploadedFiles = [...state.uploadedFiles]
            var newFiles = action.files
            var projectsCount = uploadedFiles.length

            for (var i = 0; i < projectsCount; i++) {
                if (newFiles.length === 0) {
                    break
                }
                var filesCount = uploadedFiles[i].files.length
                for (var j = 0; j < filesCount; j++) {
                    if (newFiles.length === 0) {
                        break
                    }

                    var newFileIndex = arrayObjectIndexOf(newFiles, uploadedFiles[i].files[j].id, 'id')
                    console.log(uploadedFiles[i].files[j].path)
                    console.log(newFileIndex)
                    if (newFileIndex > -1) {
                        uploadedFiles[i].files[j].name = newFiles[newFileIndex].newName
                        uploadedFiles[i].files[j].path = newFiles[newFileIndex].newPath

                        newFiles.splice(newFileIndex, 1);
                    }
                }
            }
            return { ...state, ...{ uploadedFiles: uploadedFiles } }

        case FileConst.SET_FILE_TO_VERIFI_HIGH_PRIORITY:
            const filesToVerifi = [...state.filesToVerifiLoaded];
            const projectWithFileIndex = filesToVerifi.findIndex(project => project.project === action.projectId);

            if (projectWithFileIndex > -1) {
                filesToVerifi[projectWithFileIndex].files = filesToVerifi[projectWithFileIndex].files.map(el => {
                    if (el.ftvId === action.fileId) {
                        return { ...el, priority: 'high' };
                    }
                    return el;
                })

            }

            return {
                ...state,
                filesToVerifiLoaded: filesToVerifi
            }

        case FileConst.GET_COMPANY_GENERATED_INVOICES:
            var loadedInvoices = state.loadedInvoices ? [...state.loadedInvoices] : []
            var companyIndex = arrayObjectIndexOf(loadedInvoices, action.companyId, 'companyId')

            if (companyIndex > -1) {
                var ids = new Set(loadedInvoices[companyIndex].invoices.map(d => d.issueMonth));
                loadedInvoices[companyIndex].invoices = [...loadedInvoices[companyIndex].invoices, ...action.invoices.filter(d => !ids.has(d.issueMonth))]
            } else {
                loadedInvoices.push({
                    companyId: action.companyId,
                    invoices: action.invoices
                })
            }

            return {
                ...state,
                ...{ loadedInvoices: loadedInvoices }
            }

        case FileConst.GENERATED_NEW_INVOICE:
            var loadedInvoices = state.loadedInvoices ? [...state.loadedInvoices] : []
            var companyIndex = arrayObjectIndexOf(loadedInvoices, action.companyId, 'companyId')
            var issueMonth = action.newInvoice.filePath.match(/[0-9]{4}-[0-9]{2}/)[0]
            if (companyIndex > -1) {
                var issueMonthIndex = arrayObjectIndexOf(loadedInvoices[companyIndex].invoices, issueMonth, 'directory')
                if (issueMonthIndex > -1) {
                    var invoiceIndex = arrayObjectIndexOf(loadedInvoices[companyIndex].invoices[issueMonthIndex].docs, action.invoiceId, 'id')
                    if (invoiceIndex > -1) {
                        var prevNetValuePln = parseFloat(loadedInvoices[companyIndex].invoices[issueMonthIndex].docs[invoiceIndex].total_net_value),
                            prevVatValuePln = parseFloat(loadedInvoices[companyIndex].invoices[issueMonthIndex].docs[invoiceIndex].total_vat_value),
                            prevGrossValuePln = parseFloat(loadedInvoices[companyIndex].invoices[issueMonthIndex].docs[invoiceIndex].total_gross_value),
                            currencyRate = loadedInvoices[companyIndex].invoices[issueMonthIndex].docs[invoiceIndex].currency_rate
                        if (loadedInvoices[companyIndex].invoices[issueMonthIndex].docs[invoiceIndex].currency !== 'PLN') {
                            if (currencyRate) {
                                prevNetValuePln *= parseFloat(currencyRate)
                                prevVatValuePln *= parseFloat(currencyRate)
                                prevGrossValuePln *= parseFloat(currencyRate)
                            }
                        }
                        loadedInvoices[companyIndex].invoices[issueMonthIndex].netSum -= prevNetValuePln
                        loadedInvoices[companyIndex].invoices[issueMonthIndex].vatSum -= prevVatValuePln
                        loadedInvoices[companyIndex].invoices[issueMonthIndex].grossSum -= prevGrossValuePln

                        var netValuePln = parseFloat(action.newInvoice.total_net_value),
                            vatValuePln = parseFloat(action.newInvoice.total_vat_value),
                            grossValuePln = parseFloat(action.newInvoice.total_gross_value)
                        if (action.newInvoice.currency !== 'PLN') {
                            currencyRate = action.newInvoice.currency_rate
                            if (currencyRate) {
                                netValuePln *= parseFloat(currencyRate)
                                vatValuePln *= parseFloat(currencyRate)
                                grossValuePln *= parseFloat(currencyRate)
                            }
                        }
                        loadedInvoices[companyIndex].invoices[issueMonthIndex].netSum += netValuePln
                        loadedInvoices[companyIndex].invoices[issueMonthIndex].vatSum += vatValuePln
                        loadedInvoices[companyIndex].invoices[issueMonthIndex].grossSum += grossValuePln
                        loadedInvoices[companyIndex].invoices[issueMonthIndex].docs[invoiceIndex] = action.newInvoice
                    }
                    else {
                        var netValuePln = parseFloat(action.newInvoice.total_net_value),
                            vatValuePln = parseFloat(action.newInvoice.total_vat_value),
                            grossValuePln = parseFloat(action.newInvoice.total_gross_value)
                        if (action.newInvoice.currency !== 'PLN') {
                            if (action.newInvoice.currency_rate) {
                                netValuePln *= parseFloat(action.newInvoice.currency_rate)
                                vatValuePln *= parseFloat(action.newInvoice.currency_rate)
                                grossValuePln *= parseFloat(action.newInvoice.currency_rate)
                            }
                        }
                        loadedInvoices[companyIndex].invoices[issueMonthIndex].docsCount += 1
                        loadedInvoices[companyIndex].invoices[issueMonthIndex].netSum += netValuePln
                        loadedInvoices[companyIndex].invoices[issueMonthIndex].vatSum += vatValuePln
                        loadedInvoices[companyIndex].invoices[issueMonthIndex].grossSum += grossValuePln
                        loadedInvoices[companyIndex].invoices[issueMonthIndex].docs.unshift(action.newInvoice)
                    }
                } else {
                    var netValuePln = parseFloat(action.newInvoice.total_net_value),
                        vatValuePln = parseFloat(action.newInvoice.total_vat_value),
                        grossValuePln = parseFloat(action.newInvoice.total_gross_value)
                    if (action.newInvoice.currency !== 'PLN') {
                        if (action.newInvoice.currency_rate) {
                            netValuePln *= parseFloat(action.newInvoice.currency_rate)
                            vatValuePln *= parseFloat(action.newInvoice.currency_rate)
                            grossValuePln *= parseFloat(action.newInvoice.currency_rate)
                        }
                    }
                    loadedInvoices[companyIndex].invoices.push({
                        directory: issueMonth,
                        docs: [action.newInvoice],
                        docsCount: 1,
                        netSum: netValuePln,
                        vatSum: vatValuePln,
                        grossSum: grossValuePln,
                    })

                    loadedInvoices[companyIndex].invoices.sort(dynamicComparer('directory', 'desc'))
                }
            } else {
                var netValuePln = parseFloat(action.newInvoice.total_net_value),
                    vatValuePln = parseFloat(action.newInvoice.total_vat_value),
                    grossValuePln = parseFloat(action.newInvoice.total_gross_value)
                if (action.newInvoice.currency !== 'PLN') {
                    if (action.newInvoice.currency_rate) {
                        netValuePln *= parseFloat(action.newInvoice.currency_rate)
                        vatValuePln *= parseFloat(action.newInvoice.currency_rate)
                        grossValuePln *= parseFloat(action.newInvoice.currency_rate)
                    }
                }
                loadedInvoices.push({
                    companyId: action.companyId,
                    invoices: {
                        directory: issueMonth,
                        docs: [action.newInvoice],
                        docsCount: 1,
                        netSum: netValuePln,
                        vatSum: vatValuePln,
                        grossSum: grossValuePln,
                    }
                })
            }

            return {
                ...state,
                ...{ loadedInvoices: loadedInvoices }
            }

        case FileConst.DELETE_GENERATED_INVOICE:
            var loadedInvoices = [...state.loadedInvoices]

            var companyIndex = arrayObjectIndexOf(loadedInvoices, action.companyId, 'companyId')
            var issueMonth = action.filePath.match(/[0-9]{4}-[0-9]{2}/)[0]
            console.log(companyIndex, issueMonth)
            if (companyIndex > -1) {
                var issueMonthIndex = arrayObjectIndexOf(loadedInvoices[companyIndex].invoices, issueMonth, 'directory')
                console.log(issueMonthIndex)
                if (issueMonthIndex > -1) {
                    var invoiceIndex = arrayObjectIndexOf(loadedInvoices[companyIndex].invoices[issueMonthIndex].docs, action.invoiceId, 'id')
                    if (invoiceIndex > -1) {
                        var prevVatValuePln = parseFloat(loadedInvoices[companyIndex].invoices[issueMonthIndex].docs[invoiceIndex].total_net_value),
                            prevNetValuePln = parseFloat(loadedInvoices[companyIndex].invoices[issueMonthIndex].docs[invoiceIndex].total_vat_value),
                            prevGrossValuePln = parseFloat(loadedInvoices[companyIndex].invoices[issueMonthIndex].docs[invoiceIndex].total_gross_value),
                            currencyRate = loadedInvoices[companyIndex].invoices[issueMonthIndex].docs[invoiceIndex].currency_rate
                        if (loadedInvoices[companyIndex].invoices[issueMonthIndex].docs[invoiceIndex].currency !== 'PLN') {
                            if (currencyRate) {
                                prevVatValuePln *= parseFloat(currencyRate)
                                prevNetValuePln *= parseFloat(currencyRate)
                                prevGrossValuePln *= parseFloat(currencyRate)
                            }
                        }
                        loadedInvoices[companyIndex].invoices[issueMonthIndex].netSum -= prevVatValuePln
                        loadedInvoices[companyIndex].invoices[issueMonthIndex].vatSum -= prevNetValuePln
                        loadedInvoices[companyIndex].invoices[issueMonthIndex].grossSum -= prevGrossValuePln
                    }

                    loadedInvoices[companyIndex].invoices[issueMonthIndex].docsCount -= 1
                    loadedInvoices[companyIndex].invoices[issueMonthIndex].docs = loadedInvoices[companyIndex].invoices[issueMonthIndex].docs.filter(d => d.id !== action.invoiceId)
                }
            }

            return {
                ...state,
                ...{ loadedInvoices: loadedInvoices }
            }

        case FileConst.ACCEPT_GENERATED_SALE_INVOICE:
            var loadedInvoices = state.loadedInvoices ? [...state.loadedInvoices] : []

            var companyIndex = arrayObjectIndexOf(loadedInvoices, action.companyId, 'companyId')
            if (companyIndex > -1) {
                var monthsCount = loadedInvoices[companyIndex].invoices.length
                for (var i = 0; i < monthsCount; i++) {
                    var invoiceIndex = arrayObjectIndexOf(loadedInvoices[companyIndex].invoices[i].docs, action.invoiceId, 'id')
                    if (invoiceIndex > -1) {
                        loadedInvoices[companyIndex].invoices[i].docs[invoiceIndex].accepted = action.accepted
                        loadedInvoices[companyIndex].invoices[i].docs[invoiceIndex].accepted_by = action.userId || null
                        loadedInvoices[companyIndex].invoices[i].docs[invoiceIndex].acceptance_date = action.accepted && !loadedInvoices[companyIndex].invoices[i].docs[invoiceIndex].acceptance_date ? moment() : null
                        loadedInvoices[companyIndex].invoices[i].docs[invoiceIndex].sent_to = action.email || loadedInvoices[companyIndex].invoices[i].docs[invoiceIndex].sent_to
                    }
                }
            }

            return {
                ...state,
                ...{ loadedInvoices: loadedInvoices }
            }

        case FileConst.UNLOCK_GENERATED_SALE_INVOICE:
            var loadedInvoices = state.loadedInvoices ? [...state.loadedInvoices] : []

            var companyIndex = arrayObjectIndexOf(loadedInvoices, action.companyId, 'companyId')
            if (companyIndex > -1) {
                var monthsCount = loadedInvoices[companyIndex].invoices.length
                for (var i = 0; i < monthsCount; i++) {
                    var invoiceIndex = arrayObjectIndexOf(loadedInvoices[companyIndex].invoices[i].docs, action.invoiceId, 'id')
                    if (invoiceIndex > -1) {
                        loadedInvoices[companyIndex].invoices[i].docs[invoiceIndex].accepted = false
                        loadedInvoices[companyIndex].invoices[i].docs[invoiceIndex].unlocked_by = action.userId || null
                        loadedInvoices[companyIndex].invoices[i].docs[invoiceIndex].unlock_date = moment()
                    }
                }
            }

            return {
                ...state,
                ...{ loadedInvoices: loadedInvoices }
            }

        case FileConst.SEND_SALE_INVOICE_TO_KSEF:
            var loadedInvoices = state.loadedInvoices ? [...state.loadedInvoices] : []

            var companyIndex = arrayObjectIndexOf(loadedInvoices, action.companyId, 'companyId')
            if (companyIndex > -1) {
                var monthsCount = loadedInvoices[companyIndex].invoices.length
                for (var i = 0; i < monthsCount; i++) {
                    var invoiceIndex = arrayObjectIndexOf(loadedInvoices[companyIndex].invoices[i].docs, action.invoiceId, 'id')
                    if (invoiceIndex > -1) {
                        loadedInvoices[companyIndex].invoices[i].docs[invoiceIndex].ksef_status = action.success ? 'SENT' : 'ERROR'
                    }
                }
            }

            return {
                ...state,
                ...{ loadedInvoices: loadedInvoices }
            }

        case FileConst.SORT_INVOICE:
            var { invoicesSortField, invoiceSortOrder } = state
            var loadedInvoices = state.loadedInvoices ? [...state.loadedInvoices] : []
            if (invoicesSortField === action.name) {
                if (invoiceSortOrder === 'desc') invoiceSortOrder = 'asc'
                else invoiceSortOrder = 'desc'
            } else {
                invoicesSortField = action.name
                invoiceSortOrder = 'desc'
            }

            for (var i = 0; i < loadedInvoices.length; i++) {
                if (loadedInvoices[i] && loadedInvoices[i].invoices) {
                    for (var j = 0; j < loadedInvoices[i].invoices.length; j++) {
                        if (loadedInvoices[i].invoices[j] && loadedInvoices[i].invoices[j].docs) {
                            loadedInvoices[i].invoices[j].docs.sort(dynamicComparer(invoicesSortField, invoiceSortOrder))
                        }
                    }
                }
            }

            return {
                ...state,
                ...{ invoicesSortField: invoicesSortField, invoiceSortOrder: invoiceSortOrder, loadedInvoices: loadedInvoices }
            }

        default:
            return state
    }
}