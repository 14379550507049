import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize'
import { Spinner } from 'react-activity'

import { AlertActions } from '../../actions/AlertActions'
import { DocflowActions } from '../../actions/DocflowActions'

export class DocflowNewUsersWindow extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
    }

    changeUsersSettings = (index, visibility) => {
        const { newDocflowUsersSettings } = this.props
        var tempDocflowUsersSettings = [...newDocflowUsersSettings]
        tempDocflowUsersSettings[index].visibility = visibility
        this.props.editNewDocflowUserSettings(tempDocflowUsersSettings)
    }

    render() {
        const { editedDocflow, newDocflowUsersSettings } = this.props
        const {
        } = this.state

        return (
            <div className="section-settings-window">
                <div className="settings-wrapper docflow new-docflow-users-settings">
                    <h4>
                        {this.context.t('While editing the docflow: "{d}" new users were added to it. Decide whether they should see all historical documents in this docflow or only documents that will be assigned to this workflow from now on', { d: editedDocflow.name })}
                    </h4>

                    <div className="new-docflow-users">
                        <div className="header-row">
                            <div className="header-title user">
                                {this.context.t('User')}
                            </div>
                            <div className="header-title">
                                {this.context.t('Only new documents')}
                            </div>
                            <div className="header-title">
                                {this.context.t('All documents')}
                            </div>
                        </div>
                        {
                            newDocflowUsersSettings.map((user, i) => {
                                return (
                                    <div key={i} className="user-row">
                                        <div className="user-info">
                                            <div className="user-name">
                                                {user.name}
                                            </div>
                                            <div className="user-email">
                                                {user.email}
                                            </div>
                                        </div>
                                        <div className="user-settings">
                                            <div className="checkbox-group">
                                                <div className={`checkbox ${user.visibility === 'NEW' ? 'checked' : ''}`} onClick={() => this.changeUsersSettings(i, 'NEW')}></div>
                                            </div>

                                            <div className="checkbox-group">
                                                <div className={`checkbox ${user.visibility === 'ALL' ? 'checked' : ''}`} onClick={() => this.changeUsersSettings(i, 'ALL')}></div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div className="settings-section stage-settings">
                        <div className="action-buttons align-right">
                            <div className="button reset" onClick={() => this.props.close()}>
                                {this.context.t('Cancel')}
                            </div>
                            <div className={`button save`} onClick={() => this.props.save()}>
                                {
                                    this.context.t('Save')
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}

DocflowNewUsersWindow.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    currentProject: state.User.currentProject,
})

const mapDispatchToProps = {
    getProjectMembersForDocflow: DocflowActions.getProjectMembersForDocflow,
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(DocflowNewUsersWindow)