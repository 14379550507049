import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { UserActions } from '../actions/UserActions'

import { history } from "../helpers/history"
import routes from "../helpers/routes"
import arrayObjectIndexOf from "../helpers/indexOfObject"
import isIterable from "../helpers/isIterable"

import FirstLoginImage from '../img/first-login-image.png'
import '../css/OnboardingPopup.css'

export class AdminOnboarding extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isMinimized: false,
            isPopup: false,
            seenOcrPopup: true,

            showEachStepPopups: true,
            isOnFirstStep: false,
            finishedFirstStep: false,
            showedFirstStepPopu: false,
            isOnSecondStep: false,
            finishedSecondStep: false,
            showedSecondStepPopu: false,

            showSuggestedSteps: false,
            showSummary: false,
        }
    }

    componentDidMount() {
        window.dispatchEvent(new Event("storage"));

        if (localStorage.getItem('adminOnboarding') !== 'HIDDEN' && localStorage.getItem('adminOnboarding') !== 'MINI' && localStorage.getItem('adminOnboarding') !== 'NEXT_STEPS') {
            this.setState({
                isPopup: true,
                showEachStepPopups: true
            })
        }

        if (localStorage.getItem('adminOnboarding') === 'NEXT_STEPS') {
            var currentSuggestedStep = ''
            if (window.location.href.includes('settings/company/')) currentSuggestedStep = 'company'
            else if (window.location.href.includes('settings/projects/')) currentSuggestedStep = 'projects'
            else if (window.location.href.includes('settings/profile/')) currentSuggestedStep = 'profile'
            else if (window.location.href.includes('/actions/')) currentSuggestedStep = 'actions'

            this.setState({
                currentSuggestedStep: currentSuggestedStep
            })
        }

        if (!localStorage.getItem('seenOcrPopup')) {
            this.setState({
                seenOcrPopup: false
            })
        }
    }

    componentDidUpdate(prevProps) {
        const { showEachStepPopups, showedSecondStepPopu, finishedSecondStep } = this.state
        const { currentCompany, acceptedFirtsDocument } = this.props
        if (showEachStepPopups) {
            var companyInstance = null
            var prevCompanyInstance = null
            if (currentCompany && currentCompany.company) companyInstance = currentCompany.company
            if (prevProps.currentCompany && prevProps.currentCompany.company) prevCompanyInstance = prevProps.currentCompany.company

            if (companyInstance && prevCompanyInstance) {
                var currentCompanyHasCodeAndPhone = companyInstance.code && companyInstance.phone
                var prevCompanyHasCodeAndPhone = prevCompanyInstance.code && prevCompanyInstance.phone

                if (currentCompanyHasCodeAndPhone && !prevCompanyHasCodeAndPhone && !finishedSecondStep && !showedSecondStepPopu) {
                    this.setState({
                        finishedSecondStep: true,
                        showedSecondStepPopu: true
                    })
                }
            }

            if (!prevProps.acceptedFirtsDocument && acceptedFirtsDocument) {
                this.setState({
                    finishedFirstStep: true,
                    showedFirstStepPopu: true,
                })
            }
        }
    }

    checkIfStepIsCompletetd = step => {
        var completed = false
        const { currentCompany, user, acceptedFirtsDocument, uploadedFiles, currentProject } = this.props
        if (step === 'companyCode') {
            var companyInstance = currentCompany.company
            if (companyInstance && companyInstance.code && companyInstance.vat_id) completed = true
        }

        if (step === 'companyPhone') {
            var companyInstance = currentCompany.company
            if (companyInstance && companyInstance.phone && companyInstance.vat_id) completed = true
        }

        if (step === 'companyBanks') {
            var companyInstance = currentCompany.company
            if (companyInstance && companyInstance.banks && companyInstance.banks.length > 0) completed = true
        }

        if (step === 'fileUpload') {
            const index = arrayObjectIndexOf(uploadedFiles, currentProject.id, "project")
            if (index > -1 && uploadedFiles[index] && uploadedFiles[index].files.length > 0) completed = true
        }

        if (step === 'fileVerify') {
            if (acceptedFirtsDocument) completed = true
        }

        if (step === 'profile') {
            if (user.banks && user.banks.length > 0) completed = true
        }

        if (step === 'invite') {
            var companyInstance = currentCompany.company
            if (companyInstance && (companyInstance.members_count > 1 || companyInstance.subscription_type === 'FREE' || companyInstance.next_subscription_type === 'FREE')) completed = true
        }

        return completed
    }

    beginAdminOnboarding = () => {
        localStorage.setItem('adminOnboarding', 'MINI')
        this.setState({
            isPopup: false,
            isOnFirstStep: true
        })
    }

    getOnboardingProgress = () => {
        var value = 0
        if (this.checkIfStepIsCompletetd('companyCode')) value++
        if (this.checkIfStepIsCompletetd('companyPhone')) value++
        if (this.checkIfStepIsCompletetd('fileUpload')) value++
        if (this.checkIfStepIsCompletetd('fileVerify')) value++

        return value
    }

    getOnboardingProgressPercentage = () => {
        var value = 0
        if (this.checkIfStepIsCompletetd('companyCode')) value += 25
        if (this.checkIfStepIsCompletetd('companyPhone')) value += 25
        if (this.checkIfStepIsCompletetd('fileUpload')) value += 25
        if (this.checkIfStepIsCompletetd('fileVerify')) value += 25

        if (value === 99) value = 100
        return value
    }


    minimizeOnboarding = (newisMinimized) => {
        this.setState({
            isMinimized: newisMinimized
        })
    }

    hideAdminOnboarding = () => {
        localStorage.setItem('adminOnboarding', 'HIDDEN')
        this.forceUpdate();
    }

    finishOnboarding = () => {
        const { currentCompany } = this.props
        this.props.finishAdminOnboarding(currentCompany.company_id)
    }

    hideFirstStepCompleted = () => {
        this.setState({
            finishedFirstStep: false
        })
    }

    hideOcrPopup = () => {
        this.setState({
            seenOcrPopup: true
        })
        localStorage.setItem('seenOcrPopup', true)
    }

    showNextSteps = () => {
        localStorage.setItem('adminOnboarding', 'NEXT_STEPS')
        this.setState({
            showSuggestedSteps: true
        })

        this.suggestedStepClick('company')
    }

    suggestedStepClick = step => {
        this.setState({
            currentSuggestedStep: step,
            showSuggestedStepPopup: true
        })
    }

    hideSuggestesStepPopup = () => {
        this.setState({
            showSuggestedStepPopup: false
        })
    }

    getCurrentSuggestedStepH2 = step => {
        switch (step) {
            case 'company':
                return <h2>{this.context.t('Complete your company details')}</h2>
            case 'projects':
                return <h2>{this.context.t('Invite coworkers and create binders')}</h2>
            case 'profile':
                return <h2>{this.context.t('Complete your profile setup')}</h2>
            case 'actions':
                return <h2>{this.context.t('View documents, download statements and documents')}</h2>
            case 'sale':
                return <h2>{this.context.t('Issuing invoices')}</h2>
            case 'helpdesk':
                return <h2>{this.context.t('Helpdesk')}</h2>
        }
    }

    getCurrentSuggestedStepH4 = step => {
        switch (step) {
            case 'company':
                return <h4>{this.context.t('Now it\'s time to complete your company details. Here you can add:')}</h4>
            case 'projects':
                return null
            case 'profile':
                return null
            case 'actions':
                return <h4>{this.context.t('Here you can:')}</h4>
            case 'sale':
                return <h4>{this.context.t('Now it\'s time for invoices. Here\'s what you can do here:')}</h4>
            case 'helpdesk':
                return null
        }
    }

    getCurrentSuggestedStepUL = step => {
        switch (step) {
            case 'company':
                return (
                    <ul>
                        <li>{this.context.t('Your company\'s bank accounts, which will later be used to create transfer baskets imported to your bank.')}</li>
                        <li>{this.context.t('Bank accounts for ZUS and US, which you can later select when creating payment orders.')}</li>
                        <li>{this.context.t('Set the numbering method for sales invoices.')}</li>
                    </ul>
                )
            case 'projects':
                return (
                    <ul>
                        <li>{this.context.t('It\'s time to introduce your colleagues to EasyDocs. They can also add, verify and use data from documents. As the company administrator, you will decide what level of access the invited person will receive.')}</li>
                        <li>{this.context.t('Additionally, you can create binders that will help you organize your documents thematically. This is the perfect solution if you have multiple projects, each with its own space for documents (invoices, expenses, contracts, correspondence, others) and a unique email address for uploading files.')}</li>
                    </ul>
                )
            case 'profile':
                return (
                    <ul>
                        <li>{this.context.t('Decide what email notifications you want to receive from EasyDocs. Adapt them to your business needs.')}</li>
                        <li>{this.context.t('Add your personal bank accounts to which you will receive reimbursement for expenses incurred from private funds when settling business expenses, for example during a business trip.')}</li>
                    </ul>
                )
            case 'actions':
                return (
                    <ul>
                        <li>{this.context.t('Browse your documents according to any search criteria.')}</li>
                        <li>{this.context.t('Generate reports in Excel format from selected files.')}</li>
                        <li>{this.context.t('Create transfer files that you can then easily import to your bank.')}</li>
                        <li>{this.context.t('Create ZIP packages with selected documents and send them to accounting.')}</li>
                    </ul>
                )
            case 'sale':
                return (
                    <ul>
                        <li>{this.context.t('Generate an invoice and adjust the numbering of the revenue invoice series.')}</li>
                        <li>{this.context.t('Generate the JPK_FA file.')}</li>
                    </ul>
                )
            case 'helpdesk':
                return (
                    <>
                        <p>
                            {
                                this.context.t(
                                    'If you have questions or suggestions about the new feature, do not hesitate to contact us via Helpdesk or send an e-mail to {email}.',
                                    {
                                        email: <a href="mailto:support@easydocs.pl">support@easydocs.pl</a>
                                    }
                                )
                            }
                        </p>
                        <p>{this.context.t('Our team is ready to act quickly and is here to help you.')}</p>
                    </>
                )
        }
    }

    goToSummary = () => {
        this.setState({
            showSummary: true
        })
    }

    render() {
        const {
            isMinimized, isPopup,
            finishedFirstStep, showedFirstStepPopu, finishedSecondStep, showedSecondStepPopu, seenOcrPopup,
            showSuggestedSteps, currentSuggestedStep, showSuggestedStepPopup, showSummary,
        } = this.state
        if (localStorage.getItem('adminOnboarding') === 'HIDDEN') {
            return null
        } else if (isPopup) {
            return (
                <div className="admin-onboarding-popup popup">
                    <div className="popup-card">
                        <div className="image">
                            <img src={FirstLoginImage} alt="" />
                        </div>
                        <div className="title">
                            <h3>{this.context.t('Welcome to EasyDocs!')}</h3>
                            <p class="center">{this.context.t('This is a place where working with documents becomes very easy.')}</p>
                            <p class="smaller">{this.context.t('At EasyDocs, we believe that a document should be verified only once and the data should always be available.')}</p>
                            <p class="smaller">{this.context.t('Get ready for a few steps that will make using EasyDocs easier for you.')}</p>
                        </div>
                        <div className="button" onClick={() => this.beginAdminOnboarding()}>{this.context.t('See how simple it is!')}</div>
                    </div>
                </div>
            )
        } else {
            if (localStorage.getItem('adminOnboarding') === 'NEXT_STEPS' || showSuggestedSteps) {
                if (showSummary) {
                    return (
                        <div className={`admin-onboarding-mini next-steps ${isMinimized ? 'minimized' : ''}`}>
                            <div className="title suggestions">
                                <p>{this.context.t('Congratulations! You\'ve made it through the entire guide!')}</p>
                            </div>

                            <div className="text">
                                <p>{this.context.t('Adapt EasyDocs to the needs of your company and focus on what is important.')}</p>
                                <p>
                                    {
                                        this.context.t(
                                            'Your consultant is Maria, if you have any questions, feel free to contact us by phone: {phone} or by e-mail: {email}',
                                            {
                                                phone: <span className="phone">+48 660 490 644</span>,
                                                email: <a href="mailto:maria.kosiak@easydocs.pl">maria.kosiak@easydocs.pl</a>,
                                            }
                                        )
                                    }
                                </p>
                            </div>

                            <div className="button" onClick={() => this.finishOnboarding()}>
                                {this.context.t('Got it!')}
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div className={`admin-onboarding-mini next-steps ${isMinimized ? 'minimized' : ''}`}>
                            {
                                showSuggestedStepPopup ? (
                                    <div className="popup suggestion-popup">
                                        <div className="popup-card">
                                            {this.getCurrentSuggestedStepH2(currentSuggestedStep)}
                                            <div className="text">
                                                {this.getCurrentSuggestedStepH4(currentSuggestedStep)}
                                                {this.getCurrentSuggestedStepUL(currentSuggestedStep)}
                                            </div>
                                            <div className="action-buttons">
                                                <button className="btn accept" onClick={e => this.hideSuggestesStepPopup()}>{this.context.t('Got it!')}</button>
                                            </div>
                                        </div>
                                    </div>
                                ) : null
                            }
                            {
                                !isMinimized ? (
                                    <>
                                        <div className="title suggestions">
                                            <p>{this.context.t('What\'s next?')}</p>
                                            <div className="minimize-button" onClick={() => this.minimizeOnboarding(!isMinimized)}></div>
                                            {/* <div className="hide-button" onClick={() => this.finishOnboarding()}></div> */}
                                        </div>

                                        <div className={`step suggestes ${currentSuggestedStep === 'company' ? 'current' : ''}`} onClick={() => this.suggestedStepClick('company')}>
                                            <Link to={routes.panel.settings.company}>{this.context.t('Complete your company details')}</Link>
                                        </div>

                                        <div className={`step suggestes ${currentSuggestedStep === 'projects' ? 'current' : ''}`} onClick={() => this.suggestedStepClick('projects')}>
                                            <Link to={routes.panel.settings.projects}>{this.context.t('Invite coworkers and create binders')}</Link>
                                        </div>

                                        <div className={`step suggestes ${currentSuggestedStep === 'profile' ? 'current' : ''}`} onClick={() => this.suggestedStepClick('profile')}>
                                            <Link to={routes.panel.settings.profile}>{this.context.t('Complete your profile setup')}</Link>
                                        </div>

                                        <div className={`step suggestes ${currentSuggestedStep === 'actions' ? 'current' : ''}`} onClick={() => this.suggestedStepClick('actions')}>
                                            <Link to={routes.panel.actions}>{this.context.t('Pracuj ze swoimi dokumentami')}</Link>
                                        </div>

                                        <div className={`step suggestes ${currentSuggestedStep === 'sale' ? 'current' : ''}`} onClick={() => this.suggestedStepClick('sale')}>
                                            <Link to={routes.panel.sale}>{this.context.t('Issue invoices')}</Link>
                                        </div>

                                        <div className={`step suggestes ${currentSuggestedStep === 'helpdesk' ? 'current' : ''}`} onClick={() => this.suggestedStepClick('helpdesk')}>
                                            <Link to={routes.panel.help}>{this.context.t('Check the Helpdesk')}</Link>
                                        </div>

                                        <div className="finish-small" onClick={() => this.goToSummary()}>
                                            {this.context.t('Finish onboarding')}
                                        </div>
                                    </>
                                ) : (
                                    <div className="circle-progress" onClick={() => this.minimizeOnboarding(!isMinimized)}>
                                        <CircularProgressbarWithChildren
                                            value={100}
                                            strokeWidth={12}
                                            styles={buildStyles({
                                                // Text size
                                                textSize: '26px',

                                                // How long animation takes to go from one percentage to another, in seconds
                                                pathTransitionDuration: 0.5,

                                                // Colors
                                                pathColor: '#69B792',
                                                textColor: '#2B2D31',
                                                trailColor: '#E4E9F3',
                                                backgroundColor: '#FFFFFF',
                                            })}
                                        >
                                            <strong>{`100%`}</strong>
                                        </CircularProgressbarWithChildren>
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            } else {
                return (
                    <div className={`admin-onboarding-mini ${isMinimized ? 'minimized' : ''} ${finishedFirstStep || finishedSecondStep ? 'pulse-once' : ''}`}>
                        {
                            finishedSecondStep && showedSecondStepPopu ? (
                                <div className="finished-step-popup">
                                    <div className="popup-card">
                                        <div className="title">
                                            <h3>{this.context.t('Basic setup complete!')}</h3>
                                        </div>
                                        <div className="text">
                                            <p>{this.context.t('You can now fill in the rest of your company details or proceed to the next step')}</p>
                                        </div>
                                        <div className="action-buttons">
                                            <span className="btn save" onClick={() => this.showNextSteps()}><Link to={routes.panel.settings.company}>{this.context.t('Show more tips!')}</Link></span>
                                        </div>
                                    </div>
                                </div>
                            ) : null
                        }

                        {
                            finishedFirstStep && showedFirstStepPopu ? (
                                <div className="finished-step-popup">
                                    <div className="popup-card">
                                        <div className="title">
                                            <h3>{this.context.t('Hurrah!')}</h3>
                                        </div>
                                        <div className="text">
                                            <p>{this.context.t('The first document is already in EasyDocs. Good job! It will get easier each time.')}</p>
                                        </div>
                                        <div className="text">
                                            <p>{this.context.t('Your document has been automatically cataloged in the selected binder.')}</p>
                                        </div>
                                        <div className="text">
                                            <p>{this.context.t('What\'s next?')}</p>
                                        </div>
                                        <div className="action-buttons">
                                            <span className="btn save" onClick={() => this.hideFirstStepCompleted()}><Link to={routes.panel.settings.company}>{this.context.t('Check what else we have prepared for you!')}</Link></span>
                                        </div>
                                    </div>
                                </div>
                            ) : null
                        }

                        {
                            !seenOcrPopup && window.location.href.includes('/ocr/') ? (
                                <div className="popup suggestion-popup">
                                    <div className="popup-card">
                                        <div className="header">
                                            {this.context.t('Document verification!')}
                                        </div>

                                        <div className="text">
                                            <h4>{this.context.t('This is where document verification becomes very simple. Prepare for a few steps that will make this process easier for you:')}</h4>
                                            <ul>
                                                <li>
                                                    {this.context.t("On the left side you will find a form with data and parameters to be verified - check data compliance!")}
                                                    <p className="disclaimer">{this.context.t("*Our suggestion: Check the data in the form one by one.")}</p>
                                                </li>
                                                <li>{this.context.t("By clicking on the read values in the form, they will be automatically highlighted in the document.")}</li>
                                                <li>{this.context.t("If you find empty fields on the left, you can fill them in easily. Simply frame an area on your document and select the appropriate option from the drop-down list.")}</li>
                                                <li>
                                                    {this.context.t("When everything is verified, check the \"Compatibility of data in the form with the document\" box.")}
                                                    <p className="disclaimer">{this.context.t("*If the document is unpaid, confirm the amount to be paid")}</p>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="action-buttons">
                                            <button className="btn accept" onClick={e => this.hideOcrPopup()}>{this.context.t('Got it!')}</button>
                                        </div>
                                    </div>
                                </div>
                            ) : null
                        }

                        {
                            !isMinimized ? (
                                <>
                                    <div className="title">
                                        <p>{this.context.t('Get Started')}</p>
                                        <div className="minimize-button" onClick={() => this.minimizeOnboarding(!isMinimized)}></div>
                                        {/* <div className="hide-button" onClick={() => this.hideAdminOnboarding()}></div> */}
                                    </div>

                                    <div className={`progress step-${this.getOnboardingProgress()}`}>
                                        <div className="bar"></div>
                                    </div>

                                    <div className={`step ${this.checkIfStepIsCompletetd('fileUpload') && this.checkIfStepIsCompletetd('fileVerify') ? 'completed' : ''}`}>
                                        <Link to={routes.panel.dashboard}>{this.context.t('Upload and verify your first document')}</Link>
                                    </div>

                                    <div className={`step ${this.checkIfStepIsCompletetd('companyCode') && this.checkIfStepIsCompletetd('companyPhone') ? 'completed' : ''} ${window.location.href.includes('/company/') ? 'current' : ''}`}>
                                        <Link to={routes.panel.settings.company}>{this.context.t('Complete your company details')}</Link>
                                    </div>

                                    <div className="finish-small" onClick={() => this.finishOnboarding()}>
                                        {this.context.t('Finish onboarding')}
                                    </div>
                                </>

                            ) : (
                                <div className="circle-progress" onClick={() => this.minimizeOnboarding(!isMinimized)}>
                                    <CircularProgressbarWithChildren
                                        value={this.getOnboardingProgressPercentage()}
                                        strokeWidth={12} styles={buildStyles({
                                            // Text size
                                            textSize: '26px',

                                            // How long animation takes to go from one percentage to another, in seconds
                                            pathTransitionDuration: 0.5,

                                            // Colors
                                            pathColor: '#69B792',
                                            textColor: '#2B2D31',
                                            trailColor: '#E4E9F3',
                                            backgroundColor: '#FFFFFF',
                                        })}
                                        onClick={() => this.minimizeOnboarding(!isMinimized)}
                                    >
                                        <strong>{`${this.getOnboardingProgressPercentage()}%`}</strong>
                                    </CircularProgressbarWithChildren>
                                </div>
                            )
                        }
                    </div>
                )
            }
        }
    }
}

AdminOnboarding.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
    user: state.User.user,
    acceptedFirtsDocument: state.User.acceptedFirtsDocument,
    currentCompany: { ...state.Company.currentCompany },
    currentProject: state.User.currentProject,
    filesToVerifiLoaded: isIterable(state.File.filesToVerifiLoaded) ? [...state.File.filesToVerifiLoaded] : [],
    uploadedFiles: isIterable(state.File.uploadedFiles) ? [...state.File.uploadedFiles] : [],
})

const mapDispatchToProps = {
    finishAdminOnboarding: UserActions.finishAdminOnboarding
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminOnboarding)