import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { FileActions } from '../../actions/FileActions'

import { socket } from "../../App"

import "../../../src/css/SalesPage.css";
import { CompanyActions } from "../../actions/CompanyActions";

export class ImportInvoicePopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            generatedInvoicesCount: 0,
            currentPercentage: 0,
            generatedFileIds: [],
            generationErrors: []
        };
    }

    componentDidMount() {
        if (socket) {
            socket.on('newSaleInvoiceGenerated', this.newSaleInvoiceGenerated)
            socket.on('newSaleInvoiceGenerationError', this.newSaleInvoiceGenerationError)
        }
    }

    componentWillUnmount() {
        if (socket) {
            socket.off('newSaleInvoiceGenerated', this.newSaleInvoiceGenerated)
            socket.off('newSaleInvoiceGenerationError', this.newSaleInvoiceGenerationError)
        }
    }

    newSaleInvoiceGenerated = (data) => {
        const { generatedInvoicesCount, generatedFileIds } = this.state
        const { totalInvoicesCount, currentProject} = this.props
        var newCount = generatedInvoicesCount + 1
        var newPercentage = parseInt((newCount / totalInvoicesCount) * 100)
        this.setState({
            generatedInvoicesCount: newCount,
            currentPercentage: newPercentage,
            generatedFileIds: [...generatedFileIds, ...[data.fileId]]
        })

        this.props.getNewGeneratedInvoice(currentProject.company_id, data.id)
        this.props.getFiles("", data.projectId, data.fileId)
    }

    newSaleInvoiceGenerationError = data => {
        const { generatedInvoicesCount, generationErrors } = this.state
        const { totalInvoicesCount } = this.props
        var newCount = generatedInvoicesCount + 1
        var newPercentage = parseInt((newCount / totalInvoicesCount) * 100)
        this.setState({
            generatedInvoicesCount: newCount,
            currentPercentage: newPercentage,
            generationErrors: [...generationErrors, ...[{counterparty: data.invoiceData.buyerName}]]
        })
    }

    downloadNewFiles = () => {
        const { currentProject } = this.props
        const { generatedFileIds } = this.state

        this.props.downloadMultipleFiles(currentProject.id, currentProject.code, {ids: generatedFileIds} , {ids: []}, false, () => {})
    }

    render() {
        const { generatedInvoicesCount, currentPercentage } = this.state
        const { totalInvoicesCount } = this.props

        console.log(this.state.generatedFileIds)

        return (
            <div className="import-sale-invoice-popup">
                {
                    generatedInvoicesCount === 0 ? (
                        <div className="card">
                            <div className="header">
                                {this.context.t('The Excel file has been read successfully and {n} invoices are about to be generated', { n: totalInvoicesCount })}
                            </div>
                            <div className="progress-wrapper">
                                <div className="progress-bar-track">
                                    <div className="progress-bar" style={{ width: `${currentPercentage}%` }}></div>
                                </div>
                                <div className="count">
                                    {`${currentPercentage}%`}
                                </div>
                            </div>
                            <div className="footer">
                                {
                                    generatedInvoicesCount !== totalInvoicesCount ? (
                                        this.context.t('This may take a while as a unique number and PDF documents are generated for each invoice')
                                    ) : (
                                        <>
                                            <div className="download-all-invoices" onClick={() => this.downloadNewFiles()}>
                                                {this.context.t('Download all generated invoices')}
                                            </div>

                                            <div className="close-btn" onClick={() => this.props.close()}>{this.context.t('Close')}</div>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    ) : (
                        <div className="card">
                            <div className="header">
                                {
                                    generatedInvoicesCount !== totalInvoicesCount ? (
                                        <>{this.context.t('Invoices are being generated')} {`(${generatedInvoicesCount}/${totalInvoicesCount})`}</>
                                    ) : (
                                        this.context.t('Generated {n} invoices', {n: generatedInvoicesCount})
                                    )
                                }
                            </div>
                            <div className="progress-wrapper">
                                <div className="progress-bar-track">
                                    <div className="progress-bar" style={{ width: `${currentPercentage}%` }}></div>
                                </div>
                                <div className="count">
                                    {`${currentPercentage}%`}
                                </div>
                            </div>
                            <div className="footer">
                                {
                                    generatedInvoicesCount !== totalInvoicesCount ? (
                                        this.context.t('This may take a while as a unique number and PDF documents are generated for each invoice')
                                    ) : (
                                        <>
                                            <div className="download-all-invoices" onClick={() => this.downloadNewFiles()}>
                                                {this.context.t('Download all generated invoices')}
                                            </div>

                                            <div className="close-btn" onClick={() => this.props.close()}>{this.context.t('Close')}</div>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}

ImportInvoicePopup.contextTypes = {
    t: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
});

const mapDispatchToProps = {
    getFiles: FileActions.getFiles,
    downloadMultipleFiles: FileActions.downloadMultipleFiles,
    getNewGeneratedInvoice: CompanyActions.getNewGeneratedInvoice
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportInvoicePopup);
