import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './helpers/store';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import ErrorBoundary from './components/ErrorBoundary'
import { GlobalDebug } from "./helpers/remove-console";

import I18n from 'redux-i18n'
import { translations } from './translations'

Sentry.init({ dsn: "https://e740a02b3b1d4a008909403a2a6c95dc@o402704.ingest.sentry.io/5264217" });

(process.env.NODE_ENV === "production" || process.env.REACT_APP_ENVIRONMENT === "production") && GlobalDebug(false, true);

(process.env.NODE_ENV === "production" || process.env.REACT_APP_ENVIRONMENT === "production" || process.env.NODE_ENV === "test" || process.env.REACT_APP_ENVIRONMENT === "test") && document.body.classList.add('no-errors')

render(
    <Provider store={store}>
        <I18n translations={translations} initialLang={localStorage.getItem('language') ? localStorage.getItem('language') : 'pl'}>
            <ErrorBoundary>
                <App />
            </ErrorBoundary>
        </I18n>
    </Provider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

serviceWorker.register();

// serviceWorker.register({
//     onUpdate: registration => {
//         console.log('serviceWorker onUpdate')
//         registration.unregister().then(() => {
//             console.log('REFRESH - NEW VERSION')
//             // eslint-disable-next-line no-restricted-globals
//             if(confirm('New version available. Refresh page')) {
//                 window.location.reload();
//             }

//             //const event = new Event('newContetnAvailable')
//             // window.dispatchEvent(event)
//         })
//     },
//     onSuccess: registration => {
//         console.log('App is cashed')

//         window.addEventListener('focus', (event) => {
//             console.log('focusEvent', event)

//             navigator.serviceWorker.getRegistration().then(reg => {
//                 reg.update()
//             })
//         })
//     }
// });
