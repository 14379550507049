import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import TextareaAutosize from 'react-textarea-autosize'

import { UserActions } from '../../../actions/UserActions'
import { AlertActions } from '../../../actions/AlertActions'

export class WhatsNew extends Component {

    constructor(props) {
        super(props)
        this.state = {
            cursourWait: false,
            showAddNewPopup: false,
            web: "",
            desktop: "",
            mobile: "",
        }
    }

    onInputChange = e => {
        e.preventDefault()
        const { name, value } = e.target

        this.setState({ [name]: value })
    }

    componentDidMount() {
    }

    displayWithNewLines = array => {
        var textToReturn = ''
        array.map(line => {
            textToReturn += line + '\n'
        })



        return <p>{textToReturn}</p>
    }

    onSubmit = () => {
        const { web, mobile, desktop, cursourWait } = this.state
        if (!cursourWait) {
            if ((!web || web.length === 0) && (!mobile || mobile.length === 0) && (!desktop || desktop.length === 0)) {
                this.props.alertWarn(this.context.t('You have to add at least one update info'))
            } else {
                this.setState({
                    cursourWait: true
                })
                var data = {}

                data.web = web.split('\n')
                data.mobile = mobile.split('\n')
                data.desktop = desktop.split('\n')

                this.props.createWhatsNew(data, success => {
                    this.setState({
                        cursourWait: false
                    })

                    if (success) {
                        this.setState({
                            showAddNewPopup: false,
                            web: '',
                            mobile: '',
                            desktop: ''
                        })
                    }
                })
            }
        }
    }

    showError = () => {
        this.setState({
            showError: true
        })
    }

    render() {
        const { whatsNewList, user } = this.props
        const { cursourWait, showAddNewPopup, web, mobile, desktop, showError, array } = this.state

        return (
            <div className="superadmin-emails">
                {
                    showAddNewPopup ? (
                        <div className="popup">
                            <div className="card">
                                <div className="close-button" onClick={() => this.setState({ showAddNewPopup: false })}></div>
                                <div className="header">
                                    {this.context.t('Create "Whats new"')}
                                </div>
                                <div className="body">
                                    <div className="form-group ">
                                        <TextareaAutosize
                                            id="web"
                                            name="web"
                                            value={web}
                                            onChange={this.onInputChange}
                                            minRows={5}
                                            maxRows={10}
                                        />
                                        <label htmlFor={`web`}>{this.context.t('Web')}</label>
                                    </div>

                                    <div className="form-group ">
                                        <TextareaAutosize
                                            id="desktop"
                                            name="desktop"
                                            value={desktop}
                                            onChange={this.onInputChange}
                                            minRows={5}
                                            maxRows={10}
                                        />
                                        <label htmlFor={`desktop`}>{this.context.t('Desktop')}</label>
                                    </div>

                                    <div className="form-group ">
                                        <TextareaAutosize
                                            id="mobile"
                                            name="mobile"
                                            value={mobile}
                                            onChange={this.onInputChange}
                                            minRows={5}
                                            maxRows={10}
                                        />
                                        <label htmlFor={`mobile`}>{this.context.t('Mobile app')}</label>
                                    </div>
                                    <button onClick={this.onSubmit}>{this.context.t('Create')}</button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        null
                    )
                }

                {
                    showError ? (
                        array.map(e => {
                            return
                        })
                    ) : null
                }

                {
                    user.role === "SUPERADMIN" || user.id === 417 ? (
                        <div className="header-text">
                            <div className="create" onClick={() => this.setState({ showAddNewPopup: true })}>{this.context.t('Create new entry')}</div>


                            <div className="create" onClick={() => this.showError()}>{this.context.t('Show error page')}</div>
                        </div>
                    ) : (
                        null
                    )
                }

                <table className={`${cursourWait ? 'cursour-wait' : ''}`}>
                    <thead>
                        <tr>
                            <th>
                                {this.context.t('Publication date')}
                            </th>
                            <th>
                                {this.context.t('Created by')}
                            </th>
                            <th>
                                {this.context.t('Web')}
                            </th>
                            <th>
                                {this.context.t('Desktop')}
                            </th>
                            <th>
                                {this.context.t('Mobile app')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            whatsNewList.map(tx => {
                                return (
                                    <tr>
                                        <td className="month">{moment(tx.created).format('YYYY-MM-DD HH:mm')}</td>
                                        <td className="">{`${tx.createdBy.first_name} ${tx.createdBy.last_name}`}</td>
                                        <td className="new-functions bigger">{this.displayWithNewLines(tx.web)}</td>
                                        <td className="new-functions">{this.displayWithNewLines(tx.desktop)}</td>
                                        <td className="new-functions">{this.displayWithNewLines(tx.mobile)}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

WhatsNew.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    whatsNewList: state.User.whatsNewList || [],
})

const mapDispatchToProps = {
    alertWarn: AlertActions.warning,
    createWhatsNew: UserActions.createWhatsNew,
}

export default connect(mapStateToProps, mapDispatchToProps)(WhatsNew)