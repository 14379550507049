import axios from "axios"
import moment from "moment"

const getApiResponse = async (nips) => {
    return await axios.get(`https://wl-api.mf.gov.pl/api/search/nips/${nips}?date=${moment().format("YYYY-MM-DD")}`, { timeout: 15000 })
        .then(response => response)
        .catch(err => err)
}

export const checkAccountNumber = async (accountNumber) => {
    return await fetch(`https://wl-api.mf.gov.pl/api/search/bank-account/${accountNumber}?date=${moment().format("YYYY-MM-DD")}`, { method: 'get' })
        .then(response => response.json())
        .catch(err => err)
}

export const partialRequests = async (nipStrings, callback) => {
    var requestsCount = nipStrings.length
    var response = null
    var responseArray = [];
    var timeout = false

    for (var i = 0; i < requestsCount; i++) {
        var responseInvalid = true
        while (responseInvalid) {
            response = await getApiResponse(nipStrings[i])
            if (response && response.data && response.data.result && response.data.result.entries) {
                response.data.result.entries.forEach(entry => {
                    if (entry.subjects) {
                        responseArray.push(...entry.subjects)
                    }
                })
                responseInvalid = false
            } else {
                if (response && (response.code === "WL-115" || response.code === "WL-113")) {
                    console.log(response)
                    console.log('invalid NIP found')
                    var invalidNIP = response.message.replace(/[^0-9]/gm, '')
                    console.log(invalidNIP)
                    nipStrings[i] = nipStrings[i].replace(invalidNIP, '').replace(',,', ',').replace(/\,$/, '')
                } else {
                    timeout = true
                    responseInvalid = false
                }
            }
        }
    }

    callback(responseArray, timeout)
}

export const setStatusForDoc = async (docs, whiteListResults, callback) => {
    if (whiteListResults.length) {
        var docsCount = docs.length
        var accountStatus = '', status = '', otherCompany = null, doc = null
        for (var i = 0; i < docsCount; i++) {
            accountStatus = ''
            status = ''
            otherCompany = null
            doc = docs[i]
            if (doc.type === 'DOC') {
                var apiRespons = whiteListResults.find(e => e.nip === doc.counterpartyVatId)
                if (apiRespons) {
                    if (doc.accountNumber) {
                        otherCompany = null
                        if (apiRespons.accountNumbers && apiRespons.accountNumbers.includes(doc.accountNumber.replace(/[^0-9]/gm, ''))) {
                            accountStatus = 'found'
                        } else if (apiRespons.accountNumbers && apiRespons.statusVat === 'Czynny' && !apiRespons.accountNumbers.includes(doc.accountNumber.replace(/[^0-9]/gm, ''))) {
                            var accountResponse = await checkAccountNumber(doc.accountNumber.replace(/[^0-9]/gm, ''))
                            console.log(doc.accountNumber)
                            console.log(accountResponse)

                            if (accountResponse && accountResponse.result && accountResponse.result.subjects) {
                                var foundCompany = accountResponse.result.subjects.find(x => x !== undefined)
                                if (!foundCompany) {
                                    accountStatus = 'not-found'
                                } else if (foundCompany && foundCompany.nip === doc.counterpartyVatId) {
                                    accountStatus = 'found'
                                } else {
                                    accountStatus = 'other-company'
                                    otherCompany = foundCompany.name.replace(/SPÓŁKA KOMANDYTOWA/gi, '').replace(/SPÓŁKA CYWILNA/gi, '').replace(/SPÓŁKA AKCYJNA/gi, '').replace(/SPÓŁKA DORADZTWA PODATKOWEGO/gi, '').replace(/SPÓŁKA PARTNERSKA/gi, '').replace(/SPÓŁKA JAWNA/gi, '').replace(/SP. Z O.O./gi, '').replace(/SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ/gi, '').replace(/SP. Z 0.0./gi, '').replace(/POLSKA/gi, '').replace(/\'/gm, '').replace(/\(/gm, '').replace(/\)/gm, '').replace(' - ', '-')
                                }
                            } else {
                                accountStatus = 'not-found'
                            }
                        }
                    } else {
                        accountStatus = 'not-provided'
                    }
                } else {
                    accountStatus = ''
                    status = 'Not found'
                }

                if (!apiRespons) {
                    status = (doc.counterparty && doc.counterparty !== "PL") || !doc.counterpartyVatId ? `Doesn't concern` : 'Not found'
                } else if (apiRespons.statusVat === 'Czynny') {
                    status = 'Active'
                } else if (apiRespons.statusVat === 'Niezarejestrowany') {
                    status = 'Unregistered'
                } else if (apiRespons.statusVat === 'Zwolniony') {
                    status = 'Released'
                } else if (!doc.counterpartyVatId) {
                    status = 'TaxID was not provided'
                } else {
                    status = 'Not found'
                }

                docs[i].accountStatus = accountStatus
                docs[i].status = status
                docs[i].otherCompany = otherCompany
            }
        }
    }

    callback([...docs])
}