const devCostsUsers = [
    //Proxy
    2,      // Patryk Podsiadły
    3,      // Krzysztof Młynarz
    62,     // Konrad Szałapak
    53,     // acc@proxypartners.pl
    129,    // Kinga Misiak
    225,    // Marzena Walkiewicz
    240,    // Renata Maślach
    299,    // Katarzyna Wójcik
    460,    // Aleksandra Smyka
    587,    // Beata Olejnik
    688,    // Magdalena Wisz
    773,    // Magdalena Maślach

    //PM
    51,     // Szymon Ostrowski
    101,    // Krzysztof Jermak
    626,    // Leszek Liniecki

    //JPM
    235,    // Błażej Ciecieręga
    536,    // Paulina Osińska
    730,    // Bartłomiej Koszewski
    753,    // Rafał Starzewski

    //Inni
    329,    // Karolina Kostrzewa
    547,    // Magdalena Kalisiak
]

const PMs = [
    //PM
    51,     // Szymon Ostrowski
    101,    // Krzysztof Jermak
    626,    // Leszek Liniecki

    //JPM
    235,    // Błażej Ciecieręga
    536,    // Paulina Osińska
    730,    // Bartłomiej Koszewski
    753,    // Rafał Starzewski
]

module.exports = {
    devCostsUsers,
    PMs
};