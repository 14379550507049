const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const phoneRegex = /^(\+48)?\s?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})$/
const shortnameRegex = /[^A-Za-z0-9\-\_]+/
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\?!@#\$%\^&\*\\\/\-_\}\{\(\)\[\]\'\"\|\.\,\;\:\<\>\+\=\&\~\`]{8,}$/

module.exports = {
    emailRegex,
    phoneRegex,
    shortnameRegex,
    passwordRegex
};