import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import isIterable from "../../helpers/isIterable"


export class MoveToOtherProjectPopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            companyId: -1,
            newProjectId: -1,
            newProjectCode: '',
            showProjectList: false
        }
    }

    componentDidMount() {
        const { userProjects, currentProjectId } = this.props
        if (currentProjectId) {
            var docProject = userProjects.find(p => p.id === currentProjectId)
            if (docProject) {
                this.setState({
                    companyId: docProject.company_id
                })
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { companyId } = this.state
        const { userProjects, currentProjectId } = this.props
        if (companyId === -1 && userProjects) {
            var docProject = userProjects.find(p => p.id === currentProjectId)
            if (docProject) {
                this.setState({
                    companyId: docProject.company_id
                })
            }
        }
    }

    toggleProjectsList = () => {
        this.setState({
            showProjectList: !this.state.showProjectList
        })
    }

    selectNewProject = (projectId, code) => {
        this.setState({
            newProjectId: projectId,
            newProjectCode: code
        })
    }

    render() {
        const { companyId, newProjectId, newProjectCode, showProjectList } = this.state
        const { userProjects, currentProjectId } = this.props
        return (
            <div className="delete-invoice-popup popup">
                <div className="popup-card">
                    <div className="header">{this.context.t('Select the binder where you want to move this document')}</div>

                    <div className="projects-list" onClick={() => this.toggleProjectsList()}>
                        <div className="label">{this.context.t('New binder')}</div>
                        <div className="selected-project">{newProjectCode}</div>
                        <div className={`arrow ${showProjectList ? 'extended' : ''}`}></div>

                        {
                            showProjectList ? (
                                <ul>
                                    <SimpleBar style={{ width: '100%', maxHeight: '40vh' }}>
                                        {
                                            userProjects.map(p => {
                                                if (p.id !== currentProjectId && p.company_id === companyId) {
                                                    return (
                                                        <li key={p.id} className={`${newProjectId === p.id ? 'selected' : ''}`} onClick={() => this.selectNewProject(p.id, p.code)}>{p.code}</li>
                                                    )
                                                } else return null
                                            })
                                        }
                                    </SimpleBar>
                                </ul>
                            ) : null
                        }

                    </div>

                    <div className="action-buttons">
                        <div className="btn" onClick={e => this.props.cancel()}>{this.context.t('Cancel')}</div>
                        <div className={`btn delete ${newProjectId === -1 ? 'not-allowed' : ''}`} onClick={e => this.props.move(newProjectId)}>{this.context.t('Move')}</div>
                    </div>
                </div>
            </div>
        )
    }
}

MoveToOtherProjectPopup.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
    userProjects: isIterable(state.User.userProjects) ? [...state.User.userProjects] : [],
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(MoveToOtherProjectPopup)