import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { EventActions } from '../actions/EventActions'

export class ChangeDocTypeModal extends Component {

    render() {
        return (
            <div className="delete-invoice-popup popup">
                <div className="popup-card">

                    <div className="header">{this.context.t('Are you sure you want to change this documents type?')}</div>
                    <div className="text">{this.context.t('This action will delete this file and add it back to the files that are awaiting approval')}</div>

                    <div className="action-buttons">
                        <div className="btn" onClick={e => this.props.cancel()}>{this.context.t('Cancel')}</div>
                        <div className="btn delete" onClick={e => this.props.change()}>{this.context.t('Confirm')}</div>
                    </div>
                </div>
            </div>
        )
    }
}

ChangeDocTypeModal.contextTypes = {
	t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeDocTypeModal)