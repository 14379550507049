import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Spinner } from 'react-activity'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { FileActions } from '../../actions/FileActions'
import { ChatActions } from '../../actions/ChatAction'
import Dropzone from 'react-dropzone'
import Chat from "../Chat/ChatClassComponent";
import ChatToogleButton from "../Chat/ChatToogleButton"

export class LinkedDocs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      linkedDocsHeights: {
        costs: 'calc(16.7vh - 63.5px)',
        exepenses: 'calc(16.7vh - 63.5px)',
        incomes: 'calc(16.7vh - 63.5px)',
        agreements: 'calc(16.7vh - 63.5px)',
        correspondence: 'calc(16.7vh - 63.5px)',
        otherDocs: 'calc(16.7vh - 63.5px)'
      }
    }
  }

  componentDidMount() {
    if (!this.props.counterpartyLinkedDocs.loading) {
      this.calcMaxHeightForLinkedDocs()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.counterpartyLinkedDocs.loading && !this.props.counterpartyLinkedDocs.loading) {
      this.calcMaxHeightForLinkedDocs()
    }

    if (prevProps.pdfPreviewData) {
      const { pdfPreviewData } = this.props
      if (prevProps.pdfPreviewData.id !== pdfPreviewData.id) this.props.openChatOnFilePreviewMount()
    }
  }

  getMaxHeightForLinkedDocs = type => {
    switch (type) {
      case 'COSTS':
        return this.state.linkedDocsHeights.costs
      case 'EXPENSES':
        return this.state.linkedDocsHeights.exepenses
      case 'INCOMES':
        return this.state.linkedDocsHeights.incomes
      case 'AGREEMENTS':
        return this.state.linkedDocsHeights.agreements
      case 'CORRESPONDENCE':
        return this.state.linkedDocsHeights.correspondence
      case 'OTHER':
        return this.state.linkedDocsHeights.otherDocs
      default:
        return 'calc(20vh - 68px)'
    }
  }

  downloadLinkedFile = doc => {
    this.props.setCursorWait(true)

    this.props.downloadFile(doc.file.id, doc.file.project_id, doc.file.name, () => {
      this.props.setCursorWait(false)
    })
  }

  openSideBySidePreview = doc => {
    //this.props.openFile(doc.file.id, doc.file.project_id)
    this.props.downloadFileForSideBySidePreview(doc.file.id, doc.file.project_id, doc.file.name)
  }

  calcMaxHeightForLinkedDocs = () => {
    var availableHeight = window.innerHeight - 346
    var docTypesCount = 5
    const { counterpartyLinkedDocs } = this.props

    var docsSortedByCount = [
      {
        type: 'COSTS',
        count: counterpartyLinkedDocs.costs ? counterpartyLinkedDocs.costs.length : 0
      },
      {
        type: 'EXPENSES',
        count: counterpartyLinkedDocs.expenses ? counterpartyLinkedDocs.expenses.length : 0
      },
      {
        type: 'INCOMES',
        count: counterpartyLinkedDocs.incomes ? counterpartyLinkedDocs.incomes.length : 0
      },
      {
        type: 'AGREEMENTS',
        count: counterpartyLinkedDocs.agreements ? counterpartyLinkedDocs.agreements.length : 0
      },
      {
        type: 'CORRESPONDENCE',
        count: counterpartyLinkedDocs.correspondence ? counterpartyLinkedDocs.correspondence.length : 0
      },
      {
        type: 'OTHER',
        count: counterpartyLinkedDocs.otherDocs ? counterpartyLinkedDocs.otherDocs.length : 0
      }
    ]

    docsSortedByCount = docsSortedByCount.sort((a, b) => { return a.count - b.count })
    if (counterpartyLinkedDocs.expenses && counterpartyLinkedDocs.expenses.length) {
      availableHeight -= 25
      docTypesCount = 6
    } else {
      docsSortedByCount = docsSortedByCount.filter(d => d.type !== 'EXPENSES')
    }

    console.log(availableHeight, docTypesCount)
    var costsHeight, expensesHeight, incomesHeight, agreementsHeight, correspondenceHeight, otherHeight
    var maxHeight = availableHeight / docTypesCount
    console.log(maxHeight)
    docsSortedByCount.map((doc, index) => {
      maxHeight = availableHeight / (docTypesCount - index)
      console.log(maxHeight)
      if (doc.type === 'COSTS') {
        costsHeight = counterpartyLinkedDocs.costs ? Math.min(counterpartyLinkedDocs.costs.length * 22, maxHeight) : maxHeight
        availableHeight -= costsHeight
      } else if (doc.type === 'INCOMES') {
        incomesHeight = counterpartyLinkedDocs.incomes ? Math.min(counterpartyLinkedDocs.incomes.length * 22, maxHeight) : maxHeight
        availableHeight -= incomesHeight
      } else if (doc.type === 'AGREEMENTS') {
        agreementsHeight = counterpartyLinkedDocs.agreements ? Math.min(counterpartyLinkedDocs.agreements.length * 22, maxHeight) : maxHeight
        availableHeight -= agreementsHeight
      } else if (doc.type === 'CORRESPONDENCE') {
        correspondenceHeight = counterpartyLinkedDocs.correspondence ? Math.min(counterpartyLinkedDocs.correspondence.length * 22, maxHeight) : maxHeight
        availableHeight -= correspondenceHeight
      } else if (doc.type === 'OTHER') {
        otherHeight = counterpartyLinkedDocs.otherDocs ? Math.min(counterpartyLinkedDocs.otherDocs.length * 22, maxHeight) : maxHeight
        availableHeight -= otherHeight
      } else if (doc.type === 'EXPENSES') {
        expensesHeight = counterpartyLinkedDocs.expenses ? Math.min(counterpartyLinkedDocs.expenses.length * 22, maxHeight) : maxHeight
        availableHeight -= expensesHeight
      }
    })

    console.log(costsHeight, expensesHeight, incomesHeight, agreementsHeight, correspondenceHeight, otherHeight)

    this.setState({
      linkedDocsHeights: {
        costs: costsHeight,
        expenses: expensesHeight,
        incomes: incomesHeight,
        agreements: agreementsHeight,
        correspondence: correspondenceHeight,
        otherDocs: otherHeight
      }
    })
  }

  handleAttachmentDrop = (files, rejectedFiles) => {
    console.log('drop')
    if (rejectedFiles && rejectedFiles.length > 0) {
    } else {
      const { pdfPreviewData } = this.props
      files.forEach((currentFile, index) => {
        const myFileItemReader = new FileReader()
        myFileItemReader.addEventListener("load", () => {
          const myResult = {}
          myResult.base64 = myFileItemReader.result
          myResult.name = currentFile.name
          myResult.size = currentFile.size
          myResult.type = currentFile.type
          this.props.addFileAttachment(pdfPreviewData.id, myResult)
        }, false)
        myFileItemReader.readAsDataURL(currentFile)
      })
    }
  }

  getAttachment = (attachemnt, openPreviewIfPossible) => {
    if (['image/jpeg', 'image/png', 'application/pdf'].includes(attachemnt.type) && openPreviewIfPossible) {
      this.props.downloadAttachemntForSideBySidePreview(attachemnt.id, attachemnt.name)
    } else {
      this.props.setCursorWait(true)
      this.props.downloadAttachemnt(attachemnt.id, attachemnt.name, () => {
        this.props.setCursorWait(false)
      })
    }
  }

  toggleDeleteAttachmentPopup = attachment => {
    this.setState({
      attachmentToDelete: attachment.id,
      showDeleteAttachmentPopup: true
    })
  }

  deleteAttachment = () => {
    const { attachmentToDelete } = this.state
    this.props.setCursorWait(true)
    this.props.deleteAttachment(attachmentToDelete, () => {
      this.props.setCursorWait(false)
      this.setState({
        attachmentToDelete: -1,
        showDeleteAttachmentPopup: false
      })
    })
  }

  render() {
    const { showDeleteAttachmentPopup } = this.state
    const { pdfPreviewData, counterpartyLinkedDocs, showLinkedDoc, secondPreviewName } = this.props
    return (
      <div className={`linked-docs ${showLinkedDoc ? '' : 'linked-docs-hidden'}`}>
        {
          showDeleteAttachmentPopup ? (
            <div className="popup">
              <div className="popup-card">
                <h4>{this.context.t('Are you sure you want to delete this attachment?')}</h4>
                <div className="action-buttons">
                  <div className="btn" onClick={e => this.setState({ showDeleteAttachmentPopup: false })}>{this.context.t('Cancel')}</div>
                  <div className="btn delete" onClick={e => this.deleteAttachment()}>{this.context.t('Delete')}</div>
                </div>
              </div>
            </div>
          ) : null
        }

        {
          pdfPreviewData.status !== 'NOT_ACCAPTED' && counterpartyLinkedDocs.loading ? (
            <div className="activity-indicator">
              <Spinner size={30} speed={0.8} color={"#444444"} />
            </div>
          ) : (
            pdfPreviewData.status !== 'NOT_ACCAPTED' ? (
              <>
                <div className="title">{this.context.t('Documents linked with this counterparty:')}</div>
                {
                  counterpartyLinkedDocs.expenses && counterpartyLinkedDocs.expenses.length ? (
                    <div className="doc-type-section">
                      <div className="doc-type-header">
                        <h3>{this.context.t('Included expenses')}</h3>
                        <div className={`docs-count`}>{counterpartyLinkedDocs.expenses ? counterpartyLinkedDocs.expenses.length : ''}</div>
                        <div className={`extend-icon ${counterpartyLinkedDocs.expensesExtended ? 'extended' : ''}`} onClick={() => this.props.toggleLinkedDocs('EXPENSES')}></div>
                      </div>

                      {
                        counterpartyLinkedDocs.expensesExtended && counterpartyLinkedDocs.expenses && counterpartyLinkedDocs.expenses.length ? (
                          <div className="docs-container">
                            <SimpleBar style={{ width: '100%', maxWidth: '100%', maxHeight: this.getMaxHeightForLinkedDocs('EXPENSES'), transition: '.2s ease-in-out' }}>
                              {
                                counterpartyLinkedDocs.expenses.map(d => {
                                  return (
                                    <div className={`file ${secondPreviewName === d.file.name ? 'current' : ''}`}>
                                      <div className="name" onClick={() => this.openSideBySidePreview(d)}>
                                        <div className="icon"></div>
                                        {d.file.name}
                                      </div>
                                      <div className="download" onClick={() => this.downloadLinkedFile(d)}></div>
                                    </div>
                                  )
                                })
                              }
                            </SimpleBar>
                          </div>
                        ) : (
                          null
                        )
                      }
                    </div>
                  ) : null
                }
                <div className="doc-type-section">
                  <div className="doc-type-header">
                    <h3>{this.context.t('Costs')}</h3>
                    <div className={`docs-count`}>{counterpartyLinkedDocs.costs ? counterpartyLinkedDocs.costs.length : ''}</div>
                    <div className={`extend-icon ${counterpartyLinkedDocs.costsExtended ? 'extended' : ''}`} onClick={() => this.props.toggleLinkedDocs('COSTS')}></div>
                  </div>

                  {
                    counterpartyLinkedDocs.costsExtended && counterpartyLinkedDocs.costs && counterpartyLinkedDocs.costs.length ? (
                      <div className="docs-container">
                        <SimpleBar style={{ width: '100%', maxWidth: '100%', maxHeight: this.getMaxHeightForLinkedDocs('COSTS'), transition: '.2s ease-in-out' }}>
                          {
                            counterpartyLinkedDocs.costs.map(d => {
                              return (
                                <div className={`file ${secondPreviewName === d.file.name ? 'current' : ''}`}>
                                  <div className="name" onClick={() => this.openSideBySidePreview(d)}>
                                    <div className="icon"></div>
                                    {d.file.name}
                                  </div>
                                  <div className="download" onClick={() => this.downloadLinkedFile(d)}></div>
                                </div>
                              )
                            })
                          }
                        </SimpleBar>
                      </div>
                    ) : (
                      null
                    )
                  }
                </div>

                <div className="doc-type-section">
                  <div className="doc-type-header">
                    <h3>{this.context.t('Income')}</h3>
                    <div className={`docs-count`}>{counterpartyLinkedDocs.incomes ? counterpartyLinkedDocs.incomes.length : ''}</div>
                    <div className={`extend-icon ${counterpartyLinkedDocs.incomesExtended ? 'extended' : ''}`} onClick={() => this.props.toggleLinkedDocs('INCOMES')}></div>
                  </div>

                  {
                    counterpartyLinkedDocs.incomesExtended && counterpartyLinkedDocs.incomes && counterpartyLinkedDocs.incomes.length ? (
                      <div className="docs-container">
                        <SimpleBar style={{ width: '100%', maxWidth: '100%', maxHeight: this.getMaxHeightForLinkedDocs('INCOMES'), transition: '.2s ease-in-out' }}>
                          {
                            counterpartyLinkedDocs.incomes.map(d => {
                              return (
                                <div className={`file ${secondPreviewName === d.file.name ? 'current' : ''}`}>
                                  <div className="name" onClick={() => this.openSideBySidePreview(d)}>
                                    <div className="icon"></div>
                                    {d.file.name}
                                  </div>
                                  <div className="download" onClick={() => this.downloadLinkedFile(d)}></div>
                                </div>
                              )
                            })
                          }
                        </SimpleBar>
                      </div>
                    ) : (
                      null
                    )
                  }
                </div>

                <div className="doc-type-section">
                  <div className="doc-type-header">
                    <h3>{this.context.t('Agreements')}</h3>
                    <div className={`docs-count`}>{counterpartyLinkedDocs.agreements ? counterpartyLinkedDocs.agreements.length : ''}</div>
                    <div className={`extend-icon ${counterpartyLinkedDocs.agreementsExtended ? 'extended' : ''}`} onClick={() => this.props.toggleLinkedDocs('AGREEMENTS')}></div>
                  </div>

                  {
                    counterpartyLinkedDocs.agreementsExtended && counterpartyLinkedDocs.agreements && counterpartyLinkedDocs.agreements.length ? (
                      <div className="docs-container">
                        <SimpleBar style={{ width: '100%', maxWidth: '100%', maxHeight: this.getMaxHeightForLinkedDocs('AGREEMENTS'), transition: '.2s ease-in-out' }}>
                          {
                            counterpartyLinkedDocs.agreements.map(d => {
                              return (
                                <div className={`file ${secondPreviewName === d.file.name ? 'current' : ''}`}>
                                  <div className="name" onClick={() => this.openSideBySidePreview(d)}>
                                    <div className="icon"></div>
                                    {d.file.name}
                                  </div>
                                  <div className="download" onClick={() => this.downloadLinkedFile(d)}></div>
                                </div>
                              )
                            })
                          }
                        </SimpleBar>
                      </div>
                    ) : (
                      null
                    )
                  }
                </div>

                <div className="doc-type-section">
                  <div className="doc-type-header">
                    <h3>{this.context.t('Correspondence')}</h3>
                    <div className={`docs-count`}>{counterpartyLinkedDocs.correspondence ? counterpartyLinkedDocs.correspondence.length : ''}</div>
                    <div className={`extend-icon ${counterpartyLinkedDocs.correspondenceExtended ? 'extended' : ''}`} onClick={() => this.props.toggleLinkedDocs('CORRESPONCENCE')}></div>
                  </div>

                  {
                    counterpartyLinkedDocs.correspondenceExtended && counterpartyLinkedDocs.correspondence && counterpartyLinkedDocs.correspondence.length ? (
                      <div className="docs-container">
                        <SimpleBar style={{ width: '100%', maxWidth: '100%', maxHeight: this.getMaxHeightForLinkedDocs('CORRESPONDENCE'), transition: '.2s ease-in-out' }}>
                          {
                            counterpartyLinkedDocs.correspondence.map(d => {
                              return (
                                <div className={`file ${secondPreviewName === d.file.name ? 'current' : ''}`}>
                                  <div className="name" onClick={() => this.openSideBySidePreview(d)}>
                                    <div className="icon"></div>
                                    {d.file.name}
                                  </div>
                                  <div className="download" onClick={() => this.downloadLinkedFile(d)}></div>
                                </div>
                              )
                            })
                          }
                        </SimpleBar>
                      </div>
                    ) : (
                      null
                    )
                  }
                </div>

                <div className="doc-type-section">
                  <div className="doc-type-header">
                    <h3>{this.context.t('Other ')}</h3>
                    <div className={`docs-count`}>{counterpartyLinkedDocs.otherDocs ? counterpartyLinkedDocs.otherDocs.length : ''}</div>
                    <div className={`extend-icon ${counterpartyLinkedDocs.otherDocsExtended ? 'extended' : ''}`} onClick={() => this.props.toggleLinkedDocs('OTHER')}></div>
                  </div>

                  {
                    counterpartyLinkedDocs.otherDocsExtended && counterpartyLinkedDocs.otherDocs && counterpartyLinkedDocs.otherDocs.length ? (
                      <div className="docs-container">
                        <SimpleBar style={{ width: '100%', maxWidth: '100%', maxHeight: this.getMaxHeightForLinkedDocs('OTHER'), transition: '.2s ease-in-out' }}>
                          {
                            counterpartyLinkedDocs.otherDocs.map(d => {
                              return (
                                <div className={`file ${secondPreviewName === d.file.name ? 'current' : ''}`}>
                                  <div className="name" onClick={() => this.openSideBySidePreview(d)}>
                                    <div className="icon"></div>
                                    {d.file.name}
                                  </div>
                                  <div className="download" onClick={() => this.downloadLinkedFile(d)}></div>
                                </div>
                              )
                            })
                          }
                        </SimpleBar>
                      </div>
                    ) : (
                      null
                    )
                  }
                </div>

                <div className="doc-type-section">
                  <div className="doc-type-header">
                    <h3>{this.context.t('Attachments')}</h3>
                    <div className="add-attachment">
                      <Dropzone onDrop={(files, rejectedFiles) => this.handleAttachmentDrop(files, rejectedFiles)}>
                        {({ getRootProps, getInputProps, isDragActive }) => (
                          <div {...getRootProps()} className={`dropzone${isDragActive ? ' drop-active' : ''}`}>
                            <input {...getInputProps()} />
                          </div>
                        )}
                      </Dropzone>
                    </div>
                    <div className={`docs-count`}>{counterpartyLinkedDocs.attachedFiles ? counterpartyLinkedDocs.attachedFiles.length : ''}</div>
                    <div className={`extend-icon ${counterpartyLinkedDocs.attachmentsExtended ? 'extended' : ''}`} onClick={() => this.props.toggleLinkedDocs('ATTACHMENTS')}></div>
                  </div>

                  {
                    counterpartyLinkedDocs.attachmentsExtended && counterpartyLinkedDocs.attachedFiles && counterpartyLinkedDocs.attachedFiles.length ? (
                      <div className="docs-container">
                        <SimpleBar style={{ width: '100%', maxWidth: '100%', maxHeight: '63px', transition: '.2s ease-in-out' }}>
                          {
                            counterpartyLinkedDocs.attachedFiles.map(d => {
                              return (
                                <div className={`file ${secondPreviewName === d.name ? 'current' : ''}`}>
                                  <div className={`name ${!['image/jpeg', 'image/png', 'application/pdf'].includes(d.type) ? 'pointer' : ''}`} onClick={() => this.getAttachment(d, true)}>
                                    <div className="icon"></div>
                                    {d.name}
                                  </div>
                                  {
                                    d.processing ? (
                                      <div className="attachment-processing">P</div>
                                    ) : (
                                      <>
                                        <div className="download" onClick={() => this.getAttachment(d, false)}></div>
                                        <div className="delete" onClick={() => this.toggleDeleteAttachmentPopup(d)}></div>
                                      </>
                                    )
                                  }
                                </div>
                              )
                            })
                          }
                        </SimpleBar>
                      </div>
                    ) : (
                      null
                    )
                  }
                </div>
              </>
            ) : (
              <div className="no-linked-docs">
                <div className="image"></div>
                <h4>{this.context.t('The document has not been accepted yet, which is why there is no assigned counterparty and related documents')}</h4>
              </div>
            )
          )
        }

        <Chat
          fileId={this.props.pdfPreviewData.id}
          isWidget={true}
          isInPreview={true}
        />
        <ChatToogleButton />
      </div>
    )
  }
}

LinkedDocs.contextTypes = {
  t: PropTypes.func
}

const mapStateToProps = (state) => ({
  pdfPreviewData: state.File.pdfPreviewData,
  counterpartyLinkedDocs: state.File.counterpartyLinkedDocs
})

const mapDispatchToProps = {
  downloadFile: FileActions.downloadFile,
  downloadFileForSideBySidePreview: FileActions.downloadFileForSideBySidePreview,
  toggleLinkedDocs: FileActions.toggleLinkedDocs,
  addFileAttachment: FileActions.addFileAttachment,
  downloadAttachemntForSideBySidePreview: FileActions.downloadAttachemntForSideBySidePreview,
  downloadAttachemnt: FileActions.downloadAttachemnt,
  deleteAttachment: FileActions.deleteAttachment,
  openChatOnFilePreviewMount: ChatActions.openChatOnFilePreviewMount
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkedDocs)