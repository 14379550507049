import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize'
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import NewCounterpartyPopup from '../NewCounterpartyPopup'

import { FileActions } from '../../actions/FileActions'

export class PreviewCounterpartyEdit extends Component {

    constructor(props) {
        super(props)
        this.state = {
            filter: '',
            showCounterpartiesList: false,
            counterpartiesList: [],
            showNewCounterpartyPopup: false,
            editingOldCounterparty: false,
            counterpartyToEdit: {},
        }

        this.wrapperRef = React.createRef()
        this.counterpartyContainerRef = React.createRef()
    }

    componentDidMount() {
        const { currentCompany } = this.props
        document.addEventListener("mousedown", this.handleClick);
        if (currentCompany?.company) this.onCompannyChange()
    }

    componentDidUpdate(prevProps) {
        const { currentCompany } = this.props
        if (currentCompany && currentCompany.company_id && currentCompany !== prevProps.currentCompany) {
            if (currentCompany?.company) this.onCompannyChange()
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
    }

    handleClick = (event) => {
        const { showCounterpartiesList } = this.state

        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({
                showCounterpartiesList: false
            })
        } else if (showCounterpartiesList && this.counterpartyContainerRef && !this.counterpartyContainerRef.current.contains(event.target)) {
            this.setState({
                showCounterpartiesList: false
            })
        }
    }

    onCompannyChange = () => {
        const { currentCompany } = this.props
        this.setState({
            counterpartiesList: currentCompany?.company?.counterparties || [],
        })
    }

    foundAtLeastOneSimilarCounterparty = () => {
        const { filter } = this.state
        if (!filter) return true;
        const { counterpartiesList } = this.state;
        var counterpartiesCount = counterpartiesList.length;

        for (var i = 0; i < counterpartiesCount; i++) {
            if (filter.length === 1 && counterpartiesList[i].name && counterpartiesList[i].name.toLowerCase().startsWith(filter.toLowerCase())) {
                return true;
            } else if (filter.length > 1 && counterpartiesList[i].name && counterpartiesList[i].name.toLowerCase().includes(filter.toLowerCase())) {
                return true;
            }
        }

        return false;
    };

    checkIfCounterpartyNameIsSimilar = (name) => {
        const { filter } = this.state
        if (!filter) {
            return true;
        } else if (filter.length === 2 && name && name.toLowerCase().includes(filter.toLowerCase())) {
            return true;
        } else if (filter.length > 2 && name && name.toLowerCase().includes(filter.toLowerCase())) {
            return true;
        }

        return false;
    };

    onInputChange = e => {
        e.preventDefault()
        const { name, value } = e.target

        this.setState({ [name]: value })
    }

    setCounterpaty = counterparty => {
        if (this.checkIfCounterpartyIsComplete(counterparty)) {
            const { pdfPreviewData } = this.props
            this.props.editDocInput(counterparty.id, 'counterpartyId', pdfPreviewData.docType, pdfPreviewData.docId)
            this.props.editDocInput(counterparty.name, 'counterpartyName', pdfPreviewData.docType, pdfPreviewData.docId)
            this.props.editDocInput(counterparty.vat_id, 'counterpartyVatId', pdfPreviewData.docType, pdfPreviewData.docId)
            this.props.editDocInput(counterparty.address, 'counterpartyAddress', pdfPreviewData.docType, pdfPreviewData.docId)
            this.props.editDocInput(counterparty.country, 'counterpartyCountry', pdfPreviewData.docType, pdfPreviewData.docId)
            this.setState({ showCounterpartiesList: false })
        } else {
            this.setState({
                showNewCounterpartyPopup: true,
                editingOldCounterparty: true,
                counterpartyToEdit: counterparty
            })
        }
    }

    checkIfCounterpartyIsComplete = counterparty => {
        const { name, vat_id } = counterparty

        if (!name) return false
        if (!vat_id) return false

        return true
    }

    createNewCounterparty = () => {
        this.setState({
            showNewCounterpartyPopup: true,
            showCounterpartiesList: false,
        })
    }

    editExistingCounterparty = () => {
        const { counterpartiesList } = this.state
        const { pdfPreviewData } = this.props
        const counterpartyInstance = counterpartiesList.find(c => c.id === pdfPreviewData.counterpartyId)
        this.setState({
            showNewCounterpartyPopup: true,
            editingOldCounterparty: true,
            counterpartyToEdit: {
                id: pdfPreviewData.counterpartyId || counterpartyInstance.id,
                vat_id: pdfPreviewData.counterpartyVatId || counterpartyInstance.vat_id,
                name: pdfPreviewData.counterpartyName || counterpartyInstance.name,
                code: pdfPreviewData.counterpartyCode || counterpartyInstance.code,
                address: pdfPreviewData.counterpartyAddress || counterpartyInstance.address,
                country: counterpartyInstance.country,
                email: counterpartyInstance?.email && Array.isArray(counterpartyInstance?.email)? counterpartyInstance?.email.join(', ') : ''
            }
        })
    }

    closeEditCounterpartyPopup = () => {
        this.setState({
            showNewCounterpartyPopup: false,
            editingOldCounterparty: false,
            counterpartyToEdit: {},
            showCounterpartiesList: false,
        })
    }

    savedCounterpartyChangesChanges = newCounterparty => {
        const { pdfPreviewData } = this.props

        this.setState({
            showNewCounterpartyPopup: false,
            editingOldCounterparty: false,
            counterpartyToEdit: {},
            showCounterpartiesList: false,
        })

        this.props.editDocInput(newCounterparty.id, 'counterpartyId', pdfPreviewData.docType, pdfPreviewData.docId)
        this.props.editDocInput(newCounterparty.name, 'counterpartyName', pdfPreviewData.docType, pdfPreviewData.docId)
        this.props.editDocInput(newCounterparty.vat_id, 'counterpartyVatId', pdfPreviewData.docType, pdfPreviewData.docId)
        this.props.editDocInput(newCounterparty.address, 'counterpartyAddress', pdfPreviewData.docType, pdfPreviewData.docId)
        this.props.editDocInput(newCounterparty.country, 'counterpartyCountry', pdfPreviewData.docType, pdfPreviewData.docId)
    }

    toggleCounterpartiesList = newValue => {
        const { editMode } = this.props
        if (editMode) {
            this.setState({
                showCounterpartiesList: newValue
            })
        }
    }

    render() {
        const { pdfPreviewData, editMode, titleText, marginBottom } = this.props
        const {
            counterpartiesList, showCounterpartiesList,
            filter,
            showNewCounterpartyPopup, editingOldCounterparty, counterpartyToEdit
        } = this.state
        return (
            <div className={`counterparty-section ${marginBottom ? 'margin-bottom' : ''}`} ref={this.wrapperRef}>
                {
                    showNewCounterpartyPopup ? (
                        <NewCounterpartyPopup
                            editingExisting={editingOldCounterparty}
                            editedCounterparty={counterpartyToEdit}
                            savedChanges={this.savedCounterpartyChangesChanges}
                            createdNewItem={this.savedCounterpartyChangesChanges}
                            close={this.closeEditCounterpartyPopup}
                        />
                    ) : null
                }
                <h5>{this.context.t(titleText)}</h5>
                <div className="doc-prop" ref={this.counterpartyContainerRef}>
                    <span className="name" onClick={() => this.toggleCounterpartiesList(!showCounterpartiesList)}>{this.context.t('Name')}:</span>
                    <TextareaAutosize
                        readOnly={true}
                        name="counterparty"
                        id={`counterpartyInput-${pdfPreviewData.docId}`}
                        value={pdfPreviewData.counterpartyName}
                        onClick={() => this.toggleCounterpartiesList(!showCounterpartiesList)}
                    />
                    {
                        editMode ? (
                            <span className={`list-arrow ${showCounterpartiesList ? 'extended' : ''}`} onClick={() => this.toggleCounterpartiesList(!showCounterpartiesList)}></span>
                        ) : null
                    }
                    {
                        editMode && pdfPreviewData.counterpartyId > -1 ? (
                            <div className="edit-counterparty" onClick={() => this.editExistingCounterparty()}></div>
                        ) : null
                    }
                    {
                        showCounterpartiesList && counterpartiesList.length > 0 ? (
                            <div className="sugestions-list down">
                                <div className="filter-row">
                                    <input type="text" name="filter" value={filter} onChange={this.onInputChange} placeholder={this.context.t('Search')} autoFocus />
                                </div>
                                <ul>
                                    <li className="add-new" onClick={() => this.createNewCounterparty()}>
                                        {this.context.t('Create new counterparty')}
                                    </li>
                                    {
                                        this.foundAtLeastOneSimilarCounterparty() ? (
                                            <SimpleBar style={{ height: "auto", maxHeight: "300px", width: "100%" }}>
                                                {
                                                    counterpartiesList.map(counterparty => {
                                                        if (this.checkIfCounterpartyNameIsSimilar(counterparty.name)) {
                                                            return (
                                                                <li onClick={() => this.setCounterpaty(counterparty)}>
                                                                    {counterparty.name}
                                                                </li>
                                                            )
                                                        } else return null
                                                    })
                                                }
                                            </SimpleBar>
                                        ) : null
                                    }
                                </ul>
                            </div>
                        ) : null
                    }
                </div>
                <div className="doc-prop display-only">
                    <span className="name">{this.context.t('VatID')}:</span>
                    <TextareaAutosize
                        readOnly={true}
                        name="counterpartyVatId"
                        id={`counterpartyVatIdInput-${pdfPreviewData.docId}`}
                        value={pdfPreviewData.counterpartyVatId}
                    />
                </div>
                {
                    pdfPreviewData.currency && pdfPreviewData.currency !== 'PLN' ? (
                        <div className="doc-prop display-only">
                            <span className="name">{this.context.t('Address')}:</span>
                            <TextareaAutosize
                                readOnly={true}
                                name="counterpartyAddress"
                                id={`counterpartyAddressInput-${pdfPreviewData.docId}`}
                                value={pdfPreviewData.counterpartyAddress}
                            />
                        </div>
                    ) : (
                        null
                    )
                }

                {
                    pdfPreviewData.currency && pdfPreviewData.currency !== 'PLN' ? (
                        <div className="doc-prop display-only">
                            <span className="name">{this.context.t('Country')}:</span>
                            <TextareaAutosize
                                readOnly={true}
                                name="counterpartyCountry"
                                id={`counterpartyCountryInput-${pdfPreviewData.docId}`}
                                value={pdfPreviewData.counterpartyCountry}
                            />
                        </div>
                    ) : (
                        null
                    )
                }
            </div>
        )
    }
}


PreviewCounterpartyEdit.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
    currentCompany: state.Company.currentCompany,
    pdfPreviewData: state.File.pdfPreviewData,
    user: state.User.user,
})

const mapDispatchToProps = {
    editDocInput: FileActions.editDocInput,
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewCounterpartyEdit)
