import React from 'react';
import defaultProgilePic from '../../img/default-user-photo.svg'

const getFieldStyle = (type) => {
    const style = {
      border: '1px solid $color-transparent',
      boxSizing: 'border-box',
      maxWidth: '100%',
      width: '100%',
      height: '50px',
      marginBottom: '5px',
      borderRadius: '2px',
      webkitBoxShadow: '0px 3px 6px 0px rgba(19,37,65,0.1)',
      mozBoxShadow: '0px 3px 6px 0px rgba(19,37,65,0.1)',
      boxShadow: '0px 3px 6px 0px rgba(19,37,65,0.1)',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap'
    };

    const profilePicStyle = {
      flex: '0 0 50px',
      height: '100%',
      padding: '7px',
    }

    const profilePicDefaultStyle = {
      flex: '0 0 50px',
      height: '100%',
      padding: '7px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: '36px 36px',
      backgroundImage: `url(${defaultProgilePic})`,
    }

    const imgStyle = {
      width: '100%',
      height: 'auto',
      borderRadius: '50%'
    }

    const userInfoStyle = {
      height: '48px',
      flex: '1',
      position: 'relative',
      right: 'unset',
      top: 'unset',
      overflow: 'hidden',
      paddingRight: '7px',
    }

    const nameAndSurnameStyle = {
      height: '50%',
      display: 'flex',
      flexDirection: 'row',
      wordBreak: 'keep-all',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      color:'#2B2D31',
      fontSize: '11px',
      fontWeight: '600',
      alignItems: 'flex-end',
    }

    const emailStyle = {
      height: '50%',
      display: 'flex',
      flexDirection: 'row',
      wordBreak: 'keep-all',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      color: '#9099A5',
      fontSize: '10px',
      alignItems: 'flex-start',
    }

    if(type === 'MAIN') {
      return style;
    } else if(type === 'PROFILE_PIC') {
      return profilePicStyle
    } else if(type === 'PROFILE_PIC_DEFAULT') {
      return profilePicDefaultStyle
    } else if(type === 'IMG') {
      return imgStyle
    } else if(type === 'USER_INFO') {
      return userInfoStyle
    } else if(type === 'NAME_AND_SURNAME') {
      return nameAndSurnameStyle
    } else if(type === 'EMAIL') {
      return emailStyle
    } 
    
    
};

const DragPreview = ({ fields }) => {
  if(fields) {
    const filteredFields = fields.filter(function(value, index, arr){
      return value !== undefined
    })
    const rows = filteredFields.map(field => (
      <div style={getFieldStyle('MAIN')} key={field.id}>
        {
          field.profile_pic_link ? (
            <div style={getFieldStyle('PROFILE_PIC')}>
              <img style={getFieldStyle('IMG')} src={field.profile_pic_link} alt="Profile picture"/>
            </div>
          ) : (
            <div style={getFieldStyle('PROFILE_PIC_DEFAULT')}></div>
          )
        }
        <div style={getFieldStyle('USER_INFO')}>
          {
              field.first_name && field.last_name ? (
                  <>
                      <div style={getFieldStyle('NAME_AND_SURNAME')}>{`${field.first_name} ${field.last_name}`}</div>
                      <div style={getFieldStyle('EMAIL')}>{`${field.email}`}</div>
                  </>
              ) : (
                  <div style={getFieldStyle('NAME_AND_SURNAME')}>{`${field.email}`}</div>
              )
          }   
        </div>
      </div>));
    return (<div>{rows}</div>);
  } else {
    return (<div></div>)
  }
  
};

export default DragPreview;

  