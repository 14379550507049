import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { EventActions } from '../actions/EventActions'

export class DeleteInvoiceModal extends Component {

    getHeader = () => {
        const { inDocflow, moveToTrash } = this.props
        if (inDocflow) {
            return (
                <>
                    <div className="header">{this.context.t('Are you sure you want to return this document to inbox?')}</div>
                    <div className="text">{this.context.t('The document will be returned to the inbox and will lose the acceptances received in the current docflow.')}</div>
                </>
            )
        } else if (moveToTrash) {
            return (
                <>
                    <div className="header">{this.context.t('Are you sure you want to move this document to trash?')}</div>
                    <div className="text">{this.context.t('You can restore documents from the trash can for 45 days from the time you moved them there. After this period, the files are permanently deleted without the possibility of their recovery')}</div>
                </>
            )
        } else {
            return (
                <div className="header">{this.context.t('Are you sure you want to permanently delete this document?')}</div>
            )
        }
    }

    getButtonText = () => {
        const { inDocflow, moveToTrash } = this.props
        if (inDocflow) {
            return this.context.t('Return document to inbox')
        } else if (moveToTrash) {
            return this.context.t('Move to trash')
        } else {
            return this.context.t('Delete')
        }
    }

    render() {
        return (
            <div className="delete-invoice-popup popup">
                <div className="popup-card">
                    {this.getHeader()}

                    <div className="action-buttons">
                        <div className="btn" onClick={e => this.props.cancel()}>{this.context.t('Cancel')}</div>
                        <div className="btn delete" onClick={e => this.props.delete()}>{this.getButtonText()}</div>
                    </div>
                </div>
            </div >
        )
    }
}

DeleteInvoiceModal.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteInvoiceModal)