import myFetch from "../helpers/fetch"

import { AlertActions } from './AlertActions'
import { CompanyConst } from "../reducers/CompanyReducer"
import { ReportsConst } from "../reducers/ReportsReducer"
import { EventConst } from "../reducers/EventReducer"
import { FileConst } from "../reducers/FileReducer"
import { UserConst } from "../reducers/UserReducer"

export const KsefActions = {
    saveKsefIntegration,
    getCurrentKsefScope,
    updateIntegration,
    deleteIntegration,
}

function saveKsefIntegration(companyId, data, cb) {
    return (dispatch) => {
        myFetch(`/company-ksef/add`, {
            method: "post", body: {
                companyId: companyId,
                data: data
            }
        }).then((res) => {
            cb(res.success)

            if (res.success) {
                dispatch({ type: CompanyConst.ADD_KSEF_INTEGRATION, companyId: companyId, integrationId: res.integrationId });
            }
        }).catch((err) => {
            handleError(err, dispatch, `/company-ksef/add`);
        });
    };
}

function getCurrentKsefScope(ksefId, companyId, cb) {
    return (dispatch) => {
        myFetch(`/company-ksef/get/${ksefId}/${companyId}`, {
            method: "get"
        }).then((res) => {
            if (res.success) {
                cb(res.integration)
            } else {
                cb(null)
            }
        }).catch((err) => {
            cb(null)
            handleError(err, dispatch, `/company-ksef/get/${ksefId}/${companyId}`);
        });
    };
}

function updateIntegration(ksefId, companyId, data) {
    return (dispatch) => {
        myFetch(`/company-ksef/update`, {
            method: "put", body: {
                ksefId: ksefId,
                companyId: companyId,
                data: data
            }
        }).then((res) => {
        }).catch((err) => {
            handleError(err, dispatch, `/company-ksef/update`);
        });
    };
}

function deleteIntegration(ksefId, companyId, cb) {
    return (dispatch) => {
        myFetch(`/company-ksef/delete`, {
            method: "delete", body: {
                ksefId: ksefId,
                companyId: companyId
            }
        }).then((res) => {
            cb(res.success)

            if (res.success) {
                dispatch({ type: CompanyConst.DELETE_KSEF_INTEGRATION, companyId: companyId });
            }
        }).catch((err) => {
            handleError(err, dispatch, `/company-ksef/delete`);
        });
    };
}

function handleError(error, dispatch, path) {
    if (error?.code === 'AUTHORIZATION_REQUIRED' && localStorage.getItem('dashboardProject')) {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        dispatch({ type: UserConst.LOGGED_FALSE })
        dispatch({ type: UserConst.LOGOUT })
        dispatch({ type: EventConst.LOGOUT })
        dispatch({ type: CompanyConst.LOGOUT })
        dispatch({ type: ReportsConst.LOGOUT })
        dispatch({ type: FileConst.LOGOUT })
        dispatch(AlertActions.info("You have been logged out.", 5000))
    } else {
        dispatch(AlertActions.setError(error, path));
        dispatch(AlertActions.danger("There was an internal server error while procesing request. Try again later."));
    }
}