import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import TextareaAutosize from 'react-textarea-autosize'

import '../../../css/OcrPage.css'
import { CompanyActions } from '../../../actions/CompanyActions';

export class OcrPageWaproNettoAccounts extends Component {
	constructor(props) {
		super(props)
		this.state = {
			list: [],
			sendRequest: false,
			filter: '',
			showList: false
		}

		this.wrapperRef = React.createRef();
	}

	componentDidMount() {
		document.addEventListener("mousedown", this.handleClick);
		window.addEventListener("keydown", this.escFunction, false)
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClick);
		window.removeEventListener("keydown", this.escFunction, false)
	}

	escFunction = e => {
		if (e.keyCode === 27) {
			this.handleClick(e)
		}
	}

	handleClick = (e) => {
		if (e.keyCode === 27 || (this.wrapperRef && !this.wrapperRef.current.contains(e.target))) {
			this.setState({
				showRegistersList: false,
				showRatesList: false
			})
		} else {
			if (e.keyCode === 27 || (this.state.showRegistersList && !this.hasAncestor(e.target, `registersListContainer-${this.state.registersListIndex}`))) {
				this.setState({
					showRegistersList: false
				})
			}

			if (e.keyCode === 27 || (this.state.showRatesList && !this.hasAncestor(e.target, `ratesListContainer-${this.state.ratesListIndex}`))) {
				this.setState({
					showRatesList: false
				})
			}
		}
	}

	hasAncestor = (element, id) => {
		while (element) {
			if (element.id === id) return true

			element = element.parentNode
		}

		return false
	}

	onInputChange = e => {
		e.preventDefault()
		const { name, value } = e.target

		this.setState({ [name]: value })
	}

	checkIfRegisterRowMatchesFilter = row => {
		const { filter } = this.state

		var show = false

		if (filter && row.rNazwa) {
			if (row.rNazwa.toLowerCase().includes(filter.toLocaleLowerCase())) show = true
		} else {
			show = true
		}

		return show
	}

	checkIfRateRowMatchesFilter = row => {
		const { filter } = this.state

		var show = false

		if (filter && row.nazwa && row.stawka) {
			if (row.nazwa.toLowerCase().includes(filter.toLocaleLowerCase())) show = true
			else if (row.stawka.includes(filter.toLocaleLowerCase())) show = true
		} else {
			show = true
		}

		return show
	}

	toggleRegistersList = (show, index) => {
		const { readOnly } = this.props
		if (!readOnly) {
			this.setState({
				showRegistersList: show,
				registersListIndex: index
			})
		}
	}

	selectRegister = (register, index) => {
		var symfoniaRegisters = [...this.props.symfoniaRegisters]
		const { ratesList } = this.props

		console.log(symfoniaRegisters)
		console.log(index)
		console.log(symfoniaRegisters[index])

		symfoniaRegisters[index].register = register

		this.props.editSymfoniaRegisters(symfoniaRegisters)

		var rate = ratesList.find(r => parseInt(r.stawka) === parseInt(register.defStawka))
		if (rate) {
			symfoniaRegisters[index].vatRate = rate
			this.props.editSymfoniaRegisters(symfoniaRegisters)
		}
		this.setState({
			showRegistersList: false,
			registersListIndex: -1
		})
	}

	toggleRatesList = (show, index) => {
		const { readOnly } = this.props
		if (!readOnly) {
			this.setState({
				showRatesList: show,
				ratesListIndex: index
			})
		}
	}

	selectVatRate = (rate, index) => {
		var symfoniaRegisters = [...this.props.symfoniaRegisters]

		symfoniaRegisters[index].vatRate = rate

		this.props.editSymfoniaRegisters(symfoniaRegisters)

		this.setState({
			showRatesList: false,
			ratesListIndex: -1
		})
	}

	onAmountChange = (e, type, index) => {
		var symfoniaRegisters = [...this.props.symfoniaRegisters]

		if (type === 'NET') {
			symfoniaRegisters[index].netAmount = e.target.value
		} else if (type === 'VAT') {
			symfoniaRegisters[index].vatAmount = e.target.value
		} else if (type === 'GROSS') {
			symfoniaRegisters[index].grossAmount = e.target.value
		}

		this.props.editSymfoniaRegisters(symfoniaRegisters)
	}

	netBlur = (index) => {
		var symfoniaRegisters = [...this.props.symfoniaRegisters]

		if (symfoniaRegisters[index].vatRate && symfoniaRegisters[index].vatRate.stawka && symfoniaRegisters[index].netAmount) {
			var netAmount = parseFloat(symfoniaRegisters[index].netAmount)
			var vatAmount = 0, grossAmount = 0, rate = parseFloat(symfoniaRegisters[index].vatRate.stawka)
			if (rate > 0) {
				vatAmount = parseFloat(netAmount * rate / 100).toFixed(2)
				grossAmount = parseFloat(netAmount * (100 + rate) / 100).toFixed(2)
			} else if (rate <= 0) {
				vatAmount = "0.00"
				grossAmount = parseFloat(netAmount).toFixed(2)
			}

			symfoniaRegisters[index].netAmount = parseFloat(netAmount).toFixed(2)
			symfoniaRegisters[index].vatAmount = vatAmount
			symfoniaRegisters[index].grossAmount = grossAmount

			this.props.editSymfoniaRegisters(symfoniaRegisters)
		}
	}

	addRegister = () => {
		const { readOnly } = this.props
		if (!readOnly) {
			var symfoniaRegisters = [...this.props.symfoniaRegisters]
			symfoniaRegisters.push({
				register: { rNazna: '' },
				vatRate: { stawka: '', nazwa: '' },
				netAmount: '',
				vatAmount: '',
				grossAmount: ''
			})

			this.props.editSymfoniaRegisters(symfoniaRegisters)
		}
	}

	deleteRegister = (index) => {
		const { readOnly } = this.props
		if (!readOnly) {
			var symfoniaRegisters = [...this.props.symfoniaRegisters]
			symfoniaRegisters.splice(index, 1)

			this.props.editSymfoniaRegisters(symfoniaRegisters)
		}
	}

	render() {
		const { symfoniaRegisters, registersList, ratesList, readOnly } = this.props
		const { filter, showRegistersList, registersListIndex, showRatesList, ratesListIndex } = this.state

		console.log(symfoniaRegisters)
		return (
			<div className={`ocr-data symfonia-registers`} id="wapro-fakir-net-accounts-container" ref={this.wrapperRef}>
				<h5>{this.context.t('VAT registers')}:</h5>
				<div className={`add-register ${readOnly ? 'read-only' : ''}`} onClick={() => this.addRegister()}>{this.context.t('Add register')}</div>
				{
					symfoniaRegisters.map((r, i) => {
						return (
							<>
								{
									symfoniaRegisters.length > 1 ? (
										<div className={`register-title ${i > 0 ? 'mt' : ''}`}>
											<h6>{r.register.rNazwa}</h6>
											<div className={`delete-register ${readOnly ? 'read-only' : ''}`} onClick={() => this.deleteRegister(i)}>{this.context.t('Delete register')}</div>
										</div>
									) : null
								}

								<div className="form-group" id={`registersListContainer-${i}`}>
									{
										showRegistersList && registersListIndex === i ? (
											<div className="tags-list">
												<div className="header">
													{this.context.t('Available VAT registers:')}
												</div>
												<div className="filter-row">
													<input type="text" name="filter" value={filter} onChange={this.onInputChange} placeholder={this.context.t('Search')} autoFocus />
												</div>
												<SimpleBar style={{ height: 'auto', maxHeight: '300px', width: '100%' }}>
													<ul>
														{
															registersList.map((t, j) => {
																if (this.checkIfRegisterRowMatchesFilter(t)) {
																	return (
																		<li className={`tag-item ${r.register.rNazwa === t.rNazwa ? 'selected' : ''}`} onClick={() => this.selectRegister(t, i)} key={`register-${j}`}>
																			{`${t.rNazwa}`}
																		</li>
																	)
																} else {
																	return null
																}
															})
														}
													</ul>
												</SimpleBar>
											</div>
										) : (
											null
										)
									}
									<label>{this.context.t('Register name')}</label>
									<input
										type="text"
										className={`tags-container ${readOnly ? 'read-only' : ''}`}
										value={r.register.rNazwa}
										readOnly={true}
										name='fakir-logs'
										onFocus={() => this.toggleRegistersList(true, i)}
									/>
								</div>

								<div className="form-group" id={`ratesListContainer-${i}`}>
									{
										showRatesList && ratesListIndex === i ? (
											<div className="tags-list">
												<div className="header">
													{this.context.t('Available VAT rates:')}
												</div>
												<div className="filter-row">
													<input type="text" name="filter" value={filter} onChange={this.onInputChange} placeholder={this.context.t('Search')} autoFocus />
												</div>
												<SimpleBar style={{ height: 'auto', maxHeight: '300px', width: '100%' }}>
													<ul>
														{
															ratesList.map((t, j) => {
																if (this.checkIfRateRowMatchesFilter(t)) {
																	return (
																		<li className={`tag-item ${r.vatRate.nazwa === t.nazwa ? 'selected' : ''}`} onClick={() => this.selectVatRate(t, i)} key={`vat-rate-${j}`}>
																			{`${parseInt(t.stawka)}% - ${t.nazwa}`}
																		</li>
																	)
																} else {
																	return null
																}
															})
														}
													</ul>
												</SimpleBar>
											</div>
										) : (
											null
										)
									}
									<label>{this.context.t('VAT rate')}</label>
									<input
										type="text"
										className={`tags-container ${readOnly ? 'read-only' : ''}`}
										value={r.vatRate.nazwa ? `${parseInt(r.vatRate.stawka)}% - ${r.vatRate.nazwa}` : ''}
										name='fakir-logs'
										readOnly={true}
										onFocus={() => this.toggleRatesList(true, i)}
									/>
								</div>

								<div className="form-group amount">
									<label>{this.context.t('Net amount')}</label>
									<input
										type="text"
										className={`tags-container ${readOnly ? 'read-only' : ''}`}
										value={r.netAmount}
										name={`net-amount-${i}`}
										onChange={e => this.onAmountChange(e, 'NET', i)}
										onBlur={e => this.netBlur(i)}
										readOnly={readOnly || false}
									/>
								</div>

								<div className="form-group amount">
									<label>{this.context.t('VAT amount')}</label>
									<input
										type="text"
										className={`tags-container ${readOnly ? 'read-only' : ''}`}
										value={r.vatAmount}
										name={`vat-amount-${i}`}
										onChange={e => this.onAmountChange(e, 'VAT', i)}
										readOnly={readOnly || false}
									/>
								</div>

								<div className="form-group amount">
									<label>{this.context.t('Gross amount')}</label>
									<input
										type="text"
										className={`tags-container ${readOnly ? 'read-only' : ''}`}
										value={r.grossAmount}
										name={`gross-amount-${i}`}
										onChange={e => this.onAmountChange(e, 'GROSS', i)}
										readOnly={readOnly || false}
									/>
								</div>
							</>
						)
					})
				}


			</div>
		)
	}
}

OcrPageWaproNettoAccounts.contextTypes = {
	t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
	getCompanyWaproNetAccounts: CompanyActions.getCompanyWaproNetAccounts
}

export default connect(mapStateToProps, mapDispatchToProps)(OcrPageWaproNettoAccounts)