import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Bounce } from 'react-activity'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { reverse } from 'named-urls'

import { FileActions } from '../../actions/FileActions'
import { AlertActions } from '../../actions/AlertActions'
import { TransfersActions } from '../../actions/TransfersActions'
import { DocflowActions } from '../../actions/DocflowActions'

import DocflowInfoModal from '../DocflowComponents/DocflowInfoModal'
import PdfPreview from '../PdfPreview'
import TransferPreview from '../TransferPreview'
import Calendar from '../Calendar'

import arrayObjectIndexOf from "../../helpers/indexOfObject"
import isIterable from "../../helpers/isIterable"
import routes from "../../helpers/routes"

import { AVAILABLE_STAGES, AVAILABLE_EVENTS, AVAILABLE_DOC_TYPES, AVAILABLE_DEDLINES } from '../../helpers/docflowHelpers'

import '../../css/Docflow.css'

export class DocflowFilesTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showOnlyDropzoneDocs: false,
            showOnlyDocToAccept: false,
            showOnlyDocsToPay: false,
            showOnlyDocsWithUnreadMessages: false,
            searchText: '',
            docTypeName: '',
            docTypeId: '',
            showDocTypesList: false,
            dueDate: null,
            showDueDateCalendar: false,
            docflowStageId: '',
            docflowStageName: '',
            showDocflowStagesList: false,
            selectedUserId: -1,
            selectedUserName: 'Everyone',
            availableUsers: [],
            showUsersList: false,
            showMine: false,

            fileToGenerateDocflowStepTooltip: -1,
            docflowStepTooltipX: -1000,
            docflowStepTooltipY: -1000
        }

        this.linkRef = React.createRef()
        this.tableWrapperRef = React.createRef()
        this.docTypesListWrapperRef = React.createRef()
        this.dueDateCalendarWrapperRef = React.createRef()
        this.docflowStagesListWrapperRef = React.createRef()
        this.usersListWrapperRef = React.createRef()

        this.tableHeaderRef = React.createRef()
    }

    componentDidMount() {
        this.clearFilters()

        const { currentProject } = this.props
        if (currentProject && currentProject.id) {
            this.props.getProjectMembersForDocflow(currentProject.id, members => {
                if (members) {
                    this.setState({
                        availableUsers: members
                    })
                }
            })
        }

        document.addEventListener("mousedown", this.handleClick);
    }

    componentDidUpdate(prevProps) {
        const { currentProject } = this.props
        if (currentProject && currentProject.id && currentProject.id !== prevProps?.currentProject?.id) {
            this.props.getProjectMembersForDocflow(currentProject.id, members => {
                if (members) {
                    this.setState({
                        availableUsers: members
                    })
                }
            })
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
    }

    handleClick = (event) => {
        const { showDocTypesList, showDueDateCalendar, showDocflowStagesList, showUsersList } = this.state
        if (this.tableWrapperRef && !this.tableWrapperRef.current?.contains(event.target)) {
            this.setState({
                showDocTypesList: false,
                showDueDateCalendar: false,
                showDocflowStagesList: false,
                showUsersList: false,
            })
        } else if (showDocTypesList && this.docTypesListWrapperRef && !this.docTypesListWrapperRef.current.contains(event.target)) {
            this.setState({
                showDocTypesList: false
            })
        } else if (showDueDateCalendar && this.dueDateCalendarWrapperRef && !this.dueDateCalendarWrapperRef.current.contains(event.target)) {
            this.setState({
                showDueDateCalendar: false
            })
        } else if (showDocflowStagesList && this.docflowStagesListWrapperRef && !this.docflowStagesListWrapperRef.current.contains(event.target)) {
            this.setState({
                showDocflowStagesList: false
            })
        } else if (showUsersList && this.usersListWrapperRef && !this.usersListWrapperRef.current.contains(event.target)) {
            this.setState({
                showUsersList: false
            })
        }
    };

    clearFilters = () => {
        this.setState({
            showOnlyDropzoneDocs: false,
            showOnlyDocToAccept: false,
            showOnlyDocsToPay: false,
            showOnlyDocsWithUnreadMessages: false,
            searchText: '',
            docTypeId: 'ALL',
            docTypeName: this.context.t('All'),
            showDocTypesList: false,
            dueDate: null,
            showDueDateCalendar: false,
            docflowStageId: 'ALL',
            docflowStageName: this.context.t('All '),
            showDocflowStagesList: false,
            selectedUserId: -1,
            selectedUserName: this.context.t('Everyone'),
            showUsersList: false,
            showMine: false,
        })
    }

    onInputChange = e => {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    getSortClass = (projectIndex, sortField) => {
        const { filesInDocflow } = this.props

        if (projectIndex === -1 || filesInDocflow[projectIndex]?.files?.length === 0) {
            return ''
        } else {
            if (filesInDocflow[projectIndex].sortField === sortField) {
                if (filesInDocflow[projectIndex].sortOrder === "asc")
                    return "icon-sort down"
                return "icon-sort up"
            }
            return "icon-sort"
        }
    }

    rederDocflowDocTypes = (docTypes, deleted) => {
        const { language } = this.props
        var elementsToReturn = []
        docTypes.forEach(type => {
            let typeShortName = ''
            if (['COSTS', 'EXPENSES_REPORT'].includes(type)) typeShortName = language === 'en' ? 'C' : 'K'
            else if (type === 'EXPENSES') typeShortName = language === 'en' ? 'E' : 'W'
            else if (type === 'PAYMENT_ORDER') typeShortName = language === 'en' ? 'PO' : 'ZP'
            else if (type === 'SALE_INVOICE') typeShortName = language === 'en' ? 'SI' : 'DP'
            else if (type === 'AGREEMENT') typeShortName = language === 'en' ? 'A' : 'U'
            else if (type === 'CORRESPONDENCE') typeShortName = language === 'en' ? 'CO' : 'KO'
            else if (type === 'CORPORATE') typeShortName = language === 'en' ? 'CR' : 'KR'
            else if (type === 'OTHER') typeShortName = language === 'en' ? 'O' : 'I'
            else if (type === 'WAREHOUSE') typeShortName = language === 'en' ? 'W' : 'DM'
            else if (type === 'PROTOCOL') typeShortName = language === 'en' ? 'P' : 'P'

            elementsToReturn.push(
                <span className={`type ${type.toLowerCase()} ${deleted ? 'deleted' : ''}`} key={type} data-for={`docflow-doc-type-${type === 'EXPENSES_REPORT' ? 'COSTS' : type}`} data-tip='show'>
                    {typeShortName}
                </span>
            )
        })
        return elementsToReturn
    }

    mouseEnderDocflowStatus = (e, fileId) => {
        var viewportOffset = e.target.getBoundingClientRect();
        this.setState({
            fileToGenerateDocflowStepTooltip: fileId,
            docflowStepTooltipX: viewportOffset.left + viewportOffset.width / 2,
            docflowStepTooltipY: viewportOffset.top + viewportOffset.height + 5
        })
    }

    mouseLeaveDocflowStatus = () => {
        this.setState({
            fileToGenerateDocflowStepTooltip: -1,
            docflowStepTooltipX: -1000,
            docflowStepTooltipY: -1000
        })
    }

    generateDocflowStepTooltip = file => {
        const { fileToGenerateDocflowStepTooltip } = this.state
        if (fileToGenerateDocflowStepTooltip !== file.id || file.deleted) return null

        var currentStepName = '', currentStepUsers = [], currentStepUsersRendered = []
        var nextStepName = '', nextStepUsers = [], nextStepUsersRendered = []

        var currentDocflowStepIndex = file.docflow?.docflowSteps?.findIndex(step => step.id === file.docflow_step_id)
        if (currentDocflowStepIndex === -1) return null
        var currentDocflowStep = file.docflow?.docflowSteps[currentDocflowStepIndex]
        currentStepName = currentDocflowStep?.name
        var nextDocflowStep = currentDocflowStepIndex + 1 < file.docflow?.docflowSteps?.length ? file.docflow?.docflowSteps[currentDocflowStepIndex + 1] : null
        if (nextDocflowStep) nextStepName = nextDocflowStep?.name

        console.log(currentDocflowStep?.additional_settings?.selectedUsers)
        currentDocflowStep?.additional_settings?.selectedUsers?.forEach(user => {
            if (!currentStepUsersRendered.includes(user.id || file.uploaded_by_number)) {
                var userAccepted = false
                if (currentDocflowStep.step_type === 'VERIFICATION') {
                    if (file.verified_by === user.id) {
                        userAccepted = true
                    } else {
                        var userAdditionalVerification = file.additionalVerification?.find(u => u.user_id === user.id && u.docflow_step_id === currentDocflowStep.id)

                        if (userAdditionalVerification) {
                            userAccepted = userAdditionalVerification.verified
                        } else {
                            userAdditionalVerification = file.additionalVerification?.find(u => user.id === 0 && u.user_id === file.uploaded_by_number && u.docflow_step_id === currentDocflowStep.id)
                            console.log(userAdditionalVerification)
                            userAccepted = userAdditionalVerification?.verified || false
                        }
                    }
                } else if (currentDocflowStep.step_type === 'ACCEPTANCE' || currentDocflowStep.step_type === 'CONDITION') {
                    if (file.accepted_by === user.id) {
                        userAccepted = true
                    } else {
                        var userAdditionalAcceptance = file.additionalAcceptance?.find(u => u.user_id === user.id && u.docflow_step_id === currentDocflowStep.id)
                        if (userAdditionalAcceptance) {
                            userAccepted = userAdditionalAcceptance.accepted
                        } else {
                            userAdditionalAcceptance = file.additionalAcceptance?.find(u => user.id === 0 && u.user_id === file.uploaded_by_number && u.docflow_step_id === currentDocflowStep.id)
                            userAccepted = userAdditionalAcceptance?.accepted || false
                        }
                    }
                }
                currentStepUsers.push(
                    <div className="user">
                        <div className={`user-photo ${user.profilePic ? '' : 'default-photo'}`}>
                            {
                                user.profilePic ? (
                                    <img src={user.profilePic} alt={user.name} />
                                ) : null
                            }
                            {
                                userAccepted ? (
                                    <div className="accepted"></div>
                                ) : null
                            }
                        </div>
                        <div className="user-name">
                            {user.id === 0 ? file.uploadedBy : user.name}
                        </div>
                    </div>
                )
                currentStepUsersRendered.push(user.id || file.uploaded_by_number)
            }
        })

        if (nextDocflowStep) {
            nextDocflowStep?.additional_settings?.selectedUsers?.forEach(user => {
                if (!nextStepUsersRendered.includes(user.id || file.uploaded_by_number)) {
                    nextStepUsers.push(
                        <div className="user">
                            <div className={`user-photo ${user.profilePic ? '' : 'default-photo'}`}>
                                {
                                    user.profilePic ? (
                                        <img src={user.profilePic} alt={user.name} />
                                    ) : null
                                }
                            </div>
                            <div className="user-name">
                                {user.id === 0 ? file.uploadedBy : user.name}
                            </div>
                        </div>
                    )
                    nextStepUsersRendered.push(user.id || file.uploaded_by_number)
                }
            })
        }

        var currentStepUsersWidth = currentStepUsers.length * 70 + 20
        var nextStepUsersWidth = nextStepUsers.length * 70
        if (nextStepUsersWidth) nextStepUsersWidth += 20
        var nextStepArrowWidth = nextStepUsers.length ? 45 : 0

        if (currentStepUsersWidth > 385) currentStepUsersWidth = 305
        if (nextStepUsersWidth > 385) nextStepUsersWidth = 300

        return (
            <div
                className={`docflow-step-tooltip ${this.state.docflowStepTooltipY > 0.80 * window.innerHeight ? 'flip-up' : ''}`}
                style={{
                    width: `${currentStepUsersWidth + nextStepArrowWidth + nextStepUsersWidth + 20}px`,
                    left: `${this.state.docflowStepTooltipX}px`,
                    top: `${this.state.docflowStepTooltipY}px`
                }}
            >
                <div className={`docflow-step current ${nextStepUsers?.length ? 'has-next-step' : ''}`}>
                    <div className="step-name">{currentStepName}</div>
                    <div className="users-wrapper" style={{ width: `${currentStepUsersWidth}px` }}>
                        {currentStepUsers}
                    </div>
                    {
                        currentStepUsers.length > 1 ? (
                            <div className="step-users-count">{this.context.t('required: {n1} from {n2}', { n1: currentDocflowStep.required_users_count, n2: currentStepUsers.length })}</div>
                        ) : null
                    }
                </div>
                {
                    nextStepUsers?.length && nextDocflowStep ? (
                        <>
                            <div className="step-arrow"></div>
                            <div className={`docflow-step next`}>
                                <div className="step-name">{nextStepName}</div>
                                <div className="users-wrapper" style={{ width: `${nextStepUsersWidth}px` }}>
                                    {nextStepUsers}
                                </div>
                                {
                                    nextStepUsers.length > 1 ? (
                                        <div className="step-users-count">{this.context.t('required: {n1} from {n2}', { n1: nextDocflowStep.required_users_count, n2: nextStepUsers.length })}</div>
                                    ) : null
                                }
                            </div>
                        </>
                    ) : null
                }
            </div >
        )
    }

    openDocflowModal = (file) => {
        this.setState({
            docflowModalIsOpen: true,
            docflowModalFile: file
        })
    }

    fileClick = fileInstance => {
        if (fileInstance.doc_type !== 'PAYMENT_ORDER') {
            if (fileInstance.isInOcr && !fileInstance.deleted) {
                this.setState({ dynamicPath: reverse(routes.panel.crop, { projectId: fileInstance.project_id, fileId: fileInstance.id }) }, () => {
                    if (this.linkRef.current) {
                        this.linkRef.current.click(); // Trigger the click programmatically after setting the path
                    }
                });
            } else {
                this.props.getFileBody(fileInstance.project_id, fileInstance.path, fileInstance.id, fileInstance.ocr_status, fileInstance.acceptance_step)
            }
        } else {
            const { transfers } = this.props
            const paymentOrdersProjectIndex = arrayObjectIndexOf(transfers, fileInstance.project_id, "project")
            console.log(paymentOrdersProjectIndex)
            if (paymentOrdersProjectIndex > -1) {
                console.log(transfers[paymentOrdersProjectIndex].transfers)
                console.log(fileInstance.id)
                var transfer = transfers[paymentOrdersProjectIndex].transfers.find(t => t.file_id === fileInstance.id)
                if (transfer) {
                    this.props.openTransferPreviewWindow(transfer)
                }
            }
        }
    }

    toggleTypesList = () => {
        this.setState({ showDocTypesList: !this.state.showDocTypesList })
    }

    toggleDocflowStagesList = () => {
        this.setState({ showDocflowStagesList: !this.state.showDocflowStagesList })
    }

    toggleUsersList = () => {
        this.setState({ showUsersList: !this.state.showUsersList })
    }

    toggleDueDateCalendar = () => {
        this.setState({
            showDueDateCalendar: !this.state.showDueDateCalendar
        })
    }

    onCalendarSelect = date => {
        this.setState({
            dueDate: date.format('YYYY-MM-DD'),
            showDueDateCalendar: false
        })
    }

    onCalendarUnselecet = () => {
        this.setState({
            dueDate: '',
            showDueDateCalendar: false
        })
    }

    checkIfFileMatchesFilters = file => {
        const {
            searchText, docTypeId, dueDate, docflowStageId, selectedUserId, showMine
        } = this.state

        console.log(searchText, docTypeId, dueDate, docflowStageId, selectedUserId, showMine)

        const { user } = this.props

        if (docTypeId !== 'ALL' && file.doc_type !== docTypeId)
            return false
        if (dueDate && file.due_date !== dueDate)
            return false
        if (docflowStageId !== 'ALL' && file.currentDocflowStepType !== docflowStageId.toUpperCase())
            return false
        if (selectedUserId > -1 && file.currentDocflowStepUsers?.findIndex(id => id === selectedUserId) === -1)
            return false
        if (showMine && file.currentDocflowStepUsers?.findIndex(id => id === user.id) === -1)
            return false

        if (searchText && searchText.length > 2) {
            const searchWords = searchText.toLowerCase().split(' ')
            for (let i = 0; i < searchWords.length; i++) {
                if (
                    !file.counterparty?.toLowerCase().includes(searchWords[i]) &&
                    !file.doc_number?.toLowerCase().includes(searchWords[i]) &&
                    !file.uploadedBy?.toLowerCase().includes(searchWords[i]) &&
                    !file.currentDocflowStepName?.toLowerCase().includes(searchWords[i]) &&
                    !file.currentDocflowStepUsersString?.toLowerCase().includes(searchWords[i]) &&
                    !file.docflowName?.toLowerCase().includes(searchWords[i])
                ) {
                    return false
                }
            }
        }

        return true
    }

    render() {
        const {
            searchText, docTypeName, docTypeId, dueDate, docflowStageId, docflowStageName, selectedUserId, selectedUserName, availableUsers, showMine,
            showDocTypesList, showDueDateCalendar, showDocflowStagesList, showUsersList,
            showOnlyDropzoneDocs, showOnlyDocToAccept, showOnlyDocsToPay, showOnlyDocsWithUnreadMessages,
            docflowModalIsOpen, docflowModalFile
        } = this.state
        const {
            filesInDocflow,
            currentProject, currentCompany,
            user,
            pdfPreviewOpen, transferPreviewOpen,
            unpaidDocsPreviewIsOpen, fullTextSearchPreviewIsOpen, actionsPreviewIsOpen, previewClickFromChatNotification
        } = this.props

        const index = arrayObjectIndexOf(filesInDocflow, currentProject.id, "project")

        if (index > -1 && filesInDocflow[index].loaded) {
            return (
                <div className="dashboard-docflow-files-table" ref={this.tableWrapperRef}>

                    {
                        pdfPreviewOpen && !unpaidDocsPreviewIsOpen && !fullTextSearchPreviewIsOpen && !actionsPreviewIsOpen && !previewClickFromChatNotification ? (
                            <PdfPreview location={this.props.location} />
                        ) : (
                            null
                        )
                    }

                    {
                        transferPreviewOpen ? (
                            <TransferPreview location={this.props.location} />
                        ) : (
                            null
                        )
                    }

                    {
                        docflowModalIsOpen ? (
                            <DocflowInfoModal
                                docflow={docflowModalFile.docflow}
                                additionalVerification={docflowModalFile.additionalVerification}
                                additionalAcceptance={docflowModalFile.additionalAcceptance}
                                fileInstance={docflowModalFile}
                                close={() => this.setState({ docflowModalIsOpen: false })}
                            />
                        ) : null
                    }

                    {
                        AVAILABLE_DOC_TYPES.map(type => {
                            return (
                                <ReactTooltip id={`docflow-doc-type-${type.docType}`} key={type.id} place="right" effect="solid" className="default-tooltip info-tooltip">
                                    {this.context.t(type.label)}
                                </ReactTooltip>
                            )
                        })
                    }

                    <div className="header-row">
                        <h2><span className="icon"></span>{this.context.t('Documents with active docflows')}</h2>
                    </div>
                    <div className="filters-row">
                        <div className="form-group search">
                            <input type="text" autoComplete="off" id="searchText" name="searchText" value={searchText} onChange={this.onInputChange} placeholder={this.context.t('Search for documents')} />
                            <label>{this.context.t('Search')}</label>
                        </div>
                        <div className="form-group type" ref={this.docTypesListWrapperRef}>
                            <input id="dueDate" name="docTypes" value={docTypeName} onClick={this.toggleTypesList} autoComplete="off" />
                            <label onClick={this.toggleTypesList}  >{this.context.t('Doc type')}</label>
                            <div className="extend-icon" onClick={this.toggleTypesList}  ></div>

                            {
                                showDocTypesList ? (
                                    <ul>
                                        <li key='ALL' onClick={() => this.setState({ docTypeName: this.context.t('All '), docTypeId: 'ALL', showDocTypesList: false })}>
                                            {this.context.t('All ')}
                                        </li>
                                        {
                                            AVAILABLE_DOC_TYPES.map(type => {
                                                return (
                                                    <li key={type.id} onClick={() => this.setState({ docTypeName: this.context.t(type.label), docTypeId: type.docType, showDocTypesList: false })}>
                                                        {this.context.t(type.label)}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                ) : null
                            }
                        </div>
                        <div className="form-group due-date" ref={this.dueDateCalendarWrapperRef}>
                            <input id="dueDate" name="dueDate" value={dueDate} onClick={this.toggleDueDateCalendar} autoComplete="off" />
                            <label onClick={this.toggleDueDateCalendar}>{this.context.t('Due date')}</label>
                            <div className="calendar-icon" onClick={this.toggleDueDateCalendar}></div>

                            {
                                showDueDateCalendar ? (
                                    <Calendar
                                        selectDay={this.onCalendarSelect}
                                        unselectDay={this.onCalendarUnselecet}
                                        selectedDay={
                                            moment(dueDate, 'YYYY-MM-DD').isValid() ? {
                                                display: moment(dueDate, 'YYYY-MM-DD').format('LL'),
                                                value: moment(dueDate, 'YYYY-MM-DD')
                                            } : {
                                                display: null,
                                                value: null
                                            }
                                        }
                                        projectId={'none'}
                                        startDate={moment(dueDate, 'YYYY-MM-DD').isValid() ? moment(dueDate, 'YYYY-MM-DD') : moment()}
                                    />
                                ) : null
                            }
                        </div>
                        <div className="form-group status" ref={this.docflowStagesListWrapperRef}>
                            <input id="docflowStageName" name="docflowStageName" value={docflowStageName} onClick={this.toggleDocflowStagesList} autoComplete="off" />
                            <label onClick={this.toggleDocflowStagesList}>{this.context.t('Status')}</label>
                            <div className="extend-icon" onClick={this.toggleDocflowStagesList}></div>

                            {
                                showDocflowStagesList ? (
                                    <ul>
                                        <li key='ALL' onClick={() => this.setState({ docflowStageId: 'ALL', docflowStageName: this.context.t('All '), showDocflowStagesList: false })}>
                                            {this.context.t('All ')}
                                        </li>
                                        {
                                            AVAILABLE_STAGES.map(stage => {
                                                return (
                                                    <li key={stage.id} onClick={() => this.setState({ docflowStageId: stage.id, docflowStageName: this.context.t(stage.title), showDocflowStagesList: false })}>
                                                        {this.context.t(stage.title)}
                                                    </li>
                                                )
                                            })
                                        }
                                        {
                                            AVAILABLE_EVENTS.map(stage => {
                                                return (
                                                    <li key={stage.id} onClick={() => this.setState({ docflowStageId: stage.id, docflowStageName: this.context.t(stage.title), showDocflowStagesList: false })}>
                                                        {this.context.t(stage.title)}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                ) : null
                            }
                        </div>
                        <div className="form-group user" ref={this.usersListWrapperRef}>
                            <input id="status" name="status" value={selectedUserName} onClick={this.toggleUsersList} autoComplete="off" />
                            <label onClick={this.toggleUsersList}>{this.context.t('Responsible for the stage')}</label>
                            <div className="extend-icon" onClick={this.toggleUsersList}></div>

                            {
                                showUsersList && availableUsers?.length ? (
                                    <ul>
                                        <li key='ALL' onClick={() => this.setState({ selectedUserId: -1, selectedUserName: this.context.t('Everyone'), showUsersList: false })}>
                                            {this.context.t('Everyone')}
                                        </li>
                                        {
                                            availableUsers.map(user => {
                                                return (
                                                    <li key={user.id} onClick={() => this.setState({ selectedUserId: user.id, selectedUserName: user.name, showUsersList: false })}>
                                                        {user.name}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                ) : null
                            }
                        </div>
                        <div className="checkbox-group">
                            <div className={`checkbox ${showMine ? 'checked' : ''}`} onClick={() => this.setState({ showMine: !showMine })}></div>
                            <div className="label" onClick={() => this.setState({ showMine: !showMine })}>{this.context.t("Show assigned to me")}</div>
                        </div>
                        <div className="reset-button" onClick={() => this.clearFilters()}>
                            {this.context.t('Reset filters')}
                        </div>
                    </div>
                    <div className="table">
                        <Link
                            to={this.state.dynamicPath}
                            ref={this.linkRef} // Attach the ref
                            style={{ display: "none" }}
                        ></Link>
                        {
                            filesInDocflow[index].files.length && filesInDocflow[index].loaded ? (
                                <>
                                    <div className="table-header" ref={this.tableHeaderRef}>
                                        <div className="table-row">
                                            <div className="table-cell type" onClick={() => this.props.switchSortingFilterFilesInDocflow(filesInDocflow[index].project, 'doc_type')} >
                                                {this.context.t('Type')}
                                                <div
                                                    className={this.getSortClass(index, 'doc_type')}
                                                ></div>
                                            </div>
                                            <div className="table-cell counterparty" onClick={() => this.props.switchSortingFilterFilesInDocflow(filesInDocflow[index].project, 'counterparty')} >
                                                {this.context.t('Counterparty')}
                                                <div
                                                    className={this.getSortClass(index, 'counterparty')}
                                                ></div>
                                            </div>
                                            <div className="table-cell doc-number" onClick={() => this.props.switchSortingFilterFilesInDocflow(filesInDocflow[index].project, 'doc_number')} >
                                                {this.context.t('Doc number')}
                                                <div
                                                    className={this.getSortClass(index, 'doc_number')}
                                                ></div>
                                            </div>
                                            <div className="table-cell amount" onClick={() => this.props.switchSortingFilterFilesInDocflow(filesInDocflow[index].project, 'amount_to_pay')} >
                                                {this.context.t('Amount to pay')}
                                                <div
                                                    className={this.getSortClass(index, 'amount_to_pay')}
                                                ></div>
                                            </div>
                                            <div className="table-cell due-date" onClick={() => this.props.switchSortingFilterFilesInDocflow(filesInDocflow[index].project, 'due_date')} >
                                                {this.context.t('Due date')}
                                                <div
                                                    className={this.getSortClass(index, 'due_date')}
                                                ></div>
                                            </div>
                                            <div className="table-cell uploaded-by" onClick={() => this.props.switchSortingFilterFilesInDocflow(filesInDocflow[index].project, 'uploadedBy')} >
                                                {this.context.t('Uploaded by')}
                                                <div
                                                    className={this.getSortClass(index, 'uploadedBy')}
                                                ></div>
                                            </div>
                                            <div className="table-cell status" onClick={() => this.props.switchSortingFilterFilesInDocflow(filesInDocflow[index].project, 'currentDocflowStepName')} >
                                                {this.context.t('Status')}
                                                <div
                                                    className={this.getSortClass(index, 'currentDocflowStepName')}
                                                ></div>
                                            </div>
                                            <div className="table-cell users" onClick={() => this.props.switchSortingFilterFilesInDocflow(filesInDocflow[index].project, 'currentDocflowStepUsersString')} >
                                                {this.context.t('Users')}
                                                <div
                                                    className={this.getSortClass(index, 'currentDocflowStepUsersString')}
                                                ></div>
                                            </div>
                                            <div className="table-cell docflow" onClick={() => this.props.switchSortingFilterFilesInDocflow(filesInDocflow[index].project, 'docflowName')} >
                                                {this.context.t('Docflow')}
                                                <div
                                                    className={this.getSortClass(index, 'docflowName')}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-body" style={{ height: `calc(100% - ${(this.tableHeaderRef?.current?.clientHeight || 0) + 10}px)` }}>
                                        {
                                            filesInDocflow[index].files.map((file, index) => {
                                                if (this.checkIfFileMatchesFilters(file)) {
                                                    return (
                                                        <div key={file.id} className={`table-row ${file.deleted ? 'deleted' : ''}`}>
                                                            <div className="table-cell type" onClick={() => this.fileClick(file)}>
                                                                {this.rederDocflowDocTypes(file.doc_type ? [file.doc_type] : [], file.deleted)}
                                                            </div>
                                                            <div className="table-cell counterparty" onClick={() => this.fileClick(file)}>
                                                                {file.counterparty || '-'}
                                                            </div>
                                                            <div className="table-cell doc-number" onClick={() => this.fileClick(file)}>
                                                                {file.doc_number || '-'}
                                                            </div>
                                                            <div className="table-cell amount" onClick={() => this.fileClick(file)}>
                                                                {file.amount_to_pay || '-'}
                                                            </div>
                                                            <div className="table-cell due-date" onClick={() => this.fileClick(file)}>
                                                                {file.due_date ? moment(file.due_date).format('YYYY-MM-DD') : '-'}
                                                            </div>
                                                            <div className="table-cell uploaded-by" onClick={() => this.fileClick(file)}>
                                                                {file.uploadedBy}
                                                            </div>
                                                            <div
                                                                className={`table-cell status`}
                                                                onMouseEnter={e => this.mouseEnderDocflowStatus(e, file.id)}
                                                                onMouseLeave={e => this.mouseLeaveDocflowStatus()}
                                                                onClick={() => this.fileClick(file)}
                                                            >
                                                                <span className={`${file.currentDocflowStepType ? file.currentDocflowStepType.toLowerCase() : ''} ${file.deleted ? 'deleted' : ''}`}>
                                                                    {file.deleted ? this.context.t(' Deleted ') : file.currentDocflowStepName || ''}
                                                                    {this.generateDocflowStepTooltip(file)}
                                                                </span>
                                                            </div>
                                                            <div
                                                                className="table-cell users"
                                                                onClick={() => this.fileClick(file)}
                                                                onMouseEnter={e => this.mouseEnderDocflowStatus(e, file.id)}
                                                                onMouseLeave={e => this.mouseLeaveDocflowStatus()}
                                                            >
                                                                {file.currentDocflowStepUsersString || '-'}
                                                            </div>
                                                            <div className="table-cell docflow" onClick={() => this.openDocflowModal(file)}>
                                                                {file.docflowName || '-'}
                                                            </div>
                                                        </div>
                                                    )
                                                } else {
                                                    return null
                                                }
                                            })
                                        }
                                    </div>
                                </>
                            ) : (
                                <div className="no-docflow-docs">
                                    <div className="image"></div>
                                    <h3>{this.context.t('You do not have any documents currently in docflow in this binder')}</h3>
                                </div>
                            )
                        }
                    </div>
                </div >
            )
        } else {
            return (
                <div className="dashboard-docflow-files-table">
                    <div className="activity-indicator">
                        <Bounce size={30} speed={0.8} color={"#9099A5"} />
                    </div>
                </div>
            )
        }
    }
}

DocflowFilesTable.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
    user: state.User.user,
    currentProject: state.User.currentProject,
    currentCompany: state.Company.currentCompany,
    filesInDocflow: isIterable(state.File.filesInDocflow) ? [...state.File.filesInDocflow] : [],

    pdfPreviewOpen: state.File.pdfPreviewOpen,
    unpaidDocsPreviewIsOpen: state.File.unpaidDocsPreviewIsOpen,
    fullTextSearchPreviewIsOpen: state.File.fullTextSearchPreviewIsOpen,
    actionsPreviewIsOpen: state.File.actionsPreviewIsOpen,
    previewClickFromChatNotification: state.File.previewClickFromChatNotification,

    transfers: isIterable(state.Transfers.transfers) ? [...state.Transfers.transfers] : [],
    transferPreviewData: state.Transfers.transferPreviewData,
    transferPreviewOpen: state.Transfers.transferPreviewOpen,

    language: state.i18nState.lang,
})

const mapDispatchToProps = {
    getProjectMembersForDocflow: DocflowActions.getProjectMembersForDocflow,

    switchSortingFilterFilesInDocflow: FileActions.switchSortingFilterFilesInDocflow,
    getFileBody: FileActions.getFileData,
    openTransferPreviewWindow: TransfersActions.openTransferPreviewWindow,
}

export default connect(mapStateToProps, mapDispatchToProps)(DocflowFilesTable)