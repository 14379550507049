import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize'
import Dropzone from 'react-dropzone'
import { Digital } from 'react-activity'
import { UserActions } from '../../actions/UserActions'
import { AlertActions } from '../../actions/AlertActions'

const imageMaxSize = 1000000000 // bytes

export class HelpPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            subject: '',
            description: '',
            type: '',
            showTicketTypeList: false,
            attachments: [],
            showAnswers: [0, 0, 0, 0, 0, 0, 0]
        }
    }

    componentDidMount() {
        var queryParams = this.props.location.pathname

        if (queryParams) {
            console.log(queryParams)
            queryParams = queryParams.replace('/helpdesk/', '')
            if (queryParams === 'how-to-add-docs') {
                this.setState({
                    showAnswers: [1, 0, 0, 0, 0, 0, 0]
                })
                if (this.refs.howToAddDocs) {
                    this.refs.howToAddDocs.scrollIntoView({ behavior: "smooth" });
                }
            } else if (queryParams === 'how-to-verify-docs') {
                this.setState({
                    showAnswers: [0, 1, 0, 0, 0, 0, 0]
                })
                if (this.refs.howToVerifyDocs) {
                    this.refs.howToVerifyDocs.scrollIntoView({ behavior: "smooth" });
                }
            } else if (queryParams === 'how-to-accept-docs') {
                this.setState({
                    showAnswers: [0, 0, 1, 0, 0, 0, 0]
                })
                if (this.refs.howToAcceptDocs) {
                    this.refs.howToAcceptDocs.scrollIntoView({ behavior: "smooth" });
                }
            } else if (queryParams === 'how-to-add-corrective-invoice') {
                this.setState({
                    showAnswers: [0, 0, 0, 1, 0, 0, 0]
                })
                if (this.refs.howToAddCorrectiveInvoice) {
                    this.refs.howToAddCorrectiveInvoice.scrollIntoView({ behavior: "smooth" });
                }
            } else if (queryParams === 'how-to-add-payment-orders') {
                this.setState({
                    showAnswers: [0, 0, 0, 0, 1, 0, 0]
                })
                if (this.refs.howToAddPaymentOrders) {
                    this.refs.howToAddPaymentOrders.scrollIntoView({ behavior: "smooth" });
                }
            } else if (queryParams === 'step-by-step') {
                this.setState({
                    showAnswers: [0, 0, 0, 0, 0, 1, 0]
                })
                if (this.refs.u1Scenario) {
                    this.refs.u1Scenario.scrollIntoView({ behavior: "smooth" });
                }
            } else if (queryParams === 'how-to-add-employee') {
                this.setState({
                    showAnswers: [0, 0, 0, 0, 0, 0, 1]
                })
                if (this.refs.howToAddEmployee) {
                    this.refs.howToAddEmployee.scrollIntoView({ behavior: "smooth" });
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { sendSubmitTicketRequest, gotSubmitTicketResponse, submitTicketOk } = this.props

        if (!prevProps.gotSubmitTicketResponse && gotSubmitTicketResponse && submitTicketOk) {
            this.setState({
                subject: '',
                description: '',
                attachments: [],
            })
        }
    }

    closeAllSugestionLists = e => {
        if (e.keyCode === 27 || (this.state.showTicketTypeList && !this.hasAncestor(e.target, "ticketTypeContainer"))) {
            this.setState({
                showTicketTypeList: false
            })
        }
    }

    hasAncestor = (element, id) => {
        while (element) {
            if (element.id && element.id === id) return true

            element = element.parentNode
        }

        return false
    }

    onInputChange = e => {
        e.preventDefault()

        const { name, value } = e.target

        this.setState({ [name]: value })
    }

    verifyFile = (files) => {
        if (files && files.length > 1) {
            this.props.alertWarn(this.context.t("You can attach only one file"))
            return false
        } else if (files && files.length === 1) {
            files.forEach(currentFile => {
                const currentFileType = currentFile.type
                const currentFileSize = currentFile.size
                if (currentFileSize > imageMaxSize) {
                    this.props.alertWarn("Document is to big. Maximum size is 1GB")
                    return false
                }
            })
        }
        return true
    }

    handleOnDrop = (files, rejectedFiles) => {
        console.log('drop')
        if (rejectedFiles && rejectedFiles.length > 0) {
            this.verifyFile(rejectedFiles)
        }

        else {
            files.forEach((currentFile, index) => {
                const myFileItemReader = new FileReader()
                myFileItemReader.addEventListener("load", () => {
                    const myResult = {}
                    myResult.base64 = myFileItemReader.result
                    myResult.name = currentFile.name
                    myResult.size = currentFile.size
                    myResult.type = currentFile.type
                    console.log(myResult)
                    this.setState({
                        attachments: this.state.attachments.concat(myResult)
                    })
                }, false)
                myFileItemReader.readAsDataURL(currentFile)
            })
        }
    }

    handleDropZoneClick = e => {
        if (e.target.className && e.target.className.includes('delete')) {
            e.stopPropagation()
        }
    }

    removeFile = fileName => {
        var attachments = this.state.attachments

        this.setState({
            attachments: attachments.filter(f => f.name !== fileName)
        })
    }

    submitTicket = (e) => {
        e.preventDefault()

        const { subject, description, type, attachments } = this.state

        if (!type || type.length === 0) {
            this.props.alertWarn("Ticket type is required")
        } else if (subject.length === 0) {
            this.props.alertWarn("Subject is required")
        } else if (description.length === 0) {
            this.props.alertWarn("Description is required")
        } else {
            this.props.submitTicket({
                email: this.props.user.email,
                subject: subject,
                type: type,
                description: description,
                attachments: attachments
            })
        }
    }

    scrollToAnswear = (questionNumber) => {
        var { showAnswers } = this.state

        if (questionNumber === 0) {
            if (this.refs.howToAddDocs) {
                this.refs.howToAddDocs.scrollIntoView({ behavior: "smooth" });
            }
        } else if (questionNumber === 3) {
            if (this.refs.howToAddPaymentOrders) {
                this.refs.howToAddPaymentOrders.scrollIntoView({ behavior: "smooth" });
            }
        }

        showAnswers[questionNumber] = 1
        this.setState({
            showAnswers: showAnswers
        })
    }

    toggleAnswear = (questionNumber) => {
        var { showAnswers } = this.state

        if (questionNumber === 0) {
            if (showAnswers[0] === 0) {
                window.history.replaceState(null, null, "/helpdesk/how-to-add-docs")
            } else {
                window.history.replaceState(null, null, "/helpdesk")
            }
        } else if (questionNumber === 1) {
            if (showAnswers[1] === 0) {
                window.history.replaceState(null, null, "/helpdesk/how-to-verify-docs")
            } else {
                window.history.replaceState(null, null, "/helpdesk")
            }
        } else if (questionNumber === 2) {
            if (showAnswers[2] === 0) {
                window.history.replaceState(null, null, "/helpdesk/how-to-accept-docs")
            } else {
                window.history.replaceState(null, null, "/helpdesk")
            }
        } else if (questionNumber === 3) {
            if (showAnswers[3] === 0) {
                window.history.replaceState(null, null, "/helpdesk/how-to-add-corrective-invoice")
            } else {
                window.history.replaceState(null, null, "/helpdesk")
            }
        } else if (questionNumber === 4) {
            if (showAnswers[4] === 0) {
                window.history.replaceState(null, null, "/helpdesk/how-to-add-payment-orders")
            } else {
                window.history.replaceState(null, null, "/helpdesk")
            }
        } else if (questionNumber === 5) {
            if (showAnswers[5] === 0) {
                window.history.replaceState(null, null, "/helpdesk/step-by-step")
            } else {
                window.history.replaceState(null, null, "/helpdesk")
            }
        } else if (questionNumber === 6) {
            if (showAnswers[6] === 0) {
                window.history.replaceState(null, null, "/helpdesk/how-to-add-employee")
            } else {
                window.history.replaceState(null, null, "/helpdesk")
            }
        }

        if (showAnswers[questionNumber]) {
            showAnswers[questionNumber] = 0
        } else {
            showAnswers[questionNumber] = 1
        }

        this.setState({
            showAnswers: showAnswers
        })
    }



    showTicketTypeList = (e) => {
        this.setState({
            showTicketTypeList: true
        })
    }

    toggleTicketTypeList = (e) => {
        this.setState({
            showTicketTypeList: !this.state.showTicketTypeList
        })
    }

    selectTicketType = type => {
        this.setState({
            type: type,
            showTicketTypeList: false
        })
    }

    render() {
        const { user, sendSubmitTicketRequest, gotSubmitTicketResponse, submitTicketOk, tabIsActive } = this.props
        const { subject, description, attachments, showAnswers, type, showTicketTypeList } = this.state
        if (tabIsActive || !user.new_easydocs_version) {
            return (
                <div className="freshdesk-container" onClick={e => this.closeAllSugestionLists(e)}>
                    <div className="page-header help">
                        <div className="page-name help">
                            {this.context.t('Helpdesk')}
                        </div>
                    </div>

                    <div className="helpdes-form">
                        <div className="header-row">
                            <h3>{this.context.t('How can we help?')}</h3>
                        </div>
                        <form onSubmit={this.submitTicket}>
                            <div className="form-group email">
                                <input type="text" name="senderEmail" id="senderEmail" value={user.email} readOnly={true} />
                                <label htmlFor="senderEmail">{this.context.t('E-mail')}</label>
                            </div>
                            <div className="form-group type" id="ticketTypeContainer">
                                <input type="text" name="type" id="type" value={type} readOnly={true} onFocus={(e) => this.showTicketTypeList(e)} />
                                <label htmlFor="type">{this.context.t('Ticket type')}<span className='required'> *</span></label>
                                <span className={`expend-icon ${showTicketTypeList ? 'expended' : ''}`} onClick={(e) => this.toggleTicketTypeList(e)}></span>
                                {
                                    showTicketTypeList ? (
                                        <ul className={`types-list`}>
                                            <li onClick={() => this.selectTicketType('Pytanie?')}>{this.context.t('Question?')}</li>
                                            <li onClick={() => this.selectTicketType('Błąd!')}>{this.context.t('Error!')}</li>
                                            <li onClick={() => this.selectTicketType('Proszę o nową funkcjonalność :)')}>{this.context.t('New feature request :)')}</li>
                                        </ul>
                                    ) : (
                                        null
                                    )
                                }
                            </div>
                            <div className="form-group">
                                <input type="text" name="subject" id="subject" value={subject} onChange={this.onInputChange} />
                                <label htmlFor="subject">{this.context.t('Subject')}<span className='required'> *</span></label>
                            </div>
                            <div className="form-group">
                                <TextareaAutosize style={{ minHeight: 120 }} type="text" name="description" id="description" value={description} onChange={this.onInputChange} />
                                <label htmlFor="description">{this.context.t('Description')}<span className='required'> *</span></label>
                            </div>
                            <Dropzone onDrop={(files, rejectedFiles) => this.handleOnDrop(files, rejectedFiles)} multiple={true} maxSize={imageMaxSize}>
                                {({ getRootProps, getInputProps, isDragActive }) => (
                                    <div {...getRootProps({ onClick: event => this.handleDropZoneClick(event) })} className={`dropzone${isDragActive ? ' drop-active' : ''}`}>
                                        {
                                            attachments.length === 0 ? (
                                                <div className="no-file">{this.context.t('Attachment')}</div>
                                            ) : (
                                                attachments.map(file => {
                                                    return (
                                                        <div className={`file ${attachments.length === 1 ? 'single' : ''}`}>
                                                            <div className={`name ${file.type.includes('image') ? 'image' : ''}`}>{file.name}</div>
                                                            <div className="delete" onClick={() => this.removeFile(file.name)}></div>
                                                        </div>
                                                    )
                                                })
                                            )
                                        }
                                        <input {...getInputProps()} />
                                    </div>
                                )}
                            </Dropzone>

                            <div className="footer-row">
                                <p>
                                    {this.context.t('Or submit your ticket directly via e-mail ')}<a href="mailto:support@easydocs.pl">support@easydocs.pl</a>
                                </p>
                                {
                                    sendSubmitTicketRequest ? (
                                        <div className="ticket-processing">
                                            <Digital size={20} speed={0.8} color={"#fff"} />
                                        </div>
                                    ) : (
                                        <input type="submit" value={this.context.t('Submit')} />
                                    )
                                }
                            </div>
                        </form>
                        <div className="image">

                        </div>
                    </div>

                    <div className="faq">
                        <div className="title">FAQ</div>

                        <h3>
                            <a href="https://easydocs.pl/faq/#1" target="_blank">{this.context.t("How to add a document to EasyDocs?")}</a>
                        </h3>

                        <h3>
                            <a href="https://easydocs.pl/faq/#2" target="_blank">{this.context.t("How to verify a cost document in EasyDocs?")}</a>
                        </h3>

                        <h3>
                            <a href="https://easydocs.pl/faq/#3" target="_blank">{this.context.t("How to accept a document for payment in EasyDocs?")}</a>
                        </h3>

                        <h3>
                            <a href="https://easydocs.pl/faq/#4" target="_blank">{this.context.t("How to add a correction invoice to EasyDocs?")}</a>
                        </h3>

                        <h3>
                            <a href="https://easydocs.pl/faq/#5" target="_blank">{this.context.t("How to create a payment order in EasyDocs?")}</a>
                        </h3>

                        <h3>
                            <a href="https://easydocs.pl/faq/#6" target="_blank">{this.context.t("Is it possible to add a sales invoice to the application?")}</a>
                        </h3>

                        <h3>
                            <a href="https://easydocs.pl/faq/#7" target="_blank">{this.context.t("Can I move a document to another folder?")}</a>
                        </h3>

                        <h3>
                            <a href="https://easydocs.pl/faq/#8" target="_blank">{this.context.t("How does the binder concept work?")}</a>
                        </h3>

                        <h3>
                            <a href="https://easydocs.pl/faq/#9" target="_blank">{this.context.t("There are four access levels in EasyDocs:")}</a>
                        </h3>

                        <h3>
                            <a href="https://easydocs.pl/faq/#10" target="_blank">{this.context.t("How to change user permissions?")}</a>
                        </h3>

                        <h3>
                            <a href="https://easydocs.pl/faq/#11" target="_blank">{this.context.t("How to add an employee to a company in EasyDocs?")}</a>
                        </h3>

                        <h3>
                            <a href="https://easydocs.pl/faq/#12" target="_blank">{this.context.t("How to assign an administrator or accounting role to a user?")}</a>
                        </h3>

                        <h3>
                            <a href="https://easydocs.pl/faq/#13" target="_blank">{this.context.t("Why can't the newly added user see documents in the company?")}</a>
                        </h3>

                        <h3>
                            <a href="https://easydocs.pl/faq/#14" target="_blank">{this.context.t("Can changes to employee authorizations be made for the entire company or do they have to be done separately in each binder?")}</a>
                        </h3>

                        <h3>
                            <a href="https://easydocs.pl/faq/#15" target="_blank">{this.context.t("Step-by-step action scenario for U1")}</a>
                        </h3>

                        <h3>
                            <a href="https://easydocs.pl/faq/#16" target="_blank">{this.context.t("How to send a transfer package to a bank?")}</a>
                        </h3>

                        <h3>
                            <a href="https://easydocs.pl/faq/#17" target="_blank">{this.context.t("How to select the payment method of a document added to EasyDocs?")}</a>
                        </h3>

                        <h3>
                            <a href="https://easydocs.pl/faq/#18" target="_blank">{this.context.t("Can I add employee details to generate salary payments each month?")}</a>
                        </h3>

                        <h3>
                            <a href="https://easydocs.pl/faq/#19" target="_blank">{this.context.t("How to change the payment method of an already entered invoice?")}</a>
                        </h3>

                        <h3>
                            <a href="https://easydocs.pl/faq/#20" target="_blank">{this.context.t("How to issue an income document?")}</a>
                        </h3>

                        <h3>
                            <a href="https://easydocs.pl/faq/#21" target="_blank">{this.context.t("Where can I set the numbering of revenue invoices?")}</a>
                        </h3>

                        <h3>
                            <a href="https://easydocs.pl/faq/#22" target="_blank">{this.context.t("Why do reports show the transfer date in red or orange?")}</a>
                        </h3>

                        <h3>
                            <a href="https://easydocs.pl/faq/#23" target="_blank">{this.context.t("Is it possible to reverse invoices marked in a transfer batch?")}</a>
                        </h3>

                        <h3>
                            <a href="https://easydocs.pl/faq/#24" target="_blank">{this.context.t("How to transfer a saved document in one project to another?")}</a>
                        </h3>

                        <h3>
                            <a href="https://easydocs.pl/faq/#25" target="_blank">{this.context.t("Why can't someone see the document?")}</a>
                        </h3>

                        <h3>
                            <a href="https://easydocs.pl/faq/#26" target="_blank">{this.context.t("Is it possible to change the document type from cost to revenue after verification?")}</a>
                        </h3>

                        <h3>
                            <a href="https://easydocs.pl/faq/#27" target="_blank">{this.context.t("Can I move a document from the Expenses folder to the Expenses folder without deleting it?")}</a>
                        </h3>

                        <h3>
                            <a href="https://easydocs.pl/faq/#28" target="_blank">{this.context.t("How to send a package of documents to accounting?")}</a>
                        </h3>

                        <h3>
                            <a href="https://easydocs.pl/faq/#29" target="_blank">{this.context.t("How to change the details of the contractor of an already entered invoice?")}</a>
                        </h3>

                        <h3>
                            <a href="https://easydocs.pl/faq/#30" target="_blank">{this.context.t("Can I enter Polish characters in the contractor's name and abbreviation?")}</a>
                        </h3>

                        {/* <div className="section-title">{this.context.t('Document input')}</div>
                        <div className="element" id="how-to-add-docs" ref="howToAddDocs">
                            <div className="header" onClick={() => this.toggleAnswear(0)}>
                                <span className="question">
                                    {this.context.t('How to add a document to EasyDocs?')}
                                </span>
                                <span className={`arrow ${showAnswers[0] ? "extended" : ""}`}></span>
                            </div>
                            <div className={`answer ${showAnswers[0] ? "extended" : ""}`}>
                                <div className="text">
                                    <p>{this.context.t("You can do this in 4 ways:")}</p>
                                    <ul>
                                        <li>{this.context.t('In the upper left part of the EasyDocs Desktop there is a rectangular area, the so-called Dropzone, with a document icon with an arrow. Click on this icon, File Explorer will open, in it select the document and press Open. The document shows up in Dropzone.')}</li>
                                        <li>{this.context.t('The second option is to drag-and-drop files from your computer: e.g. in File Explorer, find and select the documents you are interested in and drag them to Dropzone.')}</li>
                                        <li>{this.context.t('The third way is to send an email with an attachment to the binder\'s address, which is visible under the binder information icon. You can copy this address by clicking the icon on its right side and paste it into a new e-mail form in your e-mail program. You attach the document to the e-mail and send it. Shortly after sending the e-mail, you will receive an e-mail confirmation of the operation status. You can provide the binder\'s e-mail address to your contractors - to send documents to EasyDocs immediately, or connect to a network scanner in the company.')}</li>
                                        <li>{this.context.t('The fourth method is to use the EasyDocs mobile app for Android or iPhone. In the app you choose the binder, then the function of taking a photo of the document or selecting it from the gallery previously taken. You can pre-format and describe the photo. You send them to EasyDocs by pressing the Send button as my expense or as an expense document.')}</li>
                                    </ul>
                                </div>
                                <div className="video-container">
                                    <iframe src="https://www.youtube.com/embed/P_X601nsPgo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>

                        <div className="element" id="how-to-verify-docs" ref="howToVerifyDocs">
                            <div className="header" onClick={() => this.toggleAnswear(1)}>
                                <span className="question">
                                    {this.context.t('How to verify a cost document in EasyDocs?')}
                                </span>
                                <span className={`arrow ${showAnswers[1] ? "extended" : ""}`}></span>
                            </div>
                            <div className={`answer ${showAnswers[1] ? "extended" : ""}`}>
                                <div className="text">
                                    <p>{this.context.t('While on the Desktop, select the document to be verified in Dropzone by clicking anywhere in the line. A form with fields on the left and a photo of the document on the right opens.')}</p>
                                    <p>{this.context.t('Your task is to complete the left side with the data from the document.')}</p>
                                    <p>{this.context.t('The first time it will be more laborious, but for subsequent documents of this contractor, most of the fields will be read automatically.')}</p>
                                    <p>{this.context.t('You start with the general parameters:')}</p>
                                    <ul>
                                        <li>{this.context.t('document type - cost document by default (because it is an invoice for the company)')}</li>
                                        <li>{this.context.t('document payment status')}</li>
                                        <li>{this.context.t('currency of the document')}</li>
                                        <li>{this.context.t('type of payment')}</li>
                                        <li>{this.context.t('document priority - if it requires immediate payment - you mark it as urgent and people with the highest permissions in the company will get a notification immediately')}</li>
                                        <li>{this.context.t('invoice reading language')}</li>
                                    </ul>
                                    <p>{this.context.t('Then go to the document fields:')}</p>
                                    <ul>
                                        <li>{this.context.t('if the document does not contain the seller\'s tax identification number (NIP), check the box above the form to switch to the manual mode of filling in the fields')}</li>
                                        <li>{this.context.t('if the tax identification number is on the document, mark it with a frame in the document photo and select the "Seller\'s tax identification number" from the list (if the list does not appear automatically, click on the frame area with the right mouse button) - check if the field in the form on the left is correctly completed, if not - then correct the frame and similarly click the right button and select from the list')}</li>
                                        <li>{this.context.t('check if the next fields are completed - if they are not or are incorrect, then correct them in the same way')}</li>
                                        <li>{this.context.t('you can describe the document in the Comment field and assign tags to it by selecting them from the list or by adding your own')}</li>
                                        <li>{this.context.t('finally, click the "Submit" button to submit the document for approval to people with appropriate permissions')}</li>
                                    </ul>
                                    <p>{this.context.t('In the list in Dropzone, the document should change to the status pending approval.')}</p>
                                </div>
                                <div className="video-container">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/K7GDUynQuh8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>

                        <div className="element" id="how-to-accept-docs" ref="howToAcceptDocs">
                            <div className="header" onClick={() => this.toggleAnswear(2)}>
                                <span className="question">
                                    {this.context.t('How to accept a document for payment in EasyDocs?')}
                                </span>
                                <span className={`arrow ${showAnswers[2] ? "extended" : ""}`}></span>
                            </div>
                            <div className={`answer ${showAnswers[2] ? "extended" : ""}`}>
                                <div className="text">
                                    <p>{this.context.t('When you have higher than basic rights, you can approve previously verified documents or verify and accept them in one step.')}</p>
                                    <p>{this.context.t('While on the EasyDocs Desktop, select the document to be accepted by clicking on its line.')}</p>
                                    <p>{this.context.t('Check that the fields in the form on the left match the original document on the right. If something requires correction, apply it manually or using the frame method discussed in the question "How to verify a cost document in EasyDocs?". Then, formally confirm the compliance of the amount to be paid once the entire form with the document and click "Accept and Save".')}</p>
                                    <p>{this.context.t('The document disappears from Dropzone and is available for further use in EasyDocs.')}</p>
                                </div>
                                <div className="video-container">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/HISwFhnv_3g" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>

                        <div className="element" id="how-to-add-corrective-invoice" ref="howToAddCorrectiveInvoice">
                            <div className="header" onClick={() => this.toggleAnswear(3)}>
                                <span className="question">
                                    {this.context.t('How to add corrective invoice in EasyDocs?')}
                                </span>
                                <span className={`arrow ${showAnswers[3] ? "extended" : ""}`}></span>
                            </div>
                            <div className={`answer ${showAnswers[3] ? "extended" : ""}`}>
                                <div className="text">
                                    <p>{this.context.t('Please add the document to the system just like all others.')}</p>
                                    <p>{this.context.t('While verifying the document, mark "Correction invoice" box underneath document number field. An additional field with a list of original documents of that particular supplier will show up. Select appropriate number of corrected invoice.')}</p>
                                    <p>{this.context.t('If the correction requires payment on top of already paid amount, the additional payment amount will be available for inclusion in the bank transfer export file.')}</p>
                                    <p>{this.context.t('The correction invoices resulting in the return of funds to your company, will be shown with negative number. Once you confirm the return of funds, the document shall be marked as paid.')}</p>
                                    <p>{this.context.t('In document preview screen you will notice additional buttons: "Show original" wile viewing correction invoice and "Show correction" while viewing the original invoice so you may easily switch between associated documents.')}</p>
                                </div>
                            </div>
                        </div>

                        <div className="element" id="how-to-add-payment-orders" ref="howToAddPaymentOrders">
                            <div className="header" onClick={() => this.toggleAnswear(4)}>
                                <span className="question">
                                    {this.context.t('How to create a payment order in EasyDocs?')}
                                </span>
                                <span className={`arrow ${showAnswers[4] ? "extended" : ""}`}></span>
                            </div>
                            <div className={`answer ${showAnswers[4] ? "extended" : ""}`}>
                                <div className="text">
                                    <p>{this.context.t('To enter a financial operation that does not have the form of a cost document, such as a stamp duty or a tax transfer in EasyDocs, you use the "Payment orders" function.')}</p>
                                    <p>{this.context.t('While on the EasyDocs Desktop, in the "Payment Orders" section on the right, click the "+" icon. If you want to enter a standard stamp duty for a power of attorney, select the payment type as "regular".')}</p>
                                    <p>{this.context.t('Then complete the payment details.')}</p>
                                    <p>{this.context.t('You can also add a comment, assign tags, mark a payment as a priority or add attachments related to the order. Finally, you click the Create button.')}</p>
                                    <p>{this.context.t('The payment order appears on the list, by clicking on it you can view its contents. If the order is repeated, you can use the Redo function, which opens a new order form with already completed data.')}</p>
                                </div>
                                <div className="video-container">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/jGr_9hWI9Fg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>

                        <div className="section-title">{this.context.t('Operation scenario for U1')}</div>
                        <div className="element" id="step-by-step" ref="u1Scenario">
                            <div className="header" onClick={() => this.toggleAnswear(5)}>
                                <span className="question">
                                    {this.context.t('Step by step')}
                                </span>
                                <span className={`arrow ${showAnswers[5] ? "extended" : ""}`}></span>
                            </div>
                            <div className={`answer ${showAnswers[5] ? "extended" : ""}`}>
                                <div className="text">
                                    <ul>
                                        <li>{this.context.t('Log in to the EasyDocs app at ')}<a href="https://app.easydocs.pl">https://app.easydocs.pl</a></li>
                                        <li>{this.context.t('On the Dashboard, select the binder you want to work on from the drop-down list, or stay in the multi-binder view.')}</li>
                                        <li>{this.context.t('Start uploading document files to the system according to the instructions in the video ')}<span onClick={() => this.scrollToAnswear(0)}>{this.context.t('"How to add a document to EasyDocs?"')}</span></li>
                                        <li>{this.context.t('If you want to enter a document with no file, follow the instructions ')}<span onClick={() => this.scrollToAnswear(3)}>{this.context.t('"How to create a payment order in EasyDocs?"')}</span></li>
                                        <li>{this.context.t('You can also use the Calendar function in EasyDocs - save your tasks or read tasks assigned to you. For convenience, you can integrate the calendar with Google Calendar, in which the EasyDocs folder will appear.')}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="section-title">{this.context.t('Administration')}</div>
                        <div className="element" id="how-to-add-employee" ref="howToAddEmployee">
                            <div className="header" onClick={() => this.toggleAnswear(6)}>
                                <span className="question">
                                    {this.context.t('How to add an employee to a company in EasyDocs?')}
                                </span>
                                <span className={`arrow ${showAnswers[6] ? "extended" : ""}`}></span>
                            </div>
                            <div className={`answer ${showAnswers[6] ? "extended" : ""}`}>
                                <div className="text">
                                    <p>{this.context.t('Being logged in as the Company Administrator, in the vertical menu select "Binders" and here the "Binder members" tab.')}</p>
                                    <p>{this.context.t('Click the "Invite new user" button and select the binder (or binders) to which you want to assign him, enter his email address and send the invitation by clicking the "+" button.')}</p>
                                    <p>{this.context.t('The user will automatically receive an email with a link to activate the account.')}</p>
                                    <p>{this.context.t('If the newly added person is to have access levels higher than the basic ones, you can immediately set them by selecting the "My binders" tab, click on the row of a given binder and move the person to the appropriate section.')}</p>
                                </div>
                                <div className="video-container">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/kY9R7mH-gSU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            )
        } else {
            return null
        }
    }
}

HelpPage.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    sendSubmitTicketRequest: state.User.sendSubmitTicketRequest,
    gotSubmitTicketResponse: state.User.gotSubmitTicketResponse,
    submitTicketOk: state.User.submitTicketOk,
})

const mapDispatchToProps = {
    submitTicket: UserActions.submitTicket,
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(HelpPage)