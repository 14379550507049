export const QuickActionsConsts = {
    TOGGLE_BANK_TRANSFERS: 'TOGGLE_BANK_TRANSFERS',
    TOGGLE_DOCS_EXPORT: 'TOGGLE_DOCS_EXPORT',
    TOGGLE_EXPENSES_ASSISTANT: 'TOGGLE_EXPENSES_ASSISTANT',
    TOGGLE_DOCS_REQUIRING_ATTENTION_ASSISTANT: 'TOGGLE_DOCS_REQUIRING_ATTENTION_ASSISTANT',
    GET_DOCS_REQUIRING_ATTENTION_REQUEST: 'GET_DOCS_REQUIRING_ATTENTION_REQUEST',
    GET_DOCS_REQUIRING_ATTENTION: 'GET_DOCS_REQUIRING_ATTENTION',
    COLLAPSE_DOCS_REQUIRING_ATTENTION_PROJECT_GROUP: 'COLLAPSE_DOCS_REQUIRING_ATTENTION_PROJECT_GROUP',
    SET_SORT_FIELD_DOCS_REQUIRING_ATTENTION: 'SET_SORT_FIELD_DOCS_REQUIRING_ATTENTION',
    TOGGLE_FAVOURITE_FOR_PROJECT_GROUP: 'TOGGLE_FAVOURITE_FOR_PROJECT_GROUP'
}

const initialState = {
    showExportBankTransfers: false,
    showExportDocs: false,
    showDocsRequiringAttentionAssistant: false,
    waitingForDocsRequiringAttention: false,
    dropzoneDocs: [],
    additionalPaymentAcceptanceDocs: [],
    upcomingPayments: [],
    mentionedUnreadConversaons: [],
    attentionDocsProjectId: -1,
    projectDropzoneDocs: {},
    projectAdditionalPaymentAcceptanceDocs: {},
    projectUpcomingPayments: {},
    projectMentionedUnreadConversaons: {},
}

export default (state = initialState, action) => {
    switch (action.type) {
        case QuickActionsConsts.TOGGLE_BANK_TRANSFERS:
            return { ...state, ...{ showExportBankTransfers: action.show } }
        case QuickActionsConsts.TOGGLE_DOCS_EXPORT:
            return { ...state, ...{ showExportDocs: action.show } }
        case QuickActionsConsts.TOGGLE_EXPENSES_ASSISTANT:
            return { ...state, ...{ showExpensesAssistant: action.show } }
        case QuickActionsConsts.TOGGLE_DOCS_REQUIRING_ATTENTION_ASSISTANT:
            return { ...state, ...{ showDocsRequiringAttentionAssistant: action.show } }
        case QuickActionsConsts.GET_DOCS_REQUIRING_ATTENTION_REQUEST:
            if (action.projectId > -1) {
                return {
                    ...state, ...{
                        waitingForDocsRequiringAttention: true,
                        dropzoneDocs: [],
                        additionalPaymentAcceptanceDocs: [],
                        upcomingPayments: [],
                        mentionedUnreadConversaons: [],
                        attentionDocsProjectId: action.projectId,
                        projectDropzoneDocs: {},
                        projectAdditionalPaymentAcceptanceDocs: {},
                        projectUpcomingPayments: {},
                        projectMentionedUnreadConversaons: {},
                    }
                }
            } else {
                return {
                    ...state, ...{
                        waitingForDocsRequiringAttention: true,
                        dropzoneDocs: [],
                        additionalPaymentAcceptanceDocs: [],
                        upcomingPayments: [],
                        mentionedUnreadConversaons: []
                    }
                }
            }

        case QuickActionsConsts.GET_DOCS_REQUIRING_ATTENTION:
            if (action.projectId > -1 && state.attentionDocsProjectId === action.projectId) {
                return {
                    ...state, ...{
                        waitingForDocsRequiringAttention: false,
                        attentionDocsProjectId: action.projectId,
                        projectDropzoneDocs: action.dropzoneDocs[0] || {},
                        projectAdditionalPaymentAcceptanceDocs: action.additionalPaymentAcceptanceDocs[0] || {},
                        projectUpcomingPayments: action.upcomingPayments[0] || {},
                        projectMentionedUnreadConversaons: action.mentionedUnreadConversaons[0] || {}
                    }
                }
            } else {
                return {
                    ...state, ...{
                        waitingForDocsRequiringAttention: false,
                        dropzoneDocs: action.dropzoneDocs,
                        additionalPaymentAcceptanceDocs: action.additionalPaymentAcceptanceDocs,
                        upcomingPayments: action.upcomingPayments,
                        mentionedUnreadConversaons: action.mentionedUnreadConversaons
                    }
                }
            }
        case QuickActionsConsts.COLLAPSE_DOCS_REQUIRING_ATTENTION_PROJECT_GROUP:
            var docsList = state[action.docsList]
            if (docsList && docsList[action.groupIndex]) {
                console.log(docsList[action.groupIndex])
                docsList[action.groupIndex].areCollapsed = !docsList[action.groupIndex].areCollapsed
            }

            return { ...state, ...{ [action.docsList]: docsList } }

        case QuickActionsConsts.SET_SORT_FIELD_DOCS_REQUIRING_ATTENTION:
            var { showDocsRequiringAttentionAssistant } = state
            if (state.showDocsRequiringAttentionAssistant) {
                var docsList = state[action.docsList]
                if (docsList) {
                    var projectIndex = docsList.findIndex(e => e.project_id === action.projectId)
                    console.log(projectIndex)
                    if (projectIndex > -1) {
                        docsList[projectIndex].docs = docsList[projectIndex].docs.sort(
                            dynamicComparer(
                                action.field,
                                docsList[projectIndex].sortField === action.field && docsList[projectIndex].sortOrder === 'asc' ? 'desc' : 'asc'
                            )
                        )

                        docsList[projectIndex].sortField = action.field
                        docsList[projectIndex].sortOrder = docsList[projectIndex].sortField === action.field && docsList[projectIndex].sortOrder === 'asc' ? 'desc' : 'asc'
                    }
                }

                return { ...state, ...{ [action.docsList]: docsList } }
            } else {
                var docsList = {}
                if (action.docsList === 'additionalPaymentAcceptanceDocs') docsList = state.projectAdditionalPaymentAcceptanceDocs || {}
                if (action.docsList === 'upcomingPayments') docsList = state.projectUpcomingPayments || {}
                if (action.docsList === 'mentionedUnreadConversaons') docsList = state.projectMentionedUnreadConversaons || {}

                if (docsList.docs) {
                    docsList.docs = docsList.docs.sort(
                        dynamicComparer(
                            action.field,
                            docsList.sortField === action.field && docsList.sortOrder === 'asc' ? 'desc' : 'asc'
                        )
                    )
                    docsList.sortField = action.field
                    docsList.sortOrder = docsList.sortField === action.field && docsList.sortOrder === 'asc' ? 'desc' : 'asc'
                }

                if (action.docsList === 'additionalPaymentAcceptanceDocs') return { ...state, ...{ projectAdditionalPaymentAcceptanceDocs: docsList } }
                if (action.docsList === 'upcomingPayments') return { ...state, ...{ projectUpcomingPayments: docsList } }
                if (action.docsList === 'mentionedUnreadConversaons') return { ...state, ...{ projectMentionedUnreadConversaons: docsList } }
            }

        case QuickActionsConsts.TOGGLE_FAVOURITE_FOR_PROJECT_GROUP:
            var { showDocsRequiringAttentionAssistant } = state
            if (showDocsRequiringAttentionAssistant) {
                var dropzoneDocs = [...state.dropzoneDocs]
                var additionalPaymentAcceptanceDocs = [...state.additionalPaymentAcceptanceDocs]
                var upcomingPayments = [...state.upcomingPayments]
                var mentionedUnreadConversaons = [...state.mentionedUnreadConversaons]

                var projectIndex = dropzoneDocs.findIndex(e => e.project_id === action.projectId)
                if (projectIndex > -1) dropzoneDocs[projectIndex].favourite = action.favourite

                projectIndex = additionalPaymentAcceptanceDocs.findIndex(e => e.project_id === action.projectId)
                if (projectIndex > -1) additionalPaymentAcceptanceDocs[projectIndex].favourite = action.favourite

                projectIndex = upcomingPayments.findIndex(e => e.project_id === action.projectId)
                if (projectIndex > -1) upcomingPayments[projectIndex].favourite = action.favourite

                projectIndex = mentionedUnreadConversaons.findIndex(e => e.project_id === action.projectId)
                if (projectIndex > -1) mentionedUnreadConversaons[projectIndex].favourite = action.favourite

                return {
                    ...state, ...{
                        dropzoneDocs: dropzoneDocs.sort(projectGroupsComparer()),
                        additionalPaymentAcceptanceDocs: additionalPaymentAcceptanceDocs.sort(projectGroupsComparer()),
                        upcomingPayments: upcomingPayments.sort(projectGroupsComparer()),
                        mentionedUnreadConversaons: mentionedUnreadConversaons.sort(projectGroupsComparer())
                    }
                }
            }

        default:
            return state
    }
}

const dynamicComparer = (key, order = 'asc') => {
    return function (a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            return 0;
        }

        const varA = (typeof a[key] === 'string') ?
            a[key].toUpperCase() : a[key];
        const varB = (typeof b[key] === 'string') ?
            b[key].toUpperCase() : b[key];

        var comparison = 0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }
        return (
            (order == 'desc') ? (comparison * -1) : comparison
        );
    };
}

const projectGroupsComparer = () => {
    return function (a, b) {
        if (!a.code || !b.code) return -1

        const varA = (typeof a.code === 'string') ?
            a.code.toUpperCase() : a.code;
        const varB = (typeof b.code === 'string') ?
            b.code.toUpperCase() : b.code;

        if (
            (a.favourite && b.favourite) ||
            (!a.favourite && !b.favourite)
        ) {
            var comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
        } else if (a.favourite && !b.favourite) {
            comparison = -1;
        } else if (!a.favourite && b.favourite) {
            comparison = 1;
        }
        return comparison
    };
}