import React, { Component } from 'react'
import PropTypes, { number } from 'prop-types'
import { connect } from 'react-redux'
import { Spinner, Bounce, Digital } from 'react-activity'
import 'react-activity/dist/react-activity.css'
import TextareaAutosize from 'react-textarea-autosize'
import ReactGA from 'react-ga';
import throttle from "lodash.throttle"
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import axios from "axios"
import Dropzone from "react-dropzone";
import HTML5Backend from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd';

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import { UserActions } from '../../../actions/UserActions'
import { CompanyActions } from '../../../actions/CompanyActions'
import { AlertActions } from '../../../actions/AlertActions'

import ConnectToPsd2AisPopup from '../../../components/CompanyPageComponents/ConnectToPsd2AisPopup'
import RevokeConnectToPsd2AisPopup from '../../../components/CompanyPageComponents/RevokeConnectToPsd2AisPopup'
import OverlayInstructions from '../../../components/OverlayInstructions'
import AvailableInOtherPlanLabel from '../../../components/AvailableInOtherPlanLabel'
import DropTarget from '../../../components/CompanyPageComponents/DropTarget'
import DragLayer from '../../../components/CompanyPageComponents/DragLayer'
import SettingsTabsBar from '../../../components/SettingsTabsBar'
import KsefIntegrationWindow from '../../../components/KSeF/KsefIntegrationWindow'
import KsefIntegrationEditingWindow from '../../../components/KSeF/KsefIntegrationEditingWindow'

import bankIdentification from '../../../helpers/bankIdentification'
import arrayObjectIndexOf from "../../../helpers/indexOfObject";
import { replaceSpecialCharacters } from '../../../helpers/textSlugify'
import { generateProjectCode } from '../../../helpers/generateProjectCode'
import currencyList from '../../../helpers/currencyList.json'
import { vatIdValidate } from '../../../helpers/vatIdValidate'
import allExcelColumns from '../../../helpers/allExcelColumns.json'
import { emailRegex, phoneRegex, shortnameRegex } from '../../../helpers/regexList'

import '../../../css/CompanyPage.css'
import { socket } from "../../../App"

const incomeTaxSymbols = [
  'CIT', 'CIT-10', 'CIT-11', 'CIT-2', 'CIT-2A', 'CIT-2B', 'CIT6', 'CIT-6A', 'CIT-8', 'CIT-8A', 'CIT-8B', 'CIT-9', 'CIT10', 'CIT11', 'CIT2', 'CIT2A', 'CIT2B', 'CIT6', 'CIT6A', 'CIT8', 'CIT8A', 'CIT8B', 'CIT9', 'PIT', 'PIT-23', 'PIT-28', 'PIT-36', 'PIT-37', 'PIT-4', 'PIT-5', 'PIT-53', 'PIT-5L', 'PIT-7', 'PIT-8AR', 'PIT23', 'PIT28', 'PIT36', 'PIT37', 'PIT4', 'PIT5', 'PIT53', 'PIT5L', 'PIT7', 'PIT8AR'
]
const vatTaxSymbols = [
  'VAT', 'VAT-10', 'VAT-12', 'VAT-7', 'VAT-7K', 'VAT-8', 'VAT-9', 'VAT10', 'VAT12', 'VAT7', 'VAT7K', 'VAT8', 'VAT9'
]

const imageMaxSize = 1000000000; // bytes
const acceptedFileTypes = "image/x-png, image/png, image/jpg, image/jpeg, image/svg";
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {
  return item.trim();
})

export class CompanyDetails extends Component {

  constructor(props) {
    super(props)
    this.state = {
      name: '',
      shortName: '',
      vatId: '',
      regon: '',
      banks: [],
      bankContainer: -1,
      showCurrencyList: false,
      currencyListPosX: 0,
      currencyListPosY: 0,
      currencyListFlipUp: false,
      email: '',
      phone: '',
      www: '',
      address: '',
      showCompaniesList: false,
      tags: '',
      tagsCreationLimitation: 'EVERYONE',
      tax_account: '',
      zus_account: '',
      income_tax: '',
      income_tax_period: '',
      vat_tax: '',
      vat_tax_period: '',
      currentRepresentative: '',
      newRepresentative: '',
      newRepresentativeId: 0,
      newCompanyBinderShortName: '',
      newCompanyBinderName: '',
      newCompanyShortName: '',
      newCompanyName: '',
      newCompanyNip: '',
      failNewCompany: '',
      newProjectCodeIsUnique: true,

      gotOverlayPossitions: false,
      overlayPossitions: [],

      showCompanyMembersList: false,

      changedRepresentative: false,
      changedMainInfo: false,
      changedContactInfo: false,
      changedBankInfo: false,
      changedPaymentAccounts: false,
      showNewCompanyView: false,
      showAccountNumber: false,

      addAccountMode: false,
      bankToDisplay: 0,
      suggestedAccounts: [],

      addInvoiceNumberingSeriesMode: false,

      logo: null,
      showDeleteLogoPopup: false,
      changingCompanyLogoInProgress: false,
      invoiceNumberingSeries: [],
      changedInvoiceNumberingMask: false,
      availableExcelColumns: [],
      selectedExcelColumns: [],

      showKsefIntegration: false,
      showKsefIntegrationEditing: false,
    }

    this.incomeTaxSymbolContainerRef = React.createRef()
    this.incomeTaxPeriodContainerRef = React.createRef()
    this.vatTaxSymbolContainerRef = React.createRef()
    this.vatTaxPeriodContainerRef = React.createRef()
  }

  componentDidMount() {
    window.addEventListener("resize", throttle(this.setOverlaySize, 100))
    window.addEventListener("keydown", this.escFunction, false)
    const { userProjects, currentCompany, userCompanies } = this.props
    if (currentCompany && currentCompany.company) {
      var companyInstance = { ...currentCompany.company }
      this.props.getAllCompanyWorkers(currentCompany.company_id)
      var [availableColumns, selectedColumns] = this.getExcelReportsScheme(companyInstance.excel_reports)
      this.setState({
        name: companyInstance.name || '',
        shortName: companyInstance.code || '',
        vatId: companyInstance.vat_id || '',
        regon: companyInstance.regon || '',
        banks: companyInstance.banks ? JSON.parse(JSON.stringify(companyInstance.banks)) : [],
        email: companyInstance.email || '',
        phone: companyInstance.phone || '',
        www: companyInstance.www || '',
        address: companyInstance.address || '',
        tax_account: companyInstance.tax_account || '',
        zus_account: companyInstance.zus_account || '',
        income_tax: companyInstance.income_tax,
        income_tax_period: companyInstance.income_tax_period,
        vat_tax: companyInstance.vat_tax,
        vat_tax_period: companyInstance.vat_tax_period,
        currentRepresentative: companyInstance.representative ? `${companyInstance.representative.first_name} ${companyInstance.representative.last_name}` : '',
        logoUrl: companyInstance.logo_url || '',
        invoiceNumberingSeries: companyInstance.invoiceNumberingSeries ? JSON.parse(JSON.stringify(companyInstance.invoiceNumberingSeries)) : [],
        availableExcelColumns: availableColumns,
        selectedExcelColumns: selectedColumns,
        tagsCreationLimitation: companyInstance.tags_creation_limitation
      })

      if (userProjects) {
        var dashboardProjectId = parseInt(localStorage.getItem('dashboardProject')) || -1
        var projectIndex = arrayObjectIndexOf(userProjects, dashboardProjectId, 'id')
        if (projectIndex > -1 && currentCompany.id !== userProjects[projectIndex].company_id) {
          var companyIndex = arrayObjectIndexOf(userCompanies, userProjects[projectIndex].company_id, 'company_id')
          console.log(companyIndex)
          if (companyIndex > -1) {
            if (userCompanies[companyIndex].user_role === 'ADMIN') {
              this.props.setCurrentCompany(userCompanies[companyIndex])
            }
          }
        }
      }
    }

    const { location } = this.props
    var additionalUrlParams = location.pathname.replace('/settings/company', '')
    var companyIdAndFinancialPlatformIdArray = ''
    var psd2AisNotificationType = ''
    if (additionalUrlParams && (additionalUrlParams.includes('/psd2-ais-success'))) {
      companyIdAndFinancialPlatformIdArray = additionalUrlParams.replace('/psd2-ais-success/', '')
      psd2AisNotificationType = 'SUCCESS'
      this.props.alertInfo('PSD2 AIS connection successful', 10000)
    } else if (additionalUrlParams && (additionalUrlParams.includes('/psd2-ais-failure'))) {
      companyIdAndFinancialPlatformIdArray = additionalUrlParams.replace('/psd2-ais-failure/', '')
      psd2AisNotificationType = 'FAILURE'
      this.props.alertWarn('Connection to PSD2 AIS failed', 10000)
    }

    var companyIdAndFinancialPlatformIdArray = companyIdAndFinancialPlatformIdArray.split('/')
    if (companyIdAndFinancialPlatformIdArray?.length === 2) {
      this.props.psd2AisConsentNotification(parseInt(companyIdAndFinancialPlatformIdArray[0]), companyIdAndFinancialPlatformIdArray[1], psd2AisNotificationType, () => {
      })
    }

    if (socket) {
      socket.on('companyBanksPsd2ConsentUpdate', this.companyBanksPsd2ConsentUpdate)
    }
  }

  componentDidUpdate(prevProps) {
    const { currentCompany } = this.props
    if (currentCompany && currentCompany.company && prevProps.currentCompany) {
      var companyInstance = { ...currentCompany.company }
      if (currentCompany.id !== prevProps.currentCompany.id) {
        var [availableColumns, selectedColumns] = this.getExcelReportsScheme(companyInstance.excel_reports)
        this.setState({
          name: companyInstance.name || '',
          shortName: companyInstance.code || '',
          vatId: companyInstance.vat_id || '',
          regon: companyInstance.regon || '',
          banks: companyInstance.banks ? JSON.parse(JSON.stringify(companyInstance.banks)) : [],
          email: companyInstance.email || '',
          phone: companyInstance.phone || '',
          www: companyInstance.www || '',
          address: companyInstance.address || '',
          tax_account: companyInstance.tax_account || '',
          zus_account: companyInstance.zus_account || '',
          income_tax: companyInstance.income_tax,
          income_tax_period: companyInstance.income_tax_period,
          vat_tax: companyInstance.vat_tax,
          vat_tax_period: companyInstance.vat_tax_period,
          currentRepresentative: companyInstance.representative ? `${companyInstance.representative.first_name} ${companyInstance.representative.last_name}` : '',
          logoUrl: companyInstance.logo_url || '',
          invoiceNumberingSeries: companyInstance.invoiceNumberingSeries ? JSON.parse(JSON.stringify(companyInstance.invoiceNumberingSeries)) : [],
          availableExcelColumns: availableColumns,
          selectedExcelColumns: selectedColumns,
          tagsCreationLimitation: companyInstance.tags_creation_limitation
        })
      }

      if (prevProps.currentCompany.company && currentCompany.company.logo_url !== prevProps.currentCompany.company.logo_url) {
        console.log('changed logo')
        this.setState({
          logoUrl: currentCompany.company.logo_url || ''
        })
      }

      if (prevProps.currentCompany.company && currentCompany.company.tags_list.length > prevProps.currentCompany.company.tags_list.length) {
        this.setState({
          tags: ''
        })
      }
    }

    if (!this.state.gotOverlayPossitions) {
      const { companySelectionRef, mainInfoRef, contactInfoRef, bankInfoRef, paymentsAccountsRef, tagsRef } = this.refs
      const scrollBarWidth = window.innerWidth - document.body.offsetWidth
      if (companySelectionRef && mainInfoRef && contactInfoRef && bankInfoRef && paymentsAccountsRef && tagsRef) {
        var holesToDraw = []

        var companySelectionBox = companySelectionRef.getBoundingClientRect()
        if (companySelectionBox) {
          holesToDraw.push({
            x: companySelectionBox.x + scrollBarWidth,
            y: companySelectionBox.y,
            w: companySelectionBox.width,
            h: companySelectionBox.height
          })
        }

        var mainInfoBox = mainInfoRef.getBoundingClientRect()
        if (mainInfoBox) {
          holesToDraw.push({
            x: mainInfoBox.x,
            y: mainInfoBox.y + window.scrollY,
            w: mainInfoBox.width + scrollBarWidth,
            h: mainInfoBox.height
          })
        }

        var contactInfoBox = contactInfoRef.getBoundingClientRect()
        if (contactInfoBox) {
          holesToDraw.push({
            x: contactInfoBox.x,
            y: contactInfoBox.y + window.scrollY,
            w: contactInfoBox.width + scrollBarWidth,
            h: contactInfoBox.height
          })
        }

        var bankInfoBox = bankInfoRef.getBoundingClientRect()
        if (bankInfoBox) {
          holesToDraw.push({
            x: bankInfoBox.x,
            y: bankInfoBox.y + window.scrollY,
            w: bankInfoBox.width + scrollBarWidth,
            h: bankInfoBox.height
          })
        }

        var paymentsAccountsBox = paymentsAccountsRef.getBoundingClientRect()
        if (paymentsAccountsBox) {
          holesToDraw.push({
            x: paymentsAccountsBox.x,
            y: paymentsAccountsBox.y + window.scrollY,
            w: paymentsAccountsBox.width + scrollBarWidth,
            h: paymentsAccountsBox.height
          })
        }

        var tagsBox = tagsRef.getBoundingClientRect()
        if (tagsBox) {
          holesToDraw.push({
            x: tagsBox.x,
            y: tagsBox.y + window.scrollY,
            w: tagsBox.width + scrollBarWidth,
            h: tagsBox.height
          })
        }

        if (holesToDraw.length === 6) {
          console.log(holesToDraw)
          this.setState({
            gotOverlayPossitions: true,
            overlayPossitions: holesToDraw
          })
        }
      }
    }

    ReactTooltip.rebuild();
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.escFunction, false)
    window.removeEventListener("resize", throttle(this.setOverlaySize, 100))
    if (socket) {
      socket.off('companyBanksPsd2ConsentUpdate', this.companyBanksPsd2ConsentUpdate)
    }

    this.checkChangesAndSubmit()
  }

  companyBanksPsd2ConsentUpdate = data => {
    const { currentCompany } = this.props
    if (parseInt(data.companyId) === parseInt(currentCompany.company_id)) {
      const { location } = this.props
      console.log(data.allCompanyBanks)
      console.log(data.allCompanyBanks)
      console.log(data.allCompanyBanks)
      console.log(data.allCompanyBanks)
      if (!location.pathname.includes('/psd2-ais-')) {
        window.location.reload()
      } else {
        this.setState({
          banks: JSON.parse(JSON.stringify(data.allCompanyBanks))
        })
        window.history.replaceState(null, null, "/settings/company")
      }
    }
  }

  getExcelReportsScheme = excelSchema => {
    excelSchema = JSON.parse(excelSchema)
    console.log(excelSchema)
    var availableColumns = []

    excelSchema.forEach(column => {
      if (column.label === this.context.t(column.dataTypeName)) column.label = ""
    })

    allExcelColumns.forEach(column => {
      if (!excelSchema.find(c => c.dataType === column.dataType)) availableColumns.push(column)
    })

    return [availableColumns, excelSchema]
  }

  escFunction = (e) => {
    if (e.keyCode === 27) {
      const { showCompaniesList, showCurrencyList, showCompanyMembersList, addAccountMode, addInvoiceNumberingSeriesMode, showAccountList } = this.state
      if (showCompaniesList || showCurrencyList || showCompanyMembersList || showAccountList) {
        this.closeAllSugestionLists(e)
      } else if (addAccountMode) {
        var { banks } = this.state
        banks.pop()
        this.setState({
          banks: banks,
          addAccountMode: false
        })
      } else if (addInvoiceNumberingSeriesMode) {
        var { invoiceNumberingSeries } = this.state
        invoiceNumberingSeries.pop()
        this.setState({
          invoiceNumberingSeries: invoiceNumberingSeries,
          addInvoiceNumberingSeriesMode: false
        })
      }
    }
  }

  setOverlaySize = () => {
    const { companySelectionRef, mainInfoRef, contactInfoRef, bankInfoRef, paymentsAccountsRef, tagsRef } = this.refs
    const scrollBarWidth = window.innerWidth - document.body.offsetWidth
    if (companySelectionRef && mainInfoRef && contactInfoRef && bankInfoRef && paymentsAccountsRef && tagsRef) {
      var holesToDraw = []

      var companySelectionBox = companySelectionRef.getBoundingClientRect()
      if (companySelectionBox) {
        holesToDraw.push({
          x: companySelectionBox.x + scrollBarWidth,
          y: companySelectionBox.y,
          w: companySelectionBox.width,
          h: companySelectionBox.height
        })
      }

      var mainInfoBox = mainInfoRef.getBoundingClientRect()
      if (mainInfoBox) {
        holesToDraw.push({
          x: mainInfoBox.x,
          y: mainInfoBox.y + window.scrollY,
          w: mainInfoBox.width + scrollBarWidth,
          h: mainInfoBox.height
        })
      }

      var contactInfoBox = contactInfoRef.getBoundingClientRect()
      if (contactInfoBox) {
        holesToDraw.push({
          x: contactInfoBox.x,
          y: contactInfoBox.y + window.scrollY,
          w: contactInfoBox.width + scrollBarWidth,
          h: contactInfoBox.height
        })
      }

      var bankInfoBox = bankInfoRef.getBoundingClientRect()
      if (bankInfoBox) {
        holesToDraw.push({
          x: bankInfoBox.x,
          y: bankInfoBox.y + window.scrollY,
          w: bankInfoBox.width + scrollBarWidth,
          h: bankInfoBox.height
        })
      }

      var paymentsAccountsBox = paymentsAccountsRef.getBoundingClientRect()
      if (paymentsAccountsBox) {
        holesToDraw.push({
          x: paymentsAccountsBox.x,
          y: paymentsAccountsBox.y + window.scrollY,
          w: paymentsAccountsBox.width + scrollBarWidth,
          h: paymentsAccountsBox.height
        })
      }

      var tagsBox = tagsRef.getBoundingClientRect()
      if (tagsBox) {
        holesToDraw.push({
          x: tagsBox.x,
          y: tagsBox.y + window.scrollY,
          w: tagsBox.width + scrollBarWidth,
          h: tagsBox.height
        })
      }


      if (holesToDraw.length === 6) {
        console.log(holesToDraw)
        this.setState({
          gotOverlayPossitions: true,
          overlayPossitions: holesToDraw
        })
      }
    }
  }

  onInputChange = e => {
    const { currentCompany } = this.props
    e.preventDefault()

    const { name, value } = e.target

    this.setState({ [name]: value })

    if (name === 'name' || name === 'shortName' || name === 'vatId' || name === 'regon') {
      if (name === 'name' && currentCompany.company.name !== value) {
        this.setState({
          changedMainInfo: true
        })
      } else if (name === 'shortName' && currentCompany.company.shortName !== value) {
        this.setState({
          changedMainInfo: true
        })
      } else if (name === 'vatId' && currentCompany.company.vat_id !== value) {
        this.setState({
          changedMainInfo: true
        })
      } else if (name === 'regon' && currentCompany.company.regon !== value) {
        this.setState({
          changedMainInfo: true
        })
      } else {
        this.setState({
          changedMainInfo: false
        })
      }
    } else if (name === 'email' || name === 'phone' || name === 'www' || name === 'address') {
      if (name === 'email' && currentCompany.company.email !== value) {
        this.setState({
          changedContactInfo: true
        })
      } else if (name === 'phone' && currentCompany.company.phone !== value) {
        this.setState({
          changedContactInfo: true
        })

        if (value.length > 0 && phoneRegex.test(value)) {
          setTimeout(() => {
            this.setState({
              changedContactInfo: false
            })
            this.props.updateCompanyInfo(currentCompany.company.id, {
              phone: value,
            })
          }, 200)
        }
      } else if (name === 'www' && currentCompany.company.www !== value) {
        this.setState({
          changedContactInfo: true
        })
      } else if (name === 'address' && currentCompany.company.address !== value) {
        this.setState({
          changedContactInfo: true
        })
      } else {
        this.setState({
          changedContactInfo: false
        })
      }
    } else if (name === 'tax_account' || name === 'zus_account') {
      if (name === 'tax_account' && currentCompany.company.tax_account !== value) {
        this.setState({
          changedPaymentAccounts: true
        })
      } else if (name === 'zus_account' && currentCompany.company.zus_account !== value) {
        this.setState({
          changedPaymentAccounts: true
        })
      } else {
        this.setState({
          changedPaymentAccounts: false
        })
      }
    }

    if (name === 'newCompanyNip') {
      if (value && value.replace(/[^0-9]/gm).length === 10) {
        if (vatIdValidate(value)) {
          fetch(`https://wl-api.mf.gov.pl/api/search/nip/${value.replace(/[^0-9]/gi, '')}?date=${moment().format("YYYY-MM-DD")}`, { method: 'get' })
            .then(response => response.json())
            .then(json => {
              console.log(json)
              if (json && json.result && json.result.subject) {
                var comapnyName = json.result.subject.name
                var companyCode = generateProjectCode(comapnyName)
                companyCode = replaceSpecialCharacters(companyCode)
                if (companyCode.length >= 3) {
                  this.props.chackeIfProjectCodeIsUnique(value, unique => {
                    this.setState({
                      newProjectCodeIsUnique: unique
                    })
                  })
                }
                this.setState({
                  newCompanyName: comapnyName,
                  newCompanyShortName: companyCode,
                  newCompanyBinderName: comapnyName,
                  newCompanyBinderShortName: companyCode
                })
              }
            })
            .catch(err => {
              console.log('error when fetching companyName')
            })

          this.setState({
            showInvalidVatIdMsg: false,
            showUserFields: true
          })
        } else {
          this.setState({
            showInvalidVatIdMsg: true
          })
        }
      }
    }

    if (name === 'newCompanyBinderShortName') {
      var clearCode = replaceSpecialCharacters(value)
      this.setState({ [name]: clearCode })
      if (value.length >= 3) this.props.chackeIfProjectCodeIsUnique(value, unique => {
        this.setState({
          newProjectCodeIsUnique: unique
        })
      })
    }

    if (name === 'newCompanyShortName') {
      var clearCode = replaceSpecialCharacters(value)
      this.setState({ [name]: clearCode })
    }
  }

  onInputChangeBankInfo = (e, i) => {
    var { banks } = this.state
    e.preventDefault()

    const { name, value } = e.target
    banks[i][name] = value

    if (name === 'account_number') {
      var foundBank = this.checkAccountNumber(value)

      if (foundBank) {
        banks[i].bank_name = foundBank.name

        if (foundBank.bic) {
          banks[i].bic = foundBank.bic
        }
      }
    }

    this.setState({
      banks: banks
    })
  }

  onInputChangeInvoiceNumberingSeries = (e, i) => {
    var { invoiceNumberingSeries } = this.state
    e.preventDefault()

    const { name, value } = e.target
    if (invoiceNumberingSeries && invoiceNumberingSeries[i])
      invoiceNumberingSeries[i][name] = value

    this.setState({
      invoiceNumberingSeries: invoiceNumberingSeries
    })
  }

  verifyFile = (files) => {
    if (files && files.length > 1) {
      this.props.alertWarn(this.context.t("Only one photo is allowed"))
      return false
    } else if (files && files.length === 1) {
      files.forEach(currentFile => {
        const currentFileType = currentFile.type
        const currentFileSize = currentFile.size
        if (currentFileSize > imageMaxSize) {
          this.props.alertWarn(this.context.t("Document is to big. Maximum size is 5MB"))
          return false
        }
        if (!acceptedFileTypesArray.includes(currentFileType)) {
          this.props.alertWarn("This file is not allowed. Only png or jpg images are allowed.")
          return false
        }
      })
    }
    return true
  }

  handleOnDrop = (files, rejectedFiles) => {
    console.log('drop')
    if (rejectedFiles && rejectedFiles.length > 0) {
      this.verifyFile(rejectedFiles)
    }

    else {
      files.forEach((currentFile, index) => {
        const myFileItemReader = new FileReader()
        myFileItemReader.addEventListener("load", () => {
          var logo = myFileItemReader.result

          this.setState({
            cursorWait: true
          })

          this.props.changeCompanyLogo(logo, this.props.currentCompany.company_id, (logoUrl) => {
            this.setState({
              cursorWait: false
            })
            if (logoUrl) {
              this.setState({
                logoUrl: logoUrl
              })
            }
          })
        }, false)
        myFileItemReader.readAsDataURL(currentFile)
      })
    }
  }

  deleteCompanyLogo = () => {
    const { currentCompany } = this.props
    this.setState({
      cursorWait: true
    })
    this.props.deleteCompanyLogo(currentCompany.company_id, success => {
      if (success) {
        this.setState({
          showDeleteLogoPopup: false,
          logoUrl: ''
        })
      }
      this.setState({
        cursorWait: false
      })
    })
  }

  checkAccountNumber = (value) => {
    if (value) {
      var accountNumberOnlyDigits = value.replace(/\s/gm, '').replace('PL', '')

      if (accountNumberOnlyDigits.length === 26) {
        var bankCode = accountNumberOnlyDigits.substring(2, 6)

        var bank = bankIdentification.find(e => e.code === bankCode)

        if (bank) {
          return bank
        } else {
          return null
        }
      }
    }

  }

  checkChangesAndSubmit = () => {
    if (this.state.changedMainInfo) {
      if (shortnameRegex.test(this.state.shortName)) {
        this.props.alertWarn(this.context.t('Code can contain only letters, numbers, dashes and underscore.'))
      }
      else if (this.state.shortName.length > 20) {
        this.props.alertWarn(this.context.t('Code can have a maximum of 20 characters.'))
      }
      else {
        this.props.updateCompanyInfo(this.props.currentCompany.company.id, {
          name: this.state.name,
          vat_id: this.state.vatId,
          regon: this.state.regon,
          code: this.state.shortName
        })
        this.setState({
          changedMainInfo: false
        })
      }
    }

    if (this.state.changedContactInfo) {
      const { email, phone, www, address } = this.state
      if (email.length > 0 && !emailRegex.test(email)) {
        this.props.alertWarn(this.context.t('E-mail address is not valid'))
      } else if (phone.length > 0 && !phoneRegex.test(phone)) {
        this.props.alertWarn(this.context.t('Phone number is not valid'))
      } else {
        this.props.updateCompanyInfo(this.props.currentCompany.company.id, {
          email: email,
          phone: phone,
          www: www,
          address: address
        })
        this.setState({
          changedContactInfo: false
        })
      }
    }

    if (this.state.changedPaymentAccounts) {
      this.props.updateCompanyInfo(this.props.currentCompany.company.id, {
        zus_account: this.state.zus_account,
        tax_account: this.state.tax_account,
      })
      this.setState({
        changedPaymentAccounts: false
      })
    }
  }

  reverseChanges = section => {
    if (section === 'main') {
      this.setState({
        name: this.props.currentCompany.company.name || '',
        shortName: this.props.currentCompany.company.code || '',
        vatId: this.props.currentCompany.company.vat_id || '',
        regon: this.props.currentCompany.company.regon || '',
        changedMainInfo: false
      })
    } else if (section === 'contact') {
      this.setState({
        email: this.props.currentCompany.company.email || '',
        phone: this.props.currentCompany.company.phone || '',
        www: this.props.currentCompany.company.www || '',
        address: this.props.currentCompany.company.address || '',
        changedContactInfo: false
      })
    } else if (section === 'paymentAccounts') {
      this.setState({
        tax_account: this.props.currentCompany.company.tax_account || '',
        zus_account: this.props.currentCompany.company.zus_account || '',
        changedPaymentAccounts: false,
      })
    }
  }

  changeCurrentCompany = (e, index) => {
    e.preventDefault()
    console.log(index)

    this.props.setCurrentCompany(this.props.userCompanies[index])

    this.setState({
      currentProject: {},
      showCompaniesList: false,
      addAccountMode: false
    })

    const { groupedProjects } = this.props
    if (groupedProjects) {
      var currentGroup = groupedProjects.find(e => e.company.id === this.props.userCompanies[index].company_id)
      if (currentGroup && currentGroup.projects) {
        this.props.switchDashboardViewModel(currentGroup.projects[0].id)
      }
    }
  }

  toggleCompaniesList = () => {
    this.setState({
      showCompaniesList: !this.state.showCompaniesList
    })
  }

  addTags = () => {
    const { tags } = this.state
    const { currentCompany } = this.props

    if (tags.length > 0) {
      var tagsArray = tags.split(',')
      var uniqTagsArray = []
      var oldTags = 0

      tagsArray.forEach(t => {
        t = t.trim()

        if (currentCompany.company && currentCompany.company.tags_list && currentCompany.company.tags_list.includes(t)) {
          oldTags++
        } else if (t.length > 0) {
          uniqTagsArray.push(t)
        }
      })


      if (oldTags === tagsArray.length) {
        this.props.alertWarn(this.context.t('Please provide tags that are not yet on the list'))

        this.setState({
          tags: ''
        })
      } else {
        this.props.updateCompanyInfo(currentCompany.company.id, { tags_list: JSON.stringify(uniqTagsArray), remove_tag: false })
      }
    } else {
      this.props.alertWarn(this.context.t('First you must enter at least one tag'))
    }

  }

  removeTag = tag => {
    const { currentCompany } = this.props

    var currentTags = currentCompany.company.tags_list

    currentTags = currentTags.filter(t => t !== tag)

    this.props.updateCompanyInfo(currentCompany.company.id, { tags_list: JSON.stringify(currentTags), remove_tag: true })
  }

  changeTagsLimitations = newLimitation => {
    const { currentCompany } = this.props
    this.setState({
      tagsCreationLimitation: newLimitation
    })

    this.props.updateCompanyInfo(currentCompany.company.id, { tags_creation_limitation: newLimitation })
  }

  toogleAccessLevelForTags = accessLevel => {
    const { currentCompany } = this.props
    var { tagsCreationLimitation } = this.state

    if (!['EVERYONE', 'NOONE'].includes(tagsCreationLimitation)) {
      if (tagsCreationLimitation.includes(accessLevel)) tagsCreationLimitation = tagsCreationLimitation.replace(accessLevel, '')
      else tagsCreationLimitation += `,${accessLevel}`

      tagsCreationLimitation = tagsCreationLimitation.replace(',,', ',').replace(/^,/, '').replace(/,$/, '')
      this.setState({
        tagsCreationLimitation: tagsCreationLimitation
      })

      this.props.updateCompanyInfo(currentCompany.company.id, { tags_creation_limitation: tagsCreationLimitation })
    }
  }

  showOverlay = () => {
    const { overlayPreferance } = this.props
    this.setOverlaySize()
    overlayPreferance['adminCompany'] = true
    this.props.toggleOverlay(overlayPreferance)
  }

  showCurrencyList = (e, i) => {
    var up = false
    var pos = e.target.getClientRects()[0];
    var left = pos.left;
    var top = pos.top;
    console.log(left, top, up)
    console.log(window.innerHeight)
    if (top + 130 > window.innerHeight) {
      up = true
    }

    this.setState({
      bankContainer: i,
      showCurrencyList: true,
      currencyListPosX: left,
      currencyListPosY: top,
      currencyListFlipUp: up,
    })
  }

  toggleCurrencyList = (e, i) => {
    if (this.state.showCurrencyList && this.state.bankContainer === i) {
      this.setState({
        bankContainer: -1,
        showCurrencyList: false,
        currencyListPosX: 0,
        currencyListPosY: 0,
        currencyListFlipUp: false,
      })
    } else {
      var up = false
      var pos = e.target.getClientRects()[0];
      var left = pos.left;
      var top = pos.top;
      console.log(left, top, up)
      console.log(window.innerHeight)
      if (top + 130 > window.innerHeight) {
        up = true
      }

      this.setState({
        bankContainer: i,
        showCurrencyList: true,
        currencyListPosX: left,
        currencyListPosY: top,
        currencyListFlipUp: up,
      })
    }
  }

  toggleAccountList = (e, i) => {
    const { showAccountList, bankContainer, suggestedAccounts } = this.state
    if (suggestedAccounts && suggestedAccounts.length > 0) {
      if (showAccountList && bankContainer === i) {
        this.setState({
          bankContainer: -1,
          showAccountList: false,
          currencyListPosX: 0,
          currencyListPosY: 0,
          currencyListFlipUp: false,
        })
      } else {
        var up = false
        var pos = e.target.getClientRects()[0];
        var left = pos.left;
        var top = pos.top;
        if (top + 130 > window.innerHeight) {
          up = true
        }

        this.setState({
          bankContainer: i,
          showAccountList: true,
          currencyListPosX: left,
          currencyListPosY: top,
          currencyListFlipUp: up,
        })
      }
    }
  }

  selectCurrency = (currency, i) => {
    if (i !== -1) {
      var banks = JSON.parse(JSON.stringify(this.state.banks))
      banks[i].currency = currency

      this.setState({
        banks: banks,
        showCurrencyList: false,
        currencyListPosX: 0,
        currencyListPosY: 0,
        currencyListFlipUp: false,
      })

      if (banks[i].id !== 'new') this.props.updateBankInfo(banks[i].id, this.props.currentCompany.company_id, banks[i])
    }
  }

  selectAccount = (number, i) => {
    if (i !== -1) {
      var banks = JSON.parse(JSON.stringify(this.state.banks))
      banks[i].account_number = number
      var foundBank = this.checkAccountNumber(number)

      if (foundBank) {
        banks[i].bank_name = foundBank.name

        if (foundBank.bic) {
          banks[i].bic = foundBank.bic
        }
      }

      this.setState({
        banks: banks,
        showAccountList: false,
        currencyListPosX: 0,
        currencyListPosY: 0,
        currencyListFlipUp: false,
      })
    }
  }

  closeAllSugestionLists = e => {
    if (e.keyCode === 27 || (this.state.showCompaniesList && !this.hasAncestor(e.target, "copaniesList"))) {
      this.setState({
        currentProject: {},
        showCompaniesList: false,
      })
    }
    if (e.keyCode === 27 || (this.state.showCurrencyList && !this.hasAncestor(e.target, "currencyContainer"))) {
      this.setState({
        showCurrencyList: false,
      })
    }

    if (e.keyCode === 27 || (this.state.showAccountList && !this.hasAncestor(e.target, "accountContainer"))) {
      this.setState({
        showAccountList: false,
      })
    }

    if (e.keyCode === 27 || (this.state.showCompanyMembersList && !this.hasAncestor(e.target, "representative"))) {
      this.setState({
        showCompanyMembersList: false,
      })
    }

    if (this.incomeTaxSymbolContainerRef && !this.incomeTaxSymbolContainerRef.current.contains(e.target)) {
      this.setState({
        showIncomeTaxSymbolList: false
      })
    }

    if (this.incomeTaxPeriodContainerRef && !this.incomeTaxPeriodContainerRef.current.contains(e.target)) {
      this.setState({
        showIncomeTaxPeriodList: false
      })
    }

    if (this.vatTaxSymbolContainerRef && !this.vatTaxSymbolContainerRef.current.contains(e.target)) {
      this.setState({
        showVatTaxSymbolList: false
      })
    }

    if (this.vatTaxPeriodContainerRef && !this.vatTaxPeriodContainerRef.current.contains(e.target)) {
      this.setState({
        showVatTaxPeriodList: false
      })
    }
  }

  hasAncestor = (element, id) => {
    while (element) {
      if (element.id && element.id === id) return true

      element = element.parentNode
    }

    return false
  }

  addAccount = () => {
    if (!this.state.addAccountMode) {
      var { banks, vatId } = this.state
      banks.push({
        id: 'new',
        account_number: '',
        bank_name: '',
        bic: '',
        currency: '',
        name: '',
      })
      this.setState({
        banks: banks,
        addAccountMode: true
      })

      var vatIdToCheck = vatId.replace(/[^0-9]/gm, '')
      axios.get(`https://wl-api.mf.gov.pl/api/search/nip/${vatIdToCheck}?date=${moment().format("YYYY-MM-DD")}`, { timeout: 15000 }).then(response => {
        if (response && response.data && response.data.result && response.data.result.subject) {
          var foundCompany = response.data.result.subject
          if (foundCompany.statusVat === 'Czynny') {
            var whiteListAccounts = foundCompany.accountNumbers
            var formatedAccounts = []
            whiteListAccounts.forEach(number => {
              formatedAccounts.push(this.formatAccountNumber(number))
            })

            console.log(formatedAccounts)

            this.setState({
              suggestedAccounts: formatedAccounts
            })
          }
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }

  addInvoiceNumberingSeries = () => {
    if (!this.state.addInvoiceNumberingSeriesMode) {
      var { invoiceNumberingSeries } = this.state
      invoiceNumberingSeries.push({
        id: 'new',
        invoice_mask: "",
        correction_mask: "",
        proforma_mask: "",
      })
      this.setState({
        invoiceNumberingSeries: invoiceNumberingSeries,
        addInvoiceNumberingSeriesMode: true
      })
    }
  }

  formatAccountNumber = (number) => {
    if (number) {
      number = number.replace(/[^0-9A-Z]/gm, '')
      if (number.length === 26) {
        return (`PL${number.slice(0, 2)} ${number.slice(2, 6)} ${number.slice(6, 10)} ${number.slice(10, 14)} ${number.slice(14, 18)} ${number.slice(18, 22)} ${number.slice(22, 26)}`)
      } else if (number.length === 28) {
        return (`${number.slice(0, 4)} ${number.slice(4, 8)} ${number.slice(8, 12)} ${number.slice(12, 16)} ${number.slice(16, 20)} ${number.slice(20, 24)} ${number.slice(24, 28)}`)
      } else {
        return (number)
      }
    } else {
      return number
    }
  }

  cancelAddingAccount = () => {
    if (this.state.addAccountMode) {
      var { banks } = this.state
      banks.pop()
      this.setState({
        banks: banks,
        addAccountMode: false
      })
    }
  }

  cancelAddingInvoiceNumberingSeries = () => {
    if (this.state.addInvoiceNumberingSeriesMode) {
      var { invoiceNumberingSeries } = this.state
      invoiceNumberingSeries.pop()
      this.setState({
        invoiceNumberingSeries: invoiceNumberingSeries,
        addInvoiceNumberingSeriesMode: false
      })
    }
  }

  bankIsValid = i => {
    var valid = true
    const { banks } = this.state

    if (banks[i].account_number === '') {
      this.props.alertWarn(this.context.t('Account number is required'))
      valid = false
    } else if (!/^[A-Z]*$/.test(banks[i].account_number[0]) || !/^[A-Z]*$/.test(banks[i].account_number[1])) {
      this.props.alertWarn(this.context.t('Enter the account number in the form of an IBAN number - with the prefix being the country code'))
      valid = false
    }

    if (banks[i].bank_name === '') {
      this.props.alertWarn(this.context.t('Bank name is required'))
      valid = false
    }

    if (banks[i].bic === '') {
      this.props.alertWarn(this.context.t('BIC/SWIFT is required'))
      valid = false
    }

    if (banks[i].currency === '') {
      this.props.alertWarn(this.context.t('Currency is required'))
      valid = false
    }

    if (banks[i].name === '') {
      this.props.alertWarn(this.context.t('Bank account name is required'))
      valid = false
    }

    return valid
  }

  invoiceNumberingSeriesIsValid = i => {
    var valid = true
    const { invoiceNumberingSeries } = this.state

    if (invoiceNumberingSeries[i]) {
      if (invoiceNumberingSeries[i].invoice_mask) {
        var maskParts = invoiceNumberingSeries[i].invoice_mask.split('/')
        if (maskParts.length === 1) {
          valid = false
          this.props.alertWarn(this.context.t("The numbering mask should contain at least two of the components - MM, YYYY, 00 - separated by a slash (\"/\")"))
        }
      }

      if (invoiceNumberingSeries[i].correction_mask) {
        var maskParts = invoiceNumberingSeries[i].correction_mask.split('/')
        if (maskParts.length === 1) {
          valid = false
          this.props.alertWarn(this.context.t("The numbering mask should contain at least two of the components - MM, YYYY, 00 - separated by a slash (\"/\")"))
        }
      }

      if (invoiceNumberingSeries[i].proforma_mask) {
        var maskParts = invoiceNumberingSeries[i].proforma_mask.split('/')
        if (maskParts.length === 1) {
          valid = false
          this.props.alertWarn(this.context.t("The numbering mask should contain at least two of the components - MM, YYYY, 00 - separated by a slash (\"/\")"))
        }
      }

    } else {
      valid = false
    }

    return valid
  }

  createNewBankForCompany = (e) => {
    e.preventDefault()
    if (this.state.addAccountMode) {
      var { banks } = this.state

      if (this.bankIsValid(banks.length - 1)) {

        this.props.addNewBankForCompany(this.props.currentCompany.company_id, banks[banks.length - 1], (success, newBankId) => {
          if (success) {
            banks[banks.length - 1].id = newBankId
            this.setState({
              addAccountMode: false
            })
          }
        })
      }
    }
  }

  checkChangesAndSubmitBankInfo = (i) => {
    const { banks } = this.state
    if (banks && banks[i] && banks[i].id !== 'new') {
      if (this.bankIsValid(i)) {
        this.props.updateBankInfo(banks[i].id, this.props.currentCompany.company_id, banks[i])
      }
    }
  }

  showDeleteBankAccountModal = (e, i) => {
    e.preventDefault()

    this.setState({
      showDeleteBankAccountModal: true,
      bankIndexToDalete: i
    })
  }

  closeDeleteBankAccountModal = (e) => {
    e.preventDefault()

    this.setState({
      showDeleteBankAccountModal: false,
      bankIndexToDalete: -1
    })
  }

  deleteBankAccount = (e) => {
    e.preventDefault()

    var { banks, bankIndexToDalete } = this.state
    if (banks[bankIndexToDalete].id !== 'new') {
      this.props.deleteBankFromCompany(banks[bankIndexToDalete].id, this.props.currentCompany.company_id, success => {
        if (success) {
          banks.splice(bankIndexToDalete, 1)
          this.setState({
            banks: banks,
            bankIndexToDalete: -1
          })
        }
      })
    } else {
      banks.splice(bankIndexToDalete, 1)
      this.setState({
        banks: banks,
        bankIndexToDalete: -1
      })
    }

    this.setState({
      showDeleteBankAccountModal: false,
      bankIndexToDalete: -1
    })
  }

  createNewInvoiceNumberingSeriesForCompany = (e) => {
    e.preventDefault()
    if (this.state.addInvoiceNumberingSeriesMode) {
      var { invoiceNumberingSeries } = this.state

      if (this.invoiceNumberingSeriesIsValid(invoiceNumberingSeries.length - 1)) {

        this.props.addNewInvoiceNumberingSeries(this.props.currentCompany.company_id, invoiceNumberingSeries[invoiceNumberingSeries.length - 1], (success, newSeriesId) => {
          if (success) {
            invoiceNumberingSeries[invoiceNumberingSeries.length - 1].id = newSeriesId
            this.setState({
              addInvoiceNumberingSeriesMode: false
            })
          }
        })
      }
    }
  }

  checkChangesAndSubmitNumberingSeries = (i) => {
    const { invoiceNumberingSeries } = this.state
    if (invoiceNumberingSeries && invoiceNumberingSeries[i] && invoiceNumberingSeries[i].id && invoiceNumberingSeries[i].id !== 'new') {
      if (this.invoiceNumberingSeriesIsValid(i)) {
        this.props.updateInvoiceNumberingSeries(invoiceNumberingSeries[i].id, this.props.currentCompany.company_id, invoiceNumberingSeries[i])
      }
    }
  }

  showDeleteInvoiceNumberingSeriesPopup = (e, i) => {
    e.preventDefault()

    this.setState({
      showDeleteInvoiceNumberingSeriesPopup: true,
      numberingSeriesIndexToDalete: i
    })
  }

  closeDeleteInvoiceNumberingSeriesPopup = (e) => {
    e.preventDefault()

    this.setState({
      showDeleteInvoiceNumberingSeriesPopup: false,
      numberingSeriesIndexToDalete: -1
    })
  }

  deleteInvoiceNumberingSeries = (e) => {
    e.preventDefault()

    var { invoiceNumberingSeries, numberingSeriesIndexToDalete } = this.state
    this.props.deleteInvoiceNumberingSeriesFromCompany(invoiceNumberingSeries[numberingSeriesIndexToDalete].id, this.props.currentCompany.company_id, success => {
      if (success) {
        invoiceNumberingSeries.splice(numberingSeriesIndexToDalete, 1)
        this.setState({
          invoiceNumberingSeries: invoiceNumberingSeries,
          numberingSeriesIndexToDalete: -1
        })
      }
    })

    this.setState({
      showDeleteInvoiceNumberingSeriesPopup: false
    })
  }

  toogleCompanyMembersList = () => {
    const { showCompanyMembersList } = this.state
    this.setState({
      showCompanyMembersList: !showCompanyMembersList
    })

    const { companyWorkers, currentCompany } = this.props
    if (!companyWorkers || companyWorkers.length === 0) {
      this.props.getAllCompanyWorkers(currentCompany.company_id)
    }
  }

  toggleNewCompanyView = () => {
    this.setState({
      showNewCompanyView: !this.state.showNewCompanyView,
      newCompanyNip: '',
      newCompanyName: '',
      newCompanyShortName: '',
      newCompanyBinderName: '',
      newCompanyBinderShortName: ''
    })
  }

  selectRepresentative = (newRepresentativeUser) => {
    const { currentCompany } = this.props

    if (newRepresentativeUser) {
      if (newRepresentativeUser.id === currentCompany.company.representative.id) {
        this.setState({
          newRepresentative: '',
          newRepresentativeId: 0,
          changedRepresentative: false,
          showCompanyMembersList: false
        })
      } else {
        this.setState({
          newRepresentative: newRepresentativeUser.first_name ? `${newRepresentativeUser.first_name} ${newRepresentativeUser.last_name}` : newRepresentativeUser.email,
          newRepresentativeId: newRepresentativeUser.id,
          changedRepresentative: true,
          showCompanyMembersList: false
        })
      }
    }
  }

  changeRepresentative = (e) => {
    e.preventDefault()
    const { newRepresentativeId, changedRepresentative } = this.state
    const { currentCompany } = this.props

    if (changedRepresentative) {
      this.props.changeCompanyRepresentative(currentCompany.company_id, newRepresentativeId)
    }
  }

  showDeleteLogoPopup = () => {
    this.setState({
      showDeleteLogoPopup: true,
    });
  }

  saveNewCompany = () => {
    var valid = true
    const { newCompanyBinderShortName, newCompanyBinderName, newCompanyShortName, newCompanyName, newCompanyNip, newProjectCodeIsUnique } = this.state
    if (newCompanyBinderShortName.length < 3) {
      this.props.alertWarn(this.context.t("Invalid binder short name"))
      valid = false
    } else if (!newProjectCodeIsUnique) {
      this.props.alertWarn(this.context.t("Binder short name must be unique"))
      valid = false
    }

    if (newCompanyBinderName.length === 0) {
      this.props.alertWarn(this.context.t("Invalid binder name"))
      valid = false
    }

    if (newCompanyShortName.length === 0) {
      this.props.alertWarn(this.context.t("Invalid company short name"))
      valid = false
    }

    if (newCompanyName.length === 0) {
      this.props.alertWarn(this.context.t("Invalid company name"))
      valid = false
    }

    if (newCompanyNip.length === 0) {
      this.props.alertWarn(this.context.t("Invalid VAT-ID number"))
      valid = false
    }
    if (valid) {
      this.props.registerNextCompany(newCompanyNip, newCompanyName, newCompanyShortName, newCompanyBinderName, newCompanyBinderShortName, (success, companyId, projectId) => {
        if (success) {
          this.setState({
            registeredNewCompany: true,
            newCompanyId: companyId,
            newProjectId: projectId,
          })
          this.props.getUserProjects()
          this.props.getUserCompanies()
        }
      })
    }
  }

  completeNewCompanySetup = () => {
    this.setState({
      showNewCompanyView: false
    })
    const { newCompanyId, newProjectId } = this.state
    this.props.switchDashboardViewModel(newProjectId)

    var { userCompanies, currentCompany } = this.props

    if (currentCompany && currentCompany.id !== newCompanyId) {
      var companyIndex = arrayObjectIndexOf(userCompanies, newCompanyId, 'company_id')
      console.log(companyIndex)
      if (companyIndex > -1) {
        if (userCompanies[companyIndex].user_role === 'ADMIN') {
          this.props.setCurrentCompany(userCompanies[companyIndex])
        }
      }
    }
  }

  generateNewColumnLetters = selectedColumns => {
    const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]

    selectedColumns.forEach((field, index) => {
      if (index < alphabet.length) {
        field.column = alphabet[index]
      } else {
        var firstCharIndex = 0, secondCharIndex = 0
        firstCharIndex = Math.floor(index / alphabet.length) - 1
        secondCharIndex = (index % alphabet.length)
        field.column = `${alphabet[firstCharIndex]}${alphabet[secondCharIndex]}`
      }
    })

    return selectedColumns
  }

  handleDrop = (item, dromTargetName) => {
    const { availableExcelColumns, selectedExcelColumns } = this.state
    if (item.column && dromTargetName === 'available') {
      //remove
      console.log('remove item')
      console.log(selectedExcelColumns)
      console.log(item)
      var currentAvailable = [].concat(availableExcelColumns, [{ ...item, column: null }])
      var currentSelected = selectedExcelColumns.filter(column => column.dataType !== item.dataType)

      currentSelected = this.generateNewColumnLetters(currentSelected)

      currentSelected = currentSelected.filter(i => !i.isPseudo)
      console.log(currentSelected)
      this.setState({
        availableExcelColumns: currentAvailable,
        selectedExcelColumns: currentSelected
      })

      this.saveExcelReportsChanges()
    } else if (!item.column && dromTargetName === 'selected') {
      //add
      var currentAvailable = availableExcelColumns.filter(column => column.dataType !== item.dataType)
      var currentSelected = [...selectedExcelColumns]

      if (currentSelected.find(column => column.dataType === item.dataType)) {
        currentSelected.forEach(field => {
          if (field.isPseudo) delete field.isPseudo
        })
      } else {
        currentSelected.push(item)
      }

      currentSelected = currentSelected.filter(i => !i.isPseudo)
      currentSelected = this.generateNewColumnLetters(currentSelected)

      this.setState({
        availableExcelColumns: currentAvailable,
        selectedExcelColumns: currentSelected
      })

      this.saveExcelReportsChanges()
    } else {
      //sort
      var currentSelected = [...selectedExcelColumns]
      currentSelected = selectedExcelColumns.filter(i => !i.isPseudo)
      this.setState({
        selectedExcelColumns: currentSelected
      })

      this.saveExcelReportsChanges()
    }
  }

  sortSelected = (dragIndex, hoverIndex) => {
    const { selectedExcelColumns } = this.state
    const dragItem = selectedExcelColumns[dragIndex]

    var tempSelected = selectedExcelColumns
    tempSelected.splice(dragIndex, 1)
    tempSelected.splice(hoverIndex, 0, dragItem)

    tempSelected = this.generateNewColumnLetters(tempSelected)

    this.setState({
      selectedExcelColumns: tempSelected
    });
  }

  addAndSort = (item, hoverIndex) => {
    const { selectedExcelColumns } = this.state
    var dragItem = { ...item }
    dragItem.isPseudo = true

    var tempSelected = selectedExcelColumns.filter(i => !i.isPseudo)
    tempSelected.splice(hoverIndex, 0, dragItem)

    tempSelected = this.generateNewColumnLetters(tempSelected)

    this.setState({
      selectedExcelColumns: tempSelected
    });
  }

  removeAllPseudoElements = () => {
    const { selectedExcelColumns } = this.state
    var currentSelected = [...selectedExcelColumns]
    currentSelected = selectedExcelColumns.filter(i => !i.isPseudo)
    this.setState({
      selectedExcelColumns: currentSelected
    })

    this.saveExcelReportsChanges()
  }

  onLabelInputChange = (e, index) => {
    const { selectedExcelColumns } = this.state
    var currentSelected = [...selectedExcelColumns]

    currentSelected[index].label = e.target.value
    this.setState({
      selectedExcelColumns: currentSelected
    })
  }

  saveExcelReportsChanges = () => {
    const { selectedExcelColumns } = this.state
    var excelFormatToSave = []
    selectedExcelColumns.forEach(item => {
      excelFormatToSave.push({
        dataType: item.dataType,
        label: item.label || this.context.t(item.dataTypeName),
        dataTypeName: item.dataTypeName,
        column: item.column,
      })
    })
    this.props.updateCompanyInfo(this.props.currentCompany.company.id, {
      excel_reports: JSON.stringify(excelFormatToSave)
    })
  }

  integrateWithKsef = () => {
    this.setState({
      showKsefIntegration: true
    })
  }

  editKsefIntegration = () => {
    this.setState({
      showKsefIntegrationEditing: true
    })
  }

  toggleList = listName => {
    this.setState({
      [listName]: !this.state[listName]
    })
  }

  showVatTaxSymbolList = listName => {
    this.setState({
      [listName]: !this.state[listName]
    })
  }

  onListSelect = (value, varName, listToHide) => {
    this.setState({
      [varName]: value,
      [listToHide]: false
    })

    this.props.updateCompanyInfo(this.props.currentCompany.company_id, {
      [varName]: value,
    })
  }

  capitalizeFirstLetter = (string) => {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

    return ''
  }

  toggleConnectToPsd2AisPopup = show => {
    this.setState({
      showConnectToPsd2AisPopup: show
    })
  }

  showPsd2DisconnectMessage = consentId => {
    const { banks } = this.state
    var bankstoDisconnect = []
    banks.forEach(b => {
      if (b.psd2_ais_consent_request_id === consentId) bankstoDisconnect.push(b)
    })
    this.setState({
      psd2ConsentIdToDisconnect: consentId,
      banksToDisconnectFromPsd2: bankstoDisconnect,
      showPsd2DisconnectWindow: true,
    })
  }

  hidePsd2DisconnectWindow = () => {
    this.setState({
      psd2ConsentIdToDisconnect: '',
      banksToDisconnectFromPsd2: [],
      showPsd2DisconnectWindow: false,
    })
  }

  disconnectFromPsd2 = () => {
    const { currentCompany } = this.props
    const { psd2ConsentIdToDisconnect } = this.state
    this.props.disconnectFromPsd2(psd2ConsentIdToDisconnect, currentCompany.company_id, success => {
      if (success) {
        const { banks } = this.state
        var tempBanks = [...banks]
        tempBanks.forEach(b => {
          if (b.psd2_ais_consent_request_id === psd2ConsentIdToDisconnect) {
            b.psd2_ais_consent_request_id = ''
            b.psd2_is_connected_to_ais = false
          }
        })

        this.setState({
          banks: tempBanks
        })
      }
      this.hidePsd2DisconnectWindow()
    })
  }

  render() {
    const {
      cursorWait,
      showCompaniesList,
      currentRepresentative, newRepresentative, newRepresentativeId, changedRepresentative, showCompanyMembersList,
      name, shortName, vatId, regon,
      banks, addAccountMode, showDeleteBankAccountModal, showConnectToPsd2AisPopup, showPsd2DisconnectWindow, psd2ConsentIdToDisconnect, banksToDisconnectFromPsd2,
      showCurrencyList, bankContainer, currencyListFlipUp, suggestedAccounts, showAccountList,
      email, phone, www, address,
      tags, tagsCreationLimitation,
      showDeleteLogoPopup,
      gotOverlayPossitions, overlayPossitions,
      zus_account, tax_account, income_tax, income_tax_period, vat_tax, vat_tax_period,
      showIncomeTaxSymbolList, showIncomeTaxPeriodList, showVatTaxSymbolList, showVatTaxPeriodList,
      changingCompanyLogoInProgress, logoUrl,
      showNewCompanyView, registeredNewCompany,
      newCompanyNip, newCompanyName, newCompanyShortName, newCompanyBinderName, newCompanyBinderShortName, newProjectCodeIsUnique,
      availableExcelColumns, selectedExcelColumns,
      invoiceNumberingSeries, addInvoiceNumberingSeriesMode,
      showKsefIntegration, showKsefIntegrationEditing
    } = this.state
    const { currentCompany, userCompanies, overlayPreferance, loadingScreen, companyWorkers, deleteCompanyLogo, user, tabIsActive } = this.props

    if (tabIsActive || !user.new_easydocs_version) {
      if (currentCompany && currentCompany.company) {
        return (
          <div className={`company-container ${cursorWait ? 'cursor-wait' : ''}`} ref="companyContainer" onClick={e => this.closeAllSugestionLists(e)}>

            <div className='show-overlay' onClick={() => this.showOverlay()} data-for="info-context-overlay" data-tip="show"></div>
            {
              gotOverlayPossitions && overlayPreferance['adminCompany'] ? (
                <OverlayInstructions possitions={overlayPossitions} name="adminCompany" height={this.refs.companyContainer.getBoundingClientRect().height + 20 + 110} />
              ) : (
                null
              )
            }

            {
              loadingScreen ? (
                <div className="loading-screen">
                  <div className="activity-indicator">
                    <Spinner size={50} speed={0.8} color={"#ffffff"} />
                  </div>
                </div>
              ) : (
                null
              )
            }

            {
              showDeleteLogoPopup ? (
                <div className="delete-invoice-popup popup">
                  <div className="popup-card">
                    <div className="header">
                      {this.context.t("Are you sure you want to delete this logo?")}
                    </div>
                    <div className="action-buttons">
                      <div className="close-button btn" onClick={() => this.setState({ showDeleteLogoPopup: false })} > {this.context.t("Cancel")} </div>
                      <div className={`delete btn ${changingCompanyLogoInProgress ? "not-active" : ""}`} onClick={(e) => this.deleteCompanyLogo()} > {this.context.t("Delete")} </div>
                    </div>
                  </div>
                </div>
              ) : null
            }

            {
              showDeleteBankAccountModal ? (
                <div className="delete-invoice-popup popup">
                  <div className="popup-card">
                    <div className="header">{this.context.t('Are you sure you want to remove this bank account?')}</div>
                    <div className="action-buttons">
                      <div className="btn" onClick={e => this.closeDeleteBankAccountModal(e)}>{this.context.t('Cancel')}</div>
                      <div className="btn delete" onClick={e => this.deleteBankAccount(e)}>{this.context.t('Delete')}</div>
                    </div>
                  </div>
                </div>
              ) : (
                null
              )
            }

            {
              showConnectToPsd2AisPopup ? (
                <ConnectToPsd2AisPopup
                  accounts={banks}
                  companyId={currentCompany.company_id}
                  cancel={() => this.toggleConnectToPsd2AisPopup(false)}
                />
              ) : null
            }

            {
              showPsd2DisconnectWindow ? (
                <RevokeConnectToPsd2AisPopup
                  banksToDisconnectFromPsd2={banksToDisconnectFromPsd2}
                  revokeConsent={this.disconnectFromPsd2}
                  cancel={this.hidePsd2DisconnectWindow}
                />
              ) : null
            }

            {
              showNewCompanyView ? (
                <div className="new-company-window">
                  {
                    registeredNewCompany ? (
                      <div className="successfull-register">
                        <div className="image"></div>
                        <div className="text">{this.context.t('You have connected your next company to EasyDocs. Now complete its configuration and add coworkers in the "Binders" tab.')}</div>
                        <div className="button" onClick={() => this.completeNewCompanySetup()}>{this.context.t('Complete the setup')}</div>
                      </div>
                    ) : (
                      <div className="new-company-view">
                        <div className="exit-button" onClick={() => this.toggleNewCompanyView()}></div>
                        <h3 className="company-text">{this.context.t("Company")}</h3>
                        <div className="company-group">
                          <input type="text" name="newCompanyNip" id="newCompanyNip" value={newCompanyNip} onChange={this.onInputChange} />
                          <label htmlFor="newCompanyNip">{this.context.t('NIP')}</label>
                          <span className="info" data-tip="show" data-for="vatIdTooltip"></span>
                          <ReactTooltip id="vatIdTooltip" className="default-tooltip info-tooltip" place="bottom" effect="solid">
                            <p>{this.context.t('Enter the NIP number and we will complete the remaining data from GUS database.')}</p>
                          </ReactTooltip>
                        </div>
                        <div className="company-group">
                          <input type="text" name="newCompanyName" id="newCompanyName" value={newCompanyName} onChange={this.onInputChange} />
                          <label htmlFor="newCompanyName">{this.context.t('Company name')}</label>
                        </div>
                        <div className="company-group code">
                          <input type="text" name="newCompanyShortName" id="newCompanyShortName" value={newCompanyShortName} onChange={this.onInputChange} />
                          <label htmlFor="newCompanyShortName">{this.context.t('Company short name')}</label>
                        </div>
                        <h3>{this.context.t("Main binder in the company")}</h3>
                        <div className="company-group">
                          <input type="text" name="newCompanyBinderName" id="newCompanyBinderName" value={newCompanyBinderName} onChange={this.onInputChange} />
                          <label htmlFor="newCompanyBinderName">{this.context.t('Binder name')}</label>
                        </div>
                        <div className="company-group">
                          <input type="text" name="newCompanyBinderShortName" id="newCompanyBinderShortName" value={newCompanyBinderShortName} onChange={this.onInputChange} />
                          <label htmlFor="newCompanyBinderShortName">{this.context.t('Binder short name')}</label>
                        </div>

                        {
                          newCompanyBinderShortName.length >= 3 ? (
                            !newProjectCodeIsUnique ? (
                              <div className="not-unique">
                                {this.context.t('This binder short name is already taken. Enter a different name.')}
                              </div>
                            ) : (
                              newProjectCodeIsUnique ? (
                                <div className="unique">
                                  <div className="info">
                                    <h3>{this.context.t('Remember! Binders name and its short name cannot be edited after it has been created')}</h3>
                                    <p>{this.context.t('You can import documents into this binder by sending e-mails with attachments to this address: ')}<span className="email">{`${newCompanyBinderShortName}@app.easydocs.pl`}</span></p>
                                  </div>
                                  <div className="img"></div>
                                  <div className="disclaimer">
                                    {this.context.t('After sending an e-mail with an attachment, you will receive a report on the sending - pay attention to whether all files have been successfully imported.')}
                                  </div>
                                </div>
                              ) : (
                                null
                              )
                            )
                          ) : (
                            newCompanyBinderShortName ? (
                              <div className="not-unique">
                                {this.context.t('The binder short name must be at least 3 characters long.')}
                              </div>
                            ) : null
                          )
                        }
                        <div className="save-company-button" onClick={() => this.saveNewCompany()}>{this.context.t("Connect new company")}</div>
                      </div>

                    )
                  }
                </div>
              ) : (
                null
              )
            }

            {
              showKsefIntegration ? (
                <KsefIntegrationWindow companyId={currentCompany.company_id} close={() => this.setState({ showKsefIntegration: false })} />
              ) : null
            }

            {
              showKsefIntegrationEditing ? (
                <KsefIntegrationEditingWindow companyId={currentCompany.company_id} ksefIf={currentCompany.company.ksef_integration_id} close={() => this.setState({ showKsefIntegrationEditing: false })} />
              ) : null
            }

            <SettingsTabsBar />

            <div className="page-header-second">
              <div className="your-companies-label">{this.context.t('Your companies:')}</div>
              <div className="company-switcher" ref="companySelectionRef">
                <div className="current" onClick={() => this.toggleCompaniesList()}>
                  <h2>{currentCompany && currentCompany.company ? currentCompany.company.code : ''}</h2>
                  <span className={`expend-icon ${showCompaniesList ? 'expended' : ''}`}></span>
                </div>
                {
                  showCompaniesList ? (
                    <div id="copaniesList" className="companies-list">
                      <div className="header">
                        {this.context.t('Choose company')}
                      </div>
                      <SimpleBar style={{ maxHeight: 'calc(75vh - 70px)', width: '100%' }}>
                        <ul>
                          {
                            userCompanies.map((company, i) => {
                              if (company.user_role === 'ADMIN') {
                                return (
                                  <li className={`${currentCompany.id === company.id ? 'current-selected' : ''}`} onClick={e => this.changeCurrentCompany(e, i)}>
                                    {company.company.code}
                                  </li>
                                )
                              } else {
                                return null
                              }
                            })
                          }
                        </ul>
                      </SimpleBar>
                    </div>
                  ) : (
                    null
                  )
                }
              </div>
              <div className="new-company-button" onClick={() => this.toggleNewCompanyView()} data-tip="show" data-for="newCompanyTooltip">{this.context.t('Connect new company')}</div>
              <ReactTooltip id="newCompanyTooltip" className="default-tooltip info-tooltip" place="bottom" effect="solid">
                <p>{this.context.t('In EasyDocs you can have more of your companies, the fee for the next one according to the price list - you do not pay for users with the same e-mail addresses')}</p>
              </ReactTooltip>
            </div>

            <div className="company-form-card" ref="companyRepresentativeRef">
              <form onSubmit={this.changeRepresentative}>
                <div className="header representative">
                  <h3>{this.context.t('Company representative for administrative contact:')}</h3>

                  <div className="representative" id="representative">
                    <div className="current" onClick={() => this.toogleCompanyMembersList()}>
                      {changedRepresentative ? newRepresentative : currentRepresentative}
                      <span className={`arrow ${showCompanyMembersList ? 'extended' : ''}`}></span>
                    </div>

                    <ul className={`${showCompanyMembersList ? 'extended' : ''}`}>
                      {
                        companyWorkers && companyWorkers.length > 0 ? (
                          companyWorkers.map(companyGroup => {
                            return (
                              companyGroup.company && companyGroup.company.id === currentCompany.id ? (
                                companyGroup.members.map((user, i) => {
                                  return (
                                    <li key={`worker-${i}`} className={`${(changedRepresentative && user.id === newRepresentativeId) || (!changedRepresentative && currentCompany.company && currentCompany.company.representative && user.id === currentCompany.company.representative.id) ? 'selected' : ''}`} onClick={() => this.selectRepresentative(user)}>
                                      {
                                        user.first_name ? (
                                          `${user.first_name} ${user.last_name}`
                                        ) : (
                                          user.email
                                        )
                                      }
                                    </li>
                                  )
                                })
                              ) : (
                                null
                              )
                            )
                          })
                        ) : (
                          null
                        )
                      }
                    </ul>
                  </div>
                  <input className={`${changedRepresentative ? 'active' : 'inactive'}`} type="submit" value={this.context.t("Save")} />
                </div>
              </form>
            </div>

            <div className="company-form-card" ref="mainInfoRef">
              <form>
                <div className="header">
                  <h3>{this.context.t('Main data')}</h3>
                </div>
                <div className={`form-group ${name.length === 0 ? 'purple-frame pulsing-frame' : ''}`}>
                  <input type="text" name="name" id="name" value={name} onChange={this.onInputChange} onBlur={() => this.checkChangesAndSubmit()} />
                  <label htmlFor="name">{this.context.t('Company Name')}</label>
                </div>
                <div className={`form-group ${shortName.length === 0 ? 'purple-frame pulsing-frame' : ''}`}>
                  <input type="text" name="shortName" id="shortName" value={shortName} onChange={this.onInputChange} onBlur={() => this.checkChangesAndSubmit()} />
                  <label htmlFor="shortName">{this.context.t('EasyDocs company Code')}</label>
                  <span className="help-icon" data-for="code-info" data-tip="show"></span>
                </div>
                <div className={`form-group ${vatId.length === 0 ? 'purple-frame pulsing-frame' : ''}`}>
                  <input type="text" name="vatId" id="vatId" value={vatId} onChange={this.onInputChange} onBlur={() => this.checkChangesAndSubmit()} />
                  <label htmlFor="vatId">{this.context.t('Tax ID')}</label>
                </div>
                <div className={`form-group`}>
                  <input type="text" name="regon" id="regon" value={regon} onChange={this.onInputChange} onBlur={() => this.checkChangesAndSubmit()} />
                  <label htmlFor="regon">{this.context.t('REGON')}</label>
                </div>
              </form>
            </div>

            <div className="company-form-card" ref="contactInfoRef">
              <form>
                <div className="header">
                  <h3>{this.context.t('Contact data')}</h3>
                </div>
                <div className={`form-group ${email.length === 0 ? 'purple-frame pulsing-frame' : ''}`}>
                  <input type="text" name="email" id="email" value={email} onChange={this.onInputChange} onBlur={() => this.checkChangesAndSubmit()} />
                  <label htmlFor="email">{this.context.t('E-mail address')}</label>
                </div>
                <div className={`form-group ${phone.length === 0 ? 'purple-frame pulsing-frame' : ''}`}>
                  <input type="text" name="phone" id="phone" value={phone} onChange={this.onInputChange} onBlur={() => this.checkChangesAndSubmit()} />
                  <label htmlFor="phone">{this.context.t('Phone number')}</label>
                  <span className="help-icon" data-for="phone-info" data-tip="show"></span>
                </div>
                <div className="form-group ">
                  <input type="text" name="www" id="www" value={www} onChange={this.onInputChange} onBlur={() => this.checkChangesAndSubmit()} />
                  <label htmlFor="www">{this.context.t('Website Address')}</label>
                </div>
                <div className="form-group ">
                  <input type="text" name="address" id="address" value={address} onChange={this.onInputChange} onBlur={() => this.checkChangesAndSubmit()} />
                  <label htmlFor="address">{this.context.t('Address')}</label>
                </div>
              </form>
            </div>

            <div className={`company-form-card banks ${localStorage.getItem('adminOnboarding') === 'NEXT_STEPS' && !(tax_account || zus_account) ? 'green-frame pulsing-frame' : ''}`} ref="bankInfoRef">
              <form>
                <div className="header">
                  <h3>
                    {this.context.t('Bank data')}

                    <span className="help-icon" data-for="bank-info" data-tip="show"></span>
                  </h3>
                </div>

                {
                  banks.length > 0 ? (
                    <div className="bank-accounts-wrapper">
                      {
                        banks.map((b, i) => {
                          return (
                            <div className={`bank-container ${banks[i + 1] === undefined ? 'last' : ''}`}>
                              {
                                b.id === 'new' ? (
                                  <h4>{this.context.t('New bank account')}</h4>
                                ) : (
                                  <>
                                    <h4>
                                      {`${b.name} - ${b.currency}`}
                                      {
                                        b.psd2_is_connected_to_ais && [2, 3, 62, 597].includes(user.id) ? (
                                          <>
                                            <span className="has-psd2-ais">{this.context.t('Connected to PSD2 AIS')}</span>
                                            <span className="revoke-psd2-ais" onClick={() => this.showPsd2DisconnectMessage(b.psd2_ais_consent_request_id)}>{this.context.t('Disconnect')}</span>
                                          </>
                                        ) : null
                                      }
                                    </h4>
                                    <div className="btn delete-button" onClick={e => this.showDeleteBankAccountModal(e, i)}></div>
                                  </>
                                )
                              }
                              <div className="form-group currency" id="currencyContainer">
                                <input type="text" name="currency" id={`currency${b.id}`} value={b.currency} onChange={e => this.onInputChangeBankInfo(e, i)} onFocus={(e) => this.showCurrencyList(e, i)} autoComplete='off' />
                                <label htmlFor={`currency${b.id}`}>{this.context.t('Currency')}</label>
                                <span className={`expend-icon ${showCurrencyList && bankContainer === i ? 'expended' : ''}`} onClick={(e) => this.toggleCurrencyList(e, i)}></span>
                                {
                                  showCurrencyList && bankContainer === i ? (
                                    <ul className={`list ${currencyListFlipUp ? 'flip-up' : ''}`}>
                                      {
                                        currencyList.map(c => {
                                          return (
                                            <li onClick={e => { this.selectCurrency(c, i) }}>
                                              {c}
                                            </li>
                                          )
                                        })
                                      }
                                    </ul>
                                  ) : (
                                    null
                                  )
                                }
                              </div>
                              <div className="form-group account-number" id="accountContainer" >
                                <input type="text" name="account_number" id={`account_number${b.id}`} value={b.account_number} onChange={e => this.onInputChangeBankInfo(e, i)} placeholder="PL00 0000 0000 0000 0000 0000 0000" onFocus={(e) => this.toggleAccountList(e, i)} onBlur={() => this.checkChangesAndSubmitBankInfo(i)} />
                                <label htmlFor={`account_number${b.id}`}>{this.context.t('Account Number ')}</label>
                                {
                                  suggestedAccounts && suggestedAccounts.length > 0 ? (
                                    <span className={`expend-icon ${showAccountList && bankContainer === i ? 'expended' : ''}`} onClick={(e) => this.toggleAccountList(e, i)}></span>
                                  ) : null
                                }
                                {
                                  suggestedAccounts && suggestedAccounts.length > 0 && showAccountList && bankContainer === i && b.id === 'new' ? (
                                    <ul className={`list ${currencyListFlipUp ? 'flip-up' : ''}`}>
                                      {
                                        suggestedAccounts.map(number => {
                                          return (
                                            <li onClick={e => { this.selectAccount(number, i) }}>
                                              {number}
                                            </li>
                                          )
                                        })
                                      }
                                    </ul>
                                  ) : (
                                    null
                                  )
                                }
                              </div>
                              <div className="form-group small">
                                <input type="text" name="bank_name" id={`bank_name${b.id}`} value={b.bank_name} onChange={e => this.onInputChangeBankInfo(e, i)} onBlur={() => this.checkChangesAndSubmitBankInfo(i)} />
                                <label htmlFor={`bank_name${b.id}`}>{this.context.t('Bank Name')}</label>
                                <span className="help-icon" data-for="account-info" data-tip="show"></span>
                              </div>
                              <div className="form-group small">
                                <input type="text" name="bic" id={`bic${b.id}`} value={b.bic} onChange={e => this.onInputChangeBankInfo(e, i)} onBlur={() => this.checkChangesAndSubmitBankInfo(i)} />
                                <label htmlFor={`bic${b.id}`}>{this.context.t('BIC/SWIFT')}</label>
                                <span className="help-icon" data-for="account-info" data-tip="show"></span>
                              </div>
                              <div className="form-group ">
                                <input type="text" name="name" id={`name${b.id}`} value={b.name} onChange={e => this.onInputChangeBankInfo(e, i)} onBlur={() => this.checkChangesAndSubmitBankInfo(i)} />
                                <label htmlFor={`name${b.id}`}>{this.context.t('Bank Account Name')}</label>
                                <span className="help-icon" data-for="account-name-info" data-tip="show"></span>
                              </div>

                              {
                                b.id === 'new' ? (
                                  <div className="action-buttons">
                                    <button onClick={e => this.cancelAddingAccount(e)}>{this.context.t('Cancel')}</button>
                                    <button className="active" onClick={e => this.createNewBankForCompany(e)}>{this.context.t('Save')}</button>
                                  </div>
                                ) : (
                                  null
                                )
                              }
                            </div>
                          )
                        })
                      }
                      {
                        !addAccountMode ? (
                          <div className='bank-button'>
                            <button className="active" onClick={e => this.addAccount(e)}>{this.context.t("Add bank account")}</button>
                            {
                              [2, 3, 62, 597].includes(user.id) ? (
                                <div className="btn active psd2" onClick={e => this.toggleConnectToPsd2AisPopup(true)}>{this.context.t("Connect to PSD2 AIS")}</div>
                              ) : null
                            }
                          </div>
                        ) : (
                          null
                        )
                      }
                    </div>
                  ) : (
                    <div className="no-bank-accounts">
                      <div className="text big">
                        {this.context.t('No bank accounts have been added for your business yet')}
                      </div>
                      <div className="text small">
                        {this.context.t('Bank account numbers given here are used to generate transfer baskets that you can import to your bank')}
                      </div>

                      <button onClick={() => { this.addAccount() }}>
                        {this.context.t('Add bank account')}
                      </button>
                    </div>
                  )
                }

              </form>
            </div>

            <div className={`company-form-card ${localStorage.getItem('adminOnboarding') === 'NEXT_STEPS' && banks.length === 0 ? 'green-frame pulsing-frame' : ''}`} ref="paymentsAccountsRef">
              <form>
                <div className="header">
                  <h3>
                    {this.context.t('TAX and ZUS settings')}
                    <span className="help-icon" data-for="pyment-accounts-info" data-tip="show"></span>
                  </h3>
                </div>

                <div className="form-group micro-account">
                  <input type="text" name="tax_account" id="tax_account" value={tax_account} onChange={this.onInputChange} placeholder="PL00 0000 0000 0000 0000 0000 0000" onBlur={() => this.checkChangesAndSubmit()} />
                  <label htmlFor="tax_account">{this.context.t('Tax account')}</label>

                  <div className="generator-link"><a href="https://www.podatki.gov.pl/generator-mikrorachunku-podatkowego" target="_blank">{this.context.t('Go to the US micro-account generator')}</a></div>
                </div>
                <div className="form-group micro-account">
                  <input type="text" name="zus_account" id="zus_account" value={zus_account} onChange={this.onInputChange} placeholder="PL00 0000 0000 0000 0000 0000 0000" onBlur={() => this.checkChangesAndSubmit()} />
                  <label htmlFor="zus_account">{this.context.t('ZUS account')}</label>

                  <div className="generator-link"><a href="https://eskladka.pl/Home" target="_blank">{this.context.t('Go to the ZUS micro-account generator')}</a></div>
                </div>

                <div className="form-group list-group" ref={this.incomeTaxSymbolContainerRef}>
                  <input type="text" name="income_tax" value={income_tax} onClick={(e) => this.toggleList('showIncomeTaxSymbolList')} autoComplete='off' />
                  <label>{this.context.t('Income tax symbol')}</label>
                  <span className={`expend-icon ${showIncomeTaxSymbolList ? 'expended' : ''}`} onClick={(e) => this.toggleList('showIncomeTaxSymbolList')}></span>
                  {
                    showIncomeTaxSymbolList ? (
                      <ul className={`list`}>
                        <SimpleBar style={{ maxHeight: '25vh' }}>
                          {
                            incomeTaxSymbols.map(c => {
                              return (
                                <li onClick={e => { this.onListSelect(c, "income_tax", 'showIncomeTaxSymbolList') }}>
                                  {c}
                                </li>
                              )
                            })
                          }
                        </SimpleBar>
                      </ul>
                    ) : (
                      null
                    )
                  }
                </div>

                <div className="form-group list-group" ref={this.incomeTaxPeriodContainerRef}>
                  <input type="text" name="income_tax_period" value={this.context.t(this.capitalizeFirstLetter(income_tax_period))} onClick={(e) => this.toggleList('showIncomeTaxPeriodList')} autoComplete='off' />
                  <label>{this.context.t('Income tax period')}</label>
                  <span className={`expend-icon ${showIncomeTaxPeriodList ? 'expended' : ''}`} onClick={(e) => this.toggleList('showIncomeTaxPeriodList')}></span>
                  {
                    showIncomeTaxPeriodList ? (
                      <ul className={`list`}>
                        <li onClick={e => { this.onListSelect('MONTH', "income_tax_period", 'showIncomeTaxPeriodList') }}>
                          {this.context.t('Month')}
                        </li>
                        <li onClick={e => { this.onListSelect('QUARTER', "income_tax_period", 'showIncomeTaxPeriodList') }}>
                          {this.context.t('Quarter')}
                        </li>
                      </ul>
                    ) : (
                      null
                    )
                  }
                </div>

                <div className="form-group list-group" ref={this.vatTaxSymbolContainerRef}>
                  <input type="text" name="vat_tax" value={vat_tax} onClick={(e) => this.toggleList('showVatTaxSymbolList')} autoComplete='off' />
                  <label>{this.context.t('VAT tax symbol')}</label>
                  <span className={`expend-icon ${showVatTaxSymbolList ? 'expended' : ''}`} onClick={(e) => this.toggleList('showVatTaxSymbolList')}></span>
                  {
                    showVatTaxSymbolList ? (
                      <ul className={`list`}>
                        <SimpleBar style={{ maxHeight: '25vh' }}>
                          {
                            vatTaxSymbols.map(c => {
                              return (
                                <li onClick={e => { this.onListSelect(c, "vat_tax", 'showVatTaxSymbolList') }}>
                                  {c}
                                </li>
                              )
                            })
                          }
                        </SimpleBar>
                      </ul>
                    ) : (
                      null
                    )
                  }
                </div>

                <div className="form-group list-group" ref={this.vatTaxPeriodContainerRef}>
                  <input type="text" name="vat_tax_period" value={this.context.t(this.capitalizeFirstLetter(vat_tax_period))} onClick={(e) => this.toggleList('showVatTaxPeriodList')} autoComplete='off' />
                  <label>{this.context.t('Income tax period')}</label>
                  <span className={`expend-icon ${showVatTaxPeriodList ? 'expended' : ''}`} onClick={(e) => this.toggleList('showVatTaxPeriodList')}></span>
                  {
                    showVatTaxPeriodList ? (
                      <ul className={`list`}>
                        <li onClick={e => { this.onListSelect('MONTH', "vat_tax_period", 'showVatTaxPeriodList') }}>
                          {this.context.t('Month')}
                        </li>
                        <li onClick={e => { this.onListSelect('QUARTER', "vat_tax_period", 'showVatTaxPeriodList') }}>
                          {this.context.t('Quarter')}
                        </li>
                      </ul>
                    ) : (
                      null
                    )
                  }
                </div>
              </form>
            </div>

            <div className={`company-form-card ${localStorage.getItem('adminOnboarding') === 'NEXT_STEPS' && invoiceNumberingSeries.length === 0 ? 'green-frame pulsing-frame' : ''}`} ref="invoiceNumberingFormatRef">
              <form>
                <div className="header">
                  <h3>
                    {this.context.t('Setting the invoice numbering format')}
                    <span className="help-icon" data-for="invoice-numbering-mask-info" data-tip="show"></span>
                  </h3>
                </div>

                {
                  invoiceNumberingSeries.length > 0 ? (
                    <div className="numbering-series-wrapper">
                      {
                        invoiceNumberingSeries.map((series, i) => {
                          return (
                            <div className={`numbering-series-container ${series[i + 1] === undefined ? 'last' : ''}`}>
                              {
                                series.id === 'new' ? (
                                  <h4>{this.context.t('New numbering series')}</h4>
                                ) : (
                                  <>
                                    <h4>{series.name}</h4>
                                    {/* <h4>{this.context.t('Numbering series: {n}', { n: i + 1 })}</h4> */}
                                    {/* <div className="btn delete-button" onClick={e => this.showDeleteInvoiceNumberingSeriesPopup(e, i)}></div> */}
                                  </>
                                )
                              }

                              <div className="form-group grow margin-right">
                                <input type="text" name="name" id="name" value={series.name} onChange={e => this.onInputChangeInvoiceNumberingSeries(e, i)} onBlur={() => this.checkChangesAndSubmitNumberingSeries(i)} />
                                <label htmlFor="name">{this.context.t('Series name')}</label>
                              </div>

                              <div className="form-group grow margin-right">
                                <input type="text" name="invoice_mask" id="invoice_mask" value={series.invoice_mask} onChange={e => this.onInputChangeInvoiceNumberingSeries(e, i)} placeholder="FVS/00/MM/YYYY" onBlur={() => this.checkChangesAndSubmitNumberingSeries(i)} />
                                <label htmlFor="invoice_mask">{this.context.t('Invoice')}</label>
                              </div>
                              <div className="form-group grow margin-right">
                                <input type="text" name="correction_mask" id="correction_mask" value={series.correction_mask} onChange={e => this.onInputChangeInvoiceNumberingSeries(e, i)} placeholder="KOR/00/MM/YYYY" onBlur={() => this.checkChangesAndSubmitNumberingSeries(i)} />
                                <label htmlFor="correction_mask">{this.context.t('Invoice correction')}</label>
                              </div>
                              <div className="form-group grow">
                                <input type="text" name="proforma_mask" id="proforma_mask" value={series.proforma_mask} onChange={e => this.onInputChangeInvoiceNumberingSeries(e, i)} placeholder="PRO/00/MM/YYYY" onBlur={() => this.checkChangesAndSubmitNumberingSeries(i)} />
                                <label htmlFor="proforma_mask">{this.context.t('Proforma')}</label>
                              </div>

                              {
                                series.id === 'new' ? (
                                  <div className="action-buttons">
                                    <button onClick={e => this.cancelAddingInvoiceNumberingSeries(e)}>{this.context.t('Cancel')}</button>
                                    <button className="active" onClick={e => this.createNewInvoiceNumberingSeriesForCompany(e)}>{this.context.t('Save')}</button>
                                  </div>
                                ) : (
                                  null
                                )
                              }
                            </div>
                          )
                        })
                      }
                      {
                        !addInvoiceNumberingSeriesMode ? (
                          <div className='bank-button'><button className="active" onClick={e => this.addInvoiceNumberingSeries(e)}>{this.context.t("Add invoice numbering series")}</button></div>
                        ) : (
                          null
                        )
                      }
                    </div>
                  ) : (
                    <div className="no-bank-accounts">
                      <div className="text big">
                        {this.context.t('No sales invoice numbering series have been added in your company yet')}
                      </div>
                      <div className="text small">
                        {this.context.t('The numbering series given here will be used to generate successive sales invoice numbers, you can have several different series')}
                      </div>

                      <button onClick={() => { this.addInvoiceNumberingSeries() }}>
                        {this.context.t('Add invoice numbering series')}
                      </button>
                    </div>
                  )
                }
              </form>
            </div>

            <div className={`company-form-card`}>
              {/* ${localStorage.getItem('adminOnboarding') === 'NEXT_STEPS' && banks.length === 0 ? 'green-frame pulsing-frame' : ''} */}
              <form>
                <div className="header no-border">
                  <h3>
                    {this.context.t('National e-Invoice System (KSeF)')}
                  </h3>
                  {
                    currentCompany.company.ksef_integration_id ? (
                      <div className="button" onClick={() => this.editKsefIntegration()}>
                        <div className="connected-to-ksef"></div>
                        {this.context.t('Edit KSeF integration')}
                      </div>
                    ) : (
                      <div className="button" onClick={() => this.integrateWithKsef()}>
                        <div className="connect-to-ksef"></div>
                        {this.context.t('Integrate with KSeF')}
                      </div>
                    )
                  }
                </div>
              </form>
            </div>

            <div className="company-form-card">
              <div className="card">
                <div className="header">
                  <h3>{this.context.t("Company logo")}</h3>
                  <span
                    className="help-icon"
                    data-for="add-company-logo-info"
                    data-tip="show"
                  />
                </div>
                <div className="logoContainer">
                  <div className="logo">
                    <div className={`logo-photo ${!logoUrl ? "default" : ""}`} >
                      {
                        logoUrl ? (
                          <img src={logoUrl} alt="Company logo" />
                        ) : null
                      }
                    </div>
                    <div className="content">
                      <div className="action-buttons">
                        <div className="add-photo">
                          <Dropzone onDrop={(files, rejectedFiles) => this.handleOnDrop(files, rejectedFiles)} accept={acceptedFileTypes} multiple={false} maxSize={imageMaxSize}>
                            {({ getRootProps, getInputProps, isDragActive }) => (
                              <div {...getRootProps()}>
                                {
                                  logoUrl ? (
                                    this.context.t('Change logo')
                                  ) : (
                                    this.context.t('Add logo')
                                  )
                                }
                                <input {...getInputProps()} />
                              </div>
                            )}
                          </Dropzone>
                        </div>
                        {
                          logoUrl ? (
                            <div className="delete-photo" onClick={() => this.showDeleteLogoPopup()}>
                              {this.context.t("Delete logo")}
                            </div>
                          ) : null
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="company-form-card">
              <DndProvider backend={HTML5Backend}>
                <DragLayer translate={this.context.t} />
                <div className="card">
                  <div className="header">
                    <h3>
                      {this.context.t("\"Payment collation\" report fields configurator")}
                      <span className="help-icon" data-for="report-excel" data-tip="show"></span>
                    </h3>
                  </div>
                  <p>{this.context.t('Here you can manage the information that will be included in your Excel reports. Drag fields to the "Selected Fields" table to add a column in the report. Then sort and label each column. You can also remove fields by dragging them to the "Available Fields" table.')}</p>

                  <div className="creator-continer">
                    <div className="excel-columns available">
                      <div className="title available">
                        {this.context.t('Available fields')}
                      </div>
                      <DropTarget
                        name='available'
                        containedItems={[...availableExcelColumns]}
                        handleItemDrop={this.handleDrop}
                        moveItem={() => { }}
                        moveNewItem={() => { }}
                        removeAllPseudoElements={this.removeAllPseudoElements}
                        onInputChange={() => { }}
                        onInputBlur={() => { }}
                      />
                    </div>

                    <div className="excel-columns selected">
                      <div className="title selected">
                        {this.context.t('Selected fields')}
                      </div>
                      <DropTarget
                        name='selected'
                        containedItems={[...selectedExcelColumns]}
                        handleItemDrop={this.handleDrop}
                        moveItem={this.sortSelected}
                        moveNewItem={this.addAndSort}
                        removeAllPseudoElements={this.removeAllPseudoElements}
                        onInputChange={this.onLabelInputChange}
                        onInputBlur={this.saveExcelReportsChanges}
                      />
                    </div>
                  </div>
                </div>
              </DndProvider>
            </div>

            <div className="company-form-card" ref="tagsRef">
              {/* <AvailableInOtherPlanLabel planName='PRO' labelSize="FULL" company={currentCompany.company} /> */}
              <form>
                <div className="header">
                  <h3>{this.context.t('Tags')}</h3>
                </div>

                <p>{this.context.t('Tags in EasyDocs are used to make it easier to find documents after they are saved. Users in your company will be able to easily select tags from the list that you enter here without having to enter them manually')}</p>

                <div className="tags-limitations">
                  <p>{this.context.t('You can further restrict which users can enter their own tags when verifying documents')}:</p>

                  <ul>
                    <li className={`${tagsCreationLimitation === 'EVERYONE' ? 'selected' : ''}`} onClick={() => this.changeTagsLimitations('EVERYONE')}>{this.context.t('everyone')}</li>
                    <li className={`${!['EVERYONE', 'NOONE'].includes(tagsCreationLimitation) ? 'selected' : ''}`} onClick={() => this.changeTagsLimitations('USER2,USER3')}>
                      {this.context.t('only selected permission levels')}:
                    </li>
                    <ol className={`${!['EVERYONE', 'NOONE'].includes(tagsCreationLimitation) ? 'active' : ''}`}>
                      <li className={`${tagsCreationLimitation.includes('USER1') ? 'selected' : ''}`} onClick={() => this.toogleAccessLevelForTags('USER1')}>{this.context.t('permission level U1')}</li>
                      <li className={`${tagsCreationLimitation.includes('USER2') ? 'selected' : ''}`} onClick={() => this.toogleAccessLevelForTags('USER2')}>{this.context.t('permission level U2')}</li>
                      <li className={`${tagsCreationLimitation.includes('USER3') ? 'selected' : ''}`} onClick={() => this.toogleAccessLevelForTags('USER3')}>{this.context.t('permission level U3')}</li>
                    </ol>
                    <li className={`${tagsCreationLimitation === 'NOONE' ? 'selected' : ''}`} onClick={() => this.changeTagsLimitations('NOONE')}>{this.context.t('noone')}</li>
                  </ul>
                </div>

                <div className="form-group tags">
                  <TextareaAutosize type="text" name="tags" id="tags" value={tags} onChange={this.onInputChange} />
                  <label htmlFor="currency1">{this.context.t('Separate each tag with a comma. Example: bank, leasing')}</label>
                  <span className="add-tags" onClick={() => this.addTags()}></span>
                </div>

                {
                  currentCompany && currentCompany.company && currentCompany.company.tags_list ? (
                    <div className="tag-list">
                      {
                        currentCompany.company.tags_list.map(t => {
                          return (
                            <div className="tag">
                              {t}
                              <span className="remove-tag" onClick={() => this.removeTag(t)}></span>
                            </div>
                          )
                        })
                      }
                    </div>
                  ) : (
                    null
                  )
                }
              </form>
            </div>

            <ReactTooltip id='code-info' place="left" effect="solid" className="default-tooltip">
              <ul>
                <li>{this.context.t('Minimum 3 characters')}</li>
                <li>{this.context.t('Only letters, digits, underscore and dash')}</li>
              </ul>
            </ReactTooltip>

            <ReactTooltip id="phone-info" place="left" effect="solid" className="default-tooltip account-name-info">
              {this.context.t('Fill in so that we can contact you for assistance in setting up and using EasyDocs')}
            </ReactTooltip>

            <ReactTooltip id="account-name-info" place="left" effect="solid" className="default-tooltip account-name-info">
              {this.context.t('Your name for this bank account. Its purpose is to make it easier for you to identify your bank accounts')}
            </ReactTooltip>

            <ReactTooltip id="account-info" place="left" effect="solid" className="default-tooltip account-name-info">
              {this.context.t('Automatically completed based on the given account number')}
            </ReactTooltip>

            <ReactTooltip id="bank-info" place="right" effect="solid" className="default-tooltip account-name-info">
              {this.context.t('Add an account if you want to use the bank transfer import function')}
            </ReactTooltip>

            <ReactTooltip id="pyment-accounts-info" place="right" effect="solid" className="default-tooltip account-name-info">
              {this.context.t('Add to make them available when creating payment orders to the Tax Office and Social Insurance Institution')}
            </ReactTooltip>

            <ReactTooltip id="add-company-logo-info" place="right" effect="solid" className="default-tooltip account-name-info">
              {this.context.t("upload a file in .jpg, .png, or .svg format - it will be visible on the company's sales invoices and in the EasyDocs application")}
            </ReactTooltip>

            <ReactTooltip id="report-excel" place="right" effect="solid" className="default-tooltip account-name-info">
              {this.context.t("Select the data to be included in your Excel reports")}
            </ReactTooltip>

            <ReactTooltip id="invoice-numbering-mask-info" place="right" effect="solid" className="default-tooltip numbering-format-info">
              <p>{this.context.t("Add to change the invoice numbering scheme. You can freely change the scheme while keeping the names of the fields. e.g. FV/MM/YYYY/00 or FV/00/YYYY.")}</p>
              <p>{this.context.t("MM - means the month number.")}</p>
              <p>{this.context.t("YYYY - means the year.")}</p>
              <p>{this.context.t("FV - can be any letter abbreviation.")}</p>
              <p>{this.context.t("00 - means the invoice number if you start with a number other than 00, enter the number from which the system should start numbering.")}</p>
            </ReactTooltip>


          </div>
        )
      } else {
        return (
          <div className="activity-indicator">
            <Bounce size={50} speed={0.8} color={"#fff"} />
          </div>
        )
      }
    } else {
      return null
    }
  }
}



CompanyDetails.contextTypes = {
  t: PropTypes.func
}

const mapStateToProps = (state) => ({
  user: state.User.user,
  currentCompany: { ...state.Company.currentCompany },
  companyWorkers: state.Company.companyWorkers || [],
  userCompanies: state.Company.companies,
  userProjects: state.User.userProjects,
  groupedProjects: state.User.groupedProjects,
  overlayPreferance: state.User.overlayPreferance || {},
  loadingScreen: state.File.loadingScreen || false,
})

const mapDispatchToProps = {
  getAllCompanyWorkers: CompanyActions.getAllCompanyWorkers,
  setCurrentCompany: CompanyActions.setCurrentCompany,
  updateCompanyInfo: CompanyActions.updateCompanyData,

  addNewBankForCompany: CompanyActions.addNewBankForCompany,
  updateBankInfo: CompanyActions.updateBankInfo,
  deleteBankFromCompany: CompanyActions.deleteBankFromCompany,

  addNewInvoiceNumberingSeries: CompanyActions.addNewInvoiceNumberingSeries,
  updateInvoiceNumberingSeries: CompanyActions.updateInvoiceNumberingSeries,
  deleteInvoiceNumberingSeries: CompanyActions.deleteInvoiceNumberingSeries,

  changeCompanyRepresentative: CompanyActions.changeCompanyRepresentative,
  alertWarn: AlertActions.warning,
  alertInfo: AlertActions.info,
  toggleOverlay: UserActions.toggleOverlay,
  switchDashboardViewModel: UserActions.switchDashboardViewModel,
  changeCompanyLogo: CompanyActions.changeCompanyLogo,
  deleteCompanyLogo: CompanyActions.deleteCompanyLogo,

  chackeIfProjectCodeIsUnique: CompanyActions.chackeIfProjectCodeIsUnique,
  registerNextCompany: CompanyActions.registerNextCompany,
  getUserProjects: UserActions.getUserProjects,
  getUserCompanies: CompanyActions.getUserCompanies,

  psd2AisConsentNotification: CompanyActions.psd2AisConsentNotification,
  disconnectFromPsd2: CompanyActions.disconnectFromPsd2
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails)

