import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import { Spinner } from 'react-activity'
import 'react-activity/dist/react-activity.css'

import { Column, Table, SortDirection, AutoSizer, WindowScroller } from "react-virtualized";
import "react-virtualized/styles.css";
import _ from "lodash";

import Dotdotdot from 'react-dotdotdot'

import { ReportsActions } from '../../actions/ReportsActions'

export class CorrespondenceTableVirtualized extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            docsList: []
        }
    }

    componentDidMount() {
        this.setState({
            docsList: this.props.docsDownloadedForRaport.filter(e => this.checkIfDocMatchesFilterOptions(e)),
            docsSortField: this.props.sortField,
            docsSortOrder: this.props.sortOrder
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.docsDownloadedForRaport !== this.props.docsDownloadedForRaport || this.filtersDidChange(prevProps)) {
            this.setState({
                docsList: this.props.docsDownloadedForRaport.filter(e => this.checkIfDocMatchesFilterOptions(e))
            })
        }
        ReactTooltip.rebuild()
    }

    filtersDidChange = prevProps => {
        const { searchField } = this.props

        if (searchField !== prevProps.searchField) return true

        return false
    }

    formatPrice = price => {
        if (price || price === 0) {
            price = price.toFixed(2)
            if (price.indexOf('.') > -1) {
                var parts = price.split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                price = parts.join('.')
            } else {
                price = price.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }

            return price
        } else {
            return ''
        }
    }

    getSortClass = (sortFieldCheck) => {
        const { sortField, sortOrder } = this.props

        if (sortField === sortFieldCheck) {
            if (sortOrder === "asc")
                return "icon-sort down"
            return "icon-sort up"
        }

        return "icon-sort"
    }

    headerRenderer = (name) => {
        switch (name) {
            case 'all':
                return (
                    <div className="wrapper all">
                        <span className="column-name" onClick={e => this.props.selectAll(e)}>{this.context.t('Select all ')}</span>
                    </div>
                )
            case 'fileIcon':
                return (
                    <div className="wrapper">
                    </div>
                )
            case 'counterparty':
                return (
                    <div className="wrapper counterparty" onClick={() => this.props.switchSortingFilter('company')}>
                        <span className="column-name">{this.context.t('Counterparty')}</span>
                        <span className={this.getSortClass('company')}></span>
                    </div>
                )
            case 'from':
                return (
                    <div className="wrapper" onClick={() => this.props.switchSortingFilter('from')}>
                        <span className="column-name">{this.context.t('From')}</span>
                        <span className={this.getSortClass('from')}></span>
                    </div>
                )
            case 'importDate':
                return (
                    <div className="wrapper date" onClick={() => this.props.switchSortingFilter('importDate')}>
                        <span className="column-name">{this.context.t('Import Date')}</span>
                        <span className={this.getSortClass('importDate')}></span>
                    </div>
                )
            case 'issueDate':
                return (
                    <div className="wrapper date" onClick={() => this.props.switchSortingFilter('issueDate')}>
                        <span className="column-name">{this.context.t('Date')}</span>
                        <span className={this.getSortClass('issueDate')}></span>
                    </div>
                )
            case 'comment':
                return (
                    <div className="wrapper" onClick={() => this.props.switchSortingFilter('comment')}>
                        <span className="column-name">{this.context.t('Description')}</span>
                        <span className={this.getSortClass('comment')}></span>
                    </div>
                )
            case 'tags':
                return (
                    <div className="wrapper" onClick={() => this.props.switchSortingFilter('tags')}>
                        <span className="column-name">{this.context.t('Tags')}</span>
                        <span className={this.getSortClass('tags')}></span>
                    </div>
                )
            default:
                return null
        }
    }

    sort = ({ sortBy, sortDirection }) => {
        var { docsSortField, docsSortOrder, docsList } = this.state
        if (docsSortField === sortBy) {
            if (docsSortOrder === 'asc') {
                docsSortOrder = 'desc'
            } else {
                docsSortOrder = 'asc'
            }
        } else {
            docsSortField = sortBy
            docsSortOrder = 'asc'
        }
        docsList.sort(this.dynamicComparer(docsSortField, docsSortOrder))

        this.setState({
            docsList: docsList,
            docsSortField: sortBy,
            docsSortOrder: sortDirection === SortDirection.ASC ? 'asc' : 'desc'
        })
    }

    checkIfDocMatchesFilterOptions = doc => {
        const { searchField } = this.props
        if (doc) {
            var matches = false

            if (searchField) {
                if (doc.counterparty && doc.counterparty.toLowerCase().includes(searchField.toLowerCase())) matches = true
                else if (doc.comment && doc.comment.toLowerCase().includes(searchField.toLowerCase())) matches = true
                else if (!doc.counterparty && !doc.comment) matches = true
            } else {
                matches = true
            }
            return matches
        } else {
            return false
        }
    }

    dynamicComparer = (key, order = 'asc') => {
        return (a, b) => {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                return 0;
            }

            const varA = (typeof a[key] === 'string') ?
                a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string') ?
                b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (!varA) {
                comparison = -1
            } else if (!varB) {
                comparison = 1
            } else if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === 'desc') ? (comparison * -1) : comparison
            );
        };
    }

    rowRenderer = ({ style, key, index, parent, width }) => {
        const { docsList } = this.state
        const { alreadyPaidDocs, selectFor, roleInSelectedProject, docsSelectedForReport } = this.props
        var tx = docsList[index]

        if (tx) {
            return (
                <div
                    key={key}
                    className={`${index % 2 === 0 ? 'even' : 'odd'} ReactVirtualized__Table__row`}
                    role="row"
                    style={style}
                    onClick={e => this.props.fileIconClick(e, tx.projectId, tx.path, tx.id, tx.docType, tx.fileId, true)}
                >
                    {
                        <>
                            <div
                                className="ReactVirtualized__Table__rowColumn checkmarks"
                                data-tip='show'
                                data-for={tx.wasExported ? `zip-export-not-available` : ''}
                                role="gridcell"
                                style={{ overflow: "hidden", flex: `0 1 ${width * 0.04}px` }}
                            >
                                {
                                    tx.reportName !== 'PENDING' && tx.bankTransferFileName !== 'PENDING' && tx.waproXMLFileName !== 'PENDING' ? (
                                        <span
                                            className={`checkbox ${docsSelectedForReport.find(e => e.id === tx.id) ? 'checked' : ''}
                                        ${((!tx.canPay || tx.alreadyInReport) && selectFor === 'report') || (selectFor === 'transfer' && tx.alreadyInBankTransferFile) || (selectFor === 'wapro' && tx.missingWaproFields) || (selectFor === 'zip' && tx.wasExported) ? 'inactive' : ''}`}
                                            onClick={e => this.props.addDocToSelected(e, tx)}>
                                        </span>
                                    ) : (
                                        <span className="activity-indicator-doc-table">
                                            <Spinner size={11} speed={0.8} color={"#69B792"} />
                                        </span>
                                    )
                                }
                            </div>
                            <div
                                className={`ReactVirtualized__Table__rowColumn ${tx.docType === 'PAYMENT_ORDER' ? "payment-order-icon" : "file-icon"}`}
                                role="gridcell"
                                style={{ overflow: "hidden", flex: `0 1 ${width * 0.02}px` }}
                            ></div>
                            <div
                                className="ReactVirtualized__Table__rowColumn counterparty"
                                role="gridcell"
                                style={{ overflow: "hidden", flex: `0 1 ${width * 0.25}px` }}
                            ><Dotdotdot clamp={2}><p>{tx.counterparty}</p></Dotdotdot></div>
                            <div
                                className="ReactVirtualized__Table__rowColumn date"
                                role="gridcell"
                                style={{ overflow: "hidden", flex: `0 1 ${width * 0.07}px` }}
                            ><Dotdotdot clamp={2}><p>{tx.importDate}</p></Dotdotdot></div>
                            <div
                                className="ReactVirtualized__Table__rowColumn date"
                                role="gridcell"
                                style={{ overflow: "hidden", flex: `0 1 ${width * 0.07}px` }}
                            ><Dotdotdot clamp={2}><p>{tx.issueDate}</p></Dotdotdot></div>
                            <div
                                className="ReactVirtualized__Table__rowColumn comment"
                                role="gridcell"
                                style={{ overflow: "hidden", flex: `0 1 ${width * 0.35}px` }}
                            ><Dotdotdot clamp={2}><p>{tx.comment}</p></Dotdotdot></div>
                            <div
                                className="ReactVirtualized__Table__rowColumn tags"
                                role="gridcell"
                                style={{ overflow: "hidden", flex: `0 1 ${width * 0.2}px` }}
                            ><Dotdotdot clamp={2}><p>{tx.tags}</p></Dotdotdot></div>
                        </>
                    }
                </div>
            );
        } else {
            return null
        }
    }

    _setRef = windowScroller => {
        this._windowScroller = windowScroller;
    };


    render() {
        const { docsSortField, docsSortOrder, docsList } = this.state

        return (
            <div className="table-responsive" ref={this.props.setTableRef}>
                <WindowScroller
                    ref={this._setRef}
                    scrollElement={window}
                >
                    {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
                        <AutoSizer disableHeight>
                            {({ width }) => (
                                <div ref={registerChild}>
                                    <Table
                                        ref={el => {
                                            window.listEl = el;
                                        }}
                                        autoHeight
                                        width={width}
                                        height={height}
                                        headerHeight={40}
                                        rowHeight={40}
                                        sort={this.sort}
                                        sortBy={docsSortField}
                                        sortDirection={docsSortOrder === 'asc' ? SortDirection.ASC : SortDirection.DESC}
                                        rowCount={docsList.length}
                                        rowGetter={({ index }) => docsList[index]}
                                        rowRenderer={({ style, key, index, parent }) => this.rowRenderer({ style, key, index, parent, width })}

                                        isScrolling={isScrolling}
                                        onScroll={(e) => { onChildScroll(e); ReactTooltip.rebuild() }}
                                        scrollTop={scrollTop}
                                    >
                                        <Column dataKey="checkbox" width={width * 0.04} headerRenderer={() => this.headerRenderer('all')} />
                                        <Column dataKey="fileIcon" width={width * 0.02} headerRenderer={() => this.headerRenderer('fileIcon')} />
                                        <Column dataKey="counterparty" width={width * 0.25} headerRenderer={() => this.headerRenderer('counterparty')} />
                                        <Column dataKey="from" width={width * 0.15} headerRenderer={() => this.headerRenderer('from')} />
                                        <Column dataKey="importDate" width={width * 0.07} headerRenderer={() => this.headerRenderer('importDate')} />
                                        <Column dataKey="issueDate" width={width * 0.07} headerRenderer={() => this.headerRenderer('issueDate')} />
                                        <Column dataKey="comment" width={width * 0.35} headerRenderer={() => this.headerRenderer('comment')} />
                                        <Column dataKey="tags" width={width * 0.2} headerRenderer={() => this.headerRenderer('tags')} />
                                    </Table>
                                </div>
                            )}
                        </AutoSizer>
                    )}
                </WindowScroller>

            </div>
        )

    }
}

CorrespondenceTableVirtualized.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    sendGetDocumentsRequest: state.Reports.sendGetDocumentsRequest,
    docsDownloadedForRaport: state.Reports.docsDownloadedForRaport,
    alreadyPaidDocs: state.Reports.alreadyPaidDocs,
    sortField: state.Reports.docsSortField,
    sortOrder: state.Reports.docsSortOrder,
    downloadedDocsType: state.Reports.downloadedDocsType,
})

const mapDispatchToProps = {
    switchSortingFilter: ReportsActions.switchDocsSortingFilter,
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(CorrespondenceTableVirtualized))