

const COSTS_AVAILABLE_FIELDS = [
    {
        id: 'all',
        label: 'All fields'
    },
    {
        id: 'paymentStatus',
        label: 'Payment status'
    },
    {
        id: 'paymentType',
        label: 'Payment type'
    },
    {
        id: 'priority',
        label: 'Priority'
    },
    {
        id: 'sellerVatId',
        label: 'Seller VatID'
    },
    {
        id: 'sellerName',
        label: 'Seller name'
    },
    {
        id: 'buyerVatId',
        label: 'Buyer VatID'
    },
    {
        id: 'buyerName',
        label: 'Buyer name'
    },
    {
        id: 'docNumber',
        label: 'Document number'
    },
    {
        id: 'issueDate',
        label: 'Issue date'
    },
    {
        id: 'saleDate',
        label: 'Sale date'
    },
    {
        id: 'DueDate',
        label: 'Due date'
    },
    {
        id: 'netAmount',
        label: 'Net amount'
    },
    {
        id: 'grossAmount',
        label: 'Gross amount'
    },
    {
        id: 'amountToPay',
        label: 'Amount to pay'
    },
    {
        id: 'currency',
        label: 'Currency',
    },
    {
        id: 'comment',
        label: 'Comment'
    },
    {
        id: 'tags',
        label: 'Tags'
    },
]

const EXPENSES_AVAILABLE_FIELDS = [
    {
        id: 'all',
        label: 'All fields'
    },
    {
        id: 'priority',
        label: 'Priority'
    },
    {
        id: 'docNumber',
        label: 'Document number'
    },
    {
        id: 'issueDate',
        label: 'Issue date'
    },
    {
        id: 'amountToPay',
        label: 'Amount to pay'
    },
    {
        id: 'currency',
        label: 'Currency',
    },
    {
        id: 'comment',
        label: 'Comment'
    },
    {
        id: 'tags',
        label: 'Tags'
    },
]

const OTHER_AVAILABLE_FIELDS = [
    {
        id: 'all',
        label: 'All fields'
    },
    {
        id: 'counterpartyVatId',
        label: 'Counterparty VatID'
    },
    {
        id: 'counterpartyName',
        label: 'Counterparty name'
    },
    {
        id: 'issueDate',
        label: 'Issue date'
    },
    {
        id: 'comment',
        label: 'Comment'
    },
    {
        id: 'tags',
        label: 'Tags'
    },
]

const AGREEMENT_AVAILABLE_FIELDS = [
    {
        id: 'all',
        label: 'All fields'
    },
    {
        id: 'counterpartyVatId',
        label: 'Counterparty VatID'
    },
    {
        id: 'counterpartyName',
        label: 'Counterparty name'
    },
    {
        id: 'docNumber',
        label: 'Document number'
    },
    {
        id: 'issueDate',
        label: 'Issue date'
    },
    {
        id: 'comment',
        label: 'Comment'
    },
    {
        id: 'tags',
        label: 'Tags'
    }
]

const CORRESPONDENCE_AVAILABLE_FIELDS = [
    {
        id: 'all',
        label: 'All fields'
    },
    {
        id: 'counterpartyVatId',
        label: 'Counterparty VatID'
    },
    {
        id: 'counterpartyName',
        label: 'Counterparty name'
    },
    {
        id: 'title',
        label: 'Title'
    },
    {
        id: 'issueDate',
        label: 'Issue date'
    },
    {
        id: 'comment',
        label: 'Comment'
    },
    {
        id: 'tags',
        label: 'Tags'
    }
]

const CORPORATE_AVAILABLE_FIELDS = [
    {
        id: 'all',
        label: 'All fields'
    },
    {
        id: 'issueDate',
        label: 'Issue date'
    },
    {
        id: 'comment',
        label: 'Comment'
    },
    {
        id: 'tags',
        label: 'Tags'
    },
]

const PROTOCOL_AVAILABLE_FIELDS = [
    {
        id: 'all',
        label: 'All fields'
    },
    {
        id: 'employee',
        label: 'Employee'
    },
    {
        id: 'protocolType',
        label: 'Protocol type'
    },
    {
        id: 'issueDate',
        label: 'Issue date'
    },
    {
        id: 'comment',
        label: 'Comment'
    },
    {
        id: 'tags',
        label: 'Tags'
    },
]

const AVAILABLE_STAGES = [
    {
        id: 'verification',
        title: 'Verification',
        itemCategory: 'stage',
        itemType: 'verification'
    }, {
        id: 'acceptance',
        title: 'Acceptance',
        itemCategory: 'stage',
        itemType: 'acceptance'
    }, {
        id: 'payment',
        title: 'Payment',
        itemCategory: 'stage',
        itemType: 'payment'
    }, {
        id: 'accounting',
        title: 'Accounting',
        itemCategory: 'stage',
        itemType: 'accounting'
    }, {
        id: 'devEx',
        title: 'DevEx',
        itemCategory: 'stage',
        itemType: 'devEx'
    }, {
        id: 'mpk',
        title: 'Controlling dimension',
        itemCategory: 'stage',
        itemType: 'mpk'
    }
    // {
    //     id: 'report',
    //     title: 'Report',
    //     itemCategory: 'stage',
    //     itemType: 'report'
    // }
]

const AVAILABLE_EVENTS = [
    {
        id: 'notification',
        title: 'Notification',
        itemCategory: 'event',
        itemType: 'notification'
    }, {
        id: 'condition',
        title: 'Condition',
        itemCategory: 'event',
        itemType: 'condition'
    }
]

const AVAILABLE_DOC_TYPES = [
    {
        docType: 'COSTS',
        label: 'Cost'
    },
    {
        docType: 'EXPENSES',
        label: 'Expense'
    },
    {
        docType: 'PAYMENT_ORDER',
        label: 'Payment order'
    },
    {
        docType: 'SALE_INVOICE',
        label: 'Income doc'
    },
    {
        docType: 'AGREEMENT',
        label: 'Agreement'
    },
    {
        docType: 'CORRESPONDENCE',
        label: 'Correspondence'
    },
    {
        docType: 'CORPORATE',
        label: 'Corporate'
    },
    {
        docType: 'OTHER',
        label: 'Other'
    },
    {
        docType: 'WAREHOUSE',
        label: 'Warehouse'
    },
    {
        docType: 'PROTOCOL',
        label: 'Protocol'
    }
]

const AVAILABLE_DEDLINES = [
    {
        id: '1d',
        label: '1 day after the end of the previous stage',
        uploadLabel: '1 day after document upload',
    },
    {
        id: '2d',
        label: '2 days after the end of the previous stage',
        uploadLabel: '2 days after document upload',
    },
    {
        id: '3d',
        label: '3 days after the end of the previous stage',
        uploadLabel: '3 days after document upload',
    },
    {
        id: '5d',
        label: '5 days after the end of the previous stage',
        uploadLabel: '5 days after document upload',
    },
    {
        id: '7d',
        label: '7 days after the end of the previous stage',
        uploadLabel: '7 days after document upload',
    },
    {
        id: '10d',
        label: '10 days after the end of the previous stage',
        uploadLabel: '10 days after document upload',
    },
    {
        id: '14d',
        label: '14 days after the end of the previous stage',
        uploadLabel: '14 days after document upload',
    }
]

module.exports = {
    COSTS_AVAILABLE_FIELDS,
    EXPENSES_AVAILABLE_FIELDS,
    OTHER_AVAILABLE_FIELDS,
    AGREEMENT_AVAILABLE_FIELDS,
    CORRESPONDENCE_AVAILABLE_FIELDS,
    CORPORATE_AVAILABLE_FIELDS,
    PROTOCOL_AVAILABLE_FIELDS,
    AVAILABLE_STAGES,
    AVAILABLE_EVENTS,
    AVAILABLE_DOC_TYPES,
    AVAILABLE_DEDLINES
}