import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Document, Page } from "react-pdf";
import SimpleBar from "simplebar-react";

import "../../css/InvoicePreview.css";

export class ExampleRecurrinInvoicePdf extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            pdfScale: 1.5,
            numPages: 0,
        };
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({
            numPages: numPages,
        });
    }

    render() {
        const { numPages, pdfScale } = this.state
        const { examplePdf } = this.props
        return (
            <div className={`pdf-preview-container`}>
                <SimpleBar style={{ display: "flex", maxHeight: "100vh" }}>
                    <div className={`nav-bar`}>
                        <div className="action-buttons">
                            <div className="btn wide" onClick={() => this.props.returnToInvoiceEditing()}>
                                <div className="content-wrapper">
                                    <span className="edit-icon" />
                                    <p>{this.context.t("Edit")}</p>
                                </div>
                            </div>
                            <div className="btn wide" onClick={() => this.props.acceptRecurringInvoice()}>
                                <div className="content-wrapper">
                                    <span className="accept-icon" />
                                    <p>{this.context.t("Accept")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`pdf-wrapper`}>
                        <Document file={examplePdf} onLoadSuccess={this.onDocumentLoadSuccess} onLoadError={console.error}>
                            {
                                Array.apply(null, Array(numPages))
                                    .map((x, i) => <Page pageNumber={i + 1} scale={pdfScale} />)
                            }
                        </Document>
                    </div>
                </SimpleBar>
            </div>
        )
    }
}

ExampleRecurrinInvoicePdf.contextTypes = {
    t: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ExampleRecurrinInvoicePdf);
