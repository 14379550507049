import { AlertActions } from "./AlertActions";
import { TabsControllerConsts } from "../reducers/V2_TabsReducer";

export const TabsControlerActions = {
    setCurrentTab,
    openNewTab,
    changeOcrTabDoc,
    closeTab,
    closeTabWithSaleInvoicePreview,
    closeTabsAfterPtojectChange,
}

function setCurrentTab(tabIndex) {
    return dispatch => {
        dispatch({ type: TabsControllerConsts.SET_CURRENT_TAB, tabIndex: tabIndex })
    }
}

function openNewTab(tabType, tabComponent, additionalOptions) {
    return dispatch => {
        dispatch({ type: TabsControllerConsts.OPEN_NEW_TAB, tabType: tabType, tabComponent: tabComponent, additionalOptions: additionalOptions })
    }
}

function changeOcrTabDoc(projectId, fileId) {
    return dispatch => {
        dispatch({ type: TabsControllerConsts.CHANGE_OCR_TAB_DOC, additionalOptions: { projectId, fileId } })
    }
}

function closeTab(tabIndex) {
    return dispatch => {
        dispatch({ type: TabsControllerConsts.CLOSE_TAB, tabIndex: tabIndex })
    }
}

function closeTabWithSaleInvoicePreview() {
    return dispatch => {
        dispatch({ type: TabsControllerConsts.CLOSE_TAB_WITH_SALE_INVOICE_PREVIEW })
    }
}

function closeTabsAfterPtojectChange() {
    return dispatch => {
        dispatch({ type: TabsControllerConsts.CLOSE_TABS_AFTER_PROJECT_CHANGE })
    }
}
