import React, { Component } from 'react'
import { connect } from 'react-redux'
import { UserActions } from '../actions/UserActions'
import { AlertActions } from '../actions/AlertActions'
import { Link, Redirect } from 'react-router-dom'
import routes from '../helpers/routes'
import { emailRegex } from '../helpers/regexList'
import PropTypes from 'prop-types'
import Logo from '../img/easy-docs-logo-big.svg'
import LanguageSwitcher from '../components/LanguageSwitcher'


export class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            waitingForReset: false,
        }
    }

    componentDidMount() {
        //ReactGA.pageview(window.location.pathname + window.location.search)
    }

    onInputChange = e => {
        e.preventDefault()

        const { name, value } = e.target

        this.setState({ [name]: value })
    }

    sendResetEmail = e => {
        e.preventDefault()

        let valid = true

        const { email } = this.state

        if (email.length === 0) {
            valid = false
            this.props.alertWarn(this.context.t('E-mail address is required'))
        }
        else if (!emailRegex.test(email)) {
            valid = false
            this.props.alertWarn(this.context.t('E-mail address is not valid'))
        }

        if (valid && !this.state.waitingForReset) {
            this.setState({
                waitingForReset: true
            })
            this.props.sendPasswordResetEmail(email, () => {
                this.setState({
                    waitingForReset: false
                })
            })
        }
    }


    render() {
        const { email, waitingForReset } = this.state

        const { user } = this.props

        if (user !== null) {
            this.props.alertWarn(this.context.t('You can\'t reset password when you are logged in'))
            return <Redirect to={routes.panel.home} />
        }

        return (
            <div className="login-container">
                <LanguageSwitcher />
                <div className="header">
                    <div className="logo">
                        <a href={'https://easydocs.pl/'} />
                        <img src={Logo} alt="EasyDocs Logo" />
                    </div>
                    <div className="go-back" onClick={() => this.props.history.goBack()}>{this.context.t('Go back')}</div>
                </div>

                <div className="form-col">
                    <form onSubmit={this.sendResetEmail}>
                        <h1>{this.context.t('Reset password')}</h1>

                        <p className="info">
                            {this.context.t('Enter your registered email address to receive password reset instruction')}
                        </p>

                        <div className="form-group">
                            <input type="email" className={`${email.length ? 'has-content' : ''}`} name="email" id="email" value={email} onChange={this.onInputChange} placeholder="" />
                            <label htmlFor="email">{this.context.t('E-mail address')}</label>
                        </div>

                        <input className={`${waitingForReset ? 'waiting' : ''}`} type="submit" value={this.context.t("Reset password")} />
                    </form>
                </div>
                <div className="img-col">
                    <div className="text">
                        {this.context.t('Easy and convenient way to manage documents')}
                    </div>
                </div>
            </div>
        )
    }
}

Home.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user
})

const mapDispatchToProps = {
    sendPasswordResetEmail: UserActions.sendPasswordResetEmail,
    resetPassword: UserActions.resetPassword,
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)

