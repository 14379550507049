import React, { Component, useEffect } from 'react'
import gsap from "gsap";
import { connect } from 'react-redux'
import { UserActions } from '../actions/UserActions'
import { AlertActions } from '../actions/AlertActions'
import { Link, Redirect } from 'react-router-dom'
import routes from '../helpers/routes'
import { emailRegex } from '../helpers/regexList'
import PropTypes from 'prop-types'
import Turnstile from "react-turnstile";
import moment from 'moment'

import Logo from '../img/easy-docs-logo-big.svg'
import Man from '../img/Man.svg'
import mailbox from '../img/mailbox.svg'
import shape from '../img/shape.svg'
import vectormobile from '../img/vector-mobile.svg'
import '../css/NewRegisterPage.css'

export class NewRegisterPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cursourWait: false,
            email: '',
            showConfirmEmailPopup: false,
            selectedCheckbox: false,
            selectedPersonalDataCheckbox: false,
            selectedRegulationCheckbox: false,
            selectedPrivacyCheckbox: false,
            selectedAgreementCheckbox: false,
            showVideo: false,
            showFail: '',
            showMobileRegister: false,
            campaign: 'try-app',
            subType: 'PRO',
            captcha: '',
            focusEmail: false,
            ref: ''
        }
    }

    componentDidMount() {
        console.log("RRFERRER:")
        console.log(document.referrer)
        this.setState({
            ref: document.referrer
        })
        window.addEventListener("keydown", this.escFunction, false)
        var campaignName = this.props.location.pathname.replace('/register', '').replace('/', '')
        this.setState({
            campaign: campaignName,
        })

        if (campaignName.endsWith('-basic')) {
            this.setState({
                subType: 'BASIC',
            })
        } else if (campaignName.endsWith('-pro')) {
            this.setState({
                subType: 'PRO',
            })
        } else if (campaignName.endsWith('-pro-plus')) {
            this.setState({
                subType: 'PRO+',
            })
        } else if (campaignName.endsWith('-solo')) {
            this.setState({
                subType: 'SOLO',
            })
        } else if (campaignName.endsWith('-free')) {
            this.setState({
                subType: 'FREE',
            })
        } else {
            this.setState({
                subType: 'PRO',
            })
        }

        const divs = document.querySelectorAll(".transfers")
        gsap.set(divs[1], { x: 2, opacity: 0.2 });
        gsap.set(divs[2], { x: 5, opacity: 0.4 });
        gsap.set(divs[3], { x: 10, opacity: 1 });
        gsap.set(divs[4], { x: 5, opacity: 0.4 });
        gsap.set(divs[5], { x: 2, opacity: 0.2 });

        gsap
            .timeline({ repeat: -1, defaults: { duration: 2.5, ease: 'none' } })

            .add("one")
            .to(divs[0], { y: 540, x: 0, opacity: 0.0 }, "one")
            .to(divs[1], { y: -90, x: 0, opacity: 0.0 }, "one")
            .to(divs[2], { y: -90, x: 2, opacity: 0.2 }, "one")
            .to(divs[3], { y: -90, x: 5, opacity: 0.4 }, "one")
            .to(divs[4], { y: -90, x: 10, opacity: 1 }, "one")
            .to(divs[5], { y: -90, x: 5, opacity: 0.4 }, "one")
            .to(divs[6], { y: -90, x: 2, opacity: 0.2 }, "one")

            .add("two")
            .to(divs[0], { y: 450, x: 2, opacity: 0.2 }, "two")
            .to(divs[1], { y: 450, x: 0, opacity: 0.0 }, "two")
            .to(divs[2], { y: -180, x: 0, opacity: 0.0 }, "two")
            .to(divs[3], { y: -180, x: 2, opacity: 0.2 }, "two")
            .to(divs[4], { y: -180, x: 5, opacity: 0.4 }, "two")
            .to(divs[5], { y: -180, x: 10, opacity: 1 }, "two")
            .to(divs[6], { y: -180, x: 5, opacity: 0.4 }, "two")

            .add("three")
            .to(divs[0], { y: 360, x: 5, opacity: 0.4 }, "three")
            .to(divs[1], { y: 360, x: 2, opacity: 0.2 }, "three")
            .to(divs[2], { y: 360, x: 0, opacity: 0.0 }, "three")
            .to(divs[3], { y: -270, x: 0, opacity: 0.0 }, "three")
            .to(divs[4], { y: -270, x: 2, opacity: 0.2 }, "three")
            .to(divs[5], { y: -270, x: 5, opacity: 0.4 }, "three")
            .to(divs[6], { y: -270, x: 10, opacity: 1 }, "three")

            .add("four")
            .to(divs[0], { y: 270, x: 10, opacity: 1 }, "four")
            .to(divs[1], { y: 270, x: 5, opacity: 0.4 }, "four")
            .to(divs[2], { y: 270, x: 2, opacity: 0.2 }, "four")
            .to(divs[3], { y: 270, x: 0, opacity: 0.0 }, "four")
            .to(divs[4], { y: -360, x: 0, opacity: 0.0 }, "four")
            .to(divs[5], { y: -360, x: 2, opacity: 0.2 }, "four")
            .to(divs[6], { y: -360, x: 5, opacity: 0.4 }, "four")

            .add("five")
            .to(divs[0], { y: 180, x: 5, opacity: 0.4 }, "five")
            .to(divs[1], { y: 180, x: 10, opacity: 1 }, "five")
            .to(divs[2], { y: 180, x: 5, opacity: 0.4 }, "five")
            .to(divs[3], { y: 180, x: 5, opacity: 0.2 }, "five")
            .to(divs[4], { y: 180, x: 0, opacity: 0.0 }, "five")
            .to(divs[5], { y: -450, x: 0, opacity: 0.0 }, "five")
            .to(divs[6], { y: -450, x: 2, opacity: 0.2 }, "five")

            .add("six")
            .to(divs[0], { y: 90, x: 2, opacity: 0.2 }, "six")
            .to(divs[1], { y: 90, x: 5, opacity: 0.4 }, "six")
            .to(divs[2], { y: 90, x: 10, opacity: 1 }, "six")
            .to(divs[3], { y: 90, x: 5, opacity: 0.4 }, "six")
            .to(divs[4], { y: 90, x: 2, opacity: 0.2 }, "six")
            .to(divs[5], { y: 90, x: 0, opacity: 0.0 }, "six")
            .to(divs[6], { y: -540, x: 0, opacity: 0.0 }, "six")

            .add("seven")
            .to(divs[0], { y: 0, x: 0, opacity: 0.0 }, "seven")
            .to(divs[1], { y: 0, x: 2, opacity: 0.2 }, "seven")
            .to(divs[2], { y: 0, x: 5, opacity: 0.4 }, "seven")
            .to(divs[3], { y: 0, x: 10, opacity: 1 }, "seven")
            .to(divs[4], { y: 0, x: 5, opacity: 0.4 }, "seven")
            .to(divs[5], { y: 0, x: 2, opacity: 0.2 }, "seven")
            .to(divs[6], { y: 0, x: 0, opacity: 0.0 }, "seven")
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.escFunction, false)
    }

    escFunction = (e) => {
        if (e.keyCode === 27 && this.state.showVideo) {
            this.toggleWindowVideo()
        }
    }

    onInputChange = e => {
        e.preventDefault()

        const { name, value } = e.target

        this.setState({ [name]: value })
    }

    toggleRegister = () => {
        this.setState({
            showMobileRegister: !this.state.showMobileRegister
        })
    }

    toggleWindow = () => {
        this.setState({
            showConfirmEmailPopup: !this.state.showConfirmEmailPopup
        })
    }

    toggleWindowVideo = e => {
        this.setState({
            showVideo: !this.state.showVideo
        })
    }
    toggleCheckbox = (type) => {
        if (type === 'PERSONAL-DATA') {
            this.setState({
                selectedPersonalDataCheckbox: !this.state.selectedPersonalDataCheckbox
            })
        } else if (type === 'REGULATION') {
            this.setState({
                selectedRegulationCheckbox: !this.state.selectedRegulationCheckbox
            })
        } else if (type === 'PRIVACY') {
            this.setState({
                selectedPrivacyCheckbox: !this.state.selectedPrivacyCheckbox
            })
        } else if (type === 'AGREEMENT') {
            this.setState({
                selectedAgreementCheckbox: !this.state.selectedAgreementCheckbox
            })
        } else if (type === 'ALL-DOCS') {
            const { selectedRegulationCheckbox, selectedPrivacyCheckbox, selectedAgreementCheckbox } = this.state
            if (selectedRegulationCheckbox && selectedPrivacyCheckbox && selectedAgreementCheckbox) {
                this.setState({
                    selectedRegulationCheckbox: false,
                    selectedPrivacyCheckbox: false,
                    selectedAgreementCheckbox: false
                })
            } else {
                this.setState({
                    selectedRegulationCheckbox: true,
                    selectedPrivacyCheckbox: true,
                    selectedAgreementCheckbox: true
                })
            }
        }
    }

    captchaChange = token => {
        if (token) {
            this.setState({
                captcha: true,
                captchaToken: token,
            })
        } else {
            this.setState({
                captcha: true,
                captchaToken: '',
            })
        }
    }

    captchaExpire = () => {
        this.setState({
            captcha: '',
        })
    }

    enterEmail = () => {
        this.setState({
            focusEmail: !this.state.focusEmail,
        })
    }

    save = () => {
        const { email, selectedPersonalDataCheckbox, selectedRegulationCheckbox, selectedPrivacyCheckbox, selectedAgreementCheckbox, captcha, campaign, ref, captchaToken, subType } = this.state
        var valid = true

        if (!captcha) {
            valid = false
            this.setState({
                showFail: 'Please confirm you are not a robot'
            })
        }
        if (!selectedPersonalDataCheckbox || !selectedRegulationCheckbox || !selectedPrivacyCheckbox || !selectedAgreementCheckbox) {
            valid = false
            this.setState({
                showFail: 'You must tick all required consents.'
            })
        }

        if (email.length === 0) {
            valid = false
            this.setState({
                showFail: 'E-mail address is required'
            })
        }
        else if (!emailRegex.test(email)) {
            valid = false
            this.setState({
                showFail: 'E-mail address is not valid'
            })
        }

        if (valid && !this.state.cursourWait) {
            this.setState({
                showFail: '',
                cursourWait: true
            })
            this.props.newRegister(email.toLowerCase(), campaign, ref, subType, captchaToken, success => {
                if (success) {
                    this.setState({
                        showConfirmEmailPopup: true
                    })
                }
                this.setState({
                    cursourWait: false,
                })
            })
        }
    }




    render() {
        const { cursourWait, subType, referrer, email, showConfirmEmailPopup, selectedPersonalDataCheckbox, selectedRegulationCheckbox, selectedPrivacyCheckbox, selectedAgreementCheckbox, showFail, showMobileRegister, showMobileMenu, showVideo, enterEmail } = this.state
        const { user } = this.props

        if (user !== null) {
            this.props.alertWarn(this.context.t('You can\'t register when you are logged in'))
            return <Redirect to={routes.panel.home} />
        }
        return (
            <div className={`new-register ${cursourWait ? 'cursour-wait' : ''}`}>
                {
                    showVideo ? (
                        <div class="video-popup open" id="video-popup" onClick={e => this.toggleWindowVideo(e)}>
                            <div class="close-button"></div>
                            <div class="iframe-container">
                                <iframe id="yt-iframe" src="https://www.youtube-nocookie.com/embed/w5lSCGxVDY8?autoplay=1" alt="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                    ) : null
                }
                <div className="header">
                    <div className="logo">
                        <a href={'https://easydocs.pl/'}>
                            <img src={Logo} alt="EasyDocs Logo" />
                        </a>
                    </div>
                    <div className="lang-eng mobile-dn" onClick={() => this.props.changeLanguage('en')}>
                        EN
                    </div>
                    <div className="lang-pl mobile-dn" onClick={() => this.props.changeLanguage('pl')}>
                        PL
                    </div>

                    <Link className="login-button mobile-dn" to={routes.login}>
                        {this.context.t('Sign in')}
                    </Link>

                    <div className={`mobile-menu-icon mobile-db ${showMobileMenu ? 'menu-visible' : ''}`} onClick={() => this.setState({ showMobileMenu: !showMobileMenu })}>
                        <div className="bar-top"></div>
                        <div className="bar-mid"></div>
                        <div className="bar-bottom"></div>
                    </div>
                    <div className={`mobile-menu ${showMobileMenu ? 'show' : ''}`}>
                        <ul>
                        <li><a href="https://easydocs.pl/rozwiazania" target="_blank" className="nav-item security">Rozwiązania</a></li>
                            <li><a href="https://easydocs.pl/o-nas" target="_blank" className="nav-item about-us">O nas</a></li>
                            <li><a href="https://easydocs.pl/cennik" target="_blank" className="nav-item price-list">Cennik</a></li>
                            <li><a href="https://easydocs.pl/faq" target="_blank" className="nav-item price-list">FAQ</a></li>
                            <li><a href="https://easydocs.pl/baza-wiedzy/" target="_blank" className="nav-item blog">Blog</a></li>
                            <li className="log-in"><Link className="log-in" to={routes.login}>Zaloguj się</Link></li>
                            <li className="try-free"><a onClick={() => this.setState({ showMobileMenu: false, showMobileRegister: true })}>Wypróbuj za darmo</a></li>
                        </ul>
                    </div>
                </div>
                <div className="main">
                    <div className="register-main">
                        {
                            showConfirmEmailPopup ? (
                                <div className="confirm-email-popup">
                                    <div className="window">
                                        {/* <img src={shape} className="shape" alt="shape" onClick={() => this.toggleWindow()} /> */}
                                        <img src={mailbox} className="mailbox" alt="mailbox" />
                                        <div className="confirm-mail-one">
                                            {this.context.t('Don\'t forget to confirm your email address!')}
                                        </div>
                                        <div className="confirm-mail-two">
                                            {this.context.t('Confirm your e-mail by clicking on the activation link sent to the address provided during registration.')}
                                        </div>
                                        {/* <div className="confirm-mail-three">
                                            {this.context.t('The activation link will expire after 24 hours.')}
                                        </div> */}
                                    </div>
                                </div>
                            ) : (
                                <div className="register-box">
                                    <div className="text-register mobile-dn">
                                        <div className="text-one">{this.context.t('Create account')}</div>
                                        {
                                            subType !== 'FREE' ? (
                                                <>
                                                    <span className="text-two">{this.context.t('30 days ')}</span>
                                                    <span className="text-three">{this.context.t('without obligations')}</span>
                                                    <span className="text-four">{this.context.t(' and connecting the card.')}</span>
                                                </>
                                            ) : null
                                        }
                                    </div>

                                    <div className="text-register mobile-db">
                                        <div className="main-text">
                                            <span className="text-m1">{this.context.t('Create account')}</span>
                                        </div>
                                        <div className="smaller-text">
                                            <span className="text-m3">{this.context.t('30 days ')}</span>
                                            <span className="text-m4">{this.context.t('without obligations')}</span>
                                            <span className="text-m3">{this.context.t(' and connecting the card.')}</span>
                                        </div>
                                    </div>

                                    <div className={`register-form moble-show`}>
                                        {/* <div className="back-button mobile-db" onClick={() => this.toggleRegister()}>
                                            <img src={vectormobile} className="vectormobile" alt="Back-vector" />
                                            <span className="back-text">{this.context.t('Go back')}</span>
                                        </div> */}

                                        <div className="text-register mobile-db">
                                            <div className="main-text">
                                                <span className="text-m1">{this.context.t('Create account')}</span>
                                            </div>
                                            <div className="smaller-text">
                                                <span className="text-m3">{this.context.t('30 days ')}</span>
                                                <span className="text-m4">{this.context.t('without obligations')}</span>
                                                <span className="text-m3">{this.context.t(' and connecting the card.')}</span>
                                            </div>
                                        </div>

                                        <input id="referrer" type="hidden" name="referrer" value={referrer} />
                                        <div className="input-group">
                                            <input id="email" type="text" name="email" value={email} onChange={this.onInputChange} className={`${email ? 'has-text' : ''}`} />
                                            <label htmlFor="email">{this.context.t("Your e-mail address")}</label>
                                        </div>
                                        <div className="checkbox-all">
                                            <div className="personal-data">
                                                <div className={`checkbox ${selectedPersonalDataCheckbox ? 'selected' : ''}`} onClick={() => this.toggleCheckbox('PERSONAL-DATA')}></div>
                                                <p className="personal-data-text" onClick={() => this.toggleCheckbox('PERSONAL-DATA')}>{this.context.t('I consent to the processing of my personal data in order to provide the EasyDocs service')}</p>

                                            </div>
                                            <div className="acceptance">
                                                <div className={`checkbox ${selectedRegulationCheckbox && selectedPrivacyCheckbox && selectedAgreementCheckbox ? 'selected' : ''}`} onClick={() => this.toggleCheckbox('ALL-DOCS')}></div>
                                                <p className="acceptance-all" onClick={() => this.toggleCheckbox('ALL-DOCS')}>{this.context.t('I accept:')}</p>

                                            </div>
                                            <div className="consent-one">
                                                <div className={`checkbox ${selectedRegulationCheckbox ? 'selected' : ''}`} onClick={() => this.toggleCheckbox('REGULATION')}></div>
                                                <a href="https://easydocs.pl/regulamin/" target="_blank" className="statute">{this.context.t('Terms of service')}</a>

                                            </div>
                                            <div className="consent-two">
                                                <div className={`checkbox ${selectedPrivacyCheckbox ? 'selected' : ''}`} onClick={() => this.toggleCheckbox('PRIVACY')}></div>
                                                <a href="https://easydocs.pl/polityka-prywatnosci/" target="_blank" className="privacy-policy">{this.context.t('Privacy Policy')}</a>

                                            </div>
                                            <div className="consent-three">
                                                <div className={`checkbox ${selectedAgreementCheckbox ? 'selected' : ''}`} onClick={() => this.toggleCheckbox('AGREEMENT')}></div>
                                                <a href="https://easydocs.pl/przetwarzanie-danych/" target="_blank" className="arrangement">{this.context.t('Personal Data Processing Agreement')}</a>

                                            </div>
                                        </div>
                                        <div className="recaptcha-container">
                                            <Turnstile
                                                sitekey='0x4AAAAAAAGVLfL6qA6JrZ5p'
                                                language={localStorage.getItem('language') || 'pl'}
                                                theme="light"
                                                onVerify={(token) => this.captchaChange(token)}
                                            />
                                        </div>
                                        {/* <div className="captcha-container">
                                            <ReCAPTCHA
                                                ref="loginReCaptchaRef"
                                                size="normal"
                                                sitekey="6LceWAEVAAAAAK6LgYLnLuisqGWH9U-XEm4ACBdd"
                                                onChange={token => this.captchaChange(token)}
                                                onExpired={() => this.captchaExpire()}
                                            />
                                        </div> */}

                                        {
                                            showFail ? (
                                                <div className="show-fail">
                                                    {this.context.t(showFail)}
                                                </div>
                                            ) : (
                                                null
                                            )
                                        }

                                        <div className="existing-account">
                                            <div className="text-five">{this.context.t('Already have an account?')}</div>
                                            <Link className="text-six" to={routes.login}>{this.context.t('Log in to EasyDocs.')}</Link>
                                        </div>

                                        <div className="account-button" onClick={() => this.save()}>
                                            <span className="start">{this.context.t('Join now')}</span>
                                            {/* <span className="start">{this.context.t('Start ')}</span>
                                            {
                                                subType === 'FREE' ? (
                                                    this.context.t('free use')
                                                ) : (
                                                    this.context.t('30 days for free')
                                                )
                                            } */}
                                        </div>
                                    </div>

                                    {/* <div className="account-button-mobile mobile-db" onClick={() => this.toggleRegister()}>
                                        {this.context.t('Create an account')}
                                    </div> */}
                                </div>
                            )
                        }
                    </div>
                    {/* <div className="benefits-conteiner mobile-dn">
                        <div className="benefits-box">
                            <div className="transfers">
                                <span className='checkmark'>✓</span>
                                <div className="text">{this.context.t('Convenient execution of bank transfer packages')}</div>
                            </div>
                            <div className="transfers">
                                <span className='checkmark'>✓</span>
                                <div className="text">{this.context.t('Automatic integration with accounting')}</div>
                            </div>
                            <div className="transfers">
                                <span className='checkmark'>✓</span>
                                <div className="text">{this.context.t('Expenditure control')}</div>
                            </div>
                            <div className="transfers">
                                <span className='checkmark'>✓</span>
                                <div className="text">{this.context.t('Central hub for all company documents')}</div>
                            </div>
                            <div className="transfers">
                                <span className='checkmark'>✓</span>
                                <div className="text">{this.context.t('Independent, easy entry of documents by employees')}</div>
                            </div>
                            <div className="transfers">
                                <span className='checkmark'>✓</span>
                                <div className="text">{this.context.t('Durable digital document storage')}</div>
                            </div>
                            <div className="transfers">
                                <span className='checkmark'>✓</span>
                                <div className="text">{this.context.t('Quick search by content of all documents')}</div>
                            </div>
                        </div>
                    </div>
                    <div className="arranging-presentation">
                        <img src={Man} className="Man" alt="Man" />
                        <h2 className="text-pesentation">
                            {this.context.t('If you don\'t want to create an account yet,')}
                        </h2>

                        <h3 className="text-pesentation">
                            {this.context.t('and you would like to learn about the possibilities of EasyDocs - click "Arrange a meeting" and choose a date.')}
                        </h3>
                        <Link className="presentation-button" to={routes.appointment}>
                            <div className="image-presentation"></div>
                            {this.context.t('Arrange a meeting')}
                        </Link>
                        <div className="video-button mobile-dn" onClick={e => this.toggleWindowVideo(e)}>
                            <div className="image-video"></div>
                            {this.context.t('See the video')}
                        </div>
                        <div className="contact mobile-dn">
                            {this.context.t('Have questions? Write to us at ')} <a className="email" href="mailto:support@easydocs.pl">support@easydocs.pl</a> {this.context.t('or call +48 ')}<span className="bold">22 113 13 33</span>
                        </div>
                    </div> */}
                </div>

                <div className="page-footer">
                    <div className="register-copyright">{`© Copyright 2019-${moment().format('YYYY')} EASYDOCS ™`}</div>
                    <a href="https://easydocs.pl/bezpieczenstwo" target="_blank" className="register-security">{this.context.t('Security')}</a>
                    <a href="https://easydocs.pl/regulamin/" target="_blank" className="register-terms">{this.context.t('Terms')}</a>
                    <a href="https://easydocs.pl/polityka-prywatnosci/" target="-blank" className="register-privacy">{this.context.t('Privacy')}</a>
                </div>
            </div>
        )
    }
}

NewRegisterPage.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user
})

const mapDispatchToProps = {
    alertWarn: AlertActions.warning,
    changeLanguage: UserActions.changeLanguage,
    newRegister: UserActions.newRegister
}

export default connect(mapStateToProps, mapDispatchToProps)(NewRegisterPage)
