import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

export class NoSubscriptionWarning extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        const { userCompanies, project } = this.props
        console.log(userCompanies)
        return (
            <div className="subscription-not-active-warning">
                <div className="warning">
                    <p>{this.context.t('This company does not have an active subscription')}</p>
                    {
                        userCompanies && userCompanies.find(c => c.company_id === project.company.id) && userCompanies.find(c => c.company_id === project.company.id).user_role === 'ADMIN' ? (
                            <div className="renew-subscription" onClick={e => this.props.handleSubRenewalClick(project.company)}>
                                {this.context.t('Renew subscription')}
                            </div>
                        ) : (
                            <p className="smaller">{this.context.t('Inform the administrator of this company about the need to renew the subscription in order to continue using EasyDocs')}</p>
                        )
                    }
                </div>
            </div>
        )
    }
}

NoSubscriptionWarning.contextTypes = {
	t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(NoSubscriptionWarning)