import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { isFirefox } from "react-device-detect";
import moment from 'moment'
import copy from 'copy-to-clipboard';
import ReactTooltip from 'react-tooltip'
import Dotdotdot from 'react-dotdotdot'
import { Spinner } from 'react-activity'
import 'react-activity/dist/react-activity.css'

import CostsTabletooltips from './CostsTabletooltips'

import { AutoSizer, Grid, MultiGrid } from "react-virtualized";
import "react-virtualized/styles.css";
import _ from "lodash";

import { ReportsActions } from '../../actions/ReportsActions'
import { UserActions } from '../../actions/UserActions'
import { AlertActions } from '../../actions/AlertActions'
import defaultTableColumns from '../../helpers/defaultTableColumns.json'

export class CostsTableVirtualized extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            docsList: [],
            tableColumns: [],
            adjustedColumsWidths: false,
            maxTableHeight: 500,
            fixedColumns: 0,

            showResizeGhost: false,
            resizeGhostX: -1,
            resizeGhostY: -1
        }
    }
    componentDidMount() {
        const { user } = this.props
        var tableColumns = [], fixedColumns = 0
        if (user.custom_table_columns) {
            tableColumns = JSON.parse(user.custom_table_columns)
        } else {
            tableColumns = defaultTableColumns
        }

        tableColumns.forEach(c => {
            if (c.fixed) fixedColumns++
        })
        this.setState({
            tableColumns: tableColumns,
            fixedColumns: fixedColumns,
            docsList: this.props.docsDownloadedForRaport.filter(e => this.checkIfDocMatchesFilterOptions(e)),
            docsSortField: this.props.sortField,
            docsSortOrder: this.props.sortOrder
        })

        var maxTableHeight = 500
        const { innerHeight, innerWidth } = window
        if (user.new_easydocs_version) {
            maxTableHeight = innerHeight
                - 110 // page padding-top
                - 40  // tabs bar
                - 40  // tab wrapper padding top + bottom
                - 70  // search row + margin-bottom
                - 60  // filters row + margin-bottom
        } else {
            maxTableHeight = innerHeight
                - 110 // page padding-top
                - 50  // page header
                - 20  // page header margin-bottom
                - 10  // docs table padding top&bottom
                - 50  // filters row
                - 10  // filters row margin-bottom
                - 20  // page padding-botom
            if (innerWidth < 1500) maxTableHeight += 10
        }

        this.setState({
            maxTableHeight: maxTableHeight
        })

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.docsDownloadedForRaport !== this.props.docsDownloadedForRaport || this.filtersDidChange(prevProps)) {
            this.setState({
                docsList: this.props.docsDownloadedForRaport.filter(e => this.checkIfDocMatchesFilterOptions(e))
            })
            ReactTooltip.rebuild()
        }

        const { user } = this.props
        if (user.custom_table_columns && user.custom_table_columns !== prevProps.user.custom_table_columns) {
            var tableColumns = JSON.parse(user.custom_table_columns), fixedColumns = 0

            tableColumns.forEach(c => {
                if (c.fixed) fixedColumns++
            })

            if (this.grid?.props?.width) {
                this.adjustColumnsWidths(tableColumns, true)
            } else {
                this.setState({
                    tableColumns: tableColumns
                })
            }

            this.setState({
                fixedColumns: fixedColumns
            })
        }

        const { adjustedColumsWidths } = this.state
        if (!adjustedColumsWidths) {
            if (this.grid?.props?.width) {
                this.adjustColumnsWidths(this.state.tableColumns, true)
            }
        }

        const { docsList, maxTableHeight } = this.state
        if (docsList && prevState.docsList && docsList.length !== prevState.docsList.length) {
            console.log('should readjust width')
            var prevDocsHeight = (prevState.docsList.length + 1) * 40
            var curDocsHeight = (docsList.length + 1) * 40
            if ((prevDocsHeight < maxTableHeight && curDocsHeight > maxTableHeight) || (prevDocsHeight > maxTableHeight && curDocsHeight < maxTableHeight)) {
                setTimeout(() => this.adjustColumnsWidths(this.state.tableColumns, false, curDocsHeight > maxTableHeight), 100)
            }
        }
    }

    setRef = (ref) => {
        if (ref) this.grid = ref
    }

    adjustColumnsWidths = (tableColumns, saveWidths, showVerticalScroll) => {
        var gridWidth = this.grid?.props?.width || 0
        if (gridWidth) {
            var totalColumnsWidth = 0
            var columnsThatCanExpend = 0
            tableColumns.map(c => {
                totalColumnsWidth += c.width
                if (c.canGrow) columnsThatCanExpend++
            })

            var freeSpace = gridWidth - totalColumnsWidth
            if (freeSpace && columnsThatCanExpend && freeSpace > 0) {
                var columnsCopy = [...tableColumns]

                columnsCopy.forEach(c => {
                    var widthToAdd = Math.ceil(freeSpace / columnsThatCanExpend)
                    if (c.canGrow) {
                        c.width += widthToAdd
                        freeSpace -= widthToAdd
                        columnsThatCanExpend -= 1
                    }
                })

                this.setState({
                    tableColumns: columnsCopy,
                    adjustedColumsWidths: true
                }, () => {
                    this.grid.recomputeGridSize()
                    if (saveWidths) {
                        this.props.saveCustomTableColumns(JSON.stringify(columnsCopy), success => {
                            console.log('saved new widths')
                        })
                    }
                })
            } else if (showVerticalScroll && freeSpace > -30) {
                freeSpace *= -1
                freeSpace += 5
                var columnsCopy = [...tableColumns]
                columnsCopy.forEach(c => {
                    var spaceToSubtract = Math.ceil(freeSpace / columnsThatCanExpend)
                    if (c.canGrow && spaceToSubtract) {
                        c.width -= spaceToSubtract
                        freeSpace -= spaceToSubtract
                        columnsThatCanExpend -= 1
                    }
                })

                console.log(freeSpace)
                this.setState({
                    tableColumns: columnsCopy,
                    adjustedColumsWidths: true
                }, () => {
                    this.grid.recomputeGridSize()
                    if (saveWidths) {
                        this.props.saveCustomTableColumns(JSON.stringify(columnsCopy), success => {
                            console.log('saved new widths')
                        })
                    }
                })
            } else {
                this.setState({
                    tableColumns: tableColumns,
                    adjustedColumsWidths: true
                })
            }
        }
    }


    filtersDidChange = prevProps => {
        const { searchField, searchCurrency, selectOnlyAvailableForReport, selectOnlyAvailableForBankTransferFile, selectOnlyNotPaid, selectOnlyNotAccounted } = this.props

        if (searchField !== prevProps.searchField) return true
        if (searchCurrency !== prevProps.searchCurrency) return true
        if (selectOnlyAvailableForReport !== prevProps.selectOnlyAvailableForReport) return true
        if (selectOnlyAvailableForBankTransferFile !== prevProps.selectOnlyAvailableForBankTransferFile) return true
        if (selectOnlyNotPaid !== prevProps.selectOnlyNotPaid) return true
        if (selectOnlyNotAccounted !== prevProps.selectOnlyNotAccounted) return true

        return false
    }

    formatPrice = price => {
        if (price || price === 0) {
            price = price.toFixed(2)
            if (price.indexOf('.') > -1) {
                var parts = price.split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                price = parts.join('.')
            } else {
                price = price.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }

            return price
        } else {
            return ''
        }
    }

    getSortClass = (sortFieldCheck) => {
        const { sortField, sortOrder } = this.props

        if (sortField === sortFieldCheck) {
            if (sortOrder === "asc")
                return "icon-sort down"
            return "icon-sort up"
        }

        return "icon-sort"
    }

    getStatusTooltip = rowData => {
        if (rowData.otherCompany) {
            return rowData.id
        } else if (rowData.status === 'Not applicable') {
            return 'payment-orders'
        } else if ((rowData.status === 'Active' || rowData.status === 'Released' || rowData.status.includes('Account')) && rowData.accountStatus === 'found') {
            return 'active-found'
        } else if ((rowData.status === 'Active' || rowData.status === 'Released' || rowData.status.includes('Account')) && rowData.accountStatus !== 'found') {
            return 'active-notFound'
        } else if (!(rowData.status === 'Active' || rowData.status === 'Released' || rowData.status.includes('Account')) && rowData.accountStatus !== 'found') {
            return 'notActive-notFound'
        } else if (!(rowData.status === 'Active' || rowData.status === 'Released' || rowData.status.includes('Account')) && rowData.accountStatus === 'found') {
            return 'notActive-found'
        } else {
            return 'notActive-notFound'
        }
    }

    headerRenderer = (name) => {
        if (name === 'all') {
            return (
                <div>
                    <span className="column-name" onClick={e => this.props.selectAll(e)}>{this.context.t('Select all ')}</span>
                </div>
            )
        }
    }

    sort = (sortBy) => {
        var { docsSortField, docsSortOrder, docsList } = this.state
        if (docsSortField === sortBy) {
            if (docsSortOrder === 'asc') {
                docsSortOrder = 'desc'
            } else {
                docsSortOrder = 'asc'
            }
        } else {
            docsSortField = sortBy
            docsSortOrder = 'asc'
        }
        docsList.sort(this.dynamicComparer(docsSortField, docsSortOrder))

        this.setState({
            docsList: docsList,
            docsSortField: sortBy,
            docsSortOrder: docsSortOrder
        })
    }

    checkIfDocMatchesFilterOptions = doc => {
        const { searchField, searchCurrency, selectOnlyAvailableForReport, selectOnlyAvailableForBankTransferFile, selectOnlyNotPaid, selectOnlyNotAccounted, alreadyPaidDocs, downloadedDocsType } = this.props
        if (doc && downloadedDocsType !== 'Expenses') {
            var counterparty = doc.counterparty ? doc.counterparty.toLowerCase() : ''
            var comment = doc.comment ? doc.comment.toLowerCase() : ''
            var tags = doc.tags ? doc.tags.toLowerCase() : ''
            var docNumber = doc.documentNumber ? doc.documentNumber.toLowerCase() : ''
            var mpk = doc.mpk1 ? doc.mpk1.toLowerCase() : ''
            if (doc.mpk2) mpk += doc.mpk2.toLowerCase()
            if (doc.mpk3) mpk += doc.mpk3.toLowerCase()
            if (doc.mpk4) mpk += doc.mpk4.toLowerCase()

            var tempSearchField = searchField ? searchField.toLowerCase() : ''

            var matches = true

            if (tempSearchField) {
                if (!(counterparty.includes(tempSearchField) || comment.includes(tempSearchField) || tags.includes(tempSearchField) || docNumber.includes(tempSearchField) || mpk.includes(tempSearchField))) {
                    return false
                }
            }

            if (selectOnlyAvailableForReport) {
                if (!doc.alreadyInReport && doc.reportName === 'PENDING') {
                    return false
                }
            }

            if (selectOnlyAvailableForBankTransferFile) {
                if (!doc.alreadyInBankTransferFile && doc.bankTransferFileName === 'PENDING' || doc.alreadyInBankTransferFile) {
                    return false
                }
            }

            if (selectOnlyNotPaid) {
                if (!(!doc.alreadyPaid && doc.canPay && !alreadyPaidDocs?.find(e => e?.id === doc?.id))) {
                    return false
                }
            }

            if (selectOnlyNotAccounted) {
                if (doc.accounted) {
                    return false
                }
            }

            if (searchCurrency !== 'ALL') {
                if (doc.currency !== searchCurrency) {
                    return false
                }
            }
            console.log('co za matches', matches)
            return matches
        } else if (doc && downloadedDocsType === 'Expenses') {
            console.log(doc)
            var matches = true

            if (searchField) {
                if (!doc.uploadedBy.toLowerCase().includes(searchField.toLowerCase()) && !doc.comment.toLowerCase().includes(searchField.toLowerCase())) {
                    matches = false
                }
            }

            if (searchCurrency !== 'ALL') {
                if (doc.currency !== searchCurrency) {
                    matches = false
                }
            }

            if (selectOnlyAvailableForReport) {
                if (doc.alreadyInReport) {
                    matches = false
                }
            }

            return matches
        } else {
            return false
        }
    }

    dynamicComparer = (key, order = 'asc') => {
        return (a, b) => {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                return 0;
            }

            const varA = (typeof a[key] === 'string') ?
                a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string') ?
                b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (!varA) {
                comparison = -1
            } else if (!varB) {
                comparison = 1
            } else if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === 'desc') ? (comparison * -1) : comparison
            );
        };
    }

    getStatusColor = (rowData) => {
        if (rowData.status === 'PENDING') return ''
        if (rowData.accountStatus === 'other-company' || rowData.accountStatus === 'timeout') return 'error'
        if ((rowData.status === 'Active' && rowData.accountStatus === 'not-found') || (rowData.status !== "Doesn't concern" && rowData.accountStatus === 'not-found')) return 'error'

        return ''
    }

    generateStatusText = (rowData, docIndex) => {
        if (rowData.accountStatus === 'other-company') {
            return this.context.t('Account belongs to other company')
        } else if (rowData.status === 'Active' && rowData.accountStatus === 'not-provided') {
            return this.context.t('Active, no account number to check')
        } else if (rowData.status === 'Active' && rowData.accountStatus === 'not-found') {
            return this.context.t('Active, account not on white list')
        } else if (rowData.status !== "Doesn't concern" && rowData.accountStatus === 'not-found') {
            return this.context.t('Account not on white list')
        } else {
            return this.context.t(rowData.status)
        }
    }

    generateCustomTooltip = (rowData, docIndex) => {
        const index = -1
        // this.state.viesCheckedIndex.findIndex(item => item.docIndexOnList === docIndex)
        const showViesStatus = false
        // this.state.vatIdToCheckedInVies.find(item => item.docIndexOnList === docIndex) ? true : false
        if (rowData.accountStatus === 'timeout') {
            return (
                <div className="custom-tooltip mid">
                    <p>
                        {this.context.t('Data not available')}
                    </p>
                </div>
            )
        } else if (rowData.otherCompany && rowData.accountStatus === "other-company") {
            return (
                <div className="custom-tooltip mid status">
                    <div className='column1'>
                        <div className='item v-align'>
                            <span className="property">{this.context.t('Company to which the account belongs:')}</span>
                            <span className="value">
                                {rowData.otherCompany}
                            </span>
                        </div>
                    </div>
                </div>
            )
        } else if (rowData.status === 'Active' && rowData.accountStatus === 'not-provided') {
            return (
                <div className={'custom-tooltip status'}>
                    <div className='column1'>
                        <div className='item'>
                            <span className="property">{this.context.t('Active VAT payer:')}</span>
                            <span className="value">
                                {this.context.t('Yes')}
                            </span>
                        </div>
                        <div className='item'>
                            <span className="property">{this.context.t('Account number on the list:')}</span>
                            <span className="value">
                                {this.context.t('Not provided')}
                            </span>
                        </div>
                    </div>
                    <div className={showViesStatus ? 'column2 enabled' : 'column2'} >
                        <div className='item'>
                            <span className="property">{this.context.t('VIES VAT-EU:')}</span>
                            {
                                index >= 0 ? (
                                    <span className="value">
                                        {this.context.t(this.state.viesCheckedIndex[index].viesStatus)}
                                    </span>
                                ) : (
                                    <span className="activity-indicator-doc-table">
                                        <div className="spinner-container"><Spinner size={7} speed={0.8} color={"#69B792"} /></div>
                                    </span>
                                )
                            }
                        </div>
                    </div>
                </div>
            )
        } else if (rowData.status === 'Released' && rowData.accountStatus === 'not-provided') {
            return (
                <div className='custom-tooltip status'>
                    <div className='column1'>
                        <div className='item'>
                            <span className="property">{this.context.t('Active VAT payer:')}</span>
                            <span className="value">
                                {this.context.t('No')}
                            </span>
                        </div>
                        <div className='item'>
                            <span className="property">{this.context.t('Account number on the list:')}</span>
                            <span className="value">
                                {this.context.t('Not provided')}
                            </span>
                        </div>
                    </div>
                    <div className={showViesStatus ? 'column2 enabled' : 'column2'} >
                        <div className='item'>
                            <span className="property">{this.context.t('VIES VAT-EU:')}</span>
                            {
                                index >= 0 ? (
                                    <span className="value">
                                        {this.context.t(this.state.viesCheckedIndex[index].viesStatus)}
                                    </span>
                                ) : (
                                    <span className="activity-indicator-doc-table">
                                        <div className="spinner-container"><Spinner size={7} speed={0.8} color={"#69B792"} /></div>
                                    </span>
                                )
                            }
                        </div>
                    </div>
                </div>
            )
        } else if (rowData.status === 'Not applicable') {
            return (
                <div className="custom-tooltip mid status">
                    <p>
                        {this.context.t('Not applicable for payment order')}
                    </p>
                </div>
            )
        } else if ((rowData.status === 'Active' || rowData.status === 'Released') && rowData.accountStatus === 'found') {
            return (
                <div className='custom-tooltip status'>
                    <div className='column1'>
                        <div className='item'>
                            <span className="property">{this.context.t('Active VAT payer:')}</span>
                            <span className="value">
                                {this.context.t('Yes')}
                            </span>
                        </div>
                        <div className='item'>
                            <span className="property">{this.context.t('Account number on the list:')}</span>
                            <span className="value">
                                {this.context.t('Yes')}
                            </span>
                        </div>
                    </div>
                    <div className={showViesStatus ? 'column2 enabled' : 'column2'} >
                        <div className='item'>
                            <span className="property small">{this.context.t('VIES VAT-EU:')}</span>
                            {
                                index >= 0 ? (
                                    <span className="value small">
                                        {this.context.t(this.state.viesCheckedIndex[index].viesStatus)}
                                    </span>
                                ) : (
                                    <span className="activity-indicator-doc-table">
                                        <div className="spinner-container"><Spinner size={7} speed={0.8} color={"#69B792"} /></div>
                                    </span>
                                )
                            }
                        </div>
                    </div>
                </div>
            )
        } else if ((rowData.status === 'Active' || rowData.status === 'Released') && rowData.accountStatus !== 'found') {
            return (
                <div className='custom-tooltip status'>
                    <div className='column1'>
                        <div className='item'>
                            <span className="property">{this.context.t('Active VAT payer:')}</span>
                            <span className="value">
                                {this.context.t('Yes')}
                            </span>
                        </div>
                        <div className='item'>
                            <span className="property">{this.context.t('Account number on the list:')}</span>
                            <span className="value">
                                {this.context.t('No')}
                            </span>
                        </div>
                    </div>
                    <div className={showViesStatus ? 'column2 enabled' : 'column2'} >
                        <div className='item'>
                            <span className="property">{this.context.t('VIES VAT-EU:')}</span>
                            {
                                index >= 0 ? (
                                    <span className="value">
                                        {this.context.t(this.state.viesCheckedIndex[index].viesStatus)}
                                    </span>
                                ) : (
                                    <span className="activity-indicator-doc-table">
                                        <div className="spinner-container"><Spinner size={7} speed={0.8} color={"#69B792"} /></div>
                                    </span>
                                )
                            }
                        </div>
                    </div>
                </div>
            )
        } else if (rowData.status === "Doesn't concern") {
            if (!rowData.vatId)
                return (
                    <div className="custom-tooltip mid status">
                        <p>
                            {this.context.t('TaxID was not provided')}
                        </p>
                    </div>
                )
            else if (rowData.companyCountry && rowData.companyCountry !== "PL")
                return (
                    <div className={showViesStatus ? 'custom-tooltip status' : 'custom-tooltip mid status'}>
                        {
                            showViesStatus
                                ?
                                <>
                                    <div className='column1'>
                                        <div className='item'>
                                            {this.context.t('Not applicable to foreign counterparties')}
                                        </div>
                                    </div>
                                    <div className={showViesStatus ? 'column2 enabled' : 'column2'} >
                                        <div className='item'>
                                            <span className="property">{this.context.t('VIES VAT-EU:')}</span>
                                            {
                                                index >= 0 ? (
                                                    <span className="value">
                                                        {this.context.t(this.state.viesCheckedIndex[index].viesStatus)}
                                                    </span>
                                                ) : (
                                                    <span className="activity-indicator-doc-table">
                                                        <div className="spinner-container"><Spinner size={7} speed={0.8} color={"#69B792"} /></div>
                                                    </span>
                                                )
                                            }
                                        </div>
                                    </div>
                                </>
                                : <p>{this.context.t('Not applicable to foreign counterparties')}</p>
                        }
                    </div>
                )
            else
                return (
                    <div className={showViesStatus ? 'custom-tooltip status' : 'custom-tooltip small status'}>
                        <div className='column1'>
                            <div className='item'>
                                <span className="property">{this.context.t('Country:')}</span>
                                <span className="value">
                                    {rowData.companyCountry ? rowData.companyCountry : ''}
                                </span>
                            </div>
                            <div className='item'>
                                <span className="property">{this.context.t('Company VAT-ID:')}</span>
                                <span className="value">
                                    {rowData.vatId ? rowData.vatId : ''}
                                </span>
                            </div>
                        </div>
                        <div className={showViesStatus ? 'column2 enabled' : 'column2'} >
                            <div className='item'>
                                <span className="property">{this.context.t('VIES VAT-EU:')}</span>
                                {
                                    index >= 0 ? (
                                        <span className="value">
                                            {this.context.t(this.state.viesCheckedIndex[index].viesStatus)}
                                        </span>
                                    ) : (
                                        <span className="activity-indicator-doc-table">
                                            <div className="spinner-container"><Spinner size={7} speed={0.8} color={"#69B792"} /></div>
                                        </span>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )
        } else if (!(rowData.status === 'Active' || rowData.status === 'Released') && rowData.accountStatus !== 'found') {
            return (
                <div className='custom-tooltip status'>
                    <div className='column1'>
                        <div className='item'>
                            <span className="property">{this.context.t('Active VAT payer:')}</span>
                            <span className="value">
                                {this.context.t('No')}
                            </span>
                        </div>
                        <div className='item'>
                            <span className="property">{this.context.t('Account number on the list:')}</span>
                            <span className="value">
                                {this.context.t('No')}
                            </span>
                        </div>
                    </div>
                    <div className={showViesStatus ? 'column2 enabled' : 'column2'} >
                        <div className='item'>
                            <span className="property">{this.context.t('VIES VAT-EU')}</span>
                            {
                                index >= 0 ? (
                                    <span className="value">
                                        {this.context.t(this.state.viesCheckedIndex[index].viesStatus)}
                                    </span>
                                ) : (
                                    <span className="activity-indicator-doc-table">
                                        <div className="spinner-container"><Spinner size={7} speed={0.8} color={"#69B792"} /></div>
                                    </span>
                                )
                            }
                        </div>
                    </div>
                </div>
            )
        } else if (!(rowData.status === 'Active' || rowData.status === 'Released') && rowData.accountStatus === 'found') {
            return (
                <div className='custom-tooltip status'>
                    <div className='column1'>
                        <div className='item'>
                            <span className="property">{this.context.t('Active VAT payer:')}</span>
                            <span className="value">
                                {this.context.t('No')}
                            </span>
                        </div>
                        <div className='item'>
                            <span className="property">{this.context.t('Account number on the list:')}</span>
                            <span className="value">
                                {this.context.t('Yes')}
                            </span>
                        </div>
                    </div>
                    <div className={showViesStatus ? 'column2 enabled' : 'column2'}>
                        <div className='item'>
                            <span className="property">{this.context.t('VIES VAT-EU')}</span>
                            {
                                index >= 0 ? (
                                    <span className="value">
                                        {this.context.t(this.state.viesCheckedIndex[index].viesStatus)}
                                    </span>
                                ) : (
                                    <span className="activity-indicator-doc-table">
                                        <div className="spinner-container"><Spinner size={7} speed={0.8} color={"#69B792"} /></div>
                                    </span>
                                )
                            }
                        </div>
                    </div>
                </div>
            )
        } else {
            console.log('HEREEEEEE')
            console.log(rowData.status, rowData.accountStatus)
            return (
                <div className='custom-tooltip status'>
                    <div className='column1'>
                        <div className='item'>
                            <span className="property">{this.context.t('Active VAT payer:')}</span>
                            <span className="value">
                                {this.context.t('No')}
                            </span>
                        </div>
                        <div className='item'>
                            <span className="property">{this.context.t('Account number on the list:')}</span>
                            <span className="value">
                                {this.context.t('No')}
                            </span>
                        </div>
                    </div>
                    <div className={showViesStatus ? 'column2 enabled' : 'column2'} >
                        <div className='item'>
                            <span className="property small">{this.context.t('VIES VAT-EU')}</span>
                            {
                                index >= 0 ? (
                                    <span className="value">
                                        {this.context.t(this.state.viesCheckedIndex[index].viesStatus)}
                                    </span>
                                ) : (
                                    <span className="activity-indicator-doc-table">
                                        <div className="spinner-container"><Spinner size={7} speed={0.8} color={"#69B792"} /></div>
                                    </span>
                                )
                            }
                        </div>
                    </div>
                </div>
            )
        }
    }

    sortClick = sortBy => {
        var { docsSortField, docsSortOrder, docsList } = this.state
        if (docsSortField === sortBy) {
            if (docsSortOrder === 'asc') {
                docsSortOrder = 'desc'
            } else {
                docsSortOrder = 'asc'
            }
        } else {
            docsSortField = sortBy
            docsSortOrder = 'asc'
        }

        this.setState({
            docsList: [...docsList.sort(this.dynamicComparer(docsSortField, docsSortOrder))],
            docsSortField: docsSortField,
            docsSortOrder: docsSortOrder
        })

        this.props.switchSortingFilter(sortBy)

        // if (sortBy === 'counterparty') {
        //     console.log('now')
        //     console.log(columns[2])
        //     columns[2].width = 400
        //     console.log(columns[2])
        //     this.grid.recomputeGridSize()
        // }
    }

    hoverLeftSide = (e, rowIndex, shouldHover) => {
        const prevEl = e.previousElementSibling;
        const prevInSameRow = prevEl && prevEl.classList.contains(`grid-row-${rowIndex}`)

        if (prevInSameRow) {
            if (shouldHover) {
                prevEl.classList.add('row-hover');
            } else {
                prevEl.classList.remove('row-hover');
            }

            this.hoverLeftSide(prevEl, rowIndex, shouldHover);
        }

    }

    hoverRightSide = (e, rowIndex, shouldHover) => {
        const nextEl = e.nextElementSibling;
        const nextInSameRow = nextEl && nextEl.classList.contains(`grid-row-${rowIndex}`)

        if (nextInSameRow) {
            if (shouldHover) {
                nextEl.classList.add('row-hover');
            } else {
                nextEl.classList.remove('row-hover');
            }

            this.hoverRightSide(nextEl, rowIndex, shouldHover);
        }
    }

    hoverRow = (e, rowIndex, shouldHover) => {
        if (shouldHover) {
            e.currentTarget.classList.add('row-hover');
        } else {
            e.currentTarget.classList.remove('row-hover');
        }

        var allCellsInRow = Array.from(
            document.getElementsByClassName(`grid-row-${rowIndex}`)
        );

        allCellsInRow.forEach(cell => {
            if (shouldHover) {
                cell.classList.add('row-hover');
            } else {
                cell.classList.remove('row-hover');
            }
        })
    }

    getDueDateCellClass = doc => {
        const { alreadyPaidDocs } = this.props
        if (alreadyPaidDocs.find(e => e === doc.id) || doc.alreadyPaid || !doc.dueDate || !moment(doc.dueDate).isValid()) return ''
        if (moment(doc.dueDate).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD') || (doc.priority && !doc.alreadyPaid)) return 'overdue'
        if (moment(doc.dueDate).format('YYYY-MM-DD') < moment().add(3, 'day').format('YYYY-MM-DD') && moment(doc.dueDate).format('YYYY-MM-DD') >= moment().add(1, 'day').format('YYYY-MM-DD')) return 'upcoming'
    }

    confirmationEmailClick = email => {
        console.log('confirmationEmailClick')
        console.log(email)
        if (email) {
            copy(email)
            this.props.alertInfo('Copied to clipboard', 2500)
        }
    }

    handleClickOnHeaderCell = (e, dataKey) => {
        if (dataKey === 'all') {
            this.props.selectAll(e)
        } else {
            this.sortClick(dataKey)
        }
    }

    columnResizeStart = (e, columnIndex, dataKey) => {
        window.addEventListener("mousemove", this.columnResizeMove)
        window.addEventListener("mouseup", this.columnResizeStop)

        var targetPossition = e.currentTarget.getBoundingClientRect() || {}
        this.setState({
            showResizeGhost: true,
            resizeGhostX: e.clientX,
            resizeGhostY: targetPossition.y,
            resizeStartX: e.clientX,
            resizedColmnIndex: columnIndex,
            resizedColumnKey: dataKey
        })
    }

    columnResizeMove = e => {
        const { showResizeGhost } = this.state
        if (showResizeGhost) {
            this.setState({
                resizeGhostX: e.clientX
            })
        }
    }

    columnResizeStop = e => {
        const { resizeStartX, resizedColmnIndex, resizedColumnKey, tableColumns } = this.state
        var resizeDelta = resizeStartX - e.clientX
        var columnsCopy = [...tableColumns]
        columnsCopy[resizedColmnIndex].width -= resizeDelta
        // columnsCopy[resizedColmnIndex].canGrow = false
        this.setState({
            showResizeGhost: false,
            resizeGhostX: -1,
            resizeGhostY: -1,
            tableColumns: columnsCopy
        }, () => {
            const { docsList, maxTableHeight } = this.state
            var totalColumnsWidth = 0
            columnsCopy.forEach(c => {
                totalColumnsWidth += c.width
            })
            var curDocsHeight = (docsList.length + 1) * 40
            if (totalColumnsWidth < this.grid?.props?.width) {
                this.adjustColumnsWidths(columnsCopy, true, curDocsHeight > maxTableHeight)
            } else {
                this.grid.recomputeGridSize()
                this.props.saveCustomTableColumns(JSON.stringify(columnsCopy), success => {
                    console.log('saved new widths')
                })
            }
        })
        window.removeEventListener("mousemove", this.columnResizeMove)
        window.removeEventListener("mouseup", this.columnResizeStop)

    }

    customCellRenderer = ({ columnIndex, key, rowIndex, style, parent }) => {
        const { tableColumns, docsList } = this.state
        if (rowIndex === 0) {
            return (
                <div
                    key={key}
                    style={style}
                    className={`ReactVirtualized__Grid-header-cell ${tableColumns[columnIndex].dataKey} ${tableColumns[columnIndex].resizable ? 'can-resize' : ''}`}
                    onClick={e => this.handleClickOnHeaderCell(e, tableColumns[columnIndex].dataKey)}
                >
                    {
                        tableColumns[columnIndex].dataKey !== 'fileIcon' ? (
                            <>
                                <span className="column-name">{this.context.t(tableColumns[columnIndex].title)}</span>
                                <span className={this.getSortClass(tableColumns[columnIndex].dataKey)}></span>
                            </>
                        ) : null
                    }
                    {
                        tableColumns[columnIndex].resizable ? (
                            <div
                                className="resize-indicator"
                                onMouseDown={e => this.columnResizeStart(e, columnIndex, tableColumns[columnIndex].dataKey)}
                            />
                        ) : null
                    }
                </div>
            )
        } else if (rowIndex === docsList.length + 1) {
            return (
                <div key={key} style={style} className={`ReactVirtualized__Grid-footer-cell`} />
            )
        } else {
            const { alreadyPaidDocs, selectFor, roleInSelectedProject, docsSelectedForReport } = this.props
            var rowData = docsList[rowIndex - 1]
            var dataKey = tableColumns[columnIndex].dataKey

            switch (dataKey) {
                case 'all':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell checkmarks ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            data-tip='show'
                            data-for={selectFor === 'transfer' ? `bank-transfer-file-no-available-${rowData.notAvailableForBankTransfersMagId}` : rowData.wasExported ? `zip-export-not-available` : ''}
                        >
                            {
                                rowData.reportName !== 'PENDING' && rowData.bankTransferFileName !== 'PENDING' && rowData.waproXMLFileName !== 'PENDING' && rowData.zipId !== 'PENDING' ? (
                                    <span
                                        className={`checkbox ${docsSelectedForReport.find(e => e.id === rowData.id) ? 'checked' : ''}
                                    ${((!rowData.canPay || rowData.alreadyInReport) && selectFor === 'report') || (selectFor === 'transfer' && rowData.alreadyInBankTransferFile) || (selectFor === 'wapro' && (rowData.missingWaproFields || rowData.accountingFileId)) || (selectFor === 'symfonia' && (rowData.missingSymfoniaFields || rowData.accountingFileId)) ? 'inactive' : ''}`}
                                        onClick={e => this.props.addDocToSelected(e, rowData)}>
                                    </span>
                                ) : (
                                    <span className="activity-indicator-doc-table">
                                        <Spinner size={11} speed={0.8} color={"#69B792"} />
                                    </span>
                                )
                            }
                            {
                                rowData.priority ? (
                                    <div className={`priotity-icon ${rowData.alreadyPaid ? 'already-paid' : ''}`} data-for={`priority-tooltip`} data-tip="show">!</div>
                                ) : null
                            }
                        </div>
                    )
                case 'fileIcon':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell file ${rowData.type === 'PAYMENT_ORDER' ? "payment-order-icon" : "file-icon"} ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        ></div>
                    )
                case 'counterparty':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            <Dotdotdot clamp={2}><p>{rowData.counterparty}</p></Dotdotdot>
                        </div >
                    )
                case 'documentNumber':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell doc-number ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            <Dotdotdot clamp={2}><p>{rowData.documentNumber}</p></Dotdotdot>
                        </div>
                    )
                case 'importDate':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            <Dotdotdot clamp={2}><p>{rowData.importDate}</p></Dotdotdot>
                        </div>
                    )
                case 'saleDate':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            <Dotdotdot clamp={2}><p>{rowData.saleDate}</p></Dotdotdot>
                        </div>
                    )
                case 'saleMonth':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            <Dotdotdot clamp={2}><p>{moment(rowData.saleDate).format('MM')}</p></Dotdotdot>
                        </div>
                    )
                case 'saleYear':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            <Dotdotdot clamp={2}><p>{moment(rowData.saleDate).format('YYYY')}</p></Dotdotdot>
                        </div>
                    )
                case 'issueDate':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            <Dotdotdot clamp={2}><p>{rowData.issueDate}</p></Dotdotdot>
                        </div>
                    )
                case 'dueDate':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${this.getDueDateCellClass(rowData)} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            <Dotdotdot clamp={2}><p>{rowData.dueDate}</p></Dotdotdot>
                        </div>
                    )
                case 'nettoNumber':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell price ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            {this.formatPrice(parseFloat(rowData.nettoNumber || rowData.netto))}
                        </div>
                    )
                case 'taxNumber':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell price ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            {this.formatPrice(parseFloat(rowData.taxNumber || rowData.tax))}
                        </div>
                    )
                case 'grossNumber':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell price ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            {this.formatPrice(parseFloat(rowData.grossNumber || rowData.gross))}
                        </div>
                    )
                case 'priceNumber':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell price ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            {this.formatPrice(parseFloat(rowData.priceNumber || rowData.price))}
                        </div>
                    )
                case 'currency':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            <Dotdotdot clamp={2}><p>{rowData.currency}</p></Dotdotdot>
                        </div>
                    )
                case 'currencyRate':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell price ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            {rowData.currencyRate ? this.formatPrice(parseFloat(rowData.currencyRate)) : ''}
                        </div>
                    )
                case 'recipientsAccount':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            {rowData.recipientsAccount || ''}
                        </div>
                    )
                case 'status':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell status ${this.getStatusColor(rowData)} ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            <div className='tooltip-trigger'>
                                {
                                    rowData.status === 'PENDING' ? (
                                        <span className="activity-indicator-doc-table">
                                            <Spinner size={11} speed={0.8} color={"#69B792"} />
                                        </span>
                                    ) : (
                                        <Dotdotdot clamp={2}>
                                            <p>
                                                {this.generateStatusText(rowData, rowIndex)}

                                            </p>
                                        </Dotdotdot>

                                    )
                                }
                                {this.generateCustomTooltip(rowData, rowIndex)}
                            </div>
                        </div>
                    )
                case 'comment':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={{ ...style, overflow: 'hidden' }}
                            className={`ReactVirtualized__Grid-cell ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            <Dotdotdot clamp={2}><p>{rowData.comment}</p></Dotdotdot>
                        </div>
                    )
                case 'tags':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            <Dotdotdot clamp={2}><p>{rowData.tags}</p></Dotdotdot>
                        </div>
                    )
                case 'alreadyInReport':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell alreadyInReport ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            {
                                rowData.alreadyInReport && rowData.reportName && rowData.reportName !== 'PENDING' ? (
                                    <span className="icon-xlsx" onClick={e => this.props.downloadReport(e, rowData.projectId, rowData.reportName)}></span>
                                ) : (
                                    this.context.t("No")
                                )
                            }
                        </div>
                    )
                case 'alreadyInBankTransfersFile':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell alreadyInReport ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            {
                                rowData.bankTransferFileName && rowData.bankTransferFileName !== 'PENDING' ? (
                                    this.context.t("Yes")
                                ) : (
                                    this.context.t("No")
                                )
                            }
                        </div>
                    )
                case 'splitPayment':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell splitPayment ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            {rowData.splitPayment ? this.context.t('Yes') : this.context.t('No')}
                        </div>
                    )
                case 'alreadyPaid':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell checkmarks ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                        >
                            {
                                alreadyPaidDocs.find(e => e === rowData.id) || rowData.alreadyPaid ? (
                                    <div className="payment-status" data-for={`payment-method-tooltip-${rowData.paymentMethod}`} data-tip="show">
                                        <span className={`checkbox checked ${alreadyPaidDocs.find(e => e === rowData.id) ? 'new' : ''}`} onClick={e => this.props.togglePaymentList(e, rowData)}></span>
                                        <span>{rowData.paymentMethod === 'TRANSFER' ? (
                                            this.context.t('P')
                                        ) : (
                                            rowData.paymentMethod === 'CARD' ? (
                                                this.context.t('K')
                                            ) : (
                                                rowData.paymentMethod === 'CASH' ? (
                                                    this.context.t('G')
                                                ) : (
                                                    this.context.t('I')
                                                )
                                            )
                                        )}</span>
                                    </div>
                                ) : (
                                    <span className={`checkbox ${!rowData.canBeSelectedToPay || !rowData.canPay ? 'inactive' : ''}`} onClick={e => this.props.togglePaymentList(e, rowData)}></span>
                                )
                            }
                        </div>
                    )
                case 'paymentDate':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            <Dotdotdot clamp={2}><p>{rowData.paymentDate}</p></Dotdotdot>
                        </div>
                    )
                case 'uploadedBy':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell cut-cverflow ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            <Dotdotdot clamp={2}><p>{rowData.uploadedBy}</p></Dotdotdot>
                        </div>
                    )
                case 'acceptedBy':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell cut-cverflow ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            <Dotdotdot clamp={2}><p>{rowData.acceptedBy}</p></Dotdotdot>
                        </div>
                    )
                case 'paidBy':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            <Dotdotdot clamp={2}><p>{rowData.paidBy}</p></Dotdotdot>
                        </div>
                    )
                case 'mpk1':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            <Dotdotdot clamp={2}><p>{rowData.mpk1}</p></Dotdotdot>
                        </div>
                    )
                case 'mpk2':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            <Dotdotdot clamp={2}><p>{rowData.mpk2}</p></Dotdotdot>
                        </div>
                    )
                case 'mpk3':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            <Dotdotdot clamp={2}><p>{rowData.mpk3}</p></Dotdotdot>
                        </div>
                    )
                case 'mpk4':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            <Dotdotdot clamp={2}><p>{rowData.mpk4}</p></Dotdotdot>
                        </div>
                    )
                case 'canBeAccounted':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell checkmarks ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                        >
                            <span className={`checkbox ${rowData.canBeAccounted ? 'checked' : ''} ${roleInSelectedProject === 'USER1' ? 'inactive' : ''}`} onClick={e => this.props.toggleCanBeAccountedStatus(rowData.id, rowData.fileId, rowData.projectId, rowData.type, !rowData.canBeAccounted)}></span>
                        </div>
                    )
                case 'accounted':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell checkmarks ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                        >
                            <span className={`checkbox ${rowData.accounted ? 'checked' : ''} ${roleInSelectedProject === 'USER1' ? 'inactive' : ''}`} onClick={e => this.props.toggleAccountedStatus(rowData.id, rowData.fileId, rowData.projectId, rowData.type, !rowData.accounted)}></span>
                        </div>
                    )
                case 'hasAllWaproFields':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell checkmarks ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            {
                                !rowData.missingWaproFields ? (
                                    this.context.t('Yes')
                                ) : (
                                    this.context.t("No")
                                )
                            }
                        </div>
                    )
                case 'exportedToAccounting':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell alreadyInReport ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            onClick={e => this.props.fileIconClick(e, rowData.projectId, rowData.path, rowData.id, rowData.type, rowData.fileId, rowData.alreadyPaid)}
                        >
                            {
                                !rowData.availableForWaproExport && rowData.accountingFileId && rowData.accountingFileName !== 'PENDING' ? (
                                    <span className="icon-xlsx" onClick={e => this.props.downloadAccountingFile(rowData.accountingFileId)}></span>
                                ) : (
                                    this.context.t("No")
                                )
                            }
                        </div>
                    )
                case 'emailToSendPaymentConfirmation':
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.emailToSendPaymentConfirmation ? 'has-email-to-copy' : ''} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                            data-tip='show'
                            data-for={rowData.emailToSendPaymentConfirmation ? `email-to-send-confirmation-tooltip-${rowData.id}` : ''}
                            onClick={() => this.confirmationEmailClick(rowData.emailToSendPaymentConfirmation)}
                        >
                            <div className='tooltip-trigger'>
                                {rowData.emailToSendPaymentConfirmation ? this.context.t('Yes') : ''}

                                {
                                    rowData.emailToSendPaymentConfirmation ? (
                                        <div className="custom-tooltip left email">
                                            <p>
                                                {rowData.emailToSendPaymentConfirmation}
                                            </p>
                                        </div>
                                    ) : null
                                }
                            </div>
                        </div>
                    )
                default:
                    return (
                        <div
                            onMouseOver={(e) => this.hoverRow(e, rowIndex, true)}
                            onMouseOut={(e) => this.hoverRow(e, rowIndex, false)}
                            key={key}
                            style={style}
                            className={`ReactVirtualized__Grid-cell ${rowIndex % 2 ? 'even' : 'odd'} grid-row-${rowIndex} ${rowData.priority && !rowData.alreadyPaid ? 'high-priority-payment' : ''}`}
                        ></div>
                    )
            }
        }
    }

    render() {
        const { docsDownloadedForRaport, downloadedDocsType, selectFor, docsSelectedForReport } = this.props
        const { tableColumns, fixedColumns, docsSortField, docsSortOrder, docsList, maxTableHeight, showResizeGhost, resizeGhostX, resizeGhostY } = this.state
        console.log(tableColumns)

        if (tableColumns.length) {
            return (
                <div className={`table-responsive ${isFirefox ? 'is-firefox' : ''} ${showResizeGhost ? 'disable-text-select' : ''}`} ref={this.props.setTableRef}>
                    {
                        showResizeGhost ? (
                            <div
                                className="column-resize-ghost"
                                style={{
                                    left: `${resizeGhostX}px`,
                                    top: `${resizeGhostY}px`,
                                    height: `${Math.min((docsList.length + 1) * 41 + 17, maxTableHeight - (docsSelectedForReport.length > 0 ? 50 : 0))}px`
                                }}
                            />
                        ) : null
                    }
                    <CostsTabletooltips selectFor={selectFor} />

                    <AutoSizer disableHeight>
                        {({ width }) => (
                            <>
                                <MultiGrid
                                    ref={this.setRef}
                                    width={width}
                                    height={Math.min((docsList.length + 1) * 41 + 17, maxTableHeight - (docsSelectedForReport.length > 0 ? 50 : 0))}
                                    rowCount={docsList.length + 2}

                                    fixedColumnCount={fixedColumns}
                                    fixedRowCount={1}

                                    cellRenderer={this.customCellRenderer}
                                    columnCount={tableColumns.length}
                                    columnWidth={({ index }) => tableColumns[index].width || 50}

                                    rowHeight={({ index }) => {
                                        if (index < docsList.length + 1) return 40
                                        return 10
                                    }}
                                    overscanRowCount={10}
                                    overscanColumnsCount={3}
                                />
                            </>
                        )}
                    </AutoSizer>
                </div >
            )
        } else {
            return null
        }

    }
}

CostsTableVirtualized.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    sendGetDocumentsRequest: state.Reports.sendGetDocumentsRequest,
    docsDownloadedForRaport: state.Reports.docsDownloadedForRaport,
    alreadyPaidDocs: state.Reports.alreadyPaidDocs,
    sortField: state.Reports.docsSortField,
    sortOrder: state.Reports.docsSortOrder,
    downloadedDocsType: state.Reports.downloadedDocsType,
})

const mapDispatchToProps = {
    switchSortingFilter: ReportsActions.switchDocsSortingFilter,
    saveCustomTableColumns: UserActions.saveCustomTableColumns,
    downloadAccountingFile: ReportsActions.downloadAccountingFile,

    alertInfo: AlertActions.info,
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(CostsTableVirtualized))