import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import ReactTooltip from 'react-tooltip'
import 'react-activity/dist/react-activity.css'

import '../css/AvailableInOtherPlanLabel.css'

export class AvailableInOtherPlanLabel extends Component {

    constructor(props) {
        super(props)
        this.state = {
            companyPlanName: '',
            availableInCurrentPlan: true,
        }
    }

    componentDidMount() {
        const {company, myCompanies, planName, checkAllCompanies} = this.props

        if(company && company.id && myCompanies) {
            const roleInCompany = myCompanies.find(c => c.company_id === company.id) ? myCompanies.find(c => c.company_id === company.id).user_role : null
            this.setState({
                roleInCompany: roleInCompany
            })

            var currentPlanName = '', availableInCurrentPlan = true
            if(company.subscription_type !== 'FREE') currentPlanName = company.subscription_type
            else currentPlanName = company.next_subscription_type

            if(currentPlanName === planName) availableInCurrentPlan = true
            else if((currentPlanName === 'BASIC' && planName !== 'SOLO') || (currentPlanName === 'SOLO' && planName !== 'BASIC')) availableInCurrentPlan = false
            else if(currentPlanName === 'PRO' && planName !== 'BASIC') availableInCurrentPlan = false

            if(currentPlanName.includes('/USER') || currentPlanName === 'ENTERPRISE') availableInCurrentPlan = true

            this.setState({
                companyPlanName: currentPlanName,
                availableInCurrentPlan: availableInCurrentPlan,
            })
        } else if(checkAllCompanies && myCompanies) {
            var hasAccessInMinOneCompany = false

            myCompanies.forEach(c => {
                if(c.company && !hasAccessInMinOneCompany) {
                    var currentPlanName = ''
                    if(c.company.subscription_type !== 'FREE') currentPlanName = c.company.subscription_type
                    else currentPlanName = c.company.next_subscription_type
                    if(currentPlanName === planName) hasAccessInMinOneCompany = true
                    else if((currentPlanName === 'BASIC' && planName !== 'SOLO') || (currentPlanName === 'SOLO' && planName !== 'BASIC')) hasAccessInMinOneCompany = false
                    else if(currentPlanName === 'PRO' && planName !== 'BASIC') hasAccessInMinOneCompany = false

                    if(currentPlanName.includes('/USER') || currentPlanName === 'ENTERPRISE') hasAccessInMinOneCompany = true
                }
            })

            this.setState({
                companyPlanName: 'MIX',
                availableInCurrentPlan: hasAccessInMinOneCompany,
            })

        }
    }

    componentDidUpdate() {
        const {company, myCompanies, planName, checkAllCompanies} = this.props

        if(company && company.id && myCompanies && !this.state.companyPlanName) {
            const roleInCompany = myCompanies.find(c => c.company_id === company.id) ? myCompanies.find(c => c.company_id === company.id).user_role : null
            this.setState({
                roleInCompany: roleInCompany
            })
            var currentPlanName = '', availableInCurrentPlan = true
            if(company.subscription_type !== 'FREE') currentPlanName = company.subscription_type
            else currentPlanName = company.next_subscription_type

            if(currentPlanName === planName) availableInCurrentPlan = true
            else if((currentPlanName === 'BASIC' && planName !== 'SOLO') || (currentPlanName === 'SOLO' && planName !== 'BASIC')) availableInCurrentPlan = false
            else if(currentPlanName === 'PRO' && planName !== 'BASIC') availableInCurrentPlan = false

            if(currentPlanName.includes('/USER') || currentPlanName === 'ENTERPRISE') availableInCurrentPlan = true

            this.setState({
                companyPlanName: currentPlanName,
                availableInCurrentPlan: availableInCurrentPlan,
            })
        } else if(checkAllCompanies && myCompanies && !this.state.companyPlanName) {
            var hasAccessInMinOneCompany = false

            myCompanies.forEach(c => {
                if(c.company && !hasAccessInMinOneCompany) {
                    var currentPlanName = ''
                    if(c.company.subscription_type !== 'FREE') currentPlanName = c.company.subscription_type
                    else currentPlanName = c.company.next_subscription_type
                    if(currentPlanName === planName) hasAccessInMinOneCompany = true
                    else if((currentPlanName === 'BASIC' && planName !== 'SOLO') || (currentPlanName === 'SOLO' && planName !== 'BASIC')) hasAccessInMinOneCompany = false
                    else if(currentPlanName === 'PRO' && planName !== 'BASIC') hasAccessInMinOneCompany = false

                    if(currentPlanName.includes('/USER') || currentPlanName === 'ENTERPRISE') hasAccessInMinOneCompany = true
                }
            })

            this.setState({
                companyPlanName: 'MIX',
                availableInCurrentPlan: hasAccessInMinOneCompany,
            })

        }
    }

    render() {
        const {company, myCompanies, planName, labelSize} = this.props
        const {companyPlanName, availableInCurrentPlan, roleInCompany} = this.state

        if(!availableInCurrentPlan) {
            return(
                <div className={`not-available-in-current-plan ${labelSize === 'FULL' || labelSize === 'LABEL-SHADOW' ? 'full' : ''} ${this.props.location === 'CALENDAR' ? 'calendar' : ''}`}>
                    {
                        labelSize === 'FULL' ? (
                            <div className="info-box">
                                {/* {
                                    roleInCompany === 'ADMIN' ? (
                                        null
                                    ) : ( */}
                                        <div className={`image`}>{planName}</div>
                                    {/* )
                                } */}
                                <div className="text">{this.context.t('A {n} subscription is required for this functionality', {n: planName})}</div>
                                {/* {
                                    roleInCompany === 'ADMIN' ? (
                                        <div className="upgrade-button">{this.context.t('Upgrade to {n}', {n: planName})}</div>
                                    ) : (
                                        null
                                    )
                                } */}
                            </div>
                        ) : (
                            <div className={`label ${labelSize === 'LABEL-SMALL' ? 'small' : ''}`}>
                                <div className={`image`}>{planName}</div>
                            </div>
                        )
                    }
                </div>
            )
        } else {
            return null
        }
    }
}

AvailableInOtherPlanLabel.contextTypes = {
	t: PropTypes.func
}

const mapStateToProps = (state) => ({
    myCompanies: state.Company.companies,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(AvailableInOtherPlanLabel)