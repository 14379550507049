import React, { Component } from 'react'
import { connect } from 'react-redux'
import { UserActions } from '../actions/UserActions'
import { AlertActions } from '../actions/AlertActions'
import { Link, Redirect } from 'react-router-dom'
import routes from '../helpers/routes'
import PropTypes from 'prop-types'
import moment from 'moment'
import CryptoJS from 'crypto-js'
import generator from 'generate-password'
import copy from 'copy-to-clipboard';

import Logo from '../img/easy-docs-logo-big.svg'
import envelope from '../img/envelope.svg'
import '../css/NewRegisterPage.css'
import { vatIdValidate } from '../helpers/vatIdValidate'
import CreateNewPassword from './CreateNewPassword'


export class NewActivateAccountPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            vatId: '',
            companyName: '',
            firstName: '',
            lastName: '',
            password: '',
            confirmPassword: '',
            subType: 'PRO',
            campaign: '',
            ref: '',
            secondPasswordError: '',
            firstPasswordVisible: false,
            secondPasswordVisible: false,
            selectedCheckbox: false,
        }
    }

    componentDidMount() {
        var params = this.props.location.search
        if (params && params.substr(1)) {
            params = params.substr(1)

            var paramsArray = params.split("&")
            var email = '', token = '', object = null

            paramsArray.forEach(param => {
                var individualParam = param.split("=")
                if (individualParam[0] === 'activationToken') {
                    object = decodeURIComponent(individualParam[1])
                } else if (individualParam[0] === "email") {
                    email = decodeURIComponent(individualParam[1])
                } else if (individualParam[0] === 'token') {
                    token = decodeURIComponent(individualParam[1])
                }
            })

            if (object) {
                var bytes = CryptoJS.AES.decrypt(object, 'secret key 123');
                var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

                this.setState({
                    email: decryptedData.email.toLowerCase(),
                    token: decryptedData.token,
                    subType: decryptedData.subType,
                    newCompany: decryptedData.newCompany,
                    campaign: decryptedData.campaign || 'try-app',
                    ref: decryptedData.ref || ''
                })
                this.props.activationLinkClick(decryptedData.email, decryptedData.newCompany ? 'register' : 'invite')
            } else {
                this.setState({
                    email: email.toLowerCase(),
                    token: token,
                    newCompany: false,
                    campaign: 'try-app',
                    ref: '',
                })
                this.props.activationLinkClick(email, 'invite')
            }
        }
    }


    closeAllPopups = e => {
        if (this.state.showSubTypes && !this.hasAncestor(e.target, "subTypesContainer")) {
            this.setState({
                showSubTypes: false
            })
        }
    }

    hasAncestor = (element, id) => {
        while (element) {
            if (element.id && element.id === id) return true

            element = element.parentNode
        }
        return false
    }

    onInputChange = e => {
        e.preventDefault()

        const { name, value } = e.target

        this.setState({ [name]: value })

        if (name === 'vatId') {
            if (value && value.replace(/[^0-9]/gm).length === 10) {
                if (vatIdValidate(value)) {
                    fetch(`https://wl-api.mf.gov.pl/api/search/nip/${value.replace(/[^0-9]/gi, '')}?date=${moment().format("YYYY-MM-DD")}`, { method: 'get' })
                        .then(response => response.json())
                        .then(json => {
                            console.log(json)
                            if (json && json.result && json.result.subject) {
                                this.setState({
                                    companyName: json.result.subject.name
                                })
                            }
                        })
                        .catch(err => {
                            console.log('error when fetching companyName')
                        })

                    this.setState({
                        showInvalidVatIdMsg: false,
                        showUserFields: true
                    })
                } else {
                    this.setState({
                        showInvalidVatIdMsg: true
                    })
                }
            }
        }
    }

    toggleFirstPassword = () => {
        this.setState({
            firstPasswordVisible: !this.state.firstPasswordVisible
        })
    }

    toggleSecondPassword = () => {
        this.setState({
            secondPasswordVisible: !this.state.secondPasswordVisible
        })
    }

    toggleCheckbox = () => {
        this.setState({
            selectedCheckbox: !this.state.selectedCheckbox
        })
    }

    save = () => {
        const { vatId, companyName, firstName, lastName, password, newCompany, subType, campaign, ref, email, token } = this.state
        var valid = true
        var passwordRequirements = 0
        if (password) {
            if (this.hasUppercase(password)) passwordRequirements += 1
            if (this.hasLowercase(password)) passwordRequirements += 1
            if (this.hasDigit(password)) passwordRequirements += 1
        }

        if (!password) {
            valid = false
            this.setState({
                errorText: 'Password is required'
            })
        } else if (password.length < 12) {
            valid = false
            this.setState({
                errorText: 'Passwords must be at least 12 characters long.'
            })
        } else if (passwordRequirements !== 3) {
            valid = false
            this.setState({
                errorText: 'Password must contain at least one uppercase letter, one lowercase letter and a number.'
            })
        }

        if (!lastName) {
            valid = false
            this.setState({
                errorText: 'Last name is required'
            })

        }

        if (!firstName) {
            valid = false
            this.setState({
                errorText: 'First name is required'
            })
        }

        if (newCompany) {
            if (!companyName) {
                valid = false
                this.setState({
                    errorText: 'Company name is required'
                })
            }
            if (!vatId) {
                valid = false
                this.setState({
                    errorText: 'VAT-ID is required'
                })
            }
        }

        if (valid && !this.state.cursourWait) {
            this.setState({
                errorText: '',
                cursourWait: true
            })

            if (newCompany) {
                this.props.activateAccountAndComapny(email, token, vatId, subType, campaign, ref, companyName, firstName, lastName, password, () => {
                    this.setState({
                        cursourWait: false
                    })
                })
            } else {
                this.props.activateAccount(email, token, firstName, lastName, password, () => {
                    this.setState({
                        cursourWait: false
                    })
                })
            }
        }
    }

    hasUppercase = (str) => {
        return (/[A-Z]/.test(str));
    }

    hasLowercase = (str) => {
        return (/[a-z]/.test(str));
    }

    hasDigit = (str) => {
        return (/[0-9]/.test(str));
    }

    hasSymbols = (str) => {
        str = str.replace(/[a-zA-Z0-9]/gm, '')
        console.log(str, str.length)
        return str.length > 0
    }

    toggleSubTypes = (show) => {
        this.setState({
            showSubTypes: show
        })
    }

    selectType = (type) => {
        this.setState({
            showSubTypes: false,
            subType: type
        })
    }

    generatePassowrd = () => {
        var password = generator.generate({
            length: 12,
            numbers: true,
            lowercase: true,
            uppercase: true,
            symbols: false,
            excludeSimilarCharacter: true,
            strict: true,
        });

        this.setState({
            password: password
        })
    }

    render() {
        const { user } = this.props
        const { cursourWait, newCompany, vatId, subType, showSubTypes, companyName, firstName, lastName, password, confirmPassword, secondPasswordError, firstPasswordVisible, secondPasswordVisible, selectedCheckbox, errorText } = this.state

        if (user !== null) {
            //this.props.alertWarn(this.context.t('You can\'t register when you are logged in'))
            return <Redirect to={routes.panel.dashboard} />
        }

        return (
            <div className={`new-activate-account ${cursourWait ? 'cursour-wait' : ''}`} onClick={e => this.closeAllPopups(e)}>

                <div className="header">
                    <div className="logo">
                        <a href={'https://easydocs.pl/'}>
                            <img src={Logo} alt="EasyDocs Logo" />
                        </a>
                    </div>
                    <div className="lang-eng mobile-dn" onClick={() => this.props.changeLanguage('en')}>
                        EN
                    </div>
                    <div className="lang-pl mobile-dn" onClick={() => this.props.changeLanguage('pl')}>
                        PL
                    </div>
                </div>

                <div className="activate-account-main">
                    <div className="data-saving">
                        <img src={envelope} className="envelope" alt="envelope" />
                        <div className="welcome">
                            {this.context.t('Welcome to EasyDocs!')}
                        </div>
                        <div className="text-complete-data">
                            {this.context.t('You are only one step away from positive changes in your company! Complete the data to take full advantage of the system\'s capabilities.')}
                        </div>
                        <form className="form">
                            {
                                newCompany ? (
                                    <>
                                        <div className="input-group nip">
                                            <input id="vatId" type="text" name="vatId" value={vatId} onChange={this.onInputChange} className={`${vatId ? 'has-text' : ''}`} />
                                            <label htmlFor="vatId">{this.context.t("NIP")}</label>
                                            <span className="info">
                                                <div className="info-cloud">
                                                    <div className="info-text">{this.context.t('Enter the NIP number and we will complete the remaining data from GUS database.')}</div>
                                                </div>
                                            </span>
                                        </div>

                                        <div className="input-group company">
                                            <input id="companyName" type="text" name="companyName" value={companyName} onChange={this.onInputChange} className={`${companyName ? 'has-text' : ''}`} />
                                            <label htmlFor="companyName">{this.context.t("Company Name")}</label>
                                        </div>
                                    </>
                                ) : null
                            }


                            <div className="input-group name">
                                <input id="firstName" type="text" name="firstName" value={firstName} onChange={this.onInputChange} className={`${firstName ? 'has-text' : ''}`} />
                                <label htmlFor="firstName">{this.context.t("First name")}</label>
                            </div>
                            <div className="input-group surname">
                                <input id="lastName" type="text" name="lastName" value={lastName} onChange={this.onInputChange} className={`${lastName ? 'has-text' : ''}`} />
                                <label htmlFor="lastName">{this.context.t("Last name")}</label>
                            </div>
                            <div className="input-group email">
                                <input id="email" type="email" name="email" value={this.state.email} readOnly={true} className={`${this.state.email ? 'has-text' : ''}`} autoComplete="email" />
                                <label htmlFor="email">{this.context.t("Your e-mail address")}</label>
                            </div>
                            <div className="input-group">
                                <input id="password" type={`${firstPasswordVisible ? 'text' : 'password'}`} name="password" value={password} onChange={this.onInputChange} autoComplete="new-password" className={`${password ? 'has-text' : ''}`} />
                                <label htmlFor="password">{this.context.t("Create a password for your EasyDocs account")}</label>
                                {
                                    password ? (
                                        <span data-for="copy-password" data-tip="show" className={`copy-to-clipboard`} onClick={e => { copy(password); this.props.alertInfo('Copied to clipboard', 2500) }}>
                                            <div className="info-cloud">
                                                <div className="info-text">{this.context.t('Copy password.')}</div>
                                            </div>
                                        </span>
                                    ) : null
                                }
                                <span data-for="generate-password" data-tip="show" className={`password-generator`} onClick={() => this.generatePassowrd()}>
                                    <div className="info-cloud">
                                        <div className="info-text">{this.context.t('Generate strong password.')}</div>
                                    </div>
                                </span>
                                <span data-for="show-password" data-tip="show" className={`eye ${firstPasswordVisible ? 'visible' : ''}`} onClick={() => this.toggleFirstPassword()}>
                                    <div className="info-cloud">
                                        <div className="info-text">
                                            {
                                                firstPasswordVisible ? (
                                                    this.context.t('Hide password.')
                                                ) : (
                                                    this.context.t('Show password.')
                                                )
                                            }
                                        </div>
                                    </div>
                                </span>
                            </div>

                            <div className="password-text">
                                {this.context.t('Please use at least 12 characters.')}
                            </div>
                            <div className="password-text one">
                                {this.context.t('Password must contain at least one uppercase letter, one lowercase letter and a number.')}
                            </div>

                            {
                                errorText ? (
                                    <div className="show-fail">
                                        {this.context.t(errorText)}
                                    </div>
                                ) : (
                                    null
                                )
                            }

                            <div className="button-save" onClick={() => this.save()}>
                                {this.context.t('Save')}
                            </div>
                        </form>

                    </div>
                </div>
                <div className="page-footer mobile-dn">
                    <div className="register-copyright">{`© Copyright 2019-${moment().format('YYYY')} EASYDOCS ™`}</div>
                    <a href="https://easydocs.pl/bezpieczenstwo" target="_blank" className="register-security">{this.context.t('Security')}</a>
                    <a href="https://easydocs.pl/regulamin/" target="_blank" className="register-terms">{this.context.t('Terms')}</a>
                    <a href="https://easydocs.pl/polityka-prywatnosci/" target="-blank" className="register-privacy">{this.context.t('Privacy')}</a>
                </div>
            </div>

        )
    }
}

NewActivateAccountPage.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user
})

const mapDispatchToProps = {
    alertInfo: AlertActions.info,
    alertWarn: AlertActions.warning,
    changeLanguage: UserActions.changeLanguage,
    activateAccountAndComapny: UserActions.newAccountAndCompanyActivation,
    activateAccount: UserActions.newAccountActivation,
    activationLinkClick: UserActions.activationLinkClick
}

export default connect(mapStateToProps, mapDispatchToProps)(NewActivateAccountPage)

