import moment from "moment";

export async function getCurrencyRateFromNBP(currency, date) {
  try {
    var res = await fetch(`https://api.nbp.pl/api/exchangerates/rates/a/${currency}/${moment(date).format("YYYY-MM-DD")}/?format=json`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    console.log("response ---> " + JSON.stringify(res));
    while (res.status > 205) {
      date = moment(date).subtract(1, "day");
      res = await fetch(
        `https://api.nbp.pl/api/exchangerates/rates/a/${currency}/${moment(
          date
        ).format("YYYY-MM-DD")}/?format=json`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
          },
        }
      );
    }
    const rates = await res.json();
    console.log("response ---> " + JSON.stringify(rates));
    var tableNumber = "",
      effectiveDate = "",
      rate = 0;
    if (rates.rates && Array.isArray(rates.rates) && rates.rates.length > 0) {
      tableNumber = rates.rates[0].no;
      effectiveDate = rates.rates[0].effectiveDate;
      rate = rates.rates[0].mid;
    }
    return { rate, effectiveDate, tableNumber};
  } catch (err) {
    console.log(err);
  }
}
