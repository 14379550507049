import React from 'react'

export default function getFullErrorInfo(error, translate) {
    if (error && error.responseCode) {
        switch (error.responseCode) {
            case '00':
                if (error.status = 'executed')
                    return (
                        <div className="payment-error-info">
                            <div className="short-success-info">
                                <span className="title">{translate('The transaction was successful')}</span>
                            </div>
                        </div>
                    )
                else
                    return (
                        <div className="payment-error-info">
                            <div className="short-error-info">
                                <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                                <p>Odrzucenie transakcji na poziomie operatora (sugerowany kontakt z Espago/Elavon)</p>
                            </div>
                            <div className="full-eror-info">
                                <span className="title">{translate('Additional description of the reason for rejection')}</span>
                                <p>ODMOWA - wystąpił błąd. Transakcja została odrzucona ze względu na brak odpowiedzi z banku, blokadę konta Sprzedawcy, użycie nieobsługiwanego typu karty lub nieprawidłowe dane karty. Prosimy spróbować ponownie później lub skontaktować się z Działem Wsparcia Technicznego Espago.</p>
                            </div>
                        </div>
                    )
            case '1A':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Wymagane silne uwierzytelnienie (SCA)</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>Dla kart Visa: ta płatność wymaga dokonania silnego uwierzytelnienia (SCA).</p>
                        </div>
                    </div>
                )
            case '65':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Karta Mastercard: wymagane silne uwierzytelnienie (SCA)</p>
                            <p>Inna karta: Przekroczony limit liczby użyć</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>Dla kart Mastercard: ta płatność wymaga dokonania silnego uwierzytelnienia (SCA).</p>
                            <p>Dla innych kart: ODMOWA – przekroczony dzienny limit liczby transakcji dla tej karty. Dokonaj zmiany ustawień dziennych limitów lub spróbuj ponownie później.</p>
                        </div>
                    </div>
                )
            case '01':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Kod własny wydawcy karty</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA - problem z autoryzacją. Niezbędny kontakt z bankiem.</p>
                        </div>
                    </div>
                )
            case '02':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Kod własny wydawcy karty</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA - problem z autoryzacją.UWAGA: Nie wolno powtarzać obciążeń dla tej karty.</p>
                        </div>
                    </div>
                )
            case '03':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Nieprawidłowe/niepełne dane akceptanta lub nieaktywne konto</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA - problem z autoryzacją.UWAGA: Nie wolno powtarzać obciążeń dla tej karty.</p>
                        </div>
                    </div>
                )
            case '04':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Karta zastrzeżona (z powodów innych niż kradzież/zgubienie)</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA - karta zastrzeżona. Skontaktuj się z Twoim bankiem w celu wyjaśnienia przyczyny problemu. UWAGA: Nie wolno powtarzać obciążeń dla tej karty.</p>
                        </div>
                    </div>
                )
            case '05':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Nie honorować</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>Płatność została odrzucona przez bank bez podania szczególnej przyczyny. Prawdopodobnie płatności typu MOTO/eCommerce/subskrypcyjne nieaktywne lub niewspierane. Potrzebny kontakt klienta z bankiem w celu wyjaśnienia.</p>
                            <p>ODMOWA – bank odrzucił transakcję ze względu na ustawienia zabezpieczeń (np. karta nie obsługuje płatności cyklicznych/bez kodu CVV) albo blokadę środków lub karta nie obsługuje płatności internetowych/MOTO. Sprawdź ustawienia karty lub użyj innej karty.</p>
                        </div>
                    </div>
                )
            case '07':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Karta zastrzeżona</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA - karta zastrzeżona. UWAGA: Nie wolno powtarzać obciążeń dla tej karty.</p>
                        </div>
                    </div>
                )
            case '12':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Nieprawidłowa transakcja</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA - transakcja niedostępna dla tej karty. Skontaktuj się z Twoim bankiem w celu wyjaśnienia przyczyny problemu. UWAGA: Nie wolno powtarzać obciążeń dla tej karty.</p>
                        </div>
                    </div>
                )
            case '13':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Nieprawidłowa kwota transakcji (przekroczenie limitu) lub błędne przeliczenie walut</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA – Sprawdź, czy dla Twojej karty dostępna jest usługa płatności internetowych lub MOTO, zweryfikuj ustawienia limitów dla takich transakcji lub skontaktuj się z Twoim bankiem (wystawcą karty) w celu wyjaśnienia przyczyny odmowy.</p>
                        </div>
                    </div>
                )
            case '14':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Nieprawidłowy numer karty lub konta</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA - podano nieprawidłowy numer karty. Należy użyć innej karty. UWAGA: Nie wolno powtarzać obciążeń dla tej karty.</p>
                        </div>
                    </div>
                )
            case '15':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Nie zidentyfikowano wydawcy karty</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA - podano nieprawidłowy numer karty. Należy użyć innej karty. UWAGA: Nie wolno powtarzać obciążeń dla tej karty.</p>
                        </div>
                    </div>
                )
            case '30':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Błędny format danych wiadomości autoryzacyjnej</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA - skontaktuj się z Twoim bankiem w celu wyjaśnienia przyczyny problemu</p>
                        </div>
                    </div>
                )
            case '41':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Karta oznaczona jako zgubiona</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA - karta oznaczona jako zgubiona – skontaktuj się z Twoim bankiem w celu wyjaśnienia przyczyny problemu. UWAGA: Nie wolno powtarzać obciążeń dla tej karty.</p>
                        </div>
                    </div>
                )
            case '43':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Karta oznaczona jako skradziona</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA - karta oznaczona jako skradziona – skontaktuj się z Twoim bankiem w celu wyjaśnienia przyczyny problemu. UWAGA: Nie wolno powtarzać obciążeń dla tej karty.</p>
                        </div>
                    </div>
                )
            case '51':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Niewystarczające środki na koncie lub przekroczenie limitu dla danego typu płatności</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA - niewystarczające środki na koncie lub przekroczenie limitu. Zweryfikuj stan swojego konta i spróbuj ponownie.</p>
                        </div>
                    </div>
                )
            case '54':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Przeterminowana karta</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA - karta utraciła ważność. Użyj innej karty.UWAGA: Nie wolno powtarzać obciążeń dla tej karty.</p>
                        </div>
                    </div>
                )
            case '57':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Typ transakcji niedozwolony dla karty</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA – bank odrzucił transakcję ponieważ użyta karta nie może zostać użyta to tego typu transakcji (internetowe, MOTO lub cykliczne). Sprawdź ustawienia karty lub użyj innej karty.UWAGA: Nie wolno powtarzać obciążeń dla tej karty.</p>
                        </div>
                    </div>
                )
            case '59':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Podejrzenie oszustwa</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA - skontaktuj się z Twoim bankiem w celu wyjaśnienia przyczyny problemu</p>
                        </div>
                    </div>
                )
            case '61':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Przekroczenie limitu transakcji dla karty</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA - przekroczenie limitu kwoty transakcji. Skontaktuj się z bankiem w celu zmiany limitu lub użyj innej karty.</p>
                        </div>
                    </div>
                )
            case '62':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Ograniczenie karty, wykluczenie ze względu na kraj wydawcy</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA – użycie karty zostało ograniczone, np. ze względu na ograniczenie (embargo) nałożone na kraj wydawcy (banku). Skontaktuj się z bankiem w celu wyjaśnienia szczegółowej przyczyny problemu.UWAGA: Nie wolno powtarzać obciążeń dla tej karty.</p>
                        </div>
                    </div>
                )
            case '75':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Przekroczony limit błędnych prób podania CVV/CVC/PIN</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA – przekroczony limit błędnych prób podania kodu CVV/CVC/PIN. Zweryfikuj poprawność kodu zabezpieczającego na swojej karcie.</p>
                        </div>
                    </div>
                )
            case '78':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Nowa, nieaktywowana karta</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA – Karta nie została aktywowana. Aktywuj kartę i spróbuj ponownie.UWAGA: Nie wolno powtarzać obciążeń dla tej karty.</p>
                        </div>
                    </div>
                )
            case '79':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Błąd płatności</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>Błąd 79 w Espago - być może Twoja karta wygasła?</p>
                        </div>
                    </div>
                )
            case '82':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Nieprawidłowy kod CVV</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA – Nieprawidłowy kod CVV. Sprawdź poprawność wprowadzonych danych i spróbuj ponownie. UWAGA: Nie wolno powtarzać obciążeń dla tej karty.! Może to zostać uznane za próbę oszustwa!</p>
                        </div>
                    </div>
                )
            case 'N7':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Nieprawidłowy kod CVV</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA – Nieprawidłowy kod CVV. Sprawdź poprawność wprowadzonych danych i spróbuj ponownie. UWAGA: Nie wolno powtarzać obciążeń dla tej karty.! Może to zostać uznane za próbę oszustwa!</p>
                        </div>
                    </div>
                )
            case '91':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Usługa tymczasowo niedostępna, spróbuj ponownie</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA – Tymczasowy błąd po stronie banku, spróbuj ponownie.</p>
                        </div>
                    </div>
                )
            case '92':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Usługa tymczasowo niedostępna, spróbuj ponownie</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA – Tymczasowy błąd po stronie banku, spróbuj ponownie.</p>
                        </div>
                    </div>
                )
            case '94':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Usługa tymczasowo niedostępna, spróbuj ponownie</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA – Tymczasowy błąd po stronie banku, spróbuj ponownie.</p>
                        </div>
                    </div>
                )
            case '98':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Usługa tymczasowo niedostępna, spróbuj ponownie</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA – Tymczasowy błąd po stronie banku, spróbuj ponownie.</p>
                        </div>
                    </div>
                )
            case 'B04':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Płatność zablokowana przez Espago, ze względu na próbę płatności nieaktywaną lub zastrzeżoną kartą. Należy zaktualizować profil klienta przy użyciu innej karty.</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA - karta utraciła ważność lub jest zablokowana i można jej obciążyć. Użyj innej karty.</p>
                        </div>
                    </div>
                )
            case 'B04':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Płatność zablokowana przez Espago, ze względu na próbę płatności nieaktywaną lub zastrzeżoną kartą. Należy zaktualizować profil klienta przy użyciu innej karty.</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA - karta utraciła ważność lub jest zablokowana i można jej obciążyć. Użyj innej karty.</p>
                        </div>
                    </div>
                )
            case 'B14':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Płatność zablokowana przez Espago, ze względu na próbę płatności nieaktywaną lub zastrzeżoną kartą. Należy zaktualizować profil klienta przy użyciu innej karty.</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA - karta utraciła ważność lub jest zablokowana i można jej obciążyć. Użyj innej karty.</p>
                        </div>
                    </div>
                )
            case 'B15':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Płatność zablokowana przez Espago, ze względu na próbę płatności nieaktywaną lub zastrzeżoną kartą. Należy zaktualizować profil klienta przy użyciu innej karty.</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA - karta utraciła ważność lub jest zablokowana i można jej obciążyć. Użyj innej karty.</p>
                        </div>
                    </div>
                )
            case 'B41':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Płatność zablokowana przez Espago, ze względu na próbę płatności nieaktywaną lub zastrzeżoną kartą. Należy zaktualizować profil klienta przy użyciu innej karty.</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA - karta utraciła ważność lub jest zablokowana i można jej obciążyć. Użyj innej karty.</p>
                        </div>
                    </div>
                )
            case 'B43':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Płatność zablokowana przez Espago, ze względu na próbę płatności nieaktywaną lub zastrzeżoną kartą. Należy zaktualizować profil klienta przy użyciu innej karty.</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA - karta utraciła ważność lub jest zablokowana i można jej obciążyć. Użyj innej karty.</p>
                        </div>
                    </div>
                )
            case 'B54':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Płatność zablokowana przez Espago, ze względu na próbę płatności nieaktywaną lub zastrzeżoną kartą. Należy zaktualizować profil klienta przy użyciu innej karty.</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA - karta utraciła ważność lub jest zablokowana i można jej obciążyć. Użyj innej karty.</p>
                        </div>
                    </div>
                )
            case 'E3':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Błąd systemu weryfikacji 3D-Secure w banku.</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA – Błąd systemu ACS w banku. Spróbuj ponownie lub skonsultuj się z bankiem lub sprzedawcą.</p>
                        </div>
                    </div>
                )
            case 'E4':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Bank zwrócił informację o negatywnym wyniku uwierzytelnienia 3D-Secure, np. wpisano błędny kod lub nie zaakceptowano płatności w aplikacji.</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA – Negatywny wynik weryfikacji 3D-Secure w banku.</p>
                        </div>
                    </div>
                )
            case 'E5':
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Tymczasowy błąd przy weryfikacji 3D-Secure</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>ODMOWA – Tymczasowy błąd przy weryfikacji 3D-Secure, spróbuj ponownie później lub skontaktuj się ze sprzedawcą.</p>
                        </div>
                    </div>
                )
            default:
                return (
                    <div className="payment-error-info">
                        <div className="short-error-info">
                            <span className="title">{translate('The reason why Espago rejected the transaction')}</span>
                            <p>Nieznana przyczyna. Prosimy o kontakt z naszym działem wsparcia.</p>
                        </div>
                        <div className="full-eror-info">
                            <span className="title">{translate('Additional description of the reason for rejection')}</span>
                            <p>Operator płatności Espago nie podał dokładnego powodu odrzucenia transakcji.</p>
                        </div>
                    </div>
                )
        }
    }
}