import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'

import arrayObjectIndexOf from "../helpers/indexOfObject"
import { UserActions } from "../actions/UserActions"

const texts = [
    {
        tab: 'ocrScreen',
        steps: [
            ["Here are the basic information about the document",  "By default, they are filled with the most frequently repeated values, to change them click on the arrow and select the appropriate value from the drop-down list"],
            ["Here is a form that you have to fill in with the data from the document on the right", "Jeśli dokument nie jest napisany odręcznie i znajduje się na nim NIP sprzedawcy, odczytu danych możesz dokonać zaznaczając odpowiedni obszar na dokumencie", "Try to mark the data areas precisely so that they do not contain additional text or graphics", "After selecting the area, a list of suggestions about what it may be about should appear", "If such a list does not appear or disappears after moving or resizing the selection, right-click on it to open the list again", "For expense documents and handwritten or not containing the seller's tax tax IDs, enter the details manually"],
            ["Finally, confirm the correctness of the data and save the document", "If you have the U1 role in the binder to which the document has been imported, the data you provided must still be accepted by someone with U2 or U3 permissions", "If you have the role of U2 or U3 in the binder to which the document has been imported, the data will be automatically accepted by you and the document will change its status, disappear from the \"Dropzone and docs to verify\" section and go to the \"Binder docs\" section"]
        ]
    },
    {
        tab: 'reports',
        steps: [
            ["Here you can set parameters for searching documents", "Cost - documents paid directly by your company (e.g. invoice, bill, payment document, etc.)", "Expenses - these are documents paid for by the company's employees for which they should be reimbursed"],
            ["Here is a list of actions you can perform with downloaded documents", "To learn more about each option after closing the help overlay hover the cursor over it and wait a moment until its description is displayed"],
            ["Documents are selected by clicking on the checkbox on the left side of the table", "If the checkbox is gray, the document cannot be selected", "Accessibility of the document depends on the selected action"]
        ]
    },
    {
        tab: 'dashboard',
        steps: [
            ["This is where you start importing documents into EasyDocs", "There are four ways to import documents at your disposal: drag&drop them here, click on upload icon, send them via e-mail that you can find in binder informations above or send photos via our mobile app", "All your documents that were not accepted yet will be visible here", "When processing ends click on document to verify it", "Accepted documents will disappear from this window and will be available below and in the Docs tab"],
            ["Accepted documents are saved under the name consistent with the document naming mask (DOCUMENT-MONTH / DOCUMENT-DATE_ PROJECT-CODE_ CONTRACTOR-CODE_DOCUMENT-TYPE), and then they are archived in IBM Cloud"],
            ["If you want to add to EasyDocs a document other than a cost invoice or your expense for company purposes, here you enter other types of payments such as taxes, social insurance or other"],
            ["Accepted documents and payment orders are already active in EasyDocs and appear in the statistics, here you can view upcoming payments and grouped costs from recent months"],
            ["Calendar helps you to track your tasks", "Days in bold indicate a task for you - you can preview the content of the task by hovering over the day"]
        ]
    },
    {
        tab: 'docs',
        steps: [
            ["Here you can enter a phrase that will help you find a specific file", "You can search by filename, original filename, or tags"],
            ["You can switch between list view and tile view"],
            ["Here is a list of files that you can access in all of your binders", "Click on document to open preview, where you can edit, download or delete saved documents"]
        ]
    },
    {
        tab: 'calendar',
        steps: [
            ["Here you can add new tasks for yourself and others in your company"],
            ["Here is a list of your tasks", "If you are the creator of a task, when you hover the mouse over it, the options for editing or deleting the selected task will appear"],
            ["Day in bold indicates a scheduled task", "After clicking on a specific day, only tasks from that day will be shown on the list on the left", "Clicking again will deselect the selected day"],
            ["You can sync your EasyDocs Calendar with your Google Calendar", "The tasks that you create here will automatically go to the selected Kalernadz on Google"]
        ]
    },
    {
        tab: 'settings',
        steps: [
            ["Here you can set your profile picture, which will only be visible to other users in your company", "You can also change your current password"],
            ["Here you can set what information you want to receive in the daily e-mail from EadyDocs", "If you do not select any information, you will not receive such an email"],
            ["Here you can change the language of the EasyDocs app"],
            ["The numbers provided here will be used to reimburse any business costs incurred by you"],
            ["Here you will find the data you need to log in to the EasyDocs desktop application, which saves documents sent to IBM Cloud servers, locally on your computer"],
            ["If you find that you do not want EasyDocs to use your data, you can withdraw your consent here", "By withdrawing your consent to the processing of personal data, you will no longer be able to use EasyDocs"]
        ]
    },
    {
        tab: 'adminCompany',
        steps: [
            ["Here he can navigate between your companies if you have more then one", "Only binders from the currently selected company are displayed below"],
            ["Here you will find informations that are used to identify your company within EasyDocs"],
            ["Only you (and possibly other administrators within this company) and the EasyDocs team can see your company's contact information"],
            ["Bank details are used when creating a transfer basket that you can import directly to your bank", "Without this data, the creation of the transfer basket is blocked"],
            ["Here you can enter the account numbers for tax payments and for the payment of social security contributions", "The bank account numbers provided here will be automatically filled in when creating tax and social security payment orders"],
            ["Here you can add or remove tags that are used to describe files within your company"]
        ]
    },
    {
        tab: 'adminCounterparties',
        steps: [
            ["Here he can navigate between your companies if you have more then one", "Only binders from the currently selected company are displayed below"],
            ["Here you can enter a phrase that will help you find a specific counterparty", "You can search by name, code, or Vat ID"],
            ["Here is a list of your counterparties", "Counterparties are added automatically when importing the first document from a given seller", "By clicking on the pencil icon on the left, you can edit the name and code of a given counterparty", "By clicking on the number of documents on the right, you can go to the Reports tab and see the details of these documents"],
        ]
    },
    {
        tab: 'adminProjectsMain',
        steps: [
            ["Here he can navigate between your companies if you have more then one", "Only binders from the currently selected company are displayed below"],
            ["Create new binder and invite new members"],
            ["You can switch between Binders and Users view"],
            ["Here is a list of all binders in the currently selected company", "By clicking on a binder, you will see an access matrix that shows the rights of users in the selected binder"]
        ]
    },
    {
        tab: 'adminProjectsProject',
        steps: [
            ["By clicking this button, you will exit the access matrix view and return to the view of all binders in the company"],
            ["Here you can add new users to the selected binder", "You can also invite users who are not yet using EasyDocs"],
            ["The access matrix is located here", "To change the user's access rights in a binder, drag and drop him onto the selected area", "The descriptions of the permissions will show up after you hover over the question mark icon next to each permission level"]
        ]
    },
    {
        tab: 'adminProjectsMembers',
        steps: [
            ["You can search for a specific user by their e-mail address, first name or last name"],
            ["Here you can invite new users to EasyDocs and automatically assign them to selected binders"],
            ["Here is a list of all users assigned to your company", "By clicking on the Additional options icon on the right side of the table, you can assign the Administrator role to the user so that he can also manage your company or completely remove him from your company"]
        ]
    },
]

export class OverlayInstructions extends Component {

    constructor(props) {
        super(props)
        this.state = {
            textIndex: -1,
            scrollOffset: 0,
        }
    }

    componentDidMount() {
        window.addEventListener("keydown", this.escFunction, false)
        console.log(document.documentElement.clientHeight)
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
        document.body.style.overflow = 'hidden';

        if(this.refs.overlayBackground) {
            this.generateClipPath()
        }

        var textIndex = arrayObjectIndexOf(texts, this.props.name, 'tab')

        console.log(textIndex)

        if(textIndex > -1) {
            this.setState({
                textIndex: textIndex
            })
        }
    }

    componentDidUpdate() {
        if(this.refs.overlayBackground) {
            this.generateClipPath()
        }
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.escFunction, false)
        document.body.style.overflow = 'unset';
    }

    escFunction = (e) => {
		if(e.keyCode === 27) {
            this.hideOverlay()
		}
	}

    generateClipPath = () => {
        var cp = 'polygon(evenodd, 0 0, 100% 0, 100% 100%, 0% 100%, 0 0';
        this.props.possitions.forEach(rect => {
            cp += ','
            cp += (rect.x - 3)+ 'px 0px,'
            cp += (rect.x - 3) + 'px ' + (rect.y - 3) + 'px,'
            cp += (rect.x + rect.w + 3) + 'px ' + (rect.y - 3) + 'px,'
            cp += (rect.x + rect.w + 3) + 'px ' + (rect.y + rect.h + 3) + 'px,'
            cp += (rect.x - 3) + 'px ' + (rect.y + rect.h + 3 ) + 'px,'
            cp += (rect.x - 3) + 'px ' + (rect.y - 3) + 'px,'
            cp += (rect.x - 3) + 'px 0px'
        })

        cp += ')'

        this.refs.overlayBackground.style.clipPath = cp
    }

    hideOverlay = () => {
        var {overlayPreferance, name} = this.props

        overlayPreferance[name] = false
        this.props.toggleOverlay(overlayPreferance)
    }

    getStyleForStep = (index, hole, name) => {
        const {dashboardViewModel} = this.props
        var top = 0, left = 0

        top = hole.y - 5
        left = hole.x + hole.w + 20


        if(name === 'ocrScreen') {
            if(index === 0) {
                top = hole.y + hole.h + 5
            } else if(index === 2) {
                top = hole.y + hole.h + 5
            }
        } else if(name === 'reports') {
            if(index === 0) {
                left = hole.x - 5
            } else if(index === 1) {
                top = hole.y + hole.h + 5
                left = hole.x - 5
            } else if(index === 2) {
                top = hole.y + 25
            }
        } else if(dashboardViewModel === 'SINGLE' && name === 'dashboard') {
            if(index === 0 || index === 1 || index === 2 || index === 4) {
                top = hole.y + hole.h + 5
                left = hole.x + hole.w + 5
            } else if(index === 3) {
                top = hole.y + hole.h + 5
                left = hole.x - 5
            }
        } else if(name === 'docs') {
            left = hole.x - 25
        } else if(name === 'calendar') {
            if(index === 0 || index === 1) {
                left = hole.x - 5
            } else if(index === 3) {
                top = hole.y + hole.h + 5
                left = hole.x - 5
            }
        } else if(name === 'adminProjectsMain') {
            if(index === 0 || index === 2 || index === 3) {
                left = hole.x - 5
            } else if(index === 1) {
                left = hole.x - 5
                top = hole.y + hole.h + 5
            }
        } else if(name === 'adminProjectsProject' || name === 'adminProjectsMembers') {
            if(index === 0) {
                top = hole.y + hole.h + 5
            } else if(index === 1) {
                left = hole.x - 5
                top = hole.y + hole.h + 5
            } else if(index === 2) {
                left = hole.x + hole.w / 2
                top = hole.y + hole.h / 2
            }
        } else if(name === 'settings') {
            left = hole.x - 5
            top = hole.y - 5 + this.state.scrollOffset
        } else if(name === 'adminCompany') {
            if(index === 0) {
                left = hole.x - 5
                top = hole.y - 5 + this.state.scrollOffset
            } else {
                left = hole.x + hole.w + 5
                top = hole.y - 5 + this.state.scrollOffset
            }
        } else if(name === 'adminCounterparties') {
            if(index === 0) {
                left = hole.x - 5
                top = hole.y - 5 + this.state.scrollOffset
            } else if(index === 1) {
                left = hole.x - 5
                top = hole.y + hole.h + 5
            } else if(index === 2) {
                left = hole.x + hole.w / 2
                top = '50%'
            }
        }

        return{top: top, left: left}
    }

    getStepNumber = (index) => {
        const {possitions, name} = this.props

        if(name === 'dashboard') {
            if(possitions[3] && possitions[3].x === -9999 && index === 4) {
                return index
            } else{
                return index + 1
            }
        } else if(name === 'settings') {
            if(possitions[4] && possitions[4].x === -9999 && index === 5) {
                return index
            } else{ 
                return index + 1
            }
        } else {
            return index + 1
        }
    }

    handleScroll = e => {
        if(this.refs.overlayBackground) {
            console.log(this.refs.overlayBackground.style.top)
            console.log(window.scrollY)
            console.log(window)
            this.refs.overlayBackground.style.top = -1 * window.scrollY + 'px'
            console.log(this.refs.overlayBackground.style.top)
            this.setState({
                state: this.state
            })
        }
    }

    scrollOverlay = () => {
        if(this.state.scrollOffset === 0) {
            this.setState({
                scrollOffset: window.innerHeight - this.props.height
            })
            window.scrollTo({top: this.props.height - window.innerHeight, left: 0, behavior: 'smooth' })
        } else {
            this.setState({
                scrollOffset: 0
            })
            window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
        }
    }

    render() {

        const { possitions, name, dashboardViewModel, scrollable, height } = this.props
        const { textIndex, scrollOffset } = this.state

        return (
            <div className="overlay">
                <div className="hide-overlay" onClick={()  => this.hideOverlay()}></div>
                {
                    height > window.innerHeight ? (
                        <div className={`scroll-overlay ${scrollOffset < 0 ? 'up': 'down'}`} onClick={()  => this.scrollOverlay()} data-for="scroll-info" data-tip="show"></div>
                    ) : (
                        null
                    )
                }
                <div className={`overlay-background`} style={{height: height > window.innerHeight ? height : window.innerHeight, top: scrollOffset}} ref="overlayBackground" ></div>
                {
                    possitions.map(hole => {
                        return (
                            <div className="overlay-hole" style={{top: hole.y - 5 + scrollOffset, left: hole.x - 5, width: hole.w + 10, height: hole.h + 10}}></div>
                        )
                    })
                }

                {
                    textIndex > -1 ? (
                        possitions.map((hole, index) => {
                            return (
                                <div className={`overlay-step ${name}-${index} ${dashboardViewModel === 'SINGLE' ? 'dashboard-single': ''}`} style={this.getStyleForStep(index, hole, name)}>
                                    <div className="header">{this.context.t(`Step `)}{this.getStepNumber(index)}</div>
                                    <ul>
                                        {
                                            texts[textIndex].steps[index].map(li => {
                                                return(<li>{this.context.t(li)}</li>)
                                            })
                                        }
                                    </ul>
                                </div>
                            )
                        })
                    ) : (
                        null
                    )
                }

                <ReactTooltip id="scroll-info" effect="solid" place="left" className="default-tooltip info-tooltip" delayShow={800}>
                    <p>{this.context.t('Scroll page content')}</p>
                </ReactTooltip>
            </div>
        )
    }
}

OverlayInstructions.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    overlayPreferance: state.User.overlayPreferance || {},
    dashboardViewModel: state.User.dashboardViewModel,
})

const mapDispatchToProps = {
    toggleOverlay: UserActions.toggleOverlay
}

export default connect(mapStateToProps, mapDispatchToProps)(OverlayInstructions)