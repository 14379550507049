import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import getFullErrorInfo from '../helpers/subscriptionTransactionHelpers'

import '../css/SubscriptionTransactionPopup.css'

export class SubscriptionTransactionErrorPopup extends Component {
    constructor(props) {
        super(props)

		this.state = {}
    }

    render() {
        const { paymentError } = this.props
        return (
            <div className="payment-error popup">
                <div className="card">
                    {this.context.t('Your transaction was rejected')}
                        {getFullErrorInfo(paymentError, this.context.t)}
                    <div className="finish-button" onClick={() => {this.props.hideAfterPaymentPopup()}}>{this.context.t('Close')}</div>
                </div>
            </div>
        )
    }
}

SubscriptionTransactionErrorPopup.contextTypes = {
	t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionTransactionErrorPopup)