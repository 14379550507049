export const setNegativePricesForCosts = (costs) => {
    costs.forEach(cost => {
        if (cost.price) cost.price = `-${cost.price}`
        if (cost.priceNumber) cost.priceNumber = cost.priceNumber * (-1)
        if (cost.netto) cost.netto = `-${cost.netto}`
        if (cost.nettoNumber) cost.nettoNumber = cost.nettoNumber * (-1)
        if (cost.tax) cost.tax = `-${cost.tax}`
        if (cost.taxNumber) cost.taxNumber = cost.taxNumber * (-1)
        if (cost.gross) cost.gross = `-${cost.gross}`
        if (cost.grossNumber) cost.grossNumber = cost.grossNumber * (-1)
    })

    return costs
}