const zoomInPdf = (pdfScalePercentage, maxPdfScale) => {
    var newPdfScalePercentage, newPdfScale
    if (pdfScalePercentage >= 25 && pdfScalePercentage < 33) {
        newPdfScalePercentage = 33
        newPdfScale = 1.5 * 0.33
    } else if (pdfScalePercentage >= 33 && pdfScalePercentage < 50) {
        newPdfScalePercentage = 50
        newPdfScale = 1.5 * 0.5
    } else if (pdfScalePercentage >= 50 && pdfScalePercentage < 67) {
        newPdfScalePercentage = 67
        newPdfScale = 1.5 * 0.67
    } else if (pdfScalePercentage >= 67 && pdfScalePercentage < 75) {
        newPdfScalePercentage = 75
        newPdfScale = 1.5 * 0.75
    } else if (pdfScalePercentage >= 75 && pdfScalePercentage < 80) {
        newPdfScalePercentage = 80
        newPdfScale = 1.5 * 0.8
    } else if (pdfScalePercentage >= 80 && pdfScalePercentage < 90) {
        newPdfScalePercentage = 90
        newPdfScale = 1.5 * 0.9
    } else if (pdfScalePercentage >= 90 && pdfScalePercentage < 100) {
        newPdfScalePercentage = 100
        newPdfScale = 1.5
    } else if (pdfScalePercentage >= 100 && pdfScalePercentage < 110) {
        newPdfScalePercentage = 110
        newPdfScale = 1.5 * 1.1
    } else if (pdfScalePercentage >= 110 && pdfScalePercentage < 125) {
        newPdfScalePercentage = 125
        newPdfScale = 1.5 * 1.25
    } else if (pdfScalePercentage >= 125 && pdfScalePercentage < 150) {
        newPdfScalePercentage = 150
        newPdfScale = 1.5 * 1.5
    } else if (pdfScalePercentage >= 150 && pdfScalePercentage < 175) {
        newPdfScalePercentage = 175
        newPdfScale = 1.5 * 1.75
    } else if (pdfScalePercentage >= 175 && pdfScalePercentage < 200) {
        newPdfScalePercentage = 200
        newPdfScale = 1.5 * 2
    } else {
        newPdfScalePercentage = pdfScalePercentage + 50
        newPdfScale = 1.5 * (pdfScalePercentage + 50) / 100
    }

    if (newPdfScale >= maxPdfScale) {
        newPdfScale = maxPdfScale
        newPdfScalePercentage = parseInt(maxPdfScale / 1.5 * 100)
    }

    return ({
        pdfScale: newPdfScale,
        pdfScalePercentage: newPdfScalePercentage
    })
}

const zoomInImg = (imgScale, imgScalePercentage) => {
    var newImgScalePercentage, newImgScale
    if (imgScalePercentage >= 25 && imgScalePercentage < 33) {
        newImgScalePercentage = 33
        newImgScale = 0.6 * 0.33
    } else if (imgScalePercentage >= 33 && imgScalePercentage < 50) {
        newImgScalePercentage = 50
        newImgScale = 0.6 * 0.5
    } else if (imgScalePercentage >= 50 && imgScalePercentage < 67) {
        newImgScalePercentage = 67
        newImgScale = 0.6 * 0.67
    } else if (imgScalePercentage >= 67 && imgScalePercentage < 75) {
        newImgScalePercentage = 75
        newImgScale = 0.6 * 0.75
    } else if (imgScalePercentage >= 75 && imgScalePercentage < 80) {
        newImgScalePercentage = 80
        newImgScale = 0.6 * 0.8
    } else if (imgScalePercentage >= 80 && imgScalePercentage < 90) {
        newImgScalePercentage = 90
        newImgScale = 0.6 * 0.9
    } else if (imgScalePercentage >= 90 && imgScalePercentage < 100) {
        newImgScalePercentage = 100
        newImgScale = 0.6
    } else if (imgScalePercentage >= 100 && imgScalePercentage < 110) {
        newImgScalePercentage = 110
        newImgScale = 0.6 * 1.1
    } else if (imgScalePercentage >= 110 && imgScalePercentage < 125) {
        newImgScalePercentage = 125
        newImgScale = 0.6 * 1.25
    } else if (imgScalePercentage >= 125 && imgScalePercentage < 150) {
        newImgScalePercentage = 150
        newImgScale = 0.6 * 1.5
    } else if (imgScalePercentage >= 150 && imgScalePercentage < 175) {
        newImgScalePercentage = 175
        newImgScale = 0.6 * 1.75
    } else if (imgScalePercentage >= 175 && imgScalePercentage < 200) {
        newImgScalePercentage = 200
        newImgScale = 0.6 * 2
    }

    if (newImgScale > 1.0) {
        newImgScale = 1.0
        newImgScalePercentage = 1.0 / 0.6 * 100
    }
    return ({
        imgScale: newImgScale,
        imgScalePercentage: parseInt(newImgScalePercentage)
    })
}

const zoomOutPdf = (pdfScalePercentage) => {
    var newPdfScalePercentage, newPdfScale
    if (pdfScalePercentage <= 33 && pdfScalePercentage > 25) {
        newPdfScalePercentage = 25
        newPdfScale = 1.5 * 0.25
    } else if (pdfScalePercentage <= 50 && pdfScalePercentage > 33) {
        newPdfScalePercentage = 33
        newPdfScale = 1.5 * 0.33
    } else if (pdfScalePercentage <= 67 && pdfScalePercentage > 50) {
        newPdfScalePercentage = 50
        newPdfScale = 1.5 * 0.5
    } else if (pdfScalePercentage <= 75 && pdfScalePercentage > 67) {
        newPdfScalePercentage = 67
        newPdfScale = 1.5 * 0.67
    } else if (pdfScalePercentage <= 80 && pdfScalePercentage > 75) {
        newPdfScalePercentage = 75
        newPdfScale = 1.5 * 0.75
    } else if (pdfScalePercentage <= 90 && pdfScalePercentage > 80) {
        newPdfScalePercentage = 80
        newPdfScale = 1.5 * 0.8
    } else if (pdfScalePercentage <= 100 && pdfScalePercentage > 90) {
        newPdfScalePercentage = 90
        newPdfScale = 1.5 * 0.9
    } else if (pdfScalePercentage <= 110 && pdfScalePercentage > 100) {
        newPdfScalePercentage = 100
        newPdfScale = 1.5
    } else if (pdfScalePercentage <= 125 && pdfScalePercentage > 110) {
        newPdfScalePercentage = 110
        newPdfScale = 1.5 * 1.1
    } else if (pdfScalePercentage <= 150 && pdfScalePercentage > 125) {
        newPdfScalePercentage = 125
        newPdfScale = 1.5 * 1.25
    } else if (pdfScalePercentage <= 175 && pdfScalePercentage > 150) {
        newPdfScalePercentage = 150
        newPdfScale = 1.5 * 1.5
    } else if (pdfScalePercentage <= 200 && pdfScalePercentage > 175) {
        newPdfScalePercentage = 175
        newPdfScale = 1.5 * 1.75
    } else {
        newPdfScalePercentage = pdfScalePercentage - 50
        newPdfScale = 1.5 * (pdfScalePercentage - 50) / 100
    }

    if (newPdfScalePercentage < 25) {
        newPdfScale = 1.5 * 0.25
        newPdfScalePercentage = 25
    }

    return ({
        pdfScale: newPdfScale,
        pdfScalePercentage: newPdfScalePercentage
    })
}

const zoomOutImg = (imgScale, imgScalePercentage) => {
    var newImgScalePercentage, newImgScale
    if (imgScalePercentage <= 33 && imgScalePercentage > 25) {
        newImgScalePercentage = 25
        newImgScale = 0.6 * 0.25
    } else if (imgScalePercentage <= 50 && imgScalePercentage > 33) {
        newImgScalePercentage = 33
        newImgScale = 0.6 * 0.33
    } else if (imgScalePercentage <= 67 && imgScalePercentage > 50) {
        newImgScalePercentage = 50
        newImgScale = 0.6 * 0.5
    } else if (imgScalePercentage <= 75 && imgScalePercentage > 67) {
        newImgScalePercentage = 67
        newImgScale = 0.6 * 0.67
    } else if (imgScalePercentage <= 80 && imgScalePercentage > 75) {
        newImgScalePercentage = 75
        newImgScale = 0.6 * 0.75
    } else if (imgScalePercentage <= 90 && imgScalePercentage > 80) {
        newImgScalePercentage = 80
        newImgScale = 0.6 * 0.8
    } else if (imgScalePercentage <= 100 && imgScalePercentage > 90) {
        newImgScalePercentage = 90
        newImgScale = 0.6 * 0.9
    } else if (imgScalePercentage <= 110 && imgScalePercentage > 100) {
        newImgScalePercentage = 100
        newImgScale = 0.6
    } else if (imgScalePercentage <= 125 && imgScalePercentage > 110) {
        newImgScalePercentage = 110
        newImgScale = 0.6 * 1.1
    } else if (imgScalePercentage <= 150 && imgScalePercentage > 125) {
        newImgScalePercentage = 125
        newImgScale = 0.6 * 1.25
    } else if (imgScalePercentage <= 175 && imgScalePercentage > 150) {
        newImgScalePercentage = 150
        newImgScale = 0.6 * 1.5
    } else if (imgScalePercentage <= 200 && imgScalePercentage > 175) {
        newImgScalePercentage = 175
        newImgScale = 0.6 * 1.75
    }

    if (newImgScalePercentage < 25) {
        newImgScale = 0.6 * 0.25
        newImgScalePercentage = 25
    }

    return ({
        imgScale: newImgScale,
        imgScalePercentage: newImgScalePercentage
    })
}

const mapDocDataForPreview = (docData, docType) => {
    const moment = require("moment")

    if (['COSTS', 'EXPENSES_REPORT', 'MAG'].includes(docType)) {
        var docInstance = docData?.doc || {}
        var fileInstance = docInstance.file || {}
        var waproData = JSON.parse(docInstance.wapro_data || "{}")
        var symfoniaData = JSON.parse(docInstance.symfonia_data || "{}")
        var dataToReturn = {
            docId: docInstance.id,
            counterpartyId: docInstance.sender?.id || -1,
            counterpartyName: docInstance.sender?.name || '',
            counterpartyVatId: docInstance.sender?.vat_id || '',
            counterpartyAddress: docInstance.sender?.address || '',
            counterpartyCountry: docInstance.seller_country_code || '',
            buyer: docInstance.receiver?.name || '',
            buyerVatId: docInstance.receiver?.vat_id || '',
            price: docInstance.price ? parseFloat(docInstance.price).toFixed(2) : '',
            brutto: docInstance.brutto_number ? parseFloat(docInstance.brutto_number).toFixed(2) : '',
            netto: docInstance.netto_number ? parseFloat(docInstance.netto_number).toFixed(2) : '',
            tax: docInstance.tax_number ? parseFloat(docInstance.tax_number).toFixed(2) : '',
            currency: docInstance.currency,
            paymentType: docInstance.payment_type,
            paymentMethod: docInstance.payment_method,
            dueDate: docInstance.due_date_ts ? moment(docInstance.due_date_ts).format('YYYY-MM-DD') : '',
            issueDate: moment(docInstance.date).format('YYYY-MM-DD'),
            saleDate: docInstance.sale_date_ts ? moment(docInstance.sale_date_ts).format('YYYY-MM-DD') : '',
            accountNumber: docInstance.bic,
            swift: docInstance.seller_swift,
            invoiceNumber: docInstance.doc_number,
            doc_description: docInstance.doc_description,
            comment: docInstance.comment,
            tags: docInstance.tags || '',
            priority: fileInstance.priority,
            uploadedBy: docData.uploadedBy,
            uploadedById: fileInstance.uploaded_by_number || -1,
            uploadDate: fileInstance.created || docInstance.created || null,
            verifiedBy: docData.verifiedBy,
            verificationDate: fileInstance.verification_date || null,
            acceptedBy: docData.acceptedBy,
            acceptanceDate: fileInstance.acceptance_date || docInstance.created || null,
            acceptedById: docData.acceptedById,
            paidBy: docData.userThatMarkedPayment,
            paymentDate: fileInstance.payment_date,
            additionalAcceptances: fileInstance.additionalAcceptance || [],
            requiresAdditionalAcceptance: fileInstance.requires_additional_acceptance,
            additionalAcceptancesRequired: fileInstance.additional_acceptances_required - fileInstance.additional_acceptances_received,
            userCanPay: docData.userCanPay,
            deleted: docInstance.deleted,
            deletedBy: docData.deletedBy,
            deletedDate: fileInstance.deleted_date || null,
            accounted: docInstance.accounted || false,
            fileInstance: fileInstance,
            docflowId: fileInstance.docflow_id || null,
            docflow: fileInstance.docflow || null,
            currentDocflowStep: fileInstance.currentDocflowStep || null,

            waproData: waproData,
            symfoniaData: symfoniaData,

            wl_project_code: docInstance.wl_project_code || "",
            wl_technology: docInstance.wl_technology || "",
            wl_devex_type_1: docInstance.wl_devex_type_1 || "",
            wl_devex_type_2: docInstance.wl_devex_type_2 || "",
            wl_transaction_description_pl: docInstance.wl_transaction_description_pl || "",
            wl_transaction_description_en: docInstance.wl_transaction_description_en || "",
        }

        return dataToReturn
    } else if (docType === 'SALE_INVOICE') {
        var docInstance = docData?.doc || {}
        var fileInstance = docInstance.file || {}
        var waproData = JSON.parse(docInstance.wapro_data || "{}")
        var symfoniaData = JSON.parse(docInstance.symfonia_data || "{}")
        var dataToReturn = {
            docId: docInstance.id,
            companyName: docInstance.sender?.name || '',
            companyVatId: docInstance.sender?.vat_id || '',
            counterpartyName: docInstance.receiver?.name || '',
            counterpartyVatId: docInstance.receiver?.vat_id || '',
            price: parseFloat(docInstance.total_gross_value || 0).toFixed(2),
            brutto: parseFloat(docInstance.total_gross_value || 0).toFixed(2),
            netto: parseFloat(docInstance.total_net_value || 0).toFixed(2),
            tax: parseFloat(docInstance.total_vat_value || 0).toFixed(2),
            currency: docInstance.currency,
            paymentType: docInstance.split_payment ? 'split' : docInstance.reverse_charge ? 'reverse' : 'regular',
            paymentMethod: docInstance.payment_method,
            dueDate: docInstance.due_date ? moment(docInstance.due_date).format('YYYY-MM-DD') : '',
            issueDate: moment(docInstance.issue_date).format('YYYY-MM-DD'),
            saleDate: docInstance.sale_date ? moment(docInstance.sale_date).format('YYYY-MM-DD') : '',
            accountNumber: docInstance.account_number,
            swift: docInstance.seller_swift,
            invoiceNumber: docInstance.doc_number,
            comment: '',
            tags: docInstance.tags || '',
            comment: docInstance.comments,
            doc_description: docInstance.doc_description,
            priority: fileInstance.priority,
            uploadedBy: docData.uploadedBy,
            uploadedById: fileInstance.uploaded_by_number || -1,
            uploadDate: fileInstance.created || docInstance.created || null,
            verifiedBy: docData.verifiedBy,
            verificationDate: fileInstance.verification_date || null,
            acceptedBy: docData.acceptedBy,
            acceptanceDate: fileInstance.acceptance_date || docInstance.created || null,
            acceptedById: docData.acceptedById,
            paidBy: docData.userThatMarkedPayment,
            paymentDate: fileInstance.payment_date,
            deleted: docInstance.deleted,
            deletedBy: docData.deletedBy,
            deletedDate: fileInstance.deleted_date || null,
            accounted: docInstance.accounted || false,
            fileInstance: fileInstance,
            docflowId: fileInstance.docflow_id || null,
            docflow: fileInstance.docflow || null,
            currentDocflowStep: fileInstance.currentDocflowStep || null,
            additionalAcceptances: fileInstance.additionalAcceptance || [],
            requiresAdditionalAcceptance: fileInstance.requires_additional_acceptance,
            additionalAcceptancesRequired: fileInstance.additional_acceptances_required - fileInstance.additional_acceptances_received,

            waproData: waproData,
            symfoniaData: symfoniaData,

            wl_project_code: docInstance.wl_project_code || "",
            wl_technology: docInstance.wl_technology || "",
            wl_devex_type_1: docInstance.wl_devex_type_1 || "",
            wl_devex_type_2: docInstance.wl_devex_type_2 || "",
            wl_transaction_description_pl: docInstance.wl_transaction_description_pl || "",
            wl_transaction_description_en: docInstance.wl_transaction_description_en || "",
        }

        return dataToReturn
    } else if (docType === 'EXPENSES') {
        var expenses = docData?.doc || []
        var fileInstance = docData.file || {}
        var docs = expenses.map(d => {
            return {
                id: d.id,
                doc_number: d.doc_number || d.docNumber,
                issue_date: d.issue_date || d.issueDate,
                comment: d.comment,
                doc_description: d.doc_description,
                price: d.doc_number ? d.price.slice(0, -2) : d.price,
                currency: d.currency || '',
                tags: d.tags || ''
            }
        })
        var dataToReturn = {
            docs: docs,
            priority: fileInstance.priority,
            uploadedBy: docData.uploadedBy,
            uploadedById: fileInstance.uploaded_by_number || -1,
            uploadDate: fileInstance.created || null,
            verifiedBy: docData.verifiedBy,
            verificationDate: fileInstance.verification_date || null,
            acceptedBy: docData.acceptedBy,
            acceptanceDate: fileInstance.acceptance_date || null,
            acceptedById: docData.acceptedById,
            paidBy: docData.userThatMarkedPayment,
            paymentDate: fileInstance.payment_date,
            deleted: fileInstance.deleted,
            deletedBy: docData.deletedBy,
            deletedDate: fileInstance.deleted_date || null,
            fileInstance: fileInstance,
            docflowId: fileInstance.docflow_id || null,
            docflow: fileInstance.docflow || null,
            currentDocflowStep: fileInstance.currentDocflowStep || null,
            additionalAcceptances: fileInstance.additionalAcceptance || [],
            requiresAdditionalAcceptance: fileInstance.requires_additional_acceptance,
            additionalAcceptancesRequired: fileInstance.additional_acceptances_required - fileInstance.additional_acceptances_received,
        }

        return dataToReturn
    } else if (docType === 'AGREEMENT') {
        var docInstance = docData?.agreement || {}
        var fileInstance = docInstance.file || {}
        var dataToReturn = {
            docId: docInstance.id,
            counterpartyId: docInstance.counterparty?.id || -1,
            counterpartyName: docInstance.counterparty?.name || '',
            counterpartyVatId: docInstance.counterparty?.vat_id || '',
            issueDate: moment(docInstance.agreement_date).format('YYYY-MM-DD'),
            number: docInstance.number,
            description: docInstance.description,
            tags: docInstance.tags || '',
            doc_description: docInstance.doc_description,
            uploadedBy: docData.uploadedBy,
            uploadedById: fileInstance.uploaded_by_number || -1,
            uploadDate: fileInstance.created || docInstance.created || null,
            verifiedBy: docData.verifiedBy,
            verificationDate: fileInstance.verification_date || null,
            acceptedBy: docData.acceptedBy,
            acceptanceDate: fileInstance.acceptance_date || docInstance.created || null,
            acceptedById: docData.acceptedById,
            deleted: docInstance.deleted,
            deletedBy: docData.deletedBy,
            deletedDate: fileInstance.deleted_date || null,
            fileInstance: fileInstance,
            docflowId: fileInstance.docflow_id || null,
            docflow: fileInstance.docflow || null,
            currentDocflowStep: fileInstance.currentDocflowStep || null,
            additionalAcceptances: fileInstance.additionalAcceptance || [],
            requiresAdditionalAcceptance: fileInstance.requires_additional_acceptance,
            additionalAcceptancesRequired: fileInstance.additional_acceptances_required - fileInstance.additional_acceptances_received,
        }

        return dataToReturn
    } else if (docType === 'CORRESPONDENCE') {
        var docInstance = docData?.correspondence || {}
        var fileInstance = docInstance.file || {}
        var dataToReturn = {
            docId: docInstance.id,
            counterpartyId: docInstance.counterparty?.id || -1,
            counterpartyName: docInstance.counterparty?.name || '',
            counterpartyVatId: docInstance.counterparty?.vat_id || '',
            issueDate: moment(docInstance.date).format('YYYY-MM-DD'),
            description: docInstance.description,
            tags: docInstance.tags || '',
            doc_description: docInstance.doc_description,
            uploadedBy: docData.uploadedBy,
            uploadedById: fileInstance.uploaded_by_number || -1,
            uploadDate: fileInstance.created || docInstance.created || null,
            verifiedBy: docData.verifiedBy,
            verificationDate: fileInstance.verification_date || null,
            acceptedBy: docData.acceptedBy,
            acceptanceDate: fileInstance.acceptance_date || docInstance.created || null,
            acceptedById: docData.acceptedById,
            deleted: docInstance.deleted,
            deletedBy: docData.deletedBy,
            deletedDate: fileInstance.deleted_date || null,
            fileInstance: fileInstance,
            docflowId: fileInstance.docflow_id || null,
            docflow: fileInstance.docflow || null,
            currentDocflowStep: fileInstance.currentDocflowStep || null,
            additionalAcceptances: fileInstance.additionalAcceptance || [],
            requiresAdditionalAcceptance: fileInstance.requires_additional_acceptance,
            additionalAcceptancesRequired: fileInstance.additional_acceptances_required - fileInstance.additional_acceptances_received,
        }

        return dataToReturn
    } else if (docType === 'CORPORATE') {
        var docInstance = docData?.corporate || {}
        var fileInstance = docInstance.file || {}
        var dataToReturn = {
            docId: docInstance.id,
            issueDate: moment(docInstance.date).format('YYYY-MM-DD'),
            description: docInstance.description,
            tags: docInstance.tags || '',
            doc_description: docInstance.doc_description,
            uploadedBy: docData.uploadedBy,
            uploadedById: fileInstance.uploaded_by_number || -1,
            uploadDate: fileInstance.created || docInstance.created || null,
            verifiedBy: docData.verifiedBy,
            verificationDate: fileInstance.verification_date || null,
            acceptedBy: docData.acceptedBy,
            acceptanceDate: fileInstance.acceptance_date || docInstance.created || null,
            acceptedById: docData.acceptedById,
            deleted: docInstance.deleted,
            deletedBy: docData.deletedBy,
            deletedDate: fileInstance.deleted_date || null,
            fileInstance: fileInstance,
            docflowId: fileInstance.docflow_id || null,
            docflow: fileInstance.docflow || null,
            currentDocflowStep: fileInstance.currentDocflowStep || null,
            additionalAcceptances: fileInstance.additionalAcceptance || [],
            requiresAdditionalAcceptance: fileInstance.requires_additional_acceptance,
            additionalAcceptancesRequired: fileInstance.additional_acceptances_required - fileInstance.additional_acceptances_received,
        }

        return dataToReturn
    } else if (docType === 'OTHER') {
        var docInstance = docData?.other_doc || {}
        var fileInstance = docInstance.file || {}
        var dataToReturn = {
            docId: docInstance.id,
            counterpartyId: docInstance.counterparty?.id || -1,
            counterpartyName: docInstance.counterparty?.name || '',
            counterpartyVatId: docInstance.counterparty?.vat_id || '',
            issueDate: moment(docInstance.date).format('YYYY-MM-DD'),
            description: docInstance.description,
            tags: docInstance.tags || '',
            doc_description: docInstance.doc_description,
            uploadedBy: docData.uploadedBy,
            uploadedById: fileInstance.uploaded_by_number || -1,
            uploadDate: fileInstance.created || docInstance.created || null,
            verifiedBy: docData.verifiedBy,
            verificationDate: fileInstance.verification_date || null,
            acceptedBy: docData.acceptedBy,
            acceptanceDate: fileInstance.acceptance_date || docInstance.created || null,
            acceptedById: docData.acceptedById,
            deleted: docInstance.deleted,
            deletedBy: docData.deletedBy,
            deletedDate: fileInstance.deleted_date || null,
            fileInstance: fileInstance,
            docflowId: fileInstance.docflow_id || null,
            docflow: fileInstance.docflow || null,
            currentDocflowStep: fileInstance.currentDocflowStep || null,
            additionalAcceptances: fileInstance.additionalAcceptance || [],
            requiresAdditionalAcceptance: fileInstance.requires_additional_acceptance,
            additionalAcceptancesRequired: fileInstance.additional_acceptances_required - fileInstance.additional_acceptances_received,
        }

        return dataToReturn
    } else if (docType === 'PROTOCOL') {
        var docInstance = docData?.protocol_doc || {}
        var fileInstance = docInstance.file || {}
        var dataToReturn = {
            docId: docInstance.id,
            employee: docInstance.employee || '',
            date: moment(docInstance.date).format('YYYY-MM-DD'),
            description: docInstance.description,
            tags: docInstance.tags || '',
            doc_description: docInstance.doc_description,
            uploadedBy: docData.uploadedBy,
            uploadedById: fileInstance.uploaded_by_number || -1,
            uploadDate: fileInstance.created || docInstance.created || null,
            verifiedBy: docData.verifiedBy,
            verificationDate: fileInstance.verification_date || null,
            acceptedBy: docData.acceptedBy,
            acceptanceDate: fileInstance.acceptance_date || docInstance.created || null,
            acceptedById: docData.acceptedById,
            deleted: docInstance.deleted,
            deletedBy: docData.deletedBy,
            deletedDate: fileInstance.deleted_date || null,
            fileInstance: fileInstance,
            docflowId: fileInstance.docflow_id || null,
            docflow: fileInstance.docflow || null,
            currentDocflowStep: fileInstance.currentDocflowStep || null,
            additionalAcceptances: fileInstance.additionalAcceptance || [],
            requiresAdditionalAcceptance: fileInstance.requires_additional_acceptance,
            additionalAcceptancesRequired: fileInstance.additional_acceptances_required - fileInstance.additional_acceptances_received,
        }

        return dataToReturn
    } if (docType === 'NOT_ACCAPTED') {
        var fileInstance = docData?.file || {}
        var dataToReturn = {
            uploadedById: fileInstance.uploaded_by_number || -1,
            uploadDate: fileInstance.created || null,
            verificationDate: fileInstance.verification_date || null,
            acceptanceDate: fileInstance.acceptance_date || null,
            deleted: fileInstance.deleted,
            deletedDate: fileInstance.deleted_date || null,
            fileInstance: fileInstance,
            docflowId: fileInstance.docflow_id || null
        }

        return dataToReturn
    } else {
        return {}
    }
}

module.exports = {
    zoomInPdf,
    zoomInImg,
    zoomOutPdf,
    zoomOutImg,
    mapDocDataForPreview
};