import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { Dots } from 'react-activity'
import 'react-activity/dist/react-activity.css'
import TextareaAutosize from 'react-textarea-autosize'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import axios from "axios"

import Calendar from '../Calendar';
import Calculator from '../Calculator';

import CountriesList from '../CountriesList';

import TagsComponent from './TagsComponent';
import CostSplit from './CostSplit';
import MpkSplit from './MpkSplit/MpkSplit';
import OcrPageWaproMagDocTypes from './wapro/OcrPageWaproMagDocTypes'
import OcrPageWaproFakirDocTypes from './wapro/OcrPageWaproFakirDocTypes'
import OcrPageWaproFakirDocDictionary from './wapro/OcrPageWaproFakirDocDictionary'
import OcrPageWaproNettoAccounts from './wapro/OcrPageWaproNettoAccounts'
import OcrPageWaproDocDescription from './wapro/OcrPageWaproDocDescription'
import OcrPageWaproVatRate from './wapro/OcrPageWaproVatRate'

import OcrPageSymfoniaDocTypes from './symfonia/OcrPageSymfoniaDocTypes'
import OcrPageSymfoniaDocDescription from './symfonia/OcrPageSymfoniaDocDescription'
import OcrPageSymfoniaGrossAccount from './symfonia/OcrPageSymfoniaGrossAccount'
import OcrPageSymfoniaNetAccount from './symfonia/OcrPageSymfoniaNetAccount'
import OcrPageSymfoniaVatAccount from './symfonia/OcrPageSymfoniaVatAccount'
import OcrPageSymfoniaRegisters from './symfonia/OcrPageSymfoniaRegisters'

import ProjectCode from './WL_DevCosts/ProjectCode'
import Technology from './WL_DevCosts/Technology'
import DevExCode from './WL_DevCosts/DevExCode'
import DevExType from './WL_DevCosts/DevExType'
import TransactionDescription from './WL_DevCosts/TransactionDescription'

import OcrPageLinkDocsPopup from './OcrPageLinkDocsPopup'
import DocflowInfoModal from '../DocflowComponents/DocflowInfoModal'

import '../../css/OcrPage.css'

import parseDate from '../../helpers/dateParser'
import { getCurrencyRateFromNBP } from '../../helpers/getCurrencyRateFromNBP'
import isIterable from "../../helpers/isIterable"
import currencyList from '../../helpers/currencyList.json'
import paymentMethodsList from '../../helpers/paymentMethodsList.json'
import waproUsers from '../../helpers/waproUsers.json'
import { devCostsProjects } from '../../helpers/DevCosts/devCostsProjects'
import { gfProjects } from '../../helpers/CostSplit/gfProjects'
import allProjectsWithMpk from '../../helpers/MpkSplit/allProjectsWithMpk'
import { devCostsUsers } from '../../helpers/DevCosts/devCostsUsers'
import { vatIdValidate } from '../../helpers/vatIdValidate';
import { checkIfAccountNumberIsValid } from '../../helpers/validAccountNumber';

import { CompanyActions } from '../../actions/CompanyActions';
import { FileActions } from '../../actions/FileActions';
import { AlertActions } from '../../actions/AlertActions';

const datePattern = new RegExp("^[0-9]{2}-[0-9]{2}-[0-9]{4}$", "i");
const datePatternOneDayDigit = new RegExp("^[0-9]{1}-[0-9]{2}-[0-9]{4}$", "i");
const datePatternOneMonthDigit = new RegExp("^[0-9]{2}-[0-9]{1}-[0-9]{4}$", "i");
const datePatternReverse = new RegExp("^[0-9]{4}-[0-9]{2}-[0-9]{2}$", "i");

export class OcrPageCostsForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            symfoniaDocTypes: [],
            symfoniaAccounts: [],
            accountWhiteListStatus: '',
            checketVatId: '',
            checkedAccountNumber: '',
            checkedAmount: 0,
            sentRequetst: false,

            docsList: [],
            agreementsList: [],
            linkedFiles: {
                agreementLink: {
                    linked: false,
                    file: {}
                },
                correctionLink: {
                    linked: false,
                    file: {}
                },
                proformaLink: {
                    linked: false,
                    file: {}
                }
            },

            fieldsWithErrors: {},
            fieldsWithWarnings: {}
        }

        this.docTypeWrapperRef = React.createRef()
        this.docflowWrapperRef = React.createRef()
        this.countryCodeWrapperRef = React.createRef()
        this.sellerNameWrapperRef = React.createRef()
        this.issueDateWrapperRef = React.createRef()
        this.saleDateWrapperRef = React.createRef()
        this.dueDateWrapperRef = React.createRef()
        this.currencyDateWrapperRef = React.createRef()
        this.netCalculatorWrapperRef = React.createRef()
        this.grossCalculatorWrapperRef = React.createRef()
        this.amountCalculatorWrapperRef = React.createRef()
    }

    componentDidMount() {
        const { currentProject, currentInvoiceImageData } = this.props

        var curentProjectDocflows = currentProject?.projectDocflows || []
        this.setState({
            availableDocflows: curentProjectDocflows
        })

        var defaultDocflow = null, possibleDocflowsCount = 0
        curentProjectDocflows.forEach(docflow => {
            if (docflow.doc_types?.includes(currentInvoiceImageData.docType) && !docflow.archived) {
                possibleDocflowsCount++
                if (!defaultDocflow) defaultDocflow = docflow
            }
        })

        if (possibleDocflowsCount === 1) this.selectDocflow(defaultDocflow)

        document.addEventListener("mousedown", this.handleClick);
    }


    componentDidUpdate(prevProps) {
        const { returnedOCRData, ocrMethod, processingData } = this.props
        const { sentRequetst } = this.state

        const { currentProject } = this.props
        if (prevProps.currentProject?.id !== currentProject?.id) {
            this.setState({
                availableDocflows: currentProject?.projectDocflows || []
            })
        }

        if (prevProps.returnedOCRData?.file !== returnedOCRData?.file) {
            this.checkFieldsForErrorsAndWarnings()
        }

        if (prevProps.processingData?.length !== processingData?.length) {
            this.checkFieldsForErrorsAndWarnings()
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
    }

    handleClick = (e) => {
        const { showDocumentTypeList, showDocflowsList, showCountriesList, showCounterpartiesList, showIssueDateCalendar, showSaleDateCalendar, showDueDateCalendar, showCurrencyDateCalendar, showNetCalculator, showGrossCalculator, showAmountCalculator } = this.state
        if (this.docTypeWrapperRef.current && !this.docTypeWrapperRef.current.contains(e.target) && showDocumentTypeList) {
            this.toggleQuestionList('DOC_TYPE')
        }
        if (this.docflowWrapperRef.current && !this.docflowWrapperRef.current.contains(e.target) && showDocflowsList) {
            this.toggleQuestionList('DOCFLOW')
        }
        if (showCountriesList && this.countryCodeWrapperRef && this.countryCodeWrapperRef.current && !this.countryCodeWrapperRef.current.contains(e.target)) {
            this.setState({
                showCountriesList: false
            })
        }
        if (showCounterpartiesList && this.sellerNameWrapperRef && this.sellerNameWrapperRef.current && !this.sellerNameWrapperRef.current.contains(e.target)) {
            this.setState({
                showCounterpartiesList: false
            })
        }
        if (showIssueDateCalendar && this.issueDateWrapperRef && this.issueDateWrapperRef.current && !this.issueDateWrapperRef.current.contains(e.target)) {
            this.setState({
                showIssueDateCalendar: false,
            })
        }
        if (showSaleDateCalendar && this.saleDateWrapperRef && this.saleDateWrapperRef.current && !this.saleDateWrapperRef.current.contains(e.target)) {
            this.setState({
                showSaleDateCalendar: false,
            })
        }
        if (showDueDateCalendar && this.dueDateWrapperRef && this.dueDateWrapperRef.current && !this.dueDateWrapperRef.current.contains(e.target)) {
            this.setState({
                showDueDateCalendar: false,
            })
        }
        if (showCurrencyDateCalendar && this.currencyDateWrapperRef && this.currencyDateWrapperRef.current && !this.currencyDateWrapperRef.current.contains(e.target)) {
            this.setState({
                showCurrencyDateCalendar: false
            })
        }
        if (showNetCalculator && this.netCalculatorWrapperRef && this.netCalculatorWrapperRef.current && !this.netCalculatorWrapperRef.current.contains(e.target)) {
            this.setState({
                showNetCalculator: false
            })
        }
        if (showGrossCalculator && this.grossCalculatorWrapperRef && this.grossCalculatorWrapperRef.current && !this.grossCalculatorWrapperRef.current.contains(e.target)) {
            this.setState({
                showGrossCalculator: false
            })
        }
        if (showAmountCalculator && this.amountCalculatorWrapperRef && this.amountCalculatorWrapperRef.current && !this.amountCalculatorWrapperRef.current.contains(e.target)) {
            this.setState({
                showAmountCalculator: false
            })
        }
    }

    toCapitalCase = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    checkDate = (date) => {
        if (date && parseDate(date).match(new RegExp("[0-9]{2}-[0-9]{2}-[0-9]{4}", "i"))) {
            return true
        } else {
            return false
        }
    }

    checkIfDateIsValid = (dateText) => {
        if (dateText) {
            dateText = dateText.replace(/\,/g, '-').replace(/\./g, '-').replace(/\//g, '-').replace(/[^0-9\-]/gm, '')
            dateText = dateText.trim();
            if (datePattern.test(dateText)) return moment(dateText, 'DD-MM-YYYY').isValid()
            if (datePatternOneDayDigit.test(dateText)) return moment(dateText, 'D-MM-YYYY').isValid()
            if (datePatternOneMonthDigit.test(dateText)) return moment(dateText, 'DD-M-YYYY').isValid()
            if (datePatternReverse.test(dateText)) return moment(dateText, 'YYYY-MM-DD').isValid()
            else return false
        } else return true
    }

    testDateYYYYMMDD = date => {
        const matchFirstForDigit = /^\d{4}/g
        return matchFirstForDigit.test(date)
    }

    checkIfVatIdIsValid = (vatId) => {
        if (vatId) {
            var countryCode = ''
            const firstTwoLetter = /^[A-Z]{2}/
            if (firstTwoLetter.test(vatId) && !vatId.startsWith('PL')) {
                countryCode = vatId.slice(0, 2)
            }
            const { returnedOCRData, ocrMethod, currency } = this.props

            if (currency !== 'PLN' && returnedOCRData && returnedOCRData.incomeProps) {
                var sellerCountry = sellerCountry = returnedOCRData?.incomeProps?.find(e => e.name === 'countryCode')
                if (sellerCountry && sellerCountry.text) {
                    countryCode = sellerCountry.text
                }
                if (firstTwoLetter.test(vatId) && vatId.substring(0, 2) !== countryCode) return false
                if (!firstTwoLetter.test(vatId) && countryCode === 'DE') vatId = countryCode + vatId
            }

            return vatIdValidate(vatId)
        } else return true
    }

    checkIfAmountToPayIsValid = (amountToPay, otherValues) => {
        if (amountToPay) {
            if (/\.[0-9]{2}/.test(amountToPay) || /\,[0-9]{2}/.test(amountToPay)) {
                var bruttoValue = otherValues.find(e => e.name === 'brutto')
                if (bruttoValue && bruttoValue.text) {
                    if (/\.[0-9]{2}/.test(bruttoValue.text) || /\,[0-9]{2}/.test(bruttoValue.text)) {
                        bruttoValue.text = bruttoValue.text.replace(',', '.')
                        if (parseFloat(amountToPay) > parseFloat(bruttoValue.text)) {
                            return false
                        } else {
                            return true
                        }
                    } else return true
                } else return true
            } else {
                return false
            }
        } else return true
    }

    invalidAmountToPayMessage = (amountToPay, otherValues) => {
        if (amountToPay) {
            var bruttoValue = otherValues.find(e => e.name === 'brutto')
            if (bruttoValue && bruttoValue.text) {
                bruttoValue.text = bruttoValue.text.replace(',', '.')
                if (parseFloat(amountToPay) > parseFloat(bruttoValue.text)) {
                    return '*Amount to pay is greater than the gross amount, please make sure it is correct'
                } else {
                    return ''
                }
            } else return ''
        } else return ''
    }

    checkIfBruttoIsValid = (brutto, otherValues) => {
        if (brutto) {
            if (!/\.[0-9]{2}/.test(brutto) && !/\,[0-9]{2}/.test(brutto)) {
                return false
            } else if (this.props.currency === 'PLN') {
                var nettoValue = otherValues.find(e => e.name === 'netto')
                if (nettoValue && nettoValue.text) {
                    if (/\.[0-9]{2}/.test(nettoValue.text) || /\,[0-9]{2}/.test(nettoValue.text)) {
                        nettoValue.text = nettoValue.text.replace(',', '.')
                        console.log(nettoValue.text)
                        if (parseFloat(brutto) > parseFloat(nettoValue.text) * 1.23 + 0.1) {
                            return false
                        } else {
                            return true
                        }
                    } else return true
                } else return true
            } else return true
        } else return true
    }

    invalidBruttoMessage = (brutto, otherValues) => {
        if (brutto) {
            if (this.props.currency === 'PLN') {
                var nettoValue = otherValues.find(e => e.name === 'netto')
                if (nettoValue && nettoValue.text) {
                    nettoValue.text = nettoValue.text.replace(',', '.')
                    if (parseFloat(brutto) > parseFloat(nettoValue.text) * 1.23 + 0.1) {
                        return '*The gross amount is greater than the net amount increased by 23% VAT, make sure it is correct'
                    } else {
                        return ''
                    }
                } else return ''
            } else return ''
        } else return ''
    }

    polihAccountPritier = account => {
        if (account) {
            var formatedAccount = account.replace(/[^0-9]/gm, '')

            if (formatedAccount.length === 26) {
                formatedAccount = formatedAccount.slice(0, 2) + ' ' + formatedAccount.slice(2, 6) + ' ' + formatedAccount.slice(6, 10) + ' ' + formatedAccount.slice(10, 14) + ' ' + formatedAccount.slice(14, 18) + ' ' + formatedAccount.slice(18, 22) + ' ' + formatedAccount.slice(22, 26)
                if (account.startsWith('PL')) {
                    return 'PL' + formatedAccount
                } else {
                    return formatedAccount
                }
            } else {
                return account
            }
        } else return ''
    }

    foundAtLeastOneSimilarCounterparty = (text) => {
        const { counterpartiesList } = this.props
        var counterpartiesCount = counterpartiesList.length

        if (!text) {
            return false
        }

        for (var i = 0; i < counterpartiesCount; i++) {
            if (text.length === 1 && counterpartiesList[i].name && counterpartiesList[i].name.toLowerCase().startsWith(text.toLowerCase())) {
                return true
            } else if (text.length > 1 && counterpartiesList[i].name && counterpartiesList[i].name.toLowerCase().includes(text.toLowerCase())) {
                return true
            }
        }
        return false
    }

    checkIfCounterpartyNameIsSimilar = (text, name) => {
        if (!text) {
            return false
        }

        if (text.length === 1 && name && name.toLowerCase().startsWith(text.toLowerCase())) {
            return true

        } else if (text.length > 1 && name && name.toLowerCase().includes(text.toLowerCase())) {
            return true
        }

        return false
    }

    selectCounterpart = c => {
        const { currentInvoiceImageData } = this.props
        this.props.changeOCRData(currentInvoiceImageData.dbId, 'buyerName', c.name, '', 'SALE_INVOICE')
        this.props.changeOCRData(currentInvoiceImageData.dbId, 'buyerVatId', c.vat_id, '', 'SALE_INVOICE')
        this.props.changeOCRData(currentInvoiceImageData.dbId, 'buyerCode', c.code, '', 'SALE_INVOICE')

        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'buyerName', c.name, 'income')
        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'buyerVatId', c.vat_id, 'income')
        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'buyerCode', c.code, 'income')
        this.toggleCounterpartiesList()
        this.checkFieldsForErrorsAndWarnings()
    }

    toggleQuestionList = (listType) => {
        this.setState({
            showDocumentTypeList: listType === 'DOC_TYPE' ? !this.state.showDocumentTypeList : this.state.showDocumentTypeList,
            showCurrencyList: listType === 'CURRENCY' ? !this.state.showCurrencyList : this.state.showCurrencyList,
            showPaymentMethodList: listType === 'PAYMENT_METHOD' ? !this.state.showPaymentMethodList : this.state.showPaymentMethodList,
            showPaymentTypeList: listType === 'PAYMENT_TYPE' ? !this.state.showPaymentTypeList : this.state.showPaymentTypeList,
            showPriority: listType === 'PRIORITY' ? !this.state.showPriority : this.state.showPriority
        })

        if (listType === 'DOCFLOW') {
            const { currentProject, currentInvoiceImageData, user } = this.props
            if (
                currentProject?.is_dropzone_manager ||
                currentInvoiceImageData.uploaded_by === user.id ||
                currentInvoiceImageData.userThatUploadedId === user.id ||
                (currentInvoiceImageData.userThatUploadedId === -1 && currentInvoiceImageData.userThatUploaded === user.email)
            ) {
                this.setState({
                    showDocflowsList: !this.state.showDocflowsList
                })
            } else {
                this.props.alertWarn(this.context.t('You do not have permission to change docflow'))
            }
        }
    }

    selectDocType = (type) => {
        this.props.selectDocType(type)

        this.setState({
            showDocumentTypeList: false
        })
    }

    getDocTypeLabel = docType => {
        switch (docType) {
            case 'COSTS':
                return this.context.t('Cost')
            case 'EXPENSES_REPORT':
                return this.context.t('Cost')
            case 'MAG':
                return this.context.t('Warehouse doc')
            case 'EXPENSES':
                return this.context.t('Expense')
            case 'AGREEMENT':
                return this.context.t('Agreement')
            case 'SALE_INVOICE':
                return this.context.t('Income doc')
            case 'CORPORATE':
                return this.context.t('Corporate')
            case 'CORRESPONDENCE':
                return this.context.t('Correspondence')
            case 'OTHER':
                return this.context.t('Other')
            case 'PROTOCOL':
                return this.context.t('Protocol')
            default:
                return this.context.t('Other')
        }
    }

    selectDocflow = docflow => {
        const { currentInvoiceImageData } = this.props
        if (docflow.doc_types?.includes(currentInvoiceImageData.docType)) {
            var firstStep = docflow.docflowSteps[0]
            this.props.selectDocflow(docflow, firstStep)
            this.setState({
                showDocflowsList: false
            })
        }
        this.checkFieldsForErrorsAndWarnings()
    }

    selectCurrency = (currency) => {
        this.props.selectCurrency(currency)

        this.setState({
            showCurrencyList: false
        })
        this.checkFieldsForErrorsAndWarnings()
    }

    selectPaymentMethod = (paymentMethod) => {
        this.props.selectPaymentMethod(paymentMethod)

        this.setState({
            showPaymentMethodList: false
        })
        this.checkFieldsForErrorsAndWarnings()
    }

    selectPaymentType = (paymentType) => {
        this.props.selectPaymentType(paymentType)

        this.setState({
            showPaymentTypeList: false
        })
        this.checkFieldsForErrorsAndWarnings()
    }

    selectPriority = (priority) => {
        this.props.selectPriority(priority)

        this.setState({
            showPriority: false
        })
    }

    labelClick = (e, propName) => {
        const { returnedOCRData } = this.props
        var prop = returnedOCRData?.incomeProps?.find(e => e.name === propName)

        this.props.scrollToArea(e, propName, prop?.possition)

        if (propName.includes('Date')) {
            this.toggleCalendar(propName)
        } else if (['netto', 'brutto', 'price'].includes(propName)) {
            this.toggleCalculator(propName)
        }
    }

    onInputChange = (e, propName) => {
        const { currentInvoiceImageData } = this.props
        this.props.onInputChange(e, currentInvoiceImageData.dbId, propName)
    }

    onInputClick = (e, propName) => {
        const { returnedOCRData } = this.props
        var prop = returnedOCRData?.incomeProps?.find(e => e.name === propName)

        this.props.scrollToArea(e, propName, prop?.possition)

        if (propName.includes('Date')) {
            this.toggleCalendar(propName)
        } else if (['netto', 'brutto', 'price'].includes(propName)) {
            this.toggleCalculator(propName)
        } else if (propName === 'countryCode') {
            this.toggleCountriesList()
        } else if (propName === 'buyerName') {
            this.toggleCounterpartiesList()
        }
    }

    onInputFocus = (e, propName) => {
    }

    onInputBlur = (e, propName) => {
        const { returnedOCRData, currentInvoiceImageData } = this.props
        var prop = returnedOCRData?.incomeProps?.find(e => e.name === propName)

        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, propName, prop?.text, 'income')

        this.checkFieldsForErrorsAndWarnings()
    }

    toggleCalendar = (propName) => {
        const { showDueDateCalendar, showIssueDateCalendar, showSaleDateCalendar, showCurrencyDateCalendar } = this.state
        this.setState({
            showIssueDateCalendar: propName === 'issueDate' ? !showIssueDateCalendar : false,
            showSaleDateCalendar: propName === 'saleDate' ? !showSaleDateCalendar : false,
            showDueDateCalendar: propName === 'dueDate' ? !showDueDateCalendar : false,
            showCurrencyDateCalendar: propName === 'currencyRate' ? !showDueDateCalendar : false
        })
    }

    onCalendarSelect = async (date) => {
        const { showDueDateCalendar, showIssueDateCalendar, showSaleDateCalendar, showCurrencyDateCalendar } = this.state
        const { currentInvoiceImageData, currency } = this.props

        if (date) {
            if (showDueDateCalendar) {
                this.props.changeOCRData(currentInvoiceImageData.dbId, 'dueDate', date.format('DD-MM-YYYY'), '', 'SALE_INVOICE')
                this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'dueDate', date.format('DD-MM-YYYY'), 'income')
            } else if (showIssueDateCalendar) {
                this.props.changeOCRData(currentInvoiceImageData.dbId, 'issueDate', date.format('DD-MM-YYYY'), '', 'SALE_INVOICE')
                this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'issueDate', date.format('DD-MM-YYYY'), 'income')
            } else if (showSaleDateCalendar) {
                this.props.changeOCRData(currentInvoiceImageData.dbId, 'saleDate', date.format('DD-MM-YYYY'), '', 'SALE_INVOICE')
                this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'saleDate', date.format('DD-MM-YYYY'), 'income')
            } else if (showCurrencyDateCalendar) {
                const currencyRate = await getCurrencyRateFromNBP(currency, moment(date, this.testDateYYYYMMDD(date) ? "YYYY-MM-DD" : "DD-MM-YYYY").subtract(1, 'days').format('YYYY-MM-DD'))
                if (currencyRate) {
                    const { rate, effectiveDate, tableNumber } = currencyRate
                    this.setState({
                        currencyRateDate: effectiveDate
                    })
                    this.props.changeOCRData(currentInvoiceImageData.dbId, 'currencyRate', rate.toString(), '', 'SALE_INVOICE')
                    this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'currencyRate', rate.toString(), 'income')
                }
            }
        }

        this.setState({
            showIssueDateCalendar: false,
            showSaleDateCalendar: false,
            showDueDateCalendar: false,
            showCurrencyDateCalendar: false
        })
        this.checkFieldsForErrorsAndWarnings()
    }

    onCalendarUnselecet = () => {
        this.setState({
            showIssueDateCalendar: false,
            showSaleDateCalendar: false,
            showDueDateCalendar: false,
            showCurrencyDateCalendar: false
        })
        this.checkFieldsForErrorsAndWarnings()
    }

    toggleCalculator = (propName) => {
        const { showNetCalculator, showGrossCalculator, showAmountCalculator } = this.state
        this.setState({
            showNetCalculator: propName === 'netto' ? !showNetCalculator : false,
            showGrossCalculator: propName === 'brutto' ? !showGrossCalculator : false,
            showAmountCalculator: propName === 'price' ? !showAmountCalculator : false
        })
    }

    returnCalculatorValue = (value) => {
        const { showNetCalculator, showGrossCalculator, showAmountCalculator } = this.state
        const { currentInvoiceImageData } = this.props
        if (showNetCalculator) {
            this.props.changeOCRData(currentInvoiceImageData.dbId, 'netto', value, '', 'SALE_INVOICE')
            this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'netto', value, 'income')
        } else if (showGrossCalculator) {
            this.props.changeOCRData(currentInvoiceImageData.dbId, 'brutto', value, '', 'SALE_INVOICE')
            this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'brutto', value, 'income')
        } else if (showAmountCalculator) {
            this.props.changeOCRData(currentInvoiceImageData.dbId, 'price', value, '', 'SALE_INVOICE')
            this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'price', value, 'income')
        }

        this.setState({
            showNetCalculator: false,
            showGrossCalculator: false,
            showAmountCalculator: false
        })

        this.checkFieldsForErrorsAndWarnings()
    }

    acceptAmountToPay = () => {
        this.props.acceptAmountToPay()
        setTimeout(() => {
            this.checkFieldsForErrorsAndWarnings()
        }, 50)
    }

    toggleCountriesList = () => {
        this.setState({
            showCountriesList: !this.state.showCountriesList
        })
    }

    setSellerCountryCode = (value) => {
        const { currentInvoiceImageData, ocrMethod } = this.props
        this.props.changeOCRData(currentInvoiceImageData.dbId, 'countryCode', value, '', 'SALE_INVOICE')
        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'countryCode', value, 'income')
    }

    toggleCounterpartiesList = () => {
        this.setState({
            showCounterpartiesList: !this.state.showCounterpartiesList
        })
    }

    checkFieldsForErrorsAndWarnings = () => {
        const { accountWhiteListStatus, dataConfirmCheckbox } = this.state
        const {
            currentInvoiceImageData, returnedOCRData,
            paymentMethod, acceptedAmounttoPay, currency
        } = this.props

        if (currentInvoiceImageData?.currentDocflowStep?.step_type === 'MPK' ||
            currentInvoiceImageData?.currentDocflowStep?.step_type === 'DEVEX'
        ) {
            return true
        }

        var errors = {}, warnings = {}
        if (!currentInvoiceImageData?.docflow?.id) {
            errors.docflow = this.context.t('Docflow is required')
        }

        if (returnedOCRData && returnedOCRData.incomeProps) {
            returnedOCRData.incomeProps.forEach(prop => {
                if (prop.name === 'sellerVatId') {
                    if (!prop.text) {
                        errors.sellerVatId = this.context.t('Seller VatID is required')
                    }
                } else if (prop.name === 'sellerName') {
                    if (!prop.text) {
                        errors.sellerName = this.context.t('Seller name is required')
                    }
                } else if (prop.name === 'invoiceNumber') {
                    if (!prop.text) {
                        errors.invoiceNumber = this.context.t('Document number is required')
                    }
                } else if (prop.name === 'brutto') {
                    if (!prop.text) {
                        errors.brutto = this.context.t('Gross value is required')
                    } else if (!this.checkIfBruttoIsValid(prop.text, returnedOCRData.incomeProps)) {
                        warnings.brutto = this.context.t(this.invalidBruttoMessage(prop.text, returnedOCRData.incomeProps))
                    }
                } else if (prop.name === 'netto') {
                    if (!prop.text) {
                        errors.netto = this.context.t('Net value is required')
                    }
                } else if (prop.name === 'accountNumber') {
                    if (paymentMethod === 'not-paid' && !prop.text) {
                        errors.accountNumber = this.context.t('Account number is required for unpaid documents')
                    }
                } else if (prop.name === 'issueDate') {
                    if (!prop.text) {
                        errors.issueDate = this.context.t('Issue date is required')
                    } else if (!this.checkIfDateIsValid(prop.text)) {
                        errors.issueDate = this.context.t('The format of issue date couldn\'t be recognized. Please make sure that it is dd-mm-yyyy or yyyy-mm-dd.')
                    }
                } else if (prop.name === 'saleDate') {
                    if (!prop.text) {
                        errors.saleDate = this.context.t('Sale date is required')
                    } else if (!this.checkIfDateIsValid(prop.text)) {
                        errors.saleDate = this.context.t('The format of sale date couldn\'t be recognized. Please make sure that it is dd-mm-yyyy or yyyy-mm-dd.')
                    }
                } else if (prop.name === 'dueDate') {
                    if (paymentMethod !== 'not-paid') {
                        delete errors.dueDate
                    } else if (!prop.text) {
                        errors.dueDate = this.context.t('Due date is required')
                    } else if (!this.checkIfDateIsValid(prop.text)) {
                        errors.dueDate = this.context.t('The format of due date couldn\'t be recognized. Please make sure that it is dd-mm-yyyy or yyyy-mm-dd.')
                    }
                }
            })
        }

        this.setState({
            fieldsWithErrors: errors,
            fieldsWithWarnings: warnings
        })
        var errorsCount = 0
        Object.keys(errors).forEach(key => {
            if (errors[key]) errorsCount++
        })
        this.props.setWaningsAndErrorsVisibility(warnings, errors)

        return errorsCount === 0
    }

    saveData = () => {
        const { dataConfirmCheckbox } = this.state
        if (!dataConfirmCheckbox) {
            this.props.alertWarn(this.context.t('You have to confirm the compliance of the data in the form with the data on the original document'))
        } else if (this.checkFieldsForErrorsAndWarnings()) {
            const {
                fieldsWithWarnings, fieldsWithErrors,
                linkedFiles
            } = this.state

            var errorsCount = 0
            Object.keys(fieldsWithErrors).forEach(key => {
                if (errorsCount[key]) errorsCount++
            })

            if (errorsCount === 0) {
                this.props.saveData(linkedFiles)
            } else {
                this.props.alertWarn(this.context.t('Please correct the errors before saving the document'))
            }
        } else {
            this.props.alertWarn(this.context.t('Please correct the errors before saving the document'))
        }
    }

    render() {
        const {
            availableDocflows, docflowModalIsOpen, docflowToOpen,
            dataConfirmCheckbox, showLinkDocsPopup, docsList, agreementsList, counterpartyVatId, linkedFiles, foundDocsFromCounterparty, gotAllDocsRespons,
            showDocumentTypeList, showDocflowsList, showCurrencyList, showPaymentMethodList,
            showCounterpartiesList,
            showDueDateCalendar, showIssueDateCalendar, showSaleDateCalendar,
            showNetCalculator, showGrossCalculator,
            fieldsWithWarnings, fieldsWithErrors,
        } = this.state
        const {
            user,
            highlightWarnings, highlightErrors,
            currentInvoiceImageData, currentProjectRole,
            paymentMethod, currency,
            comment, documentDescription, tags,
            returnedOCRData, processingData, dataReadWithOcrFrame,
            tagsList, tagsCreationLimitation,
            counterpartiesList
        } = this.props

        return (
            <>
                <div className={`invoice-content ${highlightWarnings ? 'highlight-warnings' : ''} ${highlightErrors ? 'highlight-errors' : ''}`} ref="sideInvoiceBar">
                    {
                        docflowModalIsOpen ? (
                            <DocflowInfoModal
                                docflow={docflowToOpen}
                                close={() => this.setState({ docflowModalIsOpen: false, docflowToOpen: null })}
                            />
                        ) : null
                    }
                    {
                        showLinkDocsPopup ? (
                            <OcrPageLinkDocsPopup
                                linkedFiles={linkedFiles}
                                docsList={docsList}
                                agreementsList={agreementsList}
                                counterpartyVatId={counterpartyVatId}
                                foundDocsFromCounterparty={gotAllDocsRespons ? foundDocsFromCounterparty : true}
                                cancel={this.hideLinkedFilesPopup}
                                save={this.saveLinkedFiles}
                            />
                        ) : null
                    }

                    {
                        Object.keys(fieldsWithWarnings).map((keyName, keyIndex) => {
                            return (
                                <ReactTooltip id={`${keyName}-warning-tooltip`} place="bottom" effect="solid" className="default-tooltip amount-to-pay">
                                    {fieldsWithWarnings[keyName]}
                                </ReactTooltip>
                            )
                        })
                    }

                    {
                        Object.keys(fieldsWithErrors).map((keyName, keyIndex) => {
                            return (
                                <ReactTooltip id={`${keyName}-error-tooltip`} place="bottom" effect="solid" className="default-tooltip amount-to-pay">
                                    {fieldsWithErrors[keyName]}
                                </ReactTooltip>
                            )
                        })
                    }

                    <SimpleBar style={{ maxHeight: '100%', marginRight: '-12px', paddingRight: '12px' }} autoHide={false}>
                        <div id="doc-type-question" className="choice-short" ref={this.docTypeWrapperRef}>
                            <div className="question">
                                {this.context.t('Document type ')}
                            </div>
                            <div className={`choosen-option doc-types ${showDocumentTypeList ? 'extended' : ''}`} onClick={() => this.toggleQuestionList('DOC_TYPE')}>
                                {
                                    this.getDocTypeLabel(currentInvoiceImageData.docType)
                                }

                                {
                                    showDocumentTypeList ? (
                                        <ul>
                                            <li onClick={e => this.selectDocType('COSTS')}>
                                                {this.context.t('Cost')}
                                                <span className="type-description">{this.context.t('Invoice or bill for the company')}</span>
                                            </li>
                                            <li onClick={e => this.selectDocType('EXPENSES')}>
                                                {this.context.t('Expense')}
                                                <span className="type-description">{this.context.t('Receipt or invoice for the employee')}</span>
                                            </li>
                                            <li onClick={e => this.selectDocType('SALE_INVOICE')}>
                                                {this.context.t('Income doc')}
                                                <span className="type-description">{this.context.t('Your sales invoice')}</span>
                                            </li>
                                            <li onClick={e => this.selectDocType('AGREEMENT')}>
                                                {this.context.t('Agreement')}
                                                <span className="type-description">{this.context.t('Your contract with the supplier/recipient')}</span>
                                            </li>
                                            <li onClick={e => this.selectDocType('CORRESPONDENCE')}>
                                                {this.context.t('Correspondence')}
                                                <span className="type-description">{this.context.t('letter or business email')}</span>
                                            </li>
                                            <li onClick={e => this.selectDocType('CORPORATE')}>
                                                {this.context.t('Corporate')}
                                                <span className="type-description">{this.context.t('official document')}</span>
                                            </li>
                                            <li onClick={e => this.selectDocType('MAG')}>
                                                {this.context.t('Warehouse doc')}
                                            </li>
                                            <li onClick={e => this.selectDocType('OTHER')}>
                                                {this.context.t('Other')}
                                            </li>
                                            <li onClick={e => this.selectDocType('PROTOCOL')}>
                                                {this.context.t('Protocol')}
                                            </li>
                                        </ul>
                                    ) : (
                                        null
                                    )
                                }
                            </div>
                        </div>

                        <div id="docflow-question" className={`choice-short ${fieldsWithErrors.docflow ? 'error' : ''}`} ref={this.docflowWrapperRef}>
                            <div className="question">
                                {this.context.t('Docflow')}
                                {
                                    fieldsWithErrors.docflow ? (
                                        <>
                                            <div className="error-icon" data-for={`docflow-error-tooltip`} data-tip='show'></div>
                                        </>
                                    ) : null
                                }
                            </div>
                            <div className={`choosen-option docflow ${showDocflowsList ? 'extended' : ''} ${!currentInvoiceImageData?.docflow?.name ? 'placeholder' : ''}`} onClick={() => this.toggleQuestionList('DOCFLOW')}>
                                {currentInvoiceImageData?.docflow?.name || this.context.t('Select docflow')}

                                {
                                    showDocflowsList && availableDocflows?.length ? (
                                        <ul>
                                            {
                                                availableDocflows.map(docflow => {
                                                    if (docflow.doc_types?.includes(currentInvoiceImageData.docType) && !docflow.archived)
                                                        return (
                                                            <li>
                                                                <div className="text" onClick={e => this.selectDocflow(docflow)}>{docflow.name}</div>
                                                                <div className="info" onClick={() => this.setState({ docflowModalIsOpen: true, docflowToOpen: docflow })}></div>
                                                            </li>
                                                        )
                                                    else return null
                                                })
                                            }
                                        </ul>
                                    ) : (
                                        null
                                    )
                                }
                            </div>
                        </div>

                        <div className="form-section">
                            <h4>{this.context.t('Seller')}</h4>

                            <div className={`form-group ${dataReadWithOcrFrame.includes('sellerVatId') ? 'finished-ocr-indication' : ''} ${fieldsWithErrors.sellerVatId ? 'error' : ''} ${fieldsWithWarnings.sellerVatId ? 'warning' : ''}`} ref={this.sellerVatIdWrapperRef}>
                                <TextareaAutosize
                                    type="text"
                                    name='sellerVatId'
                                    id="sellerVatId"
                                    value={returnedOCRData?.incomeProps?.find(p => p.name === 'sellerVatId')?.text || ''}
                                    maxRows={10}
                                    onChange={(e) => this.onInputChange(e, 'sellerVatId')}
                                    onClick={(e) => this.onInputClick(e, 'sellerVatId')}
                                    onBlur={e => this.onInputBlur(e, 'sellerVatId')}
                                    onFocus={e => this.onInputFocus(e, 'sellerVatId')}
                                />
                                <label className='sellerVatId' onClick={(e) => this.labelClick(e, 'sellerVatId')} >
                                    {this.context.t('Seller VatID')}
                                    {
                                        processingData && processingData.find(e => e === 'sellerVatId') ? (
                                            <span className="loading"><Dots size={9} speed={0.8} color={'#69B792'} /></span>
                                        ) : null
                                    }
                                </label>
                                {
                                    fieldsWithWarnings.sellerVatId ? (
                                        <>
                                            <div className="warning-icon" data-for={`sellerVatId-warning-tooltip`} data-tip='show'></div>
                                        </>
                                    ) : null
                                }
                                {
                                    fieldsWithErrors.sellerVatId ? (
                                        <>
                                            <div className="error-icon" data-for={`sellerVatId-error-tooltip`} data-tip='show'></div>
                                        </>
                                    ) : null
                                }
                            </div>
                            <div className={`form-group ${dataReadWithOcrFrame.includes('sellerName') ? 'finished-ocr-indication' : ''} ${fieldsWithErrors.sellerName ? 'error' : ''} ${fieldsWithWarnings.sellerName ? 'warning' : ''}`}>
                                <TextareaAutosize
                                    type="text"
                                    name='sellerName'
                                    id="sellerName"
                                    value={returnedOCRData?.incomeProps?.find(p => p.name === 'sellerName')?.text || ''}
                                    maxRows={10}
                                    onChange={(e) => this.onInputChange(e, 'sellerName')}
                                    onClick={(e) => this.onInputClick(e, 'sellerName')}
                                    onBlur={e => this.onInputBlur(e, 'sellerName')}
                                    onFocus={e => this.onInputFocus(e, 'sellerName')}
                                />
                                <label className='sellerName' onClick={(e) => this.labelClick(e, 'sellerName')} >
                                    {this.context.t('Seller Name')}
                                    {
                                        processingData && processingData.find(e => e === 'sellerName') ? (
                                            <span className="loading"><Dots size={9} speed={0.8} color={'#69B792'} /></span>
                                        ) : null
                                    }
                                </label>
                                {
                                    fieldsWithWarnings.sellerName ? (
                                        <>
                                            <div className="warning-icon with-other-icon" data-for={`sellerName-warning-tooltip`} data-tip='show'></div>
                                        </>
                                    ) : null
                                }
                                {
                                    fieldsWithErrors.sellerName ? (
                                        <>
                                            <div className="error-icon with-other-icon" data-for={`sellerName-error-tooltip`} data-tip='show'></div>
                                        </>
                                    ) : null
                                }
                            </div>

                        </div>

                        <div className="form-section">
                            <h4>{this.context.t('Buyer')}</h4>
                            <div className={`form-group ${dataReadWithOcrFrame.includes('buyerVatId') ? 'finished-ocr-indication' : ''}`}>
                                <TextareaAutosize
                                    type="text"
                                    name='buyerVatId'
                                    id="buyerVatId"
                                    value={returnedOCRData?.incomeProps?.find(p => p.name === 'buyerVatId')?.text || ''}
                                    maxRows={10}
                                    onChange={(e) => this.onInputChange(e, 'buyerVatId')}
                                    onClick={(e) => this.onInputClick(e, 'buyerVatId')}
                                    onBlur={e => this.onInputBlur(e, 'buyerVatId')}
                                    onFocus={e => this.onInputFocus(e, 'buyerVatId')}
                                />
                                <label className='buyerVatId' onClick={(e) => this.labelClick(e, 'buyerVatId')} >
                                    {this.context.t('Buyer VatID')}
                                    {
                                        processingData && processingData.find(e => e === 'buyerVatId') ? (
                                            <span className="loading"><Dots size={9} speed={0.8} color={'#69B792'} /></span>
                                        ) : null
                                    }
                                </label>
                            </div>

                            <div className={`form-group ${dataReadWithOcrFrame.includes('buyerName') ? 'finished-ocr-indication' : ''}`} ref={this.sellerNameWrapperRef}>
                                <TextareaAutosize
                                    type="text"
                                    name='buyerName'
                                    id="buyerName"
                                    value={returnedOCRData?.incomeProps?.find(p => p.name === 'buyerName')?.text || ''}
                                    maxRows={10}
                                    onChange={(e) => this.onInputChange(e, 'buyerName')}
                                    onClick={(e) => this.onInputClick(e, 'buyerName')}
                                    onBlur={e => this.onInputBlur(e, 'buyerName')}
                                    onFocus={e => this.onInputFocus(e, 'buyerName')}
                                />
                                <label className='buyerName' onClick={(e) => this.labelClick(e, 'buyerName')} >
                                    {this.context.t('Buyer Name')}
                                    {
                                        processingData && processingData.find(e => e === 'buyerName') ? (
                                            <span className="loading"><Dots size={9} speed={0.8} color={'#69B792'} /></span>
                                        ) : null
                                    }
                                </label>
                                {
                                    counterpartiesList.length > 0 &&
                                        returnedOCRData?.incomeProps?.find(p => p.name === 'buyerName')?.text &&
                                        this.foundAtLeastOneSimilarCounterparty(returnedOCRData?.incomeProps?.find(p => p.name === 'buyerName')?.text) &&
                                        showCounterpartiesList ?
                                        (
                                            <div className="counterparties-list">
                                                <div className="header">
                                                    {this.context.t('Your counterparties with similar name:')}
                                                </div>
                                                <SimpleBar style={{ height: 'auto', maxHeight: '300px', width: '100%' }}>
                                                    <ul>
                                                        {
                                                            counterpartiesList.map(c => {
                                                                if (this.checkIfCounterpartyNameIsSimilar(returnedOCRData?.incomeProps?.find(p => p.name === 'buyerName')?.text, c.name)) {
                                                                    return (
                                                                        <li tabIndex={"0"} className={`item counterparty-item`} onClick={() => this.selectCounterpart(c)}>{c.name}</li>
                                                                    )
                                                                } else {
                                                                    return null
                                                                }
                                                            })
                                                        }
                                                    </ul>
                                                </SimpleBar>
                                            </div>
                                        ) : (
                                            null
                                        )
                                }
                                {
                                    counterpartiesList.length > 0 &&
                                        returnedOCRData?.incomeProps?.find(p => p.name === 'buyerName')?.text &&
                                        this.foundAtLeastOneSimilarCounterparty(returnedOCRData?.incomeProps?.find(p => p.name === 'buyerName')?.text) ?
                                        (
                                            <div className={`extend-icon ${showCounterpartiesList ? 'extended' : ''}`} onClick={() => this.toggleCounterpartiesList()}></div>
                                        ) : null
                                }
                            </div>
                        </div>

                        <div className="form-section">
                            <h4>{this.context.t('Document')}</h4>
                            <div className={`form-group ${dataReadWithOcrFrame.includes('invoiceNumber') ? 'finished-ocr-indication' : ''} ${fieldsWithErrors.invoiceNumber ? 'error' : ''} ${fieldsWithWarnings.invoiceNumber ? 'warning' : ''}`} ref={this.invoiceNumberWrapperRef}>
                                <TextareaAutosize
                                    type="text"
                                    name='invoiceNumber'
                                    id="invoiceNumber"
                                    value={returnedOCRData?.incomeProps?.find(p => p.name === 'invoiceNumber')?.text || ''}
                                    maxRows={10}
                                    onChange={(e) => this.onInputChange(e, 'invoiceNumber')}
                                    onClick={(e) => this.onInputClick(e, 'invoiceNumber')}
                                    onBlur={e => this.onInputBlur(e, 'invoiceNumber')}
                                    onFocus={e => this.onInputFocus(e, 'invoiceNumber')}
                                />
                                <label className='invoiceNumber' onClick={(e) => this.labelClick(e, 'invoiceNumber')} >
                                    {this.context.t('Document Number')}
                                    {
                                        processingData && processingData.find(e => e === 'invoiceNumber') ? (
                                            <span className="loading"><Dots size={9} speed={0.8} color={'#69B792'} /></span>
                                        ) : null
                                    }
                                </label>

                                {
                                    fieldsWithWarnings.invoiceNumber ? (
                                        <>
                                            <div className="warning-icon" data-for={`invoiceNumber-warning-tooltip`} data-tip='show'></div>
                                        </>
                                    ) : null
                                }
                                {
                                    fieldsWithErrors.invoiceNumber ? (
                                        <>
                                            <div className="error-icon" data-for={`invoiceNumber-error-tooltip`} data-tip='show'></div>
                                        </>
                                    ) : null
                                }
                            </div>

                            <div className={`form-group half ${dataReadWithOcrFrame.includes('issueDate') ? 'finished-ocr-indication' : ''} ${showIssueDateCalendar ? 'front' : ''} ${fieldsWithErrors.issueDate ? 'error' : ''}`} ref={this.issueDateWrapperRef}>
                                <TextareaAutosize
                                    type="text"
                                    name='issueDate'
                                    id="issueDate"
                                    value={returnedOCRData?.incomeProps?.find(p => p.name === 'issueDate')?.text || ''}
                                    maxRows={10}
                                    onChange={(e) => this.onInputChange(e, 'issueDate')}
                                    onClick={(e) => this.onInputClick(e, 'issueDate')}
                                    onBlur={e => this.onInputBlur(e, 'issueDate')}
                                    onFocus={e => this.onInputFocus(e, 'issueDate')}
                                />
                                <label className='issueDate' onClick={(e) => this.labelClick(e, 'issueDate')} >
                                    {this.context.t('Issue Date')}
                                    {
                                        processingData && processingData.find(e => e === 'issueDate') ? (
                                            <span className="loading"><Dots size={9} speed={0.8} color={'#69B792'} /></span>
                                        ) : null
                                    }
                                </label>
                                <div className="calendar-icon" onClick={() => this.toggleCalendar('issueDate')}></div>
                                {
                                    showIssueDateCalendar ? (
                                        <div className={`date-picker`}>
                                            <Calendar
                                                selectDay={this.onCalendarSelect}
                                                unselectDay={this.onCalendarUnselecet}
                                                selectedDay={
                                                    this.checkDate(returnedOCRData?.incomeProps?.find(p => p.name === 'issueDate')?.text || '') ? {
                                                        display: moment(parseDate(returnedOCRData?.incomeProps?.find(p => p.name === 'issueDate')?.text || ''), 'DD-MM-YYYY').format('LL'),
                                                        value: moment(returnedOCRData?.incomeProps?.find(p => p.name === 'issueDate')?.text || '', 'DD-MM-YYYY')
                                                    } : {
                                                        display: null,
                                                        value: null
                                                    }
                                                }
                                                projectId={'none'}
                                                startDate={
                                                    this.checkDate(returnedOCRData?.incomeProps?.find(p => p.name === 'issueDate')?.text || '') ?
                                                        moment(parseDate(returnedOCRData?.incomeProps?.find(p => p.name === 'issueDate')?.text || ''), 'DD-MM-YYYY')
                                                        :
                                                        moment()}
                                            />
                                        </div>
                                    ) : null
                                }

                                {
                                    fieldsWithWarnings.issueDate ? (
                                        <>
                                            <div className="warning-icon with-other-icon" data-for={`issueDate-warning-tooltip`} data-tip='show'></div>
                                        </>
                                    ) : null
                                }
                                {
                                    fieldsWithErrors.issueDate ? (
                                        <>
                                            <div className="error-icon with-other-icon" data-for={`issueDate-error-tooltip`} data-tip='show'></div>
                                        </>
                                    ) : null
                                }
                            </div>

                            <div className={`form-group half ${dataReadWithOcrFrame.includes('saleDate') ? 'finished-ocr-indication' : ''} ${showSaleDateCalendar ? 'front' : ''} ${fieldsWithErrors.saleDate ? 'error' : ''}`} ref={this.saleDateWrapperRef}>
                                <TextareaAutosize
                                    type="text"
                                    name='saleDate'
                                    id="saleDate"
                                    value={returnedOCRData?.incomeProps?.find(p => p.name === 'saleDate')?.text || ''}
                                    maxRows={10}
                                    onChange={(e) => this.onInputChange(e, 'saleDate')}
                                    onClick={(e) => this.onInputClick(e, 'saleDate')}
                                    onBlur={e => this.onInputBlur(e, 'saleDate')}
                                    onFocus={e => this.onInputFocus(e, 'saleDate')}
                                />
                                <label className='saleDate' onClick={(e) => this.labelClick(e, 'saleDate')} >
                                    {this.context.t('Sale Date')}
                                    {
                                        processingData && processingData.find(e => e === 'saleDate') ? (
                                            <span className="loading"><Dots size={9} speed={0.8} color={'#69B792'} /></span>
                                        ) : null
                                    }
                                </label>
                                <div className="calendar-icon" onClick={() => this.toggleCalendar('saleDate')}></div>
                                {
                                    showSaleDateCalendar ? (
                                        <div className={`date-picker sale-date`}>
                                            <Calendar
                                                selectDay={this.onCalendarSelect}
                                                unselectDay={this.onCalendarUnselecet}
                                                selectedDay={
                                                    this.checkDate(returnedOCRData?.incomeProps?.find(p => p.name === 'saleDate')?.text || '') ? {
                                                        display: moment(parseDate(returnedOCRData?.incomeProps?.find(p => p.name === 'saleDate')?.text || ''), 'DD-MM-YYYY').format('LL'),
                                                        value: moment(returnedOCRData?.incomeProps?.find(p => p.name === 'saleDate')?.text || '', 'DD-MM-YYYY')
                                                    } : {
                                                        display: null,
                                                        value: null
                                                    }
                                                }
                                                projectId={'none'}
                                                startDate={
                                                    this.checkDate(returnedOCRData?.incomeProps?.find(p => p.name === 'saleDate')?.text || '') ?
                                                        moment(parseDate(returnedOCRData?.incomeProps?.find(p => p.name === 'saleDate')?.text || ''), 'DD-MM-YYYY')
                                                        :
                                                        moment()}
                                            />
                                        </div>
                                    ) : null
                                }

                                {
                                    fieldsWithWarnings.saleDate ? (
                                        <>
                                            <div className="warning-icon with-other-icon" data-for={`saleDate-warning-tooltip`} data-tip='show'></div>
                                        </>
                                    ) : null
                                }
                                {
                                    fieldsWithErrors.saleDate ? (
                                        <>
                                            <div className="error-icon with-other-icon" data-for={`saleDate-error-tooltip`} data-tip='show'></div>

                                            <ReactTooltip id={`saleDate-error-tooltip`} place="bottom" effect="solid" className="default-tooltip amount-to-pay">
                                                {fieldsWithErrors.saleDate}
                                            </ReactTooltip>
                                        </>
                                    ) : null
                                }
                            </div>

                            <div className={`form-group half ${dataReadWithOcrFrame.includes('netto') ? 'finished-ocr-indication' : ''} ${showNetCalculator ? 'front' : ''}`} ref={this.netCalculatorWrapperRef}>
                                <TextareaAutosize
                                    type="text"
                                    name='netto'
                                    id="netto"
                                    value={returnedOCRData?.incomeProps?.find(p => p.name === 'netto')?.text || ''}
                                    maxRows={10}
                                    onChange={(e) => this.onInputChange(e, 'netto')}
                                    onClick={(e) => this.onInputClick(e, 'netto')}
                                    onBlur={e => this.onInputBlur(e, 'netto')}
                                    onFocus={e => this.onInputFocus(e, 'netto')}
                                />
                                <label className='netto' onClick={(e) => this.labelClick(e, 'netto')} >
                                    {this.context.t('Net')}
                                    {
                                        processingData && processingData.find(e => e === 'netto') ? (
                                            <span className="loading"><Dots size={9} speed={0.8} color={'#69B792'} /></span>
                                        ) : null
                                    }
                                </label>
                                <div className="calculator-icon" onClick={() => this.toggleCalculator('netto')}></div>
                                {
                                    showNetCalculator ? (
                                        <Calculator
                                            returnValue={this.returnCalculatorValue}
                                        />
                                    ) : null
                                }
                            </div>

                            <div className={`form-group half ${dataReadWithOcrFrame.includes('brutto') ? 'finished-ocr-indication' : ''} ${showGrossCalculator ? 'front' : ''} ${fieldsWithWarnings.brutto ? 'warning' : ''}`} ref={this.grossCalculatorWrapperRef}>
                                <TextareaAutosize
                                    type="text"
                                    name='brutto'
                                    id="brutto"
                                    value={returnedOCRData?.incomeProps?.find(p => p.name === 'brutto')?.text || ''}
                                    maxRows={10}
                                    onChange={(e) => this.onInputChange(e, 'brutto')}
                                    onClick={(e) => this.onInputClick(e, 'brutto')}
                                    onBlur={e => this.onInputBlur(e, 'brutto')}
                                    onFocus={e => this.onInputFocus(e, 'brutto')}
                                />
                                <label className='brutto' onClick={(e) => this.labelClick(e, 'brutto')} >
                                    {this.context.t('Gross')}
                                    {
                                        processingData && processingData.find(e => e === 'brutto') ? (
                                            <span className="loading"><Dots size={9} speed={0.8} color={'#69B792'} /></span>
                                        ) : null
                                    }
                                </label>
                                <div className="calculator-icon" onClick={() => this.toggleCalculator('brutto')}></div>
                                {
                                    showGrossCalculator ? (
                                        <Calculator
                                            returnValue={this.returnCalculatorValue}
                                        />
                                    ) : null
                                }

                                {
                                    fieldsWithWarnings.brutto ? (
                                        <>
                                            <div className="warning-icon with-other-icon" data-for={`brutto-warning-tooltip`} data-tip='show'></div>
                                        </>
                                    ) : null
                                }
                                {
                                    fieldsWithErrors.brutto ? (
                                        <>
                                            <div className="error-icon with-other-icon" data-for={`brutto-error-tooltip`} data-tip='show'></div>

                                            <ReactTooltip id={`brutto-error-tooltip`} place="bottom" effect="solid" className="default-tooltip amount-to-pay">
                                                {fieldsWithErrors.brutto}
                                            </ReactTooltip>
                                        </>
                                    ) : null
                                }
                            </div>
                        </div>

                        <div className="form-section">
                            <h4>{this.context.t('Payment')}</h4>
                            <div id="currency-question" className="choice-short">
                                <div className="question">
                                    {this.context.t('Currency')}
                                </div>
                                <div className={`choosen-option ${showCurrencyList ? 'extended' : ''}`} onClick={() => this.toggleQuestionList('CURRENCY')}>
                                    {currency}
                                    {
                                        showCurrencyList ? (
                                            <ul>
                                                {
                                                    currencyList.map(c => {
                                                        return (
                                                            <li className={`${currency === c ? 'selected' : ''}`} onClick={e => this.selectCurrency(c)}>{c}</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        ) : (
                                            null
                                        )
                                    }
                                </div>
                            </div>
                            <div id="payment-method-question" className="choice-short">
                                <div className="question">
                                    {this.context.t('Payment status')}
                                </div>
                                <div className={`choosen-option payment-method ${showPaymentMethodList ? 'extended' : ''}`} onClick={() => this.toggleQuestionList('PAYMENT_METHOD')}>
                                    {paymentMethod === 'not-paid' ? this.context.t('Not paid') : this.context.t('Paid - ') + this.context.t(this.toCapitalCase(paymentMethod))}

                                    {
                                        showPaymentMethodList ? (
                                            <ul>
                                                <li onClick={e => this.selectPaymentMethod('not-paid')}>
                                                    {this.context.t('Not paid')}
                                                </li>
                                                {
                                                    paymentMethodsList.map(method => {
                                                        return (
                                                            <li onClick={e => this.selectPaymentMethod(method.value)}>
                                                                {this.context.t(`Paid - ${method.title}`)}
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        ) : (
                                            null
                                        )
                                    }
                                </div>
                            </div>
                            {
                                paymentMethod === 'not-paid' ? (
                                    <div className={`form-group ${dataReadWithOcrFrame.includes('dueDate') ? 'finished-ocr-indication' : ''} ${showDueDateCalendar ? 'front' : ''} ${fieldsWithErrors.dueDate ? 'error' : ''}`} ref={this.dueDateWrapperRef}>
                                        <TextareaAutosize
                                            type="text"
                                            name='dueDate'
                                            id="dueDate"
                                            value={returnedOCRData?.incomeProps?.find(p => p.name === 'dueDate')?.text || ''}
                                            maxRows={10}
                                            onChange={(e) => this.onInputChange(e, 'dueDate')}
                                            onClick={(e) => this.onInputClick(e, 'dueDate')}
                                            onBlur={e => this.onInputBlur(e, 'dueDate')}
                                            onFocus={e => this.onInputFocus(e, 'dueDate')}
                                        />
                                        <label className='dueDate' onClick={(e) => this.labelClick(e, 'dueDate')} >
                                            {this.context.t('Due Date')}
                                            {
                                                processingData && processingData.find(e => e === 'dueDate') ? (
                                                    <span className="loading"><Dots size={9} speed={0.8} color={'#69B792'} /></span>
                                                ) : null
                                            }
                                        </label>
                                        <div className="calendar-icon" onClick={() => this.toggleCalendar('dueDate')}></div>
                                        {
                                            showDueDateCalendar ? (
                                                <div className={`date-picker`}>
                                                    <Calendar
                                                        selectDay={this.onCalendarSelect}
                                                        unselectDay={this.onCalendarUnselecet}
                                                        selectedDay={
                                                            this.checkDate(returnedOCRData?.incomeProps?.find(p => p.name === 'dueDate')?.text || '') ? {
                                                                display: moment(parseDate(returnedOCRData?.incomeProps?.find(p => p.name === 'dueDate')?.text || ''), 'DD-MM-YYYY').format('LL'),
                                                                value: moment(returnedOCRData?.incomeProps?.find(p => p.name === 'dueDate')?.text || '', 'DD-MM-YYYY')
                                                            } : {
                                                                display: null,
                                                                value: null
                                                            }
                                                        }
                                                        projectId={'none'}
                                                        startDate={
                                                            this.checkDate(returnedOCRData?.incomeProps?.find(p => p.name === 'dueDate')?.text || '') ?
                                                                moment(parseDate(returnedOCRData?.incomeProps?.find(p => p.name === 'dueDate')?.text || ''), 'DD-MM-YYYY')
                                                                :
                                                                moment()}
                                                    />
                                                </div>
                                            ) : null
                                        }
                                        {
                                            fieldsWithWarnings.dueDate ? (
                                                <>
                                                    <div className="warning-icon with-other-icon" data-for={`dueDate-warning-tooltip`} data-tip='show'></div>
                                                </>
                                            ) : null
                                        }
                                        {
                                            fieldsWithErrors.dueDate ? (
                                                <>
                                                    <div className="error-icon with-other-icon" data-for={`dueDate-error-tooltip`} data-tip='show'></div>
                                                </>
                                            ) : null
                                        }
                                    </div>
                                ) : null
                            }

                            {
                                paymentMethod === 'not-paid' ? (
                                    <div className={`form-group ${dataReadWithOcrFrame.includes('accountNumber') ? 'finished-ocr-indication' : ''} ${fieldsWithErrors.accountNumber ? 'error' : ''} ${fieldsWithWarnings.accountNumber ? 'warning' : ''}`}>
                                        <TextareaAutosize
                                            type="text"
                                            name='accountNumber'
                                            id="accountNumber"
                                            value={this.polihAccountPritier(returnedOCRData?.incomeProps?.find(p => p.name === 'accountNumber')?.text || '')}
                                            maxRows={10}
                                            onChange={(e) => this.onInputChange(e, 'accountNumber')}
                                            onClick={(e) => this.onInputClick(e, 'accountNumber')}
                                            onBlur={e => this.onInputBlur(e, 'accountNumber')}
                                            onFocus={e => this.onInputFocus(e, 'accountNumber')}
                                        />
                                        <label className='accountNumber' onClick={(e) => this.labelClick(e, 'accountNumber')} >
                                            {this.context.t('Account Number')}
                                            {
                                                processingData && processingData.find(e => e === 'accountNumber') ? (
                                                    <span className="loading"><Dots size={9} speed={0.8} color={'#69B792'} /></span>
                                                ) : null
                                            }
                                        </label>

                                        {
                                            fieldsWithWarnings.accountNumber ? (
                                                <>
                                                    <div className="warning-icon" data-for={`accountNumber-warning-tooltip`} data-tip='show'></div>
                                                </>
                                            ) : null
                                        }
                                        {
                                            fieldsWithErrors.accountNumber ? (
                                                <>
                                                    <div className="error-icon" data-for={`accountNumber-error-tooltip`} data-tip='show'></div>
                                                </>
                                            ) : null
                                        }
                                    </div>
                                ) : null
                            }
                        </div>

                        <div className="form-section">
                            <h4>{this.context.t('Additional informations')}</h4>
                            <div className="form-group">
                                <TextareaAutosize
                                    type="text"
                                    name='comment'
                                    id="comment"
                                    value={comment}
                                    onChange={(e) => this.props.setComment(e)}
                                    maxRows={10}
                                    onBlur={e => this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'comment', comment, '')}
                                />
                                <label className='comment'>{this.context.t('Comment')}</label>
                            </div>

                            <div className="form-group">
                                <TextareaAutosize
                                    type="text"
                                    name='doc_description'
                                    id="doc_description"
                                    value={documentDescription}
                                    onChange={(e) => this.props.setDocComment(e)}
                                    maxRows={10}
                                    onBlur={e => this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'doc_description', documentDescription, '')}
                                />
                                <label className='doc_description'>{this.context.t('Document description')}</label>
                            </div>

                            <TagsComponent
                                currentTags={tags}
                                tagsList={tagsList}
                                canAddNewTags={tagsCreationLimitation === 'EVERYONE' || tagsCreationLimitation.includes(currentProjectRole)}
                                setTags={this.props.setTags}
                            />
                        </div>
                    </SimpleBar>
                </div >
                <div className="action-buttons">
                    <div className="checkmark" onClick={() => this.setState({ dataConfirmCheckbox: !dataConfirmCheckbox })}>
                        {this.context.t('I confirm the compliance of the data in the form with the data on the original document')}
                        <div className={`checkbox ${dataConfirmCheckbox ? 'checked' : ''}`}></div>
                        {
                            fieldsWithErrors.dataConfirmCheckbox ? (
                                <div className="error-icon" data-for={`dataConfirmCheckbox-error-tooltip`} data-tip='show'></div>
                            ) : null
                        }
                    </div>

                    <button type="button" className={`button`} onClick={() => this.saveData()}>
                        {this.context.t("Save")}
                    </button>
                </div>
            </>
        )
    }
}

OcrPageCostsForm.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    userCompanies: state.Company.companies,
    currentProject: state.User.currentProject,

    returnedOCRData: { ...state.File.returnedOCRData },
    processingData: isIterable(state.File.processingData) ? [...state.File.processingData] : [],
    processedData: isIterable(state.File.processedData) ? [...state.File.processedData] : [],
    dataReadWithOcrFrame: isIterable(state.File.dataReadWithOcrFrame) ? [...state.File.dataReadWithOcrFrame] : []
})

const mapDispatchToProps = {
    alertWarn: AlertActions.warning,
    autoSaveOcr: FileActions.autoSaveOcr,
    changeOCRData: FileActions.changeOCRData,
    checkInvoiceNumber: FileActions.checkInvoiceNumber,
    getUserThatLockedFileTofDevEx: FileActions.getUserThatLockedFileTofDevEx,
    getCompanySymfoniaFile: CompanyActions.getCompanySymfoniaFile,
    getAllDocsFromCounterparty: CompanyActions.getAllDocsFromCounterparty
}

export default connect(mapStateToProps, mapDispatchToProps)(OcrPageCostsForm)