import React, { Component } from 'react'
import { DragSource } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend';
import ReactTooltip from 'react-tooltip'
import { Link } from 'react-router-dom'
import { reverse } from 'named-urls'
import routes from '../../helpers/routes'
import { Spinner } from 'react-activity'
import PropTypes from 'prop-types'
import moment from 'moment';
import 'react-activity/dist/react-activity.css'

const itemSource = {
    beginDrag(props) {
        return { file: props.file, source: props.selectedSource };
    },

    endDrag(props, monitor) {
        // When dropped on a compatible target, do something
        const item = monitor.getItem();
        const dropResult = monitor.getDropResult();
        if (dropResult) {
            props.handleDrop(item.file, monitor.getDropResult().id)
        }
    },
}

function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging(),
    }
}

export class DropFileItem extends Component {

    componentDidMount() {
        this.props.connectDragPreview(getEmptyImage(), {
            captureDraggingState: true,
        });
    }

    drawIcon = (name, id) => {

        var extension = name.split('.')
        extension = extension[extension.length - 1]

        if (extension === 'pdf' || extension === 'PDF') {
            return <div id={`fileRowIcon${id}`} className="icon pdf-icon"></div>
        } else {
            return <div id={`fileRowIcon${id}`} className="icon image-icon"></div>
        }
    }

    render() {
        const { isDragging, connectDragSource, file, i, accessLevel, translation, priority, viewMode } = this.props;

        return connectDragSource(
            <div id={`fileRowContainer${file.ftvId}`} className={`drag-file-item ${isDragging ? 'is-dragging' : ''}`}>
                <Link id={`fileRowLink${file.ftvId}`} className="link crop-link" to={reverse(routes.panel.crop, { projectId: file.projectId, fileId: file.dbId })}>
                    <div id={`fileRow${file.ftvId}`} className="file-row" key={i}>
                        {this.drawIcon(file.name, file.ftvId)}
                        <span id={`fileRowFileName${file.ftvId}`} className={`name ${priority === "high" ? 'urgent' : ''}`}>{file.name}</span>
                        {priority === "high" && viewMode !== 'MULTI' &&
                            <>
                                <div id={`fileRowPriority${file.ftvId}`} className="priority-icon" data-for={`doc-priority-${accessLevel}-${i}`}>
                                    {this.context.t('URGENT')}
                                </div>
                            </>
                        }
                        <span id={`fileRowDate${file.ftvId}`} className="date">{moment(file.uploadDate).format('DD MMM HH:mm')}</span>
                        {/* <div id={`fileRowUploadMethod${file.ftvId}`} className={`upload-method ${file.uploadMethod}`}></div> */}
                        <span id={`fileRowUploadedBy${file.ftvId}`} className="uploaded-by">{file?.userThatUploaded}</span>

                        {/* {
                            file.status === 'NOT_VERIFIED' ? (
                                <div id={`fileRowName-statusButton${file.ftvId}`} className={`status-file ver ${viewMode === 'MULTI' ? 'multi' : ''}`}>{this.context.t('Verify')}</div>

                            ) : (
                                file.status === "NEW" ? (
                                    <div id={`fileRowName-statusButton${file.ftvId}`} className={`status-file ver ${viewMode === 'MULTI' ? 'multi' : ''}`}>{this.context.t('Verify')}</div>
                                ) : (
                                    file.status === 'IN_PROGRESS' ? (
                                        accessLevel === 'USER1' ? (
                                            <div id={`fileRowName-statusButton${file.ftvId}`} className={`status-file ver acc ${viewMode === 'MULTI' ? 'multi' : ''}`}>{this.context.t('Verified')}</div>
                                        ) : (
                                            <div id={`fileRowName-statusButton${file.ftvId}`} className={`status-file ${viewMode === 'MULTI' ? 'multi' : ''}`}>{this.context.t('Accept')}</div>
                                        )
                                    ) : (
                                        <div id={`fileRowName-statusButton${file.ftvId}`} className={`status-file ${viewMode === 'MULTI' ? 'multi' : ''}`}>{this.context.t('Processing...')}</div>
                                    )
                                )
                            )
                        } */}
                        <div id={`fileRowName-statusButton${file.ftvId}`} className={`status-file ver ${viewMode === 'MULTI' ? 'multi' : ''}`}>{this.context.t('New')}</div>
                    </div>
                </Link>
            </div>
        )
    }
}

DropFileItem.contextTypes = {
    t: PropTypes.func
}

export default DragSource('item', itemSource, collect)(DropFileItem);