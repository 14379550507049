import React, { Component } from 'react'
import { DropTarget } from 'react-dnd'
import ReactTooltip from 'react-tooltip'
import { Spinner } from 'react-activity'
import 'react-activity/dist/react-activity.css'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import PropTypes from 'prop-types'
import moment from 'moment'
import copy from 'copy-to-clipboard';

import DragFileItem from './DragFileItem'
import QrCodesForMobile from './QrCodesForMobile'

import appleQR from '../../img/apple-qr.svg'
import appStoreButton from '../../img/download-from-app-store-PL.svg'
import googleQR from '../../img/google-qr.svg'
import playStoreButton from '../../img/download-from-google-play-store-PL.svg'

const targetSource = {
    drop(props, monitor, component) {
        return {
            id: props.dropTargetId
        }
    }
}
function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        hovered: monitor.isOver(),
        item: monitor.getItem(),
        canDrop: monitor.canDrop(),
    }
}

export class DropTargetArea extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showMobilePopup: false
        }
    }

    drawIcon = (name, id) => {

        var extension = name.split('.')
        extension = extension[extension.length - 1]

        if (extension === 'pdf' || extension === 'PDF') {
            return <div id={`fileRowIcon${id}`} className="icon pdf-icon"></div>
        } else {
            return <div id={`fileRowIcon${id}`} className="icon image-icon"></div>
        }
    }

    render() {
        const { showMobilePopup } = this.state
        const { connectDropTarget, hovered, containedItems, dropTargetId, simpleBarHeightWithDocs, simpleBarHeightWithoutDocs, accessLevel, translation, viewMode, projectCode } = this.props
        return connectDropTarget(
            <div className={`drop-file-target ${hovered ? 'target-hovered' : ''} ${containedItems.files.length > 0 && containedItems.loaded ? '' : 'no-files'}`}>
                <div className={`corner-borders ${containedItems.files.length > 0 && containedItems.loaded ? 'show' : 'hide'}`}>
                    <div className="top-left"></div>
                    <div className="top-right"></div>
                    <div className="bottom-left"></div>
                    <div className="bottom-right"></div>
                </div>
                <SimpleBar style={{ zIndex: '2', height: containedItems.files.length > 0 && containedItems.loaded ? simpleBarHeightWithDocs : simpleBarHeightWithoutDocs, minHeight: '80px', width: '100%', paddingRight: '5px' }}>
                    {
                        containedItems.files.length > 0 && containedItems.loaded ? (
                            containedItems.files.map((file, i) => {
                                if (file.projectId === dropTargetId && this.props.fileMatchesFilter(file)) {
                                    if (file.imageData !== "processing") {
                                        return (
                                            <DragFileItem
                                                key={i}
                                                file={file}
                                                handleDrop={this.props.handleItemDrop}
                                                i={i}
                                                accessLevel={accessLevel}
                                                dropTargetId={dropTargetId}
                                                translation={translation}
                                                priority={file.priority}
                                                viewMode={viewMode}
                                            />
                                        )
                                    } else {
                                        return (
                                            <div id={`fileRow${file.ftvId}`} className="file-row" key={i}>
                                                {this.drawIcon(file.name, file.id)}
                                                <div id={`fileRowName${file.ftvId}`} className="name processing">{file.name}</div>

                                                <div id={`fileRowStatus${file.ftvId}`} className={`status processing`}>
                                                    <Spinner size={10} speed={0.8} color={"#9099A5"} />
                                                </div>
                                            </div>
                                        )
                                    }
                                }
                            })
                        ) : (
                            <div className={`new-dropzone-indicator ${viewMode === 'MULTI' ? 'small' : ''}`} style={{ height: simpleBarHeightWithoutDocs }}>
                                <div className="button-quarter dnd">
                                    <div className="dropzone-button">
                                        <div className="icon dnd"></div>
                                        <p>{translation("Drop your documents here")}</p>
                                    </div>
                                </div>
                                <div className="button-quarter upload">
                                    <div className="dropzone-button">
                                        <div className="icon upload"></div>
                                        <p>{translation("Attach a document")}</p>
                                    </div>
                                </div>
                                <div className="button-quarter email">
                                    <div data-tip="show" data-for={`binderEmailTooltip-${projectCode}`} className="dropzone-button" id={`fileRowEmailAddressButton-${projectCode}`} onClick={e => { copy(`${projectCode.toLowerCase()}@app.easydocs.pl`); this.props.showInfo('Copied to clipboard') }}>
                                        <div className="icon email" id={`fileRowEmailAddressIcon-${projectCode}`}></div>
                                        <p id={`fileRowEmailAddressText-${projectCode}`}>
                                            {translation("Send documents by e-mail to ")}
                                            <a id={`fileRowEmailAddressLink-${projectCode}`} href={`mailto:${projectCode.toLowerCase()}@app.easydocs.pl`}>{`${projectCode.toLowerCase()}@app.easydocs.pl`}</a>
                                        </p>
                                    </div>

                                    <ReactTooltip id={`binderEmailTooltip-${projectCode}`} place="top" effect="solid" className="default-tooltip">
                                        <p>{projectCode.toLowerCase()}@app.easydocs.pl</p>
                                    </ReactTooltip>
                                </div>
                                <div className="button-quarter mobile" onClick={() => this.setState({ showMobilePopup: true })}>
                                    <div className="dropzone-button" id={`fileRowMobileButton-${projectCode}`}>
                                        <div className="icon mobile" id={`fileRowMobileIcon-${projectCode}`}></div>
                                        <p id={`fileRowMobileTest-${projectCode}`}>{translation("Send documents using the mobile application")}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </SimpleBar >


                {
                    containedItems.files.length > 0 && containedItems.files.length < 4 && containedItems.loaded ? (
                        <div className={`new-dropzone-indicator with-files absolute  ${viewMode === 'MULTI' ? 'small' : ''}`}>
                            <div className="button-quarter dnd">
                                <div className="dropzone-button">
                                    <div className="icon dnd"></div>
                                </div>
                            </div>
                            <div className="button-quarter upload">
                                <div className="dropzone-button">
                                    <div className="icon upload"></div>
                                </div>
                            </div>
                            <div className="button-quarter email">
                                <div data-tip="show" data-for={`binderEmailTooltip-${projectCode}`} className="dropzone-button" id={`fileRowEmailAddressButton-${projectCode}`} onClick={e => { copy(`${projectCode.toLowerCase()}@app.easydocs.pl`); this.props.showInfo('Copied to clipboard') }}>
                                    <div className="icon email" id={`fileRowEmailAddressIcon-${projectCode}`}></div>
                                </div>

                                <ReactTooltip id={`binderEmailTooltip-${projectCode}`} place="top" effect="solid" className="default-tooltip">
                                    <p>{projectCode.toLowerCase()}@app.easydocs.pl</p>
                                </ReactTooltip>
                            </div>
                            <div className="button-quarter mobile" onClick={() => this.setState({ showMobilePopup: true })}>
                                <div className="dropzone-button" id={`fileRowMobileButton-${projectCode}`}>
                                    <div className="icon mobile" id={`fileRowMobileIcon-${projectCode}`}></div>
                                </div>
                            </div>
                        </div>
                    ) : null
                }

                {
                    showMobilePopup ? (
                        <QrCodesForMobile closePopup={() => this.setState({ showMobilePopup: false })} />
                    ) : null
                }
            </div >
        )
    }
}

export default DropTarget('item', targetSource, collect)(DropTargetArea);