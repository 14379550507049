import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { List, AutoSizer, WindowScroller } from "react-virtualized";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import { AlertActions } from '../../actions/AlertActions'
import { ReportsActions } from '../../actions/ReportsActions'
import { UserActions } from '../../actions/UserActions'
import { CompanyActions } from '../../actions/CompanyActions'

import FilesPreviewInReports from './FilesPreviewInReports'

import dynamicComparer from '../../helpers/dynamicComparer'
import myFetch from '../../helpers/fetch'

export class ReportsList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            filterString: '',
            showProjectsList: false,
            lastSearchEditTime: null,
            showConfirmDeleteZipPopup: false,
            reportIdToDelete: -1,
            reportFileNameToDelete: '',
            projectIdOfReportToDelete: -1,
            cursourWait: false,
            reportsList: [],
            reportsSortField: 'created',
            reportsSortOrder: 'desc',
            howIncludedDocsList: false,
            includedDocs: []
        }

        this.projectSelectorRef = React.createRef()
    }

    componentDidMount() {
        const { currentProject, previousGeneratedZip, previousGeneratedZipLoaded,previousGeneratedReports  } = this.props
        if (currentProject?.id) this.props.getMyZips(currentProject?.id)
        // if (!previousGeneratedZipLoaded) {
        //     this.props.getMyZips(currentProject?.id || -1)
        // } else if (previousGeneratedZipLoaded && previousGeneratedZip && previousGeneratedZip.length) {
        //     if (previousGeneratedZip[0]?.projectId === currentProject?.id) {
        //         this.setState({
        //             reportsList: previousGeneratedZip.filter(r => this.checkIfMatchesFilter(r))
        //         })
        //     } else {
        //         this.props.getMyZips(currentProject?.id || -1)
        //     }
        // }
        document.addEventListener("mousedown", this.handleClick)
    }

    componentDidUpdate(prevProps) {
        const { previousGeneratedZipLoaded, previousGeneratedZip } = this.props
        if (!prevProps.previousGeneratedZipLoaded && previousGeneratedZipLoaded || prevProps.previousGeneratedZip.length != previousGeneratedZip.length) {
                    this.setState({
                        reportsList: previousGeneratedZip.filter(c => this.checkIfMatchesFilter(c))
                    })
                }

        const { currentProject } = this.props
        if (currentProject?.id && currentProject?.id !== prevProps.currentProject?.id) {
            this.props.getMyZips(currentProject?.id)
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick)
    }

    handleClick = (e) => {
        const { showProjectsList } = this.state
        if (showProjectsList && this.projectSelectorRef && this.projectSelectorRef.current && !this.projectSelectorRef.current.contains(e.target)) {
            this.setState({
                showProjectsList
            })
        }
    }

  

    inputChange = e => {
        e.preventDefault()

        const { name, value } = e.target

        this.setState({
            [name]: value,
            lastSearchEditTime: new Date()
        })

        setTimeout(() => {
            const { lastSearchEditTime, filterString } = this.state
            if (new Date() - lastSearchEditTime > 730) {
                const { previousGeneratedZip } = this.props
                this.setState({
                    reportsList: previousGeneratedZip.filter(c => this.checkIfMatchesFilter(c))
                })
            }
        }, 750)
    }

    getSortClassReports = (sortFieldCheck) => {
        const { reportsSortField, reportsSortOrder } = this.state

        if (reportsSortField === sortFieldCheck) {
            if (reportsSortOrder === "asc")
                return "icon-sort down"
            return "icon-sort up"
        }

        return "icon-sort"
    }

    switchSortingField = (name) => {
        var { reportsSortField, reportsSortOrder, reportsList } = this.state
        var newSortOrder = 'asc'
        if (reportsSortField === name) {
            if (reportsSortOrder === "asc") {
                newSortOrder = 'desc'
            }
        }
        reportsList.sort(dynamicComparer(name, newSortOrder))

        this.setState({
            reportsSortField: name,
            reportsSortOrder: newSortOrder,
            reportsList: reportsList
        })
    }

    checkIfMatchesFilter = report => {
        const { filterString } = this.state
        const lowerCaseFilterString = filterString.toLowerCase()

        if (filterString.length > 2) {
            if (report.projectName.toLowerCase().includes(lowerCaseFilterString)) {
                return true
            } else if (report.createdBy.toLowerCase().includes(lowerCaseFilterString)) {
                return true
            } else if (report.docType.toLowerCase().includes(lowerCaseFilterString)) {
                return true
            } else if (report.expensesUserName.toLowerCase().includes(lowerCaseFilterString)) {
                return true
            } else if (report.docs?.length) {
                var includesDocMatchesFilter = false
                for (const d of report.docs) {
                    if (d.counterpartyVatId?.includes(lowerCaseFilterString)) {
                        includesDocMatchesFilter = true
                        break;
                    }
                    if (d.counterpartyName?.toLowerCase().includes(lowerCaseFilterString)) {
                        includesDocMatchesFilter = true
                        break;
                    }
                    if (d.docNumber?.toLowerCase().includes(lowerCaseFilterString)) {
                        includesDocMatchesFilter = true
                        break;
                    }
                    if (d.comment?.toLowerCase().includes(lowerCaseFilterString)) {
                        includesDocMatchesFilter = true
                        break;
                    }
                }
                return includesDocMatchesFilter
            } else {
                return false
            }
        } else {
            return true
        }
    }

    downloadZipClick = (fileId, projectId) => {
        this.setState({
            cursourWait: true
        })
        this.props.downloadZip(fileId, projectId, () => {
            this.setState({
                cursourWait: false
            })
        })
    }

    deleteZipClick = (fileId, projectId) => {
        this.setState({
            showConfirmDeleteZipPopup: true,
            zipIdToDelete: fileId,
            projectIdOfZipToDelete: projectId
        })
    }

    deleteZipConfirm = () => {
        this.setState({
            cursourWait: true
        })
        const { zipIdToDelete, projectIdOfZipToDelete } = this.state
        this.props.deleteZip(zipIdToDelete, projectIdOfZipToDelete, (success) => {
            if (success) {
                this.setState({
                    showConfirmDeleteZipPopup: false,
                    zipIdToDelete: -1,
                    projectIdOfZipToDelete: -1,
                    cursourWait: false
                })
            }
        })
    }

    setRef = windowScroller => {
        this._windowScroller = windowScroller;
    }

    setListRef = listRef => {
        this.listRef = listRef
    }

    rowGetter = ({ index }) => {
        const { reportsList } = this.state

        return reportsList[index]
    }


    getRowHeight = ({ index }) => {
        const { reportsList } = this.state

        var c = reportsList[index]
        return 40
    }

    rowRenderer = ({ style, key, index, parent, width }) => {
        const { reportsList } = this.state

        var report = reportsList[index]

        return (
            <div
                key={key}
                style={style}
                role="row"
                className="ReactVirtualized__List-row"
            >
                <div className="ReactVirtualized__List-cell empty"></div>
                <div className="ReactVirtualized__List-cell name">
                    {report.projectName}
                </div>
                <div className="ReactVirtualized__List-cell user">
                    {report.createdBy}
                </div>
                <div className="ReactVirtualized__List-cell date">
                    {moment(report.created).format('YYYY-MM-DD')}
                </div>
                <div className="ReactVirtualized__List-cell count">
                    {report.filesCount}
                </div>
                <div className="ReactVirtualized__List-cell count">
                    {report.paymentOrdersCount}
                </div>
                <div className="ReactVirtualized__List-cell actions">
                    <span className="inspect" onClick={() => this.showDocsList(report.docs)}></span>
                    <span className="download-files" onClick={() => this.downloadZipClick(report.id, report.projectId)}></span>
                    <span className="delete" onClick={() => this.deleteZipClick(report.id, report.projectId)}></span>
                </div>
            </div>
        )
    }

    showDocsList = docs => {
        console.log(docs)
        this.setState({
            showIncludedDocsList: true,
            includedDocs: docs || []
        })
    }

    hideDocsList = () => {
        this.setState({
            showIncludedDocsList: false,
            includedDocs: []
        })
    }

    includedDocClick = doc => {
        this.setState({
            previewFile: {
                projectId: doc.projectId,
                file: '',
                id: doc.fileId,
                docType: 'DOC'
            }
        })
    }

    toogleProjectsList = newValue => {
        this.setState({
            showProjectsList: newValue
        })
    }

    setCurrentProject = (id, companyId) => {
        this.props.getMyZips(id)
        this.toogleProjectsList(false)
        this.props.switchDashboardViewModel(id)

        var { userCompanies, currentCompany } = this.props
        if (currentCompany && currentCompany.id !== companyId) {
            var companyIndex = userCompanies.findIndex(c => c.company_id === companyId)
            if (companyIndex > -1) {
                this.props.setCurrentCompany(userCompanies[companyIndex])
            }
        }
    }

    render() {
        const { currentProject, groupedProjects, previousGeneratedZip, previousGeneratedZipLoaded, location, wrapperRef, user } = this.props
        const {
            cursourWait,
            reportsList, filterString, showProjectsList,
            showConfirmDeleteZipPopup, zipIdToDelete, projectIdOfZipToDelete,
            showIncludedDocsList, includedDocs
        } = this.state
        return (
            <div className={`reports-list ${cursourWait ? 'loading' : ''}`}>
                {
                    showConfirmDeleteZipPopup ? (
                        <div className="popup">
                            <div className="popup-card">
                                <div className="header">{this.context.t('Are you sure you want to delete this zip?')}</div>
                                <div className="text">{this.context.t('After deleting this zip, the documents that were included in it will be unlocked for another export')}</div>

                                <div className="action-buttons">
                                    <div className="btn" onClick={e => this.setState({ showConfirmDeleteZipPopup: false })}>{this.context.t('Cancel')}</div>
                                    <div className="btn delete" onClick={e => this.deleteZipConfirm()}>{this.context.t('Delete')}</div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        null
                    )
                }

                {
                    showIncludedDocsList ? (
                        <div className="popup">
                            <div className="popup-card included-docs">
                                <div className="header">{this.context.t('Included docs')}:</div>
                                <div className="included-docs-table">
                                    <table >
                                        <thead>
                                            <tr>
                                                <th className="counterparty">{this.context.t('Counterparty')}</th>
                                                <th className="doc-number">{this.context.t('Doc number')}</th>
                                                <th className="amount">{this.context.t('Amount to pay')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                includedDocs.map(doc => {
                                                    return (
                                                        <tr>
                                                            <td className="counterparty">{doc.counterpartyName}</td>
                                                            <td className="doc-number">{doc.docNumber}</td>
                                                            <td className="amount">{`${doc.amountToPay} ${doc.currency}`}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table >
                                </div>
                                <div className="action-buttons">
                                    <div className="btn" onClick={e => this.hideDocsList()}>{this.context.t('Close')}</div>
                                </div>
                            </div>
                        </div>
                    ) : null
                }

                {
                    !previousGeneratedZipLoaded ? (
                        <div className="reports-loading">
                            <div className="image"></div>
                            <div className="text">
                                <div className="header">
                                    {this.context.t('Loading')}
                                </div>
                                <div className="instruction">
                                    {this.context.t('Please wait a second while we are retriving, loading and calculating your data')}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="reports-table">
                            <div className="filter-row">
                                <div className="projects-selector" ref={this.projectSelectorRef}>
                                    <div className="label" onClick={() => this.toogleProjectsList(!showProjectsList)}>{this.context.t('Binder')}</div>
                                    <div className="current" onClick={() => this.toogleProjectsList(!showProjectsList)}>{currentProject?.code}</div>
                                    <div className={`list-arrow ${showProjectsList ? 'extended' : ''}`} onClick={() => this.toogleProjectsList(!showProjectsList)}></div>
                                    {
                                        showProjectsList ? (
                                            <div className="projects-list">
                                                <SimpleBar style={{ maxHeight: '50vh', width: '100%' }}>
                                                    <ul>
                                                        {
                                                            groupedProjects.map(g => {
                                                                return (
                                                                    g?.projects?.map(p => {
                                                                        return (
                                                                            <li onClick={() => this.setCurrentProject(p.id, p.company_id)}>
                                                                                {p.code}
                                                                            </li>
                                                                        )
                                                                    })
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </SimpleBar>
                                            </div>
                                        ) : null
                                    }
                                </div>
                                <input type="text" name="filterString" value={filterString} onChange={e => this.inputChange(e)} placeholder={this.context.t('Search generated zips')} />
                            </div>

                            {
                                reportsList.length === 0 ? (
                                    <div className="no-reports-found">
                                        <div className="image"></div>
                                        <div className="text">
                                            <div className="header">
                                                {this.context.t('It looks like you don\'t have any reports')}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="ReactVirtualized__List-wrapper">
                                        <div className="ReactVirtualized__List-row header">
                                            <div className="ReactVirtualized__List-cell empty"></div>
                                            <div className="ReactVirtualized__List-cell name" onClick={() => this.switchSortingField('projectName')}>
                                                {this.context.t('Binder')}
                                                <span className={this.getSortClassReports('projectName')}></span>
                                            </div>

                                            <div className="ReactVirtualized__List-cell user" onClick={() => this.switchSortingField('createdBy')}>
                                                {this.context.t('Created by')}
                                                <span className={this.getSortClassReports('createdBy')}></span>
                                            </div>
                                            <div className="ReactVirtualized__List-cell date" onClick={() => this.switchSortingField('created')}>
                                                {this.context.t('Creation date')}
                                                <span className={this.getSortClassReports('created')}></span>
                                            </div>
                                            <div className="ReactVirtualized__List-cell count" onClick={() => this.switchSortingField('filesCount')}>
                                                {this.context.t('Files count')}
                                                <span className={this.getSortClassReports('filesCount')}></span>
                                            </div>
                                            <div className="ReactVirtualized__List-cell count" onClick={() => this.switchSortingField('paymentOrdersCount')}>
                                                {this.context.t('Payment orders count')}
                                                <span className={this.getSortClassReports('crepaymentOrdersCountated')}></span>
                                            </div>
                                            <div className="ReactVirtualized__List-cell actions">
                                            </div>
                                        </div>
                                        <WindowScroller
                                            ref={this.setRef}
                                            scrollElement={user.new_easydocs_version && wrapperRef && wrapperRef.current ? wrapperRef.current : window}
                                        >
                                            {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
                                                <AutoSizer disableHeight>
                                                    {({ width }) => (
                                                        <div ref={registerChild}>
                                                            <List
                                                                ref={this.setListRef}
                                                                autoHeight
                                                                width={width}
                                                                height={height}
                                                                rowHeight={this.getRowHeight}

                                                                rowCount={reportsList.length}
                                                                rowGetter={this.rowGetter}
                                                                rowRenderer={({ style, key, index, parent }) => this.rowRenderer({ style, key, index, parent, width })}
                                                                overscanRowCount={2}

                                                                isScrolling={isScrolling}
                                                                scrollTop={scrollTop}
                                                            />
                                                        </div>
                                                    )}
                                                </AutoSizer>
                                            )}
                                        </WindowScroller>
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>
        )
    }
}

ReportsList.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    currentProject: state.User.currentProject,
    userProjects: state.User.userProjects,
    groupedProjects: state.User.groupedProjects,
    currentCompany: state.Company.currentCompany,
    userCompanies: state.Company.companies,
    previousGeneratedZip: state.Reports.previousGeneratedZip,
    previousGeneratedZipLoaded: state.Reports.previousGeneratedZipLoaded,
    zipsSortField: state.Reports.zipsSortField,
    zipsSortOrder: state.Reports.zipsSortOrder
})

const mapDispatchToProps = {
    downloadZip: ReportsActions.downloadZip,
    switchSortingFilterForZip: ReportsActions.switchSortingFilterForZip,
    getMyZips: ReportsActions.getMyZips,
    deleteZip: ReportsActions.deleteZip,

    switchDashboardViewModel: UserActions.switchDashboardViewModel,
    setCurrentCompany: CompanyActions.setCurrentCompany,

    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsList)