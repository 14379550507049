import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import moment from 'moment'

import { Spinner } from 'react-activity'
import 'react-activity/dist/react-activity.css'


import { UserActions } from '../../actions/UserActions'
import { EventActions } from '../../actions/EventActions'

export class ThisMonthEvents extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        const { userProjects, events } = this.props
        if (userProjects.length > 0 && events.length === 0) this.getAllEvents()
    }

    componentDidUpdate(prevProps) {
        const { userProjects, events } = this.props
        if (prevProps.userProjects.length === 0 && userProjects.length > 0 && events.length === 0) this.getAllEvents()
    }

    getAllEvents = () => {
        const { userProjects, events, projectEventsLoaded, projectEventsLoading } = this.props
        if (events.length === 0) {
            userProjects.forEach(p => {
                if (!projectEventsLoaded.includes(p.id) && !projectEventsLoading.includes(p.id)) {
                    var filter = {
                        projectId: p.id,
                        eventId: -1,
                        startDate: moment().subtract(6, 'month').format('YYYYMMDD HHmm'),
                        endDate: moment().add(6, 'month').format('YYYYMMDD HHmm'),
                    }
                    this.props.getProjectEvents(filter, p.id)
                }
            })
        }
    }

    render() {
        const { events, monthOnToolbarCalendar, dayOnToolbarCalendar, projectEventsLoaded, projectEventsLoading } = this.props

        var day = parseInt(moment().format('D'))
        var hour = parseInt(moment().format('H'))
        var minute = parseInt(moment().format('m'))
        var currentMonth = moment().format('YYYY-MM')
        var eventsCount = 0

        if (currentMonth !== monthOnToolbarCalendar) {
            day = 1
            hour = 0
            minute = 0
        }

        return (
            <div className="upcoming-tasks">
                <div className="header">
                    {
                        dayOnToolbarCalendar ? (
                            this.context.t('Tasks for {n}', { n: dayOnToolbarCalendar })
                        ) : (
                            monthOnToolbarCalendar === moment().format('YYYY-MM') ? (
                                this.context.t('Upcoming tasks for {n}', { n: `${this.context.t(moment(monthOnToolbarCalendar, 'YYYY-MM').format("MMMM"))} ${moment(monthOnToolbarCalendar, 'YYYY-MM').format("YYYY")}` })
                            ) : (
                                this.context.t('Tasks for {n}', { n: `${this.context.t(moment(monthOnToolbarCalendar, 'YYYY-MM').format("MMMM"))} ${moment(monthOnToolbarCalendar, 'YYYY-MM').format("YYYY")}` })
                            )
                        )
                    }
                </div>
                <div className="tasks-list">
                    <SimpleBar style={{ maxHeight: '45vh', paddingRight: '10px', paddingLeft: '10px', width: 'calc(100% + 20px)', marginLeft: '-10px', marginRight: '-10px' }}>
                        {
                            events.map(e => {
                                if ((!dayOnToolbarCalendar && moment(e.start_date).format('YYYY-MM') === monthOnToolbarCalendar && moment(e.start_date) > moment(monthOnToolbarCalendar, 'YYYY-MM').set('date', day).set('hour', hour).set('minute', minute)) || (dayOnToolbarCalendar && dayOnToolbarCalendar === moment(e.start_date).format('DD-MM-YYYY'))) {
                                    eventsCount++
                                    return (
                                        <div className="event-row" key={`event-${e.id}`}>
                                            <div className="name">
                                                {e.project ? (<span className='project-code'>{'[' + e.project.code + '] '}</span>) : (null)} {e.name}
                                            </div>
                                            <div className="date">
                                                {moment(e.start_date).utc().format('YYYY-MM-DD HH:mm')}
                                            </div>
                                            <div className="description">
                                                {
                                                    e.note && e.note.split('\n') ? (
                                                        e.note.split('\n').map(l => {
                                                            if (l) {
                                                                return (
                                                                    <p>{l}</p>
                                                                )
                                                            } else {
                                                                return (
                                                                    <br />
                                                                )
                                                            }
                                                        })
                                                    ) : (
                                                        null
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return null
                                }
                            })
                        }

                        {
                            dayOnToolbarCalendar ? (
                                <div className="reset-day" onClick={() => this.props.toogleDayForTooltipCalendar(null)}>{this.context.t('Show tasks for the whole month')}</div>
                            ) : null
                        }
                    </SimpleBar>

                    {
                        eventsCount === 0 && projectEventsLoading && projectEventsLoading.length === 0 && projectEventsLoaded && projectEventsLoaded.length > 0 ? (
                            <div className="no-tasks-indicator">
                                <div className="img"></div>
                                <div className="text">
                                    {this.context.t('It looks like you don\'t have any tasks')}
                                </div>
                            </div>
                        ) : (
                            events.length === 0 && projectEventsLoading && projectEventsLoading.length > 0 ? (
                                <div className="no-tasks-indicator">
                                    <div className="activity-indicator">
                                        <Spinner color={'#69B792'} size={30} speed={0.8} />
                                    </div>
                                </div>
                            ) : null
                        )
                    }
                </div>
            </div>
        )
    }
}

ThisMonthEvents.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    userProjects: state.User.userProjects || [],
    events: [...state.Event.events],
    projectEventsLoaded: state.Event.projectEventsLoaded,
    projectEventsLoading: state.Event.projectEventsLoading,

    monthOnToolbarCalendar: state.User.monthOnToolbarCalendar,
    dayOnToolbarCalendar: state.User.dayOnToolbarCalendar,
})

const mapDispatchToProps = {
    toogleDayForTooltipCalendar: UserActions.toogleDayForTooltipCalendar,
    getProjectEvents: EventActions.getProjectEvents,
}


export default connect(mapStateToProps, mapDispatchToProps)(ThisMonthEvents)