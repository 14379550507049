import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { Link } from 'react-router-dom'
import { reverse } from 'named-urls'

import isIterable from "../../helpers/isIterable"
import availableQuickActions from '../../helpers/availableQuickActions'
import { history } from "../../helpers/history"
import routes from "../../helpers/routes"

import FilesPreviewInReports from '../ReportsPageComponents/FilesPreviewInReports'

import { FileActions } from '../../actions/FileActions'
import { UserActions } from '../../actions/UserActions'
import { CompanyActions } from '../../actions/CompanyActions'
import { TransfersActions } from '../../actions/TransfersActions'
import { AlertActions } from '../../actions/AlertActions'
import { ReportsActions } from '../../actions/ReportsActions'

import { socket } from "../../App"

import '../../css/QuickActions.css'
import '../../css/Report.css'
import { Spinner, Bounce } from 'react-activity'

export class ProjectAttentionDocs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            previewFile: {},
            docsListForPreview: [],
            areDropzoneDocsCollapsed: false,
            areAdditionalPaymentAcceptanceDocsCollapsed: false,
            areUpcomingPaymentsCollapsed: false,
            areMentionedUnreadConversationsCollapsed: false,
        }
        this.tableRef = React.createRef()
    }

    componentDidMount() {
        window.addEventListener("keydown", this.keyDownHandler, false)

        const { projectId } = this.props
        this.props.getDocsRequiringMyAttention(projectId)

        if (socket) {
            socket.on("createNewTransfersFile", this.reloadResources)
            socket.on("markDocAsPaid", this.reloadResources);
            socket.on("markPaymentOrderAsPaid", this.reloadResources);
            socket.on("additionalAcceptance", this.reloadResources);
            socket.on("createNewTransfersFile", this.reloadResources);
            socket.on("newChatMessage", this.reloadResources);
        }
    }

    componentDidUpdate(prevProps) {

        const { projectId } = this.props
        if (prevProps.projectId !== projectId)
            this.props.getDocsRequiringMyAttention(projectId)
    }

    componentWillUnmount() {
        if (socket) {
            socket.off("createNewTransfersFile", this.reloadResources)
            socket.off("markDocAsPaid", this.reloadResources);
            socket.off("markPaymentOrderAsPaid", this.reloadResources);
            socket.off("additionalAcceptance", this.reloadResources);
            socket.off("createNewTransfersFile", this.reloadResources);
            socket.off("newChatMessage", this.reloadResources);
        }
        window.removeEventListener("keydown", this.keyDownHandler, false)
    }

    reloadResources = (data) => {
        const { projectId } = this.props
        if (data.projectId === projectId)
            this.props.getDocsRequiringMyAttention(projectId)
    }

    keyDownHandler = e => {
        if (e.keyCode === 27) {
            //ESC
            const { showSectionSettings } = this.state
            if (showSectionSettings) {
                this.setState({
                    showSectionSettings: false
                })
            }
        }
    }

    fileIconClick = (projectId, filePath, fileId, docType, filesList) => {
        this.props.toogleFilePreviewInActions(true)
        this.setState({
            previewFile: {
                projectId: projectId,
                file: filePath,
                id: fileId,
                docType: docType
            },
            docsListForPreview: filesList
        })
        if (docType !== 'PAYMENT_ORDER') {
            this.props.getFileBody(parseInt(projectId), filePath, fileId, 'IN_PROGRESS', 3)
        } else {
            const { transfers } = this.props
            var projectTransfers = transfers.find(e => e.project === projectId)
            if (projectTransfers && projectTransfers.transfers) {
                var transfer = projectTransfers.transfers.find(e => e.id === fileId)
                if (transfer) {
                    this.props.openTransferPreviewWindow(transfer)
                } else {
                    this.props.getPaymentOrders(projectId, -1, (loadedTransfers) => {
                        var transfer = loadedTransfers.find(e => e.id === fileId)
                        if (transfer) {
                            this.props.openTransferPreviewWindow(transfer)
                        }
                    })
                }
            } else {
                this.props.getPaymentOrders(projectId, -1, (loadedTransfers) => {
                    var transfer = loadedTransfers.find(e => e.id === fileId)
                    if (transfer) {
                        this.props.openTransferPreviewWindow(transfer)
                    }
                })
            }
        }
    }

    setCurrentDocPreview = (doc) => {
        this.setState({
            previewFile: doc
        })
    }

    hideFilePreview = () => {
        this.props.toogleFilePreviewInActions(false)
        this.setState({
            previewFile: {},
            docsListForPreview: []
        })
    }

    collapseSection = (sectionName, isCollapsed) => {
        this.setState({
            [sectionName]: isCollapsed
        })
    }

    dropzoneFileClick = file => {
        const { userProjects, currentProject } = this.props
        if (currentProject.id !== file.projectId) {
            this.props.switchDashboardViewModel(file.projectId)
            var { userCompanies, currentCompany } = this.props
            const newProject = userProjects.find(p => p.id === file.projectId)
            var companyId = newProject?.company_id || -1


            if (companyId !== -1 && currentCompany && currentCompany.id !== companyId) {
                var companyIndex = userCompanies.findIndex(c => c.company_id === companyId)
                if (companyIndex > -1) {
                    this.props.setCurrentCompany(userCompanies[companyIndex])
                }
            }
        }
        this.props.toggleQuickAction(availableQuickActions.docsRequiringMyAttention, false)
    }

    getCheckStateForAllProjects = () => {
        const { settingsType } = this.state
        const { userProjects } = this.props

        var oneSelected = false
        userProjects.forEach(up => {
            if (up[settingsType]) oneSelected = true
        })

        return oneSelected
    }

    toggleNotificationForAllProjects = () => {
        const { settingsType } = this.state
        const { userProjects } = this.props

        var oneSelected = false
        userProjects.forEach(up => {
            if (up[settingsType]) oneSelected = true
        })

        userProjects.forEach(up => {
            this.props.toggleNotificationSettingForProject(up.id, settingsType, !oneSelected)
        })
    }

    getDueDateCellClass = doc => {
        if (!doc.dueDate) return ''
        if (doc.dueDate <= moment().format('YYYY-MM-DD') || doc.priority) return 'overdue'
        if (doc.dueDate < moment().add(3, 'day').format('YYYY-MM-DD') && doc.dueDate >= moment().add(1, 'day').format('YYYY-MM-DD')) return 'upcoming'
    }

    getSortClass = (checkField, sortField, sortOrder) => {
        if (sortField === checkField) {
            if (sortOrder === "asc")
                return "icon-sort up"
            return "icon-sort down"
        }
        return "icon-sort"
    }


    getAcceptedByTooltip = (file, tooltipId) => {
        const { user } = this.props
        if (file.additionalAcceptance && Array.isArray(file.additionalAcceptance)) {
            return (
                <ReactTooltip id={`${tooltipId}-${file.fileId}`} place="left" effect="solid" className="default-tooltip acceptance-tooltip unpaid-doc-status-tooltip">
                    <h6>{this.context.t('Accepted by:')}</h6>
                    <ol>
                        {!file.additionalAcceptance.find(e => e.user_id === file.acceptedBy?.id) && file.acceptedBy ? (
                            <li>
                                {`${file.acceptedBy.first_name} ${file.acceptedBy.last_name} ${file.accepted_by === user.id ? this.context.t(' (You)') : ''}`}
                            </li>
                        ) : (
                            null
                        )}
                        {
                            file.additionalAcceptance.map(acc => {
                                if (acc.accepted)
                                    return (
                                        <li>
                                            {`${acc.user?.first_name} ${acc.user?.last_name} ${acc.user_id === user.id ? this.context.t(' (You)') : ''}`}
                                        </li>
                                    )

                                return null
                            })
                        }
                    </ol>

                    {
                        file.requiresAdditionalAcceptance ? (
                            <>
                                {
                                    file.additionalAcceptancesRequired >= 1 ? (
                                        <h6 className="waiting">{this.context.t('Requires acceptance ({n}):', { n: file.additionalAcceptancesRequired - file.additionalAcceptancesReceived })}</h6>
                                    ) : (
                                        <h6 className="waiting">{this.context.t('Requires acceptance:')}</h6>
                                    )
                                }

                                <ol>
                                    {
                                        file.additionalAcceptance.map(acc => {
                                            if (!acc.accepted)
                                                return (
                                                    <li>
                                                        {`${acc.user?.first_name} ${acc.user?.last_name} ${acc.user_id === user.id ? this.context.t(' (You)') : ''}`}
                                                    </li>
                                                )

                                            return null
                                        })
                                    }
                                </ol>
                            </>
                        ) : (
                            null
                        )
                    }
                </ReactTooltip>
            )
        } else {
            return null
        }
    }

    getAcceptanceStatus = (file, tooltipId) => {
        const { user } = this.props
        if (file.requiresAdditionalAcceptance && Array.isArray(file.additionalAcceptance)) {
            return (
                <>
                    <span className={`status`} data-for={`${tooltipId}-${file.fileId}`} data-tip='show'>
                        {
                            file.additionalAcceptance.map(acc =>
                                <span className={`step ${acc.accepted ? 'green' : acc.user_id === user.id ? 'yellow can-accept' : 'grey'}`}></span>
                            )
                        }

                        {
                            file.additionalAcceptance.length === 0 ? (
                                <span className={`step green`}></span>
                            ) : (
                                null
                            )
                        }
                    </span>
                    {this.getAcceptedByTooltip(file, tooltipId)}
                </>
            )
        } else if (!file.requiresAdditionalAcceptance && Array.isArray(file.additionalAcceptance)) {
            return (
                <>
                    <span className={`status`} data-for={`${tooltipId}-${file.fileId}`} data-tip='show'>
                        {
                            file.additionalAcceptance.map(acc =>
                                acc.accepted ? (
                                    <span className={`step green`}></span>
                                ) : null
                            )
                        }
                    </span>
                    {this.getAcceptedByTooltip(file, tooltipId)}
                </>
            )
        } else {
            return <span className={`status`}></span>
        }
    }

    openPaymentAssistant = projectId => {
        const { userProjects, currentProject } = this.props
        if (currentProject.id !== projectId) {
            this.props.switchDashboardViewModel(projectId)
            var { userCompanies, currentCompany } = this.props
            const newProject = userProjects.find(p => p.id === projectId)
            var companyId = newProject?.company_id || -1


            if (companyId !== -1 && currentCompany && currentCompany.id !== companyId) {
                var companyIndex = userCompanies.findIndex(c => c.company_id === companyId)
                if (companyIndex > -1) {
                    this.props.setCurrentCompany(userCompanies[companyIndex])
                }
            }
        }
        this.props.toggleQuickAction(availableQuickActions.bankTransfers, true)

        var defaultDateFrom = moment().subtract(1, 'month').startOf('month')
        var defaultDateTo = moment()
        this.props.getDocuments(defaultDateFrom, defaultDateTo, projectId, 'Costs', 'importDate', null, { allUnpaid: true })

        setTimeout(() => {
            this.setState({
                areUpcomingPaymentsCollapsed: false
            })
        }, 1000)
    }

    redirectToActions = () => {
        const { projectUpcomingPayments } = this.props
        var startDate = moment(), endDate = moment().endOf('month').set('hour', 23).set('minute', 59).set('second', 59)

        var oldestDocDate = null
        projectUpcomingPayments?.docs?.forEach(f => {
            if (!oldestDocDate || f.importDate < oldestDocDate) oldestDocDate = f.importDate
        })

        if (oldestDocDate) {
            startDate = moment(oldestDocDate, 'YYYY-MM-DD HH:mm')
            this.props.getDocuments(startDate, endDate, projectUpcomingPayments.project_id, 'Costs', 'importDate', null, { unpaid: true })
            history.push(routes.panel.actions + `?p=${projectUpcomingPayments.project_id}&d=${moment(startDate).format('MM-DD-YYYY')}&t=u`)
        }
    }

    render() {
        const {
            previewFile,
            docsListForPreview,
            areAdditionalPaymentAcceptanceDocsCollapsed, areUpcomingPaymentsCollapsed, areMentionedUnreadConversationsCollapsed,
            showSectionSettings,
        } = this.state
        const {
            user, tabIsActive,
            userProjects,
            waitingForDocsRequiringAttention,
            projectAdditionalPaymentAcceptanceDocs, projectUpcomingPayments, projectMentionedUnreadConversaons,
        } = this.props

        if (tabIsActive || !user.new_easydocs_version) {
            return (
                <div className={`dashboard-attention-docs ${showSectionSettings ? 'waiting-for-actions' : ''}`}>

                    {
                        previewFile && previewFile.id ? (
                            <FilesPreviewInReports
                                docsList={docsListForPreview}
                                location={{
                                    pathname: '/actions/preview/'
                                }}
                                previewFile={previewFile}
                                close={this.hideFilePreview}
                                setCurrentDoc={this.setCurrentDocPreview}
                                checkIfDocMatchesFilterOptions={() => true}
                            />
                        ) : (
                            null
                        )
                    }

                    <div className={`attention-docs-section ${areAdditionalPaymentAcceptanceDocsCollapsed ? 'collapsed' : ''}`}>
                        <h3 className="no-hover">
                            <span
                                className="section-icon additional-acceptance"
                                onClick={() => this.collapseSection('areAdditionalPaymentAcceptanceDocsCollapsed', !areAdditionalPaymentAcceptanceDocsCollapsed)}
                            ></span>
                            <span
                                className="text"
                                onClick={() => this.collapseSection('areAdditionalPaymentAcceptanceDocsCollapsed', !areAdditionalPaymentAcceptanceDocsCollapsed)}
                            >
                                {this.context.t('Documents requiring approval')}
                            </span>
                            <div
                                className={`collapse-icon ${areAdditionalPaymentAcceptanceDocsCollapsed ? 'collapsed' : ''}`}
                                onClick={() => this.collapseSection('areAdditionalPaymentAcceptanceDocsCollapsed', !areAdditionalPaymentAcceptanceDocsCollapsed)}
                            ></div>
                        </h3>
                        {
                            waitingForDocsRequiringAttention ? (
                                <div className="activity-indicator">
                                    <Bounce size={20} speed={0.8} color={"#BCBBBB"} />
                                </div>
                            ) : (
                                !areAdditionalPaymentAcceptanceDocsCollapsed ? (
                                    projectAdditionalPaymentAcceptanceDocs?.docs?.length ? (
                                        <div className="docs-list">
                                            <>
                                                <div className="doc-row header dropzone-doc">
                                                    <span
                                                        className="file-name"
                                                        onClick={() => this.props.setSortFieldForDocsRequiringAttention('recipient', projectAdditionalPaymentAcceptanceDocs.project_id, 'additionalPaymentAcceptanceDocs')}
                                                    >
                                                        {this.context.t('Counterparty')}
                                                        <span
                                                            className={this.getSortClass('recipient', projectAdditionalPaymentAcceptanceDocs.sortField, projectAdditionalPaymentAcceptanceDocs.sortOrder)}
                                                        ></span>
                                                    </span>
                                                    <span
                                                        className="doc-number"
                                                        onClick={() => this.props.setSortFieldForDocsRequiringAttention('dueDdocNumberate', projectAdditionalPaymentAcceptanceDocs.project_id, 'additionalPaymentAcceptanceDocs')}
                                                    >
                                                        {this.context.t('Doc number')}
                                                        <span
                                                            className={this.getSortClass('docNumber', projectAdditionalPaymentAcceptanceDocs.sortField, projectAdditionalPaymentAcceptanceDocs.sortOrder)}
                                                        ></span>
                                                    </span>
                                                    <span
                                                        className="date"
                                                        onClick={() => this.props.setSortFieldForDocsRequiringAttention('dueDate', projectAdditionalPaymentAcceptanceDocs.project_id, 'additionalPaymentAcceptanceDocs')}
                                                    >
                                                        {this.context.t('Due date')}
                                                        <span
                                                            className={this.getSortClass('dueDate', projectAdditionalPaymentAcceptanceDocs.sortField, projectAdditionalPaymentAcceptanceDocs.sortOrder)}
                                                        ></span>
                                                    </span>
                                                    <span
                                                        className="amount-to-pay"
                                                        onClick={() => this.props.setSortFieldForDocsRequiringAttention('amountToPay', projectAdditionalPaymentAcceptanceDocs.project_id, 'additionalPaymentAcceptanceDocs')}
                                                    >
                                                        {this.context.t('Amount to pay')}
                                                        <span
                                                            className={this.getSortClass('amountToPay', projectAdditionalPaymentAcceptanceDocs.sortField, projectAdditionalPaymentAcceptanceDocs.sortOrder)}
                                                        ></span>
                                                    </span>
                                                    <span
                                                        className="status"
                                                    >
                                                        {this.context.t('Status')}
                                                    </span>
                                                </div>
                                                <SimpleBar style={{ maxHeight: '190px', paddingRight: '10px', marginRight: '-10px' }}>
                                                    {
                                                        projectAdditionalPaymentAcceptanceDocs?.docs?.map((doc, docIndex) => {
                                                            return (
                                                                <div
                                                                    onClick={() => this.fileIconClick(doc.projectId, doc.filePath || doc.path, doc.fileId, 'DOC', projectAdditionalPaymentAcceptanceDocs.docs)}
                                                                    className={`doc-row acceptance-doc ${!projectAdditionalPaymentAcceptanceDocs.docs[docIndex + 1] ? 'last-from-group' : ''}`}
                                                                >
                                                                    <span className="file-name">{doc.recipient || doc.fileName}</span>
                                                                    <span className="doc-number">{doc.docNumber}</span>
                                                                    <span className={`date ${this.getDueDateCellClass(doc)}`}>{doc.dueDate}</span>
                                                                    <span className="amount-to-pay">{doc.amountToPay}</span>
                                                                    {this.getAcceptanceStatus(doc, 'attention-docs_acceptance', docIndex)}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </SimpleBar>
                                            </>
                                        </div>
                                    ) : (
                                        <div className="no-docs">
                                            <div className="text">{this.context.t('No documents require your attention')}</div>
                                        </div>
                                    )
                                ) : null
                            )
                        }
                    </div >
                    <div className={`attention-docs-section ${areUpcomingPaymentsCollapsed ? 'collapsed' : ''}`}>
                        <h3 className='no-hover'>
                            <span
                                className="section-icon upcomeing-payments"
                                onClick={() => this.collapseSection('areUpcomingPaymentsCollapsed', !areUpcomingPaymentsCollapsed)}
                            ></span>
                            <span
                                className="text"
                                onClick={() => this.collapseSection('areUpcomingPaymentsCollapsed', !areUpcomingPaymentsCollapsed)}
                            >
                                {this.context.t('Documents to be paid')}

                                {
                                    projectUpcomingPayments?.docs?.length ? (
                                        <>
                                            <div
                                                className="payment-button"
                                                onClick={() => this.openPaymentAssistant(projectUpcomingPayments.project_id)}
                                            >
                                                {this.context.t('Pay')}
                                            </div>
                                            <div
                                                className="payment-button actions"
                                                onClick={() => this.redirectToActions()}
                                            >
                                                {this.context.t('Actions')}
                                            </div>
                                        </>
                                    ) : null
                                }
                            </span>
                            <div
                                className={`collapse-icon ${areUpcomingPaymentsCollapsed ? 'collapsed' : ''}`}
                                onClick={() => this.collapseSection('areUpcomingPaymentsCollapsed', !areUpcomingPaymentsCollapsed)}
                            ></div>
                        </h3>
                        {
                            waitingForDocsRequiringAttention ? (
                                <div className="activity-indicator">
                                    <Bounce size={20} speed={0.8} color={"#BCBBBB"} />
                                </div>
                            ) : (
                                !areUpcomingPaymentsCollapsed ? (
                                    projectUpcomingPayments?.docs?.length ? (
                                        <div className="docs-list">
                                            <>
                                                <div className="doc-row header dropzone-doc">
                                                    <span
                                                        className="file-name"
                                                        onClick={() => this.props.setSortFieldForDocsRequiringAttention('receiver', projectUpcomingPayments.project_id, 'upcomingPayments')}
                                                    >
                                                        {this.context.t('Counterparty')}
                                                        <span
                                                            className={this.getSortClass('receiver', projectUpcomingPayments.sortField, projectUpcomingPayments.sortOrder)}
                                                        ></span>
                                                    </span>
                                                    <span
                                                        className="doc-number"
                                                        onClick={() => this.props.setSortFieldForDocsRequiringAttention('docNumber', projectUpcomingPayments.project_id, 'upcomingPayments')}
                                                    >
                                                        {this.context.t('Doc number')}
                                                        <span
                                                            className={this.getSortClass('docNumber', projectUpcomingPayments.sortField, projectUpcomingPayments.sortOrder)}
                                                        ></span>
                                                    </span>
                                                    <span
                                                        className="date"
                                                        onClick={() => this.props.setSortFieldForDocsRequiringAttention('dueDate', projectUpcomingPayments.project_id, 'upcomingPayments')}
                                                    >
                                                        {this.context.t('Due date')}
                                                        <span
                                                            className={this.getSortClass('dueDate', projectUpcomingPayments.sortField, projectUpcomingPayments.sortOrder)}
                                                        ></span>
                                                    </span>
                                                    <span
                                                        className="amount-to-pay"
                                                        onClick={() => this.props.setSortFieldForDocsRequiringAttention('amountToPay', projectUpcomingPayments.project_id, 'upcomingPayments')}
                                                    >
                                                        {this.context.t('Amount to pay')}
                                                        <span
                                                            className={this.getSortClass('amountToPay', projectUpcomingPayments.sortField, projectUpcomingPayments.sortOrder)}
                                                        ></span>
                                                    </span>
                                                    {
                                                        projectUpcomingPayments.projectHasAdditionalAcceptance ? (
                                                            <span
                                                                className="status"
                                                            >
                                                                {this.context.t('Status')}
                                                            </span>
                                                        ) : null
                                                    }
                                                </div>
                                                <SimpleBar style={{ maxHeight: '190px', paddingRight: '10px', marginRight: '-10px' }}>
                                                    {
                                                        projectUpcomingPayments?.docs?.map((doc, docIndex) => {
                                                            return (
                                                                <div
                                                                    onClick={() => this.fileIconClick(doc.projectId, doc.filePath || doc.path, doc.fileId, doc.type, projectUpcomingPayments.docs)}
                                                                    className={`doc-row acceptance-doc ${!projectUpcomingPayments.docs[docIndex + 1] ? 'last-from-group' : ''}`}
                                                                >
                                                                    <span className="file-name">{doc.receiver || doc.fileName}</span>
                                                                    <span className="doc-number">{doc.docNumber}</span>
                                                                    <span className={`date ${this.getDueDateCellClass(doc)}`}>{doc.dueDate}</span>
                                                                    <span className="amount-to-pay">{`${doc.amountToPay} ${doc.currency}`}</span>
                                                                    {
                                                                        projectUpcomingPayments.projectHasAdditionalAcceptance ? (
                                                                            this.getAcceptanceStatus(doc, 'attention-docs_unpaid', docIndex)
                                                                        ) : null
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </SimpleBar>
                                            </>
                                        </div>
                                    ) : (
                                        <div className="no-docs">
                                            <div className="text">{this.context.t('No documents require your attention')}</div>
                                        </div>
                                    )
                                ) : null
                            )
                        }
                    </div>
                    <div className={`attention-docs-section ${areMentionedUnreadConversationsCollapsed ? 'collapsed' : ''}`}>
                        <h3 className="no-hover">
                            <span
                                className="section-icon chat-mentions"
                                onClick={() => this.collapseSection('areMentionedUnreadConversationsCollapsed', !areMentionedUnreadConversationsCollapsed)}
                            ></span>
                            <span
                                className="text"
                                onClick={() => this.collapseSection('areMentionedUnreadConversationsCollapsed', !areMentionedUnreadConversationsCollapsed)}
                            >
                                {this.context.t('Unread conversations you\'re tagged in')}
                            </span>
                            <div
                                className={`collapse-icon ${areMentionedUnreadConversationsCollapsed ? 'collapsed' : ''}`}
                                onClick={() => this.collapseSection('areMentionedUnreadConversationsCollapsed', !areMentionedUnreadConversationsCollapsed)}
                            ></div>
                        </h3>
                        {
                            waitingForDocsRequiringAttention ? (
                                <div className="activity-indicator">
                                    <Bounce size={20} speed={0.8} color={"#BCBBBB"} />
                                </div>
                            ) : (
                                !areMentionedUnreadConversationsCollapsed ? (
                                    projectMentionedUnreadConversaons?.docs?.length ? (
                                        <div className="docs-list">
                                            <>
                                                <div className="doc-row header dropzone-doc">
                                                    <span
                                                        className="file-name"
                                                        onClick={() => this.props.setSortFieldForDocsRequiringAttention('fileName', projectMentionedUnreadConversaons.project_id, 'mentionedUnreadConversaons')}
                                                    >
                                                        {this.context.t('File name')}
                                                        <span
                                                            className={this.getSortClass('fileName', projectMentionedUnreadConversaons.sortField, projectMentionedUnreadConversaons.sortOrder)}
                                                        ></span>
                                                    </span>
                                                    <span
                                                        className="date"
                                                        onClick={() => this.props.setSortFieldForDocsRequiringAttention('lastMessageDate', projectMentionedUnreadConversaons.project_id, 'mentionedUnreadConversaons')}
                                                    >
                                                        {this.context.t('Date')}
                                                        <span
                                                            className={this.getSortClass('lastMessageDate', projectMentionedUnreadConversaons.sortField, projectMentionedUnreadConversaons.sortOrder)}
                                                        ></span>
                                                    </span>
                                                </div>
                                                <SimpleBar style={{ maxHeight: '190px', paddingRight: '10px', marginRight: '-10px' }}>
                                                    {
                                                        projectMentionedUnreadConversaons?.docs?.map((doc, docIndex) => {
                                                            if (doc.acceptanceStep < 3) {
                                                                return (
                                                                    <Link
                                                                        to={reverse(routes.panel.crop, { projectId: doc.projectId, fileId: doc.fileId })}
                                                                        className={`doc-row acceptance-doc ${!projectMentionedUnreadConversaons.docs[docIndex + 1] ? 'last-from-group' : ''}`}
                                                                    >
                                                                        <span className="file-name">{doc.fileName}</span>
                                                                        <span className="date">{doc.lastMessageDate}</span>
                                                                        <span className="message"><strong>{doc.lastMessageAuthor}</strong>: {doc.lastMessage}</span>
                                                                    </Link>
                                                                )
                                                            } else {
                                                                return (
                                                                    <div
                                                                        onClick={() => this.fileIconClick(doc.projectId, doc.filePath || doc.path, doc.fileId, 'DOC', projectMentionedUnreadConversaons.docs)}
                                                                        className={`doc-row acceptance-doc ${!projectMentionedUnreadConversaons.docs[docIndex + 1] ? 'last-from-group' : ''}`}
                                                                    >
                                                                        <span className="file-name">{doc.fileName}</span>
                                                                        <span className="date">{doc.lastMessageDate}</span>
                                                                        <span className="message"><strong>{doc.lastMessageAuthor}</strong>: {doc.lastMessage}</span>
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </SimpleBar>
                                            </>
                                        </div>
                                    ) : (
                                        <div className="no-docs">
                                            <div className="text">{this.context.t('No documents require your attention')}</div>
                                        </div>
                                    )
                                ) : null
                            )

                        }
                    </div>
                </div >
            )
        } else {
            return null
        }
    }
}

ProjectAttentionDocs.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
    user: state.User.user,
    waitingForDocsRequiringAttention: state.QuickActions.waitingForDocsRequiringAttention,
    attentionDocsProjectId: state.QuickActions.projectId,
    projectAdditionalPaymentAcceptanceDocs: { ...state.QuickActions.projectAdditionalPaymentAcceptanceDocs },
    projectUpcomingPayments: { ...state.QuickActions.projectUpcomingPayments },
    projectMentionedUnreadConversaons: { ...state.QuickActions.projectMentionedUnreadConversaons },
    currentProject: state.User.currentProject,
    userProjects: isIterable(state.User.userProjects) ? [...state.User.userProjects] : [],
    userCompanies: state.Company.companies,
    currentCompany: state.Company.currentCompany,

    transfers: isIterable(state.Transfers.transfers) ? [...state.Transfers.transfers] : [],
})

const mapDispatchToProps = {
    toogleFilePreviewInActions: FileActions.toogleFilePreviewInActions,
    getFileBody: FileActions.getFileData,
    getPaymentOrders: TransfersActions.getAllMyTransfers,
    openTransferPreviewWindow: TransfersActions.openTransferPreviewWindow,

    toggleQuickAction: UserActions.toggleQuickAction,
    collapseDocsRequiringAttentionsProjectGroup: UserActions.collapseDocsRequiringAttentionsProjectGroup,
    setSortFieldForDocsRequiringAttention: UserActions.setSortFieldForDocsRequiringAttention,
    toggleNotificationSettingForProject: UserActions.toggleNotificationSettingForProject,
    getDocsRequiringMyAttention: UserActions.getDocsRequiringMyAttention,

    toggleFavouriteForProject: UserActions.toggleFavouriteForProject,
    switchDashboardViewModel: UserActions.switchDashboardViewModel,
    setCurrentCompany: CompanyActions.setCurrentCompany,

    getDocuments: ReportsActions.getDocuments,

    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAttentionDocs)