const plVatCheck = (vatNumber) => {
    if (vatNumber.length === 10) {
        var controlSum = parseInt(vatNumber[0]) * 6 +
            parseInt(vatNumber[1]) * 5 +
            parseInt(vatNumber[2]) * 7 +
            parseInt(vatNumber[3]) * 2 +
            parseInt(vatNumber[4]) * 3 +
            parseInt(vatNumber[5]) * 4 +
            parseInt(vatNumber[6]) * 5 +
            parseInt(vatNumber[7]) * 6 +
            parseInt(vatNumber[8]) * 7

        if (controlSum % 11 === parseInt(vatNumber[9])) return true
        return false
    } else return false
}

const deVatCheck = (vatNumber) => {
    if (vatNumber.length === 9) {
        const m = 10, n = 11
        const controlNumber = Number(vatNumber.substring(vatNumber.length - 1))
        const numbersWithoutCheckDigit = vatNumber.substring(0, vatNumber.length - 1)
        const arrayNumber = [...numbersWithoutCheckDigit]
        let product = m
        arrayNumber.forEach(number => {
            number = Number(number)
            let sum = (number + product) % m
            if (sum === 0) sum = m
            product = (2 * sum) % n
        })
        var submit = n - product
        if (submit === 10) submit = 0
        return submit === controlNumber ? true : false
    } else return false
}

export const vatIdValidate = (vatNumber) => {
    const firstTwoLetter = /^[A-Z]{2}/
    vatNumber = vatNumber.toUpperCase()
    vatNumber = vatNumber.replaceAll(' ', '')

    if (firstTwoLetter.test(vatNumber)) {
        if (vatNumber.startsWith('PL')) return plVatCheck(vatNumber.substring(2))
        if (vatNumber.startsWith('DE')) return deVatCheck(vatNumber.substring(2))
        return true
    } else return plVatCheck(vatNumber)
}