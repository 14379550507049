import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

export class DeleteTransferModal extends Component {

    render() {
        return (
            <div className="delete-invoice-popup popup">
                <div className="popup-card">
                    <div className="header">{this.context.t('Are You sure You want to remove this payment order?')}</div>
                    <div className="action-buttons">
                        <button className="btn" onClick={e => this.props.cancel()}>{this.context.t('Cancel')}</button>
                        <button className="btn delete" onClick={e => this.props.delete()}>{this.context.t('Delete')}</button>
                    </div>
                </div>
            </div>
        )
    }
}

DeleteTransferModal.contextTypes = {
	t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteTransferModal)