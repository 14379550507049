import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { CompanyActions } from "../../actions/CompanyActions";

import "../../../src/css/SalesPage.css";

export class DeleteDocItemPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cursorWait: false
        };
    }

    deleteItem = () => {
        const { itemToDelete, currentCompany } = this.props
        this.setState({
            cursorWait: true
        })
        this.props.deleteDocItem(currentCompany.company_id, itemToDelete.id, success => {
            this.setState({
                cursorWait: false
            })

            if (success) {
                this.props.deletedDocsItem(itemToDelete.id)
                this.props.closeDeleteItemPopup()
            }
        })
    }

    render() {
        const { cursorWait } = this.state
        const { itemToDelete } = this.props
        return (
            <div className={`delete-items-popup ${cursorWait ? 'cursor-wait' : ''}`}>
                <div className="delete-items-window">
                    <div className="main-text-items">{this.context.t("Do you confirm removal of this product/service:")}</div>
                    <div className="item-to-delet">{itemToDelete.name}</div>
                    <div className="action-buttons">
                        <div className="cancel-button" onClick={() => this.props.closeDeleteItemPopup()}>{this.context.t("Cancel")}</div>
                        <div className="delete-button" onClick={() => this.deleteItem()}>{this.context.t("Delete")}</div>
                    </div>
                </div>
            </div>
        )
    }
}

DeleteDocItemPopup.contextTypes = {
    t: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
    currentCompany: state.Company.currentCompany,
});

const mapDispatchToProps = {
    deleteDocItem: CompanyActions.deleteDocItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDocItemPopup);
