import React, { Component } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

import { CompanyActions } from '../../actions/CompanyActions'
import { ReportsActions } from '../../actions/ReportsActions'
import { FileActions } from '../../actions/FileActions'
import { TransfersActions } from '../../actions/TransfersActions'

import arrayObjectIndexOf from "../../helpers/indexOfObject"
import { history } from "../../helpers/history"
import routes from "../../helpers/routes"
import FilesPreviewControlerForUnpaidDocs from './DocsAwaitingPaymentComponents/FilesPreviewControlerForUnpaidDocs'
import PaymentOrdersPreviewControlerForUnpaidDocs from './DocsAwaitingPaymentComponents/PaymentOrdersPreviewControlerForUnpaidDocs'

import '../../css/DocsAwaitingPayment.css'
import { times } from 'lodash'

export class DocsAwaitingPayment extends Component {

    constructor(props) {
        super(props)
        this.state = {
            docsProjectIndex: -1,
            numberOfDocsToAccept: null,
            userCanAcceptAnyDocs: false,
            previewFile: null,
            unpaidDocsCount: 0,
            showAllUnpaidDocs: false,
            paymentOrdersProjectIndex: -1,
            unpaidPaymentOrdersCount: 0,
            showAllUnpaidPaymentOrders: false,
            previewPaymentOrder: null,
            shouldUpdateIndex: false
        }
    }

    componentDidMount() {
        const { uploadedFiles, projectId, transfers } = this.props
        if (uploadedFiles && projectId) {
            var projectIndex = arrayObjectIndexOf(uploadedFiles, projectId, "project")
            this.setState({
                docsProjectIndex: projectIndex
            })

            if (projectIndex > -1) {
                var docsCount = uploadedFiles[projectIndex].files.length
                var unpaidDocsCount = 0
                for (var i = 0; i < docsCount; i++) {
                    if (this.checkIfDosIsNotPaid(uploadedFiles[projectIndex].files[i])) unpaidDocsCount++
                }

                this.setState({
                    unpaidDocsCount: unpaidDocsCount
                })
            }

            projectIndex = arrayObjectIndexOf(transfers, projectId, "project")
            this.setState({
                paymentOrdersProjectIndex: projectIndex
            })

            if (projectIndex > -1) {
                var docsCount = transfers[projectIndex].transfers.length
                unpaidDocsCount = 0
                for (var i = 0; i < docsCount; i++) {
                    if (this.checkIfPaymentOrderIsNotPaid(transfers[projectIndex].transfers[i])) unpaidDocsCount++
                }

                this.setState({
                    unpaidPaymentOrdersCount: unpaidDocsCount
                })
            }
        }
    }

    componentDidUpdate(prevProps) {
        ReactTooltip.rebuild()

        const { uploadedFiles, transfers, projectId } = this.props
        const { docsProjectIndex, paymentOrdersProjectIndex, shouldUpdateIndex } = this.state

        if (uploadedFiles && transfers && projectId) {

            if ((docsProjectIndex === -1 || paymentOrdersProjectIndex === -1 || projectId !== prevProps.projectId) && !shouldUpdateIndex) {
                this.setState({
                    shouldUpdateIndex: true
                })
            } else {
                if (docsProjectIndex > -1 && !shouldUpdateIndex) {
                    if (uploadedFiles[docsProjectIndex] && uploadedFiles[docsProjectIndex].project !== projectId) {
                        this.setState({
                            shouldUpdateIndex: true
                        })
                    }
                } else if (paymentOrdersProjectIndex > -1 && !shouldUpdateIndex) {
                    if (transfers[paymentOrdersProjectIndex] && transfers[paymentOrdersProjectIndex].project !== projectId) {
                        this.setState({
                            shouldUpdateIndex: true
                        })
                    }
                }
            }

            if (shouldUpdateIndex && uploadedFiles.find(p => p.project === projectId) && transfers.find(p => p.project === projectId)) {
                this.updateCountsForNewProjects()
            }
        }

        //if(this.state.projectIndex > -1 && this.state.numberOfDocsToAccept === null) this.checkIfUserCanAcceptAnyDocs()
    }

    updateCountsForNewProjects = () => {
        const { uploadedFiles, transfers, projectId } = this.props
        var filesProjectIndex = arrayObjectIndexOf(uploadedFiles, projectId, "project")
        var transfersProjectIndex = arrayObjectIndexOf(transfers, projectId, "project")
        var docsCount = uploadedFiles[filesProjectIndex].files.length
        var unpaidDocsCount = 0
        for (var i = 0; i < docsCount; i++) {
            if (this.checkIfDosIsNotPaid(uploadedFiles[filesProjectIndex].files[i])) unpaidDocsCount++
        }
        var paymentOrdersCount = transfers[transfersProjectIndex].transfers.length
        var unpaidPaymentOrdersCount = 0
        for (var i = 0; i < paymentOrdersCount; i++) {
            if (this.checkIfPaymentOrderIsNotPaid(transfers[transfersProjectIndex].transfers[i])) unpaidPaymentOrdersCount++
        }
        this.setState({
            docsProjectIndex: filesProjectIndex,
            unpaidDocsCount: unpaidDocsCount,
            paymentOrdersProjectIndex: transfersProjectIndex,
            unpaidPaymentOrdersCount: unpaidPaymentOrdersCount,
            shouldUpdateIndex: false
        })
    }

    hasAtLeastOneUnpaidDoc = () => {
        const { uploadedFiles, projectId } = this.props
        const { docsProjectIndex } = this.state

        if (docsProjectIndex > -1) {
            var docsCount = uploadedFiles[docsProjectIndex].files.length
            var unpaidCount
            for (var i = 0; i < docsCount; i++) {
                if (this.checkIfDosIsNotPaid(uploadedFiles[docsProjectIndex].files[i])) return true
            }
        }

        return false
    }

    checkIfDosIsNotPaid = (file) => {
        if (file && file.accepted_by && file.ocr_status !== 'DONE' && file.path && file.path.includes('Costs/')) return true
    }

    checkIfPaymentOrderIsNotPaid = (file) => {
        if (file && !file.payment_method && !file.paid_by) return true
    }

    drawIcon = (name, type = 'DOC') => {
        var extension = name.split('.')
        extension = extension[extension.length - 1]

        if (type === 'PAYMENT-ORDER') {
            return <div className="icon payment-order-icon"></div>
        } else if (extension === 'pdf' || extension === 'PDF') {
            return <div className="icon pdf-icon"></div>
        } else {
            return <div className="icon image-icon"></div>
        }
    }

    checkIfRefund = file => {
        if (file.amount_to_pay) {
            var amount = parseFloat(file.amount_to_pay.replace(/[^0-9.,-]/))
            if (amount < 0) {
                return 'refund'
            } else {
                return ''
            }
        } else {
            return ''
        }
    }

    formatPrice = (amount, currency) => {
        amount = parseFloat(amount).toFixed(2)
        if (amount.indexOf('.') > -1) {
            var parts = amount.split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            amount = parts.join('.')
        } else {
            amount = amount.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }

        return `${amount} ${currency}`
    }

    checkIfOverdue = file => {
        if (file.due_date) {
            return moment(file.due_date).format('DD-MM-YYYY') < moment() ? 'overdue' : ''
        } else {
            return ''
        }
    }

    checkIfNeedsSecondAcceptance = file => {
        const { project, user } = this.props
        if (file.amount_to_pay) {
            var amount = parseFloat(file.amount_to_pay.replace(/[^0-9.,-]/))
            var treshold = project.additional_acceptance_treshold
            if (!file.amount_to_pay.includes('PLN')) treshold = project.additional_acceptance_treshold_eur

            if (amount >= treshold && project.has_additional_docs_acceptance_over_treshold) return true
            if (amount <= treshold && project.has_additional_docs_acceptance_under_treshold) return true
        }

        return false
    }

    checkIfUserCanAccept = file => {
        const { project, user } = this.props
        if (file.amount_to_pay) {
            var amount = parseFloat(file.amount_to_pay.replace(/[^0-9.,-]/))
            var treshold = project.additional_acceptance_treshold
            if (!file.amount_to_pay.includes('PLN')) treshold = project.additional_acceptance_treshold_eur

            if (amount >= treshold && project.has_additional_docs_acceptance_over_treshold && file.accepted_by !== user.id && project.users_allowed_to_accept_over_treshold.includes(user.id)) {
                return true
            } else if (amount <= treshold && project.has_additional_docs_acceptance_under_treshold && file.accepted_by !== user.id && project.users_allowed_to_accept_under_treshold.includes(user.id)) {
                return true
            }
        }
        return false
    }

    checkIfUserCanAcceptAnyDocs = () => {
        const { project, user, uploadedFiles } = this.props
        const { docsProjectIndex } = this.state

        var canAccept = false
        var numberOfDocsToAccept = 0
        if (docsProjectIndex > -1 && uploadedFiles[docsProjectIndex].sendRequest && uploadedFiles[docsProjectIndex].loaded && uploadedFiles[docsProjectIndex].files.length > 0) {
            uploadedFiles[docsProjectIndex].files.forEach(f => {
                if (this.checkIfNeedsSecondAcceptance(f) && this.checkIfUserCanAccept(f)) {
                    numberOfDocsToAccept += 1
                    canAccept = true
                }
            })
        }

        this.setState({
            numberOfDocsToAccept: numberOfDocsToAccept,
            userCanAcceptAnyDocs: canAccept
        })
    }

    showOnlyDocsThatUserCanAccapt = () => {
        this.setState({
            showOnlyDocsThatUserCanAccapt: true
        })
    }

    setCurrentDocPreview = (doc) => {
        this.setState({
            previewFile: doc
        })
    }

    hideFilePreview = () => {
        console.log('hideFilePreview DocsAwaitingPayment')
        this.props.tooglePreviewForUnpaidDocs(false)
        this.setState({
            previewFile: null
        })
    }

    onFileClick = file => {
        console.log('onFileClick')
        this.props.tooglePreviewForUnpaidDocs(true)
        this.setState({
            previewFile: {
                id: file.id
            }
        })

        this.props.getFileBody(file.project_id, file.path, file.id, file.ocr_status, file.acceptance_step)
    }

    setCurrentPaymentOrderPreview = (doc) => {
        this.setState({
            previewPaymentOrder: doc
        })
    }

    hidePaymentOrderPreview = () => {
        console.log('hidePaymentOrderPreview DocsAwaitingPayment')
        this.setState({
            previewPaymentOrder: null
        })
    }

    onPaymentOrderClick = file => {
        console.log('onPaymentOrderClick')
        this.setState({
            previewPaymentOrder: {
                id: file.id
            }
        })

        this.props.openTransferPreviewWindow(file)
    }


    getAcceptedByTooltip = (file, project, docsProjectIndex, fileIndex) => {
        const { user } = this.props
        if (project.has_additional_docs_acceptance && file.additionalAcceptance && Array.isArray(file.additionalAcceptance)) {
            return (
                <ReactTooltip id={`unpaid-doc-status-${docsProjectIndex}-${fileIndex}`} place="left" effect="solid" className="default-tooltip unpaid-doc-status-tooltip">
                    <h3>{this.context.t('Accepted by:')}</h3>
                    <ol>
                        {!file.additionalAcceptance.find(e => e.id === file.accepted_by) && file.acceptedBy ? (
                            <li>
                                {`${file.acceptedBy.first_name} ${file.acceptedBy.last_name} ${file.accepted_by === user.id ? this.context.t(' (You)') : ''}`}
                            </li>
                        ) : (
                            null
                        )}
                        {
                            file.additionalAcceptance.map(acc => {
                                if (acc.accepted)
                                    return (
                                        <li>
                                            {`${acc.user?.first_name} ${acc.user?.last_name} ${acc.user_id === user.id ? this.context.t(' (You)') : ''}`}
                                        </li>
                                    )

                                return null
                            })
                        }
                    </ol>

                    {
                        file.requires_additional_acceptance ? (
                            <>
                                {
                                    file.additional_acceptances_required >= 1 ? (
                                        <h3 className="waiting">{this.context.t('Requires acceptance ({n}):', { n: file.additional_acceptances_required - file.additional_acceptances_received })}</h3>
                                    ) : (
                                        <h3 className="waiting">{this.context.t('Requires acceptance:')}</h3>
                                    )
                                }

                                <ol>
                                    {
                                        file.additionalAcceptance.map(acc => {
                                            if (!acc.accepted)
                                                return (
                                                    <li>
                                                        {`${acc.user?.first_name} ${acc.user?.last_name} ${acc.user_id === user.id ? this.context.t(' (You)') : ''}`}
                                                    </li>
                                                )

                                            return null
                                        })
                                    }
                                </ol>
                            </>
                        ) : (
                            null
                        )
                    }
                </ReactTooltip>
            )
        } else {
            return null
        }
    }

    getAcceptanceStatus = (user, file, project, docsProjectIndex, fileIndex) => {
        console.log(file)
        console.log(project)
        if (project.has_additional_docs_acceptance && file.requires_additional_acceptance && Array.isArray(file.additionalAcceptance)) {
            return (
                <>
                    <div className={`status`} data-for={`unpaid-doc-status-${docsProjectIndex}-${fileIndex}`} data-tip='show'>
                        {
                            file.additionalAcceptance.map(acc =>
                                <span className={`step ${acc.accepted ? 'green' : acc.user_id === user.id ? 'yellow can-accept' : 'grey'}`}></span>
                            )
                        }

                        {
                            file.additionalAcceptance.length === 0 ? (
                                <span className={`step green`}></span>
                            ) : (
                                null
                            )
                        }
                    </div>

                    {this.getAcceptedByTooltip(file, project, docsProjectIndex, fileIndex)}
                </>
            )
        } else if (project.has_additional_docs_acceptance && !file.requires_additional_acceptance && Array.isArray(file.additionalAcceptance)) {
            return (
                <>
                    <div className={`status`} data-for={`unpaid-doc-status-${docsProjectIndex}-${fileIndex}`} data-tip='show'>
                        {
                            file.additionalAcceptance.map(acc =>
                                acc.accepted ? (
                                    <span className={`step green`}></span>
                                ) : null
                            )
                        }
                    </div>

                    {this.getAcceptedByTooltip(file, project, docsProjectIndex, fileIndex)}
                </>
            )
        } else {
            if (project.has_additional_docs_acceptance) {
                return <div className={`status`}></div>
            } else {
                return null
            }
        }
    }

    redirectToReports = () => {
        const { uploadedFiles, project } = this.props
        const { docsProjectIndex } = this.state
        if (docsProjectIndex > -1 && uploadedFiles[docsProjectIndex].sendRequest && uploadedFiles[docsProjectIndex].loaded) {
            var startDate = moment(), endDate = moment().endOf('month').set('hour', 23).set('minute', 59).set('second', 59)

            var oldestDocDate = null
            uploadedFiles[docsProjectIndex].files.forEach(f => {
                if (this.checkIfDosIsNotPaid(f)) {
                    if (!oldestDocDate || f.created < oldestDocDate) oldestDocDate = f.created
                }
            })

            if (oldestDocDate) {
                startDate = moment(oldestDocDate)
                this.props.getDocuments(startDate, endDate, project.id, 'Costs', 'importDate', null, { unpaid: true })
                history.push(routes.panel.actions + `?p=${project.id}&d=${moment(startDate).format('MM-DD-YYYY')}&t=u`)
            }
        }
    }

    render() {
        const { docsProjectIndex, paymentOrdersProjectIndex, numberOfDocsToAccept, userCanAcceptAnyDocs, showOnlyDocsThatUserCanAccapt, previewFile, unpaidDocsCount, showAllUnpaidDocs, unpaidPaymentOrdersCount, showAllUnpaidPaymentOrders, previewPaymentOrder } = this.state
        const { uploadedFiles, transfers, project, user, dashboardViewModel } = this.props


        var displayedDocs = 0
        var displayedPaymentOrders = 0
        if (docsProjectIndex > -1 && uploadedFiles[docsProjectIndex].sendRequest && uploadedFiles[docsProjectIndex].loaded) {
            return (
                <div className={`unpaid-docs-container ${dashboardViewModel === 'MULTI' ? 'multi-view' : ''}`}>
                    {
                        previewFile ? (
                            <FilesPreviewControlerForUnpaidDocs previewFile={previewFile} project={project} files={uploadedFiles[docsProjectIndex] ? uploadedFiles[docsProjectIndex].files : []} close={this.hideFilePreview} setCurrentDoc={this.setCurrentDocPreview} />
                        ) : (
                            previewPaymentOrder ? (
                                <PaymentOrdersPreviewControlerForUnpaidDocs previewFile={previewPaymentOrder} project={project} files={transfers[docsProjectIndex] ? transfers[docsProjectIndex].transfers : []} close={this.hidePaymentOrderPreview} setCurrentDoc={this.setCurrentPaymentOrderPreview} />
                            ) : (
                                null
                            )
                        )
                    }

                    <h2 onClick={() => this.redirectToReports()}>
                        {/* {this.context.t('Payments to settle')} */}
                        {
                            userCanAcceptAnyDocs ? (
                                <div className="marker" onClick={this.showOnlyDocsThatUserCanAccapt()}>{this.context.t('- You can accept {n} docs', { n: numberOfDocsToAccept })}</div>
                            ) : (
                                null
                            )
                        }
                    </h2>
                    {
                        unpaidDocsCount || unpaidPaymentOrdersCount ? (
                            <div className="file-row header-row">
                                <div className={`name ${!(project.has_additional_docs_acceptance_over_treshold || project.has_additional_docs_acceptance_under_treshold) ? 'no-accaptance' : ''}`}>
                                    {this.context.t('Name')}
                                </div>
                                <div className={`due-date ${!(project.has_additional_docs_acceptance_over_treshold || project.has_additional_docs_acceptance_under_treshold) ? 'no-accaptance' : ''}`}>
                                    {this.context.t('Due date')}
                                </div>
                                <div className={`amount-to-pay ${!(project.has_additional_docs_acceptance_over_treshold || project.has_additional_docs_acceptance_under_treshold) ? 'no-accaptance' : ''}`}>
                                    {this.context.t('Amount to pay')}
                                </div>
                                {
                                    (project.has_additional_docs_acceptance_over_treshold || project.has_additional_docs_acceptance_under_treshold) ? (
                                        <div className="status">
                                            {this.context.t('Acceptation status')}
                                        </div>
                                    ) : (
                                        null
                                    )
                                }
                            </div>
                        ) : (
                            null
                        )
                    }

                    {/* height: simpleBarHeight */}
                    <SimpleBar style={{ width: '100%', maxHeight: 'calc(50vh - 72px)', minHeight: '150px', paddingRight: '10px' }}>
                        {
                            unpaidDocsCount ? (
                                <>
                                    <div className="payment-type-header">{this.context.t('Docs:')}</div>
                                    {
                                        uploadedFiles[docsProjectIndex].files.map((file, i) => {
                                            if ((this.checkIfDosIsNotPaid(file) && displayedDocs < 10) || (this.checkIfDosIsNotPaid(file) && showAllUnpaidDocs)) {
                                                displayedDocs++
                                                return (
                                                    <div className="file-row" key={i} onClick={() => { this.onFileClick(file) }}>
                                                        {this.drawIcon(file.name)}
                                                        <div className={`name ${!(project.has_additional_docs_acceptance_over_treshold || project.has_additional_docs_acceptance_under_treshold) ? 'no-accaptance' : ''}`}>
                                                            {file.name}
                                                        </div>
                                                        <div className={`due-date ${!(project.has_additional_docs_acceptance_over_treshold || project.has_additional_docs_acceptance_under_treshold) ? 'no-accaptance' : ''} ${this.checkIfOverdue(file)}`}>
                                                            {file.due_date}
                                                        </div>
                                                        <div className={`amount-to-pay ${!(project.has_additional_docs_acceptance_over_treshold || project.has_additional_docs_acceptance_under_treshold) ? 'no-accaptance' : ''} ${this.checkIfRefund(file)}`}>
                                                            {file.amount_to_pay}
                                                        </div>
                                                        {this.getAcceptanceStatus(user, file, project, docsProjectIndex, i)}
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                    {
                                        unpaidDocsCount > 10 && !showAllUnpaidDocs ? (
                                            <div className="show-all-docs" onClick={() => this.setState({ showAllUnpaidDocs: !showAllUnpaidDocs })}>
                                                {this.context.t('Show more unpaid documents: +{n}', { n: unpaidDocsCount - 10 })}
                                            </div>
                                        ) : (
                                            unpaidDocsCount > 10 && showAllUnpaidDocs ? (
                                                <div className="show-all-docs" onClick={() => this.setState({ showAllUnpaidDocs: !showAllUnpaidDocs })}>
                                                    {this.context.t('Show 10 most recent unpaid documents')}
                                                </div>
                                            ) : (
                                                null
                                            )
                                        )
                                    }
                                </>
                            ) : (
                                null
                            )
                        }
                        {
                            unpaidPaymentOrdersCount ? (
                                <>
                                    <div className="payment-type-header">{this.context.t('Payment orders:')}</div>
                                    {
                                        transfers[paymentOrdersProjectIndex].transfers.map((file, i) => {
                                            if ((this.checkIfPaymentOrderIsNotPaid(file) && displayedPaymentOrders < 10) || (this.checkIfPaymentOrderIsNotPaid(file) && showAllUnpaidPaymentOrders)) {
                                                displayedPaymentOrders++
                                                return (
                                                    <div className="file-row" key={i} onClick={() => { this.onPaymentOrderClick(file) }}>
                                                        {this.drawIcon(file.name, 'PAYMENT-ORDER')}
                                                        <div className={`name ${!(project.has_additional_docs_acceptance_over_treshold || project.has_additional_docs_acceptance_under_treshold) ? 'no-accaptance' : ''}`}>
                                                            {file.name.split('/').pop()}
                                                        </div>
                                                        <div className={`due-date ${!(project.has_additional_docs_acceptance_over_treshold || project.has_additional_docs_acceptance_under_treshold) ? 'no-accaptance' : ''} ${this.checkIfOverdue(file)}`}>
                                                            {moment(file.due_date).format('YYYY-MM-DD')}
                                                        </div>
                                                        <div className={`amount-to-pay ${!(project.has_additional_docs_acceptance_over_treshold || project.has_additional_docs_acceptance_under_treshold) ? 'no-accaptance' : ''} ${this.checkIfRefund(file)}`}>
                                                            {this.formatPrice(file.amount_to_pay, file.currency)}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                    {
                                        unpaidPaymentOrdersCount > 10 && !showAllUnpaidPaymentOrders ? (
                                            <div className="show-all-docs" onClick={() => this.setState({ showAllUnpaidPaymentOrders: !showAllUnpaidPaymentOrders })}>
                                                {this.context.t('Show more unpaid payment orders: +{n}', { n: unpaidPaymentOrdersCount - 10 })}
                                            </div>
                                        ) : (
                                            unpaidDocsCount > 10 && showAllUnpaidPaymentOrders ? (
                                                <div className="show-all-docs" onClick={() => this.setState({ showAllUnpaidPaymentOrders: !showAllUnpaidPaymentOrders })}>
                                                    {this.context.t('Show 10 most recent unpaid payment orders')}
                                                </div>
                                            ) : (
                                                null
                                            )
                                        )
                                    }
                                </>
                            ) : (
                                null
                            )
                        }
                        {
                            !unpaidDocsCount && !unpaidPaymentOrdersCount ? (
                                <div className="no-files-indicator">
                                    <div className="image no-uploaded-files"></div>
                                    <div className="text">{this.context.t("It looks like you don't have any unpaid docs")}</div>
                                </div>
                            ) : (
                                null
                            )
                        }
                    </SimpleBar>

                </div>
            )
        } else {
            return null
        }
    }
}


DocsAwaitingPayment.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
    user: state.User.user,
    dashboardViewModel: state.User.dashboardViewModel,
    uploadedFiles: [...state.File.uploadedFiles],
    transfers: [...state.Transfers.transfers],
})

const mapDispatchToProps = {
    tooglePreviewForUnpaidDocs: FileActions.tooglePreviewForUnpaidDocs,
    getFileBody: FileActions.getFileData,
    getDocuments: ReportsActions.getDocuments,
    openTransferPreviewWindow: TransfersActions.openTransferPreviewWindow,
}

export default connect(mapStateToProps, mapDispatchToProps)(DocsAwaitingPayment)
