import moment from 'moment'
import 'moment-timezone'

import arrayObjectIndexOf from "../helpers/indexOfObject"

export const EventConst = {
    GET_EVENTS: 'GET_EVENTS',
    GET_PROJECT_EVENTS_REQUEST: 'GET_PROJECT_EVENTS_REQUEST',
    GET_PROJECT_EVENTS: 'GET_PROJECT_EVENTS',
    ADD_EVENT: 'ADD_EVENT',
    UPDATE_EVENT: 'UPDATE_EVENT',
    SELECT_DAY: 'SELECT_DAY',
    DATE_PICKER_SELECT_DAY: 'DATE_PICKER_SELECT_DAY',
    AUTHORIZED_GOOGLE_CALENDAR: 'AUTHORIZED_GOOGLE_CALENDAR',
    NOT_AUTHORIZED_GOOGLE_CALENDAR: 'NOT_AUTHORIZED_GOOGLE_CALENDAR',
    SEND_GOOGLE_CALENDAR_LIST_REQUEST: 'SEND_GOOGLE_CALENDAR_LIST_REQUEST',
    GET_GOOGLE_CALENDARS_LIST: 'GET_GOOGLE_CALENDARS_LIST',
    GET_GOOGLE_CALENDARS_NAME: 'GET_GOOGLE_CALENDARS_NAME',
    SET_MONTH: 'SET_MONTH',
    DELETE_EVENT_SUCCESS: "DELETE_EVENT_SUCCESS",
    UPDATE_EVENT_SUCCESS: "UPDATE_EVENT_SUCCESS",
    TOGGLE_DASHBOARD_CALENDAR: 'TOGGLE_DASHBOARD_CALENDAR',

    DELETE_PROJECT: 'DELETE_PROJECT-EVENTS',
    LOGOUT: 'LOGOUT',
}

const initialState = {
    events: [],
    nearestEvent: null,
    projectEventsLoaded: [],
    projectEventsLoading: [],
    maxEventsForProjectCount: 0,
    selectedDay: { display: null, value: null },
    datePickerDay: { display: null, value: null },
    currentMonth: moment(),
    googleCalendarsList: null,
    dashboardExtendedCalendars: []
}

function dynamicComparer(key, order = 'asc') {
    return function (a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            return 0;
        }

        const varA = (typeof a[key] === 'string') ?
            a[key].toUpperCase() : a[key];
        const varB = (typeof b[key] === 'string') ?
            b[key].toUpperCase() : b[key];

        var comparison = 0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }
        return (
            (order == 'desc') ? (comparison * -1) : comparison
        );
    };
}

export default (state = initialState, action) => {
    switch (action.type) {
        case EventConst.GET_EVENTS:
            var currentLoadedEvents = state.events
            var nearest = state.nearestEvent

            var downloadedEvents = []
            action.events.forEach((event) => {
                console.log(event.start_date)
                console.log(moment(event.start_date).utc().format("HH:mm"))
                if (nearest && (moment(nearest.start_date) > moment() && moment(nearest.start_date) > moment(event.start_date))) nearest = event;
                else if (!nearest && moment(event.start_date) > moment()) nearest = event;
                if (state.events.filter(e => e.id === event.id).length === 0) {
                    downloadedEvents.push(event)
                }
            });

            var newEvents = [].concat(downloadedEvents, state.events)
            newEvents.sort(dynamicComparer('start_date'))

            return { ...state, ...{ events: newEvents, nearestEvent: nearest } }

        case EventConst.ADD_EVENT:
            var newEvents = action.events
            var currentEvents = [...state.events]
            var nearestEvent = state.nearestEvent
            newEvents.forEach(newEvent => {
                newEvent.project = action.project
                currentEvents.push(newEvent)
                if (nearestEvent && (moment(nearestEvent.start_date) > moment() && moment(nearestEvent.start_date) > moment(newEvent.start_date))) nearestEvent = newEvent;
                else if (!nearestEvent && moment(newEvent.start_date) > moment()) nearestEvent = newEvent;
            })

            console.log("before sort")
            currentEvents.forEach(e => {
                console.log(e.start_date)
            })
            currentEvents.sort(dynamicComparer('start_date'))
            console.log("after sort")
            currentEvents.forEach(e => {
                console.log(e.start_date)
            })

            return { ...state, ...{ events: currentEvents, nearestEvent: nearestEvent, datePickerDay: { display: null, value: null } } }

        case EventConst.UPDATE_EVENT:
            return state

        case EventConst.SELECT_DAY:
            return { ...state, ...{ selectedDay: action.day, currentMonth: action.day.value || state.currentMonth } }

        case EventConst.DATE_PICKER_SELECT_DAY:
            return { ...state, ...{ datePickerDay: action.day } }

        case EventConst.AUTHORIZED_GOOGLE_CALENDAR:
            return { ...state, ...{ authorizedGoogleCalendar: true } }

        case EventConst.NOT_AUTHORIZED_GOOGLE_CALENDAR:
            return { ...state, ...{ calendarAuthorizationMessage: action.message, calendarAuthorizationErrorReason: action.reason, authorizedGoogleCalendar: false } }

        case EventConst.GET_PROJECT_EVENTS_REQUEST:
            var projectEventsLoading = [...state.projectEventsLoading]

            if (!projectEventsLoading.includes(action.projectId)) {
                projectEventsLoading.push(action.projectId)

                return { ...state, ...{ projectEventsLoading: projectEventsLoading } }
            }

            return state


        case EventConst.GET_PROJECT_EVENTS:
            var projectEventsLoading = [...state.projectEventsLoading]
            var projectEventsLoaded = JSON.parse(JSON.stringify(state.projectEventsLoaded))
            var maxEventsCount = state.maxEventsForProjectCount
            if (action.count > maxEventsCount) maxEventsCount = action.count
            projectEventsLoaded.push(action.projectId)

            var downloadedEvents = []
            action.projectEvents.forEach((event) => {
                if (state.events.filter(e => e.id === event.id).length === 0) {
                    downloadedEvents.push(event)
                }
            });

            projectEventsLoading = projectEventsLoading.filter(p => p !== action.projectId)
            return { ...state, ...{ events: [].concat(downloadedEvents, state.events).sort(dynamicComparer('start_date')), projectEventsLoaded: [...new Set(projectEventsLoaded)], projectEventsLoading: projectEventsLoading, maxEventsForProjectCount: maxEventsCount } }

        case EventConst.SEND_GOOGLE_CALENDAR_LIST_REQUEST:
            return { ...state, ...{ googleCalendarsList: [] } }

        case EventConst.GET_GOOGLE_CALENDARS_LIST:
            return { ...state, ...{ googleCalendarsList: action.calendarsList } }

        case EventConst.GET_GOOGLE_CALENDARS_NAME:
            return { ...state, ...{ currentCalendarName: action.calendarName } }

        case EventConst.SET_MONTH:
            var newDate = moment()
            if (moment(action.month).format('M') !== moment().format('M')) {
                newDate = moment(action.month).set('date', 1).set('hour', 0).set('minute', 0)
            }

            return { ...state, ...{ currentMonth: newDate } }

        case EventConst.DELETE_EVENT_SUCCESS:
            var currentLoadedEvents = [...state.events]

            if (action.deletedMultiple) {
                action.deletedEvents.forEach(event => {
                    currentLoadedEvents = currentLoadedEvents.filter(e => e.id !== event)
                })
            } else {
                currentLoadedEvents = currentLoadedEvents.filter(e => e.id !== action.deletedEvents)
            }

            currentLoadedEvents.sort(dynamicComparer('start_date'))



            return { ...state, ...{ events: currentLoadedEvents, nearestEvent: currentLoadedEvents[0] } }

        case EventConst.UPDATE_EVENT_SUCCESS:
            var currentLoadedEvents = [...state.events]
            var eventIndex
            if (action.editedMultiple) {
                action.updatedEvents.forEach(event => {
                    eventIndex = arrayObjectIndexOf(currentLoadedEvents, event.id, "id")
                    if (eventIndex > -1) {
                        console.log("found match with current ")
                        currentLoadedEvents[eventIndex].name = event.name
                        currentLoadedEvents[eventIndex].first_occurenc_id = event.first_occurenc_id
                        currentLoadedEvents[eventIndex].first_occurenc_date = event.first_occurenc_id
                        currentLoadedEvents[eventIndex].project_id = event.project_id
                        currentLoadedEvents[eventIndex].note = event.note
                        currentLoadedEvents[eventIndex].start_date = event.start_date
                        currentLoadedEvents[eventIndex].participants = event.participants
                        currentLoadedEvents[eventIndex].userResponsibleForTask = event.userResponsibleForTask
                        currentLoadedEvents[eventIndex].recurrence = event.recurrence
                        currentLoadedEvents[eventIndex].google_calendar_id = event.google_calendar_id
                    } else {
                        currentLoadedEvents.push(
                            { ...event }
                        )
                    }
                })
            } else {
                eventIndex = arrayObjectIndexOf(currentLoadedEvents, action.updatedEvents.id, "id")
                if (eventIndex > -1) {
                    console.log("found match with current ")
                    currentLoadedEvents[eventIndex].name = action.updatedEvents.name
                    currentLoadedEvents[eventIndex].first_occurenc_id = action.updatedEvents.first_occurenc_id
                    currentLoadedEvents[eventIndex].first_occurenc_date = action.updatedEvents.first_occurenc_id
                    currentLoadedEvents[eventIndex].project_id = action.updatedEvents.project_id
                    currentLoadedEvents[eventIndex].note = action.updatedEvents.note
                    currentLoadedEvents[eventIndex].start_date = action.updatedEvents.start_date
                    currentLoadedEvents[eventIndex].participants = action.updatedEvents.participants
                    currentLoadedEvents[eventIndex].userResponsibleForTask = action.updatedEvents.userResponsibleForTask
                    currentLoadedEvents[eventIndex].recurrence = action.updatedEvents.recurrence
                    currentLoadedEvents[eventIndex].google_calendar_id = action.updatedEvents.google_calendar_id
                }
            }
            currentLoadedEvents.sort(dynamicComparer('start_date'))
            return { ...state, ...{ events: currentLoadedEvents, nearestEvent: currentLoadedEvents[0] } }

        case EventConst.LOGOUT:
            return {
                events: [],
                nearestEvent: null,
                projectEventsLoaded: [],
                projectEventsLoading: [],
                maxEventsForProjectCount: 0,
                selectedDay: { display: null, value: null },
                datePickerDay: { display: null, value: null },
                currentMonth: moment(),
                googleCalendarsList: null,
            }

        case EventConst.DELETE_PROJECT:
            var projectEventsLoaded = [...state.projectEventsLoaded]
            var projectEventsLoading = [...state.projectEventsLoading]
            var events = [...state.events]

            projectEventsLoaded = projectEventsLoaded.filter(e => e !== action.projectId)
            projectEventsLoading = projectEventsLoading.filter(e => e !== action.projectId)
            events = events.filter(e => e.project_id !== action.projectId)

            return { ...state, ...{ projectEventsLoaded: projectEventsLoaded, projectEventsLoading: projectEventsLoading, events: events } }

        case EventConst.TOGGLE_DASHBOARD_CALENDAR:
            var dashboardExtendedCalendars = state.dashboardExtendedCalendars || []

            if (action.extend === undefined) {
                if (dashboardExtendedCalendars.includes(action.projectId)) {
                    dashboardExtendedCalendars = dashboardExtendedCalendars.filter(e => e !== action.projectId)
                } else {
                    dashboardExtendedCalendars.push(action.projectId)
                }
            }

            return { ...state, ...{ dashboardExtendedCalendars: dashboardExtendedCalendars } }


        default:
            return state
    }
}