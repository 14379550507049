import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import throttle from "lodash.throttle"
import XRegExp from 'xregexp'
import { List, AutoSizer, WindowScroller } from "react-virtualized";

import { Spinner, Bounce } from 'react-activity'
import 'react-activity/dist/react-activity.css'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import ReactTooltip from 'react-tooltip'

import myFetch from '../../helpers/fetch'

import { UserActions } from '../../actions/UserActions'
import { FileActions } from '../../actions/FileActions'
import { AlertActions } from '../../actions/AlertActions'

import PdfPreview from '../../components/PdfPreview'
import OverlayInstructions from '../../components/OverlayInstructions'

import arrayObjectIndexOf from "../../helpers/indexOfObject"
export class FileManagerPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            windowWidth: null,
            userProjectsDirs: [],
            directories: [],
            filesToRender: [],
            filterField: 'name',
            order: 'ASC',
            filterFieldProject: 'created',
            orderProject: 'DESC',
            currentDir: '',
            searchTags: '',
            selectedDocType: 'ALL',
            selectedDocPhrase: 'Select the type of document',
            showDocTypesList: false,
            gotOverlayPossitions: false,
            overlayPossitions: [],

            sentFilesReguest: false,
            searchField: '',
            showResults: false,
            resultsLoading: false,
            noMatchingFiles: false,
            currentProjectId: 0,
            filesToDisplay: []
        }
    }

    componentDidMount() {
        const { userProjects } = this.props
        if (userProjects && userProjects.length) {
            this.setState({
                userProjectsDirs: userProjects.map(p => { return { id: p.id, code: p.code } }).sort((a, b) => a.code > b.code ? 1 : -1)
            })
        } else {
            this.getUserProjects()
        }
        window.addEventListener("resize", throttle(this.setOverlaySize, 100))
    }

    componentDidUpdate(prevProps) {
        const { userProjects, uploadedFiles } = this.props
        const { sentFilesReguest } = this.state

        if (userProjects && userProjects.length > 0 && (!prevProps.userProjects || (prevProps.userProjects && prevProps.userProjects.length !== userProjects.length))) {
            this.setState({
                userProjectsDirs: userProjects.map(p => { return { id: p.id, code: p.code } }).sort((a, b) => a.code > b.code ? 1 : -1)
            })
        }

        if (!this.state.gotOverlayPossitions) {
            const { searchBarRef, wievModesRef, filesRef } = this.refs
            const scrollBarWidth = window.innerWidth - document.body.offsetWidth

            console.log(scrollBarWidth)
            if (searchBarRef && wievModesRef && filesRef) {
                var holesToDraw = []

                var searchBox = searchBarRef.getBoundingClientRect()
                if (searchBox) {
                    holesToDraw.push({
                        x: searchBox.x,
                        y: searchBox.y,
                        w: searchBox.width + scrollBarWidth,
                        h: searchBox.height
                    })
                }

                var viewModesBox = wievModesRef.getBoundingClientRect()
                if (viewModesBox) {
                    holesToDraw.push({
                        x: viewModesBox.x + scrollBarWidth,
                        y: viewModesBox.y,
                        w: viewModesBox.width,
                        h: viewModesBox.height
                    })
                }

                var filesBox = filesRef.getBoundingClientRect()
                if (filesBox) {
                    holesToDraw.push({
                        x: filesBox.x,
                        y: filesBox.y + 15,
                        w: filesBox.width + scrollBarWidth,
                        h: filesBox.height
                    })
                }

                if (holesToDraw.length === 3) {

                    console.log(holesToDraw)
                    this.setState({
                        gotOverlayPossitions: true,
                        overlayPossitions: holesToDraw
                    })
                }
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", throttle(this.setOverlaySize, 100))
    }

    setOverlaySize = () => {
        const { searchBarRef, wievModesRef, filesRef } = this.refs
        const scrollBarWidth = window.innerWidth - document.body.offsetWidth

        if (searchBarRef && wievModesRef && filesRef) {
            var holesToDraw = []

            var searchBox = searchBarRef.getBoundingClientRect()
            if (searchBox) {
                holesToDraw.push({
                    x: searchBox.x,
                    y: searchBox.y,
                    w: searchBox.width,
                    h: searchBox.height
                })
            }

            var viewModesBox = wievModesRef.getBoundingClientRect()
            if (viewModesBox) {
                holesToDraw.push({
                    x: viewModesBox.x,
                    y: viewModesBox.y,
                    w: viewModesBox.width,
                    h: viewModesBox.height
                })
            }

            var filesBox = filesRef.getBoundingClientRect()
            if (filesBox) {
                holesToDraw.push({
                    x: filesBox.x,
                    y: filesBox.y + 15,
                    w: filesBox.width,
                    h: filesBox.height
                })
            }

            if (holesToDraw.length === 3) {
                console.log(holesToDraw)
                this.setState({
                    gotOverlayPossitions: true,
                    overlayPossitions: holesToDraw
                })
            }
        }
    }

    getUserProjects = () => {
        this.props.getUserProjects()
    }

    getFiles = () => {
        // this.props.getFiles('', 0, 0)
    }

    switchDirectory = (path, name, id, status, fileStep, project) => {
        console.log(path, name, id, status, fileStep, project)
        if (path !== name) {
            var updatedDir = this.state.currentDir + path + '/'
            this.setDirAndCurrentProject(updatedDir, project)
        } else {
            const { userProjects } = this.props
            var projectToCheck = null
            if (userProjects) projectToCheck = this.props.userProjects.find(p => p.id === project)
            if (projectToCheck) {
                if (this.props.pdfPreviewData.name !== name) {
                    this.props.getFileBody(parseInt(project), this.state.currentDir + name, id, status, fileStep)
                } else {
                    this.props.togglePdfPreview(true)
                }
            } else {
                if (this.props.pdfPreviewData.name !== name) {
                    this.props.getFileBody(parseInt(project), this.state.currentDir + name, id, status, fileStep)
                } else {
                    this.props.togglePdfPreview(true)
                }
            }
        }
    }

    openProjectFiles = (id, code) => {
        const { uploadedFiles } = this.props
        if (uploadedFiles) {
            this.setDirAndCurrentProject(`${code}/`, id)
            const projectIndex = uploadedFiles.findIndex(files => files.project === id)
            if (projectIndex > -1 && uploadedFiles[projectIndex].sendRequest && uploadedFiles[projectIndex].loaded) {
            } else {
                this.setState({
                    showFilesLoadingIndicator: true
                })
                this.props.getFiles("", id, 0, () => {
                    this.getFilesListToRender()

                    this.setState({
                        showFilesLoadingIndicator: false
                    })
                })
            }
        }
    }

    setDirAndCurrentProject = (dir, projectId) => {
        console.log(dir, projectId)
        this.setState({
            currentDir: dir,
            currentProjectId: projectId
        }, () => this.getFilesListToRender())
    }

    getFilesListToRender = () => {
        const { uploadedFiles } = this.props
        const { currentDir, currentProjectId } = this.state

        var filesToRender = []
        var uniqueDirs = []
        var shouldSkipRest = currentDir.split('/').length > 1
        var foundFirstMatch = false
        if (currentProjectId) {
            var projectIndex = uploadedFiles.findIndex(files => files.project === currentProjectId)
            if (projectIndex > -1) {
                const filesCount = uploadedFiles[projectIndex].files?.length || 0
                for (var i = 0; i < filesCount; i++) {
                    var currentFile = uploadedFiles[projectIndex].files[i]
                    var currentFilePath = currentFile.path
                    if (currentFilePath.includes('in progress')) break;
                    if (currentFilePath.startsWith(currentDir)) {
                        if (!foundFirstMatch) foundFirstMatch = true
                        var currentFileRelativePath = currentFilePath.replace(currentDir, '')
                        var curentFileRelativePathPaths = currentFileRelativePath.split('/')
                        if (curentFileRelativePathPaths.length === 1) {
                            filesToRender.push({
                                isFile: true,
                                ...currentFile
                            })
                        } else {
                            if (!uniqueDirs.includes(curentFileRelativePathPaths[0])) {
                                filesToRender.push({
                                    isFile: false,
                                    path: curentFileRelativePathPaths[0]
                                })
                                uniqueDirs.push(curentFileRelativePathPaths[0])
                            }
                        }
                    } else {
                        if (foundFirstMatch && shouldSkipRest) break;
                    }
                }
            }
        }

        this.setState({
            filesToRender: filesToRender
        })
    }

    goToDir = dir => {
        var dirAfterUpdate = ''
        var projectAfterUpdate = 0
        if (dir) {
            var newDir = this.state.currentDir

            var goToIndex = newDir.indexOf(dir)
            newDir = newDir.substring(0, goToIndex + dir.length) + '/'

            dirAfterUpdate = newDir
            if (dirAfterUpdate) projectAfterUpdate = this.state.currentProjectId
        }

        this.setDirAndCurrentProject(dirAfterUpdate, projectAfterUpdate)
    }

    drawIcon = (path, name) => {
        if (path !== name && path.includes('deleted')) {
            return <div className="icon trash-icon"></div>
        } else if (path !== name) {
            return <div className="icon folder-icon"></div>
        } else {
            var re = /(?:\.([^.]+))?$/;
            var extension = re.exec(name)[1]

            if (extension === 'pdf' || extension === 'PDF') {
                return <div className="icon pdf-icon"></div>
            } else {
                return <div className="icon image-icon"></div>
            }
        }
    }

    getProjectCode = dir => {
        if (dir) {
            return dir.replace(/\/.*$/, ' ')
        } else {
            return ''
        }
    }

    getDirWothoutProjectCode = dir => {
        if (dir) {
            var dirParts = dir.split('/')
            var dirToShow = ''
            dirParts.map(d => {
                if (d === 'in progress') dirToShow += this.context.t('In progress') + ' / '
                else if (d === 'deleted') dirToShow += this.context.t('Deleted') + ' / '
                else if (d === 'Correspondence') dirToShow += this.context.t('Correspondence ') + ' / '
                else if (d === 'Corporate') dirToShow += this.context.t('Corporate ') + ' / '
                else if (d === 'Other') dirToShow += this.context.t('Other ') + ' / '
                else if (d === 'Protocol') dirToShow += this.context.t('Protocol ') + ' / '
                else dirToShow += this.context.t(d) + ' / '
            })

            if (dirToShow) dirToShow = dirToShow.slice(0, -4)
            return dirToShow
        } else {
            return ''
        }
    }

    displayAsFileOrDirecxtory = (filePath, fileName, counterpartyName, currentDir) => {
        if (filePath.replace(currentDir, '').split('/')[0] === 'in progress') {
            return this.context.t('In progress')
        } else if (filePath.replace(currentDir, '').split('/')[0] === 'deleted') {
            return this.context.t('Deleted')
        } else if (filePath.replace(currentDir, '').split('/')[0] === 'Costs') {
            return this.context.t('Costs')
        } else if (filePath.replace(currentDir, '').split('/')[0] === 'Expenses') {
            return this.context.t('Expenses')
        } else if (filePath.replace(currentDir, '').split('/')[0] === 'Agreements') {
            return this.context.t('Agreements')
        } else if (filePath.replace(currentDir, '').split('/')[0] === 'Income') {
            return this.context.t('Income')
        } else if (filePath.replace(currentDir, '').split('/')[0] === 'Correspondence') {
            return this.context.t('Correspondence ')
        } else if (filePath.replace(currentDir, '').split('/')[0] === 'Corporate') {
            return this.context.t('Corporate ')
        } else if (filePath.replace(currentDir, '').split('/')[0] === 'Other') {
            return this.context.t('Other ')
        } else if (filePath.replace(currentDir, '').split('/')[0] === 'Protocol') {
            return this.context.t('Protocol ')
        } else {
            var subPath = filePath.replace(currentDir, '').split('/')[0]
            if (subPath !== fileName) return subPath
            else return counterpartyName || fileName
        }
    }

    goUpDir = dir => {
        var dirAfterUpdate = ''
        var projectAfterUpdate = 0
        if (dir) {
            var newDir = dir.slice(0, -1)
            var lastSlash = newDir.lastIndexOf('/')

            if (lastSlash > 0) {
                dirAfterUpdate = dir.substr(0, lastSlash + 1)
                if (dirAfterUpdate) projectAfterUpdate = this.state.currentProjectId
            }
        }

        this.setDirAndCurrentProject(dirAfterUpdate, projectAfterUpdate)
    }

    checkIfFolderAndReturnProperty = (file, property, forceDisplay) => {
        if (file.path.replace(this.state.currentDir, '').split('/')[0] !== file.name && !forceDisplay) {
            return null
        } else {
            if (property === 'modified') {
                return moment(file.created).format('DD-MM-YYYY HH:mm')
            } else if (property === 'status') {
                return parseInt(1 + Math.random() * (2))
            } else if (property === 'issueDate') {
                return file.issue_date
            } else if (property === 'docNumber') {
                return file.doc_number
            } else if (property === 'amountToPay') {
                return file.amount_to_pay
            } else if (property === 'description') {
                return file.comment
            } else if (property === 'uploadedBy') {
                if (file.uploadedBy?.first_name) return `${file.uploadedBy?.first_name} ${file.uploadedBy?.last_name}`
                else return `${file.uploaded_by}`
            } else {
                return null
            }
        }
    }

    displayFile = (file) => {
        const { currentDir, searchTags, selectedDocType } = this.state

        if (file.path.startsWith(currentDir) && file.path.replace(currentDir, '').split('/')[0] !== 'in progress') {
            if (searchTags !== '') {
                if (file.tags && file.tags.toLowerCase().includes(searchTags.toLowerCase())) {
                    return true
                } else if (file.name.toLowerCase().includes(searchTags.toLowerCase())) {
                    return true
                } else if (file.original_name && file.original_name.toLowerCase().includes(searchTags.toLowerCase())) {
                    return true
                } else if (file.doc_number && file.doc_number.toLowerCase().includes(searchTags.toLowerCase())) {
                    return true
                } else if (file.uploadedBy) {
                    if (file.uploadedBy.first_name && file.uploadedBy.last_name) {
                        if (file.uploadedBy.first_name.toLowerCase().includes(searchTags.toLowerCase())) return true
                        else if (file.uploadedBy.last_name.toLowerCase().includes(searchTags.toLowerCase())) return true
                        else if ((file.uploadedBy.first_name + ' ' + file.uploadedBy.last_name).toLowerCase().includes(searchTags.toLowerCase())) return true
                        else return false
                    } else if (file.uploadedBy.first_name) {
                        if (file.uploadedBy.first_name.toLowerCase().includes(searchTags.toLowerCase())) return true
                        else return false
                    } else if (file.uploadedBy.last_name) {
                        if (file.uploadedBy.last_name.toLowerCase().includes(searchTags.toLowerCase())) return true
                        else return false
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            } else {
                return true
            }
        } else {
            return false
        }
    }

    showOverlay = () => {
        const { overlayPreferance } = this.props
        this.setOverlaySize()
        overlayPreferance['docs'] = true
        this.props.toggleOverlay(overlayPreferance)
    }

    toggleDocTypeList = () => {
        this.setState({
            showDocTypesList: !this.state.showDocTypesList
        })
    }

    filterByDocType = (type, phrase) => {
        this.setState({
            selectedDocType: type,
            selectedDocPhrase: phrase,
        })
    }

    findNextDocMatchingFilterOptions = (projectIndex, fileIndex, direction) => {
        const { uploadedFiles } = this.props

        var nextDoc = null
        if (direction === - 1 && fileIndex > 0) {
            nextDoc = uploadedFiles[projectIndex].files[fileIndex - 1]
        } else if (direction === -1 && fileIndex === 0) {
            return null
        } else if (direction === 1 && fileIndex < uploadedFiles[projectIndex].files.length - 1) {
            nextDoc = uploadedFiles[projectIndex].files[fileIndex + 1]
        } else if (direction === 1 && fileIndex === uploadedFiles[projectIndex].files.length - 1) {
            return null
        }

        var nextDocMatchesFilter = false
        if (nextDoc) {
            nextDocMatchesFilter = this.displayFile(nextDoc)

            while (!nextDocMatchesFilter) {
                if (direction === - 1 && fileIndex > 0) {
                    fileIndex -= 1
                    nextDoc = uploadedFiles[projectIndex].files[fileIndex]
                } else if (direction === -1 && fileIndex === 0) {
                    nextDoc = null
                    break
                } else if (direction === 1 && fileIndex < uploadedFiles[projectIndex].files.length - 1) {
                    fileIndex += 1
                    nextDoc = uploadedFiles[projectIndex].files[fileIndex]
                } else if (direction === 1 && fileIndex === uploadedFiles[projectIndex].files.length - 1) {
                    nextDoc = null
                    break
                }

                if (nextDoc) nextDocMatchesFilter = this.displayFile(nextDoc)
                else break
            }
        }

        if (nextDocMatchesFilter) return nextDoc
        else return null

    }

    checkNext = () => {
        const { uploadedFiles, pdfPreviewData } = this.props

        var projectIndex = arrayObjectIndexOf(uploadedFiles, pdfPreviewData.projectId, 'project')

        if (projectIndex > -1) {
            var fileIndex = arrayObjectIndexOf(uploadedFiles[projectIndex].files, pdfPreviewData.id, 'id')
            if (fileIndex > -1) {
                var prevExists = this.findNextDocMatchingFilterOptions(projectIndex, fileIndex, -1) ? true : false
                var nextExists = this.findNextDocMatchingFilterOptions(projectIndex, fileIndex, +1) ? true : false

                return { index: fileIndex, prev: prevExists, next: nextExists }
            } else {
                return { index: 0, prev: false, next: false }
            }
        } else {
            return { index: 0, prev: false, next: false }
        }
    }

    goToDoc = (fileIndex, direction) => {
        const { uploadedFiles, pdfPreviewData } = this.props

        var nextDoc = null
        var projectIndex = arrayObjectIndexOf(uploadedFiles, pdfPreviewData.projectId, 'project')

        if (projectIndex > -1) {
            if (uploadedFiles[projectIndex].files[fileIndex]) nextDoc = uploadedFiles[projectIndex].files[fileIndex]
        }
        if (!this.displayFile(nextDoc)) {
            nextDoc = this.findNextDocMatchingFilterOptions(projectIndex, fileIndex, direction)
        }

        if (nextDoc) {
            this.props.getFileBody(nextDoc.project_id, nextDoc.path, nextDoc.id, nextDoc.ocr_status, nextDoc.acceptance_step)
        }
    }

    showFields = field => {
        const { currentDir, searchTags } = this.state
        const costsReges = new RegExp('\/Costs\/[0-9]{4}-[0-9]{2}')
        const incomesReges = new RegExp('\/Income\/[0-9]{4}-[0-9]{2}')
        const expensesReges = new RegExp('Expenses\/[0-9]{4}-[0-9]{2}')
        const otherReges = new RegExp('Other\/[0-9]{4}-[0-9]{2}')
        const docTypeRegex = new RegExp('[A-Za-z]\/[0-9]{4}-[0-9]{2}')
        if (searchTags || field === 'issue-date') {
            return true
        } else if (costsReges.test(currentDir) && (field === 'document-number' || field === 'amount-to-pay')) {
            return true
        } else if (incomesReges.test(currentDir) && (field === 'document-number' || field === 'amount-to-pay')) {
            return true
        } else if (expensesReges.test(currentDir) && (field === 'issue-date' || field === 'amount-to-pay')) {
            return true
        } else if (otherReges.test(currentDir) && field === 'description') {
            return true
        } else if (!(field === 'document-number' || field === 'amount-to-pay' || field === 'description') && docTypeRegex.test(currentDir)) {
            return true
        }

        return false
    }

    showLoading = (show) => {
        if (show) {
            this.setState({
                showResults: true,
                resultsLoading: true,
                noMatchingFiles: false
            })
        } else {
            this.setState({
                showResults: false,
                resultsLoading: false,
                foundFiles: [],
                searchTags: '',
                noMatchingFiles: false
            })
        }
    }

    searchForFiles = async (text) => {
        const { currentProjectId } = this.state
        if (text && text.length >= 2) {
            try {
                const res = await myFetch(`/files/get-files-by-text`, {
                    method: 'POST',
                    body: {
                        searchText: text,
                        projectId: currentProjectId
                    }
                })

                if (res.success) {
                    this.setState({
                        searchTags: text,
                        resultsLoading: false,
                        filesToDisplay: res.filesToDisplay,
                        displayOrder: res.displayOrder
                    })

                } else {
                    this.props.alertWarn(this.context.t(res.msg))
                }
            } catch (err) {
                console.log(err)
                this.props.alertWarn(this.context.t("There was an internal server error while procesing request. Try again later."))
            }
        }
    }

    renderPlaceholders = () => {
        var maxHeight = window.innerHeight - 280
        var currentHeight = 0

        var elementsToReturn = []

        while (currentHeight < maxHeight - 45) {
            elementsToReturn.push(<PlaceholderFile />)

            currentHeight += 45
        }
        return elementsToReturn
    }

    setRef = windowScroller => {
        this._windowScroller = windowScroller;
    }

    setListRef = listRef => {
        this.listRef = listRef
    }

    rowGetter = ({ index }) => {
        const { filesToRender } = this.state

        return filesToRender[index]
    }


    getRowHeight = ({ index }) => {
        const { filesToRender } = this.state

        var c = filesToRender[index]
        return 40
    }

    getFileIcon = (isFile, filePath) => {
        if (filePath === 'deleted') return "trash-icon"
        if (!isFile) return 'folder-icon'

        var re = /(?:\.([^.]+))?$/;
        var extension = re.exec(filePath)[1]
        if (extension === 'pdf' || extension === 'PDF') return "pdf-icon"
        return "image-icon"
    }

    rowRenderer = ({ style, key, index, parent, width }) => {
        const { filesToRender, currentDir, currentProjectId } = this.state

        var file = filesToRender[index]

        return (
            <div
                key={key}
                style={style}
                role="row"
                className="ReactVirtualized__List-row file-row"
                onClick={() => this.switchDirectory(file.path.replace(currentDir, '').split('/')[0], file.name, file.id, file.ocr_status, file.acceptance_step, file.project_id || currentProjectId)}
            >
                <div className={`ReactVirtualized__List-cell icon ${this.getFileIcon(file.isFile, file.path)}`}></div>
                <div className={`ReactVirtualized__List-cell name ${file.isFile ? 'file' : 'folder'}`}>
                    {this.displayAsFileOrDirecxtory(file.path, file.name || '', file.counterparty || '', currentDir)}
                </div>
                {
                    this.showFields('document-number') ? (
                        <div className="ReactVirtualized__List-cell document-number">
                            <div className="text">{this.checkIfFolderAndReturnProperty(file, 'docNumber')}</div>
                        </div>
                    ) : null
                }
                {
                    this.showFields('issue-date') ? (
                        <div className="ReactVirtualized__List-cell issue-date">
                            <div className="text">{this.checkIfFolderAndReturnProperty(file, 'issueDate')}</div>
                        </div>
                    ) : null
                }
                {
                    this.showFields('description') ? (
                        <div className="ReactVirtualized__List-cell description">
                            <div className="text">{this.checkIfFolderAndReturnProperty(file, 'description', true)}</div>
                        </div>
                    ) : null
                }
                {
                    this.showFields('amount-to-pay') ? (
                        <div className="ReactVirtualized__List-cell amount-to-pay">
                            <div className="text">{this.checkIfFolderAndReturnProperty(file, 'amountToPay')}</div>
                        </div>
                    ) : null
                }
                <div className="ReactVirtualized__List-cell modified">
                    <div className="text">{this.checkIfFolderAndReturnProperty(file, 'modified')}</div>
                </div>
                {
                    this.showFields('uploaded-by') ? (
                        <div className="ReactVirtualized__List-cell uploaded-by">
                            <div className="text">{this.checkIfFolderAndReturnProperty(file, 'uploadedBy')}</div>
                        </div>
                    ) : null
                }
                {
                    file.isFile ? (
                        <>
                            <div className={`ReactVirtualized__List-cell status`} data-for={`file-status--${file.id}`} data-tip='show'>
                                <span className={`step-1 ${file.acceptance_step > 1 ? 'green' : 'yellow'}`}></span>
                                <span className={`step-2 ${file.acceptance_step > 2 ? 'green' : file.acceptance_step === 2 ? 'yellow' : 'grey'}`}></span>
                                <span className={`step-3 ${file.ocr_status === 'DONE' ? 'green' : file.acceptance_step === 3 ? 'yellow' : 'grey'}`}></span>
                            </div>

                            <ReactTooltip id={`file-status--${file.id}`} place="left" effect="solid" className="default-tooltip status-tooltip">
                                {
                                    file.acceptance_step === 1 ? (
                                        this.context.t('OCR not verified')
                                    ) : (
                                        file.acceptance_steps === 2 ? (
                                            this.context.t('OCR not accepted')
                                        ) : (
                                            file.ocr_status === 'DONE' ? (
                                                this.context.t('Paid')
                                            ) : (
                                                this.context.t('Not paid')
                                            )
                                        )
                                    )
                                }
                            </ReactTooltip>
                        </>
                    ) : null
                }
            </div>
        )
    }

    render() {
        const {
            userProjectsDirs, filesToRender, showFilesLoadingIndicator,
            currentDir,
            searchTags,
            gotOverlayPossitions, overlayPossitions,
            selectedDocType, selectedDocPhrase, showDocTypesList, showResults,
            noMatchingFiles, resultsLoading, filesToDisplay
        } = this.state
        const {
            user,
            uploadedFiles,
            pdfPreviewOpen,
            previewClickFromChatNotification,
            currentCompany,
            fileManagerViewMode,
            tabIsActive, wrapperRef,
            overlayPreferance } = this.props
        var directories = []
        if (tabIsActive || !user.new_easydocs_version) {
            if (currentCompany) {
                return (
                    <div className="full-screen-file-manager">

                        {/* <div className='show-overlay' onClick={() => this.showOverlay()} data-for="info-context-overlay" data-tip="show"></div> */}
                        {
                            gotOverlayPossitions && overlayPreferance['docs'] ? (
                                <OverlayInstructions possitions={overlayPossitions} name="docs" height={window.innerHeight} />
                            ) : (
                                null
                            )
                        }

                        {
                            pdfPreviewOpen && !previewClickFromChatNotification ? (
                                <PdfPreview location={this.props.location} prevAndNext={this.checkNext()} goToDoc={this.goToDoc} />
                            ) : (
                                null
                            )
                        }

                        <div className="search-bar file-manager" ref="searchBarRef">
                            <SearchInput
                                showLoading={show => this.showLoading(show)}
                                setSearchField={value => this.searchForFiles(value)}
                            />
                        </div>

                        <div className="container-fluid">
                            <div className="header">
                                <h1>{this.context.t('My Docs')}</h1>

                                {
                                    currentDir && currentDir.length > 0 ? (
                                        <div className="path">
                                            <div className="folder-icon"></div>
                                            <div className="go-back" onClick={() => this.goUpDir(currentDir)}></div>
                                            <h2>{this.getProjectCode(currentDir)}{this.context.t('Docs /')} <span>{this.getDirWothoutProjectCode(currentDir)}</span></h2>
                                        </div>
                                    ) : (
                                        <div className="path"></div>
                                    )
                                }

                                {/* <div className="view-modes" ref="wievModesRef">
                                <div className="list-view" onClick={() => this.props.switchFileManagerViewMode('list')} data-for="list-info" data-tip="show"></div>
                                <div className="tile-view" onClick={() => this.props.switchFileManagerViewMode('tile')} data-for="tile-info" data-tip="show"></div>
                            </div> */}
                            </div>

                            {
                                uploadedFiles && uploadedFiles.length === 0 ? (
                                    <div className="files-container empty" ref="filesRef">
                                        <div className="text">{this.context.t("It looks like you don't have any files")}</div>
                                    </div>
                                ) : (
                                    noMatchingFiles ? (
                                        <div className="no-matching-files-found">
                                            <div className="image"></div>
                                            <div className="text">{this.context.t("No results were found for your search")}</div>
                                        </div>
                                    ) : (
                                        <div className={`files-container ${fileManagerViewMode !== 'tile' ? 'list' : 'tile'}`} ref="filesRef">
                                            <div className="file-row header-row">
                                                <div className="name">
                                                    {
                                                        currentDir === '' ? (
                                                            this.context.t('Binder name')
                                                        ) : (
                                                            this.context.t('Name')
                                                        )
                                                    }
                                                </div>

                                                {
                                                    fileManagerViewMode !== 'tile' && this.showFields('document-number') ? (
                                                        <div className="document-number" style={{ marginLeft: 0 }}>
                                                            {this.context.t('Document number')}
                                                        </div>
                                                    ) : (
                                                        null
                                                    )
                                                }

                                                {
                                                    fileManagerViewMode !== 'tile' && this.showFields('issue-date') ? (
                                                        <div className="issue-date" style={{ marginLeft: 0 }}>
                                                            {this.context.t('Issue date')}
                                                        </div>
                                                    ) : (
                                                        null
                                                    )
                                                }

                                                {
                                                    fileManagerViewMode !== 'tile' && this.showFields('description') ? (
                                                        <div className="description" style={{ marginLeft: 0 }}>
                                                            {this.context.t('Description')}
                                                        </div>
                                                    ) : (
                                                        null
                                                    )
                                                }

                                                {
                                                    fileManagerViewMode !== 'tile' && this.showFields('amount-to-pay') ? (
                                                        <div className="amount-to-pay" style={{ marginLeft: 0 }}>
                                                            {this.context.t('Amount to pay')}
                                                        </div>
                                                    ) : (
                                                        null
                                                    )
                                                }

                                                {
                                                    fileManagerViewMode !== 'tile' && this.showFields('modified') ? (
                                                        <div className="modified">
                                                            {
                                                                this.context.t('Import date')
                                                            }
                                                        </div>
                                                    ) : (
                                                        null
                                                    )
                                                }

                                                {
                                                    fileManagerViewMode !== 'tile' && this.showFields('uploaded-by') ? (
                                                        <div className="uploaded-by" style={{ marginLeft: 0 }}>
                                                            {this.context.t('Uploaded by')}
                                                        </div>
                                                    ) : (
                                                        null
                                                    )
                                                }


                                                {
                                                    fileManagerViewMode !== 'tile' && this.showFields('Status') ? (
                                                        <div className="Status">
                                                            {
                                                                this.context.t('Status')
                                                            }
                                                        </div>
                                                    ) : (
                                                        null
                                                    )
                                                }
                                            </div>
                                            {
                                                resultsLoading ? (
                                                    <div className="placeholder-files-wrapper">
                                                        {this.renderPlaceholders()}
                                                    </div>
                                                ) : (
                                                    searchTags ? (
                                                        filesToDisplay.map(dir => {
                                                            if (dir && dir.files && dir.files.length > 0) {
                                                                return (
                                                                    <div className="matching-dir">
                                                                        <div className="title">{`${dir.projectCode} - ${this.context.t(dir.docsType)}`}</div>
                                                                        {
                                                                            dir.files.map((file, i) => {
                                                                                return (
                                                                                    <div className="file-row" key={i} onClick={() => this.switchDirectory(file.name, file.name, file.id, file.ocrStatus, file.acceptanceStep, file.projectId)}>
                                                                                        {this.drawIcon(file.name, file.name)}

                                                                                        <div className={`name file`}>
                                                                                            <div className="text">
                                                                                                {file.counterpartyName || file.name}
                                                                                            </div>
                                                                                        </div>

                                                                                        {
                                                                                            fileManagerViewMode !== 'tile' ? (
                                                                                                <div className="document-number">
                                                                                                    <div className="text">{file.docNumber}</div>
                                                                                                </div>
                                                                                            ) : (
                                                                                                null
                                                                                            )
                                                                                        }

                                                                                        <div className="issue-date">
                                                                                            <div className="text">{file.issueDate}</div>
                                                                                        </div>

                                                                                        {
                                                                                            fileManagerViewMode !== 'tile' ? (
                                                                                                <div className="description">
                                                                                                    <div className="text">{file.comment}</div>
                                                                                                </div>
                                                                                            ) : (
                                                                                                null
                                                                                            )
                                                                                        }

                                                                                        {
                                                                                            fileManagerViewMode !== 'tile' ? (
                                                                                                <div className="amount-to-pay">
                                                                                                    <div className="text">{file.amountToPay}</div>
                                                                                                </div>
                                                                                            ) : (
                                                                                                null
                                                                                            )
                                                                                        }

                                                                                        <div className="modified">
                                                                                            {moment(file.created).format('DD-MM-YYYY HH:mm')}
                                                                                        </div>

                                                                                        <div className={`status`} data-for={`file-status--${i}`} data-tip='show'>
                                                                                            <span className={`step-1 ${file.acceptanceStep > 1 ? 'green' : 'yellow'}`}></span>
                                                                                            <span className={`step-2 ${file.acceptanceStep > 2 ? 'green' : file.acceptanceStep === 2 ? 'yellow' : 'grey'}`}></span>
                                                                                            <span className={`step-3 ${file.ocrStatus === 'DONE' ? 'green' : file.acceptanceStep === 3 ? 'yellow' : 'grey'}`}></span>
                                                                                        </div>

                                                                                        <ReactTooltip id={`file-status--${i}`} place="left" effect="solid" className="default-tooltip status-tooltip">
                                                                                            {
                                                                                                file.acceptanceStep === 1 ? (
                                                                                                    this.context.t('OCR not verified')
                                                                                                ) : (
                                                                                                    file.acceptanceSteps === 2 ? (
                                                                                                        this.context.t('OCR not accepted')
                                                                                                    ) : (
                                                                                                        file.ocrStatus === 'DONE' ? (
                                                                                                            this.context.t('Paid')
                                                                                                        ) : (
                                                                                                            this.context.t('Not paid')
                                                                                                        )
                                                                                                    )
                                                                                                )
                                                                                            }
                                                                                        </ReactTooltip>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                )
                                                            } else {
                                                                return null
                                                            }
                                                        })
                                                    ) : (
                                                        !currentDir ? (
                                                            userProjectsDirs.map(project => {
                                                                return (
                                                                    <div className="file-row" key={`binderFolder-${project.id}`} onClick={() => this.openProjectFiles(project.id, project.code)}>
                                                                        {this.drawIcon(project.code, '')}

                                                                        <div className={`name folder`}>
                                                                            <div className="text">
                                                                                {
                                                                                    this.displayAsFileOrDirecxtory(project.code, "", "", currentDir)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        ) : (
                                                            showFilesLoadingIndicator ? (
                                                                <div className="loading-indicator">
                                                                    <Bounce size={30} speed={0.8} color={"#BCBBBB"} />
                                                                </div>
                                                            ) : (
                                                                <WindowScroller
                                                                    ref={this.setRef}
                                                                    scrollElement={user.new_easydocs_version && wrapperRef && wrapperRef.current ? wrapperRef.current : window}
                                                                >
                                                                    {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
                                                                        <AutoSizer disableHeight>
                                                                            {({ width }) => (
                                                                                <div ref={registerChild}>
                                                                                    <List
                                                                                        ref={this.setListRef}
                                                                                        autoHeight
                                                                                        width={width}
                                                                                        height={height}
                                                                                        rowHeight={this.getRowHeight}

                                                                                        rowCount={filesToRender.length}
                                                                                        rowGetter={this.rowGetter}
                                                                                        rowRenderer={({ style, key, index, parent }) => this.rowRenderer({ style, key, index, parent, width })}
                                                                                        overscanRowCount={2}

                                                                                        isScrolling={isScrolling}
                                                                                        scrollTop={scrollTop}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </AutoSizer>
                                                                    )}
                                                                </WindowScroller>
                                                            )
                                                        )
                                                    )
                                                )
                                            }
                                        </div>
                                    )
                                )
                            }
                        </div>

                        <ReactTooltip id="list-info" effect="solid" place="top" className="default-tooltip info-tooltip" delayShow={800}>
                            <p>{this.context.t('List view')}</p>
                        </ReactTooltip>

                        <ReactTooltip id="tile-info" effect="solid" place="top" className="default-tooltip info-tooltip" delayShow={800}>
                            <p>{this.context.t('Tile view')}</p>
                        </ReactTooltip>
                    </div>
                )
            } else {
                return (
                    null
                )
            }
        } else {
            return null
        }
    }
}

class SearchInput extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchField: '',
            lastSearchEditTime: null
        }
    }

    onInputChange = e => {
        e.preventDefault()

        var { name, value } = e.target
        value = value.replace(XRegExp('[^\\.\\,\\-\\+\\(\\)\\$\\%\\_\\?\\!\\#\\"\\/\\d\\s\\p{Latin}]', 'gm'), '').replace(/\s\s+/gm, ' ')
        this.setState({
            [name]: value,
            lastSearchEditTime: new Date()
        })

        if (value.length >= 2) {
            this.props.showLoading(true)
        } else {
            this.props.showLoading(false)
        }

        setTimeout(() => {
            if (new Date() - this.state.lastSearchEditTime > 730) {
                this.props.setSearchField(value)
            }
        }, 750)
    }

    render() {
        const { searchField } = this.state
        return (
            <input
                type="text"
                value={searchField}
                name="searchField"
                onChange={e => this.onInputChange(e)}
                placeholder={this.context.t('Search for documents in current directory - by a fragment of the content (also finds grammatical variants), name, tags')}
            />
        )
    }
}

class PlaceholderFile extends Component {
    render() {
        return (
            <div class="file-row">
                <div class="icon"></div>
                <div className="loading-text name"></div>
                <div className="loading-text document-number"></div>
                <div className="loading-text amount-to-pay"></div>
                <div className="loading-text modified"></div>
                <div className="loading-text status"></div>
            </div>
        )
    }
}

SearchInput.contextTypes = {
    t: PropTypes.func
}

FileManagerPage.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    currentCompany: state.Company.currentCompany || {},
    user: state.User.user || {},
    userProjects: state.User.userProjects,
    overlayPreferance: state.User.overlayPreferance || {},
    uploadedFiles: [...state.File.uploadedFiles],
    pdfPreviewData: state.File.pdfPreviewData,
    pdfPreviewOpen: state.File.pdfPreviewOpen,
    previewClickFromChatNotification: state.File.previewClickFromChatNotification,
    fileManagerViewMode: state.User.fileManagerViewMode
})

const mapDispatchToProps = {
    getFiles: FileActions.getFiles,
    getFileBody: FileActions.getFileData,
    getUserProjects: UserActions.getUserProjects,
    togglePdfPreview: FileActions.togglePdfPreview,
    switchFileManagerViewMode: UserActions.switchFileManagerViewMode,
    toggleOverlay: UserActions.toggleOverlay,
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(FileManagerPage)
