const profmProjects = [
    707,    //ProFM
    708,    //BEN
]

const profmLevel1 = [
    {
        "description": "BEN",
        "code": "BEN",
    }, {
        "description": "SCH",
        "code": "SCH"
    }, {
        "description": "CXSA",
        "code": "CXSA"
    }, {
        "description": "GL",
        "code": "GL"
    }, {
        "description": "GDN",
        "code": "GDN"
    }, {
        "description": "GDW",
        "code": "GDW"
    }, {
        "description": "KAN",
        "code": "KAN"
    }
]


const profmMpkSettings = {
    levelsCount: 1,
    hasDependentSublevels: false,
    level1List: profmLevel1,
    level2List: [],
    level3List: [],
    level4List: [],
}


module.exports = {
    profmProjects,
    profmMpkSettings
};