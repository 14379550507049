import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import TextareaAutosize from 'react-textarea-autosize'
import moment from 'moment'

import '../../css/OcrPage.css'
import { CompanyActions } from '../../actions/CompanyActions';

export class OcrPageOriginalDocNumbe extends Component {
	constructor(props) {
		super(props)
		this.state = {
			filter: '',
			showList: false
		}
		this.containerRef = React.createRef();
	}

	componentDidMount() {
		document.addEventListener("mousedown", this.handleClick);
		window.addEventListener("keydown", this.escFunction, false)
	}

	componentDidUpdate(prevProps) {
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClick);
		window.removeEventListener("keydown", this.escFunction, false)
	}

	escFunction = e => {
		if (e.keyCode === 27) {
			this.handleClick(e)
		}
	}

	handleClick = (event) => {
		if (event.keyCode === 27 || (this.containerRef && !this.containerRef.current.contains(event.target))) {
			this.setState({
				showList: false
			})
		} else {
		}
	}

	onInputChange = e => {
		e.preventDefault()
		const { name, value } = e.target

		this.setState({ [name]: value })
	}

	checkIfRowMatchesFilter = doc => {
		const { filter } = this.state

		var show = false

		if (filter && doc.doc_number) {
			if (doc.doc_number.startsWith(filter)) show = true
		} else {
			show = true
		}

		return show
	}

	toggleList = (show) => {
		this.setState({
			showList: show
		})
	}

	render() {
		const { selectedOptionId, selectedOptionValue, list, active } = this.props
		const { filter, showList } = this.state
		console.log(selectedOptionId, selectedOptionValue)
		return (
			<div className={`form-group original-doc-input-container ${!active ? 'inactive' : ''} ${showList ? 'front' : ''}`} id="original-doc-input-container" ref={this.containerRef}>
				{
					showList && active ? (
						<div className={`tags-list`}>
							<div className="header">
								{this.context.t('Documents of this counterparty:')}
							</div>
							<div className="filter-row">
								<input type="text" name="filter" value={filter} onChange={this.onInputChange} placeholder={this.context.t('Search')} autoFocus />
							</div>
							<SimpleBar style={{ height: 'auto', maxHeight: '300px', width: '100%' }}>
								<ul>
									<li class="header-row">
										<span className="number">{this.context.t('Doc number')}</span>
										<span className="date">{this.context.t('Date')}</span>
										<span className="price">{this.context.t('Price')}</span>
									</li>
									{
										list.map((d, i) => {
											if (this.checkIfRowMatchesFilter(d)) {
												return (
													<li
														key={`original-docs-${i}`}
														className={`tag-item ${selectedOptionId === d.id ? 'selected' : ''}`}
														onClick={() => { this.props.select(d); this.toggleList(false) }}
													>
														<span className="number">{`${d.doc_number}`}</span>
														<span className="date">{`${moment(d.date).format('YYYY-MM-DD')}`}</span>
														<span className="price">{`${parseFloat(d.price)} ${d.currency}`}</span>
													</li>
												)
											} else {
												return null
											}
										})
									}
								</ul>
							</SimpleBar>
						</div>
					) : (
						null
					)
				}
				<label>{this.context.t('Oryginal invoice number')}</label>
				<input
					className={`tags-container`}
					value={selectedOptionValue}
					readOnly={true}
					name='original-invoice'
					minRows={1}
					maxRows={10}
					onFocus={(e) => this.toggleList(true)}
				/>
				<div className={`extend-icon ${showList ? 'extended' : ''}`} onClick={() => this.toggleList(!showList)}></div>
			</div>
		)
	}
}

OcrPageOriginalDocNumbe.contextTypes = {
	t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
	getOriginalDocNumbersForCounterparty: CompanyActions.getOriginalDocNumbersForCounterparty
}

export default connect(mapStateToProps, mapDispatchToProps)(OcrPageOriginalDocNumbe)