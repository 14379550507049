import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import PropTypes from 'prop-types'
import throttle from "lodash.throttle"

import { Spinner, Bounce } from 'react-activity'
import 'react-activity/dist/react-activity.css'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import ReactTooltip from 'react-tooltip'

import routes from '../../../../helpers/routes'

import { AlertActions } from '../../../../actions/AlertActions'
import { CompanyActions } from '../../../../actions/CompanyActions'
import { UserActions } from '../../../../actions/UserActions'
import { FileActions } from '../../../../actions/FileActions';

import OverlayInstructions from '../../../../components/OverlayInstructions'
import SettingsTabsBar from '../../../../components/SettingsTabsBar'

import arrayObjectIndexOf from '../../../../helpers/indexOfObject';
import { emailRegex } from '../../../../helpers/regexList'

import Projects from './Projects'
import ProjectMembers from './ProjectMembers'
import CreateProject from './CreateProject'

import '../../../../css/ProjectsManagementPage.css'

const avalibleVariables = ['{date}', '{senderCode}', '{receiverCode}', '{projectCode}', '{docType}']
const tabs = ['PROJECTS', 'ADD_PROJECT', 'ASSIGN_MEMBERS', 'PROJECT_MEMBERS']

export class ProjectManagement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showCompaniesList: false,
            projectsListExpended: false,
            currentProject: {},
            currentTab: 'PROJECTS',

            editPopup: false,

            gotMainOverlayPossitions: false,
            mainOverlayPossitions: [],
            showSingleProjectOverlay: false,
            projectOverlayPossitions: [],
            projectMembersOverlayPossitions: [],

            inviteNewMemberPopup: false,
            inviteUserEmail: '',
            newUsersProjects: [],
            showProjectsList: false,
        }
    }

    componentDidMount() {
        window.addEventListener("keydown", this.escFunction, false)
        window.addEventListener("resize", throttle(this.setOverlaySize, 100))
        //ReactGA.pageview(window.location.pathname + window.location.search)
        console.log(this.state.currentProjectId)
        console.log(this.props.companyProjects)
        $("input").focusout(function () {
            if ($(this).val() !== "") {
                $(this).addClass("has-content");
            } else {
                $(this).removeClass("has-content");
            }
        })

        const { userProjects, currentCompany, userCompanies } = this.props
        if (currentCompany && currentCompany.company) {
            var dashboardProjectId = parseInt(localStorage.getItem('dashboardProject')) || -1
            var projectIndex = arrayObjectIndexOf(userProjects, dashboardProjectId, 'id')
            if (projectIndex > -1 && currentCompany.id !== userProjects[projectIndex].company_id) {
                var companyIndex = arrayObjectIndexOf(userCompanies, userProjects[projectIndex].company_id, 'company_id')
                console.log(companyIndex)
                if (companyIndex > -1) {
                    if (userCompanies[companyIndex].user_role === 'ADMIN') {
                        this.props.setCurrentCompany(userCompanies[companyIndex])
                        this.props.getAllCompanyProjects(userProjects[projectIndex].company_id)
                    }
                }
            } else {
                this.props.getAllCompanyProjects(currentCompany.company_id)
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.currentCompany && this.props.currentCompany) {
            this.props.getAllCompanyProjects(this.props.currentCompany.company.id)
        }

        if (prevProps.currentCompany !== this.props.currentCompany) {
            this.props.getAllCompanyProjects(this.props.currentCompany.company.id)
        }

        if (!this.state.gotMainOverlayPossitions) {
            const { companySelectionRef, createProjectRef, projectsListRef } = this.refs
            const scrollBarWidth = window.innerWidth - document.body.offsetWidth
            if (companySelectionRef && createProjectRef && projectsListRef) {
                var holesToDraw = []

                var companySelectionBox = companySelectionRef.getBoundingClientRect()
                if (companySelectionBox) {
                    holesToDraw.push({
                        x: companySelectionBox.x + scrollBarWidth,
                        y: companySelectionBox.y,
                        w: companySelectionBox.width,
                        h: companySelectionBox.height
                    })
                }

                var createProjectBox = createProjectRef.getBoundingClientRect()
                if (createProjectBox) {
                    holesToDraw.push({
                        x: createProjectBox.x + scrollBarWidth,
                        y: createProjectBox.y,
                        w: createProjectBox.width,
                        h: createProjectBox.height
                    })
                }

                var projectsListBox = projectsListRef.getBoundingClientRect()
                if (projectsListBox) {
                    holesToDraw.push({
                        x: projectsListBox.x,
                        y: projectsListBox.y,
                        w: projectsListBox.width + scrollBarWidth,
                        h: 50
                    })

                    holesToDraw.push({
                        x: projectsListBox.x,
                        y: projectsListBox.y + 70,
                        w: projectsListBox.width + scrollBarWidth,
                        h: projectsListBox.height - 70
                    })
                }

                if (holesToDraw.length === 4) {
                    console.log(holesToDraw)
                    this.setState({
                        gotMainOverlayPossitions: true,
                        mainOverlayPossitions: holesToDraw,
                    })
                }
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.escFunction, false)
        window.removeEventListener("resize", throttle(this.setOverlaySize, 100))
    }

    escFunction = (e) => {
        if (e.keyCode === 27) {
            if (this.state.showCompaniesList) {
                this.setState({
                    showCompaniesList: false
                })
            } else if (this.state.inviteNewMemberPopup) {
                this.setState({
                    inviteNewMemberPopup: false,
                    inviteUserEmail: '',
                    newUsersProjects: []
                })
            } else if (this.state.currentTab === 'ADD_PROJECT') {
                this.changeTab('PROJECTS')
            }
        }
    }

    setOverlaySize = () => {
        const { companySelectionRef, createProjectRef, projectsListRef } = this.refs

        if (companySelectionRef && createProjectRef && projectsListRef) {
            var holesToDraw = []

            var companySelectionBox = companySelectionRef.getBoundingClientRect()
            if (companySelectionBox) {
                holesToDraw.push({
                    x: companySelectionBox.x,
                    y: companySelectionBox.y,
                    w: companySelectionBox.width,
                    h: companySelectionBox.height
                })
            }

            var createProjectBox = createProjectRef.getBoundingClientRect()
            if (createProjectBox) {
                holesToDraw.push({
                    x: createProjectBox.x,
                    y: createProjectBox.y,
                    w: createProjectBox.width,
                    h: createProjectBox.height
                })
            }

            var projectsListBox = projectsListRef.getBoundingClientRect()
            if (projectsListBox) {
                holesToDraw.push({
                    x: projectsListBox.x,
                    y: projectsListBox.y,
                    w: projectsListBox.width,
                    h: 50
                })

                holesToDraw.push({
                    x: projectsListBox.x,
                    y: projectsListBox.y + 70,
                    w: projectsListBox.width,
                    h: projectsListBox.height - 70
                })
            }

            if (holesToDraw.length === 4) {
                console.log(holesToDraw)
                this.setState({
                    mainOverlayPossitions: holesToDraw,
                })
            }
        }
    }

    changeTab = (name) => {
        this.setState({
            currentTab: name,
            currentProject: {}
        })
    }

    changeCurrentProject = (e, id, groupIndex, companyId) => {
        e.preventDefault()
        if (id && id !== this.state.currentProject.id) {
            var currentProject = this.props.companyProjects[groupIndex].projects.find(e => e.id === id)
            currentProject.companyId = companyId
            this.setState({
                projectsListExpended: false,
                currentProject: currentProject
            })
        } else {
            this.setState({
                projectsListExpended: false,
            })

            if (!id) {
                this.setState({
                    currentProject: {},
                })
            }
        }
    }

    inviteNewMember = () => {
        var { newUsersProjects, inviteUserEmail } = this.state

        var valid = true

        if (inviteUserEmail.length === 0) {
            valid = false
            this.props.alertWarn(this.context.t('E-mail address is required'))
        }
        else if (!emailRegex.test(inviteUserEmail)) {
            valid = false
            this.props.alertWarn(this.context.t('E-mail address is not valid'))
        } else if (newUsersProjects.length === 0) {
            valid = false
            this.props.alertWarn(this.context.t('You need to select at least one binder'))
        }

        if (valid) {
            this.props.inviteAndAssignMember(newUsersProjects.map(e => e.id), inviteUserEmail, 0, (success) => { })
        }
    }

    expendProjectList = e => {
        e.preventDefault()

        this.setState({
            projectsListExpended: true
        })
    }

    hideProjectsList = e => {
        e.preventDefault()

        this.setState({
            projectsListExpended: false
        })
    }

    onInputChange = e => {
        e.preventDefault()

        const { name, value } = e.target

        this.setState({ [name]: value })
    }

    showEditProjectPopup = e => {
        e.preventDefault()

        this.setState({
            editPopup: true
        })
    }

    closeEditPopup = e => {
        e.preventDefault()

        this.setState({
            editPopup: false
        })
    }

    changeCurrentCompany = (e, index) => {
        e.preventDefault()
        console.log(index)

        this.props.setCurrentCompany(this.props.userCompanies[index])

        this.setState({
            currentProject: {},
            showCompaniesList: false,
            currentTab: 'PROJECTS'
        })

        const { groupedProjects } = this.props
        var currentGroup = groupedProjects.find(e => e.company.id === this.props.userCompanies[index].company_id)
        if (currentGroup && currentGroup.projects) {
            this.props.switchDashboardViewModel(currentGroup.projects[0].id)
        }
    }

    toggleCompaniesList = () => {
        this.setState({
            showCompaniesList: !this.state.showCompaniesList
        })
    }

    closeCompaniesList = (e) => {
        if (this.state.showCompaniesList && !this.hasAncestor(e.target, "company-switcher")) {
            this.setState({
                showCompaniesList: false
            })
        }
    }

    hasAncestor = (element, id) => {
        while (element) {
            if (element.id === id) return true

            element = element.parentNode
        }

        return false
    }

    getProjectOverlayPossitons = possitions => {
        this.setState({
            projectOverlayPossitions: possitions
        })
    }

    getProjectMembersOverlayPossitions = possitions => {
        console.log(possitions)
        this.setState({
            projectMembersOverlayPossitions: possitions
        })
    }

    showOverlay = () => {
        const { overlayPreferance } = this.props
        const { currentTab, showSingleProjectOverlay } = this.state
        if (currentTab === 'PROJECTS') {
            if (showSingleProjectOverlay) {
                overlayPreferance['adminProjectsProject'] = true
                this.props.toggleOverlay(overlayPreferance)
            } else {
                this.setOverlaySize()
                overlayPreferance['adminProjectsMain'] = true
                this.props.toggleOverlay(overlayPreferance)
            }
        } else if (currentTab === 'PROJECT_MEMBERS') {
            overlayPreferance['adminProjectsMembers'] = true
            this.props.toggleOverlay(overlayPreferance)
        }
    }

    showSingleProjectOverlay = (show) => {
        this.setState({
            showSingleProjectOverlay: show
        })
    }

    toggleAdditionalAprovalForDocsForCurrentProject = () => {
        const { currentProject } = this.state
        this.setState({
            currentProject: { ...currentProject, ...{ hasAdditionalDocsAcceptanceOverTreshold: !currentProject.hasAdditionalDocsAcceptanceOverTreshold } }
        })
    }

    toggleAdditionalAprovalForDocsUnderTresholdForCurrentProject = () => {
        const { currentProject } = this.state
        this.setState({
            currentProject: { ...currentProject, ...{ hasAdditionalDocsAcceptanceUnderTreshold: !currentProject.hasAdditionalDocsAcceptanceUnderTreshold } }
        })
    }

    changeAdditionalDocsAcceptanceTresholdsForCurrentProject = (newTresholdPLN, newTresholdEUR) => {
        console.log(newTresholdPLN, newTresholdEUR)
        const { currentProject } = this.state
        this.setState({
            currentProject: { ...currentProject, ...{ additionalAcceptanceTreshold: newTresholdPLN, additionalAcceptanceTresholdEUR: newTresholdEUR } }
        })
    }

    updateUsersAllowedToAcceptDocs = (usersList, type) => {
        const { currentProject } = this.state

        if (type === 'over') {
            this.setState({
                currentProject: { ...currentProject, ...{ usersAllowedToAccaptOverTreshold: usersList } }
            })
        } else {
            this.setState({
                currentProject: { ...currentProject, ...{ usersAllowedToAccaptUnderTreshold: usersList } }
            })
        }
    }

    toggleNewUsersProjectsList = (e) => {
        if (e.target && e.target.classList && !e.target.classList.contains('project-row') && !e.target.classList.contains('checkbox')) {
            this.setState({
                showProjectsList: !this.state.showProjectsList
            })
        }
    }

    hideNewUsersProjectList = e => {
        if (!this.hasAncestor(e.target, 'choose-projects') && this.state.showProjectsList) {
            this.setState({
                showProjectsList: false
            })
        }
    }

    selectAllProjectForNewUser = () => {
        const { companyProjects } = this.props
        const { newUsersProjects } = this.state
        var selectedProjects = [...newUsersProjects]
        var selectedOne = false

        companyProjects.forEach(g => {
            g.projects.forEach(p => {
                if (!newUsersProjects.find(e => e.id === e.id)) {
                    selectedProjects.push({
                        id: p.id,
                        code: p.code
                    })
                    selectedOne = true
                }
            })
        })

        if (!selectedOne) {
            selectedProjects = []
        }

        this.setState({
            newUsersProjects: selectedProjects,
        })
    }

    addProjectToSelected = (id, code) => {
        var { newUsersProjects } = this.state
        if (newUsersProjects.find(e => e.id === id)) {
            newUsersProjects = newUsersProjects.filter(e => e.id !== id)
        } else {
            newUsersProjects.push({
                id: id,
                code: code
            })
        }

        this.setState({
            newUsersProjects: newUsersProjects,
        })
    }

    checkIfSelectedAllProjectsFromNewUser = () => {
        const { companyProjects } = this.props
        const { newUsersProjects } = this.state

        var allProjectsCount = 0

        companyProjects.forEach(g => {
            if (g.projects) allProjectsCount += g.projects.length
        })

        return newUsersProjects.length === allProjectsCount
    }

    render() {
        const { currentProject, currentTab, gotMainOverlayPossitions, mainOverlayPossitions, showSingleProjectOverlay, projectOverlayPossitions, projectMembersOverlayPossitions, inviteNewMemberPopup, inviteUserEmail, newUsersProjects, showProjectsList, } = this.state
        const { companyProjects, currentCompany, overlayPreferance, inviteAndAssignMemberSendRequest, inviteAndAssignMemberGotResponse, inviteAndAssignMemberError, inviteAndAssignMemberErrorMsg, user, tabIsActive } = this.props

        if (tabIsActive || !user.new_easydocs_version) {
            return (
                <div className="project-management" onClick={(e) => this.closeCompaniesList(e)}>

                    {
                        (currentTab === 'PROJECTS' || currentTab === 'PROJECT_MEMBERS') ? (
                            <div className='show-overlay' onClick={() => this.showOverlay()} data-for="info-context-overlay" data-tip="show"></div>
                        ) : (
                            null
                        )
                    }

                    {
                        currentTab === 'PROJECTS' && gotMainOverlayPossitions && overlayPreferance['adminProjectsMain'] ? (
                            <OverlayInstructions possitions={mainOverlayPossitions} name="adminProjectsMain" height={window.innerHeight} />
                        ) : (
                            currentTab === 'PROJECTS' && showSingleProjectOverlay && projectOverlayPossitions.length > 0 && overlayPreferance['adminProjectsProject'] ? (
                                <OverlayInstructions possitions={projectOverlayPossitions} name="adminProjectsProject" height={window.innerHeight} />
                            ) : (
                                currentTab === 'PROJECT_MEMBERS' && projectMembersOverlayPossitions.length > 0 && overlayPreferance['adminProjectsMembers'] ? (
                                    <OverlayInstructions possitions={projectMembersOverlayPossitions} name="adminProjectsMembers" height={window.innerHeight} />
                                ) : (
                                    null
                                )
                            )
                        )
                    }

                    {
                        inviteNewMemberPopup ? (
                            <div className="popup" onClick={e => this.hideNewUsersProjectList(e)}>
                                <div className="popup-card invite">
                                    <div className="header">
                                        {this.context.t('Invite new member')}
                                        <span className="close-button" onClick={() => {
                                            this.setState({
                                                inviteNewMemberPopup: false,
                                                inviteUserEmail: '',
                                                newUsersProjects: []
                                            });
                                            this.props.resetInviteNewUserMessage()
                                        }}></span>
                                    </div>
                                    <div className="body">
                                        <div id="choose-projects" className="choosen-projects" onClick={(e) => this.toggleNewUsersProjectsList(e)}>
                                            <label>{this.context.t('Choose binder')}</label>
                                            <span className={`expend-icon ${showProjectsList ? 'expended' : ''}`}></span>
                                            {
                                                newUsersProjects.length === 0 ? (
                                                    <span className="light-gray">{this.context.t('<expand list>')}</span>
                                                ) : (
                                                    <div className="selected-projects">
                                                        {
                                                            newUsersProjects.map((p, i) => {
                                                                if (newUsersProjects[i + 1]) return p.code + ', '
                                                                return p.code
                                                            })
                                                        }
                                                    </div>
                                                )
                                            }

                                            {
                                                showProjectsList ? (
                                                    <div className="new-users-project-list">
                                                        <SimpleBar style={{ maxHeight: '300px' }}>
                                                            <ul className="project-list">
                                                                <li className="project-row all" onClick={() => this.selectAllProjectForNewUser()}>
                                                                    {this.context.t('All binders')}
                                                                    <span className={`checkbox ${this.checkIfSelectedAllProjectsFromNewUser() ? 'checked' : ''}`}></span>
                                                                </li>
                                                                {
                                                                    companyProjects.map((g, i) => {
                                                                        return (
                                                                            <>
                                                                                <div className="company" key={`company-group-${i}`}>{g.company.code}</div>
                                                                                {
                                                                                    g.projects.map((p, j) => {
                                                                                        return (
                                                                                            <li className="project-row" onClick={() => this.addProjectToSelected(p.id, p.code)}>
                                                                                                {p.code}
                                                                                                <span className={`checkbox ${newUsersProjects.find(e => e.id === p.id) ? 'checked' : ''}`}></span>
                                                                                            </li>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </SimpleBar>
                                                    </div>
                                                ) : (
                                                    null
                                                )
                                            }
                                        </div>
                                        <div className="email-input">
                                            <input type="text" id="inviteUserEmail" value={inviteUserEmail} name="inviteUserEmail" onChange={e => this.onInputChange(e)} placeholder={this.context.t("e.g justin.ramirez@mail.com")} />
                                            <label htmlFor="inviteUserEmail">{this.context.t('New member email')}</label>
                                            <div className={`add-button ${inviteAndAssignMemberSendRequest ? 'loading' : ''} ${emailRegex.test(inviteUserEmail) ? 'active' : ''}`} onClick={e => this.inviteNewMember(e)}>
                                                {
                                                    inviteAndAssignMemberSendRequest ? (
                                                        <span className="activity-indicator">
                                                            <Spinner size={20} speed={0.8} color={"#69B792"} />
                                                        </span>
                                                    ) : (
                                                        this.context.t('Invite')
                                                    )
                                                }
                                            </div>
                                        </div>

                                        {
                                            inviteAndAssignMemberGotResponse ? (
                                                inviteAndAssignMemberError ? (
                                                    <div className="invite-response error">
                                                        <div className="message">
                                                            {this.context.t(inviteAndAssignMemberErrorMsg)}
                                                        </div>
                                                        <div className="close" onClick={() => this.props.resetInviteNewUserMessage()}></div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div className="invite-response ok">
                                                            <div className="prefix">{this.context.t('Success! ')}</div>
                                                            <div className="message">
                                                                {this.context.t('Your invitation has been sent')}
                                                            </div>
                                                            <div className="close" onClick={() => this.props.resetInviteNewUserMessage()}></div>
                                                        </div>

                                                        <div className="action-buttons">
                                                            <div className="btn add" onClick={() => {
                                                                this.setState({
                                                                    inviteUserEmail: '',
                                                                    newUsersProjects: []
                                                                });
                                                                this.props.resetInviteNewUserMessage()
                                                            }}>{this.context.t('Add next')}</div>
                                                            <div className="btn close" onClick={() => {
                                                                this.setState({
                                                                    inviteNewMemberPopup: false,
                                                                    inviteUserEmail: '',
                                                                    newUsersProjects: []
                                                                });
                                                                this.props.resetInviteNewUserMessage()
                                                            }}>{this.context.t('Finish')}</div>
                                                        </div>
                                                    </>
                                                )
                                            ) : (
                                                null
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        ) : (
                            null
                        )
                    }

                    <SettingsTabsBar />

                    <div className="page-header-second">
                        {
                            currentTab !== 'ADD_PROJECT' ? (
                                <>
                                    {
                                        currentCompany.company && currentCompany.company.subscription_type !== 'FREE' && currentCompany.company.next_subscription_type !== 'FREE' ? (
                                            <div className={`inv-user ${localStorage.getItem('adminOnboarding') === 'NEXT_STEPS' && currentCompany.company && currentCompany.company.members_count === 1 ? 'green-frame pulsing-frame' : ''}`} onClick={() => this.setState({ inviteNewMemberPopup: true })} ref="addMemberRef">
                                                {this.context.t('Invite new member')}
                                            </div>
                                        ) : null
                                    }
                                    <button className={`add-project ${localStorage.getItem('adminOnboarding') === 'NEXT_STEPS' ? 'green-frame pulsing-frame' : ''}`} onClick={() => this.setState({
                                        currentTab: 'ADD_PROJECT'
                                    })} ref="createProjectRef">
                                        {this.context.t('Create binder')}
                                    </button>
                                </>
                            ) : (
                                null
                            )
                        }
                    </div>

                    <div className="tab-wrapper" ref="projectsListRef">
                        {
                            currentTab !== 'ADD_PROJECT' ? (
                                <div className="header">
                                    <div className={`nav-item ${currentTab === 'PROJECTS' ? 'active' : ''}`} onClick={() => this.changeTab('PROJECTS')}>
                                        {this.context.t('My binders')}
                                    </div>
                                    <div className={`nav-item ${currentTab === 'PROJECT_MEMBERS' ? 'active' : ''}`} onClick={() => this.changeTab('PROJECT_MEMBERS')}>
                                        {this.context.t('People in your companies')}
                                    </div>
                                </div>
                            ) : (
                                null
                            )
                        }

                        {
                            currentTab === 'PROJECTS' ? (
                                <Projects
                                    currentProject={currentProject}
                                    changeCurrentProject={this.changeCurrentProject}
                                    changeTab={this.changeTab}
                                    returnOverlayPossitions={this.getProjectOverlayPossitons}
                                    canShowSingleProjectOverlay={this.showSingleProjectOverlay}
                                    toggleAdditionalAprovalForDocsForCurrentProject={this.toggleAdditionalAprovalForDocsForCurrentProject}
                                    toggleAdditionalAprovalForDocsUnderTresholdForCurrentProject={this.toggleAdditionalAprovalForDocsUnderTresholdForCurrentProject}
                                    changeAdditionalDocsAcceptanceTresholdsForCurrentProject={this.changeAdditionalDocsAcceptanceTresholdsForCurrentProject}
                                    updateUsersAllowedToAcceptDocs={this.updateUsersAllowedToAcceptDocs}
                                />
                            ) : (
                                currentTab === 'PROJECT_MEMBERS' ? (
                                    <ProjectMembers currentProject={currentProject} changeTab={this.changeTab} returnOverlayPossitions={this.getProjectMembersOverlayPossitions} />
                                ) : (
                                    currentTab === 'ADD_PROJECT' ? (
                                        <CreateProject currentProject={currentProject} changeTab={this.changeTab} />
                                    ) : (
                                        null
                                    )
                                )
                            )
                        }
                    </div>

                </div>
            )
        } else {
            return null
        }
    }

}

ProjectManagement.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    userCompanies: state.Company.companies,
    userProjects: state.User.userProjects,
    groupedProjects: state.User.groupedProjects,
    currentCompany: state.Company.currentCompany,
    companyProjects: state.Company.companyProjects || [],
    user: state.User.user,
    overlayPreferance: state.User.overlayPreferance || {},

    inviteAndAssignMemberSendRequest: state.Company.inviteAndAssignMemberSendRequest,
    inviteAndAssignMemberGotResponse: state.Company.inviteAndAssignMemberGotResponse,
    inviteAndAssignMemberError: state.Company.inviteAndAssignMemberError,
    inviteAndAssignMemberErrorMsg: state.Company.inviteAndAssignMemberErrorMsg,
})

const mapDispatchToProps = {
    setCurrentCompany: CompanyActions.setCurrentCompany,
    getAllCompanyProjects: CompanyActions.getAllCompanyProjects,
    alertWarn: AlertActions.warning,
    toggleOverlay: UserActions.toggleOverlay,
    switchDashboardViewModel: UserActions.switchDashboardViewModel,

    inviteAndAssignMember: CompanyActions.inviteAndAssignMember,
    resetInviteNewUserMessage: CompanyActions.resetInviteNewUserMessage,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectManagement)