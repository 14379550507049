import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Spinner } from "react-activity";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import TextareaAutosize from 'react-textarea-autosize'
import SimpleBar from "simplebar-react";

import InvoicePreview from "./InvoicePreview";
import InvoiceNumberingSeriesCreator from "./InvoiceNumberingSeriesCreator"
import NewDocItemPopup from './NewDocItemPopup'
import DeleteDocItemPopup from './DeleteDocItemPopup'
import Calendar from "../Calendar";
import DropDownList from '../DropDownList'
import MpkSplitSaleInvoice from './MpkSplitSaleInvoice';

import { CompanyActions } from "../../actions/CompanyActions";
import { AlertActions } from "../../actions/AlertActions";
import { FileActions } from "../../actions/FileActions";

import { getCurrencyRateFromNBP } from "../../helpers/getCurrencyRateFromNBP";
import validateEmail from "../../helpers/validateEmail";
import { splitAddress } from "../../helpers/splitAddress";
import currencyList from '../../helpers/currencyList.json'
import paymentMethodsList from '../../helpers/paymentMethodsList.json'
import waproUsers from '../../helpers/waproUsers.json'
import magDocTypeList from '../../helpers/magDocTypes.json'
import fakirDocTypeList from '../../helpers/fakirDocTypes.json'
import fakirDictionaryList from '../../helpers/fakirDocDictionary.json'
import arrayObjectIndexOf from "../../helpers/indexOfObject";
import allProjectsWithMpk from '../../helpers/MpkSplit/allProjectsWithMpk'

import "../../../src/css/SalesPage.css";
import { GTU_CODE } from "./CorrectionItemForm";
import CountriesList from "../CountriesList";
import NewInvoiceNumberPopUp from "./NewInvoiceNumberPopUp"

export class SalesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentNumber: "",
      documentNumberBeforeEditing: "",
      numberingSeriesId: -1,
      allSeriesDocumentNumbers: [],
      showDocumentNumberSeriesList: false,
      showInvoiceNumberingSeriesPopup: false,
      documentType: "Invoice",
      documentLanguage: 'pl',
      showDocumentLanguageList: false,
      issueDate: moment().format("YYYY-MM-DD"),
      saleDate: moment().format("YYYY-MM-DD"),
      searchBuyerVatId: "",
      buyerName: "",
      buyerVatId: "",
      buyerAddress: "",
      buyerPostCode: "",
      buyerCity: "",
      buyerCountryCode: "PL",
      sellerName: "",
      sellerVatId: "",
      sellerAddress: "",
      sellerPostCode: "",
      sellerCity: "",
      issuePlace: "",
      accountNumber: "",
      bankName: "",
      bankBIC: "",
      accountsList: [],
      invoicePositions: [
        {
          name: "",
          amount: "1",
          unit: "szt.",
          netPrice: "",
          discount: "",
          discountPrice: "",
          vat: "23",
          netValue: "",
          grossValue: "",
          pkwiu: "",
          gtu: ""
        },
      ],
      showPkwiu: false, showGtu: false, showDiscount: false,
      summary: {
        netSum: 0,
        vatSum: 0,
        grossSum: 0,
      },
      paymentMethod: "transfer",
      dueDate: moment().add(7, "days").format("YYYY-MM-DD"),
      paymentStatus: "unpaid",
      currency: "PLN",
      comments: '',

      savedInvoicePositions: [],
      unitListToShow: -1,
      gtuListToShow: -1,
      posListToShow: -1,
      savedDocItems: [],
      savedCounterparties: [],
      invoicesList: [],
      logo: null,
      buyerNotFromPoland: false,
      buyerEmail: "",
      counterpartiesList: [],
      showCounterpartiesList: false,
      showInvoicePreviewPopup: false,
      fileBody: null,
      fileName: null,
      editingExistingInvoice: false,
      editedInvoiceId: null,
      currencyMidPrice: 0,
      showCurrencyCalenrad: false,
      showCountriesList: false,
      buyerCountryCode: 'PL',
      proformaNumber: null,
      isInvoiceNumberChangedManually: false,
      invoiceNumberBeforeChangedByUser: '',
      isSaleDateManuallyChanged: false,

      isAccountantInCurrentCompany: false,
      selectedMagDocType: '',
      selectedFakirDocType: '',
      selectedFakirDocDictionary: '',
      selectedFakirNetAccounts: '',
      netAccountList: [],
      selectedDocDescription: '',
      docDescriptionsList: [],
      showMagDocType: false,
      showFakirDocType: false,
      showFakirDictionary: false,
      showWaproNetAccoutList: false,
      showWaproDocDescriptionList: false,

      newItemView: false,
      newItem: {},
      editItemView: false,
      editedItem: {},
      showDeleteView: false,
      itemToDelete: '',

      sendToKsef: false,
      addMPK: false,
      mpkSplit: [],
    };

    this.magTypeRef = React.createRef()
    this.fakirTypeRef = React.createRef()
    this.fakirDictionaryRef = React.createRef()
    this.netAccountRef = React.createRef()
    this.fakirDescriptionRef = React.createRef()
  }

  componentDidMount() {
    window.addEventListener("keydown", this.escFunction, false);
    const { currentCompany, editingData, user } = this.props;

    if (currentCompany) {
      var magType = "FV", fakirType = "FS", fakirDictionary = "SPR", netAccount = "", docDescription = ""
      if (currentCompany.company_id === 1104) {
        docDescription = "Sprzedaż usług"
      } else {
        if (currentCompany.company_id === 1511) {
          var docDescriptionsList = require('../../helpers/admitad-wapro-descriptions.json')
          docDescription = "Sprzedaż usług"
          fakirDictionary = 'FS'
          netAccount = '720-01'
          this.setState({
            docDescriptionsList: docDescriptionsList
          })
        } else {
          var docDescriptionsList = require('../../helpers/proxy-wapro-descriptions.json')
          this.setState({
            docDescriptionsList: docDescriptionsList
          })
        }
      }

      if (currentCompany.is_accountant) {
        this.props.getCompanyWaproNetAccounts(currentCompany.company_id, (success, accounts) => {
          if (success) {
            this.setState({
              netAccountList: accounts
            })
          }
        })
      }

      this.setState({
        isAccountantInCurrentCompany: currentCompany.is_accountant,
        selectedMagDocType: magType,
        selectedFakirDocType: fakirType,
        selectedFakirDocDictionary: fakirDictionary,
        selectedFakirNetAccounts: netAccount,
        selectedDocDescription: docDescription,
        docDescriptionsList: docDescriptionsList,
      })
    }

    if (editingData) {
      this.setEditingData(editingData);
      this.setState({ counterpartiesList: currentCompany.company.counterparties })

    } else if (currentCompany) this.setSeller();
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentProject !== prevProps.currentProject || this.props.currentCompany !== prevProps.currentCompany) {
      this.setSeller();
    }
    const { editingData } = this.props;
    if (editingData !== prevProps.editingData) {
      if (editingData) {
        this.setEditingData(editingData);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.escFunction, false);
  }

  resetState = () => {
    this.setState({
      documentNumber: "",
      numberingSeriesId: -1,
      allSeriesDocumentNumbers: [],
      documentType: "Invoice",
      issueDate: moment().format("YYYY-MM-DD"),
      saleDate: moment().format("YYYY-MM-DD"),
      searchBuyerVatId: "",
      buyerName: "",
      buyerVatId: "",
      buyerAddress: "",
      buyerPostCode: "",
      buyerCity: "",
      buyerCountryCode: "PL",
      sellerName: "",
      sellerVatId: "",
      sellerAddress: "",
      sellerPostCode: "",
      sellerCity: "",
      issuePlace: "",
      accountNumber: "",
      bankName: "",
      bankBIC: "",
      accountsList: [],
      invoicePositions: [
        {
          name: "",
          amount: 1,
          unit: "szt.",
          netPrice: "",
          discount: "",
          discountPrice: "",
          vat: "23",
          netValue: "",
          grossValue: "",
          pkwiu: "",
          gtu: "",
        },
      ],
      showPkwiu: false, showGtu: false, showDiscount: false,
      summary: {
        netSum: 0,
        vatSum: 0,
        grossSum: 0,
      },
      paymentMethod: "transfer",
      dueDate: moment().add(7, "days").format("YYYY-MM-DD"),
      paymentStatus: "unpaid",
      currency: "PLN",

      savedInvoicePositions: [],
      unitListToShow: -1,
      posListToShow: -1,
      savedDocItems: [],
      savedCounterparties: [],
      invoicesList: [],
      logo: null,
      buyerNotFromPoland: false,
      buyerEmail: "",
      counterpartiesList: [],
      showCounterpartiesList: false,
      showInvoicePreviewPopup: false,
      fileBody: null,
      fileName: null,
      editingExistingInvoice: false,
      editedInvoiceId: null,
      currencyMidPrice: 0,
      sendToKsef: false,
    });
  };

  setEditingData = (data) => {
    if (data.wapro_data && typeof data.wapro_data === 'string') data.wapro_data = JSON.parse(data.wapro_data)
    const { currentCompany, isInvoiceFromProforma, isSimilarInvoice } = this.props;
    const seller = currentCompany.company;
    var buyerSplitAddress = splitAddress(data.buyer_address);
    var sellerSplitAddress = splitAddress(data.seller_address);
    var bank = this.getBankDetails(data.account_number, seller.banks);
    var dueDate
    if (!data.due_date) {
      dueDate = moment().add(7, "days").format("YYYY-MM-DD")
    } else {
      dueDate = moment(data.due_date).format("YYYY-MM-DD")
    }
    if (isInvoiceFromProforma) {
      this.setInvoiceNumber(
        moment().format("YYYY-MM"),
        data.company_id,
        "Invoice"
      );
      dueDate = moment().add(7, 'days').format("YYYY-MM-DD")
    }

    if (isSimilarInvoice) {
      this.setInvoiceNumber(
        moment().format("YYYY-MM"),
        data.company_id,
        "Invoice"
      );

      var dueDateDaysDiff = 7
      var originalIssueDate = moment(data.issue_date)
      var originalDueDate = moment(data.due_date)
      dueDateDaysDiff = originalDueDate.diff(originalIssueDate, 'days')
      dueDate = moment().add(dueDateDaysDiff || 7, 'days').format("YYYY-MM-DD")
    }

    var showPkwiu = false, showGtu = false, showDiscount = false
    data.docItems.map(item => {
      if (item.pkwiu) showPkwiu = true
      if (item.gtu) showGtu = true
      if (item.discount) showDiscount = true
    })
    this.setState({
      documentNumber: isInvoiceFromProforma ? this.state.documentNumber : data.doc_number,
      documentNumberBeforeEditing: isInvoiceFromProforma ? this.state.documentNumber : data.doc_number,
      numberingSeriesId: data.numbering_series_id || -1,
      documentType: isInvoiceFromProforma || isSimilarInvoice ? "Invoice" : this.capitalizeFirstLetter(data.document_type),
      proformaNumber: isInvoiceFromProforma ? data.doc_number : null,
      documentLanguage: data.document_language || 'pl',
      issueDate: isSimilarInvoice || isInvoiceFromProforma ? moment().format("YYYY-MM-DD") : moment(data.issue_date).format("YYYY-MM-DD"),
      saleDate: isSimilarInvoice ? moment().format("YYYY-MM-DD") : moment(data.sale_date).format("YYYY-MM-DD"),
      buyerName: data.buyer_name,
      buyerVatId: data.buyer_vat_id,
      buyerAddress: buyerSplitAddress.address,
      buyerPostCode: buyerSplitAddress.zipCode,
      buyerCity: buyerSplitAddress.city,
      buyerCountryCode: data.counterparty ? data.counterparty.country : "",
      sellerName: data.seller_name,
      sellerVatId: data.seller_vat_id,
      sellerAddress: sellerSplitAddress.address,
      sellerPostCode: sellerSplitAddress.zipCode,
      sellerCity: sellerSplitAddress.city,
      logoURL: seller.logo_url,
      issuePlace: data.issue_place,
      accountNumber: bank.accountNumber,
      bankName: bank.name,
      bankBIC: bank.bic,
      invoicePositions: data.docItems.map((item) => {
        var discountPrice = ""
        if (item.discount) {
          discountPrice = parseFloat(item.discount_price).toFixed(2)
        }
        return {
          id: item.id,
          name: item.name,
          amount: parseFloat(item.amount),
          unit: item.unit,
          netPrice: parseFloat(item.net_price).toFixed(2),
          discount: item.discount ? parseFloat(item.discount).toFixed(2) : "",
          discountPrice: discountPrice,
          vat: item.vat ? item.vat : "23",
          netValue: parseFloat(item.net_value).toFixed(2),
          grossValue: parseFloat(item.gross_value).toFixed(2),
          pkwiu: item.pkwiu,
          gtu: item.gtu,
        };
      }),
      showPkwiu: showPkwiu,
      showGtu: showGtu,
      showDiscount: showDiscount,
      summary: {
        netSum: parseFloat(data.total_net_value),
        vatSum: parseFloat(data.total_vat_value),
        grossSum: parseFloat(data.total_gross_value),
      },
      paymentMethod: 'transfer',
      dueDate: dueDate,
      paymentStatus: "unpaid",
      currency: data.currency,
      buyerNotFromPoland: data.foreign_receiver,

      comments: data.comments,
      splitPayment: data.split_payment,
      reverseCharge: data.reverse_charge,
      metodaKasowa: data.metoda_kasowa,

      currencyMidPrice: data.currency_rate,
      currencyEffectiveDate: data.currency_date ? moment(data.currency_date).format("YYYY-MM-DD") : null,
      currencyTableNumber: data.currency_table,

      editingExistingInvoice: isInvoiceFromProforma ? false : isSimilarInvoice ? false : true,
      editedInvoiceId: data.id,
      buyerCountryCode: data.counterparty?.country || '',
      accountsList: seller.banks,
      buyerEmail: this.concatenationMails(data.counterparty.email ? data.counterparty.email : []),

      selectedMagDocType: data.wapro_data ? data.wapro_data.magType : "",
      selectedFakirDocType: data.wapro_data ? data.wapro_data.fakirType : "",
      selectedFakirDocDictionary: data.wapro_data ? data.wapro_data.fakirDictionary : "",
      selectedFakirNetAccounts: data.wapro_data ? data.wapro_data.netAccount : "",
      selectedDocDescription: data.wapro_data ? data.wapro_data.docDescription : "",
      sendToKsef: false,
    });

    if (isSimilarInvoice && data.currency !== 'PLN') this.setCurrencyMidPrice(data.currency, moment())
  };

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  getBankDetails = (accountNumber, companyBanks) => {
    var bank = {
      accountNumber: accountNumber,
      name: "",
      bic: "",
    };

    var bankInstance = companyBanks.find(
      (b) => b.account_number === accountNumber
    );
    if (bankInstance) {
      bank.name = bankInstance.bank_name;
      bank.bic = bankInstance.bic;
    }

    return bank;
  };

  searchVatAPI = () => {
    const { waitingForVatApiReponse, searchBuyerVatId } = this.state;
    if (!waitingForVatApiReponse) {
      if (searchBuyerVatId.length === 10) {
        this.setState({
          waitingForVatApiReponse: true,
        });

        fetch(
          `https://wl-api.mf.gov.pl/api/search/nip/${searchBuyerVatId.replace(
            /[^0-9]/gi,
            ""
          )}?date=${moment().format("YYYY-MM-DD")}`,
          { method: "get" }
        )
          .then((response) => response.json())
          .then((json) => {
            if (json && json.result && json.result.subject) {
              var company = json.result.subject;
              var companyAddress =
                json.result.subject.residenceAddress ||
                json.result.subject.workingAddress;
              var address = "";
              var zipCode = "";
              var city = "";
              if (companyAddress) {
                const sAddress = splitAddress(companyAddress);
                address = sAddress.address;
                zipCode = sAddress.zipCode;
                city = sAddress.city;
              }

              var name = company.name
                .replace(/SPÓŁKA KOMANDYTOWA/gi, "")
                .replace(/SPÓŁKA CYWILNA/gi, "")
                .replace(/SPÓŁKA AKCYJNA/gi, "")
                .replace(/SPÓŁKA DORADZTWA PODATKOWEGO/gi, "")
                .replace(/SPÓŁKA PARTNERSKA/gi, "")
                .replace(/SPÓŁKA JAWNA/gi, "")
                .replace(/SP. Z O.O./gi, "")
                .replace(/SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ/gi, "")
                .replace(/SP. Z 0.0./gi, "")
                .replace(/POLSKA/gi, "")
                .replace(/\'/gm, "")
                .replace(/\(/gm, "")
                .replace(/\)/gm, "")
                .replace(" - ", "-");

              name = name.trim();
              var code = this.generateCode(name);

              this.setState({
                buyerName: company.name,
                buyerVatId: company.nip,
                buyerAddress: address,
                buyerPostCode: zipCode,
                buyerCity: city,
                waitingForVatApiReponse: false,
                showVatIDSearchPopup: false,
              });
            } else {
              this.props.alertWarn(
                this.context.t(
                  "No company with the given VatID was found. Make sure the number you entered is correct"
                )
              );
              this.setState({
                waitingForVatApiReponse: false,
              });
            }
          })
          .catch((err) => {
            this.props.alertWarn(
              this.context.t(
                "An error occurred while searching, please enter the buyer details manually or try again later."
              )
            );
            this.setState({
              waitingForVatApiReponse: false,
              showVatIDSearchPopup: false,
            });
          });
      } else {
        this.props.alertWarn(
          this.context.t("Given tax identification number is incorrect.")
        );
      }
    }
  };

  setDocType = (docType) => {
    this.setState({
      documentType: docType,
      showDocumentTypeList: false,
    });
    this.setInvoiceNumber(
      moment(this.state.issueDate, "YYYY-MM-DD").format("YYYY-MM"),
      this.props.currentCompany.company.id,
      docType
    );
  };

  getFullLanguageName = langCode => {
    if (langCode === 'pl') return 'Polish'
    else return 'English'
  }

  setDocNumberSeries = series => {
    this.setState({
      documentNumber: series.number,
      numberingSeriesId: series.seriesId,
      showDocumentNumberSeriesList: false,
    })
  }

  setDocLanguage = langCode => {
    this.setState({
      documentLanguage: langCode,
      showDocumentLanguageList: false
    })
  }

  clickOnDatePicker = (e, name) => {
    if (e.target.className.includes("day-cell")) {
      if (name === "issueDate") {
        this.setState({
          showIssueDateCalendar: false,
        });
      } else if (name === "dueDate") {
        this.setState({
          showDueDateCalendar: false,
        });
      } else if (name === "saleDate") {
        this.setState({
          showSaleDateCalendar: false,
        });
      }
    }
  };

  selectSaleDate = (date) => {
    this.setState({
      isSaleDateManuallyChanged: true,
      saleDate: moment(date).format("YYYY-MM-DD"),
    });

    const { currency } = this.state
    if (currency && currency !== 'PLN') this.setCurrencyMidPrice(currency, date);
  };

  onInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (name === 'documentNumber') {
      this.setState({ isInvoiceNumberChangedManually: true })
      if (this.state.isInvoiceNumberChangedManually === false) this.props.alertWarn(this.context.t("According to the current numbering mask, the next number should be: {n}.", { n: this.state.invoiceNumberBeforeChangedByUser }));
    }
    this.setState({ [name]: value });
    if (name === "buyerName") this.setState({ showCounterpartiesList: true });
  };

  showSearchVatIdPopup = () => {
    window.addEventListener("keydown", this.searchOnEnter, false);
    this.setState({
      showVatIDSearchPopup: true,
    });
  };

  addPossition = () => {
    var { invoicePositions } = this.state;
    invoicePositions.push({
      name: "",
      amount: 1,
      unit: "szt.",
      netPrice: "",
      discount: "",
      discountPrice: "",
      vat: "23",
      vatAmount: "",
      netValue: "",
      grossValue: "",
      pkwiu: "",
    });

    this.setState({
      invoicePositions: invoicePositions,
    });
  };

  onInputChangeInvoicePossition = (e, index) => {
    var { invoicePositions } = this.state;
    var { name, value } = e.target;
    name = name.replace(/[^a-zA-Z]/g, "");

    if (name === "amount") {
      value = value.replace(/[^0-9,.-]/gm, "").replace(",", ".");
    }
    if (name === "vat") {
      value = value.replace(/[^0-9]/gm, "");
    }
    if (name === "netPrice" || name === "netValue" || name === "grossValue" || name === "discount") {
      value = value.replace(/[^0-9\,\.]/gm, "").replace(",", ".");
    }

    invoicePositions[index][name] = value;

    this.setState({
      invoicePositions: invoicePositions,
    });
  };

  setItem = (item, index) => {
    var { invoicePositions, saleDate, documentLanguage } = this.state;
    item.name = item.name.replaceAll('DD', moment(saleDate).format('DD'))
    item.name = item.name.replaceAll('MMM', moment(saleDate).format('MMMM'))
    item.name = item.name.replaceAll('MM', moment(saleDate).format('MM'))
    item.name = item.name.replaceAll('KK', `${moment(saleDate).locale(documentLanguage).format('Qo')} ${documentLanguage === 'pl' ? 'kwartał' : 'quarter'}`)
    item.name = item.name.replaceAll('RRRR', moment(saleDate).format('YYYY'))
    item.name = item.name.replaceAll('RR', moment(saleDate).format('YY'))
    item.name = item.name.replaceAll('YYYY', moment(saleDate).format('YYYY'))
    item.name = item.name.replaceAll('YY', moment(saleDate).format('YY'))
    invoicePositions[index] = { ...item, discount: "", discountPrice: "" };

    if (item.pkwiu) {
      this.setState({
        showPkwiu: true
      })
    }

    if (item.gtu) {
      this.setState({
        showGtu: true
      })
    }

    this.setState({
      posListToShow: -1,
    });

    var summary = {
      netSum: 0,
      vatSum: 0,
      grossSum: 0,
    };

    invoicePositions.map((pos) => {
      if (pos.netValue && pos.grossValue && pos.vat) {
        var vat = isNaN(parseFloat(pos.vat)) ? 0.0 : parseFloat(pos.vat);
        summary.vatSum += parseFloat(pos.netValue) * (vat / 100);
        summary.netSum += parseFloat(pos.netValue);
        summary.grossSum += parseFloat(pos.grossValue);
      }
    });

    this.setState({
      unitListToShow: -1,
      invoicePositions: invoicePositions,
      summary: summary,
    });

    if (index === 0) {
      const { currentCompany } = this.props
      const { isAccountantInCurrentCompany } = this.state

      if (isAccountantInCurrentCompany && currentCompany.company_id !== 1511 && currentCompany.company_id !== 1104) {
        this.setState({
          selectedDocDescription: item.name
        })
      }
    }
  };

  updateSummary = (e) => {
    var { name, value } = e.target;
    var { invoicePositions } = this.state;
    var propName = name.replace(/[^a-zA-Z]/g, "");
    var posIndex = name.replace(`${propName}-`, "");

    if (posIndex > -1 && invoicePositions[posIndex].netPrice) {
      var newNetPrice = parseFloat(invoicePositions[posIndex].netPrice).toFixed(2),
        newNetValue = parseFloat(invoicePositions[posIndex].netValue).toFixed(2),
        newGrossValue = parseFloat(invoicePositions[posIndex].grossValue).toFixed(2),
        newVat = isNaN(parseFloat(invoicePositions[posIndex].vat)) ? 0.0 : parseFloat(invoicePositions[posIndex].vat),
        newAmount = invoicePositions[posIndex].amount,
        discount = isNaN(parseFloat(invoicePositions[posIndex].discount)) ? 0.0 : parseFloat(invoicePositions[posIndex].discount),
        newDiscountPrice = newNetPrice ? parseFloat(newNetPrice) * (1 - discount / 100) : 0


      if (propName === "amount" || propName === "vat" || propName === "netPrice" || propName === "discount") {
        if (newDiscountPrice) {
          newNetValue = (parseFloat(newDiscountPrice) * parseFloat(newAmount)).toFixed(2);
          newGrossValue = ((parseFloat(newNetValue) * (100 + parseFloat(newVat))) / 100).toFixed(2);
        }
      } else if (propName === "netValue") {
        newGrossValue = ((parseFloat(newNetValue) * (100 + parseFloat(newVat))) / 100).toFixed(2)
        newDiscountPrice = (parseFloat(newNetValue) / parseFloat(newAmount)).toFixed(2)
        newNetPrice = (parseFloat(newDiscountPrice) / (1 - discount / 100)).toFixed(2)
      } else if (propName === "grossValue") {
        newNetValue = (parseFloat(newGrossValue) / ((100 + parseFloat(newVat)) / 100)).toFixed(2)
        newDiscountPrice = (parseFloat(newNetValue) / parseFloat(newAmount)).toFixed(2)
        newNetPrice = (parseFloat(newDiscountPrice) / (1 - discount / 100)).toFixed(2)
      }

      invoicePositions[posIndex].amount = newAmount
      invoicePositions[posIndex].netPrice = newNetPrice
      invoicePositions[posIndex].discountPrice = parseFloat(newDiscountPrice).toFixed(2)
      invoicePositions[posIndex].netValue = newNetValue
      invoicePositions[posIndex].grossValue = newGrossValue

      var summary = {
        netSum: 0,
        vatSum: 0,
        grossSum: 0,
      };

      invoicePositions.map((pos) => {
        if (pos.netValue && pos.grossValue && pos.vat) {
          var vat = isNaN(parseFloat(pos.vat)) ? 0.0 : parseFloat(pos.vat);
          summary.vatSum += parseFloat(parseFloat(parseFloat(pos.netValue) * (vat / 100)).toFixed(2));
          summary.netSum += parseFloat(pos.netValue);
          summary.grossSum += parseFloat(pos.grossValue);
        }
      });

      this.setState({
        invoicePositions: invoicePositions,
        summary: summary,
      });
    }
  };

  setUnit = (unit, index) => {
    var { invoicePositions } = this.state;
    invoicePositions[index].unit = unit;

    this.setState({
      unitListToShow: -1,
      invoicePositions: invoicePositions,
    });
  };

  setGtu = (gtu, index) => {
    var { invoicePositions } = this.state;
    invoicePositions[index].gtu = gtu;

    this.setState({
      gtuListToShow: -1,
      invoicePositions: invoicePositions,
    });
  };

  setVat = (vat, index) => {
    var { invoicePositions } = this.state;
    invoicePositions[index].vat = vat;

    this.setState({
      invoicePositions: invoicePositions,
      vatListToShow: -1,
    });

    if (vat === "oo") {
      this.setState({
        reverseCharge: true,
      });
    }

    this.updateSummary({ target: { name: `vat-${index}`, value: vat } });
  };

  setCurrencyMidPrice = async (currency, issueDate) => {
    const date = moment(issueDate).subtract(1, "day");
    const { rate, effectiveDate, tableNumber } = await getCurrencyRateFromNBP(currency, date);
    this.setState({
      currencyEffectiveDate: effectiveDate,
      currencyMidPrice: rate,
      currencyTableNumber: tableNumber,
      showCurrencyCalenrad: false,
    });
  };

  setCurrency = (currency) => {
    this.setState({
      showCurrencyList: false,
      currency: currency,
    });

    if (currency !== "PLN") {
      this.setCurrencyMidPrice(currency, this.state.saleDate);
    }

    const { accountNumber, accountsList } = this.state;
    var neetToChangeAccountNumber = false;
    var potentialNewAccountNumber = "";
    var potentialBankName = "",
      potentialBankBIC = "";
    accountsList.forEach((a) => {
      if (a.account_number === accountNumber) {
        if (a.currency !== currency) neetToChangeAccountNumber = true;
      }

      if (a.currency === currency && !potentialNewAccountNumber) {
        potentialNewAccountNumber = a.account_number;
        potentialBankName = a.bank_name;
        potentialBankBIC = a.bic;
      }
    });

    if (neetToChangeAccountNumber && potentialNewAccountNumber) {
      this.setState({
        accountNumber: potentialNewAccountNumber,
        bankName: potentialBankName,
        bankBIC: potentialBankBIC,
      });
    }
  };

  selectNewCurrencyDay = (date) => {
    if (this.state.currency !== "PLN") {
      this.setCurrencyMidPrice(
        this.state.currency,
        moment(date).add(1, "day").format("YYYY-MM-DD")
      );
    }
  };

  selectDueDate = (date) => {
    this.setState({
      dueDate: moment(date).format("YYYY-MM-DD"),
    });
  };

  splitPaymentClick = () => {
    var { invoicePositions, splitPayment } = this.state;
    var updateSummary = false;
    for (var i = 0; i < invoicePositions.length; i++) {
      if (isNaN(parseFloat(invoicePositions[i].vat))) {
        invoicePositions[i].vat = "23";
        updateSummary = true;
      }
    }

    this.setState({
      invoicePositions: invoicePositions,
      splitPayment: !splitPayment,
      reverseCharge: false,
    });

    if (updateSummary) {
      for (var i = 0; i < invoicePositions.length; i++) {
        this.updateSummary({ target: { name: `vat-${i}`, value: "oo" } });
      }
    }
  };

  reverseChargeClick = () => {
    var { invoicePositions, reverseCharge } = this.state;

    for (var i = 0; i < invoicePositions.length; i++) {
      if (reverseCharge) invoicePositions[i].vat = "23";
      else invoicePositions[i].vat = "oo";
    }

    this.setState({
      invoicePositions: invoicePositions,
      reverseCharge: !reverseCharge,
      splitPayment: false,
    });

    for (var i = 0; i < invoicePositions.length; i++) {
      this.updateSummary({ target: { name: `vat-${i}`, value: "oo" } });
    }
  };

  metodaKasowaClick = () => {
    var { metodaKasowa } = this.state;

    this.setState({
      metodaKasowa: !metodaKasowa,
    });
  };

  setInvoiceNumber = (month, companyId, docType) => {
    const { sellerId, documentType, isInvoiceNumberChangedManually, numberingSeriesId } = this.state
    if (docType) docType = docType.toLowerCase().replace(" ", "-");
    this.props.getDocumentNumberForNewInvoice(
      month,
      companyId || sellerId,
      docType || documentType.toLowerCase().replace(" ", "-"),
      (nextNumbers, noSeriesDefined) => {
        if (nextNumbers && nextNumbers.length > 0) {
          var choosenSeries = null
          console.log(numberingSeriesId)
          console.log(nextNumbers)
          if (numberingSeriesId > -1) {
            choosenSeries = nextNumbers.find(s => s.seriesId === numberingSeriesId)
            console.log(nextNumbers.find(s => s.seriesId === numberingSeriesId))
          }
          if (!choosenSeries) choosenSeries = nextNumbers[0]
          if (!isInvoiceNumberChangedManually) {
            this.setState({
              documentNumber: choosenSeries.number,
              numberingSeriesId: choosenSeries.seriesId,
              allSeriesDocumentNumbers: nextNumbers,
              invoiceNumberBeforeChangedByUser: choosenSeries.number
            });
          } else {
            this.setState({
              invoiceNumberBeforeChangedByUser: choosenSeries.number,
              numberingSeriesId: choosenSeries.seriesId,
              allSeriesDocumentNumbers: nextNumbers
            });
          }
        } else if (noSeriesDefined) {
          this.setState({
            showInvoiceNumberingSeriesPopup: true
          })
        } else {
          this.props.alertWarn(
            this.context.t(
              "Failed to generate invoice number."
            )
          );
        }
      }
    );
  };

  getSavedDocItems = (companyId) => {
    this.props.getSavedDocItems(
      companyId || this.state.sellerId,
      (savedDocItems) => {
        if (savedDocItems) {
          this.setState({
            savedDocItems: savedDocItems,
          });
        }
      }
    );
  };

  getSavedCounterparties = (companyId) => { };

  hasAncestor = (element, id) => {
    while (element) {
      if (element.id && element.id === id) return true;
      element = element.parentNode;
    }
    return false;
  };

  closeAllSuggestions = (e) => {
    const { showMagDocType, showFakirDocType, showFakirDictionary, showWaproNetAccoutList, showWaproDocDescriptionList } = this.state
    console.log(showMagDocType, showFakirDocType, showFakirDictionary, showWaproNetAccoutList, showWaproDocDescriptionList)
    if (showMagDocType && (e.keyCode === 27 || (this.magTypeRef && this.magTypeRef.current && !this.magTypeRef.current.contains(e.target)))) {
      this.setState({
        showMagDocType: false
      })
    } else if (showFakirDocType && (e.keyCode === 27 || (this.fakirTypeRef && this.fakirTypeRef.current && !this.fakirTypeRef.current.contains(e.target)))) {
      this.setState({
        showFakirDocType: false
      })
    } else if (showFakirDictionary && (e.keyCode === 27 || (this.fakirDictionaryRef && this.fakirDictionaryRef.current && !this.fakirDictionaryRef.current.contains(e.target)))) {
      this.setState({
        showFakirDictionary: false
      })
    } else if (showWaproNetAccoutList && (e.keyCode === 27 || (this.netAccountRef && this.netAccountRef.current && !this.netAccountRef.current.contains(e.target)))) {
      this.setState({
        showWaproNetAccoutList: false
      })
    } else if (showWaproDocDescriptionList && (e.keyCode === 27 || (this.fakirDescriptionRef && this.fakirDescriptionRef.current && !this.fakirDescriptionRef.current.contains(e.target)))) {
      this.setState({
        showWaproDocDescriptionList: false
      })
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "documentTypeContainer")) && this.state.showDocumentTypeList) {
      this.setState({
        showDocumentTypeList: false,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "documentNumberContainer")) && this.state.showDocumentNumberSeriesList) {
      this.setState({
        showDocumentNumberSeriesList: false,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "documentLanguageContainer")) && this.state.showDocumentLanguageList) {
      this.setState({
        showDocumentLanguageList: false,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "issueDatePickerContainer")) && this.state.showIssueDateCalendar) {
      this.setState({
        showIssueDateCalendar: false,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "saleDatePickerContainer")) && this.state.showSaleDateCalendar) {
      this.setState({
        showSaleDateCalendar: false,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "dueDatePickerContainer")) && this.state.showDueDateCalendar) {
      this.setState({
        showDueDateCalendar: false,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "paymentMethodContainer")) && this.state.showPaymentMethodList) {
      this.setState({
        showPaymentMethodList: false,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "accountNumberContainer")) && this.state.showAccountsList) {
      this.setState({
        showAccountsList: false,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "paymentStatusContainer")) && this.state.showPaymentStatusList) {
      this.setState({
        showPaymentStatusList: false,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "currencyContainer")) && this.state.showCurrencyList) {
      this.setState({
        showCurrencyList: false,
      });
    } else if (this.state.unitListToShow > -1) {
      this.setState({
        unitListToShow: -1,
      });
    } else if (this.state.gtuListToShow > -1) {
      this.setState({
        gtuListToShow: -1,
      });
    } else if (this.state.posListToShow > -1) {
      this.setState({
        posListToShow: -1,
      });
    } else if (this.state.vatListToShow > -1) {
      this.setState({
        vatListToShow: -1,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "popup-card")) && this.state.showVatIDSearchPopup) {
      this.hideSearchVatIdPopup();
    } else if (e.keyCode === 27 || (!this.hasAncestor(e.target, "item counterparty-item") && this.state.showCounterpartiesList)) {
      this.setState({ showCounterpartiesList: false });
    } else if (e.keyCode === 27 || (!(this.hasAncestor(e.target, "currencyCalendarContainer") || this.hasAncestor(e.target, "currencyCalendarButton")) && this.state.showCurrencyCalenrad)) {
      this.setState({ showCurrencyCalenrad: false });
    }
  };

  escFunction = (e) => {
    if (e.keyCode === 27) this.closeAllSuggestions(e);
  };

  setSeller = () => {
    const { currentCompany } = this.props
    const { sellerId } = this.state
    if (currentCompany && sellerId !== currentCompany.company_id) {
      var company = currentCompany.company;
      var address = "";
      var zipCode = "";
      var city = "";
      var logoURL = "";
      var logo = "";
      if (company.address) {
        if (/, [0-9]{2}-[0-9]{3}/.test(company.address)) {
          address = company.address.split(",")[0];
          zipCode = company.address.match(/[0-9]{2}-[0-9]{3}/) ? company.address.match(/[0-9]{2}-[0-9]{3}/)[0] : "";
          city = company.address.replace(address + ", " + zipCode, "").trim();
        }
      }

      var accountNumber = "", bankName = "", bankBIC = "";

      if (company.banks && company.banks.length > 0) {
        accountNumber = company.banks[0].account_number;
        bankName = company.banks[0].bank_name;
        bankBIC = company.banks[0].bic;
      }

      if (company.logo && company.logo_url) {
        logoURL = company.logo_url;
        logo = company.logo;
      }

      this.setState({
        sellerId: company.id,
        sellerName: company.name,
        sellerVatId: company.vat_id,
        sellerAddress: address,
        sellerPostCode: zipCode,
        sellerCity: city,
        issuePlace: city,
        accountNumber: accountNumber,
        bankName: bankName,
        bankBIC: bankBIC,
        accountsList: company.banks,
        logoURL: logoURL,
        logo: logo,
        counterpartiesList: company.counterparties,
      });
      this.setInvoiceNumber(moment().format("YYYY-MM"), company.id);
      this.getSavedDocItems(company.id);
      this.getSavedCounterparties(company.id);
    }
  };

  splitEmails = (text) => {
    return text.replaceAll(";", ",").replaceAll(" ", "").split(",");
  };

  removeDuplicatesFromArray = (data) => {
    const noDuplicates = [];
    data.map((element) => {
      if (!noDuplicates.includes(element)) {
        noDuplicates.push(element);
      }
    });
    return noDuplicates;
  };

  concatenationMails = (arrayMail) => {
    let concatenatedMails = "";
    arrayMail.map((email, index) => {
      if (arrayMail.length === 1) concatenatedMails = `${email}`;
      if (arrayMail.length > 1) {
        if (index === 0) {
          concatenatedMails = `${email} ,`;
        } else if (index < arrayMail.length - 1) {
          concatenatedMails += `${email} ,`;
        }
        if (index === arrayMail.length - 1) concatenatedMails += `${email}`;
      }
    });
    return concatenatedMails;
  };

  validateInvoiceData = () => {
    var valid = true;
    const {
      documentNumber,
      issueDate,
      saleDate,
      sellerName,
      sellerVatId,
      sellerAddress,
      sellerPostCode,
      sellerCity,
      buyerName,
      buyerVatId,
      buyerAddress,
      buyerPostCode,
      buyerCountryCode,
      buyerCity,
      invoicePositions,
      paymentMethod,
      accountNumber,
      dueDate,
      paymentStatus,
      buyerNotFromPoland,
      buyerEmail,

      addMPK
    } = this.state;

    if (!documentNumber) {
      valid = false;
      this.props.alertWarn(this.context.t("Document number is required"));
    }

    if (!issueDate) {
      valid = false;
      this.props.alertWarn(this.context.t("Issue date is required"));
    }

    // if(!issuePlace) {
    //     valid = false
    //     this.props.alertWarn(this.context.t('Issue place is required.'))
    // }

    if (!saleDate) {
      valid = false;
      this.props.alertWarn(this.context.t("Sale date is required"));
    }

    if (!sellerName) {
      valid = false;
      this.props.alertWarn(this.context.t("Seller name is required."));
    }

    if (!sellerVatId) {
      valid = false;
      this.props.alertWarn(this.context.t("Seller VatID is required."));
    }

    if (!sellerAddress) {
      valid = false;
      this.props.alertWarn(this.context.t("Seller address is required."));
    } else if (!sellerCity) {
      valid = false;
      this.props.alertWarn(this.context.t("Seller address is required."));
    }

    if (!sellerPostCode) {
      valid = false;
      this.props.alertWarn(this.context.t("Seller ZIP code is required."));
    } else if (!/[0-9]{2}-[0-9]{3}/.test(sellerPostCode)) {
      valid = false;
      this.props.alertWarn(this.context.t("Seller ZIP code is invalid."));
    }

    if (!buyerName) {
      valid = false;
      this.props.alertWarn(this.context.t("Buyer name is required."));
    }

    if (!buyerNotFromPoland) {
      if (!buyerPostCode) {
        valid = false;
        this.props.alertWarn(this.context.t("Buyer ZIP code is required."));
      } else if (!/[0-9]{2}-[0-9]{3}/.test(buyerPostCode)) {
        valid = false;
        this.props.alertWarn(this.context.t("Buyer ZIP code is invalid."));
      }
    } else {
      if (!buyerCountryCode) {
        valid = false;
        this.props.alertWarn(this.context.t("Buyer country code is required."));
      } else if (!/[A-Z]{2}/.test(buyerCountryCode)) {
        valid = false;
        this.props.alertWarn(
          this.context.t(
            "Buyer country code is invalid. The country code should consist of two capital letters. Find out more by hovering over the question mark icon."
          )
        );
      }
    }

    invoicePositions.forEach((p) => {
      if (!p.name || !p.amount || !p.netPrice || !p.vat || !p.netValue || !p.grossValue) {
        valid = false;
        this.props.alertWarn(this.context.t("Invoice item is incomplete."));
      }
    });

    // paymentMethod, accountNumber, dueDate, paymentStatus

    if (paymentMethod === "transfer" && !accountNumber) {
      valid = false;
      this.props.alertWarn(this.context.t("Account number is required."));
    }

    if (paymentStatus === "unpaid" && !dueDate) {
      valid = false;
      this.props.alertWarn(this.context.t("Due date is required"));
    }

    if (dueDate < issueDate) {
      valid = false;
      this.props.alertWarn(this.context.t("Due date is invalid."));
    }

    if (buyerEmail) {
      const emailsArray = this.splitEmails(buyerEmail);
      emailsArray.forEach((email, index) => {
        if (!validateEmail(email)) {
          valid = false;
          let ordinalNumber;
          switch (index + 1) {
            case 1:
              ordinalNumber = this.context.t("1st");
              break;
            case 2:
              ordinalNumber = this.context.t("2nd");
              break;
            case 3:
              ordinalNumber = this.context.t("3rd");
              break;
            case 4:
              ordinalNumber = this.context.t("4th");
              break;
            case 5:
              ordinalNumber = this.context.t("5th");
              break;
            case 6:
              ordinalNumber = this.context.t("6th");
              break;
            case 7:
              ordinalNumber = this.context.t("7th");
              break;
            case 8:
              ordinalNumber = this.context.t("8th");
              break;
            case 9:
              ordinalNumber = this.context.t("9th");
              break;
            case 10:
              ordinalNumber = this.context.t("10th");
              break;
            default:
              ordinalNumber = this.context.t("after the tenth e-mail");
          }
          this.props.alertWarn(
            ordinalNumber + " " + this.context.t("e-mail is invalid.")
          );
        }
      });
      const noDuplicates = this.removeDuplicatesFromArray(emailsArray);
      this.setState({ buyerEmail: this.concatenationMails(noDuplicates) });
    }

    if (addMPK && !this.mpkSplitIsValie(this.state.summary.netSum)) {
      valid = false
    }

    return valid;
  };

  mpkSplitIsValie = (totalNetAmount) => {
    const { mpkSplit } = this.state
    var isValid = true
    if (mpkSplit) {
      var splitNetSum = 0
      mpkSplit.forEach(split => {
        if (split.netAmount) {
          splitNetSum += parseFloat(split.netAmount)
        } else {
          isValid = false
          this.props.alertWarn('Fill in the net amount in controlling dimension split')
        }

        if (!split.mpkLevel1) {
          isValid = false
          this.props.alertWarn('Controlling dimension at the first level is required')
        }
      })

      if (
        parseFloat(totalNetAmount).toFixed(2) !== parseFloat(splitNetSum).toFixed(2)
      ) {
        isValid = false
        this.props.alertWarn(this.context.t(
          'The controlling dimension split is incorrect. The net sum of the division is {n1}, and the net amount entered in the form is {n2}',
          {
            n1: parseFloat(totalNetAmount).toFixed(2),
            n2: parseFloat(splitNetSum).toFixed(2)
          }
        ), 15000)
      }

      return isValid
    }
    return true
  }

  clearForm = () => {
    this.setState({
      documentNumber: "",
      issueDate: moment().format("YYYY-MM-DD"),
      saleDate: moment().format("YYYY-MM-DD"),
      searchBuyerVatId: "",
      buyerName: "",
      buyerVatId: "",
      buyerAddress: "",
      buyerPostCode: "",
      buyerCity: "",
      invoicePositions: [
        {
          name: "",
          amount: 1,
          unit: "szt.",
          netPrice: "",
          discount: "",
          vat: "23",
          netValue: "",
          grossValue: "",
        },
      ],
      showPkwiu: false, showGtu: false, showDiscount: false,
      summary: {
        netSum: 0,
        vatSum: 0,
        grossSum: 0,
      },
      paymentMethod: "transfer",
      dueDate: moment().add(7, "days").format("YYYY-MM-DD"),
      paymentStatus: "unpaid",
      currency: "PLN",
      savedInvoicePositions: [],
      unitListToShow: -1,
      gtuListToShow: -1,
      posListToShow: -1,
      buyerNotFromPoland: false,
      splitPayment: false,
      reverseCharge: false,
      metodaKasowa: false,
      buyerEmail: "",
    });
  };

  checkCorrectInvoiceNumber = async () => {
    const {
      issueDate,
      documentType,
      documentNumber,
      numberingSeriesId,
      editingExistingInvoice,
      documentNumberBeforeEditing
    } = this.state;
    const { currentCompany } = this.props;
    const month = moment(issueDate).format("YYYY-MM")
    const invoiceNumber = documentNumber
    const companyId = currentCompany.company_id
    const docType = documentType.toLocaleLowerCase().replace(" ", "-")

    await this.props.checkCorrectInvoiceNumber(month, invoiceNumber, companyId, docType, numberingSeriesId, editingExistingInvoice, documentNumberBeforeEditing)
  }

  generateInvoice = async () => {
    if (this.validateInvoiceData()) {
      const {
        documentType,
        documentNumber,
        documentNumberBeforeEditing,
        numberingSeriesId,
        documentLanguage,
        issueDate,
        issuePlace,
        saleDate,
        sellerName,
        sellerVatId,
        sellerAddress,
        sellerPostCode,
        sellerCity,
        buyerName,
        buyerVatId,
        buyerAddress,
        buyerPostCode,
        buyerCity,
        buyerCountryCode,
        buyerEmail,
        invoicePositions,
        summary,
        paymentMethod,
        accountNumber,
        bankName,
        bankBIC,
        dueDate,
        paymentStatus,
        currency,
        logo,
        buyerNotFromPoland,
        comments,
        splitPayment,
        reverseCharge,
        metodaKasowa,
        editedInvoiceId,
        editingExistingInvoice,

        currencyMidPrice,
        currencyEffectiveDate,
        currencyTableNumber,
        proformaNumber,
        sendToKsef,

        addMPK, mpkSplit
      } = this.state;

      this.props.handleLoadScreen(true)
      await this.checkCorrectInvoiceNumber()
      if (this.props.isNewInvoiceNumberPopupOpen) {
        this.props.handleLoadScreen(false)
        return
      }


      const { currentCompany, currentProject } = this.props;
      var invoiceData = {
        docType: documentType.toLocaleLowerCase().replace(" ", "-"),
        documentNumber: documentNumber,
        numberingSeriesId: numberingSeriesId,
        documentLanguage: documentLanguage,
        issueDate: issueDate,
        issuePlace: issuePlace,
        saleDate: saleDate,
        sellerName: sellerName,
        sellerVatId: sellerVatId,
        sellerAddress: sellerAddress,
        sellerPostCode: sellerPostCode,
        sellerCity: sellerCity,
        buyerName: buyerName,
        buyerVatId: buyerVatId,
        buyerAddress: buyerAddress,
        buyerPostCode: buyerPostCode,
        buyerCity: buyerCity,
        buyerCountryCode: buyerCountryCode,
        buyerEmail: buyerEmail,
        invoicePositions: invoicePositions,
        summary: summary,
        paymentMethod: paymentMethod,
        accountNumber: accountNumber,
        bankName: bankName,
        bankBIC: bankBIC,
        dueDate: dueDate,
        paymentStatus: paymentStatus,
        currency: currency,
        logo: logo,
        buyerNotFromPoland: buyerNotFromPoland,
        comment: comments,
        splitPayment: splitPayment,
        reverseCharge: reverseCharge,
        metodaKasowa: metodaKasowa,

        currencyRate: currencyMidPrice,
        currencyDate: currencyEffectiveDate,
        currencyTable: currencyTableNumber,
        isInvoiceFromProforma: this.props.isInvoiceFromProforma,
        proformaNumber: this.props.isInvoiceFromProforma ? proformaNumber : null,
        sendToKsef: sendToKsef || false,
      };

      const { selectedMagDocType, selectedFakirDocType, selectedFakirDocDictionary, selectedFakirNetAccounts, selectedDocDescription, isAccountantInCurrentCompany } = this.state




      if (isAccountantInCurrentCompany) {
        var waproData = {
          magType: selectedMagDocType,
          fakirType: selectedFakirDocType,
          fakirDictionary: selectedFakirDocDictionary,
          netAccount: selectedFakirNetAccounts || "",
          docDescription: selectedDocDescription || "Sprzedaż usług"
        }

        invoiceData.waproData = waproData
      }
      if (editingExistingInvoice) {
        this.props.editInvoice(
          invoiceData,
          currentCompany.company_id,
          currentProject.id,
          editedInvoiceId,
          (success, fileBody, fileName, id) => {
            if (success) {
              this.setState({
                fileBody,
                fileName,
                showInvoicePreviewPopup: true,
                editedInvoiceId: id,
              });
            }
          }
        );
      } else {
        this.props.generateInvoice(
          invoiceData,
          currentCompany.company_id,
          currentProject.id,
          (success, fileBody, fileName, id, generatedInvoice) => {
            if (success) {
              this.setState({
                fileBody,
                fileName,
                showInvoicePreviewPopup: true,
                editedInvoiceId: id,
              });

              if (addMPK && generatedInvoice.file_id) {
                mpkSplit.forEach(split => {
                  this.props.addMpkSplitInstance(generatedInvoice.file_id, 0, split, () => { })
                })
              }
            }
          }
        );
        this.setState({ editingExistingInvoice: false });
      }
    }
  };

  showDatePicker = (e, name) => {
    if (name === "issueDate") {
      this.setState({
        showIssueDateCalendar: true,
      });
    } else if (name === "dueDate") {
      this.setState({
        showDueDateCalendar: true,
      });
    } else if (name === "saleDate") {
      this.setState({
        showSaleDateCalendar: true,
      });
    }
  };

  selectIssueDate = (date) => {
    this.setState({
      issueDate: moment(date).format("YYYY-MM-DD"),
      dueDate: moment(date).add(7, "days").format("YYYY-MM-DD")
    });
    if (!this.state.isSaleDateManuallyChanged) {
      this.setState({ saleDate: moment(date).format("YYYY-MM-DD") })
      const { currency } = this.state;
      if (currency && currency !== 'PLN') this.setCurrencyMidPrice(currency, date);
    }
    this.setInvoiceNumber(moment(date).format("YYYY-MM"), this.props.currentCompany.company.id, this.state.documentType);
  };

  deleteInvoicePossition = (index) => {
    var { invoicePositions } = this.state;
    var position = invoicePositions[index];

    invoicePositions.splice(index, 1);
    if (position.netPrice) {
      var summary = {
        netSum: 0,
        vatSum: 0,
        grossSum: 0,
      };

      invoicePositions.map((pos) => {
        if (pos.netValue && pos.grossValue && pos.vat) {
          var vat = isNaN(parseFloat(pos.vat)) ? 0.0 : parseFloat(pos.vat);
          summary.vatSum += parseFloat(pos.netValue) * (vat / 100);
          summary.netSum += parseFloat(pos.netValue);
          summary.grossSum += parseFloat(pos.grossValue);
        }
      });

      this.setState({
        summary: summary,
      });
    }

    this.setState({
      invoicePositions: invoicePositions,
    });
  };

  generateCode = (name) => {
    var indexOfComma = name.indexOf(",");

    if (indexOfComma > 0) {
      var tempName = name.substring(0, indexOfComma);
      tempName = tempName.trim();

      if ((tempName.match(/\"/gm) || []).length !== 1) {
        name = tempName;
      }
    }

    if (name.includes('"')) {
      var newName = name.match(/\"(.*)\"/g) || [];
      newName = newName.find((x) => x !== undefined);

      if (newName) {
        newName = newName.replace(/\"/gm, "");
        newName = newName.trim();
        var wordsArray = newName.split(" ");
        if (wordsArray.length === 1) {
          newName = wordsArray[0];
          var indexOfDash = newName.lastIndexOf("-");
          if (indexOfDash > 0 && newName.length > 20) {
            newName = newName.substring(0, indexOfDash);

            return newName.substring(0, 20);
          } else {
            return newName.substring(0, 20);
          }
        } else {
          var code = "";
          wordsArray.forEach((w) => {
            if (w.length > 2 && !w.match(/^\d/)) {
              code += w[0];
            } else {
              code += w;
            }
          });

          return code;
        }
      } else {
        return "";
      }
    } else {
      var wordsArray = name.split(" ");
      if (wordsArray.length === 1) {
        newName = wordsArray[0];
        var indexOfDash = newName.lastIndexOf("-");
        if (indexOfDash > 0) {
          newName = newName.substring(0, indexOfDash);

          return newName.substring(0, 20);
        } else {
          return newName.substring(0, 20);
        }
      } else if (wordsArray.length === 2) {
        var part1 = wordsArray[0];
        var part2 = wordsArray[1];

        if (part1.length + part2.length <= 19) {
          return part1 + "-" + part2;
        } else {
          if (part1.includes("-")) {
            return part1;
          } else if (part2.includes("-")) {
            return part2;
          } else {
            return part1 + "-" + part2.substring(0, 19 - part1.length);
          }
        }
      } else {
        var code = "";
        wordsArray.forEach((w) => {
          if (w.length > 2 && !w.match(/^\d/)) {
            code += w[0];
          } else {
            code += w;
          }
        });

        return code;
      }
    }
  };

  hideSearchVatIdPopup = () => {
    window.removeEventListener("keydown", this.searchOnEnter, false);
    this.setState({
      showVatIDSearchPopup: false,
      searchBuyerVatId: "",
    });
  };

  searchOnEnter = (e) => {
    if (e.keyCode === 13) this.searchVatAPI();
  };

  foundAtLeastOneSimilarCounterparty = (text) => {
    if (!text) return false;
    const { counterpartiesList } = this.state;
    var counterpartiesCount = counterpartiesList.length;

    for (var i = 0; i < counterpartiesCount; i++) {
      if (
        text.length === 1 &&
        counterpartiesList[i].name &&
        counterpartiesList[i].name.toLowerCase().startsWith(text.toLowerCase())
      ) {
        return true;
      } else if (
        text.length > 1 &&
        counterpartiesList[i].name &&
        counterpartiesList[i].name.toLowerCase().includes(text.toLowerCase())
      ) {
        return true;
      }
    }

    return false;
  };

  checkIfCounterpartyNameIsSimilar = (text, name) => {
    if (!text) {
      return false;
    }

    if (
      text.length === 1 &&
      name &&
      name.toLowerCase().startsWith(text.toLowerCase())
    ) {
      return true;
    } else if (
      text.length > 1 &&
      name &&
      name.toLowerCase().includes(text.toLowerCase())
    ) {
      return true;
    }

    return false;
  };

  checkIfInvoiceItemIsSimilar = (text, name) => {
    if (!text) return false;

    if (text.length === 1 && name && name.toLowerCase().startsWith(text.toLowerCase())) return true;
    if (text.length > 1 && name && name.toLowerCase().includes(text.toLowerCase())) return true;

    return false;
  };

  foundAtLeastOneSimilarInvoiceItem = (text) => {
    if (!text) return false;
    const { savedDocItems } = this.state;
    var invoiceItemCount = savedDocItems.length;
    for (var i = 0; i < invoiceItemCount; i++) {
      if (text.length === 1 && savedDocItems[i].name && savedDocItems[i].name.toLowerCase().startsWith(text.toLowerCase())) return true;
      if (text.length > 1 && savedDocItems[i].name && savedDocItems[i].name.toLowerCase().includes(text.toLowerCase())) return true;
    }

    return false;
  };

  hideInvoicePreview = () => {
    this.setState({ showInvoicePreviewPopup: false });
  };

  setEditingMode = () => {
    this.setState({
      editingExistingInvoice: true,
      documentNumberBeforeEditing: this.state.documentNumber,
    });
  };

  getPaymentMethodString = (paymentMethod) => {
    var paymentMethodString = "Transfer";
    if (paymentMethod === "card") paymentMethodString = "Card";
    if (paymentMethod === "cash") paymentMethodString = "Cash";
    if (paymentMethod === "other") paymentMethodString = "Other";
    return this.context.t(paymentMethodString);
  };

  getPaymentStatusString = (paymentStatus) => {
    var paymentStatusString = "Unpaid ";
    if (paymentStatus === "paid") paymentStatusString = "Paid ";
    return this.context.t(paymentStatusString);
  };

  setCountryCode = (code) => {
    this.setState({
      buyerPostCode: code,
      buyerCountryCode: code,
      showCountriesList: false
    })
  }

  cancelAddingNumberingSeries = () => {
    this.props.hideInvoiceCreatorOverlay()
  }

  invoiceNumberingSeriesIsValid = invoiceNumberingSerise => {
    var valid = true

    if (invoiceNumberingSerise) {
      if (invoiceNumberingSerise.invoice_mask) {
        var maskParts = invoiceNumberingSerise.invoice_mask.split('/')
        if (maskParts.length === 1) {
          valid = false
          this.props.alertWarn(this.context.t("The numbering mask should contain at least two of the components - MM, YYYY, 00 - separated by a slash (\"/\")"))
        }
      }

      if (invoiceNumberingSerise.correction_mask) {
        var maskParts = invoiceNumberingSerise.correction_mask.split('/')
        if (maskParts.length === 1) {
          valid = false
          this.props.alertWarn(this.context.t("The numbering mask should contain at least two of the components - MM, YYYY, 00 - separated by a slash (\"/\")"))
        }
      }

      if (invoiceNumberingSerise.proforma_mask) {
        var maskParts = invoiceNumberingSerise.proforma_mask.split('/')
        if (maskParts.length === 1) {
          valid = false
          this.props.alertWarn(this.context.t("The numbering mask should contain at least two of the components - MM, YYYY, 00 - separated by a slash (\"/\")"))
        }
      }
    } else {
      valid = false
    }

    return valid
  }

  addNumberingSeries = (invoiceMask, correctionMask, proformaMask) => {
    const { currentCompany } = this.props
    var invoiceNumberingSerise = {
      invoice_mask: invoiceMask,
      correction_mask: correctionMask,
      proforma_mask: proformaMask
    }

    if (this.invoiceNumberingSeriesIsValid(invoiceNumberingSerise)) {
      this.props.addNewInvoiceNumberingSeries(currentCompany.company_id, invoiceNumberingSerise, (success, newSeriesId) => {
        if (success) {
          this.setState({ showInvoiceNumberingSeriesPopup: false })
          this.setInvoiceNumber(moment().format("YYYY-MM"), currentCompany.company.id, this.state.documentType)
        }
      })
    }
  }

  selectWaproField = (selectedOption, field) => {
    this.setState({
      [field]: selectedOption.code || selectedOption,
      showMagDocType: false,
      showFakirDocType: false,
      showFakirDictionary: false,
      showWaproNetAccoutList: false,
      showWaproDocDescriptionList: false,
    })
  }

  toggleMagDocTypeList = show => {
    this.setState({
      showMagDocType: show !== undefined ? show : !this.state.showMagDocType
    })
  }

  toggleFakirDocTypeList = show => {
    this.setState({
      showFakirDocType: show !== undefined ? show : !this.state.showFakirDocType
    })
  }

  toggleFakirDictionaryList = show => {
    this.setState({
      showFakirDictionary: show !== undefined ? show : !this.state.showFakirDictionary
    })
  }

  toggleWaproNetAccoutList = show => {
    this.setState({
      showWaproNetAccoutList: show !== undefined ? show : !this.state.showWaproNetAccoutList
    })
  }

  toggleDocDescriptionList = show => {
    this.setState({
      showWaproDocDescriptionList: show !== undefined ? show : !this.state.showWaproDocDescriptionList
    })
  }

  openDeleteItemPopup = item => {
    this.setState({
      showDeleteView: true,
      itemToDelete: item
    })
  }

  closeDeleteItemPopup = () => {
    this.setState({
      showDeleteView: false,
      itemToDelete: ''
    })
  }

  saveNewDocItem = item => {
    this.setState({
      newItemView: true,
      newItem: item
    })
  }

  closeSaveingItemPopup = () => {
    this.setState({
      newItemView: false,
      newItem: {},
    })
  }

  startEditingItem = item => {
    this.setState({
      editItemView: true,
      editedItem: item
    })
  }

  closeEditItemPopup = () => {
    this.setState({
      editItemView: false,
      editedItem: {}
    })
  }

  addedNewItem = newItem => {
    var newInvoiceItemsList = this.state.savedDocItems
    newInvoiceItemsList.push(newItem)

    this.setState({
      savedDocItems: newInvoiceItemsList
    })
  }

  savedChanges = newItem => {
    var newInvoiceItemsList = this.state.savedDocItems
    var itemIndex = arrayObjectIndexOf(newInvoiceItemsList, newItem.id, 'id')

    if (itemIndex > -1) {
      newInvoiceItemsList[itemIndex] = newItem
    }

    this.setState({
      savedDocItems: newInvoiceItemsList
    })
  }

  deletedDocsItem = itemId => {
    var newInvoiceItemsList = this.state.savedDocItems
    newInvoiceItemsList = newInvoiceItemsList.filter(e => e.id !== itemId)

    this.setState({
      savedDocItems: newInvoiceItemsList
    })
  }

  closePopupAndDeleteInvoiceNumber = () => {
    this.props.closePopupAndDeleteInvoiceNumber()
  }

  closePopupAndSetNewInvoiceNumber = (newInvoiceNumber) => {
    this.props.closePopupAndDeleteInvoiceNumber()
    this.setState({
      documentNumber: newInvoiceNumber
    })
  }

  toggleMpk = () => {
    const { addMPK } = this.state
    var firstMpk = {
      id: -1,
      netAmount: '',
      mpkLevel1: '',
      mpkCodeLevel1: '',
      mpkLevel2: '',
      mpkCodeLevel2: '',
      mpkLevel3: '',
      mpkCodeLevel3: '',
      mpkLevel4: '',
      mpkCodeLevel4: '',
      waproNetAccount: ''
    }
    this.setState({
      addMPK: !addMPK,
      mpkSplit: addMPK ? [] : [firstMpk]
    })
  }

  setMpkSplit = newData => {
    if (newData.length > 0) {
      this.setState({
        mpkSplit: newData
      })
    }
  }

  render() {
    const {
      showInvoiceNumberingSeriesPopup,
      sellerId,
      documentType,
      allSeriesDocumentNumbers,
      showDocumentNumberSeriesList,
      showDocumentTypeList,
      documentNumber,
      showDocumentLanguageList,
      documentLanguage,
      issueDate,
      saleDate,
      sellerName,
      sellerVatId,
      sellerAddress,
      sellerPostCode,
      sellerCity,
      searchBuyerVatId,
      waitingForVatApiReponse,
      buyerName,
      buyerVatId,
      buyerAddress,
      buyerPostCode,
      buyerCity,
      invoicePositions,
      summary,
      paymentMethod,
      accountNumber,
      dueDate,
      paymentStatus,
      currency,
      logo,
      logoURL,
      showVatIDSearchPopup,
      showIssueDateCalendar,
      showSaleDateCalendar,
      showDueDateCalendar,
      showPaymentStatusList,
      showPaymentMethodList,
      unitListToShow,
      gtuListToShow,
      posListToShow,
      vatListToShow,
      savedDocItems,
      showCurrencyList,
      buyerNotFromPoland,
      comments,
      splitPayment,
      reverseCharge,
      metodaKasowa,
      showAccountsList,
      accountsList,
      buyerEmail,
      showCounterpartiesList,
      counterpartiesList,
      showInvoicePreviewPopup,
      fileBody,
      fileName,
      currencyEffectiveDate,
      currencyMidPrice,
      currencyTableNumber,
      showCurrencyCalenrad,
      editedInvoiceId,
      showCountriesList,
      buyerCountryCode,

      isAccountantInCurrentCompany,
      selectedMagDocType,
      showMagDocType,
      selectedFakirDocType,
      showFakirDocType,
      selectedFakirDocDictionary,
      showFakirDictionary,
      selectedFakirNetAccounts,
      showWaproNetAccoutList,
      netAccountList,
      selectedDocDescription,
      showWaproDocDescriptionList,
      docDescriptionsList,

      newItemView, newItem, editItemView, editedItem, showDeleteView, itemToDelete,
      showPkwiu, showGtu, showDiscount,

      sendToKsef,
      addMPK, mpkSplit,
    } = this.state;
    const { hideInvoiceCreatorOverlay, currentCompany, currentProject, user, tabIsActive, isInvoiceFromProforma, editingData, isNewInvoiceNumberPopupOpen, newInvoiceNumber } = this.props;
    if (tabIsActive || !user.new_easydocs_version) {
      return (
        <div className="generate-invoide-container" onClick={(e) => this.closeAllSuggestions(e)}>
          {
            showInvoiceNumberingSeriesPopup ? (
              <InvoiceNumberingSeriesCreator
                companyId={sellerId || currentCompany.company_id}
                close={this.cancelAddingNumberingSeries}
                save={this.addNumberingSeries}
              />
            ) : null
          }
          {
            isNewInvoiceNumberPopupOpen ? (
              <NewInvoiceNumberPopUp
                newInvoiceNumber={newInvoiceNumber}
                close={this.closePopupAndDeleteInvoiceNumber}
                save={this.closePopupAndSetNewInvoiceNumber}
              />
            ) : null
          }
          {
            showVatIDSearchPopup ? (
              <div className="searhc-vatid-popup">
                <div id="popup-card" className="popup-card">
                  <div className="header">
                    {this.context.t(
                      "Enter the contractor's NIP (tax identification number) to download his data from the Central Statistical Office database"
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="searchBuyerVatId"
                      id="searchBuyerVatId"
                      value={searchBuyerVatId}
                      onChange={this.onInputChange}
                    />
                  </div>
                  <div className="search-btn" onClick={() => this.searchVatAPI()}>
                    {waitingForVatApiReponse ? (
                      <div className="loader">
                        <Spinner size={20} speed={0.8} color={"#ffffff"} />
                      </div>
                    ) : (
                      this.context.t("Search")
                    )}
                  </div>
                </div>
              </div>
            ) : null
          }
          {
            showInvoicePreviewPopup ? (
              <InvoicePreview
                fileBody={fileBody}
                fileName={fileName}
                hideInvoicePreview={this.hideInvoicePreview}
                hideInvoiceCreatorOverlay={hideInvoiceCreatorOverlay}
                setEditingMode={this.setEditingMode}
                email={buyerEmail}
                editedInvoiceId={editedInvoiceId}
                invoiceData={{
                  id: editedInvoiceId,
                  doc_number: documentNumber,
                  issue_date: issueDate,
                  currency: currency,
                  counterparty: {
                    name: buyerName,
                    vat_id: buyerVatId
                  }
                }}
              />
            ) : null
          }
          {
            newItemView ? (
              <NewDocItemPopup
                newItem={newItem}
                close={this.closeSaveingItemPopup}
                createdNewItem={this.addedNewItem}
              />
            ) : null
          }
          {
            editItemView ? (
              <NewDocItemPopup
                editingExisting={true}
                editedItem={editedItem}
                savedChanges={this.savedChanges}
                close={this.closeEditItemPopup}
              />
            ) : null
          }

          {
            showDeleteView ? (
              <DeleteDocItemPopup
                itemToDelete={itemToDelete}
                deletedDocsItem={this.deletedDocsItem}
                closeDeleteItemPopup={this.closeDeleteItemPopup}
              />
            ) : null
          }
          <div className="content">
            <div className="header-container">
              {
                isInvoiceFromProforma ?
                  <h2> {this.context.t('Invoice issued on the basis of proforma no') + ` ${editingData.doc_number}`} </h2>
                  :
                  <h2> {this.context.t("Creating a sales invoice in {n}", { n: currentCompany && currentCompany.company ? currentCompany.company.code : "", })} </h2>
              }
              {
                !user.new_easydocs_version ? (
                  <div className="hide-button" onClick={() => hideInvoiceCreatorOverlay()} ></div>
                ) : (
                  null
                )
              }
            </div>

            <div className="header-row">
              <div id="documentTypeContainer" className="form-group" onClick={() => this.setState({ showDocumentTypeList: !showDocumentTypeList })}>
                {
                  isInvoiceFromProforma ?
                    <input
                      className={``}
                      name="documentType"
                      id={`documentType`}
                      value={this.context.t(documentType)}
                      readOnly={true}
                    />
                    :
                    <input
                      className={``}
                      name="documentType"
                      id={`documentType`}
                      value={this.context.t(documentType)}
                      readOnly={true}
                      onClick={() =>
                        this.setState({
                          showDocumentTypeList: true,
                        })
                      }
                    />
                }

                <label htmlFor="paymentMethod" onClick={() => this.setState({ showDocumentTypeList: true })}>{this.context.t("Document type ")}</label>
                {
                  isInvoiceFromProforma ? (
                    null
                  ) : (
                    <span className={`expend-icon ${showDocumentTypeList ? "expended" : ""}`}></span>
                  )
                }
                {
                  showDocumentTypeList && !isInvoiceFromProforma ? (
                    <div className="sugestions-list down">
                      <ul>
                        <li onClick={() => this.setDocType("Invoice")}>
                          {this.context.t("Invoice")}
                        </li>
                        <li onClick={() => this.setDocType("Proforma")}>
                          {this.context.t("Proforma")}
                        </li>
                      </ul>
                    </div>
                  ) : null
                }
              </div>
              <div id="documentNumberContainer" className="form-group" onClick={() => this.setState({ showDocumentNumberSeriesList: !showDocumentNumberSeriesList })}>
                <input
                  className={``}
                  name="documentNumber"
                  id={`documentNumber`}
                  value={documentNumber}
                  onChange={this.onInputChange}
                  placeholder={this.state.invoiceNumberBeforeChangedByUser}
                />
                <label htmlFor="documentNumber" onClick={() => this.setState({ showDocumentNumberSeriesList: true })}>{this.context.t("Document number")}</label>

                {
                  allSeriesDocumentNumbers.length > 1 ? (
                    <>
                      <span className={`expend-icon ${showDocumentNumberSeriesList ? "expended" : ""}`}></span>
                      {
                        showDocumentNumberSeriesList ? (
                          <div className="sugestions-list down">
                            <ul>
                              {
                                allSeriesDocumentNumbers.map(series => {
                                  return (
                                    <li onClick={() => this.setDocNumberSeries(series)}>
                                      {
                                        series.seriesName ? (
                                          <span className="series-name">{series.seriesName}</span>
                                        ) : null
                                      }
                                      {series.number}
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                        ) : null
                      }
                    </>
                  ) : null
                }
              </div>
              <div id="documentLanguageContainer" className="form-group" onClick={() => this.setState({ showDocumentLanguageList: !showDocumentLanguageList })}>
                <input
                  className={``}
                  name="documentLanguage"
                  id={`documentLanguage`}
                  value={this.context.t(this.getFullLanguageName(documentLanguage))}
                  readOnly={true}
                />

                <label htmlFor="documentLanguageContainer" onClick={() => this.setState({ showDocumentLanguageList: true })}>{this.context.t("Document language")}</label>
                <span className={`expend-icon ${showDocumentLanguageList ? "expended" : ""}`}></span>

                {
                  showDocumentLanguageList ? (
                    <div className="sugestions-list down">
                      <ul>
                        <li onClick={() => this.setDocLanguage("pl")}>
                          {this.context.t("Polish")}
                        </li>
                        <li onClick={() => this.setDocLanguage("en")}>
                          {this.context.t("English")}
                        </li>
                      </ul>
                    </div>
                  ) : null
                }
              </div>
              <div id="issueDatePickerContainer" className="form-group">
                <input
                  className={``}
                  name="issueDate"
                  id={`issueDate`}
                  value={issueDate}
                  onClick={(e) => this.showDatePicker(e, "issueDate")}
                />
                <label htmlFor="issueDate" onClick={(e) => this.showDatePicker(e, "issueDate")}>{this.context.t("Issue date")}</label>
                <span className="calendar-icon" onClick={(e) => this.showDatePicker(e, "issueDate")}></span>
                {
                  showIssueDateCalendar ? (
                    <div className={`date-picker ${showIssueDateCalendar ? "show" : ""}`} onClick={(e) => this.clickOnDatePicker(e, "issueDate")}>
                      <Calendar
                        selectDay={this.selectIssueDate}
                        selectedDay={moment(issueDate)}
                        unselectDay={() => { }}
                        projectId={"none"}
                        startDate={moment(issueDate) || moment()}
                        arrowsOnLeft={false}
                      />
                    </div>
                  ) : null
                }
              </div>
              <div id="saleDatePickerContainer" className="form-group">
                <input
                  className={``}
                  name="saleDate"
                  id={`saleDate`}
                  value={saleDate}
                  onClick={(e) => this.showDatePicker(e, "saleDate")}
                />
                <label htmlFor="saleDate" onClick={(e) => this.showDatePicker(e, "saleDate")}>{this.context.t("Sale date")}</label>
                <span className="calendar-icon" onClick={(e) => this.showDatePicker(e, "saleDate")} ></span>

                {
                  showSaleDateCalendar ? (
                    <div className={`date-picker ${showSaleDateCalendar ? "show" : ""}`} onClick={(e) => this.clickOnDatePicker(e, "saleDate")}>
                      <Calendar
                        selectDay={this.selectSaleDate}
                        selectedDay={moment(saleDate)}
                        unselectDay={() => { }}
                        projectId={"none"}
                        startDate={moment(saleDate) || moment()}
                        arrowsOnLeft={false}
                      />
                    </div>
                  ) : null
                }
              </div>
            </div>

            <div className="seller-and-buyer-row">
              <div className="seller">
                <div className="form-group">
                  <input
                    className={`no-label`}
                    name="sellerName"
                    id={`sellerName`}
                    value={sellerName}
                    onChange={this.onInputChange}
                  />
                  <label htmlFor="buyerName">{this.context.t("Name")}</label>
                  <label className="big-label" htmlFor="sellerName">{this.context.t("Seller")}</label>
                </div>
                <div className="form-group">
                  <input
                    className={``}
                    name="sellerVatId"
                    id={`sellerVatId`}
                    value={sellerVatId}
                    onChange={this.onInputChange}
                  />
                  <label htmlFor="sellerVatId">{this.context.t("VatID")}</label>
                </div>
                <div className="form-group">
                  <input
                    className={``}
                    name="sellerAddress"
                    id={`sellerAddress`}
                    value={sellerAddress}
                    onChange={this.onInputChange}
                  />
                  <label htmlFor="sellerAddress">{this.context.t("Address")}</label>
                </div>
                <div className="form-group small">
                  <input
                    className={``}
                    name="sellerPostCode"
                    id={`sellerPostCode`}
                    value={sellerPostCode}
                    onChange={this.onInputChange}
                  />
                  <label htmlFor="sellerPostCode">{this.context.t("ZIP code")}</label>
                </div>
                <div className="form-group fill-rest">
                  <input
                    className={``}
                    name="sellerCity"
                    id={`sellerCity`}
                    value={sellerCity}
                    onChange={this.onInputChange}
                  />
                  <label htmlFor="sellerCity">{this.context.t("City ")}</label>
                </div>
              </div>

              <div className="buyer">
                <div className={`get-data ${buyerNotFromPoland ? "hide" : ""}`} onClick={() => this.showSearchVatIdPopup()}>
                  {this.context.t("Load data by VatID")}
                </div>
                <div className="form-group">
                  <input
                    className={`no-label`}
                    name="buyerName"
                    id={`buyerName`}
                    value={buyerName}
                    onChange={this.onInputChange}
                    onClick={() => this.setState({ showCounterpartiesList: true })}
                  />
                  <label htmlFor="buyerName">{this.context.t("Name")}</label>
                  <label className="big-label" htmlFor="buyerName">{this.context.t("Buyer")}</label>
                  <span className="search-icon" />

                  {
                    counterpartiesList.length > 0 && this.foundAtLeastOneSimilarCounterparty(buyerName) && showCounterpartiesList ? (
                      <div className="counterparties-list">
                        <SimpleBar style={{ height: "auto", maxHeight: "300px", width: "100%" }}>
                          <ul>
                            {
                              counterpartiesList.map((c) => {
                                if (this.checkIfCounterpartyNameIsSimilar(buyerName, c.name)) {
                                  return (
                                    <li
                                      tabIndex={"0"}
                                      className={`item counterparty-item`}
                                      onClick={() => {
                                        var sAddress;
                                        if (c.address !== null) sAddress = splitAddress(c.address);
                                        var emails;
                                        if (c.email) emails = this.concatenationMails(c.email);
                                        var newDueDate = moment().format('YYYY-MM-DD')
                                        if (c.payment_due) newDueDate = moment().add(parseInt(c.payment_due), 'day').format('YYYY-MM-DD')
                                        this.setState({
                                          showCounterpartiesList: false,
                                          buyerName: c.name ? c.name : "",
                                          buyerVatId: c.vat_id ? c.vat_id : "",
                                          buyerAddress: sAddress ? sAddress.address : "",
                                          buyerPostCode: sAddress ? sAddress.zipCode : "",
                                          buyerCity: sAddress ? sAddress.city : "",
                                          buyerEmail: emails ? emails : "",
                                          buyerCountryCode: c?.country,
                                          buyerNotFromPoland: c && c.country && c.country !== 'PL',
                                          dueDate: c.payment_due ? newDueDate : dueDate
                                        });
                                      }}
                                    >
                                      {c.name}
                                    </li>
                                  );
                                } else {
                                  return null
                                }
                              })
                            }
                          </ul>
                        </SimpleBar>
                      </div>
                    ) : showCounterpartiesList && !buyerName ? (
                      <div className="counterparties-list">
                        <SimpleBar style={{ height: "auto", maxHeight: "300px", width: "100%" }}>
                          <ul>
                            {
                              counterpartiesList.map((c) => {
                                return (
                                  <li
                                    tabIndex={"0"}
                                    className={`item counterparty-item`}
                                    onClick={() => {
                                      var sAddress;
                                      if (c.address !== null) sAddress = splitAddress(c.address);
                                      var emails;
                                      if (c.email) emails = this.concatenationMails(c.email);
                                      var newDueDate = moment().format('YYYY-MM-DD')
                                      if (c.payment_due) newDueDate = moment().add(parseInt(c.payment_due), 'day').format('YYYY-MM-DD')
                                      this.setState({
                                        showCounterpartiesList: false,
                                        buyerName: c.name ? c.name : "",
                                        buyerVatId: c.vat_id ? c.vat_id : "",
                                        buyerAddress: sAddress ? sAddress.address : "",
                                        buyerPostCode: sAddress ? sAddress.zipCode : "",
                                        buyerCity: sAddress ? sAddress.city : "",
                                        buyerEmail: emails ? emails : "",
                                        dueDate: c.payment_due ? newDueDate : dueDate
                                      });
                                    }}
                                  >
                                    {c.name}
                                  </li>
                                );
                              })
                            }
                          </ul>
                        </SimpleBar>
                      </div>
                    ) : null
                  }
                </div>
                {
                  buyerNotFromPoland ? (
                    <div className="form-group">
                      <input
                        className={``}
                        name="buyerVatId"
                        id={`buyerVatId`}
                        value={buyerVatId}
                        onChange={this.onInputChange}
                      />
                      <label htmlFor="buyerVatId">
                        {this.context.t("Company ID")}
                      </label>
                    </div>
                  ) : (
                    <div className="form-group">
                      <input
                        className={``}
                        name="buyerVatId"
                        id={`buyerVatId`}
                        value={buyerVatId}
                        onChange={this.onInputChange}
                      />
                      <label htmlFor="buyerVatId">{this.context.t("VatID")}</label>
                    </div>
                  )
                }
                <div className={`form-group ${buyerNotFromPoland ? "fill-rest" : ""}`} >
                  <input
                    className={``}
                    name="buyerAddress"
                    id={`buyerAddress`}
                    value={buyerAddress}
                    onChange={this.onInputChange}
                  />
                  <label htmlFor="buyerAddress">
                    {this.context.t("Address")}
                  </label>
                </div>
                {
                  buyerNotFromPoland ? (
                    <div className="form-group mid ml">
                      <input
                        className={``}
                        name="buyerCountryCode"
                        id={`buyerCountryCode`}
                        value={buyerCountryCode}
                        readOnly={true}
                        onChange={this.onInputChange}
                        onClick={() => this.setState({ showCountriesList: true })}
                      />
                      <label htmlFor="buyerCountryCode">{this.context.t("Country code (ISO 3166-1 alpha-2)")}</label>
                      {
                        showCountriesList ? <CountriesList
                          headerText={this.context.t('Country:')}
                          selectedOption={buyerCountryCode}
                          select={(code) => this.setCountryCode(code)}
                        /> : null
                      }
                      <span className={`expend-icon ${showCountriesList ? "expended" : ""}`} onClick={() => this.setState({ showCountriesList: true })} ></span>
                    </div>
                  ) : null
                }
                {!buyerNotFromPoland ? (
                  <>
                    <div className="form-group small">
                      <input
                        className={``}
                        name="buyerPostCode"
                        id={`buyerPostCode`}
                        value={buyerPostCode}
                        onChange={this.onInputChange}
                      />
                      <label htmlFor="buyerPostCode">
                        {this.context.t("ZIP code")}
                      </label>
                    </div>
                    <div className="form-group fill-rest">
                      <input
                        className={``}
                        name="buyerCity"
                        id={`buyerCity`}
                        value={buyerCity}
                        onChange={this.onInputChange}
                      />
                      <label htmlFor="buyerCity">{this.context.t("City ")}</label>
                    </div>
                  </>
                ) : null}
                <div className="form-group">
                  <input
                    className={``}
                    name="buyerEmail"
                    id={`buyerEmail`}
                    value={buyerEmail}
                    onChange={this.onInputChange}
                  />
                  <label htmlFor="buyerEmail">{this.context.t("Email")}</label>
                </div>
                <div
                  className="checkbox-group"
                  onClick={() =>
                    this.setState({ buyerNotFromPoland: !buyerNotFromPoland })
                  }
                >
                  <div
                    className={`checkbox ${buyerNotFromPoland ? "checked" : ""}`}
                  ></div>
                  <div className="label">
                    {this.context.t("The buyer is not from Poland")}
                  </div>
                </div>
              </div>
            </div>
            <div className="invoice-possitions">
              <div className="description">
                {this.context.t("Invoice items")}
              </div>

              {
                invoicePositions.map((p, i) => {
                  return (
                    <div className="possition" key={`invoice-possition-${i}`}>
                      <div className="form-group name" onClick={() => this.setState({ posListToShow: i })}>
                        <TextareaAutosize
                          className={`name`}
                          name={`name-${i}`}
                          id={`name-${i}`}
                          value={p.name}
                          onChange={(e) => this.onInputChangeInvoicePossition(e, i)}
                          onFocus={(e) => e.target.setAttribute("autoComplete", "none")}
                        />
                        <label htmlFor={`name-${i}`}>
                          {this.context.t("Name")}
                        </label>

                        {
                          savedDocItems.length > 0 ? (
                            <span className={`expend-icon ${posListToShow === i ? "expended" : ""}`}></span>
                          ) : null
                        }

                        {
                          p.name.length > 0 && !p.id ? (
                            <span className="add-new-icon" onClick={() => this.saveNewDocItem(p)}></span>
                          ) : null
                        }

                        {
                          savedDocItems.length > 0 && this.foundAtLeastOneSimilarInvoiceItem(p.name) && posListToShow === i ? (
                            <div className="sugestions-list doc-items">
                              <ul>
                                <SimpleBar style={{ maxHeight: "30vh", width: "100%" }} >
                                  {
                                    savedDocItems.map((item) => {
                                      if (this.checkIfInvoiceItemIsSimilar(p.name, item.name))
                                        return (
                                          <li>
                                            <div className="name" onClick={() => this.setItem({ ...item }, i)}>{item.name}</div>
                                            <div className="options-menu">
                                              <span className="option edit" onClick={() => this.startEditingItem(item)}></span>
                                              <span className="option delete" onClick={() => this.openDeleteItemPopup(item)}></span>
                                              {/* <span className="option add"></span> */}
                                            </div>
                                          </li>
                                        );
                                    })
                                  }
                                </SimpleBar>
                              </ul>
                            </div>
                          ) : (
                            !p.name && posListToShow === i ? (
                              <div className="sugestions-list doc-items">
                                <ul>
                                  <SimpleBar style={{ maxHeight: "30vh", width: "100%" }} >
                                    {
                                      savedDocItems.map((item) => {

                                        return (
                                          <li>
                                            <div className="name" onClick={() => this.setItem({ ...item }, i)}>{item.name}</div>
                                            <div className="options-menu">
                                              <span className="option edit" onClick={() => this.startEditingItem(item)}></span>
                                              <span className="option delete" onClick={() => this.openDeleteItemPopup(item)}></span>
                                              {/* <span className="option add"></span> */}
                                            </div>
                                          </li>
                                        );
                                      })
                                    }
                                  </SimpleBar>
                                </ul>
                              </div>
                            ) : null
                          )
                        }

                      </div>
                      {
                        showPkwiu ? (
                          <div className="form-group pkwiu">
                            <input
                              className={`pkwiu`}
                              name={`pkwiu-${i}`}
                              id={`pkwiu-${i}`}
                              value={p.pkwiu}
                              onChange={(e) => this.onInputChangeInvoicePossition(e, i)}
                              onBlur={(e) => this.updateSummary(e)}
                            />
                            <label htmlFor={`pkwiu-${i}`}>
                              {this.context.t("PKWiU")}
                            </label>
                          </div>
                        ) : null
                      }
                      {
                        showGtu ? (
                          <div className="form-group gtu">
                            <input
                              className={`gtu`}
                              name={`gtu-${i}`}
                              id={`gtu-${i}`}
                              value={p.gtu}
                              readOnly={true}
                              onClick={() => this.setState({ gtuListToShow: i })}
                            />
                            <label htmlFor={`gtu-${i}`} onClick={() => this.setState({ gtuListToShow: i })} >
                              {this.context.t("GTU")}
                            </label>
                            <span className={`expend-icon ${gtuListToShow === i ? "expended" : ""}`} onClick={() => this.setState({ gtuListToShow: i, })} ></span>
                            {
                              gtuListToShow === i ? (
                                <div className="sugestions-list gtu">
                                  <ul>
                                    {Object.entries(GTU_CODE).map(([key, value]) => (
                                      <li
                                        onClick={() =>
                                          this.setGtu(`${key === "null" ? "" : key}`, i)
                                        }
                                      >
                                        <div className="name">{value}</div>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              ) : null
                            }
                          </div>
                        ) : null
                      }
                      <div className="form-group amount">
                        <input
                          className={`amount`}
                          name={`amount-${i}`}
                          id={`amount-${i}`}
                          value={p.amount}
                          onChange={(e) => this.onInputChangeInvoicePossition(e, i)}
                          onBlur={(e) => this.updateSummary(e)}
                          style={{ fontSize: p.amount.length > 4 ? '12px' : '14px' }}
                        />
                        <label htmlFor={`amount-${i}`}>
                          {this.context.t("Amount ")}
                        </label>
                      </div>
                      <div className="form-group unit">
                        <input
                          className={`unit`}
                          name={`unit-${i}`}
                          id={`unit-${i}`}
                          value={p.unit}
                          readOnly={true}
                          onClick={() => {
                            this.setState({
                              unitListToShow: i,
                            });
                          }}
                        />
                        <label htmlFor={`unit-${i}`} onClick={() => this.setState({ unitListToShow: i, })} >
                          {this.context.t("Unit")}
                        </label>
                        <span className={`expend-icon ${unitListToShow === i ? "expended" : ""}`} onClick={() => this.setState({ unitListToShow: i })} ></span>
                        {
                          unitListToShow === i ? (
                            <div className="sugestions-list">
                              <ul>
                                <li onClick={() => this.setUnit("szt.", i)}><div className="name">szt.</div></li>
                                <li onClick={() => this.setUnit("os.", i)}><div className="name">os.</div></li>
                                <li onClick={() => this.setUnit("godz.", i)}><div className="name">godz.</div></li>
                                <li onClick={() => this.setUnit("dni", i)}><div className="name">dni</div></li>
                                <li onClick={() => this.setUnit("m-c", i)}><div className="name">m-c</div></li>
                                <li onClick={() => this.setUnit("km", i)}><div className="name">km</div></li>
                                <li onClick={() => this.setUnit("MWh", i)}><div className="name">MWh</div></li>
                                <li onClick={() => this.setUnit("kpl.", i)}><div className="name">kpl.</div></li>
                                <li onClick={() => this.setUnit("usł.", i)}><div className="name">usł.</div></li>
                                <li onClick={() => this.setUnit("inna", i)}><div className="name">inna</div></li>
                              </ul>
                            </div>
                          ) : null
                        }
                      </div>
                      <div className="form-group netPrice">
                        <input
                          className={`netPrice`}
                          name={`netPrice-${i}`}
                          id={`netPrice-${i}`}
                          value={p.netPrice}
                          onChange={(e) => this.onInputChangeInvoicePossition(e, i)}
                          onBlur={(e) => this.updateSummary(e)}
                        />
                        <label htmlFor={`netPrice-${i}`}>
                          {this.context.t("Net price")}
                        </label>
                      </div>
                      {
                        showDiscount ? (
                          <>
                            <div className="form-group discount">
                              <input
                                className={`discount`}
                                name={`discount-${i}`}
                                id={`discount-${i}`}
                                value={p.discount}
                                onChange={(e) => this.onInputChangeInvoicePossition(e, i)}
                                onBlur={(e) => this.updateSummary(e)}
                              />
                              <label htmlFor={`discount-${i}`}>
                                {`${this.context.t("Discount")} (%)`}
                              </label>
                            </div>
                            <div className="form-group discountPrice">
                              <input
                                className={`discountPrice`}
                                name={`discountPrice-${i}`}
                                id={`discountPrice-${i}`}
                                value={p.discountPrice}
                                onChange={(e) => this.onInputChangeInvoicePossition(e, i)}
                                onBlur={(e) => this.updateSummary(e)}
                              />
                              <label htmlFor={`discountPrice-${i}`}>
                                {this.context.t("Net price after discount")}
                              </label>
                            </div>
                          </>
                        ) : null
                      }
                      <div className="form-group vat">
                        <input
                          className={`vat`}
                          name={`vat-${i}`}
                          id={`vat-${i}`}
                          value={p.vat}
                          readOnly={true}
                          onClick={() => {
                            this.setState({
                              vatListToShow: i,
                            });
                          }}
                        />
                        <label
                          htmlFor={`vat-${i}`}
                          onClick={() => {
                            this.setState({
                              vatListToShow: i,
                            });
                          }}
                        >
                          {this.context.t("VAT rate")}
                        </label>
                        <span
                          className={`expend-icon ${vatListToShow === i ? "expended" : ""
                            }`}
                          onClick={() => {
                            this.setState({
                              vatListToShow: i,
                            });
                          }}
                        ></span>

                        {vatListToShow === i ? (
                          <div className="sugestions-list">
                            <ul>
                              <li onClick={() => this.setVat("23", i)}><div className="name">23</div></li>
                              <li onClick={() => this.setVat("8", i)}><div className="name">8</div></li>
                              <li onClick={() => this.setVat("5", i)}><div className="name">5</div></li>
                              <li onClick={() => this.setVat("3", i)}><div className="name">3</div></li>
                              <li onClick={() => this.setVat("0", i)}><div className="name">0</div></li>
                              <li onClick={() => this.setVat("zw", i)}><div className="name">zw</div></li>
                              <li onClick={() => this.setVat("np", i)}><div className="name">np</div></li>
                              <li onClick={() => this.setVat("oo", i)}><div className="name">oo</div></li>
                            </ul>
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group netValue">
                        <input
                          className={`netValue`}
                          name={`netValue-${i}`}
                          id={`netValue-${i}`}
                          value={p.netValue}
                          onChange={(e) => this.onInputChangeInvoicePossition(e, i)}
                          onBlur={(e) => this.updateSummary(e)}
                          style={{ fontSize: p.netValue.length > 6 ? '12px' : '14px' }}
                        />
                        <label htmlFor={`netValue-${i}`}>
                          {this.context.t("Net value ")}
                        </label>
                      </div>
                      <div className="form-group grossValue">
                        <input
                          className={``}
                          name={`grossValue-${i}`}
                          id={`grossValue-${i}`}
                          value={p.grossValue}
                          onChange={(e) => this.onInputChangeInvoicePossition(e, i)}
                          onBlur={(e) => this.updateSummary(e)}
                          style={{ fontSize: p.grossValue.length > 6 ? '12px' : '14px' }}
                        />
                        <label htmlFor={`grossValue-${i}`}>
                          {this.context.t("Gross value ")}
                        </label>
                      </div>

                      {
                        invoicePositions.length > 1 ? (
                          <div className="delete-possition" onClick={() => this.deleteInvoicePossition(i)} ></div>
                        ) : null
                      }
                    </div>
                  );
                })
              }
              <div className="invoice-items-options">
                <div className="checkboxes">
                  <div className="checkbox-group" onClick={() => { this.setState({ showPkwiu: !showPkwiu }) }} >
                    <div className={`checkbox ${showPkwiu ? "checked" : ""}`} ></div>
                    <div className="label">{this.context.t("Show PKWiU")}</div>
                  </div>
                  <div className="checkbox-group" onClick={() => { this.setState({ showGtu: !showGtu }) }} >
                    <div className={`checkbox ${showGtu ? "checked" : ""}`} ></div>
                    <div className="label">{this.context.t("Show GTU")}</div>
                  </div>
                  <div className="checkbox-group" onClick={() => { this.setState({ showDiscount: !showDiscount }) }} >
                    <div className={`checkbox ${showDiscount ? "checked" : ""}`} ></div>
                    <div className="label">{this.context.t("Show discount")}%</div>
                  </div>
                </div>
                <div className="add-possition" onClick={() => this.addPossition()}>
                  {this.context.t("Add item")}
                </div>
              </div>
            </div>
            <div className="summary">
              {
                currencyMidPrice && currency !== 'PLN' ? (
                  <div className="currencyMidPriceContainer">
                    <h3>Kurs NBP</h3>
                    <div className="midPrice">{currencyMidPrice}</div>
                    <p> z dnia {currencyEffectiveDate} </p>
                    <div className="edit-day" id="currencyCalendarButton" onClick={() => this.setState({ showCurrencyCalenrad: true })}>
                      {this.context.t('Change day')}
                    </div>
                    {
                      showCurrencyCalenrad ? (
                        <div className="currency-calendar" id="currencyCalendarContainer">
                          <Calendar
                            selectDay={this.selectNewCurrencyDay}
                            selectedDay={{
                              display: moment(currencyEffectiveDate).format('LL'),
                              value: moment(currencyEffectiveDate)
                            }}
                            unselectDay={() => { }}
                            projectId={"none"}
                            startDate={moment(currencyEffectiveDate) || moment()}
                            arrowsOnLeft={true}
                          />
                        </div>
                      ) : null
                    }
                  </div>
                ) : null
              }
              <div>
                <div className="sum">
                  <div className="label">{this.context.t("Net sum")}</div>
                  <div className="value">{summary.netSum.toFixed(2)}</div>
                  <div id="currencyContainer" className="currency can-edit" onClick={() => this.setState({ showCurrencyList: !showCurrencyList })}>
                    {currency}
                    <span className={`expend-icon ${showCurrencyList ? "expended" : ""}`} ></span>

                    {
                      showCurrencyList ? (
                        <div className="sugestions-list">
                          <ul>
                            {
                              currencyList.map(c => {
                                return (
                                  <li onClick={e => { this.setCurrency(c) }}>
                                    {c}
                                  </li>
                                )
                              })
                            }
                          </ul>
                        </div>
                      ) : null
                    }
                  </div>
                </div>
                <div className="sum">
                  <div className="label">{this.context.t("VAT sum")}</div>
                  <div className="value">{summary.vatSum.toFixed(2)}</div>
                  <div className="currency">{currency}</div>
                </div>
                <div className="sum">
                  <div className="label">{this.context.t("Gross sum")}</div>
                  <div className="value">{summary.grossSum.toFixed(2)}</div>
                  <div className="currency">{currency}</div>
                </div>
              </div>
            </div>
            <div
              className={`payment-options ${paymentMethod === "transfer" && paymentStatus === "unpaid" ? "" : "not-full"}`}>
              <div id="paymentMethodContainer" className="form-group">
                <input
                  className={``}
                  name="paymentMethod"
                  id={`paymentMethod`}
                  value={this.getPaymentMethodString(paymentMethod)}
                  readOnly={true}
                  onClick={() =>
                    this.setState({
                      showPaymentMethodList: true,
                    })
                  }
                />
                <label htmlFor="paymentMethod" onClick={() => this.setState({ showPaymentMethodList: true })} >
                  {this.context.t("Payment method")}
                </label>
                <span
                  className={`expend-icon ${showPaymentMethodList ? "expended" : ""}`}
                  onClick={() =>
                    this.setState({
                      showPaymentMethodList: !showPaymentMethodList,
                    })
                  }
                ></span>
                {
                  showPaymentMethodList ? (
                    <div className="sugestions-list">
                      <ul>
                        {
                          paymentMethodsList.map(method => {
                            return (
                              <li onClick={e => this.setState({ showPaymentMethodList: false, paymentMethod: method.value })}>
                                {this.context.t(method.title)}
                              </li>
                            )
                          })
                        }
                      </ul>
                    </div>
                  ) : null
                }
              </div>
              {
                paymentMethod === "transfer" ? (
                  <div id="accountNumberContainer" className="form-group">
                    <input
                      className={``}
                      name="accountNumber"
                      id={`accountNumber`}
                      value={accountNumber}
                      onChange={this.onInputChange}
                    />
                    <label htmlFor="accountNumber">
                      {this.context.t("Account number")}
                    </label>

                    {accountsList.length > 0 ? (
                      <span
                        className={`expend-icon ${showAccountsList ? "expended" : ""
                          }`}
                        onClick={() =>
                          this.setState({
                            showAccountsList: !showAccountsList,
                          })
                        }
                      ></span>
                    ) : null}
                    {showAccountsList ? (
                      <div className="sugestions-list">
                        <ul>
                          {accountsList.map((a) => {
                            return (
                              <li
                                onClick={() =>
                                  this.setState({
                                    showAccountsList: false,
                                    accountNumber: a.account_number,
                                    bankName: a.bank_name,
                                    bankBIC: a.bic,
                                  })
                                }
                              >
                                <span className="account-name">{`${a.name} (${a.currency})`}</span>
                                {a.account_number}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                ) : null
              }
              <div id="paymentStatusContainer" className="form-group">
                <input
                  className={``}
                  name="paymentStatus"
                  id={`paymentStatus`}
                  value={this.getPaymentStatusString(paymentStatus)}
                  readOnly={true}
                  onClick={() =>
                    this.setState({
                      showPaymentStatusList: true,
                    })
                  }
                />
                <label htmlFor="paymentStatus" onClick={() => this.setState({ showPaymentStatusList: true })} >
                  {this.context.t("Status")}
                </label>
                <span
                  className={`expend-icon ${showPaymentStatusList ? "expended" : ""
                    }`}
                  onClick={() =>
                    this.setState({
                      showPaymentStatusList: !showPaymentStatusList,
                    })
                  }
                ></span>

                {
                  showPaymentStatusList ? (
                    <div className="sugestions-list">
                      <ul>
                        <li
                          onClick={() =>
                            this.setState({
                              showPaymentStatusList: false,
                              paymentStatus: "unpaid",
                            })
                          }
                        >
                          {this.context.t("Unpaid ")}
                        </li>
                        <li
                          onClick={() =>
                            this.setState({
                              showPaymentStatusList: false,
                              paymentStatus: "paid",
                            })
                          }
                        >
                          {this.context.t("Paid ")}
                        </li>
                      </ul>
                    </div>
                  ) : null
                }
              </div>
              {
                paymentStatus === "unpaid" ? (
                  <div id="dueDatePickerContainer" className="form-group">
                    <input
                      className={``}
                      name="dueDate"
                      id={`dueDate`}
                      value={dueDate}
                      readOnly={true}
                      onClick={(e) => this.showDatePicker(e, "dueDate")}
                    />
                    <label htmlFor="dueDate" onClick={(e) => this.showDatePicker(e, "dueDate")}>{this.context.t("Due date")}</label>
                    <span className="calendar-icon" onClick={(e) => this.showDatePicker(e, "dueDate")}></span>

                    {
                      showDueDateCalendar ? (
                        <div
                          className={`date-picker due-date ${showDueDateCalendar ? "show" : ""
                            }`}
                          onClick={(e) => this.clickOnDatePicker(e, "dueDate")}
                        >
                          <Calendar
                            selectDay={this.selectDueDate}
                            selectedDay={moment(dueDate)}
                            unselectDay={() => { }}
                            projectId={"none"}
                            startDate={moment(dueDate) || moment()}
                            arrowsOnLeft={false}
                          />
                        </div>
                      ) : null
                    }
                  </div>
                ) : null
              }

              <div className="additional-options">
                <h3>{this.context.t("Additional options:")}</h3>
                <div className="form-group comments">
                  <input
                    className={`comments`}
                    name={`comments`}
                    id={`comments`}
                    value={comments}
                    onChange={(e) => this.onInputChange(e)}
                  />
                  <label htmlFor={`comments`}>{this.context.t("Comments")}</label>
                </div>
                <div className="checkbox-group" onClick={() => this.splitPaymentClick()} >
                  <div className={`checkbox ${splitPayment ? "checked" : ""}`} ></div>
                  <div className="label">{`${this.context.t("Split payment")} (split payment)`}</div>
                </div>
                <div className="checkbox-group" onClick={() => this.reverseChargeClick()} >
                  <div className={`checkbox ${reverseCharge ? "checked" : ""}`} ></div>
                  <div className="label">{`${this.context.t("Reverse charge")} (reverse charge)`}</div>
                </div>
                <div className="checkbox-group" onClick={() => this.metodaKasowaClick()} >
                  <div className={`checkbox ${metodaKasowa ? "checked" : ""}`} ></div>
                  <div className="label">{`${this.context.t("Metoda kasowa")}`}</div>
                </div>
              </div>


              <div className="mpk">
                {
                  !this.state.editingExistingInvoice ? (
                    <div className="split-button" onClick={() => {
                      this.toggleMpk()
                    }}>
                      {
                        addMPK ? this.context.t("Delete MPK split") : this.context.t("Add MPK split")
                      }
                    </div>
                  ) : null
                }

                {
                  addMPK && allProjectsWithMpk.includes(currentProject.id) ? (
                    <MpkSplitSaleInvoice
                      projectId={currentProject.id}
                      totalNetAmount={summary.netSum.toFixed(2)}
                      mpkSplit={mpkSplit}
                      setMpkSplit={this.setMpkSplit}
                      companyId={currentProject.company_id}
                    />
                  ) : null
                }
              </div>

              {
                isAccountantInCurrentCompany && waproUsers.includes(user.id) ? (
                  <div className="wapro-options">
                    <h3>{this.context.t("Required fields for documents imported to WAPRO Fakir")}:</h3>
                    <div className="form-group wapro-mag-container" ref={this.magTypeRef}>
                      <input
                        type="text"
                        className={``}
                        readOnly={true}
                        name="waproMagType"
                        id={`waproMagType`}
                        value={selectedMagDocType}
                        onClick={e => this.toggleMagDocTypeList(!showMagDocType)}
                      />
                      <label htmlFor="waproMagType">{this.context.t('Mag type:')}</label>
                      {
                        showMagDocType ? (
                          <DropDownList
                            list={magDocTypeList}
                            selectedOption={selectedMagDocType}
                            select={value => this.selectWaproField(value, "selectedMagDocType")}
                            headerText={this.context.t('Available types:')}
                          />
                        ) : (
                          null
                        )
                      }
                    </div>

                    <div className="form-group  wapro-fakir-type-container" ref={this.fakirTypeRef}>
                      <input
                        type="text"
                        className={``}
                        readOnly={true}
                        name="selectedFakirDocType"
                        id={`selectedFakirDocType`}
                        value={selectedFakirDocType}
                        onClick={e => this.toggleFakirDocTypeList(!showFakirDocType)}
                      />
                      <label htmlFor="selectedFakirDocType">{this.context.t('Fakir type:')}</label>
                      {
                        showFakirDocType ? (
                          <DropDownList
                            list={fakirDocTypeList}
                            selectedOption={selectedFakirDocType}
                            select={value => this.selectWaproField(value, "selectedFakirDocType")}
                            headerText={this.context.t('Available types:')}
                          />
                        ) : (
                          null
                        )
                      }
                    </div>

                    <div className="form-group wapro-fakir-dictionary-container" ref={this.fakirDictionaryRef}>
                      <input
                        type="text"
                        className={``}
                        readOnly={true}
                        name="selectedFakirDocDictionary"
                        id={`selectedFakirDocDictionary`}
                        value={selectedFakirDocDictionary}
                        onClick={e => this.toggleFakirDictionaryList(!showFakirDictionary)}
                      />
                      <label htmlFor="selectedFakirDocDictionary">{this.context.t('Fakir dictionary:')}</label>
                      {
                        showFakirDictionary ? (
                          <DropDownList
                            list={fakirDictionaryList}
                            selectedOption={selectedFakirDocDictionary}
                            select={value => this.selectWaproField(value, "selectedFakirDocDictionary")}
                            headerText={this.context.t('Available logs:')}
                          />
                        ) : (
                          null
                        )
                      }
                    </div>

                    <div className="form-group wapro-net-account-container" ref={this.netAccountRef}>
                      <input
                        type="text"
                        className={``}
                        readOnly={true}
                        name="selectedFakirNetAccounts"
                        id={`selectedFakirNetAccounts`}
                        value={selectedFakirNetAccounts}
                        onClick={e => this.toggleWaproNetAccoutList(!showWaproNetAccoutList)}
                      />
                      <label htmlFor="selectedFakirNetAccounts">{this.context.t('Fakir net account:')}</label>

                      {
                        showWaproNetAccoutList && netAccountList && netAccountList.length > 0 ? (
                          <DropDownList
                            list={netAccountList}
                            selectedOption={selectedFakirNetAccounts}
                            select={value => this.selectWaproField(value, "selectedFakirNetAccounts")}
                            headerText={this.context.t('Available net accounts:')}
                          />
                        ) : (
                          null
                        )
                      }
                    </div>

                    <div className="form-group wapro-doc-description-container" ref={this.fakirDescriptionRef}>
                      <input
                        type="text"
                        className={``}
                        readOnly={true}
                        name="selectedDocDescription"
                        id={`selectedDocDescription`}
                        value={selectedDocDescription}
                        onChange={this.onInputChange}
                        onClick={e => this.toggleDocDescriptionList(!showWaproDocDescriptionList)}
                      />
                      <label htmlFor="selectedDocDescription">{this.context.t('Description:')}</label>

                      {
                        showWaproDocDescriptionList ? (
                          <DropDownList
                            list={docDescriptionsList}
                            selectedOption={selectedDocDescription}
                            select={value => this.selectWaproField(value, "selectedDocDescription")}
                            headerText={this.context.t('Available descriptions:')}
                          />
                        ) : (
                          null
                        )
                      }
                    </div>
                  </div>
                ) : null
              }
            </div>
            <div className={`footer ${!logoURL ? "only-button" : ""}`}>
              {
                currentCompany && currentCompany.company && currentCompany.company.ksef_integration_id ? (
                  <div className="send-to-ksef">
                    <div className="checkbox-group" >
                      <div className={`checkbox ${sendToKsef ? "checked" : ""}`} onClick={() => this.setState({ sendToKsef: !sendToKsef })}></div>
                      <div className="label" onClick={() => this.setState({ sendToKsef: !sendToKsef })}>{`${this.context.t("Send to KSeF")}`}</div>
                    </div>
                  </div>
                ) : null
              }
              {
                !logoURL ? null : (
                  <div className="logo">
                    <div className="file">
                      <div className="label">
                        {this.context.t("Company logo on the invoice")}:
                      </div>
                      <img src={logoURL} alt="" />
                    </div>
                  </div>
                )
              }

              <div className="generate" onClick={() => {
                this.generateInvoice()
              }}>
                {
                  this.state.editingExistingInvoice ? this.context.t("Update invoice") : this.context.t("Generate invoice")
                }
              </div>
            </div>
          </div >
        </div >
      )
    } else {
      return null
    }
  }
}

SalesForm.contextTypes = {
  t: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
  user: state.User.user,
  userProjects: state.User.userProjects,
  currentProject: state.User.currentProject,
  userCompanies: state.Company.companies,
  currentCompany: state.Company.currentCompany,
  isNewInvoiceNumberPopupOpen: state.Company.isNewInvoiceNumberPopupOpen,
  newInvoiceNumber: state.Company.newInvoiceNumber
});

const mapDispatchToProps = {
  alertWarn: AlertActions.warning,
  getDocumentNumberForNewInvoice: CompanyActions.getDocumentNumberForNewInvoice,
  getSavedDocItems: CompanyActions.getSavedDocItems,
  getPrieviosGeneratedInvoices: CompanyActions.getPrieviosGeneratedInvoices,
  generateInvoice: CompanyActions.generateInvoice,
  handleLoadScreen: CompanyActions.handleLoadScreen,
  editInvoice: CompanyActions.editInvoice,
  checkCorrectInvoiceNumber: CompanyActions.checkCorrectInvoiceNumber,
  getCompanyWaproNetAccounts: CompanyActions.getCompanyWaproNetAccounts,
  closePopupAndDeleteInvoiceNumber: CompanyActions.closePopupAndDeleteInvoiceNumber,
  addNewInvoiceNumberingSeries: CompanyActions.addNewInvoiceNumberingSeries,
  addMpkSplitInstance: FileActions.addMpkSplitInstance,
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesForm);
