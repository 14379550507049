import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize'
import moment from 'moment'

import '../../../css/SuperadminEmailTab.css'

import { AlertActions } from '../../../actions/AlertActions'
import { UserActions } from '../../../actions/UserActions'

export class CompaniesList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showAddNewPopup: false,
            showReceiversGroupList: false,
            showPasswordPopup: false,
            receiversGroup: 'ALL_USERS',
            receiversText: 'All users',
            subject: '',
            content: '',

            password: '',
        }
    }

    componentDidMount() {
        window.addEventListener("keydown", this.escFunction, false)
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.informationEmails && this.props.informationEmails && this.props.informationEmails.length > prevProps.informationEmails.length) {
            this.setState({
                showAddNewPopup: false,
                showReceiversGroupList: false,
                showPasswordPopup: false,
                receiversGroup: 'ALL_USERS',
                receiversText: 'All users',
                subject: '',
                content: '',

                password: ''
            })
        }
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.escFunction, false)
    }

    escFunction = (e) => {
        if (e.keyCode === 27) {
            if (this.state.showReceiversGroupList) {
                this.closeReceiversGroupList(e)
            } else if (this.state.showPasswordPopup) {
                this.setState({
                    showPasswordPopup: false
                })
            } else if (this.state.showAddNewPopup) {
                this.setState({
                    showAddNewPopup: false
                })
            }
        }
    }

    onInputChange = e => {
        e.preventDefault()

        const { name, value } = e.target

        this.setState({ [name]: value })
    }

    submitEmail = e => {
        e.preventDefault()

        const { receiversGroup, subject, content } = this.state
        var valid = true

        if (!subject) {
            valid = false
            this.props.alertWarn(this.context.t('Subject is required'))
        }

        if (!content) {
            valid = false
            this.props.alertWarn(this.context.t('Content is required'))
        }

        if (valid) {
            this.setState({
                showPasswordPopup: true
            })
        }
    }

    sendEmail = e => {
        e.preventDefault()
        const { receiversGroup, subject, content, password } = this.state
        if (!password) {
            this.props.alertWarn(this.context.t('Password is required'))
        } else {
            this.props.sendEmail(receiversGroup, subject, content, password)
        }
    }

    showReceiversGroupList = () => {
        const { showReceiversGroupList } = this.state
        this.setState({
            showReceiversGroupList: !showReceiversGroupList
        })
    }

    closeReceiversGroupList = e => {
        if (e.keyCode === 27 || (!this.hasAncestor(e.target, "users-input") && this.state.showReceiversGroupList)) {
            this.setState({
                showReceiversGroupList: false
            })
        }
    }

    hasAncestor = (element, id) => {
        while (element) {
            if (element.id === id) return true

            element = element.parentNode
        }

        return false
    }

    setReceiversGroup = (groupName, text) => {
        this.setState({
            showReceiversGroupList: false,
            receiversGroup: groupName,
            receiversText: text
        })
    }

    getSortClass = name => {
        const { informationEmailsSortField, informationEmailsSortOrder } = this.props
        if (informationEmailsSortField === name) {
            if (informationEmailsSortOrder === "asc")
                return "icon-sort up"
            return "icon-sort down"
        }
        return "icon-sort"
    }

    switchSortingField = (name) => {
        const { informationEmailsSortField, informationEmailsSortOrder } = this.props
        if (informationEmailsSortField === name) {
            if (informationEmailsSortOrder === "asc") {
                this.props.sortAllInformationEmails(name, 'desc')
            } else {
                this.props.sortAllInformationEmails(name, 'asc')
            }
        } else {
            this.props.sortAllInformationEmails(name, 'asc')
        }
    }

    render() {
        const { informationEmails, user} = this.props
        const { showAddNewPopup, showPasswordPopup, showReceiversGroupList, receiversText, subject, content, password } = this.state

        return (
            <div className="superadmin-emails">
                {
                    showAddNewPopup ? (
                        <div className="popup" onClick={e => this.closeReceiversGroupList(e)}>
                            {
                                showPasswordPopup ? (
                                    <div className="card password">
                                        <div className="header">
                                            {this.context.t('Enter the password for your SUPERADMINA account to authorize the sending of e-mails to EasyDocs users')}
                                        </div>
                                        <div className="body">
                                            <form onSubmit={this.sendEmail}>
                                                <div className="form-group ">
                                                    <input type="password" name="password" value={password} onChange={this.onInputChange} />
                                                    <label htmlFor={`password`}>{this.context.t('Password')}</label>
                                                </div>
                                                <div className="buttons">
                                                    <div className="back" onClick={() => this.setState({ showPasswordPopup: false, password: '' })}>{this.context.t('Back')}</div>
                                                    <input type="submit" value={this.context.t('Send')} />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="card">
                                        <div className="close-button" onClick={() => this.setState({ showAddNewPopup: false })}></div>
                                        <div className="header">
                                            {this.context.t('Create informational e-mail')}
                                        </div>
                                        <div className="body">
                                            <form onSubmit={this.submitEmail}>
                                                <div className="form-group" id="users-input" onClick={e => this.showReceiversGroupList()}>
                                                    <input type="text" value={this.context.t(receiversText)} readOnly={true} />
                                                    <label htmlFor={`users-input`}>{this.context.t('Receivers')}</label>
                                                    <span className={`arrow ${showReceiversGroupList ? 'extended' : ''}`}></span>
                                                    {
                                                        showReceiversGroupList ? (
                                                            <ul>
                                                                <li onClick={() => this.setReceiversGroup('ALL_USERS', 'All users')}>{this.context.t('All users')}</li>
                                                                <li onClick={() => this.setReceiversGroup('ALL_U1', 'Only U1 users')}>{this.context.t('Only U1 users')}</li>
                                                                <li onClick={() => this.setReceiversGroup('ALL_U2', 'Only U2 users')}>{this.context.t('Only U2 users')}</li>
                                                                <li onClick={() => this.setReceiversGroup('ALL_U3', 'Only U3 users')}>{this.context.t('Only U3 users')}</li>
                                                                <li onClick={() => this.setReceiversGroup('ALL_ADMINS', 'Only admins')}>{this.context.t('Only admins')}</li>
                                                                <li onClick={() => this.setReceiversGroup('ALL_REPRESENTATIVES', 'Only company representatives')}>{this.context.t('Only company representatives')}</li>
                                                                <li onClick={() => this.setReceiversGroup('ALL_SUPERADMINS', 'Only superadmins')}>{this.context.t('Only superadmins')}</li>
                                                            </ul>
                                                        ) : (
                                                            null
                                                        )
                                                    }
                                                </div>
                                                <div className="form-group ">
                                                    <input type="text" name="subject" value={subject} onChange={this.onInputChange} />
                                                    <label htmlFor={`subject`}>{this.context.t('E-mail subject')}</label>
                                                </div>
                                                <div className="form-group ">
                                                    <TextareaAutosize
                                                        id="content"
                                                        name="content"
                                                        value={content}
                                                        onChange={this.onInputChange}
                                                        minRows={5}
                                                        maxRows={10}
                                                    />
                                                    <label htmlFor={`content`}>{this.context.t('E-mail content')}</label>
                                                </div>
                                                <input type="submit" value={this.context.t('Send')} />
                                            </form>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    ) : (
                        null
                    )
                }

                {
                    user.role === "SUPERADMIN" || user.id === 417 ? (
                        <div className="header-text">
                            {this.context.t('Here you can create and send an informational e-mail to EasyDocs users')}
                            <div className="create" onClick={() => this.setState({ showAddNewPopup: true })}>{this.context.t('Create')}</div>
                        </div>
                    ) : (
                        null
                    )
                }

                <div className="table-header">
                    {this.context.t('Sent e-mails:')}
                </div>

                {
                    informationEmails.length === 0 ? (
                        <div className="no-emails">
                            {this.context.t('No information e-mail has been sent yet')}
                        </div>
                    ) : (
                        <table>
                            <thead>
                                <tr>
                                    <th onClick={() => this.switchSortingField('created')}>
                                        {this.context.t('Post date')}
                                        <span className={this.getSortClass('created')}></span>
                                    </th>
                                    <th onClick={() => this.switchSortingField('receivers_group')}>
                                        {this.context.t('Receivers')}
                                        <span className={this.getSortClass('receivers_group')}></span>
                                    </th>
                                    <th onClick={() => this.switchSortingField('subject')}>
                                        {this.context.t('Subject')}
                                        <span className={this.getSortClass('subject')}></span>
                                    </th>
                                    <th onClick={() => this.switchSortingField('content')}>
                                        {this.context.t('E-mail content')}
                                        <span className={this.getSortClass('content')}></span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    informationEmails.map(e => {
                                        return (
                                            <tr>
                                                <td className="created">
                                                    {moment(e.created).format('YYYY-MM-DD HH:mm')}
                                                </td>
                                                <td className="receivers">
                                                    {
                                                        e.receivers_group === 'ALL_USERS' ? (
                                                            this.context.t('All users')
                                                        ) : (
                                                            e.receivers_group === 'ALL_U1' ? (
                                                                this.context.t('Only U1 users')
                                                            ) : (
                                                                e.receivers_group === 'ALL_U2' ? (
                                                                    this.context.t('Only U2 users')
                                                                ) : (
                                                                    e.receivers_group === 'ALL_U3' ? (
                                                                        this.context.t('Only U3 users')
                                                                    ) : (
                                                                        e.receivers_group === 'ALL_ADMINS' ? (
                                                                            this.context.t('Only admins')
                                                                        ) : (
                                                                            e.receivers_group === 'ALL_REPRESENTATIVES' ? (
                                                                                this.context.t('Only company representatives')
                                                                            ) : (
                                                                                e.receivers_group === 'ALL_SUPERADMINS' ? (
                                                                                    this.context.t('Only superadmins')
                                                                                ) : (
                                                                                    null
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    }
                                                </td>
                                                <td className="subject">{e.subject}</td>
                                                <td className="content">
                                                    {
                                                        e.content ? (
                                                            e.content.split('\n').map(p => {
                                                                if (p) {
                                                                    return (<p>{p}</p>)
                                                                } else {
                                                                    return (<span className="blank-line"></span>)
                                                                }
                                                            })
                                                        ) : (
                                                            null
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    )
                }
            </div>
        )
    }
}

CompaniesList.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    informationEmails: state.User.informationEmails || [],
    informationEmailsSortField: state.User.informationEmailsSortField,
    informationEmailsSortOrder: state.User.informationEmailsSortOrder,
})

const mapDispatchToProps = {
    sendEmail: UserActions.sendEmailsAsSuperadmin,
    sortAllInformationEmails: UserActions.sortAllInformationEmails,
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesList)