import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import countriesList from '../helpers/countriesList.json'

export class CountriesList extends Component {

    constructor(props) {
		super(props)
		this.state = {
            countries: [],
            filter: ''
        }
    }

    componentDidMount() {
        this.getCountriesList()
    }

    getCountriesList = () => {
        const language = localStorage.getItem('language')

        const countries = countriesList[language]

        this.setState({
            countries: countries
        })
    }

    onInputChange = e => {
        e.preventDefault()
        const { name, value } = e.target

        this.setState({ [name]: value })
    }

	checkIfRowMatchesFilter = row => {
		const {filter} = this.state

		var show = false

		if(filter && row.name && row.alpha2) {
			if(row.name.toLowerCase().startsWith(filter.toLocaleLowerCase())) show = true
			else if(row.alpha2.toLowerCase().includes(filter.toLocaleLowerCase())) show = true
		} else {
			show = true
		}

		return show
	}

    render() {
        const {selectedOption, headerText} = this.props
        const {countries, filter} = this.state

        return (
            <div className={`countries-list`} id="countries-list-container">
                <div className="filter-row">
                    <input autoFocus type="text" name="filter" value={filter} onChange={this.onInputChange} placeholder={this.context.t('Search')}/>
                </div>
                <SimpleBar style={{ height: 'auto', maxHeight: '200px', width: '100%'}}>
                    <ul>
                        {
                            countries.map((c, i) => {
                                if(this.checkIfRowMatchesFilter(c)) {
                                    return(
                                        <li className={`country-item ${selectedOption === c.alpha2 ? 'selected' : ''}`} onClick={() => this.props.select(c.alpha2.toUpperCase())} key={`country-${i}`}>{`${c.alpha2.toUpperCase()} - ${c.name}`}</li>
                                    )
                                } else {
                                    return null
                                }
                            })
                        }
                    </ul>
                </SimpleBar>
            </div>
        )
    }
}


CountriesList.contextTypes = {
	t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(CountriesList)
