import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

export class MarketingTab extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.informationEmails && this.props.informationEmails && this.props.informationEmails.length > prevProps.informationEmails.length) {
            this.setState({
                showAddNewPopup: false,
                showReceiversGroupList: false,
                showPasswordPopup: false,
                receiversGroup: 'ALL_USERS',
                receiversText: 'All users',
                subject: '',
                content: '',

                password: ''
            })
        }
    }

    componentWillUnmount() {
    }

    render() {
        const { easydocsMarketingReport } = this.props
        return (
            easydocsMarketingReport && easydocsMarketingReport.lastWeek && easydocsMarketingReport.currentMonth && easydocsMarketingReport.currentYear ? (
                <table className={`marketing-table`}>
                    <thead>
                        <tr>
                            <th className='period'>
                            </th>
                            <th className='label'>
                                {this.context.t('Number of new company registrations')}
                            </th>
                            <th className='label'>
                                {this.context.t('Number of activations of new companies')}
                            </th>
                            <th className='label'>
                                {this.context.t('New subscription payments count')}
                            </th>
                            <th className='label'>
                                {this.context.t('New users in new companies count')} (admin+zaproszenia / firmy nowe)
                            </th>
                            <th className='label'>
                                {this.context.t('New users count')} (admin+zaproszenia / firmy nowe i istniejące)
                            </th>
                            <th className='label'>
                                {this.context.t('New active users count')} (aktywacje nowych osób / firmy nowe i istniejące)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='period'>{this.context.t('Last 7 days')}</td>
                            <td className='value'>{easydocsMarketingReport.lastWeek.newCompaniesRegistered}</td>
                            <td className={`value ${easydocsMarketingReport.lastWeek.newCompaniesRegistered > easydocsMarketingReport.lastWeek.newActiveCompanies ? 'red' : ''}`}>{easydocsMarketingReport.lastWeek.newActiveCompanies}</td>
                            <td className='value'>{easydocsMarketingReport.lastWeek.newPaymentsCount}</td>
                            <td className='value'>{easydocsMarketingReport.lastWeek.newUsersInNewCompanies}</td>
                            <td className='value'>{easydocsMarketingReport.lastWeek.newRegisteredUsers}</td>
                            <td className='value'>{easydocsMarketingReport.lastWeek.newActiveUsers}</td>
                        </tr>

                        <tr>
                            <td className='period'>{this.context.t('Current month')}</td>
                            <td className='value'>{easydocsMarketingReport.currentMonth.newCompaniesRegistered}</td>
                            <td className={`value ${easydocsMarketingReport.currentMonth.newCompaniesRegistered > easydocsMarketingReport.currentMonth.newActiveCompanies ? 'red' : ''}`}>{easydocsMarketingReport.currentMonth.newActiveCompanies}</td>
                            <td className='value'>{easydocsMarketingReport.currentMonth.newPaymentsCount}</td>
                            <td className='value'>{easydocsMarketingReport.currentMonth.newUsersInNewCompanies}</td>
                            <td className='value'>{easydocsMarketingReport.currentMonth.newRegisteredUsers}</td>
                            <td className='value'>{easydocsMarketingReport.currentMonth.newActiveUsers}</td>
                        </tr>

                        <tr>
                            <td className='period'>{this.context.t('Current year')}</td>
                            <td className='value'>{easydocsMarketingReport.currentYear.newCompaniesRegistered}</td>
                            <td className={`value ${easydocsMarketingReport.currentYear.newCompaniesRegistered > easydocsMarketingReport.currentYear.newActiveCompanies ? 'red' : ''}`}>{easydocsMarketingReport.currentYear.newActiveCompanies}</td>
                            <td className='value'>{easydocsMarketingReport.currentYear.newPaymentsCount}</td>
                            <td className='value'>{easydocsMarketingReport.currentYear.newUsersInNewCompanies}</td>
                            <td className='value'>{easydocsMarketingReport.currentYear.newRegisteredUsers}</td>
                            <td className='value'>{easydocsMarketingReport.currentYear.newActiveUsers}</td>
                        </tr>
                    </tbody>
                </table>

            ) : (
                null
            )
        )
    }
}

MarketingTab.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    easydocsMarketingReport: state.User.easydocsMarketingReport
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketingTab)