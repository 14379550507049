/* eslint-disable import/no-anonymous-default-export */
export default [
    {
        country: "Albania",
        length: "28",
        code: "AL",
        format: "AL2!n8!n16!c"
    },
    {
        country: "Andorra",
        length: "24",
        code: "AD",
        format: "AD2!n4!n4!n12!c"
    },
    {
        country: "Austria",
        length: "20",
        code: "AT",
        format: "AT2!n5!n11!n"
    },
    {
        country: "Azerbaijan, Republic of",
        length: "28",
        code: "AZ",
        format: "AZ2!n4!a20!c"
    },
    {
        country: "Bahrain",
        length: "22",
        code: "BH",
        format: "BH2!n4!a14!c"
    },
    {
        country: "Belgium",
        length: "16",
        code: "BE",
        format: "BE2!n3!n7!n2!n"
    },
    {
        country: "Bosnia and Herzegovina",
        length: "20",
        code: "BA",
        format: "BA2!n3!n3!n8!n2!n"
    },
    {
        country: "Brazil",
        length: "29",
        code: "BR",
        format: "BR2!n8!n5!n10!n1!a1!c"
    },
    {
        country: "Bulgaria",
        length: "22",
        code: "BG",
        format: "BG2!n4!a4!n2!n8!c"
    },
    {
        country: "Costa Rica",
        length: "21",
        code: "CR",
        format: "CR2!n3!n14!n"
    },
    {
        country: "Croatia",
        length: "21",
        code: "HR",
        format: "HR2!n7!n10!n"
    },
    {
        country: "Cyprus",
        length: "28",
        code: "CY",
        format: "CY2!n3!n5!n16!c"
    },
    {
        country: "Czech Republic",
        length: "24",
        code: "CZ",
        format: "CZ2!n4!n6!n10!n"
    },
    {
        country: "Denmark",
        length: "18",
        code: "DK",
        format: "DK2!n4!n9!n1!n"
    },
    {
        country: "Dominican Republic",
        length: "28",
        code: "DO",
        format: "DO2!n4!c20!n"
    },
    {
        country: "Estonia",
        length: "20",
        code: "EE",
        format: "EE2!n2!n2!n11!n1!n"
    },
    {
        country: "Faroe Islands",
        length: "18",
        code: "FO",
        format: "FO2!n4!n9!n1!n"
    },
    {
        country: "Finland",
        length: "18",
        code: "FI",
        format: "FI2!n6!n7!n1!n"
    },
    {
        country: "France",
        length: "27",
        code: "FR",
        format: "FR2!n5!n5!n11!c2!n"
    },
    {
        country: "Georgia",
        length: "22",
        code: "GE",
        format: "GE2!n2!a16!n"
    },
    {
        country: "Germany",
        length: "22",
        code: "DE",
        format: "DE2!n8!n10!n"
    },
    {
        country: "Gibraltar",
        length: "23",
        code: "GI",
        format: "GI2!n4!a15!c"
    },
    {
        country: "Greece",
        length: "27",
        code: "GR",
        format: "GR2!n3!n4!n16!c"
    },
    {
        country: "Greenland",
        length: "18",
        code: "GL",
        format: "GL2!n4!n9!n1!n"
    },
    {
        country: "Guatemala",
        length: "28",
        code: "GT",
        format: "GT2!n4!c20!c"
    },
    {
        country: "Hungary",
        length: "28",
        code: "HU",
        format: "HU2!n3!n4!n1!n15!n1!n"
    },
    {
        country: "Iceland",
        length: "26",
        code: "IS",
        format: "IS2!n4!n2!n6!n10!n"
    },
    {
        country: "Ireland",
        length: "22",
        code: "IE",
        format: "IE2!n4!a6!n8!n"
    },
    {
        country: "Israel",
        length: "23",
        code: "IL",
        format: "IL2!n3!n3!n13!n"
    },
    {
        country: "Italy",
        length: "27",
        code: "IT",
        format: "IT2!n1!a5!n5!n12!c"
    },
    {
        country: "Jordan",
        length: "30",
        code: "JO",
        format: "JO2!n4!a4!n18!c"
    },
    {
        country: "Kazakhstan",
        length: "20",
        code: "KZ",
        format: "KZ2!n3!n13!c"
    },
    {
        country: "Kuwait",
        length: "30",
        code: "KW",
        format: "KW2!n4!a22!c"
    },
    {
        country: "Latvia",
        length: "21",
        code: "LV",
        format: "LV2!n4!a13!c"
    },
    {
        country: "Lebanon",
        length: "28",
        code: "LB",
        format: "LB2!n4!n20!c"
    },
    {
        country: "Liechtenstein (Principality of)",
        length: "21",
        code: "LI",
        format: "LI2!n5!n12!c"
    },
    {
        country: "Lithuania",
        length: "20",
        code: "LT",
        format: "LT2!n5!n11!n"
    },
    {
        country: "Luxembourg",
        length: "20",
        code: "LU",
        format: "LU2!n3!n13!c"
    },
    {
        country: "Macedonia",
        length: "19",
        code: "MK",
        format: "MK2!n3!n10!c2!n"
    },
    {
        country: "Malta",
        length: "31",
        code: "MT",
        format: "MT2!n4!a5!n18!c"
    },
    {
        country: "Mauritania",
        length: "27",
        code: "MR",
        format: "MR2!n5!n5!n11!n2!n"
    },
    {
        country: "Mauritius",
        length: "30",
        code: "MU",
        format: "MU2!n4!a2!n2!n12!n3!n3!a"
    },
    {
        country: "Moldova",
        length: "24",
        code: "MD",
        format: "MD2!n2!c18!c"
    },
    {
        country: "Monaco",
        length: "27",
        code: "MC",
        format: "MC2!n5!n5!n11!c2!n"
    },
    {
        country: "Montenegro",
        length: "22",
        code: "ME",
        format: "ME2!n3!n13!n2!n"
    },
    {
        country: "Netherlands",
        length: "18",
        code: "NL",
        format: "NL2!n4!a10!n"
    },
    {
        country: "Norway",
        length: "15",
        code: "NO",
        format: "NO2!n4!n6!n1!n"
    },
    {
        country: "Pakistan",
        length: "24",
        code: "PK",
        format: "PK2!n4!a16!c"
    },
    {
        country: "Poland",
        length: "28",
        code: "PL",
        format: "PL2!n8!n16!n"
    },
    {
        country: "Palestinian Territory, Occupied",
        length: "29",
        code: "PS",
        format: "PS2!n4!a21!c"
    },
    {
        country: "Portugal",
        length: "25",
        code: "PT",
        format: "PT2!n4!n4!n11!n2!n"
    },
    {
        country: "Qatar",
        length: "29",
        code: "QA",
        format: "QA2!n4!a21!c"
    },
    {
        country: "Republic of Kosovo",
        length: "20",
        code: "XK",
        format: "XK2!n4!n10!n2!n"
    },
    {
        country: "Romania",
        length: "24",
        code: "RO",
        format: "RO2!n4!a16!c"
    },
    {
        country: "Saint Lucia",
        length: "32",
        code: "LC",
        format: "LC2!n4!a24!c"
    },
    {
        country: "San Marino",
        length: "27",
        code: "SM",
        format: "SM2!n1!a5!n5!n12!c"
    },
    {
        country: "Sao Tome And Principe",
        length: "25",
        code: "ST",
        format: "ST2!n8!n11!n2!n"
    },
    {
        country: "Saudi Arabia",
        length: "24",
        code: "SA",
        format: "SA2!n2!n18!c"
    },
    {
        country: "Serbia",
        length: "22",
        code: "RS",
        format: "RS2!n3!n13!n2!n"
    },
    {
        country: "Seychelles",
        length: "31",
        code: "SC",
        format: "SC2!n4!a2!n2!n16!n3!a"
    },
    {
        country: "Slovak Republic",
        length: "24",
        code: "SK",
        format: "SK2!n4!n6!n10!n"
    },
    {
        country: "Slovenia",
        length: "19",
        code: "SI",
        format: "SI2!n5!n8!n2!n"
    },
    {
        country: "Spain",
        length: "24",
        code: "ES",
        format: "ES2!n4!n4!n1!n1!n10!n"
    },
    {
        country: "Sweden",
        length: "24",
        code: "SE",
        format: "SE2!n3!n16!n1!n"
    },
    {
        country: "Switzerland",
        length: "21",
        code: "CH",
        format: "CH2!n5!n12!c"
    },
    {
        country: "Timor-Leste",
        length: "23",
        code: "TL",
        format: "TL2!n3!n14!n2!n"
    },
    {
        country: "Tunisia",
        length: "24",
        code: "TN",
        format: "TN2!n2!n3!n13!n2!n"
    },
    {
        country: "Turkey",
        length: "26",
        code: "TR",
        format: "TR2!n5!n1!c16!c"
    },
    {
        country: "Ukraine",
        length: "29",
        code: "UA",
        format: "UA2!n6!n19!c"
    },
    {
        country: "United Arab Emirates",
        length: "23",
        code: "AE",
        format: "AE2!n3!n16!n"
    },
    {
        country: "United Kingdom",
        length: "22",
        code: "GB",
        format: "GB2!n4!a6!n8!n"
    },
    {
        country: "Virgin Islands, British",
        length: "24",
        code: "VG",
        format: "VG2!n4!a16!n"
    }
]