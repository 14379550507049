import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { EventActions } from '../actions/EventActions'

export class DownloadMobileAppPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showDesktopVersion: false
        }
    }

    componentDidMount() {
        document.body.classList.add('mobile')

        if (sessionStorage.getItem('showDesktopVersion')) {
            this.setState({
                showDesktopVersion: true
            })
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('mobile')
    }

    showDesktopVersion = () => {
        document.body.classList.remove('mobile')
        this.setState({
            showDesktopVersion: true
        })

        sessionStorage.setItem('showDesktopVersion', true)
    }

    render() {
        const { showDesktopVersion } = this.state
        const { isAndroid, isIOS } = this.props
        if (showDesktopVersion) return null
        return (
            <div className="download-mobile-app-container">
                <div className="main-container">
                    <div className="ed-logo"></div>
                    <div className="description">
                        {this.context.t('To use EasyDocs on your phone, download our mobile app')}
                    </div>
                    <div className="mobile-app-link">
                        {
                            isAndroid ? (
                                <a className="google-play" href="https://play.google.com/store/apps/details?id=pl.proxypartners" target="_blank"></a>
                            ) : (
                                <a className="app-store" href="https://apps.apple.com/us/app/easydocs/id1534836899" target="_blank"></a>
                            )
                        }
                    </div>
                </div>
                <div className="desktop-version" onClick={() => this.showDesktopVersion()}>
                    {this.context.t('I want to use the desktop version')}
                </div>
            </div>
        )
    }
}

DownloadMobileAppPage.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadMobileAppPage)