import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'

import { EventActions } from '../actions/EventActions'

export class Calculator extends Component {

    constructor(props) {
		super(props)
		this.state = {
            firstNumber: '',
            secondNumber: '',
            operator: '',
            lastOperation: '',
            clearLast: true
        }
    }

    componentDidMount() {
        window.addEventListener("keydown", this.keyDownFunction, false)
        const {readText} = this.props

        if(readText) {
            this.setState({
                firstNumber: readText.replace(/[^0-9,.-]/gm, '').replace(',', '.')
            })
        }
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.keyDownFunction, false)
    }

    keyDownFunction = (e) => {
        console.log(e.key)
		if(e.keyCode >= 48 && e.keyCode <= 57) {
            if(e.key !== '%' && e.key !== '*') this.handleNumberClick((e.keyCode - 48).toString())
		} if(e.keyCode >= 96 && e.keyCode <= 105) {
            this.handleNumberClick((e.keyCode - 96).toString())
		} else if((e.keyCode === 56 && e.key === '*')|| e.keyCode === 106) {
            this.handleOperatorClick('*')
        } else if(e.keyCode === 187 || e.keyCode === 107) {
            this.handleOperatorClick('+')
        } else if(e.keyCode === 189 || e.keyCode === 109) {
            this.handleOperatorClick('-')
        } else if(e.keyCode === 191 || e.keyCode === 111) {
            this.handleOperatorClick('/')
        } else if(e.keyCode === 190 || e.keyCode === 110 || e.keyCode === 188) {
            this.handleOperatorClick('.')
        } else if(e.keyCode === 53 && e.key === '%') {
            this.handleOperatorClick('%')
        } else if(e.keyCode === 187 || e.keyCode === 13) {
            this.handleOperatorClick('=')
        } else if(e.keyCode === 8) {
            this.handleClearClick()
        }
    }

    handleClearClick = () => {
        const {clearLast} = this.state
        var {firstNumber, secondNumber, operator} = this.state

        if(clearLast) {
            if(secondNumber) {
                secondNumber = secondNumber.slice(0, -1)
            } else if(operator) {
                operator = ''
            } else if(firstNumber) {
                firstNumber = firstNumber.slice(0, -1)
            }
            this.setState({
                firstNumber: firstNumber,
                secondNumber: secondNumber,
                operator: operator
            })
        } else {
            this.setState({
                clearLast: true,
                lastOperation: '',
                firstNumber: '',
                secondNumber: '',
                operator: '',
            })
        }
    }

    handleOperatorClick = clickedOperator => {
        console.log('handleOperatorClick')
        console.log(clickedOperator)

        if(clickedOperator === '=') {
            this.calculate()
        } else {
            var {firstNumber, secondNumber, operator} = this.state

            console.log(firstNumber)

            if(firstNumber) {
                var operator = ''
                if(clickedOperator === '%') operator = '\u0025 \u00D7 '
                else if(clickedOperator === '/') operator = ' \u00F7 '
                else if(clickedOperator === '*') operator = ' \u00D7 '
                else if(clickedOperator === '-') operator = ' \u2212 '
                else if(clickedOperator === '+') operator = ' \u002B '
                else if(clickedOperator === '.') {
                    if(firstNumber && !secondNumber && !firstNumber.includes('.')) {
                        this.setState({
                            firstNumber: firstNumber + '.'
                        })
                    } else if(firstNumber && secondNumber && !secondNumber.includes('.')) {
                        this.setState({
                            secondNumber: secondNumber + '.'
                        })
                    }
                } else if(clickedOperator === '+-') {
                    if(!this.state.operator) {
                        if(firstNumber.includes('-')) {
                            this.setState({
                                firstNumber: firstNumber.replace(/-/m, '')
                            })
                        } else {
                            this.setState({
                                firstNumber: '-' + firstNumber
                            })
                        }
                    } else if(firstNumber && this.state.operator) {
                        if(secondNumber.includes('-')) {
                            this.setState({
                                secondNumber: secondNumber.replace(/-/m, '')
                            })
                        } else {
                            this.setState({
                                secondNumber: '-' + secondNumber
                            })
                        }
                    }
                }

                if(operator && firstNumber.endsWith('.')) {
                    this.setState({
                        firstNumber: firstNumber.slice(0, -1),
                    })
                }

                if(operator) {
                    this.setState({
                        operator: operator,
                        clearLast: true,
                        lastOperation: '',
                    })
                }
            } else if(clickedOperator === '+-') {
                if(!secondNumber && !operator) {
                    if(firstNumber.includes('-')) {
                        this.setState({
                            firstNumber: firstNumber.replace(/-/m, '')
                        })
                    } else {
                        this.setState({
                            firstNumber: '-' + firstNumber
                        })
                    }
                } else if(firstNumber && this.state.operator) {
                    if(secondNumber.includes('-')) {
                        this.setState({
                            secondNumber: secondNumber.replace(/-/m, '')
                        })
                    } else {
                        this.setState({
                            secondNumber: '-' + secondNumber
                        })
                    }
                }
            }
        }
    }

    handleNumberClick = number => {
        const {firstNumber, operator, secondNumber} = this.state

        if(operator) {
            this.setState({
                secondNumber: secondNumber + number,
                clearLast: true,
                lastOperation: '',
            })
        } else {
            this.setState({
                firstNumber: firstNumber + number,
                clearLast: true,
                lastOperation: '',
            })
        }
    }

    calculate = () => {
        var {firstNumber, operator, secondNumber} = this.state

        if(firstNumber && operator && secondNumber) {
            var lastOperation = firstNumber + operator + secondNumber
            if(operator === '\u0025 \u00D7 ') { //%
                firstNumber = parseFloat(parseFloat(firstNumber) * parseFloat(secondNumber) / 100).toFixed(2)
            } else if(operator === ' \u00F7 ') { ///
                firstNumber = parseFloat(parseFloat(firstNumber) / parseFloat(secondNumber)).toFixed(2)
            } else if(operator === ' \u00D7 ') { //*
                firstNumber = parseFloat(parseFloat(firstNumber) * parseFloat(secondNumber)).toFixed(2)
            } else if(operator === ' \u2212 ') { //-
                firstNumber = parseFloat(parseFloat(firstNumber) - parseFloat(secondNumber)).toFixed(2)
            } else if(operator === ' \u002B ') { //+
                firstNumber = parseFloat(parseFloat(firstNumber) + parseFloat(secondNumber)).toFixed(2)
            }

            this.props.returnValue(firstNumber, this.props.propName, this.props.ocrMethod, this.props.fileId)

            if(firstNumber.endsWith('.00')) firstNumber = firstNumber.slice(0, -3)
            this.setState({
                firstNumber: firstNumber,
                operator: '',
                secondNumber: '',
                clearLast: false,
                lastOperation: lastOperation,
            })
        }
    }

    render() {
        const {posYCalculator, posUpCalculator} = this.props
        const {firstNumber, secondNumber, operator, lastOperation, clearLast} = this.state

        console.log(operator)

        return (
            <div className={`calculator-container ${posUpCalculator ? 'flip-top' : ''}`} style={{top: posYCalculator}} >
                <div className="result-row">
                    <div className={`last-operation ${lastOperation ? 'show' : ''}`}>{lastOperation}</div>
                    <div className="result">{firstNumber}{operator}{secondNumber}</div>
                </div>
                <div className="body">
                    <div className="buttons-row">
                        <div className="button clear special" onClick={() => this.handleClearClick()}>{clearLast ? 'CE' : 'AC'}</div>
                        <div className="button special" onClick={() => this.handleOperatorClick('%')}>{'\u0025'}</div>
                        <div className="button special" onClick={() => this.handleOperatorClick('+-')}>{'\u00B1'}</div>
                        <div className="button special" onClick={() => this.handleOperatorClick('/')}>{'\u00F7'}</div>
                    </div>
                    <div className="buttons-row">
                        <div className="button" onClick={() => this.handleNumberClick('7')}>7</div>
                        <div className="button" onClick={() => this.handleNumberClick('8')}>8</div>
                        <div className="button" onClick={() => this.handleNumberClick('9')}>9</div>
                        <div className="button special" onClick={() => this.handleOperatorClick('*')}>{'\u00D7'}</div>
                    </div>
                    <div className="buttons-row">
                        <div className="button" onClick={() => this.handleNumberClick('4')}>4</div>
                        <div className="button" onClick={() => this.handleNumberClick('5')}>5</div>
                        <div className="button" onClick={() => this.handleNumberClick('6')}>6</div>
                        <div className="button special" onClick={() => this.handleOperatorClick('-')}>{'\u2212'}</div>
                    </div>
                    <div className="buttons-row">
                        <div className="button" onClick={() => this.handleNumberClick('1')}>1</div>
                        <div className="button" onClick={() => this.handleNumberClick('2')}>2</div>
                        <div className="button" onClick={() => this.handleNumberClick('3')}>3</div>
                        <div className="button special" onClick={() => this.handleOperatorClick('+')}>{'\u002B'}</div>
                    </div>
                    <div className="buttons-row">
                        <div className="button" onClick={() => this.handleNumberClick('0')}>0</div>
                        <div className="button" onClick={() => this.handleOperatorClick('.')}>.</div>
                        <div className="button equal special" onClick={() => this.handleOperatorClick('=')}>{'\u003D'}</div>
                    </div>
                </div>
            </div>
        )
    }
}


Calculator.contextTypes = {
	t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Calculator)
