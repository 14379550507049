import arrayObjectIndexOf from "../helpers/indexOfObject"

export const ExcelSesionConsts = {
    START_SESSION: 'START_SESSION',
    GENERATE_EXCEL: 'GENERATE_EXCEL',
    GENERATE_EXCEL_ERROR: 'GENERATE_EXCEL_ERROR',
    STOP_SESSION: 'STOP_SESSION',
    ADD_DOCS: 'ADD_DOCS',
}

const initialState = {
    sessionActive: false,
    generatingExcel: false,
    sessionDocs: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ExcelSesionConsts.START_SESSION:
            return { ...state, ...{ sessionActive: true, generatingExcel: false, sessionDocs: [] } }
        case ExcelSesionConsts.GENERATE_EXCEL:
            return { ...state, ...{ generatingExcel: true } }
        case ExcelSesionConsts.GENERATE_EXCEL_ERROR:
            return { ...state, ...{ generatingExcel: false } }
        case ExcelSesionConsts.STOP_SESSION:
            return { ...state, ...{ sessionActive: false, generatingExcel: false, sessionDocs: [] } }
        case ExcelSesionConsts.ADD_DOCS:
            const { sessionActive } = state
            if (sessionActive) {
                var currentDocsCopy = [...state.sessionDocs]
                var projectIndex = arrayObjectIndexOf(currentDocsCopy, action.projectId, "projectId");

                if (projectIndex > -1) {
                    currentDocsCopy[projectIndex].docs = [].concat(currentDocsCopy[projectIndex].docs, action.docs)
                } else {
                    currentDocsCopy.push({
                        projectId: action.projectId,
                        docs: action.docs
                    })
                }
                return { ...state, ...{ sessionDocs: currentDocsCopy } }
            }
            return { ...state, ...{ sessionDocs: [] } }
        default:
            return state
    }
}