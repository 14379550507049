import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import LanguageSwitcher from '../components/LanguageSwitcher'

import Logo from '../img/easy-docs-logo-big.svg'


export class Maintance extends Component {
    constructor(props){
        super(props)
        this.state = {
        }
    }


    componentDidMount() {
    }


    render() {
        return(
            <div className="login-container">
                <LanguageSwitcher />

                <div className="header">
                    <div className="logo">
                        <a href={'https://easydocs.pl/'}/>
                        <img src={Logo} alt="EasyDocs Logo"/>
                    </div>
                    <div className="go-back" onClick={() => this.props.history.goBack()}>{this.context.t('Go back')}</div>
                </div>
                <div className="form-col">
                    <form>
                        <h1>{this.context.t('Maintenance break')}</h1>
                        <div className="maintance-info">
                            {/* <p className="info">
                                {this.context.t('Expected resume: Friday, 18:00.')}
                            </p> */}
                            <p className="no-margin">
                                {this.context.t('EasyDocs service is out for maintenance.')}
                            </p> 
                            <p>
                                {this.context.t('We will successively inform you on this page about the date of restoring access to the service.')}
                            </p>
                            <p>
                                {this.context.t('Thank you for your understanding.')}
                            </p>

                            <p className="team">
                                {this.context.t('EasyDocs Team')}
                            </p>
                        </div>
                    </form>
                </div>
                <div className="img-col">
                </div>
            </div>
        )
    }
}

Maintance.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Maintance)

