import React, { Component } from "react";
import { NavLink, Link, Route, Redirect, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactTooltip from 'react-tooltip'

import { TabsControlerActions } from '../../actions/V2_TabsControlerActions'
import { ActionsTabsComponents, AvailableTabs } from "../../reducers/V2_TabsReducer"

export class RightMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }


    render() {
        const {
            menuExtended,
            currentProject
        } = this.props
        return (
            <>
                <div className={`new-menu-wrapper right ${menuExtended ? 'full-width' : ''}`}>
                    <div className="instruction-number" data-tip='show' data-for='instruction-tooltip-3'>3</div>
                    <div className="menu-item all-docs" onClick={() => this.props.openNewTab(AvailableTabs.ALL_DOCS, AvailableTabs.ALL_DOCS)}>{this.context.t('Your documents')}</div>
                    <div className="menu-item payments-assistant" onClick={() => this.props.openNewTab(AvailableTabs.ACTIONS_TABLE, ActionsTabsComponents.PAYMRNTS_ASSISTANT)}>{this.context.t('Payment assistant')}</div>
                    <div className="menu-item accounting-assistant" onClick={() => this.props.openNewTab(AvailableTabs.ACTIONS_TABLE, ActionsTabsComponents.ZIP_ASSISTANT)}>{this.context.t('Packages for accounting')}</div>
                    <div className="menu-item expenses-assistant" onClick={() => this.props.openNewTab(AvailableTabs.ACTIONS_TABLE, ActionsTabsComponents.EXPENSES_ASSISTANT)}>{this.context.t('Expenses assistant')}</div>
                    {/* <div className="menu-item settlement-assistant">{this.context.t('Settlement assistant')}</div>
                <div className="menu-item flow-assistant">{this.context.t('Flow assistant')}</div> */}
                    <div className="menu-item sale" onClick={() => this.props.openNewTab(AvailableTabs.ALL_SALE_INVOICES, AvailableTabs.ALL_SALE_INVOICES)}>{this.context.t('Sales')}</div>
                    <div className="menu-item reports" onClick={() => this.props.openNewTab(AvailableTabs.ACTIONS_TABLE, ActionsTabsComponents.ACTIONS)}>{this.context.t('Actions')}</div>

                    <div className="extent-menu right-menu" onClick={() => this.props.toggleMenu()}>{this.context.t('Hide')}</div>
                </div>

                <ReactTooltip id='instruction-tooltip-3' className='default-tooltip instruction-tooltip' effect='solid' place='left'>
                    <h5>{this.context.t('Use')}</h5>
                    <p>{this.context.t('Use EasyDocs to:')}</p>
                    <ul>
                        <li>{this.context.t('Save time on document handling')}</li>
                        <li>{this.context.t('Avoid mistakes')}</li>
                        <li>{this.context.t('Generate transfer packages and import them to the bank')}</li>
                        <li>{this.context.t('Pay your invoices on time')}</li>
                        <li>{this.context.t('Search for documents in just a few seconds, even those from several years ago')}</li>
                        <li>{this.context.t('Always have documents at hand')}</li>
                        <li>{this.context.t('Track costs using summaries')}</li>
                    </ul>
                </ReactTooltip>
            </>
        )
    }
}

RightMenu.contextTypes = {
    t: PropTypes.func,
};

const mapStateToProps = (state) => ({
    currentProject: state.User.currentProject,
});

const mapDispatchToProps = {
    openNewTab: TabsControlerActions.openNewTab,
}

export default connect(mapStateToProps, mapDispatchToProps)(RightMenu)
