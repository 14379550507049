import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'

export class PreviewToolbar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showDownloadOptions: false,
            showMoreDocOptions: false
        }

        this.containerRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
    }

    handleClick = (event) => {
        if (this.containerRef && !this.containerRef.current.contains(event.target)) {
            this.setState({
                showDownloadOptions: false,
                showMoreDocOptions: false
            })
        } else {
            if (event.target.nodeName !== 'LI' && this.state.showMoreDocOptions) {
                this.setState({
                    showMoreDocOptions: false
                })
            }
            if (!(event.target.nodeName === 'A' || event.target.nodeName === 'LI') && this.state.showDownloadOptions) {
                this.setState({
                    showDownloadOptions: false
                })
            }
        }
    }

    showLinkedDocsOnLoad = () => {
        const { pdfPreviewData } = this.props
        return (pdfPreviewData.name && (!pdfPreviewData.name.includes('_corporate') || !pdfPreviewData.name.includes('_expenses.') || !pdfPreviewData.name.includes('_expenses('))) && !pdfPreviewData.deleted
    }

    toggleDownloadOptions = e => {
        this.setState({
            showDownloadOptions: !this.state.showDownloadOptions
        })
    }

    toggleMoreDocsOptions = e => {
        if (e.target.nodeName !== 'LI') {
            this.setState({
                showMoreDocOptions: !this.state.showMoreDocOptions
            })
        }
    }

    hasOtherBindersInCompany = () => {
        const { pdfPreviewData, userProjects } = this.props
        var companyId = -1
        var docProject = userProjects.find(p => p.id === pdfPreviewData.projectId)
        if (docProject) {
            companyId = docProject.company_id
            if (userProjects.find(p => p.id !== pdfPreviewData.projectId && p.company_id === companyId)) return true
        }

        return false
    }

    render() {
        const { showDownloadOptions, showMoreDocOptions } = this.state
        const { user, currentProject, sideBySide, pdfPreviewData, pdfPreviewDataBody, showSideBySidePreview, pageNumber, numPages, pdfScale, pdfScalePercentage, maxPdfScale, didFitToWidth, imgScale, imgScalePercentage, didFitImgToWidth, showDocData, showLinkedDoc, pageNumberSecondDoc, secondDocNumPages } = this.props

        if (sideBySide) {
            return (
                <div className="toolbar" ref={this.containerRef}>
                    <div className="file-one">
                        <div className="start">
                            <div className="toggle-doc-data"></div>
                            <div className="file-name">{`(ED${pdfPreviewData.id}) ${pdfPreviewData.name}`}</div>
                        </div>
                        <div className="center">
                            <div className="pdf-controls">
                                {
                                    pdfPreviewDataBody ? (
                                        pdfPreviewDataBody.includes('application/pdf') ? (
                                            <>
                                                <div className="paginator">
                                                    <div className={`prev-page ${pageNumber === 1 ? 'hide' : ''}`} onClick={() => {
                                                        if (pageNumber !== 1) this.props.prevPdfPage()
                                                    }}></div>
                                                    <div className="numeration"><span className="current-page">{pageNumber}</span> / <span className="pages-count">{numPages}</span></div>
                                                    <div className={`next-page ${pageNumber === numPages ? 'hide' : ''}`} onClick={() => {
                                                        if (pageNumber !== numPages) this.props.nextPdfPage()
                                                    }}></div>
                                                </div>
                                                <div className="zoom">
                                                    <span className={`zoom-out ${pdfScale === 1.5 * 0.25 ? 'hide' : ''}`} onClick={() => this.props.zoomOut('pdf')}></span>
                                                    <span className="current-scale">{`${pdfScalePercentage}%`}</span>
                                                    <span className={`zoom-in ${pdfScale === maxPdfScale ? 'hide' : ''}`} onClick={() => this.props.zoomIn('pdf')}></span>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="zoom">
                                                    <span className={`zoom-out ${imgScale === 0.6 * 0.25 ? 'hide' : ''}`} onClick={() => this.props.zoomOut('img')}></span>
                                                    <span className="current-scale">{`${imgScalePercentage}%`}</span>
                                                    <span className={`zoom-in ${imgScale === 1.0 ? 'hide' : ''}`} onClick={() => this.props.zoomIn('img')}></span>
                                                </div>
                                            </>
                                        )
                                    ) : (
                                        null
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="file-two">
                        <div className="start">
                            <div className="toggle-doc-data"></div>
                            <div className="file-name">{showSideBySidePreview.fileId ? `(ED${showSideBySidePreview.fileId}) ${showSideBySidePreview.fileName}` : showSideBySidePreview.fileName}</div>
                        </div>
                        <div className="center">
                            <div className="pdf-controls">
                                {
                                    showSideBySidePreview.fileBody ? (
                                        showSideBySidePreview.fileBody.includes('application/pdf') ? (
                                            <>
                                                <div className="paginator">
                                                    <div className={`prev-page ${pageNumberSecondDoc === 1 ? 'hide' : ''}`} onClick={() => {
                                                        if (pageNumberSecondDoc !== 1) this.props.prevPdfPageSecondDoc()
                                                    }}></div>
                                                    <div className="numeration"><span className="current-page">{pageNumberSecondDoc}</span> / <span className="pages-count">{secondDocNumPages}</span></div>
                                                    <div className={`next-page ${pageNumberSecondDoc === secondDocNumPages ? 'hide' : ''}`} onClick={() => {
                                                        if (pageNumberSecondDoc !== secondDocNumPages) this.props.nextPdfPageSecondDoc()
                                                    }}></div>
                                                </div>
                                            </>
                                        ) : (
                                            null
                                        )
                                    ) : (
                                        null
                                    )
                                }
                            </div>
                        </div>
                        <div className="end">
                            <div className="doc-controls">
                                {
                                    showSideBySidePreview.fileBody ? (
                                        <>
                                            <a className={`download`} href={showSideBySidePreview.fileBody} download={showSideBySidePreview.fileName}></a>
                                            <div className={`close`} onClick={() => this.props.closeSideBySidePreview()}></div>
                                        </>
                                    ) : (
                                        null
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="toolbar" ref={this.containerRef}>
                    <div className="start">
                        <div className="toggle-doc-data"></div>
                        <div className="file-name">{`(ED${pdfPreviewData.id}) ${pdfPreviewData.name}`}</div>
                    </div>
                    <div className="center">
                        <div className="pdf-controls">
                            {
                                pdfPreviewDataBody ? (
                                    pdfPreviewDataBody.includes('application/pdf') ? (
                                        <>
                                            <div className="paginator">
                                                <div className={`prev-page ${pageNumber === 1 ? 'hide' : ''}`} onClick={() => {
                                                    if (pageNumber !== 1) this.props.prevPdfPage()
                                                }}></div>
                                                <div className="numeration"><span className="current-page">{pageNumber}</span> / <span className="pages-count">{numPages}</span></div>
                                                <div className={`next-page ${pageNumber === numPages ? 'hide' : ''}`} onClick={() => {
                                                    if (pageNumber !== numPages) this.props.nextPdfPage()
                                                }}></div>
                                            </div>
                                            <div className="zoom">
                                                <span className={`zoom-out ${pdfScale === 1.5 * 0.25 ? 'hide' : ''}`} onClick={() => this.props.zoomOut('pdf')}></span>
                                                <span className="current-scale">{`${pdfScalePercentage}%`}</span>
                                                <span className={`zoom-in ${pdfScale === maxPdfScale ? 'hide' : ''}`} onClick={() => this.props.zoomIn('pdf')}></span>
                                            </div>
                                            <div className="fit-dimensions">
                                                {
                                                    !didFitToWidth ? (
                                                        <span className="fit-to-width" data-tip='show' data-for='fit-file-to-dimensions' onClick={() => this.props.fitToWidth('pdf')}></span>
                                                    ) : (
                                                        <span className="fit-to-height" data-tip='show' data-for='fit-file-to-dimensions' onClick={() => this.props.fitToHeight('pdf')}></span>
                                                    )
                                                }

                                                <ReactTooltip id='fit-file-to-dimensions' className='default-tooltip info-tooltip' effect='solid' place='bottom' delayShow={1000}>
                                                    <p>
                                                        {
                                                            didFitToWidth ?
                                                                this.context.t('Fit the document size to the window height')
                                                                :
                                                                this.context.t('Fit the document size to the window width')
                                                        }
                                                    </p>
                                                </ReactTooltip>

                                                <span className="rotate-doc" data-tip='show' data-for='rotate-doc' onClick={() => this.props.rotateDoc('pdf')}></span>

                                                <ReactTooltip id='rotate-doc' className='default-tooltip info-tooltip' effect='solid' place='bottom' delayShow={1000}>
                                                    <p>
                                                        {this.context.t('Rotate the document 90 degrees clockwise')}
                                                    </p>
                                                </ReactTooltip>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="zoom">
                                                <span className={`zoom-out ${imgScale === 0.6 * 0.25 ? 'hide' : ''}`} onClick={() => this.props.zoomOut('img')}></span>
                                                <span className="current-scale">{`${imgScalePercentage}%`}</span>
                                                <span className={`zoom-in ${imgScale === 1.0 ? 'hide' : ''}`} onClick={() => this.props.zoomIn('img')}></span>
                                            </div>
                                            <div className="fit-dimensions">
                                                {
                                                    !didFitImgToWidth ? (
                                                        <span className="fit-to-width" data-tip='show' data-for='fit-file-to-dimensions' onClick={() => this.props.fitToWidth('img')}></span>
                                                    ) : (
                                                        <span className="fit-to-height" data-tip='show' data-for='fit-file-to-dimensions' onClick={() => this.props.fitToHeight('img')}></span>
                                                    )
                                                }

                                                <ReactTooltip id='fit-file-to-dimensions' className='default-tooltip info-tooltip' effect='solid' place='bottom' delayShow={1000}>
                                                    <p>
                                                        {
                                                            didFitToWidth ?
                                                                this.context.t('Fit the document size to the window height')
                                                                :
                                                                this.context.t('Fit the document size to the window width')
                                                        }
                                                    </p>
                                                </ReactTooltip>

                                                <span className="rotate-doc" data-tip='show' data-for='rotate-doc' onClick={() => this.props.rotateDoc('img')}></span>

                                                <ReactTooltip id='rotate-doc' className='default-tooltip info-tooltip' effect='solid' place='bottom' delayShow={1000}>
                                                    <p>
                                                        {this.context.t('Rotate the document 90 degrees clockwise')}
                                                    </p>
                                                </ReactTooltip>
                                            </div>
                                        </>
                                    )
                                ) : (
                                    null
                                )
                            }
                        </div>
                    </div>
                    <div className="end">
                        <div className="doc-controls">
                            {
                                pdfPreviewDataBody ? (
                                    <>
                                        {
                                            pdfPreviewData.deleted ? (
                                                <>
                                                    <div className={`restore`} onClick={() => this.props.restoreDocument(pdfPreviewData.id, pdfPreviewData.status, pdfPreviewData.name, pdfPreviewData.projectId)} data-tip='custom show' data-for='restore-tooltip'></div>
                                                    <ReactTooltip id='restore-tooltip' className='default-tooltip info-tooltip' effect='solid' place='right'>
                                                        <p>
                                                            {this.context.t('Restore document')}
                                                        </p>
                                                    </ReactTooltip>
                                                </>
                                            ) : (
                                                pdfPreviewData.docType !== 'EXPENSES_REPORT' ? (
                                                    <a className={`download`} href={pdfPreviewDataBody} download={pdfPreviewData.name}></a>
                                                ) : (
                                                    <div className={`download ${showDownloadOptions ? 'focused' : ''}`} onClick={(e) => this.toggleDownloadOptions(e)}>
                                                        {
                                                            showDownloadOptions ? (
                                                                <div className="download-tooltip">
                                                                    <ul>
                                                                        <li>
                                                                            <a href={pdfPreviewDataBody} download={pdfPreviewData.name}>{this.context.t('This document')}</a>
                                                                        </li>
                                                                        <li class="padding" onClick={e => this.props.downloadDocsInExpensesReport(e)}>
                                                                            {this.context.t('Documents included in report')}
                                                                        </li>

                                                                    </ul>
                                                                </div>
                                                            ) : (
                                                                null
                                                            )
                                                        }
                                                    </div>
                                                )
                                            )
                                        }

                                        {
                                            currentProject?.is_dropzone_manager ||                                      // jest managerem skrzynki
                                                pdfPreviewData.uploadedById == user.id ||                               // jest uploaderem
                                                pdfPreviewData.docflow?.advisor_id == user.id ||                        // jest opiekunem scieżki
                                                pdfPreviewData.currentDocflowStep?.users.includes(user.id)              // jest w obecnym kroku obiegu
                                                ? (
                                                    <div className={`delete`} onClick={() => this.props.openDeleteModal()} data-tip='custom show' data-for='delete-tooltip'></div>
                                                ) : null
                                        }


                                        <ReactTooltip id='delete-tooltip' className='default-tooltip info-tooltip' effect='solid' place='right'>
                                            <p>
                                                {
                                                    pdfPreviewData.currentDocflowStep ? (
                                                        this.context.t('Return document to inbox')
                                                    ) : (
                                                        pdfPreviewData.deleted ? (
                                                            this.context.t('Permanently delete the document')
                                                        ) : (
                                                            this.context.t('Move document to the trash can')
                                                        )
                                                    )
                                                }
                                            </p>
                                        </ReactTooltip>

                                        <div className={`more-options ${showMoreDocOptions ? 'focused' : ''}`} onClick={(e) => this.toggleMoreDocsOptions(e)}>
                                            {
                                                showMoreDocOptions ? (
                                                    <div className="more-options-list">
                                                        <ul>
                                                            <li className={`${showDocData ? 'selected' : ''}`} onClick={() => this.props.toggleDocData()}>
                                                                {this.context.t('Show information extracted from document')}
                                                            </li>
                                                            {
                                                                this.showLinkedDocsOnLoad() ? (
                                                                    <li className={`${showLinkedDoc ? 'selected' : ''}`} onClick={() => this.props.toggleLinkedDocs()}>
                                                                        {this.context.t('Show linked documents')}
                                                                    </li>
                                                                ) : (
                                                                    null
                                                                )
                                                            }

                                                            {/* {
                                                                this.hasOtherBindersInCompany() ? (
                                                                    <li onClick={() => { this.setState({ showMoreDocOptions: false }); this.props.showMoveToOtherBinderPopup() }}>
                                                                        {this.context.t('Move document to another binder')}
                                                                    </li>
                                                                ) : null
                                                            } */}
                                                        </ul>
                                                    </div>
                                                ) : (
                                                    null
                                                )
                                            }
                                        </div>



                                        <div className={`close`} onClick={() => this.props.closePreview()}></div>
                                    </>
                                ) : (
                                    null
                                )
                            }
                        </div>
                    </div>
                </div>
            )
        }

    }
}

PreviewToolbar.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    currentProject: state.User.currentProject,
    pdfPreviewData: state.File.pdfPreviewData,
    userProjects: state.User.userProjects,
    pdfPreviewData: state.File.pdfPreviewData,
    pdfPreviewDataBody: state.File.pdfPreviewDataBody,
    showSideBySidePreview: state.File.showSideBySidePreview,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewToolbar)