import React, { Component } from 'react'
import { DragSource, DropTarget } from 'react-dnd'
import PropTypes from 'prop-types'

import DragItem from './DragItem'


const targetSource = {
    drop(props, monitor, component) {
        return {
            name: props.name,
        }
    }
}

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        hovered: monitor.isOver(),
        item: monitor.getItem(),
        canDrop: monitor.canDrop(),
    }
}

export class DropTargetArea extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        const { connectDropTarget, hovered, containedItems, name } = this.props
        return connectDropTarget(
            <div className={`drop-target ${hovered ? 'target-hovered' : ''} ${name}`}>
                {
                    containedItems ? (
                        containedItems.map((item, index) => {
                            if (item.blocked) {
                                return (
                                    <div className={`drag-item blocked`} key={item.dataKey}>

                                        {
                                            name === 'selected' ? (
                                                <div className="column">
                                                    <div className="column-number">{index + 1}</div>
                                                    <div className="column-name selected">{this.context.t(item.title)}</div>
                                                    {
                                                        item.blocked ? (
                                                            <div className="blocked-icon"></div>
                                                        ) : null
                                                    }
                                                </div>
                                            ) : (
                                                <div className="column">
                                                    <div className="column-name available">{this.context.t(item.title)}</div>
                                                </div >
                                            )
                                        }
                                    </div >
                                )
                            } else {
                                return (
                                    <DragItem
                                        isInSelected={name === 'selected'}
                                        key={index}
                                        item={item}
                                        fields={this.props.companyWorkers}
                                        handleDrop={this.props.handleItemDrop}
                                        moveItem={this.props.moveItem}
                                        moveNewItem={this.props.moveNewItem}
                                        removeAllPseudoElements={this.props.removeAllPseudoElements}
                                        index={index}
                                        selectedFields={[]}
                                        listName={name}
                                    />
                                )
                            }

                        })
                    ) : (
                        null
                    )
                }
            </div>
        )
    }
}

DropTargetArea.contextTypes = {
    t: PropTypes.func
}

export default DropTarget('item', targetSource, collect)(DropTargetArea);