import React, { Component } from 'react'
import { connect } from 'react-redux'
import { UserActions } from '../actions/UserActions'
import { AlertActions } from '../actions/AlertActions'
import { Redirect } from 'react-router-dom'
import routes from '../helpers/routes'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga';


export class ChangeEasyGuard extends Component {
    constructor(props){
        super(props)
        this.state = {
        }
    }


    componentDidMount() {
        console.log('comp mounted')
        //ReactGA.pageview(window.location.pathname + window.location.search)
        var params = this.props.location.search
        params = params.substr(1)

        var paramsArray = params.split("&")
        var email, token;

        paramsArray.forEach(param => {
            var individualParam = param.split("=")
            if(individualParam[0] === "email") {
                email = individualParam[1]
            } else if(individualParam[0] === 'token') {
                token = individualParam[1]
            }
        })

        this.props.resetEasyGuardDevice(email, token, () => {
            
            if(this.props.user !== null) {
                this.props.history.push(routes.panel.dashboard)
            } else {
                this.props.history.push(routes.login)
            }
        })
    }

    render() {
        return(
            null
        )
    }
}

ChangeEasyGuard.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user
})

const mapDispatchToProps = {
    resetEasyGuardDevice: UserActions.resetEasyGuardDevice,
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeEasyGuard)

