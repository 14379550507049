import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ChatActions } from "../../actions/ChatAction.js";

import "../../css/Chat.css";


class ChatToogleButton extends Component {
  handleOpenChatToggle = () => {
    this.props.openChat(true)
  };

  render() {
    return (
      <button className="toogle-button" onClick={() => this.handleOpenChatToggle()} />
    )
  }
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
  openChat: ChatActions.openChat,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatToogleButton);
