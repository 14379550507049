import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'

import '../../css/OcrPage.css'

export class OcrPagePopups extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        const { showRefreshOcrPopup, showRefreshOcrPopupError, refreshOcrPopupUserName, showAcceptedOcrPopup, showAcceptedOcrPopupError, showDeleteOcrPopup, deleteOcrPopupUserName, showMovedOcrPopup, existingInvoice, similarInvoices } = this.props
        return (
            <>
                {
                    showRefreshOcrPopup ? (
                        <div className="invoice-exists-popup popup">
                            {
                                showRefreshOcrPopupError ? (
                                    <div className="popup-card">
                                        <div className="text error">
                                            {refreshOcrPopupUserName}{this.context.t(' has just submitted new OCR for this file but there was an error while downloading new informations. Please reload EasyDocs')}
                                        </div>
                                        <div className="action-buttons">
                                            <div className="btn refresh error" onClick={() => window.location.reload(false)}>{this.context.t('Reload EasyDocs')}</div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="popup-card">
                                        <div className="text">
                                            {refreshOcrPopupUserName}{this.context.t(' has just submitted new OCR for this file')}
                                        </div>
                                        <div className="action-buttons">
                                            <div className="btn refresh" onClick={e => this.props.handleRefreshOcrButton()}>{this.context.t('Load new informations')}</div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    ) : (
                        null
                    )
                }

                {
                    showAcceptedOcrPopup ? (
                        <div className="invoice-exists-popup popup">
                            {
                                showAcceptedOcrPopupError ? (
                                    <div className="popup-card">
                                        <div className="text error">
                                            {refreshOcrPopupUserName}{this.context.t(' has just accepted OCR for this file but there was an error while downloading new informations. Please reload EasyDocs')}
                                        </div>
                                        <div className="action-buttons">
                                            <div className="btn refresh error" onClick={() => window.location.reload(false)}>{this.context.t('Reload EasyDocs')}</div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="popup-card">
                                        <div className="text">
                                            {refreshOcrPopupUserName}{this.context.t(' has just accepted OCR for this file')}
                                        </div>
                                        <div className="action-buttons">
                                            <div className="btn refresh" onClick={e => this.props.handleGoToDashboardClick()}>{this.context.t('Go to Dashboard')}</div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    ) : (
                        null
                    )
                }

                {
                    showDeleteOcrPopup ? (
                        <div className="invoice-exists-popup popup">
                            <div className="popup-card">
                                <div className="text">
                                    {deleteOcrPopupUserName}{this.context.t(' has just daleted this file')}
                                </div>
                                <div className="action-buttons">
                                    <div className="btn refresh" onClick={e => this.props.handleGoToDashboardClick()}>{this.context.t('Go to Dashboard')}</div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        null
                    )
                }

                {
                    showMovedOcrPopup ? (
                        <div className="invoice-exists-popup popup">
                            <div className="popup-card">
                                <div className="text">
                                    {this.context.t(' has just moved this file to other binder')}
                                </div>
                                <div className="action-buttons">
                                    <div className="btn refresh" onClick={e => this.props.handleGoToDashboardClick()}>{this.context.t('Go to Dashboard')}</div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        null
                    )
                }

                {
                    existingInvoice || (similarInvoices.files && similarInvoices.files.length > 0) ? (
                        <div className="invoice-exists-popup popup">
                            <div className="popup-card">
                                <div className="text">
                                    {
                                        similarInvoices.files && similarInvoices.files.length > 0 ? (
                                            `${this.context.t('The system has detected a similar* document - check if it is a duplicate:')}`
                                        ) : (
                                            `${this.context.t('Document with that number already exists in binder')} ${existingInvoice.projectCode}`
                                        )
                                    }
                                </div>
                                {
                                    similarInvoices.files && similarInvoices.files.length === 0 ? (
                                        <div className="action-buttons">
                                            <div className="btn cancel" onClick={e => this.props.closeExistingFileModal()}>{this.context.t('Close')}</div>
                                            <div className="btn save" onClick={e => { this.props.getFileBody(parseInt(existingInvoice.projectId), existingInvoice.file, parseInt(existingInvoice.id), 'DONE', 3); this.props.closeExistingFileModal() }}>{this.context.t('Show')}</div>
                                        </div>
                                    ) : (
                                        <div className="similar-invoices">
                                            <div className="disclaimer">
                                                {this.context.t('*The system checks the similarity of documents by comparing: counterparty, dates, amount, etc')}
                                            </div>
                                            {
                                                similarInvoices.files.map(doc => {
                                                    return (
                                                        <div className="similar-doc-row">
                                                            <div className="name">{doc.name} </div>
                                                            <div className="show" onClick={e => { this.props.getFileBody(parseInt(doc.projectId), doc.path, parseInt(doc.file_id), 'DONE', 3) }}>{this.context.t('Show')}</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className="btn cancel" onClick={e => this.props.closeExistingFileModal()}>{this.context.t('Close')}</div>
                                            <div className="btn force-save" onClick={e => { this.props.closeExistingFileModal(); this.props.forceSave(similarInvoices.linkedFiles, true, true) }}>{this.context.t('Save anyway')}</div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    ) : (
                        null
                    )
                }
            </>
        )
    }
}

OcrPagePopups.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    existingInvoice: state.File.existingInvoice,
    similarInvoices: state.File.similarInvoices || { files: [], linkedFiles: {} },
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(OcrPagePopups)