import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import { Spinner } from 'react-activity'
import { List, AutoSizer, WindowScroller } from "react-virtualized";
import "react-virtualized/styles.css";

import dynamicComparer from '../../../helpers/dynamicComparer'
import { replaceSpecialCharacters } from '../../../helpers/textSlugify'
import { generateProjectCode } from '../../../helpers/generateProjectCode'

import { UserActions } from '../../../actions/UserActions'
import { AlertActions } from '../../../actions/AlertActions'
import { CompanyActions } from '../../../actions/CompanyActions'

export class ProjectsList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            projectsList: [],
            projectsSortField: "created",
            projectsSortOrder: "desc",
            showProjectEditPopup: false,
            originalProjectName: "",
            originalProjectCode: "",
            newProjectName: "",
            newProjectCode: "",
            editedProjectId: -1,
            editedProjectIndex: -1,
            gotAllEmailsThatSentFiles: false,
            waitingForEmails: false,
            emailsArray: [],
            showPasswordPopup: false,
            password: "",
            newProjectCodeIsUnique: true
        }
    }

    componentDidMount() {
        const { projects } = this.props
        this.setState({
            projectsList: projects.filter(p => this.checkIfCompanyMatchesFilters(p))
        }, () => {
            if (this.listRef) this.listRef.recomputeRowHeights()
        })
    }

    componentDidUpdate(prevProps) {
        ReactTooltip.rebuild()

        const { projects, searchField } = this.props
        if (projects !== prevProps.projects || (projects && prevProps.projects && projects.length !== prevProps.projects.length) || searchField !== prevProps.searchField) {
            this.setState({
                projectsList: projects.filter(p => this.checkIfCompanyMatchesFilters(p))
            }, () => {
                if (this.listRef) this.listRef.recomputeRowHeights()
            })
        }
    }

    checkIfCompanyMatchesFilters = p => {
        const { searchField } = this.props
        var matches = false
        var searchFieldCopy = searchField.toLowerCase()
        if (searchFieldCopy) {
            if (p.name.toLowerCase().includes(searchFieldCopy)) matches = true
            if (p.code.toLowerCase().includes(searchFieldCopy)) matches = true
            if (p.companyName.toLowerCase().includes(searchFieldCopy)) matches = true
            if (p.companyCode.toLowerCase().includes(searchFieldCopy)) matches = true

            return matches
        } else {
            return true
        }
    }

    getSortClass = name => {
        const { projectsSortField, projectsSortOrder } = this.state
        if (projectsSortField === name) {
            if (projectsSortOrder === "asc")
                return "icon-sort up"
            return "icon-sort down"
        }
        return "icon-sort"
    }


    switchSortingField = (name) => {
        var { projectsSortField, projectsSortOrder, projectsList } = this.state
        var newSortOrder = 'asc'
        if (projectsSortField === name) {
            if (projectsSortOrder === "asc") {
                newSortOrder = 'desc'
            }
        }
        projectsList.sort(dynamicComparer(name, newSortOrder, ['name', 'code', 'companyName', 'companyCode', 'totalSize'].includes(name)))

        this.setState({
            projectsSortField: name,
            projectsSortOrder: newSortOrder,
            projectsList: projectsList
        }, () => {
            if (this.listRef) this.listRef.recomputeRowHeights()
        })
    }

    formatFilesSize = (size) => {
        if (size) {
            var mbSize = size / 1024 / 1024

            if (mbSize > 1024) {
                return parseFloat(mbSize / 1024).toFixed(2) + ' [GB]'
            } else if (mbSize > 0.01) {
                return parseFloat(mbSize).toFixed(2) + ' [MB]'
            } else {
                var kbSize = mbSize * 1024
                return parseFloat(kbSize).toFixed(2) + ' [KB]'
            }
        } else {
            return '0 [MB]'
        }
    }

    setRef = windowScroller => {
        this._windowScroller = windowScroller;
    }

    setListRef = listRef => {
        this.listRef = listRef
    }


    getRowHeight = ({ index }) => {
        return 44
    }

    rowRenderer = ({ style, key, index, parent, width }) => {
        const { projectsList } = this.state

        var project = projectsList[index]

        return (
            <div
                key={key}
                style={style}
                role="row"
                className="ReactVirtualized__List-row"
            >
                <div className="ReactVirtualized__List-cell name">
                    <div className="edit-icon" onClick={() => this.showEditProjectPopup(project, index)}></div>
                    {project.name}
                </div>
                <div className="ReactVirtualized__List-cell code">
                    {project.code}
                </div>
                <div className="ReactVirtualized__List-cell name">
                    {project.companyName}
                </div>
                <div className="ReactVirtualized__List-cell code">
                    {project.companyCode}
                </div>
                <div className="ReactVirtualized__List-cell created">
                    {moment(project.created).format('YYYY-MM-DD HH:mm')}
                </div>
                {
                    project.totalCount ? (
                        <>
                            <div className="ReactVirtualized__List-cell files" data-for={`project-files-info-${project.id}`} data-tip='show'>
                                {project.totalCount}
                            </div>
                            <div className="ReactVirtualized__List-cell files" data-for={`project-files-info-${project.id}`} data-tip='show'>
                                {this.formatFilesSize(project.totalSize)}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="ReactVirtualized__List-cell files">{`0`}</div>
                            <div className="ReactVirtualized__List-cell files">{`0 [MB]`}</div>
                        </>
                    )
                }

                {
                    project.totalCount ? (
                        <ReactTooltip id={`project-files-info-${project.id}`} className="default-tooltip project-info" place="left" effect="solid">
                            <ul>
                                <li>
                                    <span className="property">{this.context.t('Imported docs count:')}</span>
                                    <span className="value">{project.docsCount}</span>
                                </li>
                                <li>
                                    <span className="property">{this.context.t('Docs size:')}</span>
                                    <span className="value">{this.formatFilesSize(project.docsSize)}</span>
                                </li>
                                <li>
                                    <span className="property">{this.context.t('Payment orders attachments count:')}</span>
                                    <span className="value">{project.paymentOrderFilesCount}</span>
                                </li>
                                <li>
                                    <span className="property">{this.context.t('Payment orders attachments size:')}</span>
                                    <span className="value">{this.formatFilesSize(project.paymentOrderFilesSize)}</span>
                                </li>
                                <li>
                                    <span className="property">{this.context.t('Reports count:')}</span>
                                    <span className="value">{project.reportsCount}</span>
                                </li>
                                <li>
                                    <span className="property">{this.context.t('Reports size:')}</span>
                                    <span className="value">{this.formatFilesSize(project.reportsSize)}</span>
                                </li>
                                <li>
                                    <span className="property">{this.context.t('Bank transfer files count:')}</span>
                                    <span className="value"> {project.bankTransfersCount} </span>
                                </li>
                                <li>
                                    <span className="property">{this.context.t('Bank transfer files size:')}</span>
                                    <span className="value">{this.formatFilesSize(project.bankTransfersSize)}</span>
                                </li>
                                <li>
                                    <span className="property">{this.context.t('Total:')}</span>
                                    <span className="value">{`${project.totalCount} / ${this.formatFilesSize(project.totalSize)}`}</span>
                                </li>
                            </ul>
                        </ReactTooltip>
                    ) : null
                }
            </div>
        )
    }

    showEditProjectPopup = (project, index) => {
        this.setState({
            showProjectEditPopup: true,
            originalProjectName: project.name,
            originalProjectCode: project.code,
            newProjectName: project.name,
            newProjectCode: project.code,
            editedProjectId: project.id,
            editedProjectIndex: index,
            gotAllEmailsThatSentFiles: false,
            waitingForEmails: false,
            emailsArray: [],
        })
    }

    hideEditProjectPopup = () => {
        this.setState({
            showProjectEditPopup: false,
            originalProjectName: "",
            originalProjectCode: "",
            newProjectName: "",
            newProjectCode: "",
            editedProjectId: -1,
            editedProjectIndex: -1,
            gotAllEmailsThatSentFiles: false,
            waitingForEmails: false,
            emailsArray: [],
        })
    }

    onInputChange = e => {
        e.preventDefault()
        const { name, value } = e.target

        this.setState({ [name]: value })

        if (name === 'newProjectCode') {
            const { originalProjectCode, editedProjectId } = this.state
            if (originalProjectCode !== value) {
                this.getEmailsThatSentFiles(editedProjectId)
            }
            if (value.length >= 3) this.props.chackeIfProjectCodeIsUnique(value, unique => {
                this.setState({
                    newProjectCodeIsUnique: unique
                })
            })
        }
    }

    generateNewBinderCode = () => {
        const { newProjectName, originalProjectCode, editedProjectId } = this.state
        var projectCodeCode = generateProjectCode(newProjectName)
        projectCodeCode = replaceSpecialCharacters(projectCodeCode)

        this.setState({
            newProjectCode: projectCodeCode
        })

        if (originalProjectCode !== projectCodeCode) {
            this.getEmailsThatSentFiles(editedProjectId)
            if (projectCodeCode.length >= 3) this.props.chackeIfProjectCodeIsUnique(projectCodeCode, unique => {
                this.setState({
                    newProjectCodeIsUnique: unique
                })
            })
        }
    }

    getEmailsThatSentFiles = projectId => {
        const { gotAllEmailsThatSentFiles } = this.state
        if (!gotAllEmailsThatSentFiles) {
            this.setState({
                waitingForEmails: true,
                emailsArray: []
            })
            this.props.getEmailsThatSentFiles(projectId, (success, emailsArray) => {
                console.log(emailsArray)
                if (success) {
                    this.setState({
                        gotAllEmailsThatSentFiles: true,
                        waitingForEmails: false,
                        emailsArray: emailsArray
                    })
                }
            })
        }
    }

    validateNewProjectInfo = () => {
        const { originalProjectName, originalProjectCode, newProjectName, newProjectCode, newProjectCodeIsUnique } = this.state
        var valid = true
        if (!newProjectName) {
            this.props.alertWarn(this.context.t("Invalid binder name"))
            valid = false
        }

        if (newProjectCode.length < 3) {
            this.props.alertWarn(this.context.t("Invalid binder short name"))
            valid = false
        } else if (!newProjectCodeIsUnique) {
            this.props.alertWarn(this.context.t("Binder short name must be unique"))
            valid = false
        }

        if (originalProjectName === newProjectName && originalProjectCode === newProjectCode) {
            this.props.alertWarn(this.context.t("You must change at least one value"))
            // Musisz zmienić przynajmniej jedną wartość
            valid = false
        }

        if (valid) {
            this.setState({
                showPasswordPopup: true,
                password: ""
            })
        }
    }

    changeProjectInfo = () => {
        const { originalProjectCode, newProjectName, newProjectCode, password, editedProjectId } = this.state
        this.props.renameProject(password, editedProjectId, newProjectName, newProjectCode, originalProjectCode !== newProjectCode, (success) => {
            if (success) {
                const { projectsList, editedProjectIndex } = this.state
                var projectsListCopy = [...projectsList]
                if (editedProjectIndex > -1 && projectsListCopy[editedProjectIndex]) {
                    projectsListCopy[editedProjectIndex].name = newProjectName
                    projectsListCopy[editedProjectIndex].code = newProjectCode
                }

                this.setState({
                    projectsList: projectsListCopy,
                    showProjectEditPopup: false,
                    originalProjectName: "",
                    originalProjectCode: "",
                    newProjectName: "",
                    newProjectCode: "",
                    editedProjectId: -1,
                    editedProjectIndex: -1,
                    gotAllEmailsThatSentFiles: false,
                    waitingForEmails: false,
                    emailsArray: [],
                    showPasswordPopup: false,
                    password: ""
                })
            }
        })
    }

    render() {
        const { projectsList, showProjectEditPopup, originalProjectName, originalProjectCode, newProjectName, newProjectCode, waitingForEmails, emailsArray, showPasswordPopup, password, newProjectCodeIsUnique } = this.state
        const { wrapperRef, user } = this.props
        return (
            <>
                {
                    showProjectEditPopup ? (
                        <div className={`popup ${showPasswordPopup ? 'show-password-overlay' : ''}`}>
                            <div className="card">
                                <div className="close-button" onClick={() => this.hideEditProjectPopup()}></div>
                                <div className="header">
                                    {this.context.t("Binder edition: {b}", { b: originalProjectName })}
                                </div>
                                <div className="body">
                                    <div className="form-group ">
                                        <input type="text" name="newProjectName" id="newProjectName" onChange={this.onInputChange} value={newProjectName} onBlur={() => this.generateNewBinderCode()} />
                                        <label htmlFor={`newProjectName`}>{this.context.t('Binder name')}</label>
                                    </div>
                                    <div className="form-group ">
                                        <input type="text" name="newProjectCode" id="newProjectCode" onChange={this.onInputChange} value={newProjectCode} />
                                        <label htmlFor={`newProjectCode`}>{this.context.t('Binder short name')}</label>
                                    </div>

                                    {
                                        !newProjectCodeIsUnique ? (
                                            <div className="not-unique">
                                                {this.context.t('This binder short name is already taken. Enter a different name.')}
                                            </div>
                                        ) : null
                                    }

                                    <div className="form-group read-only">
                                        <input type="text" name="newProjectCode" id="newProjectCode" value={`${newProjectCode}@app.easydocs.pl`} readOnly={true} />
                                        <label htmlFor={`newProjectCode`}>{this.context.t('Binder email')}</label>
                                    </div>

                                    {
                                        originalProjectCode !== newProjectCode ? (
                                            <div className={`new-email-info ${waitingForEmails ? 'loading' : ''}`}>
                                                <h5>{this.context.t("Binder's email address will be changed. Here is the list of counterparties who sent files to this binder:")}</h5>

                                                {
                                                    waitingForEmails ? (
                                                        <div className="activity-indicator">
                                                            <Spinner size={30} speed={0.8} color={"#444444"} />
                                                        </div>
                                                    ) : (
                                                        emailsArray.length ? (
                                                            <div className="email-list">
                                                                {
                                                                    emailsArray.map(e => {
                                                                        return <div className="email">{e}</div>
                                                                    })
                                                                }
                                                            </div>
                                                        ) : (
                                                            <div className="no-email">
                                                                {this.context.t("No one emailed files to this binder")}
                                                                {/* Nikt nie przesyłał plików mailem do tego segregatora */}
                                                            </div>
                                                        )
                                                    )
                                                }
                                            </div>
                                        ) : null
                                    }

                                    <button onClick={this.validateNewProjectInfo}>{this.context.t('Save')}</button>
                                </div>
                            </div>

                            {
                                showPasswordPopup ? (
                                    <div className="popup">
                                        <div className="card password">
                                            <div className="close-button" onClick={() => this.setState({ showPasswordPopup: false, password: "" })}></div>
                                            <div className="header">
                                                {this.context.t('Enter the password for your SUPERADMINA account to authorize editing binder')}
                                            </div>
                                            <div className="body">
                                                <div className="form-group ">
                                                    <input type="password" name="password" value={password} onChange={this.onInputChange} />
                                                    <label htmlFor={`password`}>{this.context.t('Password')}</label>
                                                </div>
                                                <button onClick={this.changeProjectInfo}>{this.context.t('Confirm')}</button>
                                            </div>
                                        </div>
                                    </div>
                                ) : null
                            }
                        </div>
                    ) : (
                        null
                    )
                }
                <div className="ReactVirtualized__List-wrapper">
                    <div className="ReactVirtualized__List-row header">
                        <div className="ReactVirtualized__List-cell name" onClick={() => this.switchSortingField('name', 'PROJECTS')}>
                            {this.context.t('Binder name')}
                            <span className={this.getSortClass('name', 'PROJECTS')}></span>
                        </div>
                        <div className="ReactVirtualized__List-cell code" onClick={() => this.switchSortingField('code', 'PROJECTS')}>
                            {this.context.t('Binder code')}
                            <span className={this.getSortClass('code', 'PROJECTS')}></span>
                        </div>
                        <div className="ReactVirtualized__List-cell name" onClick={() => this.switchSortingField('companyName', 'PROJECTS')}>
                            {this.context.t('Company name')}
                            <span className={this.getSortClass('companyName', 'PROJECTS')}></span>
                        </div>
                        <div className="ReactVirtualized__List-cell code" onClick={() => this.switchSortingField('companyCode', 'PROJECTS')}>
                            {this.context.t('Company code')}
                            <span className={this.getSortClass('companyCode', 'PROJECTS')}></span>
                        </div>
                        <div className="ReactVirtualized__List-cell created" onClick={() => this.switchSortingField('created', 'PROJECTS')}>
                            {this.context.t('Creation date')}
                            <span className={this.getSortClass('created', 'PROJECTS')}></span>
                        </div>
                        <div className="ReactVirtualized__List-cell files" onClick={() => this.switchSortingField('totalCount', 'PROJECTS')}>
                            {this.context.t('Files count')}
                            <span className={this.getSortClass('totalCount', 'PROJECTS')}></span>
                        </div>
                        <div className="ReactVirtualized__List-cell files" onClick={() => this.switchSortingField('totalSize', 'PROJECTS')}>
                            {this.context.t('Files size')}
                            <span className={this.getSortClass('totalSize', 'PROJECTS')}></span>
                        </div>
                    </div>
                    <WindowScroller
                        ref={this.setRef}
                        scrollElement={user.new_easydocs_version && wrapperRef && wrapperRef.current ? wrapperRef.current : window}
                    >
                        {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
                            <AutoSizer disableHeight>
                                {({ width }) => (
                                    <div ref={registerChild}>
                                        <List
                                            ref={this.setListRef}
                                            autoHeight
                                            width={width}
                                            height={height}
                                            rowHeight={this.getRowHeight}

                                            rowCount={projectsList.length}
                                            rowGetter={({ index }) => projectsList[index]}
                                            rowRenderer={({ style, key, index, parent }) => this.rowRenderer({ style, key, index, parent, width })}
                                            overscanRowCount={2}

                                            isScrolling={isScrolling}
                                            onScroll={(e) => { onChildScroll(e); ReactTooltip.rebuild() }}
                                            scrollTop={scrollTop}
                                        />
                                    </div>
                                )}
                            </AutoSizer>
                        )}
                    </WindowScroller>
                </div>
            </>
        )
    }
}

ProjectsList.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    projects: state.User.projects || [],
    projectsSortField: state.User.projectsSortField,
    projectsSortOrder: state.User.projectsSortOrder,
})

const mapDispatchToProps = {
    chackeIfProjectCodeIsUnique: CompanyActions.chackeIfProjectCodeIsUnique,
    getEmailsThatSentFiles: UserActions.getEmailsThatSentFiles,
    renameProject: UserActions.renameProject,
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsList)