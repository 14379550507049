import { include } from 'named-urls'


export default {
    home: "/",
    activateAccount: "/activate-account",
    appointment: "/appointment",
    passwordReset: "/reset-password",
    login: "/login",
    registerCompany: "/register",
    resetPassword: "/reset-password",
    createNewPassword: "/create-new-password",
    changeEasyGuardDevice: "/easyguard-reset",
    completeCompanyRegistration: "/complete-company-registration",
    googleAuth: "/google-auth",

    panel: include("/", {
        home: "",
        dashboard: "dashboard/",
        crop: "ocr/:projectId/:fileId",
        fileManager: "docs/",
        sale: "sales/",
        calendar: "calendar/",
        actions: "actions/",
        help: "helpdesk/",

        settings: include("settings/", {
            profile: "profile/",
            company: "company/",
            projects: "projects/",
            counterparties: "counterparties/",
            subscription: "subscription/"
        }),

        admin: include("admin/", {
            company: "company/",
            projects: "projects/",
            counterparties: "counterparties/",
            subscription: "subscription/"
        }),

        superAdmin: include("superadmin/", {
            management: "management/",
        })
    }),

    v2: include("/v2", {
        dashboard: "/dashboard/",
    }),
}