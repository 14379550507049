var API_URL;
if (process.env.REACT_APP_ENVIRONMENT === "local") {
  API_URL = "http://localhost:4000/api";
} else if (process.env.REACT_APP_ENVIRONMENT === "development") {
  API_URL = "https://test-api.easydocs.pl/api";
} else if (process.env.REACT_APP_ENVIRONMENT === "production") {
  API_URL = "https://app-api.easydocs.pl/api";
} else if (process.env.REACT_APP_ENVIRONMENT === "test") {
  API_URL = "https://test-api.easydocs.pl/api";
} else {
  API_URL = "http://localhost:4000/api";
}

export default function myFetch(url, requestOptions = {}, fileName) {
  return new Promise((resolve, reject) => {
    if (typeof requestOptions.body === "object") {
      requestOptions.body = JSON.stringify(requestOptions.body);
    }

    requestOptions.headers = {
      'X-Access-Token': localStorage.getItem('token'),
      "Content-Type": "application/json",
    }

    fetch(`${API_URL}${url}`, requestOptions)
      .then(res => {
        res.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
          resolve()
        });
      })
      .catch((error) => {
        reject({
          networkError: error.message,
        })
      });
  })
}