import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import 'react-activity/dist/react-activity.css'

import { CompanyActions } from '../../actions/CompanyActions'

import '../../css/PaymentBaner.css'

const COMPANIES_WITH_FV = [220, 697, 1377, 1378, 1379]

export class PaymentBaner extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        const { project, company, myCompanies, viewMode, user } = this.props
        console.log('PaymentBaner')
        console.log(company)
        console.log(company.payment_attempt_number > 0)
        if (company && myCompanies) {
            const roleInCompany = myCompanies.find(c => c.company_id === company.id) ? myCompanies.find(c => c.company_id === company.id).user_role : null

            if (roleInCompany === 'ADMIN' && company.payment_attempt_number > 0) {
                return (
                    <div className={`payment-baner ${viewMode === 'MULTI' ? 'multi-view' : 'single-view'} ${(localStorage.getItem('menuExtended') === 'true' || !localStorage.getItem('menuExtended')) ? 'menu-expended' : ''}`}>
                        {
                            company.payment_attempt_number === 3 ? (
                                <div className="text">{this.context.t('The last attempt to automatically collect payment was rejected. Pay the subscription manually to continue using EasyDocs')}</div>
                            ) : (
                                <div className="text">{this.context.t('An attempt to automatically collect payment has been rejected. The next attempt will be made on {d} at 23:59', { d: moment(company.subscription_expire_date).format('YYYY-MM-DD')})}</div>
                            )
                        }
                        <div className="pay" onClick={() => this.props.manualPaymentClick(company.id)} data-tip="show" data-for={`extend-sub-${company.id}-${project.id}`}>{this.context.t('Pay now')}</div>

                        <ReactTooltip id={`extend-sub-${company.id}-${project.id}`} place="bottom" effect="solid" className="default-tooltip extend-sub-info" delayHide={200}>
                            {this.context.t('Pay EasyDocs subscription for the company ')}{company.code}
                        </ReactTooltip>
                    </div>
                )
            } else {
                return null
            }
            // if (moment(company.subscription_expire_date).format('YYYY-MM-DD') < moment().add(3, 'days').format('YYYY-MM-DD') && roleInCompany === 'ADMIN' && !COMPANIES_WITH_FV.includes(company.id) && !company.has_paid_subscription) {
            //     return (
            //         <div className={`payment-baner ${viewMode === 'MULTI' ? 'multi-view' : 'single-view'}`}>
            //             <div className="text">{this.context.t('The license to use EasyDocs ends on ')}{moment(company.subscription_expire_date).format('YYYY-MM-DD')}</div>
            //             <div className="pay" onClick={() => this.props.renewCompany(company)} data-tip="show" data-for={`extend-sub-${company.id}-${project.id}`}>{this.context.t('Extend')}</div>

            //             <ReactTooltip id={`extend-sub-${company.id}-${project.id}`} place="bottom" effect="solid" className="default-tooltip extend-sub-info" delayHide={200}>
            //                 {this.context.t('Pay EasyDocs subscription for the company ')}{company.code}
            //             </ReactTooltip>
            //         </div>
            //     )
            // }
        } else {
            return null
        }
    }
}

PaymentBaner.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user
})

const mapDispatchToProps = {
    manualPaymentClick: CompanyActions.manualPaymentClick
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentBaner)