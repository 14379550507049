import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import arrayObjectIndexOf from "../../../helpers/indexOfObject"

import TransferPreview from '../../TransferPreview'

import { TransfersActions } from '../../../actions/TransfersActions'

export class FilesPreviewControlerForUnpaidDocs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            projectIndex: -1
        }
    }

    checkNext = () => {
        const {files, previewFile} = this.props

        var currentIndex = arrayObjectIndexOf(files, previewFile.id, 'id')

        if(currentIndex - 1 >= 0 && this.findNextDocMatchingFilterOptions(currentIndex - 1, -1) &&
            currentIndex + 1 < files.length && this.findNextDocMatchingFilterOptions(currentIndex + 1, 1)) {
            return {index: currentIndex, prev: true, next: true}
        } else if(currentIndex - 1 > 0 && this.findNextDocMatchingFilterOptions(currentIndex - 1, -1)) {
            return {index: currentIndex, prev: true, next: false}
        } else if(currentIndex + 1 < files.length && this.findNextDocMatchingFilterOptions(currentIndex  + 1, 1)) {
            return {index: currentIndex, prev: false, next: true}
        } else {
            return {index: currentIndex, prev: false, next: false}
        }
    }

    findNextDocMatchingFilterOptions = (startIndex, direction) => {
        const {files} = this.props

        var nextDoc = files[startIndex]
        var nextDocMatchesFilter = this.checkIfDocMatchesFilterOptions(nextDoc)

        while(!nextDocMatchesFilter) {
            startIndex += direction
            if(files[startIndex]) {
                nextDoc = files[startIndex]
                nextDocMatchesFilter = this.checkIfDocMatchesFilterOptions(nextDoc)
            } else break
        }

        console.log('nextDoc')
        console.log(nextDoc)
        if(nextDocMatchesFilter) return nextDoc
        return null

    }

    checkIfDocMatchesFilterOptions = file => {
        if (file && !file.payment_method) return true
    }

    goToDoc = (index, direction) => {
        var nextDoc = this.findNextDocMatchingFilterOptions(index, direction)

        if(nextDoc) {
            this.props.openTransferPreviewWindow(nextDoc)

            this.props.setCurrentDoc({
                id: nextDoc.id
            })
        }
    }

    render() {
        const {transferPreviewOpen} = this.props
        if(transferPreviewOpen) {
            return(
                <TransferPreview prevAndNext={this.checkNext()} goToDoc={this.goToDoc} close={this.props.close} customGoToDocControler={true}/>
            )
        } else {
            return null
        }
    }
}

FilesPreviewControlerForUnpaidDocs.contextTypes = {
	t: PropTypes.func
}

const mapStateToProps = (state) => ({
    transferPreviewOpen: state.Transfers.transferPreviewOpen,
})

const mapDispatchToProps = {
    openTransferPreviewWindow: TransfersActions.openTransferPreviewWindow,
}

export default connect(mapStateToProps, mapDispatchToProps)(FilesPreviewControlerForUnpaidDocs)