import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AutoSizer, List, WindowScroller } from "react-virtualized";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import FilesPreviewInReports from './FilesPreviewInReports';

import { AlertActions } from '../../actions/AlertActions';
import { CompanyActions } from '../../actions/CompanyActions';
import { FileActions } from '../../actions/FileActions';
import { ReportsActions } from '../../actions/ReportsActions';
import { TransfersActions } from '../../actions/TransfersActions';
import { UserActions } from '../../actions/UserActions';

import dynamicComparer from '../../helpers/dynamicComparer';
import myFetch from '../../helpers/fetch';

export class ReportsList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            filterString: '',
            showProjectsList: false,
            lastSearchEditTime: null,
            paidDocsFromFileToDelete: [],
            reportsList: [],
            reportsSortField: 'created',
            reportsSortOrder: 'desc',
            howIncludedDocsList: false,
            includedDocs: []
        }

        this.projectSelectorRef = React.createRef()
    }

    componentDidMount() {
        const { currentProject, previousGeneratedBankTransferFiles, previousGeneratedBankTransferFilesLoaded } = this.props

        if (currentProject?.id) this.props.getMyBankTransferFilesList(currentProject?.id)
        // if (!previousGeneratedBankTransferFilesLoaded) {
        //     this.props.getMyBankTransferFilesList(currentProject?.id || -1)
        // } else if (previousGeneratedBankTransferFilesLoaded && previousGeneratedBankTransferFiles && previousGeneratedBankTransferFiles.length) {
        //     if (previousGeneratedBankTransferFiles[0]?.projectId === currentProject?.id) {
        //         this.setState({
        //             reportsList: previousGeneratedBankTransferFiles.filter(r => this.checkIfMatchesFilter(r))
        //         })
        //     } else {
        //         this.props.getMyBankTransferFilesList(currentProject?.id || -1)
        //     }
        // }
        document.addEventListener("mousedown", this.handleClick)
    }

    componentDidUpdate(prevProps) {
            const { previousGeneratedBankTransferFiles, previousGeneratedBankTransferFilesLoaded } = this.props
                      
        if (!prevProps.previousGeneratedBankTransferFilesLoaded && previousGeneratedBankTransferFilesLoaded || prevProps.previousGeneratedBankTransferFiles.length != previousGeneratedBankTransferFiles.length) {
            this.setState({
                reportsList: previousGeneratedBankTransferFiles.filter(c => this.checkIfMatchesFilter(c))
            })
        }

        const { currentProject } = this.props
        if (currentProject?.id && currentProject?.id !== prevProps.currentProject?.id) {
            this.props.getMyBankTransferFilesList(currentProject?.id)
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick)
    }

    handleClick = (e) => {
        const { showProjectsList } = this.state
        if (showProjectsList && this.projectSelectorRef && this.projectSelectorRef.current && !this.projectSelectorRef.current.contains(e.target)) {
            this.setState({
                showProjectsList
            })
        }
    }

    inputChange = e => {
        e.preventDefault()

        const { name, value } = e.target

        this.setState({
            [name]: value,
            lastSearchEditTime: new Date()
        })

        setTimeout(() => {
            const { lastSearchEditTime, filterString } = this.state
            if (new Date() - lastSearchEditTime > 730) {
                const { previousGeneratedBankTransferFiles } = this.props
                this.setState({
                    reportsList: previousGeneratedBankTransferFiles.filter(c => this.checkIfMatchesFilter(c))
                })
            }
        }, 750)
    }

    getSortClassReports = (sortFieldCheck) => {
        const { reportsSortField, reportsSortOrder } = this.state

        if (reportsSortField === sortFieldCheck) {
            if (reportsSortOrder === "asc")
                return "icon-sort down"
            return "icon-sort up"
        }

        return "icon-sort"
    }

    switchSortingField = (name) => {
        var { reportsSortField, reportsSortOrder, reportsList } = this.state
        var newSortOrder = 'asc'
        if (reportsSortField === name) {
            if (reportsSortOrder === "asc") {
                newSortOrder = 'desc'
            }
        }
        reportsList.sort(dynamicComparer(name, newSortOrder))

        this.setState({
            reportsSortField: name,
            reportsSortOrder: newSortOrder,
            reportsList: reportsList
        })
    }

    formatPrice = price => {
        if (price || price === 0) {
            price = price.toFixed(2)
            if (price.indexOf('.') > -1) {
                var parts = price.split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                price = parts.join('.')
            } else {
                price = price.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }

            return price
        } else {
            return '--'
        }
    }

    checkIfMatchesFilter = file => {
        const { filterString } = this.state
        const lowerCaseFilterString = filterString.toLowerCase()

        if (filterString.length > 2) {
            if (file.projectName && file.projectName.toLowerCase().includes(lowerCaseFilterString)) {
                return true
            } else if (file.bankName && file.bankName.toLowerCase().includes(lowerCaseFilterString)) {
                return true
            } else if (file.createdBy && file.createdBy.toLowerCase().includes(lowerCaseFilterString)) {
                return true
            } else if (file.totalAmount && parseFloat(file.totalAmount).toFixed(2).includes(lowerCaseFilterString)) {
                return true
            } else if (file.docs?.length) {
                console.log('search in included docs')
                var includesDocMatchesFilter = false
                for (const d of file.docs) {
                    console.log(d)
                    if (d.counterpartyVatId?.includes(lowerCaseFilterString)) {
                        includesDocMatchesFilter = true
                        break;
                    }
                    if (d.counterpartyName?.toLowerCase().includes(lowerCaseFilterString)) {
                        includesDocMatchesFilter = true
                        break;
                    }
                    if (d.docNumber?.toLowerCase().includes(lowerCaseFilterString)) {
                        includesDocMatchesFilter = true
                        break;
                    }
                    if (d.comment?.toLowerCase().includes(lowerCaseFilterString)) {
                        includesDocMatchesFilter = true
                        break;
                    }
                }
                return includesDocMatchesFilter
            } else {
                return false
            }
        } else {
            return true
        }
    }

    deleteBankTransferFileClick = async (id, projectId, fileName) => {
        this.setState({
            cursourWait: true
        })

        try {
            const res = await myFetch(`/bank-transfers-files/check-docs-before-deleting/?bankTransferFileId=${id}&projectId=${projectId}`, {
                method: 'GET'
            })
            this.setState({
                cursourWait: false
            })

            if (res.success) {
                if (!this.props.transfers.find(e => e.project === projectId)) {
                    this.props.getTransfers(projectId, -1)
                }
                if (res.someDocsAreAlreadePaid) {
                    this.setState({
                        showPaidDocsInFileToDeletePopup: true,
                        fileIdToDelete: id,
                        fileNameToDelete: fileName,
                        projectIdOfFileToDelete: projectId,
                        paidDocsFromFileToDelete: res.paidDocs
                    })
                } else {
                    console.log('there are no paid docs')
                    this.setState({
                        showConfirmDeleteFilePopup: true,
                        fileIdToDelete: id,
                        fileNameToDelete: fileName,
                        projectIdOfFileToDelete: projectId,
                    })
                }
            } else {
                this.props.alertWarn(this.context.t(res.msg))
            }
        } catch (err) {
            this.setState({
                cursourWait: false
            })
            this.props.alertWarn(this.context.t("There was an internal server error while procesing request. Try again later."))
        }
    }

    toggleDocCheckbox = (e, index) => {
        if (!e.target.className.includes('name')) {
            var { paidDocsFromFileToDelete } = this.state
            paidDocsFromFileToDelete[index].selected = !paidDocsFromFileToDelete[index].selected

            this.setState({
                paidDocsFromFileToDelete: paidDocsFromFileToDelete
            })
        }
    }

    deleteBankTransferFileConfirm = () => {
        const { paidDocsFromFileToDelete } = this.state
        var docsToMarkUnpaid = []
        this.setState({
            cursourWait: true
        })
        if (paidDocsFromFileToDelete.length > 0) {
            docsToMarkUnpaid = paidDocsFromFileToDelete.map(d => { if (d.selected) return { docType: d.type, id: d.id } })
        }
        this.props.deleteBankTransferFile(this.state.fileIdToDelete, this.state.projectIdOfFileToDelete, docsToMarkUnpaid, (success) => {
            if (success) {
                this.setState({
                    showPaidDocsInFileToDeletePopup: false,
                    showConfirmDeleteFilePopup: false,
                    fileIdToDelete: -1,
                    fileNameToDelete: '',
                    projectIdOfFileToDelete: -1,
                    paidDocsFromFileToDelete: [],
                    cursourWait: false
                })
            }
        })
    }

    fileIconClick = (e, projectId, file, id, docType, fileId) => {
        e.preventDefault()
        this.props.toogleFilePreviewInActions(true)
        this.setState({
            previewFile: {
                projectId: projectId,
                file: file,
                id: id,
                docType: docType
            }
        })
        if (docType !== 'DOC') {
            const { transfers } = this.props
            var projectTransfers = transfers.find(e => e.project === projectId)
            if (projectTransfers && projectTransfers.transfers) {
                var transfer = projectTransfers.transfers.find(e => e.id === id)
                if (transfer) {
                    this.props.openTransferPreviewWindow(transfer)
                } else {
                    this.props.alertWarn(this.context.t('Payment order not found'))
                }
            } else {
                this.props.alertWarn(this.context.t('Payment order not found'))
            }
        } else {
            this.props.getFileBody(parseInt(projectId), file, fileId, 'DONE', 3)
        }
    }

    setCurrentDocPreview = (doc) => {
        this.setState({
            previewFile: doc
        })
    }

    hideFilePreview = () => {
        this.props.toogleFilePreviewInActions(false)
        this.setState({
            previewFile: null
        })
    }

    downloadBankTransferFileFilesClick = (fileId, projectId, fileName) => {
        this.setState({
            cursourWait: true
        })
        this.props.downloadBankTransferFileFiles(fileId, projectId, fileName, () => {
            this.setState({
                cursourWait: false
            })
        })
    }

    setRef = windowScroller => {
        this._windowScroller = windowScroller;
    }

    setListRef = listRef => {
        this.listRef = listRef
    }

    rowGetter = ({ index }) => {
        const { reportsList } = this.state

        return reportsList[index]
    }


    getRowHeight = ({ index }) => {
        const { reportsList } = this.state

        var c = reportsList[index]
        return 40
    }

    rowRenderer = ({ style, key, index, parent, width }) => {
        const { reportsList } = this.state

        var report = reportsList[index]

        return (
            <div
                key={key}
                style={style}
                role="row"
                className="ReactVirtualized__List-row"
            >
                <div className="ReactVirtualized__List-cell empty"></div>
                <div className="ReactVirtualized__List-cell name">
                    {report.projectName}
                </div>
                <div className="ReactVirtualized__List-cell user">
                    {report.createdBy}
                </div>
                <div className="ReactVirtualized__List-cell bank-name">
                    {report.bankName}
                </div>
                <div className="ReactVirtualized__List-cell date">
                    {moment(report.created).format('YYYY-MM-DD')}
                </div>
                <div className="ReactVirtualized__List-cell date">
                    {moment(report.dateFrom).format('YYYY-MM-DD')}
                </div>
                <div className="ReactVirtualized__List-cell date">
                    {moment(report.dateTo).format('YYYY-MM-DD')}
                </div>
                <div className="ReactVirtualized__List-cell count">
                    {report.transfersCount}
                </div>
                <div className="ReactVirtualized__List-cell price">
                    {this.formatPrice(parseFloat(report.totalAmount))}
                </div>
                <div className="ReactVirtualized__List-cell currency">
                    {report.currency}
                </div>
                <div className="ReactVirtualized__List-cell actions">
                    <span className="inspect" onClick={() => this.showDocsList(report.docs)}></span>
                    <span className="download-files" onClick={() => this.downloadBankTransferFileFilesClick(report.id, report.projectId, report.fileName)}></span>
                    <span className="delete" onClick={() => this.deleteBankTransferFileClick(report.id, report.projectId, report.fileName)}></span>
                </div>
            </div>
        )
    }

    showDocsList = docs => {
        console.log(docs)
        this.setState({
            showIncludedDocsList: true,
            includedDocs: docs || []
        })
    }

    hideDocsList = () => {
        this.setState({
            showIncludedDocsList: false,
            includedDocs: []
        })
    }

    includedDocClick = doc => {
        this.setState({
            previewFile: {
                projectId: doc.projectId,
                file: '',
                id: doc.fileId,
                docType: 'DOC'
            }
        })
    }

    toogleProjectsList = newValue => {
        this.setState({
            showProjectsList: newValue
        })
    }

    setCurrentProject = (id, companyId) => {
        this.props.getMyBankTransferFilesList(id)
        this.toogleProjectsList(false)
        this.props.switchDashboardViewModel(id)

        var { userCompanies, currentCompany } = this.props
        if (currentCompany && currentCompany.id !== companyId) {
            var companyIndex = userCompanies.findIndex(c => c.company_id === companyId)
            if (companyIndex > -1) {
                this.props.setCurrentCompany(userCompanies[companyIndex])
            }
        }
    }

    render() {
        const { currentProject, groupedProjects, previousGeneratedBankTransferFiles, previousGeneratedBankTransferFilesLoaded, location, wrapperRef, user } = this.props
        const {
            cursourWait,
            reportsList, filterString, showProjectsList,
            showPaidDocsInFileToDeletePopup, paidDocsFromFileToDelete, fileIdToDelete, fileNameToDelete, projectIdOfFileToDelete, showConfirmDeleteFilePopup,
            previewFile,
            showIncludedDocsList, includedDocs
        } = this.state
        return (
            <div className={`reports-list bank-transfers-list ${cursourWait ? 'loading' : ''}`}>

                {
                    showConfirmDeleteFilePopup ? (
                        <div className="popup">
                            <div className="popup-card">
                                <div className="header">{this.context.t('Are you sure you want to delete this bank transfer file?')}</div>
                                <div className="text">{this.context.t('After deleting this file, the documents that were included in it will be unlocked for generation of a transfer import file.')}</div>
                                <div className="download-button" onClick={() => this.downloadBankTransferFileFilesClick(fileIdToDelete, projectIdOfFileToDelete, fileNameToDelete)}>
                                    {this.context.t('Download all documents contained in this file for importing bank transfers')}
                                </div>
                                <div className="action-buttons">
                                    <div className="btn" onClick={e => this.setState({ showConfirmDeleteFilePopup: false })}>{this.context.t('Cancel')}</div>
                                    <div className="btn delete" onClick={e => this.deleteBankTransferFileConfirm()}>{this.context.t('Delete')}</div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        null
                    )
                }

                {
                    showPaidDocsInFileToDeletePopup ? (
                        <div className="popup">
                            <div className="popup-card">
                                <div className="header">{this.context.t('Are you sure you want to delete this bank transfer file?')}</div>
                                <div className="text">{this.context.t('After deleting this file, the documents that were included in it will be unlocked for generation of a transfer import file.')}</div>
                                <div className="download-button" onClick={() => this.downloadBankTransferFileFilesClick(fileIdToDelete, projectIdOfFileToDelete, fileNameToDelete)}>
                                    {this.context.t('Download all documents contained in this file for importing bank transfers')}
                                </div>
                                <div className="warning">{this.context.t('The following documents contained in this file have been marked as paid, after deleting this file selected documents will be unpaid again.')}</div>
                                <div className="documents">
                                    <SimpleBar style={{ maxHeight: '200px', width: '100%', minWidth: '100%' }}>
                                        {paidDocsFromFileToDelete.map((doc, i) => {
                                            return (
                                                <div className={`doc ${doc.selected ? 'checked' : ''}`} onClick={e => this.toggleDocCheckbox(e, i)}>
                                                    <span className="name" onClick={e => this.fileIconClick(e, doc.projectId, doc.file, doc.id, doc.type, doc.fileId)}>{doc.file || doc.orderName}</span>
                                                    <span className="sender">{doc.company}</span>
                                                    <span className="paid">{doc.price + " " + doc.currency + " (" + this.context.t(doc.paymentMethod) + ")"}</span>

                                                    <span className={`checkbox ${doc.selected ? 'checked' : ''}`}></span>
                                                </div>
                                            )
                                        })}
                                    </SimpleBar>
                                </div>
                                <div className="action-buttons">
                                    <div className="btn" onClick={e => this.setState({ showPaidDocsInFileToDeletePopup: false })}>{this.context.t('Cancel')}</div>
                                    <div className="btn delete" onClick={e => this.deleteBankTransferFileConfirm()}>{this.context.t('Delete')}</div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        null
                    )
                }

                {
                    previewFile ? (
                        <FilesPreviewInReports
                            docsList={paidDocsFromFileToDelete}
                            location={this.props.location}
                            previewFile={previewFile}
                            close={this.hideFilePreview}
                            setCurrentDoc={this.setCurrentDocPreview}
                            checkIfDocMatchesFilterOptions={() => { return true }}
                        />
                    ) : (
                        null
                    )
                }

                {
                    showIncludedDocsList ? (
                        <div className="popup">
                            <div className="popup-card included-docs">
                                <div className="header">{this.context.t('Included docs')}:</div>
                                <div className="included-docs-table">
                                    <table >
                                        <thead>
                                            <tr>
                                                <th className="counterparty">{this.context.t('Counterparty')}</th>
                                                <th className="doc-number">{this.context.t('Doc number')}</th>
                                                <th className="amount">{this.context.t('Amount to pay')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                includedDocs.map(doc => {
                                                    return (
                                                        <tr onClick={() => this.includedDocClick(doc)}>
                                                            <td className="counterparty">{doc.counterpartyName}</td>
                                                            <td className="doc-number">{doc.docNumber}</td>
                                                            <td className="amount">{`${doc.amountToPay} ${doc.currency}`}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table >
                                </div>
                                <div className="action-buttons">
                                    <div className="btn" onClick={e => this.hideDocsList()}>{this.context.t('Close')}</div>
                                </div>
                            </div>
                        </div>
                    ) : null
                }


                {
                    !previousGeneratedBankTransferFilesLoaded ? (
                        <div className="reports-loading">
                            <div className="image"></div>
                            <div className="text">
                                <div className="header">
                                    {this.context.t('Loading')}
                                </div>
                                <div className="instruction">
                                    {this.context.t('Please wait a second while we are retriving, loading and calculating your data')}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="reports-table">
                            <div className="filter-row">
                                <div className="projects-selector" ref={this.projectSelectorRef}>
                                    <div className="label" onClick={() => this.toogleProjectsList(!showProjectsList)}>{this.context.t('Binder')}</div>
                                    <div className="current" onClick={() => this.toogleProjectsList(!showProjectsList)}>{currentProject?.code}</div>
                                    <div className={`list-arrow ${showProjectsList ? 'extended' : ''}`} onClick={() => this.toogleProjectsList(!showProjectsList)}></div>
                                    {
                                        showProjectsList ? (
                                            <div className="projects-list">
                                                <SimpleBar style={{ maxHeight: '50vh', width: '100%' }}>
                                                    <ul>
                                                        {
                                                            groupedProjects.map(g => {
                                                                return (
                                                                    g?.projects?.map(p => {
                                                                        return (
                                                                            <li onClick={() => this.setCurrentProject(p.id, p.company_id)}>
                                                                                {p.code}
                                                                            </li>
                                                                        )
                                                                    })
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </SimpleBar>
                                            </div>
                                        ) : null
                                    }
                                </div>
                                <input type="text" name="filterString" value={filterString} onChange={e => this.inputChange(e)} placeholder={this.context.t('Search generated transfet baskets')} />
                            </div>
                            {
                                reportsList.length === 0 ? (
                                    <div className="no-reports-found">
                                        <div className="image"></div>
                                        <div className="text">
                                            <div className="header">
                                                {this.context.t('It looks like you don\'t have any reports')}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="ReactVirtualized__List-wrapper">
                                        <div className="ReactVirtualized__List-row header">
                                            <div className="ReactVirtualized__List-cell empty"></div>
                                            <div className="ReactVirtualized__List-cell name" onClick={() => this.switchSortingField('projectName')}>
                                                {this.context.t('Binder')}
                                                <span className={this.getSortClassReports('projectName')}></span>
                                            </div>

                                            <div className="ReactVirtualized__List-cell user" onClick={() => this.switchSortingField('createdBy')}>
                                                {this.context.t('Created by')}
                                                <span className={this.getSortClassReports('createdBy')}></span>
                                            </div>
                                            <div className="ReactVirtualized__List-cell bank-name" onClick={() => this.switchSortingField('bankName')}>
                                                {this.context.t('Bank name')}
                                                <span className={this.getSortClassReports('bankName')}></span>
                                            </div>
                                            <div className="ReactVirtualized__List-cell date" onClick={() => this.switchSortingField('created')}>
                                                {this.context.t('Creation date')}
                                                <span className={this.getSortClassReports('created')}></span>
                                            </div>
                                            <div className="ReactVirtualized__List-cell date" onClick={() => this.switchSortingField('dateFrom')}>
                                                {this.context.t('Date from')}
                                                <span className={this.getSortClassReports('dateFrom')}></span>
                                            </div>
                                            <div className="ReactVirtualized__List-cell date" onClick={() => this.switchSortingField('dateTo')}>
                                                {this.context.t('Date to')}
                                                <span className={this.getSortClassReports('dateTo')}></span>
                                            </div>
                                            <div className="ReactVirtualized__List-cell count" onClick={() => this.switchSortingField('transfersCount')}>
                                                {this.context.t('Documents count')}
                                                <span className={this.getSortClassReports('transfersCount')}></span>
                                            </div>
                                            <div className="ReactVirtualized__List-cell price" onClick={() => this.switchSortingField('totalAmount')}>
                                                {this.context.t('Total cost')}
                                                <span className={this.getSortClassReports('totalAmount')}></span>
                                            </div>
                                            <div className="ReactVirtualized__List-cell currency" onClick={() => this.switchSortingField('currency')}>
                                                {this.context.t('Currency')}
                                                <span className={this.getSortClassReports('currency')}></span>
                                            </div>
                                            <div className="ReactVirtualized__List-cell actions">
                                            </div>
                                        </div>
                                        <WindowScroller
                                            ref={this.setRef}
                                            scrollElement={user.new_easydocs_version && wrapperRef && wrapperRef.current ? wrapperRef.current : window}
                                        >
                                            {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
                                                <AutoSizer disableHeight>
                                                    {({ width }) => (
                                                        <div ref={registerChild}>
                                                            <List
                                                                ref={this.setListRef}
                                                                autoHeight
                                                                width={width}
                                                                height={height}
                                                                rowHeight={this.getRowHeight}

                                                                rowCount={reportsList.length}
                                                                rowGetter={this.rowGetter}
                                                                rowRenderer={({ style, key, index, parent }) => this.rowRenderer({ style, key, index, parent, width })}
                                                                overscanRowCount={2}

                                                                isScrolling={isScrolling}
                                                                scrollTop={scrollTop}
                                                            />
                                                        </div>
                                                    )}
                                                </AutoSizer>
                                            )}
                                        </WindowScroller>
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>
        )
    }
}

ReportsList.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    currentProject: state.User.currentProject,
    userProjects: state.User.userProjects,
    groupedProjects: state.User.groupedProjects,
    currentCompany: state.Company.currentCompany,
    userCompanies: state.Company.companies,
    previousGeneratedBankTransferFiles: state.Reports.previousGeneratedBankTransferFiles || [],
    previousGeneratedBankTransferFilesLoaded: state.Reports.previousGeneratedBankTransferFilesLoaded,
    banktransferFilesSortField: state.Reports.reportsSortField,
    banktransferFilesSortOrder: state.Reports.reportsSortOrder,
    transfers: state.Transfers.transfers,
})

const mapDispatchToProps = {
    getMyBankTransferFilesList: ReportsActions.getMyBankTransferFilesList,
    downloadBankTransferFileFiles: ReportsActions.downloadBankTransferFileFiles,
    switchBankTransferFilesFilterReports: ReportsActions.switchBankTransferFilesFilterReports,
    deleteBankTransferFile: ReportsActions.deleteBankTransferFile,

    toogleFilePreviewInActions: FileActions.toogleFilePreviewInActions,
    getFileBody: FileActions.getFileData,
    togglePdfPreview: FileActions.togglePdfPreview,
    openTransferPreviewWindow: TransfersActions.openTransferPreviewWindow,
    getTransfers: TransfersActions.getAllMyTransfers,

    switchDashboardViewModel: UserActions.switchDashboardViewModel,
    setCurrentCompany: CompanyActions.setCurrentCompany,

    alertWarn: AlertActions.warning,
    alertDanger: AlertActions.danger,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsList)