import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

import { CompanyActions } from "../../actions/CompanyActions";

import "../../css/SalesPage.css";

export class DeleteRecurringInvoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cursorWait: false
        };
    }

    deleteInvoice = () => {
        const { invoiceToDelete, currentCompany } = this.props
        this.setState({
            cursorWait: true
        })
        this.props.deleteRecurringInvoice(currentCompany.company_id, invoiceToDelete.id, success => {
            this.setState({
                cursorWait: false
            })

            if (success) {
                this.props.deletedRecurringInvoice(invoiceToDelete.id)
                this.props.closeDeleteRecurringInvoice()
            }
        })
    }

    renderInvoiceItems = invoice => {
        if (invoice && invoice.items) {
            return (
                invoice.items.map(item => {
                    if (item.docItem) {
                        return (
                            <p>{item.docItem.name}</p>
                        )
                    } else {
                        return null
                    }
                })
            )

        } else {
            return null
        }
    }

    render() {
        const { cursorWait } = this.state
        const { invoiceToDelete } = this.props
        return (
            <div className={`delete-invoice-popup ${cursorWait ? 'cursor-wait' : ''}`}>
                <div className="delete-invoice-window">
                    <h3>{this.context.t("Do you confirm removal of this recurring invoice")}:</h3>
                    <div className="recurring-invoice header">
                        <div className="name">{this.context.t('Counterparty')}</div>
                        <div className="numbering-series">{this.context.t('Numbering series')}</div>
                        <div className="date">{this.context.t('Issue date of the next invoice')}</div>
                        <div className="date">{this.context.t('Sale date of the next invoice')}</div>
                        <div className="items">{this.context.t('Products/Services')}</div>
                    </div>
                    <div className="recurring-invoice">
                        <div className="name">{invoiceToDelete.counterparty ? invoiceToDelete.counterparty.name : ''}</div>
                        <div className="date">{invoiceToDelete.numberingSeries ? invoiceToDelete.numberingSeries.name : ''}</div>
                        <div className="date">{moment(invoiceToDelete.next_issue_date).format('YYYY-MM-DD')}</div>
                        <div className="date">{moment(invoiceToDelete.next_sale_date).format('YYYY-MM-DD')}</div>
                        <div className="items">{this.renderInvoiceItems(invoiceToDelete)}</div>
                    </div>
                    <div className="action-buttons">
                        <div className="cancel-button" onClick={() => this.props.closeDeleteRecurringInvoice()}>{this.context.t("Cancel")}</div>
                        <div className="delete-button" onClick={() => this.deleteInvoice()}>{this.context.t("Delete")}</div>
                    </div>
                </div>
            </div>
        )
    }
}

DeleteRecurringInvoice.contextTypes = {
    t: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
    currentCompany: state.Company.currentCompany,
});

const mapDispatchToProps = {
    deleteRecurringInvoice: CompanyActions.deleteRecurringInvoice,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteRecurringInvoice);
