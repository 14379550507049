import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { UserActions } from '../actions/UserActions'
import PropTypes from 'prop-types'
import WhatsNewSection from './WhatsNewSection'
import myFetch from '../helpers/fetch'

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import '../css/WhatsNew.css'

const WhatsNewPopup = ({ whatsNew }, { t }) => {
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch();

    const closeModal = async () => {
        if (!loading) {
            setLoading(true)
            try {
                myFetch('/whats-new/read', {
                    method: 'POST',
                    body: JSON.stringify({
                        version: whatsNew.id
                    })
                })

                dispatch(UserActions.setWhatsNewVersion(whatsNew.id))
            } catch (err) {
                console.error(err)
                dispatch(UserActions.setWhatsNewVersion(whatsNew.id))
            }
        }
    }

    useEffect(() => {
        document.body.style.overflow = 'hidden'

        return () => {
            document.body.style.overflow = ''
        }
    }, [])

    return (
        <div className="whats-new__container">
            <div className="whats-new">
                <div className="whats-new__content">
                    <div className="whats-new__header">
                        <h4 className="whats-new__title">{t("What's new?")}</h4>
                        <p className="whats-new__subtitle">{t("We present the latest changes to EasyDocs")}</p>
                    </div>
                    <div className="whats-new__wrapper">
                        <SimpleBar style={{ maxHeight: '60vh', width: '100%'}}>
                            {whatsNew.web && whatsNew.web.length > 0 &&
                                <WhatsNewSection name={t("EasyDocs Web Application")} list={whatsNew.web} />
                            }
                            {whatsNew.mobile && whatsNew.mobile.length > 0 &&
                                <WhatsNewSection name={t("EasyDocs Mobile Application")} list={whatsNew.mobile} />
                            }
                            {whatsNew.desktop && whatsNew.desktop.length > 0 &&
                                <WhatsNewSection name={t("EasyDocs Desktop Client")} list={whatsNew.desktop} />
                            }
                        </SimpleBar>
                    </div>
                    <button onClick={closeModal} className="whats-new__button">{t('Close')}</button>
                </div>
            </div>
        </div>
    );
}

WhatsNewPopup.contextTypes = {
    t: PropTypes.func
}

export default WhatsNewPopup;