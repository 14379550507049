import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { FileActions } from '../../actions/FileActions';
import { AlertActions } from '../../actions/AlertActions';

import MpkListSelector from '../OcrPageComponents/MpkSplit/MpkListSelector'

const { waProjects, waMpkSettings } = require('../../helpers/MpkSplit/wineAvenue')
const { aventineProjects, aventineMpkSettings } = require('../../helpers/MpkSplit/aventine')
const { notelProjects, notelMpkSettings } = require('../../helpers/MpkSplit/notel')
const { profmProjects, profmMpkSettings } = require('../../helpers/MpkSplit/profm')
const { bajkaPanaKleksaProjects, bajkaPanaKleksaMpkSettings } = require('../../helpers/MpkSplit/bajkaPanaKleksa')
const { impressProjects, impressMpkSettings } = require('../../helpers/MpkSplit/impressGroup')


export class MpkSplit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showList: false,
            levelsCount: 0,
            hasDependentSublevels: false,
            level1List: [],
            level2List: [],
            level3List: [],
            level4List: [],
        }

        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        const { projectId } = this.props
        var mpkSettings = {}
        if (waProjects.includes(projectId)) {
            mpkSettings = waMpkSettings
        } else if (aventineProjects.includes(projectId)) {
            mpkSettings = aventineMpkSettings
        } else if (notelProjects.includes(projectId)) {
            mpkSettings = notelMpkSettings
        } else if (profmProjects.includes(projectId)) {
            mpkSettings = profmMpkSettings
        } else if (bajkaPanaKleksaProjects.includes(projectId)) {
            mpkSettings = bajkaPanaKleksaMpkSettings
        } else if (impressProjects.includes(projectId)) {
            mpkSettings = impressMpkSettings
        }

        this.setState({
            ...mpkSettings
        })

        document.addEventListener("mousedown", this.handleClick);
        window.addEventListener("keydown", this.escFunction, false)
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
        window.removeEventListener("keydown", this.escFunction, false)
    }

    componentDidUpdate(prevProps) {
        const { totalNetAmount } = this.props
        var mpkSplit = [...this.props.mpkSplit]

        if (totalNetAmount !== prevProps.totalNetAmount && mpkSplit?.length === 1) {
            mpkSplit[0].netAmount = totalNetAmount
            this.props.setMpkSplit(mpkSplit)
        }
    }

    componentWillUnmount() {
    }

    onNetChange = (e, type, index) => {
        var mpkSplit = [...this.props.mpkSplit]
        var { value } = e.target
        value = value.replace(/[^0-9,.-]/gm, '').replace(',', '.')
        mpkSplit[index].netAmount = value
        this.props.setMpkSplit(mpkSplit)
    }

    selectMpk = (index, level, value) => {
        const { hasDependentSublevels } = this.state
        var mpkSplit = [...this.props.mpkSplit]
        var mpkDescriptionField = `mpkLevel${level}`
        var mpkCodeField = `mpkCodeLevel${level}`
        if (mpkSplit[index][mpkCodeField] !== value.code) {
            mpkSplit[index][mpkDescriptionField] = value.description
            mpkSplit[index][mpkCodeField] = value.code
        } else {
            mpkSplit[index][mpkDescriptionField] = ''
            mpkSplit[index][mpkCodeField] = ''
        }

        if (hasDependentSublevels) {
            if (mpkSplit[index][`mpkCodeLevel${level + 1}`]) {
                mpkSplit[index][`mpkLevel${level + 1}`] = ''
                mpkSplit[index][`mpkCodeLevel${level + 1}`] = ''
            }
            this.setState({
                [`level${level + 1}List`]: value.nextLevel || []
            })

            if (mpkSplit[index][`mpkCodeLevel${level + 2}`]) {
                mpkSplit[index][`mpkLevel${level + 2}`] = ''
                mpkSplit[index][`mpkCodeLevel${level + 2}`] = ''
                this.setState({
                    [`level${level + 2}List`]: []
                })
            }

            if (mpkSplit[index][`mpkCodeLevel${level + 3}`]) {
                mpkSplit[index][`mpkLevel${level + 3}`] = ''
                mpkSplit[index][`mpkCodeLevel${level + 3}`] = ''
                this.setState({
                    [`level${level + 3}List`]: []
                })
            }
        }

        this.props.setMpkSplit(mpkSplit)
    }

    addMpkSplit = () => {
        var mpkSplit = [...this.props.mpkSplit]
        mpkSplit.push({
            id: -1,
            netAmount: '',
            mpkLevel1: '',
            mpkCodeLevel1: '',
            mpkLevel2: '',
            mpkCodeLevel2: '',
            mpkLevel3: '',
            mpkCodeLevel3: '',
            mpkLevel4: '',
            mpkCodeLevel4: '',
            waproNetAccount: ''
        })
        var newSplitIndex = mpkSplit.length - 1

        this.props.setMpkSplit(mpkSplit)
    }

    deleteCostSplit = (index) => {
        var mpkSplit = [...this.props.mpkSplit]
        mpkSplit.splice(index, 1)

        this.props.setMpkSplit(mpkSplit)
    }


    renderMpkLevelsList = (mpk, index) => {
        const { levelsCount } = this.state
        const { readOnly } = this.props
        var elementsToReturn = []

        for (let i = 1; i <= levelsCount; i++) {
            const levelList = this.state[`level${i}List`]
            if (levelList) {
                elementsToReturn.push(
                    <MpkListSelector
                        key={`mpk-level-${i}`}
                        mpkLevel={i}
                        list={levelList}
                        selectedOption={mpk[`mpkLevel${i}`]}
                        selectOption={value => this.selectMpk(index, i, value)}
                        readOnly={readOnly}
                    />
                )
            }
        }

        return elementsToReturn
    }

    render() {
        const {
        } = this.state
        const {
            customTitle,
            mpkSplit,
        } = this.props

        return (
            <div className={`mpk-split-container`}>
                <div className="cost-split-header">
                    <h4>{this.context.t(customTitle || 'Split payment into controlling dimensions')}:</h4>
                    <div className="add-allocation" onClick={() => this.addMpkSplit()}><span>+</span>{this.context.t('Add controlling dimension')}</div>
                </div>
                {
                    (mpkSplit || []).map((mpk, index) => {
                        return (
                            <div key={mpk.id > 0 ? `mpk-split-${mpk.id}` : `mpk-split-${index}`} className="cost-split">

                                <div className={`form-group amount`}>
                                    <label>{this.context.t('Net amount')}</label>
                                    <input
                                        type="text"
                                        value={mpk.netAmount}
                                        name={`net-amount-${index}`}
                                        onChange={e => this.onNetChange(e, 'NET', index)}
                                    />
                                </div>

                                {this.renderMpkLevelsList(mpk, index)}

                                {
                                    mpkSplit.length > 1 ? (
                                        <div className="delete-cost-split" onClick={() => this.deleteCostSplit(index)}><span>-</span>{this.context.t('Delete controling dimension')}</div>
                                    ) : null
                                }
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

MpkSplit.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
    alertWarn: AlertActions.warning,
    addMpkSplitInstance: FileActions.addMpkSplitInstance,
    addFirstMpkSplitInstance: FileActions.addFirstMpkSplitInstance,
    deleteMpkSplitInstance: FileActions.deleteMpkSplitInstance,
    editMpkSplitInstance: FileActions.editMpkSplitInstance
}

export default connect(mapStateToProps, mapDispatchToProps)(MpkSplit)