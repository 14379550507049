import React, { Component } from 'react'
import { DragSource } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend';
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

const itemSource = {
    beginDrag(props) {
        let dragFields;
        if (props.selectedFields.find(field => field === props.item)) {
            dragFields = props.selectedFields;
        } else {
            dragFields = [...props.selectedFields, props.item];
        }
        return { fields: dragFields, source: props.selectedSource };
    },

    endDrag(props, monitor) {
        // When dropped on a compatible target, do something
        const item = monitor.getItem();
        const dropResult = monitor.getDropResult();
        if (dropResult) {
            props.handleDrop(item.fields, monitor.getDropResult().xId, monitor.getDropResult().yId)
            props.clearItemSelection();
        }
    },
}

function collect(connect, monitor) {
    return {
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging(),
    }
}

export class DropItem extends Component {

    componentDidMount() {
        this.props.connectDragPreview(getEmptyImage(), {
          captureDraggingState: true,
        });
    }

    handleRowSelection = (cmdKey, shiftKey, index) => {
        console.log(cmdKey)
        this.props.handleSelection(index, cmdKey, shiftKey);
    }

    render() {
        const selected = this.props.selectedFields.find(field => this.props.item === field);
        const { isDragging, connectDragSource, item} = this.props;

        console.log('DRAG ITEM')
        console.log(item)
        return connectDragSource(
            <div className={`drag-item ${selected ? 'is-selected' : ''} ${isDragging ? 'is-dragging' : ''}`}>
                <div className={`profile-pic ${item.profile_pic_link ? '' : 'default'}`}>
                    {
                        item.profile_pic_link ? (
                            <img src={item.profile_pic_link} alt="Profile picture"/>
                        ) : (
                            null
                        )
                    }
                </div>
                <div className="user-info">
                    {
                        item.first_name && item.last_name ? (
                            <>
                                <div className='name-and-surname'>{`${item.first_name} ${item.last_name}`}</div>
                                <div className='email'>{`${item.email}`}</div>
                            </>
                        ) : (
                            <div className='name-and-surname'>{`${item.email}`}</div>
                        )
                    }
                </div>

                {
                    item.is_service ? (
                        <>
                            <div className="service-badge" data-for={`service-info-${item.id}`} data-tip='show'>
                                {this.context.t('SERVICE')}
                            </div>

                            <ReactTooltip id={`service-info-${item.id}`} className="default-tooltip service-tooltip" place="left" effect='solid'>
                                <p>{this.context.t('Users marked as "Service" are representatives of EasyDocs and are not taken into account when billing the subscription')}</p>
                            </ReactTooltip>
                        </>
                    ) : (
                        null
                    )
                }
            </div>
        )
    }
}

DropItem.contextTypes = {
    t: PropTypes.func
}

export default  DragSource('item', itemSource, collect)(DropItem);