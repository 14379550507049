import React, { Component } from 'react'
import { DragLayer } from 'react-dnd';
import DragPreview from './DragPreview';


const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
};

const getFieldStyle = () => {
    const style = {
        width: 500,
        maxWidth: 500,
    };
    return style;
};

const getItemStyles = (props) => {
    const { currentOffset } = props;
    if (!currentOffset) {
        return {
            display: 'none',
        };
    }

    const { x, y } = currentOffset;

    const transform = `translate(${x}px, ${y}px)`;
    return {
        transform,
        WebkitTransform: transform,
    };
};

const collect = (monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
});

export class ItemDragLayer extends Component {
    render() {
        const { item, isDragging, translate} = this.props;

        if (!isDragging) {
            return null;
        }

        return (
            <div style={layerStyles}>
                <div style={getItemStyles(this.props)}>
                    <div style={getFieldStyle()}>
                        <DragPreview item={item ? item.item : null} translate={translate}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default DragLayer(collect)(ItemDragLayer);