import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize'

import '../../css/OcrPage.css'

export class OcrPageTextPreview extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    getTitle = propName => {
        var title = "Read"
        if (propName === "invoiceNumber") title += " document number:"
        else if (propName === "docNumber") title += " document number:"
        else if (propName === "sellerVatId") title += " seller VatID:"
        else if (propName === "sellerName") title += " seller name:"
        else if (propName === "buyerName") title += " buyer name:"
        else if (propName === "buyerVatId") title += " buyer VatID:"
        else if (propName === "issueDate") title += " issue date:"
        else if (propName === "dueDate") title += " due date:"
        else if (propName === "netto") title += " net value:"
        else if (propName === "brutto") title += " gross value:"
        else if (propName === "tax") title += " tax value:"
        else if (propName === "price") title += " amount to pay:"
        else if (propName === "bankData" || propName === "accountNumber") title += " account number:"
        else if (propName === "swift") title += " SWIFT code:"
        else if (propName === "sellerCode") title += " seller code:"
        else if (propName === "countryCode") title += " country code:"
        else if (propName === "counterpartyVatId") title += " counterparty VAT-ID:"
        else if (propName === "counterpartyName") title += " counterparty name:"
        else if (propName === "counterpartyCode") title += " counterparty code:"
        else if (propName === "date") title += " date:"
        else if (propName === "subject") title += " subject:"
        else if (propName === "from") title += " sender:"
        else title += " text:"

        return title
    }

    render() {
        const { possition, propName, text, expenseIndex, ocrMethod, docType, fileId, user } = this.props

        console.log(possition)
        console.log(text)
        if (possition && text) {
            if (docType === 'AGREEMENT') {
                return (
                    <div className="ocr-text-preview" style={{ top: possition.top, left: possition.left }}>
                        <label>
                            {this.context.t(this.getTitle(propName))}
                        </label>
                        <TextareaAutosize
                            className={propName}
                            name={propName}
                            value={text}
                            onChange={(e) => this.props.onInputChangeAgreement(e, fileId, propName)}
                        // onFocus={ (e) => this.props.scrollToArea(e, propName, null)}
                        // onBlur={ e => this.props.focusOutArea(e, fileId)}
                        />
                    </div>
                )
            } else if (docType === 'EXPENSES') {
                return (
                    <div className="ocr-text-preview" style={{ top: possition.top, left: possition.left }}>
                        <label>
                            {this.context.t(this.getTitle(propName))}
                        </label>
                        <TextareaAutosize
                            className={propName}
                            name={propName}
                            value={text}
                            onChange={(e) => this.props.onInputChangeExpense(e, expenseIndex)}
                        // onFocus={ (e) => this.props.scrollToAreaForExpenses(e, propName, null)}
                        // onBlur={ e => this.props.focusOutAreaForExpenses(e, fileId)}
                        />
                    </div>
                )
            } else if (docType === 'INCOME') {
                return (
                    <div className="ocr-text-preview" style={{ top: possition.top, left: possition.left }}>
                        <label>
                            {this.context.t(this.getTitle(propName))}
                        </label>
                        <TextareaAutosize
                            className={propName}
                            name={propName}
                            value={text}
                            onChange={(e) => this.props.onInputChangeIncome(e, fileId, propName)}
                        // onFocus={ (e) => this.props.scrollToArea(e, propName, null)}
                        // onBlur={ e => this.props.focusOutArea(e, fileId)}
                        />
                    </div>
                )
            } else if (docType === 'CORPORATE') {
                return (
                    <div className="ocr-text-preview" style={{ top: possition.top, left: possition.left }}>
                        <label>
                            {this.context.t(this.getTitle(propName))}
                        </label>
                        <TextareaAutosize
                            className={propName}
                            name={propName}
                            value={text}
                            onChange={(e) => this.props.onInputChangeCorporate(e, fileId, propName)}
                        // onFocus={ (e) => this.props.scrollToArea(e, propName, null)}
                        // onBlur={ e => this.props.focusOutArea(e, fileId)}
                        />
                    </div>
                )
            } else if (docType === 'OTHER') {
                return (
                    <div className="ocr-text-preview" style={{ top: possition.top, left: possition.left }}>
                        <label>
                            {this.context.t(this.getTitle(propName))}
                        </label>
                        <TextareaAutosize
                            className={propName}
                            name={propName}
                            value={text}
                            onChange={(e) => this.props.onInputChangeOther(e, fileId, propName)}
                        // onFocus={ (e) => this.props.scrollToArea(e, propName, null)}
                        // onBlur={ e => this.props.focusOutArea(e, fileId)}
                        />
                    </div>
                )
            } else if (docType === 'CORRESPONDENCE') {
                return (
                    <div className="ocr-text-preview" style={{ top: possition.top, left: possition.left }}>
                        <label>
                            {this.context.t(this.getTitle(propName))}
                        </label>
                        <TextareaAutosize
                            className={propName}
                            name={propName}
                            value={text}
                            onChange={(e) => this.props.onInputChangeCorrespondence(e, fileId, propName)}
                        // onFocus={ (e) => this.props.scrollToArea(e, propName, null)}
                        // onBlur={ e => this.props.focusOutArea(e, fileId)}
                        />
                    </div>
                )
            } else {
                return (
                    <div className="ocr-text-preview" style={{ top: possition.top, left: possition.left }}>
                        <label>
                            {this.context.t(this.getTitle(propName))}
                        </label>
                        {
                            ocrMethod === 'select' ? (
                                <TextareaAutosize
                                    className={propName}
                                    name={propName}
                                    value={text}
                                    onChange={(e) => this.props.onInputChange(e, fileId, propName)}
                                // onFocus={ (e) => this.props.scrollToArea(e, propName, null)}
                                // onBlur={ e => this.props.focusOutArea(e, fileId)}
                                />
                            ) : (
                                <TextareaAutosize
                                    className={propName}
                                    name={propName}
                                    value={text}
                                    onChange={(e) => this.props.onInputChangeByHand(e, fileId, propName)}
                                // onFocus={ (e) => this.props.scrollToArea(e, propName, null)}
                                // onBlur={ e => this.props.focusOutArea(e, fileId)}
                                />
                            )
                        }
                    </div>
                )
            }
        } else return null
    }
}

OcrPageTextPreview.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(OcrPageTextPreview)