import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import TextareaAutosize from 'react-textarea-autosize'

import '../../../css/OcrPage.css'
import { CompanyActions } from '../../../actions/CompanyActions';

export class OcrPageWaproNettoAccounts extends Component {
	constructor(props) {
		super(props)
		this.state = {
			list: [],
			sendRequest: false,
			filter: '',
			showList: false
		}

		this.wrapperRef = React.createRef();
	}

	componentDidMount() {
		if (!this.state.sendRequest) {
			this.setState({
				sendRequest: true
			})
			console.log('fetching net accounts')
			console.log(this.props.companyId)
			this.props.getCompanyWaproNetAccounts(this.props.companyId, (success, accounts) => {
				if (success) {
					this.setState({
						list: accounts
					})
				}
			})
		}

		document.addEventListener("mousedown", this.handleClick);
		window.addEventListener("keydown", this.escFunction, false)
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClick);
		window.removeEventListener("keydown", this.escFunction, false)
	}

	escFunction = e => {
		if (e.keyCode === 27) {
			this.handleClick(e)
		}
	}

	handleClick = (event) => {
		if (event.keyCode === 27 || (this.wrapperRef && !this.wrapperRef.current.contains(event.target))) {
			this.setState({
				showList: false
			})
		} else {
		}
	}

	toggleList = show => {
		const { readOnly } = this.props
		if (!readOnly) {
			this.setState({
				showList: show
			})
		}
	}

	selectOption = (code, hasSubAccounts) => {
		if (!hasSubAccounts) {
			this.props.select({
				netAccount: code
			})
			this.setState({
				showList: false
			})
		}
	}


	onInputChange = e => {
		e.preventDefault()
		const { name, value } = e.target

		this.setState({ [name]: value })
	}

	checkIfRowMatchesFilter = row => {
		const { filter } = this.state

		var show = false

		if (filter && row.code && row.description) {
			if (row.code.toLowerCase().includes(filter.toLocaleLowerCase())) show = true
			else if (row.description.toLowerCase().includes(filter.toLocaleLowerCase())) show = true
		} else {
			show = true
		}

		return show
	}

	render() {
		const { selectedOption, readOnly } = this.props
		const { list, filter, showList } = this.state
		return (
			<div className={`ocr-data wapro-input-container`} id="wapro-fakir-net-accounts-container" ref={this.wrapperRef}>
				{
					showList ? (
						<div className="tags-list">
							<div className="header">
								{this.context.t('Available net accounts:')}
							</div>
							<div className="filter-row">
								<input type="text" name="filter" value={filter} onChange={this.onInputChange} placeholder={this.context.t('Search')} autoFocus />
							</div>
							<SimpleBar style={{ height: 'auto', maxHeight: '300px', width: '100%' }}>
								<ul>
									{
										list.map((t, i) => {
											if (this.checkIfRowMatchesFilter(t)) {
												return (
													<li className={`tag-item ${selectedOption === t.code ? 'selected' : ''} ${t.has_sub_account ? 'inactive' : ''} indent-${(t.code.match(/-/gm) || []).length}`} onClick={() => this.selectOption(t.code, t.has_sub_account)} key={`net-accounts-${i}`}>{`${t.code} - ${t.description}`}</li>
												)
											} else {
												return null
											}
										})
									}
								</ul>
							</SimpleBar>
						</div>
					) : (
						null
					)
				}
				<label>{this.context.t('Fakir net account')}</label>
				<TextareaAutosize
					className={`tags-container`}
					value={selectedOption}
					name='fakir-logs'
					minRows={1}
					maxRows={10}
					onFocus={() => this.toggleList(true)}
					readOnly={readOnly}
				/>
			</div>
		)
	}
}

OcrPageWaproNettoAccounts.contextTypes = {
	t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
	getCompanyWaproNetAccounts: CompanyActions.getCompanyWaproNetAccounts
}

export default connect(mapStateToProps, mapDispatchToProps)(OcrPageWaproNettoAccounts)