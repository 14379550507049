import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { Spinner } from 'react-activity'

import { UserActions } from '../actions/UserActions';
import { CompanyActions } from '../actions/CompanyActions';

import arrayObjectIndexOf from '../helpers/indexOfObject'

export class ProjectSwitcher extends Component {
    constructor(props) {
        super(props)

        this.state = {
            checkedProjects: [],
            checkedCompany: null,
            selectedProjectsCompany: null
        }
    }

    componentDidMount() {
        const { groupedProjects, selectedProject, selectedProjects } = this.props
        if (selectedProject && groupedProjects) {
            var selectedCompany = null
            var companiesCount = groupedProjects.length, projectsCount = 0
            for (var i = 0; i < companiesCount; i++) {
                if (groupedProjects[i] && groupedProjects[i].projects) {
                    projectsCount = groupedProjects[i].projects.length
                    for (var j = 0; j < projectsCount; j++) {
                        if (groupedProjects[i].projects[j].id === selectedProject.id) {
                            selectedCompany = groupedProjects[i].company.id
                            break;
                        }
                    }

                    if (selectedCompany) break;
                }
            }
            this.setState({
                checkedProjects: [selectedProject],
                selectedProjectsCompany: selectedCompany
            })
        }

        if (selectedProjects && selectedProjects.length > 0) {
            var selectedCompany = null
            var companiesCount = groupedProjects.length, projectsCount = 0
            for (var i = 0; i < companiesCount; i++) {
                if (groupedProjects[i] && groupedProjects[i].projects) {
                    projectsCount = groupedProjects[i].projects.length
                    for (var j = 0; j < projectsCount; j++) {
                        if (groupedProjects[i].projects[j].id === selectedProjects[0].id) {
                            selectedCompany = groupedProjects[i].company.id
                            break;
                        }
                    }

                    if (selectedCompany) break;
                }
            }
            this.setState({
                checkedProjects: selectedProjects,
                selectedProjectsCompany: selectedCompany
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.selectedProject && this.props.selectedProject) {
            const { groupedProjects, selectedProject } = this.props
            var selectedCompany = null
            var companiesCount = groupedProjects.length, projectsCount = 0
            for (var i = 0; i < companiesCount; i++) {
                if (groupedProjects[i] && groupedProjects[i].projects) {
                    projectsCount = groupedProjects[i].projects.length
                    for (var j = 0; j < projectsCount; j++) {
                        if (groupedProjects[i].projects[j].id === selectedProject.id) {
                            selectedCompany = groupedProjects[i].company.id
                            break;
                        }
                    }

                    if (selectedCompany) break;
                }
            }
            this.setState({
                checkedProjects: [selectedProject],
                selectedProjectsCompany: selectedCompany
            })
        }
    }

    componentWillUnmount() {
        const { checkedProjects, selectedProjectsCompany } = this.state
        const { groupedProjects } = this.props

        var shouldReturn = false
        if (groupedProjects) {
            groupedProjects.map(group => {
                if (group.company && group.company.id === selectedProjectsCompany) {
                    if (group.projects && group.projects.length > 1) shouldReturn = true
                }
            })
        }

        if (checkedProjects.length > 1 && this.props.multiSelect) {
            console.log('returnSelectedProjects')
            console.log(checkedProjects)
            this.props.returnSelectedProjects(checkedProjects)
        } else if (checkedProjects.length === 1 && shouldReturn) {
            console.log('returnSelectedProjects else')
            console.log(checkedProjects)
            this.props.returnSelectedProjects(checkedProjects)
        }
    }

    switchViewMode = (e, p, companyId, multiSelectActive, projectCode) => {
        e.preventDefault()
        console.log(p)

        if (multiSelectActive) {
            var { checkedProjects, selectedProjectsCompany } = this.state
            if (selectedProjectsCompany === companyId || !selectedProjectsCompany) {
                if (checkedProjects.find(e => e.id === p)) checkedProjects = checkedProjects.filter(e => e.id !== p)
                else checkedProjects.push({ id: p, name: projectCode })

                this.setState({
                    checkedProjects: checkedProjects,
                    selectedProjectsCompany: companyId,
                    checkedCompany: null
                })
            } else {
                this.setState({
                    checkedProjects: [{ id: p, name: projectCode }],
                    selectedProjectsCompany: companyId,
                    checkedCompany: null
                })
            }
        } else {
            console.log('switchViewMode else')
            this.setState({
                checkedProjects: [],
                selectedProjectsCompany: null,
                checkedCompany: null
            }, () => {
                this.props.switchDashboardViewModel(p)
                if (this.props.closeProjectsList) {
                    this.props.closeProjectsList(p, companyId, e)
                }

                var { userCompanies, currentCompany } = this.props

                if (currentCompany && currentCompany.id !== companyId) {
                    var companyIndex = arrayObjectIndexOf(userCompanies, companyId, 'company_id')
                    console.log(companyIndex)
                    if (companyIndex > -1) {
                        this.props.setCurrentCompany(userCompanies[companyIndex])
                    }
                }
            })
        }
    }

    companyLabelClick = companyId => {
        const { groupedProjects } = this.props
        var { checkedCompany, checkedProjects, selectedProjectsCompany } = this.state

        if (checkedCompany === companyId) {
            this.setState({
                checkedCompany: null,
                checkedProjects: [],
                selectedProjectsCompany: null
            })
        } else {
            if (selectedProjectsCompany !== companyId) checkedProjects = []
            groupedProjects.map(g => {
                if (g.company && g.company.id === companyId) {
                    g.projects.map(p => {
                        if (!checkedProjects.find(e => e.id === p.id)) checkedProjects.push({ id: p.id, name: p.code })
                    })

                    this.setState({
                        checkedCompany: companyId,
                        checkedProjects: checkedProjects,
                        selectedProjectsCompany: companyId
                    })
                }
            })
        }
    }

    render() {
        const { groupedProjects, userProjects, showAll, showHeader, showOnlyMain, showOnlyPaymentProjects, currentProject, viewMode, multiSelect } = this.props
        const { checkedCompany, checkedProjects } = this.state

        return (
            <div className="projects-list" id="projects-switcher-list">
                <div className="header">
                    {
                        !showAll || showHeader ? (
                            this.context.t('Choose binder')
                        ) : (
                            null
                        )
                    }
                </div>
                {
                    groupedProjects ? (
                        <SimpleBar className="scrollbar-wrapper" style={{ maxHeight: 'calc(75vh - 70px)', width: '100%' }}>
                            <ul>
                                {
                                    !showOnlyMain && !showAll && window.location.href.includes('/dashboard') ? (
                                        <li key={'ALL'} className={`${viewMode === 'MULTI' ? 'current-selected' : ''}`} onClick={(e) => this.switchViewMode(e, null)}>
                                            {this.context.t('All Binders')}
                                        </li>
                                    ) : (
                                        null
                                    )
                                }
                                {
                                    showOnlyPaymentProjects ? (
                                        userProjects.map(project => {
                                            if (project.payments) {
                                                return (
                                                    <li key={project.id} className={`${currentProject.id === project.id ? 'current-selected' : ''} no-indent}`} onClick={(e) => this.switchViewMode(e, project.id, project.company_id)}>
                                                        {project.code}
                                                    </li>
                                                )
                                            } else {
                                                return null
                                            }
                                        })
                                    ) : (
                                        groupedProjects.length > 0 ? (
                                            groupedProjects.map(g => {
                                                return (
                                                    showOnlyMain ? (
                                                        g.projects.map(p => {
                                                            if (p.main) {
                                                                return (
                                                                    <>
                                                                        {
                                                                            g.projects.length > 1 ? (
                                                                                <span className="company-label" key={g.company.code}>{g.company.code}</span>
                                                                            ) : (
                                                                                null
                                                                            )
                                                                        }
                                                                        <li key={p.id} className={`${currentProject.id === p.id ? 'current-selected' : ''} ${g.projects.length === 1 ? 'no-indent' : 'indent'}`} onClick={(e) => this.switchViewMode(e, p.id, g.company.id)}>
                                                                            {p.code}

                                                                            {
                                                                                g.projects.length === 1 ? (
                                                                                    <span className="company-label-small" key={g.company.code}>{` ${g.company.code}`}</span>
                                                                                ) : (
                                                                                    null
                                                                                )
                                                                            }
                                                                        </li>
                                                                    </>
                                                                )

                                                            } else {
                                                                return null
                                                            }
                                                        })
                                                    ) : (
                                                        showAll ? (
                                                            g.projects.map(p => {
                                                                if (p.main) {
                                                                    return (
                                                                        <>
                                                                            {
                                                                                g.projects.length > 1 ? (
                                                                                    <span key={g.company.code} className={`company-label ${multiSelect ? 'can-select' : ''}`} onClick={() => { this.companyLabelClick(g.company.id) }}>
                                                                                        {g.company.code}
                                                                                        {
                                                                                            multiSelect ? (
                                                                                                <div className={`checkbox ${checkedCompany === g.company.id ? 'checked' : ''}`}></div>
                                                                                            ) : (
                                                                                                null
                                                                                            )
                                                                                        }
                                                                                    </span>
                                                                                ) : (
                                                                                    null
                                                                                )
                                                                            }
                                                                            <li key={p.id} className={`${currentProject.id === p.id && g.projects.length === 1 ? 'current-selected' : ''} ${g.projects.length === 1 ? 'no-indent' : 'indent'}`} onClick={(e) => this.switchViewMode(e, p.id, g.company.id, multiSelect && g.projects.length > 1, p.code)}>
                                                                                {p.code}
                                                                                {
                                                                                    g.projects.length === 1 ? (
                                                                                        <span key={g.company.code} className="company-label-small">{` ${g.company.code}`}</span>
                                                                                    ) : (
                                                                                        multiSelect ? (
                                                                                            <div className={`checkbox ${checkedProjects.find(e => e.id === p.id) ? 'checked' : ''}`}></div>
                                                                                        ) : (
                                                                                            null
                                                                                        )
                                                                                    )
                                                                                }
                                                                            </li>
                                                                        </>
                                                                    )

                                                                } else {
                                                                    return (
                                                                        <li key={p.id} className={`${currentProject.id === p.id ? 'current-selected' : ''} ${g.projects.length === 1 ? 'no-indent' : 'indent'}`} onClick={(e) => this.switchViewMode(e, p.id, g.company.id, multiSelect && g.projects.length > 1, p.code)}>
                                                                            {p.code}
                                                                            {
                                                                                multiSelect && g.projects.length > 1 ? (
                                                                                    <div className={`checkbox ${checkedProjects.find(e => e.id === p.id) ? 'checked' : ''}`}></div>
                                                                                ) : (
                                                                                    null
                                                                                )
                                                                            }
                                                                        </li>
                                                                    )
                                                                }
                                                            })
                                                        ) : (
                                                            g.projects.length > 1 ? (
                                                                g.projects.map((p, i) => {
                                                                    if (i === 0) {
                                                                        return (
                                                                            <>
                                                                                <span key={g.company.code} className="company-label">{g.company.code}</span>
                                                                                <li key={p.id} className={`${currentProject.id === p.id ? 'current-selected' : ''} ${g.projects.length === 1 ? 'no-indent' : 'indent'}`} onClick={(e) => this.switchViewMode(e, p.id, g.company.id, multiSelect && g.projects.length > 1, p.code)}>
                                                                                    {p.code}
                                                                                </li>
                                                                            </>
                                                                        )
                                                                    } else {
                                                                        return (
                                                                            <li key={p.id} className={`${currentProject.id === p.id ? 'current-selected' : ''} ${g.projects.length === 1 ? 'no-indent' : 'indent'}`} onClick={(e) => this.switchViewMode(e, p.id, g.company.id, multiSelect && g.projects.length > 1, p.code)}>
                                                                                {p.code}
                                                                            </li>
                                                                        )
                                                                    }
                                                                })
                                                            ) : (
                                                                <>
                                                                    <li key={g.company.id} className={`${currentProject.id === g.projects[0].id ? 'current-selected' : ''} company ${g.projects.length === 1 ? 'no-indent' : 'indent'}`} onClick={(e) => this.switchViewMode(e, g.projects[0].id, g.company.id)}>
                                                                        {g.projects[0].code}
                                                                        <span key={g.company.code} className="company-label-small">{` ${g.company.code}`}</span>
                                                                    </li>
                                                                </>
                                                            )
                                                        )
                                                    )
                                                )
                                            })
                                        ) : (
                                            null
                                        )
                                    )
                                }
                            </ul>
                        </SimpleBar>
                    ) : (
                        <div className="no-projects-loaded">
                            <Spinner size={20} speed={0.8} color={"#69B792"} />
                        </div>
                    )
                }
            </div>
        )
    }
}

ProjectSwitcher.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
    groupedProjects: state.User.groupedProjects,
    userProjects: state.User.userProjects,
    currentProject: state.User.currentProject,
    viewMode: state.User.viewMode,

    userCompanies: state.Company.companies,
    currentCompany: state.Company.currentCompany,
})

const mapDispatchToProps = {
    switchDashboardViewModel: UserActions.switchDashboardViewModel,
    setCurrentCompany: CompanyActions.setCurrentCompany,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSwitcher)