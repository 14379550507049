import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react'

import { AlertActions } from '../../actions/AlertActions'

export class DeleteInvoiceModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showProjectsList: false,
            selectedProjectId: -1,
            selectedProjectCode: ''
        }

        this.projectsInputWrapperRef = React.createRef()
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
    }

    handleClick = (e) => {

        const { showProjectsList } = this.state
        if (showProjectsList && this.projectsInputWrapperRef && this.projectsInputWrapperRef.current && !this.projectsInputWrapperRef.current.contains(e.target)) {
            this.setState({
                showProjectsList: false,
            })
        }
    }

    selectNewProject = (id, code) => {
        const { currentProjectId } = this.props
        if (id !== currentProjectId) {
            this.setState({
                selectedProjectId: id,
                selectedProjectCode: code
            })
        }
    }

    toggleProjectsList = () => {
        this.setState({
            showProjectsList: !this.state.showProjectsList,
        })
    }

    moveToOtherProject = () => {
        const { selectedProjectId } = this.state
        if (selectedProjectId !== -1) {
            this.props.moveToOtherProject(selectedProjectId)
        } else {
            this.props.alertWarn(this.context.t('Please select a binder to move the document'))
        }
    }

    render() {
        const { showProjectsList, selectedProjectId, selectedProjectCode } = this.state
        const { currentProjectId, groupedProjects } = this.props
        return (
            <div className="move-to-other-binder popup">
                <div className="popup-card">
                    <div className="header">{this.context.t('Select binder to which you want to move the document')}</div>

                    <div className="body">
                        <div className="choosen-project" onClick={(e) => this.toggleProjectsList()} ref={this.projectsInputWrapperRef}>
                            <label>{this.context.t('Choose binder')}</label>
                            <span className={`expend-icon ${showProjectsList ? 'expended' : ''}`}></span>
                            {
                                selectedProjectCode === '' ? (
                                    <span className="light-gray">{this.context.t('<expand list>')}</span>
                                ) : (
                                    <div className="selected-project">
                                        {selectedProjectCode}
                                    </div>
                                )
                            }

                            {
                                showProjectsList ? (
                                    <ul className="project-list">
                                        {
                                            groupedProjects.map((g, i) => {
                                                return (
                                                    <>
                                                        <div className="company" key={`company-group-${i}`}>{g.company.code}</div>
                                                        {
                                                            g.projects.map((p, j) => {
                                                                return (
                                                                    <li className={`project-row ${currentProjectId === p.id ? 'inactive' : ''} ${selectedProjectId === p.id ? 'selected' : ''}`} onClick={() => this.selectNewProject(p.id, p.code)}>
                                                                        {p.code}
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </ul>
                                ) : (
                                    null
                                )
                            }
                        </div>
                    </div>

                    <div className="action-buttons">
                        <div className="btn" onClick={e => this.props.cancel()}>{this.context.t('Cancel')}</div>
                        <div className="btn delete" onClick={e => this.moveToOtherProject()}>{this.context.t('Move to other binder')}</div>
                    </div>
                </div>
            </div >
        )
    }
}

DeleteInvoiceModal.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
    groupedProjects: state.User.groupedProjects || [],
})

const mapDispatchToProps = {
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteInvoiceModal)