import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import TextareaAutosize from 'react-textarea-autosize'

import '../../../css/OcrPage.css'

export class TransactionDescription extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }

        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    onChange = e => {
        e.preventDefault()
        const { value } = e.target

        this.props.onChange(value)
    }

    render() {
        const { selectedOption, editMode, lang } = this.props
        return (
            <div className={`doc-prop transaction-description`} id="wapro-doc-description-container" ref={this.wrapperRef}>
                <span className="name">{this.context.t('Transaction description {l}', { l: lang })}</span>
                <TextareaAutosize
                    className={`${!editMode ? 'read-only' : ''}`}
                    readOnly={!editMode}
                    value={selectedOption}
                    name='fakir-logs'
                    minRows={1}
                    maxRows={10}
                    onChange={e => this.onChange(e)}
                />
            </div>
        )
    }
}

TransactionDescription.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionDescription)