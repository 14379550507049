import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'

import throttle from "lodash.throttle"
import ReactTooltip from 'react-tooltip'

import { AlertActions } from '../../../../actions/AlertActions'
import { CompanyActions } from '../../../../actions/CompanyActions'


import isIterable from "../../../../helpers/isIterable"


import DefaultPhoto from '../../../../img/default-user-photo.svg'

export class ProjectMembers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            extendedList: [],
            searchString: '',
            showOptionsList: false,
            optionsListMemberId: null,
            optionsListPosX: 0,
            optionsListPosY: 0,
            potionsListFlipUp: false,
            showDeletePopup: false,
            memberToDelete: null,
            returnedOverlayPossitions: false,
        }
    }

    componentDidMount() {
        window.addEventListener("keydown", this.escFunction, false)
        window.addEventListener("resize", throttle(this.setOverlaySize, 100))

        if (!this.state.returnedOverlayPossitions) {
            console.log('DSADWFWADWADA')
            const { searchRef, addMemberRef, membersListRef } = this.refs

            if (searchRef && addMemberRef && membersListRef) {
                console.log('DSADWFWADWADA')
                var holesToDraw = []

                var searchBox = searchRef.getBoundingClientRect()
                if (searchBox) {
                    holesToDraw.push({
                        x: searchBox.x,
                        y: searchBox.y,
                        w: searchBox.width,
                        h: searchBox.height
                    })
                }

                var addMemberBox = addMemberRef.getBoundingClientRect()
                if (addMemberBox) {
                    holesToDraw.push({
                        x: addMemberBox.x,
                        y: addMemberBox.y,
                        w: addMemberBox.width,
                        h: addMemberBox.height
                    })
                }

                var membersListBox = membersListRef.getBoundingClientRect()
                if (membersListBox) {
                    holesToDraw.push({
                        x: membersListBox.x,
                        y: membersListBox.y + 15,
                        w: membersListBox.width,
                        h: membersListBox.height
                    })
                }

                if (holesToDraw.length === 3) {
                    console.log(holesToDraw)
                    this.setState({
                        returnedOverlayPossitions: true,
                    })

                    this.props.returnOverlayPossitions(holesToDraw)
                }
            }
        }

        this.props.getAllCompanyWorkers(this.props.currentCompany.company.id)
    }

    componentDidUpdate() {
        if (!this.state.returnedOverlayPossitions) {
            console.log('DSADWFWADWADA')
            const { searchRef, addMemberRef, membersListRef } = this.refs

            if (searchRef && addMemberRef && membersListRef) {
                console.log('DSADWFWADWADA')
                var holesToDraw = []

                var searchBox = searchRef.getBoundingClientRect()
                if (searchBox) {
                    holesToDraw.push({
                        x: searchBox.x,
                        y: searchBox.y,
                        w: searchBox.width,
                        h: searchBox.height
                    })
                }

                var addMemberBox = addMemberRef.getBoundingClientRect()
                if (addMemberBox) {
                    holesToDraw.push({
                        x: addMemberBox.x,
                        y: addMemberBox.y,
                        w: addMemberBox.width,
                        h: addMemberBox.height
                    })
                }

                var membersListBox = membersListRef.getBoundingClientRect()
                if (membersListBox) {
                    holesToDraw.push({
                        x: membersListBox.x,
                        y: membersListBox.y + 15,
                        w: membersListBox.width,
                        h: membersListBox.height
                    })
                }

                if (holesToDraw.length === 3) {
                    console.log(holesToDraw)
                    this.setState({
                        returnedOverlayPossitions: true,
                    })

                    this.props.returnOverlayPossitions(holesToDraw)
                }
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.escFunction, false)
        window.removeEventListener("resize", throttle(this.setOverlaySize, 100))
    }

    escFunction = (e) => {
        if (e.keyCode === 27) {
            if (this.state.showProjectsList) {
                this.setState({
                    showProjectsList: false
                })
            } else if (this.state.showOptionsList) {
                this.setState({
                    showOptionsList: false,
                    optionsListMemberId: null,
                    optionsListPosX: 0,
                    optionsListPosY: 0,
                    potionsListFlipUp: false,
                })
            } else if (this.state.showDeletePopup) {
                this.setState({
                    showDeletePopup: false,
                    memberToDelete: null,
                })
            }
        }
    }

    setOverlaySize = () => {
        const { searchRef, addMemberRef, membersListRef } = this.refs

        if (searchRef && addMemberRef && membersListRef) {
            var holesToDraw = []

            var searchBox = searchRef.getBoundingClientRect()
            if (searchBox) {
                holesToDraw.push({
                    x: searchBox.x,
                    y: searchBox.y,
                    w: searchBox.width,
                    h: searchBox.height
                })
            }

            var addMemberBox = addMemberRef.getBoundingClientRect()
            if (addMemberBox) {
                holesToDraw.push({
                    x: addMemberBox.x,
                    y: addMemberBox.y,
                    w: addMemberBox.width,
                    h: addMemberBox.height
                })
            }

            var membersListBox = membersListRef.getBoundingClientRect()
            if (membersListBox) {
                holesToDraw.push({
                    x: membersListBox.x,
                    y: membersListBox.y + 15,
                    w: membersListBox.width,
                    h: membersListBox.height
                })
            }

            if (holesToDraw.length === 3) {
                console.log(holesToDraw)
                this.setState({
                    returnedOverlyPossitions: true,
                })

                this.props.returnOverlayPossitions(holesToDraw)
            }
        }
    }

    onInputChange = e => {
        e.preventDefault()

        const { name, value } = e.target

        this.setState({ [name]: value })
    }

    toggleExtendedProjectsForUser = userId => {
        var { extendedList } = this.state

        console.log('toggleExtendedProjectsForUser func ' + userId)
        console.log(extendedList)

        if (extendedList.includes(userId)) {
            extendedList = extendedList.filter(e => e !== userId)
        } else {
            extendedList.push(userId)
        }

        this.setState({
            extendedList: extendedList
        })
    }

    getSortClass = sortFieldCheck => {
        const { companyWorkersSortField, companyWorkersSortOrder } = this.props
        if (companyWorkersSortField === sortFieldCheck) {
            if (companyWorkersSortOrder === "asc")
                return "icon-sort down"
            return "icon-sort up"
        }

        return "icon-sort"
    }

    showOptionsList = (e, memberId, member, companyId) => {
        e.preventDefault()
        let up = false
        let pos = e.target.getClientRects()[0];
        let left = pos.left;
        let top = pos.top + pos.height;
        if (top + 250 > window.innerHeight) {
            up = true
            top = pos.top + 10
        }
        this.setState({
            showOptionsList: true,
            optionsListMemberId: memberId,
            optionsListMember: member,
            optionsListCompany: companyId,
            optionsListPosX: left,
            optionsListPosY: top,
            potionsListFlipUp: up,
        })
    }

    closeShowOptionsList = e => {
        e.preventDefault()
        this.setState({
            showOptionsList: false
        })
    }
    hideOptionsList = e => {
        if ((!this.hasAncestor(e.target, "member-options-list") && this.state.showOptionsList)) {
            this.setState({
                showOptionsList: false,
                optionsListMemberId: null,
                optionsListPosX: 0,
                optionsListPosY: 0,
                potionsListFlipUp: false,
            })
        }
    }

    hasAncestor = (element, id) => {
        while (element) {
            if (element.id && element.id === id) return true

            element = element.parentNode
        }
        return false
    }

    deleteCompanyMember = () => {
        this.setState({
            showDeletePopup: false,
            memberToDelete: null,
        })
        this.props.deleteCompanyMember(this.state.memberToDelete, this.state.companyToDeleteMemberFrom)
    }

    checkIfMemberMatchesFilter = member => {
        const { searchString } = this.state
        if (member.email && member.email.toLowerCase().includes(searchString.toLowerCase())) return true
        if (member.first_name && member.first_name.toLowerCase().includes(searchString.toLowerCase())) return true
        if (member.last_name && member.last_name.toLowerCase().includes(searchString.toLowerCase())) return true
        if (member.first_name && member.last_name && (member.first_name.toLowerCase() + ' ' + member.last_name.toLowerCase()).toString().includes(searchString.toLowerCase())) return true

        return false
    }

    render() {
        const { showDeletePopup, memberToDelete, searchString, extendedList, showOptionsList, optionsListMemberId, optionsListMember, optionsListCompany, optionsListPosX, optionsListPosY, potionsListFlipUp } = this.state
        const { user, companyWorkers } = this.props
        return (
            <div className="projects-members-tab" onClick={e => this.hideOptionsList(e)}>

                {
                    showOptionsList ? (
                        <div id="member-options-list" className={`options-list ${potionsListFlipUp ? 'up' : ''}`} style={{ top: optionsListPosY, left: optionsListPosX }}>
                            <button className='show-option-close-button' onClick={(e) => this.closeShowOptionsList(e)}></button>
                           
                            <div className="info">
                                {this.context.t('Set role in company')}
                            </div>
                            <div className={`role ${optionsListMember.role === 'PROJECT_MEMBER' ? 'active' : ''} ${optionsListMemberId === user.id ? 'unavailable' : ''}`} onClick={() => this.props.changeUserRole(optionsListMemberId, 'PROJECT_MEMBER', optionsListCompany)}>
                                {this.context.t('Binder member')}
                            </div>
                            <div className={`role ${optionsListMember.role === 'ADMIN' ? 'active' : ''} ${optionsListMemberId === user.id ? 'unavailable' : ''}`} onClick={() => this.props.changeUserRole(optionsListMemberId, 'ADMIN', optionsListCompany)}>
                                {this.context.t('Administrator')}
                            </div>

                            <div className="additional-roles-title">{this.context.t('Additional entitlements')}:</div>
                            <div className={`role additional ${optionsListMember.is_accountant ? 'active' : ''}`} onClick={() => this.props.changeUserRole(optionsListMemberId, 'ACCOUNTANT', optionsListCompany)}>
                                {this.context.t('Accountant')}
                            </div>

                            {
                                optionsListMemberId !== user.id ? (
                                    <div className="delete-member" onClick={() => {
                                        this.setState({
                                            showDeletePopup: true,
                                            memberToDelete: this.state.optionsListMemberId,
                                            companyToDeleteMemberFrom: optionsListCompany,
                                            showOptionsList: false,
                                            optionsListMemberId: null,
                                            optionsListPosX: 0,
                                            optionsListPosY: 0,
                                            potionsListFlipUp: false,
                                        })
                                    }}>
                                        {this.context.t('Delete member')}
                                    </div>
                                ) : null
                            }
                        </div>
                    ) : (
                        null
                    )
                }

                {
                    showDeletePopup ? (
                        <div className="popup">
                            <div className="popup-card delete">
                                <div className="header">
                                    {this.context.t('Are you sure you want to delete this member?')}
                                </div>
                                <div className="action-buttons">
                                    <div className="btn cancel" onClick={() => this.setState({
                                        showDeletePopup: false,
                                        memberToDelete: null,
                                    })}>{this.context.t('Cancel')}</div>
                                    <div className="btn delete" onClick={() => this.deleteCompanyMember()}>{this.context.t('Delete')}</div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        null
                    )
                }

                <div className="header-row">
                    <div className="search-input" ref="searchRef">
                        <span className="search-icon"></span>
                        <input type="text" name="searchString" id="searchString" value={searchString} placeholder={this.context.t("Search members")} onChange={e => this.onInputChange(e)} autoComplete={'off'} />
                    </div>
                </div>

                <div className="members-table table-responsive" ref="membersListRef">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <div>
                                        <span className="column-name">{this.context.t('Name')}</span>
                                    </div>
                                </th>
                                <th>
                                    <div>
                                        <span className="column-name">{this.context.t('E-mail')}</span>
                                    </div>
                                </th>
                                <th>
                                    <div>
                                        <span className="column-name">{this.context.t('Registration')}</span>
                                    </div>
                                </th>
                                <th>
                                    <div>
                                        <span className="column-name">{this.context.t('Company role')}</span>
                                    </div>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                companyWorkers.map(group => {
                                    if (group.members) {
                                        return (
                                            <>
                                                <div className="company">{group.company ? group.company.code : ""}</div>
                                                {
                                                    group.members.map(member => {
                                                        if (this.checkIfMemberMatchesFilter(member)) {
                                                            return (
                                                                <tr>
                                                                    <td className={`name`}>
                                                                        <div className="vertical-wrapper">
                                                                            <img src={member.profile_pic_link ? member.profile_pic_link : DefaultPhoto} alt="" />
                                                                            <div className="member-name">
                                                                                {
                                                                                    member.first_name ? (
                                                                                        `${member.first_name} ${member.last_name}${member.id === user.id ? this.context.t(' (You)') : ""}`
                                                                                    ) : (
                                                                                        ''
                                                                                    )
                                                                                }
                                                                                {
                                                                                    member.is_service ? (
                                                                                        <>
                                                                                            <div className="service-badge" data-for={`service-info-${member.id}`} data-tip='show'>
                                                                                                {this.context.t('SERVICE')}
                                                                                            </div>

                                                                                            <ReactTooltip id={`service-info-${member.id}`} className="default-tooltip service-tooltip" place="left" effect='solid'>
                                                                                                <p>{this.context.t('Users marked as "Service" are representatives of EasyDocs and are not taken into account when billing the subscription')}</p>
                                                                                            </ReactTooltip>
                                                                                        </>
                                                                                    ) : (
                                                                                        null
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="email">
                                                                        <div className="vertical-wrapper">
                                                                            {member.email}
                                                                        </div>
                                                                    </td>
                                                                    <td className="created">
                                                                        <div className="vertical-wrapper">
                                                                            {moment(member.created).format('YYYY-MM-DD HH:mm')}
                                                                        </div>
                                                                    </td>
                                                                    <td className="role">
                                                                        <div className="vertical-wrapper">
                                                                            {
                                                                                member.role === 'ADMIN' && member.is_accountant ? (
                                                                                    `${this.context.t('Administrator')} i ${this.context.t('Accountant')}`
                                                                                ) : (
                                                                                    member.role === 'ADMIN' ? (
                                                                                        this.context.t('Administrator')
                                                                                    ) : (
                                                                                        member.is_accountant ? (
                                                                                            this.context.t('Accountant')
                                                                                        ) : (
                                                                                            this.context.t('Binder member')
                                                                                        )
                                                                                    )
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td className="options">
                                                                        <div className="vertical-wrapper options" onClick={e => this.showOptionsList(e, member.id, member, group.company.id)}></div>
                                                                    </td>

                                                                </tr>
                                                            )
                                                        } else {
                                                            return null
                                                        }
                                                    })
                                                }
                                            </>
                                        )
                                    } else {
                                        return null
                                    }

                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

ProjectMembers.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    currentCompany: state.Company.currentCompany,
    companyWorkers: [...state.Company.companyWorkers],
    companyWorkers: isIterable(state.Company.companyWorkers) ? [...state.Company.companyWorkers] : state.Company.companyWorkers,
    companyWorkersSortField: state.Company.companyWorkersSortField,
    companyWorkersSortOrder: state.Company.companyWorkersSortOrder,
    companyProjects: state.Company.companyProjects
})

const mapDispatchToProps = {
    getAllCompanyWorkers: CompanyActions.getAllCompanyWorkers,
    switchCompanyMembersSorting: CompanyActions.switchCompanyMembersSorting,
    changeUserRole: CompanyActions.changeUserRole,
    deleteCompanyMember: CompanyActions.deleteCompanyMember,
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectMembers)
