import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import TagsComponent from '../OcrPageComponents/TagsComponent';
import { ReportsActions } from '../../actions/ReportsActions'

export class TagsBulkEditWindow extends Component {

    constructor(props) {
        super(props)
        this.state = {
            startTagsToDelete: '',
            startTagsToAdd: '',
            tagsList: [],
            tagsCreationLimitation: 'EVERYONE',
            currentProjectRole: 'USER1'
        }

        this.cardRef = React.createRef()
    }

    componentDidMount() {
        const { currentCompany, currentProject, tagsToEdit } = this.props
        // this.setState({
        //     startTagsToDelete: tagsToEdit
        // })

        if (currentCompany && currentCompany.company) {
            this.setState({
                tagsList: currentCompany.company.tags_list,
                tagsCreationLimitation: currentCompany.company.tags_creation_limitation
            })
        }

        if (currentProject) {
            this.setState({
                currentProjectRole: currentProject.acceptance_permissions
            })
        }
    }

    componentDidUpdate() {

    }

    tagsToDeleteChange = value => {
        this.setState({
            tagsToDelete: value
        })
    }

    tagsToAddChange = value => {
        this.setState({
            tagsToAdd: value
        })
    }

    saveTagsChanges = () => {
        const { waitingForTagsUpdate } = this.state
        if (!waitingForTagsUpdate) {
            const { tagsToDelete, tagsToAdd } = this.state
            const { currentSelectedDocs } = this.props
            var filesToUpdate = []
            var paymentOrdersToUpdate = []
            currentSelectedDocs.map(doc => {
                if (doc.type !== 'PAYMENT_ORDER') {
                    filesToUpdate.push(doc.fileId)
                } else {
                    paymentOrdersToUpdate.push(doc.fileId)
                }
            })

            if (filesToUpdate.length && paymentOrdersToUpdate.length) {
                this.setState({ waitingForTagsUpdate: true })
                this.props.bulkUpdatedTagsForFiles(filesToUpdate, tagsToDelete, tagsToAdd, () => {
                    this.props.bulkUpdatedTagsForPaymentOrders(paymentOrdersToUpdate, tagsToDelete, tagsToAdd, () => {
                        this.setState({ waitingForTagsUpdate: false })
                        this.props.close()
                    })
                })
            } else if (filesToUpdate.length) {
                this.setState({ waitingForTagsUpdate: true })
                this.props.bulkUpdatedTagsForFiles(filesToUpdate, tagsToDelete, tagsToAdd, () => {
                    this.setState({ waitingForTagsUpdate: false })
                    this.props.close()
                })
            } else if (paymentOrdersToUpdate.length) {
                this.setState({ waitingForTagsUpdate: true })
                this.props.bulkUpdatedTagsForPaymentOrders(filesToUpdate, tagsToDelete, tagsToAdd, () => {
                    this.setState({ waitingForTagsUpdate: false })
                    this.props.close()
                })
            }
        }
    }

    close = () => {
        const { waitingForTagsUpdate } = this.state
        if (!waitingForTagsUpdate) {
            this.props.close()
        }
    }


    render() {
        const { startTagsToDelete, startTagsToAdd, tagsList, tagsCreationLimitation, currentProjectRole, waitingForTagsUpdate } = this.state
        return (
            <div className={`bulk-tags-edit-window ${waitingForTagsUpdate ? 'wait' : ''}`}>
                <div className="card">
                    <div className="header">
                        <h2>{this.context.t('Edit tags for all selected documents')}</h2>
                    </div>
                    <div className="body">
                        <TagsComponent
                            currentTags={startTagsToDelete}
                            tagsList={tagsList}
                            canAddNewTags={tagsCreationLimitation === 'EVERYONE' || tagsCreationLimitation.includes(currentProjectRole)}
                            setTags={this.tagsToDeleteChange}
                            customLabel={this.context.t('Tags to remove from selected documents')}
                            customPlaceholder={this.context.t('Enter the tags that will be removed from the selected documents')}
                        />

                        <TagsComponent
                            currentTags={startTagsToAdd}
                            tagsList={tagsList}
                            canAddNewTags={tagsCreationLimitation === 'EVERYONE' || tagsCreationLimitation.includes(currentProjectRole)}
                            setTags={this.tagsToAddChange}
                            customLabel={this.context.t('Tags to add to selected documents')}
                            customPlaceholder={this.context.t('Enter tags that will be added to the selected documents')}
                        />
                    </div>
                    <div className="footer">
                        <button className="button cancel" onClick={e => this.close()}>{this.context.t('Cancel')}</button>
                        <button className="button" onClick={e => this.saveTagsChanges()}>{this.context.t('Confirm')}</button>
                    </div>
                </div>
            </div>
        )
    }
}


TagsBulkEditWindow.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
    currentProject: state.User.currentProject,
    currentCompany: state.Company.currentCompany
})

const mapDispatchToProps = {
    bulkUpdatedTagsForFiles: ReportsActions.bulkUpdatedTagsForFiles,
    bulkUpdatedTagsForPaymentOrders: ReportsActions.bulkUpdatedTagsForPaymentOrders,
}

export default connect(mapStateToProps, mapDispatchToProps)(TagsBulkEditWindow)