import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import TextareaAutosize from 'react-textarea-autosize'

import '../../../css/OcrPage.css'

export class MpkListSelector extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showList: false
        }

        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick);
        window.addEventListener("keydown", this.escFunction, false)
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
        window.removeEventListener("keydown", this.escFunction, false)
    }

    escFunction = e => {
        if (e.keyCode === 27) {
            this.handleClick(e)
        }
    }

    handleClick = (event) => {
        if (event.keyCode === 27 || (this.wrapperRef && !this.wrapperRef.current.contains(event.target))) {
            this.setState({
                showList: false
            })
        } else {
        }
    }

    toggleList = show => {
        const { readOnly } = this.props
        if (!readOnly) {
            this.setState({
                showList: show
            })
        }
    }

    selectOption = mpk => {
        this.props.selectOption(mpk)
        this.setState({
            showList: false
        })
    }

    onInputChange = e => {
        e.preventDefault()
        const { name, value } = e.target

        this.setState({ [name]: value })
    }

    checkIfRowMatchesFilter = row => {
        const { filter } = this.state

        var show = false

        if (filter && row.description) {
            if (row.description.toLowerCase().includes(filter.toLocaleLowerCase())) show = true
        } else {
            show = true
        }

        return show
    }

    render() {
        const { selectedOption, mpkLevel, list, readOnly } = this.props
        const { filter, showList } = this.state

        console.log(list)
        return (
            <div className={`ocr-data mpk-level-input-container`} ref={this.wrapperRef}>
                {
                    showList ? (
                        <div className="tags-list">
                            <div className="header">
                                {this.context.t('Available types:')}
                            </div>
                            <div className="filter-row">
                                <input type="text" name="filter" value={filter} onChange={this.onInputChange} placeholder={this.context.t('Search')} autoFocus />
                            </div>
                            <SimpleBar style={{ height: 'auto', maxHeight: '300px', width: '100%' }}>
                                <ul>
                                    {
                                        list.map((mpk, i) => {
                                            if (this.checkIfRowMatchesFilter(mpk)) {
                                                return (
                                                    <li className={`tag-item ${selectedOption === mpk.description ? 'selected' : ''}`} onClick={() => this.selectOption(mpk)} key={`mpk-types-${i}`}>{`${mpk.description}`}</li>
                                                )
                                            } else {
                                                return null
                                            }
                                        })
                                    }
                                </ul>
                            </SimpleBar>
                        </div>
                    ) : (
                        null
                    )
                }
                <label>{this.context.t('MPK level {n}', { n: mpkLevel })}</label>
                <TextareaAutosize
                    className={`tags-container`}
                    value={selectedOption}
                    minRows={1}
                    maxRows={10}
                    onFocus={() => this.toggleList(true)}
                    readOnly={readOnly}
                />
            </div>
        )
    }
}

MpkListSelector.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(MpkListSelector)