export default [
    { code: '1010', name: 'Narodowy Bank Polski', available: false },
    { code: '1020', name: 'PKO BP', bic: 'BPKOPLPW', available: true, availableInternational: true },
    { code: '1030', name: 'Bank Handlowy (Citi Handlowy)', bic: 'CITIPLPX', available: true },
    { code: '1050', name: 'ING Bank Śląski', bic: 'INGBPLPW', available: true, availableInternational: true },
    { code: '1060', name: 'Bank BPH', bic: 'BPHKPLP1', available: false },
    { code: '1090', name: 'Santander Bank Polska', bic: 'SCFBPLPW', available: true, availableInternational: true },
    { code: '1130', name: 'Bank Gospodarstwa Krajowego', available: false },
    { code: '1140', name: 'mBank', bic: 'BREXPLPWMBK', available: true, availableInternational: true },
    { code: '1160', name: 'Bank Millennium', bic: 'BIGBPLPW', available: true, availableInternational: true },
    { code: '1240', name: 'Pekao SA', bic: 'PKOPPLPW', available: true, availableInternational: true },
    { code: '1280', name: 'HSBC', bic: 'HSBCPLPW', available: false },
    { code: '1300', name: 'Alior Bank', bic: 'ALBPPLPW', available: true, availableInternational: true },
    { code: '1320', name: 'Bank Pocztowy', bic: 'POCZPLP4', available: true },
    { code: '1340', name: 'DRESDNER BANK POLSKA S.A.', available: false },
    { code: '1370', name: 'Bank Inicjatyw Społeczno- Ekonomicznych SA', available: false },
    { code: '1400', name: 'Bank Rozwoju Budownictwa Mieszkaniowego SA', available: false },
    { code: '1420', name: 'Bank Rozwoju Cukrownictwa SA', available: false },
    { code: '1440', name: 'PKO BP', bic: 'BPKOPLPW', available: true, availableInternational: true },
    { code: '1460', name: 'Wschodni Bank Cukrownictwa SA w Lublinie', available: false },
    { code: '1470', name: 'Euro Bank S.A.', available: false },
    { code: '1500', name: 'Santander Bank Polska', bic: 'SCFBPLPW', available: true, availableInternational: true },
    { code: '1520', name: 'Bank Przemysłowy SA w Łodzi', available: false },
    { code: '1550', name: 'LG Petro Bank S.A.', available: false },
    { code: '1560', name: 'Getin Bank SA', available: false },
    { code: '1540', name: 'BOŚ Bank', bic: 'EBOSPLPW', available: false },
    { code: '1580', name: 'Mercedes-Benz Bank Polska', available: false },
    { code: '1600', name: 'BNP Paribas Bank Polska S.A.', bic: 'PPABPLPK', available: true, availableInternational: true },
    { code: '1610', name: 'SGB - Bank', bic: 'GBWCPLPP', available: true },
    { code: '1670', name: 'RBS Bank (Polska)', available: false },
    { code: '1680', name: 'Plus Bank', bic: 'IVSEPLPP', available: true },
    { code: '1690', name: 'BNP Paribas Bank Polska S.A.', bic: 'PPABPLPK', available: true, availableInternational: true },
    { code: '1710', name: 'Alior Bank', bic: 'ALBPPLPW', available: true, availableInternational: true },
    { code: '1740', name: 'DZ BANK Polska SA', available: false },
    { code: '1750', name: 'BNP Paribas Bank Polska S.A.', bic: 'PPABPLPK', available: true, availableInternational: true },
    { code: '1790', name: 'Credit Lyonnais Bank Polska S.A.', available: false },
    { code: '1830', name: 'Danske Bank Polska SA', available: false },
    { code: '1840', name: 'Societe Generale', available: false },
    { code: '1850', name: 'Bank BPH', bic: 'BPHKPLP1', available: false },
    { code: '1860', name: 'BNP Paribas Bank Polska S.A.', bic: 'PPABPLPK', available: true, availableInternational: true },
    { code: '1870', name: 'Nest Bank', bic: 'NESBPLPW', available: true },
    { code: '1880', name: 'Deutsche Bank Polska S.A.', available: false },
    { code: '1890', name: 'BPH Bank Hipoteczny SA', available: false },
    { code: '1910', name: 'Santander Bank Polska', bic: 'SCFBPLPW', available: true, availableInternational: true },
    { code: '1930', name: 'Bank Polskiej Spółdzielczości', available: true, availableInternational: true },
    { code: '1940', name: 'Credit Agricole Bank Polska', bic: 'AGRIPLPR', available: true, availableInternational: true },
    { code: '1950', name: 'Idea Bank', available: false },
    { code: '1960', name: 'Santander Bank Polska', bic: 'SCFBPLPW', available: true, availableInternational: true },
    { code: '2000', name: 'RaboBank Polska S.A.', available: false },
    { code: '2030', name: 'BNP Paribas Bank Polska S.A.', bic: 'PPABPLPK', available: true, availableInternational: true },
    { code: '2060', name: 'Mazowiecki Bank Regionalny SA', available: false },
    { code: '2070', name: 'FCE Bank Polska', available: false },
    { code: '2090', name: 'Bankgesellschaft Berlin (Polska) SA', available: false },
    { code: '2120', name: 'Santander Bank Polska', bic: 'SCFBPLPW', available: true, availableInternational: true },
    { code: '2130', name: 'Volkswagen Bank', available: false },
    { code: '2140', name: 'Fiat Bank Polska', available: false },
    { code: '2150', name: 'mBank Hipoteczny SA', bic: 'RHBHPLPW', available: false },
    { code: '2160', name: 'Toyota Bank', available: false },
    { code: '2180', name: 'Śląski Bank Hipoteczny SA', available: false },
    { code: '2190', name: 'DnB Nord', bic: 'MHBFPLPW', available: false },
    { code: '2200', name: 'BANK SVENSKA HANDELSBANKEN (POLSKA) S.A.', available: false },
    { code: '2210', name: 'Bank of Tokyo-Mitsubishi (Polska) SA', available: false },
    { code: '2220', name: 'Nykredit Bank Hipoteczny SA', available: false },
    { code: '2230', name: 'Cetelem Bank SA', available: false },
    { code: '2240', name: 'Banque PSA Finance SA Oddział w Polsce', available: false },
    { code: '2250', name: 'Svenska Handelsbanken AB SA Oddział w Polsce', available: false },
    { code: '2260', name: 'RCI Bank Polska SA', available: false },
    { code: '2270', name: 'Sygma Banque Societe Anonyme (SA) Oddział w Polsce', available: false },
    { code: '2280', name: 'Jyske Bank A/S SA Oddział w Polsce', available: false },
    { code: '2300', name: 'ABN AMRO Bank N.V.SA Oddział w Polsce', available: false },
    { code: '2310', name: 'Dresdner Bank AG SA Oddział w Polsce', available: false },
    { code: '2320', name: 'Nykredit Realkredit A/S SA - Oddział w Polsce', available: false },
    { code: '2330', name: 'Calyon SA Oddział w Polsce', available: false },
    { code: '2340', name: 'EFG Eurobank Ergasias S.A. (Polbank) Oddział w Polsce', available: false },
    { code: '2350', name: 'BNP PARIBAS SA Oddział w Polsce', bic: 'PPABPLPK', available: true, availableInternational: true },
    { code: '2480', name: 'Getin Noble Bank', bic: 'GBGCPLPK', available: false },
    { code: '2490', name: 'Alior Bank', bic: 'ALBPPLPW', available: true, availableInternational: true },
    { code: '2530', name: 'FM Bank', available: false },
    { code: '7999', name: 'Skok', available: false },
    { code: '9068', name: 'Wielkopolski Bank Spółdzielczy (Neobank)', available: false },
    { code: '8470', name: 'Bank Spółdzielczy w Jastrzębiu-Zdroju', bic: 'POLUPLPR', available: true }
]