import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { FileActions } from '../actions/FileActions'

export class FileHistoryWindow extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loadingHistory: true,
            fileHistory: [],
        }
    }

    componentDidMount() {
        const { fileId } = this.props
        this.props.getFileHistory(fileId, (success, history) => {
            if (success) {
                this.setState({
                    loadingHistory: false,
                    fileHistory: history
                })
            } else {
                this.props.close()
            }
        })
    }

    render() {
        const { fileHistory, loadingHistory } = this.state
        const { } = this.props

        return (
            <div className="delete-invoice-popup popup file-history">
                <div className="popup-card">
                    <div className="header">{this.context.t('File history')}</div>
                    <div className="history-list">

                    </div>
                    <div className="action-buttons">
                        <div className="btn" onClick={e => this.props.close()}>{this.context.t('Close')}</div>
                    </div>
                </div>
            </div>
        )
    }
}

FileHistoryWindow.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
    getFileHistory: FileActions.getFileHistory,
}

export default connect(mapStateToProps, mapDispatchToProps)(FileHistoryWindow)





