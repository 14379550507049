import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { isAndroid, isIOS } from "react-device-detect";
import { NavLink, Link, Route, Redirect, Switch } from "react-router-dom";
import { PrivateRoute } from "../../components/PrivateRoute";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import $, { data } from "jquery";
import ReactTooltip from "react-tooltip";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import moment from "moment";
import "moment-timezone";

import routes from "../../helpers/routes";
import arrayObjectIndexOf from "../../helpers/indexOfObject";
import myFetch from "../../helpers/fetch";
import isIterable from "../../helpers/isIterable";

import { UserActions } from "../../actions/UserActions";
import { FileActions } from "../../actions/FileActions";
import { TransfersActions } from "../../actions/TransfersActions";
import { CompanyActions } from "../../actions/CompanyActions";
import { ReportsActions } from "../../actions/ReportsActions";
import { AlertActions } from "../../actions/AlertActions";
import { ChatActions } from "../../actions/ChatAction";
import { MessagesActions } from "../../actions/MessagesActions";

import DashboardPage from "./Dashboard";
import SettingsPage from "./Settings";
import HelpPage from "./Help";
import OcrPage from "./OcrPage";
import FileManagerPage from "./FileManagerPage";
import SalesPage from "./SalesPage";
import CalendarPage from "./CalendarPage";
import Reports from "./Reports";
import V2_UserPanel from '../V2/V2_UserPanel'

import CompanyPage from "./Admin/Company";
import ProjectsManagement from "./Admin/ProjectsManagement/ProjectsManagement";
import CounterpartiesList from "./Admin/CounterpartiesList";
import Subscription from "./Admin/Subscription";

import SuperadminManagement from "./SuperAdmin/Management";

import TopToolbar from "../../components/TopToolbar";
import CreateTransferOrderWindow from "../../components/CreateTransferOrderWindow";
import AdminOnboarding from "../../components/AdminOnboarding";
import WhatsNewPopup from "../../components/WhatsNewPopup";
import HighPriorityModal from "../../components/HighPriorityModal";
import ExpiringDueDateDocsModal from "../../components/ExpiringDueDateDocsModal";
import DownloadMobileAppPage from "../../components/DownloadMobileAppPage";
import ChatNotificationsPopup from "../../components/Chat/ChatNotificationsPopup";
import DocflowNotificationsPopup from "../../components/DocflowComponents/DocflowNotificationsPopup";
import { socket } from "../../App";

export class UserPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyNotification: false,
      setCurentCompanyOnLoad: true,
      setCurentProjectOnLoad: true,
      whatsNew: null,
      highPriorityDocs: null,
      sendHighPriorityDocsRequest: false,
      expiringDueDateDocs: null,
      sendExpiringDueDateDocsRequest: false,

      loadDynamicResourcesAfterSocketLogin: false
    };
  }

  componentDidMount() {
    $("input").focusout(function () {
      if ($(this).val() !== "") {
        $(this).addClass("has-content");
      } else {
        $(this).removeClass("has-content");
      }
    });

    this.getAllCompanies();

    this.getWhatsNew();
    socket.on("loadDynamicResourcesAfterSocketLogin", this.loadDynamicResourcesAfterSocketLogin)
    socket.on("newFileUploaded", this.newFileUploaded);
    socket.on("newPaymentOrder", this.newPaymentOrder);
    socket.on("edInvoiceUpload", this.edInvoiceUploadSocket);
    socket.on("moveFileToProject", this.moveFileToProject);
    socket.on("movedOldFileToTrash", this.movedOldFileToTrash);
    socket.on("deletedOldFilePermanent", this.deletedOldFilePermanent);
    socket.on("fileDeletedAutomaticly", this.deletedOldFilePermanentAutomaticly);
    socket.on("restoredOldFile", this.restoredOldFile);
    socket.on("markDocAsPaid", this.markDocAsPaid);
    socket.on("markPaymentOrderAsPaid", this.markPaymentOrderAsPaid);
    socket.on("additionalAcceptance", this.docAdditionalAcceptance);
    socket.on("submitOCRFile", this.submitOCRFile);
    socket.on("acceptOCRFile", this.acceptOCRFile);
    socket.on("setNewDocType", this.setNewDocType);
    socket.on("setNewExpenseOwner", this.setNewExpenseOwner);
    socket.on("createNewReport", this.createdNewReport);
    socket.on("createNewTransfersFile", this.createdNewTransfersFile);
    socket.on("deletedProject", this.handleDeleteProject);
    socket.on("updateAdditionalAcceptanceForProject", this.updateAdditionalAcceptanceForProject);
    socket.on("newCompanyTags", this.loadNewCompanyTags);
    socket.on("companySubscriptionUpdate", this.updateCompanySubscription);
    socket.on("newHighPriority", this.getHighPriorityDocsSocket);
    socket.on("registeredEasyGuardDevice", this.registeredEasyGuardDevice);
    socket.on("enable2FA", this.props.enable2FASocketHandler);
    socket.on("disable2FA", this.props.disable2FASocketHandler);
    socket.on("logoutAllDevices", this.logoutAllDevices);
    socket.on("addedWhatsNew", this.getWhatsNew);
    socket.on("editedFileOCR", this.editedFileOCR);
    socket.on("moveAcceptedFileToOtherProject", this.moveAcceptedFileToOtherProject);
    socket.on("updateKsefStatus", this.updateKsefStatusFromCron)
    socket.on("newChatMessage", this.newChatMessage);
    socket.on("deleteChatNotification", this.deleteChatNotification)

    socket.on("docflowUpdateForFile", this.docflowUpdateForFile);
    socket.on("gotAccessToFileAfterDocflowChange", this.gotAccessToFileAfterDocflowChange);
    socket.on("removeAccessToFile", this.removeAccessToFile);
    socket.on("addAccessToFile", this.addAccessToFile);
    socket.on("docflowFinishedForFile", this.docflowFinishedForFile);
    socket.on("docflowNotification", this.docflowNotification);
    socket.on("removeFileFromDocflow", this.removeFileFromDocflow);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.userCompanies &&
      this.props.userCompanies.length > 0 &&
      this.state.setCurentCompanyOnLoad
    ) {
      var savedCurrentCompanyId = localStorage.getItem("currentCompany");
      var company = null;
      if (savedCurrentCompanyId)
        company = this.props.userCompanies.find(
          (c) => c.company_id === parseInt(savedCurrentCompanyId)
        );
      else
        company = this.props.userCompanies.find((c) => c.user_role === "ADMIN");
      if (company) {
        this.props.setCurrentCompany(company);
      } else {
        this.props.setCurrentCompany(this.props.userCompanies[0]);
      }

      this.setState({
        setCurentCompanyOnLoad: false,
      });
    }

    const { userProjects, currentProject } = this.props
    var dashboardProjectId = parseInt(localStorage.getItem('dashboardProject'))
    if (localStorage.getItem('dashboardProject') && userProjects?.length && currentProject && currentProject.id !== dashboardProjectId) {
      if (dashboardProjectId > -1) {
        if (dashboardProjectId) {
          console.log('SET PROJECT FROM LOCAL STORAGE')
          var projectIndex = arrayObjectIndexOf(userProjects, dashboardProjectId, 'id')
          if (projectIndex > -1) {
            this.props.switchDashboardViewModel(userProjects[projectIndex].id)
          }
        }
      }
    }

    const { loadDynamicResourcesAfterSocketLogin } = this.state
    const { userFiles } = this.props
    if (loadDynamicResourcesAfterSocketLogin && userFiles && userFiles.length > 0) {
      this.setState({
        loadDynamicResourcesAfterSocketLogin: false
      })
      this.checkForCompanyChanges()
      this.getHighPriorityDocs();
      setTimeout(() => {
        this.getNewMentions(false);
      }, 1000)
      setTimeout(() => {
        this.getExpiringDueDateDocs();
      }, 2000)
    }

    // if (
    //   this.props.userFiles &&
    //   this.props.userFiles.length > 0 &&
    //   !this.state.highPriorityDocs &&
    //   !this.state.sendHighPriorityDocsRequest
    // ) {
    //   this.getHighPriorityDocs();
    // }

    // if (
    //   this.props.userFiles &&
    //   this.props.userFiles.length > 0 &&
    //   !this.state.expiringDueDateDocs &&
    //   !this.state.sendExpiringDueDateDocsRequest
    // ) {
    //   this.getExpiringDueDateDocs();
    // }

    // if (
    //   this.props.userFiles &&
    //   this.props.userFiles.length > 0 &&
    //   !this.state.newChatNotifications &&
    //   !this.state.sentNewChatNotifications
    // ) {
    //   this.getNewMentions(false);
    // }
  }

  componentWillUnmount() {
    socket.emit("disconnect");
    socket.off();
  }

  loadDynamicResourcesAfterSocketLogin = () => {
    setTimeout(() => {
      const { userFiles } = this.props
      if (userFiles && userFiles.length > 0) {
        this.checkForCompanyChanges()
        this.getHighPriorityDocs();
        setTimeout(() => {
          this.getNewMentions(false);
        }, 1000)
        setTimeout(() => {
          this.getExpiringDueDateDocs();
        }, 2000)
      } else {
        this.setState({ loadDynamicResourcesAfterSocketLogin: true })
      }
    }, 2000)
  }



  checkForCompanyChanges = () => {
    var lastSocketLoginTime = localStorage.getItem('lastSocketLoginTime')
    if (lastSocketLoginTime && moment(lastSocketLoginTime).isValid() && moment().diff(moment(lastSocketLoginTime), 'minutes') > 60) {
      this.props.getUserCompanies()
      this.props.getUserProjects()
    }
  }

  getWhatsNew = async () => {
    try {
      const notes = await myFetch("/whats-new/last", {
        method: "GET",
      });

      if (
        notes.success &&
        notes.note &&
        notes.note.id > this.props.user.whatsNewVersion
      ) {
        this.setState({
          whatsNew: notes.note,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  editedFileOCR = (data) => {
    this.props.loadNewFileToVerifyData(data);
  };

  moveAcceptedFileToOtherProject = (data) => {
    const { userProjects } = this.props;
    if (userProjects.find((p) => p.id === data.oldProjectId)) {
      this.props.deleteInvoiceFromRedux({
        fileId: data.fileId,
        projectId: data.oldProjectId,
      });
      this.props.getProjectResourcesForDashboard(data.oldProjectId, data.fileId);
    }
    if (userProjects.find((p) => p.id === data.newProjectId)) {
      this.props.getProjectResourcesForDashboard(data.newProjectId, 0);
    }
  };

  updateKsefStatusFromCron = data => {
    console.log('got updateKsefStatusFromCron')
    console.log(data)
    const { userProjects } = this.props
    var project = userProjects.find(p => p.id === data.projectId)
    if (project && project.acceptance_permissions !== 'USER1') {
      this.props.updateKsefStatusFromCron(data)
    }
  }

  getHighPriorityDocs = async () => {
    var lastSocketLoginTime = localStorage.getItem('lastSocketLoginTime')
    if (lastSocketLoginTime && moment(lastSocketLoginTime).isValid() && moment().diff(moment(lastSocketLoginTime), 'minutes') > 30) {
      try {
        this.setState({
          sendHighPriorityDocsRequest: true,
        });
        const docs = await myFetch("/files/high-priority-files", {
          method: "GET",
        });

        if (docs.success) {
          const { userFiles, userFilesToVerifi, userTransfers } = this.props;
          var projectsToLoadData = [];
          for (const [key, value] of Object.entries(
            docs.data.highPriorityFilesList
          )) {
            if (
              arrayObjectIndexOf(userFiles, value[0].projectId, "project") ===
              -1
            ) {
              projectsToLoadData.push(value[0].projectId);
            }
          }
          for (const [key, value] of Object.entries(
            docs.data.highPriorityFilesToVerifiList
          )) {
            if (
              arrayObjectIndexOf(
                userFilesToVerifi,
                value[0].projectId,
                "projectId"
              ) === -1
            ) {
              projectsToLoadData.push(value[0].projectId);
            }
          }
          for (const [key, value] of Object.entries(
            docs.data.highPriorityPaymentsList
          )) {
            if (
              arrayObjectIndexOf(
                userTransfers,
                value[0].projectId,
                "projectId"
              ) === -1
            ) {
              projectsToLoadData.push(value[0].projectId);
            }
          }

          projectsToLoadData = [...new Set(projectsToLoadData)];

          projectsToLoadData.forEach((project) => {
            this.props.getProjectResourcesForDashboard(project, 0);
          });

          setTimeout(() => {
            this.setState({
              highPriorityDocs: docs.data,
            });
          }, 1000 * projectsToLoadData.length);
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  getHighPriorityDocsSocket = (data) => {
    if (
      data.userId === this.props.user.id ||
      !this.props.user.notify_about_urgent_docs
    )
      return;

    const project = this.props.userProjects.find(
      (project) => project.id === data.projectId
    );

    if (project) {
      for (let key in data.newHighPriority) {
        const projectNames = Object.keys(data.newHighPriority[key]);
        projectNames.forEach((name) => {
          switch (key) {
            case "highPriorityFilesToVerifiList":
              this.props.setFileToVerifiHighPriority(
                data.newHighPriority[key][name][0].id,
                project.id
              );
              break;
            default:
              break;
          }
        });
      }
    }

    if (project && project.acceptance_permissions === "USER3") {
      if (!this.state.highPriorityDocs) {
        this.setState({
          highPriorityDocs: data.newHighPriority,
        });
      } else {
        const newPriorityDocs = { ...this.state.highPriorityDocs };
        for (let key in data.newHighPriority) {
          const projectNames = Object.keys(data.newHighPriority[key]);
          projectNames.forEach((name) => {
            if (newPriorityDocs[key][name]) {
              newPriorityDocs[key][name].push(
                ...data.newHighPriority[key][name]
              );
            } else {
              newPriorityDocs[key][name] = [data.newHighPriority[key][name]];
            }
          });
        }
        this.setState({
          highPriorityDocs: newPriorityDocs,
        });
      }
    }
  };

  getExpiringDueDateDocs = async () => {
    var lastModalDate = localStorage.getItem("lastExpiringDocsModalDate");
    if (!lastModalDate || lastModalDate < moment().format("YYYY-MM-DD")) {
      try {
        this.setState({
          sendExpiringDueDateDocsRequest: true,
        });
        const res = await myFetch("/docs/expiring-due-date-docs", {
          method: "GET",
        });

        if (res.success) {
          this.setState({
            expiringDueDateDocs: res.docs,
          });

          localStorage.setItem("lastExpiringDocsModalDate", moment().format("YYYY-MM-DD"));
        }
      } catch (err) {
        console.error(err);
      }
    };
  }

  getNewMentions = async (fromSocket = false) => {
    var lastSocketLoginTime = localStorage.getItem('lastSocketLoginTime')
    if (fromSocket || (lastSocketLoginTime && moment(lastSocketLoginTime).isValid() && moment().diff(moment(lastSocketLoginTime), 'minutes') > 30)) {
      localStorage.setItem('lastSocketLoginTime', moment().format('YYYY-MM-DD HH:mm:ss'))
      try {
        this.setState({
          sentNewChatNotifications: true,
        });
        const res = await myFetch(`/chat-notifications/my-all-mentions`, { method: "get" })
        if (res.success) {
          console.log(res.arrayNotification)
          if (res.arrayNotification) {
            const { userFiles } = this.props;
            var projectsToLoadData = [];
            for (const [key, value] of Object.entries(
              res.arrayNotification
            )) {
              if (arrayObjectIndexOf(userFiles, value[0].projectId, "project") === -1) {
                projectsToLoadData.push(value[0].projectId);
              }
            }

            projectsToLoadData = [...new Set(projectsToLoadData)];

            projectsToLoadData.forEach((project) => {
              this.props.getProjectResourcesForDashboard(project, 0);
            });

            if (projectsToLoadData.length) {
              setTimeout(() => {
                this.setState({
                  newChatNotifications: res.arrayNotification,
                });
                if (!fromSocket) this.getAllChatNotifications()
              }, 1000 * projectsToLoadData.length);
            } else {
              this.setState({
                newChatNotifications: res.arrayNotification,
              })
              if (!fromSocket) this.getAllChatNotifications()
            }
          } else {
            if (!fromSocket) this.getAllChatNotifications()
          }
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      localStorage.setItem('lastSocketLoginTime', moment().format('YYYY-MM-DD HH:mm:ss'))
      if (!fromSocket) this.getAllChatNotifications()
    }
  }

  newChatMessage = data => {
    const { user, userFiles } = this.props

    this.props.addNewChatNotification({
      created: data.messageBody.created,
      user_id: user.id,
      is_mentioned: data.isMentionedInMessage,
      was_read: data.wasRead,
      favorite: false,
      fileId: data.fileId,
      fileName: data.fileName,
      filePath: data.fileName,
      fileStatus: data.fileStatus,
      projectId: data.projectId,
      projectCode: data.projectCode,
      createdBy: data.createdBy,
      favorite: data.isFavourite,
      message: data.messageBody.entry
    })

    if (arrayObjectIndexOf(userFiles, data.projectId, "project") === -1) {
      this.props.getProjectResourcesForDashboard(data.projectId, 0);
    }

    if (user.id !== data.creatorId) {
      this.props.addSocketEntry(data.messageBody)
    }

    if (data.isMentionedInMessage && !data.restoringFile) {
      this.getNewMentions(true)
    }
  }

  deleteChatNotification = data => {
    this.props.deleteChatNotification(data.fileId)
  }

  docflowUpdateForFile = data => {
    this.props.getNewFileDocflowInfo(data.fileId, data.projectId)
  }

  gotAccessToFileAfterDocflowChange = data => {
    const { userFilesToVerifi } = this.props
    const projectFilesToVerify = userFilesToVerifi.find(e => e.project === data.projectId)
    if (projectFilesToVerify && projectFilesToVerify.loaded) {
      this.props.getProjectResourcesForDashboard(data.projectId, data.fileId);
    } else {
      this.props.getProjectResourcesForDashboard(data.projectId, 0)
    }
  }

  addAccessToFile = data => {
    this.props.getProjectResourcesForDashboard(data.projectId, 0)
  }

  removeAccessToFile = data => {
    this.props.removeAccessToFile(data.fileId, data.projectId)
  }

  docflowFinishedForFile = data => {
    this.props.docflowFinishedForFile(data.fileId, data.projectId)
  }

  docflowNotification = data => {
    const { newDocflowNotifications } = this.state
    if (newDocflowNotifications) {
      newDocflowNotifications.push(data)
      this.setState({ newDocflowNotifications })
    } else {
      this.setState({ newDocflowNotifications: [data] })
    }
  }

  getAllChatNotifications = () => {
    this.props.getOldChatNotifications(0, true, notifications => {
    })
  }

  registeredEasyGuardDevice = (data) => {
    this.props.registerEasyGuardDevice(data.deviceName);
  };

  logoutAllDevices = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    this.props.logoutAllDevicesSocketHandler();
  };

  newFileUploaded = (data) => {
    if (parseInt(data.senderId) !== parseInt(this.props.user.id)) {
      console.log(
        "received newFileUploaded socket event from other user with id: " +
        data.senderId +
        " " +
        moment().format("HH:mm.ss.SSS")
      );
      console.log(data.senderAcceptancePermission);
      if (
        (this.props.userProjects.find((e) => e.id === data.projectId) &&
          (this.props.userProjects.find((e) => e.id === data.projectId)
            .acceptance_permissions === "USER3" ||
            (this.props.userProjects.find((e) => e.id === data.projectId)
              .acceptance_permissions === "USER2" &&
              data.senderAcceptancePermission !== "USER3"))) ||
        ((parseInt(this.props.user.id) === 224 ||
          parseInt(this.props.user.id) === 304 ||
          parseInt(this.props.user.id) === 362) &&
          (parseInt(data.senderId) === 224 ||
            parseInt(data.senderId) === 304 ||
            parseInt(data.senderId) === 362))
      ) {
        const { userFilesToVerifi } = this.props
        const projectFilesToVerify = userFilesToVerifi.find(e => e.project === data.projectId)
        if (projectFilesToVerify && projectFilesToVerify.loaded) {
          this.props.getFilesToVerifi(data.projectId, data.fileId);
        } else {
          this.props.getProjectResourcesForDashboard(data.projectId, 0)
        }
        this.props.addMessage(
          data.user,
          "upload",
          this.props.userProjects.find((e) => e.id === data.projectId),
          data.fileId,
          data.projectId,
          data.fileName,
          moment()
        );
      }
    } else {
      setTimeout(() => {
        if (!this.checkIfMyFile(data.fileId, "NOT_VERIFIED", data.projectId)) {
          const { userFilesToVerifi } = this.props
          const projectFilesToVerify = userFilesToVerifi.find(e => e.project === data.projectId)
          if (projectFilesToVerify && projectFilesToVerify.loaded) {
            this.props.getFilesToVerifi(data.projectId, data.fileId);
          } else {
            this.props.getProjectResourcesForDashboard(data.projectId, 0)
          }
        }
      }, 5000);
    }
  };

  newPaymentOrder = (data) => {
    console.log('newPaymentOrder func')
    console.log('newPaymentOrder func')
    console.log('newPaymentOrder func')
    console.log('newPaymentOrder func')
    console.log(data);
    this.props.getAllMyTransfers(data.projectId, data.id);
    if (parseInt(data.senderId) !== this.props.user.id) {
      this.props.addMessage(
        data.user,
        "newPaymentOrder",
        this.props.userProjects.find((e) => e.id === data.projectId),
        data.id,
        data.projectId,
        data.paymentName,
        moment()
      );
    }
  };

  edInvoiceUploadSocket = (data) => {
    if (
      this.props.userProjects.find((e) => e.id === data.projectId) &&
      this.props.userProjects.find((e) => e.id === data.projectId)
        .acceptance_permissions !== "USER1"
    ) {
      this.props.getFilesToVerifi(data.projectId, data.fileId);
    }
  };

  moveFileToProject = (data) => {
    if (parseInt(data.senderId) !== parseInt(this.props.user.id)) {
      console.log(
        "received moveFileToProject socket event from other user with id: " +
        data.senderId +
        " " +
        moment().format("HH:mm.ss.SSS")
      );
      if (
        this.props.userProjects.find((e) => e.id === data.oldProjectId) &&
        this.props.userProjects.find((e) => e.id === data.projectId) &&
        this.checkIfMyFile(data.fileId, "NOT_VERIFIED", data.oldProjectId)
      ) {
        console.log("handling acceptOCRFile func");
        this.props.addMessage(
          data.user,
          "moveFileToProject",
          this.props.userProjects.find((e) => e.id === data.projectId),
          data.fileId,
          data.projectId,
          data.fileName,
          moment(),
          null,
          this.props.userProjects.find((e) => e.id === data.oldProjectId)
        );

        this.props.getFilesToVerifi(data.projectId, data.fileId);

        data.projectId = data.oldProjectId;
        data.fileStep = "NOT_VERIFIED";
        this.props.deleteInvoiceFromRedux(data);
        if (
          this.props.location.pathname.includes(
            `ocr/${data.projectId}/${data.fileName}`
          )
        ) {
          this.props.showOcrScreenNotificationAboutMovedFile(data.user);
        }
      } else if (
        !this.props.userProjects.find((e) => e.id === data.oldProjectId) &&
        this.props.userProjects.find((e) => e.id === data.projectId) &&
        (this.props.userProjects.find((e) => e.id === data.projectId)
          .acceptance_permissions === "USER3" ||
          (this.props.userProjects.find((e) => e.id === data.projectId)
            .acceptance_permissions === "USER2" &&
            data.senderAcceptancePermission !== "USER3"))
      ) {
        this.props.addMessage(
          data.user,
          "upload",
          this.props.userProjects.find((e) => e.id === data.projectId),
          data.fileId,
          data.projectId,
          data.fileName,
          moment()
        );
        this.props.getFilesToVerifi(data.projectId, data.fileId);
      }
    } else {
      console.log("received moveFileToProject socket event that i have done");
    }
  };

  setNewDocType = (data) => {
    if (parseInt(data.senderId) !== parseInt(this.props.user.id)) {
      console.log(
        "received setNewDocType socket event from other user with id: " +
        data.senderId +
        " " +
        moment().format("HH:mm.ss.SSS")
      );
      if (
        this.props.userProjects.find((e) => e.id === data.projectId) &&
        this.checkIfMyFile(data.fileId, "NOT_VERIFIED", data.projectId)
      ) {
        console.log("handling acceptOCRFile func");
        this.props.changeDocType(
          data.fileId,
          data.projectId,
          data.type,
          data.user
        );
        this.props.addMessage(
          data.user,
          "setDocType",
          this.props.userProjects.find((e) => e.id === data.projectId),
          data.fileId,
          data.projectId,
          data.fileName,
          moment(),
          data.type
        );
      }
    } else {
      console.log("received setDocType socket event that i have done");
      if (
        this.props.userProjects.find((e) => e.id === data.projectId) &&
        this.checkIfMyFile(data.fileId, "NOT_VERIFIED", data.projectId)
      ) {
        console.log("handling acceptOCRFile func");
        this.props.changeDocType(
          data.fileId,
          data.projectId,
          data.type,
          data.user
        );
      }
    }
  };

  setNewExpenseOwner = (data) => {
    if (parseInt(data.senderId) !== parseInt(this.props.user.id)) {
      console.log(
        "received setNewExpenseOwner socket event from other user with id: " +
        data.senderId +
        " " +
        moment().format("HH:mm.ss.SSS")
      );
      if (
        this.props.userProjects.find((e) => e.id === data.projectId) &&
        this.checkIfMyFile(data.fileId, "NOT_VERIFIED", data.projectId)
      ) {
        this.props.changeExpenseOwner(
          data.fileId,
          data.projectId,
          data.newOwnerId,
          data.newOwnerName
        );
        this.props.addMessage(
          data.user,
          "setExpenseOwner",
          this.props.userProjects.find((e) => e.id === data.projectId),
          data.fileId,
          data.projectId,
          data.fileName,
          moment(),
          data.type
        );
      }
    } else {
      console.log("received setDocType socket event that i have done");
    }
  };

  removeFileFromDocflow = (data) => {
    if (
      this.props.userProjects.find((e) => e.id === data.projectId)
    ) {
      this.props.removeFileFromDocflowSecketEvent(data.fileId, data.projectId)
    }
  }

  movedOldFileToTrash = (data) => {
    if (parseInt(data.senderId) !== parseInt(this.props.user.id)) {
      console.log(
        "received movedOldFileToTrash socket event from other user with id: " +
        data.senderId +
        " " +
        moment().format("HH:mm.ss.SSS")
      );
      if (
        this.props.userProjects.find((e) => e.id === data.projectId) &&
        this.checkIfMyFile(data.fileId, data.fileStep, data.projectId)
      ) {
        console.log("handling movedOldFileToTrash func");
        this.props.addMessage(
          data.user,
          "moveToTrash",
          this.props.userProjects.find((e) => e.id === data.projectId),
          data.fileId,
          data.projectId,
          data.fileName,
          moment()
        );

        this.props.moveDocumentToTrash(
          data.projectId,
          data.fileId,
          data.fileStep,
          data.fileName,
          data.user,
          this.props.userProjects.find((e) => e.id === data.projectId).code
        );

        if (
          this.props.location.pathname.includes(
            `ocr/${data.projectId}/${data.fileName}`
          )
        ) {
          this.props.showOcrScreenNotificationAboutDeletedFile(data.user);
        }
      }
    } else {
      console.log("received movedOldFileToTrash socket event that i have done");
    }
  };

  restoredOldFile = (data) => {
    if (parseInt(data.senderId) !== parseInt(this.props.user.id)) {
      console.log(
        "received restoredOldFile socket event from other user with id: " +
        data.senderId +
        " " +
        moment().format("HH:mm.ss.SSS")
      );
      if (
        this.props.userProjects.find((e) => e.id === data.projectId) &&
        this.checkIfMyFile(data.fileId, data.fileStep, data.projectId)
      ) {
        console.log("handling restoredOldFile func");
        this.props.addMessage(
          data.user,
          "restored",
          this.props.userProjects.find((e) => e.id === data.projectId),
          data.fileId,
          data.projectId,
          data.fileName,
          moment()
        );

        this.props.restoreDocumentFromTrash(
          data.projectId,
          data.fileId,
          data.fileStep,
          data.fileName,
          data.user,
          this.props.userProjects.find((e) => e.id === data.projectId).code
        );

        if (
          this.props.location.pathname.includes(
            `ocr/${data.projectId}/${data.fileName}`
          )
        ) {
          this.props.showOcrScreenNotificationAboutDeletedFile(data.user);
        }
      }
    } else {
      console.log("received restoredOldFile socket event that i have done");
    }
  };

  deletedOldFilePermanent = (data) => {
    if (parseInt(data.senderId) !== parseInt(this.props.user.id)) {
      console.log(
        "received deletedOldFilePermanent socket event from other user with id: " +
        data.senderId +
        " " +
        moment().format("HH:mm.ss.SSS")
      );
      if (
        this.props.userProjects.find((e) => e.id === data.projectId) &&
        this.checkIfMyFile(data.fileId, data.fileStep, data.projectId)
      ) {
        console.log("handling deletedOldFilePermanent func");
        this.props.addMessage(
          data.user,
          "deletePermanent",
          this.props.userProjects.find((e) => e.id === data.projectId),
          data.fileId,
          data.projectId,
          data.fileName,
          moment()
        );
        this.props.deleteInvoiceFromRedux(data);

        if (
          this.props.location.pathname.includes(
            `ocr/${data.projectId}/${data.fileName}`
          )
        ) {
          this.props.showOcrScreenNotificationAboutDeletedFile(data.user);
        }
      }
    } else {
      console.log(
        "received deletedOldFilePermanent socket event that i have done"
      );
    }
  };

  deletedOldFilePermanentAutomaticly = (data) => {
    console.log("deletedOldFilePermanentAutomaticly func");
    console.log(data);
    if (
      this.props.userProjects.find((e) => e.id === data.projectId) &&
      this.checkIfMyFile(data.fileId, data.status, data.projectId)
    ) {
      console.log("handling deletedOldFilePermanentAutomaticly func");
      this.props.deleteInvoiceFromRedux(data);

      if (
        this.props.location.pathname.includes(
          `ocr/${data.projectId}/${data.fileName}`
        )
      ) {
        this.props.showOcrScreenNotificationAboutDeletedFile(data.user);
      }
    }
  };

  markDocAsPaid = (data) => {
    console.log(this.props.userProjects);
    console.log(data);

    if (
      this.props.userProjects.find((e) => e.id === data.projectId) &&
      this.checkIfMyFile(data.fileId, data.fileStep, data.projectId)
    ) {
      console.log("handling markDocAsPaid func");
      if (parseInt(data.senderId) !== parseInt(this.props.user.id)) {
        this.props.addMessage(
          data.user,
          "markedAsPaid",
          this.props.userProjects.find((e) => e.id === data.projectId),
          data.fileId,
          data.projectId,
          data.fileName,
          moment()
        );
      }
      this.props.markFileAsPaid(data);
    }
  };

  markPaymentOrderAsPaid = (data) => {
    if (
      this.props.userProjects.find((e) => e.id === data.projectId) &&
      this.checkIfMyPaymentOrder(data.paymentOrderId, data.projectId)
    ) {
      this.props.markPaymentOrderAsPaid(data);
    }
  };

  docAdditionalAcceptance = (data) => {
    if (parseInt(data.senderId) !== parseInt(this.props.user.id)) {
      console.log(
        "received markDocAsPaid socket event from other user with id: " +
        data.senderId +
        " " +
        moment().format("HH:mm.ss.SSS")
      );
      if (
        this.props.userProjects.find((e) => e.id === data.projectId) &&
        this.checkIfMyFile(data.fileId, data.fileStep, data.projectId)
      ) {
        console.log("handling markDocAsPaid func");
        this.props.addMessage(
          data.user,
          "additionalAcceptance",
          this.props.userProjects.find((e) => e.id === data.projectId),
          data.fileId,
          data.projectId,
          data.fileName,
          moment()
        );
        this.props.markAdditionalAccepted(data);
      }
    } else {
      console.log("received deletedOldFile socket event that i have done");
    }
  };

  submitOCRFile = (data) => {
    if (parseInt(data.senderId) !== parseInt(this.props.user.id)) {
      console.log(
        "received submitOCRFile socket event from other user with id: " +
        data.senderId +
        " " +
        moment().format("HH:mm.ss.SSS")
      );
      if (
        this.props.userProjects.find((e) => e.id === data.projectId) &&
        this.checkIfMyFile(data.fileId, "NOT_VERIFIED", data.projectId)
      ) {
        console.log("handling submitOCRFile func");
        this.props.addMessage(
          data.user,
          "submited",
          this.props.userProjects.find((e) => e.id === data.projectId),
          data.fileId,
          data.projectId,
          data.fileName,
          moment()
        );

        if (
          this.props.location.pathname.includes(
            `ocr/${data.projectId}/${data.fileName}`
          )
        ) {
          this.props.changeFileStatusToNotAccepted(data, true, data.user);
        } else {
          this.props.changeFileStatusToNotAccepted(data, false, "");
        }
      }
    } else {
      console.log("received submitOCRFile socket event that i have done");
    }
  };

  acceptOCRFile = (data) => {
    if (parseInt(data.senderId) !== parseInt(this.props.user.id)) {
      console.log(
        "received acceptOCRFile socket event from other user with id: " +
        data.senderId +
        " " +
        moment().format("HH:mm.ss.SSS")
      );
      if (
        this.props.userProjects.find((e) => e.id === data.projectId) &&
        this.checkIfMyFile(data.fileId, "NOT_VERIFIED", data.projectId)
      ) {
        console.log("handling acceptOCRFile func");
        var projectIndex = arrayObjectIndexOf(
          this.props.userFilesToVerifi,
          data.projectId,
          "project"
        );
        console.log("projectIndex: " + projectIndex);
        if (projectIndex > -1) {
          var fileIndex = arrayObjectIndexOf(
            this.props.userFilesToVerifi[projectIndex].files,
            data.fileId,
            "dbId"
          );
          console.log("fileIndex: " + fileIndex);
          if (fileIndex > -1) {
            console.log(
              "file name: " +
              this.props.userFilesToVerifi[projectIndex].files[fileIndex].name
            );
            if (
              this.props.location.pathname.includes(
                `ocr/${data.projectId}/${this.props.userFilesToVerifi[projectIndex].files[fileIndex].name}`
              )
            ) {
              this.props.changeFileStatusToAccepted(data, true, data.user);
            } else {
              this.props.changeFileStatusToAccepted(data, false, "");
            }
          } else {
            this.props.changeFileStatusToAccepted(data, false, "");
          }
        } else {
          this.props.changeFileStatusToAccepted(data, false, "");
        }
        this.props.getFiles("", data.projectId, data.fileId);
        this.props.addMessage(
          data.user,
          "accepted",
          this.props.userProjects.find((e) => e.id === data.projectId),
          data.fileId,
          data.projectId,
          data.fileName,
          moment()
        );
      }
    } else {
      console.log("received acceptOCRFile socket event that i have done");
    }
  };

  createdNewReport = (data) => {
    if (parseInt(data.senderId) !== parseInt(this.props.user.id)) {
      const { docsDownloadedForRaport } = this.props;
      if (docsDownloadedForRaport.length > 0) {
        var exampleDoc = docsDownloadedForRaport[0];

        if (exampleDoc.projectId === data.projectId) {
          this.props.markDocsAsInReport(
            data.docs,
            data.reportName,
            data.reportId
          );
        }
      }
    } else {
      console.log("received createNewReport socket event that i have done");
    }
  };

  createdNewTransfersFile = (data) => {
    if (parseInt(data.senderId) !== parseInt(this.props.user.id)) {
      const { docsDownloadedForRaport } = this.props;
      if (docsDownloadedForRaport.length > 0) {
        var exampleDoc = docsDownloadedForRaport[0];

        if (exampleDoc.projectId === data.projectId) {
          this.props.markDocsAsInBankTransfersFile(data.docs, data.fileName);
        }
      }
    } else {
      console.log(
        "received createNewTransfersFile socket event that i have done"
      );
    }
  };

  checkIfMyFile = (fileId, status, projectId) => {
    if (status === "NOT_VERIFIED" || status === "NEW") {
      console.log("fileStatus not done");
      var projectIndex = arrayObjectIndexOf(
        this.props.userFilesToVerifi,
        projectId,
        "project"
      );
      if (projectIndex > -1) {
        console.log("found project");
        if (
          this.props.userFilesToVerifi[projectIndex].files.find(
            (e) => e && e.dbId === fileId
          )
        ) {
          console.log("found file");
          return true;
        } else {
          console.log("file not found");
          return false;
        }
      } else {
        return false;
      }
    } else {
      console.log("fileStatus done");
      var projectIndex = arrayObjectIndexOf(
        this.props.userFiles,
        projectId,
        "project"
      );
      if (projectIndex > -1) {
        console.log("found project");
        if (
          this.props.userFiles[projectIndex].files.find(
            (e) => e && e.id === fileId
          )
        ) {
          console.log("found project");
          return true;
        } else {
          console.log("file not found");
          return false;
        }
      } else {
        return false;
      }
    }
  };

  checkIfMyPaymentOrder = (transferId, projectId) => {
    var projectIndex = arrayObjectIndexOf(
      this.props.userTransfers,
      projectId,
      "project"
    );
    if (projectIndex > -1) {
      console.log("found project");
      if (
        this.props.userTransfers[projectIndex].transfers.find(
          (e) => e.id === transferId
        )
      ) {
        console.log("found file");
        return true;
      } else {
        console.log("file not found");
        return false;
      }
    } else {
      return false;
    }
  };

  handleDeleteProject = (data) => {
    if (parseInt(data.senderId) !== parseInt(this.props.user.id)) {
      this.props.getUserProjects();
    }
  };

  updateAdditionalAcceptanceForProject = (data) => {
    this.props.getFiles("", data.projectId, 0);
    this.props.getUserProjects();
  };

  loadNewCompanyTags = (data) => {
    this.props.loadNewCompanyTags(data.companyId, data.newTags);
  };

  updateCompanySubscription = (data) => {
    this.props.updateCompanySubscription(
      data.companyId,
      data.subscriptionExpireDate,
      data.role
    );
  };

  getAllCompanies = () => {
    this.props.getUserCompanies(this.props.user.id);
  };

  changeCurrentCompany = (e, index) => {
    e.preventDefault();
    console.log(index);

    this.props.setCurrentCompany(this.props.userCompanies[index]);
  };

  toggleSideMenu = (e) => {
    if (!this.refs.sideBar.classList.contains("expended")) {
      this.refs.sideBar.classList.add("expended");
      this.refs.pageContent.classList.add("menu-expended");
      this.refs.menuExtendIcon.classList.add("menu-expended");

      localStorage.setItem("menuExtended", true);
    } else {
      this.refs.sideBar.classList.remove("expended");
      this.refs.pageContent.classList.remove("menu-expended");
      this.refs.menuExtendIcon.classList.remove("menu-expended");

      localStorage.setItem("menuExtended", false);
    }
  };

  onInputChange = (e) => {
    e.preventDefault();

    console.log("input change trigger");
    console.log(e);
    const { name, value } = e.target;

    this.setState({ [name]: value });
  };

  logout = (e) => {
    e.preventDefault();
    console.log("logout func");
    socket.emit("disconnect");
    socket.off();
    this.props.logout();
  };

  toggleUserProfile = (e) => {
    e.preventDefault();
    this.refs.userProfile.classList.toggle("open");
    //this.refs.underProfileLayer.classList.toggle('open')
  };

  closeUserProfile = (e) => {
    if (!e.target.className.includes("avatar-icon")) {
      this.refs.userProfile.classList.remove("open");
      //this.refs.underProfileLayer.classList.toggle('open')
    }
  };

  getProjectName = (path) => {
    let subDirs = path.split("/");
    if (subDirs.length > 2) {
      let projectId = parseInt(subDirs[2]);
      let project = this.props.userProjects.find((e) => e.id === projectId);
      if (project) {
        return project.code;
      } else {
        return "undefined";
      }
    } else {
      return "undefined";
    }
  };

  getCompanyName = (path) => {
    let subDirs = path.split("/");
    if (subDirs.length > 2) {
      let projectId = parseInt(subDirs[2]);
      let project = this.props.userProjects.find((e) => e.id === projectId);
      if (project) {
        let company = this.props.userCompanies.find(
          (e) => e.company_id === parseInt(project.company_id)
        );
        if (company) {
          return company.company.code;
        } else {
          return "undefined";
        }
      } else {
        return "undefined";
      }
    } else {
      return "undefined";
    }
  };

  showMessages = (e) => {
    e.preventDefault();

    if (!this.state.showMessages) {
      console.log("show messages true");
      this.setState({
        showMessages: true,
      });
    }
  };

  isDescendant = (parent, child) => {
    var node = child.parentNode;
    while (node != null) {
      if (node == parent) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  };

  formatStorageLimit = (limit) => {
    if (limit || limit === 0) {
      limit = limit / 1024 / 1024;
      limit = limit.toFixed(2);
      if (limit.indexOf(".") > -1) {
        var parts = limit.split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        limit = parts.join(".");
      } else {
        limit = limit.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      }

      return limit;
    } else {
      return "--";
    }
  };

  closeExpiringDueDateDocsModal = () => {
    this.setState({
      expiringDueDateDocs: null
    })
  }


  render() {
    const { location, currentCompany, messagesCount, chatNotificationsCount, showCreateTransferOrderWindow, hasCompanyWithoutCode, currentProject, user } = this.props
    const { whatsNew, highPriorityDocs, newChatNotifications, newDocflowNotifications, expiringDueDateDocs } = this.state
    if (currentCompany) user.companyRole = currentCompany.user_role

    return (
      <>
        {whatsNew && user.whatsNewVersion !== whatsNew.id && <WhatsNewPopup whatsNew={whatsNew} />}
        {highPriorityDocs && <HighPriorityModal docs={highPriorityDocs} onClose={() => this.setState({ highPriorityDocs: null })} />}
        {expiringDueDateDocs && <ExpiringDueDateDocsModal docs={expiringDueDateDocs} onClose={() => this.closeExpiringDueDateDocsModal()} />}
        {
          newChatNotifications && (
            <ChatNotificationsPopup
              newChatNotifications={newChatNotifications || []}
              onClose={() => this.setState({ newChatNotifications: null })}
            />
          )
        }

        {
          newDocflowNotifications && (
            <DocflowNotificationsPopup
              newDocflowNotifications={newDocflowNotifications || []}
              onClose={() => this.setState({ newDocflowNotifications: null })}
            />
          )
        }

        {
          window.innerWidth < 1000 && (isAndroid || isIOS) ? (
            <DownloadMobileAppPage isAndroid={isAndroid} isIOS={isIOS} />
          ) : null
        }

        <Helmet defer={false}>
          {messagesCount + chatNotificationsCount > 0 ? (
            <title>{`(${messagesCount + chatNotificationsCount}) EasyDocs`}</title>
          ) : (
            <title>EasyDocs</title>
          )}
        </Helmet>

        {
          showCreateTransferOrderWindow ? (
            <CreateTransferOrderWindow />
          ) : (
            null
          )
        }

        {
          user.new_easydocs_version ? (
            <>
              <TopToolbar
                history={this.props.history}
                location={location}
                socket={socket}
              />
              <V2_UserPanel
                history={this.props.history}
                location={this.props.location}
              />
            </>
          ) : (
            <div className={`panel`}>
              <div className={`sidebar-wrapper ${((localStorage.getItem('menuExtended') === 'true' || !localStorage.getItem('menuExtended')) && !window.location.href.includes('/ocr/')) ? 'expended' : ''}`} ref="sideBar">
                <TopToolbar
                  history={this.props.history}
                  location={location}
                  socket={socket}
                />
                <div className="menu-wrapper">
                  <ul className="sidebar nav main">
                    <li className="nav-item" data-for="menu-item-tooltip-dashboard" data-tip="show">
                      <NavLink className="nav-link" to={routes.panel.dashboard}>
                        <span className="nav-icon home-icon"></span>
                        <p>{this.context.t('Dashboard')}</p>
                      </NavLink>
                    </li>
                    <ReactTooltip id="menu-item-tooltip-dashboard" place="right" effect="solid" className={`default-tooltip menu-item`}>
                      <span>{this.context.t('Dashboard')}</span>{` - ${this.context.t('home screen')}`}
                    </ReactTooltip>

                    <li className="nav-item" data-for="menu-item-tooltip-docs" data-tip="show">
                      <NavLink className="nav-link" to={routes.panel.fileManager}>
                        <span className="nav-icon file-icon"></span>
                        <p>{this.context.t('Docs')}</p>
                      </NavLink>
                    </li>
                    <ReactTooltip id="menu-item-tooltip-docs" place="right" effect="solid" className={`default-tooltip menu-item`}>
                      <span>{this.context.t('Docs')}</span>{` - ${this.context.t('all documents in EasyDcos')}`}
                    </ReactTooltip>

                    <li className="nav-item" data-for="menu-item-tooltip-sales" data-tip="show">
                      <NavLink className="nav-link" to={routes.panel.sale}>
                        <span className="nav-icon sale-icon"></span>
                        <p>{this.context.t('Sales')}</p>
                      </NavLink>
                    </li>
                    <ReactTooltip id="menu-item-tooltip-sales" place="right" effect="solid" className={`default-tooltip menu-item`}>
                      <span>{this.context.t('Sales')}</span>{` - ${this.context.t('giving invoices')}`}
                    </ReactTooltip>

                    <li className="nav-item" data-for="menu-item-tooltip-reports" data-tip="show">
                      <NavLink className="nav-link" to={routes.panel.actions}>
                        <span className="nav-icon reports-icon"></span>
                        <p>{this.context.t('Actions')}</p>
                      </NavLink>
                    </li>
                    <ReactTooltip id="menu-item-tooltip-reports" place="right" effect="solid" className={`default-tooltip menu-item`}>
                      <span>{this.context.t('Actions')}</span>{` - ${this.context.t('statements and bank transfers')}`}
                    </ReactTooltip>

                    <li className="nav-item" data-for="menu-item-tooltip-calendar" data-tip="show">
                      {/* <AvailableInOtherPlanLabel planName='PRO+' labelSize="LABEL-SMALL" company={null} checkAllCompanies={true} /> */}
                      <NavLink className="nav-link" to={routes.panel.calendar}>
                        <span className="nav-icon calendar-icon"></span>
                        <p>{this.context.t('Calendar')}</p>
                      </NavLink>
                    </li>
                    <ReactTooltip id="menu-item-tooltip-calendar" place="right" effect="solid" className={`default-tooltip menu-item`}>
                      <span>{this.context.t('Calendar')}</span>{` - ${this.context.t('your tasks')}`}
                    </ReactTooltip>

                    <li className="nav-item" data-for="menu-item-tooltip-settings" data-tip="show">
                      <NavLink className="nav-link" to={routes.panel.settings.profile}>
                        <span className="nav-icon cog-icon"></span>
                        <p>{this.context.t('Settings')}</p>
                      </NavLink>
                    </li>
                    <ReactTooltip id="menu-item-tooltip-settings" place="right" effect="solid" className={`default-tooltip menu-item`}>
                      <span>{this.context.t('Settings')}</span>{` - ${this.context.t('user profile')}`}
                    </ReactTooltip>


                    {
                      currentCompany && currentCompany.user_role === 'ADMIN' ? (
                        <li className="separator"></li>
                      ) : (
                        null
                      )
                    }

                    {
                      currentCompany && currentCompany.user_role === 'ADMIN' ? (
                        <>
                          <li data-for="menu-item-tooltip-company" data-tip="show">
                            <NavLink className="nav-link" to={routes.panel.settings.company}>
                              <span className="nav-icon company-icon"></span>
                              <p>{this.context.t('Company')}</p>
                            </NavLink>
                          </li>
                          <ReactTooltip id="menu-item-tooltip-company" place="right" effect="solid" className={`default-tooltip menu-item`}>
                            <span>{this.context.t('Company')}</span>{` - ${this.context.t('company parameters')}`}
                          </ReactTooltip>
                        </>
                      ) : (
                        null
                      )
                    }
                    {
                      currentCompany && currentCompany.user_role === 'ADMIN' ? (
                        <>
                          <li data-for="menu-item-tooltip-projects" data-tip="show">
                            <NavLink className="nav-link" to={routes.panel.settings.projects}>
                              <span className="nav-icon projects-icon"></span>
                              <p>{this.context.t('Binders')}</p>
                            </NavLink>
                          </li>
                          <ReactTooltip id="menu-item-tooltip-projects" place="right" effect="solid" className={`default-tooltip menu-item`}>
                            <span>{this.context.t('Binders')}</span>{` - ${this.context.t('document groups and accesses')}`}
                          </ReactTooltip>
                        </>
                      ) : (
                        null
                      )
                    }
                    {
                      currentCompany && currentCompany.user_role === 'ADMIN' ? (
                        <>
                          <li data-for="menu-item-tooltip-counterparties" data-tip="show">
                            <NavLink className="nav-link" to={routes.panel.settings.counterparties}>
                              <span className="nav-icon counterparties-icon"></span>
                              <p>{this.context.t('Counterparties')}</p>
                            </NavLink>
                          </li>
                          <ReactTooltip id="menu-item-tooltip-counterparties" place="right" effect="solid" className={`default-tooltip menu-item`}>
                            <span>{this.context.t('Counterparties')}</span>{` - ${this.context.t('the company\'s customers and suppliers')}`}
                          </ReactTooltip>
                        </>
                      ) : (
                        null
                      )
                    }
                    {
                      currentCompany && currentCompany.user_role === 'ADMIN' ? (
                        <>
                          <li data-for="menu-item-tooltip-subscription" data-tip="show">
                            <NavLink className="nav-link" to={routes.panel.settings.subscription}>
                              <span className="nav-icon subscription-icon"></span>
                              <p>{this.context.t('Subscription')}</p>
                            </NavLink>
                          </li>
                          <ReactTooltip id="menu-item-tooltip-subscription" place="right" effect="solid" className={`default-tooltip menu-item`}>
                            <span>{this.context.t('Subscription')}</span>{` - ${this.context.t('EasyDocs payments')}`}
                          </ReactTooltip>
                        </>
                      ) : (
                        null
                      )
                    }

                    {
                      user.role === "SUPERADMIN" || user.id === 417 ? (
                        <li className="separator"></li>
                      ) : (
                        null
                      )
                    }
                    {
                      user.role === "SUPERADMIN" || user.id === 417 ? (
                        <li>
                          <NavLink className="nav-link" to={routes.panel.superAdmin.management}>
                            <span className="nav-icon superadmin-icon"></span>
                            <p>{this.context.t('SuperAdmin')}</p>
                          </NavLink>
                        </li>
                      ) : (
                        null
                      )
                    }
                  </ul>
                  <ul className="sidebar nav">
                    <li className="help" data-for="menu-item-tooltip-help" data-tip="show">
                      <NavLink className="nav-link" to={routes.panel.help}>
                        <span className="nav-icon help-icon"></span>
                        <p>{this.context.t('Helpdesk')}</p>
                      </NavLink>
                    </li>
                    <ReactTooltip id="menu-item-tooltip-help" place="right" effect="solid" className={`default-tooltip menu-item`}>
                      <span>{this.context.t('Helpdesk')}</span>{` - ${this.context.t('help and FAQ')}`}
                    </ReactTooltip>

                    <li className="extend" onClick={e => this.toggleSideMenu(e)} data-for="menu-item-tooltip-hide" data-tip="show">
                      <a className="nav-link">
                        <span className="extend-icon nav-icon" ref="menuExtendIcon"></span>
                        <p>{this.context.t('Hide')}</p>
                      </a>
                    </li>
                    <ReactTooltip id="menu-item-tooltip-hide" place="right" effect="solid" className={`default-tooltip menu-item`}>
                      {`${this.context.t('Collapsing the menu')}`}
                    </ReactTooltip>

                    <li className="logout" onClick={(e) => this.logout(e)} data-for="menu-item-tooltip-logout" data-tip="show">
                      <a className="nav-link">
                        <span className="logout-icon nav-icon"></span>
                        <p>{this.context.t('Logout')}</p>
                      </a>
                    </li>
                    <ReactTooltip id="menu-item-tooltip-logout" place="right" effect="solid" className={`default-tooltip menu-item`}>
                      {`${this.context.t('Logging out of EasyDocs')}`}
                    </ReactTooltip>
                  </ul>
                </div>
              </div>

              <div className={`page-content-wrapper ${((localStorage.getItem('menuExtended') === 'true' || !localStorage.getItem('menuExtended')) && !window.location.href.includes('/ocr/')) ? 'menu-expended' : ''}`} ref="pageContent">

                {
                  !user.finished_admin_onboarding && user.companyRole === 'ADMIN' ? (
                    <AdminOnboarding />
                  ) : (
                    null
                  )
                }

                <TransitionGroup className="page">
                  <CSSTransition key={location.key} timeout={450} appear={true} classNames="scale-fade">
                    <Switch location={location}>
                      <PrivateRoute path={routes.panel.dashboard} component={DashboardPage} userData={user} />

                      <PrivateRoute path={routes.panel.help} component={HelpPage} userData={user} />
                      <PrivateRoute path={routes.panel.crop} component={OcrPage} userData={user} />
                      <PrivateRoute path={routes.panel.fileManager} component={FileManagerPage} userData={user} />
                      <PrivateRoute path={routes.panel.sale} component={SalesPage} userData={user} />
                      <PrivateRoute path={routes.panel.calendar} component={CalendarPage} userData={user} />
                      <PrivateRoute path={routes.panel.actions} component={Reports} userData={user} />
                      <PrivateRoute path={routes.panel.settings.profile} component={SettingsPage} userData={user} />

                      <PrivateRoute path={routes.panel.settings.company} component={CompanyPage} userData={user} />
                      <PrivateRoute path={routes.panel.settings.projects} component={ProjectsManagement} userData={user} />
                      <PrivateRoute path={routes.panel.settings.counterparties} component={CounterpartiesList} userData={user} />
                      <PrivateRoute path={routes.panel.settings.subscription} component={Subscription} userData={user} />

                      <PrivateRoute path={routes.panel.superAdmin.management} component={SuperadminManagement} userData={user} />
                    </Switch>
                  </CSSTransition>
                </TransitionGroup>

                <ReactTooltip id="info-context-overlay" place="left" effect="solid" className="default-tooltip info-tooltip" delayShow={500}>
                  <p>{this.context.t("Contextual help")}</p>
                </ReactTooltip>
              </div>
            </div >
          )
        }
      </>
    )
  }
}

UserPanel.contextTypes = {
  t: PropTypes.func,
};

const mapStateToProps = (state) => ({
  userCompanies: state.Company.companies,
  currentCompany: state.Company.currentCompany,
  currentProject: state.User.currentProject,
  userProjects: isIterable(state.User.userProjects)
    ? [...state.User.userProjects]
    : [],
  viewMode: state.User.dashboardViewModel,
  companyNotification: state.Company.companyNotification,
  firstLogin: state.User.firstLogin,
  user: state.User.user || {},
  highestAcceptancePermission: state.User.highestAcceptancePermission,
  messagesCount: state.Messages.unreadCount,
  chatNotificationsCount: state.Chat.chatNotificationsCount,

  transferPreviewOpen: state.Transfers.transferPreviewOpen,
  showCreateTransferOrderWindow: state.Transfers.showCreateTransferOrderWindow,

  userFiles: state.File.uploadedFiles,
  userFilesToVerifi: state.File.filesToVerifiLoaded,
  userTransfers: state.Transfers.transfers,

  showRefreshOcrPopup: state.File.showRefreshOcrPopup,

  //variables to set companyCode on first login
  hasCompanyWithoutCode: state.Company.hasCompanyWithoutCode,

  //turn on overlay
  overlayPreferance: state.User.overlayPreferance || {},
  docsDownloadedForRaport: state.Reports.docsDownloadedForRaport
    ? [...state.Reports.docsDownloadedForRaport]
    : [],
  reportsSearch: state.User.reportsSearch,
  gotDocumentsResponse: state.Reports.gotDocumentsResponse,
});

const mapDispatchToProps = {
  //functions for socket
  getFilesToVerifi: FileActions.getFilesToVerifi,
  getProjectResourcesForDashboard: FileActions.getProjectResourcesForDashboard,
  loadNewFileToVerifyData: FileActions.loadNewFileToVerifyData,
  getAllMyTransfers: TransfersActions.getAllMyTransfers,
  deleteInvoiceFromRedux: FileActions.deleteInvoiceFromRedux,
  markFileAsPaid: FileActions.markFileAsPaid,
  markPaymentOrderAsPaid: TransfersActions.markPaymentOrderAsPaid,
  markAdditionalAccepted: FileActions.markAdditionalAccepted,
  changeFileStatusToNotAccepted: FileActions.changeFileStatusToNotAccepted,
  changeFileStatusToAccepted: FileActions.changeFileStatusToAccepted,
  getFiles: FileActions.getFiles,
  changeDocType: FileActions.changeDocType,
  changeExpenseOwner: FileActions.changeExpenseOwner,
  setFileToVerifiHighPriority: FileActions.setFileToVerifiHighPriority,
  loadNewCompanyTags: CompanyActions.loadNewCompanyTags,
  updateCompanySubscription: CompanyActions.updateCompanySubscription,
  updateKsefStatusFromCron: FileActions.updateKsefStatusFromCron,
  getNewFileDocflowInfo: FileActions.getNewFileDocflowInfo,
  docflowFinishedForFile: FileActions.docflowFinishedForFile,
  removeAccessToFile: FileActions.removeAccessToFile,

  changeLanguage: UserActions.changeLanguage,
  switchDashboardViewModel: UserActions.switchDashboardViewModel,

  getUserCompanies: CompanyActions.getUserCompanies,
  setCurrentCompany: CompanyActions.setCurrentCompany,
  changePassword: UserActions.changePassword,
  logout: UserActions.logout,
  alertWarn: AlertActions.warning,

  getUserProjects: UserActions.getUserProjects,
  handleDeleteProjectSocketAcction: UserActions.handleDeleteProjectSocketAcction,
  markDocsAsInReport: ReportsActions.markDocsAsInReport,
  markDocsAsInBankTransfersFile: ReportsActions.markDocsAsInBankTransfersFile,
  getDocuments: ReportsActions.getDocuments,
  removeFileFromDocflowSecketEvent: FileActions.removeFileFromDocflowSecketEvent,
  moveDocumentToTrash: FileActions.moveDocumentToTrash,
  restoreDocumentFromTrash: FileActions.restoreDocumentFromTrash,
  showOcrScreenNotificationAboutDeletedFile: FileActions.showOcrScreenNotificationAboutDeletedFile,
  showOcrScreenNotificationAboutMovedFile: FileActions.showOcrScreenNotificationAboutMovedFile,
  addMessage: MessagesActions.addMessage,
  markMessageAsRead: MessagesActions.markMessageAsRead,
  markAllMessagesAsRead: MessagesActions.markAllAsRead,
  toggleOverlay: UserActions.toggleOverlay,
  registerEasyGuardDevice: UserActions.registerEasyGuardDevice,
  enable2FASocketHandler: UserActions.enable2FASocketHandler,
  disable2FASocketHandler: UserActions.disable2FASocketHandler,
  logoutAllDevicesSocketHandler: UserActions.logoutAllDevicesSocketHandler,

  getOldChatNotifications: ChatActions.getOldChatNotifications,
  addNewChatNotification: ChatActions.addNewChatNotification,
  deleteChatNotification: ChatActions.deleteChatNotification,
  addSocketEntry: ChatActions.addSocketEntry
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPanel)
