import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import { Bounce } from 'react-activity'
import DocsAwaitingPayment from './DocsAwaitingPayment'

import arrayObjectIndexOf from '../../helpers/indexOfObject'
import { history } from "../../helpers/history"
import routes from "../../helpers/routes"
import currencyList from '../../helpers/currencyList.json'

import { ProjectCostsActions } from '../../actions/ProjectCostsActions'
import { ReportsActions } from '../../actions/ReportsActions'

export class ProjectCosts extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
        const { projectsCosts, projectId } = this.props
        const index = arrayObjectIndexOf(projectsCosts, projectId, "project")

        if (index > -1 && !projectsCosts[index].sendRequest) {
            this.props.getProjectCosts(projectId)
        }
    }

    handleThisMonthCostsClick = type => {
        var startDate = moment().startOf('month').set('hour', 0).set('minute', 0).set('second', 0),
            endDate = moment().endOf('month').set('hour', 23).set('minute', 59).set('second', 59)
        this.props.getDocuments(startDate, endDate, this.props.projectId, 'Costs', 'issueDate')

        history.push(routes.panel.actions + '?p=' + this.props.projectId + '&m=c&t=' + type[0])
    }

    handleLastMonthCostsClick = type => {
        var startDate = moment().subtract(1, 'month').startOf('month').set('hour', 0).set('minute', 0).set('second', 0),
            endDate = moment().subtract(1, 'month').endOf('month').set('hour', 23).set('minute', 59).set('second', 59)
        this.props.getDocuments(startDate, endDate, this.props.projectId, 'Costs', 'issueDate')

        history.push(routes.panel.actions + '?p=' + this.props.projectId + '&m=p&t=' + type[0])
    }

    formatPrice = price => {
        if (price || price === 0) {
            price = price.toFixed(2)
            if (price.indexOf('.') > -1) {
                var parts = price.split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                price = parts.join('.')
            } else {
                price = price.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }

            return price
        } else {
            return '--'
        }
    }

    checkIfDosIsNotPaid = (file) => {
        if (file && file.accepted_by && file.ocr_status !== 'DONE' && file.path && file.path.includes('Costs/')) return true
    }

    redirectToReports = docsProjectIndex => {
        const { uploadedFiles, project } = this.props
        if (docsProjectIndex > -1 && uploadedFiles[docsProjectIndex].sendRequest && uploadedFiles[docsProjectIndex].loaded) {
            var startDate = moment(), endDate = moment().endOf('month').set('hour', 23).set('minute', 59).set('second', 59)

            var oldestDocDate = null
            uploadedFiles[docsProjectIndex].files.forEach(f => {
                if (this.checkIfDosIsNotPaid(f)) {
                    if (!oldestDocDate || f.created < oldestDocDate) oldestDocDate = f.created
                }
            })

            if (oldestDocDate) {
                startDate = moment(oldestDocDate)
                this.props.getDocuments(startDate, endDate, project.id, 'Costs', 'importDate', null, { unpaid: true })
                history.push(routes.panel.actions + `?p=${project.id}&d=${moment(startDate).format('MM-DD-YYYY')}&t=u`)
            }
        }
    }

    render() {
        const { projectsCosts, projectId } = this.props
        const index = arrayObjectIndexOf(projectsCosts, projectId, "project")

        if (index > -1 && projectsCosts[index].gotResponse && projectsCosts[index].costs && projectsCosts[index].costs.earliestToPay) {
            return (
                <div className={`project-costs ${projectsCosts[index].extended ? 'extended' : ''}`}>
                    <div className="header">
                        <div className="payment-icon" onClick={() => this.redirectToReports(index)}></div>
                        <div className="text" onClick={() => this.redirectToReports(index)}><h2>{this.context.t('Payments to settle')}</h2></div>
                        <div className="extend-button" onClick={() => this.props.extendProjectCosts(projectId)}></div>
                    </div>
                    <div className="content">
                        <DocsAwaitingPayment projectId={projectId} project={this.props.project} />
                    </div>
                </div>
            )
        } else if (index > -1) {
            return (
                <div className={`project-costs ${projectsCosts[index].extended ? 'extended' : ''}`}>
                    <div className="header">
                        <div className="payment-icon"></div>
                        <div className="text"><h2>{this.context.t('Payments to settle')}</h2></div>
                        <div className="extend-button" onClick={() => this.props.extendProjectCosts(projectId)}></div>
                    </div>
                    <div className="content empty">
                        <div className="activity-indicator">
                            <Bounce size={20} speed={0.8} color={"#9099A5"} />
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={`project-costs`}>
                    <div className="header">
                        <div className="payment-icon"></div>
                        <div className="text"><h2>{this.context.t('Payments to settle')}</h2></div>
                    </div>
                </div>
            )
        }
    }
}

ProjectCosts.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
    projectsCosts: [...state.ProjectCosts.projectsCosts],
    uploadedFiles: [...state.File.uploadedFiles],
    transfers: [...state.Transfers.transfers],
})

const mapDispatchToProps = {
    extendProjectCosts: ProjectCostsActions.extendProjectCosts,
    getDocuments: ReportsActions.getDocuments,
    getProjectCosts: ProjectCostsActions.getProjectCosts,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCosts)