const notelProjects = [
    13,     //TP1
    697,    //NOTEL-JDG
    701,    //NOTEL-SPOLKA
]

const notelLevel1 = [
    {
        "description": "Koszty ogólne",
        "code": "MPK1",
        "nextLevel": [
            {
                "description": "Koszty biura",
                "code": "MPK 1.1"
            },
            {
                "description": "Flota",
                "code": "MPK 1.2",
                "nextLevel": [
                    {
                        "description": "Leasing",
                        "code": "MPK 1.2.1"
                    },
                    {
                        "description": "Paliwo",
                        "code": "MPK 1.2.2"
                    },
                    {
                        "description": "Koszty serwisu",
                        "code": "MPK 1.2.3"
                    }
                ]
            },
            {
                "description": "Rachunki - media",
                "code": "MPK 1.3"
            },
            {
                "description": "Materiały biurowe",
                "code": "MPK 1.4"
            }
        ]
    }, {
        "description": "Wynagrodzenia",
        "code": "MPK2"
    }, {
        "description": "Wydatki pozostałe",
        "code": "MPK3",
        "nextLevel": [
            {
                "description": "Wydatki bezfakturowe",
                "code": "MPK 3.1"
            },
            {
                "description": "Opłaty skarbowe",
                "code": "MPK 3.2"
            },
            {
                "description": "Podatki",
                "code": "MPK 3.3"
            },
            {
                "description": "Koszty delegacji",
                "code": "MPK 3.4"
            }
        ]
    }, {
        "description": "DD - Al. Jerozolimskie",
        "code": "MPK10",
        "nextLevel": [
            {
                "description": "Przygotowanie projektu",
                "code": "MPK 10.1"
            },
            {
                "description": "Materiały",
                "code": "MPK 10.2"
            },
            {
                "description": "Robocizna",
                "code": "MPK 10.3"
            },
            {
                "description": "Koszty budowy",
                "code": "MPK 10.4"
            },
            {
                "description": "Zatrzymania czasowe",
                "code": "MPK 10.5"
            },
            {
                "description": "Pozostałe",
                "code": "MPK 10.6"
            }
        ]
    }, {
        "description": "DD - Urbino 1.2",
        "code": "MPK11",
        "nextLevel": [
            {
                "description": "Przygotowanie projektu",
                "code": "MPK 11.1"
            },
            {
                "description": "Materiały",
                "code": "MPK 11.2"
            },
            {
                "description": "Robocizna",
                "code": "MPK 11.3"
            },
            {
                "description": "Koszty budowy",
                "code": "MPK 11.4"
            },
            {
                "description": "Zatrzymania czasowe",
                "code": "MPK 11.5"
            },
            {
                "description": "Pozostałe",
                "code": "MPK 11.6"
            }
        ]
    }, {
        "description": "DD - Urbino 2.1",
        "code": "MPK12",
        "nextLevel": [
            {
                "description": "Przygotowanie projektu",
                "code": "MPK 12.1"
            },
            {
                "description": "Materiały",
                "code": "MPK 12.2"
            },
            {
                "description": "Robocizna",
                "code": "MPK 12.3"
            },
            {
                "description": "Koszty budowy",
                "code": "MPK 12.4"
            },
            {
                "description": "Zatrzymania czasowe",
                "code": "MPK 12.5"
            },
            {
                "description": "Pozostałe",
                "code": "MPK 12.6"
            }
        ]
    }, {
        "description": "DD - Urbino 2.2",
        "code": "MPK13",
        "nextLevel": [
            {
                "description": "Przygotowanie projektu",
                "code": "MPK 13.1"
            },
            {
                "description": "Materiały",
                "code": "MPK 13.2"
            },
            {
                "description": "Robocizna",
                "code": "MPK 13.3"
            },
            {
                "description": "Koszty budowy",
                "code": "MPK 13.4"
            },
            {
                "description": "Zatrzymania czasowe",
                "code": "MPK 13.5"
            },
            {
                "description": "Pozostałe",
                "code": "MPK 13.6"
            }
        ]
    }, {
        "description": "Lab. Sierniewice",
        "code": "MPK14",
        "nextLevel": [
            {
                "description": "Przygotowanie projektu",
                "code": "MPK 14.1"
            },
            {
                "description": "Materiały",
                "code": "MPK 14.2"
            },
            {
                "description": "Robocizna",
                "code": "MPK 14.3"
            },
            {
                "description": "Koszty budowy",
                "code": "MPK 14.4"
            },
            {
                "description": "Zatrzymania czasowe",
                "code": "MPK 14.5"
            },
            {
                "description": "Pozostałe",
                "code": "MPK 14.6"
            }
        ]
    }, {
        "description": "Ksawerów",
        "code": "MPK15",
        "nextLevel": [
            {
                "description": "Przygotowanie projektu",
                "code": "MPK 15.1"
            },
            {
                "description": "Materiały",
                "code": "MPK 15.2"
            },
            {
                "description": "Robocizna",
                "code": "MPK 15.3"
            },
            {
                "description": "Koszty budowy",
                "code": "MPK 15.4"
            },
            {
                "description": "Zatrzymania czasowe",
                "code": "MPK 15.5"
            },
            {
                "description": "Pozostałe",
                "code": "MPK 15.6"
            }
        ]
    }, {
        "description": "Mabud - Goslove",
        "code": "MPK16",
        "nextLevel": [
            {
                "description": "Przygotowanie projektu",
                "code": "MPK 16.1"
            },
            {
                "description": "Materiały",
                "code": "MPK 16.2"
            },
            {
                "description": "Robocizna",
                "code": "MPK 16.3"
            },
            {
                "description": "Koszty budowy",
                "code": "MPK 16.4"
            },
            {
                "description": "Zatrzymania czasowe",
                "code": "MPK 16.5"
            },
            {
                "description": "Pozostałe",
                "code": "MPK 16.6"
            }
        ]
    }, {
        "description": "DAMO",
        "code": "MPK17",
        "nextLevel": [
            {
                "description": "Przygotowanie projektu",
                "code": "MPK 17.1"
            },
            {
                "description": "Materiały",
                "code": "MPK 17.2"
            },
            {
                "description": "Robocizna",
                "code": "MPK 17.3"
            },
            {
                "description": "Koszty budowy",
                "code": "MPK 17.4"
            },
            {
                "description": "Zatrzymania czasowe",
                "code": "MPK 17.5"
            },
            {
                "description": "Pozostałe",
                "code": "MPK 17.6"
            }
        ]
    }, {
        "description": "EC Powiśle",
        "code": "MPK18",
        "nextLevel": [
            {
                "description": "Przygotowanie projektu",
                "code": "MPK 18.1"
            },
            {
                "description": "Materiały",
                "code": "MPK 18.2"
            },
            {
                "description": "Robocizna",
                "code": "MPK 18.3"
            },
            {
                "description": "Koszty budowy",
                "code": "MPK 18.4"
            },
            {
                "description": "Zatrzymania czasowe",
                "code": "MPK 18.5"
            },
            {
                "description": "Pozostałe",
                "code": "MPK 18.6"
            }
        ]
    }, {
        "description": "Harden - Hala Grodzisk",
        "code": "MPK19",
        "nextLevel": [
            {
                "description": "Przygotowanie projektu",
                "code": "MPK 19.1"
            },
            {
                "description": "Materiały",
                "code": "MPK 19.2"
            },
            {
                "description": "Robocizna",
                "code": "MPK 19.3"
            },
            {
                "description": "Koszty budowy",
                "code": "MPK 19.4"
            },
            {
                "description": "Zatrzymania czasowe",
                "code": "MPK 19.5"
            },
            {
                "description": "Pozostałe",
                "code": "MPK 19.6"
            }
        ]
    }, {
        "description": "Harden - Hala Koluszki",
        "code": "MPK20",
        "nextLevel": [
            {
                "description": "Przygotowanie projektu",
                "code": "MPK 20.1"
            },
            {
                "description": "Materiały",
                "code": "MPK 20.2"
            },
            {
                "description": "Robocizna",
                "code": "MPK 20.3"
            },
            {
                "description": "Koszty budowy",
                "code": "MPK 20.4"
            },
            {
                "description": "Zatrzymania czasowe",
                "code": "MPK 20.5"
            },
            {
                "description": "Pozostałe",
                "code": "MPK 20.6"
            }
        ]
    }, {
        "description": "Harden - Hala Natolin",
        "code": "MPK21",
        "nextLevel": [
            {
                "description": "Przygotowanie projektu",
                "code": "MPK 21.1"
            },
            {
                "description": "Materiały",
                "code": "MPK 21.2"
            },
            {
                "description": "Robocizna",
                "code": "MPK 21.3"
            },
            {
                "description": "Koszty budowy",
                "code": "MPK 21.4"
            },
            {
                "description": "Zatrzymania czasowe",
                "code": "MPK 21.5"
            },
            {
                "description": "Pozostałe",
                "code": "MPK 21.6"
            }
        ]
    }, {
        "description": "Stryków",
        "code": "MPK22",
        "nextLevel": [
            {
                "description": "Przygotowanie projektu",
                "code": "MPK 22.1"
            },
            {
                "description": "Materiały",
                "code": "MPK 22.2"
            },
            {
                "description": "Robocizna",
                "code": "MPK 22.3"
            },
            {
                "description": "Koszty budowy",
                "code": "MPK 22.4"
            },
            {
                "description": "Zatrzymania czasowe",
                "code": "MPK 22.5"
            },
            {
                "description": "Pozostałe",
                "code": "MPK 22.6"
            }
        ]
    }, {
        "description": "Sprzeczna",
        "code": "MPK23",
        "nextLevel": [
            {
                "description": "Przygotowanie projektu",
                "code": "MPK 23.1"
            },
            {
                "description": "Materiały",
                "code": "MPK 23.2"
            },
            {
                "description": "Robocizna",
                "code": "MPK 23.3"
            },
            {
                "description": "Koszty budowy",
                "code": "MPK 23.4"
            },
            {
                "description": "Zatrzymania czasowe",
                "code": "MPK 23.5"
            },
            {
                "description": "Pozostałe",
                "code": "MPK 23.6"
            }
        ]
    }, {
        "description": "Kutno",
        "code": "MPK24",
        "nextLevel": [
            {
                "description": "Przygotowanie projektu",
                "code": "MPK 24.1"
            },
            {
                "description": "Materiały",
                "code": "MPK 24.2"
            },
            {
                "description": "Robocizna",
                "code": "MPK 24.3"
            },
            {
                "description": "Koszty budowy",
                "code": "MPK 24.4"
            },
            {
                "description": "Zatrzymania czasowe",
                "code": "MPK 24.5"
            },
            {
                "description": "Pozostałe",
                "code": "MPK 24.6"
            }
        ]
    }, {
        "description": "Fuleda",
        "code": "MPK25",
        "nextLevel": [
            {
                "description": "Przygotowanie projektu",
                "code": "MPK 25.1"
            },
            {
                "description": "Materiały",
                "code": "MPK 25.2"
            },
            {
                "description": "Robocizna",
                "code": "MPK 25.3"
            },
            {
                "description": "Koszty budowy",
                "code": "MPK 25.4"
            },
            {
                "description": "Zatrzymania czasowe",
                "code": "MPK 25.5"
            },
            {
                "description": "Pozostałe",
                "code": "MPK 25.6"
            }
        ]
    }
]


const notelMpkSettings = {
    levelsCount: 3,
    hasDependentSublevels: true,
    level1List: notelLevel1,
    level2List: [],
    level3List: [],
    level4List: [],
}


module.exports = {
    notelProjects,
    notelMpkSettings
};