import React, { Component } from 'react'
import { connect } from 'react-redux'
import $ from 'jquery'
import { UserActions } from '../actions/UserActions'
import { AlertActions } from '../actions/AlertActions'
import { Link, Redirect } from 'react-router-dom'
import routes from '../helpers/routes'
import PropTypes from 'prop-types'
import ReCAPTCHA from "react-google-recaptcha"
import ReactTooltip from 'react-tooltip'
import { Digital } from 'react-activity'
import LanguageSwitcher from '../components/LanguageSwitcher'
import ReactGA from 'react-ga'
import moment from 'moment'

import TextareaAutosize from 'react-textarea-autosize'

import Logo from '../img/easy-docs-logo-big.svg'
import { CompanyActions } from '../actions/CompanyActions'
import myFetch from '../helpers/fetch'
import { history } from "../helpers/history"
import { vatIdValidate } from '../helpers/vatIdValidate'
import { emailRegex } from '../helpers/regexList'

const vatIdRegexList = [
    /^(PL)?\s?[0-9]{2}\s?[0-9]{2}\s?[0-9]{3}\s?[0-9]{3}$/,
    /^(PL)?\s?[0-9]{3}\s?[0-9]{3}\s?[0-9]{2}\s?[0-9]{2}$/,
    /^(DE)?\s?[0-9]{2}\s?[0-9]{3}\s?[0-9]{3}\s?[0-9]{3}$/,
]

export class CompleteCompanyRegistration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fromPage: false,
            step: 1,
            showRest: false,
            nip: '',
            didScrolToShowNipError: false,
            email: '',
            firstName: '',
            lastName: '',
            password: '',
            password2: '',
            representativeCheckbox: false,
            termsCheckbox: false,
            privacyPolicyCheckbox: true,
            companyName: '',
            companyCode: '',
            usersLimit: 999,
            usersLimitRange: 'over 100',
            subType: 'PRO',
            codeIsUnique: false,
            captcha: false,
            captchaNotCheckedError: false,
            captchaExpiredError: false,
            campaign: '',
        }
    }

    async componentDidMount() {
        console.log(this.props.history)
        //ReactGA.pageview(window.location.pathname + window.location.search)
        console.log(localStorage.getItem('companyToRegisterTokrn'))

        var params = this.props.location.search
        params = params.substr(1)

        var paramsArray = params.split("&")
        var email = '', token = '', firstName = '', lastName = ''

        paramsArray.forEach(param => {
            var individualParam = param.split("=")
            if (individualParam[0] === "email") {
                email = decodeURIComponent(individualParam[1])
            } else if (individualParam[0] === 'token') {
                token = decodeURIComponent(individualParam[1])
            } else if (individualParam[0] === 'name') {
                firstName = decodeURIComponent(individualParam[1])
            } else if (individualParam[0] === 'surname') {
                lastName = decodeURIComponent(individualParam[1])
            } else if (individualParam[0] === 'source') {
                this.setState({
                    fromPage: true
                })
            }
        })

        this.setState({
            email: email,
            token: token,
            firstName: firstName,
            lastName: lastName
        })
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.gotNipUniqnessCheckResponse && this.props.gotNipUniqnessCheckResponse && this.props.isNewNipUnique) {
            this.setState({
                step: 2
            })
        }

        if (this.props.gotNipUniqnessCheckResponse && !this.props.isNewNipUnique && !this.state.didScrolToShowNipError) {
            if (this.refs.nipInputBox) {
                const yOffset = -10;
                const y = this.refs.nipInputBox.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
            }
            this.setState({
                didScrolToShowNipError: true
            })


        }
    }

    confirmEmail = (email, token, tokenType) => {
        this.props.confirmEmail(email, token, tokenType)
    }

    onIVatIdChange = e => {
        e.preventDefault()
        var { name, value } = e.target

        if (value && value.replace(/[^0-9]/gm).length === 10) {
            if (vatIdValidate(value)) {
                fetch(`https://wl-api.mf.gov.pl/api/search/nip/${value.replace(/[^0-9]/gi, '')}?date=${moment().format("YYYY-MM-DD")}`, { method: 'get' })
                    .then(response => response.json())
                    .then(json => {
                        console.log(json)
                        if (json && json.result && json.result.subject) {
                            this.setState({
                                companyName: json.result.subject.name
                            })
                        }
                    })
                    .catch(err => {
                        console.log('error when fetching companyName')
                    })

                this.setState({
                    showInvalidVatIdMsg: false,
                    showUserFields: true
                })
            } else {
                this.setState({
                    showInvalidVatIdMsg: true
                })
            }
        }

        this.setState({ [name]: value })
    }

    onInputChange = e => {
        e.preventDefault()

        console.log("on change triggered")
        var { name, value } = e.target

        if (name !== 'companyCode' && name !== 'usersLimit') {
            this.setState({ [name]: value })
        } else {
            value = value.replace(/[^A-Za-z0-9_-]/g, '')
            value = value.slice(0, 10)
            this.setState({ [name]: value })
        }
    }

    onEmailInputBlur = () => {
        const { email } = this.state
        this.setState({ email: email.replace(/\s/gm, '').toLowerCase() })
    }

    captchaChange = token => {
        console.log('captcha token: ' + token)
        if (token) {
            this.setState({
                captcha: true,
                captchaNotCheckedError: false,
            })
        } else {
            this.setState({
                captcha: false,
            })
        }
    }

    captchaExpire = () => {
        console.log('captcha expired')
        this.setState({
            captcha: false,
            captchaExpiredError: true
        })
    }

    hasLowerCase = (str) => {
        return (/[a-z]/.test(str));
    }

    hasUpperCase = (str) => {
        return (/[A-Z]/.test(str));
    }

    hasDigit = (str) => {
        return (/[0-9]/.test(str));
    }

    showFormErrors = e => {
        e.preventDefault()

        const { nip, termsCheckbox, privacyPolicyCheckbox, dataProcessingAgreementCheckbox, dataProcessingCheckbox, email, companyName, captcha, firstName, lastName, password, password2 } = this.state

        if (!captcha) {
            this.setState({
                captchaNotCheckedError: true
            })
        }
        if (nip.length === 0) {
            this.props.alertWarn(this.context.t('VAT-ID is required'))
        } else if (!this.validateVatId(nip)) {
            this.props.alertWarn("VAT-ID is not valid")
        }
        if (companyName.length === 0) {
            this.props.alertWarn(this.context.t('Company name is required'))
        }
        if (email.length === 0) {
            this.props.alertWarn(this.context.t('E-mail address is required'))
        }
        else if (!emailRegex.test(email)) {
            this.props.alertWarn(this.context.t('E-mail address is not valid'))
        }
        if (firstName.length === 0) {
            this.props.alertWarn(this.context.t('First name is required'))
        }
        if (lastName.length === 0) {
            this.props.alertWarn(this.context.t('Last name is required'))
        }
        if (password.length === 0 && password2.length === 0) {
            this.props.alertWarn(this.context.t('Password is required'))
        }
        else if (password.length < 8 && password2.length < 8) {
            this.props.alertWarn(this.context.t('Passwords must be at least 8 characters long'))
        }
        else if (!this.hasLowerCase(password) || !this.hasUpperCase(password) || !this.hasDigit(password)) {
            this.props.alertWarn(this.context.t('Passwords must contain at least one lowercase and one uppercase character and one digit'))
        }
        if (!termsCheckbox) {
            this.props.alertWarn(this.context.t("You have to accept Terms of Service"))
        }
        if (!privacyPolicyCheckbox) {
            this.props.alertWarn(this.context.t("You have to accept Privacy Policy"))
        }
        if (!dataProcessingAgreementCheckbox) {
            this.props.alertWarn(this.context.t("Agreement for the Entrustment of Personal Data Processing"))
        }
        if (!dataProcessingCheckbox) {
            this.props.alertWarn(this.context.t("You have to allow processing of your personal data"))
        }
    }

    validateForm = () => {
        var valid = true
        const { nip, termsCheckbox, privacyPolicyCheckbox, dataProcessingAgreementCheckbox, dataProcessingCheckbox, email, companyName, captcha, firstName, lastName, password, password2 } = this.state

        if (!captcha) {
            valid = false
        }
        if (nip.length === 0) {
            valid = false
        } else if (!this.validateVatId(nip)) {
            valid = false
        }
        if (companyName.length === 0) {
            valid = false
        }
        if (email.length === 0) {
            valid = false
        }
        else if (!emailRegex.test(email)) {
            valid = false
        }

        if (firstName.length === 0) {
            valid = false
        }
        if (lastName.length === 0) {
            valid = false
        }

        if (password.length === 0 && password2.length === 0) {
            valid = false
        }
        else if (password.length < 8 && password2.length < 8) {
            valid = false
        }
        else if (!this.hasLowerCase(password) || !this.hasUpperCase(password) || !this.hasDigit(password)) {
            valid = false
        }

        if (!termsCheckbox) {
            valid = false
        }
        if (!privacyPolicyCheckbox) {
            valid = false
        }
        if (!dataProcessingAgreementCheckbox) {
            valid = false
        }
        if (!dataProcessingCheckbox) {
            valid = false
        }

        return valid
    }

    createCompanySubmit = e => {
        e.preventDefault()
        console.log('enter createCompanySubmit')

        let valid = true

        const { nip, termsCheckbox, privacyPolicyCheckbox, dataProcessingAgreementCheckbox, dataProcessingCheckbox, email, companyName, captcha, campaign, subType, firstName, lastName, password, password2 } = this.state


        if (!captcha) {
            valid = false
            this.setState({
                captchaNotCheckedError: true
            })
        }
        if (nip.length === 0) {
            valid = false
            this.props.alertWarn(this.context.t('VAT-ID is required'))
        } else if (!this.validateVatId(nip)) {
            this.props.alertWarn("VAT-ID is not valid")
            valid = false
        }
        if (companyName.length === 0) {
            valid = false
            this.props.alertWarn(this.context.t('Company name is required'))
        }
        if (email.length === 0) {
            valid = false
            this.props.alertWarn(this.context.t('E-mail address is required'))
        }
        else if (!emailRegex.test(email)) {
            valid = false
            this.props.alertWarn(this.context.t('E-mail address is not valid'))
        }
        if (firstName.length === 0) {
            valid = false
            this.props.alertWarn(this.context.t('First name is required'))
        }
        if (lastName.length === 0) {
            valid = false
            this.props.alertWarn(this.context.t('Last name is required'))
        }

        if (password.length === 0 && password2.length === 0) {
            valid = false
            this.props.alertWarn(this.context.t('Password is required'))
        }
        else if (password.length < 8 && password2.length < 8) {
            valid = false
            this.props.alertWarn(this.context.t('Passwords must be at least 8 characters long'))
        }
        else if (!this.hasLowerCase(password) || !this.hasUpperCase(password) || !this.hasDigit(password)) {
            valid = false
            this.props.alertWarn(this.context.t('Passwords must contain at least one lowercase and one uppercase character and one digit'))
        }
        else if (password !== password2) {
            valid = false
            this.props.alertWarn(this.context.t('Given passwords don\'t match'))
        }

        if (!termsCheckbox) {
            this.props.alertWarn(this.context.t("You have to accept Terms of Service"))
            valid = false
        }
        if (!privacyPolicyCheckbox) {
            this.props.alertWarn(this.context.t("You have to accept Privacy Policy"))
            valid = false
        }
        if (!dataProcessingAgreementCheckbox) {
            this.props.alertWarn(this.context.t("Agreement for the Entrustment of Personal Data Processing"))
            valid = false
        }
        if (!dataProcessingCheckbox) {
            this.props.alertWarn(this.context.t("You have to allow processing of your personal data"))
            valid = false
        }

        if (valid) {
            this.props.registerNewCompany(
                nip,
                companyName,
                email,
                campaign,
                999,
                localStorage.getItem('language'),
                subType,
                (success) => {
                    if (success) {
                        var newUser = {
                            email: email,
                            firstName: firstName,
                            lastName: lastName,
                            password: password,
                            is_active: false,
                            email_verified: false,
                            source: this.state.fromPage ? 'page' : 'email'
                        }
                        this.props.userRegister(newUser, this.state.token, (success, msg) => {
                            if (success) {
                                this.setState({
                                    showSuccessRegistrationMsg: true
                                })
                            }
                        })
                    }
                }
            )
        }
    }

    validateVatId = nip => {
        var didMatch = false
        vatIdRegexList.forEach(regex => {
            if (regex.test(nip)) {
                didMatch = true
            }
        })

        return didMatch
    }

    representativeCheck = () => {
        this.setState({
            representativeCheckbox: !this.state.representativeCheckbox
        })
    }

    agreeToTerms = () => {
        var { termsCheckbox } = this.state
        this.setState({
            termsCheckbox: !termsCheckbox
        })
    }

    acceptPrivacyPolicy = () => {
        var { privacyPolicyCheckbox } = this.state
        this.setState({
            privacyPolicyCheckbox: !privacyPolicyCheckbox
        })
    }

    acceptDataProcessingAgreement = () => {
        var { dataProcessingAgreementCheckbox } = this.state
        this.setState({
            dataProcessingAgreementCheckbox: !dataProcessingAgreementCheckbox
        })
    }

    agreeToDataProcessing = () => {
        var { dataProcessingCheckbox } = this.state
        this.setState({
            dataProcessingCheckbox: !dataProcessingCheckbox
        })
    }

    hasAncestor = (element, id) => {
        while (element) {
            if (element.id && element.id === id) return true

            element = element.parentNode
        }
        return false
    }

    toggleSubscryptionTypesList = (e) => {
        if (e.target.nodeName !== 'LI' && e.target.nodeName !== 'A') {
            const { showSubscryptionTypesList } = this.state
            this.setState({
                showSubscryptionTypesList: !showSubscryptionTypesList
            })
        }
    }

    closeAllSugestions = (e) => {
        if (this.state.showSubscryptionTypesList && !this.hasAncestor(e.target, "membersLimitContainer")) {
            this.setState({
                showSubscryptionTypesList: false
            })
        }
    }

    changeSubType = (plan) => {
        this.setState({
            subType: plan,
            showSubscryptionTypesList: false
        })
    }

    render() {
        const { step, showRest, email, nip, representativeCheckbox, termsCheckbox, privacyPolicyCheckbox, dataProcessingAgreementCheckbox, dataProcessingCheckbox, companyName, companyCode, usersLimitRange, captchaNotCheckedError, captchaExpiredError, nipConfirmed, codeIsUnique, showSubscryptionTypesList, subType, showUserFields, firstName, lastName, password, password2, passwordVisible, password2Visible } = this.state
        const { user, isNewNipUnique, sendNipUniqnessCheckRequest, gotNipUniqnessCheckResponse, sendCompanyRegistrationRequest, gotCompanyRegistrationResponse, newCompanyRegistrationSuccess } = this.props
        if (user !== null) {
            return <Redirect to={routes.panel.dashboard} />
        }

        console.log(this.state.campaign)


        return (
            <div className="company-register complete-register" onClick={e => this.closeAllSugestions(e)}>
                <LanguageSwitcher />
                <div className="header">
                    <div className="logo">
                        <a href={'https://easydocs.pl/'} />
                        <img src={Logo} alt="EasyDocs Logo" />
                    </div>
                    {
                        this.props.history && this.props.history.action !== 'POP' ? (
                            <div className="go-back" onClick={() => this.props.history.goBack()}>{this.context.t('Go back')}</div>
                        ) : (
                            null
                        )
                    }

                    <div className="header-text">
                        <h1>{this.context.t('Company Registration')} {this.context.t(' - 30 days of free trial period')}</h1>

                        <p>
                            {this.context.t('If you like EasyDocs, after the trial period you can activate a subscription with no long-term obligations according to the ')}
                            <a href="https://easydocs.pl/cennik" target="_blank">{this.context.t('Pricing')}</a>
                        </p>
                        <p>
                            {this.context.t('Do you have any questions? Write an email to ')}
                            <a href="mailto:support@easydocs.pl">support@easydocs.pl</a>
                            {this.context.t(' or call us ')}
                            <a href="tel:+48221131333">+48 22 113 13 33</a>
                        </p>
                    </div>
                </div>

                <div className="form-col">
                    <form onSubmit={this.createCompanySubmit}>

                        <div className="form-group" ref="nipInputBox">
                            <input type="text" name="nip" id="nip" value={nip} onChange={this.onIVatIdChange} placeholder={this.context.t("Enter your company\'s VAT-ID")} />
                            <label htmlFor="nip">{this.context.t('Company VAT-ID')}</label>

                            <span className="info" data-tip="show" data-for='vatId-tooltip'></span>
                            <ReactTooltip id='vatId-tooltip' place="top" effect="solid" className="default-tooltip">
                                <p>{this.context.t("In EasyDocs you add your company and VAT-ID is its identifier")}</p>
                            </ReactTooltip>
                        </div>

                        {
                            gotNipUniqnessCheckResponse && !isNewNipUnique ? (
                                <div className="error-container">
                                    {this.context.t('Looks like company with that VAT-ID is already registered.')}
                                </div>
                            ) : (
                                null
                            )
                        }
                        {
                            this.state.showInvalidVatIdMsg ? (
                                <div className="error-container">
                                    {this.context.t('The tax identification number you provided is incorrect.')}
                                </div>
                            ) : (
                                null
                            )
                        }

                        <div className="form-group">
                            <TextareaAutosize
                                name="companyName" id="companyName"
                                value={companyName}
                                placeholder={this.context.t('e.g. My Company')}
                                onChange={this.onInputChange}
                                maxRows={10}
                            />
                            <label htmlFor="companyName">{this.context.t('Company Name')}</label>
                        </div>

                        <div className="form-group" onClick={(e) => this.toggleSubscryptionTypesList(e)} id="companySubscriptionType">
                            <input type="text" name="subType" id="subType" value={subType} readOnly={true} />
                            <label htmlFor="usersLimitRange">{this.context.t('Subscription type')}</label>

                            <span className={`expend-icon ${showSubscryptionTypesList ? 'expended' : ''}`}></span>
                            {
                                showSubscryptionTypesList ? (
                                    <ul>
                                        <li onClick={() => this.changeSubType('SOLO')}>{'SOLO'}</li>
                                        <li onClick={() => this.changeSubType('BASIC')}>{'BASIC'}</li>
                                        <li onClick={() => this.changeSubType('PRO')}>{'PRO'}</li>
                                        <li onClick={() => this.changeSubType('PRO+')}>{'PRO+'}</li>
                                    </ul>
                                ) : (
                                    null
                                )
                            }

                            <span className="info" data-tip="show" data-for='sub-tooltip'></span>
                            <ReactTooltip id='sub-tooltip' place="top" effect="solid" className="default-tooltip pricing" delayHide={200}>
                                <p>{this.context.t("Read more about subscryption types at ")}<a href="https://easydocs.pl/cennik/" target="_blank">easydocs.pl/pricing</a></p>
                            </ReactTooltip>
                        </div>

                        <div className="form-group">
                            <input type="email" name="email" id="email" value={email} onChange={this.onInputChange} onBlur={this.onEmailInputBlur} placeholder="" readOnly={true} className="read-only" />
                            <label htmlFor="email">{this.context.t('Your business email address')}</label>
                        </div>

                        {
                            showUserFields ? (
                                <>
                                    <div className="form-group">
                                        <input type="text" name="firstName" id="firstName" value={firstName} onChange={this.onInputChange} placeholder="" />
                                        <label htmlFor="firstName">{this.context.t('First name')}</label>
                                    </div>

                                    <div className="form-group">
                                        <input type="text" name="lastName" id="lastName" value={lastName} onChange={this.onInputChange} placeholder="" />
                                        <label htmlFor="lastName">{this.context.t('Last name')}</label>
                                    </div>

                                    <div className="form-group">
                                        <input type="password" name="password" id="password" value={password} onChange={this.onInputChange} placeholder="" ref="password1"></input>
                                        <label htmlFor="password">{this.context.t('Password')}</label>
                                        <span className="info" data-tip="show" data-for='password1-tooltip'></span>
                                        <ReactTooltip id='password1-tooltip' place="top" effect="solid" className="default-tooltip password">
                                            <p>{this.context.t("Passwords must be at least 12 character long and contain at least one lowercase, one uppercase character and one digit")}</p>
                                        </ReactTooltip>
                                        {/* <span className={`make-visible ${passwordVisible ? 'view' : 'hide'}`} onClick={() => this.changeVisibility('p1')}></span> */}
                                    </div>

                                    <div className="form-group">
                                        <input type="password" name="password2" id="password2" value={password2} onChange={this.onInputChange} placeholder="" ref="password2" />
                                        <label htmlFor="password2">{this.context.t('Confirm password')}</label>
                                        {/* <span className={`make-visible ${password2Visible ? 'view' : 'hide'}`} onClick={() => this.changeVisibility('p2')}></span> */}
                                    </div>
                                </>
                            ) : (
                                null
                            )
                        }

                        <div className="agree-to-terms first">
                            <span className={`checkbox ${termsCheckbox ? 'checked' : ''}`} onClick={() => this.agreeToTerms()}></span>
                            <p onClick={() => this.agreeToTerms()}>
                                {this.context.t('I accept the ')}<a href="https://easydocs.pl/regulamin" target="_blank">{this.context.t('Terms of Service')}</a>
                            </p>
                        </div>

                        <div className="agree-to-terms">
                            <span className={`checkbox ${dataProcessingAgreementCheckbox ? 'checked' : ''}`} onClick={() => this.acceptDataProcessingAgreement()}></span>
                            <p onClick={() => this.acceptDataProcessingAgreement()}>
                                {this.context.t('I accept the ')}<a href="https://easydocs.pl/przetwarzanie-danych/" target="_blank">{this.context.t('Agreement for the Entrustment of Personal Data Processing')}</a>
                            </p>
                        </div>

                        <div className="agree-to-terms">
                            <span className={`checkbox ${dataProcessingCheckbox ? 'checked' : ''}`} onClick={() => this.agreeToDataProcessing()}></span>
                            <p onClick={() => this.agreeToDataProcessing()}>
                                {this.context.t('I consent to processing of my personal data in order to provide EasyDocs service')}
                            </p>
                        </div>

                        {/* <div className="agree-to-terms">
                            <span className={`checkbox ${representativeCheckbox ? 'checked': ''}`} onClick={() => this.representativeCheck()}></span>
                            <p onClick={() => this.representativeCheck()}>{this.context.t('I declare that I am a representative of this company')}</p>
                        </div> */}

                        <div className="recaptcha-container margins">
                            <ReCAPTCHA
                                ref="loginReCaptchaRef"
                                size="normal"
                                sitekey="6LceWAEVAAAAAK6LgYLnLuisqGWH9U-XEm4ACBdd"
                                onChange={token => this.captchaChange(token)}
                                onExpired={() => this.captchaExpire()}
                            />
                            {
                                captchaNotCheckedError ? (
                                    <div className={`captcha-error-container ${captchaExpiredError ? 'expired' : ''}`}>
                                        {this.context.t('Please confirm you are not a robot')}
                                    </div>
                                ) : (
                                    null
                                )
                            }
                        </div>

                        <div className="required-fields">
                            {this.context.t('* required field')}
                        </div>


                        {
                            this.validateForm() ? (
                                <button className="btn-confirm" onClick={e => this.createCompanySubmit(e)}>
                                    {
                                        sendCompanyRegistrationRequest ? (
                                            <Digital size={20} speed={0.8} color={"#fff"} />
                                        ) : (
                                            this.context.t("Submit and go to EasyDocs")
                                        )
                                    }
                                </button>
                            ) : (
                                <div className="button" onClick={e => this.showFormErrors(e)}>
                                    {this.context.t("Submit and go to EasyDocs")}
                                </div>
                            )
                        }

                        {
                            gotCompanyRegistrationResponse ? (
                                newCompanyRegistrationSuccess ? (
                                    this.state.showSuccessRegistrationMsg ? (
                                        <div className="registration-success">
                                            <span>{this.context.t('Success! ')}</span>{this.context.t('Check your email for further instructions')}
                                        </div>
                                    ) : (
                                        null
                                    )
                                ) : (
                                    !isNewNipUnique ? (
                                        null
                                    ) : (
                                        <div className="registration-error">
                                            {this.context.t('Something went wrong')}
                                        </div>
                                    )
                                )
                            ) : (
                                null
                            )
                        }
                    </form>

                    <div className="have-account">
                        {this.context.t('Already have an account?')} <Link className="link" to={routes.login}>{this.context.t(' Login ')}</Link>
                    </div>
                </div>
                <div className="img-col company">
                    <div className="text">
                        {this.context.t('Easy way of handling financial documents in your company')}
                    </div>

                    <iframe src="https://www.youtube.com/embed/w5lSCGxVDY8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
        )
    }
}

CompleteCompanyRegistration.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    isNewNipUnique: state.Company.isNewNipUnique,
    sendNipUniqnessCheckRequest: state.Company.sendNipUniqnessCheckRequest,
    gotNipUniqnessCheckResponse: state.Company.gotNipUniqnessCheckResponse,
    newRegisteredCompany: state.Company.newRegisteredCompany,
    sendCompanyRegistrationRequest: state.Company.sendCompanyRegistrationRequest,
    newCompanyRegistrationSuccess: state.Company.newCompanyRegistrationSuccess,
    gotCompanyRegistrationResponse: state.Company.gotCompanyRegistrationResponse,
})

const mapDispatchToProps = {
    confirmEmail: UserActions.confirmEmail,
    alertWarn: AlertActions.warning,
    checkIfCompanyExists: CompanyActions.checkIfCompanyWithNipExists,
    registerNewCompany: CompanyActions.registerCompany,
    userRegister: UserActions.register,
}

export default connect(mapStateToProps, mapDispatchToProps)(CompleteCompanyRegistration)
