import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import TextareaAutosize from 'react-textarea-autosize'

import { FileActions } from '../../actions/FileActions'
import { ReportsActions } from '../../actions/ReportsActions'
import { CompanyActions } from '../../actions/CompanyActions'

import arrayObjectIndexOf from "../../helpers/indexOfObject"
import waproUsers from '../../helpers/waproUsers.json'
import { devCostsProjects } from '../../helpers/DevCosts/devCostsProjects'
import { gfProjects } from '../../helpers/CostSplit/gfProjects'
import { waProjects } from '../../helpers/MpkSplitWineAvenue/waProjects'
import { devCostsUsers } from '../../helpers/DevCosts/devCostsUsers'
import magDocTypeList from '../../helpers/magDocTypes.json'
import fakirDocTypeList from '../../helpers/fakirDocTypes.json'
import fakirDictionaryList from '../../helpers/fakirDocDictionary.json'
import currencyList from '../../helpers/currencyList.json'
import paymentMethodsList from '../../helpers/paymentMethodsList.json'

import FileAcceptanceStepsSection from './FileAcceptanceStepsSection'
import DropDownList from '../DropDownList'
import PreviewCounterpartyEdit from './PreviewCounterpartyEdit'

import ProjectCode from './WL_DevCosts/ProjectCode'
import Technology from './WL_DevCosts/Technology'
import DevExCode from './WL_DevCosts/DevExCode'
import DevExType from './WL_DevCosts/DevExType'
import TransactionDescription from './WL_DevCosts/TransactionDescription'

import OcrPageSymfoniaDocTypes from '../OcrPageComponents/symfonia/OcrPageSymfoniaDocTypes'
import OcrPageSymfoniaDocDescription from '../OcrPageComponents/symfonia/OcrPageSymfoniaDocDescription'
import OcrPageSymfoniaGrossAccount from '../OcrPageComponents/symfonia/OcrPageSymfoniaGrossAccount'
import OcrPageSymfoniaNetAccount from '../OcrPageComponents/symfonia/OcrPageSymfoniaNetAccount'
import OcrPageSymfoniaVatAccount from '../OcrPageComponents/symfonia/OcrPageSymfoniaVatAccount'
import OcrPageSymfoniaRegisters from '../OcrPageComponents/symfonia/OcrPageSymfoniaRegisters'
import TagsComponent from '../OcrPageComponents/TagsComponent';
import CostSplit from '../OcrPageComponents/CostSplit'
import MpkSplitWineAvenue from '../OcrPageComponents/MpkSplit/MpkSplitWineAvenue'
import MpkSplitAventine from '../OcrPageComponents/MpkSplit/MpkSplitAventine'

const WAPRO_VAT_RATES = [
    '23%',
    '8%',
    '5%',
    '0%',
    'NP',
    'OO'
]
export class CostsFrom extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showTagsList: false,
            showCurrencyList: false,
            showPriorityList: false,
            showPaymentTypesList: false,
            showMagDocType: false,
            showFakirDocType: false,
            showFakirDictionary: false,
            showWaproNetAccountList: false,
            showWaproDocDescriptionList: false,
            netAccountList: false,
            showCountriesList: false,
            docDescriptionsList: [],

            symfoniaVatRates: [],
            symfoniaRegisters: [],
            symfoniaDocTypes: [],
            symfoniaAccounts: []
        }

        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick);
        window.addEventListener("keydown", this.escFunction, false)
    }

    componentDidUpdate(prevProps) {
        const { docOwnerCompany, isAccountantInCurrentCompany, user } = this.props
        if (!prevProps.docOwnerCompany && docOwnerCompany) {
            if (waproUsers.includes(user.id) && isAccountantInCurrentCompany) {
                if (docOwnerCompany.id === 1511) {
                    var docDescriptionsList = require('../../helpers/admitad-wapro-descriptions.json')
                    this.setState({
                        docDescriptionsList: docDescriptionsList
                    })
                } else {
                    var docDescriptionsList = require('../../helpers/proxy-wapro-descriptions.json')
                    this.setState({
                        docDescriptionsList: docDescriptionsList
                    })
                }
            }

            if (docOwnerCompany.id === 1607 || user.id === 2) {
                this.props.getCompanySymfoniaFile(docOwnerCompany.id, (res) => {
                    if (res.success) {
                        this.setState({
                            symfoniaVatRates: res.vatRates,
                            symfoniaRegisters: res.registers,
                            symfoniaDocTypes: res.docTypes,
                            symfoniaAccounts: res.accounts
                        })
                    }
                })
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
        window.removeEventListener("keydown", this.escFunction, false)
    }

    escFunction = e => {
        if (e.keyCode === 27) {
            this.handleClick(e)
        }
    }

    handleClick = (event) => {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({
                showTagsList: false,
                showCurrencyList: false,
                showPriorityList: false,
                showPaymentTypesList: false,
                showMagDocType: false,
                showFakirDocType: false,
                showFakirDictionary: false,
                showWaproNetAccountList: false,
                showWaproVatRate: false,
                showWaproDocDescriptionList: false,
                netAccountList: false,
                showCountriesList: false,
            })
        } else {
            if (event.keyCode === 27 || !this.hasSomeParentTheClass(event.target, 'payment-type-container')) {
                this.setState({
                    showPaymentTypesList: false
                })
            }

            if (event.keyCode === 27 || !this.hasSomeParentTheClass(event.target, 'priority-container')) {
                this.setState({
                    showPriorityList: false
                })
            }

            if (event.keyCode === 27 || !this.hasSomeParentTheClass(event.target, 'currency-selector')) {
                this.setState({
                    showCurrencyList: false
                })
            }

            if (event.keyCode === 27 || (this.state.showMagDocType && !this.hasSomeParentTheClass(event.target, 'wapro-mag-container'))) {
                this.toggleMagDocTypeList(false)
            }

            if (event.keyCode === 27 || (this.state.showFakirDocType && !this.hasSomeParentTheClass(event.target, 'wapro-fakir-type-container'))) {
                this.toggleFakirDocTypeList(false)
            }

            if (event.keyCode === 27 || (this.state.showFakirDictionary && !this.hasSomeParentTheClass(event.target, 'wapro-fakir-dictionary-container'))) {
                this.toggleFakirDictionaryList(false)
            }

            if (event.keyCode === 27 || (this.state.showWaproNetAccountList && !this.hasSomeParentTheClass(event.target, 'wapro-net-account-container'))) {
                this.toggleWaproNetAccountList(false)
            }

            if (event.keyCode === 27 || (this.state.showWaproVatRate && !this.hasSomeParentTheClass(event.target, 'wapro-vat-rate-container'))) {
                this.toggleWaproVatRate(false)
            }

            if (event.keyCode === 27 || (this.state.showWaproDocDescriptionList && !this.hasSomeParentTheClass(event.target, 'wapro-doc-description-container'))) {
                this.toggleDocDescriptionList(false)
            }

            if (event.keyCode === 27 || (this.state.showCountriesList && !this.hasSomeParentTheClass(event.target, 'countries-list-container'))) {
                this.toggleCountriesList(false)
            }

            if (event.keyCode === 27 || (this.state.showTagsList && !this.hasSomeParentTheClass(event.target, 'tags-container'))) {
                this.setState({
                    showTagsList: false
                })
            }
        }
    }

    hasSomeParentTheClass = (element, className) => {
        while (element) {
            if (element.className && element.className.includes(className)) return true

            element = element.parentNode
        }

        return false
    }

    changeInputValue = (e, field, docType, docId) => {
        e.preventDefault()
        console.log(field)
        this.props.editDocInput(e.target.value, field, docType, docId)
    }

    changePayamentTypeValue = (e, field, docType, docId) => {
        this.props.editDocInput(e, field, docType, docId)
    }

    additionaAcceptanceClick = () => {
        const { user, pdfPreviewData } = this.props
        this.props.toogleAdditionalAcceptanceForDoc(pdfPreviewData.docId, pdfPreviewData.id, pdfPreviewData.projectId, user.id);
        var additionalAcceptances = pdfPreviewData.additionalAcceptances || []
        var userAcceptanceIndex = arrayObjectIndexOf(additionalAcceptances, user.id, 'user_id')
        if (userAcceptanceIndex > -1) {
            additionalAcceptances[userAcceptanceIndex].accepted = true
            this.props.editDocInput(additionalAcceptances, 'additionalAcceptances', pdfPreviewData.docType, pdfPreviewData.docId)
        }
    }

    paymentMethodClick = method => {
        const { pdfPreviewData } = this.props
        this.props.tooglePaymentForDoc(pdfPreviewData.docId, pdfPreviewData.id, pdfPreviewData.projectId, method);

        if (this.refs.paymentTooltipRef) {
            this.refs.paymentTooltipRef.tooltipRef = null;
            ReactTooltip.hide()
        }

        this.props.editDocInput(method, 'paymentMethod', pdfPreviewData.docType, pdfPreviewData.docId)
        this.props.editDocInput(4, 'acceptanceStep', pdfPreviewData.docType, pdfPreviewData.docId)
        this.props.editDocInput('DONE', 'status', pdfPreviewData.docType, pdfPreviewData.docId)

        if (this.props.editMode) {
            this.props.changePaymentMethodInPdfBackup(method)
        }
    }

    getDocStatus = (pdfPreviewData, acceptancePermissions) => {

        if (pdfPreviewData.status === 'NEW')
            return (<span className="uneditable-prop">{this.context.t('OCR not submited')}</span>)

        if (pdfPreviewData.status === 'NOT_VERIFIED')
            return (<span className="uneditable-prop">{this.context.t('OCR not verified')}</span>)

        if (pdfPreviewData.status === 'IN_PROGRESS' && pdfPreviewData.acceptanceStep < 3)
            return (<span className="uneditable-prop">{this.context.t('OCR not accepted')}</span>)

        if (pdfPreviewData.status === 'IN_PROGRESS') {
            if (pdfPreviewData.requiresAdditionalAcceptance)
                return (<span className="uneditable-prop">{this.context.t('waiting for additional approval ({n})', { n: pdfPreviewData.additionalAcceptancesRequired })}</span>)

            if (acceptancePermissions !== 'USER1' && !pdfPreviewData.deleted)
                return (
                    <>
                        <span className="uneditable-prop">{this.context.t('accepted')}</span>
                        <span className="mark-as-paid-btn" data-tip='custom show' data-event='click' data-for='payment-methods-tooltip'>{this.context.t('Mark as paid')}</span>
                        <ReactTooltip ref='paymentTooltipRef' id='payment-methods-tooltip' className='default-tooltip payment-methods-tooltip' effect='solid' place='bottom' globalEventOff="click">
                            <ul>
                                {
                                    paymentMethodsList.map(method => {
                                        return (
                                            <li onClick={e => this.paymentMethodClick(method.value)}>
                                                {this.context.t(method.title)}
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </ReactTooltip>
                    </>
                )

            return (<span className="uneditable-prop">{this.context.t('accepted')}</span>)

        }
        return (
            <span className="uneditable-prop">
                {this.context.t('paid')}
                {pdfPreviewData.paymentMethod ? ` (${this.context.t(pdfPreviewData.paymentMethod)})` : ''}
                {
                    pdfPreviewData.paidBy ? (
                        <>
                            <br /> {`${this.context.t(pdfPreviewData.paidBy)} - ${moment(pdfPreviewData.paymentDate).format('YYYY-MM-DD HH:mm')}`}
                        </>
                    ) : (
                        null
                    )
                }
            </span>
        )
    }

    showTagsList = () => {
        this.setState({
            showTagsList: true
        })
    }

    returnTags = (tags, docId, docType) => {
        this.props.editDocInput(tags, 'tags', docType, docId)
    }

    setTags = value => {
        const { pdfPreviewData } = this.props
        this.props.editDocInput(value, 'tags', pdfPreviewData.docType, pdfPreviewData.docId)
    }

    setSellerCountryCode = (value) => {
        const { pdfPreviewData } = this.props
        this.props.editDocInput(value, 'sellerCountry', pdfPreviewData.docType, pdfPreviewData.docId)
        this.toggleCountriesList(false)
    }

    toggleCountriesList = show => {
        this.setState({
            showCountriesList: show !== undefined ? show : !this.state.showCountriesList
        })
    }

    showPaymentTypesList = () => {
        if (this.props.editMode) {
            this.setState({
                showPaymentTypesList: !this.state.showPaymentTypesList
            })
        }
    }

    selectPaymentType = (name, docType, docId) => {
        if (this.props.editMode) {
            this.setState({
                showPaymentTypesList: false
            })
            this.props.editDocInput(name, 'paymentType', docType, docId)
        }
    }

    showPriorityList = () => {
        if (this.props.editMode) {
            this.setState({
                showPriorityList: !this.state.showPriorityList
            })
        }
    }

    selectPriority = (name, docType, docId) => {
        if (this.props.editMode) {
            this.setState({
                showPriorityList: false
            })
            this.props.editDocInput(name, 'priority', docType, docId)
        }
    }

    showCurrencyList = (triger) => {
        this.setState({
            showCurrencyList: !this.state.showCurrencyList,
            currencyTriger: triger
        })
    }

    selectCurrency = (name, docType, docId) => {
        this.setState({
            showCurrencyList: false
        })
        this.props.editDocInput(name, 'currency', docType, docId)
    }

    selectWaproField = (selectedOption, field) => {
        const { pdfPreviewData } = this.props
        var newValue = {
            ...pdfPreviewData.waproData
        }
        newValue[field] = selectedOption.code || selectedOption

        if (selectedOption.defaultMagType) {
            newValue.magType = selectedOption.defaultMagType
            newValue.fakirType = selectedOption.defaultFakirType
            newValue.showVatRateSelector = selectedOption.showVatRateSelector
        }

        this.props.editDocInput(newValue, 'waproData', pdfPreviewData.docType, pdfPreviewData.docId)
        this.setState({
            showMagDocType: false,
            showFakirDocType: false,
            showFakirDictionary: false,
            showWaproNetAccountList: false,
            showWaproVatRate: false,
            showWaproDocDescriptionList: false,
        })
    }

    toggleMagDocTypeList = show => {
        this.setState({
            showMagDocType: show !== undefined ? show : !this.state.showMagDocType
        })
    }

    toggleFakirDocTypeList = show => {
        this.setState({
            showFakirDocType: show !== undefined ? show : !this.state.showFakirDocType
        })
    }

    toggleFakirDictionaryList = show => {
        this.setState({
            showFakirDictionary: show !== undefined ? show : !this.state.showFakirDictionary
        })
    }

    toggleWaproNetAccountList = show => {
        this.setState({
            showWaproNetAccountList: show !== undefined ? show : !this.state.showWaproNetAccountList
        })
    }

    toggleWaproVatRate = show => {
        this.setState({
            showWaproVatRate: show !== undefined ? show : !this.state.showWaproVatRate
        })
    }

    toggleDocDescriptionList = show => {
        this.setState({
            showWaproDocDescriptionList: show !== undefined ? show : !this.state.showWaproDocDescriptionList
        })
    }

    WL_setProjectCode = value => {
        const { pdfPreviewData } = this.props
        this.props.editDocInput(value, 'wl_project_code', pdfPreviewData.docType, pdfPreviewData.docId)
    }

    WL_setTechnology = value => {
        const { pdfPreviewData } = this.props
        this.props.editDocInput(value, 'wl_technology', pdfPreviewData.docType, pdfPreviewData.docId)
    }

    WL_setDevExType1 = value => {
        const { pdfPreviewData } = this.props
        this.props.editDocInput(value, 'wl_devex_type_1', pdfPreviewData.docType, pdfPreviewData.docId)
        this.props.editDocInput("", 'wl_devex_type_2', pdfPreviewData.docType, pdfPreviewData.docId)
    }

    WL_setDevExType2 = value => {
        const { pdfPreviewData } = this.props
        this.props.editDocInput(value, 'wl_devex_type_2', pdfPreviewData.docType, pdfPreviewData.docId)
    }

    WL_setTransactionDescriptionPl = value => {
        const { pdfPreviewData } = this.props
        this.props.editDocInput(value, 'wl_transaction_description_pl', pdfPreviewData.docType, pdfPreviewData.docId)
    }

    WL_setTransactionDescriptionEn = value => {
        const { pdfPreviewData } = this.props
        this.props.editDocInput(value, 'wl_transaction_description_en', pdfPreviewData.docType, pdfPreviewData.docId)
    }

    formatPrice = price => {
        if (price || price === 0) {
            price = price.toFixed(2)
            if (price.indexOf('.') > -1) {
                var parts = price.split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                price = parts.join('.')
            } else {
                price = price.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }

            return price
        } else {
            return '0.00'
        }
    }

    polihAccountPritier = account => {
        if (account) {
            var formatedAccount = account.replace(/[^0-9]/gm, '')

            if (formatedAccount.length === 26) {
                formatedAccount = formatedAccount.slice(0, 2) + ' ' + formatedAccount.slice(2, 6) + ' ' + formatedAccount.slice(6, 10) + ' ' + formatedAccount.slice(10, 14) + ' ' + formatedAccount.slice(14, 18) + ' ' + formatedAccount.slice(18, 22) + ' ' + formatedAccount.slice(22, 26)
                if (account.startsWith('PL')) {
                    return 'PL' + formatedAccount
                } else {
                    return formatedAccount
                }
            } else {
                return account
            }
        } else return ''
    }

    generateAdditionalAcceptanceFields = () => {
        const { user, pdfPreviewData } = this.props
        if (pdfPreviewData.additionalAcceptances && Array.isArray(pdfPreviewData.additionalAcceptances)) {
            var alreadyAccepted = 0, neededAcceptances = 0
            pdfPreviewData.additionalAcceptances.forEach(acc => {
                if (acc.numberOfRequiredAcceptances) neededAcceptances = acc.numberOfRequiredAcceptances
                if (acc.accepted) alreadyAccepted++
            })
            if (neededAcceptances === 0) neededAcceptances = pdfPreviewData.additionalAcceptances.length
            if (pdfPreviewData.fileInstance?.requires_additional_acceptance) {
                return (
                    pdfPreviewData.additionalAcceptances.map(acc => {
                        return (
                            <div className="doc-prop">
                                <span className="name">{acc.accepted ? this.context.t('Accepted by:') : this.context.t('Requires acceptance:')}</span>
                                <span className="uneditable-prop">
                                    {`${acc.user?.first_name} ${acc.user?.last_name}`}
                                    {
                                        acc.user_id === user.id && !acc.accepted ? (
                                            <>
                                                <span className="mark-as-paid-btn" onClick={() => this.additionaAcceptanceClick()}>{this.context.t('Accept')}</span>
                                            </>
                                        ) : (
                                            null
                                        )
                                    }
                                </span>
                            </div>
                        )
                    })
                )
            } else if (pdfPreviewData.fileInstance?.additional_acceptances_required) {
                return (
                    pdfPreviewData.additionalAcceptances.map(acc => {
                        return (
                            acc.accepted ? (
                                <div className="doc-prop">
                                    <span className="name">{acc.accepted ? this.context.t('Accepted by:') : this.context.t('Requires acceptance:')}</span>
                                    <span className="uneditable-prop">
                                        {`${acc.user?.first_name} ${acc.user?.last_name}`}
                                        {
                                            acc.user_id === user.id && !acc.accepted ? (
                                                <>
                                                    <span className="mark-as-paid-btn" onClick={() => this.additionaAcceptanceClick()}>{this.context.t('Accept')}</span>
                                                </>
                                            ) : (
                                                null
                                            )
                                        }
                                    </span>
                                </div>
                            ) : (
                                null
                            )
                        )
                    })
                )
            }
        } else {
            return null
        }
    }

    showLinkedFiles = linkedFiles => {
        var buttonsToReturn = []
        linkedFiles.map(relation => {
            if (relation.link_type === 'CORRECTION_TO') buttonsToReturn.push(<span className="mark-as-paid-btn" onClick={() => this.props.goToFile(relation.linked_file_id, relation.linkedFile.project_id)}>{this.context.t('Show original')}</span>)

            if (relation.link_type === 'CORRECTED_BY') buttonsToReturn.push(<span className="mark-as-paid-btn" onClick={() => this.props.goToFile(relation.linked_file_id, relation.linkedFile.project_id)}>{this.context.t('Show correction')}</span>)

            if (relation.link_type === 'FROM_PROFORMA') buttonsToReturn.push(<span className="mark-as-paid-btn" onClick={() => this.props.goToFile(relation.linked_file_id, relation.linkedFile.project_id)}>{this.context.t('Show proforma')}</span>)

            if (relation.link_type === 'PROFORMA') buttonsToReturn.push(<span className="mark-as-paid-btn" onClick={() => this.props.goToFile(relation.linked_file_id, relation.linkedFile.project_id)}>{this.context.t('Show invoice')}</span>)
        })

        return buttonsToReturn
    }

    setSymfoniaDocType = value => {
        const { pdfPreviewData } = this.props
        this.props.editDocInput(value, 'symfoniaData.docType', pdfPreviewData.docType, pdfPreviewData.docId)
    }

    setSymfoniaDocDescription = value => {
        const { pdfPreviewData } = this.props
        this.props.editDocInput(value, 'symfoniaData.docDescription', pdfPreviewData.docType, pdfPreviewData.docId)
    }

    selectSymfoniaVatAccount = value => {
        const { pdfPreviewData } = this.props
        this.props.editDocInput(value, 'symfoniaData.vatAccount', pdfPreviewData.docType, pdfPreviewData.docId)
    }

    selectSymfoniaNetAccount = value => {
        const { pdfPreviewData } = this.props
        this.props.editDocInput(value, 'symfoniaData.netAccount', pdfPreviewData.docType, pdfPreviewData.docId)
    }

    selectSymfoniaGrossAccount = value => {
        const { pdfPreviewData } = this.props
        this.props.editDocInput(value, 'symfoniaData.grossAccount', pdfPreviewData.docType, pdfPreviewData.docId)
    }

    editSymfoniaRegisters = value => {
        const { pdfPreviewData } = this.props
        this.props.editDocInput(value, 'symfoniaData.vatRegisters', pdfPreviewData.docType, pdfPreviewData.docId)
    }

    setCostSplitAllocation = newData => {
        const { pdfPreviewData } = this.props
        this.props.editDocInput(newData, 'costSplit', pdfPreviewData.docType, pdfPreviewData.docId)
    }

    setMpkSplitWineAvenue = newData => {
        const { pdfPreviewData } = this.props
        this.props.editDocInput(newData, 'mpkSplitWineAvenue', pdfPreviewData.docType, pdfPreviewData.docId)
    }

    render() {
        const { docDescriptionsList, showTagsList, showCurrencyList, currencyTriger, showPriorityList, showPaymentTypesList, showMagDocType, showFakirDocType, showFakirDictionary, showWaproNetAccountList, showWaproVatRate, showWaproDocDescriptionList, showCountriesList } = this.state
        const { user, pdfPreviewData, acceptancePermissions, editMode, docOwnerCompany, tagsList, netAccountList, isAccountantInCurrentCompany } = this.props
        return (
            <div onClick={e => this.handleClick(e)} ref={this.wrapperRef}>
                <FileAcceptanceStepsSection pdfPreviewData={pdfPreviewData} />

                {this.generateAdditionalAcceptanceFields()}

                {
                    pdfPreviewData.deleted ? (
                        <div className="doc-prop">
                            <span className="name">{this.context.t('Date of permanent removal:')}</span>
                            <span className="uneditable-prop">{moment(pdfPreviewData.deletedDate).add(45, 'days').format('DD-MM-YYYY')}</span>
                        </div>
                    ) : (
                        null
                    )
                }

                {
                    pdfPreviewData.docType !== 'SALE_INVOICE' ? (
                        <div className="doc-prop">
                            <span className="name">{this.context.t('Document status:')}</span>
                            {this.getDocStatus(pdfPreviewData, acceptancePermissions)}
                        </div>
                    ) : (
                        null
                    )
                }
                {
                    pdfPreviewData.priority &&
                    <div className="doc-prop payment-type-container">
                        <span className="name">{this.context.t('Payment type')}:</span>
                        <TextareaAutosize
                            onClick={() => this.showPaymentTypesList()}
                            className={`${!editMode ? 'read-only' : ''}`}
                            readOnly={!editMode}
                            name="paymentType"
                            id={`paymentTypeInput-${pdfPreviewData.docId}`}
                            value={this.context.t(pdfPreviewData.paymentType === 'regular' ? this.context.t('Regular') : this.context.t('Split-payment'))}
                        />

                        {
                            editMode ? (
                                <span className={`arrow ${showPaymentTypesList ? 'expanded' : ''} paymentType-arrow`} onClick={() => this.showPaymentTypesList()}></span>
                            ) : (
                                null
                            )
                        }

                        <ul className={`payment-types-list ${showPaymentTypesList ? 'expanded' : 'hide'}`}>
                            <li className={`${pdfPreviewData.paymentType === 'regular' ? 'selected' : ''}`} onClick={() => this.selectPaymentType('regular', pdfPreviewData.docType, pdfPreviewData.docId)}>{this.context.t('Regular')}</li>
                            <li className={`${pdfPreviewData.paymentType === 'split' ? 'selected' : ''} last`} onClick={() => this.selectPaymentType('split', pdfPreviewData.docType, pdfPreviewData.docId)}>{this.context.t('Split-payment')}</li>
                        </ul>
                    </div>
                }
                {
                    pdfPreviewData.priority &&
                    <div className="doc-prop priority-container">
                        <span className="name">{this.context.t('Priority')}:</span>
                        <TextareaAutosize
                            onClick={() => this.showPriorityList()}
                            className={`${!editMode ? 'read-only' : ''}`}
                            readOnly={!editMode}
                            name="priority"
                            id={`priorityInput-${pdfPreviewData.docId}`}
                            value={this.context.t(pdfPreviewData.priority === 'high' ? 'Important' : 'Normal')}
                        />

                        {
                            editMode ? (
                                <span className={`arrow ${showPriorityList ? 'expanded' : ''} priority-arrow`} onClick={() => this.showPriorityList()}></span>
                            ) : (
                                null
                            )
                        }

                        <ul className={`priority-list ${showPriorityList ? 'expanded' : 'hide'}`}>
                            <li className={`${pdfPreviewData.priority === 'high' ? 'selected' : ''}`} onClick={() => this.selectPriority('high', pdfPreviewData.docType, pdfPreviewData.docId)}>{this.context.t('Important')}</li>
                            <li className={`${pdfPreviewData.priority === 'regular' ? 'selected' : ''} last`} onClick={() => this.selectPriority('regular', pdfPreviewData.docType, pdfPreviewData.docId)}>{this.context.t('Normal')}</li>
                        </ul>
                    </div>
                }
                <div className="doc-prop">
                    <span className="name">{this.context.t('Comment:')}</span>
                    <TextareaAutosize
                        className={`${!editMode ? 'read-only' : ''}`}
                        //style={{padding: editMode ? '10px' : '0px'}}
                        readOnly={!editMode}
                        name="comment"
                        id={`commentInput-${pdfPreviewData.docId}`}
                        value={pdfPreviewData.comment}
                        onChange={(e) => this.changeInputValue(e, 'comment', pdfPreviewData.docType, pdfPreviewData.docId)}
                    />
                </div>
                <div className="doc-prop">
                    <span className="name">{this.context.t('Document description')}:</span>
                    <TextareaAutosize
                        className={`${!editMode ? 'read-only' : ''}`}
                        //style={{padding: editMode ? '10px' : '0px'}}
                        readOnly={!editMode}
                        name="doc_description"
                        id={`doc_descriptionInput-${pdfPreviewData.docId}`}
                        value={pdfPreviewData.doc_description}
                        onChange={(e) => this.changeInputValue(e, 'doc_description', pdfPreviewData.docType, pdfPreviewData.docId)}
                    />
                </div>

                {
                    ![607, 608].includes(pdfPreviewData.projectId) ? (
                        <TagsComponent
                            currentTags={pdfPreviewData.tags}
                            tagsList={tagsList || []}
                            canAddNewTags={true}
                            setTags={this.setTags}
                            customPlaceholder={editMode ? this.context.t('Add tags') : ' '}
                            customMinWidth={'100px'}
                            readOnly={!editMode}
                        />
                    ) : null
                }

                <div className="doc-prop">
                    {
                        pdfPreviewData.fileInstance && pdfPreviewData.fileInstance.is_correction ? (
                            <span className="name">{this.context.t('Correction number:')}</span>
                        ) : (
                            <span className="name">{this.context.t('Document number:')}</span>
                        )
                    }
                    <TextareaAutosize
                        onHeightChange={(height, instance) => console.log('teaxtarea height change ' + height, instance.rowCount)}
                        className={`${!editMode ? 'read-only' : ''}`}
                        readOnly={!editMode}
                        name="invoiceNumber"
                        id={`invoiceNumberInput-${pdfPreviewData.docId}`}
                        value={pdfPreviewData.invoiceNumber}
                        onChange={(e) => this.changeInputValue(e, 'invoiceNumber', pdfPreviewData.docType, pdfPreviewData.docId)}
                    />

                    {
                        pdfPreviewData.linkedFiles && pdfPreviewData.linkedFiles.length ? (
                            <>
                                {this.showLinkedFiles(pdfPreviewData.linkedFiles)}
                            </>
                        ) : (
                            null
                        )
                    }
                </div>
                {
                    <div className={`checkmark ${!editMode ? 'disabled-mpp' : ''}`} onClick={() => { if (editMode) { this.changePayamentTypeValue(pdfPreviewData.paymentType === 'split' ? 'regular' : 'split', 'paymentType', pdfPreviewData.docType, pdfPreviewData.docId) } }}>
                        {this.context.t('Split payment mechanism')}
                        <div className={`checkbox ${pdfPreviewData.paymentType === 'split' ? 'checked' : ''} ${!editMode ? 'disabled-mpp' : ''}`}></div>
                    </div>
                }

                <PreviewCounterpartyEdit
                    titleText={'Seller'}
                    editMode={editMode}
                />

                <h5>{this.context.t('Buyer')}</h5>
                <div className="doc-prop">
                    <span className="name">{this.context.t('Name')}:</span>
                    <TextareaAutosize
                        className={`read-only`}
                        readOnly={true}
                        name="buyer"
                        id={`buyerInput-${pdfPreviewData.docId}`}
                        value={pdfPreviewData.buyer}
                    />
                </div>
                <div className="doc-prop buyerVatID">
                    <span className="name">{this.context.t('VatID')}:</span>
                    <TextareaAutosize
                        className={`read-only`}
                        readOnly={true}
                        name="buyerVatID"
                        id={`buyerVatIdInput-${pdfPreviewData.docId}`}
                        value={pdfPreviewData.buyerVatId}
                    />
                </div>

                <div className="doc-prop">
                    <span className="name">{this.context.t('Net:')}</span>
                    <TextareaAutosize
                        className={`${!editMode ? 'read-only' : ''}`}

                        readOnly={!editMode} name="netto"
                        id={`nettoInput-${pdfPreviewData.docId}`}
                        value={!editMode ? (this.formatPrice(parseFloat(pdfPreviewData.netto)) + " " + pdfPreviewData.currency) : pdfPreviewData.netto}
                        onChange={e => this.changeInputValue(e, 'netto', pdfPreviewData.docType, pdfPreviewData.docId)}
                    />
                </div>
                <div className="doc-prop">
                    <span className="name">{this.context.t('Gross:')}</span>
                    <TextareaAutosize
                        className={`${!editMode ? 'read-only' : ''}`}
                        readOnly={!editMode} name="brutto"
                        id={`bruttoInput-${pdfPreviewData.docId}`}
                        value={!editMode ? (this.formatPrice(parseFloat(pdfPreviewData.brutto)) + " " + pdfPreviewData.currency) : pdfPreviewData.brutto}
                        onChange={e => this.changeInputValue(e, 'brutto', pdfPreviewData.docType, pdfPreviewData.docId)}
                    />
                </div>
                <div className="doc-prop">
                    <span className="name">{this.context.t('Amount to pay:')}</span>
                    <TextareaAutosize
                        className={`${!editMode ? 'read-only' : ''}`}
                        readOnly={!editMode}
                        name="price"
                        id={`priceInput-${pdfPreviewData.docId}`}
                        value={!editMode ? (this.formatPrice(parseFloat(pdfPreviewData.price)) + " " + pdfPreviewData.currency) : pdfPreviewData.price}
                        onChange={e => this.changeInputValue(e, 'price', pdfPreviewData.docType, pdfPreviewData.docId)}
                    />
                    <span className={`currency-selector ${!editMode ? 'hide' : ''} ${showCurrencyList ? 'list-expended' : ''} z-index-3`}>
                        <span className='current-currency' onClick={() => this.showCurrencyList('T')}>{pdfPreviewData.currency}</span>
                        <span className={`arrow ${showCurrencyList && currencyTriger === 'T' ? 'expanded' : ''} currency-arrow`} onClick={() => this.showCurrencyList('T')}></span>
                        <ul className={`currency-list ${showCurrencyList && currencyTriger === 'T' ? 'expanded' : 'hide'}`}>
                            {
                                currencyList.map(c => {
                                    return (
                                        <li className={`${pdfPreviewData.currency === c ? 'selected' : ''}`} onClick={() => this.selectCurrency(c, pdfPreviewData.docType, pdfPreviewData.docId)}>{c}</li>
                                    )
                                })
                            }
                        </ul>
                    </span>
                </div>

                <div className="doc-prop">
                    <span className="name">{this.context.t('Account number:')}</span>
                    <TextareaAutosize
                        className={`${!editMode ? 'read-only' : ''}`}
                        readOnly={!editMode}
                        name="accountNumber"
                        id={`accountNumberInput-${pdfPreviewData.docId}`}
                        value={this.polihAccountPritier(pdfPreviewData.accountNumber)}
                        onChange={e => this.changeInputValue(e, 'accountNumber', pdfPreviewData.docType, pdfPreviewData.docId)}
                    />
                </div>
                {
                    pdfPreviewData.currency && pdfPreviewData.currency !== 'PLN' ? (
                        <div className="doc-prop">
                            <span className="name">{this.context.t('BIC/SWIFT:')}</span>
                            <TextareaAutosize
                                className={`${!editMode ? 'read-only' : ''}`}
                                readOnly={!editMode} name="swift"
                                id={`swiftInput-${pdfPreviewData.docId}`}
                                value={pdfPreviewData.swift}
                                onChange={(e) => this.changeInputValue(e, 'swift', pdfPreviewData.docType, pdfPreviewData.docId)}
                            />
                        </div>
                    ) : (
                        null
                    )
                }
                <div className="doc-prop issue-date-container">
                    <span className="name">{this.context.t('Issue date:')}</span>
                    <TextareaAutosize
                        className={`${!editMode ? 'read-only' : ''}`}
                        readOnly={true}
                        name="issueDate"
                        id={`issueDateInput-${pdfPreviewData.docId}`}
                        value={pdfPreviewData.issueDate}
                        onChange={e => this.changeInputValue(e, 'issueDate', pdfPreviewData.docType, pdfPreviewData.docId)}
                        onFocus={e => this.props.showIssueDateDocCalendar(e)}
                    />
                </div>
                <div className="doc-prop sale-date-container">
                    <span className="name">{this.context.t('Sale date:')}</span>
                    <TextareaAutosize
                        className={`${!editMode ? 'read-only' : ''}`}
                        readOnly={true}
                        name="saleDate"
                        id={`saleDateInput-${pdfPreviewData.docId}`}
                        value={pdfPreviewData.saleDate}
                        onChange={e => this.changeInputValue(e, 'saleDate', pdfPreviewData.docType, pdfPreviewData.docId)}
                        onFocus={e => this.props.showSaleDateDocCalendar(e)}
                    />
                </div>
                <div className="doc-prop due-date-container">
                    <span className="name">{this.context.t('Due date:')}</span>
                    <TextareaAutosize
                        className={`${!editMode ? 'read-only' : ''}`}
                        readOnly={true}
                        name="dueDate"
                        id={`dueDateInput-${pdfPreviewData.docId}`}
                        value={pdfPreviewData.dueDate}
                        onChange={e => this.changeInputValue(e, 'dueDate', pdfPreviewData.docType, pdfPreviewData.docId)}
                        onFocus={e => this.props.showDueDateDocCalendar(e)}
                    />
                </div>

                {
                    (waproUsers.includes(user.id) && isAccountantInCurrentCompany) ? (
                        <>
                            <span className="separator wapro">{this.context.t('Required fields for documents imported to WAPRO Fakir')}</span>

                            <div className={`doc-prop wapro-fakir-dictionary-container ${showFakirDictionary ? 'show-wapro-list' : ''}`}>
                                <span className="name">{this.context.t('Fakir dictionary:')}</span>
                                <TextareaAutosize
                                    className={`${!editMode ? 'read-only' : ''}`}
                                    readOnly={true}
                                    name="waproFakirDictionary"
                                    id={`waproFakirDictionary-${pdfPreviewData.docId}`}
                                    value={pdfPreviewData.waproData?.fakirDictionary || ''}
                                    onChange={e => this.changeInputValue(e, 'waproData.fakirDictionary', pdfPreviewData.docType, pdfPreviewData.docId)}
                                    onFocus={e => this.toggleFakirDictionaryList(true)}
                                />
                                {
                                    showFakirDictionary && editMode ? (
                                        <DropDownList
                                            list={fakirDictionaryList}
                                            selectedOption={pdfPreviewData.waproData?.fakirDictionary || ''}
                                            select={value => this.selectWaproField(value, "fakirDictionary")}
                                            headerText={this.context.t('Available logs:')}
                                        />
                                    ) : (
                                        null
                                    )
                                }
                            </div>

                            <div className={`doc-prop wapro-mag-container ${showMagDocType ? 'show-wapro-list' : ''}`}>
                                <span className="name">{this.context.t('Mag type:')}</span>
                                <TextareaAutosize
                                    className={`${!editMode ? 'read-only' : ''}`}
                                    readOnly={true}
                                    name="waproMagType"
                                    id={`waproMagType-${pdfPreviewData.docId}`}
                                    value={pdfPreviewData.waproData?.magType || ''}
                                    onChange={e => this.changeInputValue(e, 'waproData.magType', pdfPreviewData.docType, pdfPreviewData.docId)}
                                    onFocus={e => this.toggleMagDocTypeList(true)}
                                />
                                {
                                    showMagDocType && editMode ? (
                                        <DropDownList
                                            list={magDocTypeList}
                                            selectedOption={pdfPreviewData.waproData?.magType || ''}
                                            select={value => this.selectWaproField(value, "magType")}
                                            headerText={this.context.t('Available types:')}
                                        />
                                    ) : (
                                        null
                                    )
                                }
                            </div>

                            <div className={`doc-prop  wapro-fakir-type-container ${showFakirDocType ? 'show-wapro-list' : ''}`}>
                                <span className="name">{this.context.t('Fakir type:')}</span>
                                <TextareaAutosize
                                    className={`${!editMode ? 'read-only' : ''}`}
                                    readOnly={true}
                                    name="waproFakirType"
                                    id={`waproFakirType-${pdfPreviewData.docId}`}
                                    value={pdfPreviewData.waproData?.fakirType || ''}
                                    onChange={e => this.changeInputValue(e, 'waproData.fakirType', pdfPreviewData.docType, pdfPreviewData.docId)}
                                    onFocus={e => this.toggleFakirDocTypeList(true)}
                                />
                                {
                                    showFakirDocType && editMode ? (
                                        <DropDownList
                                            list={fakirDocTypeList}
                                            selectedOption={pdfPreviewData.waproData?.fakirType || ''}
                                            select={value => this.selectWaproField(value, "fakirType")}
                                            headerText={this.context.t('Available types:')}
                                        />
                                    ) : (
                                        null
                                    )
                                }
                            </div>


                            <div className={`doc-prop wapro-net-account-container ${showWaproNetAccountList ? 'show-wapro-list' : ''}`}>
                                <span className="name">{this.context.t('Fakir net account:')}</span>
                                <TextareaAutosize
                                    className={`${!editMode ? 'read-only' : ''}`}
                                    readOnly={true}
                                    name="waproNetAccount"
                                    id={`waproNetAccount-${pdfPreviewData.docId}`}
                                    value={pdfPreviewData.waproData?.netAccount || ''}
                                    onChange={e => this.changeInputValue(e, 'waproData.netAccount', pdfPreviewData.docType, pdfPreviewData.docId)}
                                    onFocus={e => this.toggleWaproNetAccountList(true)}
                                />

                                {
                                    showWaproNetAccountList && netAccountList && netAccountList.length > 0 && editMode ? (
                                        <DropDownList
                                            list={netAccountList}
                                            selectedOption={pdfPreviewData.waproData?.netAccount || ''}
                                            select={value => this.selectWaproField(value, "netAccount")}
                                            headerText={this.context.t('Available net accounts:')}
                                        />
                                    ) : (
                                        null
                                    )
                                }
                            </div>
                            {
                                pdfPreviewData.waproData?.showVatRateSelector ? (
                                    <div className={`doc-prop wapro-vat-rate-container ${showWaproVatRate ? 'show-wapro-list' : ''}`}>
                                        <span className="name">{this.context.t('VAT rate')}:</span>
                                        <TextareaAutosize
                                            className={`${!editMode ? 'read-only' : ''}`}
                                            readOnly={true}
                                            name="waproVatRate"
                                            id={`waproVatRate-${pdfPreviewData.docId}`}
                                            value={pdfPreviewData.waproData?.vatRate || ''}
                                            onFocus={e => this.toggleWaproVatRate(true)}
                                        />
                                        {
                                            showWaproVatRate && editMode ? (
                                                <DropDownList
                                                    list={WAPRO_VAT_RATES}
                                                    selectedOption={pdfPreviewData.waproData?.vatRate || ''}
                                                    select={value => this.selectWaproField(value, "vatRate")}
                                                    headerText={this.context.t('')}
                                                />
                                            ) : (
                                                null
                                            )
                                        }
                                    </div>
                                ) : null
                            }

                            <div className={`doc-prop wapro-doc-description-container ${showWaproDocDescriptionList ? 'show-wapro-list' : ''}`}>
                                <span className="name">{this.context.t('Description:')}</span>
                                <TextareaAutosize
                                    className={`${!editMode ? 'read-only' : ''}`}
                                    readOnly={!editMode}
                                    name="docDescription"
                                    id={`docDescription-${pdfPreviewData.docId}`}
                                    value={pdfPreviewData.waproData?.docDescription}
                                    onChange={e => this.changeInputValue(e, 'waproData.docDescription', pdfPreviewData.docType, pdfPreviewData.docId)}
                                    onFocus={e => this.toggleDocDescriptionList(true)}
                                />

                                {
                                    showWaproDocDescriptionList && editMode ? (
                                        <DropDownList
                                            list={docDescriptionsList}
                                            selectedOption={pdfPreviewData.waproData?.docDescription}
                                            select={value => this.selectWaproField(value, "docDescription")}
                                            headerText={this.context.t('Available descriptions:')}
                                        />
                                    ) : (
                                        null
                                    )
                                }
                            </div>

                            <div className="accounted-doc">
                                <span
                                    className={`checkbox ${pdfPreviewData.waproData?.cashAccountingScheme ? 'checked' : ''}`}
                                    onClick={e => editMode ? this.selectWaproField(!pdfPreviewData.waproData?.cashAccountingScheme, "cashAccountingScheme") : null}
                                ></span>
                                <p
                                    onClick={e => editMode ? this.selectWaproField(!pdfPreviewData.waproData?.cashAccountingScheme, "cashAccountingScheme") : null}
                                    style={{ width: 'fit-content' }}
                                >
                                    {this.context.t("Metoda kasowa")}
                                </p>
                            </div>
                        </>
                    ) : (
                        null
                    )
                }

                {
                    user.id === 2 || [1607, 1610].includes(docOwnerCompany?.id) ? (
                        <>
                            <span className="separator wapro">{this.context.t('Required fields for documents imported to Symfonia')}</span>

                            <OcrPageSymfoniaDocTypes
                                selectedOption={pdfPreviewData.symfoniaData?.docType || {}}
                                list={this.state.symfoniaDocTypes}
                                select={this.setSymfoniaDocType}
                                readOnly={!editMode}
                            />
                            <OcrPageSymfoniaDocDescription
                                selectedOption={pdfPreviewData.symfoniaData?.docDescription}
                                select={this.setSymfoniaDocDescription}
                                readOnly={!editMode}
                            />
                            <OcrPageSymfoniaNetAccount
                                selectedOption={pdfPreviewData.symfoniaData?.netAccount}
                                list={this.state.symfoniaAccounts}
                                select={this.selectSymfoniaNetAccount}
                                readOnly={!editMode}
                            />
                            <OcrPageSymfoniaVatAccount
                                selectedOption={pdfPreviewData.symfoniaData?.vatAccount}
                                list={this.state.symfoniaAccounts}
                                select={this.selectSymfoniaVatAccount}
                                readOnly={!editMode}
                            />
                            <OcrPageSymfoniaGrossAccount
                                selectedOption={pdfPreviewData.symfoniaData?.grossAccount}
                                list={this.state.symfoniaAccounts}
                                select={this.selectSymfoniaGrossAccount}
                                readOnly={!editMode}
                            />
                            <OcrPageSymfoniaRegisters
                                symfoniaRegisters={pdfPreviewData.symfoniaData?.vatRegisters || []}
                                ratesList={this.state.symfoniaVatRates}
                                registersList={this.state.symfoniaRegisters}
                                editSymfoniaRegisters={this.editSymfoniaRegisters}
                                readOnly={!editMode}
                            />
                        </>
                    ) : (
                        null
                    )
                }

                {
                    devCostsProjects.includes(pdfPreviewData.projectId) && devCostsUsers.includes(user.id) && user.id !== 2 ? (
                        <div className="dev-costs-container">
                            <span className="separator wapro">{this.context.t('Required fields for DevCosts')}</span>
                            <ProjectCode
                                projectId={pdfPreviewData.projectId}
                                selectedOption={pdfPreviewData.wl_project_code}
                                select={this.WL_setProjectCode}
                                editMode={editMode}
                            />
                            <Technology
                                selectedOption={pdfPreviewData.wl_technology}
                                select={this.WL_setTechnology}
                                editMode={editMode}
                            />
                            <DevExCode
                                selectedOption={pdfPreviewData.wl_devex_type_1}
                                select={this.WL_setDevExType1}
                                editMode={editMode}
                            />
                            <DevExType
                                selectedOption={pdfPreviewData.wl_devex_type_2}
                                devExCode={pdfPreviewData.wl_devex_type_1}
                                select={this.WL_setDevExType2}
                                editMode={editMode}
                            />
                            <TransactionDescription
                                lang={'PL'}
                                selectedOption={pdfPreviewData.wl_transaction_description_pl}
                                onChange={this.WL_setTransactionDescriptionPl}
                                editMode={editMode}
                            />
                            {/* <TransactionDescription
                                lang={'EN'}
                                selectedOption={pdfPreviewData.wl_transaction_description_en}
                                onChange={this.WL_setTransactionDescriptionEn}
                                editMode={editMode}
                            /> */}
                        </div>
                    ) : null
                }

                {
                    gfProjects.includes(pdfPreviewData.projectId) && pdfPreviewData.costSplit?.length ? (
                        <CostSplit
                            customTitle={'Division of payment into cost categories'}
                            fileId={pdfPreviewData?.id}
                            ftvId={0}
                            totalNetAmount={pdfPreviewData.netto}
                            totalGrossAmount={pdfPreviewData.brutto}
                            costSplitAllocation={pdfPreviewData.costSplit}
                            setCostSplitAllocation={this.setCostSplitAllocation}
                            setCombinedTags={this.setTags}
                            tagsList={tagsList}
                            canAddNewTags={true}
                            splitDocAmount={() => { }}
                            isInDocPreview={true}
                            readOnly={!editMode}
                            isAccountantInCurrentCompany={isAccountantInCurrentCompany}
                            companyId={docOwnerCompany?.id || 0}
                        />
                    ) : null
                }

                {
                    waProjects.includes(pdfPreviewData.projectId) && pdfPreviewData.mpkSplitWineAvenue?.length ? (
                        <MpkSplitWineAvenue
                            dataIsSyncesInOcr={true}
                            customTitle={'Division of payment into MPK'}
                            fileId={pdfPreviewData?.id}
                            ftvId={0}
                            totalNetAmount={pdfPreviewData.netto}
                            mpkSplitWineAvenue={pdfPreviewData.mpkSplitWineAvenue}
                            setMpkSplit={this.setMpkSplitWineAvenue}
                            isInDocPreview={true}
                            readOnly={!editMode}
                            isAccountantInCurrentCompany={isAccountantInCurrentCompany}
                            companyId={docOwnerCompany?.id || 0}
                        />
                    ) : null
                }

                {
                    [13, 633].includes(pdfPreviewData.projectId) && pdfPreviewData.mpkSplitWineAvenue?.length ? (
                        <MpkSplitAventine
                            dataIsSyncesInOcr={true}
                            customTitle={'Division of payment into MPK'}
                            fileId={pdfPreviewData?.id}
                            ftvId={0}
                            totalNetAmount={pdfPreviewData.netto}
                            mpkSplitAventine={pdfPreviewData.mpkSplitWineAvenue}
                            setMpkSplit={this.setMpkSplitWineAvenue}
                            isInDocPreview={true}
                            readOnly={!editMode}
                            isAccountantInCurrentCompany={isAccountantInCurrentCompany}
                            companyId={docOwnerCompany?.id || 0}
                        />
                    ) : null
                }
            </div>
        )
    }
}

CostsFrom.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    pdfPreviewData: state.File.pdfPreviewData,
    user: state.User.user,
})

const mapDispatchToProps = {
    changePaymentMethodInPdfBackup: FileActions.changePaymentMethodInPdfBackup,
    editDocInput: FileActions.editDocInput,
    toogleAdditionalAcceptanceForDoc: ReportsActions.toogleAdditionalAcceptanceForDoc,
    tooglePaymentForDoc: ReportsActions.tooglePaymentForDoc,
    getCompanySymfoniaFile: CompanyActions.getCompanySymfoniaFile,
}

export default connect(mapStateToProps, mapDispatchToProps)(CostsFrom)