import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { AlertActions } from '../actions/AlertActions';
import { Detector } from "react-detect-offline";

export class AlertsComponent extends Component {

    componentWillReceiveProps(nextProps) {
        nextProps.alerts.alerts.map(alert => {
            if (alert.delay > 0) {
                setTimeout(() => {
                    this.props.clear(alert.id)
                }, alert.delay)
                return null
            }
            return null
        })
    }

    render() {
        const { alerts } = this.props.alerts
        return (
            <div className="alert-container">
                <Detector
                    render={({ online }) => (
                        alerts.map((alert, index) =>
                            <div key={index} className={`alert alert-${alert.type === 'danger' && !online ? 'info' : alert.type} alert-dismissible fade show`} role="alert">
                                {
                                    alert.docNumbers !== undefined ? (
                                        this.context.t(alert.message, { n: alert.docNumbers })
                                    ) : (
                                        alert.type === 'danger' && !online ? (
                                            this.context.t("Connection problem - check your internet.")
                                        ) : (
                                            this.context.t(alert.message)
                                        )
                                    )
                                }
                                <button type="button" className="close" onClick={e => this.props.clear(alert.id)}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        )
                    )}
                />
            </div>
        )
    }
}

AlertsComponent.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    alerts: state.Alert
})

const mapDispatchToProps = {
    clear: AlertActions.clear
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertsComponent)
