import React, { Component } from 'react'
import { DragSource, DropTarget } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend';
import { findDOMNode } from 'react-dom';
import flow from 'lodash/flow';
import PropTypes from 'prop-types'

const itemSource = {
    beginDrag(props) {
        return {
            index: props.index,
            listName: props.listName,
            item: props.item,
            id: props.item.dataType
        };
    },

    isDragging(props, monitor) {
        return props.item.dataType === monitor.getItem().id;
    },

    endDrag(props, monitor) {
        // When dropped on a compatible target, do something
        const object = monitor.getItem();
        const dropResult = monitor.getDropResult();
        if (dropResult) {
            props.handleDrop(object.item, monitor.getDropResult().name)
        }

        const didDrop = monitor.didDrop()
        if(!didDrop) {
           props.removeAllPseudoElements()
        }
    }
}

const itemTarget = {
    hover(props, monitor, component) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;
        const sourceList = monitor.getItem().listName;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
        }

        // Time to actually perform the action
        if (props.listName === sourceList) {
            props.moveItem(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            monitor.getItem().index = hoverIndex;
        } else if(props.listName === 'selected' && sourceList === 'available') {
            props.moveNewItem(monitor.getItem().item, hoverIndex);
            monitor.getItem().index = hoverIndex;
        }
    }
};

function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging(),
    }
}

export class DropItem extends Component {

    componentDidMount() {
        this.props.connectDragPreview(getEmptyImage(), {
            captureDraggingState: true,
        });
    }

    handleRowSelection = (cmdKey, shiftKey, index) => {
        console.log(cmdKey)
        this.props.handleSelection(index, cmdKey, shiftKey);
    }

    render() {
        const { isDragging, connectDragSource, connectDropTarget, item, index} = this.props;

        return connectDragSource(connectDropTarget(
            <div className={`drag-item ${isDragging || item.isPseudo ? 'is-dragging' : ''}`} key={item.dataTypeName}>
                {
                    item.column || item.isPseudo ? (
                        <div className="column">
                            <div className="column-number">{item.column}</div>
                            <div className="column-name selected">{this.context.t(item.dataTypeName)}</div>
                            <input className="column-label" type="text" value={item.label} onChange={e => this.props.onInputChange(e, index)} onBlur={e => this.props.onInputBlur(e)} placeholder={this.context.t(item.dataTypeName)} />
                        </div>
                    ) : (
                        <div className="column">
                            <div className="column-name available">{this.context.t(item.dataTypeName)}</div>
                        </div >
                    )
                }
            </div>
        ))
    }
}

DropItem.contextTypes = {
    t: PropTypes.func
}

export default flow(
    DropTarget("item", itemTarget, connect => ({
        connectDropTarget: connect.dropTarget()
    })),
    DragSource('item', itemSource, (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging(),
    }))
)(DropItem)