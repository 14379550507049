import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import arrayObjectIndexOf from "../../../helpers/indexOfObject"

import PdfPreview from '../../PdfPreview'
import TransferPreview from '../../TransferPreview'

import { FileActions } from '../../../actions/FileActions'

export class FilesPreviewControlerForUnpaidDocs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            projectIndex: -1
        }
    }

    checkNext = () => {
        const { files, previewFile } = this.props
        const { projectIndex } = this.state

        var currentIndex = arrayObjectIndexOf(files, previewFile.id, 'id')

        if (currentIndex - 1 >= 0 && this.findNextDocMatchingFilterOptions(currentIndex - 1, -1) &&
            currentIndex + 1 < files.length && this.findNextDocMatchingFilterOptions(currentIndex + 1, 1)) {
            return { index: currentIndex, prev: true, next: true }
        } else if (currentIndex - 1 > 0 && this.findNextDocMatchingFilterOptions(currentIndex - 1, -1)) {
            return { index: currentIndex, prev: true, next: false }
        } else if (currentIndex + 1 < files.length && this.findNextDocMatchingFilterOptions(currentIndex + 1, 1)) {
            return { index: currentIndex, prev: false, next: true }
        } else {
            return { index: currentIndex, prev: false, next: false }
        }
    }

    findNextDocMatchingFilterOptions = (startIndex, direction) => {
        const { files } = this.props
        const { projectIndex } = this.state

        var nextDoc = files[startIndex]
        var nextDocMatchesFilter = this.checkIfDocMatchesFilterOptions(nextDoc)

        while (!nextDocMatchesFilter) {
            startIndex += direction
            if (files[startIndex]) {
                nextDoc = files[startIndex]
                nextDocMatchesFilter = this.checkIfDocMatchesFilterOptions(nextDoc)
            } else break
        }

        console.log('nextDoc')
        console.log(nextDoc)
        if (nextDocMatchesFilter) return nextDoc
        return null

    }

    checkIfDocMatchesFilterOptions = file => {
        if (file && file.accepted_by && file.ocr_status !== 'DONE' && file.path && !file.path.includes('deleted/')) return true
    }

    goToDoc = (index, direction) => {
        const { project } = this.props

        var nextDoc = this.findNextDocMatchingFilterOptions(index, direction)

        if (nextDoc) {
            this.props.getFileBody(nextDoc.project_id, nextDoc.path, nextDoc.id, nextDoc.ocr_status, nextDoc.acceptance_step)

            this.props.setCurrentDoc({
                id: nextDoc.id
            })
        }
    }

    render() {
        const { pdfPreviewOpen, previewClickFromChatNotification } = this.props
        if (pdfPreviewOpen && !previewClickFromChatNotification) {
            return (
                <PdfPreview prevAndNext={this.checkNext()} goToDoc={this.goToDoc} close={this.props.close} customGoToDocControler={true} />
            )
        } else {
            return null
        }
    }
}

FilesPreviewControlerForUnpaidDocs.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    pdfPreviewOpen: state.File.pdfPreviewOpen,
    previewClickFromChatNotification: state.File.previewClickFromChatNotification
})

const mapDispatchToProps = {
    getFileBody: FileActions.getFileData,
    togglePdfPreview: FileActions.togglePdfPreview
}

export default connect(mapStateToProps, mapDispatchToProps)(FilesPreviewControlerForUnpaidDocs)