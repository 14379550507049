import moment from 'moment'
import myFetch from "../helpers/fetch"
import myDownload from "../helpers/download"
import { partialRequests, setStatusForDoc } from "../helpers/getWiteListStatusForReports"

import { FileConst } from "../reducers/FileReducer"
import { ProjectCostsConsts } from "../reducers/ProjectCostsReducer"
import { TransferConst } from "../reducers/TransfersReducer"
import { ReportsConst } from "../reducers/ReportsReducer"
import { ExcelSesionConsts } from "../reducers/ExcelSessionReducer"
import { CompanyConst } from "../reducers/CompanyReducer"
import { EventConst } from "../reducers/EventReducer"
import { UserConst } from "../reducers/UserReducer"
import { AlertActions } from "./AlertActions"

export const ReportsActions = {
    selectStartDate,
    unselectStartDate,
    selectEndDate,
    unselectEndDate,
    getDocuments,
    checkVatIdInVies,
    getDocumentsCrossProjects,
    switchDocsSortingFilter,
    tooglePaymentForDoc,
    tooglePaymentForIncomeDoc,
    toogleAdditionalAcceptanceForDoc,
    toogleCanBeAccountmentStatusForDoc,
    toogleCanBeAccountmentStatusForTransfer,
    toogleAccountmentStatusForDoc,
    toogleAccountmentStatusForIncomeDoc,
    tooglePaymentForTransfer,
    toogleAccountmentStatusForTransfer,
    markDocsAsInReport,
    generateReport,
    generateExpensesReport,
    generateWaproXML,
    generateSymfoniaXML,
    generateCustomReport,
    generateCustomAgreementsReport,
    generateCustomIncomeReport,
    markDocsAsInBankTransfersFile,
    generateBankTransfersFile,
    generateBankTransfetFileForInternationalTransfers,
    exportDocsToAccounting,
    getUsersListForExpensesReport,
    bulkUpdatedTagsForFiles,
    bulkUpdatedTagsForPaymentOrders,

    getMyReportsList,
    getMyZips,
    getMyBankTransferFilesList,
    getMyAccountingFilesList,
    deleteReport,
    deleteZip,
    deleteBankTransferFile,
    deleteAccountingFile,
    downloadReport,
    downloadReportFiles,
    downloadZip,
    downloadBankTransferFileFiles,
    downloadAccountingFile,
    downloadAccountingFileDocs,
    switchSortingFilterReports,
    switchSortingFilterForZip,
    switchBankTransferFilesFilterReports,
    switchSortingFilterAccountingFiles,
}

function selectStartDate(date) {
    return dispatch => {
        dispatch({ type: ReportsConst.START_DATE_PICKER_SELECT_DAY, day: { display: moment(date).format('YYYY-MM-DD'), value: moment(moment(date).format('DD-MM-YYYY'), 'DD-MM-YYYY') } })
    }
}

function unselectStartDate() {
    return dispatch => {
        dispatch({ type: ReportsConst.START_DATE_PICKER_SELECT_DAY, day: { display: null, value: null } })
    }
}

function selectEndDate(date) {
    return dispatch => {
        dispatch({ type: ReportsConst.END_DATE_PICKER_SELECT_DAY, day: { display: moment(date).format('YYYY-MM-DD'), value: date } })
    }
}

function unselectEndDate() {
    return dispatch => {
        dispatch({ type: ReportsConst.END_DATE_PICKER_SELECT_DAY, day: { display: null, value: null } })
    }
}

function getDocuments(startDate, endDate, projectId, docType, dateFilter, counterpartyId, additionalFilters = {}) {
    return dispatch => {
        console.log(docType)
        dispatch({ type: ReportsConst.SEND_GET_DOCUMENTS_REQUEST, projectId: projectId })
        dispatch({ type: ReportsConst.SET_WAITING_FOR_WHITE_LIST_RESPONSE, waiting: false })
        if (docType === 'Costs' || docType === 'Expenses' || docType === 'Mag') {
            var filter = {
                dateFilter: dateFilter,
                start: moment(startDate).format('DD-MM-YYYY'),
                end: moment(endDate).format('DD-MM-YYYY'),
                projectId: projectId,
                type: docType,
                counterpartyId: counterpartyId,
                additionalFilters: additionalFilters
            }
            myFetch(`/docs/documents-for-report/?filter=${JSON.stringify(filter)}`, { method: 'get' })
                .then(res => {
                    dispatch({ type: ReportsConst.GET_DOCUMENTS, projectId: projectId, docs: res.docs, docType: docType })
                    if (!res.success) {
                        dispatch(AlertActions.warning(res.msg))
                    } else {
                        if (res.nipsToCheckOnWhiteList && res.nipsToCheckOnWhiteList.length > 0) {
                            dispatch({ type: ReportsConst.SET_WAITING_FOR_WHITE_LIST_RESPONSE, waiting: true })
                            partialRequests(res.nipsToCheckOnWhiteList, (whiteListResults, timeout) => {
                                if (timeout) {
                                    dispatch({ type: ReportsConst.GET_DOCUMENTS_WHIT_LIST_STATUS_TIMEOUT })
                                } else {
                                    setStatusForDoc([...res.docs], whiteListResults, docsWithstatus => {
                                        dispatch({ type: ReportsConst.GET_DOCUMENTS, projectId: projectId, docs: docsWithstatus, docType: docType, withWhiteListStatus: true })
                                    })
                                }
                            })
                        } else {
                            setStatusForDoc([...res.docs], [], docsWithstatus => {
                                dispatch({ type: ReportsConst.GET_DOCUMENTS, projectId: projectId, docs: docsWithstatus, docType: docType })
                            })
                        }
                    }
                }).catch(err => {
                    handleError(err, dispatch, `/docs/documents-for-report/?filter=${JSON.stringify(filter)}`)
                })
        } else if (docType === 'Agreements') {
            var filter = {
                dateFilter: dateFilter,
                start: moment(startDate).format('DD-MM-YYYY'),
                end: moment(endDate).format('DD-MM-YYYY'),
                projectId: projectId,
                counterpartyId: counterpartyId
            }
            myFetch(`/agreements/get-for-report/?filter=${JSON.stringify(filter)}`, { method: 'get' })
                .then(res => {
                    dispatch({ type: ReportsConst.GET_DOCUMENTS, projectId: projectId, docs: res.agreements, docType: docType })
                }).catch(err => {
                    handleError(err, dispatch, `/cash-transfer-orders/transfers-for-report/?filter=${JSON.stringify(filter)}`)
                })
        } else if (docType === 'Correspondence') {
            var filter = {
                dateFilter: dateFilter,
                start: moment(startDate).format('DD-MM-YYYY'),
                end: moment(endDate).format('DD-MM-YYYY'),
                projectId: projectId,
                counterpartyId: counterpartyId
            }
            myFetch(`/correspondence/get-for-report/?filter=${JSON.stringify(filter)}`, { method: 'get' })
                .then(res => {
                    dispatch({ type: ReportsConst.GET_DOCUMENTS, projectId: projectId, docs: res.correspondence, docType: docType })
                }).catch(err => {
                    handleError(err, dispatch, `/cash-transfer-orders/transfers-for-report/?filter=${JSON.stringify(filter)}`)
                })
        } else if (docType === 'Corporate') {
            var filter = {
                dateFilter: dateFilter,
                start: moment(startDate).format('DD-MM-YYYY'),
                end: moment(endDate).format('DD-MM-YYYY'),
                projectId: projectId,
                counterpartyId: counterpartyId
            }
            myFetch(`/corporate/get-for-report/?filter=${JSON.stringify(filter)}`, { method: 'get' })
                .then(res => {
                    dispatch({ type: ReportsConst.GET_DOCUMENTS, projectId: projectId, docs: res.corporate, docType: docType })
                }).catch(err => {
                    handleError(err, dispatch, `/cash-transfer-orders/transfers-for-report/?filter=${JSON.stringify(filter)}`)
                })
        } else if (docType === 'Other') {
            var filter = {
                dateFilter: dateFilter,
                start: moment(startDate).format('DD-MM-YYYY'),
                end: moment(endDate).format('DD-MM-YYYY'),
                projectId: projectId,
                counterpartyId: counterpartyId
            }
            myFetch(`/other-docs/get-for-report/?filter=${JSON.stringify(filter)}`, { method: 'get' })
                .then(res => {
                    dispatch({ type: ReportsConst.GET_DOCUMENTS, projectId: projectId, docs: res.otherDocs, docType: docType })
                }).catch(err => {
                    handleError(err, dispatch, `/cash-transfer-orders/transfers-for-report/?filter=${JSON.stringify(filter)}`)
                })
        } else if (docType === 'Income') {
            var filter = {
                dateFilter: dateFilter,
                start: moment(startDate).format('DD-MM-YYYY'),
                end: moment(endDate).format('DD-MM-YYYY'),
                projectId: projectId
            }
            myFetch(`/sale-invoices/documents-for-report/?filter=${JSON.stringify(filter)}`, { method: 'get' })
                .then(res => {
                    dispatch({ type: ReportsConst.GET_DOCUMENTS, projectId: projectId, docs: res.incomeDocs, docType: docType })
                }).catch(err => {
                    handleError(err, dispatch, `/sale-invoices/documents-for-report/?filter=${JSON.stringify(filter)}`)
                })
        }
    }
}

function checkVatIdInVies(vatIdToCheck) {
    return dispatch => {
        return myFetch(`/docs/is-vatid-in-vies`, {
            method: 'post', body: {
                vatIdToCheck: vatIdToCheck
            }
        })
    }
}

function getDocumentsCrossProjects(startDate, endDate, projects, docType, dateFilter, counterpartyId, additionalFilters = {}) {
    return dispatch => {
        console.log(docType)
        dispatch({ type: ReportsConst.SEND_GET_DOCUMENTS_REQUEST, projectId: projects[0] })
        dispatch({ type: ReportsConst.SET_WAITING_FOR_WHITE_LIST_RESPONSE, waiting: false })
        if (docType === 'Costs' || docType === 'Expenses' || docType === 'Mag') {
            var filter = {
                dateFilter: dateFilter,
                start: moment(startDate).format('DD-MM-YYYY'),
                end: moment(endDate).format('DD-MM-YYYY'),
                projects: projects,
                type: docType,
                counterpartyId: counterpartyId,
                additionalFilters: additionalFilters
            }
            myFetch(`/docs/documents-for-report/?filter=${JSON.stringify(filter)}`, { method: 'get' })
                .then(res => {
                    dispatch({ type: ReportsConst.GET_DOCUMENTS, projectId: projects[0], docs: res.docs, docType: docType })
                    if (!res.success) {
                        dispatch(AlertActions.warning(res.msg))
                    } else {
                        if (res.nipsToCheckOnWhiteList && res.nipsToCheckOnWhiteList.length > 0) {
                            dispatch({ type: ReportsConst.SET_WAITING_FOR_WHITE_LIST_RESPONSE, waiting: true })
                            partialRequests(res.nipsToCheckOnWhiteList, (whiteListResults, timeout) => {
                                if (timeout) {
                                    dispatch({ type: ReportsConst.GET_DOCUMENTS_WHIT_LIST_STATUS_TIMEOUT })
                                } else {
                                    setStatusForDoc(res.docs, whiteListResults, docsWithstatus => {
                                        dispatch({ type: ReportsConst.GET_DOCUMENTS, projectId: projects[0], docs: docsWithstatus, docType: docType, withWhiteListStatus: true })
                                    })
                                }
                            })
                        } else {
                            setStatusForDoc(res.docs, [], docsWithstatus => {
                                dispatch({ type: ReportsConst.GET_DOCUMENTS, projectId: projects[0], docs: docsWithstatus, docType: docType })
                            })
                        }
                    }
                }).catch(err => {
                    handleError(err, dispatch, `/docs/documents-for-report/?filter=${JSON.stringify(filter)}`)
                })
        } else if (docType === 'Agreements') {
            var filter = {
                dateFilter: dateFilter,
                start: moment(startDate).format('DD-MM-YYYY'),
                end: moment(endDate).format('DD-MM-YYYY'),
                projects: projects,
                counterpartyId: counterpartyId
            }
            myFetch(`/agreements/get-for-report/?filter=${JSON.stringify(filter)}`, { method: 'get' })
                .then(res => {
                    dispatch({ type: ReportsConst.GET_DOCUMENTS, projectId: projects[0], docs: res.agreements, docType: docType })
                }).catch(err => {
                    handleError(err, dispatch, `/cash-transfer-orders/transfers-for-report/?filter=${JSON.stringify(filter)}`)
                })
        } else if (docType === 'Correspondence') {
            var filter = {
                dateFilter: dateFilter,
                start: moment(startDate).format('DD-MM-YYYY'),
                end: moment(endDate).format('DD-MM-YYYY'),
                projects: projects,
                counterpartyId: counterpartyId
            }
            myFetch(`/correspondence/get-for-report/?filter=${JSON.stringify(filter)}`, { method: 'get' })
                .then(res => {
                    dispatch({ type: ReportsConst.GET_DOCUMENTS, projectId: projects[0], docs: res.correspondence, docType: docType })
                }).catch(err => {
                    handleError(err, dispatch, `/cash-transfer-orders/transfers-for-report/?filter=${JSON.stringify(filter)}`)
                })
        } else if (docType === 'Corporate') {
            var filter = {
                dateFilter: dateFilter,
                start: moment(startDate).format('DD-MM-YYYY'),
                end: moment(endDate).format('DD-MM-YYYY'),
                projects: projects,
                counterpartyId: counterpartyId
            }
            myFetch(`/corporate/get-for-report/?filter=${JSON.stringify(filter)}`, { method: 'get' })
                .then(res => {
                    dispatch({ type: ReportsConst.GET_DOCUMENTS, projectId: projects[0], docs: res.corporate, docType: docType })
                }).catch(err => {
                    handleError(err, dispatch, `/cash-transfer-orders/transfers-for-report/?filter=${JSON.stringify(filter)}`)
                })
        } else if (docType === 'Other') {
            var filter = {
                dateFilter: dateFilter,
                start: moment(startDate).format('DD-MM-YYYY'),
                end: moment(endDate).format('DD-MM-YYYY'),
                projects: projects,
                counterpartyId: counterpartyId
            }
            myFetch(`/other-docs/get-for-report/?filter=${JSON.stringify(filter)}`, { method: 'get' })
                .then(res => {
                    dispatch({ type: ReportsConst.GET_DOCUMENTS, projectId: projects[0], docs: res.otherDocs, docType: docType })
                }).catch(err => {
                    handleError(err, dispatch, `/cash-transfer-orders/transfers-for-report/?filter=${JSON.stringify(filter)}`)
                })
        } else if (docType === 'Income') {
            var filter = {
                dateFilter: dateFilter,
                start: moment(startDate).format('DD-MM-YYYY'),
                end: moment(endDate).format('DD-MM-YYYY'),
                projects: projects
            }
            myFetch(`/sale-invoices/documents-for-report/?filter=${JSON.stringify(filter)}`, { method: 'get' })
                .then(res => {
                    dispatch({ type: ReportsConst.GET_DOCUMENTS, projectId: projects[0], docs: res.incomeDocs, docType: docType })
                }).catch(err => {
                    handleError(err, dispatch, `/sale-invoices/documents-for-report/?filter=${JSON.stringify(filter)}`)
                })
        }
    }
}

function tooglePaymentForDoc(id, fileId, projectId, method) {
    return dispatch => {
        if (method) {
            dispatch({ type: ReportsConst.MARK_DOC_AS_PAID, fileId: fileId, mark: true, method: method })
            dispatch({ type: FileConst.MARK_FILE_AS_PAID, id: fileId, projectId: projectId, mark: true })
            myFetch(`/docs/mark-as-paid`, {
                method: 'put', body: {
                    id: id,
                    method: method
                }
            }).then(res => {
                if (!res.success) {
                    dispatch(AlertActions.warning(res.msg))
                    dispatch({ type: ReportsConst.MARK_DOC_AS_PAID, id: id, fileId: fileId, mark: false })

                    myFetch(`/projects/get-costs/${projectId}`, { method: 'get' }).then(res => {
                        if (res.success) {
                            dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: res.costs })
                        } else {
                            dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: {} })
                        }
                    }).catch(err => {
                        dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: {} })
                        handleError(err, dispatch, `/projects/get-costs/${projectId}`)
                    })
                } else {
                    myFetch(`/projects/get-costs/${projectId}`, { method: 'get' }).then(res => {
                        if (res.success) {
                            dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: res.costs })
                        } else {
                            dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: {} })
                        }
                    }).catch(err => {
                        dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: {} })
                        handleError(err, dispatch, `/projects/get-costs/${projectId}`)
                    })
                }
            }).catch(err => {
                handleError(err, dispatch, `/docs/mark-as-paid`)
                dispatch({ type: ReportsConst.MARK_DOC_AS_PAID, id: id, fileId: fileId, mark: false })

                myFetch(`/projects/get-costs/${projectId}`, { method: 'get' }).then(res => {
                    if (res.success) {
                        dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: res.costs })
                    } else {
                        dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: {} })
                    }
                }).catch(err => {
                    dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: {} })
                    handleError(err, dispatch, `/projects/get-costs/${projectId}`)
                })
            })
        } else {
            dispatch({ type: ReportsConst.MARK_DOC_AS_PAID, id: id, fileId: fileId, mark: false })
            dispatch({ type: FileConst.MARK_FILE_AS_PAID, id: fileId, projectId: projectId, mark: false })
            myFetch(`/docs/mark-as-paid`, {
                method: 'put', body: {
                    id: id,
                    method: 'notPaid'
                }
            }).then(res => {
                if (!res.success) {
                    dispatch(AlertActions.warning(res.msg))
                    dispatch({ type: ReportsConst.MARK_DOC_AS_PAID, id: id, fileId: fileId, mark: true })

                    myFetch(`/projects/get-costs/${projectId}`, { method: 'get' }).then(res => {
                        if (res.success) {
                            dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: res.costs })
                        } else {
                            dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: {} })
                        }
                    }).catch(err => {
                        dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: {} })
                        handleError(err, dispatch, `/projects/get-costs/${projectId}`)
                    })
                }
            }).catch(err => {
                handleError(err, dispatch, `/docs/mark-as-paid`)
                dispatch({ type: ReportsConst.MARK_DOC_AS_PAID, id: id, fileId: fileId, mark: true })

                myFetch(`/projects/get-costs/${projectId}`, { method: 'get' }).then(res => {
                    if (res.success) {
                        dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: res.costs })
                    } else {
                        dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: {} })
                    }
                }).catch(err => {
                    dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: {} })
                    handleError(err, dispatch, `/projects/get-costs/${projectId}`)
                })
            })
        }
    }
}

function tooglePaymentForIncomeDoc(id, fileId, projectId, method, companyId, month) {
    return dispatch => {
        if (companyId) {
            dispatch({ type: FileConst.MARK_SALE_INVOICE_PAYMENT, id: id, method: method, companyId: companyId, month: month })
        }
        if (method) {
            dispatch({ type: ReportsConst.MARK_DOC_AS_PAID, id: id, fileId: fileId, mark: true, method: method })
            dispatch({ type: FileConst.MARK_FILE_AS_PAID, id: fileId, projectId: projectId, mark: true })
            myFetch(`/sale-invoices/mark-as-paid`, {
                method: 'put', body: {
                    id: id,
                    method: method
                }
            }).then(res => {
                if (!res.success) {
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                handleError(err, dispatch, `/sale-invoices/mark-as-paid`)
            })
        } else {
            dispatch({ type: ReportsConst.MARK_DOC_AS_PAID, id: id, fileId: fileId, mark: false })
            dispatch({ type: FileConst.MARK_FILE_AS_PAID, id: fileId, projectId: projectId, mark: false })
            myFetch(`/sale-invoices/mark-as-paid`, {
                method: 'put', body: {
                    id: id,
                    method: 'notPaid'
                }
            }).then(res => {
                if (!res.success) {
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                handleError(err, dispatch, `/sale-invoices/mark-as-paid`)
            })
        }
    }
}

function toogleAdditionalAcceptanceForDoc(id, fileId, projectId, userId) {
    return dispatch => {
        dispatch({ type: ReportsConst.DOC_ADDITIONAL_ACCEPTANCE, id: id, accept: true, userId: userId })
        myFetch(`/docs/additional-acceptance`, {
            method: 'put', body: {
                id: id
            }
        }).then(res => {
            if (!res.success) {
                dispatch(AlertActions.warning(res.msg))
                dispatch({ type: ReportsConst.DOC_ADDITIONAL_ACCEPTANCE, id: id, accept: false, userId: userId })
            } else {
                myFetch(`/projects/get-costs/${projectId}`, { methid: 'get' }).then(res => {
                    if (res.success) {
                        dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: res.costs })
                    } else {
                        dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: {} })
                    }
                }).catch(err => {
                    dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: {} })
                    handleError(err, dispatch, `/projects/get-costs/${projectId}`)
                })

                var data = {
                    currentDir: '',
                    projectId: projectId,
                    fileId: fileId,
                }
                myFetch(`/files/my-files/?data=${JSON.stringify(data)}`, { method: 'get' }).then(result => {
                    if (result.success) {
                        dispatch({ type: FileConst.GET_FILES, files: result.files, projectId: projectId, currentDir: '' })
                    } else {
                        dispatch(AlertActions.warning(result.msg))
                    }
                }).catch(err => {
                    handleError(err, dispatch, `/files/my-files/?data=${JSON.stringify(data)}`)
                })
            }
        }).catch(err => {
            handleError(err, dispatch, `/docs/mark-as-paid`)
            dispatch({ type: ReportsConst.DOC_ADDITIONAL_ACCEPTANCE, id: id, accept: false, userId: userId })
        })
    }
}

function toogleCanBeAccountmentStatusForDoc(id, fileId, projectId, canBeAccounted) {
    return dispatch => {
        dispatch({ type: ReportsConst.MARK_DOC_CAN_BE_ACCOUNTED, fileId: fileId, canBeAccounted: canBeAccounted })
        myFetch(`/docs/toggle-can-be-accounted`, {
            method: 'put', body: {
                id: id
            }
        }).then(res => {
            if (!res.success) {
                dispatch(AlertActions.warning(res.msg))
                dispatch({ type: ReportsConst.MARK_DOC_CAN_BE_ACCOUNTED, fileId: fileId, canBeAccounted: !canBeAccounted })
            } else {
            }
        }).catch(err => {
            handleError(err, dispatch, `/docs/mark-as-accounted`)
            dispatch({ type: ReportsConst.MARK_DOC_CAN_BE_ACCOUNTED, fileId: fileId, canBeAccounted: !canBeAccounted })
        })
    }
}

function toogleAccountmentStatusForDoc(id, fileId, projectId, accounted) {
    return dispatch => {
        dispatch({ type: ReportsConst.MARK_DOC_AS_ACCOUNTED, fileId: fileId, accounted: accounted })
        myFetch(`/docs/mark-as-accounted`, {
            method: 'put', body: {
                id: id,
                accounted: accounted
            }
        }).then(res => {
            if (!res.success) {
                dispatch(AlertActions.warning(res.msg))
                dispatch({ type: ReportsConst.MARK_DOC_AS_ACCOUNTED, fileId: fileId, accounted: !accounted })
            } else {
            }
        }).catch(err => {
            handleError(err, dispatch, `/docs/mark-as-accounted`)
            dispatch({ type: ReportsConst.MARK_DOC_AS_ACCOUNTED, fileId: fileId, accounted: !accounted })
        })
    }
}

function toogleAccountmentStatusForIncomeDoc(id, fileId, projectId, accounted) {
    return dispatch => {
        dispatch({ type: ReportsConst.MARK_DOC_AS_ACCOUNTED, fileId: fileId, accounted: accounted })
        myFetch(`/sale-invoices/mark-as-accounted`, {
            method: 'put', body: {
                id: id,
                accounted: accounted
            }
        }).then(res => {
            if (!res.success) {
                dispatch(AlertActions.warning(res.msg))
                dispatch({ type: ReportsConst.MARK_DOC_AS_ACCOUNTED, fileId: fileId, accounted: !accounted })
            } else {
            }
        }).catch(err => {
            handleError(err, dispatch, `/docs/mark-as-accounted`)
            dispatch({ type: ReportsConst.MARK_DOC_AS_ACCOUNTED, fileId: fileId, accounted: !accounted })
        })
    }
}

function tooglePaymentForTransfer(id, projectId, userId, mark, method) {
    return dispatch => {
        if (mark) {
            dispatch({ type: ReportsConst.MARK_DOC_AS_PAID, fileId: id, mark: true, method: method })
            dispatch({ type: TransferConst.MARK_TRANSFER_AS_PAID, id: id, projectId: projectId, userId: userId, method: method })
            myFetch(`/cash-transfer-orders/mark-as-paid`, {
                method: 'put', body: {
                    transferId: id,
                    mark: true,
                    method: method
                }
            }).then(res => {
                if (!res.success) {
                    dispatch(AlertActions.warning(res.msg))
                    dispatch({ type: ReportsConst.MARK_DOC_AS_PAID, fileId: id, mark: false })
                    dispatch({ type: TransferConst.MARK_TRANSFER_AS_PAID, id: id, projectId: projectId, method: method })
                } else {
                    myFetch(`/projects/get-costs/${projectId}`, { methid: 'get' }).then(res => {
                        if (res.success) {
                            dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: res.costs })
                        } else {
                            dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: {} })
                        }
                    }).catch(err => {
                        dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: {} })
                        handleError(err, dispatch, `/projects/get-costs/${projectId}`)
                    })
                }
            }).catch(err => {
                handleError(err, dispatch, `/cash-transfer-orders/mark-as-paid`)
                dispatch({ type: ReportsConst.MARK_DOC_AS_PAID, fileId: id, mark: false })
                dispatch({ type: TransferConst.MARK_TRANSFER_AS_PAID, id: id, projectId: projectId })
            })
        } else {
            dispatch({ type: ReportsConst.MARK_DOC_AS_PAID, fileId: id, mark: false })
            dispatch({ type: TransferConst.MARK_TRANSFER_AS_PAID, id: id, projectId: projectId, userId: null })
            myFetch(`/cash-transfer-orders/mark-as-paid`, {
                method: 'put', body: {
                    transferId: id,
                    mark: false,
                    method: '',
                }
            }).then(res => {
                if (!res.success) {
                    dispatch(AlertActions.warning(res.msg))
                    dispatch({ type: ReportsConst.MARK_DOC_AS_PAID, fileId: id, mark: true })
                    dispatch({ type: TransferConst.MARK_TRANSFER_AS_PAID, id: id, projectId: projectId, userId: userId })
                } else {
                    myFetch(`/projects/get-costs/${projectId}`, { methid: 'get' }).then(res => {
                        if (res.success) {
                            dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: res.costs })
                        } else {
                            dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: {} })
                        }
                    }).catch(err => {
                        dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: {} })
                        handleError(err, dispatch, `/projects/get-costs/${projectId}`)
                    })
                }
            }).catch(err => {
                handleError(err, dispatch, `/cash-transfer-orders/mark-as-paid`)
                dispatch({ type: ReportsConst.MARK_DOC_AS_PAID, fileId: id, mark: false })
                dispatch({ type: TransferConst.MARK_TRANSFER_AS_PAID, id: id, projectId: projectId, userId: userId })
            })
        }
    }
}

function toogleCanBeAccountmentStatusForTransfer(id, fileId, projectId, canBeAccounted) {
    return dispatch => {
        dispatch({ type: ReportsConst.MARK_DOC_CAN_BE_ACCOUNTED, fileId: fileId, canBeAccounted: canBeAccounted })
        myFetch(`/cash-transfer-orders/toggle-can-be-accounted`, {
            method: 'put', body: {
                id: id
            }
        }).then(res => {
            if (!res.success) {
                dispatch(AlertActions.warning(res.msg))
                dispatch({ type: ReportsConst.MARK_DOC_CAN_BE_ACCOUNTED, fileId: fileId, canBeAccounted: !canBeAccounted })
            } else {
            }
        }).catch(err => {
            handleError(err, dispatch, `/docs/mark-as-accounted`)
            dispatch({ type: ReportsConst.MARK_DOC_CAN_BE_ACCOUNTED, fileId: fileId, canBeAccounted: !canBeAccounted })
        })
    }
}

function toogleAccountmentStatusForTransfer(id, projectId, userId, accounted) {
    return dispatch => {
        dispatch({ type: ReportsConst.MARK_DOC_AS_ACCOUNTED, fileId: id, accounted: accounted })
        dispatch({ type: TransferConst.MARK_TRANSFER_AS_ACCOUNTED, id: id, projectId: projectId, accounted: accounted })
        myFetch(`/cash-transfer-orders/mark-as-accounted`, {
            method: 'put', body: {
                transferId: id,
                accounted: accounted
            }
        }).then(res => {
            if (!res.success) {
                dispatch(AlertActions.warning(res.msg))
                dispatch({ type: ReportsConst.MARK_DOC_AS_ACCOUNTED, fileId: id, accounted: !accounted })
                dispatch({ type: TransferConst.MARK_TRANSFER_AS_ACCOUNTED, id: id, projectId: projectId })
            } else {
            }
        }).catch(err => {
            handleError(err, dispatch, `/cash-transfer-orders/mark-as-paid`)
            dispatch({ type: ReportsConst.MARK_DOC_AS_ACCOUNTED, fileId: id, accounted: !accounted })
            dispatch({ type: TransferConst.MARK_TRANSFER_AS_ACCOUNTED, id: id, projectId: projectId })
        })
    }
}

function markDocsAsInReport(docs, reportName, reportId) {
    return dispatch => {
        dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs, mark: true, reportName: reportName })

        myFetch(`/reports/get-by-id/${reportId}`, { method: 'GET' })
            .then(res => {
                if (res.success) {
                    dispatch({ type: ReportsConst.GET_REPORT_BY_ID, report: res.report })
                }
            }).catch(err => {
                console.log(err)
            })
    }
}

function generateReport(docs, alreadyPaid, docType, accountNumbersChecked, userToCheckAccounNumber, projectId, currency, cb = () => { }) {
    return dispatch => {
        var data = {
            docs: docs,
            alreadyPaid: alreadyPaid,
            docType: docType
        }
        dispatch({ type: ExcelSesionConsts.ADD_DOCS, docs: docs, projectId: projectId })
        dispatch({ type: ReportsConst.GET_REPORT_XLSX, data: '', state: false })
        dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: true, reportName: 'PENDING' })
        if (docType !== 'Payment Orders') {
            if (accountNumbersChecked || !userToCheckAccounNumber) {
                myFetch(`/docs/genrate-report`, {
                    method: 'post', body: {
                        data: data
                    }
                })
                    .then(res => {
                        if (cb) cb()
                        if (res.success) {
                            var dLink = document.createElement('a')
                            dLink.download = res.name
                            dLink.href = res.data
                            dLink.click()

                            dispatch({ type: ReportsConst.GET_REPORT_XLSX, data: res.data, state: true })
                            dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: true, reportName: res.name })

                            myFetch(`/reports/get-by-id/${res.reportId}`, { method: 'GET' })
                                .then(res => {
                                    if (res.success) {
                                        dispatch({ type: ReportsConst.GET_REPORT_BY_ID, report: res.report })
                                    }
                                }).catch(err => {
                                    console.log(err)
                                })
                        } else {
                            dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, reportName: '' })
                            dispatch(AlertActions.warning(res.msg))
                        }
                    }).catch(err => {
                        if (cb) cb()
                        dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, reportName: '' })
                        handleError(err, dispatch, `/docs/genrate-report`)
                    })
            } else {
                myFetch(`/users/check-if-has-account-numbers/?userId=${userToCheckAccounNumber}&projectId=${projectId}&currency=${currency}`, { method: 'get' }).then(res => {
                    if (res.hasAccountNumbers) {
                        myFetch(`/docs/genrate-report`, {
                            method: 'post', body: {
                                data: data
                            }
                        })
                            .then(res => {
                                if (res.success) {
                                    var dLink = document.createElement('a')
                                    dLink.download = res.name
                                    dLink.href = res.data
                                    dLink.click()
                                    dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: true, reportName: res.name })
                                    dispatch({ type: ReportsConst.GET_REPORT_XLSX, data: res.data, state: true })
                                } else {
                                    dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, reportName: '' })
                                    dispatch(AlertActions.warning(res.msg))
                                }
                            }).catch(err => {
                                dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, reportName: '' })
                                handleError(err, dispatch, `/docs/genrate-report`)
                            })
                    } else {
                        dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, reportName: '' })
                        if (res.msg) {

                        } else {
                            if (currency === 'PLN') {
                                dispatch(AlertActions.warning("The user to whom the documents belong has not yet set the account number for reimbursement in PLN."))
                            } else {
                                dispatch(AlertActions.warning("The user to whom the documents belong has not yet set the account number for reimbursement in EUR."))
                            }
                        }
                    }
                }).catch(err => {
                    dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, reportName: '' })
                    handleError(err, dispatch, `/users/check-if-has-account-numbers/?userId=${userToCheckAccounNumber}&projectId=${projectId}`)
                })
            }
        } else {
            myFetch(`/cash-transfer-orders/genrate-report/?data=${JSON.stringify(data)}`, { method: 'get' })
                .then(res => {

                    if (res.success) {
                        var dLink = document.createElement('a')
                        dLink.download = res.name
                        dLink.href = res.data
                        dLink.click()
                        dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: true, reportName: res.name })
                        dispatch({ type: ReportsConst.GET_REPORT_XLSX, data: res.data, state: true })
                    } else {
                        dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, reportName: '' })
                        dispatch(AlertActions.warning(res.msg))
                    }
                }).catch(err => {
                    dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, reportName: '' })
                    handleError(err, dispatch, `/cash-transfer-orders/genrate-report/?data=${JSON.stringify(data)}`)
                })
        }
    }
}

function generateExpensesReport(docs, projectId, currency, accountId, advancePayment, comment, cb = () => { }) {
    return dispatch => {
        var data = {
            docs: docs,
            docType: 'Expenses',
            projectId: projectId,
            currency: currency,
            accountId: accountId,
            advancePayment: advancePayment,
            comment: comment
        }
        dispatch({ type: ExcelSesionConsts.ADD_DOCS, docs: docs, projectId: projectId })
        dispatch({ type: ReportsConst.GET_REPORT_XLSX, data: '', state: false })
        dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: true, reportName: 'PENDING' })
        myFetch(`/docs/genrate-expenses-report`, {
            method: 'post', body: {
                data: data
            }
        })
            .then(res => {
                cb()
                if (res.success) {
                    var dLink = document.createElement('a')
                    dLink.download = res.name
                    dLink.href = res.data
                    dLink.click()
                    dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: true, reportName: res.name })
                    dispatch({ type: ReportsConst.GET_REPORT_XLSX, data: res.data, state: true })
                } else {
                    dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, reportName: '' })
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                cb()
                dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, reportName: '' })
                handleError(err, dispatch, `/docs/genrate-expenses-report`)
            })
    }
}

function generateWaproXML(docs, alreadyPaid, docType, projectId, markAsAccounted, cb) {
    return dispatch => {
        var data = {
            docs: docs,
            alreadyPaid: alreadyPaid,
            docType: docType,
            markAsAccounted: markAsAccounted,
        }
        dispatch({ type: ReportsConst.MARK_DOCS_WAPRO_XML, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: true, reportName: 'PENDING' })
        if (docType === 'Income') {
            myFetch(`/sale-invoices/genrate-wapro-xml`, {
                method: 'post', body: {
                    data: data
                }
            })
                .then(res => {
                    cb(res.success)
                    if (res.success) {
                        var dLink = document.createElement('a')
                        dLink.download = res.name
                        dLink.href = res.data
                        dLink.click()
                        dispatch({
                            type: ReportsConst.MARK_DOCS_WAPRO_XML,
                            docs: docs.map(d => { return ({ id: d.id, type: d.type }) }),
                            mark: true,
                            markAsAccounted: markAsAccounted,
                            reportName: res.name,
                            reportId: res.id,
                        })
                        //dispatch({type: ReportsConst.GET_REPORT_XLSX, data: res.data, state: true})
                    } else {
                        dispatch({ type: ReportsConst.MARK_DOCS_WAPRO_XML, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, reportName: '' })
                        dispatch(AlertActions.warning(res.msg))
                    }
                }).catch(err => {
                    cb(false)
                    dispatch({ type: ReportsConst.MARK_DOCS_WAPRO_XML, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, reportName: '' })
                    handleError(err, dispatch, `/sale-invoices/genrate-wapro-xml`)
                })
        } else {
            myFetch(`/docs/genrate-wapro-xml`, {
                method: 'post', body: {
                    data: data
                }
            })
                .then(res => {
                    cb(res.success)
                    if (res.success) {
                        var dLink = document.createElement('a')
                        dLink.download = res.name
                        dLink.href = res.data
                        dLink.click()
                        dispatch({
                            type: ReportsConst.MARK_DOCS_WAPRO_XML,
                            docs: docs.map(d => { return ({ id: d.id, type: d.type }) }),
                            mark: true,
                            markAsAccounted: markAsAccounted,
                            reportName: res.name,
                            reportId: res.id,
                        })
                        //dispatch({type: ReportsConst.GET_REPORT_XLSX, data: res.data, state: true})
                    } else {
                        dispatch({ type: ReportsConst.MARK_DOCS_WAPRO_XML, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, reportName: '' })
                        dispatch(AlertActions.warning(res.msg))
                    }
                }).catch(err => {
                    cb(false)
                    dispatch({ type: ReportsConst.MARK_DOCS_WAPRO_XML, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, reportName: '' })
                    handleError(err, dispatch, `/docs/genrate-wapro-xml`)
                })
        }
    }
}

function generateSymfoniaXML(docs, alreadyPaid, docType, projectId, cb) {
    return dispatch => {
        var data = {
            docs: docs,
            alreadyPaid: alreadyPaid,
            docType: docType
        }
        dispatch({ type: ReportsConst.MARK_DOCS_SYMFONIA_XML, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: true, reportName: 'PENDING' })

        myFetch(`/docs/genrate-symfonia-import-file`, {
            method: 'post', body: {
                data: data
            }
        })
            .then(res => {
                cb(res.success)
                if (res.success) {
                    var dLink = document.createElement('a')
                    dLink.download = res.name
                    dLink.href = res.data
                    dLink.click()
                    dispatch({ type: ReportsConst.MARK_DOCS_SYMFONIA_XML, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: true, reportName: res.name })
                    //dispatch({type: ReportsConst.GET_REPORT_XLSX, data: res.data, state: true})
                } else {
                    dispatch({ type: ReportsConst.MARK_DOCS_SYMFONIA_XML, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, reportName: '' })
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                cb(false)
                dispatch({ type: ReportsConst.MARK_DOCS_SYMFONIA_XML, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, reportName: '' })
                handleError(err, dispatch, `/docs/genrate-wapro-import-file`)
            })
    }
}

function generateCustomReport(docs, alreadyPaid, docType, projectId) {
    return dispatch => {
        var data = {
            docs: docs,
            alreadyPaid: alreadyPaid,
            docType: docType,
            projectId: projectId
        }
        dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: true, reportName: 'PENDING' })
        myFetch(`/docs/genrate-custom-report`, {
            method: 'post', body: {
                data: data
            }
        })
            .then(res => {
                if (res.success) {
                    var dLink = document.createElement('a')
                    dLink.download = res.name
                    dLink.href = res.data
                    dLink.click()
                    dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, reportName: '' })
                } else {
                    dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, reportName: '' })
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, reportName: '' })
                handleError(err, dispatch, `/docs/genrate-custom-report`)
            })

    }
}


function generateCustomAgreementsReport(docs, alreadyPaid, docType, projectId) {
    return dispatch => {
        var data = {
            docs: docs,
            alreadyPaid: alreadyPaid,
            docType: docType,
            projectId: projectId
        }
        dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: true, reportName: 'PENDING' })
        myFetch(`/agreements/genrate-custom-report`, {
            method: 'post', body: {
                data: data
            }
        })
            .then(res => {
                if (res.success) {
                    var dLink = document.createElement('a')
                    dLink.download = res.name
                    dLink.href = res.data
                    dLink.click()
                    dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, reportName: '' })
                } else {
                    dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, reportName: '' })
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, reportName: '' })
                handleError(err, dispatch, `/agreements/genrate-custom-report`)
            })

    }
}
function generateCustomIncomeReport(docs, alreadyPaid, docType, projectId) {
    return dispatch => {
        var data = {
            docs: docs,
            alreadyPaid: alreadyPaid,
            docType: docType,
            projectId: projectId
        }
        dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: true, reportName: 'PENDING' })
        myFetch(`/sale-invoices/genrate-custom-report`, {
            method: 'post', body: {
                data: data
            }
        })
            .then(res => {
                if (res.success) {
                    var dLink = document.createElement('a')
                    dLink.download = res.name
                    dLink.href = res.data
                    dLink.click()
                    dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, reportName: '' })
                } else {
                    dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, reportName: '' })
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, reportName: '' })
                handleError(err, dispatch, `/sale-invoices/genrate-custom-report`)
            })

    }
}

function markDocsAsInBankTransfersFile(docs, fileName) {
    return dispatch => {
        dispatch({ type: ReportsConst.MARK_DOCS_BANK_TRANSFER_FILE, docs: docs, mark: true, fileName: fileName })
    }
}

function generateBankTransfersFile(docs, docType, projectId, paymentDate, bankId, markAsPaid = false, paymentDateOptions = {}, cb = () => { }) {
    return dispatch => {
        var data = {
            docs: docs,
            docType: docType,
            paymentDate: paymentDate,
            paymentDateOptions: paymentDateOptions,
            bankId: bankId,
            markAsPaid: markAsPaid,
        }
        dispatch({ type: ReportsConst.MARK_DOCS_BANK_TRANSFER_FILE, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: true, fileName: 'PENDING' })
        if (docType !== 'Payment Orders') {
            myFetch(`/docs/genrate-bank-transfers-file`, {
                method: 'post', body: {
                    data: data
                }
            })
                .then(res => {
                    if (cb) cb()
                    if (res.success) {
                        var dLink = document.createElement('a')
                        dLink.download = res.name
                        dLink.href = res.data
                        dLink.click()

                        dispatch({ type: ReportsConst.MARK_DOCS_BANK_TRANSFER_FILE, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: true, fileName: res.name })

                        myFetch(`/bank-transfers-files/get-by-id/${res.bankTransferFileId}`, { method: 'GET' })
                            .then(res => {
                                if (res.success) {
                                    dispatch({ type: ReportsConst.GET_TRANSFER_FILE_BY_ID, file: res.file })
                                }
                            }).catch(err => {
                                console.log(err)
                            })
                    } else {
                        dispatch({ type: ReportsConst.MARK_DOCS_BANK_TRANSFER_FILE, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, fileName: '' })
                        dispatch(AlertActions.warning(res.msg))
                    }
                }).catch(err => {
                    if (cb) cb()
                    dispatch({ type: ReportsConst.MARK_DOCS_BANK_TRANSFER_FILE, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, fileName: '' })
                    handleError(err, dispatch, `/docs/genrate-bank-transfers-file/?data=${JSON.stringify(data)}`)
                })
        } else {
            myFetch(`/cash-transfer-orders/genrate-bank-transfers-file/?data=${JSON.stringify(data)}`, { method: 'get' })
                .then(res => {
                    if (res.success) {
                        var dLink = document.createElement('a')
                        dLink.download = res.name
                        dLink.href = res.data
                        dLink.click()
                        dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: true, reportName: res.name })
                    } else {
                        dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, reportName: '' })
                        dispatch(AlertActions.warning(res.msg))
                    }
                }).catch(err => {
                    dispatch({ type: ReportsConst.MARK_DOCS_IN_REPORT, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, reportName: '' })
                    handleError(err, dispatch, `/cash-transfer-orders/genrate-bank-transfers-file/?data=${JSON.stringify(data)}`)
                })
        }
    }
}

function generateBankTransfetFileForInternationalTransfers(docs, docType, projectId, paymentDate, bankId, markAsPaid = false, paymentDateOptions = {}, cb = () => { }) {
    return dispatch => {
        var data = {
            docs: docs,
            docType: docType,
            paymentDate: paymentDate,
            paymentDateOptions: paymentDateOptions,
            bankId: bankId,
            markAsPaid: markAsPaid,
        }
        dispatch({ type: ReportsConst.MARK_DOCS_BANK_TRANSFER_FILE, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: true, fileName: 'PENDING' })

        myFetch(`/docs/genrate-bank-transfers-file-for-currency-transfers`, {
            method: 'post', body: {
                data: data
            }
        })
            .then(res => {
                if (cb) cb()
                if (res.success) {

                    if (res.caSepaData || res.caSwiftData) {
                        if (res.caSepaData && res.caSepaData.length) {
                            var dLinkSepa = document.createElement('a')
                            dLinkSepa.download = res.caSepaName
                            dLinkSepa.href = res.caSepaData
                            dLinkSepa.click()
                        }

                        if (res.caSwiftData && res.caSwiftData.length) {
                            setTimeout(() => {
                                var dLinkSwift = document.createElement('a')
                                dLinkSwift.download = res.caSwiftName
                                dLinkSwift.href = res.caSwiftData
                                dLinkSwift.click()
                            }, 300)
                        }
                    } else {
                        var dLink = document.createElement('a')
                        dLink.download = res.name
                        dLink.href = res.data
                        dLink.click()
                    }

                    dispatch({ type: ReportsConst.MARK_DOCS_BANK_TRANSFER_FILE, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: true, fileName: res.name })

                    myFetch(`/bank-transfers-files/get-by-id/${res.bankTransferFileId}`, { method: 'GET' })
                        .then(res => {
                            if (res.success) {
                                dispatch({ type: ReportsConst.GET_TRANSFER_FILE_BY_ID, file: res.file })
                            }
                        }).catch(err => {
                            console.log(err)
                        })
                } else {
                    dispatch({ type: ReportsConst.MARK_DOCS_BANK_TRANSFER_FILE, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, fileName: '' })
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                if (cb) cb()
                dispatch({ type: ReportsConst.MARK_DOCS_BANK_TRANSFER_FILE, docs: docs.map(d => { return ({ id: d.id, type: d.type }) }), mark: false, fileName: '' })
                handleError(err, dispatch, `/docs/genrate-bank-transfers-file-for-currency-transfers/?data=${JSON.stringify(data)}`)
            })
    }
}

function exportDocsToAccounting(project, projectCode, files, paymentOrders, sendEmail, emailAddress, sendLink, cb = () => { }) {
    return async dispatch => {
        dispatch({ type: ReportsConst.MARK_DOCS_IN_ZIP, files: files || [], paymentOrders: paymentOrders || [], mark: true, zipId: 'PENDING' })
        await myDownload(
            `/files/multi-download`,
            {
                method: 'post',
                body: {
                    projectId: project,
                    files: files || [],
                    paymentOrders: paymentOrders || [],
                    withFolders: true,
                    sendEmail: sendEmail,
                    emailAddress: emailAddress,
                    sendLink: sendLink,
                    markExport: true,
                }
            },
            `${moment().format('YYYY-MM-DD')}_${projectCode}_files.zip`
        )
        cb()
        dispatch({ type: ReportsConst.MARK_DOCS_IN_ZIP, files: files || [], paymentOrders: paymentOrders || [], mark: true, zipId: 0 })
    }
}

function getUsersListForExpensesReport(projectId, cb) {
    return dispatch => {
        myFetch(`/user-projects/get-users-list-for-expenses/${projectId}`, { method: 'get' })
            .then(res => {
                if (res.success) {
                    cb(res.usersList)
                } else {
                    cb([])
                }
            }).catch(err => {
                handleError(err, dispatch, `/user-projects/get-users-list-for-expenses/${projectId}`)
            })
    }
}

function bulkUpdatedTagsForFiles(filesToUpdate, tagsToDelete, tagsToAdd, cb) {
    return dispatch => {
        myFetch(`/files/bulk-tags-update`, {
            method: 'put', body: {
                files: filesToUpdate,
                tagsToDelete: tagsToDelete,
                tagsToAdd: tagsToAdd
            }
        }).then(res => {
            if (res.success) {
                dispatch({ type: ReportsConst.BULK_TAGS_UPDATE, isPaymentOrder: false, updatedFiles: res.updatedFiles || [] })
            } else {
                dispatch(AlertActions.warning(res.msg))
            }
            cb()
        }).catch(err => {
            handleError(err, dispatch, `/files/bulk-tags-update`)
            cb()
        })
    }
}

function bulkUpdatedTagsForPaymentOrders(paymentOrdersToUpdate, tagsToDelete, tagsToAdd, cb) {
    return dispatch => {
        myFetch(`/cash-transfer-orders/bulk-tags-update`, {
            method: 'put', body: {
                paymentOrders: paymentOrdersToUpdate,
                tagsToDelete: tagsToDelete,
                tagsToAdd: tagsToAdd
            }
        }).then(res => {
            if (res.success) {
                dispatch({ type: ReportsConst.BULK_TAGS_UPDATE, isPaymentOrder: true, updatedFiles: res.updatedFiles || [] })
            } else {
                dispatch(AlertActions.warning(res.msg))
            }
            cb()
        }).catch(err => {
            handleError(err, dispatch, `/files/bulk-tags-update`)
            cb()
        })
    }
}

function downloadReport(projectId, reportName) {
    return dispatch => {
        var data = {
            projectId: projectId,
            reportName: reportName.split('/').pop()
        }
        myFetch(`/projects/report/?data=${JSON.stringify(data)}`, { method: 'get' })
            .then(res => {
                if (res.success) {
                    var dLink = document.createElement('a')
                    dLink.download = res.name
                    dLink.href = res.data
                    dLink.click()
                } else {
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                handleError(err, dispatch, `/projects/report/?data=${JSON.stringify(data)}`)
            })
    }
}

function downloadReportFiles(reportId, projectId, reportName, cb) {
    return dispatch => {
        myFetch(`/reports/download-files/${reportId}`, { method: 'get' })
            .then(async res => {
                if ((res.files && res.files.length > 0) || (res.paymentOrderFiles && res.paymentOrderFiles.length > 0)) {
                    await myDownload(
                        `/files/multi-download`,
                        {
                            method: 'post',
                            body: {
                                projectId: projectId,
                                files: res.files || [],
                                paymentOrders: res.paymentOrderFiles || [],
                                reportId: reportId
                            }
                        },
                        `${reportName}-files.zip`
                    )
                    cb()
                } else {
                    dispatch(AlertActions.warning('No files asociated with report found'))
                    cb()
                }
            }).catch(err => {
                handleError(err, dispatch, `/reports/download-files/${reportId}`)
                cb()
            })
    }
}

function downloadZip(zipId, projectId, cb) {
    return dispatch => {
        myFetch(`/zip-reports/download/${zipId}`, { method: 'get' })
            .then(async res => {
                if ((res.files && res.files.length > 0) || (res.paymentOrderFiles && res.paymentOrderFiles.length > 0)) {
                    await myDownload(
                        `/files/multi-download`,
                        {
                            method: 'post',
                            body: {
                                projectId: projectId,
                                files: res.files || [],
                                paymentOrders: res.paymentOrderFiles || []
                            }
                        },
                        res.zipName
                    )
                    cb()
                } else {
                    dispatch(AlertActions.warning('No files asociated with report found'))
                    cb()
                }
            }).catch(err => {
                handleError(err, dispatch, `/zip-reports/download/${zipId}`)
                cb()
            })
    }
}

function downloadBankTransferFileFiles(fileId, projectId, fileName, cb) {
    return dispatch => {
        var extentionIndex = fileName.lastIndexOf('.')

        if (extentionIndex > -1) {
            fileName = fileName.substring(0, extentionIndex)
        }

        myFetch(`/bank-transfers-files/download-files/${fileId}`, { method: 'get' })
            .then(async res => {
                if (res.files.length > 0 || res.paymentOrderFiles.length > 0) {
                    await myDownload(
                        `/files/multi-download`,
                        {
                            method: 'post',
                            body: {
                                projectId: projectId,
                                files: res.files || [],
                                paymentOrders: res.paymentOrderFiles || [],
                                transferFileId: fileId
                            }
                        },
                        `${fileName}-files.zip`
                    )
                    cb()
                } else {
                    dispatch(AlertActions.warning('No files asociated with bank transfers basket found'))
                    cb()
                }
            }).catch(err => {
                cb()
                handleError(err, dispatch, `/bank-transfers-files/download-files/${fileId}`)
            })
    }
}

function downloadAccountingFile(fileId) {
    return dispatch => {
        myFetch(`/external-accounting-program-files/download/${fileId}`, { method: 'get' })
            .then(res => {
                if (res.success) {
                    var dLink = document.createElement('a')
                    dLink.download = res.name
                    dLink.href = res.data
                    dLink.click()
                } else {
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                handleError(err, dispatch, `/external-accounting-program-files/download/${fileId}`)
            })
    }
}

function downloadAccountingFileDocs(fileId, projectId, fileName, cb) {
    return dispatch => {
        var extentionIndex = fileName.lastIndexOf('.')

        if (extentionIndex > -1) {
            fileName = fileName.substring(0, extentionIndex)
        }

        myFetch(`/external-accounting-program-files/download-files/${fileId}`, { method: 'get' })
            .then(async res => {
                if (res.files.length > 0) {
                    await myDownload(
                        `/files/multi-download`,
                        {
                            method: 'post',
                            body: {
                                projectId: projectId,
                                files: res.files || [],
                                paymentOrders: res.paymentOrderFiles || [],
                                accountingFileId: fileId
                            }
                        },
                        `${fileName}-files.zip`
                    )
                    cb()
                } else {
                    dispatch(AlertActions.warning('No files asociated with bank transfers basket found'))
                    cb()
                }
            }).catch(err => {
                cb()
                handleError(err, dispatch, `/external-accounting-program-files/download-files/${fileId}`)
            })
    }
}

function switchDocsSortingFilter(key) {
    return dispatch => {
        dispatch({ type: ReportsConst.SORT_DOCS, name: key })
    }
}

function getMyReportsList(projectId) {
    return dispatch => {
        dispatch({ type: ReportsConst.SEND_GET_MY_REPORTS_REQUEST })
        myFetch(`/reports/get-mine/${projectId}`, { method: 'get' })
            .then(res => {
                if (res.success) {
                    dispatch({ type: ReportsConst.GET_ALL_MINE_REPORTS, reports: res.reports })
                } else {
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                handleError(err, dispatch, `/reports/get-mine`)
            })
    }
}

function getMyZips(projectId) {
    return dispatch => {
        dispatch({ type: ReportsConst.SEND_GET_MY_ZIPS_REQUEST })
        myFetch(`/zip-reports/get-mine/${projectId}`, { method: 'get' })
            .then(res => {
                if (res.success) {
                    dispatch({ type: ReportsConst.GET_ALL_MINE_ZIPS, zips: res.zips })
                } else {
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                handleError(err, dispatch, `/zip-reports/get-mine`)
            })
    }
}

function getMyBankTransferFilesList(projectId) {
    return dispatch => {
        dispatch({ type: ReportsConst.SEND_GET_MY_TRANSFER_FILES_REQUEST })
        myFetch(`/bank-transfers-files/get-mine/${projectId}`, { method: 'get' })
            .then(res => {
                if (res.success) {
                    dispatch({ type: ReportsConst.GET_ALL_MINE_TRANSFER_FILES, transferFiles: res.bankTransfers })
                } else {
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                handleError(err, dispatch, `/bank-transfers-files/get-mine`)
            })
    }
}

function getMyAccountingFilesList(projectId) {
    return dispatch => {
        dispatch({ type: ReportsConst.SEND_GET_MY_ACCOUNTING_FILES_REQUEST })
        myFetch(`/external-accounting-program-files/get-mine/${projectId}`, { method: 'get' })
            .then(res => {
                if (res.success) {
                    dispatch({ type: ReportsConst.GET_ALL_MINE_ACCOUNTING_FILES, files: res.files })
                } else {
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                handleError(err, dispatch, `/external-accounting-program-files/get-mine`)
            })
    }
}

function deleteReport(fileId, projectId, cb) {
    return dispatch => {
        myFetch(`/reports/delete-report`, {
            method: 'post', body: {
                reportId: fileId,
                projectId: projectId,
            }
        })
            .then(res => {
                cb(res.success)
                if (res.success) {
                    dispatch({ type: ReportsConst.DELETE_REPORT, fileId: fileId, reportName: res.reportName })
                } else {
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                cb(false)
                handleError(err, dispatch, `/bank-transfers-files/delete-transfer-file`)
            })
    }
}

function deleteZip(fileId, projectId, cb) {
    return dispatch => {
        myFetch(`/zip-reports/delete`, {
            method: 'post', body: {
                zipId: fileId,
                projectId: projectId,
            }
        })
            .then(res => {
                cb(res.success)
                if (res.success) {
                    dispatch({ type: ReportsConst.DELETE_ZIP, fileId: fileId })
                } else {
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                cb(false)
                handleError(err, dispatch, `/zip-reports/delete`)
            })
    }
}

function deleteBankTransferFile(fileId, projectId, docsToMarkUnpaid, cb) {
    return dispatch => {
        myFetch(`/bank-transfers-files/delete-transfer-file`, {
            method: 'post', body: {
                bankTransferFileId: fileId,
                projectId: projectId,
                docsToMarkUnpaid: docsToMarkUnpaid,
            }
        })
            .then(res => {
                cb(res.success)
                if (res.success) {
                    dispatch({ type: ReportsConst.DELETE_BANK_TRANSFER_FILE, fileId: fileId, bankTransferFileName: res.bankTransferFileName })
                } else {
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                cb(false)
                handleError(err, dispatch, `/bank-transfers-files/delete-transfer-file`)
            })
    }
}

function deleteAccountingFile(fileId, companyId, cb) {
    return dispatch => {
        myFetch(`/external-accounting-program-files/delete`, {
            method: 'post', body: {
                accountingFileId: fileId,
                companyId: companyId,
            }
        })
            .then(res => {
                cb(res.success)
                if (res.success) {
                    dispatch({ type: ReportsConst.DELETE_ACCOUNTING_FILE, fileId: fileId })
                } else {
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                cb(false)
                handleError(err, dispatch, `/external-accounting-program-files/delete`)
            })
    }
}

function switchSortingFilterReports(key) {
    return dispatch => {
        dispatch({ type: ReportsConst.SORT_REPORTS, name: key })
    }
}

function switchSortingFilterForZip(key) {
    return dispatch => {
        dispatch({ type: ReportsConst.SORT_ZIPS, name: key })
    }
}

function switchBankTransferFilesFilterReports(key) {
    return dispatch => {
        dispatch({ type: ReportsConst.SORT_TRANSFER_FILES, name: key })
    }
}

function switchSortingFilterAccountingFiles(key) {
    return dispatch => {
        dispatch({ type: ReportsConst.SORT_ACCOUNTING_FILES, name: key })
    }
}

function handleError(error, dispatch, path) {
    if (error?.code === 'AUTHORIZATION_REQUIRED' && localStorage.getItem('dashboardProject')) {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        dispatch({ type: UserConst.LOGGED_FALSE })
        dispatch({ type: UserConst.LOGOUT })
        dispatch({ type: EventConst.LOGOUT })
        dispatch({ type: CompanyConst.LOGOUT })
        dispatch({ type: ReportsConst.LOGOUT })
        dispatch({ type: FileConst.LOGOUT })
        dispatch(AlertActions.info("You have been logged out.", 5000))
    } else {
        dispatch(AlertActions.setError(error, path));
        dispatch(AlertActions.danger("There was an internal server error while procesing request. Try again later."));
    }
}