import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import ReactTooltip from 'react-tooltip'

import { AlertActions } from '../../actions/AlertActions'
import { CompanyActions } from '../../actions/CompanyActions';

export class AdditionalAcceptanceSettingsPopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hasAdditionalAcceptanceOn: false,
            maxAcceptnces: 0,
            usersThatCanAccept: [],
            acceptanceTresholds: [],
            userAcceptanceTresholds: [],
        }
    }

    componentDidMount() {
        const { projectId, projectWorkers } = this.props
        var initialAcceptanceTresholds = [{
            project_id: projectId,
            treshold_amount: '',
            treshold_amount_currency: '',
            required_acceptances: 1
        }]
        this.props.getCurrentProjectAcceptanceConfiguration(projectId, configuration => {
            var initialUserAcceptanceTresholds = []
            if (projectWorkers) {
                var maxAcceptnces = 0
                projectWorkers.forEach(user => {
                    if (user.role !== 'USER1') {
                        maxAcceptnces++
                        const userConfiguration = configuration?.userAcceptanceTresholds.find(c => c.user_id === user.id)
                        initialUserAcceptanceTresholds.push({
                            id: userConfiguration?.id || null,
                            canAccept: userConfiguration?.id ? true : false,
                            name: `${user.first_name} ${user.last_name}`,
                            email: user.email,
                            profilePic: user.profile_pic_link || null,
                            user_id: user.id,
                            project_id: projectId,
                            treshold_amount: userConfiguration?.treshold_amount && parseFloat(userConfiguration?.treshold_amount) !== -1 ? parseFloat(userConfiguration?.treshold_amount).toFixed(2) : '',
                            treshold_amount_currency: userConfiguration?.treshold_amount_currency && parseFloat(userConfiguration?.treshold_amount_currency) !== -1 ? parseFloat(userConfiguration?.treshold_amount_currency).toFixed(2) : ''
                        })
                    }
                })
            }
            if (configuration?.acceptanceTresholds) {
                initialAcceptanceTresholds = []
                configuration?.acceptanceTresholds.forEach(config => {
                    if (parseFloat(config?.treshold_amount) === -1) {
                        config.treshold_amount = ''
                    } else {
                        config.treshold_amount = parseFloat(config?.treshold_amount).toFixed(2)
                    }
                    if (parseFloat(config?.treshold_amount_currency) === -1) {
                        config.treshold_amount_currency = ''
                    } else {
                        config.treshold_amount_currency = parseFloat(config?.treshold_amount_currency).toFixed(2)
                    }
                    initialAcceptanceTresholds.push(config)
                })
            }
            this.setState({
                maxAcceptnces: maxAcceptnces,
                hasAdditionalAcceptanceOn: configuration?.hasAdditionalAcceptance || false,
                acceptanceTresholds: initialAcceptanceTresholds,
                userAcceptanceTresholds: initialUserAcceptanceTresholds
            })
        })


    }

    toogleAdditionalAcceptance = newValue => {
        this.setState({
            hasAdditionalAcceptanceOn: newValue
        })
    }

    toogleAcceptanceForUser = (newValue, index) => {
        const { userAcceptanceTresholds } = this.state
        var tempCopy = [...userAcceptanceTresholds]
        tempCopy[index].canAccept = newValue
        this.setState({
            userAcceptanceTresholds: tempCopy
        })
    }

    editProjectTreshold = (e, index) => {
        e.preventDefault()
        var { name, value } = e.target

        value = value.replace(/[^0-9.,]/g, '').replace(',', '.')

        if (/\..*\./.test(value)) {
            value = value.substring(0, value.length - 1)
        } else if (/\.[0-9]{3}/.test(value)) {
            value = value.substring(0, value.length - 1)
        }

        const { acceptanceTresholds } = this.state
        var tempCopy = [...acceptanceTresholds]
        tempCopy[index][name] = value
        this.setState({
            acceptanceTresholds: tempCopy
        })
    }

    addTreshold = () => {
        const { projectId } = this.props
        const { acceptanceTresholds } = this.state
        var tempCopy = [...acceptanceTresholds]
        tempCopy.push({
            project_id: projectId,
            treshold_amount: '',
            treshold_amount_currency: '',
            required_acceptances: 1
        })
        this.setState({
            acceptanceTresholds: tempCopy
        })
    }

    deleteTreshold = index => {
        const { acceptanceTresholds } = this.state
        var tempCopy = [...acceptanceTresholds]
        tempCopy.splice(index, 1)
        this.setState({
            acceptanceTresholds: tempCopy
        })
    }

    changeRequiredAcceptancesCount = (type, index) => {
        const { acceptanceTresholds, maxAcceptnces } = this.state
        var tempCopy = [...acceptanceTresholds]
        if (type === 'PLUS') tempCopy[index].required_acceptances++
        if (type === 'MINUS') tempCopy[index].required_acceptances--

        if (tempCopy[index].required_acceptances < 1) tempCopy[index].required_acceptances = 1
        if (tempCopy[index].required_acceptances > maxAcceptnces) tempCopy[index].required_acceptances = maxAcceptnces

        this.setState({
            acceptanceTresholds: tempCopy
        })
    }

    editUserProjectTreshold = (e, index) => {
        const { userAcceptanceTresholds } = this.state
        var tempCopy = [...userAcceptanceTresholds]
        e.preventDefault()
        var { name, value } = e.target

        value = value.replace(/[^0-9.,]/g, '').replace(',', '.')

        if (/\..*\./.test(value)) {
            value = value.substring(0, value.length - 1)
        } else if (/\.[0-9]{3}/.test(value)) {
            value = value.substring(0, value.length - 1)
        }

        tempCopy[index][name] = value
        this.setState({
            userAcceptanceTresholds: tempCopy
        })
    }

    saveConfiguration = () => {
        const { projectId } = this.props
        const {
            hasAdditionalAcceptanceOn,
            acceptanceTresholds,
            userAcceptanceTresholds
        } = this.state

        var isValid = true

        if (hasAdditionalAcceptanceOn) {
            var noLimitTresholdsCount = 0
            acceptanceTresholds.forEach(treshold => {
                if (!treshold.treshold_amount) noLimitTresholdsCount++
            })

            if (noLimitTresholdsCount > 1) {
                isValid = false
                this.props.alertWarn(this.context.t("You cannot have several thresholds without an amount limit"))
            }
        }

        if (isValid) {
            this.props.setCurrentProjectAcceptanceConfiguration(
                projectId,
                hasAdditionalAcceptanceOn,
                acceptanceTresholds,
                userAcceptanceTresholds,
                success => {
                    if (success) this.props.cancel()
                }
            )
        }
    }

    render() {
        const { projectId } = this.props
        const {
            hasAdditionalAcceptanceOn,
            acceptanceTresholds,
            userAcceptanceTresholds
        } = this.state
        console.log(acceptanceTresholds)
        console.log(userAcceptanceTresholds)
        return (
            <div className="additional-acceptance-popup">
                <div className="config-card">
                    <div className="header">
                        <h2>{this.context.t('Additional acceptance configurator')}</h2>
                        <p>{this.context.t('Here you can set parameters for additional document acceptance. Documents that do not receive approval from designated users will not be able to be paid using EasyDocs.')}</p>
                    </div>
                    <div className="settings-section">
                        <div className="checkbox-group">
                            <span className={`checkbox ${hasAdditionalAcceptanceOn ? 'checked' : ''}`} onClick={() => this.toogleAdditionalAcceptance(!hasAdditionalAcceptanceOn)}></span>
                            <span onClick={() => this.toogleAdditionalAcceptance(!hasAdditionalAcceptanceOn)}>{this.context.t('Enable additional document acceptance')}</span>
                        </div>

                        <div className={`tresholds project ${!hasAdditionalAcceptanceOn ? 'blured' : ''}`}>
                            <div className="section-header">
                                <h3>{this.context.t('Set the amount thresholds for accepting documents for payment')}</h3>
                                <div className="info-icon" data-tip="show" data-for="projectTresholdsTooltip"></div>
                            </div>
                            <div className="section-list">
                                <SimpleBar style={{ maxHeight: '50vh', width: '100%', paddingRight: '10px' }}>
                                    {
                                        acceptanceTresholds.map((treshold, index) => {
                                            return (
                                                <div key={`projectTreshold-${index}`} className="project-treshold">
                                                    <div className="treshold-title">
                                                        {this.context.t('Documents below the amounts:')}
                                                        {
                                                            acceptanceTresholds.length > 1 ? (
                                                                <span className="delete-treshold" onClick={() => this.deleteTreshold(index)}></span>
                                                            ) : null
                                                        }
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor={`projectTresholdPln-${index}`}>{this.context.t('Amount threshold for documents in PLN')}</label>
                                                        <input
                                                            id={`projectTresholdPln-${index}`}
                                                            type="text"
                                                            name="treshold_amount"
                                                            placeholder={this.context.t("no limit")}
                                                            value={treshold.treshold_amount}
                                                            onChange={(e) => this.editProjectTreshold(e, index)}
                                                            readOnly={!hasAdditionalAcceptanceOn}
                                                        />
                                                    </div>


                                                    <div className="form-group">
                                                        <label htmlFor={`projectTresholdCurrency-${index}`}>{this.context.t('Amount threshold for currency documents')}</label>
                                                        <input
                                                            id={`projectTresholdCurrency-${index}`}
                                                            type="text"
                                                            name="treshold_amount_currency"
                                                            placeholder={this.context.t("no limit")}
                                                            value={treshold.treshold_amount_currency}
                                                            onChange={(e) => this.editProjectTreshold(e, index)}
                                                            readOnly={!hasAdditionalAcceptanceOn}
                                                        />
                                                    </div>

                                                    <div className="acceptance-users-number">
                                                        <div className="text">{this.context.t("The number of people who need to accept the document")}:</div>
                                                        <div className="number">
                                                            {treshold.required_acceptances}
                                                            <span className="increment" onClick={() => this.changeRequiredAcceptancesCount('PLUS', index)}></span>
                                                            <span className="decrement" onClick={() => this.changeRequiredAcceptancesCount('MINUS', index)}></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </SimpleBar>
                                <div className="add-treshold" onClick={() => this.addTreshold()} data-tip="show" data-for="addTresholdTooltip"></div>
                            </div>
                        </div>

                        <div className={`tresholds users ${!hasAdditionalAcceptanceOn ? 'blured' : ''}`}>
                            <div className="section-header">
                                <h3>{this.context.t('Select employees who can accept documents for payment')}</h3>
                                <div className="info-icon" data-tip="show" data-for="employessTresholdsTooltip"></div>
                            </div>

                            <div className="section-list">
                                <SimpleBar style={{ maxHeight: '50vh', width: '100%', paddingRight: '10px' }}>
                                    {
                                        userAcceptanceTresholds.map((userTreshold, index) => {
                                            return (
                                                <div key={`userProjectTreshold-${index}`} className="project-treshold user">
                                                    <div className="user-info">
                                                        <div className={`profile-pic ${userTreshold.profilePic ? '' : 'default'}`}>
                                                            {
                                                                userTreshold.profilePic ? (
                                                                    <img src={userTreshold.profilePic} alt="Profile picture" />
                                                                ) : (
                                                                    null
                                                                )
                                                            }
                                                        </div>
                                                        <div className="user-name">
                                                            <span className="name">{userTreshold.name}</span>
                                                            <span className="email">{userTreshold.email}</span>
                                                        </div>
                                                        <div className={`checkbox ${userTreshold.canAccept ? 'selected' : ''}`} onClick={() => this.toogleAcceptanceForUser(!userTreshold.canAccept, index)}></div>
                                                    </div>
                                                    <div className={`form-group ${!userTreshold.canAccept ? 'blured' : ''}`}>
                                                        <label htmlFor={`userProjectTresholdPln-${index}`}>{this.context.t('Amount threshold for documents in PLN')}</label>
                                                        <input
                                                            id={`userProjectTresholdPln-${index}`}
                                                            type="text"
                                                            name="treshold_amount"
                                                            placeholder={this.context.t("no limit")}
                                                            value={userTreshold.treshold_amount}
                                                            onChange={(e) => this.editUserProjectTreshold(e, index)}
                                                            readOnly={!hasAdditionalAcceptanceOn || !userTreshold.canAccept}
                                                        />
                                                    </div>


                                                    <div className={`form-group ${!userTreshold.canAccept ? 'blured' : ''}`}>
                                                        <label htmlFor={`userProjectTresholdCurrency-${index}`}>{this.context.t('Amount threshold for currency documents')}</label>
                                                        <input
                                                            id={`userProjectTresholdCurrency-${index}`}
                                                            type="text"
                                                            name="treshold_amount_currency"
                                                            placeholder={this.context.t("no limit")}
                                                            value={userTreshold.treshold_amount_currency}
                                                            onChange={(e) => this.editUserProjectTreshold(e, index)}
                                                            readOnly={!hasAdditionalAcceptanceOn || !userTreshold.canAccept}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </SimpleBar>
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="button cancel" onClick={() => this.props.cancel()}>{this.context.t("Cancel")}</div>
                        <div className="button accept" onClick={() => this.saveConfiguration()}>{this.context.t("Save")}</div>
                    </div>

                    <ReactTooltip id='addTresholdTooltip' className='default-tooltip info-tooltip' effect='solid' place='right' delayShow={1000}>
                        <p>
                            {this.context.t('Add treshold')}
                        </p>
                    </ReactTooltip>

                    <ReactTooltip id='projectTresholdsTooltip' className='default-tooltip info-tooltip' effect='solid' place='left'>
                        <p>
                            {this.context.t('You can add several amount thresholds and set the number of people required to accept the document in each of them.')}
                        </p>
                        <p>
                            {this.context.t('Each threshold has a separate field for documents in PLN and a collective field for all other currencies, i.e. if you set a currency threshold of 10 000, it will include an invoice for EUR 9 999 and an invoice for USD 9 999.')}
                        </p>
                        <p>
                            {this.context.t('If you leave the threshold fields blank, they will include all documents not included in other thresholds.')}
                        </p>
                    </ReactTooltip>

                    <ReactTooltip id='employessTresholdsTooltip' className='default-tooltip info-tooltip' effect='solid' place='left'>
                        <p>
                            {this.context.t('Here you can select (check the checkbox on the right) people who can accept documents for payment.')}
                        </p>
                        <p>
                            {this.context.t('For each person, you can set amount thresholds that limit the possibility of accepting documents with a higher value.')}
                        </p>
                        <p>
                            {this.context.t('If you do not provide any amount for a given person, they will be able to accept any document.')}
                        </p>
                    </ReactTooltip>
                </div >
            </div >
        )
    }
}

AdditionalAcceptanceSettingsPopup.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = {
    getCurrentProjectAcceptanceConfiguration: CompanyActions.getCurrentProjectAcceptanceConfiguration,
    setCurrentProjectAcceptanceConfiguration: CompanyActions.setCurrentProjectAcceptanceConfiguration,
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalAcceptanceSettingsPopup)