import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

import { CompanyActions } from "../../actions/CompanyActions";

import "../../../src/css/SalesPage.css";

export class UnlockInvoiceBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cursorWait: false
        };
    }

    render() {
        const { invoiceData } = this.props
        return (
            <div className={`delete-items-popup`}>
                <div className="delete-items-window">
                    <div className="main-text-items">{this.context.t("Do you confirm unlocking the invoice for editing?")}</div>
                    {
                        invoiceData.acceptance_date && invoiceData.acceptedBy ? (
                            <div className="accept-info">{
                                this.context.t('Editing was blocked on {d} by {u}',
                                    {
                                        d: moment(invoiceData.acceptance_date).format('DD-MM-YYYY'),
                                        u: `${invoiceData.acceptedBy.first_name} ${invoiceData.acceptedBy.last_name}`
                                    }
                                )}</div>
                        ) : (
                            invoiceData.acceptedBy ? (
                                <div className="accept-info">{this.context.t('Editing was blocked by {u}', { u: `${invoiceData.acceptedBy.first_name} ${invoiceData.acceptedBy.last_name}` })}</div>
                            ) : null
                        )
                    }
                    {
                        invoiceData.sent_to ? (
                            <div className="sent-info">
                                <h5>{this.context.t('Invoice was sent to')}:</h5>
                                <ul>
                                    {
                                        invoiceData.sent_to.split(',').map(address => {
                                            return (
                                                <li>{address.trim()}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        ) : null
                    }
                    <div className="action-buttons">
                        <div className="cancel-button" onClick={() => this.props.cancel()}>{this.context.t("Cancel")}</div>
                        <div className="delete-button" onClick={() => this.props.confirm()}>{this.context.t("Confirm")}</div>
                    </div>
                </div>
            </div>
        )
    }
}

UnlockInvoiceBox.contextTypes = {
    t: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
    currentCompany: state.Company.currentCompany,
});

const mapDispatchToProps = {
    deleteDocItem: CompanyActions.deleteDocItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnlockInvoiceBox);
