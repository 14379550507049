import React, { Component } from 'react'
import { DropTarget } from 'react-dnd'

import DragItem from './DragItem'

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

const targetSource = {
    drop(props, monitor, component){
        return {
            xId: props.dropZoneX,
            yId: props.dropZoneY
        }
    }
}
function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        hovered: monitor.isOver(),
        item: monitor.getItem(),
        canDrop: monitor.canDrop(),
    }
}

export class DropTargetArea extends Component {

    constructor(props){
        super(props)
        this.state = {
            selectedFields: [],
            lastSelectedIndex: -1,
        }
    }

    handleItemSelection = (index, cmdKey, shiftKey) => {
        let selectedFields;
        const fields = this.props.containedItems
        console.log("handle sellect")
        console.log(fields)
        if(index >= 0) {
            const field = fields[index];
            const lastSelectedIndex = index;
            if (!cmdKey && !shiftKey) {
                selectedFields = [field];
            } else if (shiftKey) {
                if (this.state.lastSelectedIndex >= index) {
                    selectedFields = [].concat.apply(this.state.selectedFields, fields.slice(index, this.state.lastSelectedIndex));
                } else {
                    selectedFields = [].concat.apply(this.state.selectedFields, fields.slice(this.state.lastSelectedIndex + 1, index + 1));
                }
            } else if (cmdKey) {
                const foundIndex = this.state.selectedFields.findIndex(f => f.id === field.id);
                // If found remove it to unselect it.
                if (foundIndex >= 0) {
                    selectedFields = [...this.state.selectedFields.slice(0, foundIndex), ...this.state.selectedFields.slice(foundIndex + 1)];
                } else {
                    selectedFields = [...this.state.selectedFields, field];
                }
            }
            const finalList = fields ? fields.filter(f => selectedFields.find(a => a.id === f.id)) : [];
            this.setState({ selectedFields: finalList, lastSelectedIndex });
        }
    }

    clearItemSelection = () => {
        this.setState({ selectedFields: [], lastSelectedIndex: -1 });
    }

    render() {
        const { connectDropTarget, hovered, item, containedItems, deleteFromDropzone, dropZoneX, dropZoneY } = this.props
        return connectDropTarget(
            <div className={`drop-target ${hovered ? 'target-hovered' : ''}`}>
                <SimpleBar style={{ height: this.props.areaHeight, padding: '5px', width: '100%', maxWidth: '100%'}} >
                    {
                        containedItems ? (
                            containedItems.map((item, index) => (
                                <DragItem
                                    key={index}
                                    item={item}
                                    fields={this.props.companyWorkers}
                                    handleDrop={this.props.handleItemDrop}
                                    clearItemSelection={this.clearItemSelection}
                                    selectedFields={this.state.selectedFields}
                                    handleSelection={this.handleItemSelection}
                                    index={index}
                                />
                            ))
                        ) : (
                            null
                        )
                    }
                </SimpleBar>
            </div>
        )
    }
}

export default DropTarget('item', targetSource, collect)(DropTargetArea);