import React, { Component } from 'react'
import moment from 'moment'
import 'moment-timezone'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import { EventActions } from '../actions/EventActions'

export class Calendar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dateContext: moment(),
            today: moment(),
            todayDay: moment().format("DD"),
            todayMonth: moment().format("MMMM"),
            todayYear: moment().format('YYYY'),
            weekdaysShort: moment.weekdaysShort(),
            setStartDate: false
        }
    }

    componentDidMount() {
        var weekdaysIos = this.state.weekdaysShort
        var weekdaysIsoShorter = []
        weekdaysIos.push(weekdaysIos.shift())
        weekdaysIos.forEach(day => {
            weekdaysIsoShorter.push(day.slice(0, -1))
        })
        this.setState({
            weekdaysShort: weekdaysIsoShorter,
            currentTz: moment.tz.guess()
        })
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
        // if (this.props.sendDate) {
        //     this.props.sendDate(this.props.datePickerDay.display, this.props.datePickerDay.value)
        // }
    }

    year = () => {
        return this.state.dateContext.format("Y");
    }
    month = () => {
        return this.state.dateContext.format("MMMM");
    }
    daysInMonth = () => {
        return this.state.dateContext.daysInMonth();
    }

    daysInPrevMonth = (date) => {
        var prevMonth = moment(date).subtract(1, "month")
        return prevMonth.daysInMonth()
    }

    currentDate = () => {
        return this.state.dateContext.get("date");
    }
    currentDay = () => {
        return this.state.dateContext.format("D");
    }
    firstDayOfMonth = () => {
        var index = moment(this.state.dateContext).startOf('month').format('d')
        return index - 1 < 0 ? 6 : index - 1
    }

    nextMonth = () => {
        this.setState(state => {
            return {
                dateContext: moment(state.dateContext).add(1, "month")
            }
        })
    }

    prevMonth = () => {
        this.setState(state => {
            return {
                dateContext: moment(state.dateContext).subtract(1, "month")
            }
        })
    }

    toggleDay = (date) => {
        var momentDate = moment(date, 'DD-MM-YYYY')

        if(momentDate.day() === 6 || momentDate.day() === 0 || momentDate.format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD')) {

        } else {
            this.props.selectDate(date)
        }
    }

    render() {
        const { selectedDate } = this.props
        const { dateContext, eventsThisMonthTooltips } = this.state
        const currentMonth = this.month()
        const currentYear = this.year()

        var weekdays = this.state.weekdaysShort.map((day) => {
            return (
                <span className={`day-shortname`} key={day}>{this.context.t(day)}</span>
            )
        });

        var blanks = [];
        var daysInCurrentMonth = this.daysInMonth()
        var daysInPrevMonth = this.daysInPrevMonth(dateContext)
        var firstDayOfCurrMonth = this.firstDayOfMonth()

        for (var i = 1; i <= firstDayOfCurrMonth % 7; i++) {
            blanks.push(
                <span className="day-cell prev" key={"empty" + i}>{daysInPrevMonth - firstDayOfCurrMonth + i}</span>
            );
        }

        var daysInMonth = [];
        for (var i = 1; i <= daysInCurrentMonth; i++) {
            let currentDate = moment(dateContext).set("date", i).format('DD-MM-YYYY')
            daysInMonth.push(
                <span
                    key={"day" + i + currentMonth + currentYear}
                    className={`day-cell ${selectedDate && currentDate === selectedDate ? 'selected' : ''} ${moment(dateContext).set("date", i).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD') ? 'past' : ''} ${moment(dateContext).set("date", i).day() === 6 ? 'saturday' : ''} ${moment(dateContext).set("date", i).day() === 0 ? 'sunday' : ''}`}
                    onClick={() => this.toggleDay(currentDate)}
                >
                    {i}
                </span>
            );
        }

        return (
            <div className="appointment-calendar">
                <div className="calendar-header">
                    <div className="prev-month" onClick={() => this.prevMonth()}></div>
                    <h3>{this.context.t(this.month())} {this.year()}</h3>
                    <div className="next-month" onClick={() => this.nextMonth()}></div>
                </div>
                <div className="calendar-body">
                    <div className="weekdays">
                        {weekdays}
                    </div>
                    <div className="days">
                        {blanks}{daysInMonth}
                    </div>
                </div>
                {eventsThisMonthTooltips}
            </div>
        )
    }
}


Calendar.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
    user: state.User.user,
    projectEvents: [...state.Event.events.filter(e => e.project_id === ownProps.projectId)],
    events: [...state.Event.events],
    projectEventsLoaded: [...state.Event.projectEventsLoaded],
    projectEventsLoading: [...state.Event.projectEventsLoading],
    datePickerDay: state.Event.datePickerDay,
})

const mapDispatchToProps = {
    getProjectEvents: EventActions.getProjectEvents,
    getUserEvents: EventActions.getEvents,
    setCurrentMonth: EventActions.setCurrentMonth,
}

export default connect(mapStateToProps, mapDispatchToProps)(Calendar)
