import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import TextareaAutosize from 'react-textarea-autosize'

import '../../../css/OcrPage.css'

export class TransactionDescription extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }

        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    onChange = e => {
        e.preventDefault()
        const { value } = e.target

        this.props.onChange(value)
    }

    blurHandler = () => {
        if (this.props.autoSave && this.props.autoSave instanceof Function) {
            const { selectedOption } = this.props
            this.props.autoSave(selectedOption)
        }
    }

    render() {
        const { selectedOption, lang } = this.props
        return (
            <div className={`ocr-data`} id="wapro-doc-description-container" ref={this.wrapperRef}>
                <label>{this.context.t('Transaction description {l}', { l: lang })}</label>
                <TextareaAutosize
                    className={`tags-container`}
                    value={selectedOption}
                    name='fakir-logs'
                    minRows={1}
                    maxRows={10}
                    onChange={e => this.onChange(e)}
                    onBlur={e => this.blurHandler()}
                />
            </div>
        )
    }
}

TransactionDescription.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionDescription)