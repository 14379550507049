import React from 'react';

const getFieldStyle = (type) => {
    const columnAvailable = {
        display: 'inline-block',
        textAlign: 'center',
        width: 'auto',
        padding: '3px 5px',
        backgroundColor: '#F7F8FA',
        fontSize: '12px',
        borderRadius: '3px',
        webkitBoxShadow: '0px 3px 6px 0px rgba(19,37,65,0.1)',
        mozBoxShadow: '0px 3px 6px 0px rgba(19,37,65,0.1)',
        boxShadow: '0px 3px 6px 0px rgba(19,37,65,0.1)',
    };

    const columnSelected = {
        border: '1px solid #E4E9F3',
        backgroundColor: 'white',
        boxSizing: 'border-box',
        maxWidth: '420px',
        width: '420px',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '3px 5px',
        fontSize: '12px',
        webkitBoxShadow: '0px 3px 6px 0px rgba(19,37,65,0.1)',
        mozBoxShadow: '0px 3px 6px 0px rgba(19,37,65,0.1)',
        boxShadow: '0px 3px 6px 0px rgba(19,37,65,0.1)',
    };

    const columnNumber = {
        borderRadius: '3px',
        backgroundColor: '#E7E9ED',
        width: '20px',
        height: '20px',
        lineHeight: '20px',
        textAlign: 'center',
    }

    const columnNameAvailable = {
        textAlign: 'center',
    }

    const columnLabel = {
        color: '#8E99AB',
        border: '1px solid rgba(105, 183, 146, 0.5)',
        borderRadius: '2px',
        padding: '3px 5px',
    }

    if(type === 'COLUMN_AVAILABLE') {
        return columnAvailable;
    } else if (type === 'COLUMN_SELECTED') {
        return columnSelected;
    } else if (type === 'COLUMN_NUMBER') {
        return columnNumber
    } else if (type === 'COLUMN_NAME') {
        return columnNameAvailable
    } else if (type === 'COLUMN_LABEL') {
        return columnLabel
    }
}

const DragPreview = ({ item, translate}) => {
    if (item) {
        return (
            <div style={getFieldStyle(item.column ? 'COLUMN_SELECTED' : 'COLUMN_AVAILABLE')} key={item.dataType}>
                {
                    item.column ? (
                        <>
                            <div style={getFieldStyle('COLUMN_NUMBER')}>{item.column}</div>
                            <div style={getFieldStyle('COLUMN_NAME')}>{translate(item.dataTypeName)}</div>
                            <input style={getFieldStyle('COLUMN_LABEL')} type="text" value={item.label} placeholder={translate(item.dataTypeName)} />
                        </>
                    ) : (
                        <div>{translate(item.dataTypeName)}</div>
                    )
                }
            </div>
        )
    } else {
        return (<div></div>)
    }
};

export default DragPreview;