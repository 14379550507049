import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'

import '../../css/OcrPage.css'

export class OcrPageTooltips extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        const {currentInvoiceImageData, prevDocExists, prevDoc, nextDocExists, nextDoc} = this.props
        return(
            <>
                <ReactTooltip id='ocr-tooltip' className="default-tooltip project-info" place="bottom" effect='solid'>
                    <ul>
                        <li>
                            <span className="property">{this.context.t('Upload date:')}</span>
                            <span className="value">{moment(currentInvoiceImageData.uploadDate).format('DD-MM-YYYY, HH:mm')}</span>
                        </li>
                        <li>
                            <span className="property">{this.context.t('Upload method:')}</span>
                            <span className="value">{this.context.t(currentInvoiceImageData.uploadMethod === 'web' ? 'EasyDocs web' : currentInvoiceImageData.uploadMethod === 'mobile' ? 'EasyDocs mobile' : 'Email')}</span>
                        </li>
                        <li>
                            <span className="property">{this.context.t('Uploaded by:')}</span>
                            <span className="value">{currentInvoiceImageData.userThatUploaded}</span>
                        </li>

                        {
                            currentInvoiceImageData.userThatSetType ? (
                                <li>
                                    <span className="property">{this.context.t('Doc type set by:')}</span>
                                    <span className="value">{currentInvoiceImageData.userThatSetType}</span>
                                </li>
                            ) : (
                                null
                            )
                        }

                        {
                            currentInvoiceImageData.userThatVerified ? (
                                <li>
                                    <span className="property">{this.context.t('Verified by:')}</span>
                                    <span className="value">{currentInvoiceImageData.userThatVerified}</span>
                                </li>
                            ) : (
                                null
                            )
                        }
                    </ul>
                </ReactTooltip>

                <ReactTooltip id='ocr-model-tooltip' className="default-tooltip project-info" place="bottom" effect='solid'>
                    {
                        this.context.t('The document was read using {n1} OCR model. Click to read it again using {n2} OCR model.', {
                            n1: currentInvoiceImageData.used_ocr_model === 'BARDS' ? this.context.t('new') : this.context.t('old'),
                            n2: currentInvoiceImageData.used_ocr_model === 'BARDS' ? this.context.t('old') : this.context.t('new'),
                        })
                    }
                </ReactTooltip>

                {
                    prevDocExists ? (
                        <ReactTooltip id='prev-doc-tooltip' className="default-tooltip project-info" place="bottom" effect='solid'>
                            <ul>
                                <li>
                                    <span className="property">{this.context.t('Document:')}</span>
                                    <span className="value">{prevDoc.name}</span>
                                </li>
                                <li>
                                    <span className="property">{this.context.t('Status:')}</span>
                                    <span className="value">
                                        {
                                            prevDoc.status === 'IN_PROGRESS' ? (
                                                this.context.t('Awaiting acceptance')
                                            ) : (
                                                this.context.t('Awaiting verification')
                                            )
                                        }
                                    </span>
                                </li>
                                <li>
                                    <span className="property">{this.context.t('Uploaded by:')}</span>
                                    <span className="value">{prevDoc.uploaded}</span>
                                </li>
                            </ul>
                        </ReactTooltip>
                    ) : (null)
                }

                {
                    nextDocExists ? (
                        <ReactTooltip id='next-doc-tooltip' className="default-tooltip project-info" place="bottom" effect='solid'>
                            <ul>
                                <li>
                                    <span className="property">{this.context.t('Document:')}</span>
                                    <span className="value">{nextDoc.name}</span>
                                </li>
                                <li>
                                    <span className="property">{this.context.t('Status:')}</span>
                                    <span className="value">
                                        {
                                            nextDoc.status === 'IN_PROGRESS' ? (
                                                this.context.t('Awaiting acceptance')
                                            ) : (
                                                this.context.t('Awaiting verification')
                                            )
                                        }
                                    </span>
                                </li>
                                <li>
                                    <span className="property">{this.context.t('Uploaded by:')}</span>
                                    <span className="value">{nextDoc.uploaded}</span>
                                </li>
                            </ul>
                        </ReactTooltip>
                    ) : (null)
                }

            </>
        )
    }
}

OcrPageTooltips.contextTypes = {
	t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(OcrPageTooltips)