import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import 'react-activity/dist/react-activity.css'
import TextareaAutosize from 'react-textarea-autosize'
import ReactTooltip from 'react-tooltip'
import { Spinner } from 'react-activity'
import moment from 'moment'

import TagsComponent from './TagsComponent';
import MpkSplit from './MpkSplit/MpkSplit'
import Calendar from '../Calendar';

import parseDate from '../../helpers/dateParser'
import currencyList from '../../helpers/currencyList.json'

import { FileActions } from '../../actions/FileActions';
import { AlertActions } from '../../actions/AlertActions'

import '../../css/OcrPage.css'
export class OcrPageExpensesForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            fieldsWithErrors: {},
            fieldsWithWarnings: {}
        }

        this.docTypeWrapperRef = React.createRef()
        this.docflowWrapperRef = React.createRef()
        this.expenseOwnerWrapperRef = React.createRef()
        this.currencyWrapperRef = React.createRef()
        this.priorityWrapperRef = React.createRef()
    }


    componentDidMount() {
        const { currentProject, currentInvoiceImageData, projectMembers } = this.props

        var curentProjectDocflows = currentProject?.projectDocflows || []
        this.setState({
            projectMembers: projectMembers || [],
            availableDocflows: curentProjectDocflows
        })

        var defaultDocflow = null, possibleDocflowsCount = 0
        curentProjectDocflows.forEach(docflow => {
            if (docflow.doc_types?.includes(currentInvoiceImageData.docType) && !docflow.archived) {
                possibleDocflowsCount++
                if (!defaultDocflow) defaultDocflow = docflow
            }
        })

        if (possibleDocflowsCount === 1) this.selectDocflow(defaultDocflow)

        document.addEventListener("mousedown", this.handleClick);
    }

    componentDidUpdate(prevProps) {
        const { returnedOCRData, processingData } = this.props
        if (prevProps.returnedOCRData?.file !== returnedOCRData?.file) {
            this.checkFieldsForErrorsAndWarnings()
        }

        if (prevProps.processingData?.length !== processingData?.length) {
            this.checkFieldsForErrorsAndWarnings()
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
    }

    handleClick = (e) => {
        const { showIssueDateCalendar, issueDateCalendarIndex, showDocumentTypeList, showDocflowsList, showEmployeesList, showCurrencyList, showPriorityList } = this.state
        if (this.docTypeWrapperRef.current && !this.docTypeWrapperRef.current.contains(e.target) && showDocumentTypeList) {
            this.toggleQuestionList('DOC_TYPE')
        }
        if (this.docflowWrapperRef.current && !this.docflowWrapperRef.current.contains(e.target) && showDocflowsList) {
            this.toggleQuestionList('DOCFLOW')
        }
        if (this.expenseOwnerWrapperRef.current && !this.expenseOwnerWrapperRef.current.contains(e.target) && showEmployeesList) {
            this.toggleQuestionList('EMPLOYEE')
        }
        if (this.currencyWrapperRef.current && !this.currencyWrapperRef.current.contains(e.target) && showCurrencyList) {
            this.toggleQuestionList('CURRENCY')
        }
        if (this.priorityWrapperRef.current && !this.priorityWrapperRef.current.contains(e.target) && showPriorityList) {
            this.toggleQuestionList('PRIORITY')
        }

        if (showIssueDateCalendar && !this.hasAncestorId(e.target, `issueDateWrapper-${issueDateCalendarIndex}`)) {
            this.toggleCalendar('issueDate', issueDateCalendarIndex)
        }
    }

    hasAncestorId = (element, id) => {
        while (element) {
            if (element.id && element.id === id) return true

            element = element.parentNode
        }
        return false
    }

    checkDate = (date) => {
        if (date && parseDate(date).match(new RegExp("[0-9]{2}-[0-9]{2}-[0-9]{4}", "i"))) {
            return true
        } else {
            return false
        }
    }

    toggleQuestionList = (listType) => {
        this.setState({
            showDocumentTypeList: listType === 'DOC_TYPE' ? !this.state.showDocumentTypeList : this.state.showDocumentTypeList,
            showCurrencyList: listType === 'CURRENCY' ? !this.state.showCurrencyList : this.state.showCurrencyList,
            showEmployeesList: listType === 'EMPLOYEE' ? !this.state.showEmployeesList : this.state.showEmployeesList,
            showPriorityList: listType === 'PRIORITY' ? !this.state.showPriorityList : this.state.showPriorityList,
        })

        if (listType === 'EMPLOYEE' && !this.state.showEmployeesList) {
            const { currentInvoiceImageData } = this.props
            this.props.getProjectMembersForExpense(currentInvoiceImageData.projectId, members => {
                if (members) {
                    this.setState({
                        projectMembers: members
                    })
                } else {
                    this.setState({
                        showExpenseUsersList: false
                    })
                }
            })
        }

        if (listType === 'DOCFLOW') {
            const { currentProject, currentInvoiceImageData, user } = this.props
            if (
                currentProject?.is_dropzone_manager ||
                currentInvoiceImageData.uploaded_by === user.id ||
                currentInvoiceImageData.userThatUploadedId === user.id ||
                (currentInvoiceImageData.userThatUploadedId === -1 && currentInvoiceImageData.userThatUploaded === user.email)
            ) {
                this.setState({
                    showDocflowsList: !this.state.showDocflowsList
                })
            } else {
                this.props.alertWarn(this.context.t('You do not have permission to change docflow'))
            }
        }
    }

    selectDocType = (type) => {
        this.props.selectDocType(type)

        this.setState({
            showDocumentTypeList: false
        })
    }

    getDocTypeLabel = docType => {
        switch (docType) {
            case 'COSTS':
                return this.context.t('Cost')
            case 'EXPENSES_REPORT':
                return this.context.t('Cost')
            case 'MAG':
                return this.context.t('Warehouse doc')
            case 'EXPENSES':
                return this.context.t('Expense')
            case 'AGREEMENT':
                return this.context.t('Agreement')
            case 'SALE_INVOICE':
                return this.context.t('Income doc')
            case 'CORPORATE':
                return this.context.t('Corporate')
            case 'CORRESPONDENCE':
                return this.context.t('Correspondence')
            case 'OTHER':
                return this.context.t('Other')
            case 'PROTOCOL':
                return this.context.t('Protocol')
            default:
                return this.context.t('Other')
        }
    }

    selectDocflow = docflow => {
        const { currentInvoiceImageData } = this.props
        if (docflow.doc_types?.includes(currentInvoiceImageData.docType)) {
            var firstStep = docflow.docflowSteps[0]
            this.props.selectDocflow(docflow, firstStep)
            this.setState({
                showDocflowsList: false
            })
        }
        this.checkFieldsForErrorsAndWarnings()
    }

    selectCurrency = (currency) => {
        this.props.selectCurrency(currency)

        this.setState({
            showCurrencyList: false
        })
        this.checkFieldsForErrorsAndWarnings()
    }

    selectPriority = (priority) => {
        this.props.selectPriority(priority)

        this.setState({
            showPriority: false
        })
    }

    labelClick = (e, propName) => {
        const { returnedOCRData } = this.props
        var prop = returnedOCRData?.agreementProps?.find(e => e.name === propName)

        this.props.scrollToArea(e, propName, prop?.possition)

        if (propName.includes('Date')) {
            this.toggleCalendar(propName)
        }
    }

    onInputChange = (e, index) => {
        this.props.onInputChangeExpense(e, index)
    }

    onInputClick = (e, possition, index, propName) => {
        this.props.scrollToAreaForExpenses(e, possition, index, 'docNumber')

        if (propName === 'issueDate') {
            this.toggleCalendar(propName, index)
        }
    }

    onInputFocus = (e, propName) => {
    }

    onInputBlur = (e) => {
        this.props.focusOutAreaForExpenses(e)
        this.checkFieldsForErrorsAndWarnings()
    }

    toggleCounterpartiesList = () => {
        this.setState({
            showCounterpartiesList: !this.state.showCounterpartiesList
        })
    }

    toggleCalendar = (propName, index) => {
        const { showIssueDateCalendar } = this.state
        this.setState({
            showIssueDateCalendar: propName === 'issueDate' ? !showIssueDateCalendar : false,
            issueDateCalendarIndex: showIssueDateCalendar ? -1 : index
        })
    }

    onCalendarSelect = async (date) => {
        const { issueDateCalendarIndex } = this.state
        const { expensesOnDoc } = this.props


        if (date && issueDateCalendarIndex > -1 && expensesOnDoc[issueDateCalendarIndex]) {
            this.onInputChange({ target: { name: 'issueDate', value: moment(date).format('DD-MM-YYYY') } }, issueDateCalendarIndex)
        }

        this.setState({
            showIssueDateCalendar: false
        })
        this.checkFieldsForErrorsAndWarnings()
    }

    onCalendarUnselecet = () => {
        this.setState({
            showIssueDateCalendar: false,
            issueDateCalendarIndex: -1
        })
        this.checkFieldsForErrorsAndWarnings()
    }

    checkIfDateIsValid = (dateText) => {
        const datePattern = new RegExp("^[0-9]{2}-[0-9]{2}-[0-9]{4}$", "i");
        const datePatternOneDayDigit = new RegExp("^[0-9]{1}-[0-9]{2}-[0-9]{4}$", "i");
        const datePatternOneMonthDigit = new RegExp("^[0-9]{2}-[0-9]{1}-[0-9]{4}$", "i");
        const datePatternReverse = new RegExp("^[0-9]{4}-[0-9]{2}-[0-9]{2}$", "i");
        if (dateText) {
            dateText = dateText.replace(/\,/g, '-').replace(/\./g, '-').replace(/\//g, '-').replace(/[^0-9\-]/gm, '')
            dateText = dateText.trim();
            if (datePattern.test(dateText)) return moment(dateText, 'DD-MM-YYYY').isValid()
            if (datePatternOneDayDigit.test(dateText)) return moment(dateText, 'D-MM-YYYY').isValid()
            if (datePatternOneMonthDigit.test(dateText)) return moment(dateText, 'DD-M-YYYY').isValid()
            if (datePatternReverse.test(dateText)) return moment(dateText, 'YYYY-MM-DD').isValid()
            else return false
        } else return true
    }

    checkFieldsForErrorsAndWarnings = () => {
        const { accountWhiteListStatus, dataConfirmCheckbox } = this.state
        const {
            currentInvoiceImageData, expensesOnDoc
        } = this.props
        var errors = {}, warnings = {}
        if (!currentInvoiceImageData?.docflow?.id) {
            errors.docflow = this.context.t('Docflow is required')
        }

        expensesOnDoc?.forEach((expense, index) => {
            if (!expense.docNumber) {
                errors[`docNumber-${index}`] = this.context.t('Document number name is required')
            }
            if (!expense.issueDate) {
                errors[`issueDate-${index}`] = this.context.t('Issue date is required')
            }
            if (!expense.price) {
                errors[`price-${index}`] = this.context.t('Amount to pay is required')
            }
        })

        this.setState({
            fieldsWithErrors: errors,
            fieldsWithWarnings: warnings
        })
        var errorsCount = 0
        Object.keys(errors).forEach(key => {
            if (errors[key]) errorsCount++
        })
        this.props.setWaningsAndErrorsVisibility(warnings, errors)

        return errorsCount === 0
    }

    saveData = () => {
        const { dataConfirmCheckbox } = this.state
        if (!dataConfirmCheckbox) {
            this.props.alertWarn(this.context.t('You have to confirm the compliance of the data in the form with the data on the original document'))
        } else if (this.checkFieldsForErrorsAndWarnings()) {
            const {
                fieldsWithWarnings, fieldsWithErrors,
                linkedFiles
            } = this.state

            var errorsCount = 0
            Object.keys(fieldsWithErrors).forEach(key => {
                if (errorsCount[key]) errorsCount++
            })

            if (errorsCount === 0) {
                this.props.saveData()
            } else {
                this.props.alertWarn(this.context.t('Please correct the errors before saving the document'))
            }
        } else {
            this.props.alertWarn(this.context.t('Please correct the errors before saving the document'))
        }
    }

    selectExpenseOwner = (e, ftvId, dbId, userId, userName, projectId) => {
        this.props.selectExpenseOwner(e, ftvId, dbId, userId, userName, projectId)

        this.setState({
            showExpenseUsersList: false
        })
    }

    render() {
        const {
            projectMembers,
            availableDocflows, docflowModalIsOpen, docflowToOpen,
            dataConfirmCheckbox,
            showDocumentTypeList, showDocflowsList, showEmployeesList, showCurrencyList, showPriorityList,
            showCounterpartiesList,
            showIssueDateCalendar, issueDateCalendarIndex,
            fieldsWithWarnings, fieldsWithErrors,
        } = this.state
        const {
            user,
            expensesOnDoc,
            highlightWarnings, highlightErrors,
            currentInvoiceImageData, currentProjectRole,
            currency, priority,
            comment, documentDescription, tags,
            isAccountantInCurrentCompany, mpkSplit,
            returnedOCRData, processingData, dataReadWithOcrFrame,
            tagsList, tagsCreationLimitation,
            counterpartiesList,
        } = this.props


        return (
            <>
                <div className={`invoice-content ${highlightWarnings ? 'highlight-warnings' : ''} ${highlightErrors ? 'highlight-errors' : ''}`} ref="sideInvoiceBar">
                    {
                        Object.keys(fieldsWithWarnings).map((keyName, keyIndex) => {
                            return (
                                <ReactTooltip id={`${keyName}-warning-tooltip`} place="bottom" effect="solid" className="default-tooltip amount-to-pay">
                                    {fieldsWithWarnings[keyName]}
                                </ReactTooltip>
                            )
                        })
                    }

                    {
                        Object.keys(fieldsWithErrors).map((keyName, keyIndex) => {
                            return (
                                <ReactTooltip id={`${keyName}-error-tooltip`} place="bottom" effect="solid" className="default-tooltip amount-to-pay">
                                    {fieldsWithErrors[keyName]}
                                </ReactTooltip>
                            )
                        })
                    }

                    <SimpleBar style={{ maxHeight: '100%', marginRight: '-12px', paddingRight: '12px' }} autoHide={false}>
                        <div id="doc-type-question" className="choice-short" ref={this.docTypeWrapperRef}>
                            <div className="question">
                                {this.context.t('Document type ')}
                            </div>
                            <div className={`choosen-option doc-types ${showDocumentTypeList ? 'extended' : ''}`} onClick={() => this.toggleQuestionList('DOC_TYPE')}>
                                {
                                    this.getDocTypeLabel(currentInvoiceImageData.docType)
                                }

                                {
                                    showDocumentTypeList ? (
                                        <ul>
                                            <li onClick={e => this.selectDocType('COSTS')}>
                                                {this.context.t('Cost')}
                                                <span className="type-description">{this.context.t('Invoice or bill for the company')}</span>
                                            </li>
                                            <li onClick={e => this.selectDocType('EXPENSES')}>
                                                {this.context.t('Expense')}
                                                <span className="type-description">{this.context.t('Receipt or invoice for the employee')}</span>
                                            </li>
                                            <li onClick={e => this.selectDocType('SALE_INVOICE')}>
                                                {this.context.t('Income doc')}
                                                <span className="type-description">{this.context.t('Your sales invoice')}</span>
                                            </li>
                                            <li onClick={e => this.selectDocType('AGREEMENT')}>
                                                {this.context.t('Agreement')}
                                                <span className="type-description">{this.context.t('Your contract with the supplier/recipient')}</span>
                                            </li>
                                            <li onClick={e => this.selectDocType('CORRESPONDENCE')}>
                                                {this.context.t('Correspondence')}
                                                <span className="type-description">{this.context.t('letter or business email')}</span>
                                            </li>
                                            <li onClick={e => this.selectDocType('CORPORATE')}>
                                                {this.context.t('Corporate')}
                                                <span className="type-description">{this.context.t('official document')}</span>
                                            </li>
                                            <li onClick={e => this.selectDocType('MAG')}>
                                                {this.context.t('Warehouse doc')}
                                            </li>
                                            <li onClick={e => this.selectDocType('OTHER')}>
                                                {this.context.t('Other')}
                                            </li>
                                            <li onClick={e => this.selectDocType('PROTOCOL')}>
                                                {this.context.t('Protocol')}
                                            </li>
                                        </ul>
                                    ) : (
                                        null
                                    )
                                }
                            </div>
                        </div>
                        <div id="docflow-question" className={`choice-short ${fieldsWithErrors.docflow ? 'error' : ''}`} ref={this.docflowWrapperRef}>
                            <div className="question">
                                {this.context.t('Docflow')}
                                {
                                    fieldsWithErrors.docflow ? (
                                        <>
                                            <div className="error-icon" data-for={`docflow-error-tooltip`} data-tip='show'></div>
                                        </>
                                    ) : null
                                }
                            </div>
                            <div className={`choosen-option docflow ${showDocflowsList ? 'extended' : ''} ${!currentInvoiceImageData?.docflow?.name ? 'placeholder' : ''}`} onClick={() => this.toggleQuestionList('DOCFLOW')}>
                                {currentInvoiceImageData?.docflow?.name || this.context.t('Select docflow')}

                                {
                                    showDocflowsList && availableDocflows?.length ? (
                                        <ul>
                                            {
                                                availableDocflows.map(docflow => {
                                                    if (docflow.doc_types?.includes(currentInvoiceImageData.docType) && !docflow.archived)
                                                        return (
                                                            <li>
                                                                <div className="text" onClick={e => this.selectDocflow(docflow)}>{docflow.name}</div>
                                                                <div className="info" onClick={() => this.setState({ docflowModalIsOpen: true, docflowToOpen: docflow })}></div>
                                                            </li>
                                                        )
                                                    else return null
                                                })
                                            }
                                        </ul>
                                    ) : (
                                        null
                                    )
                                }
                            </div>
                        </div>
                        <div className="choice-short" ref={this.expenseOwnerWrapperRef}>
                            <div className="question">
                                {this.context.t('Expense owner')}
                            </div>
                            <div className={`choosen-option expense-owner ${showEmployeesList ? 'extended' : ''}`} onClick={() => this.toggleQuestionList('EMPLOYEE')}>
                                {currentInvoiceImageData.expenseOwnerName}

                                {
                                    showEmployeesList ? (
                                        projectMembers.length > 0 ? (
                                            <ul>
                                                <SimpleBar style={{ maxHeight: '400px', width: '100%', minWidth: '100%', paddingRight: '5px' }}>
                                                    {
                                                        projectMembers.map((user, i) => {
                                                            return (
                                                                <div className={`project-member ${projectMembers[i + 1] === undefined ? 'last' : ''} ${!user.is_service && projectMembers[i + 1]?.is_service ? 'next-is-service' : ''}`} onClick={e => this.selectExpenseOwner(e, currentInvoiceImageData.ftvId, currentInvoiceImageData.dbId, user.id, user.name, currentInvoiceImageData.projectId)}>{user.name} <br /> <span style={{ fontSize: '8px', color: 'rgba(105, 183, 146, 1)' }}>{user.email}</span></div>
                                                            )
                                                        })
                                                    }
                                                </SimpleBar>
                                            </ul>
                                        ) : (
                                            <div className="waiting-for-members">
                                                <Spinner size={20} speed={0.8} color={"#9099A5"} />
                                            </div>
                                        )
                                    ) : (
                                        null
                                    )
                                }
                            </div>
                        </div>
                        <div id="currency-question" className="choice-short" ref={this.currencyWrapperRef}>
                            <div className="question">
                                {this.context.t('Currency')}
                            </div>
                            <div className={`choosen-option ${showCurrencyList ? 'extended' : ''}`} onClick={() => this.toggleQuestionList('CURRENCY')}>
                                {currency}
                                {
                                    showCurrencyList ? (
                                        <ul>
                                            {
                                                currencyList.map(c => {
                                                    return (
                                                        <li className={`${currency === c ? 'selected' : ''}`} onClick={e => this.selectCurrency(c)}>{c}</li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    ) : (
                                        null
                                    )
                                }
                            </div>
                        </div>
                        <div id="priority-question" className="choice-short" ref={this.priorityWrapperRef}>
                            <div className="question">
                                {this.context.t('Priority')}
                            </div>
                            <div className={`choosen-option ${showPriorityList ? 'extended' : ''}`} style={{ minWidth: 100, textAlign: 'right' }} onClick={() => this.toggleQuestionList('PRIORITY')}>
                                {this.context.t(priority === 'high' ? 'Important' : 'Normal')}
                                {
                                    showPriorityList ? (
                                        <ul style={{ width: '100%', textAlign: 'left' }}>
                                            <li onClick={e => this.selectPriority('regular')}>{this.context.t('Normal')}</li>
                                            <li onClick={e => this.selectPriority('high')}>{this.context.t('Important')}</li>
                                        </ul>
                                    ) : (
                                        null
                                    )
                                }
                            </div>
                        </div>

                        <div className="form-section">
                            <h4>{this.context.t('Expenses')}</h4>
                            {
                                expensesOnDoc.map((expense, i) => {
                                    return (
                                        <div className="expense-block">
                                            {
                                                expensesOnDoc.length > 1 ? (
                                                    <div className="expenses-index">{i + 1}</div>
                                                ) : null
                                            }
                                            <div className="inputs-wrapper">
                                                <div className={`form-group doc-number ${fieldsWithErrors[`docNumber-${i}`] ? 'error' : ''}`}>
                                                    <label>{this.context.t('Document number')}</label>
                                                    <TextareaAutosize
                                                        name='docNumber'
                                                        className={`docNumber ${expense.docNumber ? '' : 'no-content'}`}
                                                        value={expense.docNumber}
                                                        onChange={(e) => this.onInputChange(e, i)}
                                                        onClick={(e) => this.onInputClick(e, expense.docNumberPossition, i, 'docNumber')}
                                                        onBlur={e => this.onInputBlur(e)}
                                                    />
                                                    {
                                                        fieldsWithErrors[`docNumber-${i}`] ? (
                                                            <>
                                                                <div className="error-icon" data-for={`docNumber-${i}-error-tooltip`} data-tip='show'></div>
                                                            </>
                                                        ) : null
                                                    }
                                                </div>
                                                <div id={`issueDateWrapper-${i}`} className={`form-group issueDate half ${showIssueDateCalendar && issueDateCalendarIndex === i ? 'front' : ''} ${fieldsWithErrors[`issueDate-${i}`] ? 'error' : ''}`}>
                                                    <label>{this.context.t('Issue Date')}</label>
                                                    <TextareaAutosize
                                                        className={`issueDate ${expense.issueDate ? '' : 'no-content'}`}
                                                        name='issueDate'
                                                        value={expense.issueDate}
                                                        onChange={(e) => this.onInputChange(e, i)}
                                                        onMouseDown={e => this.props.showDatePickerExpenses(e, i)}
                                                        onClick={(e) => this.onInputClick(e, expense.issueDatePossition, i, 'issueDate')}
                                                        onBlur={e => this.onInputBlur(e)}
                                                    />
                                                    <span className="calendar-icon" onClick={(e) => this.toggleCalendar('issueDate', i)}></span>
                                                    {
                                                        showIssueDateCalendar && issueDateCalendarIndex === i ? (
                                                            <div className={`date-picker ${i === expensesOnDoc?.length - 1 ? 'bottom' : ''}`}>
                                                                <Calendar
                                                                    selectDay={this.onCalendarSelect}
                                                                    unselectDay={this.onCalendarUnselecet}
                                                                    selectedDay={
                                                                        this.checkDate(expense.issueDate) ?
                                                                            {
                                                                                display: moment(parseDate(expense.issueDate), 'DD-MM-YYYY').format('LL'),
                                                                                value: moment(parseDate(expense.issueDate), 'DD-MM-YYYY')
                                                                            } : {
                                                                                display: null,
                                                                                value: null
                                                                            }
                                                                    }
                                                                    projectId={'none'}
                                                                    startDate={this.checkDate(expense.issueDate) ? moment(parseDate(expense.issueDate), 'DD-MM-YYYY') : moment()}
                                                                />
                                                            </div>
                                                        ) : (
                                                            null
                                                        )
                                                    }
                                                    {
                                                        fieldsWithErrors[`issueDate-${i}`] ? (
                                                            <>
                                                                <div className="error-icon with-other-icon" data-for={`issueDate-${i}-error-tooltip`} data-tip='show'></div>
                                                            </>
                                                        ) : null
                                                    }
                                                </div>
                                                <div className={`form-group price half ${fieldsWithErrors[`price-${i}`] ? 'error' : ''}`}>
                                                    <label>{this.context.t('Price')}</label>
                                                    <TextareaAutosize
                                                        name='price'
                                                        className={`price ${expense.price ? '' : 'no-content'}`}
                                                        value={expense.price}
                                                        onChange={(e) => this.onInputChange(e, i)}
                                                        onClick={(e) => this.onInputClick(e, expense.pricePossition, i, 'price')}
                                                        onBlur={e => this.onInputBlur(e)}
                                                    />
                                                    {
                                                        fieldsWithErrors[`price-${i}`] ? (
                                                            <>
                                                                <div className="error-icon" data-for={`price-${i}-error-tooltip`} data-tip='show'></div>
                                                            </>
                                                        ) : null
                                                    }
                                                </div>
                                                <div className="form-group comment">
                                                    <label>{this.context.t('Comment')}</label>
                                                    <TextareaAutosize
                                                        name='comment'
                                                        className={`comment`}
                                                        value={expense.comment}
                                                        onChange={(e) => this.onInputChange(e, i)}
                                                        onBlur={e => this.onInputBlur(e)}
                                                    />
                                                </div>

                                                <TagsComponent
                                                    currentTags={expense.tags}
                                                    tagsList={tagsList}
                                                    canAddNewTags={tagsCreationLimitation === 'EVERYONE' || tagsCreationLimitation.includes(currentProjectRole)}
                                                    setTags={value => this.onInputChange({ target: { name: 'tags', value: value } }, i)}
                                                    maxHeight={expensesOnDoc && expensesOnDoc.length === 1 ? 170 : 300}
                                                />
                                            </div>
                                            {
                                                expensesOnDoc.length > 1 ? (
                                                    <span className="delete-expense-button" onClick={e => this.props.deleteExpenseBlock(e, i)}></span>
                                                ) : null
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>

                        {
                            currentInvoiceImageData?.docflow?.has_mpk ? (
                                <div className="form-section">
                                    <h4>{this.context.t('Split payment into controlling dimensions')}</h4>
                                    <MpkSplit
                                        dataIsSyncesInOcr={returnedOCRData?.file === currentInvoiceImageData?.dbId}
                                        fileId={currentInvoiceImageData?.dbId}
                                        ftvId={currentInvoiceImageData?.ftvId}
                                        projectId={currentInvoiceImageData?.projectId}
                                        totalNetAmount={expensesOnDoc[0]?.price || 0}
                                        mpkSplit={mpkSplit}
                                        setMpkSplit={this.props.setMpkSplit}
                                        isAccountantInCurrentCompany={isAccountantInCurrentCompany}
                                        companyId={this.props.companyId}
                                        currency={currency}
                                        paid={false}
                                    />
                                </div>
                            ) : null
                        }
                    </SimpleBar>
                </div>
                <div className="action-buttons">
                    <div className="checkmark" onClick={() => this.setState({ dataConfirmCheckbox: !dataConfirmCheckbox })}>
                        {this.context.t('I confirm the compliance of the data in the form with the data on the original document')}
                        <div className={`checkbox ${dataConfirmCheckbox ? 'checked' : ''}`}></div>
                        {
                            fieldsWithErrors.dataConfirmCheckbox ? (
                                <div className="error-icon" data-for={`dataConfirmCheckbox-error-tooltip`} data-tip='show'></div>
                            ) : null
                        }
                    </div>
                    <button type="button" className={`button`} onClick={() => this.saveData()}>
                        {this.context.t("Save")}
                    </button>
                    {
                        !currentInvoiceImageData?.docflow?.has_mpk ? (
                            <span className="switcher" onClick={(e) => this.props.addExpenseToDoc(e)}>{this.context.t('Add expense to document')}</span>
                        ) : null
                    }
                </div>
            </>
        )
    }
}

OcrPageExpensesForm.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    userCompanies: state.Company.companies,
    currentProject: state.User.currentProject,
})

const mapDispatchToProps = {
    alertWarn: AlertActions.warning,
    autoSaveOcr: FileActions.autoSaveOcr,
    changeOCRData: FileActions.changeOCRData,
    getProjectMembersForExpense: FileActions.getProjectMembersForExpense,

}

export default connect(mapStateToProps, mapDispatchToProps)(OcrPageExpensesForm)