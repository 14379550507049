"use strict";

export const checkIfCompanyHasAccessToFunctionality = (company, minimumAccess) => {
    var currentPlanName = '', availableInCurrentPlan = true
    if (company) {
        if (company.subscription_type !== 'FREE') currentPlanName = company.subscription_type
        else currentPlanName = company.next_subscription_type

        if (currentPlanName === minimumAccess) availableInCurrentPlan = true
        else if (currentPlanName === 'BASIC') availableInCurrentPlan = false
        else if (currentPlanName === 'PRO' && minimumAccess !== 'BASIC') availableInCurrentPlan = false

        if (currentPlanName.includes('/USER') || currentPlanName === 'ENTERPRISE') availableInCurrentPlan = true
    }
    return availableInCurrentPlan
}

export const checkIfAnyOfUserCompaniesHasAccessToFunctionality = (userCompanies, minimumAccess) => {
    var hasAccessInMinOneCompany = false

    userCompanies.forEach(c => {
        if (c.company && !hasAccessInMinOneCompany) {
            var currentPlanName = ''
            if (c.company.subscription_type !== 'FREE') currentPlanName = c.company.subscription_type
            else currentPlanName = c.company.next_subscription_type
            if (currentPlanName === minimumAccess) hasAccessInMinOneCompany = true
            else if ((currentPlanName === 'BASIC' && minimumAccess !== 'SOLO') || (currentPlanName === 'SOLO' && minimumAccess !== 'BASIC')) hasAccessInMinOneCompany = false
            else if (currentPlanName === 'PRO' && minimumAccess !== 'BASIC') hasAccessInMinOneCompany = false

            if (currentPlanName.includes('/USER') || currentPlanName === 'ENTERPRISE') hasAccessInMinOneCompany = true
        }
    })

    return hasAccessInMinOneCompany
}