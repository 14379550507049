import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Spinner } from 'react-activity'
import moment from 'moment'
import 'simplebar/dist/simplebar.min.css';
import ReactGA from 'react-ga';

import WhatsNew from './WhatsNew'
import ProjectsList from './ProjectsList'
import CompaniesList from './CompaniesList'
import UsersList from './UsersList'
import EmailsTab from './EmailsTab'
import TransactionsTab from './TransactionsTab'
import MarketingTab from './MarketingTab'

import { UserActions } from '../../../actions/UserActions'
import { AlertActions } from '../../../actions/AlertActions'
import { CompanyActions } from '../../../actions/CompanyActions'

import '../../../css/SuperadminPage.css'

export class Management extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentTab: '',
            filterField: 'created',
            order: 'DESC',
            searchField: '',
            filterValues: false,
            selectedUser: null,
        }
    }

    componentDidMount() {
        //ReactGA.pageview(window.location.pathname + window.location.search)

        const { user } = this.props
        if (user.role === "SUPERADMIN") {
            this.setState({
                currentTab: "COMPANIES"
            })
            this.props.getAllCompanies(`filter={}`)
        } else {
            this.setState({
                currentTab: "TRANSACTIONS"
            })
            this.props.getEasyDocsPaymentsReport()
        }
    }

    onInputChange = e => {
        e.preventDefault()

        const { name, value } = e.target

        this.setState({ [name]: value })
    }

    searchSubmit = e => {
        e.preventDefault();
        this.setState({
            filterValues: true,
        })
    }

    changeTab = (tab) => {
        this.setState({
            currentTab: tab
        })

        if (tab === 'COMPANIES' && this.props.companies.length === 0) {
            this.props.getAllCompanies()
        } else if (tab === 'PROJECTS' && this.props.projects.length === 0) {
            this.props.getAllProjects()
        } else if (tab === 'USERS' && this.props.users.length === 0) {
            this.props.getAllUsers()
        } else if (tab === 'EMAILS' && this.props.informationEmails.length === 0) {
            this.props.getAllInformationEmails()
        } else if (tab === 'TRANSACTIONS' && this.props.easydocsPaymentsReport.length === 0) {
            this.props.getEasyDocsPaymentsReport()
            // this.props.getAllEasyDocsPayments()
        } else if (tab === 'WHATS_NEW' && this.props.whatsNewList.length === 0) {
            this.props.getWhatsNewList()
        } else if (tab === 'MARKETING') {
            this.props.getMarketingReport()
        }
    }

    refreshData = () => {
        const { currentTab } = this.state

        if (currentTab === 'COMPANIES') {
            this.props.getAllCompanies()
        } else if (currentTab === 'PROJECTS') {
            this.props.getAllProjects()
        } else if (currentTab === 'USERS') {
            this.props.getAllUsers()
        } else if (currentTab === 'TRANSACTIONS') {
            this.props.getEasyDocsPaymentsReport()
        } else if (currentTab === 'WHATS_NEW') {
            this.props.getWhatsNewList()
        } else if (currentTab === 'MARKETING') {
            this.props.getMarketingReport()
        }
    }

    render() {
        const { searchField, filterValues, currentTab } = this.state
        const { loadingScreen, user, tabIsActive, wrapperRef } = this.props
        if (tabIsActive || !user.new_easydocs_version) {
            return (
                <div className="superadmin-container">
                    <div className="page-header superadmin">
                        <div className="page-name superadmin">
                            {this.context.t('SuperAdmin')}
                        </div>
                    </div>

                    {
                        loadingScreen ? (
                            <div className="loading-screen">
                                <div className="activity-indicator">
                                    <Spinner size={50} speed={0.8} color={"#ffffff"} />
                                </div>
                            </div>
                        ) : (
                            null
                        )
                    }

                    <div className="tab-wraper">
                        <div className="header">
                            <div className="tab-nav">
                                {
                                    user.role === "SUPERADMIN" ? (
                                        <>
                                            <div className={`tab-name ${currentTab === 'COMPANIES' ? 'active' : ''}`} onClick={() => this.changeTab('COMPANIES')}>{this.context.t('Companies')}</div>
                                            <div className={`tab-name ${currentTab === 'PROJECTS' ? 'active' : ''}`} onClick={() => this.changeTab('PROJECTS')}>{this.context.t('Binders')}</div>
                                            <div className={`tab-name ${currentTab === 'USERS' ? 'active' : ''}`} onClick={() => this.changeTab('USERS')}>{this.context.t('Users')}</div>
                                        </>
                                    ) : (
                                        null
                                    )
                                }
                                <div className={`tab-name ${currentTab === 'WHATS_NEW' ? 'active' : ''}`} onClick={() => this.changeTab('WHATS_NEW')}>{this.context.t("What's new?")}</div>
                                <div className={`tab-name ${currentTab === 'EMAILS' ? 'active' : ''}`} onClick={() => this.changeTab('EMAILS')}>{this.context.t("Information e-mails")}</div>
                                <div className={`tab-name ${currentTab === 'TRANSACTIONS' ? 'active' : ''}`} onClick={() => this.changeTab('TRANSACTIONS')}>{this.context.t("Payments for ED")}</div>
                                <div className={`tab-name ${currentTab === 'MARKETING' ? 'active' : ''}`} onClick={() => this.changeTab('MARKETING')}>{this.context.t("Konwersje")}</div>
                            </div>
                            {currentTab !== 'WHATS_NEW' && currentTab !== 'EMAILS' && currentTab !== 'TRANSACTIONS' && currentTab !== 'MARKETING' &&
                                <div className="search-input">
                                    <input type="text" id="searchField" name="searchField" autoComplete={'off'} value={searchField} onChange={e => this.onInputChange(e)} placeholder={currentTab === 'COMPANIES' || currentTab === 'TRANSACTIONS' ? this.context.t('Search companies') : currentTab === 'PROJECTS' ? this.context.t('Search binders') : this.context.t('Search users')} />

                                    {
                                        currentTab === 'COMPANIES' || currentTab === 'PROJECTS' || currentTab === 'USERS' ? (
                                            <div className="refresh-data" onClick={() => this.refreshData()}>{this.context.t('Refresh data')}</div>
                                        ) : (
                                            null
                                        )
                                    }
                                </div>
                            }
                        </div>


                        <div className="table-wrapper table-responsive">
                            {
                                currentTab === 'COMPANIES' ? (
                                    <CompaniesList
                                        searchField={searchField}
                                        wrapperRef={wrapperRef}
                                    />
                                ) : (
                                    currentTab === 'PROJECTS' ? (
                                        <ProjectsList
                                            searchField={searchField}
                                            wrapperRef={wrapperRef}
                                        />
                                    ) : (
                                        currentTab === 'USERS' ? (
                                            <UsersList
                                                searchField={searchField}
                                                wrapperRef={wrapperRef}
                                            />
                                        ) : (
                                            currentTab === 'WHATS_NEW' ? (
                                                <WhatsNew />
                                            ) : (
                                                currentTab === 'EMAILS' ? (
                                                    <EmailsTab />
                                                ) : (
                                                    currentTab === 'TRANSACTIONS' ? (
                                                        <TransactionsTab searchField={searchField} />
                                                    ) : (
                                                        currentTab === 'MARKETING' ? (
                                                            <MarketingTab />
                                                        ) : (
                                                            null
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            }

                        </div>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }
}

Management.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    users: state.User.users || [],
    projects: state.User.projects || [],
    companies: state.Company.allCompanies || [],
    informationEmails: state.User.informationEmails || [],
    easydocsPayments: state.User.easydocsPayments || [],
    easydocsPaymentsReport: state.User.easydocsPaymentsReport || [],
    whatsNewList: state.User.whatsNewList || [],
    loadingScreen: state.File.loadingScreen || false,
})

const mapDispatchToProps = {
    getAllUsers: UserActions.getAllUsers,
    getAllProjects: UserActions.getAllProjects,
    getAllCompanies: CompanyActions.getAllCompanies,
    getAllInformationEmails: UserActions.getAllInformationEmails,
    getAllEasyDocsPayments: UserActions.getAllEasyDocsPayments,
    getEasyDocsPaymentsReport: UserActions.getEasyDocsPaymentsReport,
    getMarketingReport: UserActions.getMarketingReport,
    getWhatsNewList: UserActions.getWhatsNewList,
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(Management)