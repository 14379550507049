import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { Spinner } from 'react-activity'
import 'react-activity/dist/react-activity.css'

import { devCostsProjects } from '../../helpers/DevCosts/devCostsProjects'

import '../../css/OcrPage.css'

export class OcrProsList extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    getClassNameForOCRPropListItem = (name) => {
        if (name==='doc_description')return
        const { processingData, returnedOCRData, processedData, ocrMethod } = this.props
        if (ocrMethod === 'select') {
            var className = ''
            if (returnedOCRData && returnedOCRData.props) {
                var prop = returnedOCRData.props.find(p => p.name === name)
                if (prop && prop.text) className += ' done'
            } else if (processedData && processedData.includes(name)) className += ' done'

            if (processingData && processingData.includes(name)) className += 'processing'

            return className.trim()

        } else {
            if (returnedOCRData && returnedOCRData.manualProps) {
                var prop = returnedOCRData.manualProps.find(p => p.name === name)
                if (prop && prop.text) return 'done'
                else return ''
            }
        }
       
    }

    getClassNameForAgreementPropListItem = (name) => {
        const { returnedOCRData } = this.props
        if (returnedOCRData && returnedOCRData.agreementProps) {
            var prop = returnedOCRData.agreementProps.find(p => p.name === name)
            if (prop && prop.text) return 'done'
            else return ''
        }

    }

    getClassNameforDocDescription = () => {
        const {docDescription} = this.props
        if (docDescription) return 'done'
        else return ''
    }

    getClassNameForIncomePropListItem = (name) => {
        const { returnedOCRData } = this.props
        if (returnedOCRData && returnedOCRData.incomeProps) {
            var prop = returnedOCRData.incomeProps.find(p => p.name === name)
            if (prop && prop.text) return 'done'
            else return ''
        }
    }

    getClassNameForCorporatePropListItem = (name) => {
        const { returnedOCRData } = this.props
        if (returnedOCRData && returnedOCRData.corporateProps) {
            var prop = returnedOCRData.corporateProps.find(p => p.name === name)
            if (prop && prop.text) return 'done'
            else return ''
        }
    }

    getClassNameForOtherPropListItem = (name) => {
        const { returnedOCRData } = this.props
        if (returnedOCRData && returnedOCRData.otherProps) {
            var prop = returnedOCRData.otherProps.find(p => p.name === name)
            if (prop && prop.text) return 'done'
            else return ''
        }
    }

    getClassNameForProtocolPropListItem = (name) => {
        const { returnedOCRData } = this.props
        if (returnedOCRData && returnedOCRData.protocolProps) {
            var prop = returnedOCRData.protocolProps.find(p => p.name === name)
            if (prop && prop.text) return 'done'
            else return ''
        }
    }

    getClassNameForCorrespondencePropListItem = (name) => {
        const { returnedOCRData } = this.props
        if (returnedOCRData && returnedOCRData.correspondenceProps) {
            var prop = returnedOCRData.correspondenceProps.find(p => p.name === name)
            if (prop && prop.text) return 'done'
            else return ''
        }
    }

    render() {
        const {
            showPropertyList,
            currentInvoiceImageData,
            ocrMethod, paymentType, paymentMethod, currency,
            posUp, posLeft, posY, posX,
            waitingForExpensePropToFinishOCR, expensesOnDoc,
            projectId
        } = this.props

        if (showPropertyList && ['COSTS', 'EXPENSES_REPORT', 'MAG'].includes(currentInvoiceImageData.docType)) {
            return (
                <div className={`popup-property-list ${posUp ? 'up' : ''} ${posLeft ? 'left' : ''}`} style={{ top: posY, left: posX }}>
                    <div className="header">
                        {this.context.t('Select a data type')}
                    </div>
                    <ul>
                        {
                            ocrMethod === 'select' ? (
                                <li className={this.getClassNameForOCRPropListItem('sellerVatId')} onClick={(e) => this.props.clickOnProp(e, "sellerVatId")}>
                                    {this.context.t('Seller VAT-ID')}
                                    <div className="activity-indicator">
                                        <Spinner size={8} speed={0.8} color={"#69B792"} />
                                    </div>
                                </li>
                            ) : (
                                null
                            )
                        }

                        <li className={this.getClassNameForOCRPropListItem("sellerName")} onClick={(e) => this.props.clickOnProp(e, "sellerName")}>
                            {this.context.t('Seller Name')}
                            <div className="activity-indicator">
                                <Spinner size={8} speed={0.8} color={"#69B792"} />
                            </div>
                        </li>

                        {
                            currency !== 'PLN' && paymentMethod === 'not-paid' ? (
                                <li className={this.getClassNameForOCRPropListItem("address")} onClick={(e) => this.props.clickOnProp(e, "address")}>
                                    {this.context.t('Seller Address')}
                                    <div className="activity-indicator">
                                        <Spinner size={8} speed={0.8} color={"#69B792"} />
                                    </div>
                                </li>
                            ) : (
                                null
                            )
                        }

                        <li className={this.getClassNameForOCRPropListItem("buyerVatId")} onClick={(e) => this.props.clickOnProp(e, "buyerVatId")}>
                            {this.context.t('Buyer VAT-ID')}
                            <div className="activity-indicator">
                                <Spinner size={8} speed={0.8} color={"#69B792"} />
                            </div>
                        </li>

                        <li className={this.getClassNameForOCRPropListItem("buyerName")} onClick={(e) => this.props.clickOnProp(e, "buyerName")}>
                            {this.context.t('Buyer Name')}
                            <div className="activity-indicator">
                                <Spinner size={8} speed={0.8} color={"#69B792"} />
                            </div>
                        </li>

                        <li className={this.getClassNameForOCRPropListItem("invoiceNumber")} onClick={(e) => this.props.clickOnProp(e, "invoiceNumber")}>
                            {this.context.t('Document number')}
                            <div className="activity-indicator">
                                <Spinner size={8} speed={0.8} color={"#69B792"} />
                            </div>
                        </li>
                        <li className={this.getClassNameForOCRPropListItem("issueDate")} onClick={(e) => this.props.clickOnProp(e, "issueDate")}>
                            {this.context.t('Issue Date')}
                            <div className="activity-indicator">
                                <Spinner size={8} speed={0.8} color={"#69B792"} />
                            </div>
                        </li>
                        <li className={this.getClassNameForOCRPropListItem("saleDate")} onClick={(e) => this.props.clickOnProp(e, "saleDate")}>
                            {this.context.t('Sale Date')}
                            <div className="activity-indicator">
                                <Spinner size={8} speed={0.8} color={"#69B792"} />
                            </div>
                        </li>
                        {
                            paymentMethod === 'not-paid' || !paymentMethod ? (
                                <li className={this.getClassNameForOCRPropListItem("dueDate")} onClick={(e) => this.props.clickOnProp(e, "dueDate")}>
                                    {this.context.t('Due date')}
                                    <div className="activity-indicator">
                                        <Spinner size={8} speed={0.8} color={"#69B792"} />
                                    </div>
                                </li>
                            ) : (
                                null
                            )
                        }
                        <li className={this.getClassNameForOCRPropListItem("netto")} onClick={(e) => this.props.clickOnProp(e, "netto")}>
                            {this.context.t('Net')}
                            <div className="activity-indicator">
                                <Spinner size={8} speed={0.8} color={"#69B792"} />
                            </div>
                        </li>
                        <li className={this.getClassNameForOCRPropListItem("brutto")} onClick={(e) => this.props.clickOnProp(e, "brutto")}>
                            {this.context.t('Gross')}
                            <div className="activity-indicator">
                                <Spinner size={8} speed={0.8} color={"#69B792"} />
                            </div>
                        </li>
                        <li className={this.getClassNameForOCRPropListItem("price")} onClick={(e) => this.props.clickOnProp(e, "price")}>
                            {this.context.t('Amount to pay')}
                            <div className="activity-indicator">
                                <Spinner size={8} speed={0.8} color={"#69B792"} />
                            </div>
                        </li>
                        
                        {
                            currency !== 'PLN' ? (
                                <li className={this.getClassNameForOCRPropListItem("currencyRate")} onClick={(e) => this.props.clickOnProp(e, "currencyRate")}>
                                    {this.context.t('Currency rate')}
                                    <div className="activity-indicator">
                                        <Spinner size={8} speed={0.8} color={"#69B792"} />
                                    </div>
                                </li>
                            ) : null
                        }
                        {
                            paymentType === 'split' ? (
                                <li className={this.getClassNameForOCRPropListItem("tax")} onClick={(e) => this.props.clickOnProp(e, "tax")}>
                                    {this.context.t('VAT')}
                                    <div className="activity-indicator">
                                        <Spinner size={8} speed={0.8} color={"#69B792"} />
                                    </div>
                                </li>
                            ) : (
                                null
                            )
                        }
                        {
                            paymentMethod === 'not-paid' || !paymentMethod ? (
                                <li className={this.getClassNameForOCRPropListItem("bankData")} onClick={(e) => this.props.clickOnProp(e, "bankData")}>
                                    {this.context.t('Account number')}
                                    <div className="activity-indicator">
                                        <Spinner size={8} speed={0.8} color={"#69B792"} />
                                    </div>
                                </li>
                            ) : (
                                null
                            )
                        }

                        {
                            currency !== 'PLN' && paymentMethod === 'not-paid' ? (
                                <li className={this.getClassNameForOCRPropListItem("swift")} onClick={(e) => this.props.clickOnProp(e, "swift")}>
                                    {this.context.t('Seller\'s Bank SWIFT/BIC')}
                                    <div className="activity-indicator">
                                        <Spinner size={8} speed={0.8} color={"#69B792"} />
                                    </div>
                                </li>
                            ) : (
                                null
                            )
                        }

                        {
                            devCostsProjects.includes(projectId) ? (
                                <li onClick={(e) => this.props.clickOnProp(e, "devExTransactionDescription")}>
                                    {this.context.t('Transaction description {l}', { l: 'PL' })}
                                </li>
                            ) : (
                                null
                            )
                        }
                        <li className={this.getClassNameforDocDescription()} onClick={(e) => this.props.clickOnProp(e, "doc_description")}>
                            {this.context.t('Document description')}
                            <div className="activity-indicator">
                                <Spinner size={8} speed={0.8} color={"#69B792"} />
                            </div>
                        </li>
                        
                        
                    </ul>
                </div>
            )
        } else if (showPropertyList && currentInvoiceImageData.docType === 'EXPENSES') {
            return (
                <div className={`popup-property-list ${posUp ? 'up' : ''} ${posLeft ? 'left' : ''}`} style={{ top: posY, left: posX }}>
                    <div className="header">
                        {this.context.t('Select a data type')}
                    </div>
                    <SimpleBar style={{ maxHeight: '300px', width: '100%' }}>
                        <ul>
                            {
                                expensesOnDoc.map((expense, i) => {
                                    return (
                                        <>
                                            {expensesOnDoc.length > 1 ? <h2>{`${this.context.t('Expense bill')} ${i + 1}`}</h2> : null}
                                            <li className={`${expense && expense.docNumber ? ' done' : ''} ${waitingForExpensePropToFinishOCR ? 'cursor-wait' : ''}`} onClick={e => this.props.cropForExpenses(e, "documentNumber", i)}>
                                                {this.context.t('Document number')}
                                            </li>
                                            <li className={`${expense && expense.issueDate ? ' done' : ''} ${waitingForExpensePropToFinishOCR ? 'cursor-wait' : ''}`} onClick={e => this.props.cropForExpenses(e, "issueDate", i)}>
                                                {this.context.t('Issue Date')}
                                            </li>
                                            <li className={`${expense && expense.pricePossition ? ' done' : ''} ${waitingForExpensePropToFinishOCR ? 'cursor-wait' : ''}`} onClick={e => this.props.cropForExpenses(e, "price", i)}>
                                                {this.context.t('Price')}
                                            </li>
                                            <li className={this.getClassNameforDocDescription()} onClick={e => this.props.cropForExpenses(e, "doc_description", i)}>
                                                {this.context.t('Document description')}
                                            </li>
                                            
                                        </>
                                    )
                                })
                            }
                        </ul>
                    </SimpleBar>
                </div>
            )
        } else if (showPropertyList && (currentInvoiceImageData.docType === 'AGREEMENT')) {
            return (
                <div className={`popup-property-list ${posUp ? 'up' : ''} ${posLeft ? 'left' : ''}`} style={{ top: posY, left: posX }}>
                    <div className="header">
                        {this.context.t('Select a data type')}
                    </div>
                    <ul>
                        <li className={this.getClassNameForAgreementPropListItem('counterpartyVatId')} onClick={e => this.props.cropForDocType(e, "counterpartyVatId", "AGREEMENT")}>
                            {this.context.t('Counterpaty VAT-ID')}
                        </li>
                        <li className={this.getClassNameForAgreementPropListItem('counterpartyName')} onClick={e => this.props.cropForDocType(e, "counterpartyName", "AGREEMENT")}>
                            {this.context.t('Counterpaty name')}
                        </li>
                        <li className={this.getClassNameForAgreementPropListItem('date')} onClick={e => this.props.cropForDocType(e, "date", "AGREEMENT")}>
                            {this.context.t('Agreement date')}
                        </li>
                        <li className={this.getClassNameForAgreementPropListItem('number')} onClick={e => this.props.cropForDocType(e, "number", "AGREEMENT")}>
                            {this.context.t('Document number')}
                        </li>
                        <li className={this.getClassNameForAgreementPropListItem('description')} onClick={e => this.props.cropForDocType(e, "description", "AGREEMENT")}>
                            {this.context.t('Description')}
                        </li>
                        <li className={this.getClassNameforDocDescription()} onClick={e => this.props.cropForDocType(e, "doc_description", "AGREEMENT")}>
                            {this.context.t('Document description')}
                        </li>
                    </ul>
                </div>
            )
        } else if (showPropertyList && (currentInvoiceImageData.docType === 'SALE_INVOICE')) {
            return (
                <div className={`popup-property-list ${posUp ? 'up' : ''} ${posLeft ? 'left' : ''}`} style={{ top: posY, left: posX }}>
                    <div className="header">
                        {this.context.t('Select a data type')}
                    </div>
                    <ul>
                        <li className={this.getClassNameForIncomePropListItem('invoiceNumber')} onClick={e => this.props.cropForDocType(e, "invoiceNumber", "SALE_INVOICE")}>
                            {this.context.t('Invoice Number')}
                        </li>
                        <li className={this.getClassNameForIncomePropListItem('sellerVatId')} onClick={e => this.props.cropForDocType(e, "sellerVatId", "SALE_INVOICE")}>
                            {this.context.t('Seller VAT-ID')}
                        </li>
                        <li className={this.getClassNameForIncomePropListItem('sellerName')} onClick={e => this.props.cropForDocType(e, "sellerName", "SALE_INVOICE")}>
                            {this.context.t('Seller Name')}
                        </li>
                        <li className={this.getClassNameForIncomePropListItem('buyerVatId')} onClick={e => this.props.cropForDocType(e, "buyerVatId", "SALE_INVOICE")}>
                            {this.context.t('Buyer VAT-ID')}
                        </li>
                        <li className={this.getClassNameForIncomePropListItem('buyerName')} onClick={e => this.props.cropForDocType(e, "buyerName", "SALE_INVOICE")}>
                            {this.context.t('Buyer Name')}
                        </li>
                        <li className={this.getClassNameForIncomePropListItem('saleDate')} onClick={e => this.props.cropForDocType(e, "saleDate", "SALE_INVOICE")}>
                            {this.context.t('Sale date')}
                        </li>
                        <li className={this.getClassNameForIncomePropListItem('issueDate')} onClick={e => this.props.cropForDocType(e, "issueDate", "SALE_INVOICE")}>
                            {this.context.t('Issue date')}
                        </li>
                        <li className={this.getClassNameForIncomePropListItem('dueDate')} onClick={e => this.props.cropForDocType(e, "dueDate", "SALE_INVOICE")}>
                            {this.context.t('Due date')}
                        </li>
                        <li className={this.getClassNameForIncomePropListItem('netto')} onClick={e => this.props.cropForDocType(e, "netto", "SALE_INVOICE")}>
                            {this.context.t('Net value')}
                        </li>
                        <li className={this.getClassNameForIncomePropListItem('brutto')} onClick={e => this.props.cropForDocType(e, "brutto", "SALE_INVOICE")}>
                            {this.context.t('Gross value')}
                        </li>
                        <li className={this.getClassNameForIncomePropListItem('accountNumber')} onClick={e => this.props.cropForDocType(e, "accountNumber", "SALE_INVOICE")}>
                            {this.context.t('Account number')}
                        </li>
                        <li className={this.getClassNameforDocDescription()} onClick={e => this.props.cropForDocType(e, "doc_description", "SALE_INVOICE")}>
                            {this.context.t('Document description')}
                        </li>
                    </ul>
                </div>
            )
        } else if (showPropertyList && (currentInvoiceImageData.docType === 'CORPORATE')) {
            return (
                <div className={`popup-property-list ${posUp ? 'up' : ''} ${posLeft ? 'left' : ''}`} style={{ top: posY, left: posX }}>
                    <div className="header">
                        {this.context.t('Select a data type')}
                    </div>
                    <ul>
                        {/* <li className={this.getClassNameForCorporatePropListItem('counterpartyVatId')} onClick={e => this.props.cropForDocType(e,"counterpartyVatId", "CORPORATE")}>
                            {this.context.t('Counterpaty VAT-ID')}
                        </li>
                        <li className={this.getClassNameForCorporatePropListItem('counterpartyName')} onClick={e => this.props.cropForDocType(e,"counterpartyName", "CORPORATE")}>
                            {this.context.t('Counterpaty name')}
                        </li> */}
                        <li className={this.getClassNameForCorporatePropListItem('date')} onClick={e => this.props.cropForDocType(e, "date", 'CORPORATE')}>
                            {this.context.t('Date')}
                        </li>
                        <li className={this.getClassNameForCorporatePropListItem('description')} onClick={e => this.props.cropForDocType(e, "description", 'CORPORATE')}>
                            {this.context.t('Description')}
                        </li>
                        <li className={this.getClassNameforDocDescription()} onClick={e => this.props.cropForDocType(e, "doc_description", 'CORPORATE')}>
                            {this.context.t('Document description')}
                        </li>
                    </ul>
                </div>
            )
        } else if (showPropertyList && (currentInvoiceImageData.docType === 'OTHER')) {
            return (
                <div className={`popup-property-list ${posUp ? 'up' : ''} ${posLeft ? 'left' : ''}`} style={{ top: posY, left: posX }}>
                    <div className="header">
                        {this.context.t('Select a data type')}
                    </div>
                    <ul>
                        <li className={this.getClassNameForOtherPropListItem('counterpartyVatId')} onClick={e => this.props.cropForDocType(e, "counterpartyVatId", "OTHER")}>
                            {this.context.t('Counterpaty VAT-ID')}
                        </li>
                        <li className={this.getClassNameForOtherPropListItem('counterpartyName')} onClick={e => this.props.cropForDocType(e, "counterpartyName", "OTHER")}>
                            {this.context.t('Counterpaty name')}
                        </li>
                        <li className={this.getClassNameForOtherPropListItem('date')} onClick={e => this.props.cropForDocType(e, "date", 'OTHER')}>
                            {this.context.t('Date')}
                        </li>
                        <li className={this.getClassNameForOtherPropListItem('description')} onClick={e => this.props.cropForDocType(e, "description", 'OTHER')}>
                            {this.context.t('Description')}
                        </li>
                        <li className={this.getClassNameforDocDescription()} onClick={e => this.props.cropForDocType(e, "doc_description", 'OTHER')}>
                            {this.context.t('Document description')}
                        </li>
                    </ul>
                </div>
            )
        } else if (showPropertyList && (currentInvoiceImageData.docType === 'PROTOCOL')) {
            return (
                <div className={`popup-property-list ${posUp ? 'up' : ''} ${posLeft ? 'left' : ''}`} style={{ top: posY, left: posX }}>
                    <div className="header">
                        {this.context.t('Select a data type')}
                    </div>
                    <ul>
                        <li className={this.getClassNameForProtocolPropListItem('employee')} onClick={e => this.props.cropForDocType(e, "employee", "PROTOCOL")}>
                            {this.context.t('Employee')}
                        </li>
                        <li className={this.getClassNameForProtocolPropListItem('date')} onClick={e => this.props.cropForDocType(e, "date", 'PROTOCOL')}>
                            {this.context.t('Date')}
                        </li>
                        <li className={this.getClassNameForProtocolPropListItem('description')} onClick={e => this.props.cropForDocType(e, "description", 'PROTOCOL')}>
                            {this.context.t('Description')}
                        </li>
                        <li className={this.getClassNameforDocDescription()} onClick={e => this.props.cropForDocType(e, "doc_description", 'PROTOCOL')}>
                            {this.context.t('Document description')}
                        </li>
                    </ul>
                </div>
            )
        } else if (showPropertyList && (currentInvoiceImageData.docType === 'CORRESPONDENCE')) {
            return (
                <div className={`popup-property-list ${posUp ? 'up' : ''} ${posLeft ? 'left' : ''}`} style={{ top: posY, left: posX }}>
                    <div className="header">
                        {this.context.t('Select a data type')}
                    </div>
                    <ul>
                        <li className={this.getClassNameForCorrespondencePropListItem('counterpartyVatId')} onClick={e => this.props.cropForDocType(e, "counterpartyVatId", "CORRESPONDENCE")}>
                            {this.context.t('Counterpaty VAT-ID')}
                        </li>
                        <li className={this.getClassNameForCorrespondencePropListItem('counterpartyName')} onClick={e => this.props.cropForDocType(e, "counterpartyName", "CORRESPONDENCE")}>
                            {this.context.t('Counterpaty name')}
                        </li>
                        <li className={this.getClassNameForCorrespondencePropListItem('title')} onClick={e => this.props.cropForDocType(e, "title", 'CORRESPONDENCE')}>
                            {this.context.t('Title')}
                        </li>
                        <li className={this.getClassNameForCorrespondencePropListItem('date')} onClick={e => this.props.cropForDocType(e, "date", 'CORRESPONDENCE')}>
                            {this.context.t('Date')}
                        </li>
                        <li className={this.getClassNameForCorrespondencePropListItem('description')} onClick={e => this.props.cropForDocType(e, "description", 'CORRESPONDENCE')}>
                            {this.context.t('Description')}
                        </li>
                        <li className={this.getClassNameforDocDescription()} onClick={e => this.props.cropForDocType(e, "doc_description", 'CORRESPONDENCE')}>
                            {this.context.t('Document description')}
                        </li>
                    </ul>
                </div>
            )
        } else {
            return null
        }
    }
}

OcrProsList.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    returnedOCRData: { ...state.File.returnedOCRData },
    processingData: [...state.File.processingData],
    processedData: [...state.File.processedData],
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(OcrProsList)