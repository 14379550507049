import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

import Calendar from '../Calendar'

import currencyList from '../../helpers/currencyList.json'
import { AlertActions } from '../../actions/AlertActions'


const NUMERIC_OPERATORS = [
    {
        id: 'GT',
        label: 'Greater than'
    },
    {
        id: 'LT',
        label: 'Less than'
    },
    {
        id: 'EQ',
        label: 'Equal to'
    },
    {
        id: 'GTE',
        label: 'Greater than or equal to'
    },
    {
        id: 'LTE',
        label: 'Less than or equal to'
    }
]

const STRING_OPERATORS = [
    {
        id: 'CONTAINS',
        label: 'Contains'
    },
    {
        id: 'NCONTAINS',
        label: "Doesn't contain"
    },
    {
        id: 'EQ',
        label: 'Equal to'
    },
    {
        id: 'NEQ',
        label: 'Not equal to'
    },
    {
        id: 'STARTS',
        label: 'Startw with'
    },
    {
        id: 'ENDS',
        label: 'Ends with'
    }
]

const DATE_OPERATORS = [
    {
        id: 'GT',
        label: 'After'
    },
    {
        id: 'LT',
        label: 'Before'
    },
    {
        id: 'EQ',
        label: 'Equal to'
    }
]

const AVAILABLE_CONNECTORS = [
    {
        id: 'OR',
        label: 'Or'
    },
    {
        id: 'AND',
        label: 'And'
    }
]

const AVAILABLE_FIELDS = [
    {
        id: 'PRICE',
        label: 'Amount to pay',
        operators: [...NUMERIC_OPERATORS]
    },
    {
        id: 'DOC_NUMBER',
        label: 'Doc number',
        operators: [...STRING_OPERATORS]
    },
    {
        id: 'TAGS',
        label: 'Tags',
        operators: [...STRING_OPERATORS]
    },
    {
        id: 'MPK',
        label: 'Controlling dimension',
        operators: [...STRING_OPERATORS]
    },
    {
        id: 'IMPORT_DATE',
        label: 'Import date',
        operators: [...DATE_OPERATORS]
    },
    {
        id: 'ISSUE_DATE',
        label: 'Issue date',
        operators: [...DATE_OPERATORS]
    },
    {
        id: 'SALE_DATE',
        label: 'Sale date',
        operators: [...DATE_OPERATORS]
    },
    {
        id: 'DUE_DATE',
        label: 'Due date',
        operators: [...DATE_OPERATORS]
    }
]

const AVAILABLE_EFECTS = [
    {
        id: 'ACCEPTANCE',
        label: 'Requires acceptance'
    }
]

const DEFAULT_CONDITION_BLOCKS = [{
    conditionConnectorId: '',
    conditionConnectorLabel: '',
    conditions: [{
        conditionFieldId: '',
        conditionFieldLabel: '',
        operatorFieldId: '',
        operatorFieldLabel: '',
        availableOperators: [],
        conditionValue: '',
    }],
    efectId: 'ACCEPTANCE',
    efectLabel: 'Requires acceptance',
    selectedUsers: [],
    requiredUsersCount: 1,
}]

export class DocflowNotificationEditWindow extends Component {

    constructor(props) {
        super(props)
        this.state = {
            itemName: '',
            editingItemName: false,
            conditionBlocks: [{
                conditionConnectorId: '',
                conditionConnectorLabel: '',
                conditions: [{
                    conditionFieldId: '',
                    conditionFieldLabel: '',
                    operatorFieldId: '',
                    operatorFieldLabel: '',
                    availableOperators: [],
                    conditionValue: '',
                }],
                efectId: 'ACCEPTANCE',
                efectLabel: 'Requires acceptance',
                selectedUsers: [],
                requiredUsersCount: 1,
            }],
            showUsersList: false,
            showFieldsList: false,
            showOperatorsList: false,
            showConnectorsList: false,
            conditionBlockListIndex: -1,
            conditionListIndex: -1,
        }
    }

    componentDidMount() {
        const { editedDocflowItem } = this.props
        this.setState({
            ...editedDocflowItem,
            itemName: this.context.t(editedDocflowItem.title),
            conditionBlocks: editedDocflowItem.conditionBlocks || [{
                conditionConnectorId: '',
                conditionConnectorLabel: '',
                conditions: [{
                    conditionFieldId: '',
                    conditionFieldLabel: '',
                    operatorFieldId: '',
                    operatorFieldLabel: '',
                    availableOperators: [],
                    conditionValue: '',
                }],
                efectId: 'ACCEPTANCE',
                efectLabel: 'Requires acceptance',
                selectedUsers: [],
                requiredUsersCount: 1,
            }],
        })

        document.addEventListener("mousedown", this.handleClick);
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
    }

    handleClick = event => {
    }

    closeAllSuggestions = (e) => {
        const { showFieldsList, showOperatorsList, showConnectorsList, showCalendar, showCurrencyList, showEffectsList, showUsersList } = this.state
        if (showFieldsList) {
            this.setState({
                showFieldsList: false,
            });
        } else if (showOperatorsList) {
            this.setState({
                showOperatorsList: false,
            });
        } else if (showConnectorsList) {
            this.setState({
                showConnectorsList: false,
            });
        } else if (showCalendar) {
            this.setState({
                showCalendar: false,
            });
        } else if (showCurrencyList) {
            this.setState({
                showCurrencyList: false,
            });
        } else if (showEffectsList) {
            this.setState({
                showEffectsList: false,
            });
        } else if (showUsersList) {
            this.setState({
                showUsersList: false,
            });
        }
    };

    onInputChange = e => {
        e.preventDefault()

        var { name, value } = e.target

        this.setState({ [name]: value })
    }

    toggleConditionConnectorList = (blockIndex, conditionIndex) => {
        if (conditionIndex === 1) {
            const { showConnectorsList } = this.state
            this.setState({
                showConnectorsList: !showConnectorsList,
                conditionBlocListkIndex: showConnectorsList ? -1 : blockIndex,
                conditionListIndex: showConnectorsList ? -1 : conditionIndex
            })
        }
    }

    selectConditionConnector = (connector, blockIndex, conditionIndex) => {
        if (conditionIndex === 1) {
            const { conditionBlocks } = this.state
            var tempConditionBlocks = [...conditionBlocks]

            tempConditionBlocks[blockIndex].conditionConnectorId = connector.id
            tempConditionBlocks[blockIndex].conditionConnectorLabel = connector.label

            this.setState({
                conditionBlocks: tempConditionBlocks,
                showConnectorsList: false,
                conditionBlocListkIndex: -1,
                conditionListIndex: -1
            })
        }
    }

    toggleConditionFieldList = (blockIndex, conditionIndex) => {
        const { showFieldsList } = this.state
        this.setState({
            showFieldsList: !showFieldsList,
            conditionBlocListkIndex: showFieldsList ? -1 : blockIndex,
            conditionListIndex: showFieldsList ? -1 : conditionIndex
        })
    }

    selectConditionField = (field, blockIndex, conditionIndex) => {
        const { conditionBlocks } = this.state
        var tempConditionBlocks = [...conditionBlocks]

        if (field.id !== tempConditionBlocks[blockIndex].conditions[conditionIndex].conditionFieldId) {
            tempConditionBlocks[blockIndex].conditions[conditionIndex].operatorFieldId = ''
            tempConditionBlocks[blockIndex].conditions[conditionIndex].operatorFieldLabel = ''
            tempConditionBlocks[blockIndex].conditions[conditionIndex].conditionValue = ''
        }

        tempConditionBlocks[blockIndex].conditions[conditionIndex].conditionFieldId = field.id
        tempConditionBlocks[blockIndex].conditions[conditionIndex].conditionFieldLabel = field.label
        tempConditionBlocks[blockIndex].conditions[conditionIndex].availableOperators = field.operators

        this.setState({
            conditionBlocks: tempConditionBlocks,
            showFieldsList: false,
            conditionBlocListkIndex: -1,
            conditionListIndex: -1
        })
    }

    toggleConditionOperatorList = (blockIndex, conditionIndex, hasSelectedField) => {
        if (hasSelectedField) {
            const { showOperatorsList } = this.state
            this.setState({
                showOperatorsList: !showOperatorsList,
                conditionBlocListkIndex: showOperatorsList ? -1 : blockIndex,
                conditionListIndex: showOperatorsList ? -1 : conditionIndex
            })
        }
    }

    selectConditionOperator = (operator, blockIndex, conditionIndex) => {
        const { conditionBlocks } = this.state
        var tempConditionBlocks = [...conditionBlocks]

        tempConditionBlocks[blockIndex].conditions[conditionIndex].operatorFieldId = operator.id
        tempConditionBlocks[blockIndex].conditions[conditionIndex].operatorFieldLabel = operator.label

        this.setState({
            conditionBlocks: tempConditionBlocks,
            showOperatorsList: false,
            conditionBlocListkIndex: -1,
            conditionListIndex: -1
        })
    }

    onConditionValuechange = (event, blockIndex, conditionIndex) => {
        event.preventDefault()
        var { value } = event.target
        const { conditionBlocks } = this.state
        var tempConditionBlocks = [...conditionBlocks]

        if (tempConditionBlocks[blockIndex].conditions[conditionIndex].conditionFieldId === 'PRICE') {
            value = value.replace(/[^0-9.,]/, '').replace(',', '.')
        }
        tempConditionBlocks[blockIndex].conditions[conditionIndex].conditionValue = value
        this.setState({
            conditionBlocks: tempConditionBlocks
        })
    }

    toggleCurrencyList = (blockIndex, conditionIndex) => {
        const { showCurrencyList } = this.state
        this.setState({
            showCurrencyList: !showCurrencyList,
            conditionBlocListkIndex: showCurrencyList ? -1 : blockIndex,
            conditionListIndex: showCurrencyList ? -1 : conditionIndex
        })
    }

    selectConditionCurrency = (currency, blockIndex, conditionIndex) => {
        const { conditionBlocks } = this.state
        var tempConditionBlocks = [...conditionBlocks]

        tempConditionBlocks[blockIndex].conditions[conditionIndex].currency = currency

        this.setState({
            conditionBlocks: tempConditionBlocks,
            showCurrencyList: false,
            conditionBlocListkIndex: -1,
            conditionListIndex: -1
        })
    }

    toggleCalendar = (blockIndex, conditionIndex) => {
        const { showCalendar } = this.state
        this.setState({
            showCalendar: !showCalendar,
            conditionBlocListkIndex: showCalendar ? -1 : blockIndex,
            conditionListIndex: showCalendar ? -1 : conditionIndex
        })
    }

    onCalendarSelect = (date, blockIndex, conditionIndex) => {
        const { conditionBlocks } = this.state
        var tempConditionBlocks = [...conditionBlocks]

        tempConditionBlocks[blockIndex].conditions[conditionIndex].conditionValue = date.format('YYYY-MM-DD')

        this.setState({
            conditionBlocks: tempConditionBlocks,
            showCalendar: false,
            conditionBlocListkIndex: -1,
            conditionListIndex: -1
        })
    }

    onCalendarUnselecet = () => { }

    toggleConditionEffectList = blockIndex => {
        // const { showEffectsList } = this.state
        // this.setState({
        //     showEffectsList: !showEffectsList,
        //     conditionBlocListkIndex: showEffectsList ? -1 : blockIndex
        // })
    }

    selectConditionEffect = (effect, blockIndex) => {
        const { conditionBlocks } = this.state
        var tempConditionBlocks = [...conditionBlocks]

        tempConditionBlocks[blockIndex].efectId = effect.id
        tempConditionBlocks[blockIndex].efectLabel = effect.label

        this.setState({
            conditionBlocks: tempConditionBlocks,
            showEffectsList: false,
            conditionBlocListkIndex: -1
        })
    }

    toggleUsersList = (event, blockIndex) => {
        const clickedElement = event?.target || {};
        const clickedElementClass = clickedElement?.className || '';

        if (clickedElementClass.includes('remove')) {
            console.log('Remove button was clicked');
        } else {
            const { showUsersList } = this.state
            this.setState({
                showUsersList: !showUsersList,
                conditionBlocListkIndex: showUsersList ? -1 : blockIndex
            })
        }
    }

    selectConditionUser = (user, blockIndex) => {
        const { conditionBlocks } = this.state
        var tempConditionBlocks = [...conditionBlocks]

        if (tempConditionBlocks[blockIndex].selectedUsers.find(u => u.id === user.id)) {
            tempConditionBlocks[blockIndex].selectedUsers = tempConditionBlocks[blockIndex].selectedUsers.filter(u => u.id !== user.id)
        } else {
            tempConditionBlocks[blockIndex].selectedUsers.push(user)
        }


        this.setState({
            conditionBlocks: tempConditionBlocks
        })
    }

    removeSelectedConditionUser = (user, blockIndex) => {
        const { conditionBlocks } = this.state
        var tempConditionBlocks = [...conditionBlocks]

        tempConditionBlocks[blockIndex].selectedUsers = tempConditionBlocks[blockIndex].selectedUsers.filter(u => u.id !== user.id)
        tempConditionBlocks[blockIndex].requiredUsersCount = tempConditionBlocks[blockIndex].requiredUsersCount > tempConditionBlocks[blockIndex].selectedUsers.length ? tempConditionBlocks[blockIndex].selectedUsers.length : tempConditionBlocks[blockIndex].requiredUsersCount

        this.setState({
            conditionBlocks: tempConditionBlocks,
            showUsersList: false,
            conditionBlocListkIndex: -1
        })
    }

    addConditionConnector = (blockIndex) => {
        const { conditionBlocks } = this.state
        var tempConditionBlocks = [...conditionBlocks]

        tempConditionBlocks[blockIndex].conditions.push({
            conditionFieldId: '',
            conditionFieldLabel: '',
            operatorFieldId: '',
            operatorFieldLabel: '',
            availableOperators: [],
            conditionValue: '',
        })

        this.setState({
            conditionBlocks: tempConditionBlocks
        })
    }

    deleteConditionBlock = (blockIndex) => {
        const { conditionBlocks } = this.state
        var tempConditionBlocks = [...conditionBlocks]

        tempConditionBlocks.splice(blockIndex, 1)

        this.setState({
            conditionBlocks: tempConditionBlocks
        })
    }

    deleteCondition = (blockIndex, conditionIndex) => {
        const { conditionBlocks } = this.state
        var tempConditionBlocks = [...conditionBlocks]

        tempConditionBlocks[blockIndex].conditions.splice(conditionIndex, 1)

        this.setState({
            conditionBlocks: tempConditionBlocks
        })
    }

    addConditionBlock = () => {
        const { conditionBlocks } = this.state
        var tempConditionBlocks = [...conditionBlocks]

        tempConditionBlocks.push({
            conditionConnectorId: '',
            conditionConnectorLabel: '',
            conditions: [{
                conditionFieldId: '',
                conditionFieldLabel: '',
                operatorFieldId: '',
                operatorFieldLabel: '',
                availableOperators: [],
                conditionValue: '',
            }],
            efectId: 'ACCEPTANCE',
            efectLabel: 'Requires acceptance',
            selectedUsers: [],
            requiredUsersCount: 1,
        })

        this.setState({
            conditionBlocks: tempConditionBlocks
        })
    }

    saveCondition = () => {
        const {
            itemName, conditionBlocks
        } = this.state
        const { editedDocflowItem } = this.props
        var isValid = true

        // conditionConnectorId: '',
        //         conditionConnectorLabel: '',
        //         conditions: [{
        //             conditionFieldId: '',
        //             conditionFieldLabel: '',
        //             operatorFieldId: '',
        //             operatorFieldLabel: '',
        //             availableOperators: [],
        //             conditionValue: '',
        //         }],
        //         efectId: 'ACCEPTANCE',
        //         efectLabel: 'Requires acceptance',
        //         selectedUsers: [],
        //         requiredUsersCount: 1,

        conditionBlocks.forEach((block, blockIndex) => {
            if (block.selectedUsers.length === 0) {
                isValid = false
                this.props.alertWarn('Add users to whom the condition effect should apply')
            }

            if (block.conditions.length > 1 && !block.conditionConnectorId) {
                isValid = false
                this.props.alertWarn('Select the logical condition connector')
            }

            block.conditions.forEach((condition, conditionIndex) => {
                if (!condition.conditionFieldId) {
                    isValid = false
                    this.props.alertWarn('Select which field the condition applies to')
                }

                if (condition.conditionFieldId === 'PRICE' && !condition.currency) {
                    this.props.alertWarn('Select currency for amount in condition')
                }

                if (!condition.operatorFieldId) {
                    isValid = false
                    this.props.alertWarn('Select condition operator')
                }

                if (!condition.conditionValue) {
                    isValid = false
                    this.props.alertWarn('Provide a value for the field in the condition')
                }
            })
        })

        if (isValid) {
            var tempCondition = { ...editedDocflowItem }
            tempCondition.title = itemName
            tempCondition.conditionBlocks = conditionBlocks

            this.props.save(tempCondition)
        }
    }

    changeRequiredAcceptancesCount = (type, blockIndex) => {
        const { conditionBlocks } = this.state
        var newRequiredUsersCount = conditionBlocks[blockIndex].requiredUsersCount
        var tempSelectedUsers = conditionBlocks[blockIndex].selectedUsers
        if (tempSelectedUsers.length === 0) return


        if (type === 'PLUS') newRequiredUsersCount++
        if (type === 'MINUS') newRequiredUsersCount--

        if (newRequiredUsersCount < 1) newRequiredUsersCount = 1
        if (newRequiredUsersCount > tempSelectedUsers.length) newRequiredUsersCount = tempSelectedUsers.length

        var tempConditionBlocks = [...conditionBlocks]
        tempConditionBlocks[blockIndex].requiredUsersCount = newRequiredUsersCount
        this.setState({
            conditionBlocks: tempConditionBlocks
        })
    }


    render() {
        const { availableDocflowUsers, editedDocflowItem } = this.props
        const {
            itemName, conditionBlocks,
            editingItemName, showFieldsList, showOperatorsList, showConnectorsList, showCalendar, showCurrencyList, showEffectsList, showUsersList, conditionBlocListkIndex, conditionListIndex,
        } = this.state

        return (
            <div className="section-settings-window" onClick={(e) => this.closeAllSuggestions(e)}>
                <div className="settings-wrapper docflow condition-settings">
                    <h4>
                        {
                            editingItemName ? (
                                <>
                                    <input type="text" name="itemName" value={itemName} onChange={this.onInputChange} />
                                    <div className="icon accept-button" onClick={() => this.setState({ editingItemName: false })}></div>
                                </>
                            ) : (
                                <>
                                    <div>{itemName}</div>
                                    <div className="icon edit-button" onClick={() => this.setState({ editingItemName: true })}></div>
                                </>
                            )
                        }
                    </h4>
                    <h6>{this.context.t('Condition settings')}</h6>
                    <div className="settings-section condition-settings">
                        <div className="contitions-wrapper">
                            {
                                conditionBlocks.map((block, blockIndex) => {
                                    return (
                                        <div className={`condition-block ${conditionBlocks.length > 1 ? 'has-more-condition' : ''}`}>

                                            {
                                                block.conditions.map((condition, conditionIndex) => {
                                                    return (
                                                        <div className="condition">
                                                            {
                                                                conditionIndex > 0 ? (
                                                                    <div className={`form-group condition-connector ${showConnectorsList && conditionBlocListkIndex === blockIndex && conditionListIndex === conditionIndex ? 'in-front' : ''}`}>
                                                                        <input
                                                                            type="text"
                                                                            value={this.context.t(block.conditionConnectorLabel)}
                                                                            onClick={() => this.toggleConditionConnectorList(blockIndex, conditionIndex)}
                                                                            autoComplete="off"
                                                                        />
                                                                        <label
                                                                            onClick={() => this.toggleConditionConnectorList(blockIndex, conditionIndex)}
                                                                        >
                                                                            {this.context.t('Connector')}
                                                                        </label>
                                                                        {
                                                                            conditionIndex === 1 ? (
                                                                                <span
                                                                                    className={`expend-icon ${showConnectorsList && conditionBlocListkIndex === blockIndex && conditionListIndex === conditionIndex ? 'expended' : ''}`}
                                                                                    onClick={() => this.toggleConditionConnectorList(blockIndex, conditionIndex)}
                                                                                />
                                                                            ) : null
                                                                        }
                                                                        {
                                                                            showConnectorsList && conditionBlocListkIndex === blockIndex && conditionListIndex === conditionIndex ? (
                                                                                <ul>
                                                                                    {
                                                                                        AVAILABLE_CONNECTORS.map(c => {
                                                                                            return (
                                                                                                <li className={`${block.conditionConnectorId === c.id ? 'selected' : ''}`} onClick={() => this.selectConditionConnector(c, blockIndex, conditionIndex)}>
                                                                                                    {
                                                                                                        this.context.t(c.label)
                                                                                                    }
                                                                                                </li>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </ul>
                                                                            ) : null
                                                                        }
                                                                    </div>
                                                                ) : null
                                                            }
                                                            <div className={`form-group condition-field ${showFieldsList && conditionBlocListkIndex === blockIndex && conditionListIndex === conditionIndex ? 'in-front' : ''}`}>
                                                                <input
                                                                    type="text"
                                                                    value={this.context.t(condition.conditionFieldLabel)}
                                                                    onClick={() => this.toggleConditionFieldList(blockIndex, conditionIndex)}
                                                                    autoComplete="off"
                                                                />
                                                                <label
                                                                    onClick={() => this.toggleConditionFieldList(blockIndex, conditionIndex)}
                                                                >
                                                                    {this.context.t('If')}
                                                                </label>
                                                                <span
                                                                    className={`expend-icon ${showFieldsList && conditionBlocListkIndex === blockIndex && conditionListIndex === conditionIndex ? 'expended' : ''}`}
                                                                    onClick={() => this.toggleConditionFieldList(blockIndex, conditionIndex)}
                                                                />

                                                                {
                                                                    showFieldsList && conditionBlocListkIndex === blockIndex && conditionListIndex === conditionIndex ? (
                                                                        <ul>
                                                                            {
                                                                                AVAILABLE_FIELDS.map(f => {
                                                                                    return (
                                                                                        <li className={`${condition.conditionFieldId === f.id ? 'selected' : ''}`} onClick={() => this.selectConditionField(f, blockIndex, conditionIndex)}>
                                                                                            {
                                                                                                this.context.t(f.label)
                                                                                            }
                                                                                        </li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    ) : null
                                                                }
                                                            </div>

                                                            <div className={`form-group operator-field ${showOperatorsList && conditionBlocListkIndex === blockIndex && conditionListIndex === conditionIndex ? 'in-front' : ''}`}>
                                                                <input
                                                                    type="text"
                                                                    value={this.context.t(condition.operatorFieldLabel)}
                                                                    onClick={() => this.toggleConditionOperatorList(blockIndex, conditionIndex, condition.availableOperators?.length > 0)}
                                                                    autoComplete="off"
                                                                />
                                                                <label
                                                                    onClick={() => this.toggleConditionOperatorList(blockIndex, conditionIndex, condition.availableOperators?.length > 0)}
                                                                >
                                                                    {this.context.t('Operator')}
                                                                </label>
                                                                {
                                                                    condition.availableOperators?.length ? (
                                                                        <span
                                                                            className={`expend-icon ${showOperatorsList && conditionBlocListkIndex === blockIndex && conditionListIndex === conditionIndex ? 'expended' : ''}`}
                                                                            onClick={() => this.toggleConditionOperatorList(blockIndex, conditionIndex, condition.availableOperators?.length > 0)}
                                                                        />
                                                                    ) : null
                                                                }

                                                                {
                                                                    showOperatorsList && conditionBlocListkIndex === blockIndex && conditionListIndex === conditionIndex && condition.availableOperators?.length ? (
                                                                        <ul>
                                                                            {
                                                                                condition.availableOperators.map(o => {
                                                                                    return (
                                                                                        <li className={`${condition.operatorFieldId === o.id ? 'selected' : ''}`} onClick={() => this.selectConditionOperator(o, blockIndex, conditionIndex)}>
                                                                                            {
                                                                                                this.context.t(o.label)
                                                                                            }
                                                                                        </li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    ) : null
                                                                }
                                                            </div>

                                                            {
                                                                condition.conditionFieldId && condition.conditionFieldId.endsWith('_DATE') ? (
                                                                    <div className={`form-group value-field ${showCalendar && conditionBlocListkIndex === blockIndex && conditionListIndex === conditionIndex ? 'in-front' : ''}`}>
                                                                        <input type="text" value={condition.conditionValue} autoComplete="off" onClick={() => this.toggleCalendar(blockIndex, conditionIndex)} />
                                                                        <label onClick={() => this.toggleCalendar(blockIndex, conditionIndex)}>{this.context.t('Value')}</label>
                                                                        <span className="calendar-icon" onClick={() => this.toggleCalendar(blockIndex, conditionIndex)}></span>

                                                                        {
                                                                            showCalendar && conditionBlocListkIndex === blockIndex && conditionListIndex === conditionIndex ? (
                                                                                <Calendar
                                                                                    selectDay={date => this.onCalendarSelect(date, blockIndex, conditionIndex)}
                                                                                    unselectDay={this.onCalendarUnselecet}
                                                                                    selectedDay={
                                                                                        moment(condition.conditionValue, 'YYYY-MM-DD').isValid() ? {
                                                                                            display: moment(condition.conditionValue, 'YYYY-MM-DD').format('LL'),
                                                                                            value: moment(condition.conditionValue, 'YYYY-MM-DD')
                                                                                        } : {
                                                                                            display: null,
                                                                                            value: null
                                                                                        }
                                                                                    }
                                                                                    projectId={'none'}
                                                                                    startDate={moment(condition.conditionValue, 'YYYY-MM-DD').isValid() ? moment(condition.conditionValue, 'YYYY-MM-DD') : moment()}
                                                                                />
                                                                            ) : null
                                                                        }
                                                                    </div>
                                                                ) : (
                                                                    <div className="form-group value-field">
                                                                        <input type="text" value={condition.conditionValue} onChange={e => this.onConditionValuechange(e, blockIndex, conditionIndex)} autoComplete="off" />
                                                                        <label>{this.context.t('Value')}</label>
                                                                    </div>
                                                                )
                                                            }

                                                            {
                                                                condition.conditionFieldId === 'PRICE' ? (
                                                                    <div className={`form-group currency-field ${showCurrencyList && conditionBlocListkIndex === blockIndex && conditionListIndex === conditionIndex ? 'in-front' : ''}`}>
                                                                        <input
                                                                            type="text"
                                                                            value={this.context.t(condition.currency)}
                                                                            onClick={() => this.toggleCurrencyList(blockIndex, conditionIndex)}
                                                                            autoComplete="off"
                                                                        />
                                                                        <label
                                                                            onClick={() => this.toggleCurrencyList(blockIndex, conditionIndex)}
                                                                        >
                                                                            {this.context.t('Currency')}
                                                                        </label>
                                                                        <span
                                                                            className={`expend-icon ${showCurrencyList && conditionBlocListkIndex === blockIndex && conditionListIndex === conditionIndex ? 'expended' : ''}`}
                                                                            onClick={() => this.toggleCurrencyList(blockIndex, conditionIndex)}
                                                                        />

                                                                        {
                                                                            showCurrencyList && conditionBlocListkIndex === blockIndex && conditionListIndex === conditionIndex ? (
                                                                                <ul>
                                                                                    {
                                                                                        currencyList.map(c => {
                                                                                            return (
                                                                                                <li className={`${condition.currency === c ? 'selected' : ''}`} onClick={() => this.selectConditionCurrency(c, blockIndex, conditionIndex)}>
                                                                                                    {c}
                                                                                                </li>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </ul>
                                                                            ) : null
                                                                        }
                                                                    </div>
                                                                ) : null
                                                            }

                                                            {
                                                                block.conditions.length > 1 ? (
                                                                    <div className="delete-condition" onClick={() => this.deleteCondition(blockIndex, conditionIndex)}></div>
                                                                ) : null
                                                            }

                                                        </div>
                                                    )
                                                })
                                            }

                                            <div className="add-connector" onClick={() => this.addConditionConnector(blockIndex)}>
                                                <span className="plus-icon" />
                                                {this.context.t('Add logical connector')}
                                            </div>

                                            {
                                                <div className="condition-effect-block">
                                                    <div className={`form-group condition-effect ${showEffectsList && conditionBlocListkIndex === blockIndex ? 'in-front' : ''}`}>
                                                        <input
                                                            type="text"
                                                            value={this.context.t(block.efectLabel)}
                                                            onClick={() => this.toggleConditionEffectList(blockIndex)}
                                                            autoComplete="off"
                                                        />
                                                        <label
                                                            onClick={() => this.toggleConditionEffectList(blockIndex)}
                                                        >
                                                            {this.context.t('Effect')}
                                                        </label>
                                                        {/* <span
                                                            className={`expend-icon ${showEffectsList && conditionBlocListkIndex === blockIndex ? 'expended' : ''}`}
                                                            onClick={() => this.toggleConditionEffectList(blockIndex)}
                                                        />

                                                        {
                                                            showEffectsList && conditionBlocListkIndex === blockIndex ? (
                                                                <ul>
                                                                    {
                                                                        AVAILABLE_EFECTS.map(e => {
                                                                            return (
                                                                                <li className={`${block.efectId === e.id ? 'selected' : ''}`} onClick={() => this.selectConditionEffect(e, blockIndex)}>
                                                                                    {
                                                                                        this.context.t(e.label)
                                                                                    }
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            ) : null
                                                        } */}
                                                    </div>
                                                    <div className={`form-group condition-selected-users ${showUsersList && conditionBlocListkIndex === blockIndex ? 'in-front' : ''}`} ref={this.usersWrapperRef}>
                                                        <div className="selected-elements" onClick={event => this.toggleUsersList(event, blockIndex)}>
                                                            {
                                                                block.selectedUsers.map(user => {
                                                                    return <div className="element">
                                                                        {user.name} {user.email ? `(${user.email})` : ''}
                                                                        <span className="remove" onClick={() => this.removeSelectedConditionUser(user, blockIndex)}></span>
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                        <label htmlFor="docflowDocTypes" onClick={event => this.toggleUsersList(event, blockIndex)}>{this.context.t('Users/s responsible for the stage')}</label>
                                                        <span className={`expend-icon ${showUsersList && conditionBlocListkIndex === blockIndex ? 'expended' : ''}`} onClick={event => this.toggleUsersList(event, blockIndex)}></span>

                                                        {
                                                            showUsersList && conditionBlocListkIndex === blockIndex ? (
                                                                <ul>
                                                                    {
                                                                        availableDocflowUsers.map(user => {
                                                                            return (
                                                                                <li className={`user ${block.selectedUsers.includes(u => u.id === user.id) ? 'selected' : ''}`} onClick={() => this.selectConditionUser(user, blockIndex)}>
                                                                                    <div className="user-photo">
                                                                                        {
                                                                                            user.profilePic ? (
                                                                                                <img src={user.profilePic} alt={user.name} />
                                                                                            ) : (
                                                                                                <div className="default-pic"></div>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                    <div className="user-info">
                                                                                        <div className="user-name">{user.name}</div>
                                                                                        {
                                                                                            user.email ? (
                                                                                                <div className="user-email">
                                                                                                    {user.email}
                                                                                                </div>
                                                                                            ) : null
                                                                                        }
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            ) : null
                                                        }
                                                    </div>

                                                    {
                                                        block.efectId === 'ACCEPTANCE' ? (
                                                            <div className="users-count">
                                                                <div className="number">
                                                                    {block.requiredUsersCount}
                                                                    <span className="increment" onClick={() => this.changeRequiredAcceptancesCount('PLUS', blockIndex)}></span>
                                                                    <span className="decrement" onClick={() => this.changeRequiredAcceptancesCount('MINUS', blockIndex)}></span>
                                                                </div>
                                                                <div className="text">{this.context.t("The number of people who need to accept the document")}</div>
                                                            </div>
                                                        ) : null
                                                    }
                                                </div>
                                            }

                                            {
                                                conditionBlocks.length > 1 ? (
                                                    <div className="delete-condition" onClick={() => this.deleteConditionBlock(blockIndex)}>{this.context.t('Delete condition')}</div>
                                                ) : null
                                            }

                                        </div>
                                    )
                                })
                            }

                            <div className="add-condition" onClick={() => this.addConditionBlock()}>
                                <span className="plus-icon" />
                                {this.context.t('Add another condition')}
                            </div>
                        </div>

                        <div className="action-buttons align-right">
                            <div className="button reset" onClick={() => this.props.close()}>
                                {this.context.t('Cancel')}
                            </div>
                            <div className="button save" onClick={() => this.saveCondition()}>
                                {this.context.t('Save')}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )

    }
}

DocflowNotificationEditWindow.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(DocflowNotificationEditWindow)