import React, { Component } from 'react'
import { DragSource, DropTarget } from 'react-dnd'

import DragItem from './DragItem'


const targetSource = {
    drop(props, monitor, component) {
        return {
            name: props.name,
        }
    }
}

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        hovered: monitor.isOver(),
        item: monitor.getItem(),
        canDrop: monitor.canDrop(),
    }
}

export class DropTargetArea extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        const { connectDropTarget, hovered, containedItems, name} = this.props
        return connectDropTarget(
            <div className={`drop-target ${hovered ? 'target-hovered' : ''} ${name}`}>
                {
                    containedItems ? (
                        containedItems.map((item, index) => (
                            <DragItem
                                key={index}
                                item={item}
                                fields={this.props.companyWorkers}
                                handleDrop={this.props.handleItemDrop}
                                moveItem={this.props.moveItem}
                                moveNewItem={this.props.moveNewItem}
                                removeAllPseudoElements={this.props.removeAllPseudoElements}
                                index={index}
                                selectedFields={[]}
                                listName={name}
                                onInputChange={this.props.onInputChange}
                                onInputBlur={this.props.onInputBlur}
                            />
                        ))
                    ) : (
                        null
                    )
                }
            </div>
        )
    }
}

export default DropTarget('item', targetSource, collect)(DropTargetArea);