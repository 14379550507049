import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import arrayObjectIndexOf from "../../helpers/indexOfObject"

import { Bounce } from 'react-activity'
import 'react-activity/dist/react-activity.css'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import DropFileTarget from './DropFileTarget'
import PendingFilesList from './V2_PendingFilesList'

import { FileActions } from '../../actions/FileActions'
import { AlertActions } from '../../actions/AlertActions'

export class PendingFiles extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showFilterOptions: false,
            lastFilesLoadTime: null
        }
    }

    componentDidMount() {
        window.addEventListener('mousedown', this.pageClick, false);
        window.addEventListener('focus', this.windowFocus);
        const { filesToVerifiLoaded, projectId, directory } = this.props
        if (filesToVerifiLoaded && projectId) {
            const projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, projectId, "project")
            if (projectIndex === -1 || filesToVerifiLoaded[projectIndex].reloadFiles) {
                this.props.getProjectResourcesForDashboard(projectId, 0, directory)
                this.setState({
                    lastFilesLoadTime: new Date()
                })
            } else if (filesToVerifiLoaded[projectIndex].sendRequest && filesToVerifiLoaded[projectIndex].loaded) {
                this.props.getFilesToVerifyRefetch(projectId, 0)
                this.setState({
                    lastFilesLoadTime: new Date()
                })
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { filesToVerifiLoaded, projectId, directory } = this.props
        if (filesToVerifiLoaded && projectId) {
            const projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, projectId, "project")
            if (projectIndex === -1 || filesToVerifiLoaded[projectIndex].reloadFiles) {
                this.props.getProjectResourcesForDashboard(projectId, 0, directory)
            } else if (projectIndex > -1 && prevProps.projectId !== projectId) {
                this.props.getFilesToVerifyRefetch(projectId, 0)

            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('mousedown', this.pageClick, false);
        window.removeEventListener('focus', this.windowFocus);
    }

    windowFocus = () => {
        const { filesToVerifiLoaded, projectId } = this.props
        const { lastFilesLoadTime } = this.state
        if (
            filesToVerifiLoaded && projectId &&
            new Date() - lastFilesLoadTime > (1000 * 60 * 3) //ostatni load był minimim 3 min temu, ignorujemy w ten sposób przypadki alt tab, lub np. kliknięcie w eksplorer plików windows w celu znalezienie odpowiedniego pliku i przerzucenia go do ED - klknięcie w inną plikację (np. eksplorer plików i powrót do ED to też focus karty)
        ) {
            const projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, projectId, "project")
            if (projectIndex === -1 || filesToVerifiLoaded[projectIndex].reloadFiles) {
                this.props.getProjectResourcesForDashboard(this.props.projectId, 0, this.props.directory)
            } else if (filesToVerifiLoaded[projectIndex].sendRequest && filesToVerifiLoaded[projectIndex].loaded) {
                this.props.getFilesToVerifyRefetch(this.props.projectId, 0)
            }
        }
    }

    getSortClass = (project, sortField) => {
        const { filesToVerifiLoaded } = this.props

        const index = arrayObjectIndexOf(filesToVerifiLoaded, project, "project")

        if (index === -1 || filesToVerifiLoaded[index].files.length === 0) {
            return ''
        } else {
            if (filesToVerifiLoaded[index].sortField === sortField) {
                if (filesToVerifiLoaded[index].order === "asc")
                    return "icon-sort down"
                return "icon-sort up"
            }
            return "icon-sort"
        }
    }

    pageClick = e => {
        if (e.target) {
            if (e.target.nodeName === 'LI' && e.target.className.includes('files-to-verify-filter-option')) {
                console.log('clicked on LI')
            } else if (e.target.nodeName === 'SPAN' && e.target.className.includes('arrow')) {
                console.log('clicked on arrow')
            } else {
                this.setState({
                    showFilterOptions: false
                })
            }
        }
    }

    drawIcon = (name) => {

        var extension = name.split('.')
        extension = extension[extension.length - 1]

        if (extension === 'pdf' || extension === 'PDF') {
            return <div className="icon pdf-icon"></div>
        } else {
            return <div className="icon image-icon"></div>
        }
    }

    handleDrop = (file, dropTargetId) => {
        console.log(file)
        console.log('dropped : ' + file.dbId + " to dropTarget: " + dropTargetId)

        if (file.projectId !== dropTargetId) {
            this.props.dropFileFromOtherProject(file.dbId, file.name, file.projectId, dropTargetId)
        }
    }

    onFilterInputchange = (e, projectId) => {
        e.preventDefault()
        const { value } = e.target

        this.props.setPendingFilesSearchField(value, projectId)
    }

    setPendingFilesFilter = (value, projectId) => {
        this.props.setPendingFilesFilter(value, projectId)

        this.setState({
            showFilterOptions: false
        })
    }

    fileMatchesFilter = file => {
        const { filesToVerifiLoaded, projectId } = this.props
        const index = arrayObjectIndexOf(filesToVerifiLoaded, projectId, "project")
        if (index > -1 && file) {
            var show = true
            if (filesToVerifiLoaded[index].searchField && file.name && !file.name.toLowerCase().includes(filesToVerifiLoaded[index].searchField.toLowerCase())) show = false

            if (filesToVerifiLoaded[index].filter === 'TO_VERIFY' && file.status !== "NOT_VERIFIED" && file.status !== "NEW") show = false

            if (filesToVerifiLoaded[index].filter === 'TO_ACCEPT' && file.status !== "IN_PROGRESS") show = false

            if (filesToVerifiLoaded[index].filter === 'TO_PAY' && file.paymentMethod !== "not-paid") show = false

            return show
        } else {
            return true
        }
    }

    getSortClass = (name) => {
        const { filesToVerifySortOrder, filesToVerifySortField } = this.props
        if (filesToVerifySortField === name) {
            if (filesToVerifySortOrder === "asc")
                return "icon-sort up"
            return "icon-sort down"
        }
        return "icon-sort"
    }

    render() {
        const { showFilterOptions } = this.state
        const { user, acceptedFirtsDocument, filesToVerifiLoaded, projectId, projectCode, viewMode, simpleBarHeightWithDocs, simpleBarHeightWithoutDocs, accessLevel } = this.props
        const index = arrayObjectIndexOf(filesToVerifiLoaded, projectId, "project")
        if (index > -1 && filesToVerifiLoaded[index].loaded) {
            return (
                <div className={`pending-files ${filesToVerifiLoaded[index].files.length === 0 && localStorage.getItem('adminOnboarding') === 'MINI' && !user.finished_admin_onboarding && !acceptedFirtsDocument ? 'pulsing-frame-2' : ''}`}>
                    {
                        filesToVerifiLoaded[index].files.length > 0 && filesToVerifiLoaded[index].loaded ? (
                            <>
                                <div className="header">
                                    <div className="title-wrapper">
                                        <div className="dropzone-header-icon"></div>
                                        <div className="dropzone-header-text">
                                            <h2>{this.context.t('Dropzone and docs to verify')}</h2>
                                        </div>
                                    </div>

                                    <div id={`pendingFilesFilter${projectId}`} className="filters-wrapper">
                                        <input id={`pendingFilesFilterInput${projectId}`} type="text" name="searchField" value={filesToVerifiLoaded[index].searchField} onChange={e => this.onFilterInputchange(e, projectId)} placeholder={this.context.t('Search documents')} />

                                        <span id={`pendingFilesFilterArrow${projectId}`} className={`arrow ${showFilterOptions ? 'extended' : ''}`} onClick={() => this.setState({ showFilterOptions: !showFilterOptions })}></span>

                                        {
                                            showFilterOptions ? (
                                                <ul id={`pendingFilesFilterTypesList${projectId}`} className="filter-options">
                                                    <li id={`pendingFilesFilterType1-${projectId}`} className={`files-to-verify-filter-option ${!filesToVerifiLoaded[index].filter || filesToVerifiLoaded[index].filter === 'ALL' ? 'selected' : ''}`} onClick={() => this.setPendingFilesFilter('ALL', projectId)}>{this.context.t('Show all documents')}</li>
                                                    <li id={`pendingFilesFilterType2-${projectId}`} className={`files-to-verify-filter-option ${filesToVerifiLoaded[index].filter === 'TO_VERIFY' ? 'selected' : ''}`} onClick={() => this.setPendingFilesFilter('TO_VERIFY', projectId)}>{this.context.t('Show documents to verify')}</li>
                                                    <li id={`pendingFilesFilterType3-${projectId}`} className={`files-to-verify-filter-option ${filesToVerifiLoaded[index].filter === 'TO_ACCEPT' ? 'selected' : ''}`} onClick={() => this.setPendingFilesFilter('TO_ACCEPT', projectId)}>{this.context.t('Show documents to accept')}</li>
                                                    <li id={`pendingFilesFilterType4-${projectId}`} className={`files-to-verify-filter-option ${filesToVerifiLoaded[index].filter === 'TO_PAY' ? 'selected' : ''}`} onClick={() => this.setPendingFilesFilter('TO_PAY', projectId)}>{this.context.t('Show unpaid documents')}</li>
                                                </ul>
                                            ) : (
                                                null
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="dropzone-header-row" id={`pendingFilesHeaderRow${projectId}`}>
                                    <div
                                        id={`pendingFilesHeaderRow-Name${projectId}`}
                                        className="item name"
                                        onClick={() => this.props.switchSortingFilterVerifiFiles(projectId, 'name')}
                                    >
                                        {this.context.t('Name')}
                                        <span
                                            id={`pendingFilesHeaderRow-SortName${projectId}`}
                                            className={this.getSortClass('name')}
                                        ></span>
                                    </div>
                                    <div
                                        id={`pendingFilesHeaderRow-Date${projectId}`}
                                        className="item date"
                                        onClick={() => this.props.switchSortingFilterVerifiFiles(projectId, 'uploadDate')}
                                    >
                                        {this.context.t('Date')}
                                        <span
                                            id={`pendingFilesHeaderRow-SortDate${projectId}`}
                                            className={this.getSortClass('uploadDate')}
                                        ></span>
                                    </div>
                                    <div
                                        id={`pendingFilesHeaderRow-UploadedBy${projectId}`}
                                        className="item uploaded-by"
                                        onClick={() => this.props.switchSortingFilterVerifiFiles(projectId, 'userThatUploaded')}
                                    >
                                        {this.context.t('Uploaded by')}
                                        <span
                                            id={`pendingFilesHeaderRow-SortUser${projectId}`}
                                            className={this.getSortClass('userThatUploaded')}
                                        ></span>
                                    </div>
                                    <div
                                        id={`pendingFilesHeaderRow-Status${projectId}`}
                                        className="item status"
                                        onClick={() => this.props.switchSortingFilterVerifiFiles(projectId, 'status')}
                                    >
                                        {this.context.t('Status')}
                                        <span
                                            id={`pendingFilesHeaderRow-SortStatus${projectId}`}
                                            className={this.getSortClass('status')}
                                        ></span>
                                    </div>
                                </div>
                            </>
                        ) : (
                            null
                        )
                    }

                    {
                        user.new_easydocs_version ? (
                            <PendingFilesList
                                viewMode={viewMode}
                                projectCode={projectCode}
                                dropTargetId={projectId}
                                containedItems={filesToVerifiLoaded[index]}

                                simpleBarHeightWithDocs={simpleBarHeightWithDocs}
                                simpleBarHeightWithoutDocs={simpleBarHeightWithoutDocs}
                                accessLevel={accessLevel}
                                fileMatchesFilter={this.fileMatchesFilter}
                            />
                        ) : (
                            <DropFileTarget
                                viewMode={viewMode}
                                projectCode={projectCode}
                                dropTargetId={projectId}
                                containedItems={filesToVerifiLoaded[index]}
                                handleItemDrop={this.handleDrop}

                                simpleBarHeightWithDocs={simpleBarHeightWithDocs}
                                simpleBarHeightWithoutDocs={simpleBarHeightWithoutDocs}
                                accessLevel={accessLevel}
                                translation={this.context.t}
                                showInfo={this.props.alertInfo}
                                fileMatchesFilter={this.fileMatchesFilter}
                            />
                        )
                    }

                    {
                        filesToVerifiLoaded[index].files.length === 0 && localStorage.getItem('adminOnboarding') === 'MINI' && !user.finished_admin_onboarding && !acceptedFirtsDocument ? (
                            <div className="onboarding-tooltip">
                                <p>{this.context.t('A place where you can import your documents!')}</p>
                            </div>
                        ) : (
                            filesToVerifiLoaded[index].files.length > 0 && localStorage.getItem('adminOnboarding') === 'MINI' && !user.finished_admin_onboarding && !acceptedFirtsDocument ? (
                                <div className="onboarding-tooltip">
                                    <p>{this.context.t('When the document stops being processed, click \"Verify\" to check the correctness of the read data.')}</p>
                                </div>
                            ) : null
                        )
                    }
                </div>
            )
        } else {
            return (
                <div className="pending-files">
                    <SimpleBar style={{ height: simpleBarHeightWithoutDocs, width: '100%', paddingTop: '8px' }}>
                        <div className="activity-indicator">
                            <Bounce size={30} speed={0.8} color={"#9099A5"} />
                        </div>
                    </SimpleBar>
                </div>
            )
        }
    }
}


PendingFiles.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    acceptedFirtsDocument: state.User.acceptedFirtsDocument,
    viewMode: state.User.dashboardViewModel,
    filesToVerifiLoaded: JSON.parse(JSON.stringify(state.File.filesToVerifiLoaded)),
    filesToVerifySortField: state.File.filesToVerifySortField,
    filesToVerifySortOrder: state.File.filesToVerifySortOrder
})

const mapDispatchToProps = {
    getProjectResourcesForDashboard: FileActions.getProjectResourcesForDashboard,
    getFilesToVerifyRefetch: FileActions.getFilesToVerifyRefetch,
    switchSortingFilterVerifiFiles: FileActions.switchSortingFilterVerifiFiles,
    dropFileFromOtherProject: FileActions.dropFileFromOtherProject,
    setPendingFilesSearchField: FileActions.setPendingFilesSearchField,
    setPendingFilesFilter: FileActions.setPendingFilesFilter,
    alertInfo: AlertActions.info
}

export default connect(mapStateToProps, mapDispatchToProps)(PendingFiles)

