const impressProjects = [
    45,     //DEMO
    440,    //IMPRESS-GROUP
]

const impressLevel1 = [
    {
        "description": "MPK 1",
        "code": "MPK 1",
        "nextLevel": [
            {
                "description": "MPK 1.1",
                "code": "MPK 1.1",
                "nextLevel": [
                    {
                        "description": "MPK 1.1.1",
                        "code": "MPK 1.1.1",
                        "nextLevel": [
                            {
                                "description": "MPK 1.1.1.1",
                                "code": "MPK 1.1.1.1"
                            },
                            {
                                "description": "MPK 1.1.1.2",
                                "code": "MPK 1.1.1.2"
                            },
                            {
                                "description": "MPK 1.1.1.3",
                                "code": "MPK 1.1.1.3"
                            }
                        ]
                    },
                    {
                        "description": "MPK 1.1.2",
                        "code": "MPK 1.1.2",
                        "nextLevel": [
                            {
                                "description": "MPK 1.1.2.1",
                                "code": "MPK 1.1.2.1"
                            },
                            {
                                "description": "MPK 1.1.2.2",
                                "code": "MPK 1.1.2.2"
                            },
                            {
                                "description": "MPK 1.1.2.3",
                                "code": "MPK 1.1.2.3"
                            }
                        ]
                    },
                    {
                        "description": "MPK 1.1.3",
                        "code": "MPK 1.1.3",
                        "nextLevel": [
                            {
                                "description": "MPK 1.1.3.1",
                                "code": "MPK 1.1.3.1"
                            },
                            {
                                "description": "MPK 1.1.3.2",
                                "code": "MPK 1.1.3.2"
                            },
                            {
                                "description": "MPK 1.1.3.3",
                                "code": "MPK 1.1.3.3"
                            }
                        ]
                    }
                ]
            },
            {
                "description": "MPK 1.2",
                "code": "MPK 1.2",
                "nextLevel": [
                    {
                        "description": "MPK 1.2.1",
                        "code": "MPK 1.2.1",
                        "nextLevel": [
                            {
                                "description": "MPK 1.2.1.1",
                                "code": "MPK 1.2.1.1"
                            },
                            {
                                "description": "MPK 1.2.1.2",
                                "code": "MPK 1.2.1.2"
                            },
                            {
                                "description": "MPK 1.2.1.3",
                                "code": "MPK 1.2.1.3"
                            }
                        ]
                    },
                    {
                        "description": "MPK 1.2.2",
                        "code": "MPK 1.2.2",
                        "nextLevel": [
                            {
                                "description": "MPK 1.2.2.1",
                                "code": "MPK 1.2.2.1"
                            },
                            {
                                "description": "MPK 1.2.2.2",
                                "code": "MPK 1.2.2.2"
                            },
                            {
                                "description": "MPK 1.2.2.3",
                                "code": "MPK 1.2.2.3"
                            }
                        ]
                    },
                    {
                        "description": "MPK 1.2.3",
                        "code": "MPK 1.2.3",
                        "nextLevel": [
                            {
                                "description": "MPK 1.2.3.1",
                                "code": "MPK 1.2.3.1"
                            },
                            {
                                "description": "MPK 1.2.3.2",
                                "code": "MPK 1.2.3.2"
                            },
                            {
                                "description": "MPK 1.2.3.3",
                                "code": "MPK 1.2.3.3"
                            }
                        ]
                    }
                ]
            },
            {
                "description": "MPK 1.3",
                "code": "MPK 1.3",
                "nextLevel": [
                    {
                        "description": "MPK 1.3.1",
                        "code": "MPK 1.3.1",
                        "nextLevel": [
                            {
                                "description": "MPK 1.3.1.1",
                                "code": "MPK 1.3.1.1"
                            },
                            {
                                "description": "MPK 1.3.1.2",
                                "code": "MPK 1.3.1.2"
                            },
                            {
                                "description": "MPK 1.3.1.3",
                                "code": "MPK 1.3.1.3"
                            }
                        ]
                    },
                    {
                        "description": "MPK 1.3.2",
                        "code": "MPK 1.3.2",
                        "nextLevel": [
                            {
                                "description": "MPK 1.3.2.1",
                                "code": "MPK 1.3.2.1"
                            },
                            {
                                "description": "MPK 1.3.2.2",
                                "code": "MPK 1.3.2.2"
                            },
                            {
                                "description": "MPK 1.3.2.3",
                                "code": "MPK 1.3.2.3"
                            }
                        ]
                    },
                    {
                        "description": "MPK 1.3.3",
                        "code": "MPK 1.3.3",
                        "nextLevel": [
                            {
                                "description": "MPK 1.3.3.1",
                                "code": "MPK 1.3.3.1"
                            },
                            {
                                "description": "MPK 1.3.3.2",
                                "code": "MPK 1.3.3.2"
                            },
                            {
                                "description": "MPK 1.3.3.3",
                                "code": "MPK 1.3.3.3"
                            }
                        ]
                    }
                ]
            }
        ]
    }, {
        "description": "MPK 2",
        "code": "MPK 2",
        "nextLevel": [
            {
                "description": "MPK 2.1",
                "code": "MPK 2.1",
                "nextLevel": [
                    {
                        "description": "MPK 2.1.1",
                        "code": "MPK 2.1.1",
                        "nextLevel": [
                            {
                                "description": "MPK 2.1.1.1",
                                "code": "MPK 2.1.1.1"
                            },
                            {
                                "description": "MPK 2.1.1.2",
                                "code": "MPK 2.1.1.2"
                            },
                            {
                                "description": "MPK 2.1.1.3",
                                "code": "MPK 2.1.1.3"
                            }
                        ]
                    },
                    {
                        "description": "MPK 2.1.2",
                        "code": "MPK 2.1.2",
                        "nextLevel": [
                            {
                                "description": "MPK 2.1.2.1",
                                "code": "MPK 2.1.2.1"
                            },
                            {
                                "description": "MPK 2.1.2.2",
                                "code": "MPK 2.1.2.2"
                            },
                            {
                                "description": "MPK 2.1.2.3",
                                "code": "MPK 2.1.2.3"
                            }
                        ]
                    },
                    {
                        "description": "MPK 2.1.3",
                        "code": "MPK 2.1.3",
                        "nextLevel": [
                            {
                                "description": "MPK 2.1.3.1",
                                "code": "MPK 2.1.3.1"
                            },
                            {
                                "description": "MPK 2.1.3.2",
                                "code": "MPK 2.1.3.2"
                            },
                            {
                                "description": "MPK 2.1.3.3",
                                "code": "MPK 2.1.3.3"
                            }
                        ]
                    }
                ]
            },
            {
                "description": "MPK 2.2",
                "code": "MPK 2.2",
                "nextLevel": [
                    {
                        "description": "MPK 2.2.1",
                        "code": "MPK 2.2.1",
                        "nextLevel": [
                            {
                                "description": "MPK 2.2.1.1",
                                "code": "MPK 2.2.1.1"
                            },
                            {
                                "description": "MPK 2.2.1.2",
                                "code": "MPK 2.2.1.2"
                            },
                            {
                                "description": "MPK 2.2.1.3",
                                "code": "MPK 2.2.1.3"
                            }
                        ]
                    },
                    {
                        "description": "MPK 2.2.2",
                        "code": "MPK 2.2.2",
                        "nextLevel": [
                            {
                                "description": "MPK 2.2.2.1",
                                "code": "MPK 2.2.2.1"
                            },
                            {
                                "description": "MPK 2.2.2.2",
                                "code": "MPK 2.2.2.2"
                            },
                            {
                                "description": "MPK 2.2.2.3",
                                "code": "MPK 2.2.2.3"
                            }
                        ]
                    },
                    {
                        "description": "MPK 2.2.3",
                        "code": "MPK 2.2.3",
                        "nextLevel": [
                            {
                                "description": "MPK 2.2.3.1",
                                "code": "MPK 2.2.3.1"
                            },
                            {
                                "description": "MPK 2.2.3.2",
                                "code": "MPK 2.2.3.2"
                            },
                            {
                                "description": "MPK 2.2.3.3",
                                "code": "MPK 2.2.3.3"
                            }
                        ]
                    }
                ]
            },
            {
                "description": "MPK 2.3",
                "code": "MPK 2.3",
                "nextLevel": [
                    {
                        "description": "MPK 2.3.1",
                        "code": "MPK 2.3.1",
                        "nextLevel": [
                            {
                                "description": "MPK 2.3.1.1",
                                "code": "MPK 2.3.1.1"
                            },
                            {
                                "description": "MPK 2.3.1.2",
                                "code": "MPK 2.3.1.2"
                            },
                            {
                                "description": "MPK 2.3.1.3",
                                "code": "MPK 2.3.1.3"
                            }
                        ]
                    },
                    {
                        "description": "MPK 2.3.2",
                        "code": "MPK 2.3.2",
                        "nextLevel": [
                            {
                                "description": "MPK 2.3.2.1",
                                "code": "MPK 2.3.2.1"
                            },
                            {
                                "description": "MPK 2.3.2.2",
                                "code": "MPK 2.3.2.2"
                            },
                            {
                                "description": "MPK 2.3.2.3",
                                "code": "MPK 2.3.2.3"
                            }
                        ]
                    },
                    {
                        "description": "MPK 2.3.3",
                        "code": "MPK 2.3.3",
                        "nextLevel": [
                            {
                                "description": "MPK 2.3.3.1",
                                "code": "MPK 2.3.3.1"
                            },
                            {
                                "description": "MPK 2.3.3.2",
                                "code": "MPK 2.3.3.2"
                            },
                            {
                                "description": "MPK 2.3.3.3",
                                "code": "MPK 2.3.3.3"
                            }
                        ]
                    }
                ]
            }
        ]
    }, {
        "description": "MPK 3",
        "code": "MPK 3",
        "nextLevel": [
            {
                "description": "MPK 3.1",
                "code": "MPK 3.1",
                "nextLevel": [
                    {
                        "description": "MPK 3.1.1",
                        "code": "MPK 3.1.1",
                        "nextLevel": [
                            {
                                "description": "MPK 3.1.1.1",
                                "code": "MPK 3.1.1.1"
                            },
                            {
                                "description": "MPK 3.1.1.2",
                                "code": "MPK 3.1.1.2"
                            },
                            {
                                "description": "MPK 3.1.1.3",
                                "code": "MPK 3.1.1.3"
                            }
                        ]
                    },
                    {
                        "description": "MPK 3.1.2",
                        "code": "MPK 3.1.2",
                        "nextLevel": [
                            {
                                "description": "MPK 3.1.2.1",
                                "code": "MPK 3.1.2.1"
                            },
                            {
                                "description": "MPK 3.1.2.2",
                                "code": "MPK 3.1.2.2"
                            },
                            {
                                "description": "MPK 3.1.2.3",
                                "code": "MPK 3.1.2.3"
                            }
                        ]
                    },
                    {
                        "description": "MPK 3.1.3",
                        "code": "MPK 3.1.3",
                        "nextLevel": [
                            {
                                "description": "MPK 3.1.3.1",
                                "code": "MPK 3.1.3.1"
                            },
                            {
                                "description": "MPK 3.1.3.2",
                                "code": "MPK 3.1.3.2"
                            },
                            {
                                "description": "MPK 3.1.3.3",
                                "code": "MPK 3.1.3.3"
                            }
                        ]
                    }
                ]
            },
            {
                "description": "MPK 3.2",
                "code": "MPK 3.2",
                "nextLevel": [
                    {
                        "description": "MPK 3.2.1",
                        "code": "MPK 3.2.1",
                        "nextLevel": [
                            {
                                "description": "MPK 3.2.1.1",
                                "code": "MPK 3.2.1.1"
                            },
                            {
                                "description": "MPK 3.2.1.2",
                                "code": "MPK 3.2.1.2"
                            },
                            {
                                "description": "MPK 3.2.1.3",
                                "code": "MPK 3.2.1.3"
                            }
                        ]
                    },
                    {
                        "description": "MPK 3.2.2",
                        "code": "MPK 3.2.2",
                        "nextLevel": [
                            {
                                "description": "MPK 3.2.2.1",
                                "code": "MPK 3.2.2.1"
                            },
                            {
                                "description": "MPK 3.2.2.2",
                                "code": "MPK 3.2.2.2"
                            },
                            {
                                "description": "MPK 3.2.2.3",
                                "code": "MPK 3.2.2.3"
                            }
                        ]
                    },
                    {
                        "description": "MPK 3.2.3",
                        "code": "MPK 3.2.3",
                        "nextLevel": [
                            {
                                "description": "MPK 3.2.3.1",
                                "code": "MPK 3.2.3.1"
                            },
                            {
                                "description": "MPK 3.2.3.2",
                                "code": "MPK 3.2.3.2"
                            },
                            {
                                "description": "MPK 3.2.3.3",
                                "code": "MPK 3.2.3.3"
                            }
                        ]
                    }
                ]
            },
            {
                "description": "MPK 3.3",
                "code": "MPK 3.3",
                "nextLevel": [
                    {
                        "description": "MPK 3.3.1",
                        "code": "MPK 3.3.1",
                        "nextLevel": [
                            {
                                "description": "MPK 3.3.1.1",
                                "code": "MPK 3.3.1.1"
                            },
                            {
                                "description": "MPK 3.3.1.2",
                                "code": "MPK 3.3.1.2"
                            },
                            {
                                "description": "MPK 3.3.1.3",
                                "code": "MPK 3.3.1.3"
                            }
                        ]
                    },
                    {
                        "description": "MPK 3.3.2",
                        "code": "MPK 3.3.2",
                        "nextLevel": [
                            {
                                "description": "MPK 3.3.2.1",
                                "code": "MPK 3.3.2.1"
                            },
                            {
                                "description": "MPK 3.3.2.2",
                                "code": "MPK 3.3.2.2"
                            },
                            {
                                "description": "MPK 3.3.2.3",
                                "code": "MPK 3.3.2.3"
                            }
                        ]
                    },
                    {
                        "description": "MPK 3.3.3",
                        "code": "MPK 3.3.3",
                        "nextLevel": [
                            {
                                "description": "MPK 3.3.3.1",
                                "code": "MPK 3.3.3.1"
                            },
                            {
                                "description": "MPK 3.3.3.2",
                                "code": "MPK 3.3.3.2"
                            },
                            {
                                "description": "MPK 3.3.3.3",
                                "code": "MPK 3.3.3.3"
                            }
                        ]
                    }
                ]
            }
        ]
    }
]


const impressMpkSettings = {
    levelsCount: 4,
    hasDependentSublevels: true,
    level1List: impressLevel1,
    level2List: [],
    level3List: [],
    level4List: [],
}


module.exports = {
    impressProjects,
    impressMpkSettings
};