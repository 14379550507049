import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import appReducers from '../reducers/index'
import { setLanguage, setTranslations } from "redux-i18n"
import { translations } from '../translations'


var browserLang = (navigator.languages && navigator.languages[0]) || // Chrome / Firefox
    navigator.language ||   // All browsers
    navigator.userLanguage; // IE <= 10

var languages = ['pl', 'en']

var lng = localStorage.getItem('lang') === null ? (languages.includes(browserLang) ? browserLang : languages[0]) : localStorage.getItem('lang')

const rootReducer = (state, action) => appReducers(state, action);
const logger = createLogger();
var middleware = [];
if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_ENVIRONMENT !== "production") {
    middleware = [...middleware, thunk, logger];
} else {
    middleware = [...middleware, thunk];
}

export const store = createStore(
    rootReducer,
    compose(applyMiddleware(...middleware))
);

store.dispatch(setTranslations(translations))
store.dispatch(setLanguage(lng))