import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import moment from 'moment'
import ReactTooltip from 'react-tooltip'


export class CostsTabletooltips extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidUpdate(prevProps) {
        const { docsDownloadedForRaport } = this.props
        if (prevProps.docsDownloadedForRaport && prevProps.docsDownloadedForRaport.length === 0 && docsDownloadedForRaport && docsDownloadedForRaport.length > 0) {
            ReactTooltip.rebuild()
        }
    }

    formatPrice = price => {
        if (price || price === 0) {
            price = price.toFixed(2)
            if (price.indexOf('.') > -1) {
                var parts = price.split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                price = parts.join('.')
            } else {
                price = price.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }

            return price
        } else {
            return ''
        }
    }

    render() {
        const { selectFor } = this.props
        return (
            <>
                <ReactTooltip id="white-list-tooltip" place="bottom" effect="solid" className="default-tooltip info-tooltip">
                    <p>
                        {this.context.t('At the time of generating, we check the status of the VAT taxpayer on the government white list, whether NIP and bank account number are on the list')}
                    </p>
                </ReactTooltip>

                <ReactTooltip id="status-tooltip-payment-orders" place="right" effect="solid" className="default-tooltip project-info">
                    <p>
                        {this.context.t('Not applicable for payment order')}
                    </p>
                </ReactTooltip>

                <ReactTooltip id="status-tooltip-timeout" place="right" effect="solid" className="default-tooltip project-info">
                    <p>
                        {this.context.t('Data not available')}
                    </p>
                </ReactTooltip>

                <ReactTooltip id="status-tooltip-active-found" place="right" effect="solid" className="default-tooltip project-info">
                    <ul>
                        <li>
                            <span className="property">{this.context.t('Active VAT payer:')}</span>
                            <span className="value">
                                {this.context.t('Yes')}
                            </span>
                        </li>
                        <li>
                            <span className="property">{this.context.t('Account number on the list:')}</span>
                            <span className="value">
                                {this.context.t('Yes')}
                            </span>
                        </li>
                    </ul>
                </ReactTooltip>

                <ReactTooltip id="status-tooltip-active-notFound" place="right" effect="solid" className="default-tooltip project-info">
                    <ul>
                        <li>
                            <span className="property">{this.context.t('Active VAT payer:')}</span>
                            <span className="value">
                                {this.context.t('Yes')}
                            </span>
                        </li>
                        <li>
                            <span className="property">{this.context.t('Account number on the list:')}</span>
                            <span className="value">
                                {this.context.t('No')}
                            </span>
                        </li>
                    </ul>
                </ReactTooltip>

                <ReactTooltip id="status-tooltip-notActive-found" place="right" effect="solid" className="default-tooltip project-info">
                    <ul>
                        <li>
                            <span className="property">{this.context.t('Active VAT payer:')}</span>
                            <span className="value">
                                {this.context.t('No')}
                            </span>
                        </li>
                        <li>
                            <span className="property">{this.context.t('Account number on the list:')}</span>
                            <span className="value">
                                {this.context.t('Yes')}
                            </span>
                        </li>
                    </ul>
                </ReactTooltip>

                <ReactTooltip id="status-tooltip-notActive-notFound" place="right" effect="solid" className="default-tooltip project-info">
                    <ul>
                        <li>
                            <span className="property">{this.context.t('Active VAT payer:')}</span>
                            <span className="value">
                                {this.context.t('No')}
                            </span>
                        </li>
                        <li>
                            <span className="property">{this.context.t('Account number on the list:')}</span>
                            <span className="value">
                                {this.context.t('No')}
                            </span>
                        </li>
                    </ul>
                </ReactTooltip>

                <ReactTooltip id="status-tooltip-active-notProvided" place="right" effect="solid" className="default-tooltip project-info">
                    <ul>
                        <li>
                            <span className="property">{this.context.t('Active VAT payer:')}</span>
                            <span className="value">
                                {this.context.t('Yes')}
                            </span>
                        </li>
                        <li>
                            <span className="property">{this.context.t('Account number on the list:')}</span>
                            <span className="value">
                                {this.context.t('Not provided')}
                            </span>
                        </li>
                    </ul>
                </ReactTooltip>

                <ReactTooltip id="status-tooltip-notActive-notProvided" place="right" effect="solid" className="default-tooltip project-info">
                    <ul>
                        <li>
                            <span className="property">{this.context.t('Active VAT payer:')}</span>
                            <span className="value">
                                {this.context.t('No')}
                            </span>
                        </li>
                        <li>
                            <span className="property">{this.context.t('Account number on the list:')}</span>
                            <span className="value">
                                {this.context.t('Not provided')}
                            </span>
                        </li>
                    </ul>
                </ReactTooltip>

                <ReactTooltip id={`priority-tooltip`} place="right" effect="solid" className="default-tooltip">
                    <p className="payment-method">
                        {this.context.t('High priority')}
                    </p>
                </ReactTooltip>

                <ReactTooltip id={`payment-method-tooltip-TRANSFER`} place="left" effect="solid" className="default-tooltip">
                    <p className="payment-method">
                        {this.context.t('Paid - Transfer')}
                    </p>
                </ReactTooltip>

                <ReactTooltip id={`payment-method-tooltip-CARD`} place="left" effect="solid" className="default-tooltip">
                    <p className="payment-method">
                        {this.context.t('Paid - Card')}
                    </p>
                </ReactTooltip>

                <ReactTooltip id={`payment-method-tooltip-CASH`} place="left" effect="solid" className="default-tooltip">
                    <p className="payment-method">
                        {this.context.t('Paid - Cash')}
                    </p>
                </ReactTooltip>

                <ReactTooltip id={`payment-method-tooltip-OTHER`} place="left" effect="solid" className="default-tooltip">
                    <p className="payment-method">
                        {this.context.t('Paid - Other')}
                    </p>
                </ReactTooltip>

                {
                    selectFor === 'transfer' ? (
                        <>
                            <ReactTooltip id={`bank-transfer-file-no-available-1`} place="right" effect="solid" className="default-tooltip project-info bank-transfer-file-availability-info">
                                <p className="payment-method">
                                    {this.context.t('Option not available for refund')}
                                </p>
                            </ReactTooltip>
                            <ReactTooltip id={`bank-transfer-file-no-available-2`} place="right" effect="solid" className="default-tooltip project-info bank-transfer-file-availability-info">
                                <p className="payment-method">
                                    {this.context.t('Document already included in bank transfers file')}
                                </p>
                            </ReactTooltip>
                            <ReactTooltip id={`bank-transfer-file-no-available-3`} place="right" effect="solid" className="default-tooltip project-info bank-transfer-file-availability-info">
                                <p className="payment-method">
                                    {this.context.t('Document is marked as already paid')}
                                </p>
                            </ReactTooltip>
                            <ReactTooltip id={`bank-transfer-file-no-available-4`} place="right" effect="solid" className="default-tooltip project-info bank-transfer-file-availability-info">
                                <p className="payment-method">
                                    {this.context.t('Missing account number')}
                                </p>
                            </ReactTooltip>
                            <ReactTooltip id={`bank-transfer-file-no-available-5`} place="right" effect="solid" className="default-tooltip project-info bank-transfer-file-availability-info">
                                <p className="payment-method">
                                    {this.context.t('Missing SWIFT code')}
                                </p>
                            </ReactTooltip>
                            <ReactTooltip id={`bank-transfer-file-no-available-6`} place="right" effect="solid" className="default-tooltip project-info bank-transfer-file-availability-info">
                                <p className="payment-method">
                                    {this.context.t('Missing recipient country')}
                                </p>
                            </ReactTooltip>
                            <ReactTooltip id={`bank-transfer-file-no-available-7`} place="right" effect="solid" className="default-tooltip project-info bank-transfer-file-availability-info">
                                <p className="payment-method">
                                    {this.context.t('Missing recipient address')}
                                </p>
                            </ReactTooltip>
                            <ReactTooltip id={`bank-transfer-file-no-available-8`} place="right" effect="solid" className="default-tooltip project-info bank-transfer-file-availability-info">
                                <p className="payment-method">
                                    {this.context.t('Addition acceptance required')}
                                </p>
                            </ReactTooltip>
                        </>
                    ) : (
                        null
                    )
                }

                {
                    selectFor === 'zip' ? (
                        <ReactTooltip id={`zip-export-not-available`} place="right" effect="solid" className="default-tooltip project-info bank-transfer-file-availability-info">
                            <p className="payment-method">
                                {this.context.t('This file was already exported')}
                            </p>
                        </ReactTooltip>
                    ) : null
                }
            </>
        );
    }
}

CostsTabletooltips.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(CostsTabletooltips))