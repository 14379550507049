import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'

import currencyList from '../../helpers/currencyList.json'

export class FilterRow extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showFilterCurrencySelector: false,
            showAdditionalFilters: false,
            searchField: '',
            lastSearchEditTime: null
        }

        this.containerRef = React.createRef()
        this.currenciesContainerRef = React.createRef()
        this.additionalFiltersContainerRef = React.createRef()
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
    }

    handleClick = (event) => {
        if (this.containerRef && this.containerRef.current && !this.containerRef.current.contains(event.target)) {
            this.setState({
                showFilterCurrencySelector: false,
                showAdditionalFilters: false,
            })
        } else {
            if (this.currenciesContainerRef && this.currenciesContainerRef.current && !this.currenciesContainerRef.current.contains(event.target)) {
                this.setState({
                    showFilterCurrencySelector: false
                })
            } else if (this.additionalFiltersContainerRef && this.additionalFiltersContainerRef.current && !this.additionalFiltersContainerRef.current.contains(event.target)) {
                this.setState({
                    showAdditionalFilters: false
                })
            }
        }
    }

    onInputChange = e => {
        e.preventDefault()

        const { name, value } = e.target
        this.setState({
            [name]: value,
            lastSearchEditTime: new Date()
        })

        this.props.setParentState({
            [name]: value
        })
    }

    toggleFilterCurrencySelector = () => {
        this.setState({
            showFilterCurrencySelector: !this.state.showFilterCurrencySelector
        })
    }

    selectFilterCurrency = (currency) => {
        this.props.setParentState({
            searchCurrency: currency,
        })
        this.setState({
            showFilterCurrencySelector: false
        })
    }

    resetFilters = () => {
        this.props.setParentState({
            searchField: '',
            searchCurrency: 'ALL',
            selectOnlyAvailableForReport: false,
            selectOnlyAvailableForBankTransferFile: false,
            selectOnlyNotPaid: false,
            selectOnlyNotAccounted: false,
        })
    }

    getAdditionalFiltersText = () => {
        const { selectOnlyAvailableForReport, selectOnlyAvailableForBankTransferFile, selectOnlyNotPaid, selectOnlyNotAccounted } = this.props

        var text = '', additionalFiltersCount = 0
        if (selectOnlyAvailableForReport) {
            text = 'not included in collaion yet'
            additionalFiltersCount += 1
        }
        if (selectOnlyAvailableForBankTransferFile) {
            if (!text) text = 'not included in bank transfer file yet'
            additionalFiltersCount += 1
        }
        if (selectOnlyNotPaid) {
            if (!text) text = 'not paid yet'
            additionalFiltersCount += 1
        }
        if (selectOnlyNotAccounted) {
            if (!text) text = 'not accounted yet'
            additionalFiltersCount += 1
        }

        if (text) {
            text = this.context.t(text)
            if (additionalFiltersCount > 1) {
                text += `\u00A0\u00A0\u00A0\u00A0+${additionalFiltersCount - 1}`
            }
        } else {
            text = this.context.t('-- select additional filters --')
        }

        return text
    }

    render() {
        const { user, searchCurrency, selectOnlyAvailableForReport, selectOnlyAvailableForBankTransferFile, selectOnlyNotPaid, selectOnlyNotAccounted, downloadedDocsType } = this.props

        const { showFilterCurrencySelector, showAdditionalFilters, searchField } = this.state
        return (
            <div className="filter-row" ref={this.containerRef}>
                <div className="form-group">
                    <input type="text" name="searchField" value={searchField} autoComplete="off" placeholder={this.context.t('Search by counterparty, comment or tags')} onChange={(e) => this.onInputChange(e)} />
                </div>

                {
                    downloadedDocsType === 'Costs' || downloadedDocsType === 'Expenses' || downloadedDocsType === 'Income' ? (
                        <div className="filter-currency-selector" onClick={e => this.toggleFilterCurrencySelector()} ref={this.currenciesContainerRef}>
                            <div className="current" id="filterCurrencyContainer" >
                                <div className="label">{this.context.t('Documents currency')}</div>
                                {searchCurrency === 'ALL' ? this.context.t('All currencies') : searchCurrency}
                                <span className={`expend-icon ${showFilterCurrencySelector ? 'expended' : ''}`}></span>
                            </div>

                            {
                                showFilterCurrencySelector ? (
                                    <ul>
                                        <li className={`${searchCurrency === 'ALL' ? 'selected' : ''}`} onClick={e => { this.selectFilterCurrency('ALL') }}>
                                            {this.context.t('All currencies')}
                                        </li>
                                        {
                                            currencyList.map(c => {
                                                return (
                                                    <li className={`${searchCurrency === c ? 'selected' : ''}`} onClick={e => { this.selectFilterCurrency(c) }}>
                                                        {c}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                ) : (
                                    null
                                )
                            }
                        </div>
                    ) : (
                        null
                    )
                }

                {
                    downloadedDocsType === 'Costs' || downloadedDocsType === 'Expenses' ? (
                        <div className="filter-currency-selector additional-filters" ref={this.additionalFiltersContainerRef}>
                            <div className="current" onClick={e => this.setState({ showAdditionalFilters: !showAdditionalFilters })}>
                                <div className="label">{this.context.t('Show only documents')}</div>
                                {this.getAdditionalFiltersText()}
                                <span className={`expend-icon ${showAdditionalFilters ? 'expended' : ''}`}></span>
                            </div>

                            {
                                showAdditionalFilters ? (
                                    <ul>
                                        <li className={`group ${selectOnlyAvailableForReport ? 'selected' : ''}`} onClick={() => this.props.setParentState({ selectOnlyAvailableForReport: !selectOnlyAvailableForReport })}>
                                            <span className={`checkbox ${selectOnlyAvailableForReport ? 'checked' : ''}`}></span>
                                            {this.context.t('not included in collaion yet')}
                                        </li>
                                        {
                                            downloadedDocsType !== 'Expenses' ? (
                                                <li className={`group ${selectOnlyAvailableForBankTransferFile ? 'selected' : ''}`} onClick={() => this.props.setParentState({ selectOnlyAvailableForBankTransferFile: !selectOnlyAvailableForBankTransferFile })}>
                                                    <span className={`checkbox ${selectOnlyAvailableForBankTransferFile ? 'checked' : ''}`}></span>
                                                    {this.context.t('not included in bank transfer file yet')}
                                                </li>
                                            ) : (
                                                null
                                            )
                                        }
                                        {
                                            downloadedDocsType !== 'Expenses' ? (
                                                <li className={`group ${selectOnlyNotPaid ? 'selected' : ''}`} onClick={() => this.props.setParentState({ selectOnlyNotPaid: !selectOnlyNotPaid })}>
                                                    <span className={`checkbox ${selectOnlyNotPaid ? 'checked' : ''}`}></span>
                                                    {this.context.t('not paid yet')}
                                                </li>
                                            ) : (
                                                null
                                            )
                                        }
                                        {
                                            downloadedDocsType !== 'Expenses' ? (
                                                <li className={`group ${selectOnlyNotAccounted ? 'selected' : ''}`} onClick={() => this.props.setParentState({ selectOnlyNotAccounted: !selectOnlyNotAccounted })}>
                                                    <span className={`checkbox ${selectOnlyNotAccounted ? 'checked' : ''}`}></span>
                                                    {this.context.t('not accounted yet')}
                                                </li>
                                            ) : (
                                                null
                                            )
                                        }
                                    </ul>
                                ) : (
                                    null
                                )
                            }
                        </div>
                    ) : null
                }

                <div className="reset-button" onClick={() => this.resetFilters()}>
                    {this.context.t('Reset filters')}
                </div>
            </div>
        )
    }
}

FilterRow.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(FilterRow))