import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'

import CompanyBankInformationPopup from './CompanyBankInformationPopup'
import CompanyBanks from './CompanyBanks'

import { FileActions } from '../actions/FileActions'
import { CompanyActions } from '../actions/CompanyActions'
import { ReportsActions } from '../actions/ReportsActions'

import { history } from "../helpers/history"
import routes from "../helpers/routes"

import '../css/HighPriorityModal.css'
import pdfIcon from '../img/pdf-icon.svg'

export class ExpiringDueDateDocsModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
            showCompanyBankAccountPopup: false,
            showCompanyBanks: false,
            currenciesAlreadyInBankTransferFile: [],
            projectsWithAllDocsInBankTransferFile: [],
        }
    }

    componentDidMount() {
        const { docs } = this.props
        var projectsCount = docs.length, hasDocsToShow = false

        for (var i = 0; i < projectsCount; i++) {
            if (docs[i].docs && docs[i].docs.length > 0) {
                hasDocsToShow = true
                break;
            }
        }

        this.setState({
            showModal: hasDocsToShow
        })
    }

    componentDidUpdate() {
        ReactTooltip.rebuild()
    }

    handleOpenFile = (file, project) => {
        this.props.getFileData(parseInt(project.id), file.filePath, file.fileId, 'DONE', 3)
    }

    formatPrice = (amountToPay, currency) => {
        var amount = amountToPay
        if (amountToPay && currency) {
            amount = parseFloat(amountToPay)
            amount = amount.toFixed(2)
            if (amount.indexOf('.') > -1) {
                var parts = amount.split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                amount = parts.join('.')
            } else {
                amount = amount.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }
        }
        return `${amount} ${currency}`
    }

    companyHasBank = (projectId) => {
        const { userProjects, userCompanies } = this.props

        var project = userProjects.find(p => p.id === projectId)

        if (project) {
            var userCompanyRelation = userCompanies.find(c => c.company_id === project.company_id)

            if (userCompanyRelation) {
                if (userCompanyRelation.company) {
                    var company = userCompanyRelation.company
                    if (company.banks && company.banks.length > 0) {
                        this.setState({
                            companyToGenerateTransfers: company,
                            isAdminInCompanyToGenerateTransfers: userCompanyRelation.user_role === 'ADMIN'
                        })
                        return true
                    } else {
                        if (userCompanyRelation.user_role === 'ADMIN') {
                            this.setState({
                                showCompanyBankAccountPopup: true,
                                companyToGenerateTransfers: company,
                            })
                            return false
                        } else {
                            this.props.alertWarn(this.context.t('You company has not provided required bank account informations yet. Please contact your companies administrator'), 7000)
                            return false
                        }
                    }
                } else {
                    return false
                }
            } else {
                return false
            }
        } else {
            return false
        }
    }

    openBankTransfersPopup = (project, docs, currency) => {
        if (this.companyHasBank(project.id)) {
            const { currenciesAlreadyInBankTransferFile } = this.state
            var docsCurrency = currency, docsSelectedForTransfer = []
            docs.map(doc => {
                if ((docsCurrency === '' || docsCurrency === doc.currency) && !currenciesAlreadyInBankTransferFile.find(e => e.projectId === project.id && e.currency === doc.currency)) {
                    docsCurrency = doc.currency
                    docsSelectedForTransfer.push({ id: doc.id, status: 'IN_PROGRESS', fileId: doc.fileId, type: 'DOC', currency: doc.currency, price: parseFloat(doc.amountToPay) })
                }
            })
            this.setState({
                showCompanyBanks: true,
                currencySelectedForTransferBuckets: docsCurrency,
                docsSelectedForTransfer: docsSelectedForTransfer,
                projectSelecetedForTransfers: project
            })
        }
    }

    closeCompanyBanks = () => {
        this.setState({
            showCompanyBanks: false,
            addNewAccountForTransfersFile: false
        })
    }

    addBankToCompany = () => {
        this.setState({
            showCompanyBanks: false,
            showCompanyBankAccountPopup: true,
            addNewAccountForTransfersFile: true
        })
    }

    generateBankTransfers = (makeToday, bankId) => {
        const { docsSelectedForTransfer, projectSelecetedForTransfers } = this.state
        const { docs } = this.props
        if (docsSelectedForTransfer.length > 0) {
            if (docsSelectedForTransfer[0].currency === 'PLN') {
                this.props.generateBankTransfersFile(docsSelectedForTransfer, "Costs", projectSelecetedForTransfers.id, makeToday, bankId)
            } else {
                this.props.generateBankTransfetFileForInternationalTransfers(docsSelectedForTransfer, "Costs", projectSelecetedForTransfers.id, makeToday, bankId)
            }

            var { projectsWithAllDocsInBankTransferFile, currenciesAlreadyInBankTransferFile } = this.state
            currenciesAlreadyInBankTransferFile.push({ currency: docsSelectedForTransfer[0].currency, projectId: projectSelecetedForTransfers.id })

            var currenciesFromCurrentProject = currenciesAlreadyInBankTransferFile.filter(e => e.projectId === projectSelecetedForTransfers.id)

            var curentProjectGroup = docs.find(e => e.project.id === projectSelecetedForTransfers.id)
            if (curentProjectGroup) {
                if (curentProjectGroup.currencies.length === currenciesFromCurrentProject.length) projectsWithAllDocsInBankTransferFile.push(projectSelecetedForTransfers.id)
            }

            console.log(currenciesAlreadyInBankTransferFile, currenciesFromCurrentProject, projectsWithAllDocsInBankTransferFile)

            this.setState({
                showCompanyBanks: false,
                addNewAccountForTransfersFile: false,
                currencySelectedForTransferBuckets: '',
                docsSelectedForTransfer: [],
                projectSelecetedForTransfers: null,
                currenciesAlreadyInBankTransferFile: currenciesAlreadyInBankTransferFile,
                projectsWithAllDocsInBankTransferFile: projectsWithAllDocsInBankTransferFile
            })
        }
    }

    closeCompanyAccountPopup = () => {
        this.setState({
            showCompanyBanks: true,
            showCompanyBankAccountPopup: false,
        })
    }

    addedNewAccount = () => {
        this.setState({
            showCompanyBanks: true,
            showCompanyBankAccountPopup: false
        })
    }

    openInReports = (project, docs) => {

        var oldestDocDate = null, docsIds = []
        docs.forEach(d => {
            if (!oldestDocDate || d.importDate < oldestDocDate) oldestDocDate = d.importDate

            docsIds.push(d.id)
        })

        if (oldestDocDate) {
            var startDate = moment(oldestDocDate), endDate = moment().endOf('month').set('hour', 23).set('minute', 59).set('second', 59)
            this.props.getDocuments(startDate, endDate, project.id, 'Costs', 'importDate', null, { docsIds: docsIds })
            history.push(routes.panel.actions + `?p=${project.id}&d=${moment(startDate).format('MM-DD-YYYY')}&t=u`)

            this.props.onClose()
        }
    }

    render() {
        const { docs } = this.props
        const { showModal, showCompanyBankAccountPopup, showCompanyBanks, currenciesAlreadyInBankTransferFile, projectsWithAllDocsInBankTransferFile } = this.state

        if (showModal) {
            return (
                <div className="high-priority__container">
                    {
                        showCompanyBankAccountPopup ? (
                            <CompanyBankInformationPopup
                                company={this.state.companyToGenerateTransfers}
                                close={this.closeCompanyAccountPopup}
                                closeAndGenerateFile={this.closeCompanyAccountPopupAndGenrateFile}
                                currency={this.state.currencySelectedForTransferBuckets}
                                addNewAccount={this.state.addNewAccountForTransfersFile}
                                addedNewAccount={this.addedNewAccount}
                            />
                        ) : (
                            null
                        )
                    }

                    {
                        showCompanyBanks ? (
                            <CompanyBanks
                                company={this.state.companyToGenerateTransfers}
                                isAdmin={this.state.isAdminInCompanyToGenerateTransfers}
                                close={this.closeCompanyBanks}
                                addBank={this.addBankToCompany}
                                generateFile={this.generateBankTransfers}
                                currency={this.state.currencySelectedForTransferBuckets}
                            />
                        ) : (
                            null
                        )
                    }

                    <div className="high-priority">
                        <div className="high-priority__content">
                            <div className="high-priority__header">
                                <h4 className="high-priority__title">{this.context.t("Documents to be paid in the near future")}</h4>
                            </div>
                            <div className="high-priority__wrapper">
                                {
                                    docs.map(group => {
                                        if (group.docs && group.docs.length > 0) {
                                            return (
                                                <div className="high-priority__project" key={group.project.id}>
                                                    <div className="high-priority__project-title">
                                                        {group.project.code}

                                                        <div className="action-buttons">
                                                            {
                                                                !projectsWithAllDocsInBankTransferFile.includes(group.project.id) && group.currencies.length <= 1 ? (
                                                                    <>
                                                                        <div className="generate-bank-transfers" onClick={() => this.openBankTransfersPopup(group.project, group.docs, '')} data-for={`generate-bank-transfers-${group.project.id}`} data-tip='show'></div>

                                                                        <ReactTooltip id={`generate-bank-transfers-${group.project.id}`} place="bottom" effect="solid" className="default-tooltip" delayShow={100}>
                                                                            <p>{this.context.t('Generate file to import bank transfers')}</p>
                                                                        </ReactTooltip>
                                                                    </>
                                                                ) : (
                                                                    null
                                                                )
                                                            }

                                                            <div className="see-in-reports" onClick={() => this.openInReports(group.project, group.docs)} data-for={`see-in-reports-${group.project.id}`} data-tip='show'></div>

                                                            <ReactTooltip id={`see-in-reports-${group.project.id}`} place="bottom" effect="solid" className="default-tooltip" delayShow={100}>
                                                                <p>{this.context.t('View these documents in Reports')}</p>
                                                            </ReactTooltip>
                                                        </div>
                                                    </div>
                                                    <div className="high-priority__files">
                                                        {
                                                            group.currencies.length > 1 ? (
                                                                group.currencies.map(currency => (
                                                                    <>
                                                                        <div className="high-priority__currency-title">
                                                                            {currency}
                                                                            {
                                                                                !currenciesAlreadyInBankTransferFile.find(e => e.currency === currency && e.projectId === group.project.id) ? (
                                                                                    <>
                                                                                        <div className="generate-bank-transfers" onClick={() => this.openBankTransfersPopup(group.project, group.docs, currency)} data-for={`generate-bank-transfers-${group.project.id}-${currency}`} data-tip='show'></div>

                                                                                        <ReactTooltip id={`generate-bank-transfers-${group.project.id}-${currency}`} place="bottom" effect="solid" className="default-tooltip" delayShow={100}>
                                                                                            <p>{this.context.t('Generate file to import bank transfers in {n}', { n: currency })}</p>
                                                                                        </ReactTooltip>
                                                                                    </>
                                                                                ) : (
                                                                                    null
                                                                                )
                                                                            }
                                                                        </div>

                                                                        {
                                                                            group.docs.map(doc => (
                                                                                doc.currency === currency ? (
                                                                                    <div onClick={() => this.handleOpenFile(doc, group.project)} key={`not-in-bank-transfers-${doc.id}`} className="high-priority__file">
                                                                                        <img src={pdfIcon} alt="" />
                                                                                        <span className="file-name">
                                                                                            {doc.fileName}
                                                                                            <span className="company">{doc.counterparty.name}</span>
                                                                                        </span>
                                                                                        {
                                                                                            doc.dueDate ? (
                                                                                                <div className="doc-due-date">
                                                                                                    {`${this.context.t('Due date:')} ${moment(doc.dueDate).format('YYYY-MM-DD')}`}
                                                                                                    <span className="price">{this.formatPrice(doc.amountToPay, doc.currency)}</span>
                                                                                                </div>
                                                                                            ) : (
                                                                                                null
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                ) : (
                                                                                    null
                                                                                )
                                                                            ))
                                                                        }
                                                                    </>
                                                                ))
                                                            ) : (
                                                                group.docs.map(doc => (
                                                                    <div onClick={() => this.handleOpenFile(doc, group.project)} key={`not-in-bank-transfers-${doc.id}`} className="high-priority__file">
                                                                        <img src={pdfIcon} alt="" />
                                                                        <span className="file-name">
                                                                            {doc.fileName}
                                                                            <span className="company">{doc.counterparty.name}</span>
                                                                        </span>
                                                                        {
                                                                            doc.dueDate ? (
                                                                                <div className="doc-due-date">
                                                                                    {`${this.context.t('Due date:')} ${moment(doc.dueDate).format('YYYY-MM-DD')}`}
                                                                                    <span className="price">{this.formatPrice(doc.amountToPay, doc.currency)}</span>
                                                                                </div>
                                                                            ) : (
                                                                                null
                                                                            )
                                                                        }
                                                                    </div>
                                                                ))
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return null
                                        }
                                    })
                                }
                            </div>
                            <button onClick={() => this.props.onClose()} className="high-priority__button">{this.context.t('Close')}</button>
                        </div>
                    </div>
                </div >
            )
        } else {
            return null
        }
    }
}

ExpiringDueDateDocsModal.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    userProjects: state.User.userProjects,
    userCompanies: state.Company.companies,
})

const mapDispatchToProps = {
    getFileData: FileActions.getFileData,
    getDocuments: ReportsActions.getDocuments,
    generateBankTransfersFile: ReportsActions.generateBankTransfersFile,
    generateBankTransfetFileForInternationalTransfers: ReportsActions.generateBankTransfetFileForInternationalTransfers,
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpiringDueDateDocsModal)