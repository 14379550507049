export default function arrayObjectIndexOf(myArray, searchTerm, property, secondSearch, secondProperty) {
    if(myArray) {
        const length = myArray.length

        for(var i = 0; i < length; i++) {
            if(secondSearch && secondProperty) {
                if (myArray[i][property] === searchTerm && myArray[i][secondProperty] === secondSearch) {
                    return i;
                }
            } else if (myArray[i] && myArray[i][property] && myArray[i][property] === searchTerm) {
                return i;
            }
        }
        return -1;
    } else {
        return -1
    }
}

