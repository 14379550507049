const moment = require('moment')
const datePattern = new RegExp("[0-9]{2}-[0-9]{2}-[0-9]{4}", "i");
const datePatternOneDayDigit = new RegExp("[0-9]{1}-[0-9]{2}-[0-9]{4}", "i");
const datePatternOneMonthDigit = new RegExp("[0-9]{2}-[0-9]{1}-[0-9]{4}", "i");
const datePatternReverse = new RegExp("[0-9]{4}-[0-9]{2}-[0-9]{2}", "i");

const months = [
    'january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'
    ];

const miesiace = [
    'styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec', 'sierpień', 'wrzesień', 'październik', 'listopad', 'grudzień'
]

const miesiaceOdmienione = [
    'stycznia', 'lutego', 'marca', 'kwietnia', 'maja', 'czerca', 'lipca', 'sierpnia', 'września', 'października', 'listopada', 'grudnia'
]

export default  function parseDate(date) {
    if(date) {
        date = date.replace(/\n\s*\n/g, '\n').replace(/[^a-zA-Z0-9-.,]/gmi, '').replace(/\./gmi, '-').replace(/\,/gmi, '-').replace(/\s/gmi, '-')
        date = date.trim();
        var matches = date.match(datePattern)
        if(matches) {
            var monthNumber = date.substring(3, 5);
            monthNumber = parseInt(monthNumber)
            if(monthNumber <= 12) {
                console.log("match for date: " + date)
                console.log("output: " + moment(date, "DD-MM-YYYY").format("dddd MMMM YYYY"))
                return moment(date, "DD-MM-YYYY").format("DD-MM-YYYY")
            } else {
                console.log("match for date: " + date)
                console.log("output: " + moment(date, "MM-DD-YYYY").format("dddd MMMM YYYY"))
                return moment(date, "MM-DD-YYYY").format("DD-MM-YYYY")
            }
        } else if(date.match(datePatternReverse)) {
            var monthNumber = date.substring(5, 7);
            monthNumber = parseInt(monthNumber)
            if(monthNumber <= 12) {
                console.log("match for date: " + date)
                console.log("output: " + moment(date, "YYYY-MM-DD").format("dddd MMMM YYYY"))
                return moment(date, "YYYY-MM-DD").format("DD-MM-YYYY")
            } else {
                console.log("match for date: " + date)
                console.log("output: " + moment(date, "YYYY-DD-MM").format("dddd MMMM YYYY"))
                return moment(date, "YYYY-DD-MM").format("DD-MM-YYYY")
            }
        } else {
            if(date.match(datePatternOneDayDigit)) {
                console.log("changed: " + date)
                date = "0" + date
                console.log("to: " + date)
                console.log("output: " + moment(date, "DD-MM-YYYY").format("dddd MMMM YYYY"))
                return moment(date, "DD-MM-YYYY").format("DD-MM-YYYY")
            } else if(date.match(datePatternOneMonthDigit)) {
                console.log("changed: " + date)
                date = date.substring(0, 3) + "0" + date.substring(3, date.length);
                console.log("to: " + date)
                console.log("output: " + moment(date, "DD-MM-YYYY").format("dddd MMMM YYYY"))
                return moment(date, "DD-MM-YYYY").format("DD-MM-YYYY")
            } else {
                console.log("before replace month name: " + date)
                for(var j = 0; j < 12; j++) {
                    var replaceTo = "-" + (j + 1).toString() + "-";
                    if(date.includes(months[j])) {
                        console.log("includes english month name")
                        var replaceWhat = new RegExp(months[j], 'g')
                        date = date.replace(replaceWhat, replaceTo)
                    } else if(date.includes(miesiaceOdmienione[j])) {
                        console.log("includes polish month name 2")
                        var replaceWhat = new RegExp(miesiaceOdmienione[j], 'g')
                        date = date.replace(replaceWhat, replaceTo)
                    } else if(date.includes(miesiace[j])) {
                        console.log("includes polish month name")
                        var replaceWhat = new RegExp(miesiace[j], 'g')
                        date = date.replace(replaceWhat, replaceTo)
                    } 
                }

                console.log("after replace month name: " + date)
                if(date.match(datePatternOneDayDigit)) {
                    console.log("changed: " + date)
                    date = "0" + date
                    console.log("to: " + date)
                    console.log("output: " + moment(date, "DD-MM-YYYY").format("dddd MMMM YYYY"))
                    return moment(date, "DD-MM-YYYY").format("DD-MM-YYYY")
                } else if(date.match(datePatternOneMonthDigit)) {
                    console.log("changed: " + date)
                    date = date.substring(0, 3) + "0" + date.substring(3, date.length);
                    console.log("to: " + date)
                    console.log("output: " + moment(date, "DD-MM-YYYY").format("dddd MMMM YYYY"))
                    return moment(date, "DD-MM-YYYY").format("DD-MM-YYYY")
                } else if(date.match(datePattern)) {
                    console.log("output: " + moment(date, "DD-MM-YYYY").format("dddd MMMM YYYY"))
                    return moment(date, "DD-MM-YYYY").format("DD-MM-YYYY")
                } else {
                    console.log("DATE NOT CHANGED " + date)
                    return date
                }
            }   
        }
    } else {
        return date
    }
   
}