import React, { Component } from 'react'
import moment from 'moment'
import 'moment-timezone'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

export class TimePicker extends Component {
    constructor(props) {
        super(props)
        var startTime = moment()
        var startHour = startTime.hour()
        var startMinute = startTime.minute()
        var minute = startTime.minute() % 5
        startMinute += (5 - minute)

        if(startMinute === 60) {
            startHour += 1
            startMinute = 0
        }

		this.state = {
            hour: startHour,
            minutes: startMinute
        }
    }

    componentDidMount() {

        this.setState({
            hour: this.props.hour,
            minutes: this.props.minutes
        })

        if(this.refs.hourContainer) {
            this.refs.hourContainer.addEventListener('scroll', this.fireOnScroll , true);
        }
    }

    componentWillUnmount() {
        this.props.sendHour(this.displayHour(this.state.hour, 0), this.displayMinutes(this.state.minutes, 0))
    }

    fireOnScroll = e => {
        console.log('SCROLLLL')
    }

    handleScrollHours = e => {
        var {hour} = this.state
        if(e.nativeEvent.wheelDelta < 0) {
            if(parseInt(hour) < 23) {
                this.setState({ hour: parseInt(hour) + 1 });
            } else {
                this.setState({ hour: 0})
            }
        } else {
            if(parseInt(hour) > 0) {
                this.setState({ hour: parseInt(hour) - 1 });
            } else {
                this.setState({ hour: 23})
            }
        }
        
    }

    handleScrollMinutes = e => {
        var {minutes} = this.state
        if(e.nativeEvent.wheelDelta < 0) {
            if(parseInt(minutes) < 55) {
                this.setState({ minutes: parseInt(minutes) + 5 });
            } else {
                this.setState({ minutes: 0})
            }
        } else {
            if(parseInt(minutes) > 0) {
                this.setState({ minutes: parseInt(minutes) - 5 });
            } else {
                this.setState({ minutes: 55})
            }
        }
    }

    displayHour = (hour, value) => {
        var newHour = parseInt(hour) + parseInt(value)

        if(newHour > 23) newHour -= 23
        else if(newHour < 0) newHour += 24

        if(newHour < 10) return "0" + newHour
        return newHour
    }

    displayMinutes = (minute, value) => {
        var newMinute = parseInt(minute) + parseInt(value)

        if(newMinute > 55) newMinute -= 55
        else if(newMinute < 0) newMinute += 60

        if(newMinute < 10) return "0" + newMinute
        return newMinute
    }

    setHour = (hour, value) => {
        var newHour = parseInt(hour) + parseInt(value)

        if(newHour > 23) newHour -= 23
        else if(newHour < 0) newHour += 24

        this.setState({
            hour: newHour
        })
    }

    setMinute = (minute, value) => {
        var newMinute = parseInt(minute) + parseInt(value)

        if(newMinute > 55) newMinute -= 55
        else if(newMinute < 0) newMinute += 60

        this.setState({
            minutes: newMinute
        })
    }

    

    render() {
        var {hour, minutes} = this.state
        return (
            <div className="time-picker-container">
                <div className="text">{this.context.t('Start time')}</div>
                <div className="hours" onWheel={e => this.handleScrollHours(e)}>
                    <div className="prev" onClick={() => this.setHour(hour, -2)}>{this.displayHour(hour, -2)}</div>
                    <div className="prev" onClick={() => this.setHour(hour, -1)}>{this.displayHour(hour, -1)}</div>
                    <div className="current">{this.displayHour(hour, 0)}</div>
                    <div className="next" onClick={() => this.setHour(hour, 1)}>{this.displayHour(hour, 1)}</div>
                    <div className="next" onClick={() => this.setHour(hour, 2)}>{this.displayHour(hour, 2)}</div>
                </div>
                <div className="dots">{':'}</div>
                <div className="minutes" onWheel={e => this.handleScrollMinutes(e)}>
                    <div className="prev" onClick={() => this.setMinute(minutes, -10)}>{this.displayMinutes(minutes, -10)}</div>
                    <div className="prev" onClick={() => this.setMinute(minutes, -5)}>{this.displayMinutes(minutes, -5)}</div>
                    <div className="current">{this.displayMinutes(minutes, 0)}</div>
                    <div className="next" onClick={() => this.setMinute(minutes, 5)}>{this.displayMinutes(minutes, 5)}</div>
                    <div className="next" onClick={() => this.setMinute(minutes, 10)}>{this.displayMinutes(minutes, 10)}</div>
                </div>
            </div>
        )
    }
}

TimePicker.contextTypes = {
	t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(TimePicker)