import React, { Component } from 'react'
import { connect } from 'react-redux'
import $ from 'jquery'
import { UserActions } from '../actions/UserActions'
import { AlertActions } from '../actions/AlertActions'
import { Link, Redirect } from 'react-router-dom'
import routes from '../helpers/routes'
import { emailRegex } from '../helpers/regexList'
import PropTypes from 'prop-types'
import ReCAPTCHA from "react-google-recaptcha";
import LanguageSwitcher from '../components/LanguageSwitcher'
import ReactTooltip from 'react-tooltip'

import CryptoJS from 'crypto-js'

import Logo from '../img/easy-docs-logo-big.svg'


export class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            firstName: '',
            lastName: '',
            password: '',
            password2: '',
            passwordVisible: false,
            password2Visible: false,
            termsCheckbox: false,
            token: '',
            captcha: false,
            captchaNotCheckedError: false,
            captchaExpiredError: false,
        }
    }


    componentDidMount() {
        $("input").focusout(function () {
            if ($(this).val() !== "") {
                $(this).addClass("has-content");
            } else {
                $(this).removeClass("has-content");
            }
        })
        console.log(this.props)

        var params = this.props.location.search
        params = params.substr(1)

        var paramsArray = params.split("&")
        var email = '', token = '', vatId = "", companyName = "", object = ""

        paramsArray.forEach(param => {
            var individualParam = param.split("=")
            if (individualParam[0] === "email") {
                email = decodeURIComponent(individualParam[1])
            } else if (individualParam[0] === 'token') {
                token = decodeURIComponent(individualParam[1])
            } else if (individualParam[0] === 'vatId') {
                vatId = decodeURIComponent(individualParam[1])
            } else if (individualParam[0] === 'companyName') {
                companyName = decodeURIComponent(individualParam[1])
            } else if (individualParam[0] === 'companyName') {
                companyName = decodeURIComponent(individualParam[1])
            } else if (individualParam[0] === 'activationToken') {
                object = decodeURIComponent(individualParam[1])
            }
        })

        var bytes = CryptoJS.AES.decrypt(object, 'secret key 123');
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        this.setState({
            email: decryptedData.email,
            token: decryptedData.token,
            vatId: decryptedData.vatId,
            companyName: decryptedData.companyName,
            termsCheckbox: decryptedData.vatId ? true : false,
            privacyPolicyCheckbox: decryptedData.vatId ? true : false,
            dataProcessingCheckbox: decryptedData.vatId ? true : false,
        })

    }

    onInputChange = e => {
        e.preventDefault()

        const { name, value } = e.target

        this.setState({ [name]: value })
    }

    captchaChange = token => {
        console.log('captcha token: ' + token)
        if (token) {
            this.setState({
                captcha: true,
                captchaNotCheckedError: false,
            })
        } else {
            this.setState({
                captcha: false,
            })
        }
    }

    captchaExpire = () => {
        console.log('captcha expired')
        this.setState({
            captcha: false,
            captchaExpiredError: true
        })
    }

    hasLowerCase = (str) => {
        return (/[a-z]/.test(str));
    }

    hasUpperCase = (str) => {
        return (/[A-Z]/.test(str));
    }

    hasDigit = (str) => {
        return (/[0-9]/.test(str));
    }

    registerSubmit = e => {
        e.preventDefault()

        var valid = true

        const { email, firstName, lastName, password, password2, token, termsCheckbox, privacyPolicyCheckbox, dataProcessingCheckbox, captcha, companyName} = this.state

        if (!captcha) {
            valid = false
            this.setState({
                captchaNotCheckedError: true
            })
        }

        if (!termsCheckbox) {
            this.props.alertWarn(this.context.t('You have to accept Terms of Service'))
        } else if (!privacyPolicyCheckbox) {
            this.props.alertWarn(this.context.t('You have to accept Privacy Policy'))
        } else if (!dataProcessingCheckbox) {
            this.props.alertWarn("You have to allow processing of your personal data")
        } else {
            if (email.length === 0) {
                valid = false
                this.props.alertWarn(this.context.t('E-mail address is required'))
            }
            else if (!emailRegex.test(email)) {
                valid = false
                this.props.alertWarn(this.context.t('E-mail address is not valid'))
            }
            if (firstName.length === 0) {
                valid = false
                this.props.alertWarn(this.context.t('First name is required'))
            }
            if (lastName.length === 0) {
                valid = false
                this.props.alertWarn(this.context.t('Last name is required'))
            }

            if (password.length === 0 && password2.length === 0) {
                valid = false
                this.props.alertWarn(this.context.t('Password is required'))
            }
            else if (password.length < 8 && password2.length < 8) {
                valid = false
                this.props.alertWarn(this.context.t('Passwords must be at least 8 characters long'))
            }
            else if (!this.hasLowerCase(password) || !this.hasUpperCase(password) || !this.hasDigit(password)) {
                valid = false
                this.props.alertWarn(this.context.t('Passwords must contain at least one lowercase and one uppercase character and one digit'))
            }
            else if (password !== password2) {
                valid = false
                this.props.alertWarn(this.context.t('Given passwords don\'t match'))
            }

            if (valid) {
                var newUser = {
                    "email": email.toLowerCase(),
                    "firstName": firstName,
                    "lastName": lastName,
                    "password": password,
                    "is_active": false,
                    "email_verified": false,
                }
                this.props.register(newUser, token, companyName ? true : false, () => { })
            }
        }
    }

    changeVisibility = name => {
        if (name === 'p1') {
            if (this.state.passwordVisible === false) {
                this.refs.password1.type = 'text'
                this.setState({
                    passwordVisible: true
                })
            } else {
                this.refs.password1.type = 'password'
                this.setState({
                    passwordVisible: false
                })
            }
        } else {
            if (this.state.password2Visible === false) {
                this.refs.password2.type = 'text'
                this.setState({
                    password2Visible: true
                })
            } else {
                this.refs.password2.type = 'password'
                this.setState({
                    password2Visible: false
                })
            }
        }
    }

    agreeToTerms = () => {
        var { termsCheckbox } = this.state
        this.setState({
            termsCheckbox: !termsCheckbox
        })
    }

    acceptPrivacyPolicy = () => {
        var { privacyPolicyCheckbox } = this.state
        this.setState({
            privacyPolicyCheckbox: !privacyPolicyCheckbox
        })
    }

    agreeToDataProcessing = () => {
        var { dataProcessingCheckbox } = this.state
        this.setState({
            dataProcessingCheckbox: !dataProcessingCheckbox
        })
    }

    render() {
        const { email, firstName, lastName, password, password2, passwordVisible, password2Visible, termsCheckbox, privacyPolicyCheckbox, dataProcessingCheckbox, captchaNotCheckedError, captchaExpiredError, vatId, companyName } = this.state

        const { user } = this.props

        if (user !== null) {
            this.props.alertWarn(this.context.t('You can\'t register when you are logged in'))
            return <Redirect to={routes.panel.home} />
        }

        return (
            <div className="register-container">
                <LanguageSwitcher />
                <div className="header">
                    <div className="logo">
                        <a href={'https://easydocs.pl/'} />
                        <img src={Logo} alt="EasyDocs Logo" />
                    </div>
                    {
                        this.props.history && this.props.history.action !== 'POP' ? (
                            <div className="go-back" onClick={() => this.props.history.goBack()}>{this.context.t('Go back')}</div>
                        ) : (
                            null
                        )
                    }
                </div>
                <div className="form-col">
                    <form onSubmit={this.registerSubmit}>
                        <h1>{this.context.t('Complete the registration')}</h1>
                        {
                            vatId ? (
                                <div className="form-group">
                                    <input type="text" name="vatId" id="vatId" value={vatId} placeholder="" className="read-only" readOnly={true} />
                                    <label htmlFor="vatId" className="not-required">{this.context.t('Company VAT-ID')}</label>
                                </div>
                            ) : (
                                null
                            )
                        }
                        {
                            companyName ? (
                                <div className="form-group">
                                    <input type="text" name="companyName" id="companyName" value={companyName} placeholder="" className="read-only" readOnly={true} />
                                    <label htmlFor="companyName" className="not-required">{this.context.t('Company Name')}</label>
                                </div>
                            ) : (
                                null
                            )
                        }
                        <div className="form-group">
                            <input type="text" name="firstName" id="firstName" value={firstName} onChange={this.onInputChange} placeholder="" />
                            <label htmlFor="firstName">{this.context.t('First name')}</label>
                        </div>

                        <div className="form-group">
                            <input type="text" name="lastName" id="lastName" value={lastName} onChange={this.onInputChange} placeholder="" />
                            <label htmlFor="lastName">{this.context.t('Last name')}</label>
                        </div>

                        <div className="form-group">
                            <input type="email" name="email" id="email" value={email} onChange={this.onInputChange} placeholder="" className="read-only" readOnly={true} />
                            <label htmlFor="email">{this.context.t('E-mail address')}</label>
                        </div>

                        <div className="form-group">
                            <input type="password" name="password" id="password" value={password} onChange={this.onInputChange} placeholder="" ref="password1"></input>
                            <label htmlFor="password">{this.context.t('Password')}</label>
                            <span className="info" data-tip="show" data-for='password1-tooltip'></span>
                            <ReactTooltip id='password1-tooltip' place="top" effect="solid" className="default-tooltip password">
                                <p>{this.context.t("Passwords must be at least 12 character long and contain at least one lowercase, one uppercase character and one digit")}</p>
                            </ReactTooltip>
                            <span className={`make-visible ${passwordVisible ? 'view' : 'hide'}`} onClick={() => this.changeVisibility('p1')}></span>
                        </div>

                        <div className="form-group">
                            <input type="password" name="password2" id="password2" value={password2} onChange={this.onInputChange} placeholder="" ref="password2" />
                            <label htmlFor="password2">{this.context.t('Confirm password')}</label>
                            <span className={`make-visible ${password2Visible ? 'view' : 'hide'}`} onClick={() => this.changeVisibility('p2')}></span>
                        </div>

                        <div className="recaptcha-container">
                            <ReCAPTCHA
                                ref="loginReCaptchaRef"
                                size="normal"
                                sitekey="6LceWAEVAAAAAK6LgYLnLuisqGWH9U-XEm4ACBdd"
                                onChange={token => this.captchaChange(token)}
                                onExpired={() => this.captchaExpire()}
                            />
                            {
                                captchaNotCheckedError ? (
                                    <div className={`captcha-error-container ${captchaExpiredError ? 'expired' : ''}`}>
                                        {this.context.t('Please confirm you are not a robot')}
                                    </div>
                                ) : (
                                    null
                                )
                            }
                        </div>

                        {
                            !vatId ? (
                                <div className="agree-to-terms">
                                    <span className={`checkbox ${termsCheckbox ? 'checked' : ''}`} onClick={() => this.agreeToTerms()}></span>
                                    <p onClick={() => this.agreeToTerms()}>
                                        {this.context.t('I accept the ')}<a href="https://easydocs.pl/regulamin" target="_blank">{this.context.t('Terms of Service')}</a>
                                    </p>
                                </div>
                            ) : (
                                null
                            )
                        }

                        {
                            !vatId ? (
                                <div className="agree-to-terms">
                                    <span className={`checkbox ${privacyPolicyCheckbox ? 'checked' : ''}`} onClick={() => this.acceptPrivacyPolicy()}></span>
                                    <p onClick={() => this.agreeToTerms()}>
                                        {this.context.t('I accept the ')}<a href="https://easydocs.pl/polityka-prywatnosci" target="_blank">{this.context.t('Privacy Policy')}</a>
                                    </p>
                                </div>
                            ) : (
                                null
                            )
                        }

                        {
                            !vatId ? (
                                <div className="agree-to-terms">
                                    <span className={`checkbox ${dataProcessingCheckbox ? 'checked' : ''}`} onClick={() => this.agreeToDataProcessing()}></span>
                                    <p onClick={() => this.agreeToDataProcessing()}>
                                        {this.context.t('I consent to processing of my personal data in order to provide EasyDocs service')}
                                    </p>
                                </div>
                            ) : (
                                null
                            )
                        }

                        <div className="required-fields">
                            {this.context.t('* required field')}
                        </div>

                        <input type="submit" className="button" value={this.context.t('Save')} />
                    </form>
                </div>
                <div className="img-col">
                    <div className="text">
                        {this.context.t('Easy and convenient way to manage documents')}
                    </div>
                </div>
            </div>
        )
    }
}

Home.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user
})

const mapDispatchToProps = {
    register: UserActions.register,
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)

