import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { Digital, Spinner } from 'react-activity'
import Dropzone from 'react-dropzone'
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css'
import throttle from "lodash.throttle"
import generator from 'generate-password'
import copy from 'copy-to-clipboard';

import { UserActions } from '../../actions/UserActions';
import { AlertActions } from '../../actions/AlertActions'

import currencyList from '../../helpers/currencyList.json'

import OverlayInstructions from '../../components/OverlayInstructions'
import SettingsTabsBar from '../../components/SettingsTabsBar'

import '../../css/SettingsPage.css'

import easyGuardHelp from '../../img/easy-guard-help.gif'

const imageMaxSize = 5000000 // bytes
const acceptedFileTypes = 'image/png, image/jpg, image/jpeg'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() })

export class Settings extends Component {

    constructor(props) {
        super(props)
        this.state = {
            userFirstName: '',
            userLastName: '',
            oldPassword: '',
            newPassword: '',
            oldPasswordVisible: false,
            newPasswordVisible: false,
            photo: {},
            showPhotoCropPopup: false,
            changingProfilePicInProgress: false,
            showWithdrawConsentPopup: false,

            gotOverlayPossitions: false,
            overlayPossitions: [],

            easyGuardCode: '',

            banks: []
        }
    }

    componentDidMount() {
        window.addEventListener("keydown", this.escFunction, false)
        window.addEventListener("resize", throttle(this.setOverlaySize, 100))

        const { userProjects, user } = this.props
        if (user) {
            this.setState({
                banks: [...user.banks] || [],
                userFirstName: user.first_name,
                userLastName: user.last_name
            })
        }

        if (!userProjects) {
            this.props.getUserProjects()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.user.profile_pic_link !== this.props.user.profile_pic_link) {
            if (this.props.user.profile_pic_link) {
                this.setState({
                    showPhotoCropPopup: false,
                    changingProfilePicInProgress: false,
                })
            } else {
                this.setState({
                    showDeleteProfilePicPopup: false,
                    deleteProfilePicInProgress: false,
                })
            }

        }

        if (prevProps.user.banks !== this.props.user.banks) {
            if (this.props.user.banks) {
                this.setState({
                    banks: [...this.props.user.banks]
                })
            }

        }

        if ((!this.state.gotOverlayPossitions) || this.props.highestAcceptancePermission !== prevProps.highestAcceptancePermission) {
            const { profileRef, notificationsRef, languageRef, accountNumbersRef, ibmRef, termsRef } = this.refs
            const scrollBarWidth = window.innerWidth - document.body.offsetWidth
            if (profileRef && notificationsRef && languageRef && accountNumbersRef && termsRef) {
                var holesToDraw = []

                var profileBox = profileRef.getBoundingClientRect()
                if (profileBox) {
                    holesToDraw.push({
                        x: profileBox.x,
                        y: profileBox.y + window.scrollY,
                        w: profileBox.width + scrollBarWidth,
                        h: profileBox.height
                    })
                }

                var notificationsBox = notificationsRef.getBoundingClientRect()
                if (notificationsBox) {
                    holesToDraw.push({
                        x: notificationsBox.x,
                        y: notificationsBox.y + window.scrollY,
                        w: notificationsBox.width + scrollBarWidth,
                        h: notificationsBox.height
                    })
                }

                var languageBox = languageRef.getBoundingClientRect()
                if (languageBox) {
                    holesToDraw.push({
                        x: languageBox.x,
                        y: languageBox.y + window.scrollY,
                        w: languageBox.width + scrollBarWidth,
                        h: languageBox.height
                    })
                }

                var accountNumbersBox = accountNumbersRef.getBoundingClientRect()
                if (accountNumbersBox) {
                    holesToDraw.push({
                        x: accountNumbersBox.x,
                        y: accountNumbersBox.y + window.scrollY,
                        w: accountNumbersBox.width + scrollBarWidth,
                        h: accountNumbersBox.height
                    })
                }

                if (ibmRef) {
                    var ibmBox = ibmRef.getBoundingClientRect()
                    if (ibmBox) {
                        holesToDraw.push({
                            x: ibmBox.x,
                            y: ibmBox.y + window.scrollY,
                            w: ibmBox.width + scrollBarWidth,
                            h: ibmBox.height
                        })
                    }
                } else {
                    holesToDraw.push({
                        x: -9999,
                        y: -9999,
                        w: 0,
                        h: 0
                    })
                }

                var termsBox = termsRef.getBoundingClientRect()
                if (termsBox) {
                    holesToDraw.push({
                        x: termsBox.x,
                        y: termsBox.y + window.scrollY,
                        w: termsBox.width + scrollBarWidth,
                        h: termsBox.height
                    })
                }

                if (holesToDraw.length >= 5) {
                    console.log(holesToDraw)
                    this.setState({
                        gotOverlayPossitions: true,
                        overlayPossitions: holesToDraw
                    })
                }
            }
        }

        if (!this.props.user.enabled_2fa && prevProps.user.enabled_2fa) {
            this.setState({
                show2FATokenPopup: false,
                easyGuardCode: ''
            })
        }
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.escFunction, false)
        window.removeEventListener("resize", throttle(this.setOverlaySize, 100))
    }

    escFunction = (e) => {
        if (e.keyCode === 27) {
            if (this.state.showPhotoCropPopup) {
                this.setState({ photo: {}, showPhotoCropPopup: false })
            } else if (this.state.showWithdrawConsentPopup) {
                this.setState({ showWithdrawConsentPopup: false })
            } else if (this.state.showDeleteProfilePicPopup) {
                this.setState({
                    showDeleteProfilePicPopup: false,
                    deleteProfilePicInProgress: false,
                })
            } else if (this.state.show2FATokenPopup) {
                this.setState({
                    show2FATokenPopup: false
                })
            } else if (this.state.show2FAHelpPopup) {
                this.setState({
                    show2FAHelpPopup: false
                })
            } else if (this.state.showChangePasswordSection) {
                this.setState({
                    showChangePasswordSection: false,
                })
            } else if (this.state.showLogoutAllDevicesWarning) {
                this.setState({
                    showLogoutAllDevicesWarning: false
                })
            } else if (this.state.showChangeEasyGuardDevicePopup) {
                this.setState({
                    showChangeEasyGuardDevicePopup: false
                })
            }
        }
    }

    setOverlaySize = () => {
        const { profileRef, notificationsRef, languageRef, accountNumbersRef, ibmRef, termsRef } = this.refs
        const scrollBarWidth = window.innerWidth - document.body.offsetWidth
        if (profileRef && notificationsRef && languageRef && accountNumbersRef && termsRef) {
            var holesToDraw = []

            var profileBox = profileRef.getBoundingClientRect()
            if (profileBox) {
                holesToDraw.push({
                    x: profileBox.x,
                    y: profileBox.y + window.scrollY,
                    w: profileBox.width + scrollBarWidth,
                    h: profileBox.height
                })
            }

            var notificationsBox = notificationsRef.getBoundingClientRect()
            if (notificationsBox) {
                holesToDraw.push({
                    x: notificationsBox.x,
                    y: notificationsBox.y + window.scrollY,
                    w: notificationsBox.width + scrollBarWidth,
                    h: notificationsBox.height
                })
            }

            var languageBox = languageRef.getBoundingClientRect()
            if (languageBox) {
                holesToDraw.push({
                    x: languageBox.x,
                    y: languageBox.y + window.scrollY,
                    w: languageBox.width + scrollBarWidth,
                    h: languageBox.height
                })
            }

            var accountNumbersBox = accountNumbersRef.getBoundingClientRect()
            if (accountNumbersBox) {
                holesToDraw.push({
                    x: accountNumbersBox.x,
                    y: accountNumbersBox.y + window.scrollY,
                    w: accountNumbersBox.width + scrollBarWidth,
                    h: accountNumbersBox.height
                })
            }

            if (ibmRef) {
                var ibmBox = ibmRef.getBoundingClientRect()
                if (ibmBox) {
                    holesToDraw.push({
                        x: ibmBox.x,
                        y: ibmBox.y + window.scrollY,
                        w: ibmBox.width + scrollBarWidth,
                        h: ibmBox.height
                    })
                }
            } else {
                holesToDraw.push({
                    x: -9999,
                    y: -9999,
                    w: 0,
                    h: 0
                })
            }

            var termsBox = termsRef.getBoundingClientRect()
            if (termsBox) {
                holesToDraw.push({
                    x: termsBox.x,
                    y: termsBox.y + window.scrollY,
                    w: termsBox.width + scrollBarWidth,
                    h: termsBox.height
                })
            }

            if (holesToDraw.length >= 5) {
                console.log(holesToDraw)
                this.setState({
                    gotOverlayPossitions: true,
                    overlayPossitions: holesToDraw
                })
            }
        }
    }

    verifyFile = (files) => {
        if (files && files.length > 1) {
            this.props.alertWarn(this.context.t("Only one photo is allowed"))
            return false
        } else if (files && files.length === 1) {
            files.forEach(currentFile => {
                const currentFileType = currentFile.type
                const currentFileSize = currentFile.size
                if (currentFileSize > imageMaxSize) {
                    this.props.alertWarn(this.context.t("Document is to big. Maximum size is 5MB"))
                    return false
                }
                if (!acceptedFileTypesArray.includes(currentFileType)) {
                    this.props.alertWarn("This file is not allowed. Only png or jpg images are allowed.")
                    return false
                }
            })
        }
        return true
    }

    handleOnDrop = (files, rejectedFiles) => {
        console.log('drop')
        if (rejectedFiles && rejectedFiles.length > 0) {
            this.verifyFile(rejectedFiles)
        }

        else {
            files.forEach((currentFile, index) => {
                const myFileItemReader = new FileReader()
                myFileItemReader.addEventListener("load", () => {
                    const myResult = {}
                    console.log(myResult)
                    myResult.name = currentFile.name
                    myResult.size = currentFile.size
                    myResult.type = currentFile.type
                    myResult.base64 = myFileItemReader.result
                    this.setState({
                        photo: myResult,
                        showPhotoCropPopup: true,
                    })
                }, false)
                myFileItemReader.readAsDataURL(currentFile)
            })
        }
    }

    onInputChange = e => {
        e.preventDefault()

        const { name, value } = e.target

        this.setState({ [name]: value })
    }

    hasUppercase = (str) => {
        return (/[A-Z]/.test(str));
    }

    hasLowercase = (str) => {
        return (/[a-z]/.test(str));
    }

    hasDigit = (str) => {
        return (/[0-9]/.test(str));
    }

    saveNewPassword = () => {
        var valid = true;

        const { oldPassword, newPassword } = this.state

        if (oldPassword.length === 0) {
            valid = false
            this.props.alertWarn(this.context.t('Current password is required'))
        } else if (newPassword.length === 0) {
            valid = false
            this.props.alertWarn(this.context.t('New password is required'))
        } else if (newPassword === oldPassword) {
            valid = false
            this.props.alertWarn(this.context.t('New password must differ from the current one'))
        } else if (newPassword.length < 12) {
            valid = false
            this.props.alertWarn(this.context.t('Passwords must be at least 12 characters long.'))
        } else {
            var passwordRequirements = 0
            if (this.hasUppercase(newPassword)) passwordRequirements += 1
            if (this.hasLowercase(newPassword)) passwordRequirements += 1
            if (this.hasDigit(newPassword)) passwordRequirements += 1

            if (passwordRequirements !== 3) {
                valid = false
                this.props.alertWarn(this.context.t('Password must contain at least one uppercase letter, one lowercase letter and a number.'))
            }
        }

        if (valid) {
            this.props.changePassword(oldPassword, newPassword, success => {
                if (success) {
                    this.setState({
                        showChangePasswordSection: false
                    })
                }
            })
        }
    }

    cancelNewPassword = () => {
        this.setState({
            showChangePasswordSection: false,
            oldPassword: '',
            newPassword: ''
        })
    }

    changeVisibility = name => {
        if (name === 'old') {
            const { oldPasswordVisible } = this.state
            if (this.refs.oldPassword) this.refs.oldPassword.type = oldPasswordVisible ? 'password' : 'text'
            this.setState({
                oldPasswordVisible: !oldPasswordVisible
            })
        } else if (name === 'new') {
            const { newPasswordVisible } = this.state
            if (this.refs.newPassword) this.refs.newPassword.type = newPasswordVisible ? 'password' : 'text'
            this.setState({
                newPasswordVisible: !newPasswordVisible
            })
        } else if (name === 'easyGuardCode') {
            if (this.state.easyGuardCodeVisible === false) {
                this.refs.easyGuardCode.type = 'text'
                this.setState({
                    easyGuardCodeVisible: true
                })
            } else {
                this.refs.easyGuardCode.type = 'password'
                this.setState({
                    easyGuardCodeVisible: false
                })
            }
        }
    }

    generatePassowrd = () => {
        var password = generator.generate({
            length: 12,
            numbers: true,
            lowercase: true,
            uppercase: true,
            symbols: false,
            excludeSimilarCharacter: true,
            strict: true,
        });

        this.setState({
            newPassword: password
        })
    }

    showWithdrawConsentPopup = () => {
        var { showWithdrawConsentPopup } = this.state
        console.log(showWithdrawConsentPopup)
        this.setState({
            showWithdrawConsentPopup: !showWithdrawConsentPopup
        })
    }

    withdrawConsent = e => {
        e.preventDefault()

        this.props.withdrawConsent(this.props.user.email)
    }

    handleChangePasswordClick = e => {
        var { showChangePasswordSection } = this.state
        if (showChangePasswordSection) {
            this.changePasswordSubmit(e)
        } else {
            this.setState({
                showChangePasswordSection: true
            })
        }
    }

    cropProfilePhoto = e => {
        if (this.refs.cropper && !this.state.changingProfilePicInProgress) {
            var croppedImage = this.refs.cropper.getCroppedCanvas().toDataURL();
            var { photo } = this.state

            this.setState({
                changingProfilePicInProgress: true
            })
            this.props.changeProfilePic({
                name: photo.name,
                size: photo.size,
                type: photo.type,
                base64: croppedImage
            }, success => {
                this.setState({
                    changingProfilePicInProgress: false,
                    showPhotoCropPopup: !success
                })
            })
        }
    }

    showDeleteProfilePicPopup = () => {
        this.setState({
            showDeleteProfilePicPopup: true
        })
    }

    deleteProfilePic = () => {
        this.setState({
            deleteProfilePicInProgress: true
        })
        this.props.deleteProfilePic()
    }

    showOverlay = () => {
        const { overlayPreferance } = this.props
        this.setOverlaySize()
        overlayPreferance['settings'] = true
        this.props.toggleOverlay(overlayPreferance)
    }

    toggle2FA = () => {
        const { user } = this.props

        if (user.has_registered_2fa_device) {
            if (user.enabled_2fa) {
                this.setState({
                    show2FATokenPopup: true
                })
            } else {
                this.setState({
                    show2FAHelpPopup: true
                })
            }
        } else {
            this.props.alertInfo(this.context.t('First, install and log in to the EasyDocs application on your phone to access the EasyGuard 2FA'), 10000)
        }
    }

    turnOn2FA = (e) => {
        e.preventDefault()
        const { user } = this.props
        if (user.has_registered_2fa_device) {
            this.props.turnOn2FA()
            this.setState({
                show2FAHelpPopup: false
            })
        } else {
            this.props.alertWarn(this.context.t('First, install and log in to the EasyDocs application on your phone to access the EasyGuard code'))
        }
    }

    turnOff2FA = (e) => {
        e.preventDefault()

        const { easyGuardCode } = this.state

        if (easyGuardCode.length !== 6) {
            this.props.alertWarn(this.context.t('The code must be exactly six digits long'))
        } else if (/[^0-9]/.test(easyGuardCode)) {
            this.props.alertWarn(this.context.t('The code can only contain numbers'))
        } else {
            this.props.turnOff2FA(easyGuardCode)
        }
    }

    sendEasyGuardDeviceResetEmail = () => {
        this.setState({
            waitingForEasyGuardDeviceChange: true
        })
        this.props.sendEasyGuardDeviceResetEmail(this.props.user.email, () => {
            this.setState({
                showChangeEasyGuardDevicePopup: false,
                waitingForEasyGuardDeviceChange: false
            })
        })
    }

    logOutOfAllDevice = () => {
        const { user } = this.props
        if (user.enabled_2fa) {
            this.setState({
                showLogoutAllDevicesWarning: true
            })
        } else {
            this.props.logoutAllDevices()
        }
    }

    showCurrencyList = (e, i) => {
        var up = false
        var pos = e.target.getClientRects()[0];
        var left = pos.left;
        var top = pos.top;
        console.log(left, top, up)
        console.log(window.innerHeight)
        if (top + 130 > window.innerHeight) {
            up = true
        }

        this.setState({
            bankContainer: i,
            showCurrencyList: true,
            currencyListPosX: left,
            currencyListPosY: top,
            currencyListFlipUp: up,
        })
    }

    toggleCurrencyList = (e, i) => {
        if (this.state.showCurrencyList && this.state.bankContainer === i) {
            this.setState({
                bankContainer: -1,
                showCurrencyList: false,
                currencyListPosX: 0,
                currencyListPosY: 0,
                currencyListFlipUp: false,
            })
        } else {
            var up = false
            var pos = e.target.getClientRects()[0];
            var left = pos.left;
            var top = pos.top;
            console.log(left, top, up)
            console.log(window.innerHeight)
            if (top + 130 > window.innerHeight) {
                up = true
            }

            this.setState({
                bankContainer: i,
                showCurrencyList: true,
                currencyListPosX: left,
                currencyListPosY: top,
                currencyListFlipUp: up,
            })
        }
    }

    selectCurrency = (currency, i) => {
        if (i !== -1) {
            var banks = JSON.parse(JSON.stringify(this.state.banks))
            const user = JSON.parse(JSON.stringify(this.props.user))
            var edited = true

            banks[i].currency = currency
            if (user.banks[i] && banks[i].account_number === user.banks[i].account_number && banks[i].currency === user.banks[i].currency) {
                edited = false
            }

            banks[i].edited = edited

            this.setState({
                banks: banks,
                showCurrencyList: false,
                currencyListPosX: 0,
                currencyListPosY: 0,
                currencyListFlipUp: false,
            })
        }
    }

    onInputChangeBankInfo = (e, i) => {
        var banks = JSON.parse(JSON.stringify(this.state.banks))
        const user = JSON.parse(JSON.stringify(this.props.user))
        e.preventDefault()

        const { value } = e.target
        var edited = true

        banks[i].account_number = value

        console.log(user.banks[i])
        console.log(banks[i])
        if (user.banks[i] && banks[i].account_number === user.banks[i].account_number && banks[i].currency === user.banks[i].currency) {
            edited = false
        }

        banks[i].edited = edited

        this.setState({
            banks: banks
        })
    }

    cancelAddingAccount = () => {
        if (this.state.addAccountMode) {
            var { banks } = this.state
            banks.pop()
            this.setState({
                banks: banks,
                addAccountMode: false
            })
        }
    }

    cancelEditingBank = (e, i) => {
        e.preventDefault()
        var { banks } = this.state
        const { user } = this.props

        banks[i] = user.banks[i]

        this.setState({
            banks: banks
        })
    }

    addAccount = () => {
        if (!this.state.addAccountMode) {
            var { banks } = this.state
            banks.push({
                id: 'new',
                account_number: '',
                bank_name: '',
                bic: '',
                currency: '',
                name: '',
                edited: true
            })
            this.setState({
                banks: banks,
                addAccountMode: true
            })
        }
    }

    bankIsValid = i => {
        var valid = true
        const { banks } = this.state

        if (banks[i].account_number === '') {
            this.props.alertWarn(this.context.t('Account number is required'))
            valid = false
        } else if (!/^[A-Z]*$/.test(banks[i].account_number[0]) || !/^[A-Z]*$/.test(banks[i].account_number[1])) {
            this.props.alertWarn(this.context.t('Enter the account number in the form of an IBAN number - with the prefix being the country code'))
            valid = false
        }

        if (banks[i].currency === '') {
            this.props.alertWarn(this.context.t('Currency is required'))
            valid = false
        }

        return valid
    }

    saveBankChanges = (e, i) => {
        e.preventDefault()

        const { banks } = this.state

        if (this.bankIsValid(i)) {
            this.props.updateUserBankAccount(banks[i])
        }
    }

    createNewBankForCompany = (e) => {
        e.preventDefault()
        if (this.state.addAccountMode) {
            var { banks } = this.state

            if (this.bankIsValid(banks.length - 1)) {

                this.props.addNewUserBankAccount(banks[banks.length - 1], (success, newBankId) => {
                    this.setState({
                        addAccountMode: false
                    })
                })
            }
        }
    }

    showDeleteBankAccountModal = (e, i) => {
        e.preventDefault()

        this.setState({
            showDeleteBankAccountModal: true,
            bankIndexToDalete: i
        })
    }

    closeDeleteBankAccountModal = (e) => {
        e.preventDefault()

        this.setState({
            showDeleteBankAccountModal: false,
            bankIndexToDalete: -1
        })
    }

    deleteBankAccount = (e) => {
        e.preventDefault()

        this.props.deleteUserBankAccount(this.state.banks[this.state.bankIndexToDalete].id)

        this.setState({
            showDeleteBankAccountModal: false,
            bankIndexToDalete: -1
        })
    }

    saveNewUserName = () => {
        const { userFirstName, userLastName, editingUserName } = this.state
        if (editingUserName) {
            if (!userFirstName) {
                this.props.alertWarn('First name is required')
            } else if (!userLastName) {
                this.props.alertWarn('Last name is required')
            } else {
                this.setState({
                    editingUserName: false
                })
                this.props.updateUserName(userFirstName, userLastName)
            }
        }
    }


    render() {
        const {
            gotOverlayPossitions, overlayPossitions,
            userFirstName, userLastName, editingUserName,
            showPhotoCropPopup, changingProfilePicInProgress, showDeleteProfilePicPopup, deleteProfilePicInProgress,
            oldPassword, newPassword, oldPasswordVisible, newPasswordVisible, showChangePasswordSection,
            show2FATokenPopup, show2FAHelpPopup, easyGuardCode, showChangeEasyGuardDevicePopup, waitingForEasyGuardDeviceChange, showLogoutAllDevicesWarning,
            banks, showCurrencyList, bankContainer, currencyListFlipUp, addAccountMode, showDeleteBankAccountModal,
            showWithdrawConsentPopup
        } = this.state
        const { user, overlayPreferance, highestAcceptancePermission, loadingScreen, tabIsActive } = this.props

        if (tabIsActive || !user.new_easydocs_version) {
            return (
                <div className="settings-container" ref="contentWrapper">
                    {/* <div className="section-title">
                    <span className="icon-settings"></span>
                    <h2>{this.context.t('Settings')}</h2>
                </div> */}

                    {
                        loadingScreen ? (
                            <div className="loading-screen">
                                <div className="activity-indicator">
                                    <Spinner size={50} speed={0.8} color={"#ffffff"} />
                                </div>
                            </div>
                        ) : (
                            null
                        )
                    }

                    <div className='show-overlay' onClick={() => this.showOverlay()} data-for="info-context-overlay" data-tip="show"></div>

                    {
                        gotOverlayPossitions && overlayPreferance['settings'] ? (
                            <OverlayInstructions possitions={overlayPossitions} name="settings" height={this.refs.contentWrapper.getBoundingClientRect().height + 20 + 110} />
                        ) : (
                            null
                        )
                    }

                    <SettingsTabsBar />

                    {
                        showWithdrawConsentPopup ? (
                            <div className="popup">
                                <div className="popup-card">
                                    <div className="header">{this.context.t('After this action you will no longer be able to use EasyDocs')}</div>
                                    <div className="info">
                                        <div className="text">
                                            <p>{this.context.t('You will be logged out immediately and will not be able to use the application until you restore your consent on the login page.')}</p>
                                        </div>
                                    </div>
                                    <div className="action-buttons">
                                        <button className="close-button btn" onClick={() => this.setState({ showWithdrawConsentPopup: false })}>{this.context.t('Cancel')}</button>
                                        <button className="accept-button btn" onClick={e => this.withdrawConsent(e)}>{this.context.t('Confirm')}</button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            null
                        )
                    }

                    {
                        showPhotoCropPopup ? (
                            <div className="popup">
                                <div className="popup-card photo-popup">
                                    <div className="header">{this.context.t('Crop your photo')}</div>
                                    <Cropper
                                        className="cropper-wrapper"
                                        ref='cropper'
                                        src={this.state.photo.base64}
                                        // Cropper.js options
                                        viewMode={2}
                                        autoCropArea={1}
                                        aspectRatio={1}
                                        zoomOnTouch={false}
                                        zoomOnWheel={false}
                                        background={true}
                                    />
                                    <div className="action-buttons">
                                        <button className="close-button btn" onClick={() => this.setState({ photo: {}, showPhotoCropPopup: false })}>{this.context.t('Cancel')}</button>
                                        <button className={`accept-button btn ${changingProfilePicInProgress ? 'not-active' : ''}`} onClick={(e) => this.cropProfilePhoto(e)} >
                                            {
                                                changingProfilePicInProgress ? (
                                                    <Digital size={20} speed={0.8} color={"#fff"} />
                                                ) : (
                                                    this.context.t('Save')
                                                )
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            null
                        )
                    }

                    {
                        showDeleteProfilePicPopup ? (
                            <div className="popup">
                                <div className="popup-card">
                                    <div className="header">{this.context.t('Are you sure you want to delete this photo?')}</div>
                                    <div className="action-buttons">
                                        <button className="close-button btn" onClick={() => this.setState({ showDeleteProfilePicPopup: false })}>{this.context.t('Cancel')}</button>
                                        <button className={`accept-button btn ${changingProfilePicInProgress ? 'not-active' : ''}`} onClick={e => this.deleteProfilePic()}>
                                            {
                                                deleteProfilePicInProgress ? (
                                                    <Digital size={20} speed={0.8} color={"#fff"} />
                                                ) : (
                                                    this.context.t('Delete')
                                                )
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            null
                        )
                    }

                    {
                        show2FATokenPopup ? (
                            <div className="popup">
                                <div className="popup-card password-modal easy-guard">
                                    <div className="header">{this.context.t('Enter the EasyGuard code from the EasyDocs mobile app to confirm that you are turning off two-factor authentication')}</div>
                                    <form onSubmit={e => this.turnOff2FA(e)} autoComplete="off">
                                        <div className="form-group">
                                            <input type="text" name="easyGuardCode" id="easyGuardCode" ref="easyGuardCode" value={easyGuardCode} onChange={this.onInputChange} placeholder="" pattern="[0-9]*" maxlength="6" />
                                            <label htmlFor="easyGuardCode">{this.context.t('EasyGuard Code')}</label>
                                        </div>
                                    </form>
                                    <div className="action-buttons">
                                        <button className="accept-button btn" onClick={e => this.turnOff2FA(e)}>{this.context.t('Confirm')}</button>
                                        <button className={`close-button btn ${waitingForEasyGuardDeviceChange ? 'loading' : ''}`} onClick={e => this.sendEasyGuardDeviceResetEmail()}>{this.context.t("I've lost access to EasyGuard")}</button>
                                        <button className="close-button btn" onClick={() => this.setState({ show2FATokenPopup: false })}>{this.context.t('Cancel')}</button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            null
                        )
                    }

                    {
                        show2FAHelpPopup ? (
                            <div className="popup">
                                <div className="popup-card easy-guard-help">
                                    <div className="header">{this.context.t('The EasyGuard authentication is an additional protection of your account against unauthorized access')}</div>

                                    <div className="info">
                                        <div className="text">
                                            <p>{this.context.t('You can find it in the EasyGuard tab in our EasyDocs mobile application available for Android and iOS phones')}</p>
                                        </div>
                                        <img src={easyGuardHelp} />
                                    </div>

                                    <div className="action-buttons">
                                        <button className="close-button btn" onClick={() => this.setState({ show2FAHelpPopup: false })}>{this.context.t('Cancel')}</button>
                                        <button className="accept-button btn" onClick={e => this.turnOn2FA(e)}>{this.context.t('Turn EasyGuard On')}</button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            null
                        )
                    }

                    {
                        showLogoutAllDevicesWarning ? (
                            <div className="popup">
                                <div className="popup-card easy-guard-help">
                                    <div className="header">{this.context.t('Confirm that you want to sign out of all devices')}</div>

                                    <div className="info">
                                        <div className="text">
                                            <p>{this.context.t('You have EasyGuard protection enabled, logging in again will require access to your ')}<span>{user.registered_2fa_device_name}</span>{this.context.t(' and the six-digit code on it')}</p>
                                        </div>
                                    </div>

                                    <div className="action-buttons">
                                        <button className="close-button btn" onClick={() => this.setState({ showLogoutAllDevicesWarning: false })}>{this.context.t('Cancel')}</button>
                                        <button className="accept-button btn" onClick={e => this.props.logoutAllDevices()}>{this.context.t('Confirm')}</button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            null
                        )
                    }

                    {
                        showChangeEasyGuardDevicePopup ? (
                            <div className="popup">
                                <div className="popup-card password-modal change-easyguard">
                                    <div className="header">{this.context.t('Changing the EasyGuard device')}</div>
                                    <div className="body">
                                        <p>{this.context.t('A message will be sent to your e-mail address. After clicking on the link in the message, codes from the current device will no longer be taken into account. To register a new device, all you need to do is log in to the EasyDocs app')}</p>
                                    </div>
                                    <div className="action-buttons">
                                        <button className="close-button btn" onClick={() => this.setState({ showChangeEasyGuardDevicePopup: false })}>{this.context.t('Cancel')}</button>
                                        <button className={`accept-button btn ${waitingForEasyGuardDeviceChange ? 'loading' : ''}`} onClick={e => this.sendEasyGuardDeviceResetEmail()}>{this.context.t('Confirm')}</button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            null
                        )
                    }

                    {
                        showDeleteBankAccountModal ? (
                            <div className="delete-invoice-popup popup">
                                <div className="popup-card">
                                    <div className="header">{this.context.t('Are you sure you want to remove this bank account?')}</div>
                                    <div className="action-buttons">
                                        <div className="btn" onClick={e => this.closeDeleteBankAccountModal(e)}>{this.context.t('Cancel')}</div>
                                        <div className="btn delete" onClick={e => this.deleteBankAccount(e)}>{this.context.t('Delete')}</div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            null
                        )
                    }


                    <div className="card profile" ref="profileRef">
                        <div className="header">
                            <h3>{this.context.t('Profile')}</h3>
                            {
                                !showChangePasswordSection ? (
                                    <button onClick={e => this.handleChangePasswordClick(e)}>
                                        {this.context.t('Change password')}
                                    </button>
                                ) : null
                            }

                            <button className="second-button" onClick={e => this.logOutOfAllDevice(e)}>
                                {this.context.t('Log out of all devices')}
                            </button>
                        </div>

                        <div className="body">
                            <div className="photo">
                                <div className={`user-photo ${!user.profile_pic_link ? 'default' : ''}`}>
                                    {
                                        user.profile_pic_link ? (
                                            <img src={user.profile_pic_link} alt="Users profile picture" />
                                        ) : (
                                            null
                                        )
                                    }
                                </div>
                                <div className="content">
                                    <div className="user-name">
                                        {
                                            editingUserName ? (
                                                <>
                                                    <input type="text" name="userFirstName" value={userFirstName} onChange={this.onInputChange} />
                                                    <input type="text" name="userLastName" value={userLastName} onChange={this.onInputChange} />
                                                    <div className="button accept-button" onClick={() => this.saveNewUserName()}></div>
                                                    <div className="button cancel-button" onClick={() => this.setState({ editingUserName: false })}></div>
                                                </>
                                            ) : (
                                                <>
                                                    <div>{`${user.first_name} ${user.last_name}`}</div>
                                                    <div className="button edit-button" onClick={() => this.setState({ editingUserName: true })}></div>
                                                </>
                                            )
                                        }
                                    </div>
                                    <div className="action-buttons">
                                        <div className="add-photo">
                                            <Dropzone onDrop={(files, rejectedFiles) => this.handleOnDrop(files, rejectedFiles)} accept={acceptedFileTypes} multiple={false} maxSize={imageMaxSize}>
                                                {({ getRootProps, getInputProps, isDragActive }) => (
                                                    <div {...getRootProps()}>
                                                        {this.context.t('Add photo')}
                                                        <input {...getInputProps()} />
                                                    </div>
                                                )}
                                            </Dropzone>
                                        </div>
                                        {
                                            user.profile_pic_link ? (
                                                <div className="delete-photo" onClick={() => this.showDeleteProfilePicPopup()}>{this.context.t('Delete photo')}</div>
                                            ) : (
                                                null
                                            )
                                        }
                                    </div>
                                </div>
                            </div>

                            {
                                showChangePasswordSection ? (
                                    <div className="change-password-section">
                                        <form>
                                            <div className="form-group">
                                                <input type="password" name="oldPassword" id="oldPassword" ref="oldPassword" value={oldPassword} onChange={this.onInputChange} placeholder="" />
                                                <label htmlFor="oldPassword">{this.context.t('Current password')}</label>
                                                <span className={`icon make-visible ${oldPasswordVisible ? 'view' : 'hide'}`} onClick={() => this.changeVisibility('old')}>
                                                    <div className="info-cloud">
                                                        <div className="info-text">{this.context.t('Show password.')}</div>
                                                    </div>
                                                </span>
                                            </div>
                                            <div className="form-group" data-for="new-password-requirements" data-tip="show">
                                                <input type="password" name="newPassword" id="newPassword" ref="newPassword" value={newPassword} onChange={this.onInputChange} placeholder="" />
                                                <label htmlFor="newPassword">{this.context.t('New password')}</label>
                                                <span className={`icon make-visible ${newPasswordVisible ? 'view' : 'hide'}`} onClick={() => this.changeVisibility('new')}>
                                                    <div className="info-cloud">
                                                        <div className="info-text">{this.context.t('Show password.')}</div>
                                                    </div>
                                                </span>

                                                {
                                                    newPassword ? (
                                                        <span data-for="copy-password" data-tip="show" className={`icon copy-to-clipboard`} onClick={e => { copy(newPassword); this.props.alertInfo('Copied to clipboard', 2500) }}>
                                                            <div className="info-cloud">
                                                                <div className="info-text">{this.context.t('Copy password.')}</div>
                                                            </div>
                                                        </span>
                                                    ) : null
                                                }

                                                <span data-for="generate-password" data-tip="show" className={`icon password-generator`} onClick={() => this.generatePassowrd()}>
                                                    <div className="info-cloud">
                                                        <div className="info-text">{this.context.t('Generate strong password.')}</div>
                                                    </div>
                                                </span>
                                            </div>

                                            <ReactTooltip id="new-password-requirements" place="bottom" effect="solid" className="default-tooltip password-info">
                                                <ul>
                                                    <li className={`${newPassword?.length >= 12 ? 'completed' : ''}`}>{this.context.t('at least 12 characters')}</li>
                                                    <li className={`${this.hasUppercase(newPassword) ? 'completed' : ''}`}>{this.context.t('at least one uppercase letter')}</li>
                                                    <li className={`${this.hasLowercase(newPassword) ? 'completed' : ''}`}>{this.context.t('at least one lowercase letter')}</li>
                                                    <li className={`${this.hasDigit(newPassword) ? 'completed' : ''}`}>{this.context.t('at least one digit')}</li>
                                                </ul>
                                            </ReactTooltip>

                                            <div className="button accept-button" onClick={() => this.saveNewPassword()}></div>
                                            <div className="button cancel-button" onClick={() => this.setState({ showChangePasswordSection: false })}></div>
                                        </form>
                                    </div>
                                ) : (
                                    null
                                )
                            }

                            <div className="section-2fa">
                                <div className="switcher">
                                    {this.context.t('Secure access to the application with an additional 2FA code')}
                                    <div className={`switcher ${user.enabled_2fa ? 'on' : 'off'}`} onClick={() => this.toggle2FA()}>
                                        <span className={`ball ${user.enabled_2fa ? 'on' : 'off'}`}></span>
                                    </div>
                                </div>

                                {
                                    user.has_registered_2fa_device && user.enabled_2fa ? (
                                        <div className="device">
                                            {this.context.t('Your device with the EasyGuard code: ')}<span>{user.registered_2fa_device_name}</span>

                                            <button onClick={() => this.setState({ showChangeEasyGuardDevicePopup: true })}>{this.context.t('Change device')}</button>
                                        </div>
                                    ) : (
                                        <div className="app-store">
                                            {this.context.t("To use 2FA codes, install the EasyDocs application on your phone from the: ")}
                                            <ul>
                                                <li>
                                                    <a className="google-play" href="https://play.google.com/store/apps/details?id=pl.proxypartners" target="_blank"></a>
                                                    {this.context.t(" for Android devices or ")}
                                                </li>
                                                <li>
                                                    <a className="app-store" href="https://apps.apple.com/us/app/easydocs/id1534836899" target="_blank"></a>
                                                    {this.context.t(" for iPhone devices")}.
                                                </li>
                                            </ul>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    <div className={`card email-notifications-settings ${localStorage.getItem('adminOnboarding') === 'NEXT_STEPS' ? 'green-frame pulsing-frame' : ''}`} ref="notificationsRef">
                        <div className="header">
                            <h3>{this.context.t('Decide what information EasyDocs should notify you about')}</h3>
                        </div>
                        <div className="body">
                            <ul className="email-sections">
                                {/* <h5>{this.context.t('Daily e-mail')}</h5> */}
                                <li>
                                    {this.context.t('Keep me posted about upcoming tasks')}{this.context.t(" - email on working days in the morning")}
                                    <div className={`switcher ${user.notify_about_events ? 'on' : 'off'}`} onClick={() => this.props.toggleNotifyAboutEvents()}>
                                        <span className={`ball ${user.notify_about_events ? 'on' : 'off'}`}></span>
                                    </div>
                                </li>
                                {
                                    highestAcceptancePermission !== 'USER1' ? (
                                        <li>
                                            {this.context.t('Keep me posted about newly accepted documents')}{this.context.t(" - email on working days in the morning")}
                                            <div className={`switcher  ${user.notify_about_docs ? 'on' : 'off'}`} onClick={() => this.props.toggleNotifyAboutDocs()}>
                                                <span className={`ball ${user.notify_about_docs ? 'on' : 'off'}`}></span>
                                            </div>
                                        </li>
                                    ) : (
                                        null
                                    )
                                }
                                {
                                    highestAcceptancePermission !== 'USER1' ? (
                                        <li>
                                            {this.context.t('Keep me posted about unpaid documents')}{this.context.t(" - email on working days in the morning")}
                                            <div className={`switcher  ${user.notify_about_upcoming_payments ? 'on' : 'off'}`} onClick={() => this.props.toggleNotifyAboutPayments()}>
                                                <span className={`ball ${user.notify_about_upcoming_payments ? 'on' : 'off'}`}></span>
                                            </div>
                                        </li>
                                    ) : (
                                        null
                                    )
                                }
                                {
                                    highestAcceptancePermission !== 'USER1' ? (
                                        <>
                                            {/* <h5>{this.context.t('In app notifications')}</h5> */}
                                            <li className="last">
                                                {this.context.t('Keep me posted about urgent documents')}{this.context.t(' - email and popup when such a document appears in EasyDocs')}
                                                <div className={`switcher  ${user.notify_about_urgent_docs ? 'on' : 'off'}`} onClick={() => this.props.toggleNotifyAboutUrgentDocs()}>
                                                    <span className={`ball ${user.notify_about_urgent_docs ? 'on' : 'off'}`}></span>
                                                </div>
                                            </li>
                                        </>
                                    ) : (
                                        null
                                    )
                                }
                            </ul>
                        </div>
                    </div>

                    <div className={`card account-numbers ${localStorage.getItem('adminOnboarding') === 'NEXT_STEPS' && banks.length === 0 ? 'green-frame pulsing-frame' : ''}`} ref="accountNumbersRef">
                        <div className="header">
                            <h3>{this.context.t('My account numbers to which expenses incurred from private funds will be reimbursed (e.g. business trips settlements).')}</h3>
                        </div>
                        <div className="body">
                            {
                                banks.length > 0 ? (
                                    banks.map((bank, i) => {
                                        return (
                                            <div className={`user-bank ${banks[i + 1] === undefined ? 'last' : ''}`} key={`user-bank-${i}`}>
                                                <div className="btn delete-button" onClick={e => this.showDeleteBankAccountModal(e, i)}></div>
                                                <div className='form-group currency'>
                                                    <input type="text" name={`user-bank-currency-${i}`} id={`user-bank-currency-${i}`} value={bank.currency} readOnly={true} autoComplete='off' onFocus={(e) => this.showCurrencyList(e, i)} />
                                                    <label htmlFor={`user-bank-currency-${i}`}>{this.context.t('Choose your account currency')}</label>
                                                    <span className={`expend-icon ${showCurrencyList && bankContainer === i ? 'expended' : ''}`} onClick={(e) => this.toggleCurrencyList(e, i)}></span>
                                                    {
                                                        showCurrencyList && bankContainer === i ? (
                                                            <ul className={`currency-list ${currencyListFlipUp ? 'flip-up' : ''}`}>
                                                                {
                                                                    currencyList.map(c => {
                                                                        return (
                                                                            <li onClick={e => { this.selectCurrency(c, i) }}>
                                                                                {c}
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        ) : (
                                                            null
                                                        )
                                                    }
                                                </div>

                                                <div className='form-group number'>
                                                    <input type="text" name={`user-bank-account-number-${i}`} id={`user-bank-account-number-${i}`} value={bank.account_number} placeholder="PL00 0000 0000 0000 0000 0000 0000" autoComplete='off' onChange={e => this.onInputChangeBankInfo(e, i)} />
                                                    <label htmlFor={`user-bank-account-number-${i}`}>{this.context.t('Account number')}</label>
                                                </div>

                                                {
                                                    bank.edited ? (
                                                        <div className="action-buttons">
                                                            {
                                                                bank.id === 'new' ? (
                                                                    <button onClick={e => this.cancelAddingAccount(e)}>{this.context.t('Cancel')}</button>
                                                                ) : (
                                                                    <button onClick={(e) => this.cancelEditingBank(e, i)}>{this.context.t('Undo changes')}</button>
                                                                )
                                                            }

                                                            {
                                                                bank.id === 'new' ? (
                                                                    <button className="active" onClick={e => this.createNewBankForCompany(e)}>{this.context.t('Save')}</button>
                                                                ) : (
                                                                    <button className="active" onClick={(e) => this.saveBankChanges(e, i)}>{this.context.t('Save')}</button>
                                                                )
                                                            }

                                                        </div>
                                                    ) : (
                                                        null
                                                    )
                                                }
                                            </div>
                                        )
                                    })
                                ) : (
                                    null
                                )
                            }

                            <div className="add-account-container">
                                {
                                    !addAccountMode ? (
                                        <button className="active add-account" onClick={e => this.addAccount(e)}>{this.context.t("Add bank account")}</button>
                                    ) : (
                                        null
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    <div className="card language" ref="languageRef">
                        <div className="header">
                            <h3>
                                {this.context.t('Language')}
                                <span className="help-icon" data-for="language-info" data-tip="show"></span>

                                <ReactTooltip id="language-info" place="right" effect="solid" className="default-tooltip account-name-info">
                                    {this.context.t('Default language version of the application')}
                                </ReactTooltip>
                            </h3>
                            <div className={`lang-div pl ${localStorage.getItem('language') === 'pl' ? 'active' : ''}`} onClick={() => this.props.changeLanguage("pl")}>PL</div>
                            <div className={`lang-div ${localStorage.getItem('language') === 'en' ? 'active' : ''}`} onClick={() => this.props.changeLanguage("en")}>EN</div>
                        </div>
                    </div>

                    <div className="card language">
                        <div className="header">
                            <h3>
                                {this.context.t('Cookies')}
                            </h3>
                            <button>
                                <a href="javascript: Cookiebot.renew()">{this.context.t('Manage cookies')}</a>
                            </button>
                        </div>
                    </div>

                    <div className="card terms-of-service settings-form-card form-card-middle mt-80" ref="termsRef">
                        <div className="header">
                            <h3>{this.context.t('Permissions')}</h3>
                            <button onClick={() => this.showWithdrawConsentPopup()}>
                                {this.context.t('Withdraw Consent')}
                            </button>
                        </div>
                        <div className="body">
                            <p>
                                {this.context.t('By registering you agreed with our ')}
                                <a href="https://easydocs.pl/polityka-prywatnosci" target="_blank">{this.context.t('Privacy Policy')}</a>{this.context.t(', ')}
                                <a href="https://easydocs.pl/regulamin" target="_blank">{this.context.t('Terms of Service')}</a>
                                {this.context.t(' and ')}
                                <a href="https://easydocs.pl/przetwarzanie-danych" target="_blank">{this.context.t('Agreement for the Entrustment of Personal Data Processing')}</a>.
                                {this.context.t(' You can change your decision at any time but be aware that by doing that you will no longer be able to use EasyDocs.')}
                            </p>
                        </div>
                    </div>
                </div >
            )
        } else {
            return null
        }
    }

}

Settings.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user ? { ...state.User.user } : {},
    overlayPreferance: state.User.overlayPreferance || {},
    userProjects: state.User.userProjects,
    highestAcceptancePermission: state.User.highestAcceptancePermission,
    loadingScreen: state.File.loadingScreen || false,
})

const mapDispatchToProps = {
    getUserProjects: UserActions.getUserProjects,
    changeLanguage: UserActions.changeLanguage,

    toggleNotifyAboutEvents: UserActions.toggleNotifyAboutEvents,
    toggleNotifyAboutDocs: UserActions.toggleNotifyAboutDocs,
    toggleNotifyAboutPayments: UserActions.toggleNotifyAboutPayments,
    toggleNotifyAboutUrgentDocs: UserActions.toggleNotifyAboutUrgentDocs,

    updateUserName: UserActions.updateUserName,
    changePassword: UserActions.changePassword,
    changeProfilePic: UserActions.changeProfilePic,
    deleteProfilePic: UserActions.deleteProfilePic,

    updateUserBankAccount: UserActions.updateUserBankAccount,
    addNewUserBankAccount: UserActions.addNewUserBankAccount,
    deleteUserBankAccount: UserActions.deleteUserBankAccount,

    withdrawConsent: UserActions.withdrawConsent,

    alertWarn: AlertActions.warning,
    alertInfo: AlertActions.info,
    toggleOverlay: UserActions.toggleOverlay,

    turnOn2FA: UserActions.turnOn2FA,
    turnOff2FA: UserActions.turnOff2FA,
    sendEasyGuardDeviceResetEmail: UserActions.sendEasyGuardDeviceResetEmail,
    logoutAllDevices: UserActions.logoutAllDevices,
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)