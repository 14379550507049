import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { CompanyActions } from '../actions/CompanyActions'

import '../css/ChangeSubscriptionTyprPopup.css'
import moment from 'moment'

export class ChangeSubscriptionTyprPopup extends Component {
    constructor(props) {
        super(props)

		this.state = {}
    }

    closePopup = e => {
        if(e && e.target && e.target.className.includes('schange-sub-type-popup')) {
            this.props.close()
        }
    }

    showConfirmPopup = newPlan => {
        this.setState({
            newPlan: newPlan,
            showConfirmPopup: true
        })
    }

    closeConfirmPopup = () => {
        this.setState({
            newPlan: '',
            showConfirmPopup: false
        })
    }

    render() {
        const {currentPlan, companyId} = this.props
        const {newPlan, showConfirmPopup} = this.state
        return (
            <div className="schange-sub-type-popup popup" onClick={e => this.closePopup(e)}>
                {
                    showConfirmPopup ? (
                        <div className="confirm">
                            <div className="text">
                                {this.context.t('Are you sure you want to change your current subscription to {n}?', {n: newPlan})}
                            </div>
                            <div className="buttons">
                                <div className="cancel-btn" onClick={() => this.closeConfirmPopup()}>{this.context.t('Cancel')}</div>
                                <div className="confirm-btn" onClick={() => this.props.changeCompanySubscriptionType(companyId, newPlan, () => this.props.close())}>{this.context.t('Confirm')}</div>
                            </div>
                        </div>
                    ) : (
                        null
                    )
                }
                <div className="popup-body">
                    <div className="header row">
                        <div className="description"></div>
                        <div className={`plan ${currentPlan === 'BASIC' ? 'current' : ''}`}>
                            <h1>BASIC</h1>
                        </div>
                        <div className={`plan ${currentPlan === 'PRO' ? 'current' : ''}`}>
                            <h1>PRO</h1>
                        </div>
                        <div className={`plan ${currentPlan === 'PRO+' ? 'current' : ''}`}>
                            <h1>PRO+</h1>
                        </div>
                    </div>
                    <div className="odd row">
                        <div className="description">
                            <h3>{this.context.t('Automate your cost accounting (EasyDocs® AI)')}</h3>
                            <ul>
                                <li>{this.context.t('Automatically read payment details from documents')}</li>
                                <li>{this.context.t('Import transfers to the bank with one click')}</li>
                                <li>{this.context.t('Easily search and download cataloged documents')}</li>
                            </ul>
                        </div>
                        <div className={`available yes ${currentPlan === 'BASIC' ? 'current' : ''}`}></div>
                        <div className={`available yes ${currentPlan === 'PRO' ? 'current' : ''}`}></div>
                        <div className={`available yes ${currentPlan === 'PRO+' ? 'current' : ''}`}></div>
                    </div>
                    <div className="even row">
                        <div className="description">
                            <h3>{this.context.t('Generate reports and export them to Excel')}</h3>
                            <ul>
                                <li>{this.context.t('Export your reports to an .xls file')}</li>
                                <li>{this.context.t('Group and control costs with tags')}</li>
                            </ul>
                        </div>
                        <div className={`available no ${currentPlan === 'BASIC' ? 'current' : ''}`}></div>
                        <div className={`available yes ${currentPlan === 'PRO' ? 'current' : ''}`}></div>
                        <div className={`available yes ${currentPlan === 'PRO+' ? 'current' : ''}`}></div>
                    </div>
                    <div className="odd row">
                        <div className="description">
                            <h3>{this.context.t('Organize the circulation of documents with Accounting')}</h3>
                            <ul>
                                <li>{this.context.t('Enable access for Accounting')}</li>
                                <li>{this.context.t('Grant access (employee, accountant, management)')}</li>
                            </ul>
                        </div>
                        <div className={`available no ${currentPlan === 'BASIC' ? 'current' : ''}`}></div>
                        <div className={`available yes ${currentPlan === 'PRO' ? 'current' : ''}`}></div>
                        <div className={`available yes ${currentPlan === 'PRO+' ? 'current' : ''}`}></div>
                    </div>
                    <div className="even row">
                        <div className="description">
                            <h3>{this.context.t('Settle business trips and expenses')}</h3>
                            <ul>
                                <li>{this.context.t('Collect and send all expenses on the fly')}</li>
                                <li>{this.context.t('Generate expense reports quickly')}</li>
                            </ul>
                        </div>
                        <div className={`available no ${currentPlan === 'BASIC' ? 'current' : ''}`}></div>
                        <div className={`available yes ${currentPlan === 'PRO' ? 'current' : ''}`}></div>
                        <div className={`available yes ${currentPlan === 'PRO+' ? 'current' : ''}`}></div>
                    </div>
                    <div className="odd row">
                        <div className="description">
                            <h3>{this.context.t('Assign tasks to colleagues')}</h3>
                            <ul>
                                <li>{this.context.t('Create tasks in the calendar')}</li>
                                <li>{this.context.t('Assign tasks to your colleagues')}</li>
                            </ul>
                        </div>
                        <div className={`available no ${currentPlan === 'BASIC' ? 'current' : ''}`}></div>
                        <div className={`available no ${currentPlan === 'PRO' ? 'current' : ''}`}></div>
                        <div className={`available yes ${currentPlan === 'PRO+' ? 'current' : ''}`}></div>
                    </div>
                    <div className="even row">
                        <div className="description"></div>
                        <div className={`available ${currentPlan === 'BASIC' ? 'current' : ''}`}>
                            <div className="limits">
                                <p>{this.context.t('up to 3 users')}</p>
                                <p>{this.context.t('up to 100 docs/month')}</p>
                            </div>
                            <div className="price">
                                <span>199 zł</span>
                                <span className="small">{this.context.t('/net per month')}</span>
                            </div>
                        </div>
                        <div className={`available ${currentPlan === 'PRO' ? 'current' : ''}`}>
                            <div className="limits">
                                <p>{this.context.t('up to 5 users')}</p>
                                <p>{this.context.t('up to 500 docs/month')}</p>
                            </div>
                            <div className="price">
                                <span>299 zł</span>
                                <span className="small">{this.context.t('/net per month + 49zł for next user')}</span>
                            </div>

                            {
                                currentPlan === 'BASIC' ? (
                                    <div className="buy-now" onClick={() => this.showConfirmPopup('PRO')}>{this.context.t('Choose')}</div>
                                ) : (
                                    null
                                )
                            }
                        </div>
                        <div className={`available ${currentPlan === 'PRO+' ? 'current' : ''}`}>
                            <div className="limits">
                                <p>{this.context.t('up to 10 users')}</p>
                                <p>{this.context.t('up to 1000 docs/month')}</p>
                            </div>
                            <div className="price">
                                <span>599 zł</span>
                                <span className="small">{this.context.t('/net per month + 49zł for next user')}</span>
                            </div>

                            {
                                currentPlan !== 'PRO+' ? (
                                    <div className="buy-now" onClick={() => this.showConfirmPopup('PRO+')}>{this.context.t('Choose')}</div>
                                ) : (
                                    null
                                )
                            }
                        </div>
                    </div>
                    <div className="footer row">
                        <div className="description"></div>
                        <div className={`available ${currentPlan === 'BASIC' ? 'current' : ''}`}></div>
                        <div className={`available ${currentPlan === 'PRO' ? 'current' : ''}`}></div>
                        <div className={`available ${currentPlan === 'PRO+' ? 'current' : ''}`}></div>
                    </div>
                </div>
            </div>
        )
    }
}

ChangeSubscriptionTyprPopup.contextTypes = {
	t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = {
    changeCompanySubscriptionType: CompanyActions.changeCompanySubscriptionType
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeSubscriptionTyprPopup)