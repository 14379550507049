export const AlertConst = {
    ALERT_SUCCESS: 'ALERT_SUCCESS',
    ALERT_DANGER: 'ALERT_DANGER',
    ALERT_WARNING: 'ALERT_WARNING',
    ALERT_INFO: 'ALERT_INFO',
    ALERT_PRIMARY: 'ALERT_PRIMARY',
    ALERT_CLEAR: 'ALERT_CLEAR',
    SET_ERROR: 'SET_ERROR',
    REMOVE_ERROR: 'REMOVE_ERROR',
}

const initialState = {
    alerts: [],
    error: null
}

export default (state = initialState, { type, payload }) => {
  switch (type) {

  case AlertConst.ALERT_SUCCESS:
    state.alerts.push(payload)
    return {...state}

  case AlertConst.ALERT_DANGER:
    state.alerts.push(payload)
    return {...state}

  case AlertConst.ALERT_WARNING:
    state.alerts.push(payload)
    return {...state}

  case AlertConst.ALERT_INFO:
    state.alerts.push(payload)
    return {...state}

  case AlertConst.ALERT_PRIMARY:
    state.alerts.push(payload)
    return {...state}

  case AlertConst.ALERT_CLEAR:
    state.alerts = state.alerts.filter(alert => alert.id !== payload.index)
    return {...state}

  case AlertConst.SET_ERROR:
    return {
      ...state,
      error: payload
    }

  case AlertConst.REMOVE_ERROR:
    return {
      ...state,
      error: null
    }

  default:
    return state
  }
}
