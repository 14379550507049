import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import TextareaAutosize from 'react-textarea-autosize'

import LIST from '../../../helpers/DevCosts/devExTypes.json'

import '../../../css/OcrPage.css'

export class DevExType extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showList: false,
            typesList: [],
        }

        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        const { devExCode } = this.props
        var codeObject = LIST.find(c => c.code === devExCode)
        if (codeObject) {
            this.setState({
                typesList: codeObject.types
            })
        }
        document.addEventListener("mousedown", this.handleClick);
        window.addEventListener("keydown", this.escFunction, false)
    }

    componentDidUpdate(prevProps) {
        const { devExCode } = this.props
        if (devExCode !== prevProps.devExCode) {
            var codeObject = LIST.find(c => c.code === devExCode)
            if (codeObject) {
                this.setState({
                    typesList: codeObject.types
                })
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
        window.removeEventListener("keydown", this.escFunction, false)
    }

    escFunction = e => {
        if (e.keyCode === 27) {
            this.handleClick(e)
        }
    }

    handleClick = (event) => {
        if (event.keyCode === 27 || (this.wrapperRef && !this.wrapperRef.current.contains(event.target))) {
            this.setState({
                showList: false
            })
        } else {
        }
    }

    toggleList = show => {
        const { editMode } = this.props
        if (editMode) {
            this.setState({
                showList: show
            })
        }
    }

    selectOption = code => {
        this.props.select(code)
        this.setState({
            showList: false
        })
    }

    onInputChange = e => {
        e.preventDefault()
        const { name, value } = e.target

        this.setState({ [name]: value })
    }

    render() {
        const { selectedOption, editMode, devExCode } = this.props
        const { filter, showList, typesList } = this.state
        return (
            <div className={`doc-prop dev-ex-type ${showList ? 'list-cative' : ''}`} id="project-code-container" ref={this.wrapperRef}>
                {
                    showList ? (
                        <div className="drop-down-list">
                            <div className="header">
                                {this.context.t('DevEx Type')}:
                            </div>
                            {
                                !devExCode ? (
                                    <h6>
                                        {this.context.t('Select DevEx code first')}
                                    </h6>
                                ) : (
                                    <SimpleBar style={{ height: 'auto', maxHeight: '300px', width: '100%' }}>
                                        <ul>
                                            {
                                                typesList.map((t, i) => {
                                                    return (
                                                        <li className={`tag-item ${selectedOption === t ? 'selected' : ''}`} onClick={() => this.selectOption(t)} key={`project-code-${i}`}>{`${t}`}</li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </SimpleBar>
                                )
                            }
                        </div>
                    ) : (
                        null
                    )
                }
                <label>{this.context.t('DevEx Type')}</label>
                <TextareaAutosize
                    className={`${!editMode ? 'read-only' : ''}`}
                    readOnly={true}
                    value={selectedOption}
                    name='devex-type-2'
                    minRows={1}
                    maxRows={10}
                    onFocus={() => this.toggleList(true)}
                />
                {
                    devExCode ? (
                        <div className={`list-icon ${showList ? 'extended' : ''}`} onClick={() => this.toggleList(!showList)}></div>
                    ) : null
                }
            </div>
        )
    }
}

DevExType.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(DevExType)