import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import currencyList from '../../helpers/currencyList.json'

export class SelectedExpensesSummaryRowWithAccounts extends Component {
    constructor(props) {
        super(props)
        this.state = {
            accountListIndex: -1
        }

        this.containerRef = React.createRef()
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
    }

    handleClick = (event) => {
        if (this.containerRef && !this.containerRef.current.contains(event.target)) {
            this.setState({
                accountListIndex: -1
            })
        } else {
            const { accountListIndex } = this.state
            if (accountListIndex > -1 && !this.hasAncestor(event.target, 'paymentAccount')) {
                this.setState({
                    accountListIndex: -1
                })
            }
        }
    }

    hasAncestor = (element, id) => {
        while (element) {
            if (element.id && element.id.includes(id)) return true

            element = element.parentNode
        }
        return false
    }

    toggleAccountsList = index => {
        const { accountListIndex } = this.state

        if (accountListIndex === index) {
            this.setState({
                accountListIndex: -1
            })
        } else {
            this.setState({
                accountListIndex: index
            })
        }
    }

    formatPrice = price => {
        if (price || price === 0) {
            price = price.toFixed(2)
            if (price.indexOf('.') > -1) {
                var parts = price.split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                price = parts.join('.')
            } else {
                price = price.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }

            return price
        } else {
            return ''
        }
    }

    render() {
        const { accountListIndex } = this.state
        const { selectedDocsCount, selectedDocsSums, availableAccounts, selectedAccounts, advancePaymentReceived } = this.props
        return (
            <div className={`summary-row accounts ${selectedDocsCount > 0 ? 'show' : ''} expenses`} ref={this.containerRef}>
                {
                    currencyList.map((c, index) => {
                        if (selectedDocsSums[c]) {
                            return (
                                <div className="group" style={{ 'z-index': `${index}` }}>
                                    <div className="label">
                                        {this.context.t(`Total {n}`, { n: c })}
                                    </div>
                                    <div className="value">
                                        {this.formatPrice(selectedDocsSums[c])}
                                    </div>

                                    <div id={`paymentAccount${index}`} className={`payment-account ${accountListIndex === index ? 'extended' : ''}`} onClick={() => this.toggleAccountsList(index)}>
                                        <div className="account-label">
                                            {
                                                selectedAccounts[c] && selectedAccounts[c].id ? (
                                                    `${this.context.t('Payment account')}: (${selectedAccounts[c].currency})`
                                                ) : (
                                                    this.context.t('Payment account')
                                                )
                                            }
                                        </div>
                                        <div className="current-selected">
                                            {
                                                selectedAccounts[c] && selectedAccounts[c].id ? (
                                                    selectedAccounts[c].account_number
                                                ) : ''
                                            }
                                        </div>
                                        <div className={`extend-icon ${accountListIndex === index ? 'extended' : ''}`}></div>

                                        {
                                            accountListIndex === index ? (
                                                <div className="accounts-list">
                                                    <ul>
                                                        {
                                                            availableAccounts.map(bankAccount => {
                                                                return (
                                                                    <li onClick={() => this.props.selectAccount(c, bankAccount)}>
                                                                        <span className="account-name">
                                                                            {`${bankAccount.currency}`}
                                                                        </span>
                                                                        <span className="account-number">{bankAccount.account_number}</span>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            ) : null
                                        }
                                    </div>

                                    <div className="advande">
                                        <input
                                            type="text"
                                            id={`advance-${c}`}
                                            name={`advance-${c}`}
                                            onChange={e => this.props.setAdvancePaymentReceived(c, e.target.value)}
                                            value={advancePaymentReceived[c]}
                                            onFocus={() => {
                                                if (advancePaymentReceived[c] === '0.00') this.props.setAdvancePaymentReceived(c, '')
                                            }}
                                            onBlur={() => {
                                                if (advancePaymentReceived[c] === '') this.props.setAdvancePaymentReceived(c, '0.00')
                                            }}
                                        />
                                        <label htmlFor={`advance-${c}`}>{this.context.t('Advance payment received')}</label>
                                    </div>
                                </div>
                            )
                        } else {
                            return null
                        }
                    })
                }
            </div>
        )
    }
}

SelectedExpensesSummaryRowWithAccounts.contextTypes = {
    t: PropTypes.func
}


const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectedExpensesSummaryRowWithAccounts)
