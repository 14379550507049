import React from 'react';
import defaultProgilePic from '../../img/default-user-photo.svg'
import pdfIconImage from '../../img/pdf-icon.svg'
import imgIconImage from '../../img/img-icon.svg'

const getFieldStyle = (type) => {
    const style = {
      border: '1px solid $color-transparent',
      boxSizing: 'border-box',
      width: '60px',
      height: '60px',
      padding: '5px',
      borderRadius: '2px',
      webkitBoxShadow: '0px 3px 6px 0px rgba(19,37,65,0.1)',
      mozBoxShadow: '0px 3px 6px 0px rgba(19,37,65,0.1)',
      boxShadow: '0px 3px 6px 0px rgba(19,37,65,0.1)',

      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      color:'#2B2D31',
      fontSize: '11px',
      fontWeight: '600',
    };

    const pdfIcon = {
      height: '40px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: '36px 36px',
      backgroundImage: `url(${pdfIconImage})`,
    }

    const imgIcon = {
      height: '40px',
      width: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: '36px 36px',
      backgroundImage: `url(${imgIconImage})`,
    }

    if(type === 'MAIN') {
      return style;
    } else if(type.includes('.pdf')) {
      return pdfIcon
    } else if(type.includes('.jpeg') || type.includes('.jpg') || type.includes('.pdf')) {
      return imgIcon
    }
};

const DragPreview = ({ file }) => {
  if(file) {
    return (
      <div style={getFieldStyle('MAIN')} key={file.ftvId}>
        <div style={getFieldStyle(file.name)}></div>
        {file.name}
      </div>
    );
  } else {
    return (<div></div>)
  }
  
};

export default DragPreview;

  