import { AlertConst } from "../reducers/AlertReducer"
import { makeScreenshot } from '../helpers/screenshots'

let id = 0
let defaultDelay = 5000;

export const AlertActions = {
    success,
    danger,
    warning,
    info,
    primary,
    clear,
    setError,
    removeError
}

function success(message, delay = defaultDelay) {
    return {
        type: AlertConst.ALERT_SUCCESS,
        payload: {
            type: 'success',
            message: message,
            delay: delay,
            id: id++
        }
    }
}

function danger(message, delay = defaultDelay) {
    return {
        type: AlertConst.ALERT_DANGER,
        payload: {
            type: 'danger',
            message: message,
            delay: delay,
            id: id++
        }
    }
}

function warning(message, delay = defaultDelay, docNumbers) {
    if(docNumbers) {
        return {
            type: AlertConst.ALERT_WARNING,
            payload: {
                type: 'warning',
                message: message,
                docNumbers: docNumbers,
                delay: delay,
                id: id++
            }
        }
    } else {
        return {
            type: AlertConst.ALERT_WARNING,
            payload: {
                type: 'warning',
                message: message,
                delay: delay,
                id: id++
            }
        }
    }
}

function info(message, delay = defaultDelay, docNumbers) {
    return {
        type: AlertConst.ALERT_INFO,
        payload: {
            type: 'info',
            message: message,
            docNumbers: docNumbers,
            delay: delay,
            id: id++
        }
    }
}

function primary(message, delay = defaultDelay) {
    return {
        type: AlertConst.ALERT_PRIMARY,
        payload: {
            type: 'primary',
            message: message,
            delay: delay,
            id: id++
        }
    }
}

function clear(index) {
    return {
        type: AlertConst.ALERT_CLEAR,
        payload: {
            index: index
        }
    }
}

function setError(error, path) {
    return (dispatch, getState) => {
        if(error.networkError){
            return;
        }

        const isErrorsBlocked = localStorage.getItem('edBlockErrorsReport');
        if(isErrorsBlocked) return;

        const state = getState();
        const errorExists = state.Alert.error;
        if(errorExists) return;

        const user = state.User.user;
        if(!user) return;

        error.path = path;

        makeScreenshot('body').then(img => {
            dispatch({
                type: AlertConst.SET_ERROR,
                payload: { ...error, img },
            })
        })
    }
}

function removeError() {
    return {
        type: AlertConst.REMOVE_ERROR,
    }
}