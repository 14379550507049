import moment from "moment"

import arrayObjectIndexOf from "../helpers/indexOfObject"

export const MessageConst = {
    ADD_MESSAGE: 'ADD_MESSAGE',
    MARK_MESSAGE_READ: 'MARK_MESSAGE_READ',
    MARK_ALL_AS_READ: 'MARK_ALL_AS_READ',
    WAITING_FOR_OLD_NOTIFICATIONS: 'WAITING_FOR_OLD_NOTIFICATIONS',
    GET_OLD_MESSAGES: 'GET_OLD_MESSAGES',
    DELETE_ALL_MESSAGES: 'DELETE_ALL_MESSAGES',
    WAITING_FOR_UPCOMING_PAYMENTS: 'WAITING_FOR_UPCOMING_PAYMENTS',
    GET_UPCOMING_PAYMENTS: 'GET_UPCOMING_PAYMENTS',
    DELETE_PROJECT: 'DELETE_PROJECT-MESSAGES'
}

const initialState = {
    messages: [],
    unreadCount: 0,
    showAll: false,
    skipForOlderNotifications: 0,
    waitingForUpcomingPayments: false,
    myUpcomingPayments: []
}

export default (state = initialState, action) => {
    var prevMessages
    switch (action.type) {
        case MessageConst.ADD_MESSAGE:
            var currentMessages = state.messages
            var previousMEssageIndex = arrayObjectIndexOf(currentMessages, action.message.fileId, 'fileId')
            if (previousMEssageIndex > -1) {
                currentMessages[previousMEssageIndex].latest = false
            }
            currentMessages.unshift(action.message)

            return { ...state, ...{ messages: currentMessages, unreadCount: state.unreadCount + 1 } }

        case MessageConst.MARK_MESSAGE_READ:
            prevMessages = [...state.messages]
            let { unreadCount } = state
            var messageIndex = arrayObjectIndexOf(prevMessages, action.id, "id");

            if (messageIndex > -1) {
                if (!prevMessages[messageIndex].seen) {
                    if (unreadCount > 0) unreadCount = unreadCount - 1
                    prevMessages[messageIndex].seen = true
                }
            }

            return { ...state, ...{ messages: prevMessages, unreadCount: unreadCount } }

        case MessageConst.MARK_ALL_AS_READ:
            prevMessages = [...state.messages]
            var allRead = prevMessages.map(m => {
                m.seen = true
                return m
            })
            console.log(allRead)
            return { ...state, ...{ messages: allRead, unreadCount: 0 } }

        case MessageConst.WAITING_FOR_OLD_NOTIFICATIONS:
            return { ...state, ...{ waitingForOldNotifications: action.loading, hasOlderNotifications: false } }

        case MessageConst.GET_OLD_MESSAGES:
            var newMessages = action.messages
            newMessages.forEach(m => {
                m.time = moment(m.time)
            })

            var oldMessages = state.messages ? [...state.messages] : []
            return { ...state, ...{ messages: [].concat(oldMessages, newMessages), unreadCount: 0, skipForOlderNotifications: action.skip + 50, hasOlderNotifications: action.hasOlder } }

        case MessageConst.DELETE_ALL_MESSAGES:
            return { ...state, ...{ messages: [], unreadCount: 0 } }

        case MessageConst.WAITING_FOR_UPCOMING_PAYMENTS:
            return { ...state, ...{ waitingForUpcomingPayments: action.loading } }

        case MessageConst.GET_UPCOMING_PAYMENTS:
            return { ...state, ...{ myUpcomingPayments: action.upcomingPayments } }

        case MessageConst.DELETE_PROJECT:
            var messages = [...state.messages]

            messages = messages.filter(m => m.projectId !== action.projectId)

            return { ...state, ...{ messages: messages } }

        default:
            return state
    }
}