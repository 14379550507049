import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import '../css/new-style.css'
import '../css/TagsList.css'

export class TagsList extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    tagClick = (t) => {
        var tags = this.props.selectedTags

        if(tags && tags.includes(t)) {
            tags = tags.replace(t, '')
            tags = tags.replace(' , ', ' ')
            tags = tags.replace(',,', ',')
        } else {
            if(!tags) {
                tags = `${t}`
            } else if(tags.length > 0) {
                tags += `, ${t}`
            } else {
                tags += `${t}`
            }
        }

        tags = tags.trim()

        if(tags.startsWith(',')) {
            tags = tags.substring(1)
        }

        if(tags.endsWith(',')) {
            tags = tags.slice(0, -1)
        }

        tags = tags.trim()

        if(this.props.docType) {
            this.props.returnTags(tags, this.props.docId, this.props.docType)
        } else {
            this.props.returnTags(tags)
        }
    }

    render() {
        const {companyTags, selectedTags, maxHeight } = this.props
        return (
            <div className={`tags-list-wrapper ${this.props.flipUp ? 'flip-up' : ''}`}>
                <div className="header">
                    {this.context.t('Tags used in this company:')}
                </div>
                <SimpleBar style={{ height: 'auto', maxHeight: `${maxHeight || 300}px`, width: '100%', maxWidth: '100%', padding: '0'}}>
                    <ul>
                        {
                            companyTags.map(t => {
                                return(
                                    <li className={`tag-item ${selectedTags && selectedTags.includes(t) ? 'selected' : ''}`} onClick={() => this.tagClick(t)}>{t}</li>
                                )
                            })
                        }
                    </ul>
                </SimpleBar>
            </div>
        )
    }
}

TagsList.contextTypes = {
	t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(TagsList)