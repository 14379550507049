import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'

import currencyList from '../../helpers/currencyList.json'
import arrayObjectIndexOf from '../../helpers/indexOfObject.js'
import availableQuickActions from '../../helpers/availableQuickActions'
import { emailRegex } from '../../helpers/regexList'

import QuickActionPopupHeader from './QuickActionPopupHeader'
import DocsSearchModal from './DocsSearchModal'
import SelectedDocsSummaryRowWithAccounts from './SelectedDocsSummaryRowWithAccounts'
import AllDocsTableVirtualized from './AllDocsTableVirtualized'
import AllDocsTable from './AllDocsTable'
import FilesPreviewInReportsForAllTypes from '../ReportsPageComponents/FilesPreviewInReportsForAllTypes'
import AnimatedCheckmark from '../AnimatedCheckmark'

import { ReportsActions } from '../../actions/ReportsActions'
import { FileActions } from '../../actions/FileActions'
import { TransfersActions } from '../../actions/TransfersActions'
import { UserActions } from '../../actions/UserActions'
import { CompanyActions } from '../../actions/CompanyActions'
import { AlertActions } from '../../actions/AlertActions'

import '../../css/QuickActions.css'
import '../../css/Report.css'

export class ExportBankTransfers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tableHeight: (window.innerHeight * 0.95) - 450,
            showVirtualizedTable: false,
            allDocsSelected: false,
            docsSelectedForReport: [],
            roleInSelectedProject: '',
            previewFile: {},

            highlightTable: false,
            highlightOptions: false,

            sendEmail: false,
            emailAddress: '',
            sendLink: false,


            searchAllNotExported: true,
            dateFilterText: '',
            dateFilter: 'importDate',
            period: ''
        }
        this.tableRef = React.createRef()
    }

    componentDidMount() {
        const { currentProject, user } = this.props
        if (user.new_easydocs_version && currentProject?.id) {
            var defaultDateFrom = moment().subtract(1, 'month').startOf('month')
            var defaultDateTo = moment()
            this.props.getDocuments(defaultDateFrom, defaultDateTo, currentProject.id, 'Costs', 'importDate', null, { allNotExportedDocs: true })
        }

        var lastSelectedOptions = localStorage.getItem('quickActionDocsExportChoices')
        if (lastSelectedOptions) {
            lastSelectedOptions = JSON.parse(lastSelectedOptions)
            this.setState({
                sendEmail: lastSelectedOptions.sendEmail,
                emailAddress: lastSelectedOptions.emailAddress,
                sendLink: lastSelectedOptions.sendLink,
            })
        }
        window.addEventListener("keydown", this.keyDownHandler, false)
    }

    componentDidUpdate(prevProps) {
        const { currentProject, user, docsDownloadedForRaport } = this.props

        if (prevProps.docsDownloadedForRaport && prevProps.docsDownloadedForRaport.length === 0 && docsDownloadedForRaport && docsDownloadedForRaport.length > 0) {
            this.setState({
                docsSelectedForReport: [],
                previewFile: {}
            }, () => {
                this.selectAllOnLoad()
                this.setTableHeight()
            })
        }

        if (user.new_easydocs_version && currentProject?.id && currentProject?.id !== prevProps.currentProject?.id) {
            var defaultDateFrom = moment().subtract(1, 'month').startOf('month')
            var defaultDateTo = moment()
            this.props.getDocuments(defaultDateFrom, defaultDateTo, currentProject.id, 'Costs', 'importDate', null, { allNotExportedDocs: true })
        }
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.keyDownHandler, false)
    }

    keyDownHandler = e => {
        if (e.keyCode === 27) {
            //ESC
            // const { previewFile } = this.state
            // if (!previewFile || previewFile === {}) {
            //     this.props.close()
            // }
            //  TODO: needs to take filters modal into account
        } else if (e.keyCode === 38) {
            //UP ARROW
            this.goToPrevProject()
        } else if (e.keyCode === 40) {
            //DOWN ARROW
            this.goToNextProject()
        }
    }

    goToPrevProject = () => {
        const { groupedProjects, currentProject } = this.props
        var companyIndex = -1
        for (var i = 0; i < groupedProjects.length; i++) {
            if (groupedProjects[i] && groupedProjects[i].company) {
                if (groupedProjects[i].company.id === currentProject.company_id) {
                    companyIndex = i
                    break
                }
            }
        }

        console.log(companyIndex)
        if (companyIndex > -1) {
            var currentProjectIndex = arrayObjectIndexOf(groupedProjects[companyIndex].projects, currentProject.id, 'id')
            if (currentProjectIndex > -1) {
                if (currentProjectIndex > 0) {
                    //same company prev project
                    this.props.switchDashboardViewModel(groupedProjects[companyIndex].projects[currentProjectIndex - 1].id)
                    this.loadNewDocsAfterProjectSwitch(groupedProjects[companyIndex].projects[currentProjectIndex - 1].id)
                } else {
                    //prev company last project
                    if (companyIndex > 0 && groupedProjects[companyIndex - 1].projects) {
                        var prevCompanyProjectsCount = groupedProjects[companyIndex - 1].projects.length
                        this.props.switchDashboardViewModel(groupedProjects[companyIndex - 1].projects[prevCompanyProjectsCount - 1].id)
                        this.loadNewDocsAfterProjectSwitch(groupedProjects[companyIndex - 1].projects[prevCompanyProjectsCount - 1].id)

                        var { userCompanies } = this.props
                        var newCompanyIndex = arrayObjectIndexOf(userCompanies, groupedProjects[companyIndex - 1].company.id, 'company_id')
                        if (newCompanyIndex > -1) {
                            this.props.setCurrentCompany(userCompanies[newCompanyIndex])
                        }
                    }
                }
            }
        }
    }

    goToNextProject = () => {
        const { groupedProjects, currentProject } = this.props
        var companyIndex = -1
        for (var i = 0; i < groupedProjects.length; i++) {
            if (groupedProjects[i] && groupedProjects[i].company) {
                if (groupedProjects[i].company.id === currentProject.company_id) {
                    companyIndex = i
                    break
                }
            }
        }

        if (companyIndex > -1) {
            var currentProjectIndex = arrayObjectIndexOf(groupedProjects[companyIndex].projects, currentProject.id, 'id')
            if (currentProjectIndex > -1) {
                if (currentProjectIndex < groupedProjects[companyIndex].projects.length - 1) {
                    //same company next project
                    this.props.switchDashboardViewModel(groupedProjects[companyIndex].projects[currentProjectIndex + 1].id)
                    this.loadNewDocsAfterProjectSwitch(groupedProjects[companyIndex].projects[currentProjectIndex + 1].id)
                } else {
                    //next company first project
                    if (companyIndex < groupedProjects.length - 1 && groupedProjects[companyIndex + 1].projects[0]) {
                        this.props.switchDashboardViewModel(groupedProjects[companyIndex + 1].projects[0].id)
                        this.loadNewDocsAfterProjectSwitch(groupedProjects[companyIndex + 1].projects[0].id)

                        var { userCompanies } = this.props
                        var newCompanyIndex = arrayObjectIndexOf(userCompanies, groupedProjects[companyIndex + 1].company.id, 'company_id')
                        if (newCompanyIndex > -1) {
                            this.props.setCurrentCompany(userCompanies[newCompanyIndex])
                        }
                    }
                }
            }
        }
    }

    clearStateOnDocsSearch = () => {
        this.setState({
            docsSelectedForReport: [],
            previewFile: {}
        })
    }

    setTableHeight = () => {
        const { docsDownloadedForRaport } = this.props
        var heightToFitAllDocs = docsDownloadedForRaport.length * 40 + 40

        if (heightToFitAllDocs < (window.innerHeight * 0.95) - 450) {
            this.setState({
                tableHeight: heightToFitAllDocs,
                showVirtualizedTable: false
            })
        } else {
            this.setState({
                tableHeight: (window.innerHeight * 0.95) - 450,
                showVirtualizedTable: true
            })
        }
    }

    selectAllOnLoad = () => {
        const { docsDownloadedForRaport } = this.props
        var currentSelectedDocs = docsDownloadedForRaport.map(doc => {
            return { id: doc.id, status: doc.status, fileId: doc.id, docType: doc.docType, currency: doc.currency }
        })
        this.setState({
            docsSelectedForReport: currentSelectedDocs,
            allDocsSelected: true
        })
    }

    selectAll = () => {
        const { docsDownloadedForRaport } = this.props
        var currentSelectedDocs = [...this.state.docsSelectedForReport]
        var oneSelected = false

        docsDownloadedForRaport.forEach(doc => {
            if (!currentSelectedDocs.find(d => d.id === doc.id)) {
                currentSelectedDocs.push({ id: doc.id, status: doc.status, fileId: doc.id, docType: doc.docType, currency: doc.currency })
                oneSelected = true
            }
        })

        this.setState({
            docsSelectedForReport: oneSelected ? currentSelectedDocs : [],
            allDocsSelected: oneSelected
        })
    }

    addDocToSelected = (e, doc) => {
        e.preventDefault()
        var currentSelected = this.state.docsSelectedForReport

        if (currentSelected.find(e => e.id === doc.id)) {


            this.setState({
                docsSelectedForReport: currentSelected.filter(e => {
                    if (e.id === doc.id && e.docType === doc.docType) return false
                    return true
                }),
                allDocsSelected: false
            })
        } else {
            currentSelected.push({ id: doc.id, status: doc.status, fileId: doc.id, docType: doc.docType, currency: doc.currency, price: doc.price })

            this.setState({
                docsSelectedForReport: currentSelected
            })
        }
    }

    downloadReport = (e, projectId, reportName) => {
        e.preventDefault()

        this.props.downloadReport(projectId, reportName)
    }

    togglePaymentForDoc = (e, doc) => {
        e.preventDefault()
        const { user } = this.props
        if (!doc.alreadyPaid) {
            if (doc.docType === 'INCOME') {
                this.props.tooglePaymentForIncomeDoc(doc.docId, doc.fileId, doc.projectId, 'transfer', doc.companyId, doc.month)
            } else if (doc.docType === 'PAYMENT_ORDER') {
                this.props.tooglePaymentForTransfer(doc.id, doc.projectId, user.id, true, 'transfer')
            } else {
                this.props.tooglePaymentForDoc(doc.docId, doc.fileId, doc.projectId, 'transfer')
            }
        } else {
            if (doc.alreadyInBankTransferFile && doc.bankTransferFileName) {
                this.props.alertWarn("This document is included in bank transfer file. To prevent mistakes you can't unmark it", 8000)
            } else if (doc.alreadyInReport && doc.reportName) {
                this.props.alertWarn("This document is included in report file. To prevent mistakes you can't unmark it", 8000)
            } else {
                if (doc.docType === 'INCOME') {
                    this.props.tooglePaymentForIncomeDoc(doc.docId, doc.fileId, doc.projectId, null, doc.companyId, doc.month)
                } else if (doc.docType === 'PAYMENT_ORDER') {
                    this.props.tooglePaymentForTransfer(doc.id, doc.projectId, user.id, false, '')
                } else {
                    this.props.tooglePaymentForDoc(doc.docId, doc.fileId, doc.projectId, null)
                }
            }

        }
    }

    toggleAccountedStatus = (id, fileId, projectId, docType, accounted) => {
        if (docType === 'PAYMENT_ORDER') {
            this.props.toogleAccountmentStatusForTransfer(id, projectId, 0, accounted);
        } else if (docType === 'INCOME') {
            this.props.toogleAccountmentStatusForIncomeDoc(id, fileId, projectId, accounted);
        } else {
            this.props.toogleAccountmentStatusForDoc(id, fileId, projectId, accounted);
        }
    }

    fileIconClick = (e, projectId, filePath, id, docType, fileId, alreadyPaid) => {
        this.props.toogleFilePreviewInActions(true)
        this.setState({
            previewFile: {
                projectId: projectId,
                file: filePath,
                id: id,
                docType: docType
            }
        })
        if (docType === 'PAYMENT_ORDER') {
            const { transfers } = this.props
            var projectTransfers = transfers.find(e => e.project === projectId)
            if (projectTransfers && projectTransfers.transfers) {
                var transfer = projectTransfers.transfers.find(e => e.id === id)
                if (transfer) {
                    this.props.openTransferPreviewWindow(transfer)
                } else {
                    this.props.getPaymentOrders(projectId, -1, (loadedTransfers) => {
                        var transfer = loadedTransfers.find(e => e.id === id)
                        if (transfer) {
                            this.props.openTransferPreviewWindow(transfer)
                        } else {
                            this.props.alertWarn(this.context.t('Payment order not found'))
                        }
                    })
                }
            } else {
                this.props.getPaymentOrders(projectId, -1, (loadedTransfers) => {
                    var transfer = loadedTransfers.find(e => e.id === id)
                    if (transfer) {
                        this.props.openTransferPreviewWindow(transfer)
                    } else {
                        this.props.alertWarn(this.context.t('Payment order not found'))
                    }
                })
            }
        } else {
            this.props.getFileBody(parseInt(projectId), filePath, fileId, alreadyPaid ? 'DONE' : 'IN_PROGRESS', alreadyPaid ? 4 : 3)
        }
    }

    setCurrentDocPreview = (doc) => {
        this.setState({
            previewFile: doc
        })
    }

    hideFilePreview = () => {
        this.props.toogleFilePreviewInActions(false)
        this.setState({
            previewFile: {}
        })
    }

    checkIfDocMatchesFilterOptions = () => {
        return true
    }

    execute = () => {
        const { docsSelectedForReport, sendEmail, emailAddress, sendLink } = this.state
        const { currentProject } = this.props
        var valid = true

        if (docsSelectedForReport.length === 0) {
            valid = false
            this.props.alertWarn(this.context.t('You must select at least one document'))
        } else {
            if (sendEmail) {
                if (!emailAddress) {
                    valid = false
                    this.props.alertWarn(this.context.t('E-mail address is required'))
                } else if (!emailRegex.test(emailAddress)) {
                    valid = false
                    this.props.alertWarn(this.context.t('E-mail address is not valid'))
                }
            }
        }

        if (valid) {
            var filesToDownload = []
            var paymentOrdersToDownload = []
            docsSelectedForReport.map(doc => {
                if (doc.docType !== 'PAYMENT_ORDER') {
                    filesToDownload.push(doc.fileId)
                } else {
                    paymentOrdersToDownload.push(doc.fileId)
                }
            })

            var projectId, projectCode
            projectId = currentProject ? currentProject.id : null
            projectCode = currentProject ? currentProject.code : null
            this.props.exportDocsToAccounting(projectId, projectCode, filesToDownload, paymentOrdersToDownload, sendEmail, emailAddress, sendLink, () => {
                this.setState({
                    docsSelectedForReport: []
                })
            })

            var lastSelectedOptions = localStorage.getItem('quickActionDocsExportChoices')
            if (lastSelectedOptions) lastSelectedOptions = JSON.parse(lastSelectedOptions)
            else lastSelectedOptions = {}

            lastSelectedOptions.emailAddress = emailAddress
            localStorage.setItem('quickActionDocsExportChoices', JSON.stringify(lastSelectedOptions))
        }
    }

    closeAction = () => {
        const { finishedAllActions } = this.state

        if (finishedAllActions) {
            this.setState({
                showActionsLoadingWindow: false,
                finishedAllActions: false,
            })
        }
    }

    handleMouseEnterStep = step => {
        switch (step) {
            case 1:
                this.setState({
                    highlightTable: true
                })
                break
            case 2:
                this.setState({
                    highlightOptions: true
                })
                break
        }
    }

    handleMouseLeaveStep = step => {
        switch (step) {
            case 1:
                this.setState({
                    highlightTable: false
                })
                break
            case 2:
                this.setState({
                    highlightOptions: false
                })
                break
        }
    }

    getFiltersFromSearchForm = (data) => {
        this.setState({
            highlightTable: false,
            searchAllNotExported: data.searchAllNotExported,
            dateFilterText: data.dateFilterText,
            period: data.periodText,
            dateFilter: data.dateFilter,
            startDate: data.startDate,
            endDate: data.endDate
        })
    }

    loadNewDocsAfterProjectSwitch = projectId => {
        const { startDate, endDate, dateFilter, searchAllNotExported } = this.state
        var additionalFilteringCriteria = {
            allNotExportedDocs: searchAllNotExported,
            notExportedDocs: !searchAllNotExported
        }
        this.props.getDocuments(startDate, endDate, projectId, 'Costs', dateFilter, null, additionalFilteringCriteria)
    }

    onEmailAdresInputChange = e => {
        e.preventDefault()

        var { value } = e.target
        value = value.toLowerCase()

        this.setState({ emailAddress: value })
    }

    toggleSendEmail = send => {
        this.setState({
            sendEmail: send
        })

        var lastSelectedOptions = localStorage.getItem('quickActionDocsExportChoices')
        if (lastSelectedOptions) lastSelectedOptions = JSON.parse(lastSelectedOptions)
        else lastSelectedOptions = {}

        lastSelectedOptions.sendEmail = send
        localStorage.setItem('quickActionDocsExportChoices', JSON.stringify(lastSelectedOptions))
    }

    toggleSendLink = send => {
        this.setState({
            sendLink: send
        })

        var lastSelectedOptions = localStorage.getItem('quickActionDocsExportChoices')
        if (lastSelectedOptions) lastSelectedOptions = JSON.parse(lastSelectedOptions)
        else lastSelectedOptions = {}

        lastSelectedOptions.sendLink = send
        localStorage.setItem('quickActionDocsExportChoices', JSON.stringify(lastSelectedOptions))
    }

    render() {
        const {
            highlightTable, highlightOptions,
            searchAllNotExported, dateFilterText, period,
            tableHeight, showVirtualizedTable, allDocsSelected, docsSelectedForReport, previewFile,
            roleInSelectedProject,
            sendEmail, emailAddress, sendLink,
            showActionsLoadingWindow, finishedAllActions
        } = this.state
        const { docsDownloadedForRaport, sendGetDocumentsRequest, user, tabIsActive } = this.props

        if (tabIsActive || !user.new_easydocs_version) {
            return (
                <div className={`quic-actions-popup reports ${showActionsLoadingWindow ? 'waiting-for-actions' : ''} ${user.new_easydocs_version ? 'new-easydocs-version' : ''}`}>

                    {
                        previewFile && previewFile.id ? (
                            <FilesPreviewInReportsForAllTypes
                                docsList={docsDownloadedForRaport}
                                location={{
                                    pathname: '/actions/'
                                }}
                                previewFile={previewFile}
                                close={this.hideFilePreview}
                                setCurrentDoc={this.setCurrentDocPreview}
                                checkIfDocMatchesFilterOptions={this.checkIfDocMatchesFilterOptions}
                            />
                        ) : (
                            null
                        )
                    }

                    {
                        showActionsLoadingWindow ? (
                            <div className="actions-loading-window">
                                <div className="actions">
                                    <h4>
                                        {finishedAllActions ? this.context.t('Ready!') : this.context.t('Just a moment...')}
                                    </h4>

                                    <div className={`close-button ${finishedAllActions ? 'active' : ''}`} onClick={() => this.closeAction()}>{this.context.t('Close')}</div>
                                </div>
                            </div>
                        ) : null
                    }

                    <div className={`content`}>
                        <QuickActionPopupHeader
                            title={"Packages for accounting"}
                            close={() => this.props.toggleQuickAction(availableQuickActions.docsExport, false)}
                            getDocs={this.loadNewDocsAfterProjectSwitch}
                        />

                        <div className="action-instructions">
                            <div className="steps-wrapper">
                                <div className="step" onMouseEnter={() => this.handleMouseEnterStep(1)} onMouseLeave={() => this.handleMouseLeaveStep(1)}>
                                    <div className="number">1</div>
                                    <div className="description">{this.context.t('Select the documents you want to export')}</div>
                                    <DocsSearchModal
                                        clearParentStateOnDocsSearch={this.clearStateOnDocsSearch}
                                        selectedType={"Costs"}
                                        searchForNotExported={true}
                                        setFilters={this.getFiltersFromSearchForm}
                                        closeActionOnEsc={() => this.props.toggleQuickAction(availableQuickActions.docsExport, false)}
                                    />
                                </div>
                                <div className="step" onMouseEnter={() => this.handleMouseEnterStep(2)} onMouseLeave={() => this.handleMouseLeaveStep(2)}>
                                    <div className="number">2</div>
                                    <div className="description">{this.context.t('Select additional options')}</div>
                                </div>
                            </div>
                        </div>

                        <div className={`hightlight-container ${highlightTable ? 'highlight' : ''}`}>
                            <span className="number">1</span>
                            {
                                sendGetDocumentsRequest && docsDownloadedForRaport.length === 0 ? (
                                    <div className="docs-loading-for-report">
                                        <div className="image"></div>
                                        <div className="text">
                                            <div className="header">
                                                {this.context.t('Loading')}
                                            </div>
                                            <div className="instruction">
                                                {this.context.t('Please wait a second while we are retriving, loading and calculating your data')}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    docsDownloadedForRaport.length > 0 ? (
                                        showVirtualizedTable ? (
                                            <AllDocsTableVirtualized
                                                tableHeight={tableHeight}
                                                setTableRef={this.tableRef}
                                                searchField={''}
                                                searchCurrency={'ALL'}
                                                selectOnlyAvailableForReport={false}
                                                selectOnlyAvailableForBankTransferFile={false}
                                                selectOnlyNotPaid={false}
                                                selectOnlyNotAccounted={false}
                                                selectFor={'transfer'}
                                                roleInSelectedProject={roleInSelectedProject}
                                                docsSelectedForReport={[...docsSelectedForReport]}
                                                selectAll={this.selectAll}
                                                allDocsSelected={allDocsSelected}
                                                addDocToSelected={this.addDocToSelected}
                                                fileIconClick={this.fileIconClick}
                                                downloadReport={this.downloadReport}
                                                togglePaymentList={this.togglePaymentForDoc}
                                                toggleAccountedStatus={this.toggleAccountedStatus}
                                            />
                                        ) : (
                                            <AllDocsTable
                                                searchField={''}
                                                searchCurrency={'ALL'}
                                                selectOnlyAvailableForReport={false}
                                                selectOnlyAvailableForBankTransferFile={false}
                                                selectOnlyNotPaid={false}
                                                selectOnlyNotAccounted={false}
                                                selectFor={'transfer'}
                                                roleInSelectedProject={roleInSelectedProject}
                                                docsSelectedForReport={[...docsSelectedForReport]}
                                                selectAll={this.selectAll}
                                                allDocsSelected={allDocsSelected}
                                                addDocToSelected={this.addDocToSelected}
                                                fileIconClick={this.fileIconClick}
                                                downloadReport={this.downloadReport}
                                                togglePaymentList={this.togglePaymentForDoc}
                                                toggleAccountedStatus={this.toggleAccountedStatus}
                                            />
                                        )
                                    ) : (
                                        <div className="no-docs-found">
                                            <div className="image"></div>
                                            <div className="text">
                                                <div className="header">
                                                    {
                                                        searchAllNotExported ? (
                                                            this.context.t('No unpaid documents')
                                                        ) : (
                                                            this.context.t('No unpaid documents in the {d} period {p}', { d: this.context.t(dateFilterText), p: period })
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )
                            }
                        </div>

                        {
                            docsDownloadedForRaport.length > 0 ? (
                                <div className="footer-container export-docs">
                                    <div className={`hightlight-container options ${highlightOptions ? 'highlight' : ''}`}>
                                        <span className="number">2</span>

                                        <div className="options-to-choose">
                                            <div className="additional-actions">
                                                <div className="additional-action">
                                                    <div className={`checkbox ${sendEmail ? 'checked' : ''}`} onClick={() => this.toggleSendEmail(!sendEmail)}></div>
                                                    <span className="label" onClick={() => this.toggleSendEmail(!sendEmail)}>{this.context.t('additionally, send documents by e-mail to accounting')}</span>
                                                </div>

                                                <div className={`form-group ${!sendEmail ? 'inactive' : ''}`}>
                                                    <input
                                                        type="text"
                                                        name="emailAddress"
                                                        className={`${emailAddress ? 'has-content' : ''} `}
                                                        id="emailAddress"
                                                        value={emailAddress}
                                                        onChange={this.onEmailAdresInputChange}
                                                        autoComplete="off"
                                                    />
                                                    <label htmlFor="emailAddress">{this.context.t('Recipient\'s email address')}</label>
                                                </div>

                                                <div className={`package-options ${!sendEmail ? 'inactive' : ''}`}>
                                                    <div className={`option ${!sendLink ? 'selected' : ''}`} onClick={() => this.toggleSendLink(false)}>{this.context.t('send a zip-package')}</div>
                                                    <div className={`option ${sendLink ? 'selected' : ''}`} onClick={() => this.toggleSendLink(true)}>{this.context.t('send zip link')}</div>

                                                    {
                                                        sendLink && sendEmail ? (
                                                            <div className="link-disclaimer">*{this.context.t('link will be active for 7 days')}</div>
                                                        ) : (
                                                            sendEmail ? (
                                                                <div className="link-disclaimer">*{this.context.t('for zip packages larger than 10MB, a link to download the file from the website will be generated automatically')}</div>
                                                            ) : <div className="link-disclaimer"></div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="confirm-button" onClick={() => this.execute()}>{this.context.t("Execute")}</div>
                                    </div>
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            )
        } else {
            return null
        }
    }
}

ExportBankTransfers.contextTypes = {
    t: PropTypes.func
}

const isIterable = object => object != null && typeof object[Symbol.iterator] === 'function'

const mapStateToProps = (state, ownProps) => ({
    user: state.User.user,
    currentProject: state.User.currentProject,
    groupedProjects: state.User.groupedProjects,
    userCompanies: state.Company.companies,
    currentCompany: state.Company.currentCompany,
    docsDownloadedForRaport: isIterable(state.Reports.docsDownloadedForRaport) ? [...state.Reports.docsDownloadedForRaport] : [],
    sendGetDocumentsRequest: state.Reports.sendGetDocumentsRequest,
    transfers: isIterable(state.Transfers.transfers) ? [...state.Transfers.transfers] : [],
})

const mapDispatchToProps = {
    getDocuments: ReportsActions.getDocuments,
    downloadReport: ReportsActions.downloadReport,
    tooglePaymentForDoc: ReportsActions.tooglePaymentForDoc,
    tooglePaymentForTransfer: ReportsActions.tooglePaymentForTransfer,
    tooglePaymentForIncomeDoc: ReportsActions.tooglePaymentForIncomeDoc,
    toogleAccountmentStatusForDoc: ReportsActions.toogleAccountmentStatusForDoc,
    toogleAccountmentStatusForTransfer: ReportsActions.toogleAccountmentStatusForTransfer,
    toogleAccountmentStatusForIncomeDoc: ReportsActions.toogleAccountmentStatusForIncomeDoc,
    exportDocsToAccounting: ReportsActions.exportDocsToAccounting,

    toogleFilePreviewInActions: FileActions.toogleFilePreviewInActions,
    getFileBody: FileActions.getFileData,
    togglePdfPreview: FileActions.togglePdfPreview,
    getPaymentOrders: TransfersActions.getAllMyTransfers,
    openTransferPreviewWindow: TransfersActions.openTransferPreviewWindow,
    getTransfers: TransfersActions.getAllMyTransfers,
    getFiles: FileActions.getFiles,

    switchDashboardViewModel: UserActions.switchDashboardViewModel,
    toggleQuickAction: UserActions.toggleQuickAction,
    setCurrentCompany: CompanyActions.setCurrentCompany,


    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportBankTransfers)