import React from 'react';

const WhatsNewSection = ({ name, list }) => {
    if(list && list.length > 0 && list[0] !== "") {
        return (
            <div className="whats-new__section">
                <div className="whats-new__section-name">{name}</div>
                {
                    list.map((el, index) => {
                        if(el && el.length > 0) {
                            return(<p key={index}>{el}</p>)
                        } else {
                            return(<span className="empty-line"></span>)
                        }
                    })
                }
            </div>
        )
    } else {
        return null
    }
}

export default WhatsNewSection;