import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Digital, Bounce } from 'react-activity'
import 'react-activity/dist/react-activity.css'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import moment from 'moment'
import ReactTooltip from 'react-tooltip'

import TransferPreview from '../TransferPreview'

import { TransfersActions } from '../../actions/TransfersActions'

import arrayObjectIndexOf from "../../helpers/indexOfObject"

import noPaymentOrdersImage from '../../img/no-payment-orders.svg'


export class TransfersManager extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentDir: '',
            filterField: 'name',
            order: 'ASC',
        }
    }

    componentDidMount() {
        const { transfers, project } = this.props
        if (transfers && project.id) {
            const projectIndex = arrayObjectIndexOf(transfers, project.id, "project")
            if (projectIndex === -1) {
                this.getTransfers()
            }
        }
    }

    getTransfers = () => {
        this.props.getTransfers(this.props.project.id, -1)
    }

    switchdirectory = (path, name, transfer) => {
        if (path === name.split('/')[0]) {
            var updatedDir = this.state.currentDir + path + '/'
            this.setState({
                currentDir: updatedDir
            })
        } else {
            if (this.props.transferPreviewData.id !== transfer.id) {
                this.props.openTransferPreviewWindow(transfer)
            } else {
                this.props.openTransferPreviewWindow()
            }
        }
    }

    goToDir = dir => {
        if (dir !== '') {
            let newDir = this.state.currentDir

            var goToIndex = newDir.indexOf(dir)
            newDir = newDir.substring(0, goToIndex + dir.length) + '/'

            this.setState({
                currentDir: newDir
            })
        } else {
            this.setState({
                currentDir: ''
            })
        }

    }

    drawIcon = (path, name) => {
        if (path === name.split('/')[0]) {
            return <div className="icon folder-icon"></div>
        } else {
            return <div className="icon transfer-icon"></div>
        }
    }

    getSortClass = (project, sortField) => {
        const { transfers } = this.props

        const index = arrayObjectIndexOf(transfers, project, "project")

        if (index === -1 || transfers[index].transfers.length === 0) {
            return ''
        } else {
            if (transfers[index].sortField === sortField) {
                if (transfers[index].order === "asc")
                    return "icon-sort down"
                return "icon-sort up"
            }
            return "icon-sort"
        }

    }

    goUpDirr = dir => {
        var dir = this.state.currentDir
        dir = dir.slice(0, -1)
        var lastSlash = dir.lastIndexOf('/')


        var newDir = dir.substr(0, lastSlash + 1)
        this.setState({
            currentDir: newDir
        })
    }

    displayAsFileOrDirecxtory = (filePath, fileName, counterpartyName, currentDir) => {
        var subPath = filePath.replace(currentDir, '').split('/')[0]
        if (subPath !== fileName) return subPath
        else return counterpartyName || fileName
    }

    formatAmountToPay = (text) => {
        if (text) {
            var amount = parseFloat(text).toFixed(2)
            if (amount.indexOf('.') > -1) {
                var parts = amount.split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                amount = parts.join('.')
            } else {
                amount = amount.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }

            return amount
        } else return ''
    }

    blobToBase64(blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    uploadWaExcelHandler = async (event) => {
        const { project } = this.props
        const currentFile = event.target.files[0];
        if (currentFile) {
            const myResult = {};
            myResult.name = currentFile.name;
            myResult.size = currentFile.size;
            myResult.type = currentFile.type;
            myResult.base64 = await this.blobToBase64(currentFile);

            this.props.uploadWaPaymentOrderExcel(myResult.base64, myResult.name, project.id, (data) => {
                console.log(data)
            })
        }
    };

    render() {
        const { user, transfers, transferPreviewOpen, project, simpleBarHeight, noContentImageHeight, actionsPreviewIsOpen } = this.props
        const { currentDir } = this.state

        const projectIndex = arrayObjectIndexOf(transfers, project.id, "project")
        var directories = []

        if (transfers && projectIndex > -1) {
            return (
                <div className={`project-payment-orders ${transfers[projectIndex].extended ? 'extended' : ''}`}>
                    {
                        transferPreviewOpen && !actionsPreviewIsOpen ? (
                            <TransferPreview location={this.props.location} />
                        ) : (
                            null
                        )
                    }
                    <div className="header">
                        {
                            !transfers[projectIndex].extended ? (
                                <div className="text"><h2>{this.context.t('Payment orders')}</h2></div>
                            ) : (
                                <div className="text extended">
                                    <div className="folder-icon"></div>
                                    <div className="go-back" onClick={() => this.goUpDirr()}></div>
                                    <h2>{this.props.project.code}{" / "}<span>{currentDir}</span></h2>
                                </div>
                            )
                        }

                        <div className={`expent-button ${transfers[projectIndex].extended ? 'extended' : ''}`} onClick={e => this.props.toggleProjectPaymentOrders(this.props.project.id)}></div>
                        {
                            [590, 13, 591, 495].includes(project.id) ? ( //AdminWA || TP1 || ZakupyWa || CodeSpot
                                <div className="import-excel">
                                    <input type="file" accept=".xlsx, .xls" onChange={this.uploadWaExcelHandler} id="file-input" />
                                    <label htmlFor="file-input">
                                    </label>
                                </div>
                            ) : null
                        }
                        <div className="add-new" onClick={e => this.props.openCreateTransferWindow(this.props.project)}></div>
                    </div>
                    {
                        transfers[projectIndex].extended ? (
                            <div className="transfers-manager-table">
                                <div className="file-row header-row">
                                    <div className="name">
                                        {this.context.t('Name')}
                                    </div>
                                    <div className="date">
                                        {this.context.t('Issue date')}
                                    </div>
                                    <div className="amount-to-pay">
                                        {this.context.t('Amount to pay')}
                                    </div>
                                    <div className="date">
                                        {this.context.t('Due date')}
                                    </div>
                                    <div className="status">
                                        {this.context.t('Status')}
                                    </div>
                                </div>
                                <SimpleBar style={{ height: simpleBarHeight, width: '100%' }}>
                                    {
                                        transfers[projectIndex].transfers.length > 0 ? (
                                            transfers[projectIndex].transfers.map((transfer, i) => {
                                                if (transfer.name.startsWith(currentDir)) {
                                                    if (!directories.includes(transfer.name.replace(currentDir, '').split('/')[0])) {
                                                        directories.push(transfer.name.replace(currentDir, '').split('/')[0])
                                                        return (
                                                            <div className="file-row" key={i} onClick={() => this.switchdirectory(transfer.name.replace(currentDir, '').split('/')[0], transfer.name, transfer)}>
                                                                {this.drawIcon(transfer.name.replace(currentDir, '').split('/')[0], transfer.name)}
                                                                <div className="name">
                                                                    {this.displayAsFileOrDirecxtory(transfer.name.replace(currentDir, '').split('/')[0], transfer.name.split('/').pop(), transfer.recipient, currentDir)}
                                                                </div>
                                                                {
                                                                    transfer.priority && transfer.name.replace(currentDir, '').split('/')[0] !== transfer.name.split('/')[0] ? (
                                                                        <div className="transfers-manager-priority">
                                                                            {this.context.t(' URGENT')}
                                                                        </div>
                                                                    ) : (
                                                                        <div className="transfers-manager-priority"></div>
                                                                    )
                                                                }
                                                                {
                                                                    transfer.name.replace(currentDir, '').split('/')[0] === transfer.name.split('/').pop() &&
                                                                    <>
                                                                        <div className="date">{moment(transfer.created).format('YYYY-MM-DD')}</div>
                                                                        <div className="amount-to-pay">{transfer.amount_to_pay ? `${this.formatAmountToPay(transfer.amount_to_pay)} ${transfer.currency}` : ''}</div>
                                                                        <div className="date">{moment(transfer.due_date).format('YYYY-MM-DD')}</div>
                                                                        <div className={`status`} data-for={`file-status-${projectIndex}-${transfer.id}`} data-tip='show'>
                                                                            <div className={`step-1 green`}></div>
                                                                            <div className={`step-2 ${transfer.bank_transfers_file_id || transfer.payment_method || transfer.paid_by ? 'green' : 'yellow'}`}></div>
                                                                            <div className={`step-3 ${transfer.payment_method || transfer.paid_by ? 'green' : transfer.bank_transfers_file_id ? 'yellow' : 'grey'}`}></div>
                                                                        </div>

                                                                        <ReactTooltip id={`file-status-${projectIndex}-${transfer.id}`} place="left" effect="solid" className="default-tooltip status-tooltip">
                                                                            {
                                                                                transfer.payment_method || transfer.paid_by ? (
                                                                                    this.context.t('Paid')
                                                                                ) : (
                                                                                    transfer.bank_transfers_file_id ? (
                                                                                        this.context.t('Included in bank transfers file')
                                                                                    ) : (
                                                                                        this.context.t('Not included in bank transfers file')
                                                                                    )
                                                                                )
                                                                            }
                                                                        </ReactTooltip>

                                                                    </>
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                }
                                            })
                                        ) : (
                                            <div className="no-payment-order-indicator" style={{ height: noContentImageHeight }}>
                                                <div className="image no-transfer-orders" >
                                                    <img src={noPaymentOrdersImage} alt="No Payment Orders" onClick={e => this.props.openCreateTransferWindow(this.props.project)} />
                                                </div>
                                                <div className="text">{this.context.t("No payment orders")}</div>
                                            </div>
                                        )
                                    }
                                </SimpleBar>
                            </div>
                        ) : (
                            null
                        )
                    }
                </div>
            )
        } else {
            return (
                <div className={`project-payment-orders`}>
                    <div className="header">
                        <div className="text"><h2>{this.context.t('Payment orders')}</h2></div>
                    </div>
                </div>
            )
        }
    }
}


TransfersManager.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    transfers: [...state.Transfers.transfers],
    transferPreviewData: state.Transfers.transferPreviewData,
    transferPreviewOpen: state.Transfers.transferPreviewOpen,
    actionsPreviewIsOpen: state.File.actionsPreviewIsOpen,
})

const mapDispatchToProps = {
    openCreateTransferWindow: TransfersActions.openAddTransferWindow,
    uploadWaPaymentOrderExcel: TransfersActions.uploadWaPaymentOrderExcel,
    getTransfers: TransfersActions.getAllMyTransfers,
    openTransferPreviewWindow: TransfersActions.openTransferPreviewWindow,
    switchSortingFilter: TransfersActions.switchSortingFilter,
    toggleProjectPaymentOrders: TransfersActions.toggleProjectPaymentOrders
}

export default connect(mapStateToProps, mapDispatchToProps)(TransfersManager)