import myFetch from "../helpers/fetch";
import { AlertActions } from "./AlertActions";
import { ChatConst } from "../reducers/ChatReducer";
import { CompanyConst } from "../reducers/CompanyReducer"
import { ReportsConst } from "../reducers/ReportsReducer"
import { EventConst } from "../reducers/EventReducer"
import { FileConst } from "../reducers/FileReducer"
import { UserConst } from "../reducers/UserReducer"

export const ChatActions = {
  toggleChatNotificationFavorite,
  openChatOnFilePreviewMount,
  getOldChatNotifications,
  addNewChatNotification,
  deleteChatNotification,
  addSocketEntry,
  sortChatNotification,
  getAllEntries,
  getFileById,
  addUsersList,
  checkChatNotification,
  sendEntry,
  markFileChatAsRead,
  clearEntry,
  clearUserList,
  openChat,
  closeChat,
  closePopUP
}

function toggleChatNotificationFavorite(fileId) {
  return dispatch => {
    dispatch({ type: ChatConst.MARK_AND_SORT_NOTIFICATION_BY_FAVORITE, fileId: fileId })
    myFetch(`/chat-notifications/mark-favorite-for-file`, {
      method: "put",
      body: {
        fileId: fileId
      },
    })
      .then((res) => {
        if (res.success) {

        } else {
          dispatch({ type: ChatConst.MARK_AND_SORT_NOTIFICATION_BY_FAVORITE, fileId: fileId })
          dispatch(AlertActions.warning(res.msg));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

}

function openChatOnFilePreviewMount() {
  return dispatch => {
    dispatch({ type: ChatConst.OPEN_CHAT, isOpenChat: true });
  }
}

function getOldChatNotifications(skip = 0, resetReduxState = false, cb = () => { }) {
  return dispatch => {
    if (resetReduxState) {
      dispatch({ type: ChatConst.CLEAR_OLD_NOTIFICATIONS })
    }
    dispatch({ type: ChatConst.WAITING_FOR_OLD_NOTIFICATIONS, loading: true })
    myFetch(`/chat-notifications/get-mine/${skip}`, { method: "get" }).then(res => {
      dispatch({ type: ChatConst.WAITING_FOR_OLD_NOTIFICATIONS, loading: false })
      if (res.success) {
        cb(res.arrayNotification)
        dispatch({ type: ChatConst.GET_OLD_NOTIFICATIONS, notifications: res.arrayNotification, skip: skip, hasOlder: res.hasOlder })
      }
    }).catch(err => {
      handleError(err, dispatch, `/user-projects/my-projects`)
    })
  }
}

function addNewChatNotification(notification) {
  return dispatch => {
    dispatch({ type: ChatConst.ADD_NEW_NOTIFICATION, notification, notification })
  }
}

function deleteChatNotification(fileId) {
  return dispatch => {
    dispatch({ type: ChatConst.DELETE_NOTIFICATION, fileId: fileId })
  }
}

function sortChatNotification(field, order) {
  return dispatch => {
    dispatch({ type: ChatConst.SORT_NOTIFICATIONS, sortField: field, sortOrder: order })
  }
}

function addSocketEntry(entry) {
  return dispatch => {
    dispatch({ type: ChatConst.SOCKET_MESSAGE, entry: entry });
  }
};

function handleError(error, dispatch, path) {
  if (error?.code === 'AUTHORIZATION_REQUIRED' && localStorage.getItem('dashboardProject')) {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    dispatch({ type: UserConst.LOGGED_FALSE })
    dispatch({ type: UserConst.LOGOUT })
    dispatch({ type: EventConst.LOGOUT })
    dispatch({ type: CompanyConst.LOGOUT })
    dispatch({ type: ReportsConst.LOGOUT })
    dispatch({ type: FileConst.LOGOUT })
    dispatch(AlertActions.info("You have been logged out.", 5000))
  } else {
    dispatch(AlertActions.setError(error, path));
    dispatch(AlertActions.danger("There was an internal server error while procesing request. Try again later."))
  }
}

function getAllEntries(fileId) {
  return dispatch => {
    dispatch({ type: ChatConst.SHOW_CHAT_LOADING });
    myFetch(`/chats/get-all/${fileId}`, { method: "get" })
      .then((res) => {
        if (res.success) {
          dispatch({ type: ChatConst.ADD_ALL_ENTRIES, entries: res.array });
        } else {
          dispatch(AlertActions.warning(res.msg));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
}

function getFileById(fileId) {
  return dispatch => {
    myFetch(`/chats/getFileById/${fileId}`, { method: "get" })
      .then((res) => {
        if (res.success) {
          dispatch({ type: ChatConst.GET_FILE_INST, fileCurrentInst: res.obj });
        } else {
          dispatch(AlertActions.warning(res.msg));
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }
}

function addUsersList(fileId) {
  return dispatch => {
    myFetch(`/chats/get-file-users-list/${fileId}`, { method: "get" })
      .then((res) => {
        if (res.success) {
          dispatch({ type: ChatConst.GET_USERS_LIST, usersList: res.usersList });
        } else {
          dispatch(AlertActions.warning(res.msg));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
}

function checkChatNotification() {
  return dispatch => {
    myFetch(`/chat-notifications/check-new`, { method: "get" })
      .then((res) => {
        if (res.success) {
          console.log('sprawdzamy CHECKA', res.checkObj.isNew)
          dispatch({ type: ChatConst.IS_OPEN_POPUP, isNotificationsPopupOpen: res.checkObj.isNew });
        } else {
          dispatch(AlertActions.warning(res.msg));
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }
}

function sendEntry(fileId, entry, mentionedUsers, userId) {
  return dispatch => {
    const randomId = Math.floor(Math.random() * 1000 + 1);
    dispatch({
      type: ChatConst.ADD_NEW_ENTRY,
      entry: {
        id: randomId,
        user_id: userId,
        file_id: fileId,
        entry: entry,
      }
    });
    myFetch(`/chats/add-new`, {
      method: "post",
      body: {
        fileId: fileId,
        entry: entry,
        mentionedUsers: mentionedUsers,
      },
    })
      .then((res) => {
        if (res.success) {
          dispatch({ type: ChatConst.UPDATE_NEW_ENTRY, entry: res.obj, randomId: randomId, userId: userId });
        } else {
          dispatch(AlertActions.warning(res.msg));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
}

function markFileChatAsRead(fileId) {
  return dispatch => {
    myFetch(`/chat-notifications/mark-read-for-file`, {
      method: "put",
      body: {
        fileId: fileId
      },
    })
      .then((res) => {
        if (res.success) {
          dispatch({ type: ChatConst.MARK_NOTIFICATION_AS_READ, fileId: fileId })
        } else {
          dispatch(AlertActions.warning(res.msg));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
}

function clearEntry() {
  return dispatch => {
    dispatch({ type: ChatConst.CLEAR_ENTRIES });
  }
}

function clearUserList() {
  return dispatch => {
    dispatch({ type: ChatConst.CLEAR_USER_LIST, usersList: [] });
  }
}

function openChat(openChat) {
  return dispatch => {
    dispatch({ type: ChatConst.OPEN_CHAT, isOpenChat: openChat });
  }
}

function closeChat(closeChat) {
  return dispatch => {
    dispatch({ type: ChatConst.CLOSE_CHAT, isOpenChat: closeChat });
  }
}

function closePopUP(closePopUp) {
  return dispatch => {
    dispatch({ type: ChatConst.IS_OPEN_POPUP, isNotificationsPopupOpen: closePopUp });
  }
}
