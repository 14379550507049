import React, { Component, isValidElement } from 'react'
import ReactDOM from 'react-dom'
import { Redirect, Link } from 'react-router-dom'
import { reverse } from 'named-urls'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import * as Scroll from 'react-scroll'
import throttle from "lodash.throttle"
import moment, { relativeTimeThreshold } from 'moment'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { Spinner, Dots, Digital } from 'react-activity'
import 'react-activity/dist/react-activity.css'
//cropping library
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css'
import Chat from "../../components/Chat/ChatClassComponent";
import ChatToogleButton from "../../components/Chat/ChatToogleButton"
import './../../css/OcrPage.css'

import DeleteInvoiceModal from '../../components/DeleteInvoiceModal'
import OverlayInstructions from '../../components/OverlayInstructions'
import PdfPreview from '../../components/PdfPreview'
import MoveToOtherProjectModal from '../../components/OcrPageComponents/MoveToOtherProjectModal'

import { UserActions } from '../../actions/UserActions'
import { FileActions } from '../../actions/FileActions'
import { AlertActions } from '../../actions/AlertActions'
import { CompanyActions } from '../../actions/CompanyActions'
import { TabsControlerActions } from '../../actions/V2_TabsControlerActions'

import arrayObjectIndexOf from '../../helpers/indexOfObject'
import parseDate from '../../helpers/dateParser'
import myFetch from '../../helpers/fetch'
import { history } from "../../helpers/history"
import routes from "../../helpers/routes"
import { checkIfCompanyHasAccessToFunctionality } from "../../helpers/checkIfCompanyHasAccessToFunctionality"
import waproUsers from '../../helpers/waproUsers.json'
import { devCostsProjects } from '../../helpers/DevCosts/devCostsProjects'
import { getCurrencyRateFromNBP } from '../../helpers/getCurrencyRateFromNBP'
import { checkIfAccountNumberIsValid } from './../../helpers/validAccountNumber';
import allProjectsWithMpk from '../../helpers/MpkSplit/allProjectsWithMpk'
import { gfProjects } from '../../helpers/CostSplit/gfProjects'
import isIterable from "../../helpers/isIterable"

import OcrProsList from "../../components/OcrPageComponents/OcrProsList"
import OcrPageTooltips from "../../components/OcrPageComponents/OcrPageTooltips"
import OcrPagePopups from '../../components/OcrPageComponents/OcrPagePopups'
import OcrPageDocQuestions from '../../components/OcrPageComponents/OcrPageDocQuestions'
import OcrPageTextPreview from '../../components/OcrPageComponents/OcrPageTextPreview'

import OcrPageCostsForm from '../../components/OcrPageComponents/OcrPageCostsForm-new'
import OcrPageExpensesForm from '../../components/OcrPageComponents/OcrPageExpensesForm-new'
import OcrPageIncomeForm from '../../components/OcrPageComponents/OcrPageIncomeForm-new'
import OcrPageAgreementForm from '../../components/OcrPageComponents/OcrPageAgreementForm-new'
import OcrPageCorporateForm from '../../components/OcrPageComponents/OcrPageCorporateForm-new'
import OcrPageCorrespondenceForm from '../../components/OcrPageComponents/OcrPageCorrespondenceForm-new'
import OcrPageOtherForm from '../../components/OcrPageComponents/OcrPageOtherForm-new'
import OcrPageProtocolForm from '../../components/OcrPageComponents/OcrPageProtocolForm-new'

import { socket } from "../../App"
import { ConsoleWriter } from 'istanbul-lib-report'
import { error } from 'jquery'

const scroll = Scroll.animateScroll;
const hasOwnProperty = Object.prototype.hasOwnProperty;


const datePattern = new RegExp("^[0-9]{2}-[0-9]{2}-[0-9]{4}$", "i");
const datePatternOneDayDigit = new RegExp("^[0-9]{1}-[0-9]{2}-[0-9]{4}$", "i");
const datePatternOneMonthDigit = new RegExp("^[0-9]{2}-[0-9]{1}-[0-9]{4}$", "i");
const datePatternReverse = new RegExp("^[0-9]{4}-[0-9]{2}-[0-9]{2}$", "i");
const months = [
    'january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'
];
const miesiace = [
    'styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec', 'sierpień', 'wrzesień', 'październik', 'listopad', 'grudzień'
]
const miesiaceOdmienione = [
    'stycznia', 'lutego', 'marca', 'kwietnia', 'maja', 'czerca', 'lipca', 'sierpnia', 'września', 'października', 'listopada', 'grudnia'
]

export class OcrPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            otherUsersWorkingOnThisDoc: [],
            dataConfirmCheckbox: false,
            projectMembers: [],
            currentProjectRole: '',
            currentInvoiceImageData: null,
            currentSelectedProperties: "",
            loadedData: false,
            invoiceData: [],
            ocrMethod: 'select',
            calcHeight: null,

            byHandInvoiceNumber: '',
            byHandSellerData: '',
            byHandSellerVatId: '',
            byHandBuyerData: '',
            byHandBuyerVatId: '',
            byHandAccountNumber: '',
            byHandDueDate: '',
            byHandIssueDate: '',
            byHandPrice: '',
            byHandTax: '',
            byHandBrutto: '',
            byHandNetto: '',

            selectedLang: 'Polish',
            selectedLangFlag: 'pol',
            langExpanded: false,
            showLangSelector: false,
            focusedTextArea: '',

            acceptedAmounttoPay: false,
            comment: '',
            documentDescription: '',
            tags: '',
            tagsList: [],
            tagsCreationLimitation: '',
            emailToSendPaymentConfirmation: '',
            counterpartiesList: [],
            showTagsList: false,
            showCounterpartiesList: false,
            paymentMethod: '',
            currency: '',
            paymentType: '',
            priority: 'regular',
            errorFields: [],

            deleteModal: false,

            posX: 0,
            posY: 0,
            posUp: false,
            posLeft: false,
            showPropertyList: false,

            posXDatePicker: 0,
            posYDatePicker: 0,
            posUpDatePicker: false,
            posLeftDatePicker: false,

            expensesOnDoc: [{
                docNumber: '',
                issueDate: '',
                price: '',
                comment: ''
            }],

            gotOverlayPossitions: false,
            overlayPossitions: [],

            showAccountNumberWarningBeforeSave: false,
            didShowFrameTooltip: false,
            renderFeameTooltip: false,

            textPreview: {},

            waproData: {
                magType: '',
                fakirType: '',
                fakirDictionary: '',
                netAccount: '',
                docDescription: '',
                showVatRateSelector: false,
                vatRate: '',
                cashAccountingScheme: false
            },

            symfoniaData: {
                docType: {
                    dSkrot: "",
                    dNazwa: ""
                },
                docDescription: "",
                netAccount: "",
                vatAccount: "",
                grossAccount: "",
                vatRegisters: [{
                    register: { rNazna: '' },
                    vatRate: { stawka: '', nazwa: '' },
                    netAmount: '',
                    vatAmount: '',
                    grossAmount: ''
                }],
            },

            WL_DevCosts: {
                projectCode: '',
                technology: '',
                devExType1: '',
                devExType2: '',
                transactionDescriptionPl: '',
                transactionDescriptionEn: '',
                lockedForDevEx: false
            },
            costSplit: false,
            costSplitAllocation: [],

            mpkSplit: [],

            buyerCountryCode: 'PL',

            formWarnings: {},
            formWarningsCount: 0,
            formErrors: {},
            formErrorsCount: 0,
        }

        this.nextDocLinkRef = React.createRef()
        this.goBackRef = React.createRef()

        this.propsRef = React.createRef()
        this.fromRef = React.createRef()
        this.buttonsRef = React.createRef()
    }

    componentDidMount() {
        window.addEventListener("resize", throttle(this.setDivSize, 500))
        window.addEventListener("keydown", this.escFunction, false)
        window.addEventListener("resize", throttle(this.setOverlaySize, 100))
        //window.addEventListener('scroll', this.handleScroll);
        //worker.load();

        const {
            userProjects, userCompanies, filesToVerifiLoaded,
            user, location
        } = this.props
        var urlProjectId = 0
        var urlFileId = 0
        if (!user.new_easydocs_version) {
            urlProjectId = parseInt(this.props.match.params.projectId)
            urlFileId = parseInt(this.props.match.params.fileId)
        } else {
            var additionalUrlParams = location.pathname.replace('/ocr/', '')
            if (/^[0-9]{0,}\/[0-9]{0,}$/.test(additionalUrlParams)) {
                var projectAndFileArray = additionalUrlParams.split('/')
                const projectId = parseInt(projectAndFileArray[0])
                const fileId = parseInt(projectAndFileArray[1])

                urlProjectId = projectId
                urlFileId = fileId
            } else {
                if (this.props.urlProjectId && this.props.urlFileId) {
                    urlProjectId = this.props.urlProjectId
                    urlFileId = this.props.urlFileId
                }
            }
        }

        var projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, urlProjectId, "project")
        if (projectIndex > -1) {
            this.loadImageOnReady(urlProjectId, urlFileId)
        } else {
            this.setState({
                waitingForFile: true
            })
            this.props.getProjectResourcesForDashboard(urlProjectId, 0, "", success => {
                if (success) {
                    setTimeout(() => {
                        this.loadImageOnReady(urlProjectId, urlFileId)
                        this.setState({
                            waitingForFile: false
                        })
                    }, 500)
                } else {
                    history.push(routes.panel.dashboard)
                }
            })
        }

        var userProjectIndex = arrayObjectIndexOf(userProjects, urlProjectId, "id")
        if (userProjectIndex > -1) {
            this.props.switchDashboardViewModel(userProjects[userProjectIndex].id)
            var companyIndex = arrayObjectIndexOf(userCompanies || [], parseInt(userProjects[userProjectIndex].company_id), "company_id")
            if (companyIndex > -1) {
                this.props.setCurrentCompany(userCompanies[companyIndex])
            }
        } else {
            this.setState({
                waitForUserProjectsToLoad: true,
                urlProjectId: urlProjectId
            })
        }

        //ocrInputChange
        //ocrExpensesChange

        socket.on('ocrInputChange', this.socketInputChange)
        socket.on('ocrExpensesChange', this.socketExpensesChange)
        socket.on('ocrDocQuestionChange', this.socketDocQuestionChange)
        socket.on('ocrUserLeft', this.userLeftDoc)
    }

    componentDidUpdate(prevProps, prevState) {

        ReactTooltip.rebuild();

        if (this.state.loadedData) {
            this.loadGeneratedOCR(true);
        }

        if (this.propsRef && this.state.currentInvoiceImageData && prevState.currentInvoiceImageData && this.state.currentInvoiceImageData.docType !== prevState.currentInvoiceImageData.docType) {
            this.setDivSize()
        }

        if (!this.state.gotOverlayPossitions) {
            console.log(this.propsRef)
            console.log(this.fromRef)
            console.log(this.buttonsRef)
            if (this.propsRef.current && this.fromRef.current && this.buttonsRef.current) {
                var holesToDraw = []

                var propsBox = this.propsRef.current.getBoundingClientRect()
                var formBox = this.fromRef.current.getBoundingClientRect()
                var buttonsBox = this.buttonsRef.current.getBoundingClientRect()

                if (propsBox) {
                    holesToDraw.push({
                        x: propsBox.x,
                        y: propsBox.y,
                        w: propsBox.width,
                        h: propsBox.height
                    })
                }

                if (formBox) {
                    holesToDraw.push({
                        x: formBox.x,
                        y: formBox.y,
                        w: formBox.width,
                        h: formBox.height
                    })
                }

                if (buttonsBox) {
                    holesToDraw.push({
                        x: buttonsBox.x,
                        y: buttonsBox.y,
                        w: buttonsBox.width,
                        h: buttonsBox.height
                    })
                }

                console.log(holesToDraw)


                if (holesToDraw.length === 3 && holesToDraw[1].h > 0) {
                    this.setState({
                        gotOverlayPossitions: true,
                        overlayPossitions: holesToDraw
                    })
                }
            }
        }

        if (prevProps.urlFileId && this.props.urlFileId && prevProps.urlFileId !== this.props.urlFileId) {
            const { urlProjectId, urlFileId, filesToVerifiLoaded, userCompanies, userProjects } = this.props
            var projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, urlProjectId, "project")
            if (projectIndex > -1) {
                var image = filesToVerifiLoaded[projectIndex].files.find(element => element.dbId === urlFileId)
                if (image) {
                    this.getOriginalDocImage(image.ftvId, urlProjectId)
                    this.setState({
                        currentInvoiceImageData: { ...image, ...{ originalImageLoaded: false } },
                        loadedData: true,
                        ocrMethod: image.docType === 'EXPENSES' ? 'manual' : 'select',
                        paymentMethod: image.paymentMethod,
                        paymentType: image.paymentType,
                        currency: image.currency,
                        priority: image.priority ? image.priority : 'regular',
                        comment: image.comment,
                        documentDescription: image.doc_description,
                        emailToSendPaymentConfirmation: image.emailToSendPaymentConfirmation || '',
                        tags: image.tags || '',
                        assignToAgreement: image.agreementId ? true : false,
                        selectedAgreement: image.agreementId ? { id: image.agreementId } : {},
                        WL_DevCosts: {
                            projectCode: image.wl_project_code,
                            technology: image.wl_technology,
                            devExType1: image.wl_devex_type_1,
                            devExType2: image.wl_devex_type_2,
                            transactionDescriptionPl: image.wl_transaction_description_pl,
                            transactionDescriptionEn: image.wl_transaction_description_en,
                            lockedForDevEx: image.locked_for_devex || false,
                            lockedForDevExBy: parseInt(image?.locked_for_devex_by || -1)
                        },
                        costSplit: image.costSplit && image.costSplit.length ? true : false,
                        costSplitAllocation: image.costSplit || [],
                        mpkSplit: image.mpkSplit || [],
                    })

                    if (image.manualOcr) {
                        this.setState({
                            ocrMethod: 'manual',
                        })
                    }

                    projectIndex = arrayObjectIndexOf(userProjects, urlProjectId, "id")

                    if (projectIndex > -1) {
                        var companyIndex = arrayObjectIndexOf(userCompanies || [], parseInt(userProjects[projectIndex].company_id), "company_id")
                        if (companyIndex > -1) {
                            if (userCompanies[companyIndex].company && userCompanies[companyIndex].company.tags_list && userCompanies[companyIndex].company.counterparties) {
                                this.setState({
                                    tagsList: userCompanies[companyIndex].company.tags_list,
                                    tagsCreationLimitation: userCompanies[companyIndex].company.tags_creation_limitation,
                                    companyId: userCompanies[companyIndex].company.id,
                                    counterpartiesList: userCompanies[companyIndex].company.counterparties,
                                    docOwnerCompany: userCompanies[companyIndex].company
                                })
                            }
                        }
                    }
                }

                setTimeout(() => {
                    var projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, urlProjectId, "project")
                    var fileIndex = arrayObjectIndexOf(filesToVerifiLoaded[projectIndex].files, urlFileId, "dbId")
                    if (fileIndex > -1) {
                        var prevDocExists = false, prevDoc = {}
                        var nextDocExists = false, nextDoc = {}
                        var prevDocIndex = fileIndex - 1, nextDocIndex = fileIndex + 1
                        while (prevDocIndex > 0) {
                            if (filesToVerifiLoaded[projectIndex].files[prevDocIndex]?.docflow?.id) {
                                prevDocIndex--
                            } else {
                                prevDocExists = true
                                prevDoc = {
                                    name: filesToVerifiLoaded[projectIndex].files[prevDocIndex]?.name,
                                    fileId: filesToVerifiLoaded[projectIndex].files[prevDocIndex]?.dbId,
                                    projectId: urlProjectId,
                                    status: filesToVerifiLoaded[projectIndex].files[prevDocIndex].currentDocflowStep?.name || this.context.t('New'),
                                    uploaded: `${filesToVerifiLoaded[projectIndex].files[prevDocIndex]?.userThatUploaded} (${moment(filesToVerifiLoaded[projectIndex].files[prevDocIndex]?.uploadDate).format('DD-MM-YYYY, HH:mm')})`
                                }
                                break
                            }
                        }

                        while (nextDocIndex < filesToVerifiLoaded[projectIndex].files.length) {
                            if (filesToVerifiLoaded[projectIndex].files[nextDocIndex]?.docflow?.id) {
                                nextDocIndex++
                            } else {
                                nextDocExists = true
                                nextDoc = {
                                    name: filesToVerifiLoaded[projectIndex].files[nextDocIndex]?.name,
                                    fileId: filesToVerifiLoaded[projectIndex].files[nextDocIndex]?.dbId,
                                    projectId: urlProjectId,
                                    status: filesToVerifiLoaded[projectIndex].files[nextDocIndex]?.currentDocflowStep?.name || this.context.t('New'),
                                    uploaded: `${filesToVerifiLoaded[projectIndex].files[nextDocIndex]?.userThatUploaded} (${moment(filesToVerifiLoaded[projectIndex].files[nextDocIndex]?.uploadDate).format('DD-MM-YYYY, HH:mm')})`
                                }
                                break
                            }
                        }

                        this.setState({
                            prevDocExists: prevDocExists,
                            prevDoc: prevDoc,
                            nextDocExists: nextDocExists,
                            nextDoc: nextDoc,
                        })
                    }
                }, 500)

            }
        }

        const { waitForUserProjectsToLoad, urlProjectId } = this.state
        const { userProjects, userCompanies } = this.props

        if (waitForUserProjectsToLoad && userProjects && userProjects.length > 0) {
            var userProjectIndex = arrayObjectIndexOf(userProjects, urlProjectId, "id")
            if (userProjectIndex > -1) {
                this.props.switchDashboardViewModel(userProjects[userProjectIndex].id)
                var companyIndex = arrayObjectIndexOf(userCompanies || [], parseInt(userProjects[userProjectIndex].company_id), "company_id")
                if (companyIndex > -1) {
                    this.props.setCurrentCompany(userCompanies[companyIndex])
                }
            }

            this.setState({
                waitForUserProjectsToLoad: false
            })
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", throttle(this.setDivSize, 500))
        window.removeEventListener("keydown", this.escFunction, false)
        window.removeEventListener("resize", throttle(this.setOverlaySize, 100))

        const { currentInvoiceImageData } = this.state
        if (currentInvoiceImageData && currentInvoiceImageData.ftvId) this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'leaveOCR', '', '')

        socket.off('ocrInputChange', this.socketInputChange)
        socket.off('ocrExpensesChange', this.socketExpensesChange)
        socket.off('ocrDocQuestionChange', this.socketDocQuestionChange)
        socket.off('ocrUserLeft', this.userLeftDoc)
    }

    loadImageOnReady = (urlProjectId, urlFileId) => {
        const {
            userProjects, userCompanies, filesToVerifiLoaded
        } = this.props
        var projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, urlProjectId, "project")
        if (projectIndex > -1) {
            var image = filesToVerifiLoaded[projectIndex].files.find(element => element.dbId === urlFileId)
            if (image) {
                this.getOriginalDocImage(image.ftvId, urlProjectId)
                this.setState({
                    currentInvoiceImageData: { ...image, ...{ originalImageLoaded: false } },
                    loadedData: true,
                    ocrMethod: image.docType === 'EXPENSES' ? 'manual' : 'select',
                    paymentMethod: image.paymentMethod,
                    paymentType: image.paymentType,
                    currency: image.currency,
                    priority: image.priority ? image.priority : 'regular',
                    comment: image.comment,
                    documentDescription: image.doc_description,
                    emailToSendPaymentConfirmation: image.emailToSendPaymentConfirmation || '',
                    tags: image.tags || '',
                    assignToAgreement: image.agreementId ? true : false,
                    selectedAgreement: image.agreementId ? { id: image.agreementId } : {},
                    WL_DevCosts: {
                        projectCode: image.wl_project_code,
                        technology: image.wl_technology,
                        devExType1: image.wl_devex_type_1,
                        devExType2: image.wl_devex_type_2,
                        transactionDescriptionPl: image.wl_transaction_description_pl,
                        transactionDescriptionEn: image.wl_transaction_description_en,
                        lockedForDevEx: image.locked_for_devex || false,
                        lockedForDevExBy: parseInt(image?.locked_for_devex_by || -1)
                    },
                    costSplit: image.costSplit && image.costSplit.length ? true : false,
                    costSplitAllocation: image.costSplit || [],
                    mpkSplit: image.mpkSplit || [],
                })

                if (image.manualOcr) {
                    this.setState({
                        ocrMethod: 'manual',
                    })
                }

                var currentProject = userProjects.find(e => e.id === urlProjectId)
                if (currentProject) {
                    this.setState({
                        currentProjectRole: currentProject.acceptance_permissions
                    })
                    var currentCompany = userCompanies?.find(e => e.company_id === currentProject.company_id)
                    if (currentCompany) {
                        if (currentCompany.company && currentCompany.company.tags_list && currentCompany.company.counterparties) {
                            this.setState({
                                tagsList: currentCompany.company.tags_list,
                                tagsCreationLimitation: currentCompany.company.tags_creation_limitation,
                                companyId: currentCompany.company.id,
                                counterpartiesList: currentCompany.company.counterparties,
                                docOwnerCompany: currentCompany.company,
                                isAccountantInCurrentCompany: currentCompany.is_accountant
                            })
                        } else {
                            this.setState({
                                isAccountantInCurrentCompany: currentCompany.is_accountant
                            })
                        }
                    }
                }

                setTimeout(() => {
                    this.setDivSize()
                }, 100)
                setTimeout(() => {
                    var projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, urlProjectId, "project")
                    var fileIndex = arrayObjectIndexOf(filesToVerifiLoaded[projectIndex].files, urlFileId, "dbId")
                    var currentProject = userProjects.find(e => e.id === urlProjectId)
                    var currentProjectRole = ''
                    if (currentProject) {
                        currentProjectRole = currentProject.acceptance_permissions
                    }
                    if (fileIndex > -1) {
                        var prevDocExists = false, prevDoc = {}
                        var nextDocExists = false, nextDoc = {}
                        var prevDocIndex = fileIndex - 1, nextDocIndex = fileIndex + 1
                        while (prevDocIndex > 0) {
                            if (filesToVerifiLoaded[projectIndex].files[prevDocIndex]?.docflow?.id) {
                                prevDocIndex--
                            } else {
                                prevDocExists = true
                                prevDoc = {
                                    name: filesToVerifiLoaded[projectIndex].files[prevDocIndex]?.name,
                                    fileId: filesToVerifiLoaded[projectIndex].files[prevDocIndex]?.dbId,
                                    projectId: urlProjectId,
                                    status: filesToVerifiLoaded[projectIndex].files[prevDocIndex].currentDocflowStep?.name || this.context.t('New'),
                                    uploaded: `${filesToVerifiLoaded[projectIndex].files[prevDocIndex]?.userThatUploaded} (${moment(filesToVerifiLoaded[projectIndex].files[prevDocIndex]?.uploadDate).format('DD-MM-YYYY, HH:mm')})`
                                }
                                break
                            }
                        }

                        while (nextDocIndex < filesToVerifiLoaded[projectIndex].files.length) {
                            if (filesToVerifiLoaded[projectIndex].files[nextDocIndex]?.docflow?.id) {
                                nextDocIndex++
                            } else {
                                nextDocExists = true
                                nextDoc = {
                                    name: filesToVerifiLoaded[projectIndex].files[nextDocIndex]?.name,
                                    fileId: filesToVerifiLoaded[projectIndex].files[nextDocIndex]?.dbId,
                                    projectId: urlProjectId,
                                    status: filesToVerifiLoaded[projectIndex].files[nextDocIndex]?.currentDocflowStep?.name || this.context.t('New'),
                                    uploaded: `${filesToVerifiLoaded[projectIndex].files[nextDocIndex]?.userThatUploaded} (${moment(filesToVerifiLoaded[projectIndex].files[nextDocIndex]?.uploadDate).format('DD-MM-YYYY, HH:mm')})`
                                }
                                break
                            }
                        }

                        this.setState({
                            prevDocExists: prevDocExists,
                            prevDoc: prevDoc,
                            nextDocExists: nextDocExists,
                            nextDoc: nextDoc,
                        })
                    }
                }, 500)
            } else {
                this.props.getProjectResourcesForDashboard(urlProjectId, 0, "")
                history.push(routes.panel.dashboard)
            }
        } else {
            this.props.getProjectResourcesForDashboard(urlProjectId, 0, "")
            history.push(routes.panel.dashboard)
        }
    }

    socketInputChange = data => {
        const { user } = this.props
        const { currentInvoiceImageData, ocrMethod, otherUsersWorkingOnThisDoc } = this.state
        if (data.senderId !== user.id && currentInvoiceImageData && data.id === currentInvoiceImageData.ftvId) {
            if (data.docType === 'COSTS') this.props.changeOCRData(currentInvoiceImageData.dbId, data.propName, data.propValue, ocrMethod === 'select' ? 'select' : 'manual')
            else this.props.changeOCRData(currentInvoiceImageData.dbId, data.propName, data.propValue, '', currentInvoiceImageData.docType, null)

            if (!otherUsersWorkingOnThisDoc.find(u => u.id === data.senderId)) {
                this.setState({
                    otherUsersWorkingOnThisDoc: [].concat(otherUsersWorkingOnThisDoc, [{ id: data.senderId, name: data.user, photo: data.userPhoto }])
                })
            }
        }
    }

    socketExpensesChange = data => {
        const { user } = this.props
        const { otherUsersWorkingOnThisDoc, currentInvoiceImageData } = this.state
        if (data.senderId !== user.id && currentInvoiceImageData && data.id === currentInvoiceImageData.ftvId) {
            this.setState({
                expensesOnDoc: data.propValue
            })

            if (!otherUsersWorkingOnThisDoc.find(u => u.id === data.senderId)) {
                this.setState({
                    otherUsersWorkingOnThisDoc: [].concat(otherUsersWorkingOnThisDoc, [{ id: data.senderId, name: data.user, photo: data.userPhoto }])
                })
            }
        }
    }

    socketDocQuestionChange = data => {
        const { user } = this.props
        const { otherUsersWorkingOnThisDoc, currentInvoiceImageData } = this.state

        if (data.propName === 'docflow') {
            this.selectDocflow(data.propValue, data.currentDocflowStep, true)
        }

        if (data.senderId !== user.id && currentInvoiceImageData && data.id === currentInvoiceImageData.ftvId) {
            if (data.propName === 'costSplit') {
                this.setState({
                    costSplit: data.propValue.length > 0,
                    costSplitAllocation: data.propValue
                })
            }
            if (data.propName === 'mpkSplit') {
                this.setState({
                    mpkSplit: data.propValue
                })
            }
            if (data.propName && data.propName.startsWith('wl_')) {
                const { WL_DevCosts } = this.state
                var copy = { ...WL_DevCosts }
                if (data.propName === 'wl_project_code') copy.projectCode = data.propValue
                if (data.propName === 'wl_technology') copy.technology = data.propValue
                if (data.propName === 'wl_devex_type_1') copy.devExType1 = data.propValue
                if (data.propName === 'wl_devex_type_2') copy.devExType2 = data.propValue
                if (data.propName === 'wl_transaction_description_pl') copy.transactionDescriptionPl = data.propValue
                if (data.propName === 'wl_transaction_description_en') copy.transactionDescriptionEn = data.propValue
                if (data.propName === 'wl_locked_for_devex') copy.lockedForDevEx = data.propValue
            }
            if (data.propName === 'doc_type') this.selectDocType(data.propValue, true)
            if (data.propName === 'payment_method') this.selectPaymentMethod(data.propValue, true)
            if (data.propName === 'currency') this.selectCurrency(data.propValue, true)
            if (data.propName === 'payment_type') this.selectPaymentType(data.propValue, true)
            if (data.propName === 'priority') this.selectPriority(data.propValue, true)
            if (data.propName === 'manualOCR') this.switchMode(null, true)
            if (data.propName === 'comment') this.setState({ comment: data.propValue })
            if (data.propName === 'tags') this.setState({ tags: data.propValue })
            if (data.propName === 'emailToSendPaymentConfirmation') this.setState({ emailToSendPaymentConfirmation: data.propValue })
            if (data.propName === 'doc_description') this.setState({ documentDescription: data.propValue })

            if (!otherUsersWorkingOnThisDoc.find(u => u.id === data.senderId)) {
                this.setState({
                    otherUsersWorkingOnThisDoc: [].concat(otherUsersWorkingOnThisDoc, [{ id: data.senderId, name: data.user, photo: data.userPhoto }])
                })
            }
        }
    }

    userLeftDoc = data => {
        const { user } = this.props
        const { otherUsersWorkingOnThisDoc, currentInvoiceImageData } = this.state
        if (data.senderId !== user.id && currentInvoiceImageData && data.id === currentInvoiceImageData.ftvId) {
            if (otherUsersWorkingOnThisDoc && otherUsersWorkingOnThisDoc.find(u => u.id === data.senderId)) {
                this.setState({
                    otherUsersWorkingOnThisDoc: otherUsersWorkingOnThisDoc.filter(u => u.id !== data.senderId)
                })
            }
        }
    }

    escFunction = (e) => {
        if (e.keyCode === 27) {
            var hiddenSomething = this.hidePropertiesList(e)
            if (this.props.existingInvoice || this.props.similarInvoices.length > 0) {
                this.props.closeExistingFileModal()
            } else if (this.state.deleteModal) {
                this.cancelDeleteInvoice()
            } else if (!hiddenSomething) {
                //this.goBackRef.current.click()
            }
        }
    }

    setDivSize = () => {
        if (this.propsRef) {
            var newHeight = window.innerHeight - 110
            const { user } = this.props
            const { currentInvoiceImageData, currentProjectRole, paymentMethod, companyId } = this.state
            if (currentInvoiceImageData) {
                if (currentInvoiceImageData.docType !== 'EXPENSES') {
                    if (currentProjectRole !== 'USER1') {
                        newHeight -= 316
                    } else {
                        newHeight -= 296
                    }

                    if (paymentMethod !== 'not-paid') {
                        newHeight += 40
                    }
                }
                if (currentInvoiceImageData.docType === 'EXPENSES') {
                    if (currentProjectRole !== 'USER1') {
                        newHeight -= 236
                    } else {
                        newHeight -= 236
                    }
                }
            }

            if (user?.new_easydocs_version) {
                newHeight = window.innerHeight
                    - 90 //topbar
                    - 40 //tabsbar
                    - 40 //tabwrapper padding
                    - 10 //ocr container margin top
                    - 55 //header = file name
                    - 80 //button bottom
                    - 20 //padding bottom
            }

            this.setState({
                calcHeight: newHeight
            })
        } else {
            console.log('setDivSize but no ref')
        }
    }

    setOverlaySize = () => {
        console.log(this.propsRef)
        console.log(this.fromRef)
        console.log(this.buttonsRef)
        if (this.propsRef.current && this.fromRef.current && this.buttonsRef.current) {
            var holesToDraw = []

            var propsBox = this.propsRef.current.getBoundingClientRect()
            var formBox = this.fromRef.current.getBoundingClientRect()
            var buttonsBox = this.buttonsRef.current.getBoundingClientRect()

            if (propsBox) {
                holesToDraw.push({
                    x: propsBox.x,
                    y: propsBox.y,
                    w: propsBox.width,
                    h: propsBox.height
                })
            }

            if (formBox) {
                holesToDraw.push({
                    x: formBox.x,
                    y: formBox.y,
                    w: formBox.width,
                    h: formBox.height
                })
            }

            if (buttonsBox) {
                holesToDraw.push({
                    x: buttonsBox.x,
                    y: buttonsBox.y,
                    w: buttonsBox.width,
                    h: buttonsBox.height
                })
            }

            if (holesToDraw.length === 3 && holesToDraw[1].h > 0) {
                this.setState({
                    gotOverlayPossitions: true,
                    overlayPossitions: holesToDraw
                })
            }
        }
    }

    loadGeneratedOCR = (loadExpenses) => {
        if (this.state.currentInvoiceImageData && this.state.currentInvoiceImageData.defaultData) {
            this.setState({
                byHandBuyerData: this.state.currentInvoiceImageData.defaultData.buyerName,
                byHandBuyerVatId: this.state.currentInvoiceImageData.defaultData.buyerVatId,
                byHandSellerData: this.state.currentInvoiceImageData.defaultData.sellerName,
                byHandSellerVatId: this.state.currentInvoiceImageData.defaultData.sellerCode,
            })
        }

        if (this.state.currentInvoiceImageData && this.state.currentInvoiceImageData.ocrData) {
            this.props.loadGeneratedOCR(this.state.currentInvoiceImageData.projectId, this.state.currentInvoiceImageData.dbId)
            if (this.refs.propList) this.refs.propList.classList.add("hidden")
            if (this.refs.texAreaWrapper) this.refs.texAreaWrapper.classList.add("full-height")
            if (this.refs.modSwitcher) this.refs.modSwitcher.classList.add("d-none")
        }

        if (this.state.currentInvoiceImageData) {
            if (this.state.currentInvoiceImageData.docType !== 'EXPENSES' && this.state.currentInvoiceImageData.manualOcr) {
                this.setState({
                    byHandInvoiceNumber: this.state.currentInvoiceImageData.manualOcr.invoiceNumber,
                    byHandAccountNumber: this.state.currentInvoiceImageData.manualOcr.accountNumber,
                    byHandDueDate: this.state.currentInvoiceImageData.manualOcr.dueDate,
                    byHandIssueDate: this.state.currentInvoiceImageData.manualOcr.issueDate,
                    byHandPrice: this.state.currentInvoiceImageData.manualOcr.price,
                    byHandBrutto: this.state.currentInvoiceImageData.manualOcr.brutto,
                    byHandNetto: this.state.currentInvoiceImageData.manualOcr.netto,
                    byHandTax: this.state.currentInvoiceImageData.manualOcr.tax,
                    byHandAddress: this.state.currentInvoiceImageData.manualOcr.address,
                    byHandSwift: this.state.currentInvoiceImageData.manualOcr.swift,
                })
            }
            if (this.state.currentInvoiceImageData.expenseOcr && loadExpenses) {
                var expensesOnDoc = []
                this.state.currentInvoiceImageData.expenseOcr.forEach(e => {
                    expensesOnDoc.push({
                        docNumber: e.docNumber,
                        docNumberPossition: e.docNumberPossition,
                        issueDate: e.issueDate,
                        issueDatePossition: e.issueDatePossition,
                        price: e.price,
                        pricePossition: e.pricePossition,
                        comment: e.comment,
                        tags: e.tags
                    })
                })

                this.setState({
                    expensesOnDoc: expensesOnDoc
                })
            }
        }

        this.setDivSize()

        this.setState({
            loadedData: false,
        })
    }

    getOriginalDocImage = async (ftvId, projectId) => {
        try {
            const res = await myFetch(`/files-to-verifi/get-original-doc-image/?ftvId=${ftvId}&projectId=${projectId}`, {
                method: 'GET'
            })

            if (res.success) {
                this.setState({
                    currentInvoiceImageData: { ...this.state.currentInvoiceImageData, ...{ imageData: res.image, originalImageLoaded: true } }
                })
            } else {
                this.setState({
                    currentInvoiceImageData: { ...this.state.currentInvoiceImageData, ...{ originalImageLoaded: true } }
                })
            }
        } catch (err) {
            this.setState({
                currentInvoiceImageData: { ...this.state.currentInvoiceImageData, ...{ originalImageLoaded: true } }
            })
        }
    }

    onDocumentImageLoad = (e) => {
        if (this.state.currentInvoiceImageData && this.refs.cropper) {
            var cropperData = this.refs.cropper.getCanvasData()
            if (cropperData.width > 0) {
                this.refs.cropper.setCropBoxData({
                    left: cropperData.width * 0.4,
                    top: 100,
                    width: cropperData.width * 0.2,
                    height: 80
                })
                this.setState({
                    didShowFrameTooltip: true,
                    renderFeameTooltip: true,
                })
            }
        }
    }

    setTextPreviewBox = (name, expenseIndex) => {
        const { cropper } = this.refs
        const { user } = this.props
        var transformStyle = cropper.cropper.cropBox.style.transform || ''
        var transformCropperCanvas = cropper.cropper.canvas.style.transform || ''
        var cropperContainerWidth = this.refs.cropperContainer.getBoundingClientRect().left
        var cropperWidth = cropper.cropper.canvas.getBoundingClientRect().left
        var offsetLeft = cropperWidth - cropperContainerWidth
        var translations = transformStyle.match(/((translateX)?(translateY)?\([0-9.-]*(px)\))/gm)

        var additionalTranslationX = 0, additionalTranslationY = 0
        if (transformCropperCanvas) {
            var canvasTranslations = transformCropperCanvas.match(/((translateX)?(translateY)?\([0-9.-]*(px)\))/gm)
            if (canvasTranslations)
                canvasTranslations.forEach(tr => {
                    if (tr.includes('translateX')) {
                        additionalTranslationX = parseFloat(tr.replace(/[^0-9.\-]/gm, ''))
                    } else if (tr.includes('translateY')) {
                        additionalTranslationY = parseFloat(tr.replace(/[^0-9.\-]/gm, ''))
                    }
                })
        }

        var transX = 0, transY = 0
        if (translations)
            translations.forEach(tr => {
                if (tr.includes('translateX')) {
                    transX = tr.replace(/[^0-9.-]/gm, '')
                } else if (tr.includes('translateY')) {
                    transY = tr.replace(/[^0-9.-]/gm, '')
                }
            })
        transX = parseFloat(transX)
        transY = parseFloat(transY)

        transX += offsetLeft + cropper.cropper.cropBox.getBoundingClientRect().width / 2 - additionalTranslationX / 2
        transY += cropper.cropper.cropBox.getBoundingClientRect().height + 10 - additionalTranslationY

        if (user.new_easydocs_version) {
            transY += 50
        }

        this.setState({
            textPreview: {
                possition: {
                    left: transX,
                    top: transY,
                },
                prop: name,
                expenseIndex: expenseIndex,
            }
        })
    }

    _crop = (e, name) => {
        //check if any propery was checked
        if (!e.target.className.includes('processing')) {
            const { selectedLangFlag, currentInvoiceImageData, ocrMethod } = this.state;

            let croppedImage

            croppedImage = this.refs.cropper.getCroppedCanvas().toDataURL();
            var scale = this.refs.cropper.cropper.canvasData.naturalWidth / this.refs.cropper.cropper.containerData.width;
            var boxPossition = {}
            boxPossition.x1 = ((this.refs.cropper.getCropBoxData().left) * scale).toFixed(2);
            boxPossition.x2 = ((this.refs.cropper.getCropBoxData().left + this.refs.cropper.getCropBoxData().width) * scale).toFixed(2);
            boxPossition.y1 = ((this.refs.cropper.getCropBoxData().top) * scale).toFixed(2);
            boxPossition.y2 = ((this.refs.cropper.getCropBoxData().top + this.refs.cropper.getCropBoxData().height) * scale).toFixed(2);

            var title = ''
            if (name === 'price') {
                title = 'Price'
            } else if (name === 'currencyRate') {
                title = 'Currency rate'
            } else if (name === 'netto') {
                title = 'Net'
            } else if (name === 'brutto') {
                title = 'Gross'
            } else if (name === 'bankData') {
                title = 'Account Number'
            } else if (name === 'buyerData') {
                title = 'Buyer Data'
            } else if (name === 'sellerName') {
                title = 'Seller Name'
            } else if (name === 'invoiceNumber') {
                title = 'Document Number'
            } else if (name === 'nip') {
                title = "Saller Vat ID"
            } else if (name === 'dueDate') {
                title = "Due Date"
            } else if (name === 'issueDate') {
                title = "Issue Date"
            } else if (name === 'saleDate') {
                title = "Sale Date"
            } else if (name === 'tax') {
                title = 'VAT'
            } else if (name === 'sellerVatId') {
                title = 'Seller VAT-ID'
            } else if (name === 'address') {
                title = 'Seller Address'
            } else if (name === 'swift') {
                title = 'Seller\'s Bank SWIFT/BIC'
            } else if (name === 'buyerName') {
                title = 'Buyer Name'
            } else if (name === 'buyerVatId') {
                title = 'Buyer VAT-ID'
            } else if (name === 'doc_description') {
                title = 'Document description'
            }
            this.props.generateOcr(croppedImage, selectedLangFlag, currentInvoiceImageData.dbId, name, boxPossition, title, currentInvoiceImageData.projectId, ocrMethod, (text, lastTags) => {
                if (!['devExTransactionDescription', 'doc_description'].includes(name)) this.props.autoSaveOcr(currentInvoiceImageData.ftvId, name, text, ocrMethod === 'select' ? 'costsOCR' : 'costsManual')
                if (name === 'invoiceNumber' && text) {
                    setTimeout(() => {
                        const { currentInvoiceImageData, ocrMethod } = this.state
                        const { returnedOCRData } = this.props

                        if (ocrMethod === 'select' && returnedOCRData.props) {
                            if (returnedOCRData.props.find(e => e.name === 'invoiceNumber') && returnedOCRData.props.find(e => e.name === 'invoiceNumber').text &&
                                returnedOCRData.props.find(e => e.name === 'sellerVatId') && returnedOCRData.props.find(e => e.name === 'sellerVatId').text)
                                this.props.checkInvoiceNumber(returnedOCRData.props, currentInvoiceImageData.projectId, currentInvoiceImageData.dbId, currentInvoiceImageData.docType)
                        } else if (returnedOCRData.manualProps) {
                            if (returnedOCRData.manualProps.find(e => e.name === 'invoiceNumber') && returnedOCRData.manualProps.find(e => e.name === 'invoiceNumber').text &&
                                returnedOCRData.manualProps.find(e => e.name === 'sellerVatId') && returnedOCRData.manualProps.find(e => e.name === 'sellerVatId').text)
                                this.props.checkInvoiceNumber(returnedOCRData.manualProps, currentInvoiceImageData.projectId, currentInvoiceImageData.dbId, currentInvoiceImageData.docType)
                        }
                    }, 500)
                } else if (name === 'sellerVatId' && text) {
                    const { counterpartiesList } = this.state
                    var onlyDigits = text.replace(/[^0-9]/gm, '')
                    var c = counterpartiesList.find(e => e.vat_id === text || e.vat_id === onlyDigits)
                    if (c) {
                        this.props.changeOCRData(currentInvoiceImageData.dbId, 'sellerName', c.name, 'select', currentInvoiceImageData.docType)
                    } else {
                        fetch(`https://wl-api.mf.gov.pl/api/search/nip/${onlyDigits}?date=${moment().format("YYYY-MM-DD")}`, { method: 'get' })
                            .then(response => response.json())
                            .then(json => {
                                console.log(json)
                                if (json && json.result && json.result.subject) {
                                    this.props.changeOCRData(currentInvoiceImageData.dbId, 'sellerName', json.result.subject.name, 'select', currentInvoiceImageData.docType)
                                }
                            })
                            .catch(err => {
                                console.log('error when fetching companyName')
                            })
                    }
                } else if (name === 'buyerVatId' && text) {
                    const { currentCompany } = this.props
                    if (currentCompany && currentCompany.company && currentCompany.company.vat_id) {
                        var currentCompanyVatIdOnlyDigits = currentCompany.company.vat_id.replace(/[^0-9]/gm, '')
                        var readTextVatIdOnlyDigits = text.replace(/[^0-9]/gm, '')

                        if (currentCompanyVatIdOnlyDigits === readTextVatIdOnlyDigits) {
                            this.props.changeOCRData(currentInvoiceImageData.dbId, 'buyerName', currentCompany.company.name, 'select', currentInvoiceImageData.docType)
                        }
                    }
                } else if (name === 'devExTransactionDescription') {
                    const { WL_DevCosts } = this.state
                    this.setState({
                        WL_DevCosts: {
                            ...WL_DevCosts,
                            ...{ transactionDescriptionPl: text }
                        }
                    })
                    this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'wl_transaction_description_pl', text, '')
                } else if (name === 'doc_description') {
                    this.setState({
                        documentDescription: text
                    })
                    this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'doc_description', text, '')
                }
                if (text) this.setTextPreviewBox(name, -1)

                if (lastTags) {
                    this.setState({
                        tags: lastTags
                    })
                    this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'tags', lastTags, '')
                }
            })
        }

        if (name === 'price' && this.refs.pricePropRef) {
            this.refs.pricePropRef.scrollIntoView({ behavior: "smooth" });
        } else if (name === 'brutto' && this.refs.bruttoPropRef) {
            this.refs.bruttoPropRef.scrollIntoView({ behavior: "smooth" });
        } else if (name === 'netto' && this.refs.nettoPropRef) {
            this.refs.nettoPropRef.scrollIntoView({ behavior: "smooth" });
        } else if (name === 'bankData' && this.refs.bankDataPropRef) {
            this.refs.bankDataPropRef.scrollIntoView({ behavior: "smooth" });
        } else if (name === 'buyerData' && this.refs.buyerDataPropRef) {
            this.refs.buyerDataPropRef.scrollIntoView({ behavior: "smooth" });
        } else if (name === 'sellerData' && this.refs.sellerDataPropRef) {
            this.refs.sellerDataPropRef.scrollIntoView({ behavior: "smooth" });
        } else if (name === 'invoiceNumber' && this.refs.invoiceNumberPropRef) {
            this.refs.invoiceNumberPropRef.scrollIntoView({ behavior: "smooth" });
        } else if (name === 'nip' && this.refs.nipPropRef) {
            this.refs.nipPropRef.scrollIntoView({ behavior: "smooth" });
        } else if (name === 'dueDate' && this.refs.dueDatePropRef) {
            this.refs.dueDatePropRef.scrollIntoView({ behavior: "smooth" });
        } else if (name === 'issueDate' && this.refs.issueDatePropRef) {
            this.refs.issueDatePropRef.scrollIntoView({ behavior: "smooth" });
        } else if (name === 'tax' && this.refs.taxPropRef) {
            this.refs.taxPropRef.scrollIntoView({ behavior: "smooth" });
        } else if (name === 'address' && this.refs.addressPropRef) {
            this.refs.addressPropRef.scrollIntoView({ behavior: "smooth" });
        } else if (name === 'swift' && this.refs.swiftPropRef) {
            this.refs.swiftPropRef.scrollIntoView({ behavior: "smooth" });
        }
    }

    cropForExpenses = (e, name, expenseIndex) => {
        const { currentInvoiceImageData } = this.state;
        if (!this.state.waitingForExpensePropToFinishOCR) {
            this.setState({ waitingForExpensePropToFinishOCR: true })
            var croppedImage = this.refs.cropper.getCroppedCanvas().toDataURL();
            var scale = this.refs.cropper.cropper.canvasData.naturalWidth / this.refs.cropper.cropper.containerData.width;
            var boxPossition = {}
            boxPossition.x1 = ((this.refs.cropper.getCropBoxData().left) * scale).toFixed(2);
            boxPossition.x2 = ((this.refs.cropper.getCropBoxData().left + this.refs.cropper.getCropBoxData().width) * scale).toFixed(2);
            boxPossition.y1 = ((this.refs.cropper.getCropBoxData().top) * scale).toFixed(2);
            boxPossition.y2 = ((this.refs.cropper.getCropBoxData().top + this.refs.cropper.getCropBoxData().height) * scale).toFixed(2);

            this.props.generateOcrForExpenses(croppedImage, name, (text) => {
                var { expensesOnDoc } = this.state
                if (name === 'documentNumber') {
                    expensesOnDoc[expenseIndex].docNumber = text
                    expensesOnDoc[expenseIndex].docNumberPossition = boxPossition
                } else if (name === 'issueDate') {
                    expensesOnDoc[expenseIndex].issueDate = text
                    expensesOnDoc[expenseIndex].issueDatePossition = boxPossition
                } else if (name === 'doc_description') {
                    this.setState({
                        documentDescription: text
                    })
                    this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'doc_description', text, '')

                } else {
                    expensesOnDoc[expenseIndex].price = text
                    expensesOnDoc[expenseIndex].pricePossition = boxPossition
                }
                this.setState({
                    waitingForExpensePropToFinishOCR: false,
                    expensesOnDoc: expensesOnDoc
                })

                if (text) this.setTextPreviewBox(name, expenseIndex)
            })
        }
    }

    cropForDocType = (e, name, docType) => {

        if (!this.state.waitingForExpensePropToFinishOCR) {
            const { currentInvoiceImageData } = this.state;
            this.setState({ waitingForExpensePropToFinishOCR: true })
            var croppedImage = this.refs.cropper.getCroppedCanvas().toDataURL();
            var scale = this.refs.cropper.cropper.canvasData.naturalWidth / this.refs.cropper.cropper.containerData.width;
            var boxPossition = {}
            boxPossition.x1 = ((this.refs.cropper.getCropBoxData().left) * scale).toFixed(2);
            boxPossition.x2 = ((this.refs.cropper.getCropBoxData().left + this.refs.cropper.getCropBoxData().width) * scale).toFixed(2);
            boxPossition.y1 = ((this.refs.cropper.getCropBoxData().top) * scale).toFixed(2);
            boxPossition.y2 = ((this.refs.cropper.getCropBoxData().top + this.refs.cropper.getCropBoxData().height) * scale).toFixed(2);

            this.props.generateOcrForExpenses(croppedImage, name, (text) => {
                this.props.changeOCRData(currentInvoiceImageData.dbId, name, text, '', docType, boxPossition)
                this.props.autoSaveOcr(currentInvoiceImageData.ftvId, name, text, docType.toLowerCase())
                this.setState({
                    waitingForExpensePropToFinishOCR: false
                })

                if (docType === 'AGREEMENT' || docType === 'CORRESPONDENCE' || docType === 'OTHER') {
                    if (name === 'counterpartyVatId' && text) {
                        const { counterpartiesList } = this.state
                        var onlyDigits = text.replace(/[^0-9]/gm, '')
                        var c = counterpartiesList.find(e => e.vat_id === text || e.vat_id === onlyDigits)
                        if (c) {
                            this.props.changeOCRData(currentInvoiceImageData.dbId, 'counterpartyName', c.name, '', docType)
                            this.props.changeOCRData(currentInvoiceImageData.dbId, 'counterpartyCode', c.code, '', docType)
                            this.props.setNewCounterparty(false, currentInvoiceImageData.dbId)
                            this.setState({
                                oldCodeSelected: true
                            })
                        } else {
                            fetch(`https://wl-api.mf.gov.pl/api/search/nip/${onlyDigits}?date=${moment().format("YYYY-MM-DD")}`, { method: 'get' })
                                .then(response => response.json())
                                .then(json => {
                                    console.log(json)
                                    if (json && json.result && json.result.subject) {
                                        this.props.changeOCRData(currentInvoiceImageData.dbId, 'counterpartyName', json.result.subject.name, '', docType)
                                    }
                                })
                                .catch(err => {
                                    console.log('error when fetching companyName')
                                })
                        }
                    }
                }
                if (name === 'doc_description' && text) {
                    this.setState({
                        documentDescription: text
                    })
                    this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'doc_description', text, '')

                }

                if (text) this.setTextPreviewBox(name, -1)
            })
        }
    }

    isEmpty = (obj) => {

        if (obj == null) return true;

        if (obj.length > 0) return false;
        if (obj.length === 0) return true;

        if (typeof obj !== "object") return true;

        for (var key in obj) {
            if (hasOwnProperty.call(obj, key)) return false;
        }
        return true;
    }

    onCalendarSelect = (date) => {
        const { focusedTextArea, focusedTextAreaIndex, ocrMethod, currentInvoiceImageData } = this.state
        if (date) {
            if (focusedTextArea === 'dueDate') {
                this.props.changeOCRData(currentInvoiceImageData.dbId, 'dueDate', date.format('DD-MM-YYYY'), ocrMethod === 'select' ? 'select' : 'manual')
                this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'dueDate', date.format('DD-MM-YYYY'), ocrMethod === 'select' ? 'costsOCR' : 'costsManual')
            } else if (focusedTextArea === 'issueDate') {
                this.props.changeOCRData(currentInvoiceImageData.dbId, 'issueDate', date.format('DD-MM-YYYY'), ocrMethod === 'select' ? 'select' : 'manual')
                this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'issueDate', date.format('DD-MM-YYYY'), ocrMethod === 'select' ? 'costsOCR' : 'costsManual')
            } else if (focusedTextArea === 'saleDate') {
                this.props.changeOCRData(currentInvoiceImageData.dbId, 'saleDate', date.format('DD-MM-YYYY'), ocrMethod === 'select' ? 'select' : 'manual')
                this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'saleDate', date.format('DD-MM-YYYY'), ocrMethod === 'select' ? 'costsOCR' : 'costsManual')
                this.addCommentWithCurrencyRate(date.format('DD-MM-YYYY'))
            } else if (focusedTextArea.includes('issueDate')) {
                var { expensesOnDoc } = this.state
                if (focusedTextAreaIndex > -1 && expensesOnDoc.length - 1 >= focusedTextAreaIndex) {
                    expensesOnDoc[focusedTextAreaIndex].issueDate = date.format('DD-MM-YYYY')
                    this.setState({
                        expensesOnDoc: expensesOnDoc
                    })

                    this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'expenses', JSON.stringify(expensesOnDoc), 'expenses')
                }
            } else if (focusedTextArea === 'currencyRate') {
                this.addCommentWithCurrencyRate(date.add(1, 'day').format('DD-MM-YYYY'))
            }

            this.setState({
                focusedTextArea: ''
            })
        }
    }

    onCalendarSelectIncome = date => {
        const { focusedTextArea, currentInvoiceImageData } = this.state
        if (date) {
            if (focusedTextArea === 'saleDate') {
                this.props.changeOCRData(currentInvoiceImageData.dbId, 'saleDate', date.format('DD-MM-YYYY'), '', 'SALE_INVOICE')
                this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'saleDate', date.format('DD-MM-YYYY'), 'income')
            } else if (focusedTextArea === 'issueDate') {
                this.props.changeOCRData(currentInvoiceImageData.dbId, 'issueDate', date.format('DD-MM-YYYY'), '', 'SALE_INVOICE')
                this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'issueDate', date.format('DD-MM-YYYY'), 'income')
                this.addCommentWithCurrencyRate(date.format('DD-MM-YYYY'))
            } else if (focusedTextArea === 'dueDate') {
                this.props.changeOCRData(currentInvoiceImageData.dbId, 'dueDate', date.format('DD-MM-YYYY'), '', 'SALE_INVOICE')
                this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'dueDate', date.format('DD-MM-YYYY'), 'income')
            }
            this.setState({
                focusedTextArea: ''
            })
        }
    }

    onCalendarSelectAgreement = date => {
        const { focusedTextArea, currentInvoiceImageData } = this.state
        if (date) {
            if (focusedTextArea === 'date') {
                this.props.changeOCRData(currentInvoiceImageData.dbId, 'date', date.format('DD-MM-YYYY'), '', 'AGREEMENT')
                this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'date', date.format('DD-MM-YYYY'), 'agreement')
            }
            this.setState({
                focusedTextArea: ''
            })
        }
    }

    onCalendarSelectCorporate = date => {
        const { focusedTextArea, currentInvoiceImageData } = this.state
        if (date) {
            if (focusedTextArea === 'date') {
                this.props.changeOCRData(currentInvoiceImageData.dbId, 'date', date.format('DD-MM-YYYY'), '', 'CORPORATE')
                this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'date', date.format('DD-MM-YYYY'), 'agreement')
            }
            this.setState({
                focusedTextArea: ''
            })
        }
    }

    onCalendarSelectOther = date => {
        const { focusedTextArea, currentInvoiceImageData } = this.state
        if (date) {
            if (focusedTextArea === 'date') {
                this.props.changeOCRData(currentInvoiceImageData.dbId, 'date', date.format('DD-MM-YYYY'), '', 'OTHER')
                this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'date', date.format('DD-MM-YYYY'), 'other')
            }
            this.setState({
                focusedTextArea: ''
            })
        }
    }

    onCalendarSelectProtocol = date => {
        const { focusedTextArea, currentInvoiceImageData } = this.state
        if (date) {
            if (focusedTextArea === 'date') {
                this.props.changeOCRData(currentInvoiceImageData.dbId, 'date', date.format('DD-MM-YYYY'), '', 'PROTOCOL')
                this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'date', date.format('DD-MM-YYYY'), 'protocol')
            }
            this.setState({
                focusedTextArea: ''
            })
        }
    }

    onCalendarSelectCorrespondence = date => {
        const { focusedTextArea, currentInvoiceImageData } = this.state
        if (date) {
            if (focusedTextArea === 'date') {
                this.props.changeOCRData(currentInvoiceImageData.dbId, 'date', date.format('DD-MM-YYYY'), '', 'CORRESPONDENCE')
                this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'date', date.format('DD-MM-YYYY'), 'correspondence')
            }
            this.setState({
                focusedTextArea: ''
            })
        }
    }

    onCalendarUnselecet = (date) => {
        console.log(date)
        this.setState({
            focusedTextArea: ''
        })
    }

    onCalendarUnselecetAgreement = (date) => {
        console.log(date)
    }

    onInputChange = (e, file, name) => {
        e.preventDefault()
        const { value } = e.target
        this.props.changeOCRData(file, name, value, 'select')
        if (name === 'sellerVatId') {
            var nip = value.replace(/[^0-9]/gm, '')
            if (nip.length === 10) {
                const { counterpartiesList, currentInvoiceImageData, ocrMethod } = this.state
                var c = counterpartiesList.find(e => e.vat_id === nip)
                if (c) {
                    this.props.changeOCRData(currentInvoiceImageData.dbId, 'sellerName', c.name, 'select', currentInvoiceImageData.docType)
                    this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'sellerName', c.name, 'costsOCR')
                    if (c.last_account_number) {
                        this.props.changeOCRData(currentInvoiceImageData.dbId, 'bankData', c.last_account_number, 'select', currentInvoiceImageData.docType)
                        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'bankData', c.last_account_number, 'costsOCR')
                    }
                    console.log(c)
                    if (c.last_tags) {
                        this.setState({
                            tags: c.last_tags
                        })
                        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'tags', c.last_tags, '')
                    }
                } else {
                    fetch(`https://wl-api.mf.gov.pl/api/search/nip/${nip}?date=${moment().format("YYYY-MM-DD")}`, { method: 'get' })
                        .then(response => response.json())
                        .then(json => {
                            console.log(json)
                            if (json && json.result && json.result.subject) {
                                this.props.changeOCRData(currentInvoiceImageData.dbId, 'sellerName', json.result.subject.name, 'select', currentInvoiceImageData.docType)
                                this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'sellerName', json.result.subject.name, 'costsOCR')
                            }
                        })
                        .catch(err => {
                            console.log('error when fetching companyName')
                        })
                }
            }
        } else if (name === 'sellerCode') {
            var code = value.replace(/[^0-9a-zA-Z\-\_]/gm, '')
            this.props.changeOCRData(file, name, code, 'select')
        } else if (name === 'issueDate') {
            this.addCommentWithCurrencyRate()
        } else if (name === 'buyerVatId') {
            var nip = value.replace(/[^0-9]/gm, '')
            if (nip.length === 10) {
                const { currentCompany } = this.props
                if (currentCompany && currentCompany.company && currentCompany.company.vat_id) {
                    var currentCompanyVatIdDigits = currentCompany.company.vat_id.replace(/[^0-9]/gm, '')
                    if (nip === currentCompanyVatIdDigits) {
                        const { currentInvoiceImageData, ocrMethod } = this.state
                        this.props.changeOCRData(currentInvoiceImageData.dbId, 'buyerName', currentCompany.company.name, 'select', currentInvoiceImageData.docType)
                        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'buyerName', currentCompany.company.name, 'costsOCR')
                    }
                }
            }
        } else if (name === 'sellerName' && !this.state.showCounterpartiesList) {
            this.setState({
                showCounterpartiesList: true
            })
        }
    }

    setAmountValue = (value, propName, ocrMethod, file) => {
        this.props.changeOCRData(file, propName, value, ocrMethod)
    }

    onInputChangeByHand = (e, file, name) => {
        e.preventDefault()

        const { value } = e.target
        this.props.changeOCRData(file, name, value, 'manual')
        if (name === 'sellerVatId') {
            var nip = value.replace(/[^0-9]/gm, '')
            if (nip.length === 10) {
                const { counterpartiesList, currentInvoiceImageData } = this.state
                var c = counterpartiesList.find(e => e.vat_id === nip)
                if (c) {
                    this.props.changeOCRData(currentInvoiceImageData.dbId, 'sellerName', c.name, 'manual', currentInvoiceImageData.docType)
                    this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'sellerName', c.name, 'costsManual')

                    if (c.last_tags) {
                        this.setState({
                            tags: c.last_tags
                        })
                        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'tags', c.last_tags, '')
                    }
                } else {
                    fetch(`https://wl-api.mf.gov.pl/api/search/nip/${nip}?date=${moment().format("YYYY-MM-DD")}`, { method: 'get' })
                        .then(response => response.json())
                        .then(json => {
                            console.log(json)
                            if (json && json.result && json.result.subject) {
                                this.props.changeOCRData(currentInvoiceImageData.dbId, 'sellerName', json.result.subject.name, 'select', currentInvoiceImageData.docType)
                                this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'sellerName', json.result.subject.name, 'costsManual')
                            }
                        })
                        .catch(err => {
                            console.log('error when fetching companyName')
                        })
                }
            }
        } else if (name === 'buyerVatId') {
            var nip = value.replace(/[^0-9]/gm, '')
            if (nip.length === 10) {
                const { currentCompany } = this.props
                if (currentCompany && currentCompany.company && currentCompany.company.vat_id) {
                    var currentCompanyVatIdDigits = currentCompany.company.vat_id.replace(/[^0-9]/gm, '')
                    if (nip === currentCompanyVatIdDigits) {
                        const { currentInvoiceImageData } = this.state
                        this.props.changeOCRData(currentInvoiceImageData.dbId, 'buyerName', currentCompany.company.name, 'manual', currentInvoiceImageData.docType)
                        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'buyerName', currentCompany.company.name, 'costsManual')
                    }
                }
            }
        } else if (name === 'sellerName' && !this.state.showCounterpartiesList) {
            this.setState({
                showCounterpartiesList: true
            })
        }
    }

    onInputChangeAgreement = (e, file, name) => {
        const { value } = e.target
        this.props.changeOCRData(file, name, value, '', 'AGREEMENT')

        if (name === 'counterpartyName' && !this.state.showCounterpartiesList) {
            this.setState({
                showCounterpartiesList: true
            })
        }
    }

    onInputChangeIncome = (e, file, name) => {
        const { value } = e.target
        this.props.changeOCRData(file, name, value, '', 'SALE_INVOICE')
        if (name === 'issueDate') this.addCommentWithCurrencyRate(value)
    }

    onInputChangeCorporate = (e, file, name) => {
        const { value } = e.target
        this.props.changeOCRData(file, name, value, '', 'CORPORATE')

        if (name === 'counterpartyName' && !this.state.showCounterpartiesList) {
            this.setState({
                showCounterpartiesList: true
            })
        }
    }

    onInputChangeOther = (e, file, name) => {
        const { value } = e.target
        this.props.changeOCRData(file, name, value, '', 'OTHER')

        if (name === 'counterpartyName' && !this.state.showCounterpartiesList) {
            this.setState({
                showCounterpartiesList: true
            })
        }
    }

    onInputChangeProtocol = (e, file, name) => {
        const { value } = e.target
        this.props.changeOCRData(file, name, value, '', 'PROTOCOL')
    }

    selectProtocolType = (file, type) => {
        const { currentInvoiceImageData } = this.state
        this.props.changeOCRData(file, 'protocolType', type, '', 'PROTOCOL')
        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'protocolType', type, 'protocol')
    }


    onInputChangeCorrespondence = (e, file, name) => {
        const { value } = e.target
        this.props.changeOCRData(file, name, value, '', 'CORRESPONDENCE')

        if (name === 'counterpartyName' && !this.state.showCounterpartiesList) {
            this.setState({
                showCounterpartiesList: true
            })
        }
    }

    loadGeneratedOcrToManual = () => {
        const { currentInvoiceImageData } = this.state
        const { returnedOCRData } = this.props

        if (returnedOCRData && returnedOCRData.props) {
            returnedOCRData.props.forEach(ocrProp => {
                if (ocrProp) {
                    if (ocrProp.name !== 'sellerVatId' && ocrProp.name !== 'sellerCode' && ocrProp.text) {
                        this.props.changeOCRData(currentInvoiceImageData.dbId, ocrProp.name, ocrProp.text, 'manual')
                    } else if (ocrProp.name === 'sellerCode') {
                        this.props.changeOCRData(currentInvoiceImageData.dbId, 'sellerVatId', ocrProp.text, 'manual')
                    }
                }
            })
        }
    }

    loadGeneratedOcrToIncome = () => {
        const { currentInvoiceImageData } = this.state
        const { returnedOCRData } = this.props

        if (returnedOCRData && returnedOCRData.props) {
            returnedOCRData.props.forEach(ocrProp => {
                if (ocrProp) {
                    if (!ocrProp?.name?.includes('seller') && !ocrProp?.name?.includes('buyer') && ocrProp.text && ocrProp.name !== 'bankData') {
                        this.props.changeOCRData(currentInvoiceImageData.dbId, ocrProp.name, ocrProp.text, '', 'SALE_INVOICE', ocrProp.possition)
                    } else if (ocrProp?.name === 'sellerVatId') {
                        this.props.changeOCRData(currentInvoiceImageData.dbId, 'buyerVatId', ocrProp.text, '', 'SALE_INVOICE', ocrProp.possition)
                    } else if (ocrProp?.name === 'sellerName') {
                        this.props.changeOCRData(currentInvoiceImageData.dbId, 'buyerName', ocrProp.text, '', 'SALE_INVOICE', ocrProp.possition)
                    } else if (ocrProp?.name === 'buyerVatId') {
                        this.props.changeOCRData(currentInvoiceImageData.dbId, 'sellerVatId', ocrProp.text, '', 'SALE_INVOICE', ocrProp.possition)
                    } else if (ocrProp?.name === 'buyerName') {
                        this.props.changeOCRData(currentInvoiceImageData.dbId, 'sellerName', ocrProp.text, '', 'SALE_INVOICE')
                    } else if (ocrProp?.name === 'bankData') {
                        this.props.changeOCRData(currentInvoiceImageData.dbId, 'accountNumber', ocrProp.text, '', 'SALE_INVOICE', ocrProp.possition)
                    }
                }
            })
        }
    }

    loadGeneratedOcrToAgreement = () => {
        const { currentInvoiceImageData } = this.state
        const { returnedOCRData } = this.props

        if (returnedOCRData && returnedOCRData.props) {
            returnedOCRData.props.forEach(ocrProp => {

                if (ocrProp) {
                    if (ocrProp.name === 'sellerVatId') {
                        this.props.changeOCRData(currentInvoiceImageData.dbId, 'counterpartyVatId', ocrProp.text, '', 'AGREEMENT', ocrProp.possition)
                    } else if (ocrProp.name === 'sellerName') {
                        this.props.changeOCRData(currentInvoiceImageData.dbId, 'counterpartyName', ocrProp.text, '', 'AGREEMENT')
                    } else if (ocrProp.name === 'invoiceNumber') {
                        this.props.changeOCRData(currentInvoiceImageData.dbId, 'number', ocrProp.text, '', 'AGREEMENT')
                    } else if (ocrProp.name === 'issueDate') {
                        this.props.changeOCRData(currentInvoiceImageData.dbId, 'date', ocrProp.text, '', 'AGREEMENT')
                    }
                }
            })
        }
    }

    loadGeneratedOcrToCorrespondence = () => {
        const { currentInvoiceImageData } = this.state
        const { returnedOCRData } = this.props

        if (returnedOCRData && returnedOCRData.props) {
            returnedOCRData.props.forEach(ocrProp => {
                if (ocrProp) {
                    if (ocrProp.name === 'sellerVatId') {
                        this.props.changeOCRData(currentInvoiceImageData.dbId, 'counterpartyVatId', ocrProp.text, '', 'CORRESPONDENCE', ocrProp.possition)
                    } else if (ocrProp.name === 'sellerName') {
                        this.props.changeOCRData(currentInvoiceImageData.dbId, 'counterpartyName', ocrProp.text, '', 'CORRESPONDENCE')
                    } else if (ocrProp.name === 'issueDate') {
                        this.props.changeOCRData(currentInvoiceImageData.dbId, 'date', ocrProp.text, '', 'CORRESPONDENCE')
                    }
                }
            })
        }
    }

    loadGeneratedOcrToCorporate = () => {
        const { currentInvoiceImageData } = this.state
        const { returnedOCRData } = this.props
        if (returnedOCRData && returnedOCRData.props) {
            returnedOCRData.props.forEach(ocrProp => {
                if (ocrProp) {
                    if (ocrProp.name === 'issueDate') {
                        this.props.changeOCRData(currentInvoiceImageData.dbId, 'date', ocrProp.text, '', 'CORPORATE')
                    }
                }
            })
        }
    }

    loadGeneratedOcrToOther = () => {
        const { currentInvoiceImageData } = this.state
        const { returnedOCRData } = this.props

        if (returnedOCRData && returnedOCRData.props) {
            returnedOCRData.props.forEach(ocrProp => {
                if (ocrProp) {
                    if (ocrProp.name === 'sellerVatId') {
                        this.props.changeOCRData(currentInvoiceImageData.dbId, 'counterpartyVatId', ocrProp.text, '', 'OTHER', ocrProp.possition)
                    } else if (ocrProp.name === 'sellerName') {
                        this.props.changeOCRData(currentInvoiceImageData.dbId, 'counterpartyName', ocrProp.text, '', 'OTHER')
                    } else if (ocrProp.name === 'issueDate') {
                        this.props.changeOCRData(currentInvoiceImageData.dbId, 'date', ocrProp.text, '', 'OTHER')
                    }
                }
            })
        }
    }



    selectCounterpartOcrData = async (c, file) => {

        const { currentInvoiceImageData } = this.state
        this.props.changeOCRData(file, 'sellerName', c.name, 'select')
        await this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'sellerName', c.name, 'costsOCR')
        if (c.country) {
            this.props.changeOCRData(file, 'countryCode', c.country, 'select')
            await this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'countryCode', c.country, 'costsOCR')
        }
        if (c.vat_id) {
            this.props.changeOCRData(file, 'sellerVatId', c.vat_id, 'select')
            await this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'sellerVatId', c.vat_id, 'costsOCR')
        }
        if (c.code) {
            this.props.changeOCRData(file, 'sellerCode', c.code, 'select')
            await this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'sellerCode', c.code, 'costsOCR')
        }
        this.setState({
            showCounterpartiesList: false
        })
    }


    selectCounterpart = async (c, file) => {
        const { currentInvoiceImageData } = this.state

        this.props.changeOCRData(file, 'sellerName', c.name, 'manual')
        await this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'sellerName', c.name, 'costsManual')
        this.props.changeOCRData(file, 'sellerVatId', c.code, 'manual')
        await this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'sellerVatId', c.code, 'costsManual')
        if (c.address) {
            this.props.changeOCRData(file, 'address', c.address, 'manual')
            await this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'address', c.address, 'costsManual')
        }
        if (c.country) {
            this.props.changeOCRData(file, 'countryCode', c.country, 'manual')
            await this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'countryCode', c.country, 'costsManual')
        }
        if (c.last_swift) {
            this.props.changeOCRData(file, 'swift', c.last_swift, 'manual')
            await this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'swift', c.last_swift, 'costsManual')
        }
        if (c.last_account_number) {
            this.props.changeOCRData(file, 'bankData', c.last_account_number, 'manual')
            await this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'bankData', c.last_account_number, 'costsManual')
        }
        this.setState({
            showCounterpartiesList: false
        })
    }

    selectCounterpartForDocTypes = (c, file, docType) => {
        const { currentInvoiceImageData } = this.state
        this.props.changeOCRData(file, 'counterpartyName', c.name, '', docType)
        this.props.changeOCRData(file, 'counterpartyVatId', c.vat_id, '', docType)
        this.props.changeOCRData(file, 'counterpartyCode', c.code, '', docType)
        this.props.setNewCounterparty(false, file)
        this.setState({
            showCounterpartiesList: false
        })


        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'counterpartyName', c.name, docType.toLowerCase())
        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'counterpartyVatId', c.vat_id, docType.toLowerCase())
        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'counterpartyCode', c.code, docType.toLowerCase())
    }

    onInputChangeExpense = (e, i) => {
        const { name, value } = e.target

        let currentExpensesOnDoc = this.state.expensesOnDoc
        let expenseObject = currentExpensesOnDoc[i]

        expenseObject[name] = value

        currentExpensesOnDoc[i] = expenseObject

        this.setState({
            expensesOnDoc: currentExpensesOnDoc
        })

        if (name === 'tags') {
            const { currentInvoiceImageData } = this.state
            this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'expenses', JSON.stringify(currentExpensesOnDoc), 'expenses')
        }
    }

    scrollToArea = (e, name, possition) => {
        if (name === 'sellerName' && this.state.ocrMethod === 'manual') {
            this.setState({
                showCounterpartiesList: true
            })
        }

        const { cropper } = this.refs
        if (cropper && possition && possition.x1 !== 0 && this.state.currentInvoiceImageData.originalImageLoaded) {
            var scale = cropper.cropper.canvasData.naturalWidth / cropper.cropper.containerData.width;
            cropper.setCropBoxData({
                left: possition.x1 / scale,
                top: possition.y1 / scale,
                width: (possition.x2 - possition.x1) / scale,
                height: (possition.y2 - possition.y1) / scale
            })

            if (cropper.cropper.dragBox) {
                cropper.cropper.dragBox.classList.add('cropper-modal')
                cropper.cropper.cropBox.scrollIntoView({ behavior: "smooth", block: "center" });
                // scroll.scrollTo(cropper.cropper.cropBox.getBoundingClientRect().top + window.pageYOffset - 100)

                this.setTextPreviewBox(name, -1)
            }

            this.setState({
                focusedTextArea: '',
            })

            if (name === 'dueDate' || name === 'issueDate' || name === 'saleDate' || name === 'currencyRate') {
                var inputPosition = e.target.getBoundingClientRect()
                var top = inputPosition.bottom - 1
                var flipTop = false

                if (window.innerHeight - top < 210) {
                    flipTop = true
                    top -= inputPosition.height - 1

                    if (e.target.nodeName === 'SPAN') {
                        top -= 10
                    } else if (e.target.nodeName === 'LABEL') {
                        top -= 4
                    }
                } else {
                    if (e.target.nodeName === 'SPAN') {
                        top += 9
                    } else if (e.target.nodeName === 'LABEL') {
                        top += 32
                    }
                }

                this.setState({
                    focusedTextArea: name,
                    posXDatePicker: 0,
                    posYDatePicker: top,
                    posUpDatePicker: flipTop,
                    posLeftDatePicker: false,
                })
            }
        } else if (name === 'dueDate' || name === 'issueDate' || name === 'saleDate' || name === 'currencyRate') {
            this.setState({
                focusedTextArea: name,
            })

            if (possition === null) {
                var inputPosition = e.target.getBoundingClientRect()
                var top = inputPosition.bottom - 1
                var flipTop = false

                if (window.innerHeight - top < 210) {
                    flipTop = true
                    top -= inputPosition.height

                    if (e.target.nodeName === 'SPAN') {
                        top -= 10
                    } else if (e.target.nodeName === 'LABEL') {
                        top -= 4
                    }
                } else {
                    if (e.target.nodeName === 'SPAN') {
                        top += 8
                    } else if (e.target.nodeName === 'LABEL') {
                        top += 31
                    }
                }

                this.setState({
                    focusedTextArea: name,
                    posXDatePicker: 0,
                    posYDatePicker: top,
                    posUpDatePicker: flipTop,
                    posLeftDatePicker: false
                })
            }
        } else if (name === 'countryCode') {
            this.toggleCountriesList(true)
        }
    }

    scrollToAreaForExpenses = (e, possition, expenseIndex, name) => {
        console.log(possition)
        const { cropper } = this.refs
        if (cropper && possition && this.state.currentInvoiceImageData.originalImageLoaded) {
            var scale = cropper.cropper.canvasData.naturalWidth / cropper.cropper.containerData.width;
            cropper.setCropBoxData({
                left: possition.x1 / scale,
                top: possition.y1 / scale,
                width: (possition.x2 - possition.x1) / scale,
                height: (possition.y2 - possition.y1) / scale
            })

            if (cropper.cropper.dragBox) {
                cropper.cropper.dragBox.classList.add('cropper-modal')
                cropper.cropper.cropBox.scrollIntoView({ behavior: "smooth", block: "center" });
                // scroll.scrollTo(cropper.cropper.cropBox.getBoundingClientRect().top + window.pageYOffset - 100)

                this.setTextPreviewBox(name, expenseIndex)
            }
        }

        this.setState({
            focusedTextArea: `${name}${expenseIndex}`
        })
    }

    showDatePickerExpenses = (e, i) => {
        var inputPosition = e.target.getBoundingClientRect()
        var top = inputPosition.bottom - 1
        var flipTop = false

        if (window.innerHeight - top < 210) {
            flipTop = true
            top -= inputPosition.height

            if (e.target.nodeName === 'SPAN') {
                top -= 9
            } else if (e.target.nodeName === 'LABEL') {
                top -= 3
            }
        } else {
            if (e.target.nodeName === 'SPAN') {
                top += 9
            } else if (e.target.nodeName === 'LABEL') {
                top += 32
            }
        }

        this.setState({
            focusedTextArea: `issueDate${i}`,
            focusedTextAreaIndex: i,
            posXDatePicker: 0,
            posYDatePicker: top,
            posUpDatePicker: flipTop,
            posLeftDatePicker: false,
        })
    }

    showDatePickerAgreement = (e, name, possition) => {
        if (name === 'date') {
            var inputPosition = e.target.getBoundingClientRect()
            var top = inputPosition.bottom - 1
            var flipTop = false

            if (window.innerHeight - top < 210) {
                flipTop = true
                top -= inputPosition.height

                if (e.target.nodeName === 'SPAN') {
                    top -= 10
                } else if (e.target.nodeName === 'LABEL') {
                    top -= 4
                }
            } else {
                if (e.target.nodeName === 'SPAN') {
                    top += 9
                } else if (e.target.nodeName === 'LABEL') {
                    top += 32
                }
            }

            this.setState({
                posXDatePicker: 0,
                posYDatePicker: top,
                posUpDatePicker: flipTop,
                posLeftDatePicker: false,
                focusedTextArea: 'date'
            })
        }
        const { cropper } = this.refs
        if (cropper && possition && possition.x1 !== 0 && this.state.currentInvoiceImageData.originalImageLoaded) {
            var scale = cropper.cropper.canvasData.naturalWidth / cropper.cropper.containerData.width;
            cropper.setCropBoxData({
                left: possition.x1 / scale,
                top: possition.y1 / scale,
                width: (possition.x2 - possition.x1) / scale,
                height: (possition.y2 - possition.y1) / scale
            })

            if (cropper.cropper.dragBox) {
                cropper.cropper.dragBox.classList.add('cropper-modal')
                cropper.cropper.cropBox.scrollIntoView({ behavior: "smooth", block: "center" });
                // scroll.scrollTo(cropper.cropper.cropBox.getBoundingClientRect().top + window.pageYOffset - 100)

                this.setTextPreviewBox(name, -1)
            }
        }
    }

    showDatePickerIncome = (e, name, possition) => {
        if (name === 'issueDate' || name === 'dueDate') {
            var inputPosition = e.target.getBoundingClientRect()
            var top = inputPosition.bottom - 1
            var flipTop = false


            if (window.innerHeight - top < 210) {
                flipTop = true
                top -= inputPosition.height - 1

                if (e.target.nodeName === 'SPAN') {
                    top -= 10
                } else if (e.target.nodeName === 'LABEL') {
                    top -= 4
                }
            } else {
                if (e.target.nodeName === 'SPAN') {
                    top += 9
                } else if (e.target.nodeName === 'LABEL') {
                    top += 32
                }
            }

            this.setState({
                posXDatePicker: 0,
                posYDatePicker: top,
                posUpDatePicker: flipTop,
                posLeftDatePicker: false,
                focusedTextArea: name
            })
        }
        const { cropper } = this.refs
        if (cropper && possition && possition.x1 !== 0 && this.state.currentInvoiceImageData.originalImageLoaded) {
            var scale = cropper.cropper.canvasData.naturalWidth / cropper.cropper.containerData.width;
            cropper.setCropBoxData({
                left: possition.x1 / scale,
                top: possition.y1 / scale,
                width: (possition.x2 - possition.x1) / scale,
                height: (possition.y2 - possition.y1) / scale
            })

            if (cropper.cropper.dragBox) {
                cropper.cropper.dragBox.classList.add('cropper-modal')
                cropper.cropper.cropBox.scrollIntoView({ behavior: "smooth", block: "center" });
                // scroll.scrollTo(cropper.cropper.cropBox.getBoundingClientRect().top + window.pageYOffset - 100)

                this.setTextPreviewBox(name, -1)
            }
        }
    }

    showDatePickerCorporate = (e, name, possition) => {
        if (name === 'date') {
            var inputPosition = e.target.getBoundingClientRect()
            var top = inputPosition.bottom - 1
            var flipTop = false


            if (window.innerHeight - top < 210) {
                flipTop = true
                top -= inputPosition.height

                if (e.target.nodeName === 'SPAN') {
                    top -= 10
                } else if (e.target.nodeName === 'LABEL') {
                    top -= 4
                }
            } else {
                if (e.target.nodeName === 'SPAN') {
                    top += 9
                } else if (e.target.nodeName === 'LABEL') {
                    top += 32
                }
            }

            this.setState({
                posXDatePicker: 0,
                posYDatePicker: top,
                posUpDatePicker: flipTop,
                posLeftDatePicker: false,
                focusedTextArea: 'date'
            })
        }
        const { cropper } = this.refs
        if (cropper && possition && possition.x1 !== 0 && this.state.currentInvoiceImageData.originalImageLoaded) {
            var scale = cropper.cropper.canvasData.naturalWidth / cropper.cropper.containerData.width;
            cropper.setCropBoxData({
                left: possition.x1 / scale,
                top: possition.y1 / scale,
                width: (possition.x2 - possition.x1) / scale,
                height: (possition.y2 - possition.y1) / scale
            })

            if (cropper.cropper.dragBox) {
                cropper.cropper.dragBox.classList.add('cropper-modal')
                cropper.cropper.cropBox.scrollIntoView({ behavior: "smooth", block: "center" });
                // scroll.scrollTo(cropper.cropper.cropBox.getBoundingClientRect().top + window.pageYOffset - 100)

                this.setTextPreviewBox(name, -1)
            }
        }
    }

    scrollToAreaForAgreement = (e, name) => {
        this.setState({
            focusedTextArea: name,
        })

        if (name === 'counterpartyName') {
            this.setState({
                showCounterpartiesList: true
            })
        }
    }

    blurAreaForAgreement = e => {
        this.setState({
            focusedTextArea: '',
        })
    }

    scrollToAreaIncome = (e, name) => {
        console.log(name)
        this.setState({
            focusedTextArea: name,
        })
    }

    blurAreaForIncome = e => {
        this.setState({
            focusedTextArea: '',
        })
    }

    generateCodeBasedOnName = (nameValue, file) => {
        if (nameValue) {
            var newCode = ''
            nameValue = nameValue.replace(/\"/g, '').replace(/\'/g, '')
            var nameParts = nameValue.split(/[\s-]/gm)

            if (nameParts.length > 1) {
                nameParts.forEach(part => {
                    newCode += part.slice(0, 1)
                })
            } else if (nameValue.length < 12) {
                newCode = nameValue
            } else {
                newCode = nameValue.slice(0, 3)
            }

            newCode = newCode.toUpperCase()

            this.props.changeOCRData(file, 'sellerVatId', newCode, 'manual')
        }
    }

    generateCodeBasedOnNameForAgreement = (nameValue, file) => {
        if (nameValue) {
            var newCode = ''
            nameValue = nameValue.replace(/\"/g, '').replace(/\'/g, '')
            var nameParts = nameValue.split(/[\s-]/gm)

            if (nameParts.length > 1) {
                nameParts.forEach(part => {
                    newCode += part.slice(0, 1)
                })
            } else if (nameValue.length < 12) {
                newCode = nameValue
            } else {
                newCode = nameValue.slice(0, 3)
            }

            newCode = newCode.toUpperCase()

            this.props.changeOCRData(file, 'counterpartyCode', newCode, '', 'AGREEMENT')
            this.props.setNewCounterparty(true, file)
        }
    }

    focusOutArea = (e, file, propName) => {

        var newTarget = e.relatedTarget || document.activeElement;
        if (newTarget.nodeName !== 'TEXTAREA' && newTarget.nodeName !== 'INPUT') {
            this.refs.cropper.cropper.dragBox.classList.remove('cropper-modal')
        }


        if (e.relatedTarget && e.relatedTarget.className.includes('counterparty-item')) {
            console.log('selected counterparty - not generating code')
        } else if (this.state.ocrMethod === 'manual' && e.target && e.target.name === 'sellerName') {
            this.generateCodeBasedOnName(e.target.value, file)
        }

        if (propName === 'invoiceNumber') {
            const { currentInvoiceImageData, ocrMethod } = this.state
            const { returnedOCRData } = this.props

            if (ocrMethod === 'select' && returnedOCRData.props) {
                if (returnedOCRData.props.find(e => e.name === 'invoiceNumber') && returnedOCRData.props.find(e => e.name === 'invoiceNumber').text &&
                    returnedOCRData.props.find(e => e.name === 'sellerVatId') && returnedOCRData.props.find(e => e.name === 'sellerVatId').text)
                    this.props.checkInvoiceNumber(returnedOCRData.props, currentInvoiceImageData.projectId, currentInvoiceImageData.dbId, currentInvoiceImageData.docType)
            } else if (returnedOCRData.manualProps) {
                if (returnedOCRData.manualProps.find(e => e.name === 'invoiceNumber') && returnedOCRData.manualProps.find(e => e.name === 'invoiceNumber').text &&
                    returnedOCRData.manualProps.find(e => e.name === 'sellerVatId') && returnedOCRData.manualProps.find(e => e.name === 'sellerVatId').text)
                    this.props.checkInvoiceNumber(returnedOCRData.manualProps, currentInvoiceImageData.projectId, currentInvoiceImageData.dbId, currentInvoiceImageData.docType)
            }
        }
    }

    focusOutAreaForExpenses = e => {
        const { currentInvoiceImageData, expensesOnDoc } = this.state
        this.refs.cropper.cropper.dragBox.classList.remove('cropper-modal')
        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'expenses', JSON.stringify(expensesOnDoc), 'expenses')
        // this.setState({
        //     textPreview: {
        //         possition: null,
        //         prop: null,
        //         expenseIndex: null,
        //     }
        // })
    }

    focusOutAreaForAgreements = (e, file) => {
        if (e.relatedTarget && e.relatedTarget.className.includes('counterparty-item') || this.state.oldCodeSelected) {
            console.log('selected counterparty - not generating code')
        } else if (e.target && e.target.name === 'counterpartyName') {
            this.generateCodeBasedOnNameForAgreement(e.target.value, file)
        }
    }

    focusOutAreaForCorporate = e => {
        this.refs.cropper.cropper.dragBox.classList.remove('cropper-modal')
    }

    focusOutAreaForOther = e => {
        this.refs.cropper.cropper.dragBox.classList.remove('cropper-modal')
    }

    focusOutAreaForProtocol = e => {
        this.refs.cropper.cropper.dragBox.classList.remove('cropper-modal')
    }

    focusOutAreaForCorrespondence = e => {
        this.refs.cropper.cropper.dragBox.classList.remove('cropper-modal')
    }

    savePartialOcrData = (linkedFiles) => {
        const { currentInvoiceImageData, comment, tags, paymentMethod, paymentType, priority, currency } = this.state
        const { user, returnedOCRData, currentCompany } = this.props

        var waproData = null, symfoniaData = null, WL_DevCosts = {}
        if (waproUsers.includes(user.id)) {
            waproData = this.state.waproData
        }
        if (currentCompany?.company_id === 1607 || user.id === 2 || user.id === 76) {
            symfoniaData = this.state.symfoniaData
        }
        if (devCostsProjects.includes(currentInvoiceImageData.projectId)) {
            WL_DevCosts = this.state.WL_DevCosts
        }

        this.props.updatePartialOCRData(currentInvoiceImageData.projectId, returnedOCRData.props, currentInvoiceImageData.dbId, currentInvoiceImageData.ftvId, comment, tags, paymentMethod, priority, currency, currentInvoiceImageData.docType, paymentType, currentInvoiceImageData.name, this.state.selectedLangFlag, returnedOCRData.newCounterparty, this.state.nextDocExists, waproData, symfoniaData, WL_DevCosts, linkedFiles, () => {
            const { user } = this.props
            if (user.new_easydocs_version) {
                this.goToDoc('next')
            } else {
                this.nextDocLinkRef.current.click()
            }
        })
    }

    savePartialManualData = (linkedFiles) => {
        var nextDocURL = null
        if (this.state.nextDocExists) {
            console.log(routes.panel.crop)
        }
        const { currentInvoiceImageData, comment, tags, paymentMethod, paymentType, currency, priority, documentDescription } = this.state
        const { returnedOCRData } = this.props

        if (returnedOCRData) {
            var manualOcr = returnedOCRData.manualProps
            if (manualOcr) {
                const { user, currentCompany } = this.props
                var waproData = null, symfoniaData = null, WL_DevCosts = {}
                if (waproUsers.includes(user.id)) {
                    waproData = this.state.waproData
                }
                if (currentCompany?.company_id === 1607 || user.id === 2 || user.id === 76) {
                    symfoniaData = this.state.symfoniaData
                }
                if (devCostsProjects.includes(currentInvoiceImageData.projectId)) {
                    WL_DevCosts = this.state.WL_DevCosts
                }

                this.props.updatePartialByHandOcr(currentInvoiceImageData.projectId, manualOcr, currentInvoiceImageData.dbId, currentInvoiceImageData.ftvId, paymentMethod, currency, currentInvoiceImageData.docType, paymentType, priority, comment, tags, currentInvoiceImageData.name, this.state.nextDocExists, waproData, symfoniaData, WL_DevCosts, linkedFiles, documentDescription, () => {
                    const { user } = this.props
                    if (user.new_easydocs_version) {
                        this.goToDoc('next')
                    } else {
                        this.nextDocLinkRef.current.click()
                    }
                })
            }
        }
    }

    savePartialExpenses = () => {
        var nextDocURL = null
        if (this.state.nextDocExists) {
            console.log(routes.panel.crop)
        }
        const { expensesOnDoc, currency, tags, priority, currentInvoiceImageData, documentDescription } = this.state

        const { user, currentCompany } = this.props
        var waproData = null, symfoniaData = null, WL_DevCosts = {}
        if (waproUsers.includes(user.id)) {
            waproData = this.state.waproData
        }
        if (currentCompany?.company_id === 1607 || user.id === 2 || user.id === 76) {
            symfoniaData = this.state.symfoniaData
        }
        if (devCostsProjects.includes(currentInvoiceImageData.projectId)) {
            WL_DevCosts = this.state.WL_DevCosts
        }

        this.props.updatePartialExpenses(expensesOnDoc, currentInvoiceImageData.projectId, currentInvoiceImageData.dbId, currentInvoiceImageData.ftvId, priority, currency, tags, 'DONE', currentInvoiceImageData.name, this.state.nextDocExists, waproData, symfoniaData, WL_DevCosts, documentDescription, () => {
            const { user } = this.props
            if (user.new_easydocs_version) {
                this.goToDoc('next')
            } else {
                this.nextDocLinkRef.current.click()
            }
        })
    }

    costSplitIsValid = (totalNetAmount, totalGrossAmount) => {
        const { costSplit, costSplitAllocation } = this.state
        var isValid = true
        if (costSplit) {
            var splitNetSum = 0, splitGrossSum = 0
            costSplitAllocation.forEach(split => {
                if (split.netAmount) {
                    splitNetSum += parseFloat(split.netAmount)
                } else {
                    isValid = false
                    this.props.alertWarn('Fill in the net amount in payment category division')
                }

                if (split.vatAmount) {
                } else {
                    isValid = false
                    this.props.alertWarn('Fill in the vat amount in payment category division')
                }

                if (split.grossAmount) {
                    splitGrossSum += parseFloat(split.grossAmount)
                } else {
                    isValid = false
                    this.props.alertWarn('Fill in the gross amount in payment category division')
                }
            })

            if (parseFloat(totalNetAmount).toFixed(2) !== parseFloat(splitNetSum).toFixed(2)) {
                this.props.alertWarn(this.context.t(
                    'The division of payments into categories is incorrec. The net sum of the division is {n1}, and the net amount entered in the form is {n2}',
                    {
                        n1: parseFloat(totalNetAmount).toFixed(2),
                        n2: parseFloat(splitNetSum).toFixed(2)
                    }
                ), 15000)
                isValid = false
            } else if (parseFloat(totalGrossAmount).toFixed(2) !== parseFloat(splitGrossSum).toFixed(2)) {
                this.props.alertWarn(this.context.t(
                    'The division of payments into categories is incorrec. The gross sum of the division is {n1}, and the gross amount entered in the form is {n2}',
                    {
                        n1: parseFloat(totalNetAmount).toFixed(2),
                        n2: parseFloat(splitNetSum).toFixed(2)
                    }
                ), 15000)
                isValid = false
            }

            return isValid
        }
        return true
    }

    mpkSplitIsValie = (totalNetAmount) => {
        const { mpkSplit } = this.state
        var isValid = true
        if (mpkSplit) {
            var splitNetSum = 0
            mpkSplit.forEach(split => {
                if (split.netAmount) {
                    splitNetSum += parseFloat(split.netAmount)
                } else {
                    isValid = false
                    this.props.alertWarn('Fill in the net amount in Controlling dimension split')
                }

                if (!split.mpkLevel1) {
                    isValid = false
                    this.props.alertWarn('Controlling dimension at the first level is required')
                }
            })

            if (
                parseFloat(totalNetAmount).toFixed(2) !== parseFloat(splitNetSum).toFixed(2)
            ) {
                isValid = false
                this.props.alertWarn(this.context.t(
                    'The controlling dimension split is incorrect. The net sum of the division is {n1}, and the net amount entered in the form is {n2}',
                    {
                        n1: parseFloat(totalNetAmount).toFixed(2),
                        n2: parseFloat(splitNetSum).toFixed(2)
                    }
                ), 15000)
            }

            return isValid
        }
        return true
    }

    checkIfAllPaymentInfoAreGiven = (formData, currency) => {
        var wasAccountProvided = false
        var wasSwiftProvided = false

        var formDataAccountNumberProp = formData.find(e => e.name === 'bankData')
        var formDataSwiftProp = formData.find(e => e.name === 'swift')
        wasAccountProvided = formDataAccountNumberProp && formDataAccountNumberProp.text
        wasSwiftProvided = currency === 'PLN' || (formDataSwiftProp && formDataSwiftProp.text)

        return wasAccountProvided && wasSwiftProvided
    }

    saveDocflow = async () => {
        const { currentInvoiceImageData, hasDocflowChangesToSave } = this.state
        if (hasDocflowChangesToSave) {

            this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'docflow', JSON.stringify(currentInvoiceImageData.docflow), '')

            this.setState({
                hasDocflowChangesToSave: false
            })


            if (this.nextDocLinkRef?.current) {
                this.nextDocLinkRef?.current.click()
            }
            else {
                this.props.history.push(routes.panel.dashboard)
            }
        } else {
            if (this.nextDocLinkRef?.current) {
                this.nextDocLinkRef?.current.click()
            }
            else {
                this.props.history.push(routes.panel.dashboard)
            }
        }
    }

    saveMPK = async () => {
        const { user, returnedOCRData } = this.props
        var totalNetAmount = returnedOCRData?.props?.find(e => e.name === 'netto')?.text || 0
        if (this.mpkSplitIsValie(totalNetAmount)) {
            const { currentInvoiceImageData, mpkSplit, hasDocflowChangesToSave } = this.state
            if (hasDocflowChangesToSave) {
                await new Promise(resolve => {
                    this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'docflow', JSON.stringify(currentInvoiceImageData.docflow), '', () => {
                        resolve()
                        this.setState({
                            hasDocflowChangesToSave: false
                        })
                    })
                })
            }
            this.props.saveMPK(currentInvoiceImageData.ftvId, currentInvoiceImageData.dbId, mpkSplit, (success, nextDocflowStep) => {
                if (success && nextDocflowStep) {
                    this.setState({
                        currentInvoiceImageData: {
                            ...currentInvoiceImageData,
                            currentDocflowStep: nextDocflowStep
                        }
                    })

                    if (nextDocflowStep?.users?.includes(user.id)) {
                    } else {
                        if (this.nextDocLinkRef?.current) {
                            this.nextDocLinkRef?.current.click()
                        }
                        else {
                            this.props.history.push(routes.panel.dashboard)
                        }
                    }
                }
            })
        }
    }

    saveDevEx = async () => {
        const { currentInvoiceImageData, WL_DevCosts, hasDocflowChangesToSave } = this.state
        if (hasDocflowChangesToSave) {
            await new Promise(resolve => {
                this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'docflow', JSON.stringify(currentInvoiceImageData.docflow), '', () => {
                    resolve()
                    this.setState({
                        hasDocflowChangesToSave: false
                    })
                })
            })
        }
        const { user } = this.props
        this.props.saveDevEx(currentInvoiceImageData.ftvId, currentInvoiceImageData.dbId, WL_DevCosts, (success, nextDocflowStep) => {
            if (success && nextDocflowStep) {
                this.setState({
                    currentInvoiceImageData: {
                        ...currentInvoiceImageData,
                        currentDocflowStep: nextDocflowStep
                    }
                })

                if (nextDocflowStep?.users?.includes(user.id)) {
                } else {
                    if (this.nextDocLinkRef?.current) {
                        this.nextDocLinkRef?.current.click()
                    }
                    else {
                        this.props.history.push(routes.panel.dashboard)
                    }
                }
            }
        })
    }

    dataIsValid = () => {
        var isValid = true
        const { currentInvoiceImageData } = this.state
        const { returnedOCRData } = this.props
        const { ocrMethod, paymentMethod, currency, acceptedAmounttoPay, costSplit } = this.state

        if (!currentInvoiceImageData.docflow) {
            isValid = false
            this.props.alertWarn('First, select the docflow for this document')
        } else if (['COSTS', 'EXPENSES_REPORT', 'MAG'].includes(currentInvoiceImageData.docType)) {
            if (ocrMethod === 'select') {
                if (returnedOCRData && returnedOCRData.props) {
                    var sellerVatId = returnedOCRData.props.find(e => e.name === 'sellerVatId')
                    var sellerName = returnedOCRData.props.find(e => e.name === 'sellerName')
                    if (sellerVatId && !sellerVatId.text) {
                        isValid = false;
                        this.props.alertWarn(this.context.t('Seller VAT-ID is required'))
                    } else if (sellerName && !sellerName.text) {
                        isValid = false;
                        this.props.alertWarn(this.context.t('Seller name is required'))
                    }

                    if (currentInvoiceImageData?.docflow?.has_devex && costSplit) {
                        var totalNetAmount = returnedOCRData.props.find(e => e.name === 'netto')?.text
                        var totalGrossAmount = returnedOCRData.props.find(e => e.name === 'brutto')?.text

                        if (!this.costSplitIsValid(totalNetAmount, totalGrossAmount)) {
                            isValid = false
                        }
                    }

                    if (currentInvoiceImageData?.docflow?.has_mpk) {
                        var totalNetAmount = returnedOCRData.props.find(e => e.name === 'netto')?.text
                        if (!this.mpkSplitIsValie(totalNetAmount)) {
                            isValid = false
                        }
                    }

                    if (returnedOCRData.props.find(element => element.name === "dueDate") && paymentMethod === 'not-paid') {
                        var dueDateText = returnedOCRData.props.find(element => element.name === "dueDate").text
                        if (!dueDateText || !this.checkIfDateIsValid(dueDateText)) {
                            this.props.alertWarn(this.context.t('The format of due date couldn\'t be recognized. Please make sure that it is dd-mm-yyyy or yyyy-mm-dd.'))
                            isValid = false
                        }
                    }

                    if (returnedOCRData.props.find(element => element.name === "issueDate")) {
                        var issueDateText = returnedOCRData.props.find(element => element.name === "issueDate").text
                        if (!issueDateText || !this.checkIfDateIsValid(issueDateText)) {
                            this.props.alertWarn(this.context.t('The format of issue date couldn\'t be recognized. Please make sure that it is dd-mm-yyyy or yyyy-mm-dd.'))
                            isValid = false
                        }
                    }

                    if (returnedOCRData.props.find(element => element.name === "saleDate")) {
                        var saleDateText = returnedOCRData.props.find(element => element.name === "saleDate").text
                        if (!this.checkIfDateIsValid(saleDateText)) {
                            this.props.alertWarn(this.context.t('The format of sale date couldn\'t be recognized. Please make sure that it is dd-mm-yyyy or yyyy-mm-dd.'))
                            isValid = false
                        }
                    }

                    if (!acceptedAmounttoPay && this.state.currentProjectRole !== 'USER1' && paymentMethod === 'not-paid') {
                        this.props.alertWarn(this.context.t('Confirm that the amount to pay is correct.'))
                        isValid = false
                    }

                    if (paymentMethod === 'not-paid' && !this.checkIfAllPaymentInfoAreGiven(returnedOCRData.props, currency)) {
                        if ([186, 460, 459, 181].includes(currentInvoiceImageData.projectId) && currency === 'CZK') {
                            // wyjątek dla psmolarski@gmail.com ticket https://easydocs.freshdesk.com/a/tickets/1224
                        } else {
                            if (currency === 'PLN') {
                                this.props.alertWarn(this.context.t('Account number is required for unpaid documents'))
                            } else {
                                this.props.alertWarn(this.context.t('Account number and SWIFT are required for unpaid documents'))
                            }
                            isValid = false
                        }
                    }
                }
            } else if (ocrMethod === 'manual') {
                if (!returnedOCRData.manualProps) isValid = false
                else {
                    var manualOcr = returnedOCRData.manualProps
                    if (manualOcr) {
                        var sellerVatId = manualOcr.find(e => e.name === 'sellerVatId')
                        var sellerName = manualOcr.find(e => e.name === 'sellerName')
                        var buyerVatId = manualOcr.find(e => e.name === 'buyerVatId')
                        var buyerName = manualOcr.find(e => e.name === 'buyerName')
                        if (!sellerVatId.text || sellerVatId.text.length === 0 || !sellerName.text || sellerName.text.length === 0) {
                            isValid = false;
                            this.props.alertWarn(this.context.t('Informations about seller are required.'))
                        } else if (!buyerVatId.text || buyerVatId.text.length === 0 || !buyerName.text || buyerName.text.length === 0) {
                            isValid = false;
                            this.props.alertWarn(this.context.t('Informations about buyer are required.'))
                        }

                        if (paymentMethod === 'not-paid' && manualOcr.find(e => e.name === 'dueDate')) {
                            var dueDateText = manualOcr.find(e => e.name === 'dueDate').text
                            if (!dueDateText || !this.checkIfDateIsValid(dueDateText)) {
                                this.props.alertWarn(this.context.t('The format of due date couldn\'t be recognized. Please make sure that it is dd-mm-yyyy or yyyy-mm-dd.'))
                                isValid = false
                            }
                        }

                        if (manualOcr.find(e => e.name === 'issueDate')) {
                            var issueDateText = manualOcr.find(e => e.name === 'issueDate').text
                            if (!issueDateText || !this.checkIfDateIsValid(issueDateText)) {
                                this.props.alertWarn(this.context.t('The format of issue date couldn\'t be recognized. Please make sure that it is dd-mm-yyyy or yyyy-mm-dd.'))
                                isValid = false
                            }
                        }

                        if (manualOcr.find(element => element.name === "saleDate")) {
                            var saleDateText = manualOcr.find(element => element.name === "saleDate").text
                            if (!this.checkIfDateIsValid(saleDateText)) {
                                this.props.alertWarn(this.context.t('The format of sale date couldn\'t be recognized. Please make sure that it is dd-mm-yyyy or yyyy-mm-dd.'))
                                isValid = false
                            }
                        }

                        if (!acceptedAmounttoPay && this.state.currentProjectRole !== 'USER1' && paymentMethod === 'not-paid') {
                            this.props.alertWarn(this.context.t('Confirm that the amount to pay is correct.'))
                            isValid = false
                        }
                    } else {
                        isValid = false
                    }
                }
            }
        } else if (currentInvoiceImageData.docType === 'EXPENSES') {
            const { expensesOnDoc, currency, tags } = this.state
            var listOfDocNumbers = []
            if (expensesOnDoc.length === 0) isValid = false
            expensesOnDoc.forEach(expense => {
                if (!expense.docNumber || expense.docNumber.length === 0) {
                    this.props.alertWarn(this.context.t('Document number is required'))
                    isValid = false;
                } else if (listOfDocNumbers.includes(expense.docNumber)) {
                    this.props.alertWarn(this.context.t('Document number {n} id used twice.', { n: expense.docNumber }))
                } else {
                    listOfDocNumbers.push(expense.docNumber)
                }
                if (!expense.price || expense.price.length === 0) {
                    this.props.alertWarn(this.context.t('Price is required.'))
                    isValid = false;
                }
                if (!expense.issueDate || expense.issueDate.length === 0) {
                    this.props.alertWarn(this.context.t('Issue date is required'))
                    isValid = false;
                }
                if (!this.checkIfDateIsValid(expense.issueDate)) {
                    this.props.alertWarn(this.context.t('The format of issue date couldn\'t be recognized. Please make sure that it is dd-mm-yyyy or yyyy-mm-dd.'))
                    isValid = false
                }
                if (!expense.comment || expense.comment === 0) {
                    this.props.alertWarn(this.context.t('Comment is required.'))
                    isValid = false;
                }
            })
        } else if (currentInvoiceImageData.docType === 'SALE_INVOICE') {
            if (!returnedOCRData.incomeProps) isValid = false
            else returnedOCRData.incomeProps.map(p => {
                if (p.name === 'buyerName' && !p.text) {
                    isValid = false
                    this.props.alertWarn(this.context.t('Buyer name is required.'))
                } else if (p.name === 'issueDate' && (!p.text || !this.checkIfDateIsValid(p.text))) {
                    isValid = false
                    this.props.alertWarn(this.context.t('Issue date is invalid.'))
                } else if (p.name === 'saleDate' && (!p.text || !this.checkIfDateIsValid(p.text))) {
                    isValid = false
                    this.props.alertWarn(this.context.t('Sale date is invalid.'))
                } else if ((!paymentMethod || paymentMethod === 'not-paid') && p.name === 'dueDate' && (!p.text || !this.checkIfDateIsValid(p.text))) {
                    isValid = false
                    this.props.alertWarn(this.context.t('Due date is invalid.'))
                }
            })
        } else if (currentInvoiceImageData.docType === 'AGREEMENT') {
            if (!returnedOCRData.agreementProps) isValid = false
            else returnedOCRData.agreementProps.map(p => {
                if (p.name === 'counterpartyName' && !p.text) {
                    isValid = false
                    this.props.alertWarn(this.context.t('Counterparty name is required.'))
                } else if (p.name === 'date' && (!p.text || !this.checkIfDateIsValid(p.text))) {
                    isValid = false
                    this.props.alertWarn(this.context.t('Agreement date is invalid.'))
                } else if (p.name === 'number' && !p.text) {
                    isValid = false
                    this.props.alertWarn(this.context.t('Agreement number is required.'))
                }
            })
        } else if (currentInvoiceImageData.docType === 'CORPORATE') {
            if (!returnedOCRData.corporateProps) isValid = false
            else returnedOCRData.corporateProps.map(p => {
                if (p.name === 'date' && (!p.text || !this.checkIfDateIsValid(p.text))) {
                    isValid = false
                    this.props.alertWarn(this.context.t('Date is invalid.'))
                }
            })
        } else if (currentInvoiceImageData.docType === 'OTHER') {
            if (!returnedOCRData.otherProps) isValid = false
            else returnedOCRData.otherProps.map(p => {
                if (p.name === 'date' && (!p.text || !this.checkIfDateIsValid(p.text))) {
                    isValid = false
                    this.props.alertWarn(this.context.t('Date is invalid.'))
                }
            })
        } else if (currentInvoiceImageData.docType === 'PROTOCOL') {
            if (!returnedOCRData.protocolProps) isValid = false
            else returnedOCRData.protocolProps.map(p => {
                if (p.name === 'date' && (!p.text || !this.checkIfDateIsValid(p.text))) {
                    isValid = false
                    this.props.alertWarn(this.context.t('Date is invalid.'))
                }
            })
        } else if (currentInvoiceImageData.docType === 'CORRESPONDENCE') {
            if (!returnedOCRData.correspondenceProps) isValid = false
            else returnedOCRData.correspondenceProps.map(p => {
                if (p.name === 'from' && !p.text) {
                    isValid = false
                    this.props.alertWarn(this.context.t('Sender is required.'))
                } else if (p.name === 'date' && (!p.text || !this.checkIfDateIsValid(p.text))) {
                    isValid = false
                    this.props.alertWarn(this.context.t('Date is invalid.'))
                }
            })
        }

        return isValid
    }

    setWaningsAndErrorsVisibility = (warnings, errors) => {
        var warningsCount = 0, errorsCount = 0
        Object.keys(warnings).forEach(key => {
            if (warnings[key]) warningsCount++
        })
        Object.keys(errors).forEach(key => {
            if (errors[key]) errorsCount++
        })

        this.setState({
            formWarnings: warnings,
            formWarningsCount: warningsCount,
            formErrors: errors,
            formErrorsCount: errorsCount
        })
    }

    showWarnings = () => {
        this.setState({
            highlightWarnings: true
        })
    }

    hideWarnings = () => {
        this.setState({
            highlightWarnings: false
        })
    }

    showErrors = () => {
        this.setState({
            highlightErrors: true
        })
    }

    hideErrors = () => {
        this.setState({
            highlightErrors: false
        })
    }

    saveData = async (linkedFiles = {}, forceAccountSave, forceSimilarSave) => {
        var nextDocURL = null
        const { currentInvoiceImageData, documentDescription, hasDocflowChangesToSave } = this.state
        if (hasDocflowChangesToSave) {
            await new Promise(resolve => {
                this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'docflow', JSON.stringify(currentInvoiceImageData.docflow), '', () => {
                    resolve()
                    this.setState({
                        hasDocflowChangesToSave: false
                    })
                })
            })
        }

        if (forceAccountSave || forceSimilarSave) {
            this.saveCostsData(linkedFiles, forceAccountSave, forceSimilarSave)
        } else {
            this.props.verifyOcrForDoc(
                currentInvoiceImageData.ftvId,
                currentInvoiceImageData.dbId,
                currentInvoiceImageData.projectId,
                (success, userInNextStep) => {
                    if (success) {
                        if (userInNextStep) {
                            this.props.alertSuccess(this.context.t('Document has been verified.'))
                            if (['COSTS', 'EXPENSES_REPORT', 'MAG'].includes(currentInvoiceImageData.docType)) {
                                this.saveCostsData(linkedFiles, forceAccountSave, forceSimilarSave)
                            } else if (currentInvoiceImageData.docType === 'EXPENSES') {
                                this.saveExpensesData()
                            } else if (currentInvoiceImageData.docType === 'SALE_INVOICE') {
                                this.saveIncome()
                            } else if (currentInvoiceImageData.docType === 'AGREEMENT') {
                                this.saveAgreement()
                            } else if (currentInvoiceImageData.docType === 'CORPORATE') {
                                this.saveCorporate()
                            } else if (currentInvoiceImageData.docType === 'OTHER') {
                                this.saveOther()
                            } else if (currentInvoiceImageData.docType === 'PROTOCOL') {
                                this.saveProtocol()
                            } else if (currentInvoiceImageData.docType === 'CORRESPONDENCE') {
                                this.saveCorrespondence()
                            }
                        } else {
                            this.props.alertSuccess(this.context.t('Document has been verified.'))
                            if (this.nextDocLinkRef && this.nextDocLinkRef.current) {
                                this.nextDocLinkRef.current.click()
                            } else {
                                history.push(routes.panel.dashboard)
                            }
                        }
                    } else {
                        this.props.alertError(this.context.t('An error occurred while verifying the document.'))
                    }
                }
            )
        }
    }

    saveCostsData = (linkedFiles = {}, forceAccountSave, forceSimilarSave) => {
        const { currentInvoiceImageData, ocrMethod, documentDescription, comment, tags, emailToSendPaymentConfirmation, paymentMethod, paymentType, priority, currency, acceptedAmounttoPay, costSplit } = this.state
        const { returnedOCRData } = this.props

        var waproData = null, symfoniaData = null, WL_DevCosts = {}
        const { user, currentCompany } = this.props
        var waproData = null, symfoniaData = null, WL_DevCosts = {}
        if (waproUsers.includes(user.id)) {
            waproData = this.state.waproData
        }
        if (currentCompany?.company_id === 1607 || user.id === 2 || user.id === 76) {
            symfoniaData = this.state.symfoniaData
        }
        if (devCostsProjects.includes(currentInvoiceImageData.projectId)) {
            WL_DevCosts = this.state.WL_DevCosts
        }


        this.props.saveOCRData(currentInvoiceImageData.projectId, returnedOCRData.props, currentInvoiceImageData.dbId, currentInvoiceImageData.ftvId, comment, tags, paymentMethod, priority, currency, currentInvoiceImageData.docType, paymentType, 'DONE', true, this.state.nextDocExists, forceSimilarSave, waproData, symfoniaData, WL_DevCosts, linkedFiles, emailToSendPaymentConfirmation, documentDescription, () => {
            const { user } = this.props
            if (user.new_easydocs_version) {
                this.goToDoc('next')
            } else {
                this.nextDocLinkRef.current.click()
            }
        })
    }

    saveExpensesData = () => {
        const { currentInvoiceImageData, priority, expensesOnDoc, currency, documentDescription, tags, nextDocExists } = this.state
        this.props.saveExpenses(expensesOnDoc, currentInvoiceImageData.projectId, currentInvoiceImageData.dbId, currentInvoiceImageData.ftvId, currency, priority, tags, 'DONE', nextDocExists, null, null, null, documentDescription, () => {
            const { user } = this.props
            if (user.new_easydocs_version) {
                this.goToDoc('next')
            } else {
                this.nextDocLinkRef.current.click()
            }
        })
    }

    savePartialAgreement = () => {
        const { currentInvoiceImageData, comment, tags, priority, documentDescription } = this.state
        const { returnedOCRData } = this.props
        if (returnedOCRData) {
            this.props.savePartialAgreement(returnedOCRData.agreementProps, currentInvoiceImageData.projectId, currentInvoiceImageData.dbId, currentInvoiceImageData.ftvId, priority, tags, comment, this.state.currentInvoiceImageData.name, this.state.nextDocExists, documentDescription, () => {
                const { user } = this.props
                if (user.new_easydocs_version) {
                    this.goToDoc('next')
                } else {
                    this.nextDocLinkRef.current.click()
                }
            })
        }
    }

    saveAgreement = () => {
        const { currentInvoiceImageData, comment, tags, priority, documentDescription } = this.state
        const { returnedOCRData } = this.props
        if (returnedOCRData) {
            this.props.saveAgreement(returnedOCRData.agreementProps, currentInvoiceImageData.projectId, currentInvoiceImageData.dbId, currentInvoiceImageData.ftvId, priority, tags, comment, this.state.currentInvoiceImageData.name, this.state.nextDocExists, documentDescription, () => {
                const { user } = this.props
                if (user.new_easydocs_version) {
                    this.goToDoc('next')
                } else {
                    this.nextDocLinkRef.current.click()
                }
            })
        }
    }

    savePartialIncome = () => {
        const { currentInvoiceImageData, comment, paymentMethod, currency, tags, priority, documentDescription } = this.state
        const { returnedOCRData } = this.props
        if (returnedOCRData) {
            this.props.savePartialIncome(returnedOCRData.incomeProps, currentInvoiceImageData.projectId, currentInvoiceImageData.dbId, currentInvoiceImageData.ftvId, priority, tags, paymentMethod, comment, currency, this.state.currentInvoiceImageData.name, this.state.nextDocExists, documentDescription, () => {
                const { user } = this.props
                if (user.new_easydocs_version) {
                    this.goToDoc('next')
                } else {
                    this.nextDocLinkRef.current.click()
                }
            })
        }
    }

    saveIncome = () => {
        const { currentInvoiceImageData, comment, paymentMethod, currency, tags, priority, buyerCountryCode, documentDescription } = this.state
        const { returnedOCRData } = this.props
        if (returnedOCRData) {
            var valid = true
            if (returnedOCRData.incomeProps) {
                returnedOCRData.incomeProps.map(p => {
                    if (p.name === 'buyerName' && !p.text) {
                        valid = false
                        this.props.alertWarn(this.context.t('Buyer name is required.'))
                    } else if (p.name === 'issueDate' && (!p.text || !this.checkIfDateIsValid(p.text))) {
                        valid = false
                        this.props.alertWarn(this.context.t('Issue date is invalid.'))
                    } else if (p.name === 'saleDate' && (!p.text || !this.checkIfDateIsValid(p.text))) {
                        valid = false
                        this.props.alertWarn(this.context.t('Sale date is invalid.'))
                    } else if ((!paymentMethod || paymentMethod === 'not-paid') && p.name === 'dueDate' && (!p.text || !this.checkIfDateIsValid(p.text))) {
                        valid = false
                        this.props.alertWarn(this.context.t('Due date is invalid.'))
                    }
                })
            } else {
                valid = false
            }
            if (valid) {
                this.props.saveIncome(returnedOCRData.incomeProps, currentInvoiceImageData.projectId, currentInvoiceImageData.dbId, currentInvoiceImageData.ftvId, priority, tags, paymentMethod, comment, currency, this.state.currentInvoiceImageData.name, this.state.nextDocExists, buyerCountryCode, documentDescription, () => {
                    const { user } = this.props
                    if (user.new_easydocs_version) {
                        this.goToDoc('next')
                    } else {
                        this.nextDocLinkRef.current.click()
                    }
                })
            }
        }
    }

    savePartialCorrespondence = () => {
        const { currentInvoiceImageData, tags, priority, documentDescription } = this.state
        const { returnedOCRData } = this.props
        if (returnedOCRData) {
            this.props.savePartialCorrespondence(returnedOCRData.correspondenceProps, currentInvoiceImageData.projectId, currentInvoiceImageData.dbId, currentInvoiceImageData.ftvId, priority, tags, this.state.currentInvoiceImageData.name, this.state.nextDocExists, documentDescription, () => {
                const { user } = this.props
                if (user.new_easydocs_version) {
                    this.goToDoc('next')
                } else {
                    this.nextDocLinkRef.current.click()
                }
            })
        }
    }

    saveCorrespondence = () => {
        const { currentInvoiceImageData, tags, priority, documentDescription } = this.state
        const { returnedOCRData } = this.props
        if (returnedOCRData) {
            var valid = true
            if (returnedOCRData.correspondenceProps) {
                returnedOCRData.correspondenceProps.map(p => {
                    if (p.name === 'from' && !p.text) {
                        valid = false
                        this.props.alertWarn(this.context.t('Sender is required.'))
                    } else if (p.name === 'date' && (!p.text || !this.checkIfDateIsValid(p.text))) {
                        valid = false
                        this.props.alertWarn(this.context.t('Date is invalid.'))
                    }
                })
            } else {
                valid = false
            }
            if (valid) {
                this.props.saveCorrespondence(returnedOCRData.correspondenceProps, currentInvoiceImageData.projectId, currentInvoiceImageData.dbId, currentInvoiceImageData.ftvId, priority, tags, this.state.currentInvoiceImageData.name, this.state.nextDocExists, documentDescription, () => {
                    const { user } = this.props
                    if (user.new_easydocs_version) {
                        this.goToDoc('next')
                    } else {
                        this.nextDocLinkRef.current.click()
                    }
                })
            }
        }
    }

    savePartialCorporate = () => {
        const { currentInvoiceImageData, tags, priority, documentDescription } = this.state
        const { returnedOCRData } = this.props
        if (returnedOCRData) {
            this.props.savePartialCorporate(returnedOCRData.corporateProps, currentInvoiceImageData.projectId, currentInvoiceImageData.dbId, currentInvoiceImageData.ftvId, priority, tags, this.state.currentInvoiceImageData.name, this.state.nextDocExists, documentDescription, () => {
                const { user } = this.props
                if (user.new_easydocs_version) {
                    this.goToDoc('next')
                } else {
                    this.nextDocLinkRef.current.click()
                }
            })
        }
    }

    saveCorporate = () => {
        const { currentInvoiceImageData, tags, priority, documentDescription } = this.state
        const { returnedOCRData } = this.props
        if (returnedOCRData) {
            var valid = true
            if (returnedOCRData.corporateProps) {
                returnedOCRData.corporateProps.map(p => {
                    if (p.name === 'date' && (!p.text || !this.checkIfDateIsValid(p.text))) {
                        valid = false
                        this.props.alertWarn(this.context.t('Date is invalid.'))
                    }
                })
            } else {
                valid = false
            }
            if (valid) {
                this.props.saveCorporate(returnedOCRData.corporateProps, currentInvoiceImageData.projectId, currentInvoiceImageData.dbId, currentInvoiceImageData.ftvId, priority, tags, this.state.currentInvoiceImageData.name, this.state.nextDocExists, documentDescription, () => {
                    const { user } = this.props
                    if (user.new_easydocs_version) {
                        this.goToDoc('next')
                    } else {
                        this.nextDocLinkRef.current.click()
                    }
                })
            }
        }
    }

    savePartialOther = () => {
        const { currentInvoiceImageData, tags, priority, documentDescription } = this.state
        const { returnedOCRData } = this.props
        if (returnedOCRData) {
            this.props.savePartialOther(returnedOCRData.otherProps, currentInvoiceImageData.projectId, currentInvoiceImageData.dbId, currentInvoiceImageData.ftvId, priority, tags, this.state.currentInvoiceImageData.name, this.state.nextDocExists, documentDescription, () => {
                const { user } = this.props
                if (user.new_easydocs_version) {
                    this.goToDoc('next')
                } else {
                    this.nextDocLinkRef.current.click()
                }
            })
        }
    }

    saveOther = () => {
        const { currentInvoiceImageData, tags, priority, documentDescription } = this.state
        const { returnedOCRData } = this.props
        if (returnedOCRData) {
            var valid = true
            if (returnedOCRData.otherProps) {
                returnedOCRData.otherProps.map(p => {
                    if (p.name === 'date' && (!p.text || !this.checkIfDateIsValid(p.text))) {
                        valid = false
                        this.props.alertWarn(this.context.t('Date is invalid.'))
                    }
                })
            } else {
                valid = false
            }
            if (valid) {
                this.props.saveOther(returnedOCRData.otherProps, currentInvoiceImageData.projectId, currentInvoiceImageData.dbId, currentInvoiceImageData.ftvId, priority, tags, this.state.currentInvoiceImageData.name, this.state.nextDocExists, documentDescription, () => {
                    const { user } = this.props
                    if (user.new_easydocs_version) {
                        this.goToDoc('next')
                    } else {
                        this.nextDocLinkRef.current.click()
                    }
                })
            }
        }
    }

    savePartialProtocol = () => {
        const { currentInvoiceImageData, tags, priority, documentDescription } = this.state
        const { returnedOCRData } = this.props
        if (returnedOCRData) {
            this.props.savePartialProtocol(returnedOCRData.protocolProps, currentInvoiceImageData.projectId, currentInvoiceImageData.dbId, currentInvoiceImageData.ftvId, priority, tags, this.state.currentInvoiceImageData.name, this.state.nextDocExists, documentDescription, () => {
                const { user } = this.props
                if (user.new_easydocs_version) {
                    this.goToDoc('next')
                } else {
                    this.nextDocLinkRef.current.click()
                }
            })
        }
    }

    saveProtocol = () => {
        const { currentInvoiceImageData, tags, priority, documentDescription } = this.state
        const { returnedOCRData } = this.props
        if (returnedOCRData) {
            var valid = true
            if (returnedOCRData.protocolProps) {
                returnedOCRData.protocolProps.map(p => {
                    if (p.name === 'date' && (!p.text || !this.checkIfDateIsValid(p.text))) {
                        valid = false
                        this.props.alertWarn(this.context.t('Date is invalid.'))
                    }
                })
            } else {
                valid = false
            }
            if (valid) {
                this.props.saveProtocol(returnedOCRData.protocolProps, currentInvoiceImageData.projectId, currentInvoiceImageData.dbId, currentInvoiceImageData.ftvId, priority, tags, this.state.currentInvoiceImageData.name, this.state.nextDocExists, documentDescription, () => {
                    const { user } = this.props
                    if (user.new_easydocs_version) {
                        this.goToDoc('next')
                    } else {
                        this.nextDocLinkRef.current.click()
                    }
                })
            }
        }
    }

    checkIfDateIsValid = (dateText) => {
        if (dateText) {
            dateText = dateText.replace(/\,/g, '-').replace(/\./g, '-').replace(/\//g, '-').replace(/[^0-9\-]/gm, '')
            dateText = dateText.trim();
            if (datePattern.test(dateText)) return moment(dateText, 'DD-MM-YYYY').isValid()
            if (datePatternOneDayDigit.test(dateText)) return moment(dateText, 'D-MM-YYYY').isValid()
            if (datePatternOneMonthDigit.test(dateText)) return moment(dateText, 'DD-M-YYYY').isValid()
            if (datePatternReverse.test(dateText)) return moment(dateText, 'YYYY-MM-DD').isValid()
            else return false
        } else return true
    }

    switchMode = (e, fromSocket) => {
        const { currentInvoiceImageData, ocrMethod } = this.state
        if (fromSocket || (e && e.target && e.target.className && !e.target.className.includes('next'))) {
            if (ocrMethod === 'select') {
                this.setState({
                    ocrMethod: 'manual'
                })
                if (!fromSocket) this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'manualOCR', 'manual', '')
                this.loadGeneratedOcrToManual()
            } else {
                this.setState({
                    ocrMethod: 'select'
                })
                if (!fromSocket) this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'manualOCR', '', '')
            }
        }
    }

    setLangage = (e, flag, name) => {
        this.setState(prevState => ({
            selectedLangFlag: flag,
            selectedLang: name,
            langExpanded: !prevState.langExpanded
        }))

        this.setDivSize(e)
    }

    togglrLangList = () => {
        this.setState(prevState => ({
            langExpanded: !prevState.langExpanded
        }))
    }

    openDeleteModal = () => {
        const { currentInvoiceImageData } = this.state
        this.setState({
            deleteModal: true
        })
    }

    deleteInvoice = () => {
        const { currentInvoiceImageData, hasDocflowChangesToSave } = this.state
        const { user } = this.props
        if (currentInvoiceImageData.docflow?.id && !hasDocflowChangesToSave) {
            this.props.removeFileFromDocflow(
                currentInvoiceImageData.projectId,
                currentInvoiceImageData.dbId,
                currentInvoiceImageData.status,
                currentInvoiceImageData.name,
                false,
                user.first_name + ' ' + user.last_name,
                () => {
                    history.push(routes.panel.dashboard)
                })
        } else if (currentInvoiceImageData && currentInvoiceImageData.dbId) {
            this.props.deleteInvoice(
                currentInvoiceImageData.projectId,
                currentInvoiceImageData.dbId,
                currentInvoiceImageData.status,
                currentInvoiceImageData.name,
                !user?.new_easydocs_version,
                user.first_name + ' ' + user.last_name,
                () => {
                    if (user?.new_easydocs_version) {
                        const { currentTabIndex } = this.props
                        this.props.closeTab(currentTabIndex || -1)
                    } else {
                        history.push(routes.panel.dashboard)
                    }
                }
            )
            // this.props.permanentDeleteInvoice(this.state.currentInvoiceImageData.projectId, this.state.currentInvoiceImageData.dbId, this.state.currentInvoiceImageData.status, this.state.currentInvoiceImageData.name, true, user.first_name + ' ' + user.last_name)
        }
    }

    cancelDeleteInvoice = () => {
        this.setState({
            deleteModal: false,
        })
    }

    openMoveToOtherProjectModal = () => {
        this.setState({
            showMoveToOtherProjectModal: true
        })
    }

    moveToOtherProject = (newProjectId) => {
        this.props.moveToOtherProject(this.state.currentInvoiceImageData.dbId, this.state.currentInvoiceImageData.name, this.state.currentInvoiceImageData.projectId, newProjectId)

        if (this.state.projectMembers.length > 0) {
            this.props.getProjectMembersForExpense(newProjectId, (members) => {
                if (members) {
                    this.setState({
                        projectMembers: members
                    })
                }
            })
        }
    }

    cancelMoveToOtherProjectModal = () => {
        this.setState({
            showMoveToOtherProjectModal: false,
        })
    }

    selectPaymentMethod = (name, fromSocket) => {
        const { currentInvoiceImageData } = this.state
        this.setState({
            paymentMethod: name,
        }, () => this.setDivSize())
        if (!fromSocket) this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'payment_method', name, '')
    }

    selectExpenseOwner = (e, ftvId, dbId, userId, userName, projectId) => {
        this.setState({
            showExpenseUsersList: false
        })

        var { currentInvoiceImageData } = this.state
        currentInvoiceImageData.expenseOwnerId = userId
        currentInvoiceImageData.expenseOwnerName = userName

        this.setState({
            currentInvoiceImageData: currentInvoiceImageData
        })

        this.props.setExpenseOwner(ftvId, dbId, userId, userName, projectId)
    }

    selectCurrency = (name, fromSocket) => {
        const { currentInvoiceImageData } = this.state

        this.setState({
            currency: name,
        })
        if (!fromSocket) this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'currency', name, '')
        this.addCommentWithCurrencyRate(undefined, name)
        this.setDivSize()
    }

    selectPaymentType = (name, fromSocket) => {
        const { currentInvoiceImageData } = this.state
        this.setState({
            paymentType: name,
        })
        if (!fromSocket) this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'payment_type', name, '')

        this.setDivSize()
    }

    selectPriority = (name, fromSocket) => {
        const { currentInvoiceImageData } = this.state
        this.setState({
            priority: name,
        })
        if (!fromSocket) this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'priority', name, '')

        // this.setDivSize()
    }

    parseNameToTitle = (name) => {
        if (name === 'sellerData') return "Seller Data"
        else if (name === 'buyerData') return "Buyer Data"
        else if (name === 'invoiceNumber') return "Document Number"
        else if (name === 'bankData') return "Account Number"
        else if (name === 'issueDate') return "Issue Date"
        else if (name === 'dueDate') return "Due Date"
        else if (name === 'price') return "Price"
        else if (name === 'tax') return 'VAT'
        else return "Vat ID"
    }

    mouseEnterCropper = e => {
        if (this.state.renderFeameTooltip) {
            setTimeout(() => {
                this.setState({
                    renderFeameTooltip: false
                })
            }, 5000)
        }
    }

    showPropertyList = e => {
        const { currentInvoiceImageData } = this.state
        const { user } = this.props
        const { cropper, cropperContainer, cropperContainerCanvas } = this.refs
        if (currentInvoiceImageData) {
            var posUp = false
            var posLeft = false
            var transX = 0, transY = 0

            const { left: canvasLeft, width: canvasWidth } = cropper.cropper.canvas.getBoundingClientRect()
            const { left: cropBoxLeft, width: cropBoxWidth, top: cropBoxTop, height: cropBoxHeight } = cropper.cropper.cropBox.getBoundingClientRect()
            const canvasRightEdge = canvasLeft + canvasWidth
            const cropBoxRightEdge = cropBoxLeft + cropBoxWidth, cropBoxBottomEdge = cropBoxTop + cropBoxHeight

            transX = cropBoxRightEdge
            transY = cropBoxBottomEdge
            if (cropBoxRightEdge + 380 > canvasRightEdge) {
                posLeft = true
                // transX -= this.refs.cropper.getCropBoxData().width
            }
            if (cropBoxBottomEdge + 380 > window.innerHeight) {
                transY -= this.refs.cropper.getCropBoxData().height
                posUp = true
            }
            this.setState({
                posX: transX,
                posY: transY,
                posUp: posUp,
                posLeft: posLeft,
                showPropertyList: true,
            })

            this.refs.cropper.cropper.dragBox.classList.add('cropper-modal')
        }

        if (this.state.renderFeameTooltip) {
            this.setState({
                renderFeameTooltip: false
            })
        }
    }

    showPropertyListRightClick = e => {
        e.preventDefault()
        const { currentInvoiceImageData } = this.state
        const { user } = this.props
        const { cropper, cropperContainer } = this.refs
        if (currentInvoiceImageData && cropper && cropperContainer) {
            var posUp = false
            var posLeft = false
            var transX, transY

            const { left: canvasLeft, width: canvasWidth } = cropper.cropper.canvas.getBoundingClientRect()
            const { left: cropBoxLeft, width: cropBoxWidth, top: cropBoxTop, height: cropBoxHeight } = cropper.cropper.cropBox.getBoundingClientRect()
            const canvasRightEdge = canvasLeft + canvasWidth
            const cropBoxRightEdge = cropBoxLeft + cropBoxWidth, cropBoxBottomEdge = cropBoxTop + cropBoxHeight

            transX = cropBoxRightEdge
            transY = cropBoxBottomEdge
            if (cropBoxRightEdge + 380 > canvasRightEdge) {
                posLeft = true
                // transX -= this.refs.cropper.getCropBoxData().width
            }
            if (cropBoxBottomEdge + 380 > window.innerHeight) {
                posUp = true
            }
            this.setState({
                posX: transX,
                posY: transY,
                posUp: posUp,
                posLeft: posLeft,
                showPropertyList: true,
            })

            this.refs.cropper.cropper.dragBox.classList.add('cropper-modal')
        }
    }

    showDatePicker = e => {
        if (e.target.className && e.target.className.includes('dueDate') && this.refs.dueDatePropRef) {
            var inputPosition = e.target.getBoundingClientRect()
            var top = inputPosition.bottom - 1
            var flipTop = false

            if (window.innerHeight - top < 210) {
                flipTop = true
                top -= inputPosition.height
            }

            this.setState({
                posXDatePicker: 0,
                posYDatePicker: top,
                posUpDatePicker: flipTop,
                posLeftDatePicker: false,
                focusedTextArea: 'dueDate'
            })
            //this.refs.dueDatePropRef.scrollIntoView({behavior: "smooth", block: "start"});
        } else if (e.target.className && e.target.className.includes('issueDate')) {
            var inputPosition = e.target.getBoundingClientRect()
            var top = inputPosition.bottom - 1
            var flipTop = false

            if (window.innerHeight - top < 210) {
                flipTop = true
                top -= inputPosition.height
            }

            this.setState({
                posXDatePicker: 0,
                posYDatePicker: top,
                posUpDatePicker: flipTop,
                posLeftDatePicker: false,
                focusedTextArea: 'issueDate'
            })
            //this.refs.issueDatePropRef.scrollIntoView({behavior: "smooth", block: "start"});
        }
    }

    checkIfParentIncludesCalendarOrCalculator = element => {
        do {
            if (element.tagName === 'H3' && element.className && !element.className.includes('issueDate') && !element.className.includes('dueDate') && !element.className.includes('saleDate') && !element.className.includes('currencyRate')) {
                return false
            } else if (element.className && (element.className.includes('ocr-screen-date-picker') || element.className.includes('dueDate') || element.className.includes('issueDate') || element.className.includes('saleDate') || element.className.includes('date') || element.className.includes('netto') || element.className.includes('brutto') || element.className.includes('price'))) {
                return true;
            }
            element = element.parentNode;
        } while (element);
        return false;
    }

    hidePropertiesList = e => {
        var hiddenSomething = false
        const { focusedTextArea } = this.state
        const { cropperContainer } = this.refs
        if ((e.keyCode === 27 || !cropperContainer?.contains(e.target)) && this.state.showPropertyList) {
            e.preventDefault()
            this.setState({
                posX: 0,
                posY: 0,
                posUp: false,
                posLeft: false,
                showPropertyList: false,
            })
            hiddenSomething = true
        }
        if ((e.keyCode === 27 || !this.checkIfParentIncludesCalendarOrCalculator(e.target)) && (/date/i.test(focusedTextArea) || focusedTextArea === 'brutto' || focusedTextArea === 'netto' || focusedTextArea === 'price' || focusedTextArea === 'currencyRate')) {
            this.setState({
                focusedTextArea: ''
            })
            hiddenSomething = true
        }

        if ((e.keyCode === 27 || (!e.target.className.includes('tags-container') && !e.target.className.includes('tag-item'))) && this.state.showTagsList) {
            this.showTagsList(false)
            hiddenSomething = true
        }

        if ((e.keyCode === 27 || (!this.ancestorHasClass(e.target, "counterparties-list-container") && this.state.showCounterpartiesList)) && this.state.showCounterpartiesList) {
            this.setState({
                showCounterpartiesList: false
            })
            hiddenSomething = true
        }

        if ((e.keyCode === 27 || (!this.ancestorHasId(e.target, "countries-list-container") && !e.target.className.includes('country-item'))) && this.state.showCountriesList) {
            this.toggleCountriesList(false)
            hiddenSomething = true
        }

        if ((e.keyCode === 27 || (!this.ancestorHasId(e.target, "original-doc-input-container") && !e.target.className.includes('tag-item'))) && this.state.showOriginalDocNumbersList) {
            this.toggleOriginalDocNumbersList(null, false)
            hiddenSomething = true
        }

        if ((e.keyCode === 27 || (!this.ancestorHasId(e.target, "agreements-list") && !e.target.className.includes('tag-item'))) && this.state.showAgreementsList) {
            console.log('hidePropertiesList toggleAgreementsList()')
            this.toggleAgreementsList(null, false)
            hiddenSomething = true
        }

        return hiddenSomething
    }

    ancestorHasClass = (element, className) => {
        while (element) {
            if (element.className && element.className.includes(className)) return true

            element = element.parentNode
        }
        return false
    }

    ancestorHasId = (element, id) => {
        while (element) {
            if (element.id && element.id === id) return true

            element = element.parentNode
        }
        return false
    }

    addExpenseToDoc = e => {
        if (e) {
            e.preventDefault()
        }

        let prevExpensesOnDoc = this.state.expensesOnDoc
        prevExpensesOnDoc.push({ docNumber: '', issueDate: '', price: '', comment: '' })
        this.setState({
            expensesOnDoc: prevExpensesOnDoc
        })
    }

    deleteExpenseBlock = (e, i) => {
        if (e) e.preventDefault()
        const { currentInvoiceImageData } = this.state
        var prevExpensesOnDoc = this.state.expensesOnDoc

        prevExpensesOnDoc.splice(i, 1)
        this.setState({
            expensesOnDoc: prevExpensesOnDoc
        })

        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'expenses', JSON.stringify(prevExpensesOnDoc), 'expenses')
    }

    selectDocflow = (docflow, currentDocflowStep, fromSocket) => {
        var { currentInvoiceImageData } = this.state
        currentInvoiceImageData.docflow = docflow
        currentInvoiceImageData.currentDocflowStep = currentDocflowStep

        this.setState({
            currentInvoiceImageData: currentInvoiceImageData,
            hasDocflowChangesToSave: true
        })

        this.setDivSize()

        this.props.clearDataReadWithOcrFrame()
        // if (!fromSocket) this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'docflow', JSON.stringify(docflow), '')
    }

    selectDocType = (type, fromSocket) => {
        var { currentInvoiceImageData } = this.state
        currentInvoiceImageData.docType = type
        currentInvoiceImageData.userThatSetType = this.props.user.first_name + ' ' + this.props.user.last_name

        console.log('selectDocType')
        console.log('selectDocType')
        console.log('selectDocType')
        console.log('selectDocType')
        console.log(currentInvoiceImageData.docflow)
        if (currentInvoiceImageData.docflow) {
            if (currentInvoiceImageData.docflow?.doc_types && !currentInvoiceImageData.docflow?.doc_types?.includes(type)) {
                currentInvoiceImageData.docflow_id = null
                currentInvoiceImageData.docflow = null
                currentInvoiceImageData.currentDocflowStep = null
            }
        }

        this.setState({
            currentInvoiceImageData: currentInvoiceImageData,
            ocrMethod: 'select'
        })

        this.setDivSize()

        this.props.clearDataReadWithOcrFrame()
        if (!fromSocket) this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'doc_type', type, '')

        if (type === 'SALE_INVOICE') {
            this.loadGeneratedOcrToIncome()
        } else if (type === 'AGREEMENT') {
            this.loadGeneratedOcrToAgreement()
        } else if (type === 'CORRESPONDENCE') {
            this.loadGeneratedOcrToCorrespondence()
        } else if (type === 'CORPORATE') {
            this.loadGeneratedOcrToCorporate()
        } else if (type === 'OTHER') {
            this.loadGeneratedOcrToOther()
        }
    }

    getProjectName = () => {
        const {
            userProjects,
            user, location
        } = this.props
        var urlProjectId = 0
        if (!user.new_easydocs_version) {
            urlProjectId = parseInt(this.props.match.params.projectId)
        } else {
            var additionalUrlParams = location.pathname.replace('/ocr/', '')
            if (/^[0-9]{0,}\/[0-9]{0,}$/.test(additionalUrlParams)) {
                var projectAndFileArray = additionalUrlParams.split('/')
                const projectId = parseInt(projectAndFileArray[0])

                urlProjectId = projectId
            } else {
                if (this.props.urlProjectId) {
                    urlProjectId = this.props.urlProjectId
                }
            }
        }

        var project = userProjects.find(e => e.id === urlProjectId)
        if (project) {
            return project.code
        } else {
            return "undefined"
        }
    }

    toCapitalCase = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    handleRefreshOcrButton = () => {
        const { currentInvoiceImageData } = this.state
        var projectIndex = arrayObjectIndexOf(this.props.filesToVerifiLoaded, parseInt(currentInvoiceImageData.projectId), "project")
        if (projectIndex > -1) {
            var image = this.props.filesToVerifiLoaded[projectIndex].files.find(element => element.id === currentInvoiceImageData.dbId)
            image.imageData = this.state.currentInvoiceImageData.imageData
            image.originalImageLoaded = true
            var expensesOnDoc = []
            console.log(image.expenseOcr)
            if (image.expenseOcr) {
                image.expenseOcr.forEach(e => {
                    expensesOnDoc.push({
                        docNumber: e.docNumber,
                        docNumberPossition: e.docNumberPossition,
                        issueDate: e.issueDate,
                        issueDatePossition: e.issueDatePossition,
                        price: e.price,
                        pricePossition: e.pricePossition,
                        comment: e.comment
                    })
                })
            }

            this.setState({
                currentInvoiceImageData: image,
                loadedData: true,
                ocrMethod: image.docType === 'EXPENSES' ? 'manual' : 'select',
                paymentMethod: image.paymentMethod,
                paymentType: image.paymentType,
                currency: image.currency,
                priority: image.priority ? image.priority : 'regular',
                comment: image.comment,
                tags: image.tags,
                expensesOnDoc: expensesOnDoc.length ? expensesOnDoc : this.state.expensesOnDoc
            })
        }
        this.loadGeneratedOCR(false)
        this.props.hideRefreshOcrPopup()
    }

    handleGoToDashboardClick = () => {
        this.props.hideAcceptedOcrPopup()
        this.props.hideOcrScreenNotificationAboutDeletedFile()
        this.props.hideOcrScreenNotificationAboutMovedFile()

        const { user } = this.props
        if (user?.new_easydocs_version) {
            const { currentTabIndex } = this.props
            this.props.closeTab(currentTabIndex || -1)
        } else {
            history.push(routes.panel.dashboard)
        }
    }

    showTagsList = show => {
        this.setState({
            showTagsList: show
        })
    }

    setTags = value => {
        var { currentInvoiceImageData } = this.state
        this.setState({
            tags: value
        })
        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'tags', value, '')
    }

    tagClick = (t) => {
        var { tags, currentInvoiceImageData } = this.state

        if (tags.includes(t)) {
            tags = tags.replace(t, '')
            tags = tags.replace(' , ', ' ')
            tags = tags.replace(',,', ',')
        } else {
            if (tags.length > 0) {
                tags += `, ${t}`
            } else {
                tags += `${t}`
            }
        }

        tags = tags.trim()

        if (tags.startsWith(',')) {
            tags = tags.substring(1)
        }

        if (tags.endsWith(',')) {
            tags = tags.slice(0, -1)
        }

        tags = tags.trim()

        this.setState({
            tags: tags
        })
        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'tags', tags, '')
    }

    showOverlay = () => {
        const { overlayPreferance } = this.props
        this.setOverlaySize()
        overlayPreferance['ocrScreen'] = true
        this.props.toggleOverlay(overlayPreferance)
    }

    showProjectsList = () => {
        this.setState({
            showProjectsList: true
        })
    }

    downloadOriginalFile = (fileId, projectId, fileName) => {
        if (!this.props.ocrDownloadButtonLoading) {
            this.props.downloadOriginalFile(fileId, projectId, fileName)
        }
    }

    readWithDifferentOcr = currentOcrModel => {
        const { currentInvoiceImageData } = this.state
        var newOcrModel = currentOcrModel === 'PATRYK' ? 'BARDS' : 'PATRYK'
        this.props.readWithDifferentOcr(currentInvoiceImageData.dbId, newOcrModel, success => {
            if (success) {
                this.setState({
                    currentInvoiceImageData: {
                        ...currentInvoiceImageData,
                        used_ocr_model: newOcrModel
                    }
                })
            }
        })
    }

    goToDoc = direction => {
        const { currentInvoiceImageData } = this.state
        const { filesToVerifiLoaded, user } = this.props
        var projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, parseInt(currentInvoiceImageData.projectId), "project")

        if (projectIndex > -1) {
            var fileIndex = arrayObjectIndexOf(filesToVerifiLoaded[projectIndex].files, parseInt(currentInvoiceImageData.dbId), "dbId")

            if (direction === 'prev') {
                if (fileIndex > 0) {
                    if (user.new_easydocs_version) {
                        this.props.changeOcrTabDoc(filesToVerifiLoaded[projectIndex].files[fileIndex - 1].projectId, filesToVerifiLoaded[projectIndex].files[fileIndex - 1].dbId)
                    } else {
                        var docUrl = `/ocr/${filesToVerifiLoaded[projectIndex].files[fileIndex - 1].projectId}/${filesToVerifiLoaded[projectIndex].files[fileIndex - 1].dbId}`
                        var a = document.createElement('a');
                        a.href = docUrl;
                        a.click();
                    }
                }

            } else {
                if (fileIndex > -1 && fileIndex < filesToVerifiLoaded[projectIndex].files.length - 1) {
                    if (user.new_easydocs_version) {
                        this.props.changeOcrTabDoc(filesToVerifiLoaded[projectIndex].files[fileIndex + 1].projectId, filesToVerifiLoaded[projectIndex].files[fileIndex + 1].dbId)
                    } else {
                        var docUrl = `/ocr/${filesToVerifiLoaded[projectIndex].files[fileIndex + 1].projectId}/${filesToVerifiLoaded[projectIndex].files[fileIndex + 1].dbId}`
                        var a = document.createElement('a');
                        a.href = docUrl;
                        a.click();
                    }
                }
            }
        }
    }

    getTextForPreview = (docType, expensesOnDoc) => {
        const { textPreview } = this.state
        const { returnedOCRData } = this.props
        if (textPreview.prop) {
            if (docType === 'EXPENSES') {
                if (expensesOnDoc && expensesOnDoc[textPreview.expenseIndex] && expensesOnDoc[textPreview.expenseIndex][textPreview.prop])
                    return expensesOnDoc[textPreview.expenseIndex][textPreview.prop]
                else
                    return ''
            } else if (docType === 'AGREEMENT' && returnedOCRData.agreementProps) {
                var prop = returnedOCRData.agreementProps.find(p => p.name === textPreview.prop)
                if (prop) {
                    return prop.text
                } else {
                    return ''
                }
            } else if (docType === 'SALE_INVOICE' && returnedOCRData.incomeProps) {
                var prop = returnedOCRData.incomeProps.find(p => p.name === textPreview.prop)
                if (prop) {
                    return prop.text
                } else {
                    return ''
                }
            } else {
                if (this.state.ocrMethod === 'select' && returnedOCRData.props) {
                    var prop = returnedOCRData.props.find(p => p.name === textPreview.prop)
                    if (prop) {
                        return prop.text
                    } else {
                        return ''
                    }
                } else if (this.state.ocrMethod === 'manual' && returnedOCRData.manualProps) {
                    var prop = returnedOCRData.manualProps.find(p => p.name === textPreview.prop)
                    if (prop) {
                        return prop.text
                    } else {
                        return ''
                    }
                }
            }
        } else {
            return ''
        }

    }

    showCalculator = (e, propName, position) => {
        var inputPosition = e.target.getBoundingClientRect()
        var top = inputPosition.bottom
        var flipTop = false

        if (window.innerHeight - top < 300) {
            flipTop = true
            top -= inputPosition.height + 9
        } else {
            top += 7
        }

        this.setState({
            focusedTextArea: propName,
            posXCalculator: 0,
            posYCalculator: top,
            posUpCalculator: flipTop
        })
    }

    cropperMouseDown = e => {
        this.setState({
            textPreview: {},

            showPropertyList: false
        })
    }

    selectMagDocType = type => {
        this.setState({
            selectedMagDocType: type
        })
    }

    selectFakirDocType = type => {
        this.setState({
            selectedFakirDocType: type
        })
    }

    selectFakirDocDictionary = type => {
        this.setState({
            selectedFakirDocDictionary: type
        })
    }

    selectFakirNetAccounts = type => {
        this.setState({
            selectedFakirNetAccounts: type
        })
    }

    selectDocDescription = value => {
        this.setState({
            selectedDocDescription: value,
        })
    }

    setWaproData = newData => {
        console.log(newData)
        this.setState({
            waproData: newData
        })
    }

    setSymfoniaData = newData => {
        this.setState({
            symfoniaData: newData
        })
    }

    setWL_DevCosts = newData => {
        this.setState({
            WL_DevCosts: newData
        })
    }

    setCostSplitAllocation = newData => {
        this.setState({
            costSplitAllocation: newData
        })
    }

    setCostSplit = value => {
        var costSplitAllocationToSet = []
        if (value) {
            costSplitAllocationToSet = [
                {
                    netAmount: '',
                    vatAmount: '',
                    grossAmount: '',
                    comment: '',
                    tags: '',
                    waproNetAccount: '',
                    id: -1,
                },
                {
                    netAmount: '',
                    vatAmount: '',
                    grossAmount: '',
                    comment: '',
                    tags: '',
                    waproNetAccount: '',
                    id: -1,
                }
            ]
        }
        this.setState({
            costSplit: value,
            costSplitAllocation: costSplitAllocationToSet,
        })

        const { currentInvoiceImageData } = this.state
        if (value) {
            this.props.addCostSplitForFile(currentInvoiceImageData.dbId, currentInvoiceImageData.ftvId, ids => {
                var costSplitAllocation = [...this.state.costSplitAllocation]
                if (ids) {
                    ids.forEach((id, index) => {
                        costSplitAllocation[index].id = id
                    })
                }
                console.log(costSplitAllocation)
                this.setState({
                    costSplitAllocation: costSplitAllocation
                })
            })
        } else {
            this.props.deleteCostSplitForFile(currentInvoiceImageData.dbId, currentInvoiceImageData.ftvId)
        }
    }

    setMpkSplit = newData => {
        console.log(`mpk: ${JSON.stringify(newData)}`)
        if (newData.length > 0) {
            this.setState({
                mpkSplit: newData
            })
        }
    }

    setSellerCountryCode = (value, propName, ocrMethod, file) => {
        this.props.changeOCRData(file, propName, value, ocrMethod)
        this.setState({
            showCountriesList: false
        })
    }

    toggleCountriesList = show => {
        this.setState({
            showCountriesList: show !== undefined ? show : !this.state.showCountriesList
        })
    }

    selectOriginalDocNumber = doc => {
        this.setState({
            originalDocNumber: doc.doc_number,
            originalDocId: parseInt(doc.file_id),
            showOriginalDocNumbersList: false
        })
    }

    selectAgreement = agreement => {
        this.setState({
            selectedAgreement: agreement,
            showAgreementsList: false
        })
    }

    toggleAgreementsList = (e, show) => {
        if (e && e.target) {
            var inputPosition = e.target.getBoundingClientRect()
            var top = inputPosition.bottom
            var flipTop = false

            if (window.innerHeight - top < 400) {
                flipTop = true
                top -= inputPosition.height
            }
            this.setState({
                showAgreementsList: show !== undefined ? show : !this.state.showAgreementsList,
                agreementsListPos: {
                    x: 0,
                    y: top,
                    flipTop: flipTop,
                }
            })
        } else {
            this.setState({
                showAgreementsList: show !== undefined ? show : !this.state.showAgreementsList
            })
        }
    }

    toggleOriginalDocNumbersList = (e, show) => {
        if (e && e.target) {
            var inputPosition = e.target.getBoundingClientRect()
            var top = inputPosition.bottom
            var flipTop = false

            if (window.innerHeight - top < 400) {
                flipTop = true
                top -= inputPosition.height
            }
            this.setState({
                showOriginalDocNumbersList: show !== undefined ? show : !this.state.showOriginalDocNumbersList,
                originalDocsListPos: {
                    x: 0,
                    y: top,
                    flipTop: flipTop,
                }
            })
        } else {
            this.setState({
                showOriginalDocNumbersList: show !== undefined ? show : !this.state.showOriginalDocNumbersList
            })
        }
    }

    confirmDataCheckbox = () => {
        this.setState({ dataConfirmCheckbox: !this.state.dataConfirmCheckbox })
        const { currentInvoiceImageData } = this.state
        const { returnedOCRData } = this.props
        if (this.state.dataConfirmCheckbox === false) {
            if (['COSTS', 'EXPENSES_REPORT', 'MAG'].includes(currentInvoiceImageData.docType)) {
                if (this.state.ocrMethod !== 'select') {
                    if (returnedOCRData.manualProps) {
                        var manualOcr = returnedOCRData.manualProps
                        let netto = manualOcr.find(e => e.name === 'netto').text
                        if (netto === '') netto = 0
                        let brutto = manualOcr.find(e => e.name === 'brutto').text
                        if (brutto === '') brutto = 0
                        if (parseFloat(netto) !== parseFloat(brutto)) {
                            this.props.alertWarn(this.context.t('Note: net and gross value are different.'))
                        }
                    }
                }
            }
        }

    }

    testDateYYYYMMDD = date => {
        const matchFirstForDigit = /^\d{4}/g
        return matchFirstForDigit.test(date)
    }

    addCommentWithCurrencyRate = async (date, currency) => {
        const { currentInvoiceImageData, ocrMethod, currencyRateDate } = this.state
        const { returnedOCRData } = this.props
        const selectedCurrency = currency ? currency : this.state.currency
        if (selectedCurrency !== "PLN") {
            if (date) {
                const currencyRate = await getCurrencyRateFromNBP(selectedCurrency, moment(date, this.testDateYYYYMMDD(date) ? "YYYY-MM-DD" : "DD-MM-YYYY").subtract(1, 'days').format('YYYY-MM-DD'))
                if (currencyRate) {
                    const { rate, effectiveDate, tableNumber } = currencyRate
                    this.setState({
                        currencyRateDate: effectiveDate
                    })
                    this.props.changeOCRData(currentInvoiceImageData.dbId, 'currencyRate', rate.toString(), ocrMethod === 'select' ? 'select' : 'manual')
                    this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'currencyRate', rate.toString(), ocrMethod === 'select' ? 'costsOCR' : 'costsManual')
                }
            } else {
                if (returnedOCRData && returnedOCRData.props) {
                    const issueDate = returnedOCRData.props.find(prop => prop.name === 'issueDate').text
                    if (issueDate) {
                        const currencyRate = await getCurrencyRateFromNBP(selectedCurrency, moment(issueDate, this.testDateYYYYMMDD(issueDate) ? "YYYY-MM-DD" : "DD-MM-YYYY").subtract(1, 'days').format('YYYY-MM-DD'))
                        if (currencyRate) {
                            const { rate, effectiveDate, tableNumber } = currencyRate
                            this.setState({
                                currencyRateDate: effectiveDate
                            })
                            this.props.changeOCRData(currentInvoiceImageData.dbId, 'currencyRate', rate.toString(), ocrMethod === 'select' ? 'select' : 'manual')
                            this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'currencyRate', rate.toString(), ocrMethod === 'select' ? 'costsOCR' : 'costsManual')
                        }
                    }
                }
            }

        }
    }

    selectBayerCountryHandler = (value) => {
        this.setState({ buyerCountryCode: value })
        this.toggleCountriesList()
    }

    convertUsersToAvatars = () => {
        const { otherUsersWorkingOnThisDoc } = this.state
        if (otherUsersWorkingOnThisDoc.length > 0) {
            var maxDisplayedPhotos = 4
            var usersCount = otherUsersWorkingOnThisDoc.length
            var displayNumberOfAdditionalMembers = false
            var additionalMembersNames = []
            var photosToReturn = []
            var helperDivIndex = usersCount

            if (usersCount > maxDisplayedPhotos) helperDivIndex = maxDisplayedPhotos + 1

            otherUsersWorkingOnThisDoc.forEach((u, i) => {
                if (i < maxDisplayedPhotos) {
                    if (u.photo) {
                        photosToReturn.push(
                            <div className={`user number-${(helperDivIndex - i)}`} data-for={`user-${u.id}`} data-tip='show'>
                                <img src={u.photo} alt={u.name} />
                            </div>
                        )
                        photosToReturn.push(
                            <ReactTooltip id={`user-${u.id}`} className="default-tooltip event-members-tooltip" place="top" effect='solid'>
                                <p>{u.name}</p>
                            </ReactTooltip>
                        )
                    } else {
                        photosToReturn.push(
                            <div className={`user number-${(helperDivIndex - i)} initials`} data-for={`user-${u.id}`} data-tip='show'>
                                {
                                    u.name.split(' ').map((part, i) => {
                                        if (i < 2) return part[0]
                                        return null
                                    })
                                }
                            </div>
                        )
                        photosToReturn.push(
                            <ReactTooltip id={`user-${u.id}`} className="default-tooltip event-members-tooltip" place="top" effect='solid'>
                                <p>{u.name}</p>
                            </ReactTooltip>
                        )
                    }
                } else {
                    displayNumberOfAdditionalMembers = true

                    additionalMembersNames.push(u.name)
                }
            })

            if (displayNumberOfAdditionalMembers) {
                photosToReturn.push(
                    <div className={`user number-1 additional-members`} data-for={`additional-users`} data-tip='show'>
                        {`+${usersCount - maxDisplayedPhotos}`}
                    </div>
                )

                photosToReturn.push(
                    <ReactTooltip id={`additional-users`} className="default-tooltip event-members-tooltip" place="top" effect='solid'>
                        {
                            additionalMembersNames.map(m => {
                                return (<p>{m}</p>)
                            })
                        }
                    </ReactTooltip>
                )
            }

            return photosToReturn
        } else {
            return null
        }
    }

    checkNext = () => {
        return { index: 0, prev: false, next: false }
    }

    returnDocTypeForm = () => {
        const {
            calcHeight,
            currentInvoiceImageData, errorFields,
            acceptedAmounttoPay, dataConfirmCheckbox,
            currency, currencyRateDate,
            documentDescription,
            comment,
            tags, tagsList, tagsCreationLimitation, showTagsList,
            emailToSendPaymentConfirmation,
            counterpartiesList, showCounterpartiesList,
            paymentMethod, paymentType, priority,
            posYDatePicker, posUpDatePicker,
            focusedTextArea,
            showAccountNumberWarningBeforeSave,
            selectedMagDocType, selectedFakirDocType, selectedFakirDocDictionary, selectedFakirNetAccounts, selectedDocDescription,
            showCountriesList, buyerCountryCode,
            highlightWarnings, highlightErrors,
        } = this.state;
        if (['COSTS', 'EXPENSES_REPORT', 'MAG'].includes(currentInvoiceImageData.docType)) {
            return <OcrPageCostsForm
                setFormRef={this.fromRef}
                setButtonsRef={this.buttonsRef}
                ocrMethod={this.state.ocrMethod}
                companyId={this.state.companyId}
                docOwnerCompany={this.state.docOwnerCompany}
                currentInvoiceImageData={currentInvoiceImageData}
                currentProjectRole={this.state.currentProjectRole}
                isAccountantInCurrentCompany={this.state.isAccountantInCurrentCompany}
                paymentMethod={paymentMethod}
                currency={currency}
                paymentType={paymentType}
                priority={priority}
                documentDescription={documentDescription}
                currencyRateDate={currencyRateDate}
                comment={comment}
                tags={tags}
                toggleNoSellerVatId={() => this.setState({ noSellerVatId: !this.state.noSellerVatId })}
                noSellerVatId={this.state.noSellerVatId}
                scrollToArea={this.scrollToArea}
                onInputChange={this.onInputChange}
                acceptedAmounttoPay={acceptedAmounttoPay}
                showAccountNumberWarningBeforeSave={showAccountNumberWarningBeforeSave}
                showTagsList={showTagsList}
                tagsList={tagsList}
                tagsCreationLimitation={tagsCreationLimitation}
                showTagsListFunc={this.showTagsList}
                setTags={this.setTags}
                tagClick={this.tagClick}
                counterpartiesList={counterpartiesList}
                showCounterpartiesList={showCounterpartiesList}
                selectCounterpart={this.selectCounterpart}
                selectCounterpartOcrData={this.selectCounterpartOcrData}
                hideAccountNumberWarning={() => this.setState({ showAccountNumberWarningBeforeSave: false })}
                acceptAmountToPay={() => this.setState({ acceptedAmounttoPay: !acceptedAmounttoPay })}
                setComment={e => this.setState({ comment: e.target.value })}
                setDocComment={e => this.setState({ documentDescription: e.target.value })}
                emailToSendPaymentConfirmation={emailToSendPaymentConfirmation}
                setEmailToSendPaymentConfirmation={value => this.setState({ emailToSendPaymentConfirmation: value })}
                saveData={this.saveData}
                saveDocflow={this.saveDocflow}
                saveMPK={this.saveMPK}
                saveDevEx={this.saveDevEx}
                setWaningsAndErrorsVisibility={this.setWaningsAndErrorsVisibility}
                highlightWarnings={highlightWarnings}
                highlightErrors={highlightErrors}

                //wapro args
                waproData={this.state.waproData}
                setWaproData={this.setWaproData}

                selectMagDocType={this.selectMagDocType}
                selectedMagDocType={selectedMagDocType}
                selectFakirDocType={this.selectFakirDocType}
                selectedFakirDocType={selectedFakirDocType}
                selectFakirDocDictionary={this.selectFakirDocDictionary}
                selectedFakirDocDictionary={selectedFakirDocDictionary}
                selectFakirNetAccounts={this.selectFakirNetAccounts}
                selectedFakirNetAccounts={selectedFakirNetAccounts}
                selectDocDescription={this.selectDocDescription}
                selectedDocDescription={selectedDocDescription}
                setCashAccountingScheme={e => this.setState({ cashAccountingScheme: !this.state.cashAccountingScheme })}
                cashAccountingScheme={this.state.cashAccountingScheme}

                //symfonia args
                symfoniaData={this.state.symfoniaData}
                setSymfoniaData={this.setSymfoniaData}

                //WL_DevCosts
                WL_DevCosts={this.state.WL_DevCosts}
                setWL_DevCosts={this.setWL_DevCosts}

                //CostSplit
                costSplit={this.state.costSplit}
                setCostSplit={this.setCostSplit}
                costSplitAllocation={this.state.costSplitAllocation}
                setCostSplitAllocation={this.setCostSplitAllocation}

                mpkSplit={this.state.mpkSplit}
                setMpkSplit={this.setMpkSplit}

                hideTestPreview={() => this.setState({ textPreview: {} })}

                selectDocType={this.selectDocType}
                selectDocflow={this.selectDocflow}
                selectCurrency={this.selectCurrency}
                selectPaymentMethod={this.selectPaymentMethod}
                selectPaymentType={this.selectPaymentType}
                selectPriority={this.selectPriority}

            />
        } else if (currentInvoiceImageData.docType === 'EXPENSES') {
            return <OcrPageExpensesForm
                currency={currency}
                priority={priority}
                setFormRef={this.fromRef}
                setButtonsRef={this.buttonsRef}
                docOwnerCompany={this.state.docOwnerCompany}
                currentProjectRole={this.state.currentProjectRole}
                expensesOnDoc={this.state.expensesOnDoc}
                deleteExpenseBlock={this.deleteExpenseBlock}
                addExpenseToDoc={this.addExpenseToDoc}
                tags={tags}
                calcHeight={calcHeight}
                scrollToArea={this.scrollToArea}
                scrollToAreaForExpenses={this.scrollToAreaForExpenses}
                showDatePickerExpenses={this.showDatePickerExpenses}
                focusOutAreaForExpenses={this.focusOutAreaForExpenses}
                onInputChangeExpense={this.onInputChangeExpense}
                focusedTextArea={focusedTextArea}
                posUpDatePicker={posUpDatePicker}
                posYDatePicker={posYDatePicker}
                posYCalculator={this.state.posYCalculator}
                onCalendarSelect={this.onCalendarSelect}
                onCalendarUnselecet={this.onCalendarUnselecet}
                showTagsList={showTagsList}
                tagsList={tagsList}
                tagsCreationLimitation={tagsCreationLimitation}
                showTagsListFunc={this.showTagsList}
                setTags={this.setTags}
                tagClick={this.tagClick}
                saveData={this.saveData}
                documentDescription={documentDescription}
                setDocComment={e => this.setState({ documentDescription: e.target.value })}
                currentInvoiceImageData={currentInvoiceImageData}
                isAccountantInCurrentCompany={this.state.isAccountantInCurrentCompany}

                companyId={this.state.companyId}

                mpkSplit={this.state.mpkSplit}
                setMpkSplit={this.setMpkSplit}

                selectDocType={this.selectDocType}
                selectDocflow={this.selectDocflow}
                selectExpenseOwner={this.selectExpenseOwner}
                selectCurrency={this.selectCurrency}
                selectPriority={this.selectPriority}
                setWaningsAndErrorsVisibility={this.setWaningsAndErrorsVisibility}
                highlightWarnings={highlightWarnings}
                highlightErrors={highlightErrors}
            />
        } else if (currentInvoiceImageData.docType === 'AGREEMENT') {
            return <OcrPageAgreementForm
                selectDocType={this.selectDocType}
                selectDocflow={this.selectDocflow}
                setFormRef={this.fromRef}
                setButtonsRef={this.buttonsRef}
                docOwnerCompany={this.state.docOwnerCompany}
                currentProjectRole={this.state.currentProjectRole}
                currentInvoiceImageData={currentInvoiceImageData}
                tags={tags}
                showTagsList={showTagsList}
                tagsList={tagsList}
                tagsCreationLimitation={tagsCreationLimitation}
                showTagsListFunc={this.showTagsList}
                setTags={this.setTags}
                tagClick={this.tagClick}
                onInputChangeAgreement={this.onInputChangeAgreement}
                focusOutAreaForAgreements={this.focusOutAreaForAgreements}
                documentDescription={documentDescription}
                setDocComment={e => this.setState({ documentDescription: e.target.value })}

                scrollToArea={this.scrollToAreaForAgreement}
                blurTextArea={this.blurAreaForAgreement}

                counterpartiesList={counterpartiesList}
                showCounterpartiesList={showCounterpartiesList}
                selectCounterpart={this.selectCounterpartForDocTypes}
                setWaningsAndErrorsVisibility={this.setWaningsAndErrorsVisibility}
                highlightWarnings={highlightWarnings}
                highlightErrors={highlightErrors}

                saveData={this.saveData}
            />
        } else if (currentInvoiceImageData.docType === 'SALE_INVOICE') {
            return <OcrPageIncomeForm
                selectDocType={this.selectDocType}
                selectDocflow={this.selectDocflow}
                setFormRef={this.fromRef}
                setButtonsRef={this.buttonsRef}
                ocrMethod={this.state.ocrMethod}
                docOwnerCompany={this.state.docOwnerCompany}
                currentInvoiceImageData={currentInvoiceImageData}
                isAccountantInCurrentCompany={this.state.isAccountantInCurrentCompany}
                currentProjectRole={this.state.currentProjectRole}
                paymentMethod={paymentMethod}
                currency={currency}
                paymentType={paymentType}
                showCountriesList={showCountriesList}
                comment={comment}
                tags={tags}
                calcHeight={calcHeight}
                scrollToArea={this.scrollToAreaIncome}
                onInputChange={this.onInputChangeIncome}
                showDatePicker={this.showDatePickerIncome}
                acceptedAmounttoPay={acceptedAmounttoPay}
                focusOutArea={this.focusOutArea}
                focusedTextArea={focusedTextArea}
                posUpDatePicker={posUpDatePicker}
                posYDatePicker={posYDatePicker}
                posYCalculator={this.state.posYCalculator}
                posUpCalculator={this.state.posUpCalculator}
                showCalculator={this.showCalculator}
                setAmountValue={this.setAmountValue}
                onCalendarSelect={this.onCalendarSelectIncome}
                onCalendarUnselecet={this.onCalendarUnselecet}
                showAccountNumberWarningBeforeSave={showAccountNumberWarningBeforeSave}
                showTagsList={showTagsList}
                tagsList={tagsList}
                tagsCreationLimitation={tagsCreationLimitation}
                showTagsListFunc={this.showTagsList}
                setTags={this.setTags}
                tagClick={this.tagClick}
                counterpartiesList={counterpartiesList}
                showCounterpartiesList={showCounterpartiesList}
                selectCounterpart={this.selectCounterpart}
                hideAccountNumberWarning={() => this.setState({ showAccountNumberWarningBeforeSave: false })}
                acceptAmountToPay={() => this.setState({ acceptedAmounttoPay: !acceptedAmounttoPay })}
                setComment={e => this.setState({ comment: e.target.value })}
                saveData={this.saveData}
                toggleCountriesList={this.toggleCountriesList}
                documentDescription={documentDescription}
                setDocComment={e => this.setState({ documentDescription: e.target.value })}

                //assign to agreement
                assignToAgreement={this.state.assignToAgreement}
                toggleAssignToAgreement={e => this.setState({ assignToAgreement: !this.state.assignToAgreement, showAgreementsList: this.state.assignToAgreement })}
                showAgreementsList={this.state.showAgreementsList}
                selectedAgreement={this.state.selectedAgreement || {}}
                selectAgreement={this.selectAgreement}
                toggleAgreementsList={this.toggleAgreementsList}
                agreementsListPos={this.state.agreementsListPos}

                setWaningsAndErrorsVisibility={this.setWaningsAndErrorsVisibility}
                highlightWarnings={highlightWarnings}
                highlightErrors={highlightErrors}

                selectBayerCountryHandler={this.selectBayerCountryHandler}
                buyerCountryCode={buyerCountryCode}
            />
        } else if (currentInvoiceImageData.docType === 'CORPORATE') {
            return <OcrPageCorporateForm
                selectDocType={this.selectDocType}
                selectDocflow={this.selectDocflow}
                setFormRef={this.fromRef}
                setButtonsRef={this.buttonsRef}
                docOwnerCompany={this.state.docOwnerCompany}
                currentProjectRole={this.state.currentProjectRole}
                currentInvoiceImageData={currentInvoiceImageData}
                tags={tags}
                showTagsList={showTagsList}
                tagsList={tagsList}
                tagsCreationLimitation={tagsCreationLimitation}
                showTagsListFunc={this.showTagsList}
                setTags={this.setTags}
                tagClick={this.tagClick}
                onInputChangeCorporate={this.onInputChangeCorporate}
                focusOutAreaForCorporate={this.focusOutAreaForCorporate}
                documentDescription={documentDescription}
                setDocComment={e => this.setState({ documentDescription: e.target.value })}

                focusedTextArea={focusedTextArea}
                posUpDatePicker={posUpDatePicker}
                posYDatePicker={posYDatePicker}
                onCalendarSelect={this.onCalendarSelectCorporate}
                onCalendarUnselecet={(date) => { console.log(date) }}
                showDatePicker={this.showDatePickerCorporate}

                scrollToArea={(e, name) => this.setState({ focusedTextArea: name })}
                blurTextArea={() => this.setState({ focusedTextArea: '', })}

                counterpartiesList={counterpartiesList}
                showCounterpartiesList={showCounterpartiesList}
                selectCounterpart={this.selectCounterpartForDocTypes}
                setWaningsAndErrorsVisibility={this.setWaningsAndErrorsVisibility}
                highlightWarnings={highlightWarnings}
                highlightErrors={highlightErrors}

                saveData={this.saveData}
            />
        } else if (currentInvoiceImageData.docType === 'OTHER') {
            return <OcrPageOtherForm
                selectDocType={this.selectDocType}
                selectDocflow={this.selectDocflow}
                setFormRef={this.fromRef}
                setButtonsRef={this.buttonsRef}
                docOwnerCompany={this.state.docOwnerCompany}
                currentProjectRole={this.state.currentProjectRole}
                currentInvoiceImageData={currentInvoiceImageData}
                tags={tags}
                showTagsList={showTagsList}
                tagsList={tagsList}
                tagsCreationLimitation={tagsCreationLimitation}
                showTagsListFunc={this.showTagsList}
                setTags={this.setTags}
                tagClick={this.tagClick}
                onInputChangeOther={this.onInputChangeOther}
                focusOutAreaForOther={this.focusOutAreaForOther}
                documentDescription={documentDescription}
                setDocComment={e => this.setState({ documentDescription: e.target.value })}

                focusedTextArea={focusedTextArea}
                posUpDatePicker={posUpDatePicker}
                posYDatePicker={posYDatePicker}
                onCalendarSelect={this.onCalendarSelectOther}
                onCalendarUnselecet={(date) => { console.log(date) }}
                showDatePicker={this.showDatePickerCorporate}

                scrollToArea={(e, name) => this.setState({ focusedTextArea: name })}
                blurTextArea={() => this.setState({ focusedTextArea: '', })}

                counterpartiesList={counterpartiesList}
                showCounterpartiesList={showCounterpartiesList}
                selectCounterpart={this.selectCounterpartForDocTypes}
                setWaningsAndErrorsVisibility={this.setWaningsAndErrorsVisibility}
                highlightWarnings={highlightWarnings}
                highlightErrors={highlightErrors}

                saveData={this.saveData}
            />
        } else if (currentInvoiceImageData.docType === 'PROTOCOL') {
            return <OcrPageProtocolForm
                selectDocType={this.selectDocType}
                selectDocflow={this.selectDocflow}
                setFormRef={this.fromRef}
                setButtonsRef={this.buttonsRef}
                docOwnerCompany={this.state.docOwnerCompany}
                currentProjectRole={this.state.currentProjectRole}
                currentInvoiceImageData={currentInvoiceImageData}
                tags={tags}
                showTagsList={showTagsList}
                tagsList={tagsList}
                tagsCreationLimitation={tagsCreationLimitation}
                showTagsListFunc={this.showTagsList}
                setTags={this.setTags}
                tagClick={this.tagClick}
                onInputChangeProtocol={this.onInputChangeProtocol}
                selectProtocolType={this.selectProtocolType}
                focusOutAreaForProtocol={this.focusOutAreaForProtocol}
                documentDescription={documentDescription}
                setDocComment={e => this.setState({ documentDescription: e.target.value })}

                focusedTextArea={focusedTextArea}
                posUpDatePicker={posUpDatePicker}
                posYDatePicker={posYDatePicker}
                onCalendarSelect={this.onCalendarSelectProtocol}
                onCalendarUnselecet={(date) => { console.log(date) }}
                showDatePicker={this.showDatePickerCorporate}

                scrollToArea={(e, name) => this.setState({ focusedTextArea: name })}
                blurTextArea={() => this.setState({ focusedTextArea: '', })}

                counterpartiesList={counterpartiesList}
                showCounterpartiesList={showCounterpartiesList}
                selectCounterpart={this.selectCounterpartForDocTypes}
                setWaningsAndErrorsVisibility={this.setWaningsAndErrorsVisibility}
                highlightWarnings={highlightWarnings}
                highlightErrors={highlightErrors}

                savePartialOther={this.savePartialProtocol}
                saveData={this.saveData}
            />
        } else {
            return <OcrPageCorrespondenceForm
                selectDocType={this.selectDocType}
                selectDocflow={this.selectDocflow}
                setFormRef={this.fromRef}
                setButtonsRef={this.buttonsRef}
                docOwnerCompany={this.state.docOwnerCompany}
                currentProjectRole={this.state.currentProjectRole}
                currentInvoiceImageData={currentInvoiceImageData}
                tags={tags}
                showTagsList={showTagsList}
                tagsList={tagsList}
                tagsCreationLimitation={tagsCreationLimitation}
                showTagsListFunc={this.showTagsList}
                setTags={this.setTags}
                tagClick={this.tagClick}
                dataConfirmCheckbox={dataConfirmCheckbox}
                onInputChangeCorrespondence={this.onInputChangeCorrespondence}
                focusOutAreaForCorrespondence={this.focusOutAreaForCorrespondence}
                documentDescription={documentDescription}
                setDocComment={e => this.setState({ documentDescription: e.target.value })}

                focusedTextArea={focusedTextArea}
                posUpDatePicker={posUpDatePicker}
                posYDatePicker={posYDatePicker}
                onCalendarSelect={this.onCalendarSelectCorrespondence}
                onCalendarUnselecet={(date) => { console.log(date) }}
                showDatePicker={this.showDatePickerCorporate}

                scrollToArea={(e, name) => this.setState({ focusedTextArea: name })}
                blurTextArea={() => this.setState({ focusedTextArea: '', })}

                counterpartiesList={counterpartiesList}
                showCounterpartiesList={showCounterpartiesList}
                selectCounterpart={this.selectCounterpartForDocTypes}
                setWaningsAndErrorsVisibility={this.setWaningsAndErrorsVisibility}
                highlightWarnings={highlightWarnings}
                highlightErrors={highlightErrors}

                saveData={this.saveData}
            />
        }
    }

    WL_setTransactionDescriptionPl = value => {
        const { WL_DevCosts } = this.state
        this.setState({
            ...WL_DevCosts,
            ...{ transactionDescriptionPl: value }
        })
    }

    render() {
        const {
            waitingForFile,
            otherUsersWorkingOnThisDoc,
            currentInvoiceImageData,
            paymentMethod, paymentType, currency,
            deleteModal, showMoveToOtherProjectModal,
            posX, posY, posUp, posLeft, showPropertyList,
            waitingForExpensePropToFinishOCR, expensesOnDoc,
            renderFeameTooltip,
            hasDocflowChangesToSave,
            formWarnings, formWarningsCount, formErrors, formErrorsCount
        } = this.state;
        const {
            loadingScreen, existingInvoice, showRefreshOcrPopup, showRefreshOcrPopupError, refreshOcrPopupUserName, showAcceptedOcrPopup, showAcceptedOcrPopupError, overlayPreferance, showDeleteOcrPopup, deleteOcrPopupUserName, showMovedOcrPopup, ocrDownloadButtonLoading, pdfPreviewOpen,
            tabIsActive, user, currentProject,
        } = this.props;

        if (tabIsActive || !user.new_easydocs_version) {
            if (waitingForFile) {
                return (
                    <div className="loading-screen">
                        <div className="activity-indicator">
                            <Spinner size={50} speed={0.8} color={"#ffffff"} />
                        </div>
                    </div>
                )
            } else {
                if (this.state.currentInvoiceImageData !== null) {
                    return (
                        <div className={`ocr-container ${user.new_easydocs_version ? 'new-easydocs-version' : ''}`} onClick={e => this.hidePropertiesList(e)}>

                            <div className='show-overlay ocr' onClick={() => this.showOverlay()} data-for="info-context-overlay" data-tip="show"></div>
                            {
                                loadingScreen ? (
                                    <div className="loading-screen">
                                        <div className="activity-indicator">
                                            <Spinner size={50} speed={0.8} color={"#ffffff"} />
                                        </div>
                                    </div>
                                ) : (
                                    null
                                )
                            }
                            {
                                this.state.gotOverlayPossitions && overlayPreferance['ocrScreen'] ? (
                                    <OverlayInstructions possitions={this.state.overlayPossitions} name="ocrScreen" height={window.innerHeight} />
                                ) : (
                                    null
                                )
                            }
                            {
                                pdfPreviewOpen ? (
                                    <PdfPreview location={this.props.location} prevAndNext={this.checkNext()} goToDoc={this.goToDoc} />
                                ) : (
                                    null
                                )
                            }


                            <OcrProsList
                                ocrMethod={this.state.ocrMethod}
                                showPropertyList={showPropertyList}
                                currentInvoiceImageData={currentInvoiceImageData}
                                paymentType={paymentType}
                                paymentMethod={paymentMethod}
                                currency={currency}
                                posUp={posUp}
                                posLeft={posLeft}
                                posY={posY}
                                posX={posX}
                                waitingForExpensePropToFinishOCR={waitingForExpensePropToFinishOCR}
                                expensesOnDoc={expensesOnDoc}
                                clickOnProp={this._crop}
                                cropForExpenses={this.cropForExpenses}
                                cropForDocType={this.cropForDocType}
                                projectId={currentInvoiceImageData.projectId}
                                docDescription={this.state.documentDescription}


                            />

                            <OcrPagePopups
                                showRefreshOcrPopup={showRefreshOcrPopup}
                                showRefreshOcrPopupError={showRefreshOcrPopupError}
                                refreshOcrPopupUserName={refreshOcrPopupUserName}
                                handleRefreshOcrButton={this.handleRefreshOcrButton}
                                showAcceptedOcrPopup={showAcceptedOcrPopup}
                                showAcceptedOcrPopupError={showAcceptedOcrPopupError}
                                handleGoToDashboardClick={this.handleGoToDashboardClick}
                                showDeleteOcrPopup={showDeleteOcrPopup}
                                deleteOcrPopupUserName={deleteOcrPopupUserName}
                                showMovedOcrPopup={showMovedOcrPopup}
                                closeExistingFileModal={this.props.closeExistingFileModal}
                                forceSave={this.saveData}
                                getFileBody={this.props.getFileBody}
                            />


                            {
                                deleteModal ? (
                                    <DeleteInvoiceModal
                                        delete={this.deleteInvoice}
                                        moveToTrash={true}
                                        inDocflow={currentInvoiceImageData.docflow?.id && !hasDocflowChangesToSave ? true : false}
                                        cancel={this.cancelDeleteInvoice}
                                        name={this.state.currentInvoiceImageData.name}
                                    />
                                ) : (
                                    null
                                )
                            }

                            {
                                showMoveToOtherProjectModal ? (
                                    <MoveToOtherProjectModal
                                        cancel={this.cancelMoveToOtherProjectModal}
                                        moveToOtherProject={this.moveToOtherProject}
                                        currentProjectId={currentInvoiceImageData.projectId}
                                    />
                                ) : null
                            }

                            <ReactTooltip ref='moveToOtherProjectTooltpiRef' id='download-tooltip' className='default-tooltip move-doc-to-project-tooltip' effect='solid' place='bottom' globalEventOff="click">
                                <SimpleBar style={{ maxHeight: '500px' }}>
                                    <ul>
                                        {
                                            this.props.userProjects.map(p => {
                                                return (
                                                    <li className={`${this.getProjectName(this.props.location.pathname) === p.code ? 'selected' : ''}`} onClick={() => this.moveDocToProject(p)}>{p.code}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                </SimpleBar>
                            </ReactTooltip>

                            <OcrPageTooltips
                                currentInvoiceImageData={currentInvoiceImageData}
                                prevDocExists={this.state.prevDocExists}
                                prevDoc={this.state.prevDoc}
                                nextDocExists={this.state.nextDocExists}
                                nextDoc={this.state.nextDoc}
                            />

                            {
                                otherUsersWorkingOnThisDoc.length > 0 ? (
                                    <div className={`other-users-wotking-on-doc width-${otherUsersWorkingOnThisDoc.length}`}>
                                        <div className="text">{this.context.t('Other users working on this doc:')}</div>
                                        <div className={`users width-${otherUsersWorkingOnThisDoc.length}`}>{this.convertUsersToAvatars()}</div>
                                    </div>
                                ) : null
                            }

                            <div className="crop-pdf-menu">
                                <div className="header">
                                    <div className="info-icon" data-tip='show' data-for='ocr-tooltip'>
                                    </div>
                                    <div className="file-name">
                                        {`[${currentProject.code}] ${this.state.currentInvoiceImageData.name}`}
                                    </div>
                                    <div className="action-buttons">
                                        <div className={`nav-container icons-wrapper`}>
                                            <div className={`arrow prev-doc icon ${this.state.prevDocExists ? 'active' : 'not-allowed'}`} data-for="prev-doc-tooltip" data-tip="show">
                                                {
                                                    user.new_easydocs_version ? (
                                                        <div className="link crop-link" onClick={() => this.goToDoc('prev')}></div>
                                                    ) : (
                                                        this.state.prevDocExists ? (
                                                            <Link className="link crop-link" to={reverse(routes.panel.crop, { projectId: this.state.prevDoc.projectId, fileId: this.state.prevDoc.fileId })} />
                                                        ) : (
                                                            null
                                                        )
                                                    )
                                                }
                                            </div>
                                            <div className={`arrow next-doc icon ${this.state.nextDocExists ? 'active' : 'not-allowed'}`} data-for="next-doc-tooltip" data-tip="show">
                                                {
                                                    user.new_easydocs_version ? (
                                                        <div className="link crop-link" onClick={() => this.goToDoc('next')}></div>
                                                    ) : (
                                                        this.state.nextDocExists ? (
                                                            <Link className="link crop-link" to={reverse(routes.panel.crop, { projectId: this.state.nextDoc.projectId, fileId: this.state.nextDoc.fileId })} innerRef={this.nextDocLinkRef} />
                                                        ) : (
                                                            null
                                                        )
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="go-back icon">
                                            <Link className="link crop-link" to={routes.panel.dashboard} innerRef={this.goBackRef} />
                                        </div>
                                        {
                                            currentProject?.is_dropzone_manager ||                                       // jest managerem skrzynki
                                                currentInvoiceImageData?.docflow?.advisor_id == user.id ||               // jest opiekunem scieżki
                                                currentInvoiceImageData?.userThatUploadedId == user.id ||                // jest uploaderem
                                                currentInvoiceImageData?.currentDocflowStep?.users.includes(user.id) ||  // jest w obecnym kroku obiegu||
                                                (currentInvoiceImageData?.userThatUploadedId === -1 && currentInvoiceImageData?.userThatUploaded === user.email)    //user wrzucił plik z maile z adresu popiętego do kona ED
                                                ? (
                                                    <div className="folder icon" onClick={() => this.openMoveToOtherProjectModal()}></div>
                                                ) : null
                                        }
                                        <div className={`download icon ${ocrDownloadButtonLoading ? 'loading' : ''}`} onClick={() => this.downloadOriginalFile(currentInvoiceImageData.dbId, currentInvoiceImageData.projectId, currentInvoiceImageData.name)}></div>
                                        {
                                            currentProject?.is_dropzone_manager ||                                       // jest managerem skrzynki
                                                currentInvoiceImageData?.docflow?.advisor_id == user.id ||               // jest opiekunem scieżki
                                                currentInvoiceImageData?.userThatUploadedId == user.id ||                // jest uploaderem
                                                currentInvoiceImageData?.currentDocflowStep?.users.includes(user.id) ||  // jest w obecnym kroku obiegu||
                                                (currentInvoiceImageData?.userThatUploadedId === -1 && currentInvoiceImageData?.userThatUploaded === user.email)    //user wrzucił plik z maile z adresu popiętego do kona ED
                                                ? (
                                                    <div className="delete icon" onClick={() => this.openDeleteModal()}></div>
                                                ) : null
                                        }
                                        <div className={`ocr icon ${currentInvoiceImageData.used_ocr_model === 'BARDS' ? 'new' : 'old'}`} data-tip='show' data-for='ocr-model-tooltip' onClick={() => this.readWithDifferentOcr(currentInvoiceImageData.used_ocr_model)}></div>

                                        <div className="warnings-wrapper icons-wrapper">
                                            {
                                                formWarningsCount ? (
                                                    <>
                                                        <div className="icon warnings" data-for={`form-warnings-tooltip`} data-tip="show" onMouseEnter={this.showWarnings} onMouseLeave={this.hideWarnings}>
                                                            <div className="count">{formWarningsCount}</div>
                                                        </div>
                                                        <ReactTooltip id={`form-warnings-tooltip`} place="bottom" effect="solid" className="default-tooltip amount-to-pay">
                                                            <ul>
                                                                {
                                                                    Object.keys(formWarnings).map((keyName, keyIndex) => {
                                                                        return (
                                                                            <li>
                                                                                {formWarnings[keyName]}
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </ReactTooltip>
                                                    </>
                                                ) : null
                                            }
                                            {
                                                formErrorsCount ? (
                                                    <>
                                                        <div className="icon errors" data-for={`form-errors-tooltip`} data-tip="show" onMouseEnter={this.showErrors} onMouseLeave={this.hideErrors}>
                                                            <div className="count">{formErrorsCount}</div>
                                                        </div>
                                                        <ReactTooltip id={`form-errors-tooltip`} place="bottom" effect="solid" className="default-tooltip amount-to-pay">
                                                            <ul>
                                                                {
                                                                    Object.keys(formErrors).map((keyName, keyIndex) => {
                                                                        return (
                                                                            <li>
                                                                                {formErrors[keyName]}
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </ReactTooltip>
                                                    </>
                                                ) : null
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* <OcrPageDocQuestions
                                    setPropsRef={this.propsRef}
                                    docOwnerCompany={this.state.docOwnerCompany}
                                    currentInvoiceImageData={currentInvoiceImageData}
                                    selectDocflow={this.selectDocflow}
                                    selectDocType={this.selectDocType}
                                    paymentMethod={paymentMethod}
                                    selectPaymentMethod={this.selectPaymentMethod}
                                    currentProjectRole={this.state.currentProjectRole}
                                    projectMembers={projectMembers}
                                    selectExpenseOwner={this.selectExpenseOwner}
                                    currency={currency}
                                    selectCurrency={this.selectCurrency}
                                    paymentType={paymentType}
                                    selectPaymentType={this.selectPaymentType}
                                    priority={priority}
                                    selectPriority={this.selectPriority}
                                /> */}

                                {this.returnDocTypeForm()}
                            </div>

                            <div ref="cropperContainer" className="cropper-wrapper">

                                {
                                    currentInvoiceImageData.originalImageLoaded ? (
                                        <OcrPageTextPreview
                                            possition={this.state.textPreview.possition}
                                            propName={this.state.textPreview.prop}
                                            expenseIndex={this.state.textPreview.expenseIndex}
                                            text={this.getTextForPreview(currentInvoiceImageData.docType, expensesOnDoc)}
                                            fileId={currentInvoiceImageData.dbId}
                                            docType={currentInvoiceImageData.docType}
                                            ocrMethod={this.state.ocrMethod}
                                            onInputChange={this.onInputChange}
                                            onInputChangeByHand={this.onInputChangeByHand}
                                            onInputChangeExpense={this.onInputChangeExpense}
                                            onInputChangeAgreement={this.onInputChangeAgreement}
                                            onInputChangeIncome={this.onInputChangeIncome}
                                            onInputChangeCorporate={this.onInputChangeCorporate}
                                            onInputChangeOther={this.onInputChangeOther}
                                            onInputChangeCorrespondence={this.onInputChangeCorrespondence}
                                            focusOutArea={this.focusOutArea}
                                            focusOutAreaForExpenses={this.focusOutAreaForExpenses}
                                            scrollToArea={this.scrollToArea}
                                            scrollToAreaForExpenses={this.scrollToAreaForExpenses}
                                        />
                                    ) : (
                                        null
                                    )
                                }

                                {
                                    !this.state.currentInvoiceImageData.originalImageLoaded ? (
                                        <div className="loading-original-image">
                                            <div className="activity-indicator">
                                                <Spinner size={50} speed={0.8} color={"#ffffff"} />
                                            </div>
                                        </div>
                                    ) : (
                                        null
                                    )
                                }
                                <div ref="cropperContainerCanvas" className="cropper" onContextMenu={e => this.showPropertyListRightClick(e)} onMouseEnter={this.mouseEnterCropper}>
                                    <Cropper
                                        ref='cropper'
                                        src={this.state.currentInvoiceImageData.imageData}
                                        // Cropper.js options
                                        viewMode={2}
                                        guides={false}
                                        autoCropArea={0.2}
                                        zoomOnTouch={false}
                                        zoomOnWheel={false}
                                        modal={false}
                                        background={false}
                                        checkOrientation={false}
                                        ready={this.onDocumentImageLoad}
                                        cropstart={this.cropperMouseDown}
                                        cropend={this.showPropertyList}
                                    />
                                    {
                                        renderFeameTooltip ? (
                                            <div className={`frame-tooltip ${user.new_easydocs_version ? 'new-ed-version' : ''}`}>
                                                {this.context.t('Frame the given information (e.g. containing the invoice number) and click the right mouse button to assign it to the field from the list')}
                                            </div>
                                        ) : (
                                            null
                                        )
                                    }
                                </div>
                            </div>
                            <Chat
                                fileId={currentInvoiceImageData.dbId}
                                isWidget={true}
                                isInPreview={false}
                            />
                            <ChatToogleButton />
                        </div>
                    )
                } else {
                    return null
                }
            }
        } else {
            return null
        }
    }
}


OcrPage.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    userProjects: isIterable(state.User.userProjects) ? [...state.User.userProjects] : [],
    currentProject: state.User.currentProject,
    overlayPreferance: state.User.overlayPreferance || {},
    currentCompany: state.Company.currentCompany,
    userCompanies: state.Company.companies,
    filesToVerifiLoaded: state.File.filesToVerifiLoaded,
    ocrModel: state.File.ocrModel || {},
    loadingScreen: state.File.loadingScreen || false,
    returnedOCRData: { ...state.File.returnedOCRData },
    processingData: [...state.File.processingData],
    processedData: [...state.File.processedData],
    existingInvoice: state.File.existingInvoice,
    similarInvoices: state.File.similarInvoices || [],
    pdfPreviewOpen: state.File.pdfPreviewOpen,
    showRefreshOcrPopup: state.File.showRefreshOcrPopup,
    showRefreshOcrPopupError: state.File.showRefreshOcrPopupError,
    refreshOcrPopupUserName: state.File.refreshOcrPopupUserName,
    showAcceptedOcrPopup: state.File.showAcceptedOcrPopup,
    showAcceptedOcrPopupError: state.File.showAcceptedOcrPopupError,
    showDeleteOcrPopup: state.File.showDeleteOcrPopup,
    deleteOcrPopupUserName: state.File.deleteOcrPopupUserName,
    showMovedOcrPopup: state.File.showMovedOcrPopup,
    moveOcrPopupUserName: state.File.moveOcrPopupUserName,
    ocrDownloadButtonLoading: state.Buttons.ocrDownloadButtonLoading,

    currentTabIndex: state.V2_TabsCronroller.currentTabIndex,
})

const mapDispatchToProps = {
    verifyOcrForDoc: FileActions.verifyOcrForDoc,

    getFilesToVerifyRefetch: FileActions.getFilesToVerifyRefetch,
    getProjectResourcesForDashboard: FileActions.getProjectResourcesForDashboard,


    autoSaveOcr: FileActions.autoSaveOcr,
    readWithDifferentOcr: FileActions.readWithDifferentOcr,
    setDocType: FileActions.setDocType,
    clearDataReadWithOcrFrame: FileActions.clearDataReadWithOcrFrame,
    getProjectMembersForExpense: FileActions.getProjectMembersForExpense,
    setExpenseOwner: FileActions.setExpenseOwner,
    generateOcr: FileActions.generateOcr,
    generateOcrForExpenses: FileActions.generateOcrForExpenses,
    changeOCRData: FileActions.changeOCRData,
    setNewCounterparty: FileActions.setNewCounterparty,
    loadGeneratedOCR: FileActions.loadGeneratedOCR,
    checkInvoiceNumber: FileActions.checkInvoiceNumber,
    saveMPK: FileActions.saveMPK,
    saveDevEx: FileActions.saveDevEx,
    saveOCRModel: FileActions.saveOCRModel,
    updateOCRModel: FileActions.updateOCRModel,
    saveOCRData: FileActions.saveOCRData,
    updateOCRData: FileActions.updateOCRData,
    updatePartialOCRData: FileActions.updatePartialOCRData,
    saveByHandOCR: FileActions.saveByHandOCR,
    updateByHandOCR: FileActions.updateByHandOcr,
    updatePartialByHandOcr: FileActions.updatePartialByHandOcr,
    saveExpenses: FileActions.saveExpenses,
    updateExpenses: FileActions.updateExpenses,
    updatePartialExpenses: FileActions.updatePartialExpenses,
    deleteInvoice: FileActions.deleteInvoice,
    permanentDeleteInvoice: FileActions.permanentDeleteInvoice,
    removeFileFromDocflow: FileActions.removeFileFromDocflow,
    getFileBody: FileActions.getFileData,
    closeExistingFileModal: FileActions.closeExistingFileModal,
    findCompanyByVatId: CompanyActions.findCompanyByVatId,
    hideRefreshOcrPopup: FileActions.hideRefreshOcrPopup,
    hideAcceptedOcrPopup: FileActions.hideAcceptedOcrPopup,
    hideOcrScreenNotificationAboutDeletedFile: FileActions.hideOcrScreenNotificationAboutDeletedFile,
    hideOcrScreenNotificationAboutMovedFile: FileActions.hideOcrScreenNotificationAboutMovedFile,
    toggleOverlay: UserActions.toggleOverlay,
    moveToOtherProject: FileActions.moveToOtherProject,
    downloadOriginalFile: FileActions.downloadOriginalFile,

    savePartialAgreement: FileActions.savePartialAgreement,
    saveAgreement: FileActions.saveAgreement,

    savePartialIncome: FileActions.savePartialIncome,
    saveIncome: FileActions.saveIncome,

    savePartialCorrespondence: FileActions.savePartialCorrespondence,
    saveCorrespondence: FileActions.saveCorrespondence,

    savePartialCorporate: FileActions.savePartialCorporate,
    saveCorporate: FileActions.saveCorporate,

    savePartialOther: FileActions.savePartialOther,
    saveOther: FileActions.saveOther,

    savePartialOther: FileActions.savePartialOther,
    saveProtocol: FileActions.saveProtocol,

    setCurrentTab: TabsControlerActions.setCurrentTab,
    closeTab: TabsControlerActions.closeTab,
    changeOcrTabDoc: TabsControlerActions.changeOcrTabDoc,

    addCostSplitForFile: FileActions.addCostSplitForFile,
    deleteCostSplitForFile: FileActions.deleteCostSplitForFile,

    switchDashboardViewModel: UserActions.switchDashboardViewModel,
    setCurrentCompany: CompanyActions.setCurrentCompany,

    alertWarn: AlertActions.warning,
    alertSuccess: AlertActions.info,
}

export default connect(mapStateToProps, mapDispatchToProps)(OcrPage)

