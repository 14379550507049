import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { Spinner } from 'react-activity'
import 'react-activity/dist/react-activity.css'

import NewRecurringInvoice from './NewRecurringInvoice'
import DeleteRecurringInvoice from './DeleteRecurringInvoice'

import { CompanyActions } from "../../actions/CompanyActions";
import { AlertActions } from "../../actions/AlertActions";

import "../../../src/css/SalesPage.css";
import arrayObjectIndexOf from "../../helpers/indexOfObject";

export class RecurringInvoicesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchField: '',
            waitingForInvoices: false,
            recurringInvoices: [],
            newRecurringInvoiceView: false,
            editRecurringInvoiceView: false,
            editedRecurringInvoice: {},
            deleteRecurringInvoiceView: false,
            recurringInvoiceToDelete: {},
            sortField: 'next_issue_date',
            sortOrder: 'asc'
        };
    }

    componentDidMount() {
        const { currentCompany } = this.props
        if (currentCompany && currentCompany.company_id) {
            this.getRecurringInvoices()
        }
    }

    componentDidUpdate(prevProps) {
        const { currentCompany } = this.props
        if (!prevProps.currentCompany && currentCompany && currentCompany.company_id) {
            this.getRecurringInvoices()
        }
    }

    getRecurringInvoices = () => {
        const { currentCompany } = this.props
        this.setState({
            waitingForInvoices: true
        })
        this.props.getSavedRecurringInvoice(currentCompany.company_id, (savedRecurringInvoice) => {
            if (savedRecurringInvoice) {
                this.setState({
                    recurringInvoices: savedRecurringInvoice,
                });
            }
            this.setState({
                waitingForInvoices: false
            })
        })
    }

    onInputChange = e => {
        e.preventDefault()

        const { name, value } = e.target
        this.setState({
            [name]: value,
        })
    }

    addNewRecurringInvoice = () => {
        this.setState({
            newRecurringInvoiceView: true
        })
    }

    startEditingInvoice = invoice => {
        this.setState({
            editRecurringInvoiceView: true,
            editedRecurringInvoice: invoice
        })
    }

    savedChanges = () => {
        this.getRecurringInvoices()
        this.closeNewRecurringiInvoice()
    }

    closeNewRecurringiInvoice = () => {
        this.setState({
            newRecurringInvoiceView: false,
            editRecurringInvoiceView: false,
            editedRecurringInvoice: {}
        })
    }

    openDeleteInvoicePopup = invoice => {
        this.setState({
            deleteRecurringInvoiceView: true,
            recurringInvoiceToDelete: invoice
        })
    }

    closeDeleteRecurringInvoice = () => {
        this.setState({
            deleteRecurringInvoiceView: false,
            recurringInvoiceToDelete: {}
        })
    }

    deletedRecurringInvoice = invoiceId => {
        const { recurringInvoices } = this.state
        var newRecurringInvoicesList = recurringInvoices.filter(i => i.id !== invoiceId)

        this.setState({
            recurringInvoices: newRecurringInvoicesList
        })
    }

    renderInvoiceItems = (invoice, invoiceIndex) => {
        var itemsToReturn = [], hasMoreItemsToShow = false, itemsCountToShow = 0
        if (invoice && invoice.items) {
            invoice.items.map((item, index) => {
                if (item) {
                    if (index < 2 || invoice.showAllItems) itemsToReturn.push(<p>{item.name}</p>)
                    if (index >= 2 && !invoice.showAllItems) hasMoreItemsToShow = true
                }
            })
            if (hasMoreItemsToShow) {
                itemsCountToShow = invoice.items.length - 2
                itemsToReturn.push(
                    <p className="show-more-items" onClick={() => this.toggleMoreItemsForInvoice(invoiceIndex, true)}>{this.context.t('+ Show {n} more items', { n: itemsCountToShow })}</p>
                )
            }

            if (invoice.showAllItems) {
                itemsCountToShow = invoice.items.length - 2
                itemsToReturn.push(
                    <p className="show-more-items" onClick={() => this.toggleMoreItemsForInvoice(invoiceIndex, false)}>{this.context.t('- Hide {n} items', { n: itemsCountToShow })}</p>
                )
            }

            return itemsToReturn
        } else {
            return null
        }
    }

    toggleMoreItemsForInvoice = (invoiceIndex, show) => {
        const { recurringInvoices } = this.state
        var recurringInvoicesCopy = [...recurringInvoices]
        recurringInvoicesCopy[invoiceIndex].showAllItems = show
        this.setState({
            recurringInvoices: recurringInvoicesCopy
        })
    }

    dynamicComparer = (key, order = 'asc') => {
        return (a, b) => {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                return 0;
            }

            const varA = (typeof a[key] === 'string') ?
                a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string') ?
                b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (!varA) {
                comparison = -1
            } else if (!varB) {
                comparison = 1
            } else if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === 'desc') ? (comparison * -1) : comparison
            );
        };
    }

    sortInvoices = sortBy => {
        var { recurringInvoices, sortField, sortOrder } = this.state
        if (sortField === sortBy) {
            if (sortOrder === 'asc') {
                sortOrder = 'desc'
            } else {
                sortOrder = 'asc'
            }
        } else {
            sortField = sortBy
            sortOrder = 'asc'
        }

        this.setState({
            recurringInvoices: [...recurringInvoices.sort(this.dynamicComparer(sortField, sortOrder))],
            sortField: sortField,
            sortOrder: sortOrder
        })
    }

    getSortClass = (sortFieldCheck) => {
        const { sortField, sortOrder } = this.state

        if (sortField === sortFieldCheck) {
            if (sortOrder === "asc")
                return "icon-sort down"
            return "icon-sort up"
        }

        return "icon-sort"
    }

    invoiceMatchesFilter = invoice => {
        const { searchField } = this.state
        var showInvoice = false
        if (searchField.length < 2) showInvoice = true

        if (!showInvoice && invoice.counterpartyName.toLowerCase().includes(searchField.toLowerCase())) showInvoice = true
        if (!showInvoice && invoice.numberingSeriesName.toLowerCase().includes(searchField.toLowerCase())) showInvoice = true
        if (!showInvoice && moment(invoice.next_issue_date).format('YYYY-MM-DD').includes(searchField.toLowerCase())) showInvoice = true
        if (!showInvoice && moment(invoice.next_sale_date).format('YYYY-MM-DD').includes(searchField.toLowerCase())) showInvoice = true
        if (!showInvoice && invoice.repeat_until && moment(invoice.repeat_until).format('YYYY-MM-DD').includes(searchField.toLowerCase())) showInvoice = true

        console.log(showInvoice)
        if (!showInvoice) {
            var itemsCount = invoice.items.length
            for (var i = 0; i < itemsCount; i++) {
                console.log(invoice.items[i])
                if (invoice.items[i]) {
                    console.log(invoice.items[i].name)
                    if (invoice.items[i].name.toLowerCase().includes(searchField.toLowerCase())) {
                        showInvoice = true
                        console.log(invoice.items[i].name)
                    }
                }
            }
        }

        return showInvoice
    }

    render() {
        const { waitingForInvoices, recurringInvoices, newRecurringInvoiceView, editRecurringInvoiceView, editedRecurringInvoice, deleteRecurringInvoiceView, recurringInvoiceToDelete, searchField } = this.state

        if (newRecurringInvoiceView) {
            return (
                <NewRecurringInvoice
                    close={this.closeNewRecurringiInvoice}
                    savedChanges={this.savedChanges}
                />
            )
        } else if (editRecurringInvoiceView) {
            return (
                <NewRecurringInvoice
                    editingExisting={true}
                    editedRecurringInvoice={editedRecurringInvoice}
                    savedChanges={this.savedChanges}
                    close={this.closeNewRecurringiInvoice}
                />
            )
        } else if (deleteRecurringInvoiceView) {
            return (
                <DeleteRecurringInvoice
                    invoiceToDelete={recurringInvoiceToDelete}
                    deletedRecurringInvoice={this.deletedRecurringInvoice}
                    closeDeleteRecurringInvoice={this.closeDeleteRecurringInvoice}
                />
            )
        } else {
            return (
                <div className="recurring-invoices-popup" >
                    <div className="recurring-invoices-window">
                        <h3>{this.context.t("Manage your recurring invoices")}</h3>
                        <span className="close-button" onClick={() => this.props.closePopup(false)}></span>
                        <div className="doc-items">
                            <div className="search-field">
                                <input
                                    type="text"
                                    value={searchField}
                                    name="searchField"
                                    onChange={e => this.onInputChange(e)}
                                    placeholder={this.context.t('Search for invoices - by a fragment of counterparty name, numbering series, items or dates')}
                                />
                            </div>
                            <ul>
                                <li className="header">
                                    <div className="name" onClick={() => this.sortInvoices('counterpartyName')}>
                                        {this.context.t('Counterparty')}
                                        <span className={this.getSortClass('counterpartyName')}></span>
                                    </div>
                                    <div className="numbering-series" onClick={() => this.sortInvoices('numberingSeriesName')}>
                                        {this.context.t('Numbering series')}
                                        <span className={this.getSortClass('numberingSeriesName')}></span>
                                    </div>
                                    <div className="date" onClick={() => this.sortInvoices('next_issue_date')}>
                                        {this.context.t('Issue date of the next invoice')}
                                        <span className={this.getSortClass('next_issue_date')}></span>
                                    </div>
                                    <div className="date" onClick={() => this.sortInvoices('next_sale_date')}>
                                        {this.context.t('Sale date of the next invoice')}
                                        <span className={this.getSortClass('next_sale_date')}></span>
                                    </div>
                                    <div className="date" onClick={() => this.sortInvoices('repeat_until')}>
                                        {this.context.t('Invoice generation end date')}
                                        <span className={this.getSortClass('repeat_until')}></span>
                                    </div>
                                    <div className="items" onClick={() => this.sortInvoices('itemName')}>
                                        {this.context.t('Products/Services')}
                                        <span className={this.getSortClass('itemName')}></span>
                                    </div>
                                    <div className="options-menu"></div>
                                </li>
                                {
                                    waitingForInvoices ? (
                                        <div className="loading-invoices">
                                            <Spinner size={40} speed={0.8} color={"#69B792"} />
                                        </div>
                                    ) : (
                                        recurringInvoices && recurringInvoices.length > 0 ? (
                                            <SimpleBar style={{ maxHeight: "60vh", width: "100%" }} >
                                                {
                                                    recurringInvoices.map((invoice, index) => {
                                                        if (this.invoiceMatchesFilter(invoice)) {
                                                            return (
                                                                <li className={`${index % 2 === 1 ? 'odd' : ''} invoice-row`}>
                                                                    <div className="name">{invoice.counterpartyName}</div>
                                                                    <div className="numbering-series">{invoice.numberingSeriesName}</div>
                                                                    <div className="date">{moment(invoice.next_issue_date).format('YYYY-MM-DD')}</div>
                                                                    <div className="date">{moment(invoice.next_sale_date).format('YYYY-MM-DD')}</div>
                                                                    <div className="date">{invoice.repeat_until ? moment(invoice.repeat_until).format('YYYY-MM-DD') : ''}</div>
                                                                    <div className="items">{this.renderInvoiceItems(invoice, index)}</div>
                                                                    <div className="options-menu">
                                                                        <span className="option edit" onClick={() => this.startEditingInvoice(invoice)}></span>
                                                                        <span className="option delete" onClick={() => this.openDeleteInvoicePopup(invoice)}></span>
                                                                    </div>
                                                                </li>
                                                            )
                                                        } else {
                                                            return null
                                                        }
                                                    })
                                                }
                                            </SimpleBar>
                                        ) : (
                                            <div className="no-recurring-invoices">
                                                <p>{this.context.t("It looks like you don't have any recurring invoices yet")}</p>
                                            </div>
                                        )
                                    )
                                }
                            </ul>
                        </div>
                        <div className="add-new-item" onClick={() => this.addNewRecurringInvoice()}>{this.context.t("Add new recurring invoice")}</div>
                    </div>
                </div>
            );
        }
    }
}

RecurringInvoicesList.contextTypes = {
    t: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
    currentCompany: state.Company.currentCompany,
});

const mapDispatchToProps = {
    getSavedRecurringInvoice: CompanyActions.getSavedRecurringInvoice
};

export default connect(mapStateToProps, mapDispatchToProps)(RecurringInvoicesList);
