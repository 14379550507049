import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { Dots } from 'react-activity'
import 'react-activity/dist/react-activity.css'
import TextareaAutosize from 'react-textarea-autosize'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'

import TagsComponent from './TagsComponent';
import Calendar from '../Calendar';
import parseDate from '../../helpers/dateParser'
import isIterable from "../../helpers/isIterable"

import '../../css/OcrPage.css'

import { FileActions } from '../../actions/FileActions';
import { AlertActions } from '../../actions/AlertActions'

const REQUIRED_FIELDS = ['date', 'counterpartyName', 'counterpartyVatId']
export class OcrPageExpensesForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            fieldsWithErrors: {},
            fieldsWithWarnings: {}
        }

        this.docTypeWrapperRef = React.createRef()
        this.docflowWrapperRef = React.createRef()
        this.issueDateWrapperRef = React.createRef()
    }

    componentDidMount() {
        const { currentProject, currentInvoiceImageData } = this.props

        var curentProjectDocflows = currentProject?.projectDocflows || []
        this.setState({
            availableDocflows: curentProjectDocflows
        })

        var defaultDocflow = null, possibleDocflowsCount = 0
        curentProjectDocflows.forEach(docflow => {
            if (docflow.doc_types?.includes(currentInvoiceImageData.docType) && !docflow.archived) {
                possibleDocflowsCount++
                if (!defaultDocflow) defaultDocflow = docflow
            }
        })

        if (possibleDocflowsCount === 1) this.selectDocflow(defaultDocflow)

        document.addEventListener("mousedown", this.handleClick);
    }

    componentDidUpdate(prevProps) {
        const { returnedOCRData, processingData } = this.props
        if (prevProps.returnedOCRData?.file !== returnedOCRData?.file) {
            this.checkFieldsForErrorsAndWarnings()
        }

        if (prevProps.processingData?.length !== processingData?.length) {
            this.checkFieldsForErrorsAndWarnings()
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
    }

    handleClick = (e) => {
        const { showDocumentTypeList, showDocflowsList, showCounterpartiesList, showIssueDateCalendar } = this.state
        if (this.docTypeWrapperRef.current && !this.docTypeWrapperRef.current.contains(e.target) && showDocumentTypeList) {
            this.toggleQuestionList('DOC_TYPE')
        }
        if (this.docflowWrapperRef.current && !this.docflowWrapperRef.current.contains(e.target) && showDocflowsList) {
            this.toggleQuestionList('DOCFLOW')
        }
        if (showCounterpartiesList && this.sellerNameWrapperRef && this.sellerNameWrapperRef.current && !this.sellerNameWrapperRef.current.contains(e.target)) {
            this.setState({
                showCounterpartiesList: false
            })
        }
        if (showIssueDateCalendar && this.issueDateWrapperRef && this.issueDateWrapperRef.current && !this.issueDateWrapperRef.current.contains(e.target)) {
            this.setState({
                showIssueDateCalendar: false,
            })
        }
    }

    getClassNameForOcrData = (propName, text, newCounterparty) => {
        const { dataReadWithOcrFrame } = this.props

        var classes = ''
        var hide = false
        var half = false

        if (propName === 'dueDate' || propName === 'issueDate') {
            half = true
        }

        if (!newCounterparty && propName === 'counterpartyCode') {
            hide = true
        }

        if (hide) classes += ' hide'
        if (half) classes += ' half'
        if (dataReadWithOcrFrame.includes(propName)) classes += ' finished-ocr-indication'
        if (REQUIRED_FIELDS.includes(propName) && !text) classes += ' required-field'

        return classes.trim()
    }

    checkDate = (date) => {
        if (date && parseDate(date).match(new RegExp("[0-9]{2}-[0-9]{2}-[0-9]{4}", "i"))) {
            return true
        } else {
            return false
        }
    }

    foundAtLeastOneSimilarCounterparty = (text) => {
        const { counterpartiesList } = this.props
        var counterpartiesCount = counterpartiesList.length

        if (!text) {
            return false
        }

        for (var i = 0; i < counterpartiesCount; i++) {
            if (text.length === 1 && counterpartiesList[i].name && counterpartiesList[i].name.toLowerCase().startsWith(text.toLowerCase())) {
                return true
            } else if (text.length > 1 && counterpartiesList[i].name && counterpartiesList[i].name.toLowerCase().includes(text.toLowerCase())) {
                return true
            }
        }

        return false
    }

    checkIfCounterpartyNameIsSimilar = (text, name) => {
        if (!text) {
            return false
        }

        if (text.length === 1 && name && name.toLowerCase().startsWith(text.toLowerCase())) {
            return true
        } else if (text.length > 1 && name && name.toLowerCase().includes(text.toLowerCase())) {
            return true
        }

        return false
    }

    selectCounterpart = c => {
        const { currentInvoiceImageData } = this.props
        this.props.changeOCRData(currentInvoiceImageData.dbId, 'counterpartyName', c.name, '', 'AGREEMENT')
        this.props.changeOCRData(currentInvoiceImageData.dbId, 'counterpartyVatId', c.vat_id, '', 'AGREEMENT')
        this.props.changeOCRData(currentInvoiceImageData.dbId, 'counterpartyCode', c.code, '', 'AGREEMENT')

        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'counterpartyName', c.name, 'agreement')
        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'counterpartyVatId', c.vat_id, 'agreement')
        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'counterpartyCode', c.code, 'agreement')
        this.toggleCounterpartiesList()
        this.checkFieldsForErrorsAndWarnings()
    }

    toggleQuestionList = (listType) => {
        this.setState({
            showDocumentTypeList: listType === 'DOC_TYPE' ? !this.state.showDocumentTypeList : this.state.showDocumentTypeList,
        })

        if (listType === 'DOCFLOW') {
            const { currentProject, currentInvoiceImageData, user } = this.props
            if (
                currentProject?.is_dropzone_manager ||
                currentInvoiceImageData.uploaded_by === user.id ||
                currentInvoiceImageData.userThatUploadedId === user.id ||
                (currentInvoiceImageData.userThatUploadedId === -1 && currentInvoiceImageData.userThatUploaded === user.email)
            ) {
                this.setState({
                    showDocflowsList: !this.state.showDocflowsList
                })
            } else {
                this.props.alertWarn(this.context.t('You do not have permission to change docflow'))
            }
        }
    }

    selectDocType = (type) => {
        this.props.selectDocType(type)

        this.setState({
            showDocumentTypeList: false
        })
    }

    getDocTypeLabel = docType => {
        switch (docType) {
            case 'COSTS':
                return this.context.t('Cost')
            case 'EXPENSES_REPORT':
                return this.context.t('Cost')
            case 'MAG':
                return this.context.t('Warehouse doc')
            case 'EXPENSES':
                return this.context.t('Expense')
            case 'AGREEMENT':
                return this.context.t('Agreement')
            case 'SALE_INVOICE':
                return this.context.t('Income doc')
            case 'CORPORATE':
                return this.context.t('Corporate')
            case 'CORRESPONDENCE':
                return this.context.t('Correspondence')
            case 'OTHER':
                return this.context.t('Other')
            case 'PROTOCOL':
                return this.context.t('Protocol')
            default:
                return this.context.t('Other')
        }
    }

    selectDocflow = docflow => {
        const { currentInvoiceImageData } = this.props
        if (docflow.doc_types?.includes(currentInvoiceImageData.docType)) {
            var firstStep = docflow.docflowSteps[0]
            this.props.selectDocflow(docflow, firstStep)
            this.setState({
                showDocflowsList: false
            })
        }
        this.checkFieldsForErrorsAndWarnings()
    }

    labelClick = (e, propName) => {
        const { returnedOCRData } = this.props
        var prop = returnedOCRData?.agreementProps?.find(e => e.name === propName)

        this.props.scrollToArea(e, propName, prop?.possition)

        if (propName.includes('Date')) {
            this.toggleCalendar(propName)
        } else if (['netto', 'brutto', 'price'].includes(propName)) {
            this.toggleCalculator(propName)
        }
    }

    onInputChange = (e, propName) => {
        const { currentInvoiceImageData } = this.props
        this.props.onInputChangeAgreement(e, currentInvoiceImageData.dbId, propName)
    }

    onInputClick = (e, propName) => {
        const { returnedOCRData } = this.props
        var prop = returnedOCRData?.agreementProps?.find(e => e.name === propName)

        // this.props.scrollToArea(e, propName, prop?.possition)

        if (propName === 'date') {
            this.toggleCalendar(propName)
        } if (propName === 'counterpartyName') {
            this.toggleCounterpartiesList()
        }
    }

    onInputFocus = (e, propName) => {
    }

    onInputBlur = (e, propName) => {
        const { returnedOCRData, currentInvoiceImageData } = this.props
        var prop = returnedOCRData?.agreementProps?.find(e => e.name === propName)

        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, propName, prop?.text, 'agreement')

        this.checkFieldsForErrorsAndWarnings()
    }

    toggleCounterpartiesList = () => {
        this.setState({
            showCounterpartiesList: !this.state.showCounterpartiesList
        })
    }

    toggleCalendar = (propName) => {
        const { showIssueDateCalendar } = this.state
        this.setState({
            showIssueDateCalendar: propName === 'date' ? !showIssueDateCalendar : false,
        })
    }

    onCalendarSelect = async (date) => {
        const { showIssueDateCalendar } = this.state
        const { currentInvoiceImageData } = this.props

        if (date) {
            if (showIssueDateCalendar) {
                this.props.changeOCRData(currentInvoiceImageData.dbId, 'date', date.format('DD-MM-YYYY'), '', 'AGREEMENT')
                this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'date', date.format('DD-MM-YYYY'), 'agreement')
            }
        }

        this.setState({
            showIssueDateCalendar: false
        })
        this.checkFieldsForErrorsAndWarnings()
    }

    onCalendarUnselecet = () => {
        this.setState({
            showIssueDateCalendar: false
        })
        this.checkFieldsForErrorsAndWarnings()
    }

    checkIfDateIsValid = (dateText) => {
        const datePattern = new RegExp("^[0-9]{2}-[0-9]{2}-[0-9]{4}$", "i");
        const datePatternOneDayDigit = new RegExp("^[0-9]{1}-[0-9]{2}-[0-9]{4}$", "i");
        const datePatternOneMonthDigit = new RegExp("^[0-9]{2}-[0-9]{1}-[0-9]{4}$", "i");
        const datePatternReverse = new RegExp("^[0-9]{4}-[0-9]{2}-[0-9]{2}$", "i");
        if (dateText) {
            dateText = dateText.replace(/\,/g, '-').replace(/\./g, '-').replace(/\//g, '-').replace(/[^0-9\-]/gm, '')
            dateText = dateText.trim();
            if (datePattern.test(dateText)) return moment(dateText, 'DD-MM-YYYY').isValid()
            if (datePatternOneDayDigit.test(dateText)) return moment(dateText, 'D-MM-YYYY').isValid()
            if (datePatternOneMonthDigit.test(dateText)) return moment(dateText, 'DD-M-YYYY').isValid()
            if (datePatternReverse.test(dateText)) return moment(dateText, 'YYYY-MM-DD').isValid()
            else return false
        } else return true
    }

    checkFieldsForErrorsAndWarnings = () => {
        const { accountWhiteListStatus, dataConfirmCheckbox } = this.state
        const {
            currentInvoiceImageData, returnedOCRData,
            paymentMethod, acceptedAmounttoPay, currency
        } = this.props
        var errors = {}, warnings = {}
        if (!currentInvoiceImageData?.docflow?.id) {
            errors.docflow = this.context.t('Docflow is required')
        }

        if (returnedOCRData && returnedOCRData.agreementProps) {
            returnedOCRData.agreementProps?.forEach(prop => {
                if (prop.name === 'sellerName') {
                    if (!prop.text) {
                        errors.sellerName = this.context.t('Counterparty name is required')
                    }
                } else if (prop.name === 'number') {
                    if (!prop.text) {
                        errors.invoiceNumber = this.context.t('Document number is required')
                    }
                } else if (prop.name === 'date') {
                    if (!prop.text) {
                        errors.issueDate = this.context.t('Issue date is required')
                    } else if (!this.checkIfDateIsValid(prop.text)) {
                        errors.issueDate = this.context.t('The format of issue date couldn\'t be recognized. Please make sure that it is dd-mm-yyyy or yyyy-mm-dd.')
                    }
                }
            })
        }

        this.setState({
            fieldsWithErrors: errors,
            fieldsWithWarnings: warnings
        })
        var errorsCount = 0
        Object.keys(errors).forEach(key => {
            if (errors[key]) errorsCount++
        })
        this.props.setWaningsAndErrorsVisibility(warnings, errors)

        return errorsCount === 0
    }

    saveData = () => {
        const { dataConfirmCheckbox } = this.state
        if (!dataConfirmCheckbox) {
            this.props.alertWarn(this.context.t('You have to confirm the compliance of the data in the form with the data on the original document'))
        } else if (this.checkFieldsForErrorsAndWarnings()) {
            const {
                fieldsWithWarnings, fieldsWithErrors,
                linkedFiles
            } = this.state

            var errorsCount = 0
            Object.keys(fieldsWithErrors).forEach(key => {
                if (errorsCount[key]) errorsCount++
            })

            if (errorsCount === 0) {
                this.props.saveData()
            } else {
                this.props.alertWarn(this.context.t('Please correct the errors before saving the document'))
            }
        } else {
            this.props.alertWarn(this.context.t('Please correct the errors before saving the document'))
        }
    }

    render() {
        const {
            availableDocflows, docflowModalIsOpen, docflowToOpen,
            dataConfirmCheckbox,
            showDocumentTypeList, showDocflowsList,
            showCounterpartiesList,
            showIssueDateCalendar,
            fieldsWithWarnings, fieldsWithErrors,
        } = this.state
        const {
            user,
            highlightWarnings, highlightErrors,
            currentInvoiceImageData, currentProjectRole,
            comment, documentDescription, tags,
            returnedOCRData, processingData, dataReadWithOcrFrame,
            tagsList, tagsCreationLimitation,
            counterpartiesList,
        } = this.props

        return (
            <div className={`invoice-content ${highlightWarnings ? 'highlight-warnings' : ''} ${highlightErrors ? 'highlight-errors' : ''}`} ref="sideInvoiceBar">
                {
                    Object.keys(fieldsWithWarnings).map((keyName, keyIndex) => {
                        return (
                            <ReactTooltip id={`${keyName}-warning-tooltip`} place="bottom" effect="solid" className="default-tooltip amount-to-pay">
                                {fieldsWithWarnings[keyName]}
                            </ReactTooltip>
                        )
                    })
                }

                {
                    Object.keys(fieldsWithErrors).map((keyName, keyIndex) => {
                        return (
                            <ReactTooltip id={`${keyName}-error-tooltip`} place="bottom" effect="solid" className="default-tooltip amount-to-pay">
                                {fieldsWithErrors[keyName]}
                            </ReactTooltip>
                        )
                    })
                }

                <SimpleBar style={{ maxHeight: '100%', marginRight: '-12px', paddingRight: '12px' }} autoHide={false}>
                    <div id="doc-type-question" className="choice-short" ref={this.docTypeWrapperRef}>
                        <div className="question">
                            {this.context.t('Document type ')}
                        </div>
                        <div className={`choosen-option doc-types ${showDocumentTypeList ? 'extended' : ''}`} onClick={() => this.toggleQuestionList('DOC_TYPE')}>
                            {
                                this.getDocTypeLabel(currentInvoiceImageData.docType)
                            }

                            {
                                showDocumentTypeList ? (
                                    <ul>
                                        <li onClick={e => this.selectDocType('COSTS')}>
                                            {this.context.t('Cost')}
                                            <span className="type-description">{this.context.t('Invoice or bill for the company')}</span>
                                        </li>
                                        <li onClick={e => this.selectDocType('EXPENSES')}>
                                            {this.context.t('Expense')}
                                            <span className="type-description">{this.context.t('Receipt or invoice for the employee')}</span>
                                        </li>
                                        <li onClick={e => this.selectDocType('SALE_INVOICE')}>
                                            {this.context.t('Income doc')}
                                            <span className="type-description">{this.context.t('Your sales invoice')}</span>
                                        </li>
                                        <li onClick={e => this.selectDocType('AGREEMENT')}>
                                            {this.context.t('Agreement')}
                                            <span className="type-description">{this.context.t('Your contract with the supplier/recipient')}</span>
                                        </li>
                                        <li onClick={e => this.selectDocType('CORRESPONDENCE')}>
                                            {this.context.t('Correspondence')}
                                            <span className="type-description">{this.context.t('letter or business email')}</span>
                                        </li>
                                        <li onClick={e => this.selectDocType('CORPORATE')}>
                                            {this.context.t('Corporate')}
                                            <span className="type-description">{this.context.t('official document')}</span>
                                        </li>
                                        <li onClick={e => this.selectDocType('MAG')}>
                                            {this.context.t('Warehouse doc')}
                                        </li>
                                        <li onClick={e => this.selectDocType('OTHER')}>
                                            {this.context.t('Other')}
                                        </li>
                                        <li onClick={e => this.selectDocType('PROTOCOL')}>
                                            {this.context.t('Protocol')}
                                        </li>
                                    </ul>
                                ) : (
                                    null
                                )
                            }
                        </div>
                    </div>
                    <div id="docflow-question" className={`choice-short ${fieldsWithErrors.docflow ? 'error' : ''}`} ref={this.docflowWrapperRef}>
                        <div className="question">
                            {this.context.t('Docflow')}
                            {
                                fieldsWithErrors.docflow ? (
                                    <>
                                        <div className="error-icon" data-for={`docflow-error-tooltip`} data-tip='show'></div>
                                    </>
                                ) : null
                            }
                        </div>
                        <div className={`choosen-option docflow ${showDocflowsList ? 'extended' : ''} ${!currentInvoiceImageData?.docflow?.name ? 'placeholder' : ''}`} onClick={() => this.toggleQuestionList('DOCFLOW')}>
                            {currentInvoiceImageData?.docflow?.name || this.context.t('Select docflow')}

                            {
                                showDocflowsList && availableDocflows?.length ? (
                                    <ul>
                                        {
                                            availableDocflows.map(docflow => {
                                                if (docflow.doc_types?.includes(currentInvoiceImageData.docType) && !docflow.archived)
                                                    return (
                                                        <li>
                                                            <div className="text" onClick={e => this.selectDocflow(docflow)}>{docflow.name}</div>
                                                            <div className="info" onClick={() => this.setState({ docflowModalIsOpen: true, docflowToOpen: docflow })}></div>
                                                        </li>
                                                    )
                                                else return null
                                            })
                                        }
                                    </ul>
                                ) : (
                                    null
                                )
                            }
                        </div>
                    </div>

                    <div className="form-section">
                        <h4>{this.context.t('Counterparty')}</h4>

                        <div className={`form-group ${dataReadWithOcrFrame.includes('counterpartyName') ? 'finished-ocr-indication' : ''} ${fieldsWithErrors.counterpartyName ? 'error' : ''} ${fieldsWithWarnings.counterpartyName ? 'warning' : ''}`} ref={this.sellerNameWrapperRef}>
                            <TextareaAutosize
                                type="text"
                                name='counterpartyName'
                                id="counterpartyName"
                                value={returnedOCRData?.agreementProps?.find(p => p.name === 'counterpartyName')?.text || ''}
                                maxRows={10}
                                onChange={(e) => this.onInputChange(e, 'counterpartyName')}
                                onClick={(e) => this.onInputClick(e, 'counterpartyName')}
                                onBlur={e => this.onInputBlur(e, 'counterpartyName')}
                                onFocus={e => this.onInputFocus(e, 'counterpartyName')}
                            />
                            <label className='counterpartyName' onClick={(e) => this.labelClick(e, 'counterpartyName')} >
                                {this.context.t('Counterparty name')}
                                {
                                    processingData && processingData.find(e => e === 'counterpartyName') ? (
                                        <span className="loading"><Dots size={9} speed={0.8} color={'#69B792'} /></span>
                                    ) : null
                                }
                            </label>
                            {
                                counterpartiesList.length > 0 &&
                                    returnedOCRData?.agreementProps?.find(p => p.name === 'counterpartyName')?.text &&
                                    this.foundAtLeastOneSimilarCounterparty(returnedOCRData?.agreementProps?.find(p => p.name === 'counterpartyName')?.text) &&
                                    showCounterpartiesList ?
                                    (
                                        <div className="counterparties-list">
                                            <div className="header">
                                                {this.context.t('Your counterparties with similar name:')}
                                            </div>
                                            <SimpleBar style={{ height: 'auto', maxHeight: '300px', width: '100%' }}>
                                                <ul>
                                                    {
                                                        counterpartiesList.map(c => {
                                                            if (this.checkIfCounterpartyNameIsSimilar(returnedOCRData?.agreementProps?.find(p => p.name === 'counterpartyName')?.text, c.name)) {
                                                                return (
                                                                    <li tabIndex={"0"} className={`item counterparty-item`} onClick={() => this.selectCounterpart(c)}>{c.name}</li>
                                                                )
                                                            } else {
                                                                return null
                                                            }
                                                        })
                                                    }
                                                </ul>
                                            </SimpleBar>
                                        </div>
                                    ) : (
                                        null
                                    )
                            }
                            {
                                counterpartiesList.length > 0 &&
                                    returnedOCRData?.agreementProps?.find(p => p.name === 'counterpartyName')?.text &&
                                    this.foundAtLeastOneSimilarCounterparty(returnedOCRData?.agreementProps?.find(p => p.name === 'counterpartyName')?.text) ?
                                    (
                                        <div className={`extend-icon ${showCounterpartiesList ? 'extended' : ''}`} onClick={() => this.toggleCounterpartiesList()}></div>
                                    ) : null
                            }
                            {
                                fieldsWithWarnings.counterpartyName ? (
                                    <>
                                        <div className="warning-icon with-other-icon" data-for={`counterpartyName-warning-tooltip`} data-tip='show'></div>
                                    </>
                                ) : null
                            }
                            {
                                fieldsWithErrors.counterpartyName ? (
                                    <>
                                        <div className="error-icon with-other-icon" data-for={`counterpartyName-error-tooltip`} data-tip='show'></div>
                                    </>
                                ) : null
                            }
                        </div>
                        <div className={`form-group ${dataReadWithOcrFrame.includes('counterpartyVatId') ? 'finished-ocr-indication' : ''} ${fieldsWithErrors.counterpartyVatId ? 'error' : ''} ${fieldsWithWarnings.counterpartyVatId ? 'warning' : ''}`} ref={this.counterpartyVatIdWrapperRef}>
                            <TextareaAutosize
                                type="text"
                                name='counterpartyVatId'
                                id="counterpartyVatId"
                                value={returnedOCRData?.agreementProps?.find(p => p.name === 'counterpartyVatId')?.text || ''}
                                maxRows={10}
                                onChange={(e) => this.onInputChange(e, 'counterpartyVatId')}
                                onClick={(e) => this.onInputClick(e, 'counterpartyVatId')}
                                onBlur={e => this.onInputBlur(e, 'counterpartyVatId')}
                                onFocus={e => this.onInputFocus(e, 'counterpartyVatId')}
                            />
                            <label className='counterpartyVatId' onClick={(e) => this.labelClick(e, 'counterpartyVatId')} >
                                {this.context.t('Counterparty VatID')}
                                {
                                    processingData && processingData.find(e => e === 'counterpartyVatId') ? (
                                        <span className="loading"><Dots size={9} speed={0.8} color={'#69B792'} /></span>
                                    ) : null
                                }
                            </label>
                            {
                                fieldsWithWarnings.counterpartyVatId ? (
                                    <>
                                        <div className="warning-icon" data-for={`counterpartyVatId-warning-tooltip`} data-tip='show'></div>
                                    </>
                                ) : null
                            }
                            {
                                fieldsWithErrors.counterpartyVatId ? (
                                    <>
                                        <div className="error-icon" data-for={`counterpartyVatId-error-tooltip`} data-tip='show'></div>
                                    </>
                                ) : null
                            }
                        </div>
                    </div>

                    <div className="form-section">
                        <h4>{this.context.t('Agreement')}</h4>

                        <div className={`form-group ${dataReadWithOcrFrame.includes('number') ? 'finished-ocr-indication' : ''} ${fieldsWithErrors.number ? 'error' : ''} ${fieldsWithWarnings.number ? 'warning' : ''}`}>
                            <TextareaAutosize
                                type="text"
                                name='number'
                                id="number"
                                value={returnedOCRData?.agreementProps?.find(p => p.name === 'number')?.text || ''}
                                maxRows={10}
                                onChange={(e) => this.onInputChange(e, 'number')}
                                onClick={(e) => this.onInputClick(e, 'number')}
                                onBlur={e => this.onInputBlur(e, 'number')}
                                onFocus={e => this.onInputFocus(e, 'number')}
                            />
                            <label className='number' onClick={(e) => this.labelClick(e, 'number')} >
                                {this.context.t('Title/Number')}
                                {
                                    processingData && processingData.find(e => e === 'number') ? (
                                        <span className="loading"><Dots size={9} speed={0.8} color={'#69B792'} /></span>
                                    ) : null
                                }
                            </label>

                            {
                                fieldsWithWarnings.number ? (
                                    <>
                                        <div className="warning-icon" data-for={`number-warning-tooltip`} data-tip='show'></div>
                                    </>
                                ) : null
                            }
                            {
                                fieldsWithErrors.number ? (
                                    <>
                                        <div className="error-icon" data-for={`number-error-tooltip`} data-tip='show'></div>
                                    </>
                                ) : null
                            }
                        </div>
                        <div className={`form-group ${dataReadWithOcrFrame.includes('date') ? 'finished-ocr-indication' : ''} ${showIssueDateCalendar ? 'front' : ''} ${fieldsWithErrors.issueDate ? 'error' : ''}`} ref={this.issueDateWrapperRef}>
                            <TextareaAutosize
                                type="text"
                                name='date'
                                id="date"
                                value={returnedOCRData?.agreementProps?.find(p => p.name === 'date')?.text || ''}
                                maxRows={10}
                                onChange={(e) => this.onInputChange(e, 'date')}
                                onClick={(e) => this.onInputClick(e, 'date')}
                                onBlur={e => this.onInputBlur(e, 'date')}
                                onFocus={e => this.onInputFocus(e, 'date')}
                            />
                            <label className='date' onClick={(e) => this.labelClick(e, 'date')} >
                                {this.context.t('Agreement date')}
                                {
                                    processingData && processingData.find(e => e === 'date') ? (
                                        <span className="loading"><Dots size={9} speed={0.8} color={'#69B792'} /></span>
                                    ) : null
                                }
                            </label>
                            <div className="calendar-icon" onClick={() => this.toggleCalendar('date')}></div>
                            {
                                showIssueDateCalendar ? (
                                    <div className={`date-picker`}>
                                        <Calendar
                                            selectDay={this.onCalendarSelect}
                                            unselectDay={this.onCalendarUnselecet}
                                            selectedDay={
                                                this.checkDate(returnedOCRData?.agreementProps?.find(p => p.name === 'date')?.text || '') ? {
                                                    display: moment(parseDate(returnedOCRData?.agreementProps?.find(p => p.name === 'date')?.text || ''), 'DD-MM-YYYY').format('LL'),
                                                    value: moment(returnedOCRData?.agreementProps?.find(p => p.name === 'date')?.text || '', 'DD-MM-YYYY')
                                                } : {
                                                    display: null,
                                                    value: null
                                                }
                                            }
                                            projectId={'none'}
                                            startDate={
                                                this.checkDate(returnedOCRData?.agreementProps?.find(p => p.name === 'date')?.text || '') ?
                                                    moment(parseDate(returnedOCRData?.agreementProps?.find(p => p.name === 'date')?.text || ''), 'DD-MM-YYYY')
                                                    :
                                                    moment()}
                                        />
                                    </div>
                                ) : null
                            }

                            {
                                fieldsWithWarnings.date ? (
                                    <>
                                        <div className="warning-icon with-other-icon" data-for={`date-warning-tooltip`} data-tip='show'></div>
                                    </>
                                ) : null
                            }
                            {
                                fieldsWithErrors.date ? (
                                    <>
                                        <div className="error-icon with-other-icon" data-for={`date-error-tooltip`} data-tip='show'></div>
                                    </>
                                ) : null
                            }
                        </div>
                        <div className={`form-group ${dataReadWithOcrFrame.includes('description') ? 'finished-ocr-indication' : ''} ${fieldsWithErrors.description ? 'error' : ''} ${fieldsWithWarnings.description ? 'warning' : ''}`}>
                            <TextareaAutosize
                                type="text"
                                name='description'
                                id="description"
                                value={returnedOCRData?.agreementProps?.find(p => p.name === 'description')?.text || ''}
                                maxRows={10}
                                onChange={(e) => this.onInputChange(e, 'description')}
                                onClick={(e) => this.onInputClick(e, 'description')}
                                onBlur={e => this.onInputBlur(e, 'description')}
                                onFocus={e => this.onInputFocus(e, 'description')}
                            />
                            <label className='description' onClick={(e) => this.labelClick(e, 'description')} >
                                {this.context.t('Description')}
                                {
                                    processingData && processingData.find(e => e === 'description') ? (
                                        <span className="loading"><Dots size={9} speed={0.8} color={'#69B792'} /></span>
                                    ) : null
                                }
                            </label>

                            {
                                fieldsWithWarnings.description ? (
                                    <>
                                        <div className="warning-icon" data-for={`description-warning-tooltip`} data-tip='show'></div>
                                    </>
                                ) : null
                            }
                            {
                                fieldsWithErrors.description ? (
                                    <>
                                        <div className="error-icon" data-for={`description-error-tooltip`} data-tip='show'></div>
                                    </>
                                ) : null
                            }
                        </div>
                    </div>

                    <div className="form-section">
                        <h4>{this.context.t('Additional informations')}</h4>
                        <div className="form-group">
                            <TextareaAutosize
                                type="text"
                                name='doc_description'
                                id="doc_description"
                                value={documentDescription}
                                onChange={(e) => this.props.setDocComment(e)}
                                maxRows={10}
                                onBlur={e => this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'doc_description', documentDescription, '')}
                            />
                            <label className='doc_description'>{this.context.t('Document description')}</label>
                        </div>

                        <TagsComponent
                            currentTags={tags}
                            tagsList={tagsList}
                            canAddNewTags={tagsCreationLimitation === 'EVERYONE' || tagsCreationLimitation.includes(currentProjectRole)}
                            setTags={this.props.setTags}
                        />
                    </div>
                </SimpleBar>


                <div className="action-buttons">
                    <div className="checkmark" onClick={() => this.setState({ dataConfirmCheckbox: !dataConfirmCheckbox })}>
                        {this.context.t('I confirm the compliance of the data in the form with the data on the original document')}
                        <div className={`checkbox ${dataConfirmCheckbox ? 'checked' : ''}`}></div>
                        {
                            fieldsWithErrors.dataConfirmCheckbox ? (
                                <div className="error-icon" data-for={`dataConfirmCheckbox-error-tooltip`} data-tip='show'></div>
                            ) : null
                        }
                    </div>
                    <button type="button" className={`button`} onClick={() => this.saveData()}>
                        {this.context.t("Save")}
                    </button>
                </div>
            </div>
        )
    }
}

OcrPageExpensesForm.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    userCompanies: state.Company.companies,
    currentProject: state.User.currentProject,

    returnedOCRData: { ...state.File.returnedOCRData },
    processingData: isIterable(state.File.processingData) ? [...state.File.processingData] : [],
    processedData: isIterable(state.File.processedData) ? [...state.File.processedData] : [],
    dataReadWithOcrFrame: isIterable(state.File.dataReadWithOcrFrame) ? [...state.File.dataReadWithOcrFrame] : []
})

const mapDispatchToProps = {
    alertWarn: AlertActions.warning,
    autoSaveOcr: FileActions.autoSaveOcr,
    changeOCRData: FileActions.changeOCRData,
}

export default connect(mapStateToProps, mapDispatchToProps)(OcrPageExpensesForm)