import React, { Component } from "react";
import { NavLink, Link, Route, Redirect, Switch } from "react-router-dom";
import { PrivateRoute } from "../../components/PrivateRoute";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import Dropzone from 'react-dropzone'

import isIterable from "../../helpers/isIterable";

import DashboardPage from "./V2_Dashboard"
import LeftMenu from "./V2_MenuLeft"
import RightMenu from "./V2_MenuRight"
import TabsController from "./V2_TabsController"

import { TabsControlerActions } from '../../actions/V2_TabsControlerActions'
import { AvailableTabs, GenerateInvoiceComponents, ActionsTabsComponents, ActionsHistoryTabsComponents, SettingsTabsComponents, SuperadminTabsComponents } from "../../reducers/V2_TabsReducer"

import '../../css/V2_EasyDocs.css'

export class UserPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            leftMenuExtended: localStorage.getItem('V2_leftMenuExtended') ? JSON.parse(localStorage.getItem('V2_leftMenuExtended')) : true,
            rightMenuExtended: localStorage.getItem('V2_rightMenuExtended') ? JSON.parse(localStorage.getItem('V2_rightMenuExtended')) : true
        }
    }

    componentDidMount() {
        if (!localStorage.getItem('V2_leftMenuExtended')) localStorage.setItem('V2_leftMenuExtended', JSON.stringify(true))
        if (!localStorage.getItem('V2_rightMenuExtended')) localStorage.setItem('V2_rightMenuExtended', JSON.stringify(true))

        var additionalUrlParams = this.props.location.pathname.replace('/dashboard', '')
        console.log(additionalUrlParams)
        if (additionalUrlParams) {
            this.checkUrlAndOpenNewTab(additionalUrlParams)
        }
    }

    checkUrlAndOpenNewTab = url => {
        if (url) url = url.replace(/\/$/, '')
        switch (url) {
            case '/generate-payment-order':
                this.props.openNewTab(AvailableTabs.GENERATE_PAYMENT_ORDER, AvailableTabs.GENERATE_PAYMENT_ORDER)
            case '/generate-sale-invoice':
                this.props.openNewTab(AvailableTabs.GENERATE_SALE_INVOICE, GenerateInvoiceComponents.INVOICE)
            case '/file-preview':
                this.props.openNewTab(AvailableTabs.ALL_DOCS, AvailableTabs.ALL_DOCS)
            case '/actions':
                this.props.openNewTab(AvailableTabs.ACTIONS_TABLE, ActionsTabsComponents.ACTIONS)
                break
            case '/actions/payments-assistant':
                this.props.openNewTab(AvailableTabs.ACTIONS_TABLE, ActionsTabsComponents.PAYMRNTS_ASSISTANT)
                break
            case '/actions/zip-assistant':
                this.props.openNewTab(AvailableTabs.ACTIONS_TABLE, ActionsTabsComponents.ZIP_ASSISTANT)
                break
            case '/actions/expenses-assistant':
                this.props.openNewTab(AvailableTabs.ACTIONS_TABLE, ActionsTabsComponents.EXPENSES_ASSISTANT)
                break
            case '/actions/actions-history/generated-excels':
                this.props.openNewTab(AvailableTabs.ACTIONS_HISTORY, ActionsHistoryTabsComponents.GENERATED_EXCELS)
                break
            case '/actions/actions-history/generated-transfer-files':
                this.props.openNewTab(AvailableTabs.ACTIONS_HISTORY, ActionsHistoryTabsComponents.GENERATED_TRANSFERS)
                break
            case '/actions/actions-history/generated-zip-packages':
                this.props.openNewTab(AvailableTabs.ACTIONS_HISTORY, ActionsHistoryTabsComponents.GENERATED_ZIPS)
                break
            case '/actions/actions-history/generated-accounting-files':
                this.props.openNewTab(AvailableTabs.ACTIONS_HISTORY, ActionsHistoryTabsComponents.GENERATED_ACCOUNTING_FILES)
                break
            case '/docs':
                this.props.openNewTab(AvailableTabs.ALL_DOCS, AvailableTabs.ALL_DOCS)
                break
            case '/sales':
                this.props.openNewTab(AvailableTabs.ALL_SALE_INVOICES, AvailableTabs.ALL_SALE_INVOICES)
                break
            case '/settings/profile':
                this.props.openNewTab(AvailableTabs.SETTINGS, SettingsTabsComponents.PROFILE)
                break
            case '/settings/company':
                this.props.openNewTab(AvailableTabs.SETTINGS, SettingsTabsComponents.COMPANY)
                break
            case '/settings/projects':
                this.props.openNewTab(AvailableTabs.SETTINGS, SettingsTabsComponents.BINDERS)
                break
            case '/settings/counterparties':
                this.props.openNewTab(AvailableTabs.SETTINGS, SettingsTabsComponents.COUNTERPARTIES)
                break
            case '/settings/subscription':
                this.props.openNewTab(AvailableTabs.SETTINGS, SettingsTabsComponents.SUBSCRIPTION)
                break
            case '/helpdesk':
                this.props.openNewTab(AvailableTabs.HELPDESK, AvailableTabs.HELPDESK)
                break
            case '/superadmin/companies':
                this.props.openNewTab(AvailableTabs.SUPERADMIN, SuperadminTabsComponents.COMPANIES)
                break
            // if (tabComponent === SuperadminTabsComponents.BINDERS) return '/superadmin/binders'
            // if (tabComponent === SuperadminTabsComponents.USERS) return '/superadmin/users'
            // if (tabComponent === SuperadminTabsComponents.WHATS_NEW) return '/superadmin/whats-new'
            // if (tabComponent === SuperadminTabsComponents.EMAILS) return '/superadmin/emails'
            // if (tabComponent === SuperadminTabsComponents.PAYMENTS) return '/superadmin/payments'
            // if (tabComponent === SuperadminTabsComponents.CONVERSIONS) return '/superadmin/conversions'
            default:
                break
        }
    }

    togleLeftMenu = () => {
        const { leftMenuExtended } = this.state
        this.setState({
            leftMenuExtended: !leftMenuExtended
        })
        localStorage.setItem('V2_leftMenuExtended', JSON.stringify(!leftMenuExtended))
    }

    togleRightMenu = () => {
        const { rightMenuExtended } = this.state
        this.setState({
            rightMenuExtended: !rightMenuExtended
        })
        localStorage.setItem('V2_rightMenuExtended', JSON.stringify(!rightMenuExtended))
    }

    getMenuClassesForTabsWrapper = () => {
        const { leftMenuExtended, rightMenuExtended } = this.state
        var classes = ''
        if (leftMenuExtended) classes += 'left-menu-extended'
        if (leftMenuExtended && rightMenuExtended) {
            classes += ' both-menus-extended'
        } else if (leftMenuExtended || rightMenuExtended) {
            classes += ' one-menu-extended'
        } else if (!leftMenuExtended && !rightMenuExtended) {
            classes += ' none-menus-extended'
        }

        return classes
    }

    render() {
        const { leftMenuExtended, rightMenuExtended } = this.state
        const { location, history } = this.props
        return (
            <div className="easydocs-wrapper">
                <LeftMenu
                    menuExtended={leftMenuExtended}
                    toggleMenu={this.togleLeftMenu}
                />
                <TabsController
                    tabsWrapperClasses={this.getMenuClassesForTabsWrapper()}
                    location={location}
                    history={history}
                />
                <RightMenu
                    menuExtended={rightMenuExtended}
                    toggleMenu={this.togleRightMenu}
                />
            </div>
        )
    }
}

UserPanel.contextTypes = {
    t: PropTypes.func,
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
    openNewTab: TabsControlerActions.openNewTab,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPanel)
