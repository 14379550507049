import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

export class FileAcceptanceStepsSection extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        const { pdfPreviewData } = this.props
        return (
            <>
                <div className="doc-prop">
                    <span className="name">{this.context.t('Uploaded by:')}</span>
                    <span className="uneditable-prop">{`${pdfPreviewData.uploadedBy}${pdfPreviewData.uploadDate ? ` - ${moment(pdfPreviewData.uploadDate).format('YYYY-MM-DD HH:mm')}` : ''}`}</span>
                </div>
                {
                    pdfPreviewData.deleted ? (
                        <div className="doc-prop">
                            <span className="name">{this.context.t('Removed by:')}</span>
                            <span className="uneditable-prop">{pdfPreviewData.deletedBy}</span>
                        </div>
                    ) : (

                        pdfPreviewData.verifiedBy ? (
                            <div className="doc-prop">
                                <span className="name">{this.context.t('Verified by:')}</span>
                                <span className="uneditable-prop">{`${pdfPreviewData.verifiedBy}${pdfPreviewData.verificationDate ? ` - ${moment(pdfPreviewData.verificationDate).format('YYYY-MM-DD HH:mm')}` : ''}`}</span>
                            </div>
                        ) : (
                            null
                        )
                    )
                }
                {
                    pdfPreviewData.deleted ? (
                        <div className="doc-prop">
                            <span className="name">{this.context.t('Date of removal:')}</span>
                            <span className="uneditable-prop">{moment(pdfPreviewData.deletedDate).format('DD-MM-YYYY')}</span>
                        </div>
                    ) : (
                        pdfPreviewData.acceptedBy ? (
                            <div className="doc-prop">
                                <span className="name">{this.context.t('Accepted by:')}</span>
                                <span className="uneditable-prop">{`${pdfPreviewData.acceptedBy}${pdfPreviewData.acceptanceDate ? ` - ${moment(pdfPreviewData.acceptanceDate).format('YYYY-MM-DD HH:mm')}` : ''}`}</span>
                            </div>
                        ) : (
                            null
                        )
                    )
                }
            </>
        )
    }
}

FileAcceptanceStepsSection.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(FileAcceptanceStepsSection)