import React, { Component } from "react";
import { connect } from "react-redux";
import moment from 'moment'
import PropTypes from "prop-types";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import { CompanyActions } from "../../actions/CompanyActions";
import { GTU_CODE } from "./CorrectionItemForm";
import { AlertActions } from "../../actions/AlertActions";
import ReactTooltip from 'react-tooltip'

import "../../../src/css/SalesPage.css";

export class NewDocItemPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            pkwiu: '',
            gtu: '',
            amount: '1',
            unit: 'szt.',
            netPrice: '',
            vatRate: '23%',

            cursorWait: false,
            showGtuList: false,
            showUnitsList: false,
            showVatRatesList: false
        };

        this.gtuContainerRef = React.createRef()
        this.unitContainerRef = React.createRef()
        this.vatRateContainerRef = React.createRef()
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick);
        const { editingExisting, editedItem, newItem } = this.props
        if (editingExisting) {
            this.setState({
                name: editedItem.name,
                pkwiu: editedItem.pkwiu || '',
                gtu: editedItem.gtu || '',
                amount: editedItem.amount || '1',
                unit: editedItem.unit || 'szt.',
                netPrice: editedItem.netPrice,
                vatRate: editedItem.vatRate || '23%'
            })
        }

        if (newItem) {
            this.setState({
                name: newItem.name,
                pkwiu: newItem.pkwiu || '',
                gtu: newItem.gtu || '',
                amount: newItem.amount || '1',
                unit: newItem.unit || 'szt.',
                netPrice: newItem.netPrice,
                vatRate: newItem.vat || '23%'
            })
        }
    }

    handleClick = (event) => {
        const { showGtuList, showUnitsList, showVatRatesList } = this.state
        if (showGtuList && this.gtuContainerRef && !this.gtuContainerRef.current.contains(event.target)) {
            this.setState({
                showGtuList: false
            })
        }

        if (showUnitsList && this.unitContainerRef && !this.unitContainerRef.current.contains(event.target)) {
            this.setState({
                showUnitsList: false
            })
        }

        if (showVatRatesList && this.vatRateContainerRef && !this.vatRateContainerRef.current.contains(event.target)) {
            this.setState({
                showVatRatesList: false
            })
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
    }

    onInputChange = (e) => {
        e.preventDefault();
        var { name, value } = e.target;

        if (name === 'amount' || name === 'netPrice') {
            value = value.replace(/[^0-9.,]/gm, '').replace(',', '.')
        }

        this.setState({ [name]: value });
    };

    saveItem = () => {
        const { cursorWait, name, pkwiu, gtu, amount, unit, netPrice, vatRate } = this.state
        const { currentCompany, editingExisting, editedItem, validateAllFieldsForRecurringInvoice } = this.props
        var valid = true


        if (!name) {
            this.props.alertWarn(this.context.t('Name is required'))
            valid = false
        }

        if (validateAllFieldsForRecurringInvoice) {
            if (!amount) {
                this.props.alertWarn(this.context.t('Amount is required'))
                valid = false
            } else if (!unit) {
                this.props.alertWarn(this.context.t('Unit is required'))
                valid = false
            } else if (!netPrice || !parseFloat(netPrice)) {
                this.props.alertWarn(this.context.t('Net price is required'))
                valid = false
            } else if (!vatRate) {
                this.props.alertWarn(this.context.t('VAT rate is required'))
                valid = false
            }
        }

        if (valid && !cursorWait) {
            this.setState({
                cursorWait: true
            })
            if (editingExisting) {
                this.props.editInvoiceItem(currentCompany.company_id, editedItem.id, name, pkwiu, gtu, amount, unit, netPrice, vatRate, (newItem) => {
                    if (newItem) {
                        this.props.savedChanges(newItem)
                        this.props.close()
                    }
                    this.setState({
                        cursorWait: false
                    })
                })
            } else {
                this.props.saveNewInvoiceItem(currentCompany.company_id, name, pkwiu, gtu, amount, unit, netPrice, vatRate, (newItem) => {
                    if (newItem) {
                        this.props.createdNewItem(newItem)
                        this.props.close()
                    }
                    this.setState({
                        cursorWait: false
                    })
                })
            }
        }
    }

    setGtu = value => {
        this.setState({
            gtu: value,
            showGtuList: false
        })
    }

    setUnit = value => {
        this.setState({
            unit: value,
            showGtuList: false
        })
    }

    setVat = value => {
        this.setState({
            vatRate: value,
            showVatRatesList: false
        })
    }

    render() {
        const { cursorWait, name, pkwiu, gtu, amount, unit, netPrice, vatRate, showGtuList, showUnitsList, showVatRatesList } = this.state
        const { editingExisting } = this.props
        return (
            <div className={`new-item-popup ${cursorWait ? 'cursor-wait' : ''}`}>
                <div className="new-item-window">
                    <div className="main-text-item">
                        {
                            editingExisting ? (
                                this.context.t("Edit a product/service")
                            ) : (
                                this.context.t("Add a new product/service")
                            )
                        }
                    </div>
                    <div className="add-new-label">
                        <div className="form-group">
                            <input type="text" id="add-new" autocomplete="off" name="name" value={name} onChange={this.onInputChange} />
                            <label for="add-new">{this.context.t("Item name")}</label>
                            <span className="info" data-tip="show" data-for="infoTooltip"></span>
                            <ReactTooltip id="infoTooltip" className="default-tooltip info-tooltip" place="bottom" effect="solid">
                                <p>{this.context.t("You can add your own date mask: DD- day, MM- month, MMM - month in words, KK - quarter, RRRR or RR - year e.g. Accounting services DD-MM-RRRR, which will appear on the invoice as Accounting services {n}", { n: moment().format('DD-MM-YYYY') })}</p>
                            </ReactTooltip>
                        </div>
                        <div className="form-group small">
                            <input type="text" className="add-pkwiu" id="add-pkwiu" autocomplete="off" name="pkwiu" value={pkwiu} onChange={this.onInputChange} />
                            <label for="add-pkwiu">{this.context.t("PKWiU")}</label>
                        </div>
                        <div className="form-group small" onClick={() => this.setState({ showGtuList: !showGtuList })} ref={this.gtuContainerRef}>
                            <input type="text" className="add-gtu" id="add-gtu" autocomplete="off" name="gtu" value={gtu} onChange={this.onInputChange} />
                            <label for="add-gtu">{this.context.t("GTU")}</label>

                            <span className={`expend-icon ${showGtuList ? "expended" : ""}`}></span>
                            {
                                showGtuList ? (
                                    <div className="sugestions-list gtu">
                                        <SimpleBar style={{ maxHeight: "30vh", width: "100%" }} >
                                            <ul>
                                                {Object.entries(GTU_CODE).map(([key, value]) => (
                                                    <li onClick={() => this.setGtu(`${key === "null" ? "" : key}`)} >
                                                        {value}
                                                    </li>
                                                ))}
                                            </ul>
                                        </SimpleBar>
                                    </div>
                                ) : null
                            }
                        </div>
                        <div className="form-group small">
                            <input type="text" className="add-amount" id="add-amount" autocomplete="off" name="amount" value={amount} onChange={this.onInputChange} />
                            <label for="add-new">{this.context.t("Amount ")}</label>
                        </div>
                        <div className="form-group small" onClick={() => this.setState({ showUnitsList: !showUnitsList })} ref={this.unitContainerRef}>
                            <input type="text" className="add-unit" id="add-unit" autocomplete="off" name="unit" value={unit} onChange={this.onInputChange} />
                            <label for="unit">{this.context.t("Unit")}</label>

                            <span className={`expend-icon ${showUnitsList ? "expended" : ""}`}></span>
                            {
                                showUnitsList ? (
                                    <div className="sugestions-list">
                                        <ul>
                                            <li onClick={() => this.setUnit("szt.")}>szt.</li>
                                            <li onClick={() => this.setUnit("os.")}>os.</li>
                                            <li onClick={() => this.setUnit("godz.")}>godz.</li>
                                            <li onClick={() => this.setUnit("dni")}>dni</li>
                                            <li onClick={() => this.setUnit("m-c")}>m-c</li>
                                            <li onClick={() => this.setUnit("km")}>km</li>
                                            <li onClick={() => this.setUnit("MWh")}>MWh</li>
                                            <li onClick={() => this.setUnit("inna")}>inna</li>
                                        </ul>
                                    </div>
                                ) : null
                            }
                        </div>
                        <div className="form-group small">
                            <input type="text" className="add-net" id="add-net" autocomplete="off" name="netPrice" value={netPrice} onChange={this.onInputChange} />
                            <label for="add-net">{this.context.t("Net price")}</label>
                        </div>
                        <div className="form-group small" onClick={() => this.setState({ showVatRatesList: !showVatRatesList })} ref={this.vatRateContainerRef}>
                            <input type="text" className="add-vat" id="add-vat" autocomplete="off" name="vatRate" value={vatRate} onChange={this.onInputChange} />
                            <label for="add-vat">{this.context.t("VAT rate")}</label>

                            <span className={`expend-icon ${showVatRatesList ? "expended" : ""}`} ></span>
                            {
                                showVatRatesList ? (
                                    <div className="sugestions-list">
                                        <ul>
                                            <li onClick={() => this.setVat("23%")}>23%</li>
                                            <li onClick={() => this.setVat("8%")}>8%</li>
                                            <li onClick={() => this.setVat("5%")}>5%</li>
                                            <li onClick={() => this.setVat("3%")}>3%</li>
                                            <li onClick={() => this.setVat("0")}>0</li>
                                            <li onClick={() => this.setVat("zw")}>zw</li>
                                            <li onClick={() => this.setVat("np")}>np</li>
                                            <li onClick={() => this.setVat("oo")}>oo</li>
                                        </ul>
                                    </div>
                                ) : null
                            }
                        </div>
                    </div>
                    <div className="action-buttons">
                        <div className="cancel-button" onClick={() => this.props.close()}>{this.context.t("Cancel")}</div>
                        <div className="save-button" onClick={() => this.saveItem()}>{this.context.t("Save")}</div>
                    </div>
                </div>
            </div>
        )
    }
}

NewDocItemPopup.contextTypes = {
    t: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
    currentCompany: state.Company.currentCompany,
});

const mapDispatchToProps = {
    getSavedDocItems: CompanyActions.getSavedDocItems,
    saveNewInvoiceItem: CompanyActions.saveNewInvoiceItem,
    editInvoiceItem: CompanyActions.editInvoiceItem,
    alertWarn: AlertActions.warning,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewDocItemPopup);
