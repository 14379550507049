import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Spinner, Bounce } from 'react-activity'
import 'react-activity/dist/react-activity.css'
import ReactTooltip from 'react-tooltip'

import { AlertActions } from '../../../../actions/AlertActions'
import { UserActions } from '../../../../actions/UserActions'
import { CompanyActions } from '../../../../actions/CompanyActions'

import { replaceSpecialCharacters } from '../../../../helpers/textSlugify'
import { emailRegex, shortnameRegex } from '../../../../helpers/regexList'

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

export class CreateProject extends Component {

    constructor(props) {
        super(props)
        this.state = {
            name: '',
            code: '',
            asignedUsers: [],
            usersToInvite: [],
            invitedUsersToJoin: [],
            newUserEmail: '',
            newProjectCodeIsUnique: true,
        };
    }

    componentDidMount() {
        var asignedUsers = []
        asignedUsers.push(this.props.user.id)
        this.setState({
            asignedUsers: asignedUsers
        })

        if (this.props.companyWorkers && this.props.companyWorkers.length === 0) this.props.getAllCompanyWorkers(this.props.currentCompany.company.id)
    }

    onInputChange = e => {
        e.preventDefault()

        var { name, value } = e.target

        if (name !== 'code') {
            this.setState({ [name]: value })
        } else {
            value = value.replace(/[^A-Za-z0-9_-]/g, '')
            value = replaceSpecialCharacters(value)
            this.setState({ [name]: value })
            if (value.length >= 3) {
                this.props.chackeIfProjectCodeIsUnique(value, unique => {
                    this.setState({
                      newProjectCodeIsUnique: unique
                    })
                  })
            }
        }
    }

    generateProjectcode = e => {
        const { name, code } = this.state

        if (name && !code) {
            var newCode = ''
            var nameValue = name.replace(/\"/g, '').replace(/\'/g, '')
            var nameParts = nameValue.split(/[\s-]/gm)

            if (nameParts.length === 2) {
                newCode = nameParts[0] + '-' + nameParts[1]
            } else if (nameParts.length > 1) {
                nameParts.forEach(part => {
                    newCode += part.slice(0, 1)
                })
            } else if (nameValue.length < 12) {
                newCode = nameValue
            } else {
                newCode = nameValue.slice(0, 3)
            }

            newCode = newCode.toUpperCase()
            newCode = replaceSpecialCharacters(newCode)

            this.setState({
                code: newCode
            })
            if (newCode.length >= 3) {
                this.props.chackeIfProjectCodeIsUnique(newCode, unique => {
                    this.setState({
                      newProjectCodeIsUnique: unique
                    })
                  })
            }
        }
    }

    validateProject = e => {
        e.preventDefault()

        const { name, code } = this.state;

        let valid = true;

        if (name.length === 0) {
            valid = false
            this.props.alertWarn(this.context.t('Name is required'))
        }
        if (code.length === 0) {
            valid = false
            this.props.alertWarn(this.context.t('Code is required'))
        }
        else if (code.length < 3) {
            valid = false
            this.props.alertWarn(this.context.t('Code must have at least 3 characters.'))
        }
        else if (code.length > 12) {
            valid = false
            this.props.alertWarn(this.context.t('Code can have a maximum of 12 characters.'))
        }

        if (valid) {
            this.state.root_dir = this.state.code + '/'
            this.props.saveProject(this.state, this.props.currentCompany.company_id, this.state.asignedUsers, this.state.usersToInvite, this.props.user.id)
        }
    }

    asignUser = (e, id) => {
        e.preventDefault()
        const { companyWorkers, user } = this.props;

        var currentAsignedWorkers = this.state.asignedUsers

        if (user.id === id) {
            this.props.alertWarn(this.context.t('As a creator you will be assigned to this project and can\'t leave it.'))
        } else {
            if (currentAsignedWorkers.includes(id)) {
                this.setState({
                    asignedUsers: currentAsignedWorkers.filter(e => e !== id)
                })
            } else {
                currentAsignedWorkers.push(id)

                this.setState({
                    asignedUsers: currentAsignedWorkers
                })
            }
        }
    }

    removeUserFromInviteList = (email) => {
        var currentInvitedUsers = this.state.usersToInvite

        currentInvitedUsers = currentInvitedUsers.filter(e => e !== email)

        this.setState({
            usersToInvite: currentInvitedUsers
        })

    }

    addEmailToInviteList = (e) => {
        e.preventDefault();
        const email = this.state.newUserEmail
        const { companyWorkers } = this.props;

        var valid = true
        if (email.length === 0) {
            valid = false
            this.props.alertWarn(this.context.t('E-mail address is required'))
        }
        else if (!emailRegex.test(email)) {
            valid = false
            this.props.alertWarn(this.context.t('E-mail address is not valid'))
        } else if (companyWorkers.find(e => e.email === email)) {
            valid = false
            this.props.alertWarn(this.context.t('User with that e-mail address already is in Your company'))
        }

        var currentUsersToInvite = this.state.usersToInvite


        if (valid) {
            currentUsersToInvite.push(email)

            this.setState({
                usersToInvite: currentUsersToInvite,
                newUserEmail: ''
            })
        }
    }

    handleSelectAll = () => {
        var currentSelectedUsers = [...this.state.asignedUsers]
        const { companyWorkers, currentCompany, user } = this.props
        var oneSelected = false
        companyWorkers.forEach(g => {
            if (g.company.id === currentCompany.company_id) {
                g.members.forEach(u => {
                    if (!currentSelectedUsers.includes(u.id)) {
                        currentSelectedUsers.push(u.id)
                        oneSelected = true
                    }
                })
            }
        })

        this.setState({
            asignedUsers: oneSelected ? currentSelectedUsers : []
        })
    }

    checkIfSelectedAllProjectsFromNewUser = () => {
        const { companyWorkers, currentCompany } = this.props
        const { asignedUsers } = this.state

        var allUsersCount = 0

        companyWorkers.forEach(g => {
            if (g.company.id === currentCompany.company_id) {
                if (g.members) allUsersCount = g.members.length
            }
        })

        return asignedUsers.length === allUsersCount
    }

    toggleCompaniesList = () => {
        this.setState({
            showCompaniesList: !this.state.showCompaniesList
        })
    }

    changeCurrentCompany = (e, index) => {
        e.preventDefault()
        console.log(index)

        this.props.setCurrentCompany(this.props.userCompanies[index])

        var asignedUsers = []
        asignedUsers.push(this.props.user.id)

        this.setState({
            currentProject: {},
            showCompaniesList: false,
            asignedUsers: asignedUsers
        })

        const { groupedProjects } = this.props
        var currentGroup = groupedProjects.find(e => e.company.id === this.props.userCompanies[index].company_id)
        if (currentGroup && currentGroup.projects) {
            this.props.switchDashboardViewModel(currentGroup.projects[0].id)
        }

        //this.props.getAllCompanyWorkers(this.props.userCompanies[index].company_id)
    }

    render() {
        const { name, code, asignedUsers, usersToInvite, userToInviteEmail, newUserEmail, showCompaniesList, newProjectCodeIsUnique } = this.state;
        const { companyWorkers, user, loadingScreen, currentCompany, userCompanies } = this.props;

        return (
            <div className="create-project-wraper">

                {
                    loadingScreen ? (
                        <div className="loading-screen">
                            <div className="activity-indicator">
                                <Spinner size={50} speed={0.8} color={"#ffffff"} />
                            </div>
                        </div>
                    ) : (
                        null
                    )
                }

                <div className="project-col">
                    <div className="company-selection-row">
                        <h3>{this.context.t('Select the company where you create the binder')}:</h3>

                        <div className="company-switcher" ref="companySelectionRef">
                            <div className="current" onClick={() => this.toggleCompaniesList()}>
                                <h2>{currentCompany && currentCompany.company ? currentCompany.company.code : ''}</h2>
                                <span className={`expend-icon ${showCompaniesList ? 'expended' : ''}`}></span>
                            </div>
                            {
                                showCompaniesList ? (
                                    <div id="copaniesList" className="companies-list">
                                        <div className="header">
                                            {this.context.t('Choose company')}
                                        </div>
                                        <SimpleBar style={{ maxHeight: 'calc(75vh - 70px)', width: '100%' }}>
                                            <ul>
                                                {
                                                    userCompanies.map((company, i) => {
                                                        if (company.user_role === 'ADMIN') {
                                                            return (
                                                                <li className={`${currentCompany.id === company.id ? 'current-selected' : ''}`} onClick={e => this.changeCurrentCompany(e, i)}>
                                                                    {company.company.code}
                                                                </li>
                                                            )
                                                        } else {
                                                            return null
                                                        }
                                                    })
                                                }
                                            </ul>
                                        </SimpleBar>
                                    </div>
                                ) : (
                                    null
                                )
                            }
                        </div>
                    </div>

                    <div className="header-row">
                        {this.context.t('Set binder name')}
                    </div>

                    <div className="form-group">
                        <input type="text" name="name" id="name" value={name} onChange={this.onInputChange} onBlur={this.generateProjectcode} />
                        <label htmlFor="name">{this.context.t('Binder Name')}</label>
                    </div>
                    <div className="form-group code">
                        <input type="text" name="code" id="code" value={code} onChange={this.onInputChange} autoComplete={'off'} />
                        <label htmlFor="code">{this.context.t('Binder Code')}</label>
                        <span className="info" data-tip="show" data-for='code-tooltip'></span>
                        <ReactTooltip id='code-tooltip' place="top" effect="solid" className="default-tooltip">
                            <ul>
                                <li>{this.context.t('The code cannot be changed after the binder has been created')}</li>
                                <li>{this.context.t('It is used as a prefix in e-mail to send documents and as the name of a directory in the EasyDocs cloud')}</li>
                                <li>{this.context.t('Minimum 3 characters')}</li>
                                <li>{this.context.t('Only letters, digits, underscore and dash')}</li>
                            </ul>
                        </ReactTooltip>
                    </div>

                    {
                        code.length >= 3 ? (
                            !newProjectCodeIsUnique ? (
                                <div className="not-unique">
                                    {this.context.t('This code is already used. Try another one.')}
                                </div>
                            ) : (
                                newProjectCodeIsUnique ? (
                                    <div className="unique">
                                        <div className="info">
                                            <h3>{this.context.t('Remember! Binders name and its short name cannot be edited after it has been created')}</h3>
                                            <p>{this.context.t('You can import documents into this binder by sending e-mails with attachments to this address: ')}<span className="email">{`${code}@app.easydocs.pl`}</span></p>
                                        </div>
                                        <div className="img"></div>
                                        <div className="disclaimer">
                                            {this.context.t('After sending an e-mail with an attachment, you will receive a report on the sending - pay attention to whether all files have been successfully imported.')}
                                        </div>
                                    </div>
                                ) : (
                                    null
                                )
                            )
                        ) : (
                            null
                        )
                    }

                    <div className="invite-new-info">
                        {this.context.t('Invite and assign new member')}
                    </div>

                    <div className="invite-new-member">
                        <label htmlFor="newUserEmail">{this.context.t('New member email')}</label>
                        <input type="text" name="newUserEmail" id="newUserEmail" value={newUserEmail} placeholder={this.context.t("e.g justin.ramirez@mail.com")} onChange={e => this.onInputChange(e)} autoComplete={'off'} />
                        <div className={`add-button ${emailRegex.test(newUserEmail) ? 'active' : ''}`} onClick={e => this.addEmailToInviteList(e)}>{this.context.t('Invite')}</div>
                    </div>

                    <div className="action-buttons">
                        <div className="btn cancel" onClick={() => this.props.changeTab('PROJECTS')}>
                            {this.context.t('Cancel')}
                        </div>
                        <div className="btn create" onClick={e => this.validateProject(e)}>
                            {this.context.t('Create binder')}
                        </div>
                    </div>
                </div>

                <div className="members-col">
                    <div className="header-row">
                        {this.context.t('Assign members')}
                        <span className="select-all" onClick={() => this.handleSelectAll()}>
                            {this.context.t('Select all')}
                            <span className={`checkbox ${this.checkIfSelectedAllProjectsFromNewUser() ? 'checked' : ''}`}></span>
                        </span>
                    </div>
                    <SimpleBar className="simple-bar-custom" style={{ height: 'calc(100% - 70px)', width: '100%', paddingLeft: '25px' }} >
                        <div className="company-worker">
                            <div className={`profile-pic ${user.profile_pic_link ? '' : 'default'}`}>
                                {
                                    user.profile_pic_link ? (
                                        <img src={user.profile_pic_link} alt="" />
                                    ) : (
                                        null
                                    )
                                }
                            </div>
                            {
                                user.first_name && user.last_name ? (
                                    <div className="info">
                                        <div className="name">{`${user.first_name} ${user.last_name} `}{this.context.t('(Me)')}</div>
                                        <div className="email">{user.email}</div>
                                    </div>
                                ) : (
                                    <div className="info">
                                        <div className="name">{user.email}</div>
                                    </div>
                                )
                            }
                            <div className="checkmark">
                                <span className="checkbox checked" onClick={e => this.asignUser(e, user.id)}></span>
                            </div>
                        </div>
                        {
                            companyWorkers.map(companyGroup => {
                                return (
                                    companyGroup.company.id === currentCompany.company_id ? (
                                        companyGroup.members.map((worker, i) => {
                                            if (worker.email !== user.email) {
                                                return (
                                                    <div className="company-worker">
                                                        <div className={`profile-pic ${worker.profile_pic_link ? '' : 'default'}`}>
                                                            {
                                                                worker.profile_pic_link ? (
                                                                    <img src={worker.profile_pic_link} alt="" />
                                                                ) : (
                                                                    null
                                                                )
                                                            }
                                                        </div>

                                                        {
                                                            worker.first_name && worker.last_name ? (
                                                                <div className="info">
                                                                    <div className="name">{`${worker.first_name} ${worker.last_name}`}</div>
                                                                    <div className="email">{worker.email}</div>
                                                                </div>
                                                            ) : (
                                                                <div className="info">
                                                                    <div className="name">{worker.email}</div>
                                                                </div>
                                                            )
                                                        }
                                                        <div className="checkmark">
                                                            <span className={`checkbox ${asignedUsers.includes(worker.id) ? 'checked' : ''}`} onClick={e => this.asignUser(e, worker.id)}></span>
                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                return null
                                            }
                                        })
                                    ) : (
                                        null
                                    )
                                )
                            })
                        }

                        {
                            usersToInvite.length > 0 ? (
                                <div className="separator"></div>
                            ) : (
                                null
                            )
                        }

                        {
                            usersToInvite.map(newMember => {
                                return (
                                    <div className="company-worker">
                                        <div className="profile-pic default">
                                        </div>
                                        <div className="info">
                                            <div className="name">{newMember}</div>
                                        </div>

                                        <div className="checkmark">
                                            <span className="checkbox checked" onClick={e => this.removeUserFromInviteList(newMember)}></span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </SimpleBar>
                </div>

            </div>
        )
    }
}

CreateProject.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    currentCompany: state.Company.currentCompany,
    companyWorkers: state.Company.companyWorkers || [],
    userCompanies: state.Company.companies || [],
    groupedProjects: state.User.groupedProjects,
    user: state.User.user,
    loadingScreen: state.File.loadingScreen,
})

const mapDispatchToProps = {
    saveProject: UserActions.createProject,
    getAllCompanyWorkers: CompanyActions.getAllCompanyWorkers,
    setCurrentCompany: CompanyActions.setCurrentCompany,
    switchDashboardViewModel: UserActions.switchDashboardViewModel,
    chackeIfProjectCodeIsUnique: CompanyActions.chackeIfProjectCodeIsUnique,
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateProject)