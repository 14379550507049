import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import ProjectSwitcher from '../ProjectSwitcher'
import Calendar from '../Calendar'

import { UserActions } from '../../actions/UserActions'
import { ReportsActions } from '../../actions/ReportsActions'
import { CompanyActions } from '../../actions/CompanyActions'
import { AlertActions } from '../../actions/AlertActions'
import { TransfersActions } from '../../actions/TransfersActions'

import arrayObjectIndexOf from '../../helpers/indexOfObject';
import isIterable from "../../helpers/isIterable"

export class DocsSearchModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showFilters: false,
            dateFilter: 'importDate',
            dateFilterText: 'Import date',
            showDateFilterList: false,
            startDate: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
            startDateValue: moment().subtract(1, 'month').startOf('month'),
            showStartDatePicker: false,
            endDate: moment().format('YYYY-MM-DD'),
            endDateValue: moment(),
            showEndDatePicker: false,
            showProjectsList: false,
            selectedProject: {
                name: '<expand list>',
                id: null
            },
            selectedProjects: [],
            selectedProjectCompany: null,
            roleInSelectedProject: '',

            showAllUnpaid: false,
            showUnpaidFromPeriod: false,

            showAllNotExported: false,
            showNotExportedFromPeriod: false,

            showAllNotSettled: false,
            showNotSettledFromPeriod: false,
            userId: -1,
            selectedUser: null,
            showUsersList: false,
        }

        this.containerRef = React.createRef()
        this.projectRef = React.createRef()
        this.usersListRef = React.createRef()
        this.dateFilterRef = React.createRef()
        this.fromInputRef = React.createRef()
        this.toInputRef = React.createRef()
    }

    componentDidMount() {
        const { currentProject, searchForUnpaid, searchForNotExported, searchForNotSettled, userId, usersList } = this.props
        console.log(userId, usersList)
        if (currentProject) {
            this.setState({
                selectedProject: {
                    name: currentProject.code,
                    id: currentProject.id
                }
            })
        }

        if (searchForUnpaid) {
            this.setState({
                showAllUnpaid: true,
                showUnpaidFromPeriod: false
            })
        }

        if (searchForNotExported) {
            this.setState({
                showAllNotExported: true,
                showNotExportedFromPeriod: false
            })
        }

        if (searchForNotSettled) {
            this.setState({
                showAllNotSettled: true,
                showNotSettledFromPeriod: false,
                userId: userId,
                selectedUser: userId ? usersList.find(u => u.id === userId) : { name: 'All employees' }
            })
        }

        document.addEventListener("mousedown", this.handleClick);
        window.addEventListener("keydown", this.escFunction, false)
    }

    componentDidUpdate(prevProps) {
        const { usersList, userId } = this.props
        if (prevProps.usersList !== usersList) {
            this.setState({
                userId: userId,
                selectedUser: userId ? usersList.find(u => u.id === userId) : { name: 'All employees' }
            })
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
        window.removeEventListener("keydown", this.escFunction, false)
    }

    escFunction = (e) => {
        if (e.keyCode === 27) {
            const { showDateFilterList, showStartDatePicker, showEndDatePicker, showProjectsList, showFilters } = this.state
            if (showDateFilterList || showStartDatePicker || showEndDatePicker || showProjectsList) {
                this.setState({
                    showDateFilterList: false,
                    showStartDatePicker: false,
                    showEndDatePicker: false,
                    showProjectsList: false
                })
            } else if (showFilters) {
                this.setState({
                    showFilters: false
                })
            }
        }
    }

    handleClick = (event) => {
        if (this.containerRef && !this.containerRef.current.contains(event.target)) {
            this.setState({
                showFilters: false,
                showDateFilterList: false,
                showStartDatePicker: false,
                showEndDatePicker: false,
                showUsersList: false,
                showProjectsList: false
            })
        } else {
            const { showDateFilterList, showStartDatePicker, showEndDatePicker, showProjectsList, showUsersList } = this.state

            if (showDateFilterList && this.dateFilterRef && !this.dateFilterRef.current.contains(event.target)) {
                this.setState({
                    showDateFilterList: false
                })
            }

            if (showStartDatePicker && this.fromInputRef && !this.fromInputRef.current.contains(event.target)) {
                this.setState({
                    showStartDatePicker: false
                })
            }

            if (showEndDatePicker && this.toInputRef && !this.toInputRef.current.contains(event.target)) {
                this.setState({
                    showEndDatePicker: false
                })
            }

            if (showProjectsList && this.projectRef && !this.projectRef.current.contains(event.target)) {
                this.setState({
                    showProjectsList: false
                })
            }

            if (showUsersList && this.usersListRef && !this.usersListRef.current.contains(event.target)) {
                this.setState({
                    showUsersList: false
                })
            }
        }
    }

    toggleProjectsListInSingleView = (show) => {
        this.setState({
            showProjectsList: show,
        })
    }

    setCurrentProject = (id, companyId, e) => {
        const { selectedProject } = this.state
        const { userProjects } = this.props

        const project = userProjects.find(p => p.id === id)

        if (selectedProject && selectedProject.id !== id) {
            this.setState({
                selectedProject: { id: id, name: project.code },
                roleInSelectedProject: project.acceptance_permissions,
                showProjectsList: false,
                selectedProjects: []
            }, () => {
                this.getDocs(e, id)

                var { userCompanies, currentCompany } = this.props
                if (currentCompany && currentCompany.company_id !== companyId) {
                    var companyIndex = arrayObjectIndexOf(userCompanies, companyId, 'company_id')
                    console.log(companyIndex)
                    if (companyIndex > -1) {
                        if (userCompanies[companyIndex].user_role === 'ADMIN') {
                            this.props.setCurrentCompany(userCompanies[companyIndex])
                        }
                    }
                }
            })
        }
    }

    setMultiSelectedProjects = projects => {
        this.setState({
            selectedProjects: projects,
            selectedProject: { id: null, name: null }
        })
    }

    getDocs = (e, projectId) => {
        if (e) e.preventDefault()
        this.setState({
            showFilters: false
        })

        const { startDateValue, endDateValue, selectedProject, selectedProjects, dateFilter, dateFilterText } = this.state
        const { selectedType, searchType } = this.props
        var valid = true

        if (selectedProjects && selectedProjects.length > 0) {
            selectedProjects.map(p => {
                if (!p.id) {
                    valid = false
                }
            })

            if (!valid) {
                this.props.alertWarn(this.context.t('Binder is required'))
            }
        } else if (!selectedProject.id) {
            valid = false
            this.props.alertWarn(this.context.t('Binder is required'))
        }

        if (!startDateValue) {
            valid = false
            this.props.alertWarn(this.context.t('Start date is required '))
        }
        if (!endDateValue) {
            valid = false
            this.props.alertWarn(this.context.t('End date is required'))
        }

        if (startDateValue && endDateValue && moment(startDateValue).diff(moment(endDateValue), 'days', true) > 0) {
            valid = false
            this.props.alertWarn(this.context.t('Start date must be before end date'))
        }

        if (valid) {
            // this.props.clearParentStateOnDocsSearch()
            var additionalFilteringCriteria = {}
            const { showAllUnpaid, showUnpaidFromPeriod, showAllNotExported, showNotExportedFromPeriod, showAllNotSettled, showNotSettledFromPeriod, selectedUser } = this.state
            const { searchForUnpaid, searchForNotExported, searchForNotSettled } = this.props
            if (searchForUnpaid) {
                if (showAllUnpaid) {
                    additionalFilteringCriteria.allUnpaid = true
                } else if (showUnpaidFromPeriod) {
                    additionalFilteringCriteria.unpaid = true
                }
            }
            if (searchForNotExported) {
                if (showAllNotExported) {
                    additionalFilteringCriteria.allNotExportedDocs = true
                } else if (showNotExportedFromPeriod) {
                    additionalFilteringCriteria.notExportedDocs = true
                }
            }
            if (searchForNotSettled) {
                if (showAllNotSettled) {
                    additionalFilteringCriteria.allNotSettled = true
                } else if (showNotSettledFromPeriod) {
                    additionalFilteringCriteria.notSettled = true
                }
                if (selectedUser && selectedUser.id) {
                    additionalFilteringCriteria.userId = selectedUser.id
                }
            }
            if (selectedProjects && selectedProjects.length > 0) {
                this.props.getDocumentsCrossProjects(startDateValue, endDateValue, selectedProjects, selectedType, dateFilter, null, additionalFilteringCriteria)
            } else {
                this.props.getDocuments(startDateValue, endDateValue, projectId || selectedProject.id, selectedType, dateFilter, null, additionalFilteringCriteria)
            }

            if (projectId || (selectedProject && selectedProject.id)) {
                const { transfers } = this.props
                if (!transfers.find(e => e.project === selectedProject.id)) {
                    this.props.getTransfers(projectId || selectedProject.id, -1)
                }
            }

            if (this.props.setFilters) {
                var periodText = 'import'
                if (dateFilter === 'issueDate') periodText = 'issue'
                if (dateFilter === 'dueDate') periodText = 'payment date'
                this.props.setFilters({
                    showAllUnpaid: showAllUnpaid,
                    showAllNotExported: showAllNotExported,
                    showAllNotSettled: showAllNotSettled,
                    selectedUser: selectedUser || {},
                    dateFilterText: periodText,
                    periodText: this.context.t('from {s} to {f}', { s: moment(startDateValue).format('YYYY-MM-DD'), f: moment(endDateValue).format('YYYY-MM-DD') }),
                    dateFilter: dateFilter,
                    startDate: startDateValue,
                    endDate: endDateValue
                })
            }

            if (selectedProjects && selectedProjects.length > 0) {
                const { userProjects, userCompanies, currentCompany } = this.props
                var newMainProject = userProjects.find(p => p.id === selectedProjects[0].id)
                if (newMainProject) {
                    this.props.switchDashboardViewModel(newMainProject.id)

                    if (currentCompany && currentCompany.id !== newMainProject.company_id) {
                        var companyIndex = arrayObjectIndexOf(userCompanies, newMainProject.company_id, 'company_id')
                        console.log(companyIndex)
                        if (companyIndex > -1) {
                            if (userCompanies[companyIndex].user_role === 'ADMIN') {
                                this.props.setCurrentCompany(userCompanies[companyIndex])
                            }
                        }
                    }
                }
            }
        }
    }

    toggleDataFilterList = (show) => {
        const { showAllUnpaid } = this.state
        const { searchForUnpaid } = this.props
        if (!searchForUnpaid || !showAllUnpaid)
            this.setState({
                showDateFilterList: show
            })
    }

    switchDateFilter = name => {
        this.setState({
            dateFilter: name,
            dateFilterText: name === 'issueDate' ? 'Issue date' : name === 'importDate' ? 'Import date' : 'Due date',
            showDateFilterList: false
        })
    }

    toggleDatePicker = (name, show) => {
        const { showAllUnpaid } = this.state
        const { searchForUnpaid } = this.props
        if (!searchForUnpaid || !showAllUnpaid) {
            if (name === 'start') {
                this.setState({
                    showStartDatePicker: show,
                })
            } else {
                this.setState({
                    showEndDatePicker: show,
                })
            }
        }
    }

    hideDatePicker = (name) => {
        if (name === 'start') {
            this.setState({
                showStartDatePicker: false,
            })
        } else if (name === 'end') {
            this.setState({
                showEndDatePicker: false,
            })
        }
    }

    clickOnDatePicker = (e, name) => {
        if (e.target.className.includes('day-cell')) {
            if (name === 'start') {
                this.setState({
                    showStartDatePicker: false
                })
            } else if (name === 'end') {
                this.setState({
                    showEndDatePicker: false
                })
            }

        }
    }

    selectStartDate = date => {
        this.setState({
            startDateValue: date,
            startDate: date.format('YYYY-MM-DD'),
            showStartDatePicker: false
        })
    }

    selectEndDate = date => {
        this.setState({
            endDateValue: date,
            endDate: date.format('YYYY-MM-DD'),
            showStartDatePicker: false
        })
    }

    unselectDate = () => { }

    toggleFilters = show => {
        this.setState({
            showFilters: show
        })
    }

    showAllUnpaidClick = () => {
        this.setState({
            showAllUnpaid: true,
            showUnpaidFromPeriod: false
        })
    }

    showUnpaidFromPeriodClick = () => {
        this.setState({
            showAllUnpaid: false,
            showUnpaidFromPeriod: true
        })
    }

    showAllNotExportedClick = () => {
        this.setState({
            showAllNotExported: true,
            showNotExportedFromPeriod: false
        })
    }

    showNotExportedFromPeriodClick = () => {
        this.setState({
            showAllNotExported: false,
            showNotExportedFromPeriod: true
        })
    }

    showAllNotSettledClick = () => {
        this.setState({
            showAllNotSettled: true,
            showNotSettledFromPeriod: false
        })
    }

    showNotSettledFromPeriodClick = () => {
        this.setState({
            showAllNotSettled: false,
            showNotSettledFromPeriod: true
        })
    }

    toggleUsersList = show => {
        this.setState({
            showUsersList: show
        })
    }

    selectEmployee = user => {
        if (user === 'all') {
            this.setState({
                showUsersList: false,
                userId: null,
                selectedUser: {}
            })
        } else {
            this.setState({
                showUsersList: false,
                userId: user.id,
                selectedUser: user
            })
        }
    }

    render() {
        const {
            showFilters,
            selectedProjects, selectedProject, showProjectsList,
            dateFilter, dateFilterText, showDateFilterList,
            startDate, startDateValue, showStartDatePicker,
            endDate, endDateValue, showEndDatePicker,

            showAllUnpaid, showUnpaidFromPeriod,
            showAllNotExported, showNotExportedFromPeriod,
            showAllNotSettled, showNotSettledFromPeriod, userId, selectedUser, showUsersList
        } = this.state
        const { selectedType, searchForUnpaid, searchForNotExported, searchForNotSettled, usersList, currentProject } = this.props
        return (
            <div className="reports-form" ref={this.containerRef}>
                <div className="filters-icon" onClick={() => this.toggleFilters(!showFilters)}></div>
                {
                    showFilters ? (
                        <div className="search-modal">
                            <form onSubmit={this.getDocs}>
                                <div className="form-group" id="formProjectContainer" ref={this.projectRef}>
                                    <div className="current-selected-project" onClick={e => this.toggleProjectsListInSingleView(!showProjectsList)}>
                                        <span className="label">{this.context.t("Binder")}</span>
                                        {
                                            selectedProjects && selectedProjects.length > 0 ? (
                                                <span className="current">{selectedProjects.map((p, i) => {
                                                    if (selectedProjects[i + 1]) return p.name + ', '
                                                    else return p.name
                                                })}</span>
                                            ) : (
                                                <span className="current">{selectedProject.name}</span>
                                            )
                                        }
                                        <span className={`expend-icon ${showProjectsList ? 'expended' : ''}`}></span>
                                    </div>
                                    {
                                        showProjectsList ? (
                                            <ProjectSwitcher
                                                closeProjectsList={this.setCurrentProject}
                                                showAll={true}
                                                selectedProject={selectedProject}
                                                selectedProjects={selectedProjects}
                                                multiSelect={true}
                                                returnSelectedProjects={this.setMultiSelectedProjects}
                                            />
                                        ) : (
                                            null
                                        )
                                    }
                                </div>

                                {
                                    searchForUnpaid ? (
                                        <div className="search-options">
                                            <div className={`option ${showAllUnpaid ? 'selected' : ''}`} onClick={() => this.showAllUnpaidClick()}>{this.context.t('show all unpaid docs')}</div>
                                            <div className={`option ${showUnpaidFromPeriod ? 'selected' : ''}`} onClick={() => this.showUnpaidFromPeriodClick()}>{this.context.t('show unpaid docs from selected period')}</div>
                                        </div>
                                    ) : null
                                }

                                {
                                    searchForNotExported ? (
                                        <div className="search-options">
                                            <div className={`option ${showAllNotExported ? 'selected' : ''}`} onClick={() => this.showAllNotExportedClick()}>{this.context.t('show all unexported documents')}</div>
                                            <div className={`option ${showNotExportedFromPeriod ? 'selected' : ''}`} onClick={() => this.showNotExportedFromPeriodClick()}>{this.context.t('show unexported docs from selected period')}</div>
                                        </div>
                                    ) : null
                                }

                                {
                                    searchForNotSettled ? (
                                        <>
                                            <div className="form-group" id="formProjectContainer" ref={this.usersListRef}>
                                                <div className="current-selected-project" onClick={e => this.toggleUsersList(!showUsersList)}>
                                                    <span className="label">{this.context.t("Employee")}</span>
                                                    <span className="current">{selectedUser ? this.context.t(selectedUser.name) : ''}</span>
                                                    <span className={`expend-icon ${showUsersList ? 'expended' : ''}`}></span>
                                                </div>
                                                {
                                                    showUsersList ? (
                                                        <div className="projects-list users">
                                                            <SimpleBar className="scrollbar-wrapper" style={{ maxHeight: 'calc(75vh - 70px)', width: '100%' }}>
                                                                <ul>
                                                                    {
                                                                        currentProject.acceptance_permissions !== 'USER1' ? (
                                                                            <li className={`${userId === null ? 'current-selected' : ''}`} onClick={() => this.selectEmployee('all')}>
                                                                                {this.context.t('All employees')}
                                                                            </li>
                                                                        ) : null
                                                                    }
                                                                    {
                                                                        usersList.map(user => {
                                                                            return (
                                                                                <li className={`${user.id === userId ? 'current-selected' : ''}`} onClick={() => this.selectEmployee(user)}>
                                                                                    {user.name}
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </SimpleBar>
                                                        </div>
                                                    ) : (
                                                        null
                                                    )
                                                }
                                            </div>
                                            <div className="search-options">
                                                <div className={`option ${showAllNotSettled ? 'selected' : ''}`} onClick={() => this.showAllNotSettledClick()}>{this.context.t('show all not settled expenses')}</div>
                                                <div className={`option ${showNotSettledFromPeriod ? 'selected' : ''}`} onClick={() => this.showNotSettledFromPeriodClick()}>{this.context.t('show not settled expenses from selected period')}</div>
                                            </div>
                                        </>
                                    ) : null
                                }


                                <div className={`form-group ${(searchForUnpaid && showAllUnpaid) || (searchForNotExported && showAllNotExported) || (searchForNotSettled && showAllNotSettled) ? 'not-active' : ''}`} id="formSearchTypeContainer" ref={this.dateFilterRef}>
                                    <div className="search-type" onClick={(e) => this.toggleDataFilterList(!showDateFilterList)}>
                                        <span className="label">{this.context.t("Search by")}</span>
                                        <span className="search-by">{selectedType === 'Income' && this.state.dateFilter === 'importDate' ? this.context.t("Sale date") : this.context.t(dateFilterText)}</span>
                                        <span className={`expend-icon ${showDateFilterList ? 'expended' : ''}`}></span>
                                    </div>
                                    {
                                        showDateFilterList ? (
                                            <ul>
                                                <li className={`${dateFilter === 'issueDate' ? 'current' : ''}`} onClick={e => { this.switchDateFilter('issueDate') }}>
                                                    {this.context.t("Issue date")}
                                                </li>
                                                <li className={`${dateFilter === 'importDate' ? 'current' : ''}`} onClick={e => { this.switchDateFilter('importDate') }}>
                                                    {selectedType === 'Income' ? this.context.t("Sale date") : this.context.t("Import date")}
                                                </li>
                                                {
                                                    searchForUnpaid ? (
                                                        <li onClick={e => { this.switchDateFilter('dueDate') }}>
                                                            {this.context.t("Due date")}
                                                            <span className="info" data-for="due-date-filter-info" data-tip="show"></span>
                                                        </li>
                                                    ) : null
                                                }
                                            </ul>
                                        ) : (
                                            null
                                        )
                                    }
                                </div>

                                <div className={`form-group ${(searchForUnpaid && showAllUnpaid) || (searchForNotExported && showAllNotExported) || (searchForNotSettled && showAllNotSettled) ? 'not-active' : ''}`} id="formStartDateContainer" ref={this.fromInputRef}>
                                    <input
                                        type="text"
                                        name="startDate"
                                        className={`${startDate ? 'has-content' : ''} `}
                                        id="startDate"
                                        value={startDate}
                                        onClick={e => this.toggleDatePicker('start', !showStartDatePicker)}
                                        autoComplete="off"
                                        placeholder={`e.g. ${moment().format('MMMM DD, YYYY')}`}
                                        readOnly={true}
                                    />
                                    <label htmlFor="startDate">{this.context.t('From')}</label>
                                    <span className="calendar-icon" onClick={e => this.toggleDatePicker('start', !showStartDatePicker)}></span>
                                    {
                                        showStartDatePicker ? (
                                            <div className={`date-picker ${showStartDatePicker ? 'show' : ''}`}>
                                                <Calendar
                                                    selectDay={this.selectStartDate}
                                                    unselectDay={this.unselectDate}
                                                    selectedDay={
                                                        {
                                                            value: startDateValue
                                                        }
                                                    }
                                                    projectId={'none'}
                                                    startDate={startDateValue || moment()}
                                                    arrowsOnLeft={false}
                                                    selectFirstDayOnMonthChange={true}
                                                />
                                            </div>
                                        ) : (
                                            null
                                        )
                                    }
                                </div>

                                <div className={`form-group ${(searchForUnpaid && showAllUnpaid) || (searchForNotExported && showAllNotExported) || (searchForNotSettled && showAllNotSettled) ? 'not-active' : ''}`} id="formEndDateContainer" ref={this.toInputRef}>
                                    <input
                                        type="text"
                                        name="endDate"
                                        className={`${endDate ? 'has-content' : ''} `}
                                        id="endDate"
                                        value={endDate}
                                        onClick={e => this.toggleDatePicker('end', !showEndDatePicker)}
                                        autoComplete="off"
                                        readOnly={true}
                                    />
                                    <label htmlFor="endDate">{this.context.t('To')}</label>
                                    <span className="calendar-icon" onClick={e => this.toggleDatePicker('end', !showEndDatePicker)}></span>

                                    {
                                        showEndDatePicker ? (
                                            <div className={`date-picker ${showEndDatePicker ? 'show' : ''}`}>
                                                <Calendar
                                                    selectDay={this.selectEndDate}
                                                    unselectDay={this.unselectDate}
                                                    selectedDay={
                                                        {
                                                            value: endDateValue
                                                        }
                                                    }
                                                    projectId={'none'}
                                                    startDate={endDateValue || moment()}
                                                    arrowsOnLeft={false}
                                                    selectFirstDayOnMonthChange={true}
                                                />
                                            </div>
                                        ) : (
                                            null
                                        )
                                    }
                                </div>

                                <input type="submit" className="button" value={this.context.t('Search Docs')} />
                            </form>
                        </div>
                    ) : null
                }
            </div>
        )
    }
}


DocsSearchModal.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
    currentProject: state.User.currentProject,
    userProjects: state.User.userProjects,
    userCompanies: state.Company.companies,
    currentCompany: state.Company.currentCompany,
    transfers: isIterable(state.Transfers.transfers) ? [...state.Transfers.transfers] : [],
})

const mapDispatchToProps = {
    switchDashboardViewModel: UserActions.switchDashboardViewModel,
    setCurrentProject: UserActions.setCurrentProject,
    getDocuments: ReportsActions.getDocuments,
    getDocumentsCrossProjects: ReportsActions.getDocumentsCrossProjects,
    setCurrentCompany: CompanyActions.setCurrentCompany,
    getTransfers: TransfersActions.getAllMyTransfers,

    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(DocsSearchModal)