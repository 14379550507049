import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import appleQR from '../../img/apple-qr.svg'
import appStoreButton from '../../img/download-from-app-store-PL.svg'
import googleQR from '../../img/google-qr.svg'
import playStoreButton from '../../img/download-from-google-play-store-PL.svg'

export class QrCodesForMobile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentDir: '',
            filterField: 'name',
            order: 'ASC',
        }

        this.popuoCardRef = React.createRef()
    }

    componentDidMount() {
        window.addEventListener("keydown", this.keyDownFunction, false)
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.keyDownFunction, false)
    }

    keyDownFunction = (e) => {
        if (e.keyCode === 27) {
            this.props.closePopup()
        }
    }

    handlepopupClick = e => {
        if (this.popuoCardRef && this.popuoCardRef.current) {
            if (!this.popuoCardRef.current.contains(e.target)) {
                this.props.closePopup()
            }
        }
    }

    render() {
        return (
            <div className="popup" id={`fileRowPopup`} onClick={e => this.handlepopupClick(e)}>
                <div className="popup-card mobile-qrs" id={`fileRowPopupCard`} ref={this.popuoCardRef}>
                    <div className="close-button" id={`fileRowPopupCloseButton`} onClick={() => this.props.closePopup()}></div>
                    <div className="body" id={`fileRowPopupBody`}>
                        <div className="logo" id={`fileRowPopupLogo`}></div>
                        <h4 id={`fileRowPopupHeader`}>{this.context.t("Download the mobile application to your phone and run your business easier!")}</h4>
                        <div className="mobile-qrs-container" id={`fileRowPopupQrsContainer`}>
                            <div className="qr-column" id={`fileRowPopupQrsColumnsApple`}>
                                <div className="store-button" id={`fileRowPopupQrsStoreButtonApple`}>
                                    <a className="app-store" href="https://apps.apple.com/us/app/easydocs/id1534836899" target="_blank">
                                        <img src={appStoreButton} id={`fileRowPopupQrsStoreButtonAppleLogo`} />
                                    </a>
                                </div>
                                <img className="qr-img" src={appleQR} id={`fileRowPopupQrApple`} />
                            </div>
                            <div className="qr-column" id={`fileRowPopupQrsColumnsGoogle`}>
                                <div className="store-button" id={`fileRowPopupQrsStoreButtonGoogle`}>
                                    <a className="google-play" href="https://play.google.com/store/apps/details?id=pl.proxypartners" target="_blank">
                                        <img src={playStoreButton} id={`fileRowPopupQrsStoreButtonGoogleLogo`} />
                                    </a>
                                </div>
                                <img className="qr-img" src={googleQR} id={`fileRowPopupQrGoogle`} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

QrCodesForMobile.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(QrCodesForMobile)