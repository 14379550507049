import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { CompanyActions } from "../../actions/CompanyActions";
import { KsefActions } from "../../actions/KsefActions";
import { AlertActions } from "../../actions/AlertActions";
import ReactTooltip from 'react-tooltip'

import "../../../src/css/ksef.css";

export class KsefIntegrationWindow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cursorWait: false,
            token: '',

            accessToInvoices: false,
            issuingInvoices: false,
            grantingPermissions: false,
            readingPermissions: false
        }
    }

    componentDidMount() {
    }

    handleClick = (event) => {
    }

    componentWillUnmount() {
    }

    onInputChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }

    saveKsefIntegration = () => {
        const { cursorWait, token, accessToInvoices, issuingInvoices, grantingPermissions, readingPermissions } = this.state
        const { companyId } = this.props

        if (!cursorWait) {
            if (token) {
                this.setState({
                    cursorWait: true
                })

                this.props.saveKsefIntegration(companyId, {
                    token, accessToInvoices, issuingInvoices, grantingPermissions, readingPermissions
                }, success => {
                    this.setState({
                        cursorWait: false
                    })
                    if (success) {
                        this.props.close()
                    }
                })
            } else {
                this.props.alertWarn("Token is required")
            }
        }
    }

    cancel = () => {
        const { cursorWait } = this.state
        if(!cursorWait) this.props.close()
    }

    render() {
        const { cursorWait, token, accessToInvoices, issuingInvoices, grantingPermissions, readingPermissions } = this.state
        return (
            <div className={`ksef-popup ${cursorWait ? 'cursor-wait' : ''}`}>
                <div className="ksef-window">
                    <h3>{this.context.t('KSeF integration')}</h3>
                    <h5>*{this.context.t('Currently, integration with KSeF is connected to the demo (pre-production) environment, so you can test without restrictions, as invoices entered into the demo environment are not binding in any way.')}</h5>

                    <div className="step-section">
                        <div className="step-number">1</div>
                        <p className="primary">
                            {this.context.t('Generate the authorization token from the KSeF application and then paste it into the designated field.')}
                        </p>
                        <p className="secondary">
                            {this.context.t(
                                "A token on the KSeF side can be generated by first logging in to the {link} and then going to the {bold1} tab. The list of generated tokens is visible in the {bold2} tab.",
                                {
                                    link: <a href="https://ksef-demo.mf.gov.pl/web/" target="_blank">{this.context.t("ministry's application")}</a>,
                                    bold1: <span className="bold">{this.context.t("TOKENS » GENERATE TOKEN")}</span>,
                                    bold2: <span className="bold">{this.context.t("TOKENS » GENERATE TOKEN")}</span>
                                }
                            )}
                        </p>

                        <div className="form-group">
                            <input type="text" className="token" id="token" autocomplete="off" name="token" value={token} onChange={this.onInputChange} />
                            <label for="token">{this.context.t("Token")}</label>
                        </div>
                    </div>

                    <div className="step-section">
                        <div className="step-number">2</div>
                        <p className="primary">
                            {this.context.t('Scope of authorization')}
                        </p>
                        <p className="secondary">
                            {this.context.t('The roles assigned in KSeF by an authorized person should be indicated')}
                        </p>

                        <div className="authorization">
                            <div className="checkbox-group">
                                <div className={`checkbox ${accessToInvoices ? 'checked' : ''}`} onClick={() => this.setState({ accessToInvoices: !accessToInvoices })}></div>
                                <div className="label" onClick={() => this.setState({ accessToInvoices: !accessToInvoices })}>{this.context.t("Access to invoices")}</div>
                            </div>
                            <div className="checkbox-group">
                                <div className={`checkbox ${issuingInvoices ? 'checked' : ''}`} onClick={() => this.setState({ issuingInvoices: !issuingInvoices })}></div>
                                <div className="label" onClick={() => this.setState({ issuingInvoices: !issuingInvoices })}>{this.context.t("Issuing invoices")}</div>
                            </div>
                            <div className="checkbox-group">
                                <div className={`checkbox ${grantingPermissions ? 'checked' : ''}`} onClick={() => this.setState({ grantingPermissions: !grantingPermissions })}></div>
                                <div className="label" onClick={() => this.setState({ grantingPermissions: !grantingPermissions })}>{this.context.t("Granting permissions")}</div>
                            </div>
                            <div className="checkbox-group">
                                <div className={`checkbox ${readingPermissions ? 'checked' : ''}`} onClick={() => this.setState({ readingPermissions: !readingPermissions })}></div>
                                <div className="label" onClick={() => this.setState({ readingPermissions: !readingPermissions })}>{this.context.t("Reading permissions")}</div>
                            </div>
                        </div>
                    </div>

                    <div className="action-buttons">
                        <div className="button cancel" onClick={() => this.cancel()}>{this.context.t('Cancel')}</div>
                        <div className="button save" onClick={() => this.saveKsefIntegration()}>{this.context.t('Save')}</div>
                    </div>
                </div>
            </div>
        )
    }
}

KsefIntegrationWindow.contextTypes = {
    t: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
    currentCompany: state.Company.currentCompany,
});

const mapDispatchToProps = {
    alertWarn: AlertActions.warning,
    saveKsefIntegration: KsefActions.saveKsefIntegration,
};

export default connect(mapStateToProps, mapDispatchToProps)(KsefIntegrationWindow);
