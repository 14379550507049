import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import copy from 'copy-to-clipboard';
import { List, AutoSizer, WindowScroller } from "react-virtualized";
import "react-virtualized/styles.css";

import dynamicComparer from '../../../helpers/dynamicComparer'

import { CompanyActions } from '../../../actions/CompanyActions'
import { AlertActions } from '../../../actions/AlertActions'

export class CompaniesList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            companiesList: [],
            companiesSortField: "created",
            companiesSortOrder: "desc",
        }
    }

    componentDidMount() {
        const { companies } = this.props
        this.setState({
            companiesList: companies.filter(c => this.checkIfCompanyMatchesFilters(c))
        }, () => {
            if (this.listRef) this.listRef.recomputeRowHeights()
        })
    }

    componentDidUpdate(prevProps) {
        ReactTooltip.rebuild()

        const { companies, searchField } = this.props
        if (companies !== prevProps.companies || (companies && prevProps.companies && companies.length !== prevProps.companies.length) || searchField !== prevProps.searchField) {
            this.setState({
                companiesList: companies.filter(c => this.checkIfCompanyMatchesFilters(c))
            }, () => {
                if (this.listRef) this.listRef.recomputeRowHeights()
            })
        }
    }

    checkIfCompanyMatchesFilters = c => {
        const { searchField } = this.props
        var matches = false
        var searchFieldCopy = searchField.toLowerCase()
        if (searchFieldCopy) {
            if (c.name.toLowerCase().includes(searchFieldCopy)) matches = true
            if (c.code.toLowerCase().includes(searchFieldCopy)) matches = true
            if (c.taxId.toLowerCase().includes(searchFieldCopy)) matches = true

            return matches
        } else {
            return true
        }
    }

    getSortClass = name => {
        const { companiesSortField, companiesSortOrder } = this.state
        if (companiesSortField === name) {
            if (companiesSortOrder === "asc")
                return "icon-sort up"
            return "icon-sort down"
        }
        return "icon-sort"
    }

    switchSortingField = (name) => {
        var { companiesSortField, companiesSortOrder, companiesList } = this.state
        var newSortOrder = 'asc'
        if (companiesSortField === name) {
            if (companiesSortOrder === "asc") {
                newSortOrder = 'desc'
            }
        }
        companiesList.sort(dynamicComparer(name, newSortOrder))

        this.setState({
            companiesSortField: name,
            companiesSortOrder: newSortOrder,
            companiesList: companiesList
        }, () => {
            if (this.listRef) this.listRef.recomputeRowHeights()
        })
    }

    campaignClick = company => {
        if (company.ref) {
            copy(company.ref)
            this.props.alertInfo('Copied to clipboard', 1000)
        }
    }

    setRef = windowScroller => {
        this._windowScroller = windowScroller;
    }

    setListRef = listRef => {
        this.listRef = listRef
    }


    getRowHeight = ({ index }) => {
        const { companiesList } = this.state

        var c = companiesList[index]
        if (c.adminEmails && c.adminEmails.length > 1) {
            return 44 + ((c.adminEmails.length - 1) * 16)
        } else {
            return 44
        }
    }

    rowRenderer = ({ style, key, index, parent, width }) => {
        const { companiesList } = this.state

        var company = companiesList[index]

        return (
            <div
                key={key}
                style={style}
                role="row"
                className="ReactVirtualized__List-row"
            >
                <div className="ReactVirtualized__List-cell name">
                    {company.name}
                </div>
                <div className="ReactVirtualized__List-cell code">
                    {company.code}
                </div>
                <div className="ReactVirtualized__List-cell vat-id">
                    {company.taxId}
                </div>
                <div className="ReactVirtualized__List-cell email">
                    {company.email}
                </div>
                <div className="ReactVirtualized__List-cell phone">
                    {company.phone}
                </div>
                <div className="ReactVirtualized__List-cell created">
                    {moment(company.created).format('YYYY-MM-DD HH:mm')}
                </div>
                <div className="ReactVirtualized__List-cell accounts">
                    {company.accounts}{company.serviceAccounts ? ` (+${company.serviceAccounts})` : ''}
                </div>
                <div className="ReactVirtualized__List-cell admin-emails">
                    {
                        company.adminEmails ? (
                            company.adminEmails.map(adminEmail => {
                                return (
                                    <p>{adminEmail}</p>
                                )
                            })
                        ) : null
                    }
                </div>
                <div className="ReactVirtualized__List-cell status">
                    {company.status}
                </div>
                <div className="ReactVirtualized__List-cell status">
                    {company.subscriptionExpireDate ? moment(company.subscriptionExpireDate).format('YYYY-MM-DD') : ''}
                </div>
                <div className={`ReactVirtualized__List-cell campaign ${company.ref ? 'has-ref' : ''}`} data-tip="show" data-for={`company-ref-${company.id}`} onClick={() => this.campaignClick(company)}>
                    {company.campaign}
                </div>
                {
                    company.ref ? (
                        <ReactTooltip id={`company-ref-${company.id}`} className="default-tooltip" place="left" effect="solid">
                            <p>{company.ref}</p>
                        </ReactTooltip>
                    ) : null
                }
            </div>
        )
    }

    render() {
        const { companiesList } = this.state
        const { wrapperRef, user } = this.props

        return (
            <div className="ReactVirtualized__List-wrapper">
                <div className="ReactVirtualized__List-row header">
                    <div className="ReactVirtualized__List-cell name" onClick={() => this.switchSortingField('name', 'COMPANIES')}>
                        {this.context.t('Company name')}
                        <span className={this.getSortClass('name', 'COMPANIES')} />
                    </div>

                    <div className="ReactVirtualized__List-cell code" onClick={() => this.switchSortingField('code', 'COMPANIES')}>
                        {this.context.t('Company code')}
                        <span className={this.getSortClass('code', 'COMPANIES')}></span>
                    </div>
                    <div className="ReactVirtualized__List-cell vat-id" onClick={() => this.switchSortingField('taxId', 'COMPANIES')}>
                        {this.context.t('Tax ID')}
                        <span className={this.getSortClass('taxId', 'COMPANIES')}></span>
                    </div>
                    <div className="ReactVirtualized__List-cell email" onClick={() => this.switchSortingField('email', 'COMPANIES')}>
                        {this.context.t('E-mail')}
                        <span className={this.getSortClass('email', 'COMPANIES')}></span>
                    </div>
                    <div className="ReactVirtualized__List-cell phone" onClick={() => this.switchSortingField('phone', 'COMPANIES')}>
                        {this.context.t('Phone')}
                        <span className={this.getSortClass('phone', 'COMPANIES')}></span>
                    </div>
                    <div className="ReactVirtualized__List-cell created" onClick={() => this.switchSortingField('created', 'COMPANIES')}>
                        {this.context.t('Created')}
                        <span className={this.getSortClass('created', 'COMPANIES')}></span>
                    </div>
                    <div className="ReactVirtualized__List-cell accounts" onClick={() => this.switchSortingField('accounts', 'COMPANIES')}>
                        {this.context.t('Users (+srv)')}
                        <span className={this.getSortClass('accounts', 'COMPANIES')}></span>
                    </div>
                    <div className="ReactVirtualized__List-cell admin-emails">
                        {this.context.t('Admin e-mails')}
                    </div>
                    <div className="ReactVirtualized__List-cell status">
                        {this.context.t('Client type')}
                    </div>
                    <div className="ReactVirtualized__List-cell status">
                        {this.context.t('trial_expire_date')}
                    </div>
                    <div className="ReactVirtualized__List-cell campaign" onClick={() => this.switchSortingField('campaign', 'COMPANIES')}>
                        {this.context.t('Campaign')}
                        <span className={this.getSortClass('campaign', 'COMPANIES')}></span>
                    </div>
                </div>
                <WindowScroller
                    ref={this.setRef}
                    scrollElement={user.new_easydocs_version && wrapperRef && wrapperRef.current ? wrapperRef.current : window}
                >
                    {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
                        <AutoSizer disableHeight>
                            {({ width }) => (
                                <div ref={registerChild}>
                                    <List
                                        ref={this.setListRef}
                                        autoHeight
                                        width={width}
                                        height={height}
                                        rowHeight={this.getRowHeight}

                                        rowCount={companiesList.length}
                                        rowGetter={({ index }) => companiesList[index]}
                                        rowRenderer={({ style, key, index, parent }) => this.rowRenderer({ style, key, index, parent, width })}
                                        overscanRowCount={2}

                                        isScrolling={isScrolling}
                                        onScroll={(e) => { ReactTooltip.rebuild() }}
                                        scrollTop={scrollTop}
                                    />
                                </div>
                            )}
                        </AutoSizer>
                    )}
                </WindowScroller>
            </div>
        )
    }
}

CompaniesList.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    companies: state.Company.allCompanies || [],
    companiesSortField: state.Company.companiesSortField,
    companiesSortOrder: state.Company.companiesSortOrder,
})

const mapDispatchToProps = {
    sortAllCompaniesForSuperadmin: CompanyActions.sortAllCompaniesForSuperadmin,
    alertWarn: AlertActions.warning,
    alertInfo: AlertActions.info
}

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesList)