import arrayObjectIndexOf from "../helpers/indexOfObject"

export const TabsControllerConsts = {
    SET_CURRENT_TAB: 'SET_CURRENT_TAB',
    OPEN_NEW_TAB: 'OPEN_NEW_TAB',
    CHANGE_OCR_TAB_DOC: 'CHANGE_OCR_TAB_DOC',
    CLOSE_TAB: 'CLOSE_TAB',
    CLOSE_TAB_WITH_SALE_INVOICE_PREVIEW: 'CLOSE_TAB_WITH_SALE_INVOICE_PREVIEW',
    CLOSE_TABS_AFTER_PROJECT_CHANGE: 'CLOSE_TABS_AFTER_PROJECT_CHANGE'
}

export const AvailableTabs = {
    DASHBOARD: 'DASHBOARD',
    OCR: 'OCR',
    GENERATE_PAYMENT_ORDER: 'GENERATE_PAYMENT_ORDER',
    GENERATE_SALE_INVOICE: 'GENERATE_SALE_INVOICE',
    FILE_PREVIEW: 'FILE_PREVIEW',
    ACTIONS_TABLE: 'ACTIONS_TABLE',
    ACTIONS_HISTORY: 'ACTIONS_HISTORY',
    ALL_DOCS: 'ALL_DOCS',
    ALL_SALE_INVOICES: 'ALL_SALE_INVOICES',
    SETTINGS: 'SETTINGS',
    HELPDESK: 'HELPDESK',
    SUPERADMIN: 'SUPERADMIN'
}

export const GenerateInvoiceComponents = {
    INVOICE: 'INVOICE',
    BUYER_CORRECTION: 'BUYER_CORRECTION',
    ITEMS_CORRECTION: 'ITEMS_CORRECTION'
}

export const ActionsTabsComponents = {
    ACTIONS: 'ACTIONS',
    PAYMRNTS_ASSISTANT: 'PAYMRNTS_ASSISTANT',
    ZIP_ASSISTANT: 'ZIP_ASSISTANT',
    EXPENSES_ASSISTANT: 'EXPENSES_ASSISTANT'
}

export const ActionsHistoryTabsComponents = {
    GENERATED_EXCELS: 'GENERATED_EXCELS',
    GENERATED_TRANSFERS: 'GENERATED_TRANSFERS',
    GENERATED_ZIPS: 'GENERATED_ZIPS',
    GENERATED_ACCOUNTING_FILES: 'GENERATED_ACCOUNTING_FILES'
}

export const SettingsTabsComponents = {
    PROFILE: 'PROFILE',
    COMPANY: 'COMPANY',
    BINDERS: 'BINDERS',
    COUNTERPARTIES: 'COUNTERPARTIES',
    SUBSCRIPTION: 'SUBSCRIPTION'
}

export const SuperadminTabsComponents = {
    COMPANIES: 'COMPANIES',
    BINDERS: 'BINDERS',
    USERS: 'USERS',
    WHATS_NEW: 'WHATS_NEW',
    EMAILS: 'EMAILS',
    PAYMENTS: 'PAYMENTS',
    CONVERSIONS: 'CONVERSIONS'
}

const getTabTitle = (tabType, tabComponent) => {
    switch (tabType) {
        case AvailableTabs.DASHBOARD:
            return 'Dashboard'
        case AvailableTabs.OCR:
            return 'Document verification'
        case AvailableTabs.GENERATE_PAYMENT_ORDER:
            return 'Payment order generator'
        case AvailableTabs.GENERATE_SALE_INVOICE:
            if (tabComponent === GenerateInvoiceComponents.INVOICE) return 'Sale invoice generator'
            if (tabComponent === GenerateInvoiceComponents.BUYER_CORRECTION) return 'Buyer correction'
            if (tabComponent === GenerateInvoiceComponents.ITEMS_CORRECTION) return 'Items correction'
        case AvailableTabs.FILE_PREVIEW:
            return 'Document preview'
        case AvailableTabs.ACTIONS_TABLE:
            if (tabComponent === ActionsTabsComponents.ACTIONS) return 'Actions'
            if (tabComponent === ActionsTabsComponents.PAYMRNTS_ASSISTANT) return 'Payment assistant'
            if (tabComponent === ActionsTabsComponents.ZIP_ASSISTANT) return 'Packages for accounting'
            if (tabComponent === ActionsTabsComponents.EXPENSES_ASSISTANT) return 'Expenses assistant'
        case AvailableTabs.ACTIONS_HISTORY:
            if (tabComponent === ActionsHistoryTabsComponents.GENERATED_EXCELS) return 'Generated Excel statements'
            if (tabComponent === ActionsHistoryTabsComponents.GENERATED_TRANSFERS) return 'Generated transfer files'
            if (tabComponent === ActionsHistoryTabsComponents.GENERATED_ZIPS) return 'Generated ZIP packages'
            if (tabComponent === ActionsHistoryTabsComponents.GENERATED_ACCOUNTING_FILES) return 'Generated accounting files'
        case AvailableTabs.ALL_DOCS:
            return 'Your documents'
        case AvailableTabs.ALL_SALE_INVOICES:
            return 'Sales'
        case AvailableTabs.SETTINGS:
            if (tabComponent === SettingsTabsComponents.PROFILE) return 'Settings: Profile'
            if (tabComponent === SettingsTabsComponents.COMPANY) return 'Settings: Company'
            if (tabComponent === SettingsTabsComponents.BINDERS) return 'Settings: Binders'
            if (tabComponent === SettingsTabsComponents.COUNTERPARTIES) return 'Settings: Counterparties'
            if (tabComponent === SettingsTabsComponents.SUBSCRIPTION) return 'Settings: Subscription'
        case AvailableTabs.HELPDESK:
            return 'Helpdesk'
        case AvailableTabs.SUPERADMIN:
            return 'Superadmin'
            if (tabComponent === SuperadminTabsComponents.COMPANIES) return 'Superadmin: Companies'
            if (tabComponent === SuperadminTabsComponents.BINDERS) return 'Superadmin: Binders'
            if (tabComponent === SuperadminTabsComponents.USERS) return 'Superadmin: Users'
            if (tabComponent === SuperadminTabsComponents.WHATS_NEW) return 'Superadmin: Whats new?'
            if (tabComponent === SuperadminTabsComponents.EMAILS) return 'Superadmin: Info emails'
            if (tabComponent === SuperadminTabsComponents.PAYMENTS) return 'Superadmin: Payments'
            if (tabComponent === SuperadminTabsComponents.CONVERSIONS) return 'Superadmin: Conversions'
        default:
            return 'New tab'
    }
}

const getTabUrl = (tabType, tabComponent, additionalOptions) => {
    switch (tabType) {
        case AvailableTabs.DASHBOARD:
            return '/dashboard'
        case AvailableTabs.OCR:
            if (additionalOptions) {
                return `/ocr/${additionalOptions.projectId}/${encodeURIComponent(additionalOptions.fileId)}`
            } else {
                return '/ocr'
            }
        case AvailableTabs.GENERATE_PAYMENT_ORDER:
            return '/generate-payment-order'
        case AvailableTabs.GENERATE_SALE_INVOICE:
            return '/generate-sale-invoice'
        case AvailableTabs.FILE_PREVIEW:
            return '/file-preview'
        case AvailableTabs.ACTIONS_TABLE:
            if (tabComponent === ActionsTabsComponents.ACTIONS) return '/actions'
            if (tabComponent === ActionsTabsComponents.PAYMRNTS_ASSISTANT) return '/actions/payments-assistant'
            if (tabComponent === ActionsTabsComponents.ZIP_ASSISTANT) return '/actions/zip-assistant'
            if (tabComponent === ActionsTabsComponents.EXPENSES_ASSISTANT) return '/actions/expenses-assistant'
        case AvailableTabs.ACTIONS_HISTORY:
            if (tabComponent === ActionsHistoryTabsComponents.GENERATED_EXCELS) return '/actions/actions-history/generated-excels'
            if (tabComponent === ActionsHistoryTabsComponents.GENERATED_TRANSFERS) return '/actions/actions-history/generated-transfer-files'
            if (tabComponent === ActionsHistoryTabsComponents.GENERATED_ZIPS) return '/actions/actions-history/generated-zip-packages'
            if (tabComponent === ActionsHistoryTabsComponents.GENERATED_ACCOUNTING_FILES) return '/actions/actions-history/generated-accounting-files'
        case AvailableTabs.ALL_DOCS:
            return '/docs'
        case AvailableTabs.ALL_SALE_INVOICES:
            return '/sales'
        case AvailableTabs.SETTINGS:
            if (tabComponent === SettingsTabsComponents.PROFILE) return '/settings/profile'
            if (tabComponent === SettingsTabsComponents.COMPANY) return '/settings/company'
            if (tabComponent === SettingsTabsComponents.BINDERS) return '/settings/projects'
            if (tabComponent === SettingsTabsComponents.COUNTERPARTIES) return '/settings/counterparties'
            if (tabComponent === SettingsTabsComponents.SUBSCRIPTION) return '/settings/subscription'
        case AvailableTabs.HELPDESK:
            return '/helpdesk'
        case AvailableTabs.SUPERADMIN:
            if (tabComponent === SuperadminTabsComponents.COMPANIES) return '/superadmin/companies'
            if (tabComponent === SuperadminTabsComponents.BINDERS) return '/superadmin/binders'
            if (tabComponent === SuperadminTabsComponents.USERS) return '/superadmin/users'
            if (tabComponent === SuperadminTabsComponents.WHATS_NEW) return '/superadmin/whats-new'
            if (tabComponent === SuperadminTabsComponents.EMAILS) return '/superadmin/emails'
            if (tabComponent === SuperadminTabsComponents.PAYMENTS) return '/superadmin/payments'
            if (tabComponent === SuperadminTabsComponents.CONVERSIONS) return '/superadmin/conversions'
        default:
            return 'New tab'
    }
}

const initialState = {
    currentTabIndex: 0,
    allOpenedTabs: [
        {
            type: AvailableTabs.DASHBOARD,
            title: 'Dashboard',
            component: AvailableTabs.DASHBOARD
        }
    ]
}

export default (state = initialState, action) => {
    switch (action.type) {
        case TabsControllerConsts.SET_CURRENT_TAB:
            var allOpenedTabsCopy = [...state.allOpenedTabs]
            var newCurrentTab = allOpenedTabsCopy[action.tabIndex]
            if (newCurrentTab) {
                window.history.replaceState(null, null, getTabUrl(newCurrentTab.type, newCurrentTab.component))
            }
            return { ...state, ...{ currentTabIndex: action.tabIndex } }
        case TabsControllerConsts.OPEN_NEW_TAB:
            var allOpenedTabsCopy = [...state.allOpenedTabs]
            var tabTypeIndex = arrayObjectIndexOf(allOpenedTabsCopy, action.tabType, 'type')
            var newTabIndex = tabTypeIndex
            if (tabTypeIndex > -1) {
                allOpenedTabsCopy[tabTypeIndex] = {
                    type: action.tabType,
                    title: getTabTitle(action.tabType, action.tabComponent),
                    component: action.tabComponent,
                    additionalOptions: action.additionalOptions || {}
                }
            } else {
                var newTabObject = {
                    type: action.tabType,
                    title: getTabTitle(action.tabType, action.tabComponent),
                    component: action.tabComponent,
                    additionalOptions: action.additionalOptions || {}
                }
                allOpenedTabsCopy.splice(state.currentTabIndex + 1, 0, newTabObject)
                newTabIndex = state.currentTabIndex + 1
            }
            window.history.replaceState(null, null, getTabUrl(action.tabType, action.tabComponent, action.additionalOptions))
            return { ...state, ...{ allOpenedTabs: allOpenedTabsCopy, currentTabIndex: newTabIndex } }

        case TabsControllerConsts.CLOSE_TAB:
            if (action.tabIndex > -1) {
                var allOpenedTabsCopy = [...state.allOpenedTabs]

                if (allOpenedTabsCopy[action.tabIndex]) {
                    allOpenedTabsCopy.splice(action.tabIndex, 1)
                }
                var newCurrentTab = null, newCurrentTabIndex = state.currentTabIndex
                if (state.currentTabIndex === action.tabIndex) {
                    if (allOpenedTabsCopy[action.tabIndex]) {
                        newCurrentTabIndex = action.tabIndex
                    } else {
                        newCurrentTabIndex = allOpenedTabsCopy.length - 1
                    }
                    newCurrentTab = allOpenedTabsCopy[newCurrentTabIndex]
                } else if (state.currentTabIndex > action.tabIndex) {
                    newCurrentTabIndex -= 1
                }
                if (newCurrentTab) {
                    window.history.replaceState(null, null, getTabUrl(newCurrentTab.type, newCurrentTab.component))
                }
                return { ...state, ...{ allOpenedTabs: allOpenedTabsCopy, currentTabIndex: newCurrentTabIndex } }
            } else {
                return state
            }

        case TabsControllerConsts.CLOSE_TAB_WITH_SALE_INVOICE_PREVIEW:
            var allOpenedTabsCopy = [...state.allOpenedTabs]
            allOpenedTabsCopy = allOpenedTabsCopy.filter(t => t.type !== AvailableTabs.GENERATE_SALE_INVOICE)
            var newCurrentTabIndex = 0
            for (const [index, tab] of allOpenedTabsCopy.entries()) {
                console.log(tab)
                if (tab.type === AvailableTabs.ALL_SALE_INVOICES) {
                    newCurrentTabIndex = index
                    break
                }
            }
            return { ...state, ...{ allOpenedTabs: allOpenedTabsCopy, currentTabIndex: newCurrentTabIndex } }

        case TabsControllerConsts.CLOSE_TABS_AFTER_PROJECT_CHANGE:
            var allOpenedTabsCopy = [...state.allOpenedTabs]
            var currentTabType = allOpenedTabsCopy[state.currentTabIndex].type
            allOpenedTabsCopy = allOpenedTabsCopy.filter(t => ![AvailableTabs.OCR, AvailableTabs.GENERATE_PAYMENT_ORDER, AvailableTabs.GENERATE_SALE_INVOICE].includes(t.type))
            var newCurrentTabIndex = 0
            for (const [index, tab] of allOpenedTabsCopy.entries()) {
                console.log(tab)
                if (tab.type === currentTabType) {
                    newCurrentTabIndex = index
                    break
                }
            }
            return { ...state, ...{ allOpenedTabs: allOpenedTabsCopy, currentTabIndex: newCurrentTabIndex } }

        case TabsControllerConsts.CHANGE_OCR_TAB_DOC:
            var allOpenedTabsCopy = [...state.allOpenedTabs]
            var tabTypeIndex = arrayObjectIndexOf(allOpenedTabsCopy, AvailableTabs.OCR, 'type')
            var newTabIndex = tabTypeIndex
            if (tabTypeIndex > -1) {
                allOpenedTabsCopy[tabTypeIndex].additionalOptions = action.additionalOptions || {}
            }
            window.history.replaceState(null, null, getTabUrl(AvailableTabs.OCR, AvailableTabs.OCR, action.additionalOptions))
            return { ...state, ...{ allOpenedTabs: allOpenedTabsCopy, currentTabIndex: newTabIndex } }

        default:
            return state
    }
}