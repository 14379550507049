import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { AlertActions } from '../../actions/AlertActions'
import { CompanyActions } from '../../actions/CompanyActions'

export class RevokeConnectToPsd2AisPopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        const { banksToDisconnectFromPsd2 } = this.props
        return (
            <div className="psd2-ais popup">
                <div className="popup-card">
                    {
                        banksToDisconnectFromPsd2?.length > 1 ? (
                            <>
                                <h3>{this.context.t('Do you confirm withdrawal of consent PSD2 AIS?')}</h3>
                                <h4>{this.context.t('Consent was granted for several accounts at once, therefore its withdrawal will result in disconnection of PSD2 AIS for the following accounts:')}</h4>
                                <ul>
                                    {
                                        banksToDisconnectFromPsd2.map(account => {
                                            if (account.psd2_is_connected_to_ais) {
                                                return (
                                                    <li
                                                        className={`disconnect`}
                                                    >
                                                        <span className="name">{`${account.name} (${account.currency})`}</span>
                                                        <span className="bank">{`${account.bank_name}: ${account.account_number}`}</span>
                                                    </li>
                                                )
                                            } else {
                                                return null
                                            }
                                        })
                                    }
                                </ul>
                            </>
                        ) : (
                            <h3>{this.context.t('Do you confirm withdrawal of consent PSD2 AIS?')}</h3>
                        )
                    }

                    <div className="action-buttons">
                        <div className="btn cancel" onClick={() => this.props.cancel()}>{this.context.t('Cancel')}</div>
                        <div className="btn accept" onClick={() => this.props.revokeConsent()}>{this.context.t('Disconnect')}</div>
                    </div>
                </div>
            </div>
        )
    }
}

RevokeConnectToPsd2AisPopup.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
    connectToPsd2Ais: CompanyActions.connectToPsd2Ais,
    alertWarn: AlertActions.warning
}

export default connect(mapStateToProps, mapDispatchToProps)(RevokeConnectToPsd2AisPopup)