import moment from "moment"
import { func } from "prop-types"
import { reverse } from 'named-urls'

import { history } from "../helpers/history"
import routes from "../helpers/routes"
import myFetch from "../helpers/fetch"
import myDownload from "../helpers/download"

import { FileConst } from "../reducers/FileReducer"
import { ButtonsConsts } from "../reducers/ButtonsReducer"
import { AlertActions } from './AlertActions'
import { ReportsConst } from "../reducers/ReportsReducer"
import { ProjectCostsConsts } from '../reducers/ProjectCostsReducer'
import { EventConst } from "../reducers/EventReducer"
import { TransferConst } from "../reducers/TransfersReducer"
import { CompanyConst } from "../reducers/CompanyReducer"

import { socket } from "../App"
import { UserConst } from "../reducers/UserReducer"
import { ChatConst } from "../reducers/ChatReducer"

export const FileActions = {
    setDocType,
    changeDocType,
    autoSaveOcr,
    readWithDifferentOcr,
    getUserThatLockedFileTofDevEx,
    loadNewFileToVerifyData,
    getProjectMembersForExpense,
    setExpenseOwner,
    changeExpenseOwner,
    getFiles,
    downloadMultipleFiles,
    downloadOriginalFile,
    downloadFile,
    switchDir,
    markProjectsToLoad,
    getFilesToVerifi,
    getFilesToVerifyRefetch,
    getProjectResourcesForDashboard,
    parsePDF,
    dropFileFromOtherProject,
    moveToOtherProject,
    moveAcceptedFileToOtherProject,
    cacheFile,
    saveOCRModel,
    updateOCRModel,
    tooglePreviewForUnpaidDocs,
    togglePreviewForFullTextSearch,
    toogleFilePreviewInActions,
    getFileData,
    getCounterpartyDocs,
    toggleLinkedDocs,
    downloadFileForSideBySidePreview,
    closeSideBySidePreview,
    addFileAttachment,
    downloadAttachemntForSideBySidePreview,
    downloadAttachemnt,
    deleteAttachment,
    togglePdfPreview,
    checkAccessToFileFromUrl,
    checkInvoiceNumber,
    updatePartialOCRData,
    updateOCRData,
    saveOCRData,
    updatePartialByHandOcr,
    updateByHandOcr,
    saveByHandOCR,
    updatePartialExpenses,
    updateExpenses,
    saveExpenses,
    savePartialAgreement,
    saveAgreement,
    savePartialIncome,
    saveIncome,
    savePartialCorrespondence,
    saveCorrespondence,
    savePartialCorporate,
    saveCorporate,
    savePartialOther,
    saveOther,
    savePartialProtocol,
    saveProtocol,
    changeFileStatusToNotAccepted,
    changeFileStatusToAccepted,
    deleteInvoice,
    deleteInvoiceFromRedux,
    moveDocumentToTrash,
    restoreDocumentFromTrash,
    permanentDeleteInvoice,
    confirmDocTypeChange,
    restoreDocument,
    markFileAsPaid,
    markAdditionalAccepted,
    updateKsefStatusFromCron,
    switchSortingFilterVerifiFiles,
    setPendingFilesSearchField,
    setPendingFilesFilter,
    switchSortingFilterUploadedFiles,
    generateOcr,
    generateOcrForExpenses,
    changeOCRData,
    clearDataReadWithOcrFrame,
    setNewCounterparty,
    closeExistingFileModal,
    loadGeneratedOCR,
    hideRefreshOcrPopup,
    hideAcceptedOcrPopup,
    showOcrScreenNotificationAboutDeletedFile,
    hideOcrScreenNotificationAboutDeletedFile,
    showOcrScreenNotificationAboutMovedFile,
    hideOcrScreenNotificationAboutMovedFile,
    downloadReportFiles,

    toggleProjectFiles,

    makeEditedDocBackup,
    cancelDocEdit,
    saveDocChanges,
    editDocInput,
    changePaymentMethodInPdfBackup,
    changeAccountmentStatusInPdfBackup,
    setFileToVerifiHighPriority,
    downloadFilesAssignedToAgreement,
    switchInvoiceSortingFilter,

    addCostSplitForFile,
    deleteCostSplitForFile,
    addCostSplitInstance,
    deleteCostSplitInstance,
    editCostSplitInstance,

    addFirstMpkSplitInstance,
    addMpkSplitInstance,
    deleteMpkSplitInstance,
    editMpkSplitInstance,

}

function setDocType(ftvId, dbId, type, projectId, fileName) {
    console.log(dbId)
    return dispatch => {
        myFetch(`/files-to-verifi/set-doc-type`, {
            method: 'put', body: {
                ftvId: ftvId,
                type: type
            }
        }).then(res => {
            if (!res.success) {
                dispatch(AlertActions.warning(res.msg))
            } else {
                socket.emit('setDocType', dbId, projectId, type, fileName, (err) => {
                    if (err) {
                        console.log(err)
                        console.log('socket event setDocType wasn\'t broadcasted' + moment().format('HH:mm.ss.SSS'))
                    } else {
                        console.log('socket event setDocType was broadcasted' + moment().format('HH:mm.ss.SSS'))
                    }
                })
            }
        }).catch(err => {
            handleError(err, dispatch, `/files-to-verifi/set-doc-type`)
        })
    }
}

function changeDocType(fileId, projectId, type, user) {
    return dispatch => {
        dispatch({ type: FileConst.SET_DOC_TYPE, projectId: projectId, fileId: fileId, docType: type, user: user })
    }
}

function autoSaveOcr(id, propertyName, propertyValue, documentType) {
    return dispatch => {

        myFetch(`/files-to-verifi/auto-save`, {
            method: 'put', body: {
                id: id,
                propertyName: propertyName,
                propertyValue: typeof propertyValue === 'string' ? propertyValue : '',
                documentType: documentType
            }
        }).then(res => {
        }).catch(err => {
            console.log(err)
        })
    }
}

function readWithDifferentOcr(fileId, modelName, cb) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/files-to-verifi/read-again-with-other-model`, {
            method: 'put', body: {
                fileId: fileId,
                modelName: modelName
            }
        }).then(res => {
            cb(res.success)
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
            if (!res.success) {
                dispatch(AlertActions.warning(res.msg))
            } else if (res.readData) {
                dispatch({ type: FileConst.LOAD_NEW_OCR, file: res.ftvId, ocr: res.readData })
            }
        }).catch(err => {
            cb(false)
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
            console.log(err)
        })
    }
}

function getUserThatLockedFileTofDevEx(fileId, cb) {
    return dispatch => {
        myFetch(`/files-to-verifi/get-user-that-locked-for-devex/${fileId}`, {
            method: 'get'
        }).then(res => {
            if (res.success) {
                cb(res.userName)
            }
        }).catch(err => {
            console.log(err)
        })
    }
}

function loadNewFileToVerifyData(data) {
    return dispatch => {
        dispatch({ type: FileConst.LOAD_NEW_FILE_TO_VERIFY_DATA, id: data.id, projectId: data.projectId, newData: data.newData })
    }
}


function getProjectMembersForExpense(projectId, cb) {
    return dispatch => {
        myFetch(`/user-projects/get-users-names-list/${projectId}`, { method: 'get' })
            .then(res => {
                if (!res.success) {
                    dispatch(AlertActions.warning(res.msg))
                    cb(null)
                } else {
                    cb(res.users)
                }
            }).catch(err => {
                cb(null)
                handleError(err, dispatch, `/files-to-verifi/set-doc-type`)
            })
    }
}

function setExpenseOwner(ftvId, dbId, userId, userName, projectId) {
    return dispatch => {
        myFetch(`/files-to-verifi/set-new-expense-ownere`, {
            method: 'put', body: {
                ftvId: ftvId,
                newOwnerId: userId,
                newOwnerName: userName,
                projectId: projectId
            }
        })
            .then(res => {
                if (!res.success) {
                    dispatch(AlertActions.warning(res.msg))
                } else {
                    dispatch({ type: FileConst.SET_EXPENSE_OWNER, ownerId: userId, ownerName: userName, projectId: projectId, fileId: dbId })
                }
            }).catch(err => {
                handleError(err, dispatch, `/files-to-verifi/set-new-expense-ownere`)
            })
    }
}

function changeExpenseOwner(fileId, projectId, userId, userName) {
    return dispatch => {
        dispatch({ type: FileConst.SET_EXPENSE_OWNER, projectId: projectId, fileId: fileId, ownerId: userId, ownerName: userName })
    }
}

function getFiles(dir, projectId, fileId, cb) {
    var data = {
        projectId: projectId,
        fileId: fileId,
        language: localStorage.getItem('language')
    }
    return dispatch => {
        dispatch({ type: FileConst.GET_FILES_REQUEST, projectId: projectId })
        myFetch(`/files/my-files/?data=${JSON.stringify(data)}`, { method: 'get', responseType: 'blob' }).then(result => {
            if (result.success) {
                dispatch({ type: FileConst.GET_FILES, files: result.files, projectId: projectId, currentDir: dir })
                if (cb) {
                    cb()
                }
            } else {
                dispatch(AlertActions.warning(result.msg))
            }
        }).catch(err => {
            handleError(err, dispatch, `/files/my-files/?data=${JSON.stringify(data)}`)
        })
    }
}

function downloadMultipleFiles(project, projectCode, files, paymentOrders, markInReportsTab, cb = () => { }) {
    return async dispatch => {
        dispatch({ type: ReportsConst.MARK_DOCS_IN_ZIP, files: files.ids || [], paymentOrders: paymentOrders.ids || [], mark: true, zipId: 'PENDING' })
        await myDownload(
            `/files/multi-download`,
            {
                method: 'post',
                body: {
                    projectId: project,
                    files: files.ids || [],
                    paymentOrders: paymentOrders.ids || []
                }
            },
            `${moment().format('YYYY-MM-DD')}_${projectCode}_files.zip`
        )
        cb()
        if (markInReportsTab) {
            dispatch({ type: ReportsConst.MARK_DOCS_IN_ZIP, files: files.ids || [], paymentOrders: paymentOrders.ids || [], mark: true, zipId: 0 })
        }
    }
}

function downloadOriginalFile(fileId, projectId, fileName) {
    return dispatch => {
        dispatch({ type: ButtonsConsts.OCR_DOWNLOAD_BUTTON, loading: true })
        myFetch(`/files/download-file/?projectId=${projectId}&fileId=${fileId}`, { method: 'get' }).then(res => {
            if (res.success) {
                var a = document.createElement('a');
                a.href = res.data;
                a.download = fileName;
                a.click();
            }
            dispatch({ type: ButtonsConsts.OCR_DOWNLOAD_BUTTON, loading: false })
        }).catch(err => {
            handleError(err, dispatch, `/files/download-file/?projectId=${projectId}&fileId=${fileId}`)
            dispatch({ type: ButtonsConsts.OCR_DOWNLOAD_BUTTON, loading: false })
        })
    }
}

function downloadFile(fileId, projectId, fileName, cb) {
    return dispatch => {
        myFetch(`/files/download-file/?projectId=${projectId}&fileId=${fileId}`, { method: 'get' }).then(res => {
            if (res.success) {
                var a = document.createElement('a');
                a.href = res.data;
                a.download = fileName;
                a.click();
            }
            cb()
        }).catch(err => {
            handleError(err, dispatch, `/files/download-file/?projectId=${projectId}&fileId=${fileId}`)
            cb()
        })
    }
}

function switchDir(dir, projectId) {
    return dispatch => {
        dispatch({ type: FileConst.SWITCH_DIR, projectId: projectId, currentDir: dir })
    }
}

function tooglePreviewForUnpaidDocs(show) {
    return dispatch => {
        dispatch({ type: FileConst.TOGGLE_PREVIEW_FOR_UNPAID_DOCS, show: show })
    }
}

function togglePreviewForFullTextSearch(show) {
    return dispatch => {
        dispatch({ type: FileConst.TOGGLE_PREVIEW_FOR_FULL_TEXT_SEARCH, show: show })
    }
}

function toogleFilePreviewInActions(show) {
    return dispatch => {
        dispatch({ type: FileConst.TOGGLE_PREVIEW_FOR_ACTIONS, show: show })
    }
}

function getFileData(projectId, filePath, id, status, fileStep, previewClickFromChatNotification = false) {
    console.log(projectId, filePath, id, status, fileStep, previewClickFromChatNotification)
    return dispatch => {
        console.log(status)
        var fileBaseName = filePath.split('/')
        fileBaseName = fileBaseName[fileBaseName.length - 1]
        dispatch({ type: FileConst.GET_PREVIEV_DATA, fileBody: null, fileId: id, fileName: fileBaseName, docType: 'LOADING', previewClickFromChatNotification: previewClickFromChatNotification })
        dispatch({ type: FileConst.TOGGLE_PREVIEW, status: true })
        myFetch(`/files/download?projectId=${projectId}&fileId=${id}`, { method: 'get' }).then(result => {
            if (result.success) {
                var filter = {
                    where: {},
                    include: []
                }
                if (status === 'DONE' || (status === 'IN_PROGRESS' && fileStep > 2)) {
                    filter = `filter=${JSON.stringify(filter)}`
                    if ((filePath && filePath.includes('/Income')) || fileBaseName.includes('_income')) {
                        myFetch(`/sale-invoices/related-to-file?fileId=${id}`, { method: 'get' }).then(res => {
                            if (res.success) {
                                dispatch({
                                    type: FileConst.GET_PREVIEV_DATA,
                                    previewClickFromChatNotification: previewClickFromChatNotification,
                                    fileBody: result.data,
                                    fileName: fileBaseName,
                                    fileId: id,
                                    projectId: projectId,
                                    fileStatus: status,
                                    docType: 'SALE_INVOICE',
                                    docData: res,
                                    linkedFiles: result.linkedFiles
                                })
                                dispatch({ type: UserConst.UPDATE_USER_BANDWIDTH_LIMITS, companyId: result.companyId, size: result.size })

                                if (res.doc.receiver && res.doc.receiver.id) {
                                    myFetch(`/files/get-docs-from-counterparty/${id}/${res.doc.receiver.id}`, { method: 'get' }).then(res => {
                                        dispatch({
                                            type: FileConst.GET_LINKED_DOCS,
                                            counterpartyLinkedDocs: res.docs,
                                            fileId: id,
                                        })
                                    })
                                } else {
                                    dispatch({
                                        type: FileConst.GET_LINKED_DOCS,
                                        counterpartyLinkedDocs: {},
                                        fileId: id,
                                    })
                                }
                            } else {
                                dispatch({ type: FileConst.TOGGLE_PREVIEW, status: false, name: null })
                                dispatch(AlertActions.warning(res.msg))
                            }
                        })
                    } else if (filePath && (filePath.includes('/Agreements') || filePath.includes('_agreement'))) {
                        myFetch(`/agreements/related-to-file?fileId=${id}`, { method: 'get' }).then(res => {
                            if (res.success) {
                                dispatch({
                                    type: FileConst.GET_PREVIEV_DATA,
                                    previewClickFromChatNotification: previewClickFromChatNotification,
                                    fileBody: result.data,
                                    fileName: fileBaseName,
                                    fileId: id,
                                    projectId: projectId,
                                    fileStatus: status,
                                    docType: 'AGREEMENT',
                                    docData: res,
                                    linkedFiles: result.linkedFiles
                                })
                                dispatch({ type: UserConst.UPDATE_USER_BANDWIDTH_LIMITS, companyId: result.companyId, size: result.size })

                                if (res.agreement.counterparty && res.agreement.counterparty.id) {
                                    myFetch(`/files/get-docs-from-counterparty/${id}/${res.agreement.counterparty.id}`, { method: 'get' }).then(res => {
                                        dispatch({
                                            type: FileConst.GET_LINKED_DOCS,
                                            counterpartyLinkedDocs: res.docs,
                                            fileId: id,
                                        })
                                    })
                                } else {
                                    dispatch({
                                        type: FileConst.GET_LINKED_DOCS,
                                        counterpartyLinkedDocs: {},
                                        fileId: id,
                                    })
                                }
                            } else {
                                dispatch({ type: FileConst.TOGGLE_PREVIEW, status: false, name: null })
                                dispatch(AlertActions.warning(res.msg))
                            }
                        })
                    } else if (filePath && (filePath.includes('/Correspondence') || filePath.includes('_correspondence'))) {
                        myFetch(`/correspondence/related-to-file?fileId=${id}`, { method: 'get' }).then(res => {
                            if (res.success) {
                                dispatch({
                                    type: FileConst.GET_PREVIEV_DATA,
                                    previewClickFromChatNotification: previewClickFromChatNotification,
                                    fileBody: result.data,
                                    fileName: fileBaseName,
                                    fileId: id,
                                    projectId: projectId,
                                    fileStatus: status,
                                    docType: 'CORRESPONDENCE',
                                    docData: res,
                                    linkedFiles: result.linkedFiles
                                })
                                dispatch({ type: UserConst.UPDATE_USER_BANDWIDTH_LIMITS, companyId: result.companyId, size: result.size })

                                if (res.correspondence.counterparty && res.correspondence.counterparty.id) {
                                    myFetch(`/files/get-docs-from-counterparty/${id}/${res.correspondence.counterparty.id}`, { method: 'get' }).then(res => {
                                        dispatch({
                                            type: FileConst.GET_LINKED_DOCS,
                                            counterpartyLinkedDocs: res.docs,
                                            fileId: id,
                                        })
                                    })
                                } else {
                                    dispatch({
                                        type: FileConst.GET_LINKED_DOCS,
                                        counterpartyLinkedDocs: {},
                                        fileId: id,
                                    })
                                }
                            } else {
                                dispatch({ type: FileConst.TOGGLE_PREVIEW, status: false, name: null })
                                dispatch(AlertActions.warning(res.msg))
                            }
                        })
                    } else if (filePath && (filePath.includes('/Corporate') || filePath.includes('_corporate'))) {
                        myFetch(`/corporate/related-to-file?fileId=${id}`, { method: 'get' }).then(res => {
                            if (res.success) {
                                dispatch({
                                    type: FileConst.GET_PREVIEV_DATA,
                                    previewClickFromChatNotification: previewClickFromChatNotification,
                                    fileBody: result.data,
                                    fileName: fileBaseName,
                                    fileId: id,
                                    projectId: projectId,
                                    fileStatus: status,
                                    docType: 'CORPORATE',
                                    docData: res,
                                    linkedFiles: result.linkedFiles
                                })
                                dispatch({ type: UserConst.UPDATE_USER_BANDWIDTH_LIMITS, companyId: result.companyId, size: result.size })
                                dispatch({
                                    type: FileConst.GET_LINKED_DOCS,
                                    counterpartyLinkedDocs: {},
                                    fileId: id,
                                })
                            } else {
                                dispatch({ type: FileConst.TOGGLE_PREVIEW, status: false, name: null })
                                dispatch(AlertActions.warning(res.msg))
                            }
                        })
                    } else if (filePath && (filePath.includes('/Other') || filePath.includes('/_other'))) {
                        myFetch(`/other-docs/related-to-file?fileId=${id}`, { method: 'get' }).then(res => {
                            if (res.success) {
                                dispatch({
                                    type: FileConst.GET_PREVIEV_DATA,
                                    previewClickFromChatNotification: previewClickFromChatNotification,
                                    fileBody: result.data,
                                    fileName: fileBaseName,
                                    fileId: id,
                                    projectId: projectId,
                                    fileStatus: status,
                                    docType: 'OTHER',
                                    docData: res,
                                    linkedFiles: result.linkedFiles
                                })
                                dispatch({ type: UserConst.UPDATE_USER_BANDWIDTH_LIMITS, companyId: result.companyId, size: result.size })

                                if (res.other_doc.counterparty && res.other_doc.counterparty.id) {
                                    myFetch(`/files/get-docs-from-counterparty/${id}/${res.other_doc.counterparty.id}`, { method: 'get' }).then(res => {
                                        dispatch({
                                            type: FileConst.GET_LINKED_DOCS,
                                            counterpartyLinkedDocs: res.docs,
                                            fileId: id,
                                        })
                                    })
                                } else {
                                    dispatch({
                                        type: FileConst.GET_LINKED_DOCS,
                                        counterpartyLinkedDocs: {},
                                        fileId: id,
                                    })
                                }
                            } else {
                                dispatch({ type: FileConst.TOGGLE_PREVIEW, status: false, name: null })
                                dispatch(AlertActions.warning(res.msg))
                            }
                        })
                    } else if (filePath && (filePath.includes('/Protocol') || filePath.includes('/_other'))) {
                        myFetch(`/protocol-docs/related-to-file?fileId=${id}`, { method: 'get' }).then(res => {
                            if (res.success) {
                                dispatch({
                                    type: FileConst.GET_PREVIEV_DATA,
                                    previewClickFromChatNotification: previewClickFromChatNotification,
                                    fileBody: result.data,
                                    fileName: fileBaseName,
                                    fileId: id,
                                    projectId: projectId,
                                    fileStatus: status,
                                    docType: 'PROTOCOL',
                                    docData: res,
                                    linkedFiles: []
                                })
                                dispatch({ type: UserConst.UPDATE_USER_BANDWIDTH_LIMITS, companyId: result.companyId, size: result.size })
                                dispatch({
                                    type: FileConst.GET_LINKED_DOCS,
                                    counterpartyLinkedDocs: {},
                                    fileId: id,
                                })
                            } else {
                                dispatch({ type: FileConst.TOGGLE_PREVIEW, status: false, name: null })
                                dispatch(AlertActions.warning(res.msg))
                            }
                        })
                    } else {
                        myFetch(`/docs/related-to-file?fileId=${id}`, { method: 'get' }).then(res => {
                            if (res.success) {
                                if (res.type === 'COSTS' || res.type === 'EXPENSES_REPORT' || res.type === 'MAG') {
                                    console.log('CO POBRAﬂEM dla COST!!!!', res)
                                    dispatch({
                                        type: FileConst.GET_PREVIEV_DATA,
                                        previewClickFromChatNotification: previewClickFromChatNotification,
                                        fileBody: result.data,
                                        fileName: fileBaseName,
                                        fileId: id,
                                        projectId: projectId,
                                        fileStatus: status,
                                        docType: res.type,
                                        docData: res,
                                        linkedFiles: result.linkedFiles,
                                        costSplit: result.costSplit,
                                        mpkSplitWineAvenue: result.mpkSplitWineAvenue
                                    })

                                    if (res.doc.sender && res.doc.sender.id) {
                                        myFetch(`/files/get-docs-from-counterparty/${id}/${res.doc.sender.id}`, { method: 'get' }).then(res => {
                                            dispatch({
                                                type: FileConst.GET_LINKED_DOCS,
                                                counterpartyLinkedDocs: res.docs,
                                                fileId: id,
                                            })
                                        })
                                    } else {
                                        dispatch({
                                            type: FileConst.GET_LINKED_DOCS,
                                            counterpartyLinkedDocs: {},
                                            fileId: id,
                                        })
                                    }
                                } else if (res.type === 'EXPENSES') {
                                    dispatch({
                                        type: FileConst.GET_PREVIEV_DATA,
                                        fileBody: result.data,
                                        fileName: fileBaseName,
                                        fileId: id,
                                        projectId: projectId,
                                        fileStatus: status,
                                        docType: 'EXPENSES',
                                        docData: res,
                                        mpkSplitWineAvenue: result.mpkSplitWineAvenue
                                    })
                                    dispatch({
                                        type: FileConst.GET_LINKED_DOCS,
                                        counterpartyLinkedDocs: {},
                                        fileId: id,
                                    })
                                }

                                dispatch({ type: UserConst.UPDATE_USER_BANDWIDTH_LIMITS, companyId: result.companyId, size: result.size })
                            } else {
                                dispatch({ type: FileConst.TOGGLE_PREVIEW, status: false })
                                dispatch(AlertActions.warning(res.msg))
                            }
                        })
                    }
                } else {
                    dispatch({
                        type: FileConst.GET_PREVIEV_DATA,
                        fileBody: result.data,
                        fileName: fileBaseName,
                        fileId: id,
                        projectId: projectId,
                        fileStatus: 'NOT_ACCAPTED',
                        docType: 'NOT_ACCAPTED'
                    })
                }
            } else {
                dispatch({ type: FileConst.TOGGLE_PREVIEW, status: false })
                dispatch(AlertActions.warning(result.msg))
            }
        }).catch(err => {
            dispatch({ type: FileConst.TOGGLE_PREVIEW, status: false })
            handleError(err, dispatch, `/files/download?projectId=${projectId}&fileId=${id}`)
        })
    }
}

function getCounterpartyDocs(fileId, counterpartyId) {
    return dispatch => {
        dispatch({ type: FileConst.WAIT_FOR_LINKED_DOCS, fileId: fileId })
        myFetch(`/files/get-docs-from-counterparty/${fileId}/${counterpartyId}`, { method: 'get' }).then(res => {
            if (res.success) {
                dispatch({
                    type: FileConst.GET_LINKED_DOCS,
                    counterpartyLinkedDocs: res.docs,
                    fileId: fileId,
                })
            }
        })
    }
}

function toggleLinkedDocs(docType) {
    return dispatch => {
        dispatch({
            type: FileConst.TOGGLE_LINKED_DOCS,
            docType: docType,
        })
    }
}

function downloadFileForSideBySidePreview(fileId, projectId, fileName) {
    return dispatch => {
        dispatch({
            type: FileConst.OPEN_SIDE_BY_SIDE_PREVIEW,
            fileName: fileName,
            fileId: fileId,
        })
        myFetch(`/files/download-file/?projectId=${projectId}&fileId=${fileId}`, { method: 'get' }).then(res => {
            if (res.success) {
                dispatch({
                    type: FileConst.OPEN_SIDE_BY_SIDE_PREVIEW,
                    fileBody: res.data,
                    fileName: fileName,
                    fileId: fileId,
                })
            } else {
                dispatch({
                    type: FileConst.CLOSE_SIDE_BY_SIDE_PREVIEW,
                })
            }
        }).catch(err => {
            dispatch({
                type: FileConst.CLOSE_SIDE_BY_SIDE_PREVIEW,
            })
            handleError(err, dispatch, `/files/download-file/?projectId=${projectId}&fileId=${fileId}`)
        })
    }
}

function closeSideBySidePreview() {
    return dispatch => {
        dispatch({
            type: FileConst.CLOSE_SIDE_BY_SIDE_PREVIEW,
        })
    }
}

function addFileAttachment(fileId, attachment) {
    return dispatch => {
        dispatch({
            type: FileConst.ADD_NEW_ATTACHMENT, attachmentInstance: { name: attachment.name, type: attachment.type, file_id: fileId }
        })
        myFetch(`/file-attachments/add`, {
            method: 'post', body: {
                fileId: fileId,
                attachmentBody: attachment.base64,
                attachmentName: attachment.name,
                attachmentType: attachment.type
            }
        }).then(res => {
            if (res.success) {
                dispatch({
                    type: FileConst.ADD_NEW_ATTACHMENT, attachmentInstance: res.attachmentInstance
                })
            } else {
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            dispatch({
                type: FileConst.REMOVE_ATTACHMENT, attachmentId: -1, attachmentName: attachment.name
            })
            handleError(err, dispatch, `/file-attachments/add`)
        })
    }
}

function downloadAttachemntForSideBySidePreview(attachmentId, attachmentName) {
    return dispatch => {
        dispatch({
            type: FileConst.OPEN_SIDE_BY_SIDE_PREVIEW,
            fileName: attachmentName
        })
        myFetch(`/file-attachments/get-body/${attachmentId}`, { method: 'get' }).then(res => {
            if (res.success) {
                dispatch({
                    type: FileConst.OPEN_SIDE_BY_SIDE_PREVIEW,
                    fileBody: res.data,
                    fileName: attachmentName
                })
            } else {
                dispatch({
                    type: FileConst.CLOSE_SIDE_BY_SIDE_PREVIEW,
                })
            }
        }).catch(err => {
            dispatch({
                type: FileConst.CLOSE_SIDE_BY_SIDE_PREVIEW,
            })
            handleError(err, dispatch, `/file-attachments/get-body/${attachmentId}`)
        })
    }
}

function downloadAttachemnt(attachmentId, attachmentName, cb) {
    return dispatch => {
        myFetch(`/file-attachments/get-body/${attachmentId}`, { method: 'get' }).then(res => {
            if (res.success) {
                var a = document.createElement('a');
                a.href = res.data;
                a.download = attachmentName;
                a.click();
            }
            cb()
        }).catch(err => {
            handleError(err, dispatch, `/file-attachments/get-body/${attachmentId}`)
            cb()
        })
    }
}

function deleteAttachment(attachmentId, cb) {
    return dispatch => {
        myFetch(`/file-attachments/delete`, { method: 'delete', body: { attachmentId: attachmentId } }).then(res => {
            if (res.success) {
                dispatch({
                    type: FileConst.REMOVE_ATTACHMENT, attachmentId: attachmentId
                })
            }
            cb()
        }).catch(err => {
            handleError(err, dispatch, `/file-attachments/delete`)
            cb()
        })
    }
}

function togglePdfPreview(status) {
    return dispatch => {
        dispatch({ type: FileConst.TOGGLE_PREVIEW, status: status })
    }
}

function checkAccessToFileFromUrl(projectId, fileId, cb) {
    return dispatch => {
        myFetch(`/files/check-access-for-url-preview/${projectId}/${fileId}`, { method: 'get' }).then(res => {
            cb(res.hasAccess, res.msg)
        }).catch(err => {
            handleError(err, dispatch, `/files/check-access-for-url-preview/${projectId}/${fileId}`)
            cb()
        })
    }
}

function parsePDF(fileData, projectId, size, pages) {
    return dispatch => {
        myFetch(`/files/parse-pdf`, {
            method: 'post', body: {
                file: JSON.stringify(fileData),
                projectId: projectId,
                size: size,
                fromEmail: false,
                senderEmail: '',
                pages: pages,
            }
        }).then(res => {
            console.log(res.success)
            if (res.success) {
                console.log(res.name)
                //if(res.foundOCRModel === null) {
                console.log('before dispatch new file')
                console.log(res)
                dispatch({ type: UserConst.UPDATE_USER_LIMITS, action: 'UPLOAD', size: size, companyId: res.companyId })
                dispatch({
                    type: FileConst.PARSED_PDF_FILE,
                    projectId: projectId,
                    ftvId: res.ftvId,
                    dbId: res.dbId,
                    name: res.name,
                    newName: res.newName,
                    image: res.data,
                    status: res.status,
                    ocr: res.ocrData,
                    defaultData: res.defaultData,
                    language: null,
                    manualOcr: null,
                    expenseOcr: JSON.parse(res.expense_ocr),
                    agreementProps: JSON.parse(res.agreementProps),
                    correspondenceProps: JSON.parse(res.correspondenceProps),
                    corporateProps: JSON.parse(res.corporateProps),
                    incomeProps: JSON.parse(res.incomeProps),
                    otherProps: JSON.parse(res.otherProps),
                    protocolProps: JSON.parse(res.protocolProps),
                    paymentMethod: 'not-paid',
                    docType: res.docType,
                    currency: 'PLN',
                    paymentType: 'regular',
                    fromNewCounterparty: res.fromNewCounterparty,
                    expenseOwnerName: res.expenseOwnerName,
                    expenseOwnerId: res.expenseOwnerId,
                    uploadDate: res.uploadDate,
                    userThatUploaded: res.userThatUploaded,
                    uploadMethod: 'web',
                    comment: res.comment,
                    tags: res.tags,
                    locked_for_devex: res.locked_for_devex,
                    locked_for_devex_by: res.locked_for_devex_by,
                    used_ocr_model: res.used_ocr_model,
                })

                var data = {
                    projectId: projectId,
                    fileId: res.dbId,
                    language: localStorage.getItem('language')
                }
                myFetch(`/files/my-files/?data=${JSON.stringify(data)}`, { method: 'get' }).then(result => {
                    if (result.success) {
                        dispatch({ type: FileConst.GET_FILES, files: result.files, projectId: projectId, currentDir: null })
                    } else {
                        dispatch(AlertActions.warning(result.msg))
                    }
                }).catch(err => {
                    handleError(err, dispatch, `/files/my-files/?data=${JSON.stringify(data)}`)
                })
            } else {
                console.log('remove from cache success false')
                dispatch({ type: FileConst.REMOVE_FILE_FROM_CACHE, name: fileData.fileName, projectId: projectId })
                dispatch(AlertActions.warning(res.msg, 5000))
            }
        }).catch(err => {
            console.log('remove from cache error')
            dispatch({ type: FileConst.REMOVE_FILE_FROM_CACHE, name: fileData.name, projectId: projectId })
            handleError(err, dispatch, `/files/parse-pdf`)
        })
    }
}

function dropFileFromOtherProject(fileId, fileName, oldProjectId, newProjectId) {
    console.log(fileId, oldProjectId, newProjectId)
    var filesSearchData = {
        projectId: newProjectId,
        fileId: fileId,
        language: localStorage.getItem('language')
    }
    return dispatch => {
        dispatch({ type: FileConst.CACHE_FILE, name: fileName, image: "processing", status: "processing", projectId: newProjectId, size: 0, docType: null })
        dispatch({ type: FileConst.DELETE_UNVERIFIED_FILE, projectId: oldProjectId, id: fileId })
        dispatch({ type: FileConst.DELETE_FILE_PERMANENT, projectId: oldProjectId, id: fileId })
        //dispatch({type: FileConst.LOADING_SCREEN, loadingScreen: true})
        myFetch(`/files-to-verifi/move-to-other-project`, {
            method: 'put', body: {
                fileId: fileId,
                oldProjectId: oldProjectId,
                newProjectId: newProjectId
            }
        })
            .then(res => {
                //dispatch({type: FileConst.LOADING_SCREEN, loadingScreen: false})
                dispatch({ type: FileConst.REMOVE_FILE_FROM_CACHE, name: fileName, projectId: newProjectId })
                if (res.success) {
                    dispatch({ type: FileConst.GET_FILES_TO_VERIFI, files: [res.file], projectId: newProjectId })
                    myFetch(`/files/my-files/?data=${JSON.stringify(filesSearchData)}`, { method: 'get', responseType: 'blob' }).then(result => {
                        if (result.success) {
                            dispatch({ type: FileConst.GET_FILES, files: result.files, projectId: newProjectId, currentDir: '' })
                        } else {
                            dispatch(AlertActions.warning(result.msg))
                        }
                    })
                } else {
                    dispatch({ type: FileConst.DELETE_UNVERIFIED_FILE, projectId: newProjectId, id: fileId })
                    dispatch({ type: FileConst.DELETE_FILE_PERMANENT, projectId: newProjectId, id: fileId })
                    dispatch(AlertActions.warning(res.msg))

                    myFetch(`/files-to-verifi/my-files/?projectId=${oldProjectId}&fileId=${fileId}`, { method: 'get' })
                        .then(res => {
                            if (res.success) {
                                dispatch({ type: FileConst.GET_FILES_TO_VERIFI, files: res.files, projectId: oldProjectId })
                            } else {
                                console.log(res.msg)
                            }
                        }).catch(err => {
                            console.log(err)
                        })
                }
            }).catch(err => {
                dispatch({ type: FileConst.REMOVE_FILE_FROM_CACHE, name: fileName, projectId: newProjectId })
                myFetch(`/files-to-verifi/my-files/?projectId=${oldProjectId}&fileId=${fileId}`, { method: 'get' })
                    .then(res => {
                        if (res.success) {
                            dispatch({ type: FileConst.GET_FILES_TO_VERIFI, files: res.files, projectId: oldProjectId })
                        } else {
                            console.log(res.msg)
                        }
                    }).catch(err => {
                        console.log(err)
                    })
                //dispatch({type: FileConst.LOADING_SCREEN, loadingScreen: false})
                handleError(err, dispatch, `/files-to-verifi/my-files/?projectId=${oldProjectId}&fileId=${fileId}`)
            })
    }
}

function moveToOtherProject(fileId, fileName, oldProjectId, newProjectId) {
    console.log('moveToOtherProject func')
    console.log(fileId, oldProjectId, newProjectId)
    var filesSearchData = {
        projectId: newProjectId,
        fileId: fileId,
        language: localStorage.getItem('language')
    }
    return dispatch => {
        dispatch({ type: FileConst.CACHE_FILE, name: fileName, image: "processing", status: "processing", projectId: newProjectId, size: 0, docType: null })
        dispatch({ type: FileConst.DELETE_UNVERIFIED_FILE, projectId: oldProjectId, id: fileId })
        dispatch({ type: FileConst.DELETE_FILE_PERMANENT, projectId: oldProjectId, id: fileId })
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/files-to-verifi/move-to-other-project`, {
            method: 'put', body: {
                fileId: fileId,
                oldProjectId: oldProjectId,
                newProjectId: newProjectId
            }
        })
            .then(res => {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                dispatch({ type: FileConst.REMOVE_FILE_FROM_CACHE, name: fileName, projectId: newProjectId })
                if (res.success) {
                    dispatch({ type: FileConst.GET_FILES_TO_VERIFI, files: [res.file], projectId: newProjectId })
                    history.push(reverse(routes.panel.crop, { projectId: newProjectId, fileId: fileId }))
                    myFetch(`/files/my-files/?data=${JSON.stringify(filesSearchData)}`, { method: 'get', responseType: 'blob' }).then(result => {
                        if (result.success) {
                            dispatch({ type: FileConst.GET_FILES, files: result.files, projectId: newProjectId, currentDir: '' })
                        } else {
                            dispatch(AlertActions.warning(result.msg))
                        }
                    })
                } else {
                    dispatch({ type: FileConst.DELETE_UNVERIFIED_FILE, projectId: newProjectId, id: fileId })
                    dispatch({ type: FileConst.DELETE_FILE_PERMANENT, projectId: newProjectId, id: fileId })
                    dispatch(AlertActions.warning(res.msg))

                    myFetch(`/files-to-verifi/my-files/?projectId=${oldProjectId}&fileId=${fileId}`, { method: 'get' })
                        .then(res => {
                            if (res.success) {
                                dispatch({ type: FileConst.GET_FILES_TO_VERIFI, files: res.files, projectId: oldProjectId })
                            } else {
                                console.log(res.msg)
                            }
                        }).catch(err => {
                            console.log(err)
                        })
                }
            }).catch(err => {
                dispatch({ type: FileConst.REMOVE_FILE_FROM_CACHE, name: fileName, projectId: newProjectId })
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                handleError(err, dispatch, `/files-to-verifi/move-to-other-project`)
            })
    }
}

function moveAcceptedFileToOtherProject(fileId, oldProjectId, newProjectId, cb) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/files/move-to-other-project`, {
            method: 'put', body: {
                fileId: fileId,
                oldProjectId: oldProjectId,
                newProjectId: newProjectId
            }
        })
            .then(res => {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                cb(res.success)
            })
            .catch(err => {
                cb(false)
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                handleError(err, dispatch, `/files-to-verifi/update-ocr`)
            })
    }
}

function markProjectsToLoad() {
    return dispatch => {
        dispatch({ type: FileConst.MARK_PROJECTS_TO_LOAD })
    }
}

function getProjectResourcesForDashboard(projectId, fileId, dir, cb = () => { }) {
    return async dispatch => {
        var filesSearchData = {
            projectId: projectId,
            fileId: 0,
            language: localStorage.getItem('language')
        }

        dispatch({ type: FileConst.GET_FILES_TO_VERIFI_REQUEST, projectId: projectId })
        dispatch({ type: FileConst.GET_FILES_REQUEST, projectId: projectId })
        dispatch({ type: TransferConst.GET_TRANSFERS_REQUEST, projectId: projectId })
        dispatch({ type: ProjectCostsConsts.SEND_PROJECT_COSTS_REQUEST, projectId: projectId })
        try {
            const filesToVerifyRes = await myFetch(`/files-to-verifi/my-files/?projectId=${projectId}&fileId=${fileId}`, { method: 'get' })
            cb(filesToVerifyRes.success)
            if (filesToVerifyRes.success) {
                dispatch({ type: FileConst.GET_FILES_TO_VERIFI, files: filesToVerifyRes.files, projectId: projectId })
            } else {
                dispatch(AlertActions.warning(filesToVerifyRes.msg))
            }

            const filesRes = await myFetch(`/files/my-files/?data=${JSON.stringify(filesSearchData)}`, { method: 'get', responseType: 'blob' })
            if (filesRes.success) {
                dispatch({ type: FileConst.GET_FILES, files: filesRes.files, projectId: projectId, currentDir: dir })
            } else {
                dispatch(AlertActions.warning(filesRes.msg))
            }

            const paymentOrdersRes = await myFetch(`/cash-transfer-orders/get-mine/?projectId=${projectId}&transferId=${-1}`, { method: 'get' })
            if (paymentOrdersRes.success) {
                dispatch({ type: TransferConst.GET_TRANSFERS, projectId: projectId, transfers: paymentOrdersRes.transfers })
            } else {
                dispatch(AlertActions.warning(paymentOrdersRes.msg))
            }

            const projectCostsRes = await myFetch(`/projects/get-costs/${projectId}`, { methid: 'get' })
            if (projectCostsRes.success) {
                dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: projectCostsRes.costs })
            } else {
                dispatch(AlertActions.warning(projectCostsRes.msg))
            }
        } catch (err) {
            handleError(err, dispatch, `getProjectResourcesForDashboard(${projectId}, ${fileId}, ${dir})`)
        }
    }
}

function getFilesToVerifi(projectId, fileId) {
    return async dispatch => {
        dispatch({ type: FileConst.GET_FILES_TO_VERIFI_REQUEST, projectId: projectId })

        myFetch(`/files-to-verifi/my-files/?projectId=${projectId}&fileId=${fileId}`, { method: 'get' })
            .then(res => {
                if (res.success) {
                    dispatch({ type: FileConst.GET_FILES_TO_VERIFI, files: res.files, projectId: projectId })
                } else {
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                handleError(err, dispatch, `/files-to-verifi/my-files/?projectId=${projectId}&fileId=${fileId}`)
            })
    }
}

function getFilesToVerifyRefetch(projectId, fileId, cb = () => { }) {
    return async dispatch => {
        myFetch(`/files-to-verifi/my-files/?projectId=${projectId}&fileId=${fileId}`, { method: 'get' })
            .then(res => {
                cb(res.success)
                if (res.success) {
                    dispatch({ type: FileConst.GET_FILES_TO_VERIFI, files: res.files, projectId: projectId })
                } else {
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                cb(false)
                handleError(err, dispatch, `/files-to-verifi/my-files/?projectId=${projectId}&fileId=${fileId}`)
            })
    }
}

function cacheFile(file) {
    return dispatch => {
        dispatch({ type: FileConst.CACHE_FILE, name: file.name, image: "processing", status: "processing", projectId: file.projectId, size: file.size, docType: null })
    }
}

function saveOCRModel(modelData) {
    console.log(modelData)
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/ocr/save-model`, {
            method: 'post', body: {
                data: {
                    pos_sender: modelData.sellerPos,
                    pos_receiver: modelData.buyerPos,
                    pos_amount: modelData.pricePos,
                    pos_brutto: modelData.bruttoPos,
                    pos_netto: modelData.nettoPos,
                    pos_duedate: modelData.dueDatePos,
                    pos_issuedate: modelData.issueDatePos,
                    pos_bic: modelData.bankPos,
                    pos_invoice_number: modelData.invoiceNumber,
                    pos_vatid: modelData.sellerVatIdPos,
                    value_vatid: modelData.sellerVatId,
                    language: modelData.language,
                    pos_address: modelData.addressPos,
                    pos_swift: modelData.swiftPos
                }
            }
        }).then(res => {
            if (res.success) {
                //dispatch(AlertActions.success("New OCR model successfully added to base."))
            }
        }).catch(err => {
            handleError(err, dispatch, `/ocr/save-model`)
        })
    }
}

function updateOCRModel(modelData) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/ocr/update-model`, {
            method: 'put', body: {
                data: {
                    id: modelData.id,
                    pos_sender: modelData.sellerPos,
                    pos_receiver: modelData.buyerPos,
                    pos_amount: modelData.pricePos,
                    pos_brutto: modelData.bruttoPos,
                    pos_netto: modelData.nettoPos,
                    pos_tax: modelData.taxPos,
                    pos_duedate: modelData.dueDatePos,
                    pos_issuedate: modelData.issueDatePos,
                    pos_bic: modelData.bankPos,
                    pos_invoice_number: modelData.invoiceNumber,
                    pos_vatid: modelData.sellerVatIdPos,
                    pos_address: modelData.addressPos,
                    pos_swift: modelData.swiftPos
                }
            }
        }).then(res => {

        }).catch(err => {
            handleError(err, dispatch, `/ocr/update-model`)
        })
    }
}

function checkInvoiceNumber(ocrData, projectId, type) {
    return dispatch => {
        var filter = {
            invoiceNumber: ocrData.find(e => e.name === 'invoiceNumber') ? ocrData.find(e => e.name === 'invoiceNumber').text : '',
            docType: type,
            seller: ocrData.find(e => e.name === 'sellerVatId') ? ocrData.find(e => e.name === 'sellerVatId').text : '',
            projectId: projectId
        }
        myFetch(`/docs/check-invoice-number/?filter=${encodeURIComponent(JSON.stringify(filter))}`, { method: 'get' }).then(res => {
            if (res.exists && res.similar) {
                dispatch({ type: FileConst.INVOICE_EXISTS, similar: res.similar, files: res.files })
            } else if (res.exists) {
                dispatch({ type: FileConst.INVOICE_EXISTS, project: res.file.projectId, projectCode: res.file.projectCode, file: res.file.path, id: res.file.file_id, similar: res.similar })
            }
        }).catch(err => {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
            handleError(err, dispatch, `/docs/check-invoice-number/?filter=${encodeURIComponent(JSON.stringify(filter))}`)
        })
    }

}

function updatePartialOCRData(projectId, ocrData, id, ftvId, comment, tags, paymentMethod, priority, currency, docType, paymentType, fileName, language, fromNewCounterparty, nextDocExists, waproData, symfoniaData, WL_DevCosts, linkedFiles, cb) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/files-to-verifi/update-ocr`, {
            method: 'put', body: {
                data: {
                    projectId: projectId,
                    ocrData: ocrData,
                    fileId: id,
                    ftvId: ftvId,
                    paymentMethod: paymentMethod,
                    paymentType: paymentType,
                    currency: currency,
                    comment: comment || '',
                    tags: tags || '',
                    docType: docType,
                    priority: priority,
                    language: language,
                    ocrScreen: true,
                    manualOcr: null,
                    fromNewCounterparty: fromNewCounterparty,
                    waproData: waproData,
                    symfoniaData: symfoniaData,
                    WL_DevCosts: WL_DevCosts,
                    linkedFiles: linkedFiles,
                }
            }
        }).then(res => {
            if (res.success) {
                dispatch({ type: FileConst.CHANGE_FILE_ACCEPTNCE_STEP, step: 2, projectId: projectId, fileId: id })
                dispatch({ type: FileConst.CHANGE_FILE_TO_VERIFI_ACCEPTNCE_STEP, step: 2, ocrData: JSON.parse(res.ocrData), currency: res.currency, comment: res.comment, tags: res.tags, paymentMethod: res.paymentMethod, paymentType: res.paymentType, projectId: projectId, ftvId: ftvId, priority: res.priority })
                if (nextDocExists) {
                    cb()
                } else {
                    history.push(routes.panel.dashboard)
                }
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })

                socket.emit('submitOCR', id, projectId, fileName, (err) => {
                    if (err) {
                        console.log(err)
                        console.log('socket event submitOCR  wasn\'t broadcasted' + moment().format('HH:mm.ss.SSS'))
                    } else {
                        console.log('socket event submitOCR  was broadcasted' + moment().format('HH:mm.ss.SSS'))
                    }
                })

            } else {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                dispatch(AlertActions.warning(res.msg))
            }

        }).catch(err => {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
            handleError(err, dispatch, `/files-to-verifi/update-ocr`)
        })
    }
}

function updateOCRData(projectId, ocrData, id, ftvId, comment, tags, paymentMethod, currency, docType, paymentType, status, fileName, language, nextDocExists, forceSimilarSave, waproData, symfoniaData, WL_DevCosts, linkedFiles, documentDescription, cb) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        if (ocrData.find(e => e.name === 'invoiceNumber')) {
            var filter = {
                invoiceNumber: ocrData.find(e => e.name === 'invoiceNumber').text,
                docType: docType,
                seller: ocrData.find(e => e.name === 'sellerVatId') ? ocrData.find(e => e.name === 'sellerVatId').text : '',
                projectId: projectId,
                accountNumber: ocrData.find(e => e.name === 'bankData') ? ocrData.find(e => e.name === 'bankData').text : '',
                amountToPay: ocrData.find(e => e.name === 'price') ? ocrData.find(e => e.name === 'price').text : '',
                dueDate: ocrData.find(e => e.name === 'dueDate') ? ocrData.find(e => e.name === 'dueDate').text : '',
                paymentMethod: paymentMethod,
                issueDate: ocrData.find(e => e.name === 'issueDate') ? ocrData.find(e => e.name === 'issueDate').text : '',
            }
            myFetch(`/docs/check-invoice-number/?filter=${encodeURI(JSON.stringify(filter))}`, { method: 'get' }).then(res => {
                if (!res.exists || (res.similar && forceSimilarSave)) {
                    myFetch(`/docs/save`, {
                        method: 'post', body: {
                            ocrData: ocrData,
                            id: id,
                            docType: typeof docType === 'string' ? docType : 'COSTS',
                            comment: typeof comment === 'string' ? comment : '',
                            tags: typeof tags === 'string' ? tags : '',
                            paymentMethod: typeof paymentMethod === 'string' ? paymentMethod : 'not-paid',
                            paymentType: typeof paymentType === 'string' ? paymentType : 'reqular',
                            currency: typeof currency === 'string' ? currency : 'PLN',
                            waproData: waproData,
                            symfoniaData: symfoniaData,
                            WL_DevCosts: WL_DevCosts,
                            docDescription: documentDescription
                        }
                    }).then(res => {
                        console.log(res)
                        if (res.success) {
                            dispatch(AlertActions.info("Data from invoice was saved."))
                            myFetch(`/files-to-verifi/update-ocr`, {
                                method: 'put', body: {
                                    data: {
                                        projectId: projectId,
                                        ocrData: ocrData,
                                        fileId: id,
                                        ftvId: ftvId,
                                        paymentMethod: paymentMethod,
                                        paymentType: paymentType,
                                        currency: currency,
                                        comment: comment || '',
                                        tags: tags || '',
                                        docType, docType,
                                        language: language,
                                        ocrScreen: true,
                                        manualOcr: null,
                                        linkedFiles: linkedFiles,
                                    }
                                }
                            }).then(res => {
                                if (res.success) {
                                    dispatch({ type: FileConst.CHANGE_FILE_ACCEPTNCE_STEP, step: 2, projectId: projectId, fileId: id })
                                    dispatch({ type: FileConst.CHANGE_FILE_TO_VERIFI_ACCEPTNCE_STEP, step: 2, ocrData: JSON.parse(res.ocrData), currency: res.currency, comment: res.comment, tags: res.tags, paymentMethod: res.paymentMethod, paymentType: res.paymentType, projectId: projectId, ftvId: ftvId })
                                    if (nextDocExists) {
                                        cb()
                                    } else {
                                        history.push(routes.panel.dashboard)
                                    }
                                    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })

                                    socket.emit('submitOCR', id, projectId, fileName, (err) => {
                                        if (err) {
                                            console.log(err)
                                            console.log('socket event submitOCR  wasn\'t broadcasted' + moment().format('HH:mm.ss.SSS'))
                                        } else {
                                            console.log('socket event submitOCR  was broadcasted' + moment().format('HH:mm.ss.SSS'))
                                        }
                                    })

                                } else {
                                    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                                    dispatch(AlertActions.warning(res.msg))
                                }
                            }).catch(err => {
                                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                                handleError(err, dispatch, `/files-to-verifi/update-ocr`)
                            })
                        }
                    }).catch(err => {
                        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                        handleError(err, dispatch, `/docs/save`)
                    })
                } else {
                    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                    if (res.exists && res.similar) {
                        dispatch({ type: FileConst.INVOICE_EXISTS, similar: res.similar, files: res.files })
                    } else if (res.exists) {
                        dispatch({ type: FileConst.INVOICE_EXISTS, project: res.file.projectId, projectCode: res.file.projectCode, file: res.file.path, id: res.file.file_id, similar: res.similar })
                    }
                }
            }).catch(err => {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                handleError(err, dispatch, `/docs/check-invoice-number/?filter=${encodeURI(JSON.stringify(filter))}`)
            })
        } else {
            dispatch(AlertActions.warning("Document number is required"))
        }
    }
}

function saveOCRData(projectId, ocrData, id, ftvId, comment, tags, paymentMethod, priority, currency, docType, paymentType, status, updateFileInstance, nextDocExists, forceSimilarSave, waproData, symfoniaData, WL_DevCosts, linkedFiles, emailToSendPaymentConfirmation, documentDescription, cb) {
    console.log(projectId)
    console.log(docType)

    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        console.log("save ocr was used")
        if (ocrData.find(e => e.name === 'invoiceNumber')) {
            var filter = {
                invoiceNumber: ocrData.find(e => e.name === 'invoiceNumber').text,
                docType: docType,
                seller: ocrData.find(e => e.name === 'sellerVatId') ? ocrData.find(e => e.name === 'sellerVatId').text : '',
                projectId: projectId,
                accountNumber: ocrData.find(e => e.name === 'bankData') ? ocrData.find(e => e.name === 'bankData').text : '',
                amountToPay: ocrData.find(e => e.name === 'price') ? ocrData.find(e => e.name === 'price').text : '',
                dueDate: ocrData.find(e => e.name === 'dueDate') ? ocrData.find(e => e.name === 'dueDate').text : '',
                paymentMethod: paymentMethod,
                issueDate: ocrData.find(e => e.name === 'issueDate') ? ocrData.find(e => e.name === 'issueDate').text : '',
            }
            myFetch(`/docs/check-invoice-number/?filter=${encodeURIComponent(JSON.stringify(filter))}`, { method: 'get' }).then(res => {
                if (!res.exists || (res.similar && forceSimilarSave)) {
                    myFetch(`/docs/save`, {
                        method: 'post', body: {
                            ocrData: ocrData,
                            id: id,
                            docType: typeof docType === 'string' ? docType : 'COSTS',
                            comment: typeof comment === 'string' ? comment : '',
                            tags: typeof tags === 'string' ? tags : '',
                            emailToSendPaymentConfirmation: typeof emailToSendPaymentConfirmation === 'string' ? emailToSendPaymentConfirmation : '',
                            paymentMethod: typeof paymentMethod === 'string' ? paymentMethod : 'not-paid',
                            paymentType: typeof paymentType === 'string' ? paymentType : 'reqular',
                            currency: typeof currency === 'string' ? currency : 'PLN',
                            waproData: waproData,
                            symfoniaData: symfoniaData,
                            WL_DevCosts: WL_DevCosts,
                            docDescription: documentDescription || ""

                        }
                    }).then(res => {
                        console.log(res)
                        if (res.success) {
                            dispatch(AlertActions.info("Data from invoice was saved."))
                            if (localStorage.getItem('adminOnboarding') === 'MINI') {
                                dispatch({ type: UserConst.ACCEPTED_FIRST_DOCUMENT })
                            }

                            myFetch(`/projects/get-costs/${projectId}`, { methid: 'get' }).then(resp => {
                                if (resp.success) {
                                    dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: resp.costs })
                                } else {
                                    dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: {} })
                                }
                            }).catch(err => {
                                dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: {} })
                                handleError(err, dispatch, `/projects/get-costs/${projectId}`)
                            })

                            myFetch(`/files/update-ocr`, {
                                method: 'post', body: {
                                    ocrData: ocrData,
                                    id: id,
                                    paymentMethod: paymentMethod,
                                    paymentType: paymentType,
                                    docType: docType,
                                    priority: priority,
                                    tags: tags || '',
                                    linkedFiles: linkedFiles,
                                }
                            }).then(res => {
                                console.log(res)

                                var fileName = res.name
                                var filePath = res.path
                                dispatch(AlertActions.info("File status updated."))
                                myFetch(`/files-to-verifi/delete`, {
                                    method: 'delete', body: {
                                        ftvId: ftvId,
                                        ocrData: ocrData
                                    }
                                }).then(resp => {
                                    if (nextDocExists) {
                                        cb()
                                    } else {
                                        history.push(routes.panel.dashboard)
                                    }
                                    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                                    myFetch(`/files/my-files/?data=${JSON.stringify({
                                        projectId: projectId,
                                        fileId: id,
                                        language: localStorage.getItem('language')
                                    })}`, { method: 'get' }).then(result => {
                                        dispatch({ type: FileConst.CHANGE_FILE_STATUS_TO_ACCEPTED, file: result.files.find(x => x !== undefined), projectId: projectId, dbId: id })
                                        dispatch({ type: ChatConst.CHANGE_FILE_STATUS_TO_ACCEPTED, fileId: id })
                                    }).catch(err => {
                                        dispatch({ type: FileConst.CHANGE_FILE_STATUS, projectId: projectId, dbId: id, status: res.status, fileName: fileName, filePath: filePath, tags: tags })
                                    })

                                    console.log('emited socket event saveOCR ' + moment().format('HH:mm.ss.SSS'))
                                    socket.emit('acceptOCR', id, projectId, fileName, (err) => {
                                        if (err) {
                                            console.log(err)
                                            console.log('socket event acceptOCR wasn\'t broadcasted' + moment().format('HH:mm.ss.SSS'))
                                        } else {
                                            console.log('socket event acceptOCR was broadcasted' + moment().format('HH:mm.ss.SSS'))
                                        }
                                    })
                                }).catch(err => {
                                    console.log(err)
                                })
                            }).catch(err => {
                                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                                handleError(err, dispatch, `/files/update-ocr`)
                            })
                        } else {
                            dispatch({
                                type: FileConst.LOADING_SCREEN, loadingScreen: false
                            })
                            dispatch(AlertActions.warning(res.message || res.msg))
                        }
                    }).catch(err => {
                        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                        handleError(err, dispatch, `/docs/save`)
                    })
                } else {
                    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                    if (res.exists && res.similar) {
                        dispatch({ type: FileConst.INVOICE_EXISTS, similar: res.similar, files: res.files, linkedFiles: linkedFiles })
                    } else if (res.exists) {
                        dispatch({ type: FileConst.INVOICE_EXISTS, project: res.file.projectId, projectCode: res.file.projectCode, file: res.file.path, id: res.file.file_id, similar: res.similar, linkedFiles: linkedFiles })
                    }
                }
            }).catch(err => {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                handleError(err, dispatch, `/docs/check-invoice-number/?filter=${encodeURIComponent(JSON.stringify(filter))}`)
            })
        }
    }
}

function updatePartialByHandOcr(projectId, manualOcr, id, ftvId, paymentMethod, currency, docType, paymentType, priority, comment, tags, fileName, nextDocExists, waproData, symfoniaData, WL_DevCosts, linkedFiles, documentDescription, cb) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        var manualOcrToSave = {}
        manualOcr.forEach(prop => {
            manualOcrToSave[prop.name] = prop.text
        })
        myFetch(`/files-to-verifi/update-ocr`, {
            method: 'put', body: {
                data: {
                    projectId: projectId,
                    ocrData: null,
                    fileId: id,
                    ftvId: ftvId,
                    paymentMethod: paymentMethod,
                    paymentType: paymentType,
                    priority: priority,
                    currency: currency,
                    docType: docType,
                    comment: comment || '',
                    tags: tags || '',
                    ocrScreen: true,
                    manualOcr: manualOcrToSave,
                    waproData: waproData,
                    symfoniaData: symfoniaData,
                    WL_DevCosts: WL_DevCosts,
                    linkedFiles: linkedFiles,
                    doc_description: documentDescription
                }
            }
        }).then(res => {
            if (res.success) {
                dispatch({ type: FileConst.CHANGE_FILE_ACCEPTNCE_STEP, step: 2, projectId: projectId, fileId: id })
                dispatch({ type: FileConst.CHANGE_FILE_TO_VERIFI_ACCEPTNCE_STEP, step: 2, manualOcr: JSON.parse(res.ocrData), currency: res.currency, comment: res.comment, tags: res.tags, paymentMethod: res.paymentMethod, paymentType: res.paymentType, projectId: projectId, ftvId: ftvId, priority: res.priority })
                if (nextDocExists) {
                    cb()
                } else {
                    history.push(routes.panel.dashboard)
                }
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                socket.emit('submitOCR', id, projectId, fileName, (err) => {
                    if (err) {
                        console.log(err)
                        console.log('socket event submitOCR  wasn\'t broadcasted' + moment().format('HH:mm.ss.SSS'))
                    } else {
                        console.log('socket event submitOCR  was broadcasted' + moment().format('HH:mm.ss.SSS'))
                    }
                })
            } else {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
            handleError(err, dispatch, `/files-to-verifi/update-ocr`)
        })
    }
}

function updateByHandOcr(projectId, manualOcr, ifSellerCode, ifBuyerCode, id, ftvId, type, paymentType, paymentMethod, currency, status, docType, comment, tags, fileName, nextDocExists, forceSimilarSave, waproData, symfoniaData, WL_DevCosts, linkedFiles, documentDescription, cb) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        var filter = {
            invoiceNumber: manualOcr.find(e => e.name === 'invoiceNumber') ? manualOcr.find(e => e.name === 'invoiceNumber').text : '',
            docType: type,
            seller: manualOcr.find(e => e.name === 'sellerVatId') ? manualOcr.find(e => e.name === 'sellerVatId').text : '',
            projectId: projectId,
            accountNumber: manualOcr.find(e => e.name === 'bankData') ? manualOcr.find(e => e.name === 'bankData').text : '',
            amountToPay: manualOcr.find(e => e.name === 'price') ? manualOcr.find(e => e.name === 'price').text : '',
            dueDate: manualOcr.find(e => e.name === 'dueDate') ? manualOcr.find(e => e.name === 'dueDate').text : '',
            paymentMethod: paymentMethod,
            issueDate: manualOcr.find(e => e.name === 'issueDate') ? manualOcr.find(e => e.name === 'issueDate').text : '',
        }

        myFetch(`/docs/check-invoice-number/?filter=${encodeURIComponent(JSON.stringify(filter))}`, { method: 'get' }).then(res => {
            if (!res.exists || (res.similar && forceSimilarSave)) {
                myFetch(`/docs/save-written-data`, {
                    method: 'post', body: {
                        data: {
                            invoiceNumber: manualOcr.find(e => e.name === 'invoiceNumber') ? manualOcr.find(e => e.name === 'invoiceNumber').text : '',
                            sellerData: manualOcr.find(e => e.name === 'sellerName') ? manualOcr.find(e => e.name === 'sellerName').text : '',
                            sellerVatId: '',
                            sellerCode: manualOcr.find(e => e.name === 'sellerCode') ? manualOcr.find(e => e.name === 'sellerCode').text : '',
                            buyerData: manualOcr.find(e => e.name === 'buyerName') ? manualOcr.find(e => e.name === 'buyerName').text : '',
                            buyerVatId: manualOcr.find(e => e.name === 'buyerVatId') ? manualOcr.find(e => e.name === 'buyerVatId').text : '',
                            ifBuyerCode: ifBuyerCode,
                            accountNumber: manualOcr.find(e => e.name === 'bankData') ? manualOcr.find(e => e.name === 'bankData').text : '',
                            dueDate: manualOcr.find(e => e.name === 'dueDate') ? manualOcr.find(e => e.name === 'dueDate').text : '',
                            issueDate: manualOcr.find(e => e.name === 'issueDate') ? manualOcr.find(e => e.name === 'issueDate').text : '',
                            price: manualOcr.find(e => e.name === 'price') ? manualOcr.find(e => e.name === 'price').text : '',
                            brutto: manualOcr.find(e => e.name === 'brutto') ? manualOcr.find(e => e.name === 'brutto').text : '',
                            netto: manualOcr.find(e => e.name === 'netto') ? manualOcr.find(e => e.name === 'netto').text : '',
                            address: manualOcr.find(e => e.name === 'address') ? manualOcr.find(e => e.name === 'address').text : '',
                            swift: manualOcr.find(e => e.name === 'swift') ? manualOcr.find(e => e.name === 'swift').text : '',
                            countryCode: manualOcr.find(e => e.name === 'countryCode') ? manualOcr.find(e => e.name === 'countryCode').text : '',
                            docType: type,
                            id: id,
                            paymentMethod: paymentMethod,
                            paymentType: paymentType,
                            currency: currency,
                            comment: comment,
                            waproData: waproData,
                            doc_description: documentDescription
                        }
                    }
                }).then(res => {
                    if (res.success) {
                        myFetch(`/files-to-verifi/update-ocr`, {
                            method: 'put', body: {
                                data: {
                                    projectId: projectId,
                                    ocrData: null,
                                    fileId: id,
                                    ftvId: ftvId,
                                    paymentMethod: paymentMethod,
                                    paymentType: paymentType,
                                    currency: currency,
                                    docType, docType,
                                    comment: comment || '',
                                    tags: tags || '',
                                    ocrScreen: true,
                                    manualOcr: {
                                        invoiceNumber: manualOcr.find(e => e.name === 'invoiceNumber') ? manualOcr.find(e => e.name === 'invoiceNumber').text : '',
                                        sellerData: manualOcr.find(e => e.name === 'sellerName') ? manualOcr.find(e => e.name === 'sellerName').text : '',
                                        sellerVatId: manualOcr.find(e => e.name === 'sellerVatId') ? manualOcr.find(e => e.name === 'sellerVatId').text : '',
                                        buyerData: manualOcr.find(e => e.name === 'buyerName') ? manualOcr.find(e => e.name === 'buyerName').text : '',
                                        buyerVatId: manualOcr.find(e => e.name === 'buyerVatId') ? manualOcr.find(e => e.name === 'buyerVatId').text : '',
                                        accountNumber: manualOcr.find(e => e.name === 'bankData') ? manualOcr.find(e => e.name === 'bankData').text : '',
                                        dueDate: manualOcr.find(e => e.name === 'dueDate') ? manualOcr.find(e => e.name === 'dueDate').text : '',
                                        issueDate: manualOcr.find(e => e.name === 'issueDate') ? manualOcr.find(e => e.name === 'issueDate').text : '',
                                        price: manualOcr.find(e => e.name === 'price') ? manualOcr.find(e => e.name === 'price').text : '',
                                        brutto: manualOcr.find(e => e.name === 'brutto') ? manualOcr.find(e => e.name === 'brutto').text : '',
                                        netto: manualOcr.find(e => e.name === 'netto') ? manualOcr.find(e => e.name === 'netto').text : ''
                                    },
                                    waproData: waproData,
                                    symfoniaData: symfoniaData,
                                    WL_DevCosts: WL_DevCosts,
                                    linkedFiles: linkedFiles,
                                }
                            }
                        }).then(res => {
                            if (res.success) {
                                dispatch({ type: FileConst.CHANGE_FILE_ACCEPTNCE_STEP, step: 2, projectId: projectId, fileId: id })
                                dispatch({ type: FileConst.CHANGE_FILE_TO_VERIFI_ACCEPTNCE_STEP, step: 2, manualOcr: JSON.parse(res.ocrData), currency: res.currency, comment: res.comment, tags: tags, paymentMethod: res.paymentMethod, paymentType: res.paymentType, projectId: projectId, ftvId: ftvId })
                                if (nextDocExists) {
                                    cb()
                                } else {
                                    history.push(routes.panel.dashboard)
                                }
                                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                                socket.emit('submitOCR', id, projectId, fileName, (err) => {
                                    if (err) {
                                        console.log(err)
                                        console.log('socket event submitOCR  wasn\'t broadcasted' + moment().format('HH:mm.ss.SSS'))
                                    } else {
                                        console.log('socket event submitOCR  was broadcasted' + moment().format('HH:mm.ss.SSS'))
                                    }
                                })
                            } else {
                                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                                dispatch(AlertActions.warning(res.msg))
                            }
                        }).catch(err => {
                            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                            handleError(err, dispatch, `/files-to-verifi/update-ocr`)
                        })
                    } else {
                        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                        dispatch(AlertActions.danger(res.message || res.msg))
                    }
                })
            } else {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                if (res.exists && res.similar) {
                    dispatch({ type: FileConst.INVOICE_EXISTS, similar: res.similar, files: res.files })
                } else if (res.exists) {
                    dispatch({ type: FileConst.INVOICE_EXISTS, project: res.file.projectId, projectCode: res.file.projectCode, file: res.file.path, id: res.file.file_id, similar: res.similar })
                }
            }
        }).catch(err => {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
            handleError(err, dispatch, `/docs/check-invoice-number/?filter=${encodeURIComponent(JSON.stringify(filter))}`)
        })
    }
}

function saveByHandOCR(projectId, manualOcr, ifSellerCode, ifBuyerCode, id, ftvId, type, paymentType, paymentMethod, priority, currency, tags, comment, status, nextDocExists, forceSimilarSave, waproData, symfoniaData, WL_DevCosts, linkedFiles, emailToSendPaymentConfirmation, documentDescription, cb) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        var filter = {
            invoiceNumber: manualOcr.find(e => e.name === 'invoiceNumber') ? manualOcr.find(e => e.name === 'invoiceNumber').text : '',
            docType: type,
            seller: manualOcr.find(e => e.name === 'sellerVatId') ? manualOcr.find(e => e.name === 'sellerVatId').text : '',
            projectId: projectId,
            accountNumber: manualOcr.find(e => e.name === 'bankData') ? manualOcr.find(e => e.name === 'sellerVatId').text : '',
            amountToPay: manualOcr.find(e => e.name === 'price') ? manualOcr.find(e => e.name === 'price').text : '',
            dueDate: manualOcr.find(e => e.name === 'dueDate') ? manualOcr.find(e => e.name === 'dueDate').text : '',
            paymentMethod: paymentMethod,
            issueDate: manualOcr.find(e => e.name === 'issueDate') ? manualOcr.find(e => e.name === 'issueDate').text : '',
        }
        myFetch(`/docs/check-invoice-number/?filter=${encodeURIComponent(JSON.stringify(filter))}`, { method: 'get' }).then(res => {
            if (!res.exists || (res.similar && forceSimilarSave)) {
                myFetch(`/docs/save-written-data`, {
                    method: 'post', body: {
                        data: {
                            invoiceNumber: manualOcr.find(e => e.name === 'invoiceNumber') ? manualOcr.find(e => e.name === 'invoiceNumber').text : '',
                            sellerData: manualOcr.find(e => e.name === 'sellerName') ? manualOcr.find(e => e.name === 'sellerName').text : '',
                            sellerVatId: manualOcr.find(e => e.name === 'sellerVatId') ? manualOcr.find(e => e.name === 'sellerVatId').text : '',
                            ifSellerCode: ifSellerCode,
                            buyerData: manualOcr.find(e => e.name === 'buyerName') ? manualOcr.find(e => e.name === 'buyerName').text : '',
                            buyerVatId: manualOcr.find(e => e.name === 'buyerVatId') ? manualOcr.find(e => e.name === 'buyerVatId').text : '',
                            ifBuyerCode: ifBuyerCode,
                            accountNumber: manualOcr.find(e => e.name === 'bankData') ? manualOcr.find(e => e.name === 'bankData').text : '',
                            dueDate: manualOcr.find(e => e.name === 'dueDate') ? manualOcr.find(e => e.name === 'dueDate').text : '',
                            issueDate: manualOcr.find(e => e.name === 'issueDate') ? manualOcr.find(e => e.name === 'issueDate').text : '',
                            price: manualOcr.find(e => e.name === 'price') ? manualOcr.find(e => e.name === 'price').text : '',
                            brutto: manualOcr.find(e => e.name === 'brutto') ? manualOcr.find(e => e.name === 'brutto').text : '',
                            netto: manualOcr.find(e => e.name === 'netto') ? manualOcr.find(e => e.name === 'netto').text : '',
                            address: manualOcr.find(e => e.name === 'address') ? manualOcr.find(e => e.name === 'address').text : '',
                            swift: manualOcr.find(e => e.name === 'swift') ? manualOcr.find(e => e.name === 'swift').text : '',
                            countryCode: manualOcr.find(e => e.name === 'countryCode') ? manualOcr.find(e => e.name === 'countryCode').text : '',
                            docType: type,
                            id: id,
                            paymentMethod: paymentMethod,
                            paymentType: paymentType,
                            currency: currency,
                            comment: comment,
                            emailToSendPaymentConfirmation: emailToSendPaymentConfirmation,
                            waproData: waproData,
                            symfoniaData: symfoniaData,
                            WL_DevCosts: WL_DevCosts,
                            doc_description: documentDescription || ""
                        }
                    }
                }).then(res => {
                    if (res.success) {
                        dispatch(AlertActions.info("Data from invoice was saved."))
                        if (localStorage.getItem('adminOnboarding') === 'MINI') {
                            dispatch({ type: UserConst.ACCEPTED_FIRST_DOCUMENT })
                        }
                        myFetch(`/files/update-ocr`, {
                            method: 'post', body: {
                                ocrData: manualOcr,
                                id: id,
                                paymentMethod: paymentMethod,
                                paymentType: paymentType,
                                priority: priority,
                                docType: type,
                                tags: tags || '',
                                linkedFiles: linkedFiles,
                            }
                        }).then(res => {
                            console.log(res)
                            var fileName = res.name
                            var filePath = res.path
                            dispatch(AlertActions.info("File status updated."))
                            myFetch(`/files-to-verifi/delete`, {
                                method: 'delete', body: {
                                    ftvId: ftvId,
                                    ocrData: null
                                }
                            }).then(resp => {
                                if (nextDocExists) {
                                    cb()
                                } else {
                                    history.push(routes.panel.dashboard)
                                }
                                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                                myFetch(`/files/my-files/?data=${JSON.stringify({
                                    projectId: projectId,
                                    fileId: id,
                                    language: localStorage.getItem('language')
                                })}`, { method: 'get' }).then(result => {
                                    dispatch({ type: FileConst.CHANGE_FILE_STATUS_TO_ACCEPTED, file: result.files.find(x => x !== undefined), projectId: projectId, dbId: id })
                                    dispatch({ type: ChatConst.CHANGE_FILE_STATUS_TO_ACCEPTED, fileId: id })
                                }).catch(err => {
                                    dispatch({ type: FileConst.CHANGE_FILE_STATUS, projectId: projectId, dbId: id, status: res.status, fileName: fileName, filePath: filePath, tags: tags })
                                })
                                dispatch({ type: ChatConst.CHANGE_FILE_STATUS_TO_ACCEPTED, fileId: id })

                                console.log('emited socket event saveOCR  ' + moment().format('HH:mm.ss.SSS'))
                                socket.emit('acceptOCR', id, projectId, fileName, (err) => {
                                    if (err) {
                                        console.log(err)
                                        console.log('socket event acceptOCR wasn\'t broadcasted ' + moment().format('HH:mm.ss.SSS'))
                                    } else {
                                        console.log('socket event acceptOCR was broadcasted ' + moment().format('HH:mm.ss.SSS'))
                                    }
                                })
                            }).catch(err => {
                                console.log(err)
                            })
                        }).catch(err => {
                            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                            handleError(err, dispatch, `/files/update-ocr`)
                        })
                    } else {
                        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                        dispatch(AlertActions.danger(res.message || res.msg))
                    }
                }).catch(err => {
                    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                    handleError(err, dispatch, `/docs/save-written-data`)
                })
            } else {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                if (res.exists && res.similar) {
                    dispatch({ type: FileConst.INVOICE_EXISTS, similar: res.similar, files: res.files })
                } else if (res.exists) {
                    dispatch({ type: FileConst.INVOICE_EXISTS, project: res.file.projectId, projectCode: res.file.projectCode, file: res.file.path, id: res.file.file_id, similar: res.similar })
                }
            }
        }).catch(err => {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
            handleError(err, dispatch, `/docs/check-invoice-number/?filter=${encodeURIComponent(JSON.stringify(filter))}`)
        })
    }
}

function updatePartialExpenses(expenses, projectId, id, ftvId, priority, currency, tags, status, fileName, nextDocExists, waproData, symfoniaData, WL_DevCosts, documentDescription, cb) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/files-to-verifi/update-ocr`, {
            method: 'put', body: {
                data: {
                    projectId: projectId,
                    ocrData: null,
                    fileId: id,
                    ftvId: ftvId,
                    priority: priority,
                    currency: currency,
                    docType: 'EXPENSES',
                    ocrScreen: true,
                    expensesOcr: expenses,
                    tags: tags || '',
                    waproData: waproData,
                    symfoniaData: symfoniaData,
                    WL_DevCosts: WL_DevCosts,
                    doc_description: documentDescription
                }
            }
        }).then(res => {
            if (res.success) {
                dispatch({ type: FileConst.CHANGE_FILE_ACCEPTNCE_STEP, step: 2, projectId: projectId, fileId: id })
                dispatch({ type: FileConst.CHANGE_FILE_TO_VERIFI_ACCEPTNCE_STEP, step: 2, expensesOcr: JSON.parse(res.ocrData), currency: res.currency, comment: res.comment, tags: res.tags, paymentMethod: res.paymentMethod, projectId: projectId, ftvId: ftvId })
                if (nextDocExists) {
                    cb()
                } else {
                    history.push(routes.panel.dashboard)
                }
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                socket.emit('submitOCR', id, projectId, fileName, (err) => {
                    if (err) {
                        console.log(err)
                        console.log('socket event submitOCR  wasn\'t broadcasted' + moment().format('HH:mm.ss.SSS'))
                    } else {
                        console.log('socket event submitOCR  was broadcasted' + moment().format('HH:mm.ss.SSS'))
                    }
                })
            } else {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
            handleError(err, dispatch, `/files-to-verifi/update-ocr`)
        })
    }
}

function updateExpenses(expenses, projectId, id, ftvId, currency, tags, status, fileName, nextDocExists, waproData, symfoniaData, WL_DevCosts, documentDescription, cb) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/docs/save-expenses`, {
            method: 'post', body: {
                data: {
                    expenses: expenses,
                    fileId: id,
                    projectId: projectId,
                    currency: currency,
                    waproData: waproData,
                    symfoniaData: symfoniaData,
                    WL_DevCosts: WL_DevCosts,
                    doc_description: documentDescription || ""
                }
            }
        }).then(res => {
            if (res.success) {
                myFetch(`/files-to-verifi/update-ocr`, {
                    method: 'put', body: {
                        data: {
                            projectId: projectId,
                            ocrData: null,
                            fileId: id,
                            ftvId: ftvId,
                            currency: currency,
                            docType: 'EXPENSES',
                            ocrScreen: true,
                            expensesOcr: expenses
                        }
                    }
                }).then(res => {
                    if (res.success) {
                        dispatch({ type: FileConst.CHANGE_FILE_ACCEPTNCE_STEP, step: 2, projectId: projectId, fileId: id })
                        dispatch({ type: FileConst.CHANGE_FILE_TO_VERIFI_ACCEPTNCE_STEP, step: 2, expenseOcr: JSON.parse(res.ocrData), currency: res.currency, comment: res.comment, tags: res.tags, paymentMethod: res.paymentMethod, projectId: projectId, ftvId: ftvId })
                        if (nextDocExists) {
                            cb()
                        } else {
                            history.push(routes.panel.dashboard)
                        }
                        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                        socket.emit('submitOCR', id, projectId, fileName, (err) => {
                            if (err) {
                                console.log(err)
                                console.log('socket event submitOCR  wasn\'t broadcasted' + moment().format('HH:mm.ss.SSS'))
                            } else {
                                console.log('socket event submitOCR  was broadcasted' + moment().format('HH:mm.ss.SSS'))
                            }
                        })
                    } else {
                        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                        dispatch(AlertActions.warning(res.msg))
                    }
                }).catch(err => {
                    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                    handleError(err, dispatch, `/files-to-verifi/update-ocr`)
                })
            } else {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                dispatch(AlertActions.warning(res.msg, 5000, res.number))
            }
        }).catch(err => {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
            handleError(err, dispatch, `/docs/save-expenses`)
        })
    }
}

function saveExpenses(expenses, projectId, id, ftvId, currency, priority, tags, status, nextDocExists, waproData, symfoniaData, WL_DevCosts, documentDescription, cb) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/docs/save-expenses`, {
            method: 'post', body: {
                data: {
                    expenses: expenses,
                    fileId: id,
                    projectId: projectId,
                    currency: currency,
                    waproData: waproData,
                    symfoniaData: symfoniaData,
                    WL_DevCosts: WL_DevCosts,
                    doc_description: documentDescription || ""
                }
            }
        }).then(res => {
            if (res.success) {
                console.log('priority', priority)
                var firstExpense = expenses.find(x => x !== undefined)
                myFetch(`/files/update-ocr`, {
                    method: 'post', body: {
                        ocrData: [
                            {
                                name: "price",
                                text: firstExpense.price
                            },
                            {
                                name: "invoiceNumber",
                                text: firstExpense.docNumber
                            },
                            {
                                name: "issueDate",
                                text: firstExpense.issueDate
                            }
                        ],
                        id: id,
                        docType: 'EXPENSES',
                        priority: priority,
                        tags: tags || '',
                        linkedFiles: {}
                    }
                }).then(res => {
                    console.log(res)
                    var fileName = res.name
                    var filePath = res.path
                    dispatch(AlertActions.info("File status updated."))
                    myFetch(`/files-to-verifi/delete`, {
                        method: 'delete', body: {
                            ftvId: ftvId,
                            ocrData: null
                        }
                    }).then(resp => {
                        if (nextDocExists) {
                            cb()
                        } else {
                            history.push(routes.panel.dashboard)
                        }
                        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                        dispatch({ type: FileConst.CHANGE_FILE_STATUS, projectId: projectId, dbId: id, status: res.status, fileName: fileName, filePath: filePath, tags: tags })
                        dispatch({ type: ChatConst.CHANGE_FILE_STATUS_TO_ACCEPTED, fileId: id })

                        console.log('emited socket event saveOCR  ' + moment().format('HH:mm.ss.SSS'))
                        socket.emit('acceptOCR', id, projectId, fileName, (err) => {
                            if (err) {
                                console.log(err)
                                console.log('socket event acceptOCR  wasn\'t broadcasted' + moment().format('HH:mm.ss.SSS'))
                            } else {
                                console.log('socket event acceptOCR  was broadcasted' + moment().format('HH:mm.ss.SSS'))
                            }
                        })
                    }).catch(err => {
                        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                        handleError(err, dispatch, `/files-to-verifi/${ftvId}`)
                    })
                }).catch(err => {
                    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                    handleError(err, dispatch, `/files/update-ocr`)
                })
            } else {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                dispatch(AlertActions.warning(res.msg, 5000, res.number))
            }
        }).catch(err => {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
            handleError(err, dispatch, `/docs/save-expenses`)
        })
    }
}

function savePartialAgreement(agreementProps, projectId, id, ftvId, priority, tags, comment, fileName, nextDocExists, documentDescription, cb) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/files-to-verifi/update-ocr`, {
            method: 'put', body: {
                data: {
                    projectId: projectId,
                    ocrData: null,
                    fileId: id,
                    ftvId: ftvId,
                    priority: priority,
                    docType: 'AGREEMENT',
                    ocrScreen: true,
                    tags: tags || '',
                    comment: comment || '',
                    agreementProps: agreementProps,
                    doc_description: documentDescription

                }
            }
        }).then(res => {
            if (res.success) {
                dispatch({ type: FileConst.CHANGE_FILE_ACCEPTNCE_STEP, step: 2, projectId: projectId, fileId: id })
                dispatch({ type: FileConst.CHANGE_FILE_TO_VERIFI_ACCEPTNCE_STEP, step: 2, expensesOcr: JSON.parse(res.ocrData), currency: res.currency, comment: res.comment, tags: res.tags, paymentMethod: res.paymentMethod, projectId: projectId, ftvId: ftvId })
                if (nextDocExists) {
                    cb()
                } else {
                    history.push(routes.panel.dashboard)
                }
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                socket.emit('submitOCR', id, projectId, fileName, (err) => {
                    if (err) {
                        console.log(err)
                        console.log('socket event submitOCR  wasn\'t broadcasted' + moment().format('HH:mm.ss.SSS'))
                    } else {
                        console.log('socket event submitOCR  was broadcasted' + moment().format('HH:mm.ss.SSS'))
                    }
                })
            } else {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
            handleError(err, dispatch, `/files-to-verifi/update-ocr`)
        })
    }
}

function saveAgreement(agreementProps, projectId, id, ftvId, priority, tags, comment, fileName, nextDocExists, documentDescription, cb) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/agreements/save-agreement`, {
            method: 'post', body: {
                data: {
                    projectId: projectId,
                    fileId: id,
                    docType: 'AGREEMENT',
                    ocrScreen: true,
                    tags: tags || '',
                    comment: comment || '',
                    agreementProps: agreementProps,
                    doc_description: documentDescription
                }
            }
        }).then(res => {
            if (res.success) {
                myFetch(`/files/update-ocr`, {
                    method: 'post', body: {
                        ocrData: agreementProps,
                        id: id,
                        docType: 'AGREEMENT',
                        priority: priority,
                        tags: tags || '',
                        linkedFiles: {}
                    }
                }).then(res => {
                    console.log(res)
                    var fileName = res.name
                    var filePath = res.path
                    dispatch(AlertActions.info("File status updated."))
                    myFetch(`/files-to-verifi/delete`, {
                        method: 'delete', body: {
                            ftvId: ftvId,
                            ocrData: null
                        }
                    }).then(resp => {
                        if (nextDocExists) {
                            cb()
                        } else {
                            history.push(routes.panel.dashboard)
                        }
                        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                        dispatch({ type: FileConst.CHANGE_FILE_STATUS, projectId: projectId, dbId: id, status: res.status, fileName: fileName, filePath: filePath, tags: tags })
                        dispatch({ type: ChatConst.CHANGE_FILE_STATUS_TO_ACCEPTED, fileId: id })

                        console.log('emited socket event saveOCR  ' + moment().format('HH:mm.ss.SSS'))
                        socket.emit('acceptOCR', id, projectId, fileName, (err) => {
                            if (err) {
                                console.log(err)
                                console.log('socket event acceptOCR  wasn\'t broadcasted' + moment().format('HH:mm.ss.SSS'))
                            } else {
                                console.log('socket event acceptOCR  was broadcasted' + moment().format('HH:mm.ss.SSS'))
                            }
                        })
                    }).catch(err => {
                        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                        handleError(err, dispatch, `/files-to-verifi/${ftvId}`)
                    })
                }).catch(err => {
                    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                    handleError(err, dispatch, `/files/update-ocr`)
                })
            } else {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                dispatch(AlertActions.warning(res.msg, 5000, res.number))
            }
        }).catch(err => {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
            handleError(err, dispatch, `/docs/save-agreement`)
        })
    }
}

function savePartialIncome(incomeProps, projectId, id, ftvId, priority, tags, paymentMethod, comment, currency, fileName, nextDocExists, documentDescription, cb) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/files-to-verifi/update-ocr`, {
            method: 'put', body: {
                data: {
                    projectId: projectId,
                    ocrData: null,
                    fileId: id,
                    ftvId: ftvId,
                    priority: priority,
                    docType: 'AGREEMENT',
                    ocrScreen: true,
                    tags: tags || '',
                    comment: comment || '',
                    incomeProps: incomeProps,
                    currency: currency,
                    paymentMethod: paymentMethod,
                    doc_description: documentDescription
                }
            }
        }).then(res => {
            if (res.success) {
                dispatch({ type: FileConst.CHANGE_FILE_ACCEPTNCE_STEP, step: 2, projectId: projectId, fileId: id })
                dispatch({ type: FileConst.CHANGE_FILE_TO_VERIFI_ACCEPTNCE_STEP, step: 2, incomeProps: JSON.parse(res.ocrData), currency: res.currency, comment: res.comment, tags: res.tags, paymentMethod: res.paymentMethod, projectId: projectId, ftvId: ftvId })
                if (nextDocExists) {
                    cb()
                } else {
                    history.push(routes.panel.dashboard)
                }
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                socket.emit('submitOCR', id, projectId, fileName, (err) => {
                    if (err) {
                        console.log(err)
                        console.log('socket event submitOCR  wasn\'t broadcasted' + moment().format('HH:mm.ss.SSS'))
                    } else {
                        console.log('socket event submitOCR  was broadcasted' + moment().format('HH:mm.ss.SSS'))
                    }
                })
            } else {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
            handleError(err, dispatch, `/files-to-verifi/update-ocr`)
        })
    }
}

function saveIncome(incomeProps, projectId, id, ftvId, priority, tags, paymentMethod, comment, currency, fileName, nextDocExists, buyerCountryCode, documentDescription, cb) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/sale-invoices/save-income`, {
            method: 'post', body: {
                data: {
                    projectId: projectId,
                    fileId: id,
                    docType: 'INCOME',
                    ocrScreen: true,
                    tags: tags || '',
                    comment: comment || '',
                    incomeProps: incomeProps,
                    currency: currency,
                    paymentMethod: paymentMethod,
                    buyerCountryCode,
                    doc_description: documentDescription
                }
            }
        }).then(res => {
            if (res.success) {
                myFetch(`/files/update-ocr`, {
                    method: 'post', body: {
                        ocrData: incomeProps,
                        id: id,
                        docType: 'INCOME',
                        priority: priority,
                        tags: tags || '',
                        paymentMethod: paymentMethod,
                        linkedFiles: {}
                    }
                }).then(res => {
                    console.log(res)
                    var fileName = res.name
                    var filePath = res.path
                    dispatch(AlertActions.info("File status updated."))
                    myFetch(`/files-to-verifi/delete`, {
                        method: 'delete', body: {
                            ftvId: ftvId,
                            ocrData: null
                        }
                    }).then(resp => {
                        if (nextDocExists) {
                            cb()
                        } else {
                            history.push(routes.panel.dashboard)
                        }
                        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                        dispatch({ type: FileConst.CHANGE_FILE_STATUS, projectId: projectId, dbId: id, status: res.status, fileName: fileName, filePath: filePath, tags: tags })
                        dispatch({ type: ChatConst.CHANGE_FILE_STATUS_TO_ACCEPTED, fileId: id })

                        console.log('emited socket event saveOCR  ' + moment().format('HH:mm.ss.SSS'))
                        socket.emit('acceptOCR', id, projectId, fileName, (err) => {
                            if (err) {
                                console.log(err)
                                console.log('socket event acceptOCR  wasn\'t broadcasted' + moment().format('HH:mm.ss.SSS'))
                            } else {
                                console.log('socket event acceptOCR  was broadcasted' + moment().format('HH:mm.ss.SSS'))
                            }
                        })
                    }).catch(err => {
                        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                        handleError(err, dispatch, `/files-to-verifi/${ftvId}`)
                    })
                }).catch(err => {
                    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                    handleError(err, dispatch, `/files/update-ocr`)
                })
            } else {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                dispatch(AlertActions.warning(res.msg, 5000, res.number))
            }
        }).catch(err => {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
            handleError(err, dispatch, `/docs/save-agreement`)
        })
    }
}

function savePartialCorrespondence(correspondenceProps, projectId, id, ftvId, priority, tags, fileName, nextDocExists, documentDescription, cb) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/files-to-verifi/update-ocr`, {
            method: 'put', body: {
                data: {
                    projectId: projectId,
                    ocrData: null,
                    fileId: id,
                    ftvId: ftvId,
                    priority: priority,
                    docType: 'CORRESPONDENCE',
                    ocrScreen: true,
                    tags: tags || '',
                    correspondenceProps: correspondenceProps,
                    doc_description: documentDescription
                }
            }
        }).then(res => {
            if (res.success) {
                dispatch({ type: FileConst.CHANGE_FILE_ACCEPTNCE_STEP, step: 2, projectId: projectId, fileId: id })
                dispatch({ type: FileConst.CHANGE_FILE_TO_VERIFI_ACCEPTNCE_STEP, step: 2, expensesOcr: JSON.parse(res.ocrData), currency: res.currency, comment: res.comment, tags: res.tags, paymentMethod: res.paymentMethod, projectId: projectId, ftvId: ftvId })
                if (nextDocExists) {
                    cb()
                } else {
                    history.push(routes.panel.dashboard)
                }
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                socket.emit('submitOCR', id, projectId, fileName, (err) => {
                    if (err) {
                        console.log(err)
                        console.log('socket event submitOCR  wasn\'t broadcasted' + moment().format('HH:mm.ss.SSS'))
                    } else {
                        console.log('socket event submitOCR  was broadcasted' + moment().format('HH:mm.ss.SSS'))
                    }
                })
            } else {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
            handleError(err, dispatch, `/files-to-verifi/update-ocr`)
        })
    }
}

function saveCorrespondence(correspondenceProps, projectId, id, ftvId, priority, tags, fileName, nextDocExists, documentDescription, cb) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/correspondence/save-correspondence`, {
            method: 'post', body: {
                data: {
                    projectId: projectId,
                    fileId: id,
                    docType: 'CORRESPONDENCE',
                    ocrScreen: true,
                    tags: tags || '',
                    correspondenceProps: correspondenceProps,
                    doc_description: documentDescription
                }
            }
        }).then(res => {
            if (res.success) {
                myFetch(`/files/update-ocr-correspondence`, {
                    method: 'post', body: {
                        ocrData: correspondenceProps,
                        id: id,
                        priority: priority,
                        tags: tags || '',
                    }
                }).then(res => {
                    console.log(res)
                    var fileName = res.name
                    var filePath = res.path
                    dispatch(AlertActions.info("File status updated."))
                    myFetch(`/files-to-verifi/delete`, {
                        method: 'delete', body: {
                            ftvId: ftvId,
                            ocrData: null
                        }
                    }).then(resp => {
                        if (nextDocExists) {
                            cb()
                        } else {
                            history.push(routes.panel.dashboard)
                        }
                        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                        dispatch({ type: FileConst.CHANGE_FILE_STATUS, projectId: projectId, dbId: id, status: res.status, fileName: fileName, filePath: filePath, tags: tags })
                        dispatch({ type: ChatConst.CHANGE_FILE_STATUS_TO_ACCEPTED, fileId: id })

                        console.log('emited socket event saveOCR  ' + moment().format('HH:mm.ss.SSS'))
                        socket.emit('acceptOCR', id, projectId, fileName, (err) => {
                            if (err) {
                                console.log(err)
                                console.log('socket event acceptOCR  wasn\'t broadcasted' + moment().format('HH:mm.ss.SSS'))
                            } else {
                                console.log('socket event acceptOCR  was broadcasted' + moment().format('HH:mm.ss.SSS'))
                            }
                        })
                    }).catch(err => {
                        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                        handleError(err, dispatch, `/files-to-verifi/${ftvId}`)
                    })
                }).catch(err => {
                    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                    handleError(err, dispatch, `/files/update-ocr-correspondence`)
                })
            } else {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                dispatch(AlertActions.warning(res.msg, 5000, res.number))
            }
        }).catch(err => {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
            handleError(err, dispatch, `/docs/save-agreement`)
        })
    }
}

function savePartialCorporate(corporateProps, projectId, id, ftvId, priority, tags, fileName, nextDocExists, documentDescription, cb) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/files-to-verifi/update-ocr`, {
            method: 'put', body: {
                data: {
                    projectId: projectId,
                    ocrData: null,
                    fileId: id,
                    ftvId: ftvId,
                    priority: priority,
                    docType: 'CORPORATE',
                    ocrScreen: true,
                    tags: tags || '',
                    corporateProps: corporateProps,
                    doc_description: documentDescription
                }
            }
        }).then(res => {
            if (res.success) {
                dispatch({ type: FileConst.CHANGE_FILE_ACCEPTNCE_STEP, step: 2, projectId: projectId, fileId: id })
                dispatch({ type: FileConst.CHANGE_FILE_TO_VERIFI_ACCEPTNCE_STEP, step: 2, expensesOcr: JSON.parse(res.ocrData), currency: res.currency, comment: res.comment, tags: res.tags, paymentMethod: res.paymentMethod, projectId: projectId, ftvId: ftvId })
                if (nextDocExists) {
                    cb()
                } else {
                    history.push(routes.panel.dashboard)
                }
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                socket.emit('submitOCR', id, projectId, fileName, (err) => {
                    if (err) {
                        console.log(err)
                        console.log('socket event submitOCR  wasn\'t broadcasted' + moment().format('HH:mm.ss.SSS'))
                    } else {
                        console.log('socket event submitOCR  was broadcasted' + moment().format('HH:mm.ss.SSS'))
                    }
                })
            } else {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
            handleError(err, dispatch, `/files-to-verifi/update-ocr`)
        })
    }
}

function saveCorporate(corporateProps, projectId, id, ftvId, priority, tags, fileName, nextDocExists, documentDescription, cb) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/corporate/save-corporate`, {
            method: 'post', body: {
                data: {
                    projectId: projectId,
                    fileId: id,
                    docType: 'CORPORATE',
                    ocrScreen: true,
                    tags: tags || '',
                    corporateProps: corporateProps,
                    doc_description: documentDescription
                }
            }
        }).then(res => {
            if (res.success) {
                myFetch(`/files/update-ocr-corporate`, {
                    method: 'post', body: {
                        ocrData: corporateProps,
                        id: id,
                        priority: priority,
                        tags: tags || '',
                    }
                }).then(res => {
                    console.log(res)
                    var fileName = res.name
                    var filePath = res.path
                    dispatch(AlertActions.info("File status updated."))
                    myFetch(`/files-to-verifi/delete`, {
                        method: 'delete', body: {
                            ftvId: ftvId,
                            ocrData: null
                        }
                    }).then(resp => {
                        if (nextDocExists) {
                            cb()
                        } else {
                            history.push(routes.panel.dashboard)
                        }
                        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                        dispatch({ type: FileConst.CHANGE_FILE_STATUS, projectId: projectId, dbId: id, status: res.status, fileName: fileName, filePath: filePath, tags: tags })
                        dispatch({ type: ChatConst.CHANGE_FILE_STATUS_TO_ACCEPTED, fileId: id })

                        console.log('emited socket event saveOCR  ' + moment().format('HH:mm.ss.SSS'))
                        socket.emit('acceptOCR', id, projectId, fileName, (err) => {
                            if (err) {
                                console.log(err)
                                console.log('socket event acceptOCR  wasn\'t broadcasted' + moment().format('HH:mm.ss.SSS'))
                            } else {
                                console.log('socket event acceptOCR  was broadcasted' + moment().format('HH:mm.ss.SSS'))
                            }
                        })
                    }).catch(err => {
                        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                        handleError(err, dispatch, `/files-to-verifi/${ftvId}`)
                    })
                }).catch(err => {
                    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                    handleError(err, dispatch, `/files/update-ocr-corporate`)
                })
            } else {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                dispatch(AlertActions.warning(res.msg, 5000, res.number))
            }
        }).catch(err => {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
            handleError(err, dispatch, `/docs/save-agreement`)
        })
    }
}

function savePartialOther(otherProps, projectId, id, ftvId, priority, tags, fileName, nextDocExists, documentDescription, cb) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/files-to-verifi/update-ocr`, {
            method: 'put', body: {
                data: {
                    projectId: projectId,
                    ocrData: null,
                    fileId: id,
                    ftvId: ftvId,
                    priority: priority,
                    docType: 'OTHER',
                    ocrScreen: true,
                    tags: tags || '',
                    otherProps: otherProps,
                    doc_description: documentDescription
                }
            }
        }).then(res => {
            if (res.success) {
                dispatch({ type: FileConst.CHANGE_FILE_ACCEPTNCE_STEP, step: 2, projectId: projectId, fileId: id })
                dispatch({ type: FileConst.CHANGE_FILE_TO_VERIFI_ACCEPTNCE_STEP, step: 2, expensesOcr: JSON.parse(res.ocrData), currency: res.currency, comment: res.comment, tags: res.tags, paymentMethod: res.paymentMethod, projectId: projectId, ftvId: ftvId })
                if (nextDocExists) {
                    cb()
                } else {
                    history.push(routes.panel.dashboard)
                }
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                socket.emit('submitOCR', id, projectId, fileName, (err) => {
                    if (err) {
                        console.log(err)
                        console.log('socket event submitOCR  wasn\'t broadcasted' + moment().format('HH:mm.ss.SSS'))
                    } else {
                        console.log('socket event submitOCR  was broadcasted' + moment().format('HH:mm.ss.SSS'))
                    }
                })
            } else {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
            handleError(err, dispatch, `/files-to-verifi/update-ocr`)
        })
    }
}

function saveOther(otherProps, projectId, id, ftvId, priority, tags, fileName, nextDocExists, documentDescription, cb) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/other-docs/save-other`, {
            method: 'post', body: {
                data: {
                    projectId: projectId,
                    fileId: id,
                    docType: 'OTHER',
                    ocrScreen: true,
                    tags: tags || '',
                    otherProps: otherProps,
                    doc_description: documentDescription
                }
            }
        }).then(res => {
            if (res.success) {
                myFetch(`/files/update-ocr-other`, {
                    method: 'post', body: {
                        ocrData: otherProps,
                        id: id,
                        docType: 'OTHER',
                        priority: priority,
                        tags: tags || '',
                    }
                }).then(res => {
                    console.log(res)
                    var fileName = res.name
                    var filePath = res.path
                    dispatch(AlertActions.info("File status updated."))
                    myFetch(`/files-to-verifi/delete`, {
                        method: 'delete', body: {
                            ftvId: ftvId,
                            ocrData: null
                        }
                    }).then(resp => {
                        if (nextDocExists) {
                            cb()
                        } else {
                            history.push(routes.panel.dashboard)
                        }
                        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                        dispatch({ type: FileConst.CHANGE_FILE_STATUS, projectId: projectId, dbId: id, status: res.status, fileName: fileName, filePath: filePath, tags: tags })
                        dispatch({ type: ChatConst.CHANGE_FILE_STATUS_TO_ACCEPTED, fileId: id })

                        console.log('emited socket event saveOCR  ' + moment().format('HH:mm.ss.SSS'))
                        socket.emit('acceptOCR', id, projectId, fileName, (err) => {
                            if (err) {
                                console.log(err)
                                console.log('socket event acceptOCR  wasn\'t broadcasted' + moment().format('HH:mm.ss.SSS'))
                            } else {
                                console.log('socket event acceptOCR  was broadcasted' + moment().format('HH:mm.ss.SSS'))
                            }
                        })
                    }).catch(err => {
                        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                        handleError(err, dispatch, `/files-to-verifi/${ftvId}`)
                    })
                }).catch(err => {
                    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                    handleError(err, dispatch, `/files/update-ocr-other`)
                })
            } else {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                dispatch(AlertActions.warning(res.msg, 5000, res.number))
            }
        }).catch(err => {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
            handleError(err, dispatch, `/docs/save-agreement`)
        })
    }
}

function savePartialProtocol(protocolProps, projectId, id, ftvId, priority, tags, fileName, nextDocExists, documentDescription, cb) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/files-to-verifi/update-ocr`, {
            method: 'put', body: {
                data: {
                    projectId: projectId,
                    ocrData: null,
                    fileId: id,
                    ftvId: ftvId,
                    priority: priority,
                    docType: 'PROTOCOL',
                    ocrScreen: true,
                    tags: tags || '',
                    protocolProps: protocolProps,
                    doc_description: documentDescription
                }
            }
        }).then(res => {
            if (res.success) {
                dispatch({ type: FileConst.CHANGE_FILE_ACCEPTNCE_STEP, step: 2, projectId: projectId, fileId: id })
                dispatch({ type: FileConst.CHANGE_FILE_TO_VERIFI_ACCEPTNCE_STEP, step: 2, expensesOcr: JSON.parse(res.ocrData), currency: res.currency, comment: res.comment, tags: res.tags, paymentMethod: res.paymentMethod, projectId: projectId, ftvId: ftvId })
                if (nextDocExists) {
                    cb()
                } else {
                    history.push(routes.panel.dashboard)
                }
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                socket.emit('submitOCR', id, projectId, fileName, (err) => {
                    if (err) {
                        console.log(err)
                        console.log('socket event submitOCR  wasn\'t broadcasted' + moment().format('HH:mm.ss.SSS'))
                    } else {
                        console.log('socket event submitOCR  was broadcasted' + moment().format('HH:mm.ss.SSS'))
                    }
                })
            } else {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
            handleError(err, dispatch, `/files-to-verifi/update-ocr`)
        })
    }
}

function saveProtocol(protocolProps, projectId, id, ftvId, priority, tags, fileName, nextDocExists, documentDescription, cb) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/protocol-docs/save`, {
            method: 'post', body: {
                data: {
                    projectId: projectId,
                    fileId: id,
                    docType: 'PROTOCOL',
                    ocrScreen: true,
                    tags: tags || '',
                    protocolProps: protocolProps,
                    doc_description: documentDescription
                }
            }
        }).then(res => {
            if (res.success) {
                myFetch(`/files/update-ocr-protocol`, {
                    method: 'post', body: {
                        ocrData: protocolProps,
                        id: id,
                        docType: 'PROTOCOL',
                        priority: priority,
                        tags: tags || '',
                    }
                }).then(res => {
                    console.log(res)
                    var fileName = res.name
                    var filePath = res.path
                    dispatch(AlertActions.info("File status updated."))
                    myFetch(`/files-to-verifi/delete`, {
                        method: 'delete', body: {
                            ftvId: ftvId,
                            ocrData: null
                        }
                    }).then(resp => {
                        if (nextDocExists) {
                            cb()
                        } else {
                            history.push(routes.panel.dashboard)
                        }
                        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                        dispatch({ type: FileConst.CHANGE_FILE_STATUS, projectId: projectId, dbId: id, status: res.status, fileName: fileName, filePath: filePath, tags: tags })
                        dispatch({ type: ChatConst.CHANGE_FILE_STATUS_TO_ACCEPTED, fileId: id })

                        console.log('emited socket event saveOCR  ' + moment().format('HH:mm.ss.SSS'))
                        socket.emit('acceptOCR', id, projectId, fileName, (err) => {
                            if (err) {
                                console.log(err)
                                console.log('socket event acceptOCR  wasn\'t broadcasted' + moment().format('HH:mm.ss.SSS'))
                            } else {
                                console.log('socket event acceptOCR  was broadcasted' + moment().format('HH:mm.ss.SSS'))
                            }
                        })
                    }).catch(err => {
                        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                        handleError(err, dispatch, `/files-to-verifi/${ftvId}`)
                    })
                }).catch(err => {
                    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                    handleError(err, dispatch, `/files/update-ocr-protocol`)
                })
            } else {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                dispatch(AlertActions.warning(res.msg, 5000, res.number))
            }
        }).catch(err => {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
            handleError(err, dispatch, `/protocol-docs/save`)
        })
    }
}

function changeFileStatusToNotAccepted(data, showRefreshOcrPopup, userName) {
    var filter = {
        projectId: data.projectId,
        fileId: data.fileId,
        language: localStorage.getItem('language')
    }
    return dispatch => {
        myFetch(`/files/my-files/?data=${JSON.stringify(filter)}`, { method: 'get' }).then(result => {
            if (result.success) {
                myFetch(`/files-to-verifi/my-files/?projectId=${data.projectId}&fileId=${data.fileId}`, { method: 'get' })
                    .then(res => {
                        if (res.success) {
                            dispatch({ type: FileConst.CHANGE_FILE_STATUS_TO_NOT_ACCEPTED, fileToVerifi: res.files.find(x => x !== undefined), file: result.files.find(x => x !== undefined), projectId: data.projectId, dbId: data.fileId, showRefreshOcrPopup: showRefreshOcrPopup, refreshOcrPopupUserName: userName })
                        } else {
                            dispatch(AlertActions.warning(res.msg))
                            if (showRefreshOcrPopup) {
                                dispatch({ type: FileConst.SHOW_REFRESH_OCR_POPUP_WITH_ERROR, refreshOcrPopupUserName: userName })
                            }
                        }
                    }).catch(err => {
                        dispatch({ type: FileConst.SHOW_REFRESH_OCR_POPUP_WITH_ERROR, refreshOcrPopupUserName: userName })
                        handleError(err, dispatch, `/files-to-verifi/my-files/?projectId=${data.projectId}&fileId=${data.fileId}`)
                    })
            } else {
                dispatch({ type: FileConst.SHOW_REFRESH_OCR_POPUP_WITH_ERROR, refreshOcrPopupUserName: userName })
                dispatch(AlertActions.warning(result.msg))
            }
        }).catch(err => {
            dispatch({ type: FileConst.SHOW_REFRESH_OCR_POPUP_WITH_ERROR, refreshOcrPopupUserName: userName })
            handleError(err, dispatch, `/files/my-files/?data=${JSON.stringify(filter)}`)
        })
    }
}

function changeFileStatusToAccepted(data, showAcceptedOcrPopup, userName) {
    var filter = {
        projectId: data.projectId,
        fileId: data.fileId,
        language: localStorage.getItem('language')
    }
    return dispatch => {
        myFetch(`/files/my-files/?data=${JSON.stringify(filter)}`, { method: 'get' }).then(result => {
            if (result.success) {
                dispatch({ type: FileConst.CHANGE_FILE_STATUS_TO_ACCEPTED, file: result.files.find(x => x !== undefined), projectId: data.projectId, dbId: data.fileId, showAcceptedOcrPopup: showAcceptedOcrPopup, refreshOcrPopupUserName: userName })
                dispatch({ type: ChatConst.CHANGE_FILE_STATUS_TO_ACCEPTED, fileId: data.fileId })

                myFetch(`/projects/get-costs/${data.projectId}`, { methid: 'get' }).then(res => {
                    if (res.success) {
                        dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: data.projectId, costs: res.costs })
                    } else {
                        dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: data.projectId, costs: {} })
                    }
                }).catch(err => {
                    dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: data.projectId, costs: {} })
                    handleError(err, dispatch, `/projects/get-costs/${data.projectId}`)
                })
            } else {
                dispatch(AlertActions.warning(result.msg))
                dispatch({ type: FileConst.SHOW_ACCEPTED_OCR_POPUP_WITH_ERROR, refreshOcrPopupUserName: userName })

                myFetch(`/projects/get-costs/${data.projectId}`, { methid: 'get' }).then(res => {
                    if (res.success) {
                        dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: data.projectId, costs: res.costs })
                    } else {
                        dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: data.projectId, costs: {} })
                    }
                }).catch(err => {
                    dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: data.projectId, costs: {} })
                    handleError(err, dispatch, `/projects/get-costs/${data.projectId}`)
                })
            }
        }).catch(err => {
            handleError(err, dispatch, `/files/my-files/?data=${JSON.stringify(filter)}`)
            dispatch({ type: FileConst.SHOW_ACCEPTED_OCR_POPUP_WITH_ERROR, refreshOcrPopupUserName: userName })

            myFetch(`/projects/get-costs/${data.projectId}`, { methid: 'get' }).then(res => {
                if (res.success) {
                    dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: data.projectId, costs: res.costs })
                } else {
                    dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: data.projectId, costs: {} })
                }
            }).catch(err => {
                dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: data.projectId, costs: {} })
                handleError(err, dispatch, `/projects/get-costs/${data.projectId}`)
            })
        })
    }
}

function deleteInvoice(projectId, id, status, fileName, ifReload, userName, cb) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/files/delete`, {
            method: 'post', body: {
                fileId: id
            }
        }).then(res => {
            if (res.success) {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                dispatch({ type: ReportsConst.DELETE_FILE_FROM_REPORT, id: id })
                dispatch({ type: FileConst.DELETE_FILE, projectId: projectId, id: id, file: res.updatedFile, deletedBy: userName })
                if (status === 'NOT_VERIFIED' || status === 'NEW' || status === 'IN_PROGRESS') {
                    dispatch({ type: FileConst.DELETE_UNVERIFIED_FILE, projectId: projectId, id: id })
                }
                if (cb && cb instanceof Function) {
                    cb()
                } else {
                    if (ifReload) {
                        history.push(routes.panel.dashboard)
                    }
                }
                if (socket) {
                    socket.emit('moveFileToTrash', id, projectId, status, fileName, (err) => {
                        if (err) {
                            console.log(err)
                        }
                    })
                }
                // myFetch(`/chat-notifications/delete-notification`, {
                //     method: 'put', body: {
                //         fileId: id
                //     }
                // }).then(res => {
                //     if (res.success) {
                //         dispatch({ type: ChatConst.DELETE_NOTIFICATION, fileId: id })
                //     }
                // }).catch(err => {
                //     handleError(err, dispatch, `/chat-notifications/delete-notification`)
                // })
            }
        }).catch(err => {
            handleError(err, dispatch, `/files/delete`)
        })
    }
}

function moveDocumentToTrash(projectId, fileId, status, fileName, userName, projectCode) {
    return dispatch => {
        dispatch({ type: FileConst.DELETE_FILE, projectId: projectId, id: fileId, file: { deleted_date: moment(), path: projectCode + '/deleted/' + fileName }, deletedBy: userName })
        dispatch({ type: ReportsConst.DELETE_FILE_FROM_REPORT, id: fileId })
        if (status === 'NOT_VERIFIED' || status === 'NEW' || status === 'IN_PROGRESS') {
            dispatch({ type: FileConst.DELETE_UNVERIFIED_FILE, projectId: projectId, id: fileId })
        }
    }
}

function restoreDocumentFromTrash(projectId, fileId, status, fileName, userName, projectCode) {
    return dispatch => {
        var filePath = projectCode + '/'
        if (status === 'NOT_VERIFIED' || status === 'NEW') {
            filePath += 'in progess/' + fileName
        } else {
            if (/^[0-9]{4}-[0-9]{2}/.test(fileName)) {
                filePath += fileName.substring(0, 7) + '/' + fileName
            } else {
                filePath += 'in progess/' + fileName
            }
        }
        dispatch({ type: FileConst.RESTORE_DOCUMENT, projectId: projectId, id: fileId, file: { path: filePath } })
        if (status === 'NOT_VERIFIED' || status === 'NEW' || status === 'IN_PROGRESS') {
            myFetch(`/files-to-verifi/my-files/?projectId=${projectId}&fileId=${fileId}`, { method: 'get' })
                .then(res => {
                    if (res.success) {
                        dispatch({ type: FileConst.GET_FILES_TO_VERIFI, files: res.files, projectId: projectId })
                    } else {
                        dispatch(AlertActions.warning(res.msg))
                    }
                }).catch(err => {
                    handleError(err, dispatch, `/files-to-verifi/my-files/?projectId=${projectId}&fileId=${fileId}`)
                })
        }
    }
}

function deleteInvoiceFromRedux(data) {
    return dispatch => {
        dispatch({ type: FileConst.DELETE_FILE_PERMANENT, projectId: data.projectId, id: data.fileId })
        if (data.fileStep === 'NOT_VERIFIED' || data.fileStep === 'NEW' || data.fileStep === 'IN_PROGRESS') {
            dispatch({ type: FileConst.DELETE_UNVERIFIED_FILE, projectId: data.projectId, id: data.fileId })
        }
    }
}

function permanentDeleteInvoice(projectId, id, status, fileName, ifReload, userName) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/files/delete-permanent`, {
            method: 'post', body: {
                fileId: id
            }
        }).then(res => {
            if (res.success) {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                dispatch({ type: FileConst.DELETE_FILE_PERMANENT, projectId: projectId, id: id, file: res.file })
                if (status === 'NOT_VERIFIED' || status === 'NEW' || status === 'IN_PROGRESS') {
                    dispatch({ type: FileConst.DELETE_UNVERIFIED_FILE, projectId: projectId, id: id })
                }
                if (ifReload) {
                    history.push(routes.panel.dashboard)
                }
                console.log('emited socket event delete file ' + moment().format('HH:mm.ss.SSS'))
                socket.emit('deleteFilePermanent', id, projectId, status, fileName, (err) => {
                    if (err) {
                        console.log(err)
                        console.log('socket event deleteFile wasn\'t broadcasted ' + moment().format('HH:mm.ss.SSS'))
                    } else {
                        console.log('socket event deleteFile was broadcasted ' + moment().format('HH:mm.ss.SSS'))
                    }
                })
            }
        }).catch(err => {
            handleError(err, dispatch, `/files/delete-permanent`)
        })
    }
}

function restoreDocument(id, status, fileName, projectId) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/files/restore-document`, {
            method: 'post', body: {
                fileId: id
            }
        }).then(res => {
            if (res.success) {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                dispatch({ type: FileConst.RESTORE_DOCUMENT, projectId: projectId, id: id, file: res.updatedFile })
                if (status === 'NOT_VERIFIED' || status === 'NEW' || status === 'IN_PROGRESS') {
                    myFetch(`/files-to-verifi/my-files/?projectId=${projectId}&fileId=${id}`, { method: 'get' })
                        .then(res => {
                            if (res.success) {
                                dispatch({ type: FileConst.GET_FILES_TO_VERIFI, files: res.files, projectId: projectId })
                            } else {
                                dispatch(AlertActions.warning(res.msg))
                            }
                        }).catch(err => {
                            handleError(err, dispatch, `/files-to-verifi/my-files/?projectId=${projectId}&fileId=${id}`)
                        })
                }

                if (socket) {
                    console.log('emited socket event restoreDocument  ' + moment().format('HH:mm.ss.SSS'))
                    socket.emit('restoreDocument', id, projectId, status, fileName, (err) => {
                        if (err) {
                            console.log(err)
                            console.log('socket event restoreDocument wasn\'t broadcasted ' + moment().format('HH:mm.ss.SSS'))
                        } else {
                            console.log('socket event restoreDocument was broadcasted ' + moment().format('HH:mm.ss.SSS'))
                        }
                    })
                }
            }
        }).catch(err => {
            handleError(err, dispatch, `/files/restore-document`)
        })
    }
}

function confirmDocTypeChange(id, projectId, docType, cb) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/files/change-saved-document-type`, {
            method: 'post', body: {
                fileId: id,
                projectId: projectId,
                currentDocType: docType
            }
        }).then(res => {
            if (res.success) {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                dispatch({ type: FileConst.DELETE_FILE_PERMANENT, projectId: projectId, id: id })
                cb(res.fileId)
                dispatch(AlertActions.info("Everything went smooth and the document is awaiting re-approval"))
            } else {
                cb(null)
            }
        }).catch(err => {
            cb(null)
            handleError(err, dispatch, `/files/change-saved-document-type`)
        })
    }
}

function markFileAsPaid(data) {
    return dispatch => {
        dispatch({ type: ReportsConst.MARK_DOC_AS_PAID, id: data.docId, fileId: data.fileId, mark: data.mark, method: 'transfer' })
        dispatch({ type: FileConst.MARK_FILE_AS_PAID, id: data.fileId, projectId: data.projectId, mark: data.mark })

        myFetch(`/projects/get-costs/${data.projectId}`, { methid: 'get' }).then(res => {
            if (res.success) {
                dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: data.projectId, costs: res.costs })
            } else {
                dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: data.projectId, costs: {} })
            }
        }).catch(err => {
            dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: data.projectId, costs: {} })
            handleError(err, dispatch, `/projects/get-costs/${data.projectId}`)
        })

    }
}

function markAdditionalAccepted(data) {
    return dispatch => {
        dispatch({ type: ReportsConst.DOC_ADDITIONAL_ACCEPTANCE, id: data.docId, projectId: data.projectId, userId: data.senderId, accept: true })

        var fileSearch = {
            projectId: data.projectId,
            fileId: data.fileId,
            language: localStorage.getItem('language')
        }

        myFetch(`/files/my-files/?data=${JSON.stringify(fileSearch)}`, { method: 'get' }).then(result => {
            if (result.success) {
                dispatch({ type: FileConst.GET_FILES, files: result.files, projectId: data.projectId, currentDir: '' })
            } else {
                dispatch(AlertActions.warning(result.msg))
            }
        }).catch(err => {
            handleError(err, dispatch, `/files/my-files/?data=${JSON.stringify(fileSearch)}`)
        })
    }
}

function updateKsefStatusFromCron(data) {
    return dispatch => {
        dispatch({ type: FileConst.UPDATE_SALE_INVOICE_KSEF_STATUS, id: data.invoiceId, companyId: data.companyId, ksefNumber: data.ksefNumber, ksefStatus: data.ksefStatus, month: data.invoiceMonth })
    }
}

function switchSortingFilterVerifiFiles(project, name) {
    return dispatch => {
        dispatch({ type: FileConst.SORT_FIlES_TO_VERIFI, projectId: project, name: name })
    }
}

function setPendingFilesSearchField(value, projectId) {
    return dispatch => {
        dispatch({ type: FileConst.SEARCH_FILES_TO_VERIFY, projectId: projectId, value: value })
    }
}

function setPendingFilesFilter(value, projectId) {
    return dispatch => {
        dispatch({ type: FileConst.FILTER_FILES_TO_VERIFY, projectId: projectId, value: value })
    }
}

function switchSortingFilterUploadedFiles(project, name) {
    return dispatch => {
        dispatch({ type: FileConst.SORT_UPLOADED_FIlES, projectId: project, name: name })
    }
}

function generateOcr(image, lang, file, prop, box, title, projectId, ocrMethod, cb) {
    return dispatch => {
        dispatch({ type: FileConst.MARK_AREA_PROCESSING, prop: prop })
        myFetch(`/files/ocr-area`, {
            method: 'post', body: {
                image: image,
                lang: lang,
                areaName: prop,
                fileId: file,
                projectId: projectId,
            }
        }).then(resp => {
            if (resp.success) {
                cb(resp.ocrData.text.trim(), resp.lastTags)
                if (!['devExTransactionDescription', 'doc_description'].includes(prop)) {
                    if (ocrMethod === 'manual') {
                        dispatch({ type: FileConst.CHANGE_OCR_VALUE, file: file, prop: prop, value: resp.ocrData.text.trim(), ocrType: ocrMethod })
                        dispatch({ type: FileConst.MARK_AREA_PROCESSED, file: file, prop: prop, hasText: resp.ocrData.text ? true : false })
                    } else {
                        dispatch({
                            type: FileConst.OCR_FOR_AREA,
                            file: file,
                            prop: prop,
                            text: resp.ocrData.text.trim(),
                            offset: resp.ocrData.offset,
                            possition: box,
                            title: title,
                            language: lang,
                            companyName: resp.companyName,
                            companyCode: resp.companyCode,
                            sellerAddress: resp.sellerAddress,
                            lastSellerAccount: resp.lastSellerAccount,
                            lastTags: resp.lastTags,
                            newCounterparty: resp.newCounterparty
                        })
                        if (resp.newOcr) {
                            dispatch({ type: FileConst.LOAD_NEW_OCR, file: file, ocr: resp.newOcr })
                        }
                    }
                }
            } else {
                cb(false)
                console.log(resp)
            }
        }).catch(err => {
            cb(false)
            dispatch({ type: FileConst.MARK_AREA_PROCESSED, prop: prop, hasText: false })
            handleError(err, dispatch, `/files/ocr-area`)
        })
    }
}

function generateOcrForExpenses(image, prop, cb) {
    return dispatch => {
        dispatch({ type: FileConst.MARK_AREA_PROCESSING, prop: prop })
        myFetch(`/files/ocr-area-expenses`, {
            method: 'post', body: {
                image: image,
                areaName: prop
            }
        }).then(res => {
            dispatch({ type: FileConst.MARK_AREA_PROCESSED, prop: prop, hasText: res.text ? true : false })
            if (res.success) {
                cb(res.text)
            } else {
                cb('')
                console.log(res)
            }
        }).catch(err => {
            cb('')
            dispatch({ type: FileConst.MARK_AREA_PROCESSED, prop: prop, hasText: false })
            handleError(err, dispatch, `/files/ocr-area-expenses`)
        })
    }
}

function changeOCRData(file, prop, value, type, docType, possition) {
    console.log('CHANGEOCRDATA W FILE ACTION', file, prop, value, type, docType, possition)
    return dispatch => {
        dispatch({ type: FileConst.CHANGE_OCR_VALUE, file: file, prop: prop, value: value, ocrType: type, docType: docType, possition: possition })
    }
}

function clearDataReadWithOcrFrame() {
    return dispatch => {
        dispatch({ type: FileConst.CLEAR_DATA_READ_BY_OCR_FRAME })
    }
}

function setNewCounterparty(fromNewCounterparty, fileId) {
    return dispatch => {
        dispatch({ type: FileConst.SET_OCR_NEW_COUNTERPARTY, file: fileId, fromNewCounterparty: fromNewCounterparty })
    }
}

function loadGeneratedOCR(project, file) {
    return dispatch => {
        dispatch({ type: FileConst.LOAD_GENERATED_OCR, project: project, file: file })
    }
}

function closeExistingFileModal() {
    return dispatch => {
        dispatch({ type: FileConst.CLOSE_EXISTING_INVOICE_MODAL })
    }
}

function makeEditedDocBackup() {
    return dispatch => {
        dispatch({ type: FileConst.MAKE_DOC_BACKUP })
    }
}

function cancelDocEdit() {
    return dispatch => {
        dispatch({ type: FileConst.LOAD_DOC_BUCKUP })
    }
}

function saveDocChanges(data, cb) {
    console.log('SPRAWDZAM JAKIE DANE IDA PO zaspisie COSTOW', data)
    return dispatch => {
        if (data.docType === 'COSTS' || data.docType === 'EXPENSES_REPORT' || data.docType === 'MAG') {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
            console.log("save ocr was used")
            var filter = {
                invoiceNumber: data.invoiceNumber,
                docType: data.docType,
                seller: data.sellerVatId,
                projectId: data.projectId,
                accountNumber: data.accountNumber,
                amountToPay: data.price,
                dueDate: data.dueDate,
                paymentMethod: '',
                issueDate: data.issueDate,
            }
            myFetch(`/docs/check-invoice-number/?filter=${encodeURIComponent(JSON.stringify(filter))}`, { method: 'get' }).then(res => {
                myFetch(`/docs/update-record`, {
                    method: 'put', body: {
                        data: data,
                    }
                }).then(res => {
                    cb()
                    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                    if (res.success) {
                        dispatch(AlertActions.info("Document data was updated."))
                        dispatch({
                            type: FileConst.UPDATE_PREVIEV_DATA,
                            projectId: data.projectId,
                            file: {
                                counterparty: data.counterpartyName,
                                issue_date: data.issueDate ? moment(data.issueDate).format('YYYY-MM-DD') : '',
                                amount_to_pay: data.price ? `${parseFloat(data.price).toFixed(2)} ${data.currency}` : '',
                                due_date: data.dueDate ? moment(data.dueDate).format('YYYY-MM-DD') : '',
                                ...res.file
                            },
                        })


                        dispatch({
                            type: ReportsConst.UPDATE_DOWNLOADED_DOC_DATA,
                            docId: res.doc.id,
                            seller: res.doc.sender ? res.doc.sender.name : '',
                            sellerVatId: res.doc.sender ? res.doc.sender.vat_id : '',
                            sellerAddress: res.doc.sender ? res.doc.sender.address : '',
                            sellerCountry: res.doc.seller_country_code,
                            price: res.doc.price,
                            netto: res.doc.netto_number,
                            tax: res.doc.tax_number,
                            currency: res.doc.currency,
                            dueDate: res.doc.due_date_ts ? moment(res.doc.due_date_ts).format('YYYY-MM-DD') : '',
                            issueDate: moment(res.doc.date).format('YYYY-MM-DD'),
                            comment: res.doc.comment,
                            docType: 'DOC',
                            accountNumber: res.doc.bic,
                            swift: res.doc.seller_swift,
                            tags: res.doc.tags,
                            waproData: JSON.parse(res.doc.wapro_data || "{}")
                        })
                    } else {
                        dispatch({ type: FileConst.LOAD_DOC_BUCKUP })
                        dispatch(AlertActions.warning(res.msg))
                    }
                }).catch(err => {
                    dispatch({ type: FileConst.LOAD_DOC_BUCKUP })
                    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                    cb()
                    handleError(err, dispatch, `/docs/update-record`)
                })
            }).catch(err => {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                cb()
                handleError(err, dispatch, `/docs/check-invoice-number/?filter=${encodeURIComponent(JSON.stringify(filter))}`)
            })
        } else if (data.docType === 'EXPENSES') {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
            myFetch(`/docs/update-expenses`, {
                method: 'put', body: {
                    expenses: data.docs,
                }
            }).then(res => {
                cb()
                dispatch({ type: FileConst.LOADING_SCREEN, status: false })
                if (res.success) {
                    dispatch(AlertActions.info("Expenses updated successfully"))
                    dispatch({
                        type: FileConst.UPDATE_PREVIEV_DATA,
                        projectId: data.projectId,
                        file: res.file || null,
                    })
                    dispatch({ type: ReportsConst.UPDATE_DOWNLOADED_DOC_DATA, docType: 'EXPENSES', docs: res.updatedExpenses })
                } else {
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                cb()
                handleError(err, dispatch, `/docs/update-expenses`)
            })
        } else if (data.docType === 'SALE_INVOICE') {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
            console.log("save ocr was used")
            var filter = {
                invoiceNumber: data.invoiceNumber,
                docType: data.docType,
                seller: data.sellerVatId,
                projectId: data.projectId,
                accountNumber: data.accountNumber,
                amountToPay: data.price,
                dueDate: data.dueDate,
                paymentMethod: '',
                issueDate: data.issueDate,
            }
            myFetch(`/sale-invoices/check-invoice-number/?filter=${encodeURIComponent(JSON.stringify(filter))}`, { method: 'get' }).then(res => {
                myFetch(`/sale-invoices/update-record`, {
                    method: 'put', body: {
                        data: data,
                    }
                }).then(res => {
                    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                    cb()
                    if (res.success) {
                        dispatch(AlertActions.info("Document data was updated."))
                        dispatch({
                            type: FileConst.UPDATE_PREVIEV_DATA,
                            projectId: data.projectId,
                            file: res.file,
                        })

                        if (res.loadNewPdf) {
                            myFetch(`/files/download?projectId=${data.projectId}&fileId=${data.id}`, { method: 'get' }).then(result => {
                                if (result.success) {
                                    dispatch({
                                        type: FileConst.UPDATE_PREVIEV_FILE,
                                        data: result.data
                                    })
                                }
                            })
                        }
                    } else {
                        dispatch({ type: FileConst.LOAD_DOC_BUCKUP })
                        dispatch(AlertActions.warning(res.msg))
                    }
                }).catch(err => {
                    dispatch({ type: FileConst.LOAD_DOC_BUCKUP })
                    dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                    cb()
                    handleError(err, dispatch, `/sale-invoices/update-record`)
                })
            }).catch(err => {
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                cb()
                handleError(err, dispatch, `/sale-invoices/check-invoice-number/?filter=${encodeURIComponent(JSON.stringify(filter))}`)
            })
        } else if (data.docType === 'AGREEMENT') {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
            console.log("save ocr was used")
            myFetch(`/agreements/update-record`, {
                method: 'put', body: {
                    data: data,
                }
            }).then(res => {
                cb()
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                if (res.success) {
                    dispatch(AlertActions.info("Document data was updated."))
                    dispatch({
                        type: FileConst.UPDATE_PREVIEV_DATA,
                        projectId: data.projectId,
                        file: res.file,
                    })
                    dispatch({
                        type: ReportsConst.UPDATE_DOWNLOADED_DOC_DATA,
                        docId: res.doc.id,
                        counterparty: res.doc.counterparty ? res.doc.counterparty.name : '',
                        issueDate: moment(res.doc.agreement_date).format('YYYY-MM-DD'),
                        comment: res.doc.description,
                        documentNumber: res.doc.number,
                        docType: data.docType,
                        tags: res.doc.tags
                    })
                } else {
                    dispatch({ type: FileConst.LOAD_DOC_BUCKUP })
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                dispatch({ type: FileConst.LOAD_DOC_BUCKUP })
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                cb()
                handleError(err, dispatch, `/sale-invoices/update-record`)
            })
        } else if (data.docType === 'CORRESPONDENCE') {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
            console.log("save ocr was used")
            myFetch(`/correspondence/update-record`, {
                method: 'put', body: {
                    data: data,
                }
            }).then(res => {
                cb()
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                if (res.success) {
                    dispatch(AlertActions.info("Document data was updated."))
                    dispatch({
                        type: FileConst.UPDATE_PREVIEV_DATA,
                        projectId: data.projectId,
                        file: res.file,
                    })
                    dispatch({
                        type: ReportsConst.UPDATE_DOWNLOADED_DOC_DATA,
                        docId: res.doc.id,
                        counterparty: res.doc.counterparty ? res.doc.counterparty.name : '',
                        issueDate: moment(res.doc.date).format('YYYY-MM-DD'),
                        comment: res.doc.description,
                        docType: data.docType,
                        tags: res.doc.tags
                    })
                } else {
                    dispatch({ type: FileConst.LOAD_DOC_BUCKUP })
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                dispatch({ type: FileConst.LOAD_DOC_BUCKUP })
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                cb()
                handleError(err, dispatch, `/sale-invoices/update-record`)
            })
        } else if (data.docType === 'CORPORATE') {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
            console.log("save ocr was used")
            myFetch(`/corporate/update-record`, {
                method: 'put', body: {
                    data: data,
                }
            }).then(res => {
                cb()
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                if (res.success) {
                    dispatch(AlertActions.info("Document data was updated."))
                    dispatch({
                        type: FileConst.UPDATE_PREVIEV_DATA,
                        projectId: data.projectId,
                        file: res.file,
                    })
                    dispatch({
                        type: ReportsConst.UPDATE_DOWNLOADED_DOC_DATA,
                        docId: res.doc.id,
                        issueDate: moment(res.doc.date).format('YYYY-MM-DD'),
                        comment: res.doc.description,
                        docType: data.docType,
                        tags: res.doc.tags
                    })
                } else {
                    dispatch({ type: FileConst.LOAD_DOC_BUCKUP })
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                dispatch({ type: FileConst.LOAD_DOC_BUCKUP })
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                cb()
                handleError(err, dispatch, `/sale-invoices/update-record`)
            })
        } else if (data.docType === 'OTHER') {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
            console.log("save ocr was used")
            myFetch(`/other-docs/update-record`, {
                method: 'put', body: {
                    data: data,
                }
            }).then(res => {
                cb()
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                if (res.success) {
                    dispatch(AlertActions.info("Document data was updated."))
                    dispatch({
                        type: FileConst.UPDATE_PREVIEV_DATA,
                        projectId: data.projectId,
                        file: res.file,
                    })
                    dispatch({
                        type: ReportsConst.UPDATE_DOWNLOADED_DOC_DATA,
                        docId: res.doc.id,
                        counterparty: res.doc.counterparty ? res.doc.counterparty.name : '',
                        issueDate: moment(res.doc.date).format('YYYY-MM-DD'),
                        comment: res.doc.description,
                        docType: data.docType,
                        tags: res.doc.tags
                    })
                } else {
                    dispatch({ type: FileConst.LOAD_DOC_BUCKUP })
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                dispatch({ type: FileConst.LOAD_DOC_BUCKUP })
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                cb()
                handleError(err, dispatch, `/sale-invoices/update-record`)
            })
        } else if (data.docType === 'PROTOCOL') {
            dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true })
            console.log("save ocr was used")
            myFetch(`/protocol-docs/update-record`, {
                method: 'put', body: {
                    data: data,
                }
            }).then(res => {
                cb()
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                if (res.success) {
                    dispatch(AlertActions.info("Document data was updated."))
                    dispatch({
                        type: FileConst.UPDATE_PREVIEV_DATA,
                        projectId: data.projectId,
                        file: res.file,
                    })
                    dispatch({
                        type: ReportsConst.UPDATE_DOWNLOADED_DOC_DATA,
                        docId: res.doc.id,
                        counterparty: res.doc.counterparty ? res.doc.counterparty.name : '',
                        issueDate: moment(res.doc.date).format('YYYY-MM-DD'),
                        comment: res.doc.description,
                        docType: data.docType,
                        tags: res.doc.tags
                    })
                } else {
                    dispatch({ type: FileConst.LOAD_DOC_BUCKUP })
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                dispatch({ type: FileConst.LOAD_DOC_BUCKUP })
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false })
                cb()
                handleError(err, dispatch, `/protocol-docs/update-record`)
            })
        }
    }
}

function editDocInput(value, field, docType, docId) {
    return dispatch => {
        dispatch({ type: FileConst.EDIT_DOC_INPUT, value: value, field: field, docId: docId, docType: docType })
    }
}

function changePaymentMethodInPdfBackup(value) {
    return dispatch => {
        dispatch({ type: FileConst.EDIT_PDF_BACKUP, value: value, field: 'paymentMethod' })
    }
}

function changeAccountmentStatusInPdfBackup(value) {
    return dispatch => {
        dispatch({ type: FileConst.EDIT_PDF_BACKUP, value: value, field: 'accounted' })
    }
}

function toggleProjectFiles(projectId, extended) {
    return dispatch => {
        dispatch({ type: FileConst.TOGGLE_PROJECT_FILES, projectId: projectId, extended: extended })
    }
}

function hideRefreshOcrPopup() {
    return dispatch => {
        dispatch({ type: FileConst.HIDE_REFRESH_OCR_POPUP })
    }
}

function hideAcceptedOcrPopup() {
    return dispatch => {
        dispatch({ type: FileConst.HIDE_ACCEPTED_OCR_POPUP })
    }
}

function showOcrScreenNotificationAboutDeletedFile(user) {
    return dispatch => {
        dispatch({ type: FileConst.OCR_SCREEN_NOTIFICATION_ABOUT_DELETED_FILE, show: true, user: user })
    }
}

function hideOcrScreenNotificationAboutDeletedFile() {
    return dispatch => {
        dispatch({ type: FileConst.OCR_SCREEN_NOTIFICATION_ABOUT_DELETED_FILE, show: false, user: '' })
    }
}

function showOcrScreenNotificationAboutMovedFile(user) {
    return dispatch => {
        dispatch({ type: FileConst.OCR_SCREEN_NOTIFICATION_ABOUT_MOVED_FILE, show: true, user: user })
    }
}

function hideOcrScreenNotificationAboutMovedFile() {
    return dispatch => {
        dispatch({ type: FileConst.OCR_SCREEN_NOTIFICATION_ABOUT_MOVED_FILE, show: false, user: '' })
    }
}

function downloadReportFiles(fileId, projectId, fileName, cb) {
    return dispatch => {
        myFetch(`/reports/download-files/file-id/${fileId}`, { method: 'get' })
            .then(async res => {
                if ((res.files && res.files.length > 0) || (res.paymentOrderFiles && res.paymentOrderFiles.lenght > 0)) {
                    await myDownload(
                        `/files/multi-download`,
                        {
                            method: 'post',
                            body: {
                                projectId: projectId,
                                files: res.files || [],
                                paymentOrders: []
                            }
                        },
                        `${fileName}-files.zip`
                    )
                    cb()
                } else {
                    dispatch(AlertActions.warning('No files asociated with report found'))
                    cb()
                }
            }).catch(err => {
                handleError(err, dispatch, `/reports/download-files/file-id/${fileId}`)
                cb()
            })
    }
}

function downloadFilesAssignedToAgreement(projectId, fileIds, fileName, cb) {
    return async dispatch => {
        await myDownload(
            `/files/multi-download`,
            {
                method: 'post',
                body: {
                    projectId: projectId,
                    files: fileIds || [],
                    paymentOrders: []
                }
            },
            `${fileName}-files.zip`
        )
        cb()
    }
}

function setFileToVerifiHighPriority(fileId, projectId) {
    return dispatch => {
        dispatch({
            type: FileConst.SET_FILE_TO_VERIFI_HIGH_PRIORITY,
            fileId,
            projectId
        })
    }
}

function switchInvoiceSortingFilter(key) {
    return dispatch => {
        dispatch({ type: FileConst.SORT_INVOICE, name: key })
    }
}

function addCostSplitForFile(fileId, ftvId, cb) {
    return dispatch => {
        myFetch(`/costs-split/add-for-file`, {
            method: 'post', body: {
                fileId: fileId,
                ftvId: ftvId
            }
        }).then(res => {
            if (res.success) {
                cb(res.splitIds)
            } else {
                cb([])
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            cb([])
            handleError(err, dispatch, `/costs-split/add-for-file`)
        })
    }
}

function deleteCostSplitForFile(fileId, ftvId) {
    return dispatch => {
        myFetch(`/costs-split/delete-for-file`, {
            method: 'delete', body: {
                fileId: fileId,
                ftvId: ftvId
            }
        }).then(res => {
            if (res.success) {
            } else {
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            handleError(err, dispatch, `/costs-split/delete-for-file`)
        })
    }
}

function addCostSplitInstance(fileId, ftvId, splitData, cb) {
    return dispatch => {
        myFetch(`/costs-split/add`, {
            method: 'post', body: {
                fileId: fileId,
                ftvId: ftvId,
                splitData: splitData
            }
        }).then(res => {
            if (res.success) {
                cb(res.splitId)
            } else {
                cb(-1)
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            cb(-1)
            handleError(err, dispatch, `/costs-split/add`)
        })
    }
}

function deleteCostSplitInstance(fileId, ftvId, splitId) {
    return dispatch => {
        myFetch(`/costs-split/delete`, {
            method: 'delete', body: {
                fileId: fileId,
                ftvId: ftvId,
                splitId: splitId
            }
        }).then(res => {
            if (res.success) {
            } else {
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            handleError(err, dispatch, `/costs-split/delete`)
        })
    }
}

function editCostSplitInstance(fileId, ftvId, splitId, splitData) {
    return dispatch => {
        myFetch(`/costs-split/edit`, {
            method: 'put', body: {
                fileId: fileId,
                ftvId: ftvId,
                splitId: splitId,
                splitData: splitData
            }
        }).then(res => {
            if (res.success) {
            } else {
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            handleError(err, dispatch, `/costs-split/edit`)
        })
    }
}

function addFirstMpkSplitInstance(fileId, ftvId, netAmount, cb) {
    return dispatch => {
        myFetch(`/mpk-split-wine-avenue/add-first`, {
            method: 'post', body: {
                fileId: fileId,
                ftvId: ftvId,
                netAmount: netAmount || ''
            }
        }).then(res => {
            if (res.success) {
                cb(res.splitInstances)
            } else {
                cb(-1)
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            cb(-1)
            handleError(err, dispatch, `/mpk-split-wine-avenue/add-first`)
        })
    }
}

function addMpkSplitInstance(fileId, ftvId, splitData, cb) {
    return dispatch => {
        myFetch(`/mpk-split-wine-avenue/add`, {
            method: 'post', body: {
                fileId: fileId,
                ftvId: ftvId,
                splitData: splitData
            }
        }).then(res => {
            if (res.success) {
                cb(res.splitId)
            } else {
                cb(-1)
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            cb(-1)
            handleError(err, dispatch, `/mpk-split-wine-avenue/add`)
        })
    }
}

function deleteMpkSplitInstance(fileId, ftvId, splitId) {
    return dispatch => {
        myFetch(`/mpk-split-wine-avenue/delete`, {
            method: 'delete', body: {
                fileId: fileId,
                ftvId: ftvId,
                splitId: splitId
            }
        }).then(res => {
            if (res.success) {
            } else {
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            handleError(err, dispatch, `/mpk-split-wine-avenue/delete`)
        })
    }
}

function editMpkSplitInstance(fileId, ftvId, splitId, splitData) {
    return dispatch => {
        myFetch(`/mpk-split-wine-avenue/edit`, {
            method: 'put', body: {
                fileId: fileId,
                ftvId: ftvId,
                splitId: splitId,
                splitData: splitData
            }
        }).then(res => {
            if (res.success) {
            } else {
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            handleError(err, dispatch, `/mpk-split-wine-avenue/edit`)
        })
    }
}

function handleError(error, dispatch, path) {
    if (error?.code === 'AUTHORIZATION_REQUIRED' && localStorage.getItem('dashboardProject')) {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        dispatch({ type: UserConst.LOGGED_FALSE })
        dispatch({ type: UserConst.LOGOUT })
        dispatch({ type: EventConst.LOGOUT })
        dispatch({ type: CompanyConst.LOGOUT })
        dispatch({ type: ReportsConst.LOGOUT })
        dispatch({ type: FileConst.LOGOUT })
        dispatch(AlertActions.info("You have been logged out.", 5000))
    } else {
        dispatch(AlertActions.setError(error, path));
        dispatch(AlertActions.danger("There was an internal server error while procesing request. Try again later."));
    }
}