import React, { Component } from "react";
import { NavLink, Link, Route, Redirect, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import copy from 'copy-to-clipboard';
import { connect } from "react-redux";
import Dropzone from 'react-dropzone'
import { PDFDocument } from 'pdf-lib';
import ReactTooltip from 'react-tooltip'

import { AlertActions } from '../../actions/AlertActions'
import { TabsControlerActions } from '../../actions/V2_TabsControlerActions'
import { FileActions } from '../../actions/FileActions'
import { AvailableTabs, GenerateInvoiceComponents } from "../../reducers/V2_TabsReducer"

import arrayObjectIndexOf from '../../helpers/indexOfObject';


import QrCodesForMobile from '../../components/DashboardComponents/QrCodesForMobile'
import PdfPagesSelector from '../../components/PdfPagesSelector'

const fileMaxSize = 1000000000 // bytes
const acceptedFileTypes = 'image/png, image/jpg, image/jpeg, application/pdf'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() })

export class RightMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMobilePopup: false
        }

        this.attachDocsWrapperRef = React.createRef()
        this.dropDocsWrapperRef = React.createRef()
    }

    verifyFile = (files) => {
        if (files && files.length > 0) {
            files.forEach(currentFile => {
                const currentFileType = currentFile.type
                const currentFileSize = currentFile.size
                if (currentFileSize > fileMaxSize) {
                    //file ot big
                    return false
                }
                if (!acceptedFileTypesArray.includes(currentFileType)) {
                    //not supported type
                    return false
                }
            })
            return true
        }
    }

    verifiName = (name) => {
        var base = name.substring(0, name.lastIndexOf('.'))
        var extension = name.substring(name.lastIndexOf('.'))
        console.log(base)
        base = base.replace(/\s\s+/g, ' ')
        console.log(extension)
        console.log(base + extension)
        return base + extension
    }

    handleOnDrop = (files, rejectedFiles) => {
        if (rejectedFiles && rejectedFiles.length > 0) {
            this.verifyFile(rejectedFiles)
        } else {
            const { currentProject } = this.props
            var verifiedName = ''
            files.forEach((currentFile, index) => {
                console.log('drop 3 for files')
                console.log(currentFile.name, currentFile.size)
                const myFileItemReader = new FileReader()
                myFileItemReader.addEventListener("load", () => {
                    verifiedName = this.verifiName(currentFile.name)
                    const myResult = {}
                    myResult.src = myFileItemReader.result
                    myResult.name = verifiedName
                    myResult.size = currentFile.size
                    myResult.type = currentFile.type
                    myResult.docType = this.state.docType
                    myResult.projectId = currentProject.id
                    myResult.docType = 'COSTS'
                    console.log(myResult)
                    // this.parseFiles(myResult, projectId)
                    if (currentFile.type === 'application/pdf') {
                        const arrayBufferReader = new FileReader()
                        arrayBufferReader.addEventListener("load", () => {
                            this.countPdfPages(arrayBufferReader.result, myResult, currentProject.id)
                        })
                        arrayBufferReader.readAsArrayBuffer(currentFile);
                    } else {
                        this.parseFiles(myResult, currentProject.id)
                    }
                }, false)
                myFileItemReader.readAsDataURL(currentFile)
            })
        }
    }

    parseFiles = (file, projectId, pages = []) => {
        const { filesToVerifiLoaded } = this.props
        var projectIndex = arrayObjectIndexOf(filesToVerifiLoaded, projectId, 'project')
        if (projectIndex > -1) {
            if (!filesToVerifiLoaded[projectIndex].files.find(e => e.name === file.name)) {
                this.props.parsePDF({
                    fileName: file.name,
                    base64: file.src,
                    type: file.type,
                    senderEmail: this.props.user?.email
                }, projectId, file.size, pages)
                this.props.cacheUploadedFile({
                    name: file.name,
                    size: file.size,
                    projectId: projectId,
                })
            } else {
                var newName = this.generateUniqueName(file.name, filesToVerifiLoaded[projectIndex].files)
                this.props.parsePDF({
                    fileName: newName,
                    base64: file.src,
                    type: file.type,
                    senderEmail: this.props.user.email
                }, projectId, file.size, pages)
                this.props.cacheUploadedFile({
                    name: newName,
                    size: file.size,
                    projectId: projectId,
                })
            }
        }
    }

    countPdfPages = async (arrayBuffer, myResult, projectId) => {
        try {
            const pdf = await PDFDocument.load(arrayBuffer, { ignoreEncryption: true });
            var pages = pdf.getPages()
            console.log(pages)

            if (pages.length >= 5) {
                this.setState({
                    fileToChoosePages: { ...myResult, ...{ projectId: projectId } },
                    showPdfPageSelector: true
                })
            } else {
                this.parseFiles(myResult, projectId)
            }
        } catch (err) {
            console.log(err)
            this.parseFiles(myResult, projectId)
        }

    }

    closePdfPagesSelector = () => {
        this.setState({
            fileToChoosePages: {},
            showPdfPageSelector: false
        })
    }

    handleDropZoneClick = e => {
        if (
            (this.attachDocsWrapperRef && this.attachDocsWrapperRef.current && !this.attachDocsWrapperRef.current.contains(e.target)) &&
            (this.dropDocsWrapperRef && this.dropDocsWrapperRef.current && !this.dropDocsWrapperRef.current.contains(e.target))
        ) {
            e.stopPropagation()
        }
    }


    render() {
        const {
            showMobilePopup,
            showPdfPageSelector, fileToChoosePages
        } = this.state
        const {
            menuExtended,
            currentProject
        } = this.props
        return (
            <>
                <Dropzone onDrop={(files, rejectedFiles) => this.handleOnDrop(files, rejectedFiles)} accept={acceptedFileTypes} multiple={true} maxSize={fileMaxSize}>
                    {({ getRootProps, getInputProps, isDragActive }) => (
                        <div {...getRootProps({ onClick: event => this.handleDropZoneClick(event) })} className={`new-menu-wrapper left ${menuExtended ? 'full-width' : ''}`}>
                            <input {...getInputProps()} type="file" />
                            <div className="instruction-number" data-tip='show' data-for='instruction-tooltip-1'>1</div>
                            {/* <div className="menu-item insert-docs">{this.context.t('Insert documents')}</div> */}
                            <div className="insert-docs-options">
                                <div className={`menu-item drop-docs ${isDragActive ? 'drag-active' : ''}`} ref={this.dropDocsWrapperRef}>{this.context.t('Drop your documents here')}</div>
                                <div className="menu-item attach-docs" ref={this.attachDocsWrapperRef}>{this.context.t('Attach a document')}</div>
                                <div className="menu-item email-docs" onClick={e => { copy(`${currentProject?.code?.toLowerCase()}@app.easydocs.pl`); this.props.aletrInfo('Copied to clipboard') }}>
                                    <a href={`mailto:${currentProject?.code?.toLowerCase()}@app.easydocs.pl`}>{this.context.t('Send documents by e-mail to ')} <span className="bold">{`${currentProject?.code}@app.easydocs.pl`}</span></a>
                                </div>
                                <div className="menu-item mobile-docs" onClick={() => this.setState({ showMobilePopup: true })}>{this.context.t('Send documents using the mobile application')}</div>
                            </div>
                            <div className="menu-item payment-orders" onClick={() => this.props.openNewTab(AvailableTabs.GENERATE_PAYMENT_ORDER, AvailableTabs.GENERATE_PAYMENT_ORDER)}>{this.context.t('Add payment order')}</div>
                            <div className="menu-item sale-invoice" onClick={() => this.props.openNewTab(AvailableTabs.GENERATE_SALE_INVOICE, GenerateInvoiceComponents.INVOICE)}>{this.context.t('Issue an invoice')}</div>
                            {/* <div className="menu-item ksef">{this.context.t('KSeF')}</div> */}

                            <div className="extent-menu" onClick={() => this.props.toggleMenu()}>{this.context.t('Hide')}</div>
                        </div>
                    )}
                </Dropzone>

                {
                    showPdfPageSelector ? (
                        <PdfPagesSelector file={fileToChoosePages} saveFile={this.parseFiles} close={this.closePdfPagesSelector} />
                    ) : null
                }

                {
                    showMobilePopup ? (
                        <QrCodesForMobile closePopup={() => this.setState({ showMobilePopup: false })} />
                    ) : null
                }

                <ReactTooltip id='instruction-tooltip-1' className='default-tooltip instruction-tooltip' effect='solid' place='right'>
                    <h5>{this.context.t('Insert')}</h5>
                    <p>{this.context.t('Add documents:')}</p>
                    <ul>
                        <li>{this.context.t('Drag and drop the file')}</li>
                        <li>{this.context.t('Send to an individual e-mail address (you can copy it by clicking the envelope icon)')}</li>
                        <li>{this.context.t('Use EasyDocs mobile application')}</li>
                        <li>{this.context.t('Use a network scanner')}</li>
                        <li>{this.context.t('Create a payment order, without a document or based on a source other than an invoice')}</li>
                    </ul>
                </ReactTooltip>
            </>
        )
    }
}

RightMenu.contextTypes = {
    t: PropTypes.func,
};

const mapStateToProps = (state) => ({
    currentProject: state.User.currentProject,
    filesToVerifiLoaded: state.File.filesToVerifiLoaded,
    user: state.User.user,
});

const mapDispatchToProps = {
    aletrInfo: AlertActions.info,
    openNewTab: TabsControlerActions.openNewTab,
    parsePDF: FileActions.parsePDF,
    cacheUploadedFile: FileActions.cacheFile,
}

export default connect(mapStateToProps, mapDispatchToProps)(RightMenu)
