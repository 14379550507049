import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import myFetch from '../helpers/fetch'
import { reverse } from 'named-urls'
import routes from '../helpers/routes'
import { history } from '../helpers/history'
import arrayObjectIndexOf from '../helpers/indexOfObject'
import { FileActions } from '../actions/FileActions'
import { TransfersActions } from '../actions/TransfersActions'
import { AlertActions } from '../actions/AlertActions'
import moment from 'moment'

import '../css/HighPriorityModal.css'
import pdfIcon from '../img/pdf-icon.svg'

const FILE_TYPES = {
    FILE_TO_VERIFI: 'FILE_TO_VERIFI',
    FILE: 'FILE',
    PAYMENT: 'PAYMENT',
}

const HighPriorityModal = ({ docs, onClose }, { t }) => {
    const [loading, setLoading] = useState(false)

    const closeModal = async () => {
        if (!loading) {
            setLoading(true)
            try {
                myFetch('/files/clear-high-priority-files', {
                    method: 'GET',
                })

                onClose();
            } catch (err) {
                console.error(err)
            }
        }
    }

    useEffect(() => {
        document.body.style.overflow = 'hidden'

        return () => {
            document.body.style.overflow = ''
        }
    }, [])

    if (!Object.keys(docs.highPriorityFilesToVerifiList).length && !Object.keys(docs.highPriorityFilesList).length && !Object.keys(docs.highPriorityPaymentsList).length) {
        onClose();
    }

    return (
        <div className="high-priority__container">
            <div className="high-priority">
                <div className="high-priority__content">
                    <div className="high-priority__header">
                        <h4 className="high-priority__title">{t("New high priority documents")}</h4>
                        <p className="high-priority__subtitle">{t("New documents with high priority have been added to your binders")}</p>
                    </div>
                    <div className="high-priority__wrapper">
                        {docs.highPriorityFilesToVerifiList && <HighPriorityModalList type={FILE_TYPES.FILE_TO_VERIFI} title={t('Docs to verify')} projects={docs.highPriorityFilesToVerifiList} closeModal={closeModal} />}
                        {docs.highPriorityFilesList && <HighPriorityModalList type={FILE_TYPES.FILE} title={t('Docs')} projects={docs.highPriorityFilesList} closeModal={closeModal} />}
                        {docs.highPriorityPaymentsList && <HighPriorityModalList type={FILE_TYPES.PAYMENT} title={t('Payment orders')} projects={docs.highPriorityPaymentsList} closeModal={closeModal} />}
                    </div>
                    <button onClick={closeModal} className="high-priority__button">{t('Close')}</button>
                </div>
            </div>
        </div>
    );
}

HighPriorityModal.contextTypes = {
    t: PropTypes.func
}

const HighPriorityModalList = ({ projects, title, type, closeModal }, { t }) => {
    const projectNames = Object.keys(projects)
    const { uploadedFiles } = useSelector(state => state.File)
    const { transfers } = useSelector(state => state.Transfers)

    const dispatch = useDispatch()

    const handleOpenFile = (file) => {
        switch (type) {
            case FILE_TYPES.FILE_TO_VERIFI:
                history.push(reverse(routes.panel.crop, { projectId: file.projectId, fileId: file.id }));
                closeModal()
                break;
            case FILE_TYPES.FILE:
                console.log(uploadedFiles)
                console.log(file)
                var projectIndex = arrayObjectIndexOf(uploadedFiles, file.projectId, 'project')
                if (projectIndex === -1) {
                    dispatch(AlertActions.warning(t('Sorry, we are unable to open this file')))
                    return;
                }
                var fileIndex = arrayObjectIndexOf(uploadedFiles[projectIndex].files, file.id, 'id')
                if (fileIndex === -1) {
                    dispatch(AlertActions.warning(t('Sorry, we are unable to open this file')))
                    return;
                }
                var fileToOpen = uploadedFiles[projectIndex].files[fileIndex]
                dispatch(FileActions.getFileData(parseInt(fileToOpen.project_id), fileToOpen.path, fileToOpen.id, fileToOpen.ocr_status, fileToOpen.acceptance_step))
                break;
            case FILE_TYPES.PAYMENT:
                const transferProject = transfers.find(t => t.project === file.projectId)
                if (!transferProject) {
                    dispatch(AlertActions.warning(t('Sorry, we are unable to open this file')))
                    return;
                }
                const transferToOpen = transferProject.transfers.find(t => t.id === file.id)
                if (!transferToOpen) {
                    dispatch(TransfersActions.openTransferPreviewWindow({ id: file.id, projectId: file.projectId, downloadPaymentOrder: true }))
                    return;
                }
                dispatch(TransfersActions.openTransferPreviewWindow(transferToOpen))
                break;
            default:
                break
        }
    }

    if (!projectNames.length) return null

    return (
        <div className="high-priority__list">
            <div className="high-priority__list-title">{title}</div>
            {
                projectNames.map((project) => (
                    <div className="high-priority__project" key={project}>
                        <div className="high-priority__project-title">{project}</div>
                        <div className="high-priority__files">
                            {projects[project].map(file => (
                                <div onClick={() => handleOpenFile(file)} key={file.id} className="high-priority__file">
                                    <img src={pdfIcon} alt="" />
                                    <span className="file-name">{file.name}</span>
                                    <span className="date">{`(${moment(file.date).format('YYYY-MM-DD HH:mm')})`}</span>
                                    {
                                        file.dueDate ? (
                                            <div className="doc-due-date">
                                                {`${t('Due date:')} ${file.dueDate}`}
                                            </div>
                                        ) : (
                                            null
                                        )
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

HighPriorityModalList.contextTypes = {
    t: PropTypes.func
}

export default HighPriorityModal;