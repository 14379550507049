/* eslint-disable import/no-anonymous-default-export */
import moment from "moment"

import arrayObjectIndexOf from "../helpers/indexOfObject"

const months = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
]

const quarters = [
    'January - March', 'April - June', 'July - September', 'October - December'
]

export const TransferConst = {
    SHOW_ADD_TRANSFER_WINDOW: 'SHOW_ADD_TRANSFER_WINDOW',
    HIDE_ADD_TRANSFER_WINDOW: 'HIDE_ADD_TRANSFER_WINDOW',
    SHOW_TRANSFER_PREVIEW_WINDOW: 'SHOW_TRANSFER_PREVIEW_WINDOW',
    DOWNLOAD_FILE: 'DOWNLOAD_FILE',
    HIDE_TRANSFER_PREVIEW_WINDOW: 'HIDE_TRANSFER_PREVIEW_WINDOW',
    ADD_TRANSFER: 'ADD_TRANSFER',
    GET_TRANSFERS_REQUEST: 'GET_TRANSFERS_REQUEST',
    GET_TRANSFERS: 'GET_TRANSFERS',
    MAKE_TRANSFER_BACKUP: 'MAKE_TRANSFER_BACKUP',
    LOAD_TRANSFER_BACKUP: 'LOAD_TRANSFER_BACKUP',
    EDIT_TRANSFER_INPUT: 'EDIT_TRANSFER_INPUT',
    REMOVE_PREVIEW_FILE: 'REMOVE_PREVIEW_FILE',
    SET_PREVIEW_FILE: 'SET_PREVIEW_FILE',
    SAVE_TRANSFER_CHANGES: 'SAVE_TRANSFER_CHANGES',
    DELETE_TRANSFER: 'DELETE_TRANSFER',
    MARK_TRANSFER_AS_PAID: 'MARK_TRANSFER_AS_PAID',
    MARK_TRANSFER_AS_ACCOUNTED: 'MARK_TRANSFER_AS_ACCOUNTED',
    SORT_TRANSFERS: 'SORT_TRANSFERS',

    TOGGLE_PROJECT_PAYMENT_ORDERS: 'TOGGLE_PROJECT_PAYMENT_ORDERS',
    HIDE_ALL_PROJECT_PAYMENT_ORDERS: 'HIDE_ALL_PROJECT_PAYMENT_ORDERS',

    DUPLICATE_PAYMENT_ORDER: 'DUPLICATE_PAYMENT_ORDER',
    DATA_FROM_WA_EXCEL: 'DATA_FROM_WA_EXCEL',

    LOADING_SCREEN: 'LOADING_SCREEN',
    DELETE_PROJECT: 'DELETE_PROJECT-PAYMENT_ORDERS',
    LOGOUT: 'LOGOUT',
}

function dynamicComparer(key, order = 'asc') {
    return function (a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            return 0;
        }

        const varA = (typeof a[key] === 'string') ?
            a[key].toUpperCase() : a[key];
        const varB = (typeof b[key] === 'string') ?
            b[key].toUpperCase() : b[key];

        var comparison = 0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }
        return (
            (order == 'desc') ? (comparison * -1) : comparison
        );
    };
}

const initialState = {
    showCreateTransferOrderWindow: false,
    transferOrderProject: null,
    transfers: [],
    transferPreviewData: {
        id: null,
    },
    backupPTransferPreviewData: null,
    backupTransferPreviewFile: null,
    transferPreviewFile: null,
    transferPreviewOpen: false,
    transferDataToDuplicate: null,
    loadingScreen: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case TransferConst.SHOW_ADD_TRANSFER_WINDOW:
            return { ...state, ...{ showCreateTransferOrderWindow: true, transferOrderProject: action.project, dataFromUrl: action.dataFromUrl } }

        case TransferConst.HIDE_ADD_TRANSFER_WINDOW:
            return { ...state, ...{ showCreateTransferOrderWindow: false, transferOrderProject: null, transferDataToDuplicate: null } }

        case TransferConst.SHOW_TRANSFER_PREVIEW_WINDOW:
            if (action.transfer) {
                var year = moment().format('YYYY'), periodType, periodNumber, periodName, symbol
                if (action.transfer.additional_payment_data && action.transfer.payment_type === 'TAX') {
                    year = action.transfer.additional_payment_data.year
                    periodNumber = action.transfer.additional_payment_data.period ? action.transfer.additional_payment_data.period.substring(3, 5) : ''
                    periodType = action.transfer.additional_payment_data.period ? action.transfer.additional_payment_data.period.substring(2, 3) : ''
                    if (periodType === 'R') {
                        periodType = 'year'
                        periodName = 'Year'
                    } else if (periodType === 'K') {
                        periodType = 'quarter'
                        periodName = quarters[parseInt(periodNumber) - 1]
                    } else if (periodType === 'M') {
                        periodType = 'month'
                        periodName = months[parseInt(periodNumber) - 1]
                    }

                    symbol = action.transfer.additional_payment_data.tax_symbol
                }
                return {
                    ...state, ...{
                        transferPreviewOpen: true,
                        transferPreviewFile: null,
                        transferPreviewData: {
                            ...action.transfer,
                            amount_to_pay: action.transfer.amount_to_pay ? action.transfer.amount_to_pay.slice(0, -2) : "",
                            year: year,
                            period_type: periodType,
                            period_number: periodNumber,
                            period_name: periodName,
                            symbol: symbol
                        }
                    }
                }
            } else {
                return { ...state, ...{ transferPreviewOpen: true } }
            }

        case TransferConst.DOWNLOAD_FILE:
            if (!action.error && action.data) {
                return { ...state, ...{ transferPreviewFile: { base64: action.data, name: action.name, type: action.fileType } } }
            } else {
                return { ...state, ...{ transferPreviewFile: null } }
            }

        case TransferConst.HIDE_TRANSFER_PREVIEW_WINDOW:
            return { ...state, ...{ transferPreviewOpen: false } }

        case TransferConst.ADD_TRANSFER:
            var currentTransfers = [...state.transfers]

            if (action.transfer.additional_payment_data) {
                action.transfer.additional_payment_data = JSON.parse(action.transfer.additional_payment_data)
            }
            var projectIndex = arrayObjectIndexOf(currentTransfers, action.projectId, 'project')
            if (projectIndex > -1) {
                currentTransfers[projectIndex].transfers.push({
                    id: action.transfer.id,
                    creator: action.transfer.creator,
                    project_id: action.transfer.project_id,
                    name: action.transfer.name,
                    recipient: action.transfer.recipient,
                    title: action.transfer.title,
                    tags: action.transfer.tags,
                    account_number: action.transfer.account_number,
                    amount_to_pay: action.transfer.amount_to_pay,
                    currency: action.transfer.currency,
                    due_date: moment(action.transfer.due_date),
                    file_key: action.transfer.file_key,
                    attachment_name: action.transfer?.attachment_name || '',
                    attachment_type: action.transfer?.attachment_type || '',
                    comment: action.transfer.comment,
                    payment_type: action.transfer.payment_type,
                    priority: action.transfer.priority,
                    additional_payment_data: action.transfer.additional_payment_data,
                    address: action.transfer.address,
                    country_code: action.transfer.country_code,
                    swift: action.transfer.swift,
                    paid_by: action.transfer.paid_by,
                    payment_method: action.transfer.payment_method,
                    email_to_send_payment_confirmation: action.transfer.email_to_send_payment_confirmation,
                    wl_project_code: action.transfer.wl_project_code || '',
                    wl_technology: action.transfer.wl_technology || '',
                    wl_devex_type_1: action.transfer.wl_devex_type_1 || '',
                    wl_devex_type_2: action.transfer.wl_devex_type_2 || '',
                    wl_transaction_description_pl: action.transfer.wl_transaction_description_pl || '',
                    wl_transaction_description_en: action.transfer.wl_transaction_description_en || '',
                })
                currentTransfers[projectIndex].transfers.sort(dynamicComparer(currentTransfers[projectIndex].sortField, currentTransfers[projectIndex].order))
            } else {
                currentTransfers.push({
                    project: action.projectId,
                    transfers: [{
                        id: action.transfer.id,
                        creator: action.transfer.creator,
                        project_id: action.transfer.project_id,
                        name: action.transfer.name,
                        recipient: action.transfer.recipient,
                        title: action.transfer.title,
                        tags: action.transfer.tags,
                        account_number: action.transfer.account_number,
                        price: action.transfer.price,
                        currency: action.transfer.currency,
                        due_date: moment(action.transfer.due_date),
                        file_key: action.transfer.file_key,
                        comment: action.transfer.comment,
                        payment_type: action.transfer.payment_type,
                        additional_payment_data: action.transfer.additional_payment_data,
                        address: action.transfer.address,
                        country_code: action.transfer.country_code,
                        swift: action.transfer.swift,
                        paid_by: action.transfer.paid_by,
                        payment_method: action.transfer.payment_method,
                        wl_project_code: action.transfer.wl_project_code || '',
                        wl_technology: action.transfer.wl_technology || '',
                        wl_devex_type_1: action.transfer.wl_devex_type_1 || '',
                        wl_devex_type_2: action.transfer.wl_devex_type_2 || '',
                        wl_transaction_description_pl: action.transfer.wl_transaction_description_pl || '',
                        wl_transaction_description_en: action.transfer.wl_transaction_description_en || '',
                    }],
                    sortField: 'name',
                    order: 'desc'
                })
            }

            return { ...state, ...{ transfers: currentTransfers, showCreateTransferOrderWindow: false, transferOrderProject: null, transferDataToDuplicate: null } }

        case TransferConst.GET_TRANSFERS_REQUEST:
            var currentTransfers = JSON.parse(JSON.stringify(state.transfers))
            var projectIndex = arrayObjectIndexOf(currentTransfers, action.projectId, "project");

            if (projectIndex === -1) {
                currentTransfers.push({
                    project: action.projectId,
                    transfers: [],
                    sortField: "name",
                    order: "desc",
                    sendRequest: true,
                    loaded: false,
                    extended: false,
                })

                return { ...state, ...{ transfers: currentTransfers } }
            }

            return state

        case TransferConst.GET_TRANSFERS:
            if (action.projectId !== -1) {
                var currentTransfers = [...state.transfers]
                var projectIndex = arrayObjectIndexOf(currentTransfers, action.projectId, "project");

                if (projectIndex > -1) {
                    action.transfers.forEach(transfer => {
                        if (transfer.additional_payment_data) {
                            transfer.additional_payment_data = JSON.parse(transfer.additional_payment_data)
                        }

                        let transferOndex = arrayObjectIndexOf(currentTransfers[projectIndex].transfers, transfer.id, "id")
                        if (transferOndex === -1) {
                            currentTransfers[projectIndex].transfers.push(transfer)
                        } else {
                            currentTransfers[projectIndex].transfers[transferOndex] = transfer
                        }

                        currentTransfers[projectIndex].loaded = true
                    })
                } else {
                    action.transfers.forEach(transfer => {
                        if (transfer.additional_payment_data) {
                            transfer.additional_payment_data = JSON.parse(transfer.additional_payment_data)
                        }
                    })

                    currentTransfers.push({
                        project: action.projectId,
                        transfers: action.transfers,
                        sortField: "name",
                        order: "desc",
                        sendRequest: true,
                        loaded: true,
                        extended: false,
                    })
                }

                projectIndex = arrayObjectIndexOf(currentTransfers, action.projectId, "project");
                currentTransfers[projectIndex].transfers.sort(dynamicComparer(currentTransfers[projectIndex].sortField, currentTransfers[projectIndex].order))

                return { ...state, ...{ transfers: currentTransfers } }
            } else {
                var projectTransfers = [...action.transfers]
                var currentTransfers = []
                var projectsCount = projectTransfers.length
                for (var i = 0; i < projectsCount; i++) {
                    var transfersCount = projectTransfers[i].transfers.length
                    for (var j = 0; j < transfersCount; j++) {
                        console.log(projectTransfers[i].transfers[j].additional_payment_data)
                        if (projectTransfers[i].transfers[j].additional_payment_data) {
                            projectTransfers[i].transfers[j].additional_payment_data = JSON.parse(projectTransfers[i].transfers[j].additional_payment_data)
                        }
                    }
                    currentTransfers.push({
                        project: projectTransfers[i].project,
                        transfers: projectTransfers[i].transfers,
                        sortField: "name",
                        order: "desc",
                        sendRequest: true,
                        loaded: true,
                        extended: true,
                    })
                }
                return { ...state, ...{ transfers: currentTransfers } }
            }

        case TransferConst.MAKE_TRANSFER_BACKUP:
            if (!state.backupPTransferPreviewData) {
                return {
                    ...state,
                    ...{
                        backupPTransferPreviewData: {
                            file_key: state.transferPreviewData.file_key,
                            title: state.transferPreviewData.title,
                            recipient: state.transferPreviewData.recipient,
                            account_number: state.transferPreviewData.account_number,
                            amount_to_pay: state.transferPreviewData.amount_to_pay,
                            currency: state.transferPreviewData.currency,
                            due_date: state.transferPreviewData.due_date,
                            comment: state.transferPreviewData.comment,
                            tags: state.transferPreviewData.tags,
                            payment_type: state.transferPreviewData.payment_type,
                            year: state.transferPreviewData.year,
                            period_type: state.transferPreviewData.period_type,
                            period_number: state.transferPreviewData.period_number,
                            period_name: state.transferPreviewData.period_name,
                            symbol: state.transferPreviewData.symbol,
                            priority: state.transferPreviewData.priority,
                            address: state.transferPreviewData.address,
                            country_code: state.transferPreviewData.country_code,
                            swift: state.transferPreviewData.swift,
                            paid_by: state.transferPreviewData.paid_by,
                            payment_method: state.transferPreviewData.payment_method
                        },
                        backupTransferPreviewFile: state.transferPreviewFile
                    },
                }
            } else {
                return state
            }

        case TransferConst.LOAD_TRANSFER_BACKUP:
            return {
                ...state,
                ...{
                    transferPreviewData:
                    {
                        id: state.transferPreviewData.id,
                        project_id: state.transferPreviewData.project_id,
                        created: state.transferPreviewData.created,
                        created_by: state.transferPreviewData.created_by,
                        creator: state.transferPreviewData.creator,
                        accepted_by: state.transferPreviewData.accepted_by,
                        paid_by: state.backupPTransferPreviewData.paid_by,
                        payment_method: state.backupPTransferPreviewData.payment_method,
                        name: state.transferPreviewData.name,
                        file_key: state.backupPTransferPreviewData.file_key,
                        title: state.backupPTransferPreviewData.title,
                        recipient: state.backupPTransferPreviewData.recipient,
                        account_number: state.backupPTransferPreviewData.account_number,
                        amount_to_pay: state.backupPTransferPreviewData.amount_to_pay,
                        currency: state.backupPTransferPreviewData.currency,
                        due_date: state.backupPTransferPreviewData.due_date,
                        comment: state.backupPTransferPreviewData.comment,
                        tags: state.backupPTransferPreviewData.tags,
                        payment_type: state.backupPTransferPreviewData.payment_type,
                        year: state.backupPTransferPreviewData.year,
                        period_type: state.backupPTransferPreviewData.period_type,
                        period_number: state.backupPTransferPreviewData.period_number,
                        period_name: state.backupPTransferPreviewData.period_name,
                        symbol: state.backupPTransferPreviewData.symbol,
                        priority: state.backupPTransferPreviewData.priority,
                        address: state.backupPTransferPreviewData.address,
                        country_code: state.backupPTransferPreviewData.country_code,
                        swift: state.backupPTransferPreviewData.swift
                    },
                    transferPreviewFile: state.backupTransferPreviewFile,
                    backupPTransferPreviewData: null,
                    backupTransferPreviewFile: null
                }
            }

        case TransferConst.EDIT_TRANSFER_INPUT:
            var transferPreviewData = { ...state.transferPreviewData }

            if (action.field === 'file.additionalAcceptance') {
                if (transferPreviewData.file) {
                    transferPreviewData.file.additionalAcceptance = action.value
                }
            } else {
                transferPreviewData[action.field] = action.value
            }

            return { ...state, ...{ transferPreviewData: transferPreviewData } }

        case TransferConst.REMOVE_PREVIEW_FILE:
            return {
                ...state,
                ...{
                    transferPreviewFile: null,
                    transferPreviewData: {
                        ...state.transferPreviewData,
                        file_key: null,
                        attachment_name: null,
                        attachment_type: null,
                    }
                }
            }

        case TransferConst.SET_PREVIEW_FILE:
            return {
                ...state,
                ...{
                    transferPreviewFile: action.file,
                    transferPreviewData: {
                        ...state.transferPreviewData,
                        file_key: action.file.name,
                        attachment_name: action.file.name,
                        attachment_type: action.file.type
                    }
                }
            }

        case TransferConst.SAVE_TRANSFER_CHANGES:
            var transfers = [...state.transfers]
            console.log(transfers)
            if (action.transfer.additional_payment_data) {
                action.transfer.additional_payment_data = JSON.parse(action.transfer.additional_payment_data)
            }

            var projectIndex = arrayObjectIndexOf(transfers, action.transfer.project_id, 'project')

            if (projectIndex > -1) {
                var fileIndex = arrayObjectIndexOf(transfers[projectIndex].transfers, action.transfer.id, 'id')

                if (fileIndex > -1) {
                    transfers[projectIndex].transfers[fileIndex] = {
                        ...transfers[projectIndex].transfers[fileIndex],
                        ...action.transfer
                    }
                } else {
                    transfers[projectIndex].transfers.push(action.transfer)
                }

            } else {
                transfers.push({
                    project: action.transfer.project_id,
                    transfers: [
                        action.transfer
                    ],
                    sortField: "name",
                    order: "asc",
                    sendRequest: true,
                    loaded: true,
                })
            }

            var year = moment().format('YYYY'), periodType, periodNumber, periodName, symbol
            if (action.transfer.additional_payment_data && action.transfer.payment_type === 'TAX') {
                year = action.transfer.additional_payment_data.year
                periodNumber = action.transfer.additional_payment_data.period.substring(3, 5)
                periodType = action.transfer.additional_payment_data.period.substring(2, 3)
                if (periodType === 'R') {
                    periodType = ' year'
                } else if (periodType === 'K') {
                    periodType = 'quarter'
                    periodName = quarters[parseInt(periodNumber) - 1]
                } else if (periodType === 'M') {
                    periodType = 'month'
                    periodName = months[parseInt(periodNumber) - 1]
                }

                symbol = action.transfer.additional_payment_data.tax_symbol
            }

            var transferPreviewData = {
                ...action.transfer,
                amount_to_pay: action.transfer.amount_to_pay ? action.transfer.amount_to_pay.slice(0, -2) : "",
                year: year,
                period_type: periodType,
                period_number: periodNumber,
                period_name: periodName,
                symbol: symbol
            }

            return { ...state, ...{ transfers: transfers, transferPreviewData: transferPreviewData, backupPTransferPreviewData: null } }

        case TransferConst.DELETE_TRANSFER:
            var transfers = [...state.transfers]

            var projectIndex = arrayObjectIndexOf(transfers, action.projectId, 'project')

            if (projectIndex > -1) {
                transfers[projectIndex].transfers = transfers[projectIndex].transfers.filter(e => e.id !== action.transferId)
            }

            return { ...state, ...{ transfers: transfers, transferPreviewData: { id: null }, backupPTransferPreviewData: null, transferPreviewFile: null, transferPreviewOpen: false, loadingScreen: false } }

        case TransferConst.MARK_TRANSFER_AS_PAID:
            var transfers = [...state.transfers]
            var projectIndex = arrayObjectIndexOf(transfers, action.projectId, 'project')
            if (projectIndex > -1) {
                var transferIndex = arrayObjectIndexOf(transfers[projectIndex].transfers, action.id, 'id')
                if (transferIndex > -1) {
                    if (action.method) {
                        transfers[projectIndex].transfers[transferIndex].paid_by = action.userId
                        transfers[projectIndex].transfers[transferIndex].payment_method = action.method
                    } else {
                        transfers[projectIndex].transfers[transferIndex].paid_by = null
                        transfers[projectIndex].transfers[transferIndex].payment_method = null
                        if (state.transferPreviewData?.id === transfers[projectIndex].transfers[transferIndex].id) {
                            const upDatedTransferPreviewData = { ...state.transferPreviewData, ...transfers[projectIndex].transfers[transferIndex], amount_to_pay: Number(transfers[projectIndex].transfers[transferIndex].amount_to_pay).toFixed(2).toString() }
                            return { ...state, ...{ transfers: transfers }, ...{ transferPreviewData: upDatedTransferPreviewData } }
                        }
                    }
                }
            }
            return { ...state, ...{ transfers: transfers } }

        case TransferConst.MARK_TRANSFER_AS_ACCOUNTED:
            var transfers = [...state.transfers]
            var projectIndex = arrayObjectIndexOf(transfers, action.projectId, 'project')
            if (projectIndex > -1) {
                var transferIndex = arrayObjectIndexOf(transfers[projectIndex].transfers, action.id, 'id')

                if (transferIndex > -1) {
                    transfers[projectIndex].transfers[transferIndex].accounted = action.accounted
                }
            }
            return { ...state, ...{ transfers: transfers } }

        case TransferConst.LOADING_SCREEN:
            return { ...state, ...{ loadingScreen: action.loadingScreen } }

        case TransferConst.LOGOUT:
            return {
                showCreateTransferOrderWindow: false,
                transferOrderProject: null,
                transfers: [],
                transferPreviewData: {
                    id: null,
                },
                backupPTransferPreviewData: null,
                transferPreviewFile: null,
                transferPreviewOpen: false,
                loadingScreen: false,
            }

        case TransferConst.SORT_TRANSFERS:
            var transfers = [...state.transfers]
            var projectIndex = arrayObjectIndexOf(transfers, action.projectId, "project");
            if (projectIndex > -1) {
                if (transfers[projectIndex].sortField === action.name) {
                    if (transfers[projectIndex].order === 'asc') {
                        transfers[projectIndex].order = 'desc'
                    } else {
                        transfers[projectIndex].order = 'asc'
                    }
                } else {
                    transfers[projectIndex].sortField = action.name
                    transfers[projectIndex].order = 'asc'
                }
                transfers[projectIndex].transfers.sort(dynamicComparer(transfers[projectIndex].sortField, transfers[projectIndex].order))
            }

            return { ...state, ...{ transfers: transfers } }

        case TransferConst.TOGGLE_PROJECT_PAYMENT_ORDERS:
            var transfers = [...state.transfers]
            var projectIndex = arrayObjectIndexOf(transfers, action.projectId, "project");
            if (projectIndex > -1) {

                if (action.extended !== undefined) {
                    transfers[projectIndex].extended = action.extended
                } else {
                    transfers[projectIndex].extended = !transfers[projectIndex].extended
                }
            }

            return { ...state, ...{ transfers: transfers } }

        case TransferConst.HIDE_ALL_PROJECT_PAYMENT_ORDERS:
            var transfers = [...state.transfers]

            transfers.forEach(p => {
                p.extended = false
            })
            return { ...state, ...{ transfers: transfers } }

        case TransferConst.DUPLICATE_PAYMENT_ORDER:
            console.log(action.paymentOrder)
            console.log('logging from potential error function')

            var transferData = {}
            transferData.title = action.paymentOrder.title
            transferData.recipient = action.paymentOrder.recipient
            transferData.accountNumber = action.paymentOrder.account_number
            transferData.price = parseFloat(action.paymentOrder.amount_to_pay).toFixed(2)
            transferData.currency = action.paymentOrder.currency
            transferData.dueDate = action.paymentOrder.due_date
            transferData.comment = action.paymentOrder.comment
            transferData.paymentType = action.paymentOrder.payment_type
            transferData.file = action.paymentOrder.file
            transferData.tags = action.paymentOrder.tags
            transferData.priority = action.paymentOrder.priority
            transferData.email_to_send_payment_confirmation = action.paymentOrder.email_to_send_payment_confirmation
            transferData.dueDate = transferData.priority ? moment().format('YYYY-MM-DD') : moment().add(1, 'days').format('YYYY-MM-DD')
            transferData.wl_project_code = action.paymentOrder.wl_project_code || ''
            transferData.wl_technology = action.paymentOrder.wl_technology || ''
            transferData.wl_devex_type_1 = action.paymentOrder.wl_devex_type_1 || ''
            transferData.wl_devex_type_2 = action.paymentOrder.wl_devex_type_2 || ''
            transferData.wl_transaction_description_pl = action.paymentOrder.wl_transaction_description_pl || ''
            transferData.wl_transaction_description_en = action.paymentOrder.wl_transaction_description_en || ''

            if (action.paymentOrder.currency !== 'PLN') {
                transferData.address = action.paymentOrder.address
                transferData.countryCode = action.paymentOrder.country_code
                transferData.swift = action.paymentOrder.swift
            }

            console.log('logging before potential error part')
            if (action.paymentOrder.file && action.paymentOrder.file.name && transferData.file && transferData.file.name) {
                var temp = transferData.file.name.split('/')
                transferData.file.name = temp[temp.length - 1]
            }
            console.log('logging after potential error part')

            var taxYear = 0, taxperiodType = '', taxPeriodNumber = 0
            if (action.paymentOrder.additional_payment_data && action.paymentOrder.additional_payment_data.period) {
                taxYear = parseInt(action.paymentOrder.additional_payment_data.period.slice(0, 2))
                transferData.periodType = action.paymentOrder.additional_payment_data.period.slice(2, 3)
                if (taxperiodType !== 'R') taxPeriodNumber = parseInt(action.paymentOrder.additional_payment_data.period.slice(3, 5))

                transferData.symbol = action.paymentOrder.additional_payment_data.tax_symbol
            }

            if (action.duplicatePeriod === 'month') {
                transferData.dueDate = moment(transferData.dueDate).add(1, 'month').format('YYYY-MM-DD')
                if (action.paymentOrder.additional_payment_data && transferData.periodType === 'M') {
                    if (taxPeriodNumber === 12) {
                        transferData.periodNumber = '01'
                        transferData.year = (parseInt(action.paymentOrder.additional_payment_data.year) + 1).toString()
                    } else {
                        transferData.periodNumber = taxPeriodNumber + 1

                        if (transferData.periodNumber < 10) {
                            transferData.periodNumber = '0' + transferData.periodNumber.toString
                        } else {
                            transferData.periodNumber = transferData.periodNumber.toString()
                        }
                    }

                    transferData.periodType = 'month'
                    transferData.periodName = months[parseInt(transferData.periodNumber) - 1]
                }
            } else if (action.duplicatePeriod === 'quarter') {
                transferData.dueDate = moment(transferData.dueDate).add(3, 'month').format('YYYY-MM-DD')

                if (action.paymentOrder.additional_payment_data && transferData.periodType === 'K') {
                    if (taxPeriodNumber === 4) {
                        transferData.periodNumber = '01'
                        transferData.year = (parseInt(action.paymentOrder.additional_payment_data.year) + 1).toString()
                    } else {
                        transferData.periodNumber = '0' + (taxPeriodNumber + 1).toString()
                    }

                    transferData.periodType = 'quarter'
                    transferData.periodName = quarters[parseInt(transferData.periodNumber) - 1]
                }
            } else if (action.duplicatePeriod === 'year') {
                transferData.dueDate = moment(transferData.dueDate).add(12, 'month').format('YYYY-MM-DD')

                if (action.paymentOrder.additional_payment_data && transferData.periodType === 'R') {
                    transferData.year = (parseInt(action.paymentOrder.additional_payment_data.year) + 1).toString()

                    transferData.periodType = 'year'
                }
            }

            return { ...state, ...{ transferDataToDuplicate: transferData, transferOrderProject: action.project, showCreateTransferOrderWindow: true, transferPreviewOpen: false } }

        case TransferConst.DATA_FROM_WA_EXCEL:
            var transferData = {
                paymentType: 'NORMAL',
                title: action.excelData.paymentTitle,
                recipient: action.excelData.companyName,
                address: action.excelData.companyAddress,
                countryCode: action.excelData.companyCountry,
                accountNumber: action.excelData.accountNumber,
                swift: action.excelData.swiftCode,
                price: action.excelData.amountToPay,
                currency: action.excelData.currency,
                fromExcel: true,
                file: action.file,
            }

            return { ...state, ...{ transferDataToDuplicate: transferData, transferOrderProject: action.projectId, showCreateTransferOrderWindow: true, transferPreviewOpen: false } }

        case TransferConst.DELETE_PROJECT:
            var transfers = [...state.transfers]
            var { transferPreviewData, transferPreviewFile, transferPreviewOpen, backupPTransferPreviewData } = state


            transfers = transfers.filter(t => t.project !== action.projectId)

            if (transferPreviewData && transferPreviewData.project_id === action.projectId) {
                transferPreviewData = {
                    id: null,
                }
                backupPTransferPreviewData = null
                transferPreviewFile = null
                transferPreviewOpen = false
            }

            return { ...state, ...{ transfers: transfers, transferPreviewData: transferPreviewData, transferPreviewFile: transferPreviewFile, transferPreviewOpen: transferPreviewOpen, backupPTransferPreviewData: backupPTransferPreviewData } }

        default:
            return state
    }
}