import { history } from "../helpers/history"
import routes from "../helpers/routes"
import myFetch from "../helpers/fetch"
import moment from 'moment'

import { ExcelSesionConsts } from "../reducers/ExcelSessionReducer"
import { CompanyConst } from "../reducers/CompanyReducer"
import { ReportsConst } from "../reducers/ReportsReducer"
import { EventConst } from "../reducers/EventReducer"
import { FileConst } from "../reducers/FileReducer"
import { UserConst } from "../reducers/UserReducer"
import { AlertActions } from "./AlertActions"

export const ExcelSessionActions = {
    startExcelSession,
    stopExcelSession,
    generateSessionExcel,
    addDocsToSession,
}

function startExcelSession() {
    return dispatch => {
        dispatch({ type: ExcelSesionConsts.START_SESSION })
    }
}

function stopExcelSession() {
    return dispatch => {
        dispatch({ type: ExcelSesionConsts.STOP_SESSION })
    }
}

function generateSessionExcel(sessionDocs) {
    return dispatch => {
        dispatch({ type: ExcelSesionConsts.GENERATE_EXCEL })

        myFetch(`/docs/genrate-session-report`, {
            method: 'post', body: {
                sessionDocs: sessionDocs
            }
        }).then(res => {
            if (res.success) {
                dispatch({ type: ExcelSesionConsts.STOP_SESSION })
                var dLink = document.createElement('a')
                dLink.download = res.name
                dLink.href = res.data
                dLink.click()
            } else {
                dispatch({ type: ExcelSesionConsts.GENERATE_EXCEL_ERROR })
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            dispatch({ type: ExcelSesionConsts.GENERATE_EXCEL_ERROR })
            handleError(err, dispatch, `/docs/genrate-session-report`)
        })
    }
}

function addDocsToSession(docs, projectId) {
    return dispatch => {
        dispatch({ type: ExcelSesionConsts.ADD_DOCS, docs: docs, companyId: projectId })
    }
}

function handleError(error, dispatch, path) {
    if (error?.code === 'AUTHORIZATION_REQUIRED' && localStorage.getItem('dashboardProject')) {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        dispatch({ type: UserConst.LOGGED_FALSE })
        dispatch({ type: UserConst.LOGOUT })
        dispatch({ type: EventConst.LOGOUT })
        dispatch({ type: CompanyConst.LOGOUT })
        dispatch({ type: ReportsConst.LOGOUT })
        dispatch({ type: FileConst.LOGOUT })
        dispatch(AlertActions.info("You have been logged out.", 5000))
    } else {
        dispatch(AlertActions.setError(error, path));
        dispatch(AlertActions.danger("There was an internal server error while procesing request. Try again later."));
    }
}