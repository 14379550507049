import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import TextareaAutosize from 'react-textarea-autosize'

import '../../../css/OcrPage.css'

import list from '../../../helpers/fakirDocTypes.json'

export class OcrPageWaproFakirDocTypes extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showList: false
		}

		this.wrapperRef = React.createRef();
	}

	componentDidMount() {
		document.addEventListener("mousedown", this.handleClick);
		window.addEventListener("keydown", this.escFunction, false)
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClick);
		window.removeEventListener("keydown", this.escFunction, false)
	}

	escFunction = e => {
		if (e.keyCode === 27) {
			this.handleClick(e)
		}
	}

	handleClick = (event) => {
		if (event.keyCode === 27 || (this.wrapperRef && !this.wrapperRef.current.contains(event.target))) {
			this.setState({
				showList: false
			})
		} else {
		}
	}

	toggleList = show => {
		this.setState({
			showList: show
		})
	}

	selectOption = code => {
		this.props.select({
			fakirType: code
		})
		this.setState({
			showList: false
		})
	}

	onInputChange = e => {
		e.preventDefault()
		const { name, value } = e.target

		this.setState({ [name]: value })
	}

	checkIfRowMatchesFilter = row => {
		const { filter } = this.state

		var show = false

		if (filter && row.code && row.description) {
			if (row.code.toLowerCase().includes(filter.toLocaleLowerCase())) show = true
			else if (row.description.toLowerCase().includes(filter.toLocaleLowerCase())) show = true
		} else {
			show = true
		}

		return show
	}

	render() {
		const { selectedOption } = this.props
		const { filter, showList } = this.state
		return (
			<div className={`form-group wapro-input-container half ${showList ? 'front' : ''} wapro`} id="wapro-fakir-types-container" ref={this.wrapperRef}>
				{
					showList ? (
						<div className="tags-list">
							<div className="header">
								{this.context.t('Available types:')}
							</div>
							<div className="filter-row">
								<input type="text" name="filter" value={filter} onChange={this.onInputChange} placeholder={this.context.t('Search')} autoFocus />
							</div>
							<SimpleBar style={{ height: 'auto', maxHeight: '300px', width: '100%' }}>
								<ul>
									{
										list.map((t, i) => {
											if (this.checkIfRowMatchesFilter(t)) {
												return (
													<li className={`tag-item ${selectedOption === t.code ? 'selected' : ''}`} onClick={() => this.selectOption(t.code)} key={`fakir-types-${i}`}>{`${t.code} - ${t.description}`}</li>
												)
											} else {
												return null
											}
										})
									}
								</ul>
							</SimpleBar>
						</div>
					) : (
						null
					)
				}
				<label>{this.context.t('Fakir types')}</label>
				<TextareaAutosize
					className={`tags-container`}
					value={selectedOption}
					name='fakir-types'
					minRows={1}
					maxRows={10}
					onFocus={() => this.toggleList(true)}
				/>
                <div className={`extend-icon ${showList ? 'extended' : ''}`} onClick={() => this.toggleList(!showList)}></div>
			</div>
		)
	}
}

OcrPageWaproFakirDocTypes.contextTypes = {
	t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(OcrPageWaproFakirDocTypes)