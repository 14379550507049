import myFetch from "../helpers/fetch"

import { TransferConst } from "../reducers/TransfersReducer"
import { AlertActions } from "./AlertActions"
import { ProjectCostsConsts } from "../reducers/ProjectCostsReducer"
import { CompanyConst } from "../reducers/CompanyReducer"
import { EventConst } from "../reducers/EventReducer"
import { FileConst } from "../reducers/FileReducer"
import { UserConst } from "../reducers/UserReducer"
import { ReportsConst } from "../reducers/ReportsReducer"

export const TransfersActions = {
    getAllMyTransfers,
    openAddTransferWindow,
    uploadWaPaymentOrderExcel,
    hideAddTransferWindow,
    openTransferPreviewWindow,
    hideTransferPreviewWindow,
    makeEditedTransferBackup,
    cancelTransferEdit,
    etidTransferField,
    removePreviewFile,
    setTransferPreviewFile,
    addTransfer,
    saveTransferChanges,
    deleteTransfer,
    switchSortingFilter,
    toggleProjectPaymentOrders,
    showDuplicatePaymentOrderWindow,

    markPaymentOrderAsPaid,
    editPaymentConfirmation
}

function openAddTransferWindow(project, dataFromUrl) {
    return dispatch => {
        dispatch({ type: TransferConst.SHOW_ADD_TRANSFER_WINDOW, project: project, dataFromUrl: dataFromUrl })
    }
}

function uploadWaPaymentOrderExcel(fileBody, fileName, projectId, cb) {
    return dispatch => {
        myFetch(`/cash-transfer-orders/read-wa-excel`, {
            method: 'post', body: {
                excelFileData: fileBody,
                excelFileName: fileName,
                projectId: projectId,
            }
        })
            .then(res => {
                if (res.success) {
                    cb(res.data)
                    dispatch({
                        type:
                            TransferConst.DATA_FROM_WA_EXCEL,
                        excelData: res.data,
                        file: {
                            base64: fileBody,
                            name: fileName,
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        },
                        projectId: projectId
                    })
                } else {
                    cb(null)
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                cb(null)
                handleError(err, dispatch, `/cash-transfer-orders/read-wa-excel`)
            })
    }
}

function hideAddTransferWindow() {
    return dispatch => {
        dispatch({ type: TransferConst.HIDE_ADD_TRANSFER_WINDOW })
    }
}

function openTransferPreviewWindow(transfer) {
    return dispatch => {
        if (transfer) {
            dispatch({ type: TransferConst.SHOW_TRANSFER_PREVIEW_WINDOW, transfer: transfer })
            if (transfer.attachment_name) {
                dispatch({ type: TransferConst.DOWNLOAD_FILE, data: 'loading' })
                myFetch(`/cash-transfer-orders/download-file/?projectId=${transfer.project_id}&transferId=${transfer.id}`, { method: 'get' })
                    .then(res => {
                        if (res.success) {
                            dispatch({ type: TransferConst.DOWNLOAD_FILE, error: false, data: res.data, name: res.name, fileType: res.type })
                        } else {
                            dispatch(AlertActions.warning(res.msg))
                            dispatch({ type: TransferConst.DOWNLOAD_FILE, error: true })
                        }
                    }).catch(err => {
                        handleError(err, dispatch, `/cash-transfer-orders/download-file/?projectId=${transfer.project_id}&transferId=${transfer.id}`)
                    })
            } else {
                dispatch({ type: TransferConst.DOWNLOAD_FILE, data: null })
            }
        } else {
            dispatch({ type: TransferConst.SHOW_TRANSFER_PREVIEW_WINDOW })
        }
    }
}

function hideTransferPreviewWindow() {
    return dispatch => {
        dispatch({ type: TransferConst.HIDE_TRANSFER_PREVIEW_WINDOW })
    }
}

function addTransfer(paymentOrderData, cb = () => { }) {
    return dispatch => {
        dispatch({ type: TransferConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/cash-transfer-orders/create`, {
            method: 'post', body: {
                data: paymentOrderData
            }
        }).then(res => {
            cb(res.success)
            if (res.success) {
                if (paymentOrderData.counterpartyId === 0) {
                    dispatch({
                        type: CompanyConst.ADD_COUNTERPARTY,
                        companyId: paymentOrderData.currentCompanyId,
                        newCounterparty: {
                            company_id: paymentOrderData.currentCompanyId,
                            name: paymentOrderData.recipient,
                            last_account_number: paymentOrderData.account_number
                        }
                    })
                }
                dispatch({ type: TransferConst.LOADING_SCREEN, loadingScreen: false })

                dispatch({ type: TransferConst.ADD_TRANSFER, projectId: paymentOrderData.projectId, transfer: { ...res.newTransfer, paid_by: null, payment_method: null } })
            } else {
                dispatch({ type: TransferConst.LOADING_SCREEN, loadingScreen: false })
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            cb(false)
            dispatch({ type: TransferConst.LOADING_SCREEN, loadingScreen: false })
            handleError(err, dispatch, `/cash-transfer-orders/create`)
        })
    }
}

function getAllMyTransfers(projectId, transferId, cb = () => { }) {
    return dispatch => {
        dispatch({ type: TransferConst.GET_TRANSFERS_REQUEST, projectId: projectId })
        myFetch(`/cash-transfer-orders/get-mine/?projectId=${projectId}&transferId=${transferId}`, { method: 'get' })
            .then(res => {
                if (res.success) {
                    dispatch({ type: TransferConst.GET_TRANSFERS, projectId: projectId, transfers: res.transfers })
                    cb(res.transfers)
                } else {
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                handleError(err, dispatch, `/cash-transfer-orders/get-mine/?projectId=${projectId}&transferId=${transferId}`)
            })
    }

}

function makeEditedTransferBackup() {
    return dispatch => {
        dispatch({ type: TransferConst.MAKE_TRANSFER_BACKUP })
    }
}

function cancelTransferEdit() {
    return dispatch => {
        dispatch({ type: TransferConst.LOAD_TRANSFER_BACKUP })
    }
}

function etidTransferField(field, value) {
    return dispatch => {
        dispatch({ type: TransferConst.EDIT_TRANSFER_INPUT, field: field, value: value })
    }
}

function removePreviewFile() {
    return dispatch => {
        dispatch({ type: TransferConst.REMOVE_PREVIEW_FILE })
    }
}

function setTransferPreviewFile(file) {
    return dispatch => {
        dispatch({ type: TransferConst.SET_PREVIEW_FILE, file: file })
    }
}

function saveTransferChanges(transfer, file, accept) {
    transfer.recipient = transfer.recipient.replace(/\n/gm, ' ')
    transfer.title = transfer.title.replace(/\n/gm, ' ')
    return dispatch => {
        dispatch({ type: TransferConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/cash-transfer-orders/save-changes`, {
            method: 'put', body: {
                transfer: transfer,
                file: file,
                accept: accept
            }
        })
            .then(res => {
                if (res.success) {
                    dispatch({ type: TransferConst.LOADING_SCREEN, loadingScreen: false })
                    dispatch({ type: TransferConst.SAVE_TRANSFER_CHANGES, transfer: { ...res.transfer, ...{ amount_to_pay: parseFloat(transfer.amount_to_pay).toFixed(4) } } })
                    dispatch({ type: ReportsConst.UPDATE_DOWNLOADED_PAYMENT_ORDERS, transfer: { ...res.transfer, ...{ amount_to_pay: parseFloat(transfer.amount_to_pay).toFixed(4) } } })
                    dispatch(AlertActions.info("Payment order updated."))
                } else {
                    dispatch({ type: TransferConst.LOADING_SCREEN, loadingScreen: false })
                    dispatch(AlertActions.info(res.msg))
                }
            }).catch(err => {
                dispatch({ type: TransferConst.LOADING_SCREEN, loadingScreen: false })
                handleError(err, dispatch, `/cash-transfer-orders/save-changes`)
            })
    }
}

function deleteTransfer(transferId, projectId) {
    return dispatch => {
        dispatch({ type: TransferConst.LOADING_SCREEN, loadingScreen: true })
        myFetch(`/cash-transfer-orders/delete`, { method: 'post', body: { transferId: transferId } })
            .then(res => {
                if (res.success) {
                    dispatch({ type: TransferConst.LOADING_SCREEN, loadingScreen: false })
                    dispatch({ type: TransferConst.DELETE_TRANSFER, projectId: projectId, transferId: transferId })
                    dispatch({ type: ReportsConst.DELETE_FILE_FROM_REPORT, id: transferId })
                    dispatch(AlertActions.info("Payment order deleted from system."))
                } else {
                    dispatch(AlertActions.info(res.msg))
                }
            }).catch(err => {
                dispatch({ type: TransferConst.LOADING_SCREEN, loadingScreen: false })
                handleError(err, dispatch, `/cash-transfer-orders/delete`)
            })
    }
}

function switchSortingFilter(project, sortField) {
    return dispatch => {
        dispatch({ type: TransferConst.SORT_TRANSFERS, projectId: project, name: sortField })
    }
}

function toggleProjectPaymentOrders(projectId, extended) {
    return dispatch => {
        dispatch({ type: TransferConst.TOGGLE_PROJECT_PAYMENT_ORDERS, projectId: projectId, extended: extended })
    }
}

function showDuplicatePaymentOrderWindow(project, paymentOrder, duplicatePeriod) {
    return dispatch => {
        dispatch({ type: TransferConst.DUPLICATE_PAYMENT_ORDER, project: project, paymentOrder: paymentOrder, duplicatePeriod: duplicatePeriod })
    }
}

function markPaymentOrderAsPaid(data) {
    return dispatch => {
        dispatch({ type: ReportsConst.MARK_DOC_AS_PAID, id: data.paymentOrderId, mark: data.mark, method: data.method })
        dispatch({ type: TransferConst.MARK_TRANSFER_AS_PAID, id: data.paymentOrderId, projectId: data.projectId, method: data.method, userId: data.userId })

        myFetch(`/projects/get-costs/${data.projectId}`, { methid: 'get' }).then(res => {
            if (res.success) {
                dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: data.projectId, costs: res.costs })
            } else {
                dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: data.projectId, costs: {} })
            }
        }).catch(err => {
            dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: data.projectId, costs: {} })
            handleError(err, dispatch, `/projects/get-costs/${data.projectId}`)
        })

    }
}

function editPaymentConfirmation(transferId, paymentConfirmationEmail) {
    return dispatch => {
        dispatch({ type: TransferConst.EDIT_TRANSFER_INPUT, field: 'email_to_send_payment_confirmation', value: paymentConfirmationEmail })
        myFetch(`/cash-transfer-orders/edit-payment-confirmation`, {
            method: 'put', body: {
                transferId: transferId,
                paymentConfirmation: paymentConfirmationEmail
            }
        }).then(res => {
            if (res.success) {
                dispatch({ type: TransferConst.SAVE_TRANSFER_CHANGES, transfer: { ...res.updatedTransferInstance, ...{ amount_to_pay: parseFloat(res.updatedTransferInstance.amount_to_pay).toFixed(4) } } })
            }
        }).catch(err => {
            handleError(err, dispatch, `/cash-transfer-orders/edit-payment-confirmation`)
        })

    }
}

function handleError(error, dispatch, path) {
    if (error?.code === 'AUTHORIZATION_REQUIRED' && localStorage.getItem('dashboardProject')) {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        dispatch({ type: UserConst.LOGGED_FALSE })
        dispatch({ type: UserConst.LOGOUT })
        dispatch({ type: EventConst.LOGOUT })
        dispatch({ type: CompanyConst.LOGOUT })
        dispatch({ type: ReportsConst.LOGOUT })
        dispatch({ type: FileConst.LOGOUT })
        dispatch(AlertActions.info("You have been logged out.", 5000))
    } else {
        dispatch(AlertActions.setError(error, path));
        dispatch(AlertActions.danger("There was an internal server error while procesing request. Try again later."));
    }
}