import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'

import currencyList from '../../helpers/currencyList.json'
import isIterable from "../../helpers/isIterable"
import arrayObjectIndexOf from '../../helpers/indexOfObject.js'
import availableQuickActions from '../../helpers/availableQuickActions'

import QuickActionPopupHeader from './QuickActionPopupHeader'
import DocsSearchModal from './DocsSearchModal'
import SelectedExpensesSummaryRowWithAccounts from './SelectedExpensesSummaryRowWithAccounts'
import ExpensesTableVirtualized from './ExpensesTableVirtualized'
import ExpensesTable from './ExpensesTable'
import FilesPreviewInReports from '../ReportsPageComponents/FilesPreviewInReports'
import AnimatedCheckmark from '../AnimatedCheckmark'

import { ReportsActions } from '../../actions/ReportsActions'
import { FileActions } from '../../actions/FileActions'
import { TransfersActions } from '../../actions/TransfersActions'
import { UserActions } from '../../actions/UserActions'
import { CompanyActions } from '../../actions/CompanyActions'
import { AlertActions } from '../../actions/AlertActions'

import '../../css/QuickActions.css'
import '../../css/Report.css'
import { Spinner } from 'react-activity'

export class ExportBankTransfers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tableHeight: (window.innerHeight * 0.95) - 400,
            showVirtualizedTable: false,
            allDocsSelected: false,
            usersList: [],
            docsSelectedForReport: [],
            roleInSelectedProject: '',
            selectedDocsSums: Object.fromEntries(
                currencyList.map(c => {
                    return ([c, 0])
                })
            ),
            previewFile: {},
            availableAccounts: [],
            selectedAccounts: Object.fromEntries(
                currencyList.map(c => {
                    return ([c, { id: 0 }])
                })
            ),
            advancePaymentReceived: Object.fromEntries(
                currencyList.map(c => {
                    return ([c, '0.00'])
                })
            ),
            additionalComments: '',

            highlightTable: false,
            highlightAccounts: false,
            highlightOptions: false,

            userId: null,
            userName: '',
            showAllNotSettled: true,
            dateFilter: 'importDate',
            period: ''
        }
        this.tableRef = React.createRef()
    }

    componentDidMount() {
        const { user, currentProject } = this.props

        if (user) {
            if (currentProject.acceptance_permissions === 'USER1') {
                this.setState({
                    userId: user.id,
                    userName: `${user.first_name} ${user.last_name}`,
                    availableAccounts: user.banks,
                    usersList: [{
                        userId: user.id,
                        userName: `${user.first_name} ${user.last_name}`,
                        banks: user.banks,
                    }]
                })
                this.setDefaultSelectedAccounts(user.banks)
            }
        }
        if (user.new_easydocs_version && currentProject?.id) {
            var defaultDateFrom = moment().subtract(1, 'month').startOf('month')
            var defaultDateTo = moment()
            if (currentProject?.acceptance_permissions === 'USER1')
                this.props.getDocuments(defaultDateFrom, defaultDateTo, currentProject.id, 'Costs', 'importDate', null, { userId: user.id, allNotSettled: true })
            else
                this.props.getDocuments(defaultDateFrom, defaultDateTo, currentProject.id, 'Expenses', 'importDate', null, { allNotSettled: true })
        }

        if (currentProject && currentProject?.id && currentProject?.acceptancePermission !== 'USER1') {
            this.props.getUsersListForExpensesReport(currentProject.id, usersList => {
                this.setState({
                    usersList: usersList
                })
            })
        }

        window.addEventListener("keydown", this.keyDownHandler, false)
    }

    componentDidUpdate(prevProps) {
        const { currentProject, user, docsDownloadedForRaport, sendGetDocumentsRequest } = this.props

        if (prevProps.currentProject && prevProps.currentProject.id !== currentProject.id) {
            if (currentProject && currentProject.id && currentProject.acceptancePermission !== 'USER1') {
                this.props.getUsersListForExpensesReport(currentProject.id, usersList => {
                    this.setState({
                        usersList: usersList
                    })
                })
            }
        }

        if (!prevProps.sendGetDocumentsRequest && sendGetDocumentsRequest) {
            this.clearStateOnDocsSearch()
        }

        if (prevProps.docsDownloadedForRaport && prevProps.docsDownloadedForRaport.length === 0 && docsDownloadedForRaport && docsDownloadedForRaport.length > 0) {
            this.setState({
                docsSelectedForReport: [],
                previewFile: {}
            }, () => {
                this.setTableHeight()
            })
        }


        if (user.new_easydocs_version && currentProject?.id && currentProject?.id !== prevProps.currentProject?.id) {
            var defaultDateFrom = moment().subtract(1, 'month').startOf('month')
            var defaultDateTo = moment()
            if (currentProject?.acceptance_permissions === 'USER1')
                this.props.getDocuments(defaultDateFrom, defaultDateTo, currentProject.id, 'Costs', 'importDate', null, { userId: user.id, allNotSettled: true })
            else
                this.props.getDocuments(defaultDateFrom, defaultDateTo, currentProject.id, 'Expenses', 'importDate', null, { allNotSettled: true })
        }
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.keyDownHandler, false)
    }

    onInputChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }

    keyDownHandler = e => {
        if (e.keyCode === 27) {
            //ESC
            // const { previewFile } = this.state
            // if (!previewFile || previewFile === {}) {
            //     this.props.close()
            // }
            //  TODO: needs to take filters modal into account
        } else if (e.keyCode === 38) {
            //UP ARROW
            this.goToPrevProject()
        } else if (e.keyCode === 40) {
            //DOWN ARROW
            this.goToNextProject()
        }
    }

    goToPrevProject = () => {
        const { userProjects, currentProject } = this.props
        var projectIndex = arrayObjectIndexOf(userProjects, currentProject.id, 'id')

        console.log(projectIndex)
        if (projectIndex > 0) {
            var prevProject = userProjects[projectIndex - 1]
            while (projectIndex > 0 && !prevProject.payments) {
                projectIndex -= 1
                prevProject = userProjects[projectIndex]
            }

            if (prevProject.payments) {
                this.props.switchDashboardViewModel(prevProject.id)
                this.loadNewDocsAfterProjectSwitch(prevProject.id)

                const { userCompanies } = this.props
                var newCompanyIndex = arrayObjectIndexOf(userCompanies, prevProject.company.id, 'company_id')
                if (newCompanyIndex > -1) {
                    this.props.setCurrentCompany(userCompanies[newCompanyIndex])
                }
            }

        }
    }

    goToNextProject = () => {
        const { userProjects, currentProject } = this.props
        var projectIndex = arrayObjectIndexOf(userProjects, currentProject.id, 'id')

        console.log(projectIndex)
        if (projectIndex < userProjects.length - 1) {
            var prevProject = userProjects[projectIndex + 1]
            while (projectIndex < userProjects.length - 1 && !prevProject.payments) {
                projectIndex += 1
                prevProject = userProjects[projectIndex]
            }

            if (prevProject.payments) {
                this.props.switchDashboardViewModel(prevProject.id)
                this.loadNewDocsAfterProjectSwitch(prevProject.id)

                const { userCompanies } = this.props
                var newCompanyIndex = arrayObjectIndexOf(userCompanies, prevProject.company.id, 'company_id')
                if (newCompanyIndex > -1) {
                    this.props.setCurrentCompany(userCompanies[newCompanyIndex])
                }
            }

        }
    }

    clearStateOnDocsSearch = () => {
        this.setState({
            docsSelectedForReport: [],
            selectedDocsSums: Object.fromEntries(
                currencyList.map(c => {
                    return ([c, 0])
                })
            ),
            selectedAccounts: Object.fromEntries(
                currencyList.map(c => {
                    return ([c, { id: 0 }])
                })
            ),
            previewFile: {}
        })
    }

    setTableHeight = () => {
        const { docsDownloadedForRaport } = this.props
        var heightToFitAllDocs = docsDownloadedForRaport.length * 40 + 40

        if (heightToFitAllDocs < (window.innerHeight * 0.95) - 400) {
            this.setState({
                tableHeight: heightToFitAllDocs,
                showVirtualizedTable: false
            })
        } else {
            this.setState({
                tableHeight: (window.innerHeight * 0.95) - 400,
                showVirtualizedTable: true
            })
        }
    }

    setDefaultSelectedAccounts = accounts => {
        var currentSelectedAccounts = Object.fromEntries(
            currencyList.map(c => {
                return ([c, { id: 0 }])
            })
        )

        accounts.forEach(bankAccount => {
            if (bankAccount && bankAccount.currency && currentSelectedAccounts[bankAccount.currency] && currentSelectedAccounts[bankAccount.currency].id === 0) {
                currentSelectedAccounts[bankAccount.currency] = bankAccount
            }
        })

        this.setState({
            selectedAccounts: currentSelectedAccounts
        })
    }

    selectAccount = (currency, bankAccount) => {
        var currentSelectedAccounts = { ...this.state.selectedAccounts }

        currentSelectedAccounts[currency] = bankAccount

        this.setState({
            selectedAccounts: currentSelectedAccounts
        })
    }

    setAdvancePaymentReceived = (currency, value) => {
        var currentAdvancePaymentReceived = { ...this.state.advancePaymentReceived }

        currentAdvancePaymentReceived[currency] = value.replace(/[^0-9.,]/gm, '').replace(',', '.')

        this.setState({
            advancePaymentReceived: currentAdvancePaymentReceived
        })
    }

    selectAll = () => {
        const { docsDownloadedForRaport } = this.props
        const { userId } = this.state
        var currentSelectedDocs = [...this.state.docsSelectedForReport]
        var { selectedDocsSums } = this.state
        var oneSelected = false
        var currentUser = userId

        docsDownloadedForRaport.forEach(doc => {
            if (!currentUser || currentUser === doc.uploadedById) {
                if (!currentSelectedDocs.find(d => d.id === doc.id) && !doc.alreadyInBankTransferFile) {
                    currentSelectedDocs.push({ id: doc.id, status: doc.status, fileId: doc.fileId, type: doc.type, currency: doc.currency })
                    oneSelected = true

                    selectedDocsSums[doc.currency] += parseFloat(doc.price)
                }

                if (!currentUser) {
                    currentUser = doc.uploadedById
                }
            }
        })
        this.setState({
            docsSelectedForReport: oneSelected ? currentSelectedDocs : [],
            selectedDocsSums: oneSelected ? selectedDocsSums : Object.fromEntries(
                currencyList.map(c => {
                    return ([c, 0])
                })
            ),
            allDocsSelected: oneSelected
        })

        if (!oneSelected) {
            this.setState({
                availableAccounts: [],
                userId: null,
                userName: 'All employees'
            })
        }

        if (currentUser !== userId) {
            const { usersList } = this.state
            var expensesUser = usersList.find(u => u.id === currentUser)
            if (expensesUser) {
                this.setState({
                    availableAccounts: expensesUser.banks || [],
                    userId: expensesUser.id || null,
                    userName: expensesUser.name || 'All employees'
                })
                this.setDefaultSelectedAccounts(expensesUser.banks || [])
            }
        }
    }

    addDocToSelected = (e, doc) => {
        e.preventDefault()
        const { userId } = this.state
        var currentSelected = this.state.docsSelectedForReport
        var currentUser = userId

        if (!doc.alreadyInBankTransferFile) {
            if (!currentUser || currentUser === doc.uploadedById) {
                if (currentSelected.find(e => e.id === doc.id)) {
                    var { selectedDocsSums } = this.state
                    selectedDocsSums[doc.currency] -= parseFloat(doc.price)
                    var newDocsSelectedForReport = currentSelected.filter(e => {
                        if (e.id === doc.id && e.type === doc.type) return false
                        return true
                    })

                    this.setState({
                        docsSelectedForReport: newDocsSelectedForReport,
                        selectedDocsSums: selectedDocsSums,
                        allDocsSelected: false
                    })

                    if (newDocsSelectedForReport.length === 0) {
                        this.setState({
                            availableAccounts: [],
                            userId: null,
                            userName: 'All employees'
                        })
                    }
                } else {
                    currentUser = doc.uploadedById
                    currentSelected.push({ id: doc.id, status: doc.status, fileId: doc.fileId, type: doc.type, currency: doc.currency, price: doc.price })
                    var { selectedDocsSums } = this.state
                    selectedDocsSums[doc.currency] += parseFloat(doc.price)

                    this.setState({
                        docsSelectedForReport: currentSelected,
                        selectedDocsSums: selectedDocsSums,
                    })

                    if (currentUser !== userId) {
                        const { usersList } = this.state
                        var expensesUser = usersList.find(u => u.id === currentUser)
                        if (expensesUser) {
                            this.setState({
                                availableAccounts: expensesUser.banks || [],
                                userId: expensesUser.id || null,
                                userName: expensesUser.name || 'All employees'
                            })
                            this.setDefaultSelectedAccounts(expensesUser.banks || [])
                        }
                    }
                }
            } else {
                this.props.alertWarn(this.context.t('You can only select expenses of one employee at a time'))
            }
        }
    }

    downloadReport = (e, projectId, reportName) => {
        e.preventDefault()

        this.props.downloadReport(projectId, reportName)
    }

    fileIconClick = (e, projectId, file, id, docType, fileId, alreadyPaid) => {
        this.props.toogleFilePreviewInActions(true)
        this.setState({
            previewFile: {
                projectId: projectId,
                file: file,
                id: id,
                docType: docType
            }
        })
        this.props.getFileBody(parseInt(projectId), file, fileId, alreadyPaid ? 'DONE' : 'IN_PROGRESS', alreadyPaid ? 4 : 3)
    }

    setCurrentDocPreview = (doc) => {
        this.setState({
            previewFile: doc
        })
    }

    hideFilePreview = () => {
        this.props.toogleFilePreviewInActions(false)
        this.setState({
            previewFile: {}
        })
    }

    checkIfDocMatchesFilterOptions = () => {
        return true
    }
    execute = () => {
        const { docsSelectedForReport, selectedAccounts, advancePaymentReceived, additionalComments } = this.state
        const { currentProject } = this.props
        var valid = true
        var docsSplitByCurrency = Object.fromEntries(
            currencyList.map(c => {
                return ([c, { docs: [], bankId: 0 }])
            })
        )

        if (docsSelectedForReport.length === 0) {
            valid = false
            this.props.alertWarn(this.context.t('You must select at least one document'))
        } else {
            docsSelectedForReport.map(d => {
                if (docsSplitByCurrency[d.currency]) {
                    docsSplitByCurrency[d.currency].docs.push(d)
                }
            })

            for (const currency in selectedAccounts) {
                if (selectedAccounts.hasOwnProperty(currency) && docsSplitByCurrency.hasOwnProperty(currency)) {
                    docsSplitByCurrency[currency].bankId = selectedAccounts[currency].id
                    if (docsSplitByCurrency[currency].docs.length && !docsSplitByCurrency[currency].bankId) {
                        valid = false
                        this.props.alertWarn(this.context.t('You must select a bank account for documents in the currency: {n}', { n: currency }))
                    }
                }

                if (selectedAccounts.hasOwnProperty(currency) && advancePaymentReceived.hasOwnProperty(currency)) {
                    docsSplitByCurrency[currency].advancePayment = advancePaymentReceived[currency]
                }
            }
        }

        if (valid) {
            var currenciesToGenerate = []
            for (const currency in selectedAccounts) {
                if (docsSplitByCurrency.hasOwnProperty(currency)) {
                    if (docsSplitByCurrency[currency].docs.length && docsSplitByCurrency[currency].bankId) {
                        currenciesToGenerate.push(currency)
                    }
                }
            }

            this.setState({
                currenciesToGenerate: currenciesToGenerate,
                showActionsLoadingWindow: true
            })

            this.generateReports(currenciesToGenerate, docsSplitByCurrency, () => {
                this.setState({
                    finishedAllActions: true
                })
            })
        }
    }

    generateReports = async (currenciesToGenerate, docsSplitByCurrency, callback) => {
        const { currentProject } = this.props
        const { additionalComments } = this.state
        var currenciesCount = currenciesToGenerate.length

        for (var i = 0; i < currenciesCount; i++) {
            var currency = currenciesToGenerate[i]
            await new Promise((resolve, reject) => {
                this.props.generateExpensesReport(docsSplitByCurrency[currency].docs, currentProject.id, currency, docsSplitByCurrency[currency].bankId, docsSplitByCurrency[currency].advancePayment, additionalComments, () => {
                    resolve()
                    this.setState({
                        [`finishedGenerating${currency}`]: true
                    })
                })
            })
        }
        callback()
    }

    closeAction = () => {
        const { finishedAllActions } = this.state

        if (finishedAllActions) {
            this.setState({
                showActionsLoadingWindow: false,
                finishedAllActions: false,
            })
        }
    }

    handleMouseEnterStep = step => {
        switch (step) {
            case 1:
                this.setState({
                    highlightTable: true
                })
                break
            case 2:
                this.setState({
                    highlightAccounts: true
                })
                break
            case 3:
                this.setState({
                    highlightOptions: true
                })
                break
        }
    }

    handleMouseLeaveStep = step => {
        switch (step) {
            case 1:
                this.setState({
                    highlightTable: false
                })
                break
            case 2:
                this.setState({
                    highlightAccounts: false
                })
                break
            case 3:
                this.setState({
                    highlightOptions: false
                })
                break
        }
    }

    getFiltersFromSearchForm = (data) => {
        this.setState({
            highlightTable: false,
            showAllNotSettled: data.showAllNotSettled,
            dateFilterText: data.dateFilterText,
            period: data.periodText,
            dateFilter: data.dateFilter,
            startDate: data.startDate,
            endDate: data.endDate,
            availableAccounts: data.selectedUser.banks || [],
            userId: data.selectedUser.id || null,
            userName: data.selectedUser.name || 'All employees'
        })
        this.setDefaultSelectedAccounts(data.selectedUser.banks || [])
    }

    loadNewDocsAfterProjectSwitch = projectId => {
        const { startDate, endDate, dateFilter, showAllNotSettled, userId } = this.state
        var additionalFilteringCriteria = {
            userId: userId,
            allNotSettled: showAllNotSettled,
        }
        this.props.getDocuments(startDate, endDate, projectId, 'Expenses', dateFilter, null, additionalFilteringCriteria)
    }

    render() {
        const {
            highlightTable, highlightAccounts, highlightOptions,
            showAllNotSettled, dateFilterText, period, usersList, userId, userName,
            tableHeight, showVirtualizedTable, allDocsSelected, docsSelectedForReport, previewFile,
            selectedDocsSums, roleInSelectedProject, availableAccounts, selectedAccounts, advancePaymentReceived,
            additionalComments,
            showActionsLoadingWindow, currenciesToGenerate, finishedAllActions
        } = this.state
        const { docsDownloadedForRaport, sendGetDocumentsRequest, user, tabIsActive } = this.props

        if (tabIsActive || !user.new_easydocs_version) {
            return (
                <div className={`quic-actions-popup reports ${showActionsLoadingWindow ? 'waiting-for-actions' : ''} ${user.new_easydocs_version ? 'new-easydocs-version' : ''}`}>

                    {
                        previewFile && previewFile.id ? (
                            <FilesPreviewInReports
                                docsList={docsDownloadedForRaport}
                                location={{
                                    pathname: '/actions/preview/'
                                }}
                                previewFile={previewFile}
                                close={this.hideFilePreview}
                                setCurrentDoc={this.setCurrentDocPreview}
                                checkIfDocMatchesFilterOptions={this.checkIfDocMatchesFilterOptions}
                            />
                        ) : (
                            null
                        )
                    }

                    {
                        showActionsLoadingWindow ? (
                            <div className="actions-loading-window">
                                <div className="actions">
                                    <h4>
                                        {finishedAllActions ? this.context.t('Ready!') : this.context.t('Just a moment...')}
                                    </h4>

                                    {
                                        currenciesToGenerate.map(currency => {
                                            return (
                                                <div className="action">
                                                    {
                                                        this.state[`finishedGenerating${currency}`] ? (
                                                            <AnimatedCheckmark />
                                                        ) : (
                                                            <div className="loading-action">
                                                                <Spinner color={'#69B792'} size={14} speed={0.8} />
                                                            </div>
                                                        )
                                                    }

                                                    <div className="action-text">
                                                        {this.context.t('Generating {c} expenses report', { c: currency })}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                    <div className={`close-button ${finishedAllActions ? 'active' : ''}`} onClick={() => this.closeAction()}>{this.context.t('Close')}</div>
                                </div>
                            </div>
                        ) : null
                    }

                    <div className={`content`}>
                        <QuickActionPopupHeader
                            title={"Expenses assistant"}
                            type={"expensesAssistane"}
                            close={() => this.props.toggleQuickAction(availableQuickActions.expensesAssistant, false)}
                            getDocs={this.loadNewDocsAfterProjectSwitch}
                        />

                        <div className="action-instructions">
                            <div className="steps-wrapper">
                                <div className="step" onMouseEnter={() => this.handleMouseEnterStep(1)} onMouseLeave={() => this.handleMouseLeaveStep(1)}>
                                    <div className="number">1</div>
                                    <div className="description">{this.context.t('Select expenses you want to settle')}</div>
                                    <DocsSearchModal
                                        clearParentStateOnDocsSearch={this.clearStateOnDocsSearch}
                                        selectedType={"Expenses"}
                                        searchForNotSettled={true}
                                        usersList={usersList}
                                        userId={userId}
                                        setFilters={this.getFiltersFromSearchForm}
                                        closeActionOnEsc={() => this.props.toggleQuickAction(availableQuickActions.expensesAssistant, false)}
                                    />
                                </div>
                                <div className="step" onMouseEnter={() => this.handleMouseEnterStep(2)} onMouseLeave={() => this.handleMouseLeaveStep(2)}>
                                    <div className="number">2</div>
                                    <div className="description">{this.context.t('Select the accounts where expenses should be reimbursed')}</div>
                                </div>
                                <div className="step" onMouseEnter={() => this.handleMouseEnterStep(3)} onMouseLeave={() => this.handleMouseLeaveStep(3)}>
                                    <div className="number">3</div>
                                    <div className="description">{this.context.t('Additional options')}</div>
                                </div>
                            </div>
                        </div>

                        <div className={`hightlight-container ${highlightTable ? 'highlight' : ''}`}>
                            <span className="number">1</span>
                            {
                                sendGetDocumentsRequest && docsDownloadedForRaport.length === 0 ? (
                                    <div className="docs-loading-for-report">
                                        <div className="image"></div>
                                        <div className="text">
                                            <div className="header">
                                                {this.context.t('Loading')}
                                            </div>
                                            <div className="instruction">
                                                {this.context.t('Please wait a second while we are retriving, loading and calculating your data')}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    docsDownloadedForRaport.length > 0 ? (
                                        showVirtualizedTable ? (
                                            <ExpensesTableVirtualized
                                                tableHeight={tableHeight}
                                                setTableRef={this.tableRef}
                                                searchField={''}
                                                searchCurrency={'ALL'}
                                                selectOnlyAvailableForReport={false}
                                                selectOnlyAvailableForBankTransferFile={false}
                                                selectOnlyNotPaid={false}
                                                selectOnlyNotAccounted={false}
                                                selectFor={'transfer'}
                                                roleInSelectedProject={roleInSelectedProject}
                                                docsSelectedForReport={[...docsSelectedForReport]}
                                                selectAll={this.selectAll}
                                                allDocsSelected={allDocsSelected}
                                                addDocToSelected={this.addDocToSelected}
                                                fileIconClick={this.fileIconClick}
                                                downloadReport={this.downloadReport}
                                                togglePaymentList={this.togglePaymentForDoc}
                                                toggleAccountedStatus={this.toggleAccountedStatus}
                                            />
                                        ) : (
                                            <ExpensesTable
                                                searchField={''}
                                                searchCurrency={'ALL'}
                                                selectOnlyAvailableForReport={false}
                                                selectOnlyAvailableForBankTransferFile={false}
                                                selectOnlyNotPaid={false}
                                                selectOnlyNotAccounted={false}
                                                selectFor={'transfer'}
                                                roleInSelectedProject={roleInSelectedProject}
                                                docsSelectedForReport={[...docsSelectedForReport]}
                                                selectAll={this.selectAll}
                                                allDocsSelected={allDocsSelected}
                                                addDocToSelected={this.addDocToSelected}
                                                fileIconClick={this.fileIconClick}
                                                downloadReport={this.downloadReport}
                                                togglePaymentList={this.togglePaymentForDoc}
                                                toggleAccountedStatus={this.toggleAccountedStatus}
                                            />
                                        )
                                    ) : (
                                        <div className="no-docs-found">
                                            <div className="image"></div>
                                            <div className="text">
                                                <div className="header">
                                                    {
                                                        showAllNotSettled ? (
                                                            this.context.t('{e} has no unsettled expenses', { e: userName })
                                                        ) : (
                                                            this.context.t('{e} has no unpaid expenses in the {d} period {p}', { e: userName, d: this.context.t(dateFilterText), p: period })
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )
                            }
                        </div>

                        {
                            docsDownloadedForRaport.length > 0 ? (
                                <div className="footer-container">
                                    <div className={`hightlight-container accounts ${highlightAccounts ? 'highlight' : ''}`}>
                                        <span className="number">2</span>
                                        <SelectedExpensesSummaryRowWithAccounts
                                            selectedDocsCount={docsSelectedForReport.length}
                                            selectedDocsSums={selectedDocsSums}
                                            availableAccounts={availableAccounts}
                                            selectedAccounts={selectedAccounts}
                                            selectAccount={this.selectAccount}
                                            advancePaymentReceived={advancePaymentReceived}
                                            setAdvancePaymentReceived={this.setAdvancePaymentReceived}
                                        />

                                        {
                                            docsSelectedForReport.length === 0 ? (
                                                <div className="no-selected-documents">
                                                    {this.context.t('You must select at least one document')}
                                                </div>
                                            ) : null
                                        }
                                    </div>


                                    <div className={`hightlight-container options ${highlightOptions ? 'highlight' : ''}`}>
                                        <span className="number">3</span>
                                        <div className="form-group">
                                            <input type="text" id="additionalComments" name="additionalComments" onChange={this.onInputChange} value={additionalComments} />
                                            <label htmlFor="additionalComments">{this.context.t('Expenses report comment')}</label>
                                        </div>

                                        <div className="confirm-button" onClick={() => this.execute()}>{this.context.t("Execute")}</div>
                                    </div>
                                </div>
                            ) : null
                        }
                    </div>
                </div>
            )
        } else {
            return null
        }
    }
}

ExportBankTransfers.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
    user: state.User.user,
    currentProject: state.User.currentProject,
    userProjects: state.User.userProjects,
    groupedProjects: state.User.groupedProjects,
    userCompanies: state.Company.companies,
    currentCompany: state.Company.currentCompany,
    docsDownloadedForRaport: isIterable(state.Reports.docsDownloadedForRaport) ? [...state.Reports.docsDownloadedForRaport] : [],
    sendGetDocumentsRequest: state.Reports.sendGetDocumentsRequest,
    transfers: isIterable(state.Transfers.transfers) ? [...state.Transfers.transfers] : [],
})

const mapDispatchToProps = {
    getDocuments: ReportsActions.getDocuments,
    downloadReport: ReportsActions.downloadReport,
    getUsersListForExpensesReport: ReportsActions.getUsersListForExpensesReport,
    generateExpensesReport: ReportsActions.generateExpensesReport,
    downloadMultipleFiles: FileActions.downloadMultipleFiles,

    toogleFilePreviewInActions: FileActions.toogleFilePreviewInActions,
    getFileBody: FileActions.getFileData,
    togglePdfPreview: FileActions.togglePdfPreview,
    getFiles: FileActions.getFiles,

    toggleQuickAction: UserActions.toggleQuickAction,
    switchDashboardViewModel: UserActions.switchDashboardViewModel,
    setCurrentCompany: CompanyActions.setCurrentCompany,

    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportBankTransfers)