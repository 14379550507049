import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import TextareaAutosize from 'react-textarea-autosize'

import '../../../css/OcrPage.css'
import { CompanyActions } from '../../../actions/CompanyActions';
export class OcrPageWaproDocDescription extends Component {
	constructor(props) {
		super(props)
		this.state = {
		}

		this.wrapperRef = React.createRef();
	}

	componentDidMount() {
	}

	componentWillUnmount() {
	}


	docDescriptionOnChange = e => {
		e.preventDefault()
		const { value } = e.target

		this.props.select(value)
	}

	render() {
		const { selectedOption, readOnly } = this.props
		const { } = this.state
		return (
			<div className={`ocr-data wapro-input-container`} id="wapro-doc-description-container" ref={this.wrapperRef}>
				<label>{this.context.t('Description')}</label>
				<TextareaAutosize
					className={`tags-container ${readOnly ? 'read-only' : ''}`}
					value={selectedOption}
					name='fakir-logs'
					minRows={1}
					maxRows={10}
					onChange={e => this.docDescriptionOnChange(e)}
					readOnly={readOnly || false}
				/>
			</div>
		)
	}
}

OcrPageWaproDocDescription.contextTypes = {
	t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
	getCompanyWaproNetAccounts: CompanyActions.getCompanyWaproNetAccounts
}

export default connect(mapStateToProps, mapDispatchToProps)(OcrPageWaproDocDescription)