import React, { Component } from 'react'
import { connect } from 'react-redux'
import { UserActions } from '../actions/UserActions'
import { AlertActions } from '../actions/AlertActions'
import { Link, Redirect } from 'react-router-dom'
import routes from '../helpers/routes'
import PropTypes from 'prop-types'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import moment from 'moment'

import Calendar from '../components/AppointmentCalendar'

import Logo from '../img/easy-docs-logo-big.svg'
import imgappointment from '../img/image-appointment.svg'
import shape from '../img/shape.svg'
import calengrey from '../img/Calendar-grey.svg'
import docimage from '../img/doc-image.svg'
import skandoc from '../img/skan-doc.svg'
import vectormobile from '../img/vector-mobile.svg'
import '../css/NewRegisterPage.css'

export class NewAppointmentPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cursourWait: false,
            email: '',
            phone: '',
            name: '',
            selectedDate: '',
            selectedCheckbox: false,
            showDateWindow: false,
            selectedOneTime: false,
            selectedTwoTime: false,
            selectedThreeTime: false,
            selectedFourTime: false,
            selectedNightTime: false,
            viewCalendar: false,
            sendDate: false,
            viewStartText: true,
            showFail: '',
            showFailMain: '',
            showMobileRegister: false
        }
    }

    onInputChange = e => {
        e.preventDefault()

        const { name, value } = e.target

        this.setState({ [name]: value })
    }

    sendDate = () => {
        const { selectedDate, selectedOneTime, selectedTwoTime, selectedThreeTime, selectedFourTime, selectedNightTime } = this.state
        var valid = true
        // if (!selectedDate || (!selectedOneTime && !selectedTwoTime && !selectedThreeTime && !selectedFourTime && !selectedNightTime)) {
        //     valid = false
        //     this.setState({
        //         showFail: "Musisz wybrać datę i godzinę",
        //     })

        // }
        var time = ''
        if (selectedOneTime && selectedTwoTime && selectedThreeTime && selectedFourTime && selectedNightTime) time = 'dowolna pora'
        else {
            if (selectedOneTime) time = '10:00-12:00'

            if (selectedTwoTime && time) time += ', 12:00-14:00'
            else if (selectedTwoTime) time += '12:00-14:00'

            if (selectedThreeTime && time) time += ', 14:00-16:00'
            else if (selectedThreeTime) time += '14:00-16:00'

            if (selectedFourTime && time) time += ', 16:00-18:00'
            else if (selectedFourTime) time += '16:00-18:00'

            if (selectedNightTime && time) time += ', po 18'
            else if (selectedNightTime) time += 'po 18'
        }

        if (valid) {
            this.setState({
                showFail: '',
                sendDate: true,
                showDateWindow: false,
                selectedTime: time
            })
        }
    }

    send = () => {
        const { email, name, phone, company, description, selectedCheckbox } = this.state
        var valid = true


        if (!selectedCheckbox) {
            valid = false
            this.setState({
                showFailMain: "Musisz wyrazić zgodę na kontakt",
            })
        }

        if (!email) {
            valid = false
            this.setState({
                showFailMain: "E-mail jest wymagany",
            })
        }

        if (!name) {
            valid = false
            this.setState({
                showFailMain: "Imię i nazwisko są wymagane",
            })
        }

        if (valid && !this.state.cursourWait) {
            this.setState({
                showFailMain: ''
            })
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'bookingFormSubmit',
                'formName': 'formBooking', // Nazwa formularza
                'email': email,
                'name': name,
                'phone': phone,
                'company': company,
                'description': description,
            });

            var a = document.createElement("a");
            a.href = `https://calendly.com/easydocs-pl/wprowadzenie?name=${name}&email=${email}`
            a.click();
            // this.props.registerForAppointment(email.toLowerCase(), phone, selectedDate, selectedTime, name, success => {
            //     if (success) {
            //         this.setState({
            //             viewStartText: false,
            //             cursourWait: false
            //         })
            //     }
            // })
        }
    }

    toggleDataPresentation = () => {
        if (this.state.sendDate === true) {
            this.setState({
                viewStartText: false
            })
        }
    }

    closeAllSugestions = (e) => {
        if (this.state.viewCalendar && !this.hasAncestor(e.target, "calendarContainer")) {
            this.setState({
                viewCalendar: false
            })
        }
    }

    hasAncestor = (element, id) => {
        while (element) {
            if (element.id && element.id === id) return true

            element = element.parentNode
        }
        return false
    }

    toggleTime = (type) => {
        if (type === 'ONE') {
            this.setState({
                selectedOneTime: !this.state.selectedOneTime
            })
        } else if (type === 'TWO') {
            this.setState({
                selectedTwoTime: !this.state.selectedTwoTime
            })
        } else if (type === 'THREE') {
            this.setState({
                selectedThreeTime: !this.stateselectedThreeTime
            })
        } else if (type === 'FOUR') {
            this.setState({
                selectedFourTime: !this.state.selectedFourTime
            })
        } else if (type === 'NIGHT') {
            this.setState({
                selectedNightTime: !this.state.selectedNightTime
            })
        } else if (type === 'ANY') {
            const { selectedOneTime, selectedTwoTime, selectedThreeTime, selectedFourTime, selectedNightTime } = this.state
            if (selectedOneTime && selectedTwoTime && selectedThreeTime && selectedFourTime && selectedNightTime) {
                this.setState({
                    selectedOneTime: false,
                    selectedTwoTime: false,
                    selectedThreeTime: false,
                    selectedFourTime: false,
                    selectedNightTime: false
                })
            } else {
                this.setState({
                    selectedOneTime: true,
                    selectedTwoTime: true,
                    selectedThreeTime: true,
                    selectedFourTime: true,
                    selectedNightTime: true
                })
            }
        }
    }

    toggleCheckbox = () => {
        this.setState({
            selectedCheckbox: !this.state.selectedCheckbox
        })
    }

    toggleDateWindow = (show) => {
        this.setState({
            showDateWindow: show
        })
    }

    selectDate = date => {
        this.setState({
            selectedDate: date,
            viewCalendar: false
        })

        console.log(date)
    }

    toggleView = () => {
        this.setState({
            viewCalendar: !this.state.viewCalendar
        })
    }

    toggleRegister = () => {
        this.setState({
            showMobileRegister: !this.state.showMobileRegister
        })
    }


    render() {
        const { user } = this.props
        const { cursourWait, email, phone, name, company, description, selectedCheckbox, showFailMain, showMobileMenu } = this.state

        return (
            <div className={`new-appointment ${cursourWait ? 'cursour-wait' : ''}`} onClick={e => this.closeAllSugestions(e)}>
                {/* <Link className="back-button mobile-db" to={"/register/try-app"} onClick={() => this.toggleRegister()}>
                    <img src={vectormobile} className="vectormobile" alt="Back-vector" />
                    <span className="back-text">{this.context.t('Go back')}</span>
                </Link> */}
                {/* {
                    showDateWindow ? (
                        <div className="date-window">
                            <div className="window">
                                <img src={shape} className="shape" alt="shape" onClick={() => this.toggleDateWindow(false)} />
                                <span className="data-text mobile-dn">Wybierz dogodny termin rozmowy</span>
                                <span className="data-text mobile-db">Powiedz nam o jakiej porze najlepiej się z Tobą kontaktować?</span>
                                <div id="calendarContainer" className="select-date" >
                                    <img src={calengrey} className="calengrey" alt="Calendar" onClick={() => this.toggleView()} />
                                    <span className="choose-date" onClick={() => this.toggleView()}>{selectedDate ? selectedDate : 'Wybierz datę'}</span >
                                    <div className={`vectorapp ${viewCalendar ? 'extendes' : ''}`} onClick={() => this.toggleView()}></div>

                                    {
                                        viewCalendar ? (
                                            <Calendar selectDate={this.selectDate} selectedDate={selectedDate} />
                                        ) : null
                                    }
                                </div>
                                <div className="select-time">
                                    <div className={`time-group any ${selectedOneTime && selectedTwoTime && selectedThreeTime && selectedFourTime && selectedNightTime ? 'selected' : ''}`} onClick={() => this.toggleTime('ANY')}>dowolna pora</div>
                                    <div className={`time-group ${selectedOneTime ? 'selected' : ''}`} onClick={() => this.toggleTime('ONE')}>10:00-12:00</div>
                                    <div className={`time-group ${selectedTwoTime ? 'selected' : ''}`} onClick={() => this.toggleTime('TWO')}>12:00-14:00</div>
                                    <div className={`time-group ${selectedThreeTime ? 'selected' : ''}`} onClick={() => this.toggleTime('THREE')}>14:00-16:00</div>
                                    <div className={`time-group ${selectedFourTime ? 'selected' : ''}`} onClick={() => this.toggleTime('FOUR')}>16:00-18:00</div>
                                    <div className={`time-group night ${selectedNightTime ? 'selected' : ''}`} onClick={() => this.toggleTime('NIGHT')}>po 18</div>
                                </div>
                                <div className="button-send-date" onClick={() => this.sendDate()} >Wybierz</div>
                                {
                                    showFail ? (
                                        <div className="fail-text">
                                            {this.context.t(showFail)}
                                        </div>
                                    ) : null
                                }

                            </div>
                        </div>
                    ) : null
                } */}

                <div className="header-appointment">
                    <div className="logo">
                        <a href={'https://easydocs.pl/'}>
                            <img src={Logo} alt="EasyDocs Logo" />
                        </a>
                    </div>
                    <a href="https://easydocs.pl/rozwiazania" target="_blank" className="nav-item security mobile-dn">Rozwiązania</a>
                    <a href="https://easydocs.pl/cennik" target="_blank" className="nav-item price-list mobile-dn">Cennik</a>
                    <a href="https://easydocs.pl/o-nas" target="_blank" className="nav-item about-us mobile-dn">O nas</a>
                    <a href="https://easydocs.pl/faq" target="_blank" className="nav-item about-us mobile-dn">FAQ</a>
                    <a href="https://easydocs.pl/baza-wiedzy/" target="_blank" className="nav-item blog mobile-dn">Blog</a>
                    <Link className="log-in mobile-dn" to={routes.login}>Zaloguj się</Link>
                    <Link className="try-free mobile-dn" to={"/register/try-app"}>Wypróbuj za darmo</Link>

                    <div className={`mobile-menu-icon mobile-db ${showMobileMenu ? 'menu-visible' : ''}`} onClick={() => this.setState({ showMobileMenu: !showMobileMenu })}>
                        <div className="bar-top"></div>
                        <div className="bar-mid"></div>
                        <div className="bar-bottom"></div>
                    </div>
                    <div className={`mobile-menu ${showMobileMenu ? 'show' : ''}`}>
                        <ul>
                            <li><a href="https://easydocs.pl/rozwiazania" target="_blank" className="nav-item security">Rozwiązania</a></li>
                            <li><a href="https://easydocs.pl/o-nas" target="_blank" className="nav-item about-us">O nas</a></li>
                            <li><a href="https://easydocs.pl/cennik" target="_blank" className="nav-item price-list">Cennik</a></li>
                            <li><a href="https://easydocs.pl/faq" target="_blank" className="nav-item price-list">FAQ</a></li>
                            <li><a href="https://easydocs.pl/baza-wiedzy/" target="_blank" className="nav-item blog">Blog</a></li>
                            <li className="log-in"><Link className="log-in" to={routes.login}>Zaloguj się</Link></li>
                            <li className="try-free"><a onClick={() => this.setState({ showMobileMenu: false, showMobileRegister: true })}>Wypróbuj za darmo</a></li>
                        </ul>
                    </div>
                </div>

                <h2>Umów spotkanie <strong>dopasowane do Twoich potrzeb!</strong></h2>

                <div className="main-appointment">

                    {/* <div className="image-box mobile-dn">
                        <div className="text-carousel">EasyDocs to:</div>
                        <Carousel showArrows={false} autoPlay={true} interval={2000} infiniteLoop={true} animationHandler='fade'>
                            <div>
                                <div className="transfers"><span className='checkmark'>✓</span> Centralny hub na wszystkie<br /> dokumenty firmowe</div>
                            </div>
                            <div>
                                <div className="transfers"><span className='checkmark'>✓</span> Samodzielne, łatwe wprowadzanie <br /> dokumentów przez pracowników</div>
                            </div>
                            <div>
                                <div className="transfers"><span className='checkmark'>✓</span> Trwałe cyfrowe <br />przechowywanie dokumentów</div>
                            </div>
                            <div>
                                <div className="transfers"><span className='checkmark'>✓</span> Szybkie szukanie po treści <br />wszystkich dokumentów</div>
                            </div>
                            <div>
                                <div className="transfers"><span className='checkmark'>✓</span> Wygodne realizowanie <br />paczek przelewów bankowych</div>
                            </div>
                            <div>
                                <div className="transfers"><span className='checkmark'>✓</span> Automatyczna integracja <br />z księgowością</div>
                            </div>
                            <div>
                                <div className="transfers"><span className='checkmark'>✓</span> Kontrola <br />wydatków</div>
                            </div>
                        </Carousel>
                        <div className="contact">
                            Masz pytania? Napisz do nas <a className="email" href="mailto:support@easydocs.pl">support@easydocs.pl</a> lub zadzwoń + 48 <span className="bold">22 113 13 33</span>
                        </div>
                    </div> */}
                    <div className="form">
                        <div className="input-group email">
                            <input id="email" type="text" name="email" value={email} onChange={this.onInputChange} className={`${email ? 'has-text' : ''}`} />
                            <label htmlFor="email">E-mail*</label>
                        </div>
                        <div className="input-group name">
                            <input id="name" type="text" name="name" value={name} onChange={this.onInputChange} className={`${name ? 'has-text' : ''}`} />
                            <label htmlFor="name">Imię i nazwisko*</label>
                        </div>
                        {/* <div className="calendar">
                            <div className="calendar-image" onClick={() => this.toggleDateWindow(true)}></div>
                            <span className="calendar-text" onClick={() => this.toggleDateWindow(true)}>{selectedDate ? selectedDate : 'Preferowany termin'}</span>
                        </div>
                        <div className="input-group">
                            <input id="name" type="text" name="name" value={name} onChange={this.onInputChange} className={`${name ? 'has-text' : ''}`} />
                            <label htmlFor="name">Jak się do Ciebie zwracać?</label>
                        </div> */}
                        <div className="input-group">
                            <input id="phone" type="text" name="phone" value={phone} onChange={this.onInputChange} className={`${phone ? 'has-text' : ''}`} />
                            <label htmlFor="phone">Numer telefonu</label>
                        </div>
                        <div className="input-group company">
                            <input id="company" type="text" name="company" value={company} onChange={this.onInputChange} className={`${company ? 'has-text' : ''}`} />
                            <label htmlFor="company">Nazwa firmy</label>
                        </div>
                        <div className="input-group description">
                            <input id="description" type="text" name="description" value={description} onChange={this.onInputChange} className={`${description ? 'has-text' : ''}`} />
                            <label htmlFor="description">Czego oczekujesz od EasyDocs?</label>
                        </div>
                        <div className="acceptance-phone">
                            <div className={`checkbox ${selectedCheckbox ? 'selected' : ''}`} onClick={() => this.toggleCheckbox()}></div>
                            <p className="acceptance" onClick={() => this.toggleCheckbox()}>Wyrażam zgodę na kontakt telefoniczny lub mailowy ws. aplikacji EasyDocs</p>
                        </div>
                        <div className="button-send" onClick={() => this.send()}>Wysyłam</div>

                        {
                            showFailMain ? (
                                <div className="show-fail">
                                    {this.context.t(showFailMain)}
                                </div>
                            ) : null
                        }
                    </div>

                    <div className="benefits">
                        <h4>Wypełnij formularz, a my <span className='green'>skontaktujemy się z Tobą</span>, aby omówić <span className='green'>Twoje oczekiwania</span>.</h4>

                        <h5>Podczas spotkania: </h5>
                        <ul>
                            <li>Poznasz aplikację EasyDocs w kontekście Twoich potrzeb i specyfiki
                                organizacji.</li>
                            <li>Dowiesz się o kluczowych funkcjach i praktycznym zastosowaniu.</li>
                            <li>Sprawdzisz w jaki sposób EasyDocs pomoże Ci usprawnić proces
                                zarządzania dokumentacją w Twojej organizacji.</li>
                        </ul>

                        <h4>Spotkanie jest <span className='green'>bez zobowiązań</span> - sprawdzimy, czy to rozwiązanie jest dla Ciebie!</h4>

                        <div className="contact">
                            <div className="method email">
                                <div className="label">Wiadomość e-mail</div>
                                <div className="value">
                                    <a href="mailto:info@easydocs.pl">info@easydocs.pl</a>
                                </div>
                            </div>
                            <div className="method phone">
                                <div className="label">Telefon (pon.-pt. 8:00-17:00)</div>
                                <div className="value">
                                    <a href="tel:+48660-490-644">+48 660 490 644</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="page-footer mobile-dn">
                    <div className="register-copyright">{`© Copyright 2019-${moment().format('YYYY')} EASYDOCS ™`}</div>
                    <a href="https://easydocs.pl/bezpieczenstwo" target="_blank" className="register-security">{this.context.t('Security')}</a>
                    <a href="https://easydocs.pl/regulamin/" target="_blank" className="register-terms">{this.context.t('Terms')}</a>
                    <a href="https://easydocs.pl/polityka-prywatnosci/" target="-blank" className="register-privacy">{this.context.t('Privacy')}</a>
                </div>
            </div>
        )
    }
}

NewAppointmentPage.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user
})

const mapDispatchToProps = {
    alertWarn: AlertActions.warning,
    registerForAppointment: UserActions.registerForAppointment
}

export default connect(mapStateToProps, mapDispatchToProps)(NewAppointmentPage)

