import myFetch from "../helpers/fetch"

import { DocflowConst } from "../reducers/DocflowReducer"
import { FileConst } from "../reducers/FileReducer"
import { AlertActions } from "./AlertActions"
import { UserConst } from "../reducers/UserReducer"
import { CompanyConst } from "../reducers/CompanyReducer"
import { ReportsConst } from "../reducers/ReportsReducer"
import { EventConst } from "../reducers/EventReducer"


export const DocflowActions = {
    getProjectMembersForDocflow,
    createNewDocflow,
    saveDocflowChanges,
    archiveDocflow,
    activateDocflow,
    getProjectDocflows,
}

function getProjectMembersForDocflow(projectId, cb) {
    return dispatch => {
        myFetch(`/docflows/get-users-for-docflow/${projectId}`, { method: 'get' })
            .then(res => {
                if (!res.success) {
                    dispatch(AlertActions.warning(res.msg))
                    cb(null)
                } else {
                    cb(res.users)
                }
            }).catch(err => {
                cb(null)
                handleError(err, dispatch, `/docflow/get-users-for-docflow/${projectId}`)
            })
    }
}

function createNewDocflow(projectId, pathAdvisorId, name, description, docTypes, docflowSchema, cb) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true });
        myFetch(`/docflows/add`, {
            method: 'post', body: {
                projectId,
                pathAdvisorId,
                name,
                description,
                docTypes,
                docflowSchema
            }
        })
            .then(res => {
                cb(res.success)
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
                if (!res.success) {
                    dispatch(AlertActions.warning(res.msg))
                } else {
                    dispatch(AlertActions.info('The document flow path has been created'))
                }
            }).catch(err => {
                cb(false)
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
                handleError(err, dispatch, `/docflows/add`)
            })
    }
}

function saveDocflowChanges(docflowId, projectId, pathAdvisorId, name, description, docTypes, docflowSchema, deletedSteps, newDocflowUsersSettings, cb) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true });
        myFetch(`/docflows/edit`, {
            method: 'post', body: {
                docflowId,
                projectId,
                pathAdvisorId,
                name,
                description,
                docTypes,
                docflowSchema,
                deletedSteps: deletedSteps || [],
                newDocflowUsersSettings
            }
        })
            .then(res => {
                cb(res.success)
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
                if (!res.success) {
                    dispatch(AlertActions.warning(res.msg))
                } else {
                    dispatch(AlertActions.info('The document flow path has been created'))
                }
            }).catch(err => {
                cb(false)
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
                handleError(err, dispatch, `/docflows/add`)
            })
    }
}

function archiveDocflow(docflowId, projectId, cb) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true });
        myFetch(`/docflows/archive`, {
            method: 'post', body: {
                docflowId,
                projectId
            }
        })
            .then(res => {
                cb(res.success)
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
                if (!res.success) {
                    dispatch(AlertActions.warning(res.msg))
                } else {
                    dispatch(AlertActions.info('The document flow path has been archived'))
                }
            }).catch(err => {
                cb(false)
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
                handleError(err, dispatch, `/docflows/archive`)
            })
    }
}

function activateDocflow(docflowId, projectId, cb) {
    return dispatch => {
        dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: true });
        myFetch(`/docflows/activate`, {
            method: 'post', body: {
                docflowId,
                projectId
            }
        })
            .then(res => {
                cb(res.success)
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
                if (!res.success) {
                    dispatch(AlertActions.warning(res.msg))
                } else {
                    dispatch(AlertActions.info('The document flow path has been activated'))
                }
            }).catch(err => {
                cb(false)
                dispatch({ type: FileConst.LOADING_SCREEN, loadingScreen: false });
                handleError(err, dispatch, `/docflows/activate`)
            })
    }
}

function getProjectDocflows(projectId) {
    return dispatch => {
        dispatch({ type: DocflowConst.SENT_GET_PROJECT_DOCFLOWS_REQUEST, projectId: projectId })
        myFetch(`/docflows/get-mine/${projectId}`, {
            method: 'get'
        })
            .then(res => {
                if (res.success) {
                    dispatch({ type: DocflowConst.GET_PROJECT_DOCFLOWS, docflows: res.docflows, projectId: projectId })
                    dispatch({ type: UserConst.GET_PROJECT_DOCFLOWS, docflows: res.docflows, projectId: projectId })
                } else {
                    dispatch(AlertActions.warning(res.msg))
                }
            }).catch(err => {
                handleError(err, dispatch, `/docflows/get-mine/${projectId}`)
            })
    }
}

function handleError(error, dispatch, path) {
    console.log(error)
    if (error?.code === 'AUTHORIZATION_REQUIRED' && localStorage.getItem('dashboardProject')) {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        dispatch({ type: UserConst.LOGGED_FALSE })
        dispatch({ type: UserConst.LOGOUT })
        dispatch({ type: EventConst.LOGOUT })
        dispatch({ type: CompanyConst.LOGOUT })
        dispatch({ type: ReportsConst.LOGOUT })
        dispatch({ type: FileConst.LOGOUT })
        dispatch(AlertActions.info("You have been logged out.", 5000))
    } else {
        dispatch(AlertActions.setError(error, path));
        dispatch(AlertActions.danger("There was an internal server error while procesing request. Try again later."));
    }
}