import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import { List, AutoSizer, WindowScroller } from "react-virtualized";
import "react-virtualized/styles.css";

import dynamicComparer from '../../../helpers/dynamicComparer'

import { UserActions } from '../../../actions/UserActions'
import { AlertActions } from '../../../actions/AlertActions'

import DefaultUserPhoto from '../../../img/default-user-photo.svg'

export class UsersList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            usersList: [],
            usersSortField: "created",
            usersSortOrder: "desc",
        }
    }

    componentDidMount() {
        const { users } = this.props
        this.setState({
            usersList: users.filter(p => this.checkIfUserMatchesFilter(p))
        }, () => {
            if (this.listRef) this.listRef.recomputeRowHeights()
        })
    }

    componentDidUpdate(prevProps) {
        ReactTooltip.rebuild()

        const { users, searchField } = this.props
        if (users !== prevProps.users || (users && prevProps.users && users.length !== prevProps.users.length) || searchField !== prevProps.searchField) {
            this.setState({
                usersList: users.filter(p => this.checkIfUserMatchesFilter(p))
            }, () => {
                if (this.listRef) this.listRef.recomputeRowHeights()
            })
        }
    }

    checkIfUserMatchesFilter = user => {
        const { searchField } = this.props
        var searchFieldCopy = searchField.toLowerCase()
        if (searchField) {
            if (user.name && user.name.toLowerCase().includes(searchFieldCopy)) return true
            if (user.email && user.email.toLowerCase().includes(searchFieldCopy)) return true
            if (user.companies && user.companies.toLowerCase().includes(searchFieldCopy)) return true

            return false
        } else return true
    }

    getSortClass = name => {
        const { usersSortField, usersSortOrder } = this.state
        if (usersSortField === name) {
            if (usersSortOrder === "asc")
                return "icon-sort up"
            return "icon-sort down"
        }
        return "icon-sort"
    }

    switchSortingField = (name) => {
        const { usersSortField, usersSortOrder } = this.props
        if (usersSortField === name) {
            if (usersSortOrder === "asc") {
                this.props.sortAllUsersForSuperadmin(name, 'desc')
            } else {
                this.props.sortAllUsersForSuperadmin(name, 'asc')
            }
        } else {
            this.props.sortAllUsersForSuperadmin(name, 'asc')
        }
    }

    switchSortingField = (name) => {
        var { usersSortField, usersSortOrder, usersList } = this.state
        var newSortOrder = 'asc'
        if (usersSortField === name) {
            if (usersSortOrder === "asc") {
                newSortOrder = 'desc'
            }
        }
        usersList.sort(dynamicComparer(name, newSortOrder, false))

        this.setState({
            usersSortField: name,
            usersSortOrder: newSortOrder,
            usersList: usersList
        }, () => {
            if (this.listRef) this.listRef.recomputeRowHeights()
        })
    }

    setRef = windowScroller => {
        this._windowScroller = windowScroller;
    }

    setListRef = listRef => {
        this.listRef = listRef
    }


    getRowHeight = (index, width) => {
        const { usersList } = this.state
        var user = usersList[index]

        var availableWidth = width - 640
        var userCompaniesStringLength = user.companies.length
        if (userCompaniesStringLength) {
            var userCompaniesPixelsLength = userCompaniesStringLength * 6
            var userCompaniesLines = Math.ceil(userCompaniesPixelsLength / availableWidth)

            if (userCompaniesLines > 1) {
                return 44 + ((userCompaniesLines - 1) * 16)
            } else {
                return 44
            }
        } else {
            return 44
        }
    }



    rowRenderer = ({ style, key, index, parent, width }) => {
        const { usersList } = this.state

        var user = usersList[index]

        return (
            <div
                key={key}
                style={style}
                role="row"
                className="ReactVirtualized__List-row"
            >
                <div className="ReactVirtualized__List-cell user-name">
                    <div className="photo">
                        {
                            user.photo ? (
                                <img src={user.photo} alt="Users profile picture" />
                            ) : (
                                <img src={DefaultUserPhoto} alt="Users profile picture" />
                            )
                        }
                    </div>
                    {user.name}
                </div>
                <div className="ReactVirtualized__List-cell email">
                    {user.email}
                </div>
                <div className="ReactVirtualized__List-cell name">
                    {user.companies}
                </div>
                <div className="ReactVirtualized__List-cell created">
                    {user.lastLoggedIn ? moment(user.lastLoggedIn).format('YYYY-MM-DD HH:mm') : ''}
                </div>
                <div className="ReactVirtualized__List-cell created">
                    {moment(user.created).format('YYYY-MM-DD HH:mm')}
                </div>
            </div>
        )
    }

    render() {
        const { usersList } = this.state
        const { wrapperRef, user } = this.props

        return (
            <div className="ReactVirtualized__List-wrapper">
                <div className="ReactVirtualized__List-row header">
                    <div className="ReactVirtualized__List-cell user-name" onClick={() => this.switchSortingField('name', 'USERS')}>
                        {this.context.t('Name and Surname')}
                        <span className={this.getSortClass('name', 'USERS')}></span>
                    </div>
                    <div className="ReactVirtualized__List-cell email" onClick={() => this.switchSortingField('email', 'USERS')}>
                        {this.context.t('E-mail')}
                        <span className={this.getSortClass('email', 'USERS')}></span>
                    </div>
                    <div className="ReactVirtualized__List-cell name" onClick={() => this.switchSortingField('companies', 'USERS')}>
                        {this.context.t('Companies')}
                        <span className={this.getSortClass('companies', 'USERS')}></span>
                    </div>
                    <div className="ReactVirtualized__List-cell created" onClick={() => this.switchSortingField('lastLoggedIn', 'USERS')}>
                        {this.context.t('Last logged in')}
                        <span className={this.getSortClass('lastLoggedIn', 'USERS')}></span>
                    </div>
                    <div className="ReactVirtualized__List-cell created" onClick={() => this.switchSortingField('created', 'USERS')}>
                        {this.context.t('Registration')}
                        <span className={this.getSortClass('created', 'USERS')}></span>
                    </div>
                </div>
                <WindowScroller
                    ref={this.setRef}
                    scrollElement={user.new_easydocs_version && wrapperRef && wrapperRef.current ? wrapperRef.current : window}
                >
                    {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
                        <AutoSizer disableHeight>
                            {({ width }) => (
                                <div ref={registerChild}>
                                    <List
                                        ref={this.setListRef}
                                        autoHeight
                                        width={width}
                                        height={height}
                                        rowHeight={({ index }) => this.getRowHeight(index, width)}

                                        rowCount={usersList.length}
                                        rowGetter={({ index }) => usersList[index]}
                                        rowRenderer={({ style, key, index, parent }) => this.rowRenderer({ style, key, index, parent, width })}
                                        overscanRowCount={2}

                                        isScrolling={isScrolling}
                                        onScroll={(e) => { onChildScroll(e); ReactTooltip.rebuild() }}
                                        scrollTop={scrollTop}
                                    />
                                </div>
                            )}
                        </AutoSizer>
                    )}
                </WindowScroller>
            </div>
        )
    }
}

UsersList.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    users: state.User.users || [],
    usersSortField: state.User.usersSortField,
    usersSortOrder: state.User.usersSortOrder,
})

const mapDispatchToProps = {
    sortAllUsersForSuperadmin: UserActions.sortAllUsersForSuperadmin,
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersList)