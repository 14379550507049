import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import { Spinner } from 'react-activity'
import 'react-activity/dist/react-activity.css'

import CostsTabletooltips from '../ReportsPageComponents/CostsTabletooltips'

import _ from "lodash";

import Dotdotdot from 'react-dotdotdot'

import { ReportsActions } from '../../actions/ReportsActions'
import countryCodeEU from '../../helpers/countryCodeEU.json'

export class CostsTable extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            docsList: [],
            viesCheckedIndex: [],
            vatIdToCheckedInVies: []
        }
    }

    componentDidMount() {
        const docList = this.props.docsDownloadedForRaport.filter(e => this.checkIfDocMatchesFilterOptions(e))
        this.setState({
            docsList: docList,
            docsSortField: this.props.sortField,
            docsSortOrder: this.props.sortOrder
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.docsDownloadedForRaport !== this.props.docsDownloadedForRaport) {
            const docList = this.props.docsDownloadedForRaport.filter(e => this.checkIfDocMatchesFilterOptions(e))
            this.setState({
                docsList: docList
            })
        }
        ReactTooltip.rebuild()

    }

    formatPrice = price => {
        if (price || price === 0) {
            price = price.toFixed(2)
            if (price.indexOf('.') > -1) {
                var parts = price.split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                price = parts.join('.')
            } else {
                price = price.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }

            return price
        } else {
            return ''
        }
    }

    getSortClass = (sortFieldCheck) => {
        const { sortField, sortOrder } = this.props

        if (sortField === sortFieldCheck) {
            if (sortOrder === "asc")
                return "icon-sort down"
            return "icon-sort up"
        }

        return "icon-sort"
    }

    headerRendererExpenses = (name) => {
        switch (name) {
            case 'all':
                return (
                    <div className="wrapper all">
                        <span className="column-name" onClick={e => this.props.selectAll(e)}>{this.context.t('Select all ')}</span>
                    </div>
                )
            case 'fileIcon':
                return (
                    <div className="wrapper">
                    </div>
                )
            case 'company':
                return (
                    <div className="wrapper counterparty" onClick={() => this.props.switchSortingFilter('company')}>
                        <span className="column-name">{this.context.t('Counterparty')}</span>
                        <span className={this.getSortClass('company')}></span>
                    </div>
                )
            case 'importDate':
                return (
                    <div className="wrapper date" onClick={() => this.props.switchSortingFilter('importDate')}>
                        <span className="column-name">{this.context.t('Import Date')}</span>
                        <span className={this.getSortClass('importDate')}></span>
                    </div>
                )
            case 'issueDate':
                return (
                    <div className="wrapper date" onClick={() => this.props.switchSortingFilter('issueDate')}>
                        <span className="column-name">{this.context.t('Import Date')}</span>
                        <span className={this.getSortClass('issueDate')}></span>
                    </div>
                )
            case 'priceNumber':
                return (
                    <div className="wrapper price" onClick={() => this.props.switchSortingFilter('priceNumber')}>
                        <span className="column-name">{this.context.t('Amount to pay')}</span>
                        <span className={this.getSortClass('priceNumber')}></span>
                    </div>
                )
            case 'currency':
                return (
                    <div className="wrapper" onClick={() => this.props.switchSortingFilter('currency')}>
                        <span className="column-name">{this.context.t('Currency')}</span>
                        {/* <span className={this.getSortClass('currency')}></span> */}
                    </div>
                )
            case 'comment':
                return (
                    <div className="wrapper" onClick={() => this.props.switchSortingFilter('comment')}>
                        <span className="column-name">{this.context.t('Comment')}</span>
                        <span className={this.getSortClass('comment')}></span>
                    </div>
                )
            case 'tags':
                return (
                    <div className="wrapper" onClick={() => this.props.switchSortingFilter('tags')}>
                        <span className="column-name">{this.context.t('Tags')}</span>
                        <span className={this.getSortClass('tags')}></span>
                    </div>
                )
            case 'uploadedBy':
                return (
                    <div className="wrapper in-report" onClick={() => this.props.switchSortingFilter('uploadedBy')}>
                        <span className="column-name">{this.context.t('Uploaded by')}</span>
                        <span className={this.getSortClass('uploadedBy')}></span>
                    </div>
                )
            case 'alreadyInReport':
                return (
                    <div className="wrapper in-report" onClick={() => this.props.switchSortingFilter('alreadyInReport')}>
                        <span className="column-name">{this.context.t('In report')}</span>
                        <span className={this.getSortClass('alreadyInReport')}></span>
                    </div>
                )
            default:
                return null
        }
    }


    sort = ({ sortBy, sortDirection }) => {
        var { docsSortField, docsSortOrder, docsList } = this.state
        if (docsSortField === sortBy) {
            if (docsSortOrder === 'asc') {
                docsSortOrder = 'desc'
            } else {
                docsSortOrder = 'asc'
            }
        } else {
            docsSortField = sortBy
            docsSortOrder = 'asc'
        }
        docsList.sort(this.dynamicComparer(docsSortField, docsSortOrder))

        this.setState({
            docsList: docsList,
            docsSortField: sortBy,
            docsSortOrder: docsSortOrder
        })
    }

    checkIfDocMatchesFilterOptions = doc => {
        return true
    }

    dynamicComparer = (key, order = 'asc') => {
        return (a, b) => {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                return 0;
            }

            const varA = (typeof a[key] === 'string') ?
                a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string') ?
                b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (!varA) {
                comparison = -1
            } else if (!varB) {
                comparison = 1
            } else if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === 'desc') ? (comparison * -1) : comparison
            );
        };
    }

    rowRendererExpenses = (doc, index) => {
        const { docsSelectedForReport, selectFor } = this.props
        return (
            <div
                key={`table-row-${doc.id}`}
                className={`${index % 2 === 0 ? 'even' : 'odd'} ReactVirtualized__Table__row`}
                role="row"
            >
                {
                    <>
                        <div
                            className="ReactVirtualized__Table__rowColumn checkmarks"
                            data-tip='show'
                            data-for={doc.wasExported ? `zip-export-not-available` : ''}
                            role="gridcell"
                            style={{ overflow: "hidden", flex: `0 1 4%` }}
                        >
                            {
                                doc.reportName !== 'PENDING' ? (
                                    <span
                                        className={`checkbox ${docsSelectedForReport.find(e => e.id === doc.id) ? 'checked' : ''}
                                        ${doc.alreadyInReport ? 'inactive' : ''}`}
                                        onClick={e => this.props.addDocToSelected(e, doc)}>
                                    </span>
                                ) : (
                                    <span className="activity-indicator-doc-table">
                                        <Spinner size={11} speed={0.8} color={"#69B792"} />
                                    </span>
                                )
                            }
                        </div>
                        <div
                            className={`ReactVirtualized__Table__rowColumn ${doc.type === 'PAYMENT_ORDER' ? "payment-order-icon" : "file-icon"}`}
                            role="gridcell"
                            style={{ overflow: "hidden", flex: `0 1 2%` }}
                            onClick={e => this.props.fileIconClick(e, doc.projectId, doc.path, doc.id, doc.type, doc.fileId, true)}
                        ></div>
                        <div
                            className="ReactVirtualized__Table__rowColumn counterparty"
                            role="gridcell"
                            style={{ overflow: "hidden", flex: `0 1 7%` }}
                            onClick={e => this.props.fileIconClick(e, doc.projectId, doc.path, doc.id, doc.type, doc.fileId, true)}
                        ><Dotdotdot clamp={2}><p>{doc.projectCode}</p></Dotdotdot></div>
                        <div
                            className="ReactVirtualized__Table__rowColumn date"
                            role="gridcell"
                            style={{ overflow: "hidden", flex: `0 1 6%` }}
                            onClick={e => this.props.fileIconClick(e, doc.projectId, doc.path, doc.id, doc.type, doc.fileId, true)}
                        ><Dotdotdot clamp={2}><p>{doc.importDate}</p></Dotdotdot></div>
                        <div
                            className="ReactVirtualized__Table__rowColumn date"
                            role="gridcell"
                            style={{ overflow: "hidden", flex: `0 1 6%` }}
                            onClick={e => this.props.fileIconClick(e, doc.projectId, doc.path, doc.id, doc.type, doc.fileId, true)}
                        ><Dotdotdot clamp={2}><p>{moment(doc.issueDate).isValid() ? moment(doc.issueDate).format('YYYY-MM-DD') : doc.issueDate}</p></Dotdotdot></div>
                        <div
                            className={`ReactVirtualized__Table__rowColumn price ${parseFloat(doc.price) < 0 ? 'refund' : ''}`}
                            role="gridcell"
                            style={{ overflow: "hidden", flex: `0 1 6%` }}
                            onClick={e => this.props.fileIconClick(e, doc.projectId, doc.path, doc.id, doc.type, doc.fileId, true)}
                        ><Dotdotdot clamp={2}><p>{this.formatPrice(parseFloat(doc.price))}</p></Dotdotdot></div>
                        <div
                            className="ReactVirtualized__Table__rowColumn currency"
                            role="gridcell"
                            style={{ overflow: "hidden", flex: `0 1 4%` }}
                            onClick={e => this.props.fileIconClick(e, doc.projectId, doc.path, doc.id, doc.type, doc.fileId, true)}
                        ><Dotdotdot clamp={2}><p>{doc.currency}</p></Dotdotdot></div>
                        <div
                            className="ReactVirtualized__Table__rowColumn comment"
                            role="gridcell"
                            style={{ overflow: "hidden", flex: `0 1 20%` }}
                            onClick={e => this.props.fileIconClick(e, doc.projectId, doc.path, doc.id, doc.type, doc.fileId, true)}
                        ><Dotdotdot clamp={2}><p>{doc.comment}</p></Dotdotdot></div>
                        <div
                            className="ReactVirtualized__Table__rowColumn tags"
                            role="gridcell"
                            style={{ overflow: "hidden", flex: `0 1 20%` }}
                            onClick={e => this.props.fileIconClick(e, doc.projectId, doc.path, doc.id, doc.type, doc.fileId, true)}
                        ><Dotdotdot clamp={2}><p>{doc.tags}</p></Dotdotdot></div>
                        <div
                            className="ReactVirtualized__Table__rowColumn uploadedBy"
                            role="gridcell"
                            style={{ overflow: "hidden", flex: `0 1 20%` }}
                            onClick={e => this.props.fileIconClick(e, doc.projectId, doc.path, doc.id, doc.type, doc.fileId, true)}
                        ><Dotdotdot clamp={2}><p>{doc.uploadedBy}</p></Dotdotdot></div>
                        <div
                            className="ReactVirtualized__Table__rowColumn in-report"
                            role="gridcell"
                            style={{ overflow: "hidden", flex: `0 1 5%` }}
                        >
                            {
                                doc.alreadyInReport && doc.reportName && doc.reportName !== 'PENDING' ? (
                                    <span className="icon-xlsx" onClick={e => this.props.downloadReport(e, doc.projectId, doc.reportName)}></span>
                                ) : (
                                    this.context.t("No")
                                )
                            }
                        </div>
                    </>
                }
            </div>
        )
    }

    _setRef = windowScroller => {
        this._windowScroller = windowScroller;
    };

    getColumnWidth = name => {
        switch (name) {
            case 'all':
                return '4%'
            case 'fileIcon':
                return '2%'
            case 'company':
                return '7%'
            case 'importDate':
                return '6%'
            case 'issueDate':
                return '6%'
            case 'priceNumber':
                return '6%'
            case 'currency':
                return '4%'
            case 'comment':
                return '20%'
            case 'tags':
                return '20%'
            case 'uploadedBy':
                return '20%'
            case 'alreadyInReport':
                return '5%'
        }
    }

    render() {
        const { docsList } = this.state

        var columnsArray = ['all', 'fileIcon', 'company', 'importDate', 'issueDate', 'priceNumber', 'currency', 'comment', 'tags', 'uploadedBy', 'alreadyInReport']

        return (
            <div className="table-responsive" ref={this.props.setTableRef}>
                <div className='ReactVirtualized__Table'>
                    <div className="ReactVirtualized__Table__headerRow">
                        {
                            columnsArray.map(column => {
                                return (
                                    <div className={`ReactVirtualized__Table__headerColumn expenses ${column}`} style={{ flex: `0 1 ${this.getColumnWidth(column)}` }}>
                                        {this.headerRendererExpenses(column)}
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="ReactVirtualized__Table__Grid">
                        {
                            docsList.map((doc, index) => {
                                return (
                                    this.rowRendererExpenses(doc, index)
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )

    }
}

CostsTable.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    currentCompany: state.Company.currentCompany,
    sendGetDocumentsRequest: state.Reports.sendGetDocumentsRequest,
    docsDownloadedForRaport: state.Reports.docsDownloadedForRaport,
    alreadyPaidDocs: state.Reports.alreadyPaidDocs,
    sortField: state.Reports.docsSortField,
    sortOrder: state.Reports.docsSortOrder,
    downloadedDocsType: state.Reports.downloadedDocsType,
})

const mapDispatchToProps = {
    switchSortingFilter: ReportsActions.switchDocsSortingFilter
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(CostsTable))