import { isArray } from "jquery"
import moment from "moment"

import arrayObjectIndexOf from "../helpers/indexOfObject"

export const ProjectCostsConsts = {
    SEND_PROJECT_COSTS_REQUEST: 'SEND_PROJECT_COSTS_REQUEST',
    GET_PROJECT_COSTS: 'GET_PROJECT_COSTS',
    EXTEND_PROJECT_COSTS: 'EXTEND_PROJECT_COSTS',
    HIDE_ALL_PROJECT_COSTS: 'HIDE_ALL_PROJECT_COSTS',

    DELETE_PROJECT: 'DELETE_PROJECT-CHARTS'
}

const initialState = {
    projectsCosts: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ProjectCostsConsts.SEND_PROJECT_COSTS_REQUEST:
            var projectsCosts = [...state.projectsCosts]
            var projectIndex = arrayObjectIndexOf(projectsCosts, action.projectId, 'project')
            var projectCostsExtended = localStorage.getItem('userPreferanceCostsExtended')
            var extendCostAtThisProject = false
            if(projectCostsExtended) {
                projectCostsExtended = JSON.parse(projectCostsExtended)
                var project = projectCostsExtended.find(e => e.id === action.projectId)
                if(project) {
                    extendCostAtThisProject = project.extended
                } else {
                    projectCostsExtended.push({id: action.projectId, extended: false})
                }
            } else {
                projectCostsExtended = [{id: action.projectId, extended: false}]
            }

            localStorage.setItem('userPreferanceCostsExtended', JSON.stringify(projectCostsExtended))

            if(projectIndex > -1) {
                projectsCosts[projectIndex].sendRequest = true
                projectsCosts[projectIndex].gotResponse = false
            } else {
                projectsCosts.push({
                    project: action.projectId,
                    costs: {},
                    extended: extendCostAtThisProject,
                    gotResponse: false,
                    sendRequest: true
                })
            }

            return {...state, ...{projectsCosts: projectsCosts}}

        case ProjectCostsConsts.GET_PROJECT_COSTS:
            var projectsCosts = [...state.projectsCosts]
            var projectIndex = arrayObjectIndexOf(projectsCosts, action.projectId, 'project')

            if(projectIndex > -1) {
                projectsCosts[projectIndex].costs = action.costs
                projectsCosts[projectIndex].gotResponse = true
            } else {
                projectsCosts.push({
                    project: action.projectId,
                    costs: action.costs,
                    extended: false,
                    gotResponse: true,
                    sendRequest: true
                })
            }

            return {...state, ...{projectsCosts: projectsCosts}}

        case ProjectCostsConsts.EXTEND_PROJECT_COSTS:
            var projectsCosts = [...state.projectsCosts]
            var projectIndex = arrayObjectIndexOf(projectsCosts, action.projectId, 'project')

            var projectCostsExtended = localStorage.getItem('userPreferanceCostsExtended')

            if(projectIndex > -1) {
                if(action.extended !== undefined) {
                    projectsCosts[projectIndex].extended = action.extended
                } else {
                    projectsCosts[projectIndex].extended = !projectsCosts[projectIndex].extended
                }
            } else {
                projectsCosts.push({
                    project: action.projectId,
                    costs: {},
                    extended: true,
                })
            }

            if(projectCostsExtended && projectIndex > -1 && projectsCosts[projectIndex]) {
                projectCostsExtended = JSON.parse(projectCostsExtended)
                var projectIndexStored = arrayObjectIndexOf(projectCostsExtended, action.projectId, 'id')
                if(projectIndexStored > -1 && projectCostsExtended[projectIndexStored] && projectsCosts[projectIndex]) {
                    projectCostsExtended[projectIndexStored].extended = projectsCosts[projectIndex].extended
                } else {
                    projectCostsExtended.push({id: action.projectId, extended: projectsCosts[projectIndex].extended})
                }

                localStorage.setItem('userPreferanceCostsExtended', JSON.stringify(projectCostsExtended))
            }

            return {...state, ...{projectsCosts: projectsCosts}}

        case ProjectCostsConsts.HIDE_ALL_PROJECT_COSTS:
            var projectsCosts = [...state.projectsCosts]
            var projectsCount = projectsCosts.length
            for(var i = 0; i < projectsCount; i++) {
                projectsCosts[i].extended = false
            }

            var projectCostsExtended = localStorage.getItem('userPreferanceCostsExtended')
            projectCostsExtended = JSON.parse(projectCostsExtended)
            if(projectCostsExtended && isArray(projectCostsExtended)) {
                projectsCount = projectCostsExtended.length
                for(var i = 0; i < projectsCount; i++) {
                    projectCostsExtended[i].extended = false
                }

                localStorage.setItem('userPreferanceCostsExtended', JSON.stringify(projectCostsExtended))
            }
            return {...state, ...{projectsCosts: projectsCosts}}

        case ProjectCostsConsts.DELETE_PROJECT:
            var projectsCosts = [...state.projectsCosts]

            projectsCosts = projectsCosts.filter(c => c.projectId !== action.projectId)
            return {...state, ...{projectsCosts: projectsCosts}}

        default:
            return state
    }
}