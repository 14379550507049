const waProjects = [
    587,    //Wine Avenue - Marketing
    588,    //Sales On&Off trade
    589,    //Sales B2B B2C
    590,    //Admin
    591,    //Zakupy
    592,    //Wynagrodzenia
    593,    //BCH
]

const waLevel1 = [
    {
        "description": "KWS - 100",
        "code": "100",
    },
    {
        "description": "KWS-Towary - 110",
        "code": "110",
    },
    {
        "description": "KWS-Usługi - 120",
        "code": "120",
    },
    {
        "description": "KWS-Transport - 130",
        "code": "130",
    },
    {
        "description": "KWS-Transport-duży - 131",
        "code": "131",
    },
    {
        "description": "KWS-Transport-kurierski - 132",
        "code": "132",
    },
    {
        "description": "KWS-Transport-własny - 133",
        "code": "132",
    },
    {
        "description": "KWS-Magazyny - 140",
        "code": "140",
    },
    {
        "description": "KWS-Magazyn - 141",
        "code": "141",
    },
    {
        "description": "KWS-Opakowania - 150",
        "code": "150",
    },
    {
        "description": "Punkty sprzedaży - 200",
        "code": "200",
    },
    {
        "description": "Punkty sprzedaży-CS Szara 10 - 201",
        "code": "201",
    },
    {
        "description": "Marketing - 300",
        "code": "300",
    },
    {
        "description": "Marketing-usługi graf druk - 301",
        "code": "301",
    },
    {
        "description": "Marketing-Komunikacja PR - 302",
        "code": "302",
    },
    {
        "description": "Marketing-Szkolenia eventy - 303",
        "code": "303",
    },
    {
        "description": "Marketing-Retro - 304",
        "code": "304",
    },
    {
        "description": "Marketing-Urządzenia i usługi kons-inst - 305",
        "code": "305",
    },
    {
        "description": "Marketing-Pozostałe usługi - 306",
        "code": "306",
    },
    {
        "description": "Marketing-Trade marketing - 307",
        "code": "307",
    },
    {
        "description": "KDS - 400",
        "code": "400",
    },
    {
        "description": "KDS-Wynagrodzenia - 401",
        "code": "401",
    },
    {
        "description": "KDS-Koszty szkoleń i integracji sprzedaży - 402",
        "code": "402",
    },
    {
        "description": "KDS-Dorota Rozbicka - 403",
        "code": "403",
    },
    {
        "description": "KDS-Miroslława Nilek - 404",
        "code": "404",
    },
    {
        "description": "KDS-Paweł Złotnik - 405",
        "code": "405",
    },
    {
        "description": "KDS-Dariusz Ostrowski - 406",
        "code": "406",
    },
    {
        "description": "KDS-Michał Cieśliński - 407",
        "code": "407",
    },
    {
        "description": "KDS-Daniel Melchior - 408",
        "code": "408",
    },
    {
        "description": "KDS-Dominika Szymańska - 409",
        "code": "409",
    },
    {
        "description": "KDS-Mateusz Opara - 410",
        "code": "410",
    },
    {
        "description": "KDS-Artur Marcula - 411",
        "code": "411",
    },
    {
        "description": "KDS-Sebastian Zacharko - 412",
        "code": "412",
    },
    {
        "description": "KDS-Łukasz Sajnóg - 413",
        "code": "413",
    },
    {
        "description": "KDS-Ewelina Krawczyk-Staniszewska - 414",
        "code": "413",
    },
    {
        "description": "KO - 500",
        "code": "500",
    },
    {
        "description": "KO-Wynagrodzenia - 501",
        "code": "501",
    },
    {
        "description": "KO-Koszty biura - 502",
        "code": "502",
    },
    {
        "description": "KO-Szkolenia pracowników biurowych - 503",
        "code": "503",
    },
    {
        "description": "KO-Koszty Zarządu - 503",
        "code": "504",
    },
    {
        "description": "Przychody operacyjne - 601",
        "code": "601",
    },
    {
        "description": "Koszty operacyjne - 602",
        "code": "602",
    },
    {
        "description": "Przychody finansowe - 701",
        "code": "701",
    },
    {
        "description": "Koszty finansowe - 702",
        "code": "702",
    }
]

const waLevel2 = [
    {
        "description": "KDS-Dorota Rozbicka - 403",
        "code": "403"
    },
    {
        "description": "KDS-Miroslława Nilek - 404",
        "code": "404"
    },
    {
        "description": "KDS-Paweł Złotnik - 405",
        "code": "405"
    },
    {
        "description": "KDS-Dariusz Ostrowski - 406",
        "code": "406"
    },
    {
        "description": "KDS-Michał Cieśliński - 407",
        "code": "407"
    },
    {
        "description": "KDS-Daniel Melchior - 408",
        "code": "408"
    },
    {
        "description": "KDS-Dominika Szymańska - 409",
        "code": "409"
    },
    {
        "description": "KDS-Mateusz Opara - 410",
        "code": "4103"
    },
    {
        "description": "KDS-Artur Marcula - 411",
        "code": "411"
    },
    {
        "description": "KDS-Sebastian Zacharko - 412",
        "code": "412"
    },
    {
        "description": "KDS-Łukasz Sajnóg - 413",
        "code": "413"
    },
    {
        "description": "KDS-Ewelina Krawczyk-Staniszewska - 414",
        "code": "413",
    }
]

const waLevel3 = [
    {
        "description": "Zestawy świąteczne - ?",
        "code": "?"
    }
]

const waLevel4 = [
    {
        "description": "NKUP - ?",
        "code": "?"
    }
]

const waMpkSettings = {
    levelsCount: 4,
    hasDependentSublevels: false,
    level1List: waLevel1,
    level2List: waLevel2,
    level3List: waLevel3,
    level4List: waLevel4,
}

module.exports = {
    waProjects,
    waMpkSettings
};