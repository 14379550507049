import React, { Component } from 'react'
import PropTypes from 'prop-types'

import routes from "../helpers/routes"
import { history } from "../helpers/history"

import '../css/ErrorPage.css'

export class ErrorPage extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        return (
            <div className="error-panel">
                <div className="error-container">
                    <div className="d-flex">
                        <div id="errorBoundler">
                            {/*<span style={{fontSize: '70px'}}className="icon-meh"></span>*/}
                            <h3 className="text big">{this.context.t('Oops... a server error occurred.')}</h3>
                            <div className="text medium">{this.context.t("We know about it and we act.")}</div>
                            <div className="text">{this.context.t("Please refresh the page and try again. If that doesn't help, try again in about an hour.")}</div>
                            <div className="text">
                                {this.context.t("You can also send a service request by providing details in the form: ")}
                                <span className="helpdesk-link" onClick={() => { history.push(routes.panel.help); window.location.reload(false) }}>{this.context.t('Helpdesk')}</span>
                                {this.context.t(' or by e-mail: ')}
                                <a href="mailto:support@easydocs.pl">support@easydocs.pl</a>
                            </div>
                            <div className="button">
                                <div className="button refresh" onClick={() => window.location.reload(false)}>{this.context.t('Refresh page')} </div>
                                <div className="button home" onClick={() => { history.push(routes.panel.dashboard); window.location.reload(false) }}>{this.context.t('Home page')} </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ErrorPage.contextTypes = {
    t: PropTypes.func
}

export default ErrorPage