import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Document, Page, pdfjs } from 'react-pdf';
import { Spinner, Bounce } from 'react-activity'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import { AlertActions } from '../actions/AlertActions'

import '../css/PdfPagesSelector.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export class PdfPagesSelector extends Component {
    constructor(props) {
        super(props)
        this.state = {
            numPages: 0,
            selectedPages: [1],
            pdfScale: 1,

            showZoom: false,
            zoomedPage: -1,
        }
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({
            numPages: numPages,
        });
    }

    onPageLoadSuccess = obj => {
        var originalPdfWidth = obj.originalWidth
        console.log(((window.innerWidth * 0.175) / originalPdfWidth * 0.9).toFixed(1))

        this.setState({
            pdfScale: ((window.innerWidth * 0.175) / originalPdfWidth * 0.9).toFixed(1)
        })
    }

    selectPageClick = (i, alreadySelected) => {
        var { selectedPages } = this.state
        if (alreadySelected) {
            selectedPages = selectedPages.filter(e => e !== i)
        } else {
            if (selectedPages.length < 9) {
                selectedPages.push(i)
            } else {
                this.props.alertWarn(this.context.t('You can select a maximum of 8 pages'))
            }
        }

        selectedPages.sort()

        this.setState({
            selectedPages: selectedPages
        })
    }

    pdfPageClick = i => {
        this.setState({
            showZoom: true,
            zoomedPage: i
        })
    }

    confirmPages = () => {
        const { selectedPages } = this.state
        const { file } = this.props

        if (selectedPages.length === 0) {
            this.props.alertWarn(this.context.t("You must select at least one page"))
        } else {
            console.log(file)
            this.props.saveFile(file, file.projectId, selectedPages)
            this.props.close()
        }
    }

    mouseDownOnPreview = e => {
    }

    closeZoom = () => {
        this.setState({
            showZoom: false,
            zoomedPage: -1
        })
    }

    render() {
        const { numPages, pdfScale, selectedPages, showZoom, zoomedPage } = this.state
        const { file } = this.props
        return (
            <div className={`pdf-pages-selector-background ${showZoom ? 'zoom-opened' : ''}`}>

                {
                    showZoom ? (
                        <div className="pdf-zoom" onClick={e => this.closeZoom()}>
                            <SimpleBar style={{ width: '100%', maxHeight: "100vh" }}>
                                <Document file={file.src} onLoadSuccess={this.onDocumentLoadSuccess} onLoadError={console.error}>
                                    <Page pageNumber={zoomedPage} scale={1.5} />
                                </Document>
                            </SimpleBar>
                        </div>
                    ) : null
                }
                <div className="pdf-pages-selector">
                    <h3>{this.context.t('Select the pages that will be interpreted in the OCR form')}</h3>
                    <p className="disclaimer">{this.context.t('(whole document will be indexed for later content search during night processing)')}</p>

                    <div className="selected-pages">
                        <span className="label">{this.context.t('Selected pages numbers:')}</span>
                        <span className="pages">{selectedPages.join(', ')}</span>
                    </div>

                    <SimpleBar style={{ display: "flex", maxHeight: "60vh" }}>
                        <Document file={file.src} onLoadSuccess={this.onDocumentLoadSuccess} onLoadError={console.error}>
                            {
                                Array.apply(null, Array(numPages))
                                    .map((x, i) => (
                                        <div className={`page-container ${selectedPages.includes(i + 1) ? 'selected' : ''}`}>
                                            <div className={`select-page-checkbox ${selectedPages.includes(i + 1) ? 'selected' : ''}`} onClick={() => this.selectPageClick(i + 1, selectedPages.includes(i + 1))}></div>
                                            <Page pageNumber={i + 1} scale={pdfScale} onLoadSuccess={this.onPageLoadSuccess} onClick={() => this.pdfPageClick(i + 1)} />
                                        </div>
                                    ))
                            }
                        </Document>
                    </SimpleBar>
                    <div className="action-buttons">
                        <div className="button cancel" onClick={() => this.props.close()}>{this.context.t('Cancel')}</div>
                        <div className="button upload" onClick={() => this.confirmPages()}>{this.context.t('Upload')}</div>
                    </div>
                </div >

            </div>
        )
    }
}

PdfPagesSelector.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(PdfPagesSelector)