import arrayObjectIndexOf from "../helpers/indexOfObject"

export const ChatConst = {
  SHOW_CHAT_LOADING: 'SHOW_CHAT_LOADING',
  ADD_ALL_ENTRIES: "ADD_ALL_ENTRIES",
  ADD_NEW_ENTRY: "ADD_NEW_ENTRY",
  UPDATE_NEW_ENTRY: "UPDATE_NEW_ENTRY",
  GET_FILE_INST: "GET_FILE_INST",
  CLEAR_ENTRIES: "CLEAR_ENTRIES",
  OPEN_CHAT: "OPEN_CHAT",
  CLOSE_CHAT: "CLOSE_CHAT",
  GET_USERS_LIST: "GET_USERS_LIST",
  SOCKET_MESSAGE: "SOCKET_MESSAGE",
  CLEAR_USER_LIST: "CLEAR_USER_LIST",
  IS_OPEN_POPUP: "IS_OPEN_POPUP",

  WAITING_FOR_OLD_NOTIFICATIONS: "WAITING_FOR_OLD_NOTIFICATIONS",
  GET_OLD_NOTIFICATIONS: "GET_OLD_NOTIFICATIONS",
  CLEAR_OLD_NOTIFICATIONS: 'CLEAR_OLD_NOTIFICATIONS',
  MARK_NOTIFICATION_AS_READ: "MARK_NOTIFICATION_AS_READ",
  ADD_NEW_NOTIFICATION: "ADD_NEW_NOTIFICATION",
  CHANGE_FILE_STATUS_TO_ACCEPTED: "CHANGE_FILE_STATUS_TO_ACCEPTED",
  MARK_AND_SORT_NOTIFICATION_BY_FAVORITE: "MARK_AND_SORT_NOTIFICATION_BY_FAVORITE",
  SORT_NOTIFICATIONS: "SORT_NOTIFICATIONS",
  DELETE_NOTIFICATION: "DELETE_NOTIFICATION"
};

const initialState = {
  showChatLoading: false,
  entries: [],
  fileCurrentInst: {},
  isOpenChat: false,
  usersList: [],
  isNotificationsPopupOpen: false,

  chatNotificationsCount: 0,
  chatNotifications: [],
  waitingForOldChatNotifications: false,
  skipForOlderChatNotifications: 0,
  hasOlderChatNotifications: false,
  notificationsSortOrder: "DESC",
  notificationsSortField: "created",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ChatConst.SHOW_CHAT_LOADING: {
      return { ...state, entries: [], showChatLoading: true };
    }
    case ChatConst.ADD_ALL_ENTRIES: {
      return { ...state, entries: action.entries, showChatLoading: false };
    }
    case ChatConst.ADD_NEW_ENTRY: {
      const { entry } = action;
      const entries = [...state.entries, entry];
      return { ...state, entries };
    }
    case ChatConst.UPDATE_NEW_ENTRY: {
      const { entry, randomId, userId } = action;
      var currentEntries = [...state.entries];
      const messageToUpdateIndex = currentEntries.findIndex(e => e.id === randomId && e.user_id === userId)
      if (messageToUpdateIndex > -1) currentEntries[messageToUpdateIndex] = entry
      return { ...state, entries: currentEntries };
    }
    case ChatConst.GET_FILE_INST: {
      const { fileCurrentInst } = action;
      return { ...state, fileCurrentInst };
    }
    case ChatConst.CLEAR_ENTRIES: {
      return {
        ...state,
        entries: [],
        fileCurrentInst: {}
      };
    }
    case ChatConst.OPEN_CHAT: {
      const { isOpenChat } = action;
      return { ...state, isOpenChat };
    }
    case ChatConst.CLOSE_CHAT: {
      const { isOpenChat } = action;
      return { ...state, isOpenChat };
    }
    case ChatConst.GET_USERS_LIST: {
      const { usersList } = action;
      return { ...state, usersList };
    }
    case ChatConst.SOCKET_MESSAGE: {
      const { entry } = action;
      const { fileCurrentInst } = state
      if (fileCurrentInst && fileCurrentInst.id === entry.file_id) {
        const entries = [...state.entries, entry];
        return { ...state, entries };
      } else {
        return state
      }
    }
    case ChatConst.CLEAR_USER_LIST: {
      const { usersList } = action;
      return { ...state, usersList };
    }
    case ChatConst.IS_OPEN_POPUP: {
      const { isNotificationsPopupOpen } = action;
      return { ...state, isNotificationsPopupOpen };
    }

    case ChatConst.WAITING_FOR_OLD_NOTIFICATIONS:
      return { ...state, ...{ waitingForOldChatNotifications: action.loading, hasOlderChatNotifications: false } }

    case ChatConst.GET_OLD_NOTIFICATIONS:
      var chatNotificationsCount = state.chatNotificationsCount
      var oldNotifications = state.chatNotifications ? [...state.chatNotifications] : []
      action.notifications.forEach(n => {
        if (!n.was_read) chatNotificationsCount++
      })
      return { ...state, ...{ chatNotifications: [].concat(oldNotifications, action.notifications), chatNotificationsCount: chatNotificationsCount, skipForOlderChatNotifications: action.skip + 50, hasOlderChatNotifications: action.hasOlder } }

    case ChatConst.CLEAR_OLD_NOTIFICATIONS:
      return { ...state, ...{ chatNotifications: [], chatNotificationsCount: 0, skipForOlderChatNotifications: 0, hasOlderChatNotifications: false } }

    case ChatConst.MARK_NOTIFICATION_AS_READ:
      var newChatNotificationsCount = 0
      var oldNotifications = state.chatNotifications ? [...state.chatNotifications] : []
      var notificationIndex = arrayObjectIndexOf(oldNotifications, action.fileId, "fileId")
      if (notificationIndex > -1) {
        oldNotifications[notificationIndex].was_read = true
        chatNotificationsCount--
      }
      oldNotifications.forEach(n => {
        if (!n.was_read) newChatNotificationsCount++
      })
      sortList = oldNotifications.sort(compare(state.notificationsSortField, state.notificationsSortOrder))
      var notificationListWithoutFavorite = sortList.filter(el => !el.favorite)
      var notificationListOnlyFavorite = sortList.filter(el => el.favorite)
      notificationListWithoutFavorite.unshift(notificationListOnlyFavorite)

      return { ...state, ...{ chatNotifications: notificationListWithoutFavorite.flat(1), chatNotificationsCount: newChatNotificationsCount } }

    case ChatConst.CHANGE_FILE_STATUS_TO_ACCEPTED:
      var oldNotifications = state.chatNotifications ? [...state.chatNotifications] : []
      var notificationIndex = arrayObjectIndexOf(oldNotifications, action.fileId, "fileId")
      if (notificationIndex > -1) {
        oldNotifications[notificationIndex].fileStatus = "VERIFIED"
      }

      return { ...state, ...{ chatNotifications: oldNotifications } }

    case ChatConst.ADD_NEW_NOTIFICATION:
      console.log('HERE 2222')
      console.log('HERE 2222')
      console.log('HERE 2222')
      console.log('HERE 2222')
      console.log('HERE 2222')
      console.log(action.notification)
      var newChatNotificationsCount = 0
      var oldNotifications = state.chatNotifications ? [...state.chatNotifications] : []
      const selectedNotification = oldNotifications.find(n => n.fileId === action.notification.fileId)
      oldNotifications = oldNotifications.filter(n => n.fileId !== action.notification.fileId)
      var newNotification
      if (selectedNotification) {
        newNotification = {
          ...action.notification,
          is_mentioned: selectedNotification?.is_mentioned || action?.notification?.is_mentioned,
          favorite: selectedNotification?.favorite || action?.notification?.favorite || false
        }
      } else {
        newNotification = {
          ...action.notification,
          favorite: action?.notification?.favorite || false
        }
      }

      oldNotifications.unshift(newNotification)
      oldNotifications.forEach(n => {
        if (!n.was_read) newChatNotificationsCount++
      })
      sortList = oldNotifications.sort(compare(state.notificationsSortField, state.notificationsSortOrder))
      var sort = 'DESC'
      var notificationListWithoutFavorite = sortList.filter(el => !el.favorite)
      var notificationListOnlyFavorite = sortList.filter(el => el.favorite)
      notificationListWithoutFavorite.unshift(notificationListOnlyFavorite)

      return { ...state, ...{ chatNotifications: notificationListWithoutFavorite.flat(1), chatNotificationsCount: newChatNotificationsCount } }

    case ChatConst.MARK_AND_SORT_NOTIFICATION_BY_FAVORITE:
      var oldNotifications = state.chatNotifications ? [...state.chatNotifications] : []
      var r = oldNotifications.map(n => {
        if (n.fileId === action.fileId) {
          n.favorite = !n.favorite
          return n
        } else {
          return n
        }
      })
      var sortList = r.sort(compare("created", "DESC"))
      var notificationListWithoutFavorite = sortList.filter(el => !el.favorite)
      var notificationListOnlyFavorite = sortList.filter(el => el.favorite)
      notificationListWithoutFavorite.unshift(notificationListOnlyFavorite)

      return { ...state, ...{ chatNotifications: notificationListWithoutFavorite.flat(1) } }

    case ChatConst.SORT_NOTIFICATIONS:
      var oldNotifications = state.chatNotifications ? [...state.chatNotifications] : []
      var sortList = oldNotifications.sort(compare(action.sortField, action.sortOrder))
      var notificationListWithoutFavorite = sortList.filter(el => !el.favorite)
      var notificationListOnlyFavorite = sortList.filter(el => el.favorite)
      notificationListWithoutFavorite.unshift(notificationListOnlyFavorite)

      return { ...state, ...{ chatNotifications: notificationListWithoutFavorite.flat(1), notificationsSortField: action.sortField, notificationsSortOrder: action.sortOrder } }

    case ChatConst.DELETE_NOTIFICATION:
      var newChatNotificationsCount = 0
      var oldNotifications = state.chatNotifications ? [...state.chatNotifications] : []
      oldNotifications = oldNotifications.filter(n => n.fileId !== action.fileId)
      oldNotifications.forEach(n => {
        if (!n.was_read) newChatNotificationsCount++
      })
      sortList = oldNotifications.sort(compare(state.notificationsSortField, state.notificationsSortOrder))
      var notificationListWithoutFavorite = sortList.filter(el => !el.favorite)
      var notificationListOnlyFavorite = sortList.filter(el => el.favorite)
      notificationListWithoutFavorite.unshift(notificationListOnlyFavorite)

      return { ...state, ...{ chatNotifications: notificationListWithoutFavorite.flat(1), chatNotificationsCount: newChatNotificationsCount } }


    default: {
      return state;
    }
  }
};

//Selectory
export const getAllEntriess = (state) => state.Chat.entries;
export const getCurrentFile = (state) => state.Chat.fileCurrentInst;
export const getisOpenChat = (state) => state.Chat.isOpenChat;
export const getUsersList = (state) => state.Chat.usersList;
export const getIsOpenPopUp = (state) => state.Chat.isNotificationsPopupOpen;


const compare = (field, order) => {
  return function comparatorDESC(a, b) {
    const sortA = a[field]
    const sortB = b[field]
    if (order === 'DESC') {
      if (sortA < sortB) {
        return 1;
      } else if (sortA > sortB) {
        return -1;
      } else if (sortA === sortB) {
        return 0;
      }
    }
    if (order === 'ASC') {
      if (sortA > sortB) {
        return 1;
      } else if (sortA < sortB) {
        return -1;
      } else if (sortA === sortB) {
        return 0;
      }
    }

  }
}