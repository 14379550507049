const plDirectorySortingValues = {
    'other': 1,
    'correspondence': 2,
    'corporate': 3,
    'costs': 4,
    'income': 5,
    'agreements': 6,
    'expenses': 7,
    'deleted': 8,
    'in progress': 9
}

const enDirectorySortingValues = {
    'agreements': 1,
    'corporate': 2,
    'correspondence': 3,
    'costs': 4,
    'expenses': 5,
    'income': 6,
    'other': 7,
    'deleted': 8,
    'in progress': 9
}

function dynamicComparer(key, order = 'asc') {
    return function (a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            return 0;
        }

        const varA = (typeof a[key] === 'string') ?
            a[key].toUpperCase() : a[key];
        const varB = (typeof b[key] === 'string') ?
            b[key].toUpperCase() : b[key];

        var comparison = 0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }
        return (
            (order == 'desc') ? (comparison * -1) : comparison
        );
    };
}

function sortUploadedFiles(files) {
    files.map(project => {
        if (project.files && project.files.length) {
            var projectCode = project.files[0].path.split('/')[0]
            project.code = projectCode
        } else {
            project.code = ''
        }
    })
    files.sort(dynamicComparer("code"))
    return files
}

function findIndex(files, n, f) {
    // Lower and upper bounds
    var start = 0;
    var end = n - 1;

    if (f) {
        // Traverse the search space
        while (start <= end) {
            var mid = Math.floor((start + end) / 2);

            if (files[mid].id === f.id)
                return mid;

            var varA = files[mid].path.split('/')[1].toLowerCase()
            var varB = f.path.split('/')[1].toLowerCase()
            var comparison = 0
            if (localStorage.getItem('language') === 'pl') {
                comparison = plDirectorySortingValues[varA] - plDirectorySortingValues[varB]
            } else {
                comparison = enDirectorySortingValues[varA] - enDirectorySortingValues[varB]
            }

            if (comparison < 0) start = mid + 1;
            else if (comparison > 0) end = mid - 1;

            else {
                if (files[mid].path > f.path) start = mid + 1;
                else end = mid - 1;
            }
        }
    }

    // Return insert position
    return end + 1;
}

module.exports = {
    sortUploadedFiles,
    findIndex
}