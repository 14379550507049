import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { EventActions } from '../actions/EventActions'

export class DeleteInvoiceModal extends Component {

    render() {
        return (
            <div className="delete-invoice-popup popup">
                <div className="popup-card">
                    {
                        this.props.moveToTrash ? (
                            <>
                                <div className="header">{this.context.t('Are you sure you want to move this document to trash?')}</div>
                                <div className="text">{this.context.t('You can restore documents from the trash can for 45 days from the time you moved them there. After this period, the files are permanently deleted without the possibility of their recovery')}</div>
                            </>
                        ) : (
                            <div className="header">{this.context.t('Are you sure you want to permanently delete this document?')}</div>
                        )
                    }

                    <div className="action-buttons">
                        <div className="btn" onClick={e => this.props.cancel()}>{this.context.t('Cancel')}</div>
                        <div className="btn delete" onClick={e => this.props.delete()}>{this.props.moveToTrash ? this.context.t('Move to trash') : this.context.t('Delete')}</div>
                    </div>
                </div>
            </div>
        )
    }
}

DeleteInvoiceModal.contextTypes = {
	t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteInvoiceModal)