import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import TextareaAutosize from 'react-textarea-autosize'

import LIST from '../../../helpers/DevCosts/projectCodes.json'

import '../../../css/OcrPage.css'
export class ProjectCode extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showList: false
        }

        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick);
        window.addEventListener("keydown", this.escFunction, false)
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
        window.removeEventListener("keydown", this.escFunction, false)
    }

    escFunction = e => {
        if (e.keyCode === 27) {
            this.handleClick(e)
        }
    }

    handleClick = (event) => {
        if (event.keyCode === 27 || (this.wrapperRef && !this.wrapperRef.current.contains(event.target))) {
            this.setState({
                showList: false
            })
        } else {
        }
    }

    toggleList = show => {
        const { editMode } = this.props
        if (editMode) {
            this.setState({
                showList: show
            })
        }
    }

    selectOption = code => {
        this.props.select(code)
        this.setState({
            showList: false
        })
    }

    onInputChange = e => {
        e.preventDefault()
        const { name, value } = e.target

        this.setState({ [name]: value })
    }

    render() {
        const { selectedOption, editMode, projectId } = this.props
        const { filter, showList } = this.state
        return (
            <div className={`doc-prop project ${showList ? 'list-cative' : ''}`} id="project-code-container" ref={this.wrapperRef}>
                {
                    showList ? (
                        <div className="drop-down-list">
                            <div className="header">
                                {this.context.t('Project code')}:
                            </div>
                            <SimpleBar style={{ height: 'auto', maxHeight: '300px', width: '100%' }}>
                                <ul>
                                    {
                                        LIST.map((t, i) => {
                                            if ((t.visibleInBinders && t.visibleInBinders.includes(projectId)) || [13, 45].includes(projectId)) {
                                                return (
                                                    <li
                                                        className={`tag-item ${selectedOption === t.code ? 'selected' : ''}`}
                                                        onClick={() => this.selectOption(t.code)}
                                                        key={`project-code-${i}`}>
                                                        {`${t.code} - ${t.description}`}
                                                        {
                                                            t.company ? ` (${t.company})` : ""
                                                        }
                                                    </li>
                                                )
                                            } else {
                                                return null
                                            }
                                        })
                                    }
                                </ul>
                            </SimpleBar>
                        </div>
                    ) : (
                        null
                    )
                }
                <span className="name">{this.context.t('Project code')}</span>
                <TextareaAutosize
                    className={`${!editMode ? 'read-only' : ''}`}
                    readOnly={true}
                    value={selectedOption}
                    name='project-codes'
                    minRows={1}
                    maxRows={10}
                    onFocus={() => this.toggleList(true)}
                />
                <div className={`list-icon ${showList ? 'extended' : ''}`} onClick={() => this.toggleList(!showList)}></div>
            </div>
        )
    }
}

ProjectCode.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCode)