import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TextareaAutosize from 'react-textarea-autosize'
import { Spinner } from 'react-activity'

import { AlertActions } from '../../actions/AlertActions'
import { DocflowActions } from '../../actions/DocflowActions'

export class DocflowInfoModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            alreadyPassedSteps: [],
            currentStep: -1,
            showAcceptances: false
        }

        this.wrapperRef = React.createRef()
    }

    componentDidMount() {
        const { docflow, fileInstance } = this.props
        if (fileInstance) {
            var docflowOrder = docflow.docflow_scheme.stages_order
            if (docflowOrder) {
                var tempPassedSteps = [], foundCurrent = false
                docflowOrder.forEach((step, index) => {
                    if (step === fileInstance.docflow_step_id) {
                        foundCurrent = true
                    }

                    if (!foundCurrent) {
                        tempPassedSteps.push(step)
                    }
                })

                this.setState({
                    alreadyPassedSteps: tempPassedSteps,
                    currentStep: fileInstance.docflow_step_id,
                    showAcceptances: true
                })
            }
        }
        document.addEventListener("mousedown", this.handleClick);
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
    }

    handleClick = event => {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.props.close()
        }
    }

    renderStepUsers = (stepId, stepType, requiredUsersCount, additionalSettings) => {
        const { additionalVerification, additionalAcceptance, fileInstance } = this.props
        const {
            alreadyPassedSteps, currentStep, showAcceptances
        } = this.state

        var usersToReturn = []
        console.log(additionalSettings)
        if (stepType === "CONDITION") {
            additionalSettings.conditionBlocks.forEach((block, index) => {
                if (block.selectedUsers) {
                    usersToReturn.push(
                        <div className="contition-header">{this.context.t(block.efectId === 'ACCEPTANCE' ? 'Acceptance' : 'Notification')}</div>
                    )
                    let renderedUsers = []
                    block.selectedUsers.forEach(user => {
                        if (user.id === 0 && fileInstance && fileInstance.uploaded_by_number) {
                            user.id = fileInstance.uploaded_by_number
                            user.name = fileInstance.uploadedBy
                        }
                        if (!renderedUsers.includes(user.id)) {
                            renderedUsers.push(user.id)

                            let userAccepted = false
                            if (block.efectId === 'ACCEPTANCE' && fileInstance) {
                                if (fileInstance.accepted_by === user.id) {
                                    userAccepted = true
                                } else {
                                    var userAdditionalAcceptance = additionalAcceptance?.find(u => u.user_id === user.id)
                                    if (userAdditionalAcceptance) {
                                        userAccepted = userAdditionalAcceptance.accepted
                                    } else {
                                        userAdditionalAcceptance = additionalAcceptance?.find(u => user.id === 0 && u.user_id === fileInstance.uploaded_by_number)
                                        userAccepted = userAdditionalAcceptance?.accepted || false
                                    }
                                }
                            }

                            usersToReturn.push(
                                <div className="user">
                                    <div className={`user-photo ${user.profilePic ? '' : 'default-photo'}`}>
                                        {
                                            user.profilePic ? (
                                                <img src={user.profilePic} alt={user.name} />
                                            ) : null
                                        }
                                    </div>
                                    <div className="name">{user.name}</div>
                                    {
                                        userAccepted ? (
                                            <div className="accepted"></div>
                                        ) : null
                                    }
                                </div>
                            )
                        }
                    })

                    if (block.selectedUsers.length > 1 && block.efectId === 'ACCEPTANCE') {
                        usersToReturn.push(
                            <div className="users-required">{this.context.t('required: {n1} from {n2}', { n1: block.requiredUsersCount, n2: block.selectedUsers.length })}</div>
                        )
                    }

                    if (additionalSettings.conditionBlocks[index + 1]) {
                        usersToReturn.push(
                            <div className="contition-separator">{this.context.t('Or')}</div>
                        )
                    }

                }
            })
        } else {
            let renderedUsers = []
            additionalSettings.selectedUsers.forEach(user => {
                if (user.id === 0 && fileInstance && fileInstance.uploaded_by_number) {
                    user.id = fileInstance.uploaded_by_number
                    user.name = fileInstance.uploadedBy
                }

                let userAccepted = false
                if (stepType === 'VERIFICATION' && fileInstance) {
                    if (fileInstance.verified_by === user.id && requiredUsersCount === 1) {
                        userAccepted = true
                    } else {
                        var userAdditionalVerification = additionalVerification?.find(u => u.user_id === user.id && u.docflow_step_id === stepId)
                        if (userAdditionalVerification) {
                            userAccepted = userAdditionalVerification.verified
                        } else {
                            userAdditionalVerification = additionalVerification?.find(u => user.id === 0 && u.user_id === fileInstance.uploaded_by_number && u.docflow_step_id === stepId)
                            userAccepted = userAdditionalVerification?.verified || false
                        }
                    }
                } else if (stepType === 'ACCEPTANCE' && fileInstance) {
                    if (fileInstance.accepted_by === user.id && requiredUsersCount === 1) {
                        userAccepted = true
                    } else {
                        var userAdditionalAcceptance = additionalAcceptance?.find(u => u.user_id === user.id && u.docflow_step_id === stepId)
                        if (userAdditionalAcceptance) {
                            userAccepted = userAdditionalAcceptance.accepted
                        } else {
                            userAdditionalAcceptance = additionalAcceptance?.find(u => user.id === 0 && u.user_id === fileInstance.uploaded_by_number && u.docflow_step_id === stepId)
                            userAccepted = userAdditionalAcceptance?.accepted || false
                        }
                    }
                }

                if (!alreadyPassedSteps.includes(stepId) && stepId !== currentStep) {
                    userAccepted = false
                }

                if (!renderedUsers.includes(user.id)) {
                    console.log(user)
                    renderedUsers.push(user.id)
                    usersToReturn.push(
                        <div className="user">
                            <div className={`user-photo ${user.profilePic ? '' : 'default-photo'}`}>
                                {
                                    user.profilePic ? (
                                        <img src={user.profilePic} alt={user.name} />
                                    ) : null
                                }
                            </div>
                            <div className="name">{user.name}</div>
                            {
                                userAccepted ? (
                                    <div className="accepted"></div>
                                ) : null
                            }
                        </div>
                    )
                }
            })

            if (additionalSettings.selectedUsers.length > 1) {
                usersToReturn.push(
                    <div className="users-required">{this.context.t('required: {n1} from {n2}', { n1: requiredUsersCount, n2: usersToReturn.length })}</div>
                )
            }
        }

        return usersToReturn
    }

    render() {
        const { docflow, additionalVerification, additionalAcceptance, fileInstance } = this.props
        const {
            alreadyPassedSteps, currentStep, showAcceptances
        } = this.state

        console.log(docflow)

        return (
            <div className="popup docflow-info-popup">
                <div className="popup-card" ref={this.wrapperRef} style={{ width: `${docflow?.docflowSteps?.length * 200 + 20}px` }}>
                    <h3>{this.context.t('Scheme: {d}', { d: docflow.name })}</h3>
                    <div className="steps-wrapper">
                        {
                            docflow?.docflowSteps?.map((step, index) => {
                                console.log('HERE')
                                console.log(index < docflow?.docflowSteps.length - 1)
                                return (
                                    <>
                                        <div key={step.id} className={`step`}>
                                            <div className={`step-title ${alreadyPassedSteps.includes(step.id) ? 'passed' : ''} ${step.id === currentStep ? 'current' : ''}`}>{step.name}</div>
                                            <div className="step-users">
                                                {this.renderStepUsers(step.id, step.step_type, step.required_users_count, step.additional_settings)}
                                            </div>
                                        </div>

                                        {
                                            index < docflow?.docflowSteps.length - 1 ? (
                                                <div className="step-separator"></div>
                                            ) : null
                                        }
                                    </>
                                )
                            })
                        }
                    </div>

                    <div className="close-button" onClick={() => this.props.close()}>
                    </div>
                </div>
            </div>
        )

    }
}

DocflowInfoModal.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(DocflowInfoModal)