import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from 'moment'
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import ReactTooltip from 'react-tooltip'

import { CompanyActions } from "../../actions/CompanyActions";
import { AlertActions } from "../../actions/AlertActions";

import { splitAddress } from "../../helpers/splitAddress";
import currencyList from '../../helpers/currencyList.json'
import { emailRegex } from '../../helpers/regexList'

import Calendar from "../Calendar";
import NewDocItemPopup from './NewDocItemPopup'
import NewCounterpartyPopup from '../NewCounterpartyPopup'
import ExampleRecurrinInvoicePdf from './ExampleRecurrinInvoicePdf'

import "../../css/SalesPage.css";

export class NewRecurringInvoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLanguagesList: false,
            documentLanguage: 'pl',
            showNumberingSeriesList: false,
            numberingSeriesList: [],
            numberingSeriesId: -1,
            numberingSeriesName: '',
            numberingSeriesNextNumber: '',
            showIssueDateCalendar: false,
            showCurrencyList: false,
            currency: 'PLN',
            showAccountNumbersList: false,
            accountNumbersList: [],
            accountNumberId: -1,
            accountNumberName: '',
            accountNumber: '',
            accountNumberSwift: '',
            firstIssueDate: moment().add(1, 'day').format('YYYY-MM-DD'),
            showSaleDateCalendar: false,
            firstSaleDate: moment().add(1, 'day').format('YYYY-MM-DD'),
            showDueDateCalendar: false,
            firstDueDate: moment().add(8, 'day').format('YYYY-MM-DD'),
            recurringRule: 'MONTHLY',
            showRepeatPatternsList: false,
            repeatPatterns: ['ONCE', 'MONTHLY', 'LAST_DAY_OF_MONTH', 'LAST_WORKING_DAY_OF_MONTH', 'FIRST_DAY_OF_MONTH', 'FIRST_WORKING_DAY_OF_MONTH', 'FIRST_WORKING_DAY_OF_QUARTER', 'LAST_DAY_OF_QUARTER', 'LAST_DAY_OF_YEAR'],
            showRepeatUntilCalendar: false,
            repeatUntil: '',
            sellerName: '',
            sellerVatId: '',
            sellerAddress: '',
            sellerZipCode: '',
            sellerCity: '',
            sellerLogo: '',
            showCounterpartiesList: false,
            counterpartiesList: [],
            counterpartyId: -1,
            counterpartyName: '',
            counterpartyCode: '',
            counterpartyVatId: '',
            counterpartyAddress: '',
            counterpartyZipCode: '',
            counterpartyCity: '',
            showItemsList: false,
            savedDocItems: [],
            itemsListIndex: -1,
            invoicePositions: [
                {
                    id: -1,
                    name: "",
                    amount: "1",
                    unit: "szt.",
                    netPrice: "",
                    vat: "23",
                    netValue: "",
                    grossValue: "",
                },
            ],
            comments: '',
            splitPayment: false,
            reverseCharge: false,
            metodaKasowa: false,
            sendAfterGerating: false,
            email: '',

            cursorWait: false,

            showNewDocItemPopup: false,
            editingOldDocItem: false,
            editedDocItemIndex: -1,
            docItemToEdit: {},
            showNewCounterpartyPopup: false,
            editingOldCounterparty: false,
            counterpartyToEdit: {},

            showExamplePdf: false,
            examplePdf: null,
        };

        this.languageContainerRef = React.createRef()
        this.numberingSeriesContainerRef = React.createRef()
        this.issueDateContainerRef = React.createRef()
        this.saleDateContainerRef = React.createRef()
        this.dueDateContainerRef = React.createRef()
        this.repeatPatternContainerRef = React.createRef()
        this.repeatUntilContainerRef = React.createRef()
        this.counterpartyContainerRef = React.createRef()
        this.currencyContainerRef = React.createRef()
        this.accountNumberContainerRef = React.createRef()
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick);
        const { editingExisting, editedRecurringInvoice, currentCompany } = this.props
        if (editingExisting) {
            this.loadEditedInvoice(editedRecurringInvoice)
        }

        if (currentCompany && currentCompany.company_id) {
            this.getInvoiceNumberingSeries()
            this.getSavedDocItems();
            if (currentCompany.company) this.onCompannyChange()
        }
    }

    componentDidUpdate(prevProps) {
        const { currentCompany } = this.props
        if (currentCompany && currentCompany.company_id && currentCompany !== prevProps.currentCompany) {
            this.getInvoiceNumberingSeries()
            this.getSavedDocItems();
            if (currentCompany.company) this.onCompannyChange()
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
    }

    loadEditedInvoice = invoice => {
        var sellerBuyerAddress = splitAddress(invoice.counterparty.address)
        var formatedDocItems = [], amount, netPrice, vat, netValue

        invoice.items.map(item => {
            console.log(item)
            amount = parseFloat(item.amount) || 1
            netPrice = item.net_price ? parseFloat(item.net_price).toFixed(2) : parseFloat(0).toFixed(2)
            vat = this.getVat(item.vat_rate || '23')
            netValue = (amount * netPrice).toFixed(2)
            formatedDocItems.push({
                id: item.doc_item_dictionary_id,
                name: item.name,
                pkwiu: item.pkwiu,
                gtu: item.gtu,
                amount: item.amount % 1 ? parseFloat(amount).toFixed(3) : parseFloat(amount).toFixed(0),
                unit: item.unit,
                netPrice: netPrice,
                vat: item.vat_rate,
                netValue: netValue,
                grossValue: (netValue * (100 + vat) / 100).toFixed(2)
            })
        })
        this.setState({
            documentLanguage: invoice.language,
            numberingSeriesId: invoice.invoice_numbering_series_id,
            numberingSeriesName: invoice.numberingSeries.name,
            numberingSeriesNextNumber: invoice.numberingSeries.number,
            currency: invoice.currency,
            accountNumberId: invoice.bank_account_id,
            accountNumberName: invoice.bankAccount.bank_name,
            accountNumberSwift: invoice.bankAccount.bic,
            accountNumber: invoice.bankAccount.account_number,
            firstIssueDate: moment(invoice.next_issue_date).format('YYYY-MM-DD'),
            firstSaleDate: moment(invoice.next_sale_date).format('YYYY-MM-DD'),
            firstDueDate: moment(invoice.next_due_date).format('YYYY-MM-DD'),
            recurringRule: invoice.recurrence_rule,
            repeatUntil: invoice.repeat_until ? moment(invoice.repeat_until).format('YYYY-MM-DD') : '',
            counterpartyId: invoice.counterparty_id,
            counterpartyName: invoice.counterparty.name,
            counterpartyCode: invoice.counterparty.code,
            counterpartyVatId: invoice.counterparty.vat_id,
            counterpartyAddress: sellerBuyerAddress.address,
            counterpartyZipCode: sellerBuyerAddress.zipCode,
            counterpartyCity: sellerBuyerAddress.city,
            invoicePositions: formatedDocItems,
            comments: invoice.comments,
            splitPayment: invoice.split_payment,
            reverseCharge: invoice.reverse_charge,
            metodaKasowa: invoice.metoda_kasowa,
            sendAfterGerating: invoice.send_after_generating,
            email: invoice.send_after_generating ? invoice.email : '',
        })
    }

    getVat = vatRate => {
        return isNaN(parseFloat(vatRate)) ? 0.0 : parseFloat(vatRate);
    }

    getInvoiceNumberingSeries = () => {
        const { editingExisting, currentCompany } = this.props
        const { numberingSeriesId } = this.state
        this.props.getDocumentNumberForNewInvoice(moment().format("YYYY-MM"), currentCompany.company_id, 'invoice', (nextNumbers, noSeriesDefined) => {
            var choosenSeries = null
            if (nextNumbers && nextNumbers.length > 0) {
                if (editingExisting) {
                    this.setState({
                        numberingSeriesList: nextNumbers
                    });
                } else {
                    if (numberingSeriesId > -1) {
                        choosenSeries = nextNumbers.find(s => s.seriesId === numberingSeriesId)
                    }
                    if (!choosenSeries) choosenSeries = nextNumbers[0]
                    this.setState({
                        numberingSeriesId: choosenSeries.seriesId,
                        numberingSeriesName: choosenSeries.seriesName,
                        numberingSeriesNextNumber: choosenSeries.number,
                        numberingSeriesList: nextNumbers
                    });
                }
            } else if (noSeriesDefined) {
                this.props.alertWarn(this.context.t("You do not have any invoice numbering series defined. Create a new numbering series in the 'Company' tab"), 8000);
            } else {
                this.props.alertWarn(this.context.t("Failed to generate invoice number."));
            }
        });
    }

    getSavedDocItems = () => {
        const { currentCompany } = this.props
        this.props.getSavedDocItems(currentCompany.company_id, (savedDocItems) => {
            if (savedDocItems) {
                this.setState({
                    savedDocItems: savedDocItems,
                });
            }
        }
        );
    }

    onCompannyChange = () => {
        const { currentCompany } = this.props
        var sellerSplitAddress = splitAddress(currentCompany.company.address);
        this.setState({
            sellerName: currentCompany.company.name,
            sellerVatId: currentCompany.company.vat_id,
            sellerAddress: sellerSplitAddress.address,
            sellerZipCode: sellerSplitAddress.zipCode,
            sellerCity: sellerSplitAddress.city,
            sellerLogo: currentCompany.company.logo_url,
            counterpartiesList: currentCompany.company.counterparties,
            accountNumbersList: currentCompany.company.banks
        })
    }

    handleClick = (event) => {
        const { showLanguagesList, showNumberingSeriesList, showIssueDateCalendar, showSaleDateCalendar, showDueDateCalendar, showRepeatPatternsList, showRepeatUntilCalendar, showCounterpartiesList, showCurrencyList, showAccountNumbersList, showItemsList, itemsListIndex } = this.state
        if (showLanguagesList && this.languageContainerRef && !this.languageContainerRef.current.contains(event.target)) {
            this.setState({
                showLanguagesList: false
            })
        } else if (showNumberingSeriesList && this.numberingSeriesContainerRef && !this.numberingSeriesContainerRef.current.contains(event.target)) {
            this.setState({
                showNumberingSeriesList: false
            })
        } else if (showIssueDateCalendar && this.issueDateContainerRef && !this.issueDateContainerRef.current.contains(event.target)) {
            this.setState({
                showIssueDateCalendar: false
            })
        } else if (showSaleDateCalendar && this.saleDateContainerRef && !this.saleDateContainerRef.current.contains(event.target)) {
            this.setState({
                showSaleDateCalendar: false
            })
        } else if (showDueDateCalendar && this.dueDateContainerRef && !this.dueDateContainerRef.current.contains(event.target)) {
            this.setState({
                showDueDateCalendar: false
            })
        } else if (showRepeatPatternsList && this.repeatPatternContainerRef && !this.repeatPatternContainerRef.current.contains(event.target)) {
            this.setState({
                showRepeatPatternsList: false
            })
        } else if (showRepeatUntilCalendar && this.repeatUntilContainerRef && !this.repeatUntilContainerRef.current.contains(event.target)) {
            this.setState({
                showRepeatUntilCalendar: false
            })
        } else if (showCounterpartiesList && this.counterpartyContainerRef && !this.counterpartyContainerRef.current.contains(event.target)) {
            this.setState({
                showCounterpartiesList: false
            })
        } else if (showCurrencyList && this.currencyContainerRef && !this.currencyContainerRef.current.contains(event.target)) {
            this.setState({
                showCurrencyList: false
            })
        } else if (showAccountNumbersList && this.accountNumberContainerRef && !this.accountNumberContainerRef.current.contains(event.target)) {
            this.setState({
                showAccountNumbersList: false
            })
        } else if (itemsListIndex > -1 && showItemsList && !this.hasAncestor(event.target, `item-name-${itemsListIndex}`)) {
            this.setState({
                itemsListIndex: -1,
                showItemsList: false
            })
        }
    }

    hasAncestor = (element, id) => {
        while (element) {
            if (element.id && element.id === id) return true;
            element = element.parentNode;
        }
        return false;
    }

    onInputChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target
        this.setState({ [name]: value });
    }

    onInputChangeInvoicePossition = (e, index) => {
        var { invoicePositions } = this.state;
        var { name, value } = e.target;
        name = name.replace(/[^a-zA-Z]/g, "");
        invoicePositions[index][name] = value;

        this.setState({
            invoicePositions: invoicePositions,
        });
    }

    saveItem = () => {
        const { cursorWait, name, pkwiu, gtu, amount, unit, netPrice, vatRate } = this.state
        const { currentCompany, editingExisting, editedItem } = this.props
        var valid = true

        console.log(name, pkwiu, gtu, amount, unit, netPrice, vatRate)

        if (!name) {
            this.props.alertWarn(this.context.t('Name is required'))
            valid = false
        }

        if (valid && !cursorWait) {
            this.setState({
                cursorWait: true
            })
            if (editingExisting) {
                this.props.editInvoiceItem(currentCompany.company_id, editedItem.id, name, pkwiu, gtu, amount, unit, netPrice, vatRate, (newItem) => {
                    if (newItem) {
                        this.props.savedChanges(newItem)
                        this.props.close()
                    }
                    this.setState({
                        cursorWait: false
                    })
                })
            } else {
                this.props.saveNewInvoiceItem(currentCompany.company_id, name, pkwiu, gtu, amount, unit, netPrice, vatRate, (newItem) => {
                    if (newItem) {
                        this.props.createdNewItem(newItem)
                        this.props.close()
                    }
                    this.setState({
                        cursorWait: false
                    })
                })
            }
        }
    }

    getFullLanguageName = langCode => {
        if (langCode === 'pl') return 'Polish'
        else return 'English'
    }

    setLanguage = value => {
        this.setState({
            documentLanguage: value,
            showLanguagesList: false
        })
    }

    setNumberingSeries = numberingSeries => {
        this.setState({
            numberingSeriesId: numberingSeries.seriesId,
            numberingSeriesName: numberingSeries.seriesName,
            numberingSeriesNextNumber: numberingSeries.number,
            showNumberingSeriesList: false
        })
    }

    setIssueDate = date => {
        const { firstIssueDate, firstSaleDate, firstDueDate, recurringRule } = this.state
        var saleDaysDiff = moment(firstSaleDate).diff(moment(firstIssueDate), 'days')
        var dueDaysDiff = moment(firstDueDate).diff(moment(firstIssueDate), 'days')

        var newIssueDate = moment(date).format('YYYY-MM-DD')
        var newSaleDate = moment(newIssueDate).add(saleDaysDiff, 'day').format('YYYY-MM-DD')
        var newDueDate = moment(newIssueDate).add(dueDaysDiff, 'day').format('YYYY-MM-DD')
        this.setState({
            firstIssueDate: moment(date).format("YYYY-MM-DD"),
            firstSaleDate: newSaleDate,
            firstDueDate: newDueDate,
            showIssueDateCalendar: false
        })

        if (recurringRule === 'ONCE') {
            this.setState({
                repeatUntil: moment(date).format("YYYY-MM-DD"),
            })
        }
    }

    setSaleDate = date => {
        this.setState({
            firstSaleDate: moment(date).format("YYYY-MM-DD"),
            showSaleDateCalendar: false
        })
    }

    setDueDate = date => {
        this.setState({
            firstDueDate: moment(date).format("YYYY-MM-DD"),
            showDueDateCalendar: false
        })
    }

    getPatternName = pattern => {
        // ['ONCE', 'MONTHLY', 'LAST_DAY_OF_MONTH', 'LAST_WORKING_DAY_OF_MONTH', 'FIRST_DAY_OF_MONTH', 'FIRST_WORKING_DAY_OF_MONTH', 'FIRST_WORKING_DAY_OF_QUARTER', 'LAST_DAY_OF_QUARTER', 'LAST_DAY_OF_YEAR'],
        switch (pattern) {
            case 'ONCE':
                return this.context.t('Generate once')
            case 'MONTHLY':
                return this.context.t('Every month')
            case 'QUARTERLY':
                return this.context.t('Every quarter')
            case 'ANNUALLY':
                return this.context.t('Every year')
            case 'LAST_DAY_OF_MONTH':
                return this.context.t('Last day of each month')
            case 'LAST_WORKING_DAY_OF_MONTH':
                return this.context.t('Last working day of each month')
            case 'FIRST_DAY_OF_MONTH':
                return this.context.t('First day of each month')
            case 'FIRST_WORKING_DAY_OF_MONTH':
                return this.context.t('First working day of each month')
            case 'FIRST_WORKING_DAY_OF_QUARTER':
                return this.context.t('First working day of each quarter')
            case 'LAST_DAY_OF_QUARTER':
                return this.context.t('Last day of each quarter')
            case 'LAST_DAY_OF_YEAR':
                return this.context.t('Last day of each year')
        }
    }

    setRepeatPattern = value => {
        if (value === 'MONTHLY') {
            this.setState({
                recurringRule: value,
                showRepeatPatternsList: false
            })
        } else if (value === 'ONCE') {
            const { firstIssueDate } = this.state
            this.setState({
                recurringRule: value,
                showRepeatPatternsList: false,
                repeatUntil: moment(firstIssueDate).format("YYYY-MM-DD"),
            })
        } else {
            const { firstIssueDate, firstSaleDate, firstDueDate } = this.state
            var saleDaysDiff = moment(firstSaleDate).diff(moment(firstIssueDate), 'days')
            var dueDaysDiff = moment(firstDueDate).diff(moment(firstIssueDate), 'days')

            var newIssueDate = null
            if (value === 'LAST_DAY_OF_MONTH') {
                newIssueDate = moment(firstIssueDate).endOf('month').format('YYYY-MM-DD')
            } else if (value === 'LAST_WORKING_DAY_OF_MONTH') {
                newIssueDate = moment(firstIssueDate).endOf('month')
                var dayNumber = newIssueDate.isoWeekday()
                if (dayNumber > 5) {
                    newIssueDate.subtract(dayNumber - 5, 'days')
                }
                newIssueDate = newIssueDate.format('YYYY-MM-DD')
            } else if (value === 'FIRST_DAY_OF_MONTH') {
                newIssueDate = moment(firstIssueDate).add(1, 'month').startOf('month').format('YYYY-MM-DD')
            } else if (value === 'FIRST_WORKING_DAY_OF_MONTH') {
                newIssueDate = moment(firstIssueDate).add(1, 'month').startOf('month')
                var dayNumber = newIssueDate.isoWeekday()
                if (dayNumber > 5) {
                    newIssueDate.add(8 - dayNumber, 'days')
                }
                newIssueDate = newIssueDate.format('YYYY-MM-DD')
            } else if (value === 'FIRST_WORKING_DAY_OF_QUARTER') {
                newIssueDate = moment(firstIssueDate).endOf('quarter').add(1, 'day')
                var dayNumber = newIssueDate.isoWeekday()
                if (dayNumber > 5) {
                    newIssueDate.add(8 - dayNumber, 'days')
                }
                newIssueDate = newIssueDate.format('YYYY-MM-DD')
            } else if (value === 'LAST_DAY_OF_QUARTER') {
                newIssueDate = moment(firstIssueDate).endOf('quarter').format('YYYY-MM-DD')
            } else if (value === 'LAST_DAY_OF_YEAR') {
                newIssueDate = moment(firstIssueDate).endOf('year').format('YYYY-MM-DD')
            }


            var newSaleDate = moment(newIssueDate).add(saleDaysDiff, 'day').format('YYYY-MM-DD')
            var newDueDate = moment(newIssueDate).add(dueDaysDiff, 'day').format('YYYY-MM-DD')

            this.setState({
                recurringRule: value,
                showRepeatPatternsList: false,
                firstIssueDate: newIssueDate,
                firstSaleDate: newSaleDate,
                firstDueDate: newDueDate,
            })
        }
    }

    setRepeatUntil = date => {
        this.setState({
            repeatUntil: moment(date).format("YYYY-MM-DD"),
            showRepeatUntilCalendar: false
        })
    }

    setCurrency = value => {
        this.setState({
            currency: value,
            showCurrencyList: false
        })
    }

    setAccountNumber = bankInstance => {
        this.setState({
            accountNumberId: bankInstance.id,
            accountNumberName: bankInstance.bank_name,
            accountNumber: bankInstance.account_number,
            accountNumberSwift: bankInstance.bic,
            showAccountNumbersList: false,
        })
    }

    setCounterpaty = counterparty => {
        if (this.checkIfCounterpartyIsComplete(counterparty)) {
            var counterpartySplitAddress = splitAddress(counterparty.address);
            const { firstIssueDate, firstDueDate } = this.state
            var newDueDate = null
            if (counterparty.payment_due) {
                newDueDate = moment(firstIssueDate, 'YYYY-MM-DD').add(parseInt(counterparty.payment_due), 'day').format('YYYY-MM-DD')
            }
            this.setState({
                counterpartyId: counterparty.id,
                counterpartyName: counterparty.name,
                counterpartyCode: counterparty.code,
                counterpartyVatId: counterparty.vat_id,
                counterpartyAddress: counterpartySplitAddress.address,
                counterpartyZipCode: counterpartySplitAddress.zipCode,
                counterpartyCity: counterpartySplitAddress.city,
                showCounterpartiesList: false,
                firstDueDate: newDueDate || firstDueDate,
                email: counterparty.email ? counterparty.email.join(', ') : '',
            })
        } else {
            this.setState({
                showNewCounterpartyPopup: true,
                editingOldCounterparty: true,
                counterpartyToEdit: counterparty
            })
        }
    }

    checkIfCounterpartyIsComplete = counterparty => {
        console.log(counterparty)
        const { name, vat_id, address } = counterparty

        console.log(name, vat_id, address)
        if (!name) return false
        if (!vat_id) return false
        if (!address) return false

        return true
    }

    createNewCounterparty = () => {
        this.setState({
            showNewCounterpartyPopup: true,
            showCounterpartiesList: false,
        })
    }

    editExistingCounterparty = () => {
        const { counterpartyId, counterpartyName, counterpartyCode, counterpartyVatId, counterpartyAddress, counterpartyZipCode, counterpartyCity, email } = this.state
        this.setState({
            showNewCounterpartyPopup: true,
            editingOldCounterparty: true,
            counterpartyToEdit: {
                id: counterpartyId,
                vat_id: counterpartyVatId,
                name: counterpartyName,
                code: counterpartyCode,
                address: counterpartyZipCode ? `${counterpartyAddress}, ${counterpartyZipCode} ${counterpartyCity}` : counterpartyAddress,
                email: email && Array.isArray(email) ? email.join(', ') : ''
            }
        })
    }

    closeEditCounterpartyPopup = () => {
        this.setState({
            showNewCounterpartyPopup: false,
            editingOldCounterparty: false,
            counterpartyToEdit: {},
            showCounterpartiesList: false,
        })
    }

    savedCounterpartyChangesChanges = newCounterparty => {
        var counterpartySplitAddress = splitAddress(newCounterparty.address);
        const { firstIssueDate, firstDueDate } = this.state
        var newDueDate = null
        if (newCounterparty.payment_due) {
            newDueDate = moment(firstIssueDate, 'YYYY-MM-DD').add(parseInt(newCounterparty.payment_due), 'day').format('YYYY-MM-DD')
        }
        this.setState({
            counterpartyId: newCounterparty.id,
            counterpartyName: newCounterparty.name,
            counterpartyCode: newCounterparty.code,
            counterpartyVatId: newCounterparty.vat_id,
            counterpartyAddress: counterpartySplitAddress.address,
            counterpartyZipCode: counterpartySplitAddress.zipCode,
            counterpartyCity: counterpartySplitAddress.city,
            firstDueDate: newDueDate || firstDueDate,
            email: newCounterparty.email || '',

            showNewCounterpartyPopup: false,
            editingOldCounterparty: false,
            counterpartyToEdit: {},
            showCounterpartiesList: false,
        })
    }

    foundAtLeastOneSimilarCounterparty = (text) => {
        if (!text) return true;
        const { counterpartiesList } = this.state;
        var counterpartiesCount = counterpartiesList.length;

        for (var i = 0; i < counterpartiesCount; i++) {
            if (text.length === 1 && counterpartiesList[i].name && counterpartiesList[i].name.toLowerCase().startsWith(text.toLowerCase())) {
                return true;
            } else if (text.length > 1 && counterpartiesList[i].name && counterpartiesList[i].name.toLowerCase().includes(text.toLowerCase())) {
                return true;
            }
        }

        return false;
    };

    checkIfCounterpartyNameIsSimilar = (text, name) => {
        if (!text) {
            return true;
        } else if (text.length === 1 && name && name.toLowerCase().startsWith(text.toLowerCase())) {
            return true;
        } else if (text.length > 1 && name && name.toLowerCase().includes(text.toLowerCase())) {
            return true;
        }

        return false;
    };

    checkIfInvoiceItemIsSimilar = (text, name) => {
        if (!text) return true;

        if (text.length === 1 && name && name.toLowerCase().startsWith(text.toLowerCase())) return true;
        if (text.length > 1 && name && name.toLowerCase().includes(text.toLowerCase())) return true;

        return false;
    };

    foundAtLeastOneSimilarInvoiceItem = (text) => {
        if (!text) return true;
        const { savedDocItems } = this.state;
        var invoiceItemCount = savedDocItems.length;
        for (var i = 0; i < invoiceItemCount; i++) {
            if (text.length === 1 && savedDocItems[i].name && savedDocItems[i].name.toLowerCase().startsWith(text.toLowerCase())) return true;
            if (text.length > 1 && savedDocItems[i].name && savedDocItems[i].name.toLowerCase().includes(text.toLowerCase())) return true;
        }

        return false;
    };

    setItem = (item, index) => {
        var { invoicePositions } = this.state;
        if (this.checkIfDocItemIsComplete({ ...item })) {
            invoicePositions[index] = { ...item };

            this.setState({
                itemsListIndex: -1,
                showItemsList: false,
                invoicePositions: invoicePositions,
            });
        } else {
            this.setState({
                showNewDocItemPopup: true,
                editingOldDocItem: true,
                editedDocItemIndex: index,
                docItemToEdit: { ...item }
            })
        }
    }

    checkIfDocItemIsComplete = item => {
        const { name, amount, unit, vatRate, netPrice } = item

        console.log(name, amount, unit, vatRate, netPrice)
        if (!name) return false
        if (!amount) return false
        if (!unit) return false
        if (!vatRate) return false
        if (!netPrice || !parseFloat(netPrice)) return false

        return true
    }

    createNewDocItem = index => {
        this.setState({
            showNewDocItemPopup: true,
            editedDocItemIndex: index,
            itemsListIndex: -1,
            showItemsList: false,
        })
    }

    editExistingDocItem = (item, index) => {
        this.setState({
            showNewDocItemPopup: true,
            editingOldDocItem: true,
            editedDocItemIndex: index,
            docItemToEdit: { ...item }
        })
    }

    closeEditItemPopup = () => {
        this.setState({
            showNewDocItemPopup: false,
            editingOldDocItem: false,
            editedDocItemIndex: -1,
            docItemToEdit: {},
            itemsListIndex: -1,
            showItemsList: false,
        })
    }

    savedDocItemChangesChanges = newItem => {
        var { invoicePositions, editedDocItemIndex } = this.state;
        invoicePositions[editedDocItemIndex] = { ...newItem };

        this.setState({
            invoicePositions: invoicePositions,
            showNewDocItemPopup: false,
            editingOldDocItem: false,
            editedDocItemIndex: -1,
            docItemToEdit: {},
        });
    }

    deleteInvoicePossition = (index) => {
        var { invoicePositions } = this.state;
        invoicePositions.splice(index, 1);

        this.setState({
            invoicePositions: invoicePositions,
        });
    }

    addItem = () => {
        var { invoicePositions } = this.state;
        invoicePositions.push({
            id: -1,
            name: "",
            amount: 1,
            unit: "szt.",
            netPrice: "",
            vat: "23",
            netValue: "",
            grossValue: "",
        });

        this.setState({
            invoicePositions: invoicePositions,
        });
    }

    generateSubsequentInvoiceDates = interval => {
        const { firstIssueDate, firstSaleDate, firstDueDate, recurringRule } = this.state
        var saleDaysDiff = moment(firstSaleDate).diff(moment(firstIssueDate), 'days')
        var dueDaysDiff = moment(firstDueDate).diff(moment(firstIssueDate), 'days')

        console.log()
        var newIssueDate = null
        if (recurringRule === 'MONTHLY') {
            newIssueDate = moment(firstIssueDate).add(interval, 'month').format('YYYY-MM-DD')
        } else if (recurringRule === 'LAST_DAY_OF_MONTH') {
            newIssueDate = moment(firstIssueDate).add(interval, 'month').endOf('month').format('YYYY-MM-DD')
        } else if (recurringRule === 'LAST_WORKING_DAY_OF_MONTH') {
            newIssueDate = moment(firstIssueDate).add(interval, 'month').endOf('month')
            var dayNumber = newIssueDate.isoWeekday()
            if (dayNumber > 5) {
                newIssueDate.subtract(dayNumber - 5, 'days')
            }
            newIssueDate = newIssueDate.format('YYYY-MM-DD')
        } else if (recurringRule === 'FIRST_DAY_OF_MONTH') {
            newIssueDate = moment(firstIssueDate).add(interval, 'month').startOf('month').format('YYYY-MM-DD')
        } else if (recurringRule === 'FIRST_WORKING_DAY_OF_MONTH') {
            newIssueDate = moment(firstIssueDate).add(interval, 'month').startOf('month')
            var dayNumber = newIssueDate.isoWeekday()
            if (dayNumber > 5) {
                newIssueDate.add(8 - dayNumber, 'days')
            }
            newIssueDate = newIssueDate.format('YYYY-MM-DD')
        } else if (recurringRule === 'FIRST_WORKING_DAY_OF_QUARTER') {
            newIssueDate = moment(firstIssueDate).add(interval - 1, 'quarter').endOf('quarter').add(1, 'day')
            var dayNumber = newIssueDate.isoWeekday()
            if (dayNumber > 5) {
                newIssueDate.add(8 - dayNumber, 'days')
            }
            newIssueDate = newIssueDate.format('YYYY-MM-DD')
        } else if (recurringRule === 'LAST_DAY_OF_QUARTER') {
            newIssueDate = moment(firstIssueDate).add(interval, 'quarter').endOf('quarter').format('YYYY-MM-DD')
        } else if (recurringRule === 'LAST_DAY_OF_YEAR') {
            newIssueDate = moment(firstIssueDate).add(interval, 'year').endOf('year').format('YYYY-MM-DD')
        }
        var newSaleDate = moment(newIssueDate).add(saleDaysDiff, 'day').format('YYYY-MM-DD')
        var newDueDate = moment(newIssueDate).add(dueDaysDiff, 'day').format('YYYY-MM-DD')

        return (
            <div className="next-invoice">
                <div className="next-date">
                    {newIssueDate}
                </div>
                <div className="next-date">
                    {newSaleDate}
                </div>
                <div className="next-date">
                    {newDueDate}
                </div>
            </div>
        )

    }

    saveRecurringInvoice = () => {
        const {
            cursorWait,
            numberingSeriesId,
            documentLanguage, currency,
            accountNumberId,
            firstIssueDate, firstSaleDate, firstDueDate, recurringRule, repeatUntil,
            counterpartyId,
            invoicePositions,
            comments, splitPayment, reverseCharge, metodaKasowa,
            sendAfterGerating, email,
        } = this.state
        const { currentCompany, editingExisting, editedRecurringInvoice } = this.props
        if (!cursorWait && this.validateForm()) {
            var newRecurringInvoice = {
                numberingSeriesId, documentLanguage, accountNumberId, currency,
                firstIssueDate, firstSaleDate, firstDueDate, recurringRule, repeatUntil,
                counterpartyId,
                invoicePositions,
                comments, splitPayment, reverseCharge, metodaKasowa,
                sendAfterGerating, email,
            }

            if (editingExisting) {
                this.props.saveChangesToRecurringInvoice(currentCompany.company_id, editedRecurringInvoice.id, newRecurringInvoice, (success, examplePdf) => {
                    if (success) {
                        this.props.savedChanges()
                    }
                })
            } else {
                this.props.createRecurringInvoice(currentCompany.company_id, newRecurringInvoice, (success, examplePdf, newRecurringInvoiceId) => {
                    if (success) {
                        if (examplePdf && examplePdf.data) {
                            this.setState({
                                showExamplePdf: true,
                                examplePdf: examplePdf.data,
                                newRecurringInvoiceId: newRecurringInvoiceId,
                            })
                        } else this.props.savedChanges()
                    }
                })
            }
        }
    }

    generateExamplePdf = () => {
        const { cursorWait } = this.state
        const { currentCompany, editingExisting, editedRecurringInvoice } = this.props
        if (!cursorWait && this.validateForm()) {
            const {
                documentLanguage, currency,
                firstIssueDate, firstSaleDate, firstDueDate,
                invoicePositions,
                comments, splitPayment, reverseCharge, metodaKasowa,
                numberingSeriesId, numberingSeriesNextNumber,
                accountNumberName, accountNumber, accountNumberSwift,
                sellerName, sellerVatId, sellerAddress, sellerZipCode, sellerCity, sellerLogo,
                counterpartyName, counterpartyCode, counterpartyVatId, counterpartyAddress, counterpartyZipCode, counterpartyCity
            } = this.state
            var recurringInvoice = {
                documentLanguage, currency,
                numberingSeriesId: numberingSeriesId,
                nextInvoiceNumber: numberingSeriesNextNumber,
                account: {
                    name: accountNumberName, number: accountNumber, swift: accountNumberSwift
                },
                firstIssueDate, firstSaleDate, firstDueDate,
                seller: {
                    name: sellerName, vatId: sellerVatId, address: sellerAddress, zipCode: sellerZipCode, city: sellerCity, logo: sellerLogo
                },
                buyer: {
                    name: counterpartyName, code: counterpartyCode, vatId: counterpartyVatId, address: counterpartyAddress, zipCode: counterpartyZipCode, city: counterpartyCity
                },
                invoicePositions,
                comments, splitPayment, reverseCharge, metodaKasowa
            }

            this.props.generateExamplePdf(currentCompany.company_id, recurringInvoice, (success, examplePdf) => {
                if (success) {
                    if (examplePdf && examplePdf.data) {
                        this.setState({
                            showExamplePdf: true,
                            examplePdf: examplePdf.data
                        })
                    }
                }
            })
        }
    }

    validateForm = () => {
        const {
            numberingSeriesId,
            accountNumberId,
            firstIssueDate, firstDueDate, repeatUntil,
            counterpartyId,
            invoicePositions,
            sendAfterGerating, email,
        } = this.state

        var valid = true
        if (numberingSeriesId === -1) {
            valid = false
            this.props.alertWarn(this.context.t("Numbering series is required"));
        } else if (accountNumberId === -1) {
            valid = false
            this.props.alertWarn(this.context.t("Account number is required"));
        } else if (counterpartyId === -1) {
            valid = false
            this.props.alertWarn(this.context.t("Counterparty is required"));
        } else if (moment(firstIssueDate).startOf('day').diff(moment().startOf('day'), 'days') <= 0) {
            valid = false
            this.props.alertWarn(this.context.t("First issue date must be in the future"));
        } else if (moment(firstIssueDate).diff(moment(firstDueDate), 'days') > 0) {
            valid = false
            this.props.alertWarn(this.context.t("The due date cannot be before the issue date"));
        } else if (repeatUntil && moment(firstIssueDate).diff(moment(repeatUntil), 'days') > 0) {
            valid = false
            this.props.alertWarn(this.context.t("The invoice generation end date is incorrect"));
        }

        if (sendAfterGerating && !email) {
            valid = false
            this.props.alertWarn(this.context.t("Email address is required"));
        } else if (sendAfterGerating && email) {
            var emailsArray = email.split(",")
            emailsArray.map(emailAddress => {
                if (emailAddress && !emailRegex.test(emailAddress.trim())) {
                    valid = false
                    this.props.alertWarn(this.context.t('Email address is not valid'))
                }
            })
        }

        invoicePositions.map(docItem => {
            if (docItem.id === -1) {
                valid = false
                this.props.alertWarn(this.context.t('Invoice items are not valid'))
            }
        })

        return valid
    }

    acceptRecurringInvoice = () => {
        this.setState({
            showExamplePdf: false,
            examplePdf: null,
        })
        this.saveRecurringInvoice()
    }

    returnToInvoiceEditing = () => {
        this.setState({
            showExamplePdf: false,
            examplePdf: null,
        })
    }

    render() {
        const { cursorWait,
            showLanguagesList, showNumberingSeriesList, showIssueDateCalendar, showSaleDateCalendar, showDueDateCalendar, showRepeatPatternsList, showRepeatUntilCalendar, showCounterpartiesList, showCurrencyList, showAccountNumbersList,
            numberingSeriesId, numberingSeriesName, numberingSeriesList,
            documentLanguage, currency,
            accountNumberId, accountNumberName, accountNumber, accountNumbersList,
            firstIssueDate, firstSaleDate, firstDueDate, recurringRule, repeatPatterns, repeatUntil,
            sellerName, sellerVatId, sellerAddress, sellerZipCode, sellerCity,
            counterpartiesList, counterpartyId, counterpartyName, counterpartyVatId, counterpartyAddress, counterpartyZipCode, counterpartyCity,
            showItemsList, savedDocItems, itemsListIndex, invoicePositions,
            comments, splitPayment, reverseCharge, metodaKasowa,
            sendAfterGerating, email,

            showNewDocItemPopup, editingOldDocItem, docItemToEdit,
            showNewCounterpartyPopup, editingOldCounterparty, counterpartyToEdit,

            showExamplePdf, examplePdf, editingAfterExamplePreview
        } = this.state
        const { editingExisting, currentCompany } = this.props
        return (
            <div className={`new-recurring-invoice-popup ${cursorWait ? 'cursor-wait' : ''} ${showNewDocItemPopup || showNewCounterpartyPopup || showExamplePdf ? 'has-overlay' : ''}`}>

                {
                    showNewDocItemPopup ? (
                        <NewDocItemPopup
                            editingExisting={editingOldDocItem}
                            editedItem={docItemToEdit}
                            savedChanges={this.savedDocItemChangesChanges}
                            createdNewItem={this.savedDocItemChangesChanges}
                            close={this.closeEditItemPopup}
                            validateAllFieldsForRecurringInvoice={true}
                        />
                    ) : null
                }

                {
                    showNewCounterpartyPopup ? (
                        <NewCounterpartyPopup
                            editingExisting={editingOldCounterparty}
                            editedCounterparty={counterpartyToEdit}
                            savedChanges={this.savedCounterpartyChangesChanges}
                            createdNewItem={this.savedCounterpartyChangesChanges}
                            close={this.closeEditCounterpartyPopup}
                        />
                    ) : null
                }

                {
                    showExamplePdf ? (
                        <ExampleRecurrinInvoicePdf
                            examplePdf={examplePdf}
                            acceptRecurringInvoice={this.acceptRecurringInvoice}
                            returnToInvoiceEditing={this.returnToInvoiceEditing}
                        />
                    ) : null
                }

                <div className="new-recurring-invoice-window">
                    <h3>
                        {
                            editingExisting ? (
                                this.context.t("Update recurring invoice")
                            ) : (
                                this.context.t("Create new recurring invoice")
                            )
                        }
                    </h3>
                    <p>{this.context.t('Data such as numbering series, account number, counterparty and invoice items must be fully defined before creating recurring sales invoices.')}</p>
                    <div className="new-invoice">
                        <div className="general-info">
                            <h5>{this.context.t('General informations')}</h5>
                            <div className="form-group" onClick={() => this.setState({ showNumberingSeriesList: !showNumberingSeriesList })} ref={this.numberingSeriesContainerRef}>
                                <input type="text" id="numberingSeriesName" autocomplete="off" name="numberingSeriesName" value={numberingSeriesName} readOnly={true} />
                                <label for="numberingSeriesName">{this.context.t("Numbering series")}</label>

                                {
                                    numberingSeriesList.length > 0 ? (
                                        <>
                                            <span className={`expend-icon ${showNumberingSeriesList ? "expended" : ""}`}></span>
                                            {
                                                showNumberingSeriesList ? (
                                                    <div className="sugestions-list down">
                                                        <ul>
                                                            {
                                                                numberingSeriesList.map(series => {
                                                                    return (
                                                                        <li onClick={() => this.setNumberingSeries(series)}>
                                                                            {
                                                                                series.seriesName ? (
                                                                                    <span className="series-name">{series.seriesName}</span>
                                                                                ) : null
                                                                            }
                                                                            {series.number}
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                ) : null
                                            }
                                        </>
                                    ) : null
                                }
                            </div>

                            <div className="form-group" onClick={() => this.setState({ showLanguagesList: !showLanguagesList })} ref={this.languageContainerRef}>
                                <input type="text" id="documentLanguage" autocomplete="off" name="documentLanguage" value={this.context.t(this.getFullLanguageName(documentLanguage))} readOnly={true} />
                                <label for="documentLanguage">{this.context.t("Document language")}</label>
                                <span className={`expend-icon ${showLanguagesList ? "expended" : ""}`}></span>

                                {
                                    showLanguagesList ? (
                                        <div className="sugestions-list">
                                            <ul>
                                                <li onClick={() => this.setLanguage("pl")}>
                                                    {this.context.t("Polish")}
                                                </li>
                                                <li onClick={() => this.setLanguage("en")}>
                                                    {this.context.t("English")}
                                                </li>
                                            </ul>
                                        </div>
                                    ) : null
                                }
                            </div>

                            <div className="form-group" onClick={() => this.setState({ showAccountNumbersList: !showAccountNumbersList })} ref={this.accountNumberContainerRef}>
                                <input type="text" id="accountNumber" autocomplete="off" name="accountNumber" value={accountNumber} readOnly={true} />
                                <label for="accountNumber">{this.context.t("Account number")}</label>
                                <span className={`expend-icon ${showAccountNumbersList ? "expended" : ""}`}></span>

                                {
                                    showAccountNumbersList ? (
                                        <div className="sugestions-list">
                                            <ul>
                                                {
                                                    accountNumbersList.map((a) => {
                                                        return (
                                                            <li onClick={() => this.setAccountNumber(a)}>
                                                                <span className="account-name">{`${a.name} (${a.currency})`}</span>
                                                                {a.account_number}
                                                            </li>
                                                        );
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    ) : null
                                }
                            </div>

                            <div className="form-group" onClick={() => this.setState({ showCurrencyList: !showCurrencyList })} ref={this.currencyContainerRef}>
                                <input type="text" id="currency" autocomplete="off" name="currency" value={currency} readOnly={true} />
                                <label for="currency">{this.context.t("Currency")}</label>
                                <span className={`expend-icon ${showCurrencyList ? "expended" : ""}`}></span>

                                {
                                    showCurrencyList ? (
                                        <div className="sugestions-list">
                                            <ul>
                                                {
                                                    currencyList.map(c => {
                                                        return (
                                                            <li onClick={e => { this.setCurrency(c) }}>
                                                                {c}
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    ) : null
                                }
                            </div>
                        </div>

                        <div className="date-info">
                            <h5>{this.context.t('Dates for the next invoice')}</h5>

                            <div className="form-group" onClick={() => this.setState({ showRepeatPatternsList: !showRepeatPatternsList })} ref={this.repeatPatternContainerRef}>
                                <input type="text" id="recurringRule" autocomplete="off" name="recurringRule" value={this.getPatternName(recurringRule)} readOnly={true} />
                                <label for="recurringRule">{this.context.t("Repeat pattern")}</label>
                                <span className={`expend-icon ${showRepeatPatternsList ? "expended" : ""}`}></span>

                                {
                                    showRepeatPatternsList ? (
                                        <div className="sugestions-list">
                                            <ul>
                                                {
                                                    repeatPatterns.map(pattern => {
                                                        return (
                                                            <li onClick={e => { this.setRepeatPattern(pattern) }}>
                                                                {this.getPatternName(pattern)}
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    ) : null
                                }
                            </div>

                            <div className="form-group" ref={this.issueDateContainerRef}>
                                <input type="text" id="firstIssueDate" autocomplete="off" name="firstIssueDate" value={firstIssueDate} readOnly={true} onClick={() => this.setState({ showIssueDateCalendar: !showIssueDateCalendar })} />
                                <label for="firstIssueDate" onClick={() => this.setState({ showIssueDateCalendar: !showIssueDateCalendar })}>{this.context.t("Issue date")}</label>
                                <span className={`calendar-icon ${showIssueDateCalendar ? "expended" : ""}`} onClick={() => this.setState({ showIssueDateCalendar: !showIssueDateCalendar })}></span>
                                {
                                    showIssueDateCalendar ? (
                                        <div className="date-picker">
                                            <Calendar
                                                selectDay={this.setIssueDate}
                                                selectedDay={{
                                                    value: moment(firstIssueDate)
                                                }}
                                                projectId={"none"}
                                                startDate={moment(firstIssueDate) || moment()}
                                                arrowsOnLeft={false}
                                            />
                                        </div>
                                    ) : null
                                }
                            </div>

                            <div className="form-group" ref={this.saleDateContainerRef}>
                                <input type="text" id="firstSaleDate" autocomplete="off" name="firstSaleDate" value={firstSaleDate} readOnly={true} onClick={() => this.setState({ showSaleDateCalendar: !showSaleDateCalendar })} />
                                <label for="firstSaleDate" onClick={() => this.setState({ showSaleDateCalendar: !showSaleDateCalendar })}>{this.context.t("Sale date")}</label>
                                <span className={`calendar-icon ${showSaleDateCalendar ? "expended" : ""}`} onClick={() => this.setState({ showSaleDateCalendar: !showSaleDateCalendar })}></span>
                                {
                                    showSaleDateCalendar ? (
                                        <div className="date-picker">
                                            <Calendar
                                                selectDay={this.setSaleDate}
                                                selectedDay={{
                                                    value: moment(firstSaleDate)
                                                }}
                                                projectId={"none"}
                                                startDate={moment(firstSaleDate) || moment()}
                                                arrowsOnLeft={false}
                                            />
                                        </div>
                                    ) : null
                                }
                            </div>

                            <div className="form-group" ref={this.dueDateContainerRef}>
                                <input type="text" id="firstDueDate" autocomplete="off" name="firstDueDate" value={firstDueDate} readOnly={true} onClick={() => this.setState({ showDueDateCalendar: !showDueDateCalendar })} />
                                <label for="firstDueDate" onClick={() => this.setState({ showDueDateCalendar: !showDueDateCalendar })}>{this.context.t("Due date")}</label>
                                <span className={`calendar-icon ${showDueDateCalendar ? "expended" : ""}`} onClick={() => this.setState({ showDueDateCalendar: !showDueDateCalendar })}></span>
                                {
                                    showDueDateCalendar ? (
                                        <div className="date-picker">
                                            <Calendar
                                                selectDay={this.setDueDate}
                                                selectedDay={{
                                                    value: moment(firstDueDate)
                                                }}
                                                projectId={"none"}
                                                startDate={moment(firstDueDate) || moment()}
                                                arrowsOnLeft={false}
                                            />
                                        </div>
                                    ) : null
                                }
                            </div>

                            <div className="form-group" ref={this.repeatUntilContainerRef}>
                                <input type="text" id="repeatUntil" autocomplete="off" name="repeatUntil" value={repeatUntil} readOnly={true} onClick={() => this.setState({ showRepeatUntilCalendar: !showRepeatUntilCalendar })} />
                                <label for="repeatUntil" onClick={() => this.setState({ showRepeatUntilCalendar: !showRepeatUntilCalendar })}>{this.context.t("Invoice generation end date")}</label>
                                <span className={`calendar-icon ${showRepeatUntilCalendar ? "expended" : ""}`} onClick={() => this.setState({ showRepeatUntilCalendar: !showRepeatUntilCalendar })}></span>
                                {
                                    showRepeatUntilCalendar ? (
                                        <div className="date-picker">
                                            <Calendar
                                                selectDay={this.setRepeatUntil}
                                                selectedDay={repeatUntil ? {
                                                    value: moment(repeatUntil)
                                                } : {}}
                                                projectId={"none"}
                                                startDate={repeatUntil ? moment(repeatUntil) : moment()}
                                                arrowsOnLeft={false}
                                            />
                                        </div>
                                    ) : null
                                }
                            </div>

                            {
                                recurringRule !== 'ONCE' ? (
                                    <>
                                        <h6>{this.context.t('Dates for three subsequent invoices according to the selected repetition rule')}:</h6>
                                        <div className="subsequent-invoices">
                                            <div className="next-invoice">
                                                <div className="next-date label">
                                                    {this.context.t('Issue date')}
                                                </div>
                                                <div className="next-date label">
                                                    {this.context.t('Sale date')}
                                                </div>
                                                <div className="next-date label">
                                                    {this.context.t('Due date')}
                                                </div>
                                            </div>
                                            {this.generateSubsequentInvoiceDates(1)}
                                            {this.generateSubsequentInvoiceDates(2)}
                                            {this.generateSubsequentInvoiceDates(3)}
                                        </div>
                                    </>
                                ) : null
                            }

                        </div>

                        <div className="seller-and-buyer-row">
                            <div className="company-column">
                                <h5>{this.context.t('Seller')}</h5>
                                <div className="form-group read-only">
                                    <input name="sellerName" id={`sellerName`} value={sellerName} readOnly={true} />
                                    <label htmlFor="sellerName">{this.context.t("Name")}</label>
                                </div>
                                <div className="form-group read-only">
                                    <input name="sellerVatId" id={`sellerVatId`} value={sellerVatId} readOnly={true} />
                                    <label htmlFor="sellerVatId">{this.context.t("VatID")}</label>
                                </div>
                                <div className="form-group read-only">
                                    <input name="sellerAddress" id={`sellerAddress`} value={sellerAddress} readOnly={true} />
                                    <label htmlFor="sellerAddress">{this.context.t("Address")}</label>
                                </div>
                                <div className="form-group zip-code read-only">
                                    <input name="sellerZipCode" id={`sellerZipCode`} value={sellerZipCode} readOnly={true} />
                                    <label htmlFor="sellerZipCode">{this.context.t("ZIP code")}</label>
                                </div>
                                <div className="form-group city read-only">
                                    <input name="sellerCity" id={`sellerCity`} value={sellerCity} readOnly={true} />
                                    <label htmlFor="sellerCity">{this.context.t("City ")}</label>
                                </div>
                            </div>

                            <div className="company-column">
                                <h5>{this.context.t('Buyer')}</h5>
                                <div className="form-group" ref={this.counterpartyContainerRef}>
                                    <input onFocus={(e) => e.target.setAttribute("autoComplete", "none")} name="counterpartyName" id={`counterpartyName`} value={counterpartyName} onChange={this.onInputChange} onClick={() => this.setState({ showCounterpartiesList: !showCounterpartiesList })} />
                                    <label htmlFor="counterpartyName" onClick={() => this.setState({ showCounterpartiesList: !showCounterpartiesList })}>{this.context.t("Name")}</label>

                                    {
                                        counterpartiesList.length > 0 ? (
                                            <>
                                                <span className={`expend-icon ${showCounterpartiesList ? "expended" : ""}`} onClick={() => this.setState({ showCounterpartiesList: !showCounterpartiesList })}></span>
                                                {
                                                    showCounterpartiesList && this.foundAtLeastOneSimilarCounterparty(counterpartyName) ? (
                                                        <div className="sugestions-list down">
                                                            <SimpleBar style={{ height: "auto", maxHeight: "300px", width: "100%" }}>
                                                                <ul>
                                                                    <li className="add-new" onClick={() => this.createNewCounterparty()}>
                                                                        {this.context.t('Create new counterparty')}
                                                                    </li>
                                                                    {
                                                                        counterpartiesList.map(counterparty => {
                                                                            if (this.checkIfCounterpartyNameIsSimilar(counterpartyName, counterparty.name)) {
                                                                                return (
                                                                                    <li onClick={() => this.setCounterpaty(counterparty)}>
                                                                                        {counterparty.name}
                                                                                    </li>
                                                                                )
                                                                            } else return null
                                                                        })
                                                                    }
                                                                </ul>
                                                            </SimpleBar>
                                                        </div>
                                                    ) : null
                                                }
                                            </>
                                        ) : null
                                    }

                                    {
                                        counterpartyId > -1 ? (
                                            <div className="edit-counterparty" onClick={() => this.editExistingCounterparty()}></div>
                                        ) : null
                                    }
                                </div>
                                <div className="form-group read-only">
                                    <input name="counterpartyVatId" id={`counterpartyVatId`} value={counterpartyVatId} readyOnly={true} />
                                    <label htmlFor="counterpartyVatId">{this.context.t("VatID")}</label>
                                </div>
                                <div className="form-group read-only">
                                    <input name="counterpartyAddress" id={`counterpartyAddress`} value={counterpartyAddress} readyOnly={true} />
                                    <label htmlFor="counterpartyAddress">{this.context.t("Address")}</label>
                                </div>
                                <div className="form-group zip-code read-only">
                                    <input name="counterpartyZipCode" id={`counterpartyZipCode`} value={counterpartyZipCode} readyOnly={true} />
                                    <label htmlFor="counterpartyZipCode">{this.context.t("ZIP code")}</label>
                                </div>
                                <div className="form-group city read-only">
                                    <input name="counterpartyCity" id={`counterpartyCity`} value={counterpartyCity} readyOnly={true} />
                                    <label htmlFor="counterpartyCity">{this.context.t("City ")}</label>
                                </div>
                            </div>
                        </div>

                        <div className="invoice-possitions">
                            <h5>{this.context.t("Invoice items")}</h5>

                            {
                                invoicePositions.map((p, i) => {
                                    return (
                                        <div className="possition" key={`invoice-possition-${i}`}>
                                            <div className="form-group name" id={`item-name-${i}`}>
                                                <input onFocus={(e) => e.target.setAttribute("autoComplete", "none")} type="text" id="name" name="name" value={p.name} onChange={(e) => this.onInputChangeInvoicePossition(e, i)} onClick={() => this.setState({ itemsListIndex: i, showItemsList: true })} />
                                                <label for="name" onClick={() => this.setState({ itemsListIndex: i, showItemsList: true })}>{this.context.t("Name")}</label>
                                                {
                                                    savedDocItems.length > 0 ? (
                                                        <span className={`expend-icon ${itemsListIndex === i && showItemsList ? "expended" : ""}`} onClick={() => this.setState({ itemsListIndex: i, showItemsList: !showItemsList })}></span>
                                                    ) : null
                                                }

                                                {
                                                    // && this.foundAtLeastOneSimilarInvoiceItem(p.name)
                                                    savedDocItems.length > 0 && showItemsList && itemsListIndex === i ? (
                                                        <div className="sugestions-list doc-items">
                                                            <ul>
                                                                <SimpleBar style={{ maxHeight: "20vh", width: "100%" }} >
                                                                    <li className="doc-item add-new" onClick={() => this.createNewDocItem(i)}>
                                                                        {this.context.t('Create new product/service')}
                                                                    </li>
                                                                    {
                                                                        savedDocItems.map((item) => {
                                                                            if (this.checkIfInvoiceItemIsSimilar(p.name, item.name))
                                                                                return (
                                                                                    <li className="doc-item">
                                                                                        <div className="name" onClick={() => this.setItem({ ...item }, i)}>{item.name}</div>
                                                                                    </li>
                                                                                );
                                                                        })
                                                                    }
                                                                </SimpleBar>
                                                            </ul>
                                                        </div>
                                                    ) : (
                                                        null
                                                    )
                                                }
                                            </div>
                                            <div className="form-group amount read-only">
                                                <input name={`amount-${i}`} id={`amount-${i}`} value={p.amount} readOnly={true} />
                                                <label htmlFor="amount">{this.context.t("Amount ")}</label>
                                            </div>
                                            <div className="form-group unit read-only">
                                                <input name={`unit-${i}`} id={`unit-${i}`} value={p.unit} readOnly={true} />
                                                <label htmlFor="unit">{this.context.t("Unit")}</label>
                                            </div>
                                            <div className="form-group price read-only">
                                                <input name={`netPrice-${i}`} id={`netPrice-${i}`} value={p.netPrice} readOnly={true} />
                                                <label htmlFor="netPrice">{this.context.t("Net price")}</label>
                                            </div>
                                            <div className="form-group vat read-only">
                                                <input name={`vat-${i}`} id={`vat-${i}`} value={p.vat} readOnly={true} />
                                                <label htmlFor="vat">{this.context.t("VAT rate")}</label>
                                            </div>
                                            <div className="form-group price read-only">
                                                <input name={`netValue-${i}`} id={`netValue-${i}`} value={p.netValue} readOnly={true} />
                                                <label htmlFor="netValue">{this.context.t("Net value ")}</label>
                                            </div>
                                            <div className="form-group price read-only">
                                                <input name={`grossValue-${i}`} id={`grossValue-${i}`} value={p.grossValue} readOnly={true} />
                                                <label htmlFor="grossValue">{this.context.t("Gross value ")}</label>
                                            </div>

                                            {
                                                p.id > -1 ? (
                                                    <div className="edit-possition" onClick={() => this.editExistingDocItem(p, i)} ></div>
                                                ) : (
                                                    invoicePositions.length > 1 ? (
                                                        <div className="edit-possition filler"></div>
                                                    ) : null
                                                )
                                            }

                                            {
                                                invoicePositions.length > 1 ? (
                                                    <div className="delete-possition" onClick={() => this.deleteInvoicePossition(i)} ></div>
                                                ) : null
                                            }
                                        </div>
                                    );
                                })
                            }
                            <div className="add-possition" onClick={() => this.addItem()}>
                                {this.context.t("Add item")}
                            </div>
                        </div>

                        <div className="additional-info">
                            <h5>{this.context.t("Additional options")}</h5>
                            <div className="form-group">
                                <input type="text" id="comments" autocomplete="off" name="comments" value={comments} onChange={this.onInputChange} />
                                <label for="comments">{this.context.t("Comments")}</label>
                            </div>

                            <div className="checkbox-group">
                                <div className={`checkbox ${splitPayment ? "checked" : ""}`} onClick={() => this.setState({ splitPayment: !splitPayment })} ></div>
                                <div className="label" onClick={() => this.setState({ splitPayment: !splitPayment })} >{`${this.context.t("Split payment")} (split payment)`}</div>
                            </div>
                            <div className="checkbox-group">
                                <div className={`checkbox ${reverseCharge ? "checked" : ""}`} onClick={() => this.setState({ reverseCharge: !reverseCharge })} ></div>
                                <div className="label" onClick={() => this.setState({ reverseCharge: !reverseCharge })} >{`${this.context.t("Reverse charge")} (reverse charge)`}</div>
                            </div>
                            <div className="checkbox-group">
                                <div className={`checkbox ${metodaKasowa ? "checked" : ""}`} onClick={() => this.setState({ metodaKasowa: !metodaKasowa })} ></div>
                                <div className="label" onClick={() => this.setState({ metodaKasowa: !metodaKasowa })} >{`${this.context.t("Metoda kasowa")}`}</div>
                            </div>
                        </div>

                        <div className="email-info">
                            <h5>{this.context.t("Sending options")}</h5>
                            <div className="checkbox-group">
                                <div className={`checkbox ${sendAfterGerating ? "checked" : ""}`} onClick={() => this.setState({ sendAfterGerating: !sendAfterGerating })} ></div>
                                <div className="label" onClick={() => this.setState({ sendAfterGerating: !sendAfterGerating })} >{this.context.t("Always send the document after generating")}</div>
                            </div>
                            <div className={`form-group ${!sendAfterGerating ? 'read-only' : ''}`}>
                                <input type="text" id="email" autocomplete="off" name="email" value={email} readOnly={!sendAfterGerating} onChange={this.onInputChange} />
                                <label for="email">{this.context.t("Email")}</label>
                            </div>
                        </div>
                    </div>

                    <div className="action-buttons">
                        <div className="cancel-button" onClick={() => this.props.close()}>{this.context.t("Cancel")}</div>
                        <div className="save-button" onClick={() => this.generateExamplePdf()}>
                            {this.context.t("Invoice preview")}
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

NewRecurringInvoice.contextTypes = {
    t: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
    currentCompany: state.Company.currentCompany,
});

const mapDispatchToProps = {
    alertWarn: AlertActions.warning,
    getDocumentNumberForNewInvoice: CompanyActions.getDocumentNumberForNewInvoice,
    getSavedDocItems: CompanyActions.getSavedDocItems,
    generateExamplePdf: CompanyActions.generateExamplePdf,
    createRecurringInvoice: CompanyActions.createRecurringInvoice,
    saveChangesToRecurringInvoice: CompanyActions.saveChangesToRecurringInvoice
};

export default connect(mapStateToProps, mapDispatchToProps)(NewRecurringInvoice);
