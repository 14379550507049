import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { AlertActions } from '../actions/AlertActions'
import { CompanyActions } from '../actions/CompanyActions'
import routes from '../helpers/routes'
import arrayObjectIndexOf from '../helpers/indexOfObject.js'
import isIterable from "../helpers/isIterable"
import { createHashHistory } from 'history'
import bankIdentification from '../helpers/bankIdentification'

export class CompanyBankInformationPopup extends Component {

    constructor(props) {
        super(props)
        this.state = {
            bankName: '',
            accountNumber: '',
            bic: '',
            currency: 'PLN',
            choosenBankIsAvailable: 'unknown'
        }
    }

    componentDidMount() {
        window.addEventListener("keydown", this.escFunction, false)
    }

    componentDidUpdate(prevProps) {
        const { userCompanies, company } = this.props

        console.log('componentDidUpdate CompanyBankInformationPopup')

        var companyIndexInPrevProps = arrayObjectIndexOf(prevProps.userCompanies, company.id, 'company_id')
        var companyIndexInCurrentProps = arrayObjectIndexOf(userCompanies, company.id, 'company_id')

        if (companyIndexInPrevProps > -1 && companyIndexInCurrentProps > -1) {

            if (prevProps.userCompanies[companyIndexInPrevProps].company.banks.length !== userCompanies[companyIndexInCurrentProps].company.banks.length) {
                if (this.state.choosenBankIsAvailable !== 'unknown' && this.state.choosenBankIsAvailable) {
                    this.props.closeAndGenerateFile(userCompanies[companyIndexInCurrentProps].company.banks[0].id)
                } else {
                    this.props.addedNewAccount()
                }
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.escFunction, false)
    }

    escFunction = (e) => {
        if (e.keyCode === 27) {
            this.props.close()
        }
    }

    onInputChange = e => {
        e.preventDefault()

        var { name, value } = e.target

        this.setState({ [name]: value })
    }

    checkAccountNumber = () => {
        const { accountNumber } = this.state
        const { currency } = this.props

        if (accountNumber) {
            var accountNumberOnlyDigits = accountNumber.replace(/\s/gm, '').replace('PL', '')

            console.log(accountNumberOnlyDigits)
            console.log(accountNumberOnlyDigits.length)

            if (accountNumberOnlyDigits.length === 26) {
                var bankCode = accountNumberOnlyDigits.substring(2, 6)

                var bank = bankIdentification.find(e => e.code === bankCode)

                if (bank) {
                    if (bank.bic) {
                        this.setState({
                            bic: bank.bic,
                            bankName: bank.name,
                            choosenBankIsAvailable: currency === 'PLN' ? bank.available : bank.availableInternational
                        })
                    } else {
                        this.setState({
                            bankName: bank.name,
                            choosenBankIsAvailable: currency === 'PLN' ? bank.available : bank.availableInternational
                        })
                    }
                } else {
                    this.setState({
                        choosenBankIsAvailable: false
                    })
                }
            } else {
                this.setState({
                    choosenBankIsAvailable: false
                })
            }
        } else {
            this.setState({
                choosenBankIsAvailable: 'unknown'
            })
        }
    }

    bankIsValid = () => {
        var valid = true
        const { bankName, bic, accountNumber, currency, accountName } = this.state

        if (accountNumber === '') {
            this.props.alertWarn(this.context.t('Account number is required'))
            valid = false
        } else if (!/^[A-Z]*$/.test(accountNumber[0]) || !/^[A-Z]*$/.test(accountNumber[1])) {
            this.props.alertWarn(this.context.t('Enter the account number in the form of an IBAN number - with the prefix being the country code'))
            valid = false
        }

        if (bankName === '') {
            this.props.alertWarn(this.context.t('Bank name is required'))
            valid = false
        }

        if (bic === '') {
            this.props.alertWarn(this.context.t('BIC/SWIFT is required'))
            valid = false
        }

        if (currency === '') {
            this.props.alertWarn(this.context.t('Currency is required'))
            valid = false
        }

        if (accountName === '') {
            this.props.alertWarn(this.context.t('Bank account name is required'))
            valid = false
        }

        return valid
    }

    createNewBankForCompany = (e) => {
        e.preventDefault()
        const { accountName, bankName, bic, accountNumber, currency } = this.state

        if (this.bankIsValid()) {
            this.setState({
                cursorWait: true
            })
            this.props.addNewBankForCompany(this.props.company.id, { account_number: accountNumber, bank_name: bankName, bic: bic, currency: currency, name: accountName }, (success, newBankId) => {
                this.setState({
                    cursorWait: false
                })
            })
        }
    }

    render() {
        const { company, addNewAccount, addFirstAccount } = this.props
        const { accountName, bankName, accountNumber, bic, currency, choosenBankIsAvailable, cursorWait } = this.state
        return (
            <div className={`bank-info-update-modal ${cursorWait ? 'wait' : ''}`}>
                <div className="card">
                    <span className="close-button" onClick={e => this.props.close(e)}></span>
                    <div className="header">
                        {
                            addNewAccount ? (
                                <h2>{this.context.t('Enter the details for the new bank account')}</h2>
                            ) : (
                                <h2>{this.context.t('Before generating first file to import bank transfers you have to provide informations about your company\'s bank')}</h2>
                            )
                        }

                    </div>
                    <div className="body">
                        <form onSubmit={this.createNewBankForCompany}>
                            <div className='form-group'>
                                <input type="text" name="accountName" id="accountName" value={accountName} onChange={e => this.onInputChange(e)} autoComplete='off' />
                                <label htmlFor="accountName">{this.context.t('Bank Account Name')}</label>
                            </div>

                            <div className='form-group'>
                                <input type="text" name="accountNumber" id="accountNumber" value={accountNumber} onChange={e => this.onInputChange(e)} autoComplete='off' onBlur={e => this.checkAccountNumber()} />
                                <label htmlFor="accountNumber">{this.context.t('Account number')}</label>
                            </div>

                            <div className='form-group'>
                                <input type="text" name="bankName" id="bankName" value={bankName} onChange={e => this.onInputChange(e)} autoComplete='off' />
                                <label htmlFor="bankName">{this.context.t('Bank Name')}</label>
                            </div>

                            <div className='form-group half'>
                                <input type="text" name="bic" id="bic" value={bic} onChange={e => this.onInputChange(e)} autoComplete='off' />
                                <label htmlFor="bic">{this.context.t('BIC/SWIFT')}</label>
                            </div>

                            <div className='form-group half'>
                                <input type="text" name="currency" id="currency" value={currency} onChange={e => this.onInputChange(e)} autoComplete='off' />
                                <label htmlFor="currency">{this.context.t('Currency')}</label>
                            </div>

                            {
                                choosenBankIsAvailable !== 'unknown' && choosenBankIsAvailable && !addFirstAccount ? (
                                    <input type="submit" value={this.context.t('Save and generate file')} />
                                ) : (
                                    <input type="submit" value={this.context.t('Save bank account')} />
                                )
                            }

                        </form>

                        {
                            choosenBankIsAvailable !== 'unknown' ? (
                                choosenBankIsAvailable ? (
                                    null
                                ) : (
                                    <div className="bank-not-available-yet">
                                        {
                                            currency === 'PLN' ? (
                                                this.context.t('Generating files with bank transfer is not yet supported for your bank')
                                            ) : (
                                                this.context.t('Generating files with bank transfer in currencies other than PLN is not yet supported for your bank')
                                            )
                                        }
                                        {this.context.t(' Contact us via')}<Link to={routes.help}>{this.context.t(' Helpdesk ')}</Link>{this.context.t('for more informations.')}
                                    </div>
                                )
                            ) : (
                                null
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }
}

CompanyBankInformationPopup.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    userCompanies: state.Company.companies,
    docsDownloadedForRaport: isIterable(state.Reports.docsDownloadedForRaport) ? [...state.Reports.docsDownloadedForRaport] : [],
})

const mapDispatchToProps = {
    addNewBankForCompany: CompanyActions.addNewBankForCompany,
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyBankInformationPopup)