import React, { Component } from "react";
import SimpleBar from "simplebar-react";
import { Document, Page } from "react-pdf";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Spinner } from 'react-activity'
import "../../css/InvoicePreview.css";
import ReactTooltip from "react-tooltip";
import moment from "moment";

import SendEmailBox from "./SendEmailBox";
import { DOCUMENT_TYPE } from "../../pages/User/SalesPage";

import { FileActions } from "../../actions/FileActions";
import { CompanyActions } from "../../actions/CompanyActions";
import { TabsControlerActions } from '../../actions/V2_TabsControlerActions'
import { AvailableTabs } from "../../reducers/V2_TabsReducer"

export class InvoicePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cursourWait: false,
      pageNumber: 1,
      pdfScale: 1.5,
      isShowedSendEmailBox: false,
      numPages: 0,
      correctionsList: [],
      otherSaleInvoices: [],
      maxHeightForCorrections: 'calc(50% - 65px)',
      maxHeightForOtherInvoice: 'calc(50% - 65px)',
    };

    this.sideBySidePdfContainer = React.createRef()
  }

  componentDidMount() {
    window.addEventListener("keydown", this.escFunction, false)
    const { invoiceData } = this.props
    console.log(this.props)

    this.setState({
      waitingForAllCorrections: true
    })
    console.log('should get other invoices now')
    this.props.getAllCorrectionsToInvoice(invoiceData.id, (success, correctionsList, otherSaleInvoices) => {
      console.log('waiting for other invoices')
      if (success) {
        var availableHeight = window.innerHeight - 216
        var correctionsMaxHeight = availableHeight / 2
        var otherInvoicesMaxHeight = availableHeight / 2
        if (correctionsList.length === 0) {
          availableHeight += 68
          correctionsMaxHeight = 0
          otherInvoicesMaxHeight = availableHeight
        } else {
          var maxHeight = availableHeight / 2
          correctionsMaxHeight = Math.min(correctionsList.length * 22, maxHeight)
          availableHeight -= correctionsMaxHeight
          otherInvoicesMaxHeight = Math.min(otherSaleInvoices.length * 22, availableHeight)
        }

        this.setState({
          waitingForAllCorrections: false,
          correctionsList: correctionsList,
          otherSaleInvoices: otherSaleInvoices,
          maxHeightForCorrections: correctionsMaxHeight,
          maxHeightForOtherInvoice: otherInvoicesMaxHeight
        })
      } else {
        this.setState({
          waitingForAllCorrections: false
        })
      }
    })
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.escFunction, false)
  }

  escFunction = (e) => {
    if (e.keyCode === 27) {
      const { isShowedSendEmailBox } = this.state
      if (isShowedSendEmailBox) {
        this.setState({
          isShowedSendEmailBox: false
        })
      } else if (this.props.showSideBySide) {
        this.closeSecondDocPreview()
      } else {
        this.props.hideInvoiceCreatorOverlay()
        this.props.hideInvoicePreview()
      }

    }
  }

  mouseDownOnPreview = e => {
  }

  mouseUpOnPreview = e => {
    if (e.target && e.target.className && !e.target.className.includes('react-pdf__Page__canvas') && !e.target.className.includes('react-pdf__Page__textContent')) {
      this.props.hideInvoiceCreatorOverlay()
      this.props.hideInvoicePreview()
    }
  }

  escFunction = (e) => {
    if (e.keyCode === 27) {
      const { isShowedSendEmailBox } = this.state
      if (isShowedSendEmailBox) {
        this.setState({
          isShowedSendEmailBox: false
        })
      } else {
        this.props.hideInvoiceCreatorOverlay()
        this.props.hideInvoicePreview()
      }

    }
  }

  mouseDownOnPreview = e => {
  }

  mouseUpOnPreview = e => {
    if (e.target && e.target.className && !e.target.className.includes('react-pdf__Page__canvas') && !e.target.className.includes('react-pdf__Page__textContent')) {
      this.props.hideInvoiceCreatorOverlay()
      this.props.hideInvoicePreview()
    }
  }

  toggleSendEmailBox = (hidePreview) => {
    const { isShowedSendEmailBox } = this.state
    this.setState({ isShowedSendEmailBox: !isShowedSendEmailBox });
    if (isShowedSendEmailBox) {
      this.props.hideInvoiceCreatorOverlay();
      if (hidePreview) this.props.hideInvoicePreview()
    }
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({
      numPages: numPages,
    });
  }

  onSecondDocumentLoadSuccess = ({ numPages }) => {
    this.setState({
      numPagesSecondPreview: numPages,
    });
  }

  onPageLoadSuccess = obj => {
    var originalPdfWidth = obj.originalWidth
    if (this.sideBySidePdfContainer && this.sideBySidePdfContainer.current) {
      console.log(this.sideBySidePdfContainer.current.clientWidth)
      console.log(originalPdfWidth)
      console.log((this.sideBySidePdfContainer.current.clientWidth / originalPdfWidth * 0.9).toFixed(1))

      this.setState({
        pdfScale: (this.sideBySidePdfContainer.current.clientWidth / originalPdfWidth * 0.9).toFixed(1)
      })
    }
  }

  closeSecondDocPreview = () => {
    this.props.closeSecondDocPreview()
    this.setState({
      pdfScale: 1.5
    })
  }

  closePreview = () => {
    const { user } = this.props
    if (!user.new_easydocs_version) {
      this.props.hideInvoiceCreatorOverlay()
      this.props.hideInvoicePreview();
    } else {
      this.props.closeTabWithSaleInvoicePreview()
    }
  }

  render() {
    const { pageNumber, pdfScale, isShowedSendEmailBox, numPages, numPagesSecondPreview, cursourWait, waitingForAllCorrections, correctionsList, otherSaleInvoices, maxHeightForCorrections, maxHeightForOtherInvoice } = this.state;
    const { fileBody, fileName, setEditingMode, email, editedInvoiceId, invoiceData, documentType, showSideBySide, secondPreviewFileId, secondPreviewFileBody } = this.props;

    console.log(email)

    return (
      <div className={`pdf-preview-container ${cursourWait ? 'loading' : ''}`}>
        {
          isShowedSendEmailBox ? (
            <SendEmailBox toggleSendEmailBox={this.toggleSendEmailBox} email={email} editedInvoiceId={editedInvoiceId} invoiceData={invoiceData} />
          ) : null
        }
        <SimpleBar style={{ display: "flex", maxHeight: "100vh" }}>
          <ReactTooltip id="iso-tooltip" place="bottom" effect="solid" className="default-tooltip" delayHide={200} >
            <p>{" "}{this.context.t("To send an email, add the recipient's address (es) by editing the invoice form.")}{" "}</p>
          </ReactTooltip>
          <div className={`nav-bar show-corrections`}>
            <div className="action-buttons">
              <div className="btn" onClick={() => this.closePreview()} >
                <div className="content-wrapper">
                  <span className="back-list-icon" />
                  <p>{this.context.t("Back to list")}</p>
                </div>
              </div>

              {
                showSideBySide ? (
                  <div className="btn disabled">
                    <a>
                      <span className="download-icon" />
                      <p>{this.context.t("Download PDF")}</p>
                    </a>
                  </div>
                ) : (
                  <div className="btn">
                    <a href={fileBody} download={fileName}>
                      <span className="download-icon" />
                      <p>{this.context.t("Download PDF")}</p>
                    </a>
                  </div>
                )
              }
              {
                showSideBySide ? (
                  <div className="btn disabled">
                    <div className="content-wrapper">
                      <span className="send-email-icon" />
                      <p>{this.context.t("Send email")}</p>
                    </div>
                  </div>
                ) : (
                  <div className="btn" onClick={() => { this.toggleSendEmailBox(); }} >
                    <div className="content-wrapper">
                      <span className="send-email-icon" />
                      <p>{this.context.t("Send email")}</p>
                    </div>
                  </div>
                )
              }

              {
                !showSideBySide && (setEditingMode && documentType !== DOCUMENT_TYPE.corrBuyer && documentType !== DOCUMENT_TYPE.corrItems) ? (
                  <div className="btn" onClick={() => { setEditingMode(); this.props.hideInvoicePreview(); }} >
                    <div className="content-wrapper">
                      <span className="edit-icon" />
                      <p>{this.context.t("Edit")}</p>
                    </div>
                  </div>
                ) : (
                  <div className="btn disabled">
                    <div className="content-wrapper">
                      <span className="edit-icon" />
                      <p>{this.context.t("Edit")}</p>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
          <div className={`pdf-wrapper show-corrections`} ref="pdfWrapper">
            {
              showSideBySide ? (
                <div className="side-by-side-preview">
                  <div className={`pdf-container first-file`} ref={this.sideBySidePdfContainer}>
                    {
                      fileBody ? (
                        <Document file={fileBody} onLoadSuccess={this.onDocumentLoadSuccess} onLoadError={console.error}>
                          {/* <Page pageNumber={pageNumber} scale={pdfScale} onLoadSuccess={this.onPageLoadSuccess} onLoadError={console.error} /> */}
                          {
                            Array.apply(null, Array(numPages))
                              .map((x, i) => <Page pageNumber={i + 1} scale={pdfScale} onLoadSuccess={this.onPageLoadSuccess} />)
                          }
                        </Document>
                      ) : (
                        <div className="activity-indicator">
                          <Spinner size={30} speed={0.8} color={"#444444"} />
                        </div>
                      )
                    }
                  </div>
                  <div className={`pdf-container second-file`}>
                    <div className="close" onClick={() => this.closeSecondDocPreview()}></div>
                    {
                      secondPreviewFileBody ? (
                        <Document file={secondPreviewFileBody} onLoadSuccess={this.onSecondDocumentLoadSuccess} onLoadError={console.error}>
                          {/* <Page pageNumber={pageNumber} scale={pdfScale} onLoadSuccess={this.onPageLoadSuccess} onLoadError={console.error} /> */}
                          {
                            Array.apply(null, Array(numPagesSecondPreview))
                              .map((x, i) => <Page pageNumber={i + 1} scale={pdfScale} />)
                          }
                        </Document>
                      ) : (
                        <div className="activity-indicator">
                          <Spinner size={30} speed={0.8} color={"#444444"} />
                        </div>
                      )
                    }
                  </div>
                </div>
              ) : (
                fileBody ? (
                  <Document file={fileBody} onLoadSuccess={this.onDocumentLoadSuccess} onLoadError={console.error} onMouseDown={e => this.mouseDownOnPreview(e)} onMouseUp={e => this.mouseUpOnPreview(e)}>
                    {/* <Page pageNumber={pageNumber} scale={pdfScale} onLoadSuccess={this.onPageLoadSuccess} onLoadError={console.error} /> */}
                    {
                      Array.apply(null, Array(numPages))
                        .map((x, i) => <Page pageNumber={i + 1} scale={pdfScale} />)
                    }
                  </Document>
                ) : (
                  <div className="activity-indicator">
                    <Spinner size={30} speed={0.8} color={"#444444"} />
                  </div>
                )
              )
            }
          </div>
        </SimpleBar >
        {
          invoiceData.has_correction ? (
            <div className="corrections-list">
              <h2>{this.context.t('Corrections issued to the invoice')}:</h2>
              <div className="header-row">
                <div className="doc-number">{this.context.t('Doc number')}</div>
                <div className="issue-date">{this.context.t('Date')}</div>
                <div className="net">{this.context.t('Net value')}</div>
              </div>
              <SimpleBar style={{ maxHeight: maxHeightForCorrections, marginBottom: '20px' }}>
                <div className="corrections-container">
                  {
                    waitingForAllCorrections ? (
                      <div className="corrections-liading">
                        <div className="activity-indicator">
                          <Spinner size={30} speed={0.8} color={"#444444"} />
                        </div>
                      </div>
                    ) : (
                      <>
                        {
                          correctionsList.map((correction, index) => {
                            return (
                              <div className={`correction-row ${secondPreviewFileId === correction.id ? 'selected' : ''} ${index === 0 ? 'first-row' : ''}`} onClick={() => this.props.openSecondDocPreview(correction.id)}>
                                <div className="doc-number">{correction.doc_number}</div>
                                <div className="issue-date">{moment(correction.issue_date).format('YYYY-MM-DD')}</div>
                                <div className="net">{`${parseFloat(correction.total_net_value).toFixed(2)} ${correction.currency}`}</div>
                              </div>
                            )
                          })
                        }
                      </>
                    )
                  }
                </div>
              </SimpleBar>

              <h2>{this.context.t('Other sales invoices for this counterparty')}:</h2>
              <div className="header-row">
                <div className="doc-number">{this.context.t('Doc number')}</div>
                <div className="issue-date">{this.context.t('Date')}</div>
                <div className="net">{this.context.t('Net value')}</div>
              </div>
              <SimpleBar style={{ maxHeight: maxHeightForOtherInvoice }}>
                <div className="corrections-container">
                  {
                    waitingForAllCorrections ? (
                      <div className="corrections-liading">
                        <div className="activity-indicator">
                          <Spinner size={30} speed={0.8} color={"#444444"} />
                        </div>
                      </div>
                    ) : (
                      <>
                        {
                          otherSaleInvoices.map((doc, index) => {
                            return (
                              <div className={`correction-row ${secondPreviewFileId === doc.id ? 'selected' : ''} ${index === 0 ? 'first-row' : ''}`} onClick={() => this.props.openSecondDocPreview(doc.id)}>
                                <div className="doc-number">{doc.doc_number}</div>
                                <div className="issue-date">{moment(doc.issue_date).format('YYYY-MM-DD')}</div>
                                <div className="net">{`${parseFloat(doc.total_net_value).toFixed(2)} ${doc.currency}`}</div>
                              </div>
                            )
                          })
                        }
                      </>
                    )
                  }
                </div>
              </SimpleBar>
            </div>
          ) : (
            <div className="corrections-list">
              <h2>{this.context.t('Other sales invoices for this counterparty')}:</h2>
              <div className="header-row">
                <div className="doc-number">{this.context.t('Doc number')}</div>
                <div className="issue-date">{this.context.t('Date')}</div>
                <div className="net">{this.context.t('Net value')}</div>
              </div>
              <SimpleBar style={{ maxHeight: maxHeightForOtherInvoice }}>
                <div className="corrections-container">
                  {
                    waitingForAllCorrections ? (
                      <div className="corrections-liading">
                        <div className="activity-indicator">
                          <Spinner size={30} speed={0.8} color={"#444444"} />
                        </div>
                      </div>
                    ) : (
                      <>
                        {
                          otherSaleInvoices.map((doc, index) => {
                            return (
                              <div className={`correction-row ${secondPreviewFileId === doc.id ? 'selected' : ''} ${index === 0 ? 'first-row' : ''}`} onClick={() => this.props.openSecondDocPreview(doc.id)}>
                                <div className="doc-number">{doc.doc_number}</div>
                                <div className="issue-date">{moment(doc.issue_date).format('YYYY-MM-DD')}</div>
                                <div className="net">{`${parseFloat(doc.total_net_value).toFixed(2)} ${doc.currency}`}</div>
                              </div>
                            )
                          })
                        }
                      </>
                    )
                  }
                </div>
              </SimpleBar>
            </div>
          )
        }
      </div >
    );
  }
}

InvoicePreview.contextTypes = {
  t: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
  user: state.User.user
});

const mapDispatchToProps = {
  getAllCorrectionsToInvoice: CompanyActions.getAllCorrectionsToInvoice,
  closeTabWithSaleInvoicePreview: TabsControlerActions.closeTabWithSaleInvoicePreview
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePreview);
