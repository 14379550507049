import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import "../../../src/css/SalesPage.css";
import alertTriangle from "../../img/alert-triangle-red.svg";

export class InvoiceInfoBox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { title, hideInfoBox, arrayData = undefined } = this.props;
    const success = "The invoice data has been imported.";
    return (
      <div className="import-info-popup">
        <div className={title !== success ? "popup-card" : "popup-card-success"} >
          {
            title !== success ? (
              <div className="image">
                <img src={alertTriangle} alt="alertTriangle" />
              </div>
            ) : null
          }
          <div className="title">
            <h3>{this.context.t(title)}</h3>
          </div>
          <div className="text">
            {
              !arrayData || arrayData.length > 0 ? (
                <p>{this.context.t("Invalid data in the lines:")}</p>
              ) : null
            }
            {
              !arrayData || arrayData.length > 0 ? (
                <SimpleBar style={{ maxHeight: "30vh", width: "100%" }}>
                  {
                    arrayData ?
                      arrayData.map((rowData) => {
                        let invalidItems = "";
                        rowData.invalidColumns.forEach(
                          (item) => (invalidItems += this.context.t(item) + " ")
                        );
                        return (
                          <div className="row-container">
                            <div className="number">{rowData.rowId} :</div>
                            <div className="content">{invalidItems}</div>
                          </div>
                        );
                      }) : null
                  }
                </SimpleBar>
              ) : null
            }
            {
              !arrayData || arrayData.length > 0 ? (
                <span>
                  {this.context.t(
                    "After correcting the above errors, import the file again."
                  )}
                </span>
              ) : null
            }
          </div>
          <div className="button" onClick={() => { hideInfoBox() }} >
            {this.context.t("Close")}
          </div>
        </div>
      </div>
    );
  }
}

InvoiceInfoBox.contextTypes = {
  t: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceInfoBox);
