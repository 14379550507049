import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import { Spinner } from 'react-activity'
import 'react-activity/dist/react-activity.css'

import CostsTabletooltips from './CostsTabletooltips'

import { Column, Table, SortDirection, AutoSizer, WindowScroller } from "react-virtualized";
import "react-virtualized/styles.css";
import _ from "lodash";

import Dotdotdot from 'react-dotdotdot'

import { ReportsActions } from '../../actions/ReportsActions'

export class IncomesTableVirtualized extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            docsList: []
        }
    }

    componentDidMount() {
        this.setState({
            docsList: this.props.docsDownloadedForRaport.filter(e => this.checkIfDocMatchesFilterOptions(e)),
            docsSortField: this.props.sortField,
            docsSortOrder: this.props.sortOrder
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.docsDownloadedForRaport !== this.props.docsDownloadedForRaport || this.filtersDidChange(prevProps)) {
            this.setState({
                docsList: this.props.docsDownloadedForRaport.filter(e => this.checkIfDocMatchesFilterOptions(e))
            })
        }
        ReactTooltip.rebuild()
    }

    filtersDidChange = prevProps => {
        const { searchField, searchCurrency, selectOnlyAvailableForReport, selectOnlyAvailableForBankTransferFile, selectOnlyNotPaid, selectOnlyNotAccounted } = this.props

        if (searchField !== prevProps.searchField) return true
        if (searchCurrency !== prevProps.searchCurrency) return true
        if (selectOnlyAvailableForReport !== prevProps.selectOnlyAvailableForReport) return true
        if (selectOnlyAvailableForBankTransferFile !== prevProps.selectOnlyAvailableForBankTransferFile) return true
        if (selectOnlyNotPaid !== prevProps.selectOnlyNotPaid) return true
        if (selectOnlyNotAccounted !== prevProps.selectOnlyNotAccounted) return true

        return false
    }

    formatPrice = price => {
        if (price || price === 0) {
            price = price.toFixed(2)
            if (price.indexOf('.') > -1) {
                var parts = price.split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                price = parts.join('.')
            } else {
                price = price.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }

            return price
        } else {
            return ''
        }
    }

    getSortClass = (sortFieldCheck) => {
        const { sortField, sortOrder } = this.props

        if (sortField === sortFieldCheck) {
            if (sortOrder === "asc")
                return "icon-sort down"
            return "icon-sort up"
        }

        return "icon-sort"
    }

    getStatusTooltip = tx => {
        if (tx.accountStatus === 'timeout') {
            return 'timeout'
        } else if (tx.otherCompany && tx.accountStatus === "other-company") {
            return tx.id
        } else if (tx.status === 'Not applicable') {
            return 'payment-orders'
        } else if ((tx.status === 'Active' || tx.status === 'Released' || tx.status.includes('Account')) && tx.accountStatus === 'found') {
            return 'active-found'
        } else if ((tx.status === 'Active' || tx.status === 'Released' || tx.status.includes('Account')) && tx.accountStatus !== 'found') {
            return 'active-notFound'
        } else if (!(tx.status === 'Active' || tx.status === 'Released' || tx.status.includes('Account')) && tx.accountStatus !== 'found') {
            return 'notActive-notFound'
        } else if (!(tx.status === 'Active' || tx.status === 'Released' || tx.status.includes('Account')) && tx.accountStatus === 'found') {
            return 'notActive-found'
        } else {
            return 'notActive-notFound'
        }
    }

    headerRendererCosts = (name) => {
        switch (name) {
            case 'all':
                return (
                    <div className="wrapper all">
                        <span className="column-name" onClick={e => this.props.selectAll(e)}>{this.context.t('Select all ')}</span>
                    </div>
                )
            case 'fileIcon':
                return (
                    <div className="wrapper">
                    </div>
                )
            case 'counterparty':
                return (
                    <div className="wrapper counterparty" onClick={() => this.props.switchSortingFilter('company')}>
                        <span className="column-name">{this.context.t('Counterparty')}</span>
                        <span className={this.getSortClass('company')}></span>
                    </div>
                )
            case 'documentNumber':
                return (
                    <div className="wrapper" onClick={() => this.props.switchSortingFilter('documentNumber')}>
                        <span className="column-name">{this.context.t('Document number')}</span>
                        <span className={this.getSortClass('documentNumber')}></span>
                    </div>
                )
            case 'importDate':
                return (
                    <div className="wrapper date" onClick={() => this.props.switchSortingFilter('saleDate')}>
                        <span className="column-name">{this.context.t('Sale date')}</span>
                        <span className={this.getSortClass('saleDate')}></span>
                    </div>
                )
            case 'issueDate':
                return (
                    <div className="wrapper date" onClick={() => this.props.switchSortingFilter('issueDate')}>
                        <span className="column-name">{this.context.t('Issue Date')}</span>
                        <span className={this.getSortClass('issueDate')}></span>
                    </div>
                )
            case 'dueDate':
                return (
                    <div className="wrapper date" onClick={() => this.props.switchSortingFilter('dueDate')}>
                        <span className="column-name">{this.context.t('Due Date')}</span>
                        <span className={this.getSortClass('dueDate')}></span>
                    </div>
                )
            case 'nettoNumber':
                return (
                    <div className="wrapper price" onClick={() => this.props.switchSortingFilter('nettoNumber')}>
                        <span className="column-name">{this.context.t('Net')}</span>
                        <span className={this.getSortClass('nettoNumber')}></span>
                    </div>
                )
            case 'priceNumber':
                return (
                    <div className="wrapper price" onClick={() => this.props.switchSortingFilter('priceNumber')}>
                        <span className="column-name">{this.context.t('Amount to pay')}</span>
                        <span className={this.getSortClass('priceNumber')}></span>
                    </div>
                )
            case 'currency':
                return (
                    <div className="wrapper" onClick={() => this.props.switchSortingFilter('currency')}>
                        <span className="column-name">{this.context.t('Currency')}</span>
                        {/* <span className={this.getSortClass('currency')}></span> */}
                    </div>
                )
            case 'status':
                return (
                    <div className="wrapper" onClick={() => this.props.switchSortingFilter('status')} data-for="white-list-tooltip" data-tip="show">
                        <span className="column-name">{this.context.t('VAT taxpayer status')}</span>
                        <span className={this.getSortClass('status')}></span>
                    </div>
                )
            case 'comment':
                return (
                    <div className="wrapper" onClick={() => this.props.switchSortingFilter('comment')}>
                        <span className="column-name">{this.context.t('Comment')}</span>
                        <span className={this.getSortClass('comment')}></span>
                    </div>
                )
            case 'tags':
                return (
                    <div className="wrapper" onClick={() => this.props.switchSortingFilter('tags')}>
                        <span className="column-name">{this.context.t('Tags')}</span>
                        <span className={this.getSortClass('tags')}></span>
                    </div>
                )
            case 'alreadyInReport':
                return (
                    <div className="wrapper in-report" onClick={() => this.props.switchSortingFilter('alreadyInReport')}>
                        <span className="column-name">{this.context.t('In report')}</span>
                        <span className={this.getSortClass('alreadyInReport')}></span>
                    </div>
                )
            case 'splitPayment':
                return (
                    <div className="wrapper already-paid" onClick={() => this.props.switchSortingFilter('splitPayment')}>
                        <span className="column-name">{this.context.t('Split Payment')}</span>
                        <span className={this.getSortClass('splitPayment')}></span>
                    </div>
                )
            case 'alreadyPaid':
                return (
                    <div className="wrapper already-paid" onClick={() => this.props.switchSortingFilter('alreadyPaid')}>
                        <span className="column-name">{this.context.t('Paid?')}</span>
                        <span className={this.getSortClass('alreadyPaid')}></span>
                    </div>
                )
            case 'accounted':
                return (
                    <div className="wrapper already-paid" onClick={() => this.props.switchSortingFilter('accounted')}>
                        <span className="column-name">{this.context.t('Accounted?')}</span>
                        <span className={this.getSortClass('accounted')}></span>
                    </div>
                )
            default:
                return null
        }
    }

    sort = ({ sortBy, sortDirection }) => {
        var { docsSortField, docsSortOrder, docsList } = this.state
        if (docsSortField === sortBy) {
            if (docsSortOrder === 'asc') {
                docsSortOrder = 'desc'
            } else {
                docsSortOrder = 'asc'
            }
        } else {
            docsSortField = sortBy
            docsSortOrder = 'asc'
        }
        docsList.sort(this.dynamicComparer(docsSortField, docsSortOrder))

        this.setState({
            docsList: docsList,
            docsSortField: sortBy,
            docsSortOrder: sortDirection === SortDirection.ASC ? 'asc' : 'desc'
        })
    }

    checkIfDocMatchesFilterOptions = doc => {
        const { searchField, searchCurrency, selectOnlyAvailableForReport, selectOnlyAvailableForBankTransferFile, selectOnlyNotPaid, selectOnlyNotAccounted, alreadyPaidDocs, downloadedDocsType } = this.props

        if (doc && downloadedDocsType !== 'Expenses') {
            var company = doc.company ? doc.company.toLowerCase() : ''
            var comment = doc.comment ? doc.comment.toLowerCase() : ''
            var waproMPK = doc.waproMPK ? doc.waproMPK.toLowerCase() : ''
            var tags = doc.tags ? doc.tags.toLowerCase() : ''
            var docNumber = doc.documentNumber ? doc.documentNumber.toLowerCase() : ''

            var matches = true

            if (searchField) {
                if (!(waproMPK.includes(searchField.toLowerCase()) || company.includes(searchField.toLowerCase()) || comment.includes(searchField.toLowerCase()) || tags.includes(searchField.toLowerCase()) || docNumber.includes(searchField.toLowerCase()))) {
                    return false
                }
            }

            if (selectOnlyAvailableForReport) {
                if (!(doc.canPay && (!doc.alreadyInReport || doc.reportName === 'PENDING'))) {
                    return false
                }
            }

            if (selectOnlyAvailableForBankTransferFile) {
                if (!(!doc.alreadyInBankTransferFile || doc.bankTransferFileName === 'PENDING')) {
                    return false
                }
            }

            if (selectOnlyNotPaid) {
                if (!(!doc.alreadyPaid && doc.canPay && !alreadyPaidDocs.find(e => e.id === doc.id))) {
                    return false
                }
            }

            if (selectOnlyNotAccounted) {
                if (doc.accounted) {
                    return false
                }
            }


            if (searchCurrency !== 'ALL') {
                if (doc.currency !== searchCurrency) {
                    return false
                }
            }

            return matches
        } else if (doc && downloadedDocsType === 'Expenses') {
            console.log(doc)
            var matches = true

            if (searchField) {
                if (!doc.uploadedBy.toLowerCase().includes(searchField.toLowerCase()) && !doc.comment.toLowerCase().includes(searchField.toLowerCase())) {
                    matches = false
                }
            }

            if (searchCurrency !== 'ALL') {
                if (doc.currency !== searchCurrency) {
                    matches = false
                }
            }

            if (selectOnlyAvailableForReport) {
                if (doc.alreadyInReport) {
                    matches = false
                }
            }

            return matches
        } else {
            return false
        }
    }

    dynamicComparer = (key, order = 'asc') => {
        return (a, b) => {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                return 0;
            }

            const varA = (typeof a[key] === 'string') ?
                a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string') ?
                b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (!varA) {
                comparison = -1
            } else if (!varB) {
                comparison = 1
            } else if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === 'desc') ? (comparison * -1) : comparison
            );
        };
    }

    rowRendererIncome = ({ style, key, index, parent, width }) => {
        const { docsList } = this.state
        const { alreadyPaidDocs, selectFor, roleInSelectedProject, docsSelectedForReport } = this.props
        var tx = docsList[index]

        if (tx) {
            return (
                <div
                    key={key}
                    className={`${index % 2 === 0 ? 'even' : 'odd'} ReactVirtualized__Table__row`}
                    role="row"
                    style={style}
                    onClick={e => this.props.fileIconClick(e, tx.projectId, tx.path, tx.id, tx.type, tx.fileId, tx.alreadyPaid)}
                >
                    {
                        <>
                            <div
                                className="ReactVirtualized__Table__rowColumn checkmarks"
                                data-tip='show'
                                data-for={`bank-transfer-file-no-available-${tx.notAvailableForBankTransfersMagId}`}
                                role="gridcell"
                                style={{ overflow: "hidden", flex: `0 1 ${width * 0.05}px` }}
                            >
                                {
                                    tx.reportName !== 'PENDING' && tx.bankTransferFileName !== 'PENDING' && tx.waproXMLFileName !== 'PENDING' ? (
                                        <span
                                            className={`checkbox ${docsSelectedForReport.find(e => e.id === tx.id) ? 'checked' : ''}
                                        ${((!tx.canPay || tx.alreadyInReport) && selectFor === 'report') || (selectFor === 'transfer' && tx.alreadyInBankTransferFile) || (selectFor === 'wapro' && (tx.missingWaproFields || tx.accountingFileId)) ? 'inactive' : ''}`}
                                            onClick={e => this.props.addDocToSelected(e, tx)}>
                                        </span>
                                    ) : (
                                        <span className="activity-indicator-doc-table">
                                            <Spinner size={11} speed={0.8} color={"#69B792"} />
                                        </span>
                                    )
                                }
                            </div>
                            <div
                                className={`ReactVirtualized__Table__rowColumn ${tx.type === 'PAYMENT_ORDER' ? "payment-order-icon" : "file-icon"}`}
                                role="gridcell"
                                style={{ overflow: "hidden", flex: `0 1 ${width * 0.02}px` }}
                            ></div>
                            <div
                                className="ReactVirtualized__Table__rowColumn counterparty"
                                role="gridcell"
                                style={{ overflow: "hidden", flex: `0 1 ${width * 0.13}px` }}
                            ><Dotdotdot clamp={2}><p>{tx.company}</p></Dotdotdot></div>
                            <div
                                className="ReactVirtualized__Table__rowColumn doc-number"
                                role="gridcell"
                                style={{ overflow: "hidden", flex: `0 1 ${width * 0.11}px` }}
                            ><Dotdotdot clamp={2}><p>{tx.documentNumber}</p></Dotdotdot></div>
                            <div
                                className="ReactVirtualized__Table__rowColumn date"
                                role="gridcell"
                                style={{ overflow: "hidden", flex: `0 1 ${width * 0.08}px` }}
                            ><Dotdotdot clamp={2}><p>{tx.saleDate}</p></Dotdotdot></div>
                            <div
                                className="ReactVirtualized__Table__rowColumn date"
                                role="gridcell"
                                style={{ overflow: "hidden", flex: `0 1 ${width * 0.08}px` }}
                            ><Dotdotdot clamp={2}><p>{tx.issueDate}</p></Dotdotdot></div>
                            <div
                                className={`ReactVirtualized__Table__rowColumn date ${moment(tx.dueDate).isValid() && moment(tx.dueDate) < moment() && !(alreadyPaidDocs.find(e => e === tx.id) || tx.alreadyPaid) ? 'overdue' : ''}`}
                                role="gridcell"
                                style={{ overflow: "hidden", flex: `0 1 ${width * 0.08}px` }}
                            ><Dotdotdot clamp={2}><p>{moment(tx.dueDate).isValid() ? moment(tx.dueDate).format('YYYY-MM-DD') : tx.dueDate}</p></Dotdotdot></div>
                            <div
                                className={`ReactVirtualized__Table__rowColumn price ${parseFloat(tx.netto) < 0 ? 'refund' : ''}`}
                                role="gridcell"
                                style={{ overflow: "hidden", flex: `0 1 ${width * 0.07}px` }}
                            ><Dotdotdot clamp={2}><p>{this.formatPrice(parseFloat(tx.netto))}</p></Dotdotdot></div>
                            <div
                                className={`ReactVirtualized__Table__rowColumn price ${parseFloat(tx.price) < 0 ? 'refund' : ''}`}
                                role="gridcell"
                                style={{ overflow: "hidden", flex: `0 1 ${width * 0.07}px` }}
                            ><Dotdotdot clamp={2}><p>{this.formatPrice(parseFloat(tx.price))}</p></Dotdotdot></div>
                            <div
                                className="ReactVirtualized__Table__rowColumn currency"
                                role="gridcell"
                                style={{ overflow: "hidden", flex: `0 1 ${width * 0.04}px` }}
                            ><Dotdotdot clamp={2}><p>{tx.currency}</p></Dotdotdot></div>
                            {/* <div
                                className="ReactVirtualized__Table__rowColumn status"
                                role="gridcell"
                                style={{ overflow: "hidden", flex: `0 1 ${width * 0.1}px` }}
                            >
                                <div data-for={`status-tooltip-${this.getStatusTooltip(tx)}`} data-tip="show">
                                    <Dotdotdot clamp={2}><p>{this.context.t(tx.status)}</p></Dotdotdot>
                                    {/* {
                                    tx.accountStatus === 'timeout' ? (
                                        <span className="status-warning vat-api-unavailable">E</span>
                                    ) : (
                                        tx.accountStatus === 'not-found' || !(tx.status === 'Active' || tx.status === 'Released' || tx.status === 'Not applicable' || tx.status.includes('Account')) ? (
                                            <span className="status-warning yellow">W</span>
                                        ) : (
                                            tx.accountStatus === 'other-company' ? (
                                                <span className="status-warning red">E</span>
                                            ) : (
                                                null
                                            )
                                        )
                                    )
                                } */}
                            {/*    </div>
                            </div>
                            <div
                                className="ReactVirtualized__Table__rowColumn comment"
                                role="gridcell"
                                style={{ overflow: "hidden", flex: `0 1 ${width * 0.085}px` }}
                            ><Dotdotdot clamp={2}><p>{tx.comment}</p></Dotdotdot></div> */}
                            <div
                                className="ReactVirtualized__Table__rowColumn tags"
                                role="gridcell"
                                style={{ overflow: "hidden", flex: `0 1 ${width * 0.12}px` }}
                            ><Dotdotdot clamp={2}><p>{tx.tags}</p></Dotdotdot></div>
                            {/* <div
                                className="ReactVirtualized__Table__rowColumn in-report"
                                role="gridcell"
                                style={{ overflow: "hidden", flex: `0 1 ${width * 0.04}px` }}
                            >
                                {
                                    tx.alreadyInReport && tx.reportName && tx.reportName !== 'PENDING' ? (
                                        <span className="icon-xlsx" onClick={e => this.props.downloadReport(e, tx.projectId, tx.reportName)}></span>
                                    ) : (
                                        this.context.t("No")
                                    )
                                }
                            </div>
                            <div
                                className="ReactVirtualized__Table__rowColumn mpp"
                                role="gridcell"
                                style={{ overflow: "hidden", flex: `0 1 ${width * 0.04}px` }}
                            >
                                {tx.splitPayment ? this.context.t('Yes') : this.context.t('No')}
                            </div> */}
                            <div
                                className="ReactVirtualized__Table__rowColumn checkmarks"
                                role="gridcell"
                                style={{ overflow: "hidden", flex: `0 1 ${width * 0.07}px` }}
                            >
                                {
                                    alreadyPaidDocs.find(e => e === tx.id) || tx.alreadyPaid ? (
                                        <div className="payment-status" data-for={`payment-method-tooltip-${tx.paymentMethod}`} data-tip="show">
                                            <span className={`checkbox checked ${alreadyPaidDocs.find(e => e === tx.id) ? 'new' : ''}`} onClick={e => this.props.togglePaymentList(e, tx)}></span>
                                            <span>{tx.paymentMethod && tx.paymentMethod.toLowerCase() === 'transfer' ? (
                                                this.context.t('P')
                                            ) : (
                                                tx.paymentMethod && tx.paymentMethod.toLowerCase() === 'card' ? (
                                                    this.context.t('K')
                                                ) : (
                                                    tx.paymentMethod && tx.paymentMethod.toLowerCase() === 'cash' ? (
                                                        this.context.t('G')
                                                    ) : (
                                                        this.context.t('I')
                                                    )
                                                )
                                            )}</span>
                                        </div>
                                    ) : (
                                        <span className={`checkbox ${!tx.canPay ? 'inactive' : ''}`} onClick={e => this.props.togglePaymentList(e, tx)}></span>
                                    )
                                }
                            </div>
                            <div
                                className="ReactVirtualized__Table__rowColumn checkmarks"
                                role="gridcell"
                                style={{ overflow: "hidden", flex: `0 1 ${width * 0.07}px` }}
                            >
                                <span className={`checkbox ${tx.accounted ? 'checked' : ''} ${roleInSelectedProject === 'USER1' ? 'inactive' : ''}`} onClick={e => this.props.toggleAccountedStatus(tx.id, tx.fileId, tx.projectId, tx.type, !tx.accounted)}></span>
                            </div>
                        </>
                    }
                </div>
            );
        } else {
            return null
        }
    }

    _setRef = windowScroller => {
        this._windowScroller = windowScroller;
    };

    generateOtherComoanyTooltip = (tx) => {
        if (tx.otherCompany) {
            return (
                <ReactTooltip id={`status-tooltip-${tx.id}`} place="right" effect="solid" className="default-tooltip project-info">
                    <ul>
                        <li>
                            <span className="property">{this.context.t('Active VAT payer:')}</span>
                            <span className="value">
                                {
                                    tx.status === 'Active' || tx.status === 'Released' || tx.status.includes('Account') ? this.context.t('Yes') : this.context.t('No')
                                }
                            </span>
                        </li>
                        <li>
                            <span className="property">{this.context.t('Account number on the list:')}</span>
                            <span className="value">
                                {
                                    tx.accountStatus === 'found' ? this.context.t('Yes') : this.context.t('No')
                                }
                            </span>
                        </li>
                        <li>
                            <span className="property">{this.context.t('Company to which the account belongs:')}</span>
                            <span className="value">
                                {tx.otherCompany}
                            </span>
                        </li>
                    </ul>
                </ReactTooltip>
            )
        } else {
            return null
        }
    }


    render() {
        const { docsDownloadedForRaport, selectFor } = this.props
        const { docsSortField, docsSortOrder, docsList } = this.state

        return (
            <div className="table-responsive" ref={this.props.setTableRef}>
                <CostsTabletooltips selectFor={selectFor} />

                <>
                    {
                        docsDownloadedForRaport.map(tx => this.generateOtherComoanyTooltip(tx))
                    }
                </>

                <WindowScroller
                    ref={this._setRef}
                    scrollElement={window}
                >
                    {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
                        <AutoSizer disableHeight>
                            {({ width }) => (
                                <div ref={registerChild}>
                                    <Table
                                        ref={el => {
                                            window.listEl = el;
                                        }}
                                        autoHeight
                                        width={width}
                                        height={height}
                                        headerHeight={40}
                                        rowHeight={40}
                                        sort={this.sort}
                                        sortBy={docsSortField}
                                        sortDirection={docsSortOrder === 'asc' ? SortDirection.ASC : SortDirection.DESC}
                                        rowCount={docsList.length}
                                        rowGetter={({ index }) => docsList[index]}
                                        rowRenderer={({ style, key, index, parent }) => this.rowRendererIncome({ style, key, index, parent, width })}

                                        isScrolling={isScrolling}
                                        onScroll={(e) => { onChildScroll(e); ReactTooltip.rebuild() }}
                                        scrollTop={scrollTop}
                                    >
                                        <Column dataKey="checkbox" width={width * 0.05} headerRenderer={() => this.headerRendererCosts('all')} />
                                        <Column dataKey="fileIcon" width={width * 0.02} headerRenderer={() => this.headerRendererCosts('fileIcon')} />
                                        <Column dataKey="company" width={width * 0.13} headerRenderer={() => this.headerRendererCosts('counterparty')} />
                                        <Column dataKey="documentNumber" width={width * 0.11} headerRenderer={() => this.headerRendererCosts('documentNumber')} />
                                        <Column dataKey="importDate" width={width * 0.08} headerRenderer={() => this.headerRendererCosts('importDate')} />
                                        <Column dataKey="issueDate" width={width * 0.08} headerRenderer={() => this.headerRendererCosts('issueDate')} />
                                        <Column dataKey="dueDate" width={width * 0.08} headerRenderer={() => this.headerRendererCosts('dueDate')} />
                                        <Column dataKey="nettoNumber" width={width * 0.07} headerRenderer={() => this.headerRendererCosts('nettoNumber')} />
                                        <Column dataKey="priceNumber" width={width * 0.07} headerRenderer={() => this.headerRendererCosts('priceNumber')} />
                                        <Column dataKey="currency" width={width * 0.04} headerRenderer={() => this.headerRendererCosts('currency')} />
                                        {/* <Column dataKey="status" width={width * 0.1} headerRenderer={() => this.headerRendererCosts('status')} />
                                        <Column dataKey="comment" width={width * 0.085} headerRenderer={() => this.headerRendererCosts('comment')} /> */}
                                        <Column dataKey="tags" width={width * 0.12} headerRenderer={() => this.headerRendererCosts('tags')} />
                                        {/* <Column dataKey="alreadyInReport" width={width * 0.04} headerRenderer={() => this.headerRendererCosts('alreadyInReport')} />
                                        <Column dataKey="splitPayment" width={width * 0.04} headerRenderer={() => this.headerRendererCosts('splitPayment')} /> */}
                                        <Column dataKey="alreadyPaid" width={width * 0.07} headerRenderer={() => this.headerRendererCosts('alreadyPaid')} />
                                        <Column dataKey="accounted" width={width * 0.07} headerRenderer={() => this.headerRendererCosts('accounted')} />
                                    </Table>
                                </div>
                            )}
                        </AutoSizer>
                    )}
                </WindowScroller>

            </div>
        )

    }
}

IncomesTableVirtualized.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    sendGetDocumentsRequest: state.Reports.sendGetDocumentsRequest,
    docsDownloadedForRaport: state.Reports.docsDownloadedForRaport,
    alreadyPaidDocs: state.Reports.alreadyPaidDocs,
    sortField: state.Reports.docsSortField,
    sortOrder: state.Reports.docsSortOrder,
    downloadedDocsType: state.Reports.downloadedDocsType,
})

const mapDispatchToProps = {
    switchSortingFilter: ReportsActions.switchDocsSortingFilter,
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(IncomesTableVirtualized))