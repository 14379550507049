import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { EventActions } from '../actions/EventActions';
import { AlertActions } from '../actions/AlertActions';


export class GoogleAuthHandler extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.user && this.props.user) {
            this.props.alertInfo(this.context.t("We are finishing your synchronization with the Google calendar. You will be redirected to the home page in a moment."))

            var search = this.props.location.search
            var params = new URLSearchParams(search);
            var error = params.get('error');
            var code = params.get('code');

            if (error) {
                console.log(error)
            } else {
                this.props.authorizeGoogleCalendar(code, this.props.user.id, (success, err) => {
                    if (success) {
                        window.opener = null;
                        window.open("", "_self");
                        window.close();
                    }
                })
            }
        }
    }

    render() {
        return (null)
    }
}

GoogleAuthHandler.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user
})

const mapDispatchToProps = {
    alertInfo: AlertActions.info,
    authorizeGoogleCalendar: EventActions.authorizeGoogleCalendar,
}

export default connect(mapStateToProps, mapDispatchToProps)(GoogleAuthHandler)

