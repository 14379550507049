import React, { Component } from 'react'
import $ from 'jquery'
import moment from 'moment'
import 'moment-timezone'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import TextareaAutosize from 'react-textarea-autosize'
import { Spinner, Digital } from 'react-activity'
import 'react-activity/dist/react-activity.css'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import ReactTooltip from 'react-tooltip'
import throttle from "lodash.throttle"

import arrayObjectIndexOf from "../../helpers/indexOfObject"
import Calendar from '../../components/Calendar'
import TimePicker from '../../components/TimePicker'
import OverlayInstructions from '../../components/OverlayInstructions'
import AvailableInOtherPlanLabel from '../../components/AvailableInOtherPlanLabel'

import { EventActions } from '../../actions/EventActions';
import { AlertActions } from '../../actions/AlertActions';
import { UserActions } from '../../actions/UserActions'
import { CompanyActions } from '../../actions/CompanyActions'

import { socket } from "../../App"
export class CalendarPage extends Component {

    constructor(props) {
        super(props)
        var startTime = moment()
        var startHour = startTime.hour()
        var startMinute = startTime.minute()
        var minute = startTime.minute() % 5
        startMinute += (5 - minute)
        if (startMinute === 60) {
            startHour += 1
            startHour = 0
        }
        console.log(startMinute)

        this.state = {
            today: moment(),
            mainCalendarDate: moment(),
            authorizationModal: 'closed',
            authCode: '',
            authStep: 'form',
            addEventTab: 'closed',
            addEventStep: 'details',
            showGoogleCalendarList: false,
            googleCalendarName: 'Primary',
            googleCalendarId: 'primary',
            showRepetitionPatterns: false,
            eventRepeat: false,
            eventRepeatPatern: { code: 'ONCE', text: 'One-time Event' },
            possibleEventsRepeatPaterns: [
                { code: 'ONCE', text: 'One-time Event' },
                { code: 'WEEKLY', text: 'Every week' },
                { code: 'BIWEEKLY', text: 'Every second week' },
                { code: 'MONTHLY', text: 'Every month' },
                { code: 'LAST_WORKING_DAY_OF_MONTH', text: 'Last working day of each month' },
                { code: 'PENULTIMATE_WORKING_DAY_OF_MONTH', text: 'Penultimate working day of each month' },
                { code: 'LAST_DAY_OF_MONTH', text: 'Last day of each month' },
                { code: 'PENULTIMATE_DAY_OF_MONTH', text: 'Penultimate day of each month' },
                { code: 'QUATERLY', text: 'Every quarter' },
                { code: 'HALFYEAR', text: 'Every six months' },
                { code: 'YEAR', text: 'Every year' }
            ],
            eventName: '',
            eventStartDate: '',
            eventStartDateValue: '',
            eventStartTime: '',
            eventStartHour: startHour,
            eventStartMinute: startMinute,
            eventProjectName: '',
            eventProjectCode: '',
            eventProjectId: '',
            eventProjectsSuggestions: [],
            suggestionsOpen: false,
            eventDescription: '',
            selectSelf: true,
            eventParticipants: [],
            userResponsibleForTask: {},
            showEventParticipants: false,
            textareaFocus: false,
            focusedField: null,

            editMode: false,
            eventToUpdateOriginalId: null,
            eventToUpdateRecurrence: null,
            eventToUpdateOriginalDate: null,
            eventToUpdateId: null,
            eventToDeleteId: null,
            eventToDeleteRecurrence: null,

            showDesynchronizeOption: false,
            showDatePicker: false,
            showTimePicker: false,
            showUpdateModal: false,
            updateSingle: false,
            updateFolowing: false,
            showDeleteModal: false,

            gotOverlayPossitions: false,
            overlayPossitions: []
        }
    }

    componentDidMount() {
        window.addEventListener("keydown", this.escFunction, false)
        window.addEventListener("resize", throttle(this.setOverlaySize, 100))
        //ReactGA.pageview(window.location.pathname + window.location.search)
        const { user } = this.props
        this.setState({
            eventParticipants: [].concat({
                id: user.id,
                profile_pic_link: user.profile_pic_link,
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email
            }),
            userResponsibleForTask: {
                id: user.id,
                profile_pic_link: user.profile_pic_link,
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email
            },
            googleCalendarId: user.default_google_calendar_id ? user.default_google_calendar_id : 'primary',
            googleCalendarName: user.default_google_calendar_name ? user.default_google_calendar_name : 'Primary',
        })

        if (!this.props.userProjects) {
            this.props.getUserProjects()
        } else if (this.props.events.length === 0) {
            this.props.userProjects.forEach(p => {
                if (!this.props.projectEventsLoaded.includes(p.project_id) && !this.props.projectEventsLoading.includes(p.project_id)) {


                    let filter = {
                        projectId: p.project_id,
                        eventId: -1,
                        startDate: moment(this.state.dateContext).subtract(6, 'month').format('YYYYMMDD HHmm'),
                        endDate: moment(this.state.dateContext).add(6, 'month').format('YYYYMMDD HHmm'),
                    }
                    this.props.getProjectEvents(filter, this.props.projectId)
                }
            })
        }

        if (socket) {
            socket.on('authorizedGoogleCalendar', this.authorizedGoogleCalendarHandler)
        }
    }

    componentDidUpdate(prevProps) {
        ReactTooltip.rebuild()

        if (this.state.editMode && this.state.googleCalendarName === 'Loading...' && this.props.editingGoogleCalendarName) {
            console.log("now should update name")
            this.setState({
                googleCalendarName: this.props.editingGoogleCalendarName
            })
        }

        if (this.props.projectMembers !== prevProps.projectMembers) {

            const { projectMembers } = this.props
            var { eventParticipants } = this.state
            var formatedEventParticipants = []

            if (eventParticipants.length > 0 && !this.isObject(eventParticipants[0])) {
                eventParticipants.forEach(p => {
                    var participantIndex = arrayObjectIndexOf(projectMembers, p, 'id')

                    if (participantIndex > -1) {
                        formatedEventParticipants.push(projectMembers[participantIndex])
                    }
                })

                this.setState({
                    eventParticipants: formatedEventParticipants
                })
            }
        }

        if (!prevProps.userProjects && this.props.userProjects) {
            this.props.userProjects.forEach(p => {
                if (!this.props.projectEventsLoaded.includes(p.project_id) && !this.props.projectEventsLoading.includes(p.project_id)) {


                    let filter = {
                        projectId: p.id,
                        eventId: -1,
                        startDate: moment(this.state.dateContext).subtract(6, 'month').format('YYYYMMDD HHmm'),
                        endDate: moment(this.state.dateContext).add(6, 'month').format('YYYYMMDD HHmm'),
                    }
                    this.props.getProjectEvents(filter, p.id)
                }
            })
        }

        if (this.props.user.role === "SUPERADMIN") {
            if (!this.state.gotOverlayPossitions) {
                const { addTaskRef, tasksRef, calendarRef, googleSyncRef } = this.refs
                const scrollBarWidth = window.innerWidth - document.body.offsetWidth
                if (addTaskRef && tasksRef && calendarRef && googleSyncRef) {
                    var holesToDraw = []

                    var addTaskBox = addTaskRef.getBoundingClientRect()
                    if (addTaskBox) {
                        holesToDraw.push({
                            x: scrollBarWidth === 0 ? addTaskBox.x - 17 : addTaskBox.x + scrollBarWidth,
                            y: addTaskBox.y,
                            w: scrollBarWidth === 0 ? addTaskBox.width + 17 : addTaskBox.width,
                            h: addTaskBox.height
                        })
                    }

                    var tasksBox = tasksRef.getBoundingClientRect()
                    if (tasksBox) {
                        holesToDraw.push({
                            x: tasksBox.x,
                            y: tasksBox.y,
                            w: tasksBox.width - 30,
                            h: tasksBox.height
                        })
                    }

                    var calendarBox = calendarRef.getBoundingClientRect()
                    if (calendarBox) {
                        holesToDraw.push({
                            x: calendarBox.x + scrollBarWidth - 3,
                            y: calendarBox.y,
                            w: calendarBox.width - 20,
                            h: 300
                        })
                    }

                    var googleSyncBox = googleSyncRef.getBoundingClientRect()
                    if (googleSyncBox) {
                        holesToDraw.push({
                            x: googleSyncBox.x + scrollBarWidth - 3,
                            y: googleSyncBox.y,
                            w: googleSyncBox.width,
                            h: googleSyncBox.height
                        })
                    }

                    if (holesToDraw.length === 4) {
                        this.setState({
                            gotOverlayPossitions: true,
                            overlayPossitions: holesToDraw
                        })
                    }
                }
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", throttle(this.setOverlaySize, 100))
        window.removeEventListener("keydown", this.escFunction, false)

        socket.off('authorizedGoogleCalendar', this.authorizedGoogleCalendarHandler)
    }

    escFunction = (e) => {
        if (e.keyCode === 27) {
            const { showGoogleCalendarList, showRepetitionPatterns, suggestionsOpen, showDatePicker, showTimePicker } = this.state
            if (showGoogleCalendarList || showRepetitionPatterns || suggestionsOpen || showDatePicker || showTimePicker) {
                this.closeAllAddEventSuggestions(e)
            } else if (this.state.authorizationModal === 'open') {
                if (this.state.authStep === 'calendars') {
                    if (this.state.defaultCalendar) {
                        this.setState({
                            authorizationModal: 'close',
                            authStep: 'form'
                        })
                        this.props.setDefaultGoogleCalendar(this.state.defaultCalendar)
                    } else {
                        this.props.alertWarn(this.context.t('Choose default calendar first'))
                    }
                } else if (this.state.authStep !== 'note') {
                    this.setState({
                        authorizationModal: 'close',
                        authStep: 'form'
                    })
                }
            } else if (this.state.showDesynchronizeOption) {
                this.setState({ showDesynchronizeOption: false })
            } else if (this.state.showUpdateModal) {
                this.setState({ showUpdateModal: false })
            } else if (this.state.showDeleteModal) {
                this.setState({ showDeleteModal: false })
            } else if (this.state.showEventParticipants) {
                this.setState({
                    showEventParticipants: false,
                    eventParticipants: this.state.backupEventParticipants,
                })
            } else if (this.state.addEventTab === 'open') {
                this.resetState()
            }
        }
    }

    setOverlaySize = () => {
        const { addTaskRef, tasksRef, calendarRef, googleSyncRef } = this.refs
        if (addTaskRef && tasksRef && calendarRef && googleSyncRef) {
            var holesToDraw = []

            var addTaskBox = addTaskRef.getBoundingClientRect()
            if (addTaskBox) {
                holesToDraw.push({
                    x: addTaskBox.x,
                    y: addTaskBox.y,
                    w: addTaskBox.width,
                    h: addTaskBox.height
                })
            }

            var tasksBox = tasksRef.getBoundingClientRect()
            if (tasksBox) {
                holesToDraw.push({
                    x: tasksBox.x,
                    y: tasksBox.y,
                    w: tasksBox.width - 30,
                    h: tasksBox.height
                })
            }

            var calendarBox = calendarRef.getBoundingClientRect()
            if (calendarBox) {
                holesToDraw.push({
                    x: calendarBox.x,
                    y: calendarBox.y,
                    w: calendarBox.width - 20,
                    h: 300
                })
            }

            var googleSyncBox = googleSyncRef.getBoundingClientRect()
            if (googleSyncBox) {
                holesToDraw.push({
                    x: googleSyncBox.x,
                    y: googleSyncBox.y,
                    w: googleSyncBox.width,
                    h: googleSyncBox.height
                })
            }

            if (holesToDraw.length === 4) {
                this.setState({
                    gotOverlayPossitions: true,
                    overlayPossitions: holesToDraw
                })
            }
        }
    }

    isObject = (a) => {
        return (!!a) && (a.constructor === Object);
    };

    getEvents = () => {
        const { userProjects } = this.props
        let filter = {
            where: {
                participants: { regexp: new RegExp(`[\\[,]{1}${this.props.user.id}[,\\]]{1}`, 'i').toString() },
                start_date: {
                    between: [moment().subtract(6, 'month'), moment().add(6, 'month')]
                }
            },
            include: [{
                relation: "project",
                scope: {
                    fields: { code: true, name: true }
                }
            }],
            order: 'start_date ASC'
        }

        console.log(filter)

        this.props.getEvents(`filter=${JSON.stringify(filter)}`)
    }

    onInputChange = e => {
        e.preventDefault()

        const { name, value } = e.target

        this.setState({ [name]: value })
    }

    inputChangeDate = e => {
        if (e.keyCode === 8) {
            this.props.unselectDatePickerDay()
        }
    }

    addEventOpen = () => {
        if (this.state.editMode) {
            this.resetState()
        } else {
            this.setState({
                addEventTab: 'open',
            })
        }
    }

    addEventClose = () => {
        this.setState({
            addEventTab: 'close',
            focusedField: ''
        })
    }

    eventSubmit = e => {
        e.preventDefault()

        this.setState({
            focusedField: ''
        })

        const { today, eventName, eventProjectName, eventProjectId, eventProjectCode, eventStartDateValue, eventStartTime, eventDescription, selectSelf, eventParticipants, userResponsibleForTask, googleCalendarId, eventRepeatPatern, eventToUpdateId, eventToUpdateOriginalId, eventToUpdateOriginalDate, editMode } = this.state

        var date;
        let dateStr = moment(eventStartDateValue).format('YYYY-MM-DD'),
            timeStr = eventStartTime,
            time = moment(timeStr, 'HH:mm');

        date = moment(dateStr)

        date.set({
            hour: time.get('hour'),
            minute: time.get('minute'),
            second: time.get('second')
        });

        if (this.validateEvent()) {
            if (editMode) {
                this.setState({ showUpdateModal: true })
            } else {
                if (!this.props.user.google_calendar_token) {
                    this.props.saveEvent({
                        creatorId: this.props.user.id,
                        projectId: eventProjectId,
                        projectCode: eventProjectCode,
                        name: eventName,
                        description: eventDescription,
                        startDate: moment(date).format("YYYY-MM-DD HH:mm"),
                        timeZone: moment.tz.guess(),
                        participants: JSON.stringify(eventParticipants.map(e => e.id)),
                        userResponsibleForTask: userResponsibleForTask,
                        googleCalendarId: googleCalendarId,
                        repeatPattern: eventRepeatPatern.code,
                    }, {
                        id: eventProjectId,
                        code: eventProjectCode,
                        name: eventProjectName
                    })
                } else {
                    this.props.saveEvent({
                        creatorId: this.props.user.id,
                        projectId: eventProjectId,
                        projectCode: eventProjectCode,
                        name: eventName,
                        description: eventDescription,
                        startDate: moment(date).format("YYYY-MM-DD HH:mm"),
                        timeZone: moment.tz.guess(),
                        participants: JSON.stringify(eventParticipants.map(e => e.id)),
                        userResponsibleForTask: userResponsibleForTask,
                        googleCalendarId: googleCalendarId,
                        repeatPattern: eventRepeatPatern.code,
                    }, {
                        id: eventProjectId,
                        code: eventProjectCode,
                        name: eventProjectName
                    })
                }
                this.resetState()
            }
        }

    }

    uploadChanges = () => {
        const { updateSingle, updateFolowing, eventToUpdateId, eventToUpdateOriginalId, eventToUpdateOriginalDate, eventProjectId, eventProjectCode, eventName, eventStartDateValue, selectSelf, eventParticipants, userResponsibleForTask, eventStartTime, googleCalendarId, eventRepeatPatern, eventDescription, eventToUpdateRecurrence } = this.state

        var date;
        let dateStr = moment(eventStartDateValue).format('YYYY-MM-DD'),
            timeStr = eventStartTime,
            time = moment(timeStr, 'HH:mm');

        date = moment(dateStr)

        date.set({
            hour: time.get('hour'),
            minute: time.get('minute'),
            second: time.get('second')
        });


        this.props.updateEvent({
            id: eventToUpdateId,
            originalEventId: eventToUpdateOriginalId,
            originalDate: moment(eventToUpdateOriginalDate).format('YYYY-MM-DD HH:mm'),
            creatorId: this.props.user.id,
            projectId: eventProjectId,
            projectCode: eventProjectCode,
            name: eventName,
            description: eventDescription,
            startDate: moment(date).format("YYYY-MM-DD HH:mm"),
            timeZone: moment.tz.guess(),
            participants: JSON.stringify(eventParticipants.map(e => e.id)),
            userResponsibleForTask: userResponsibleForTask,
            googleCalendarId: googleCalendarId,
            repeatPattern: eventRepeatPatern.code,
        }, eventToUpdateRecurrence !== 'ONCE')

        this.resetState();
    }

    resetState = () => {
        const { user } = this.props
        this.setState({
            eventName: '',
            eventStartDate: '',
            eventStartDateValue: '',
            eventStartTime: '',
            eventProjectId: '',
            eventProjectCode: '',
            eventProjectName: '',
            eventDescription: '',
            addEventTab: 'closed',
            focusedField: null,
            eventParticipants: [].concat({
                id: user.id,
                profile_pic_link: user.profile_pic_link,
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email
            }),
            userResponsibleForTask: {
                id: user.id,
                profile_pic_link: user.profile_pic_link,
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email
            },
            eventParticipantsPhotos: [],
            selectSelf: true,
            showGoogleCalendarList: false,
            showRepetitionPatterns: false,
            eventRepeat: false,
            eventRepeatPatern: { code: 'ONCE', text: 'One-time Event' },
            editMode: false,
            updateFolowing: false,
            updateSingle: false,
            showUpdateModal: false,

            eventToUpdateOriginalId: null,
            eventToUpdateRecurrence: null,
            eventToUpdateOriginalDate: null,
            eventToUpdateId: null,
            eventToDeleteId: null,
            eventToDeleteRecurrence: null,
        })
    }

    validateEvent = () => {
        const { today, eventName, eventProjectName, eventProjectId, eventProjectCode, eventStartDateValue, eventStartTime, eventDescription, selectSelf, eventParticipants, googleCalendarId, eventRepeatPatern, eventToUpdateId, eventToUpdateOriginalId, eventToUpdateOriginalDate, editMode } = this.state
        console.log(this.state)
        let date

        let vaild = true

        if (eventName.length === 0) {
            vaild = false
            this.props.alertWarn(this.context.t('Name is required'))
        }

        if (eventStartDateValue.length === 0) {
            vaild = false
            this.props.alertWarn(this.context.t('Start date is required'))
        }

        if (eventStartTime.length === 0) {
            vaild = false
            this.props.alertWarn(this.context.t('Start time is required'))
        } else {
            var timeElements = eventStartTime.split(':')
            if (timeElements.length != 2) {
                vaild = false
                this.props.alertWarn(this.context.t('Start time is invalid'))
            } else if (parseInt(timeElements[0]) > 23 || parseInt(timeElements[0]) < 0) {
                vaild = false
                this.props.alertWarn(this.context.t('Hour should range form 0 to 23'))
            } else if (parseInt(timeElements[1]) > 59 || parseInt(timeElements[1]) < 0) {
                vaild = false
                this.props.alertWarn(this.context.t('Minutes should range form 0 to 59'))
            } else {
                let dateStr = moment(eventStartDateValue).format('YYYY-MM-DD'),
                    timeStr = eventStartTime,
                    time = moment(timeStr, 'HH:mm');

                console.log("time moment" + time.format("HH:mm"))

                date = moment(dateStr)

                date.set({
                    hour: time.get('hour'),
                    minute: time.get('minute'),
                    second: time.get('second')
                });
                console.log("date moment: " + date.format("HH:mm"))
            }
        }

        if (!selectSelf && eventParticipants.length === 0) {
            vaild = false
            this.props.alertWarn(this.context.t('Event has to have at least one participant.'))
        }

        if (!moment(date).isAfter(today)) {
            vaild = false
            this.props.alertWarn(this.context.t('You can\'t go back in time.'))
        }

        if (eventProjectName.length === 0 || eventProjectId.length === 0) {
            vaild = false
            this.props.alertWarn(this.context.t('Binder is required. Please choose one from list.'))
        }

        if (eventDescription.length === 0) {
            vaild = false
            this.props.alertWarn(this.context.t('Description is required'))
        }

        if (eventProjectId) {
            var projectToCreateEventIn = this.props.userProjects.find(p => p.id === eventProjectId)
            if (projectToCreateEventIn) {
                if (moment(projectToCreateEventIn.company.subscription_expire_date).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
                    vaild = false
                    this.props.alertWarn(this.context.t('This company\'s subscription has expired. Send it or inform the company administrator about it to continue using EasyDocs'))
                }
            } else {
                vaild = false
            }
        }

        return vaild
    }

    editEvent = (id, recurrence) => {
        const { events } = this.props
        console.log(recurrence)

        console.log(events)
        var eventToEdit = events.find(e => e.id === id);
        console.log(events)
        this.props.getProjectMembers(eventToEdit.project.id)
        if (eventToEdit.google_calendar_id !== 'primary') {
            this.props.getGoogleCalendarName(eventToEdit.google_calendar_id)
        }


        const { projectMembers } = this.props
        var eventParticipants = JSON.parse(eventToEdit.participants)
        var formatedEventParticipants = []

        if (projectMembers.length > 0) {
            console.log(eventParticipants)
            console.log(projectMembers)

            eventParticipants.forEach(p => {
                var participantIndex = arrayObjectIndexOf(projectMembers, p, 'id')
                console.log(participantIndex)
                if (participantIndex > -1) {
                    formatedEventParticipants.push(projectMembers[participantIndex])
                }
            })

            console.log(formatedEventParticipants)
        } else {
            formatedEventParticipants = eventParticipants
        }


        this.setState({
            eventToUpdateId: eventToEdit.id,
            eventToUpdateRecurrence: recurrence,
            eventToUpdateOriginalId: eventToEdit.prev_occurenc_id,
            eventToUpdateOriginalDate: moment(eventToEdit.start_date),
            editMode: true,
            eventName: eventToEdit.name,
            eventStartDate: moment(eventToEdit.start_date).format("LL"),
            eventStartDateValue: moment(eventToEdit.start_date),
            eventStartTime: moment(eventToEdit.start_date).utc().format("HH:mm"),
            eventProjectId: eventToEdit.project.id,
            eventProjectCode: eventToEdit.project.code,
            eventProjectName: eventToEdit.project.name,
            eventDescription: eventToEdit.note,
            addEventTab: 'open',
            focusedField: null,
            eventParticipants: formatedEventParticipants,
            userResponsibleForTask: eventToEdit.userResponsibleForTask,
            eventParticipantsPhotos: [],
            selectSelf: true,
            showGoogleCalendarList: false,
            googleCalendarName: eventToEdit.google_calendar_id === 'primary' ? 'Primary' : 'Loading...',
            googleCalendarId: eventToEdit.google_calendar_id,
            showRepetitionPatterns: false,
            eventRepeat: eventToEdit.recurrence === 'ONCE' ? false : true,
            eventRepeatPatern: { code: eventToEdit.recurrence, text: this.resolveReccurenceCode(eventToEdit.recurrence) }
        })

        console.log(eventToEdit)
    }

    showUpdateModal = () => {
        this.setState({
            showUpdateModal: true
        })
    }

    checkOptionUpload = (name, value) => {
        console.log(name, value)
        if (!value) {
            this.setState({
                updateSingle: value,
                updateFolowing: value
            })
        }
        else if (name === "updateSingle") {
            this.setState({
                updateSingle: value,
                updateFolowing: !value
            })
        } else {
            this.setState({
                updateSingle: !value,
                updateFolowing: value
            })
        }
    }

    checkOptionDelete = (name, value) => {
        console.log(name, value)
        if (!value) {
            this.setState({
                deleteSingle: value,
                deleteFolowing: value
            })
        }
        else if (name === "deleteSingle") {
            this.setState({
                deleteSingle: value,
                deleteFolowing: !value
            })
        } else {
            this.setState({
                deleteSingle: !value,
                deleteFolowing: value
            })
        }
    }

    resolveReccurenceCode = pattern => {
        if (!pattern) return null
        else if (pattern === 'ONCE') return 'One-time Event'
        else if (pattern === 'WEEKLY') return 'Every week'
        else if (pattern === 'BIWEEKLY') return 'Every second week'
        else if (pattern === 'MONTHLY') return 'Every month'
        else if (pattern === 'QUATERLY') return 'Every quarter'
        else if (pattern === 'HALFYEAR') return 'Every six months'
        else if (pattern === 'YEAR') return 'Every year'
        else if (pattern === 'LAST_WORKING_DAY_OF_MONTH') return 'Last working day each the month'
        else if (pattern === 'PENULTIMATE_WORKING_DAY_OF_MONTH') return 'Penultimate working day of each month'
        else if (pattern === 'LAST_DAY_OF_MONTH') return 'Last day of each month'
        else if (pattern === 'PENULTIMATE_DAY_OF_MONTH') return 'Penultimate day of each month'
        else return null
    }

    setFocus = (e, name) => {
        if (e.target && e.target.placeholder) {
            e.target.placeholder = ''
        }
        this.setState({
            focusedField: name,
            showDatePicker: (name === 'eventStartDate')
        })
    }

    unsetFocus = () => {
        this.setState({
            focusedField: ''
        })
    }

    setTextareaFocus = () => {
        this.setState({
            textareaFocus: true
        })
    }

    setTextareaBlur = (e) => {
        e.target.placeholder = this.context.t("e.g. Monthly summary")
        this.setState({
            textareaFocus: false
        })
    }

    getCalendarAuthorizationLink = () => {
        this.setState({
            authorizationModal: 'open'
        })
        this.props.getCalendarAuthorizationLink(this.props.user.id)
    }

    sendCode = e => {
        e.preventDefault()

        const { authCode } = this.state

        if (authCode.length === 0) {
            this.props.alertWarn(this.context.t('Code is required'))
        } else {
            this.setState({
                authStep: 'note'
            })

            this.props.authorizeGoogleCalendar(authCode, this.props.user.id)

            this.setState({
                authCode: ''
            })
        }
    }

    authorizedGoogleCalendarHandler = (data) => {
        console.log(data)
        this.setState({
            authStep: 'note'
        })
        this.props.authorizedGoogleCalendarFromSocket(data.user, data.calendars)
    }

    closeAuthModal = () => {
        const { authStep, defaultCalendar } = this.state
        if (authStep === 'calendars') {
            if (defaultCalendar) {
                this.setState({
                    authorizationModal: 'close',
                    authStep: 'form',
                    googleCalendarName: defaultCalendar.summary,
                    googleCalendarId: defaultCalendar.id,
                })

                this.props.setDefaultGoogleCalendar(defaultCalendar)

                //TODO
            } else {
                this.props.alertWarn(this.context.t('Choose default calendar first'))
            }
        } else {
            this.setState({
                authorizationModal: 'close',
                authStep: 'form'
            })
        }
    }

    createNewGoogleCalendar = () => {
        const { newDefaultCalendar } = this.state

        if (!newDefaultCalendar || newDefaultCalendar.length === 0) {
            this.props.alertWarn(this.context.t('New calendar\'s name is required'))
        } else {
            this.props.createNewGoogleCalendar(newDefaultCalendar)
            this.setState({
                authorizationModal: 'close',
                authStep: 'form'
            })
        }
    }

    loadSugestionsProject = (e, value) => {
        e.target.placeholder = ''

        const inputValue = value !== null ? value : e.target.value
        const target = 'eventProjectName'

        console.log(value)
        console.log(inputValue, target)
        let suggestions = this.props.userProjects

        this.setState({
            eventProjectsSuggestions: suggestions,
            [target]: inputValue,
            suggestionsOpen: true,
        })
    }

    renderSuggestionsProjects = () => {
        const suggestions = this.state.eventProjectsSuggestions
        if (suggestions.length > 0) {
            return (
                <ul className="suggestions-list">
                    <SimpleBar style={{ height: 'auto', maxHeight: '40vh', width: '100%', paddongLeft: '25px' }}>
                        {
                            suggestions.map((project, index) =>
                                <li key={index} onClick={e => this.chooseSuggestionProject(e, project.name, project.id, project.code)}>
                                    {project.name}
                                </li>
                            )
                        }
                    </SimpleBar>
                </ul>
            )
        } else {
            return null
        }
    }

    chooseSuggestionProject = (e, name, id, code) => {
        const { user } = this.props
        e.preventDefault();
        this.setState({
            eventProjectName: name,
            eventProjectId: id,
            eventProjectCode: code,
            eventProjectsSuggestions: [],
            suggestionsOpen: false,
            eventParticipants: [].concat({
                id: user.id,
                profile_pic_link: user.profile_pic_link,
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email
            }),
            userResponsibleForTask: {
                id: user.id,
                profile_pic_link: user.profile_pic_link,
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email
            },
            selectSelf: true,
        })
        this.props.getProjectMembers(id)
    }

    renderSuggestionsRepeatingEvents = () => {
        const suggestions = this.state.possibleEventsRepeatPaterns
        if (this.state.showRepetitionPatterns) {
            return (
                <ul className="suggestions-list">
                    {
                        suggestions.map((pattern, index) =>
                            <li key={index} onClick={e => this.chooseSuggestionPattern(e, pattern)}>{this.context.t(pattern.text)}</li>
                        )
                    }
                </ul>
            )
        } else {
            return null
        }
    }

    // checkIfTryToUpdate = (e) => {
    //     if(this.state.eventToUpdateId && !this.state.eventRepeat) { 
    //         this.props.alertWarn(this.context.t("You can't change evente repeat pattern if it's one time event"))
    //     }
    // }

    chooseSuggestionPattern = (e, pattern) => {
        console.log(pattern)
        e.preventDefault();
        this.setState({
            eventRepeatPatern: pattern,
            eventRepeat: (pattern.code !== 'ONCE'),
            showRepetitionPatterns: false
        })

        if (pattern.code === 'LAST_WORKING_DAY_OF_MONTH') {
            var eventNextDate = moment().endOf('month')
            var dayNumber = eventNextDate.isoWeekday()
            if (dayNumber > 5) {
                eventNextDate.subtract(dayNumber - 5, 'days')
            }
            this.setDate(eventNextDate.format('LL'), eventNextDate)
            this.props.datePickerSelect(eventNextDate)
        } else if (pattern.code === "PENULTIMATE_WORKING_DAY_OF_MONTH") {
            var eventNextDate = moment().endOf('month')
            var dayNumber = eventNextDate.isoWeekday()
            if (dayNumber > 5) {
                eventNextDate.subtract(dayNumber - 5, 'days')
            }
            eventNextDate.subtract(1, 'days')
            dayNumber = eventNextDate.isoWeekday()
            if (dayNumber > 5) {
                eventNextDate.subtract(dayNumber - 5, 'days')
            }
            this.setDate(eventNextDate.format('LL'), eventNextDate)
            this.props.datePickerSelect(eventNextDate)
        } else if (pattern.code === 'LAST_DAY_OF_MONTH') {
            var eventNextDate = moment().endOf('month')
            this.setDate(eventNextDate.format('LL'), eventNextDate)
            this.props.datePickerSelect(eventNextDate)
        } else if (pattern.code === 'PENULTIMATE_DAY_OF_MONTH') {
            var eventNextDate = moment().endOf('month')
            eventNextDate.subtract(1, 'days')
            this.setDate(eventNextDate.format('LL'), eventNextDate)
            this.props.datePickerSelect(eventNextDate)
        }
    }

    getGoogleCalendarsList = () => {
        if (!this.state.eventToUpdateId) {
            this.setState({
                showGoogleCalendarList: true
            })
            this.props.getGoogleCalendarsList(this.props.user.id)
        } else {
            this.props.alertWarn(this.context.t('You can\'t change to this property'))
        }

    }

    renderSuggestionsCalendars = () => {
        const { calendarList } = this.props

        if (this.state.showGoogleCalendarList) {
            if (!calendarList) {
                return null
            } else if (calendarList.length === 0) {
                return (
                    <div className="downloading-calendar-list">
                        <div className="activity-indicator">
                            <Digital />
                        </div>
                    </div>
                )
            } else {
                return (
                    <ul className="suggestions-list">
                        {calendarList.map((calendar, index) => <li key={index} onClick={e => this.chooseGoogleCalendar(e, calendar)}>{calendar.primary ? 'Primary' : calendar.summary}</li>)}
                    </ul>
                )
            }
        }
    }

    chooseGoogleCalendar = (e, calendar) => {
        if (calendar.primary) {
            this.setState({
                googleCalendarId: 'primary',
                googleCalendarName: 'Primary',
                showGoogleCalendarList: false

            })
        } else {
            this.setState({
                googleCalendarId: calendar.id,
                googleCalendarName: calendar.summary,
                showGoogleCalendarList: false
            })
        }
    }

    hideProjectSuggestions = e => {
        e.preventDefault()
        this.setState({
            eventProjectsSuggestions: [],
            suggestionsOpen: false,
        })
    }

    changeAddEventStep = (name) => {
        this.setState({
            addEventStep: name
        })
    }

    toggleParticipant = (participant) => {
        var { eventParticipants, eventParticipantsType } = this.state
        if (eventParticipantsType === 'responsible') {
            this.setState({
                userResponsibleForTask: participant,
                showEventParticipants: false,
            })
        } else {
            if (participant.id !== this.props.user.id) {
                if (eventParticipants.find(e => e.id === participant.id)) {
                    console.log('participant not selected yet')
                    this.setState({
                        eventParticipants: eventParticipants.filter(e => e.id !== participant.id)
                    })
                } else {
                    console.log('participant already selected')
                    this.setState({
                        eventParticipants: eventParticipants.concat(participant),
                    })
                }
            } else {
                this.props.alertWarn(this.context.t('As creater you can not unselect yourself'))
            }
        }
    }

    selectAllMembers = () => {
        this.setState({
            eventParticipants: this.props.projectMembers.map(e => e.id).filter(e => e !== this.props.user.id)
        })
    }

    getReccurenceText = pattern => {
        if (!pattern) return null
        else if (pattern === 'WEEKLY') return '(every week)'
        else if (pattern === 'BIWEEKLY') return '(every second week)'
        else if (pattern === 'MONTHLY') return '(every month)'
        else if (pattern === 'QUATERLY') return '(every quater)'
        else if (pattern === 'HALFYEAR') return '(every six months)'
        else if (pattern === 'YEAR') return '(every year)'
        else if (pattern === 'LAST_WORKING_DAY_OF_MONTH') return '(last working day of each month)'
        else if (pattern === 'PENULTIMATE_WORKING_DAY_OF_MONTH') return '(penultimate working day of each month)'
        else if (pattern === 'LAST_DAY_OF_MONTH') return '(last day of each month)'
        else if (pattern === 'PENULTIMATE_DAY_OF_MONTH') return '(penultimate day of each month)'
        else return null
    }

    hideDatePicker = e => {
        this.setState({
            showDatePicker: false
        })
    }

    showDeleteModal = (id, recurrence) => {
        console.log(recurrence)
        this.setState({
            showDeleteModal: true,
            eventToDelete: id,
            eventToDeleteReccurence: recurrence,
        })
    }

    deleteEvent = () => {
        this.setState({
            showDeleteModal: false
        })
        this.props.deleteEvent(this.state.eventToDelete, this.state.eventToDeleteReccurence !== 'ONCE')
    }

    changeMonthOnMainCalendar = (date) => {
        if (moment(date).format('M') !== moment().format('M')) {
            moment(date).set("date", 1).set('hour', 0).set('minute', 0)
            this.setState({
                mainCalendarDate: moment(date).set("date", 1).set('hour', 0).set('minute', 0)
            })
        } else {
            this.setState({
                mainCalendarDate: date
            })
        }

    }

    toggleDesynchronizeOption = () => {
        var { showDesynchronizeOption } = this.state
        this.setState({
            showDesynchronizeOption: !showDesynchronizeOption
        })
    }

    revokeGoogleCalendarAccess = () => {
        this.props.revokeGoogleCalendarAccess()
        this.setState({
            showDesynchronizeOption: false
        })
    }

    closeAllAddEventSuggestions = e => {
        if (e.keyCode === 27 || !(e.target instanceof HTMLLIElement)) {
            if (e.keyCode === 27 || (e.target.id !== 'googleCalendarName' && this.state.showGoogleCalendarList)) {
                this.setState({
                    showGoogleCalendarList: false,
                })
            }
            if (e.keyCode === 27 || (e.target.id !== 'eventRepeatPatern' && this.state.showRepetitionPatterns)) {
                this.setState({
                    showRepetitionPatterns: false,
                })
            }
            if (e.keyCode === 27 || (e.target.id !== 'eventProjectName' && this.state.eventProjectsSuggestions.length > 0)) {
                this.setState({
                    suggestionsOpen: false,
                    eventProjectsSuggestions: []
                })
            }
        }

        if (e.keyCode === 27 || (!this.hasAncestor(e.target, "date-picker-cintainer") && this.state.showDatePicker)) {
            this.setState({
                showDatePicker: false
            })
        }

        if (e.keyCode === 27 || (!this.hasAncestor(e.target, "time-picker-container") && this.state.showTimePicker)) {
            this.setState({
                showTimePicker: false
            })
        }
    }

    hasAncestor = (element, id) => {
        while (element) {
            if (element.id === id) return true

            element = element.parentNode
        }

        return false
    }

    setTime = (hour, minutes) => {
        this.setState({
            eventStartTime: hour + ":" + minutes,
            eventStartHour: hour,
            eventStartMinute: minutes,
        })
    }

    setDate = (display, value) => {
        this.setState({
            eventStartDate: display || '',
            eventStartDateValue: value || ''
        })
    }

    convertToMembersPhotos = (members, type) => {
        var maxDisplayedPhotos = 3
        var displayNumberOfAdditionalMembers = false
        var photosToReturn = [], additionalMembersNames = []

        var membersCount = members.length
        var helperDivIndex = membersCount

        if (membersCount > maxDisplayedPhotos) helperDivIndex = maxDisplayedPhotos + 1

        members.forEach((m, i) => {
            if (i < maxDisplayedPhotos) {
                photosToReturn.push(
                    <div className={`member number-${(helperDivIndex - i)} ${m.profile_pic_link ? '' : 'default'}`} data-for={`new-event-member-${m.id}-${type}`} data-tip='show'>
                        {
                            m.profile_pic_link ? (
                                <img src={m.profile_pic_link} alt={m.name} />
                            ) : (
                                // <img src={require('../../../../img/default-user-photo.svg')} alt={m.name}/>
                                null
                            )
                        }
                    </div>
                )

                photosToReturn.push(
                    <ReactTooltip id={`new-event-member-${m.id}-${type}`} className="default-tooltip event-members-tooltip" place="top" effect='solid'>
                        <p>{m.first_name ? (m.first_name + ' ' + m.last_name) : m.email}</p>
                    </ReactTooltip>
                )
            } else {
                displayNumberOfAdditionalMembers = true
                additionalMembersNames.push(
                    m.first_name ? (m.first_name + ' ' + m.last_name) : m.email
                )
            }
        })

        if (displayNumberOfAdditionalMembers) {
            photosToReturn.push(
                <div className={`member number-1 additional-members`} data-for={`additional-new-event-members`} data-tip='show'>
                    {`+${membersCount - maxDisplayedPhotos}`}
                </div>
            )

            photosToReturn.push(
                <ReactTooltip id={`additional-new-event-members`} className="default-tooltip event-members-tooltip" place="top" effect='solid'>
                    {
                        additionalMembersNames.map(m => {
                            return (<p>{m}</p>)
                        })
                    }
                </ReactTooltip>
            )
        }

        return photosToReturn
    }

    showProjectMembers = type => {
        const { user } = this.props
        const { eventProjectId } = this.state
        if (user.google_calendar_token && eventProjectId) {
            this.setState({
                showEventParticipants: true,
                backupEventParticipants: this.state.eventParticipants,
                eventParticipantsType: type
            })
        } else {
            return null
        }
    }

    selectAllForEvent = () => {
        var { eventParticipants } = this.state
        const { projectMembers } = this.props

        projectMembers.forEach(m => {
            if (!eventParticipants.find(e => e.id === m.id)) {
                eventParticipants.push(m)
            }
        })

        this.setState({
            eventParticipants: eventParticipants
        })
    }

    selectCalendarAsDefault = (calendar) => {
        const { defaultCalendar } = this.state

        if (defaultCalendar && defaultCalendar.id === calendar.id) {
            this.setState({
                defaultCalendar: null
            })
        } else {
            this.setState({
                defaultCalendar: calendar
            })
        }
    }

    convertToMembersPhotosEventsList = (members, eventId) => {
        if (members) {
            var maxDisplayedPhotos = 5
            var numberOfDisplayedPhotos = 0
            var displayNumberOfAdditionalMembers = false
            var additionalMembersNames = []
            var photosToReturn = []

            var membersCount = members.length
            var helperDivIndex = membersCount

            if (membersCount > maxDisplayedPhotos) helperDivIndex = maxDisplayedPhotos + 1

            members.forEach((m, i) => {
                if (i < maxDisplayedPhotos) {
                    if (m.profilePic) {
                        photosToReturn.push(
                            <div className={`user number-${(helperDivIndex - i)}`} data-for={`member-${m.id}-${eventId}`} data-tip='show'>
                                <img src={m.profilePic} alt={m.firstName ? (m.firstName + ' ' + m.lastName) : m.email} />
                            </div>
                        )
                        photosToReturn.push(
                            <ReactTooltip id={`member-${m.id}-${eventId}`} className="default-tooltip event-members-tooltip" place="top" effect='solid'>
                                <p>{m.firstName ? (m.firstName + ' ' + m.lastName) : m.email}</p>
                            </ReactTooltip>
                        )
                    } else {
                        photosToReturn.push(
                            <div className={`user number-${(helperDivIndex - i)} initials`} data-for={`member-${m.id}-${eventId}`} data-tip='show'>
                                {
                                    m.firstName && m.lastName ? (
                                        m.firstName[0] + m.lastName[0]
                                    ) : (
                                        m.email[0]
                                    )
                                }
                            </div>
                        )
                        photosToReturn.push(
                            <ReactTooltip id={`member-${m.id}-${eventId}`} className="default-tooltip event-members-tooltip" place="top" effect='solid'>
                                <p>{m.firstName ? (m.firstName + ' ' + m.lastName) : m.email}</p>
                            </ReactTooltip>
                        )
                    }
                } else {
                    displayNumberOfAdditionalMembers = true

                    additionalMembersNames.push(
                        m.firstName ? (m.firstName + ' ' + m.lastName) : m.email
                    )
                }
            })

            if (displayNumberOfAdditionalMembers) {
                photosToReturn.push(
                    <div className={`user number-1 additional-members`} data-for={`additional-members-${eventId}`} data-tip='show'>
                        {`+${membersCount - maxDisplayedPhotos}`}
                    </div>
                )

                photosToReturn.push(
                    <ReactTooltip id={`additional-members-${eventId}`} className="default-tooltip event-members-tooltip" place="top" effect='solid'>
                        {
                            additionalMembersNames.map(m => {
                                return (<p>{m}</p>)
                            })
                        }
                    </ReactTooltip>
                )
            }

            return photosToReturn
        } else {
            return null
        }
    }

    showOverlay = () => {
        const { overlayPreferance } = this.props
        this.setOverlaySize()
        overlayPreferance['calendar'] = true
        this.props.toggleOverlay(overlayPreferance)
    }

    render() {
        const { user, calendarList, selectedDay, events, authorizedGoogleCalendar, calendarAuthorizationMessage, calendarAuthorizationErrorReason, projectMembers, projectId, nearestEvent, datePickerDay, currentMonth } = this.props
        const { defaultCalendar, newDefaultCalendar, mainCalendarDate, eventName, eventStartDate, eventStartDateValue, eventStartTime, eventStartHour, eventStartMinute, eventProjectName, eventProjectId, eventProjectsSuggestions, eventDescription, textareaFocus, addEventTab, addEventStep, authorizationModal, authCode, authStep, today, selectSelf, eventParticipants, userResponsibleForTask, showRepetitionPatterns, eventRepeatPatern, showGoogleCalendarList, googleCalendarName, googleCalendarId, focusedField, editMode, showDatePicker, showTimePicker, showUpdateModal, updateSingle, updateFolowing, showDeleteModal, deleteSingle, deleteFolowing, eventToDeleteReccurence, eventToUpdateRecurrence, showDesynchronizeOption, showEventParticipants, eventParticipantsType } = this.state

        var eventsCount = 0

        return (
            <div className="calendar-page-container">
                {/* <AvailableInOtherPlanLabel planName='PRO+' labelSize="FULL" company={null} checkAllCompanies={true} location={'CALENDAR'} /> */}
                <div className='show-overlay' onClick={() => this.showOverlay()} data-for="info-context-overlay" data-tip="show"></div>

                {
                    this.state.gotOverlayPossitions && this.props.overlayPreferance['calendar'] ? (
                        <OverlayInstructions possitions={this.state.overlayPossitions} name="calendar" height={window.innerHeight} />
                    ) : (
                        null
                    )
                }

                <div className="page-header calendar">
                    <div className="page-name calendar">
                        {this.context.t('Calendar')}
                    </div>
                    <button className="add-event" onClick={() => this.addEventOpen()} ref="addTaskRef">
                        {this.context.t('Add new task')}
                    </button>
                </div>

                {
                    authorizationModal === 'open' ? (
                        <div className="popup google-authorization-popup">
                            <div className={`card ${authStep === 'form' ? 'authentication-code' : 'final-info'}`}>
                                <div className="calendar-logo"></div>
                                {
                                    authStep === 'form' ? (
                                        <>
                                            <div className="auth-info">
                                                {this.context.t('You will be automatically redirected to the Google website. To connect to the calendar, select an account and give EasyDocs the permission to view and edit Google events and calendars.')}
                                            </div>
                                        </>
                                    ) : (
                                        authStep === 'note' ? (
                                            authorizedGoogleCalendar === true ? (
                                                <div className="note">
                                                    <div className="description">
                                                        {this.context.t('Your Google Calendar is now connected with EasyDocs. All events added through EasyDocs will automatically be added to your Calendar as well.')}
                                                    </div>
                                                    <div className="disclaimer">
                                                        {this.context.t('*Events added through Google Calendar won\'t be displayed here.')}
                                                    </div>
                                                    <div className="action-buttons">
                                                        <button className="btn accept-button" onClick={() => this.setState({ authStep: 'calendars' })}>{this.context.t('Continue')}</button>
                                                    </div>
                                                </div>
                                            ) : (
                                                calendarAuthorizationMessage ? (
                                                    <div className="note">
                                                        <div className="description">{this.context.t(calendarAuthorizationMessage)}</div>
                                                        {
                                                            calendarAuthorizationErrorReason === 'insufficientPermissions' ? (
                                                                <div className="additional-error-info">{this.context.t('*If you want to synchronize EasyDocs with Google Calendar, you must select both checkboxes at the stage of consenting to EasyDocs access to your data on Google.')}</div>
                                                            ) : (
                                                                null
                                                            )
                                                        }
                                                        <div className="action-buttons">
                                                            <button className="btn accept-button" onClick={() => this.closeAuthModal()}>{this.context.t('Continue')}</button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="note">
                                                        <div className="activit-indicator-placeholder"></div>
                                                        <div className="activity-indicator">
                                                            <Spinner size={40} speed={0.8} color={"#444444"} />
                                                        </div>
                                                    </div>
                                                )
                                            )
                                        ) : (
                                            authStep === 'calendars' ? (
                                                <div className="note">
                                                    <div className="description left">
                                                        {this.context.t('Choose your default calendar to save events:')}
                                                    </div>
                                                    <div className="google-calendars">
                                                        {
                                                            calendarList && calendarList.length > 0 ? (
                                                                calendarList.map(cal => {
                                                                    return (
                                                                        <div className="google-calendar-item" onClick={() => this.selectCalendarAsDefault(cal)}>
                                                                            <div className={`checkbox ${defaultCalendar && defaultCalendar.id === cal.id ? 'checked' : ''}`}></div>
                                                                            {cal.primary ? 'Primary' : cal.summary}
                                                                        </div>
                                                                    )
                                                                })
                                                            ) : (
                                                                null
                                                            )
                                                        }
                                                    </div>

                                                    <div className="create-google-calendar">
                                                        <input type="text" name="newDefaultCalendar" id="newDefaultCalendar" value={newDefaultCalendar} onChange={this.onInputChange} placeholder={this.context.t('e.g. EasyDocs')} />
                                                        <label htmlFor="newDefaultCalendar">{this.context.t('Create new calendar for EasyDocs')}</label>
                                                        <div className="create-new-calendar" onClick={() => this.createNewGoogleCalendar()}></div>
                                                    </div>

                                                    <div className="disclaimer long">
                                                        {this.context.t('*Only events that you create will be saved in this calendar. Events created by someone else in whitch you will be a participant might be visible in your primary google calendar.')}
                                                    </div>

                                                    <div className="action-buttons">
                                                        <button className={`btn accept-button ${!defaultCalendar ? 'blocked' : ''}`} onClick={() => this.closeAuthModal()}>{this.context.t('Continue')}</button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="note">
                                                    <div className="activit-indicator-placeholder"></div>
                                                    <div className="activity-indicator">
                                                        <Spinner size={40} speed={0.8} color={"#444444"} />
                                                    </div>
                                                </div>
                                            )
                                        )
                                    )
                                }
                            </div>
                        </div>
                    ) : (
                        null
                    )
                }

                {
                    showUpdateModal ? (
                        <div className="popup event-update-popup">
                            <div className={`card`}>
                                <div className="header">
                                    {
                                        this.state.eventToUpdateRecurrence === 'ONCE' ? (
                                            this.context.t('Are you sure you want to edit this event?')
                                        ) : (
                                            this.context.t('Are you sure you want to edit this and all recuring events?')
                                        )
                                    }
                                </div>
                                <div className="action-buttons">
                                    <span className="btn btn-cancel" onClick={e => this.setState({ showUpdateModal: false })}>{this.context.t("Cancel")}</span>
                                    <span className="btn btn-confirm" onClick={e => this.uploadChanges()}>{this.context.t("Update")}</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        null
                    )
                }

                {
                    showDeleteModal ? (
                        <div className="popup event-delete-popup">
                            <div className={`card`}>
                                <div className="header">
                                    {
                                        this.state.eventToDeleteReccurence === 'ONCE' ? (
                                            this.context.t('Are you sure you want to delete this event?')
                                        ) : (
                                            this.context.t('Are you sure you want to delete this and all recuring events?')
                                        )
                                    }
                                </div>
                                <div className="action-buttons">
                                    <span className="btn btn-cancel" onClick={e => this.setState({ showDeleteModal: false })}>{this.context.t("Cancel")}</span>
                                    <span className="btn btn-confirm" onClick={e => this.deleteEvent()}>{this.context.t("Delete")}</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        null
                    )
                }

                {
                    addEventTab === 'open' ? (
                        <div className="popup add-event-popup" onClick={(e) => this.closeAllAddEventSuggestions(e)}>
                            <div className="card">
                                <h3>{this.context.t('Create Task')}</h3>
                                <form onSubmit={this.eventSubmit}>
                                    {
                                        user.google_calendar_token ? (
                                            <div className="form-group z-index-100 half margin-r">
                                                <input type="text" name="googleCalendarName" id="googleCalendarName" value={googleCalendarName} onFocus={e => this.getGoogleCalendarsList()} autoComplete="off" readOnly />
                                                <label htmlFor="googleCalendarName">{this.context.t('Google Calendar')}</label>
                                                <span className={`expend-icon ${showGoogleCalendarList ? 'expended' : ''}`} onClick={() => this.getGoogleCalendarsList()}></span>
                                                {this.renderSuggestionsCalendars()}
                                            </div>
                                        ) : (
                                            null
                                        )
                                    }

                                    <div className={`form-group z-index-100 ${user.google_calendar_token ? 'half' : ''}`}>
                                        <input type="text" name="eventRepeatPatern" id="eventRepeatPatern" value={this.context.t(eventRepeatPatern.text)} onFocus={e => { this.setState({ showRepetitionPatterns: true }) }} autoComplete="off" readOnly />
                                        <label htmlFor="eventRepeatPatern">{this.context.t('Repeat event')}</label>
                                        <span className={`expend-icon ${showRepetitionPatterns ? 'expended' : ''}`} onClick={() => this.setState({ showRepetitionPatterns: !this.state.showRepetitionPatterns })}></span>
                                        {this.renderSuggestionsRepeatingEvents()}
                                    </div>

                                    <div className="form-group z-index-99">
                                        <input type="text" name="eventProjectName" id="eventProjectName" value={eventProjectName} readOnly={true} onFocus={e => this.loadSugestionsProject(e, null)} autoComplete="off" onClick={e => { this.unsetFocus(e); this.loadSugestionsProject(e, null) }} placeholder={this.context.t("Select from list")} onBlur={e => e.target.placeholder = this.context.t("Select from list")} />
                                        <label htmlFor="eventProjectName">{this.context.t('Binder')}</label>
                                        <span className={`expend-icon ${this.state.eventProjectsSuggestions && this.state.eventProjectsSuggestions.length > 0 ? 'expended' : ''}`} onClick={(e) => this.loadSugestionsProject(e, eventProjectName)}></span>
                                        {this.renderSuggestionsProjects()}
                                    </div>

                                    <div className="form-group">
                                        <input type="text" name="eventName" id="eventName" value={eventName} onChange={this.onInputChange} autoComplete="off" placeholder={this.context.t("e.g. Meeting 1")} onFocus={e => e.target.placeholder = ""} onBlur={e => e.target.placeholder = this.context.t("e.g. Meeting 1")} />
                                        <label htmlFor="eventName">{this.context.t('Title')}</label>
                                    </div>

                                    <div id="date-picker-cintainer" className="form-group half margin-r">
                                        <input type="text" name="eventStartDate" id="eventStartDate" ref="eventStartDateInput" value={eventStartDate} onKeyDown={e => this.inputChangeDate(e)} onFocus={(e) => this.setFocus(e, 'eventStartDate')} autoComplete="off" placeholder={this.context.t("e.g. ") + moment(today).format("LL")} />
                                        <label htmlFor="eventStartDate">{this.context.t('Start date')}</label>
                                        <span className="calendar-icon" onClick={(e) => this.setFocus(e, 'eventStartDate')}></span>
                                        {
                                            showDatePicker ? (
                                                <div className={`date-picker ${showDatePicker ? 'show' : ''}`}>
                                                    <Calendar sendDate={this.setDate} selectDay={this.props.datePickerSelect} unselectDay={this.props.unselectDatePickerDay} selectedDay={datePickerDay} projectId={'none'} startDate={eventStartDateValue || mainCalendarDate} />
                                                    <TimePicker sendHour={this.setTime} hour={eventStartHour} minutes={eventStartMinute} />
                                                    <div className="button">
                                                        <button onClick={() => this.setState({ showDatePicker: false })}>{this.context.t('Set')}</button>
                                                    </div>
                                                </div>
                                            ) : (
                                                null
                                            )
                                        }
                                    </div>

                                    <div className="form-group half" id="time-picker-container">
                                        <input type="text" name="eventStartTime" id="eventStartTime" value={eventStartTime} onChange={this.onInputChange} autoComplete="off" placeholder={this.context.t("e.g. ") + moment(today).add(1, 'hour').set('minutes', 0).format("HH:mm")} onFocus={e => { e.target.placeholder = ""; this.setState({ showTimePicker: true }) }} onBlur={e => e.target.placeholder = `e.g. ${moment(today).add(1, 'hour').set('minutes', 0).format("HH:mm")}`} />
                                        <label htmlFor="eventStartTime">{this.context.t('Start time')}</label>
                                        {
                                            showTimePicker ? (
                                                <div className={`time-picker`}>
                                                    <TimePicker sendHour={this.setTime} hour={eventStartHour} minutes={eventStartMinute} />
                                                    <div className="button">
                                                        <button onClick={() => this.setState({ showTimePicker: false })}>{this.context.t('Set')}</button>
                                                    </div>
                                                </div>
                                            ) : (
                                                null
                                            )
                                        }
                                    </div>

                                    <div className="form-group textarea">
                                        <label className={`textarea ${textareaFocus ? 'focus' : ''}`}>{this.context.t('Description')}</label>

                                        <TextareaAutosize
                                            id="eventDescription"
                                            name="eventDescription"
                                            value={eventDescription}
                                            onChange={this.onInputChange}
                                            onClick={e => this.unsetFocus(e)}
                                            onFocus={e => {
                                                e.target.placeholder = ""
                                                this.setState({
                                                    textareaFocus: true
                                                })
                                            }}
                                            onBlur={(e) => this.setTextareaBlur(e)}
                                            ref="textarea"
                                            style={{ maxHeight: '100%', paddingBottom: '8px' }}
                                            placeholder={this.context.t("e.g. Monthly summary")}
                                        />
                                    </div>

                                    <div className="add-members-button">
                                        <div className="plus-button" onClick={(e) => this.showProjectMembers('responsible')}></div>
                                        <div className="text" onClick={(e) => this.showProjectMembers('responsible')}>
                                            {this.context.t('Choose the person responsible')}
                                        </div>
                                        <div className="members">
                                            {this.convertToMembersPhotos([userResponsibleForTask], 'responsible')}
                                        </div>
                                    </div>

                                    <div className="add-members-button">
                                        <div className="plus-button" onClick={(e) => this.showProjectMembers('observers')}></div>
                                        <div className="text" onClick={(e) => this.showProjectMembers('observers')}>
                                            {this.context.t('Add followers')}
                                        </div>
                                        <div className="members">
                                            {this.convertToMembersPhotos(eventParticipants, 'observers')}
                                        </div>
                                    </div>
                                    {
                                        !user.google_calendar_token ? (
                                            <div className="add-member-warning">
                                                {this.context.t('You need to sync your calendar with Google Calendar to assign members')}
                                            </div>
                                        ) : (
                                            eventProjectId === '' ? (
                                                <div className="add-member-warning">
                                                    {this.context.t('Choose binder first')}
                                                </div>
                                            ) : (
                                                null
                                            )
                                        )
                                    }
                                </form>

                                {
                                    showEventParticipants ? (
                                        <div className="participants-list">
                                            <div className="header-row">
                                                {
                                                    eventParticipantsType === 'responsible' ? (
                                                        this.context.t('Choose the person responsible')
                                                    ) : (
                                                        this.context.t('Add followers')
                                                    )
                                                }

                                                {
                                                    eventParticipantsType === 'observers' ? (
                                                        <div className="select-all">
                                                            {this.context.t('Select all')}
                                                            <span className={`checkbox ${projectMembers.length === eventParticipants.length ? 'checked' : ''}`} onClick={() => this.selectAllForEvent()}></span>
                                                        </div>
                                                    ) : null
                                                }
                                            </div>

                                            <SimpleBar style={{ height: 'calc(100% - 170px)', width: '100%', paddongLeft: '25px' }}>
                                                <div className="company-worker"  onClick={e => this.toggleParticipant(user)}>
                                                    <div className={`profile-pic ${user.profile_pic_link ? '' : 'default'}`}>
                                                        {
                                                            user.profile_pic_link ? (
                                                                <img src={user.profile_pic_link} alt="" />
                                                            ) : (
                                                                null
                                                            )
                                                        }
                                                    </div>

                                                    {
                                                        user.first_name && user.last_name ? (
                                                            <div className="info">
                                                                <div className="name">{`${user.first_name} ${user.last_name} `}{this.context.t('(Me)')}</div>
                                                                <div className="email">{user.email}</div>
                                                            </div>
                                                        ) : (
                                                            <div className="info">
                                                                <div className="name">{user.email}</div>
                                                            </div>
                                                        )
                                                    }
                                                    <div className="checkmark">
                                                        <span className={`checkbox ${eventParticipantsType !== 'responsible' || (eventParticipantsType === 'responsible' && userResponsibleForTask.id === user.id) ? 'checked' : ''} ${eventParticipantsType === 'responsible' ? 'radio' : ''}`}></span>
                                                    </div>
                                                </div>
                                                {
                                                    projectMembers.map((worker, i) => {
                                                        if (worker.id !== this.props.user.id) {
                                                            return (
                                                                <div className="company-worker"  onClick={e => this.toggleParticipant(worker)}>
                                                                    <div className={`profile-pic ${worker.profile_pic_link ? '' : 'default'}`}>
                                                                        {
                                                                            worker.profile_pic_link ? (
                                                                                <img src={worker.profile_pic_link} alt="" />
                                                                            ) : (
                                                                                null
                                                                            )
                                                                        }
                                                                    </div>

                                                                    {
                                                                        worker.first_name && worker.last_name ? (
                                                                            <div className="info">
                                                                                <div className="name">{`${worker.first_name} ${worker.last_name}`}</div>
                                                                                <div className="email">{worker.email}</div>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="info">
                                                                                <div className="name">{worker.email}</div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    <div className="checkmark">
                                                                        <span className={`checkbox ${(eventParticipantsType !== 'responsible' && eventParticipants.find(e => e.id === worker.id)) || (eventParticipantsType === 'responsible' && userResponsibleForTask.id === worker.id) ? 'checked' : ''} ${eventParticipantsType === 'responsible' ? 'radio' : ''}`}></span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        } else {
                                                            return null
                                                        }
                                                    })
                                                }
                                            </SimpleBar>


                                            <div className="action-buttons">
                                                <div className="btn cancel-button" onClick={() => this.setState({
                                                    showEventParticipants: false,
                                                    eventParticipants: this.state.backupEventParticipants,
                                                })}>{this.context.t('Cancel')}</div>
                                                {
                                                    eventParticipantsType === 'observers' ? (
                                                        <div className="btn accept-button" onClick={() => this.setState({
                                                            showEventParticipants: false,
                                                            backupEventParticipants: this.state.eventParticipants
                                                        })}>{this.context.t('Confirm')}</div>
                                                    ) : null
                                                }
                                            </div>

                                        </div>
                                    ) : (
                                        null
                                    )
                                }

                                <div className="action-buttons">
                                    <button className="btn cancel-button" onClick={() => this.addEventClose()}>{this.context.t('Cancel')}</button>
                                    <button className="btn accept-button" onClick={e => this.eventSubmit(e)}>
                                        {
                                            !editMode ? (
                                                this.context.t('Save')
                                            ) : (
                                                this.context.t('Update')
                                            )
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        null
                    )
                }


                <div className="events-col" ref="tasksRef">
                    <div className="header">{(selectedDay && selectedDay.value) ? (this.context.t("Tasks for ") + selectedDay.value.format("DD-MM-YYYY")) : (moment(currentMonth).format('YYYY-MM') < moment().format('YYYY-MM')) ? this.context.t("Tasks for ") + this.context.t(currentMonth.format("MMMM")) + " " + currentMonth.format("YYYY") : (this.context.t("Upcoming tasks for ") + this.context.t(currentMonth.format("MMMM")) + " " + currentMonth.format("YYYY"))}</div>
                    <SimpleBar style={{ height: 'calc(100vh - 280px)', width: 'calc(100% + 20px)', marginLeft: '-20px', paddingLeft: '20px', paddingRight: '30px', paddingTop: '20px' }}>
                        <div className="events-list">
                            {
                                events.map((event, i) => {
                                    if (selectedDay && selectedDay.value) {
                                        if (moment(selectedDay.value).format("YYYY-MM-DD") === moment(event.start_date).format("YYYY-MM-DD")) {
                                            eventsCount++
                                            return (
                                                <div className="event-row" key={"event-row" + event.id + "-" + i}>
                                                    <div className="event-header">
                                                        <div className="name-and-project">{event.name} {event.project ? (<span>{' [' + event.project.code + ']'}</span>) : (null)}</div>
                                                        <div className="date">{moment(event.start_date).utc().format('HH:mm')}</div>
                                                        {/* {this.context.t(this.getReccurenceText(event.recurrence))} */}
                                                    </div>
                                                    <div className="highlighted-users-row">
                                                        {
                                                            event.creator ? (
                                                                <div className="highlighted-user">
                                                                    <div className="label">{this.context.t('Creator')}:</div>
                                                                    <div className={`profile-pic ${event.creator.profile_pic_link ? '' : 'default'}`}>
                                                                        {
                                                                            event.creator.profile_pic_link ? (
                                                                                <img src={event.creator.profile_pic_link} alt="" />
                                                                            ) : (
                                                                                null
                                                                            )
                                                                        }
                                                                    </div>
                                                                    {
                                                                        event.creator.first_name && event.creator.last_name ? (
                                                                            <div className="info">
                                                                                <div className="name">{`${event.creator.first_name} ${event.creator.last_name}`}</div>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="info">
                                                                                <div className="name">{event.creator.email}</div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            ) : null
                                                        }
                                                        {
                                                            event.userResponsibleForTask ? (
                                                                <div className="highlighted-user">
                                                                    <div className="label">{this.context.t('Executive')}:</div>
                                                                    <div className={`profile-pic ${event.userResponsibleForTask.profile_pic_link ? '' : 'default'}`}>
                                                                        {
                                                                            event.userResponsibleForTask.profile_pic_link ? (
                                                                                <img src={event.userResponsibleForTask.profile_pic_link} alt="" />
                                                                            ) : (
                                                                                null
                                                                            )
                                                                        }
                                                                    </div>
                                                                    {
                                                                        event.userResponsibleForTask.first_name && event.userResponsibleForTask.last_name ? (
                                                                            <div className="info">
                                                                                <div className="name">{`${event.userResponsibleForTask.first_name} ${event.userResponsibleForTask.last_name}`}</div>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="info">
                                                                                <div className="name">{event.userResponsibleForTask.email}</div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            ) : null
                                                        }
                                                        <div className="event-participants">
                                                            {/* <div className="label">{this.context.t('Followers')}:</div> */}
                                                            {this.convertToMembersPhotosEventsList(event.participants_with_photos, event.id)}
                                                        </div>
                                                    </div>
                                                    <div className="event-description">
                                                        <div className="note">
                                                            {
                                                                event.note && event.note.split('\n') ? (
                                                                    event.note.split('\n').map(l => {
                                                                        if (l) {
                                                                            return (
                                                                                <p>{l}</p>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <br />
                                                                            )
                                                                        }
                                                                    })
                                                                ) : (
                                                                    null
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        event.user_id_responsible === user.id ? (
                                                            <>
                                                                <div className="btn edit-button" onClick={e => this.editEvent(event.id, event.recurrence)}></div>
                                                                <div className="btn delete-button" onClick={e => this.showDeleteModal(event.id, event.recurrence)}></div>
                                                            </>
                                                        ) : (
                                                            null
                                                        )
                                                    }

                                                </div>
                                            )
                                        } else {
                                            return null
                                        }
                                    } else {
                                        if (moment(currentMonth).format("YYYY-MM") === moment(event.start_date).format("YYYY-MM") && moment(event.start_date).format("YYYY-MM-DD") >= moment(currentMonth).format("YYYY-MM-DD")) {
                                            console.log(event)
                                            eventsCount++
                                            return (
                                                <div className="event-row" key={"event-row" + event.id + "-" + i}>
                                                    <div className="event-header">
                                                        <div className="name-and-project">{event.name} {event.project ? (<span>{' [' + event.project.code + ']'}</span>) : (null)}</div>
                                                        <div className="date">{moment(event.start_date).utc().format('YYYY-MM-DD HH:mm')}</div>
                                                        {/* {this.context.t(this.getReccurenceText(event.recurrence))} */}
                                                    </div>
                                                    <div className="highlighted-users-row">
                                                        {
                                                            event.creator ? (
                                                                <div className="highlighted-user">
                                                                    <div className="label">{this.context.t('Creator')}:</div>
                                                                    <div className={`profile-pic ${event.creator.profile_pic_link ? '' : 'default'}`}>
                                                                        {
                                                                            event.creator.profile_pic_link ? (
                                                                                <img src={event.creator.profile_pic_link} alt="" />
                                                                            ) : (
                                                                                null
                                                                            )
                                                                        }
                                                                    </div>
                                                                    {
                                                                        event.creator.first_name && event.creator.last_name ? (
                                                                            <div className="info">
                                                                                <div className="name">{`${event.creator.first_name} ${event.creator.last_name}`}</div>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="info">
                                                                                <div className="name">{event.creator.email}</div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            ) : null
                                                        }
                                                        {
                                                            event.userResponsibleForTask ? (
                                                                <div className="highlighted-user">
                                                                    <div className="label">{this.context.t('Executive')}:</div>
                                                                    <div className={`profile-pic ${event.userResponsibleForTask.profile_pic_link ? '' : 'default'}`}>
                                                                        {
                                                                            event.userResponsibleForTask.profile_pic_link ? (
                                                                                <img src={event.userResponsibleForTask.profile_pic_link} alt="" />
                                                                            ) : (
                                                                                null
                                                                            )
                                                                        }
                                                                    </div>
                                                                    {
                                                                        event.userResponsibleForTask.first_name && event.userResponsibleForTask.last_name ? (
                                                                            <div className="info">
                                                                                <div className="name">{`${event.userResponsibleForTask.first_name} ${event.userResponsibleForTask.last_name}`}</div>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="info">
                                                                                <div className="name">{event.userResponsibleForTask.email}</div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            ) : null
                                                        }

                                                        <div className="event-participants">
                                                            {/* <div className="label">{this.context.t('Followers')}:</div> */}
                                                            {this.convertToMembersPhotosEventsList(event.participants_with_photos, event.id)}
                                                        </div>
                                                    </div>
                                                    <div className="event-description">
                                                        <div className="note">
                                                            {
                                                                event.note && event.note.split('\n') ? (
                                                                    event.note.split('\n').map(l => {
                                                                        if (l) {
                                                                            return (
                                                                                <p>{l}</p>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <br />
                                                                            )
                                                                        }

                                                                    })
                                                                ) : (
                                                                    null
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        event.user_id_responsible === user.id ? (
                                                            <>
                                                                <div className="btn edit-button" onClick={e => this.editEvent(event.id, event.recurrence)}></div>
                                                                <div className="btn delete-button" onClick={e => this.showDeleteModal(event.id, event.recurrence)}></div>
                                                            </>
                                                        ) : (
                                                            null
                                                        )
                                                    }

                                                </div>
                                            )
                                        } else {
                                            return null
                                        }
                                    }
                                })
                            }
                        </div>
                    </SimpleBar>
                    {
                        eventsCount === 0 ? (
                            <div className="not-events-indicator">
                                <div className="img"></div>
                                <div className="text">{this.context.t('It looks like you don\'t have any tasks')}</div>
                            </div>
                        ) : (
                            null
                        )
                    }
                    <div className="bottom-gradeint"></div>
                </div>

                <div className="calendar-col" ref="calendarRef">
                    <Calendar selectDay={this.props.selectDay} unselectDay={this.props.unselectDay} selectedDay={selectedDay} projectId={null} changeMonth={this.changeMonthOnMainCalendar} />

                    <div className="google-calendar-sync" ref="googleSyncRef">
                        {
                            !user.google_calendar_token ? (
                                <div className="sync-with-calendar">
                                    <div className="header">
                                        {this.context.t('Sync with Google Calendar')}
                                    </div>
                                    <div className="text">
                                        {this.context.t('You can sync Google calendar tasks with EasyDocs, just click the button below and paste the authorization code.')}

                                        <span className="warning">{this.context.t('*During authorization, you must give EasyDocs access to Google calendars and events for the synchronization to work properly')}</span>
                                    </div>
                                    <div className="sync-button" onClick={() => this.getCalendarAuthorizationLink()}>
                                        {this.context.t('Sync')}
                                    </div>
                                </div>
                            ) : (
                                <div className="sync-with-calendar-info">
                                    <div className="icon"></div>
                                    <div className="info">

                                        <div className="text">
                                            {this.context.t('You are synchronized with Google Calendar')}
                                        </div>
                                        <div className="options" onClick={() => this.toggleDesynchronizeOption()}>
                                            {
                                                showDesynchronizeOption ? (
                                                    <div className="desynchronize-option" onClick={() => this.revokeGoogleCalendarAccess()}>
                                                        {this.context.t('Disconnect')}
                                                    </div>

                                                ) : (
                                                    null
                                                )
                                            }
                                        </div>
                                        <div className="sync-email">
                                            {user.google_calendar_sync_email}
                                        </div>
                                    </div>

                                </div>
                            )
                        }
                    </div>

                </div>
            </div>

        )
    }
}


CalendarPage.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    overlayPreferance: state.User.overlayPreferance || {},
    user: state.User.user,
    userProjects: state.User.userProjects,
    projectMembers: state.Company.projectWorkers,
    events: [...state.Event.events],
    projectEventsLoaded: [...state.Event.projectEventsLoaded],
    projectEventsLoading: [...state.Event.projectEventsLoading],
    selectedDay: state.Event.selectedDay,
    currentMonth: state.Event.currentMonth,
    authorizedGoogleCalendar: state.Event.authorizedGoogleCalendar,
    calendarAuthorizationMessage: state.Event.calendarAuthorizationMessage,
    calendarAuthorizationErrorReason: state.Event.calendarAuthorizationErrorReason,
    calendarList: state.Event.googleCalendarsList,
    editingGoogleCalendarName: state.Event.currentCalendarName,
    nearestEvent: { ...state.Event.nearestEvent },
    datePickerDay: state.Event.datePickerDay,
})

const mapDispatchToProps = {
    getUserProjects: UserActions.getUserProjects,
    getEvents: EventActions.getEvents,
    getProjectEvents: EventActions.getProjectEvents,
    getProjectMembers: CompanyActions.getProjectWorkers,
    selectDay: EventActions.selectDay,
    datePickerSelect: EventActions.datePickerSelect,
    unselectDatePickerDay: EventActions.datePickerUnselect,
    unselectDay: EventActions.unselectDay,
    alertWarn: AlertActions.warning,
    saveEvent: EventActions.addEvent,
    updateEvent: EventActions.updateEvent,
    deleteEvent: EventActions.deleteEvent,
    getCalendarAuthorizationLink: EventActions.getCalendarAuthorizationLink,
    authorizeGoogleCalendar: EventActions.authorizeGoogleCalendar,
    authorizedGoogleCalendarFromSocket: EventActions.authorizedGoogleCalendarFromSocket,
    revokeGoogleCalendarAccess: EventActions.revokeGoogleCalendarAccess,
    getGoogleCalendarsList: EventActions.getGoogleCalendarsList,
    getGoogleCalendarName: EventActions.getGoogleCalendarName,
    setDefaultGoogleCalendar: EventActions.setDefaultGoogleCalendar,
    createNewGoogleCalendar: EventActions.createNewGoogleCalendar,
    toggleOverlay: UserActions.toggleOverlay
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarPage)