import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { AlertActions } from '../../actions/AlertActions'
import { CompanyActions } from '../../actions/CompanyActions'

export class ConnectToPsd2AisPopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedAccounts: [],
            selectedBank: '',
        }
    }

    selectBankAccount = account => {
        const { selectedAccounts, selectedBank } = this.state
        if (selectedAccounts.includes(account.id)) {
            var newSelectedAccounts = selectedAccounts.filter(id => id !== account.id)
            this.setState({
                selectedAccounts: newSelectedAccounts,
                selectedBank: newSelectedAccounts.length ? selectedBank : ''
            })
        } else {
            if ((selectedBank && selectedBank === account.bank_name) || !selectedBank) {
                var newSelectedAccounts = selectedAccounts
                newSelectedAccounts.push(account.id)

                this.setState({
                    selectedAccounts: newSelectedAccounts,
                    selectedBank: account.bank_name
                })
            }
        }
    }

    connectPsd2Ais = () => {
        const { selectedAccounts } = this.state
        const { companyId } = this.props
        if (selectedAccounts.length === 0) {
            this.props.alertWarn('You must select at least one account')
        } else {
            this.props.connectToPsd2Ais(selectedAccounts, companyId, () => {

            })
        }
    }

    render() {
        const { selectedAccounts, selectedBank } = this.state
        const { accounts } = this.props
        console.log(selectedAccounts, selectedBank)
        return (
            <div className="psd2-ais popup">
                <div className="popup-card">
                    <h3>{this.context.t('Select bank accounts you want to connect to PSD2 AIS')}</h3>
                    <h4>{this.context.t('You can select multiple accounts from the same bank at once')}</h4>

                    <ul>
                        {
                            accounts.map(account => {
                                if (!account.psd2_is_connected_to_ais) {
                                    return (
                                        <li
                                            className={`${selectedAccounts.includes(account.id) ? 'selected' : ''} ${selectedBank && selectedBank !== account.bank_name ? 'inactive' : ''}`}
                                            onClick={() => this.selectBankAccount(account)}
                                        >
                                            <span className="name">{`${account.name} (${account.currency})`}</span>
                                            <span className="bank">{`${account.bank_name}: ${account.account_number}`}</span>
                                        </li>
                                    )
                                } else {
                                    return null
                                }
                            })
                        }
                    </ul>

                    <div className="action-buttons">
                        <div className="btn cancel" onClick={() => this.props.cancel()}>{this.context.t('Cancel')}</div>
                        <div className="btn accept" onClick={() => this.connectPsd2Ais()}>{this.context.t('Connect')}</div>
                    </div>
                </div>
            </div>
        )
    }
}

ConnectToPsd2AisPopup.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
    connectToPsd2Ais: CompanyActions.connectToPsd2Ais,
    alertWarn: AlertActions.warning
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectToPsd2AisPopup)