import React, { Component } from 'react'
import { useSelector, useDispatch, connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from "moment";
import { reverse } from 'named-urls'

import { history } from '../../helpers/history'
import routes from '../../helpers/routes'
import arrayObjectIndexOf from '../../helpers/indexOfObject'

import { AlertActions } from '../../actions/AlertActions'
import { FileActions } from '../../actions/FileActions'
import { UserActions } from '../../actions/UserActions'
import { ChatActions } from '../../actions/ChatAction'

import "../../css/ChatNotificationsPopup.css";
import pdfIcon from '../../img/pdf-icon.svg'

export class ChatNotificationsPopup extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        document.body.style.overflow = 'hidden'
    }

    componentWillUnmount() {
        document.body.style.overflow = ''
    }

    render() {
        const { newChatNotifications } = this.props
        return (
            <div className="chat-notifications-popup">
                <div className="popup-card">
                    <div className="header">
                        <h4>{this.context.t("You were mentioned in the chat regarding the document")}</h4>
                        <p>{this.context.t("Click on the document to enter the chat window")}</p>
                    </div>
                    <div className="notifications-wrapper">
                        <NotificationsList groupedNotifications={newChatNotifications} closePopup={this.props.onClose} />
                    </div>
                    <button onClick={() => this.props.onClose()} className="button">{this.context.t('Close')}</button>
                </div>
            </div>
        )
    }
}

ChatNotificationsPopup.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {
    closePopUP: ChatActions.closePopUP,
    checkChatNotification: ChatActions.checkChatNotification,
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatNotificationsPopup);



const NotificationsList = ({ groupedNotifications, closePopup }, { t }) => {
    const projectNames = Object.keys(groupedNotifications)
    const { uploadedFiles } = useSelector(state => state.File)

    const dispatch = useDispatch()

    const handleNotificationClick = notification => {
        switch (notification.fileStatus) {
            case 'NOT_VERIFIED':
                history.push(reverse(routes.panel.crop, { projectId: notification.projectId, fileId: notification.fileId }));
                dispatch(UserActions.switchDashboardViewModel(notification.projectId))
                closePopup()
                break;
            case 'VERIFIED':
                console.log(uploadedFiles)
                var projectIndex = arrayObjectIndexOf(uploadedFiles, notification.projectId, 'project')
                if (projectIndex === -1) {
                    dispatch(AlertActions.warning(t('Sorry, we are unable to open this file')))
                    return;
                }
                var fileIndex = arrayObjectIndexOf(uploadedFiles[projectIndex].files, notification.fileId, 'id')
                if (fileIndex === -1) {
                    dispatch(AlertActions.warning(t('Sorry, we are unable to open this file')))
                    return;
                }
                var fileToOpen = uploadedFiles[projectIndex].files[fileIndex]
                dispatch(FileActions.getFileData(parseInt(fileToOpen.project_id), fileToOpen.path, fileToOpen.id, fileToOpen.ocr_status, fileToOpen.acceptance_step))
                dispatch(UserActions.switchDashboardViewModel(notification.projectId))
                closePopup()
                break;
            default:
                break
        }
    }

    if (!projectNames.length) return null

    return (
        <div className="list">
            {
                projectNames.map((project) => (
                    <div className="project" key={project}>
                        <div className="project-title">{project}</div>
                        <div className="files">
                            {groupedNotifications[project].map(notification => (
                                <div onClick={() => handleNotificationClick(notification)} key={notification.id} className="file">
                                    <img src={pdfIcon} alt="" />
                                    <span className="file-name">{notification.fileName}</span>
                                    <span className="created">{`${notification.createdBy} ${t('at')} ${moment(notification.created).format('HH:mm YYYY-MM-DD ')}`}</span>
                                    <span className="message">{`${t('Message')}: ${notification.message}`}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

NotificationsList.contextTypes = {
    t: PropTypes.func
}