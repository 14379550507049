import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { UserActions } from '../actions/UserActions'

export class LanguageSwitcher extends Component {

    render() {
        return (
            <div className="change-language">
                <div className={`lang-button pl ${localStorage.getItem('language') === 'pl' ? 'active' : ''}`} onClick={() => this.props.changeLanguage("pl")}>PL</div>
                <div className={`lang-button ${localStorage.getItem('language') === 'en' ? 'active' : ''}`} onClick={() => this.props.changeLanguage("en")}>EN</div>
            </div>
        )
    }
}

LanguageSwitcher.contextTypes = {
	t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = {
    changeLanguage: UserActions.changeLanguage,
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher)