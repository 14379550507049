export default (key, order = 'asc', numericCompare = false) => {
    return (a, b) => {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            return 0;
        }

        const varA = (typeof a[key] === 'string') ?
            a[key].toUpperCase() : a[key];
        const varB = (typeof b[key] === 'string') ?
            b[key].toUpperCase() : b[key];

        var comparison = 0;
        if (!varA) {
            comparison = -1
        } else if (!varB) {
            comparison = 1
        } else if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }

        if (numericCompare && varA && varB) {
            comparison = varA.localeCompare(varB, 'pl', { numeric: true })
        }
        return (
            (order === 'desc') ? (comparison * -1) : comparison
        );
    };
}