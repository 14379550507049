import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import { AlertActions } from '../../actions/AlertActions'

export class AdditionalAcceptanceUsersList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            acceptationType: 'ALL',
            requiredAcceptanceNumber: 2,
        }
    }

    componentDidMount() {
        if (this.props.numberOfRequiredAcceptances) {
            this.setState({
                requiredAcceptanceNumber: this.props.numberOfRequiredAcceptances || 2
            })
        }

        if (this.props.projectWorkers) {
            var maxAcceptnces = 0
            this.props.projectWorkers.map(u => {
                if (u.role !== 'USER1') maxAcceptnces++
            })

            this.setState({
                maxAcceptnces: maxAcceptnces
            })
        }
    }

    save = () => {
        const { selectedUsers } = this.props
        const { requiredAcceptanceNumber } = this.state

        if (selectedUsers.length === 0) {
            this.props.alertWarn(this.context.t('You must select at least one user'))
        } else if(selectedUsers.length < requiredAcceptanceNumber) {
            this.props.alertWarn(this.context.t('Select more users or decrease number of required acceptances'))
        } else {
            this.props.save(requiredAcceptanceNumber)
        }
    }

    userOnClick = userId => {
        const { selectedUsers } = this.props

        if (selectedUsers.includes(userId)) {
            this.props.unselectUser(userId)
        } else {
            this.props.selectUser(userId)
        }
    }

    changeRequiredAcceptancesCount = (type) => {
        const { requiredAcceptanceNumber, maxAcceptnces } = this.state

        var newRequiredAcceptanceNumber = requiredAcceptanceNumber
        if(type === 'PLUS') newRequiredAcceptanceNumber++
        if(type === 'MINUS') newRequiredAcceptanceNumber--

        if(newRequiredAcceptanceNumber < 1) newRequiredAcceptanceNumber = 1
        if(newRequiredAcceptanceNumber > maxAcceptnces) newRequiredAcceptanceNumber = maxAcceptnces

        this.setState({
            requiredAcceptanceNumber: newRequiredAcceptanceNumber
        })
    }

    changeAcceptationType = newType => {
        this.setState({
            acceptationType: newType
        })
    }

    render() {
        const { additionalAcceptanceType, projectWorkers, selectedUsers } = this.props
        const { acceptationType, requiredAcceptanceNumber } = this.state
        return (
            <div className="additional-acceptance-users-list popup">
                <div className="popup-card">
                    <div className="heading">
                        {
                            additionalAcceptanceType === 'over' ? (
                                this.context.t('Select users whose approval will be required for documents above the amount thresholds')
                            ) : (
                                this.context.t('Select users whose approval will be required for documents below the amount thresholds')
                            )
                        }

                        <div className="acceptance-users-number">
                            <div className="text">{this.context.t("The number of people who need to accept the document")}:</div>
                            <div className="number">
                                {requiredAcceptanceNumber}
                                <span className="increment" onClick={() => this.changeRequiredAcceptancesCount('PLUS')}></span>
                                <span className="decrement" onClick={() => this.changeRequiredAcceptancesCount('MINUS')}></span>
                            </div>
                        </div>

                        {/* <div className="acceptation-doc">
                            <h6>{this.context.t("Require approval:")}</h6>
                            <div id="acceptation-all" className={`acceptation-type ${acceptationType === 'ALL' ? 'selected' : ''}`}
                                onClick={() => this.changeAcceptationType('ALL')}>
                                <span className="checkbox"></span>
                                {this.context.t("all users")}
                            </div>
                            <div id="acceptation-one" className={`acceptation-type ${acceptationType === 'ONE' ? 'selected' : ''}`} onClick={() => this.changeAcceptationType('ONE')}>
                                <span className="checkbox"></span>
                               { this.context.t("one user")}
                            </div>
                        </div> */}

                    </div>
                    <div className="users-list">
                        <SimpleBar style={{ maxHeight: '50vh', width: '100%', paddingLeft: '10px', paddingRight: '10px' }}>
                            {
                                projectWorkers.map(user => {
                                    if (user.role !== 'USER1') {
                                        return (
                                            <div onClick={() => this.userOnClick(user.id)} className={`user ${selectedUsers.includes(user.id) ? 'selected' : ''}`} key={`user-${user.id}`}>
                                                <div className={`profile-pic ${user.profile_pic_link ? '' : 'default'}`}>
                                                    {
                                                        user.profile_pic_link ? (
                                                            <img src={user.profile_pic_link} alt="Profile picture" />
                                                        ) : (
                                                            null
                                                        )
                                                    }
                                                </div>
                                                <div className="user-info">
                                                    {
                                                        user.first_name && user.last_name ? (
                                                            <>
                                                                <div className='name-and-surname'>{`${user.first_name} ${user.last_name}`}</div>
                                                                <div className='email'>{`${user.email}`}</div>
                                                            </>
                                                        ) : (
                                                            <div className='name-and-surname'>{`${user.email}`}</div>
                                                        )
                                                    }
                                                </div>
                                                <div className={`checkbox ${selectedUsers.includes(user.id) ? 'selected' : ''}`}></div>
                                            </div>
                                        )
                                    } else {
                                        return null
                                    }
                                })
                            }
                        </SimpleBar>
                    </div>
                    <div className="footer">
                        <div className="button btn-cancel" onClick={() => this.props.cancel()}>{this.context.t('Cancel')}</div>
                        <div className="button btn-confirm" onClick={() => this.save()}>{this.context.t('Save')}</div>
                    </div>
                </div>
            </div>
        )
    }
}

AdditionalAcceptanceUsersList.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = {
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalAcceptanceUsersList)