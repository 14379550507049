import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

export class KsefInfoPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

  render() {
    const { } = this.state;
    const { } = this.props

    return (
      <div className="ksef-info-popup">
        <div className="popup-card">
          <h2>{this.context.t('KSeF integration')}</h2>
          <p className="important">{this.context.t("For this company, the possibility of sending sales invoices directly to KSeF has been enabled.")}</p>
          <p>{this.context.t(
            "Invoices sent to KSeF can no longer be edited or deleted, therefore EasyDocs does not send your invoices automatically after generation - unsent documents have the so-called {b1} status. You can recognize them by the lack of a number in the {b2} column.",
            {
              b1: <span className="bold">{this.context.t("Working invoice")}</span>,
              b2: <span className="bold">{this.context.t("KSeF document number")}</span>
            }
          )}</p>
          <p>{this.context.t(
            "You can verify and correct {b1} again before sending them to KSeF.",
            {
              b1: <span className="bold">{this.context.t("Working invoices")}</span>
            }
          )}</p>
          <p>{this.context.t("You can send it to KSeF in two ways:")}</p>
          <ul>
            <li>{this.context.t(
              "by selecting the {b1} checkbox directly when issuing an invoice",
              {
                b1: <span className="bold">{this.context.t("Send to KSeF")}</span>
              }
            )}</li>
            <li>{this.context.t(
              "by clicking {b1} in the line of a given invoice",
              {
                b1: <span className="bold">{this.context.t("Send to KSeF")}</span>
              }
            )}</li>
          </ul>
          <p>{this.context.t("Once an invoice has been sent to KSeF, it cannot be edited or deleted. You can only issue and send a correction invoice to KSeF.")}</p>

          <div className="button" onClick={() => this.props.togglePopup(false)}>{this.context.t("Got it!")}</div>
        </div>
      </div>
    );
  }
}

KsefInfoPopup.contextTypes = {
  t: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
  user: state.User.user,
  currentCompany: state.Company.currentCompany || {},
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(KsefInfoPopup);
