import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Spinner } from "react-activity";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import SimpleBar from "simplebar-react";

import InvoicePreview from "./InvoicePreview";
import Calendar from "../Calendar";

import { CompanyActions } from "../../actions/CompanyActions";
import { AlertActions } from "../../actions/AlertActions";

import { getCurrencyRateFromNBP } from "../../helpers/getCurrencyRateFromNBP";
import validateEmail from "../../helpers/validateEmail";
import { splitAddress } from "../../helpers/splitAddress";
import currencyList from '../../helpers/currencyList.json'
import paymentMethodsList from '../../helpers/paymentMethodsList.json'

import "../../../src/css/CorrectionItemForm.css";
import { DOCUMENT_TYPE } from "../../pages/User/SalesPage";
import CountriesList from "../CountriesList";
import NewInvoiceNumberPopUp from "./NewInvoiceNumberPopUp"

export const GTU_CODE = {
  GTU_01: "GTU_01: Dostawa napojów alkoholowych w rozumieniu przepisów o podatku akcyzowym (nie dotyczy gastronomii)",
  GTU_02: "GTU_02: Dostawa towarów, o których mowa w art. 103 ust. 5aa ustawy o VAT (m.in. paliwa i oleje napędowe)",
  GTU_03: "GTU_03: Dostawa oleju opałowego",
  GTU_04: "GTU_04: Dostawa wyrobów tytoniowych",
  GTU_05: "GTU_05: Dostawa odpadów",
  GTU_06: "GTU_06: Dostawa urządzeń elektronicznych",
  GTU_07: "GTU_07: Dostawa pojazdów oraz części samochodowych",
  GTU_08: "GTU_08: Dostawa metali szlachetnych oraz nieszlachetnych",
  GTU_09: "GTU_09: Dostawa leków oraz wyrobów medycznych",
  GTU_10: "GTU_10: Dostawa budynków, budowli i gruntów",
  GTU_11: "GTU_11: Świadczenie usług w zakresie przenoszenia uprawnień do emisji gazów cieplarnianych",
  GTU_12: "GTU_12: Świadczenie usług o charakterze niematerialnych, np.: doradczych, księgowych, prawnych, zarządczych, szkoleniowych, marketingowych",
  GTU_13: "GTU_13: Świadczenie usług transportowych i gospodarki magazynowej",
  null: "Brak",
};

export class CorrectionItemForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentNumber: "",
      numberingSeriesId: -1,
      allSeriesDocumentNumbers: [],
      showDocumentNumberSeriesList: false,
      documentType: DOCUMENT_TYPE.corrItems,
      documentLanguage: 'pl',
      issueDate: moment().format("YYYY-MM-DD"),
      saleDate: moment().format("YYYY-MM-DD"),
      searchBuyerVatId: "",
      buyerName: "",
      buyerVatId: "",
      buyerAddress: "",
      buyerPostCode: "",
      buyerCity: "",
      buyerCountryCode: "",
      sellerName: "",
      sellerVatId: "",
      sellerAddress: "",
      sellerPostCode: "",
      sellerCity: "",
      issuePlace: "",
      accountNumber: "",
      bankName: "",
      bankBIC: "",
      accountsList: [],
      invoicePositions: [
        {
          name: "",
          amount: 1,
          unit: "szt.",
          netPrice: "",
          discount: "",
          discountPrice: "",
          vat: "23",
          netValue: "",
          grossValue: "",
          pkwiu: "",
          gtu: "",
        },
      ],
      beforeInvoicePositions: [
        {
          name: "",
          amount: 1,
          unit: "szt.",
          netPrice: "",
          discount: "",
          discountPrice: "",
          vat: "23",
          netValue: "",
          grossValue: "",
          pkwiu: "",
          gtu: "",
        },
      ],
      summary: {
        netSum: 0,
        vatSum: 0,
        grossSum: 0,
      },
      beforeSummary: {
        netSum: 0,
        vatSum: 0,
        grossSum: 0,
      },
      showBeforePkwiu: false, showBeforeGtu: false, showBeforeDiscount: false,
      showPkwiu: false, showGtu: false, showDiscount: false,
      paymentMethod: "transfer",
      dueDate: moment().add(7, "days").format("YYYY-MM-DD"),
      paymentStatus: "unpaid",
      currency: "PLN",

      savedInvoicePositions: [],
      unitListToShow: -1,
      gtuListToShow: -1,
      posListToShow: -1,
      savedDocItems: [],
      savedCounterparties: [],
      invoicesList: [],
      logo: null,
      buyerNotFromPoland: false,
      buyerEmail: "",
      counterpartiesList: [],
      showCounterpartiesList: false,
      showInvoicePreviewPopup: false,
      fileBody: null,
      fileName: null,
      editingExistingInvoice: false,
      editedInvoiceId: null,
      currencyMidPrice: 0,
      showCurrencyMidPrice: false,
      showReasonForCorrectionList: false,
      reasonForCorrection: "",
      correctedInvoiceId: null,
      showCountriesList: false,
      buyerCountryCode: 'PL',
      sendToKsef: false
    };
  }

  componentDidMount() {
    window.addEventListener("keydown", this.escFunction, false);
    const { currentCompany } = this.props;
    if (currentCompany) {
      this.setData();
    }
  }

  componentDidUpdate(prevProps) {
    console.log(this.props.selectedInvoiceData?.id)
    console.log(prevProps.selectedInvoiceData?.id)
    if (this.props.currentProject !== prevProps.currentProject ||
      this.props.currentCompany !== prevProps.currentCompany ||
      this.props.selectedInvoiceData?.id !== prevProps.selectedInvoiceData?.id
    ) {
      this.setData();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.escFunction, false);
  }

  searchVatAPI = () => {
    const { waitingForVatApiReponse, searchBuyerVatId } = this.state;
    if (!waitingForVatApiReponse) {
      if (searchBuyerVatId.length === 10) {
        this.setState({
          waitingForVatApiReponse: true,
        });

        fetch(`https://wl-api.mf.gov.pl/api/search/nip/${searchBuyerVatId.replace(/[^0-9]/gi, "")}?date=${moment().format("YYYY-MM-DD")}`, { method: "get" })
          .then((response) => response.json())
          .then((json) => {
            if (json && json.result && json.result.subject) {
              var company = json.result.subject;
              var companyAddress =
                json.result.subject.residenceAddress ||
                json.result.subject.workingAddress;
              var address = "";
              var zipCode = "";
              var city = "";
              if (companyAddress) {
                const sAddress = splitAddress(companyAddress);
                address = sAddress.address;
                zipCode = sAddress.zipCode;
                city = sAddress.city;
              }

              var name = company.name
                .replace(/SPÓŁKA KOMANDYTOWA/gi, "")
                .replace(/SPÓŁKA CYWILNA/gi, "")
                .replace(/SPÓŁKA AKCYJNA/gi, "")
                .replace(/SPÓŁKA DORADZTWA PODATKOWEGO/gi, "")
                .replace(/SPÓŁKA PARTNERSKA/gi, "")
                .replace(/SPÓŁKA JAWNA/gi, "")
                .replace(/SP. Z O.O./gi, "")
                .replace(/SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ/gi, "")
                .replace(/SP. Z 0.0./gi, "")
                .replace(/POLSKA/gi, "")
                .replace(/\'/gm, "")
                .replace(/\(/gm, "")
                .replace(/\)/gm, "")
                .replace(" - ", "-");

              name = name.trim();
              var code = this.generateCode(name);

              this.setState({
                buyerName: company.name,
                buyerVatId: company.nip,
                buyerAddress: address,
                buyerPostCode: zipCode,
                buyerCity: city,
                buyerCountryCode: "PL",
                waitingForVatApiReponse: false,
                showVatIDSearchPopup: false,
              });
            } else {
              this.props.alertWarn(
                this.context.t(
                  "No company with the given VatID was found. Make sure the number you entered is correct"
                )
              );
              this.setState({
                waitingForVatApiReponse: false,
              });
            }
          })
          .catch((err) => {
            this.props.alertWarn(
              this.context.t(
                "An error occurred while searching, please enter the buyer details manually or try again later."
              )
            );
            this.setState({
              waitingForVatApiReponse: false,
              showVatIDSearchPopup: false,
            });
          });
      } else {
        this.props.alertWarn(
          this.context.t("Given tax identification number is incorrect.")
        );
      }
    }
  };

  setDocType = (docType) => {
    this.setState({
      documentType: docType,
      showDocumentTypeList: false,
    });

    this.setInvoiceNumber(
      moment(this.state.issueDate, "YYYY-MM-DD").format("YYYY-MM"),
      null,
      docType
    );
  };

  clickOnDatePicker = (e, name) => {
    if (e.target.className.includes("day-cell")) {
      if (name === "issueDate") {
        this.setState({
          showIssueDateCalendar: false,
        });
      } else if (name === "dueDate") {
        this.setState({
          showDueDateCalendar: false,
        });
      } else if (name === "saleDate") {
        this.setState({
          showSaleDateCalendar: false,
        });
      }
    }
  };

  selectSaleDate = (date) => {
    this.setState({
      saleDate: moment(date).format("YYYY-MM-DD"),
    });

    const { currency } = this.state;
    if (currency && currency !== 'PLN') this.setCurrencyMidPrice(currency, date);
  };

  onInputChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    this.setState({ [name]: value });
    if (name === "buyerName") this.setState({ showCounterpartiesList: true });
  };

  showSearchVatIdPopup = () => {
    window.addEventListener("keydown", this.searchOnEnter, false);
    this.setState({
      showVatIDSearchPopup: true,
    });
  };

  addPossition = () => {
    var { invoicePositions } = this.state;
    invoicePositions.push({
      name: "",
      amount: 1,
      unit: "szt.",
      netPrice: "",
      vat: "23",
      vatAmount: "",
      netValue: "",
      grossValue: "",
      pkwiu: "",
      gtu: "",
    });

    this.setState({
      invoicePositions: invoicePositions,
    });
  };

  onInputChangeInvoicePossition = (e, index) => {
    var { invoicePositions } = this.state;
    var { name, value } = e.target;
    name = name.replace(/[^a-zA-Z]/g, "");

    if (name === "amount") {
      value = value.replace(/[^0-9,.-]/gm, "").replace(",", ".");
    }
    if (name === "vat") {
      value = value.replace(/[^0-9]/gm, "");
    }
    if (name === "netPrice" || name === "netValue" || name === "grossValue") {
      value = value.replace(/[^0-9\,\.]/gm, "");
    }

    console.log(invoicePositions)
    console.log(index)
    console.log(name)
    console.log(value)

    invoicePositions[index][name] = value;

    console.log(invoicePositions)

    this.setState({
      invoicePositions: invoicePositions,
      posListToShow: -1,
    });
  };

  subtractBeforeSummary = (summary) => {
    const { netSum, vatSum, grossSum } = this.state.beforeSummary;
    return {
      netSum: (summary.netSum - netSum).toFixed(2),
      vatSum: (summary.vatSum - vatSum).toFixed(2),
      grossSum: (summary.grossSum - grossSum).toFixed(2),
    };
  };

  setItem = (item, index) => {
    var { invoicePositions } = this.state;
    invoicePositions[index] = { ...item };

    this.setState({
      posListToShow: -1,
    });

    var summary = {
      netSum: 0,
      vatSum: 0,
      grossSum: 0,
    };
    invoicePositions.map((pos) => {
      if (pos.netValue && pos.grossValue && pos.vat) {
        var vat = isNaN(parseFloat(pos.vat)) ? 0.0 : parseFloat(pos.vat);
        summary.vatSum += parseFloat(pos.netValue) * (vat / 100);
        summary.netSum += parseFloat(pos.netValue);
        summary.grossSum += parseFloat(pos.grossValue);
      }
    });

    this.setState({
      unitListToShow: -1,
      gtuListToShow: -1,
      invoicePositions: invoicePositions,
      summary: this.subtractBeforeSummary(summary),
    });
  };

  updateSummary = (e) => {
    var { name, value } = e.target;
    var { invoicePositions } = this.state;
    var propName = name.replace(/[^a-zA-Z]/g, "");
    var posIndex = name.replace(`${propName}-`, "");

    if (posIndex > -1 && invoicePositions[posIndex].netPrice) {
      var newNetPrice = parseFloat(invoicePositions[posIndex].netPrice).toFixed(2),
        newNetValue = parseFloat(invoicePositions[posIndex].netValue).toFixed(2),
        newGrossValue = parseFloat(invoicePositions[posIndex].grossValue).toFixed(2),
        newVat = isNaN(parseFloat(invoicePositions[posIndex].vat)) ? 0.0 : parseFloat(invoicePositions[posIndex].vat),
        newAmount = invoicePositions[posIndex].amount,
        discount = isNaN(parseFloat(invoicePositions[posIndex].discount)) ? 0.0 : parseFloat(invoicePositions[posIndex].discount),
        newDiscountPrice = newNetPrice ? parseFloat(newNetPrice) * (1 - discount / 100) : 0

      if (propName === "amount" || propName === "vat" || propName === "netPrice" || propName === "discount") {
        if (newDiscountPrice) {
          newNetValue = (parseFloat(newDiscountPrice) * parseFloat(newAmount)).toFixed(2);
          newGrossValue = ((parseFloat(newNetValue) * (100 + parseFloat(newVat))) / 100).toFixed(2);
        }
      } else if (propName === "netValue") {
        newGrossValue = ((parseFloat(newNetValue) * (100 + parseFloat(newVat))) / 100).toFixed(2);
        newDiscountPrice = (parseFloat(newNetValue) / parseFloat(newAmount)).toFixed(2)
        newNetPrice = (parseFloat(newDiscountPrice) / (1 - discount / 100)).toFixed(2)
      } else if (propName === "grossValue") {
        newNetValue = (parseFloat(newGrossValue) / ((100 + parseFloat(newVat)) / 100)).toFixed(2);
        newDiscountPrice = (parseFloat(newNetValue) / parseFloat(newAmount)).toFixed(2)
        newNetPrice = (parseFloat(newDiscountPrice) / (1 - discount / 100)).toFixed(2)
      }

      invoicePositions[posIndex].amount = newAmount;
      invoicePositions[posIndex].netPrice = newNetPrice;
      invoicePositions[posIndex].discountPrice = parseFloat(newDiscountPrice).toFixed(2)
      invoicePositions[posIndex].netValue = newNetValue;
      invoicePositions[posIndex].grossValue = newGrossValue;

      var summary = {
        netSum: 0,
        vatSum: 0,
        grossSum: 0,
      };

      invoicePositions.map((pos) => {
        if (pos.netValue && pos.grossValue && pos.vat) {
          var vat = isNaN(parseFloat(pos.vat)) ? 0.0 : parseFloat(pos.vat);
          summary.vatSum += parseFloat(pos.netValue) * (vat / 100);
          summary.netSum += parseFloat(pos.netValue);
          summary.grossSum += parseFloat(pos.grossValue);
        }
      });

      this.setState({
        invoicePositions: invoicePositions,
        summary: this.subtractBeforeSummary(summary),
      });
    }
  };

  setUnit = (unit, index) => {
    var { invoicePositions } = this.state;
    invoicePositions[index].unit = unit;

    this.setState({
      unitListToShow: -1,
      invoicePositions: invoicePositions,
    });
  };

  setGtu = (gtu, index) => {
    var { invoicePositions } = this.state;
    invoicePositions[index].gtu = gtu;

    this.setState({
      gtuListToShow: -1,
      invoicePositions: invoicePositions,
    });
  };

  setVat = (vat, index) => {
    var { invoicePositions } = this.state;
    invoicePositions[index].vat = vat;

    this.setState({
      invoicePositions: invoicePositions,
      vatListToShow: -1,
    });

    if (vat === "oo") {
      this.setState({
        reverseCharge: true,
      });
    }

    this.updateSummary({ target: { name: `vat-${index}`, value: vat } });
  };

  setCurrencyMidPrice = async (currency, issueDate) => {
    const date = moment(issueDate).subtract(1, "day");
    const { rate, effectiveDate } = await getCurrencyRateFromNBP(
      currency,
      date
    );
    this.setState({
      currencyEffectiveDate: effectiveDate,
      currencyMidPrice: rate,
      showCurrencyMidPrice: true,
    });
  };

  setCurrency = (currency) => {
    this.setState({
      showCurrencyList: false,
      currency: currency,
    });

    if (currency !== "PLN") {
      this.setCurrencyMidPrice(currency, this.state.saleDate);
    } else {
      this.setState({ showCurrencyMidPrice: false });
    }

    const { accountNumber, accountsList } = this.state;
    var neetToChangeAccountNumber = false;
    var potentialNewAccountNumber = "";
    var potentialBankName = "",
      potentialBankBIC = "";
    accountsList.forEach((a) => {
      if (a.account_number === accountNumber) {
        if (a.currency !== currency) neetToChangeAccountNumber = true;
      }

      if (a.currency === currency && !potentialNewAccountNumber) {
        potentialNewAccountNumber = a.account_number;
        potentialBankName = a.bank_name;
        potentialBankBIC = a.bic;
      }
    });

    if (neetToChangeAccountNumber && potentialNewAccountNumber) {
      this.setState({
        accountNumber: potentialNewAccountNumber,
        bankName: potentialBankName,
        bankBIC: potentialBankBIC,
      });
    }
  };

  selectNewCurrencyDay = (date) => {
    if (this.state.currency !== "PLN") {
      this.setCurrencyMidPrice(
        this.state.currency,
        moment(date).add(1, "day").format("YYYY-MM-DD")
      );
    }
  };

  selectDueDate = (date) => {
    this.setState({
      dueDate: moment(date).format("YYYY-MM-DD"),
    });
  };

  splitPaymentClick = () => {
    var { invoicePositions, splitPayment } = this.state;
    var updateSummary = false;
    for (var i = 0; i < invoicePositions.length; i++) {
      if (isNaN(parseFloat(invoicePositions[i].vat))) {
        invoicePositions[i].vat = "23";
        updateSummary = true;
      }
    }

    this.setState({
      invoicePositions: invoicePositions,
      splitPayment: !splitPayment,
      reverseCharge: false,
    });

    if (updateSummary) {
      for (var i = 0; i < invoicePositions.length; i++) {
        this.updateSummary({ target: { name: `vat-${i}`, value: "oo" } });
      }
    }
  };

  reverseChargeClick = () => {
    var { invoicePositions, reverseCharge } = this.state;

    for (var i = 0; i < invoicePositions.length; i++) {
      if (reverseCharge) invoicePositions[i].vat = "23";
      else invoicePositions[i].vat = "oo";
    }

    this.setState({
      invoicePositions: invoicePositions,
      reverseCharge: !reverseCharge,
      splitPayment: false,
    });

    for (var i = 0; i < invoicePositions.length; i++) {
      this.updateSummary({ target: { name: `vat-${i}`, value: "oo" } });
    }
  };

  metodaKasowaClick = () => {
    var { metodaKasowa } = this.state;

    this.setState({
      metodaKasowa: !metodaKasowa,
    });
  };

  setInvoiceNumber = (month, companyId, docType) => {
    const { sellerId, documentType, numberingSeriesId } = this.state
    if (docType) docType = docType.toLowerCase().replace(" ", "-");
    this.props.getDocumentNumberForNewInvoice(
      month,
      companyId || sellerId,
      docType || documentType.toLowerCase().replace(" ", "-"),
      (nextNumbers, noSeriesDefined) => {
        if (nextNumbers && nextNumbers.length > 0) {
          var choosenSeries = null
          if (numberingSeriesId > -1) {
            choosenSeries = nextNumbers.find(s => s.seriesId === numberingSeriesId)
          }
          if (!choosenSeries) choosenSeries = nextNumbers[0]
          this.setState({
            documentNumber: choosenSeries.number,
            numberingSeriesId: choosenSeries.seriesId,
            allSeriesDocumentNumbers: nextNumbers
          });
        } else {
          var docNumber = "1/" + moment().format("MM/YYYY");
          this.setState({
            documentNumber: docNumber,
          });
        }
      }
    );
  };

  getSavedDocItems = (companyId) => {
    this.props.getSavedDocItems(
      companyId || this.state.sellerId,
      (savedDocItems) => {
        if (savedDocItems) {
          this.setState({
            savedDocItems: savedDocItems,
          });
        }
      }
    );
  };

  getSavedCounterparties = (companyId) => { };

  hasAncestor = (element, id) => {
    while (element) {
      if (element.id && element.id === id) return true;
      element = element.parentNode;
    }
    return false;
  };

  closeAllSuggestions = (e) => {
    if ((e.keyCode === 27 || !this.hasAncestor(e.target, "documentTypeContainer")) && this.state.showDocumentTypeList) {
      this.setState({
        showDocumentTypeList: false,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "documentNumberContainer")) && this.state.showDocumentNumberSeriesList) {
      this.setState({
        showDocumentNumberSeriesList: false,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "documentLanguageContainer")) && this.state.showDocumentLanguageList) {
      this.setState({
        showDocumentLanguageList: false,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "issueDatePickerContainer")) && this.state.showIssueDateCalendar) {
      this.setState({
        showIssueDateCalendar: false,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "saleDatePickerContainer")) && this.state.showSaleDateCalendar) {
      this.setState({
        showSaleDateCalendar: false,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "dueDatePickerContainer")) && this.state.showDueDateCalendar) {
      this.setState({
        showDueDateCalendar: false,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "paymentMethodContainer")) && this.state.showPaymentMethodList) {
      this.setState({
        showPaymentMethodList: false,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "accountNumberContainer")) && this.state.showAccountsList) {
      this.setState({
        showAccountsList: false,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "paymentStatusContainer")) && this.state.showPaymentStatusList) {
      this.setState({
        showPaymentStatusList: false,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "currencyContainer")) && this.state.showCurrencyList) {
      this.setState({
        showCurrencyList: false,
      });
    } else if (this.state.unitListToShow > -1) {
      this.setState({
        unitListToShow: -1,
      });
    } else if (this.state.gtuListToShow > -1) {
      this.setState({
        gtuListToShow: -1,
      });
    } else if (this.state.posListToShow > -1) {
      this.setState({
        posListToShow: -1,
      });
    } else if (this.state.vatListToShow > -1) {
      this.setState({
        vatListToShow: -1,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "popup-card")) && this.state.showVatIDSearchPopup) {
      this.hideSearchVatIdPopup();
    } else if (e.keyCode === 27 || (!this.hasAncestor(e.target, "item counterparty-item") && this.state.showCounterpartiesList)) {
      this.setState({ showCounterpartiesList: false });
    } else if (e.keyCode === 27 || (!(this.hasAncestor(e.target, "currencyCalendarContainer") || this.hasAncestor(e.target, "currencyCalendarButton")) && this.state.showCurrencyCalenrad)) {
      this.setState({ showCurrencyCalenrad: false });
    }
  };

  escFunction = (e) => {
    if (e.keyCode === 27) this.closeAllSuggestions(e);
  };

  setData = () => {
    var company = this.props.currentCompany.company;
    var address = "";
    var zipCode = "";
    var city = "";
    var logoURL = "";
    var logo = "";
    var MPK = ""
    const {
      sale_date,
      account_number,
      currency,
      payment_method,
      payment_status,
      due_date,
      foreign_receiver,
      counterparty_id,
      docItems,
      split_payment,
      reverse_charge,
      issue_place,
      metoda_kasowa,
      total_gross_value,
      total_net_value,
      total_vat_value,
      id,
      currency_rate,
      currency_date,
      currency_table,
      counterparty,
      document_language,
      numbering_series_id,

      buyer_vat_id,
      buyer_name,
      buyer_address,

      wapro_data
    } = this.props.selectedInvoiceData;
    var buyerAddressToSplit = buyer_address

    var buyer = company.counterparties.find(c => c.id === counterparty_id);
    if (!buyerAddressToSplit && buyer) buyerAddressToSplit = buyer.address
    var noDuplicates = [];
    if (buyer && buyer.email) noDuplicates = this.removeDuplicatesFromArray(buyer.email)

    const sAddress = splitAddress(buyerAddressToSplit);
    var totalNetBefore = 0, totalVatBefore = 0, totalGrossBefore = 0
    var showPkwiu = false, showGtu = false, showDiscount = false
    const beforeInvoicePositions = docItems.map((item) => {
      totalNetBefore += parseFloat(item.net_value)
      totalGrossBefore += parseFloat(item.gross_value)
      totalVatBefore += (parseFloat(item.gross_value) - parseFloat(item.net_value))

      if (item.pkwiu) showPkwiu = true
      if (item.gtu) showGtu = true
      if (item.discount) showDiscount = true

      var discountPrice = ""
      if (item.discount) {
        discountPrice = parseFloat(item.discount_price).toFixed(2)
      }
      return {
        name: item.name,
        amount: parseFloat(item.amount).toFixed(0).toString(),
        unit: item.unit,
        netPrice: parseFloat(item.net_price).toFixed(2).toString(),
        discount: item.discount ? parseFloat(item.discount).toFixed(2) : "",
        discountPrice: discountPrice,
        vat: isNaN(parseFloat(item.vat)) ? item.vat : parseFloat(item.vat).toFixed(0).toString(),
        netValue: parseFloat(item.net_value).toFixed(2).toString(),
        grossValue: parseFloat(item.gross_value).toFixed(2).toString(),
        pkwiu: item.pkwiu,
        gtu: item.gtu,
      };
    });
    const invoicePositions = docItems.map((item) => {
      var discountPrice = ""
      if (item.discount) {
        discountPrice = parseFloat(item.discount_price).toFixed(2)
      }
      return {
        name: item.name,
        amount: parseFloat(item.amount).toFixed(0).toString(),
        unit: item.unit,
        netPrice: parseFloat(item.net_price).toFixed(2).toString(),
        discount: item.discount ? parseFloat(item.discount).toFixed(2) : "",
        discountPrice: discountPrice,
        vat: isNaN(parseFloat(item.vat)) ? item.vat : parseFloat(item.vat).toFixed(0).toString(),
        netValue: parseFloat(item.net_value).toFixed(2).toString(),
        grossValue: parseFloat(item.gross_value).toFixed(2).toString(),
        pkwiu: item.pkwiu,
        gtu: item.gtu,
      };
    });
    if (company.address) {
      if (/, [0-9]{2}-[0-9]{3}/.test(company.address)) {
        address = company.address.split(",")[0];
        zipCode = company.address.match(/[0-9]{2}-[0-9]{3}/)
          ? company.address.match(/[0-9]{2}-[0-9]{3}/)[0]
          : "";
        city = company.address.replace(address + ", " + zipCode, "").trim();
      }
    }

    var accountNumber = "",
      bankName = "",
      bankBIC = "";

    if (company.banks && company.banks.length > 0) {
      accountNumber = company.banks[0].account_number;
      bankName = company.banks[0].bank_name;
      bankBIC = company.banks[0].bic;
    }

    if (company.logo && company.logo_url) {
      logoURL = company.logo_url;
      logo = company.logo;
    }

    if (wapro_data && (typeof wapro_data === 'string' || wapro_data instanceof String)) {
      var waproData = JSON.parse(wapro_data)
      MPK = waproData.netAccount
    } else if (wapro_data && typeof wapro_data === 'object') {
      MPK = wapro_data.netAccount
    }

    console.log(MPK)

    this.setState({
      documentLanguage: document_language,
      numberingSeriesId: numbering_series_id || -1,
      sellerId: company.id,
      sellerName: company.name,
      sellerVatId: company.vat_id,
      sellerAddress: address,
      sellerPostCode: zipCode,
      sellerCity: city,
      bankName: bankName,
      bankBIC: bankBIC,
      accountsList: company.banks,
      logoURL: logoURL,
      logo: logo,
      counterpartiesList: company.counterparties,
      documentType: DOCUMENT_TYPE.corrItems,
      documentNumber: "",
      issuePlace: issue_place,
      buyerName: buyer_name ? buyer_name : buyer ? buyer.name : '',
      issueDate: moment().format("YYYY-MM-DD"),
      saleDate: moment(sale_date).format("YYYY-MM-DD"),
      buyerVatId: buyer_vat_id ? buyer_vat_id : buyer ? buyer.vat_id : '',
      buyerAddress: sAddress.address,
      buyerPostCode: sAddress.zipCode,
      buyerCity: sAddress.city,
      buyerCountryCode: buyer ? buyer.country : "",
      buyerEmail: this.concatenationMails(noDuplicates),
      beforeInvoicePositions,
      invoicePositions,
      beforeSummary: {
        netSum: totalNetBefore,
        vatSum: totalVatBefore,
        grossSum: totalGrossBefore,
      },
      showPkwiu: showPkwiu,
      showGtu: showGtu,
      showDiscount: showDiscount,
      showBeforePkwiu: showPkwiu,
      showBeforeGtu: showGtu,
      showBeforeDiscount: showDiscount,
      MPK: MPK,
      paymentMethod: payment_method,
      accountNumber: account_number,
      dueDate: moment(due_date).format("YYYY-MM-DD"),
      paymentStatus: payment_status,
      currency,
      buyerNotFromPoland: foreign_receiver,
      splitPayment: split_payment,
      reverseCharge: reverse_charge,
      metodaKasowa: metoda_kasowa,
      editedInvoiceId: false,
      editingExistingInvoice: false,
      correctedInvoiceId: id,
      currencyMidPrice: currency_rate,
      currencyEffectiveDate: currency_date ? moment(currency_date).format('YYYY-MM-DD') : '',
      currencyTableNumber: currency_table,
      buyerCountryCode: counterparty?.country,
      sendToKsef: false
    });
    this.setInvoiceNumber(
      moment().format("YYYY-MM"),
      company.id,
      this.state.documentType
    );
  };

  splitEmails = (text) => {
    return text.replaceAll(";", ",").replaceAll(" ", "").split(",");
  };

  removeDuplicatesFromArray = (data) => {
    const noDuplicates = [];
    data.map((element) => {
      if (!noDuplicates.includes(element)) {
        noDuplicates.push(element);
      }
    });
    return noDuplicates;
  };

  concatenationMails = (arrayMail) => {
    let concatenatedMails = "";
    arrayMail.map((email, index) => {
      if (arrayMail.length === 1) concatenatedMails = `${email}`;
      if (arrayMail.length > 1) {
        if (index === 0) {
          concatenatedMails = `${email} ,`;
        } else if (index < arrayMail.length - 1) {
          concatenatedMails += `${email} ,`;
        }
        if (index === arrayMail.length - 1) concatenatedMails += `${email}`;
      }
    });
    return concatenatedMails;
  };

  validateInvoiceData = () => {
    var valid = true;
    const {
      documentNumber,
      issueDate,
      saleDate,
      sellerName,
      sellerVatId,
      sellerAddress,
      sellerPostCode,
      sellerCity,
      buyerName,
      buyerVatId,
      buyerAddress,
      buyerPostCode,
      buyerCity,
      buyerCountryCode,
      buyerNotFromPoland,
      buyerEmail,
      invoicePositions,
      paymentMethod,
      accountNumber,
      dueDate,
      paymentStatus,
      reasonForCorrection,
    } = this.state;

    if (!documentNumber) {
      valid = false;
      this.props.alertWarn(this.context.t("Document number is required."));
    }

    if (!issueDate) {
      valid = false;
      this.props.alertWarn(this.context.t("Issue date is required."));
    }

    // if(!issuePlace) {
    //     valid = false
    //     this.props.alertWarn(this.context.t('Issue place is required.'))
    // }

    if (!saleDate) {
      valid = false;
      this.props.alertWarn(this.context.t("Sale date is required."));
    }

    if (!sellerName) {
      valid = false;
      this.props.alertWarn(this.context.t("Seller name is required."));
    }

    if (!sellerVatId) {
      valid = false;
      this.props.alertWarn(this.context.t("Seller VatID is required."));
    }

    if (!sellerAddress) {
      valid = false;
      this.props.alertWarn(this.context.t("Seller address is required."));
    } else if (!sellerCity) {
      valid = false;
      this.props.alertWarn(this.context.t("Seller address is required."));
    }

    if (!sellerPostCode) {
      valid = false;
      this.props.alertWarn(this.context.t("Seller ZIP code is required."));
    } else if (!/[0-9]{2}-[0-9]{3}/.test(sellerPostCode)) {
      valid = false;
      this.props.alertWarn(this.context.t("Seller ZIP code is invalid."));
    }

    if (!buyerName) {
      valid = false;
      this.props.alertWarn(this.context.t("Buyer name is required."));
    }

    if (reasonForCorrection === "") {
      valid = false;
      this.props.alertWarn(
        this.context.t("Reason for correction is required.")
      );
    }

    if (!buyerNotFromPoland) {
      if (!buyerPostCode) {
        valid = false;
        this.props.alertWarn(this.context.t("Buyer ZIP code is required."));
      } else if (!/[0-9]{2}-[0-9]{3}/.test(buyerPostCode)) {
        valid = false;
        this.props.alertWarn(this.context.t("Buyer ZIP code is invalid."));
      }
    } else {
      if (!buyerCountryCode) {
        valid = false;
        this.props.alertWarn(this.context.t("Buyer country code is required."));
      } else if (!/[A-Z]{2}/.test(buyerCountryCode)) {
        valid = false;
        this.props.alertWarn(
          this.context.t(
            "Buyer country code is invalid. The country code should consist of two capital letters. Find out more by hovering over the question mark icon."
          )
        );
      }
    }

    invoicePositions.forEach((p) => {
      if (
        !p.name ||
        !p.amount ||
        !p.netPrice ||
        !p.vat ||
        !p.netValue ||
        !p.grossValue
      ) {
        valid = false;
        this.props.alertWarn(this.context.t("Invoice item is incomplete."));
      }
    });

    // paymentMethod, accountNumber, dueDate, paymentStatus

    if (paymentMethod === "transfer" && !accountNumber) {
      valid = false;
      this.props.alertWarn(this.context.t("Account number is required."));
    }

    if (paymentStatus === "unpaid" && !dueDate) {
      valid = false;
      this.props.alertWarn(this.context.t("Due date is required."));
    }

    return valid;
  };

  clearForm = () => {
    this.setState({
      documentNumber: "",
      issueDate: moment().format("YYYY-MM-DD"),
      saleDate: moment().format("YYYY-MM-DD"),
      searchBuyerVatId: "",
      buyerName: "",
      buyerVatId: "",
      buyerAddress: "",
      buyerPostCode: "",
      buyerCity: "",
      buyerCountryCode: "",
      invoicePositions: [
        {
          name: "",
          amount: 1,
          unit: "szt.",
          netPrice: "",
          vat: "23",
          netValue: "",
          grossValue: "",
          pkwiu: "",
          gtu: "",
        },
      ],
      summary: {
        netSum: 0,
        vatSum: 0,
        grossSum: 0,
      },
      paymentMethod: "transfer",
      dueDate: moment().add(7, "days").format("YYYY-MM-DD"),
      paymentStatus: "unpaid",
      currency: "PLN",
      savedInvoicePositions: [],
      unitListToShow: -1,
      gtuListToShow: -1,
      posListToShow: -1,
      buyerNotFromPoland: false,
      splitPayment: false,
      reverseCharge: false,
      metodaKasowa: false,
      buyerEmail: "",

      sendToKsef: false
    });
  };

  checkCorrectInvoiceNumber = async () => {
    const {
      issueDate,
      documentType,
      documentNumber,
      numberingSeriesId,
    } = this.state;
    const { currentCompany } = this.props;
    const month = moment(issueDate).format("YYYY-MM")
    const invoiceNumber = documentNumber
    const companyId = currentCompany.company_id
    const docType = documentType.toLocaleLowerCase().replace(" ", "-")

    await this.props.checkCorrectInvoiceNumber(month, invoiceNumber, companyId, docType, numberingSeriesId)
  }

  generateInvoice = async () => {
    if (this.validateInvoiceData()) {
      const {
        documentType,
        documentNumber,
        numberingSeriesId,
        documentLanguage,
        issueDate,
        issuePlace,
        saleDate,
        sellerName,
        sellerVatId,
        sellerAddress,
        sellerPostCode,
        sellerCity,
        sellerId,
        buyerName,
        buyerVatId,
        buyerAddress,
        buyerPostCode,
        buyerCity,
        buyerCountryCode,
        buyerEmail,
        invoicePositions,
        summary,
        paymentMethod,
        accountNumber,
        bankName,
        bankBIC,
        dueDate,
        paymentStatus,
        currency,
        logo,
        buyerNotFromPoland,
        splitPayment,
        reverseCharge,
        metodaKasowa,
        editedInvoiceId,
        editingExistingInvoice,
        reasonForCorrection,
        MPK,

        currencyMidPrice,
        currencyEffectiveDate,
        currencyTableNumber,

        sendToKsef
      } = this.state;

      if(!editingExistingInvoice){

        await this.checkCorrectInvoiceNumber()
        if (this.props.isNewInvoiceNumberPopupOpen) return
      }


      var invoiceData = {
        docType: documentType.toLocaleLowerCase().replace(" ", "-"),
        documentNumber: documentNumber,
        numberingSeriesId: numberingSeriesId,
        documentLanguage: documentLanguage || 'pl',
        issueDate: issueDate,
        issuePlace: issuePlace,
        saleDate: saleDate,
        sellerName: sellerName,
        sellerVatId: sellerVatId,
        sellerAddress: sellerAddress,
        sellerPostCode: sellerPostCode,
        sellerCity: sellerCity,
        buyerName: buyerName,
        buyerVatId: buyerVatId,
        buyerAddress: buyerAddress,
        buyerPostCode: buyerPostCode,
        buyerCity: buyerCity,
        buyerCountryCode: buyerCountryCode,
        buyerEmail: buyerEmail,
        invoicePositions: invoicePositions,
        summary: summary,
        paymentMethod: paymentMethod,
        accountNumber: accountNumber,
        bankName: bankName,
        bankBIC: bankBIC,
        dueDate: dueDate,
        paymentStatus: paymentStatus,
        currency: currency,
        logo: logo,
        buyerNotFromPoland: buyerNotFromPoland,
        splitPayment: splitPayment,
        reverseCharge: reverseCharge,
        metodaKasowa: metodaKasowa,
        reasonForCorrection: reasonForCorrection,
        correctedInvoiceData: this.props.selectedInvoiceData,
        mpk: MPK,

        currencyRate: currencyMidPrice,
        currencyDate: currencyEffectiveDate,
        currencyTable: currencyTableNumber,

        sendToKsef: sendToKsef,
      };
      if (editingExistingInvoice) {
        this.props.editInvoice(invoiceData, this.state.sellerId, editedInvoiceId, (success, fileBody, fileName, id) => {
          if (success) {
            this.setState({
              fileBody,
              fileName,
              editedInvoiceId: id,
            });
            this.props.toggleShowCorrectionItemForm();
          }
        });
      } else {
        this.props.generateInvoice(invoiceData, this.state.sellerId, (success, fileBody, fileName, id, generatedInvoice) => {
          if (success) {
            this.setState({
              fileBody,
              fileName,
              editedInvoiceId: id,
            });
            this.props.openInvoicePreview(fileBody, fileName, generatedInvoice)
            this.props.toggleShowCorrectionItemForm();
          }
        });
        this.setState({ editingExistingInvoice: false });
      }
    }
  };

  showDatePicker = (e, name) => {
    if (name === "issueDate") {
      this.setState({
        showIssueDateCalendar: true,
      });
    } else if (name === "dueDate") {
      this.setState({
        showDueDateCalendar: true,
      });
    } else if (name === "saleDate") {
      this.setState({
        showSaleDateCalendar: true,
      });
    }
  };

  getFullLanguageName = langCode => {
    if (langCode === 'pl') return 'Polish'
    else return 'English'
  }

  setDocNumberSeries = series => {
    this.setState({
      documentNumber: series.number,
      numberingSeriesId: series.seriesId,
      showDocumentNumberSeriesList: false,
    })
  }

  setDocLanguage = langCode => {
    this.setState({
      documentLanguage: langCode,
      showDocumentLanguageList: false
    })
  }

  selectIssueDate = (date) => {
    this.setState({
      issueDate: moment(date).format("YYYY-MM-DD"),
    });

    if (moment(date).format("YYYY-MM") !== moment().format("YYYY-MM")) {
      this.setInvoiceNumber(
        moment(date).format("YYYY-MM"),
        null,
        this.state.documentType
      );
    }
  };

  deleteInvoicePossition = (index) => {
    var { invoicePositions } = this.state;
    var position = invoicePositions[index];

    invoicePositions.splice(index, 1);
    if (position.netPrice) {
      var summary = {
        netSum: 0,
        vatSum: 0,
        grossSum: 0,
      };

      invoicePositions.map((pos) => {
        if (pos.netValue && pos.grossValue && pos.vat) {
          var vat = isNaN(parseFloat(pos.vat)) ? 0.0 : parseFloat(pos.vat);
          summary.vatSum += parseFloat(pos.netValue) * (vat / 100);
          summary.netSum += parseFloat(pos.netValue);
          summary.grossSum += parseFloat(pos.grossValue);
        }
      });

      this.setState({
        summary: this.subtractBeforeSummary(summary),
      });
    }

    this.setState({
      invoicePositions: invoicePositions,
    });
  };

  generateCode = (name) => {
    var indexOfComma = name.indexOf(",");

    if (indexOfComma > 0) {
      var tempName = name.substring(0, indexOfComma);
      tempName = tempName.trim();

      if ((tempName.match(/\"/gm) || []).length !== 1) {
        name = tempName;
      }
    }

    if (name.includes('"')) {
      var newName = name.match(/\"(.*)\"/g) || [];
      newName = newName.find((x) => x !== undefined);

      if (newName) {
        newName = newName.replace(/\"/gm, "");
        newName = newName.trim();
        var wordsArray = newName.split(" ");
        if (wordsArray.length === 1) {
          newName = wordsArray[0];
          var indexOfDash = newName.lastIndexOf("-");
          if (indexOfDash > 0 && newName.length > 20) {
            newName = newName.substring(0, indexOfDash);

            return newName.substring(0, 20);
          } else {
            return newName.substring(0, 20);
          }
        } else {
          var code = "";
          wordsArray.forEach((w) => {
            if (w.length > 2 && !w.match(/^\d/)) {
              code += w[0];
            } else {
              code += w;
            }
          });

          return code;
        }
      } else {
        return "";
      }
    } else {
      var wordsArray = name.split(" ");
      if (wordsArray.length === 1) {
        newName = wordsArray[0];
        var indexOfDash = newName.lastIndexOf("-");
        if (indexOfDash > 0) {
          newName = newName.substring(0, indexOfDash);

          return newName.substring(0, 20);
        } else {
          return newName.substring(0, 20);
        }
      } else if (wordsArray.length === 2) {
        var part1 = wordsArray[0];
        var part2 = wordsArray[1];

        if (part1.length + part2.length <= 19) {
          return part1 + "-" + part2;
        } else {
          if (part1.includes("-")) {
            return part1;
          } else if (part2.includes("-")) {
            return part2;
          } else {
            return part1 + "-" + part2.substring(0, 19 - part1.length);
          }
        }
      } else {
        var code = "";
        wordsArray.forEach((w) => {
          if (w.length > 2 && !w.match(/^\d/)) {
            code += w[0];
          } else {
            code += w;
          }
        });

        return code;
      }
    }
  };

  hideSearchVatIdPopup = () => {
    window.removeEventListener("keydown", this.searchOnEnter, false);
    this.setState({
      showVatIDSearchPopup: false,
      searchBuyerVatId: "",
    });
  };

  searchOnEnter = (e) => {
    if (e.keyCode === 13) this.searchVatAPI();
  };

  foundAtLeastOneSimilarCounterparty = (text) => {
    if (text.length < 2) return false;
    const { counterpartiesList } = this.state;
    var counterpartiesCount = counterpartiesList.length;

    if (!text) {
      return false;
    }

    for (var i = 0; i < counterpartiesCount; i++) {
      if (
        text.length === 1 &&
        counterpartiesList[i].name &&
        counterpartiesList[i].name.toLowerCase().startsWith(text.toLowerCase())
      ) {
        return true;
      } else if (
        text.length > 1 &&
        counterpartiesList[i].name &&
        counterpartiesList[i].name.toLowerCase().includes(text.toLowerCase())
      ) {
        return true;
      }
    }

    return false;
  };

  checkIfCounterpartyNameIsSimilar = (text, name) => {
    if (!text) {
      return false;
    }

    if (
      text.length === 1 &&
      name &&
      name.toLowerCase().startsWith(text.toLowerCase())
    ) {
      return true;
    } else if (
      text.length > 1 &&
      name &&
      name.toLowerCase().includes(text.toLowerCase())
    ) {
      return true;
    }

    return false;
  };

  hideInvoicePreview = () => {
    this.setState({ showInvoicePreviewPopup: false });
  };

  setEditingMode = () => {
    this.setState({
      editingExistingInvoice: true,
    });
  };

  setCountryCode = (code) => {
    this.setState({
      buyerCountryCode: code,
      showCountriesList: false
    })
  }

  closePopupAndDeleteInvoiceNumber = () => {
    this.props.closePopupAndDeleteInvoiceNumber()
  }

  closePopupAndSetNewInvoiceNumber = (newInvoiceNumber) => {
    this.props.closePopupAndDeleteInvoiceNumber()
    this.setState({
      documentNumber: newInvoiceNumber
    })
  }

  render() {
    const {
      documentNumber,
      allSeriesDocumentNumbers,
      showDocumentNumberSeriesList,
      documentLanguage,
      showDocumentLanguageList,
      issueDate,
      saleDate,
      sellerName,
      sellerVatId,
      sellerAddress,
      sellerPostCode,
      sellerCity,
      searchBuyerVatId,
      waitingForVatApiReponse,
      buyerName,
      buyerVatId,
      buyerAddress,
      buyerPostCode,
      buyerCity,
      buyerCountryCode,
      invoicePositions,
      summary,
      paymentMethod,
      accountNumber,
      dueDate,
      paymentStatus,
      currency,
      logo,
      logoURL,
      showVatIDSearchPopup,
      showIssueDateCalendar,
      showSaleDateCalendar,
      showDueDateCalendar,
      showPaymentStatusList,
      showPaymentMethodList,
      unitListToShow,
      gtuListToShow,
      posListToShow,
      vatListToShow,
      savedDocItems,
      showCurrencyList,
      buyerNotFromPoland,
      splitPayment,
      reverseCharge,
      metodaKasowa,
      showAccountsList,
      accountsList,
      buyerEmail,
      showCounterpartiesList,
      counterpartiesList,
      showInvoicePreviewPopup,
      fileBody,
      fileName,
      currencyEffectiveDate,
      currencyMidPrice,
      editedInvoiceId,
      beforeInvoicePositions,
      showReasonForCorrectionList,
      reasonForCorrection,
      showCurrencyCalenrad,
      showCountriesList,

      showBeforePkwiu, showBeforeGtu, showBeforeDiscount,
      showPkwiu, showGtu, showDiscount,

      sendToKsef
    } = this.state;

    const { doc_number } = this.props.selectedInvoiceData;

    const {
      user,
      tabIsActive,
      currentCompany,
      toggleShowCorrectionItemForm,
      isNewInvoiceNumberPopupOpen,
      newInvoiceNumber
    } = this.props;

    if (tabIsActive || !user.new_easydocs_version) {
      return (
        <div className={`background-container-item ${user.new_easydocs_version ? 'new-easydocs-version' : ''}`}>
          <div className="generate-invoide-container" onClick={(e) => this.closeAllSuggestions(e)}>
            {
              showVatIDSearchPopup ? (
                <div className="">
                  <div id="popup-card" className="popup-card">
                    <div className="header">
                      {this.context.t(
                        "Enter the contractor's NIP (tax identification number) to download his data from the Central Statistical Office database"
                      )}
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="searchBuyerVatId"
                        id="searchBuyerVatId"
                        value={searchBuyerVatId}
                        onChange={this.onInputChange}
                      />
                    </div>
                    <div className="search-btn" onClick={() => this.searchVatAPI()}>
                      {waitingForVatApiReponse ? (
                        <div className="loader">
                          <Spinner size={20} speed={0.8} color={"#ffffff"} />
                        </div>
                      ) : (
                        this.context.t("Search")
                      )}
                    </div>
                  </div>
                </div>
              ) : null
            }
            {
            isNewInvoiceNumberPopupOpen ? (
              <NewInvoiceNumberPopUp
                newInvoiceNumber={newInvoiceNumber}
                close={this.closePopupAndDeleteInvoiceNumber}
                save={this.closePopupAndSetNewInvoiceNumber}
              />
            ) : null
          }
            {
              showInvoicePreviewPopup ? (
                <InvoicePreview
                  fileBody={fileBody}
                  fileName={fileName}
                  hideInvoicePreview={this.hideInvoicePreview}
                  hideInvoiceCreatorOverlay={toggleShowCorrectionItemForm}
                  setEditingMode={this.setEditingMode}
                  email={buyerEmail}
                  editedInvoiceId={editedInvoiceId}
                  invoiceData={{
                    id: editedInvoiceId,
                    doc_number: documentNumber,
                    issue_date: issueDate,
                    currency: currency,
                    counterparty: {
                      name: buyerName,
                      vat_id: buyerVatId
                    }
                  }}
                />
              ) : null
            }
            <div className="content">
              <div className="header-container">
                <h2>
                  {this.context.t("Giving correcting invoice", {
                    n:
                      currentCompany && currentCompany.company
                        ? currentCompany.company.code
                        : "",
                  })}
                </h2>
                <div
                  className="hide-button"
                  onClick={() => toggleShowCorrectionItemForm()}
                ></div>
              </div>
              <SimpleBar style={{ maxHeight: user.new_easydocs_version ? 'unset' : 'calc(90vh - 192px)', width: "calc(100% + 20px)", marginRight: '-20px', paddingRight: '20px' }}>
                {
                  <div className="header-row">
                    <div className="form-group">
                      <input
                        className={`disabled`}
                        name="correctingDocumentNumber"
                        id={`correctingDocumentNumber`}
                        value={doc_number}
                      />
                      <label className={`disabled`} htmlFor="correctingDocumentNumber">{this.context.t("Correcting document number")}</label>
                    </div>
                    <div id="documentNumberContainer" className="form-group" onClick={() => this.setState({ showDocumentNumberSeriesList: !showDocumentNumberSeriesList })}>
                      <input
                        className={``}
                        name="documentNumber"
                        id={`documentNumber`}
                        value={documentNumber}
                        onChange={this.onInputChange}
                      />
                      <label htmlFor="documentNumber" onClick={() => this.setState({ showDocumentNumberSeriesList: true })}>{this.context.t("Document number")}</label>

                      {
                        allSeriesDocumentNumbers.length > 1 ? (
                          <>
                            <span className={`expend-icon ${showDocumentNumberSeriesList ? "expended" : ""}`}></span>
                            {
                              showDocumentNumberSeriesList ? (
                                <div className="sugestions-list down">
                                  <ul>
                                    {
                                      allSeriesDocumentNumbers.map(serise => {
                                        return (
                                          <li onClick={() => this.setDocNumberSeries(serise)}>{serise.number}</li>
                                        )
                                      })
                                    }
                                  </ul>
                                </div>
                              ) : null
                            }
                          </>
                        ) : null
                      }
                    </div>
                    <div id="documentLanguageContainer" className="form-group" onClick={() => this.setState({ showDocumentLanguageList: !showDocumentLanguageList })}>
                      <input
                        className={``}
                        name="documentLanguage"
                        id={`documentLanguage`}
                        value={this.context.t(this.getFullLanguageName(documentLanguage))}
                        readOnly={true}
                      />

                      <label htmlFor="documentLanguageContainer" onClick={() => this.setState({ showDocumentLanguageList: true })}>{this.context.t("Document language")}</label>
                      <span className={`expend-icon ${showDocumentLanguageList ? "expended" : ""}`}></span>

                      {
                        showDocumentLanguageList ? (
                          <div className="sugestions-list down">
                            <ul>
                              <li onClick={() => this.setDocLanguage("pl")}>
                                {this.context.t("Polish")}
                              </li>
                              <li onClick={() => this.setDocLanguage("en")}>
                                {this.context.t("English")}
                              </li>
                            </ul>
                          </div>
                        ) : null
                      }
                    </div>
                    <div id="issueDatePickerContainer" className="form-group">
                      <input
                        className={``}
                        name="issueDate"
                        id={`issueDate`}
                        value={issueDate}
                        onClick={(e) => this.showDatePicker(e, "issueDate")}
                      />
                      <label htmlFor="issueDate" onClick={(e) => this.showDatePicker(e, "issueDate")}>{this.context.t("Issue date correction invoice")}</label>
                      <span className="calendar-icon" onClick={(e) => this.showDatePicker(e, "issueDate")}></span>
                      {
                        showIssueDateCalendar ? (
                          <div className={`date-picker ${showIssueDateCalendar ? "show" : ""}`} onClick={(e) => this.clickOnDatePicker(e, "issueDate")}>
                            <Calendar
                              selectDay={this.selectIssueDate}
                              selectedDay={moment(issueDate)}
                              projectId={"none"}
                              startDate={moment(issueDate) || moment()}
                              arrowsOnLeft={false}
                            />
                          </div>
                        ) : null
                      }
                    </div>
                    <div id="saleDatePickerContainer" className="form-group">
                      <input
                        className={``}
                        name="saleDate"
                        id={`saleDate`}
                        value={saleDate}
                        onClick={(e) => this.showDatePicker(e, "saleDate")}
                      />
                      <label htmlFor="saleDate" onClick={(e) => this.showDatePicker(e, "saleDate")}>{this.context.t("Sale date correction invoice")}</label>
                      <span className="calendar-icon" onClick={(e) => this.showDatePicker(e, "saleDate")}></span>

                      {
                        showSaleDateCalendar ? (
                          <div className={`date-picker ${showSaleDateCalendar ? "show" : ""}`} onClick={(e) => this.clickOnDatePicker(e, "saleDate")}>
                            <Calendar
                              selectDay={this.selectSaleDate}
                              selectedDay={moment(saleDate)}
                              projectId={"none"}
                              startDate={moment(saleDate) || moment()}
                              arrowsOnLeft={false}
                            />
                          </div>
                        ) : null
                      }
                    </div>
                  </div>
                }

                <div className="seller-and-buyer-row">
                  <div className="seller">
                    <div className="form-group">
                      <input
                        className={`no-label`}
                        name="sellerName"
                        id={`sellerName`}
                        value={sellerName}
                        onChange={this.onInputChange}
                      />
                      <label htmlFor="buyerName">{this.context.t("Name")}</label>
                      <label className="big-label" htmlFor="sellerName">
                        {this.context.t("Seller")}
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        className={``}
                        name="sellerVatId"
                        id={`sellerVatId`}
                        value={sellerVatId}
                        onChange={this.onInputChange}
                      />
                      <label htmlFor="sellerVatId">
                        {this.context.t("VatID")}
                      </label>
                    </div>
                    <div className="form-group">
                      <input
                        className={``}
                        name="sellerAddress"
                        id={`sellerAddress`}
                        value={sellerAddress}
                        onChange={this.onInputChange}
                      />
                      <label htmlFor="sellerAddress">
                        {this.context.t("Address")}
                      </label>
                    </div>
                    <div className="form-group small">
                      <input
                        className={``}
                        name="sellerPostCode"
                        id={`sellerPostCode`}
                        value={sellerPostCode}
                        onChange={this.onInputChange}
                      />
                      <label htmlFor="sellerPostCode">
                        {this.context.t("ZIP code")}
                      </label>
                    </div>
                    <div className="form-group fill-rest">
                      <input
                        className={``}
                        name="sellerCity"
                        id={`sellerCity`}
                        value={sellerCity}
                        onChange={this.onInputChange}
                      />
                      <label htmlFor="sellerCity">
                        {this.context.t("City ")}
                      </label>
                    </div>
                  </div>

                  <div className="buyer">
                    <div
                      className={`get-data ${buyerNotFromPoland ? "hide" : ""}`}
                      onClick={() => this.showSearchVatIdPopup()}
                    >
                      {this.context.t("Load data by VatID")}
                    </div>
                    <div className="form-group">
                      <input
                        className={`no-label`}
                        name="buyerName"
                        id={`buyerName`}
                        value={buyerName}
                        onChange={this.onInputChange}
                        onClick={() => this.setState({ showCounterpartiesList: true })}
                      />
                      <label htmlFor="buyerName">{this.context.t("Name")}</label>
                      <label className="big-label" htmlFor="buyerName">
                        {this.context.t("Buyer")}
                      </label>
                      <span className="search-icon" />
                      {counterpartiesList.length > 0 &&
                        this.foundAtLeastOneSimilarCounterparty(buyerName) &&
                        showCounterpartiesList ? (
                        <div className="counterparties-list">
                          <SimpleBar
                            style={{
                              height: "auto",
                              maxHeight: "300px",
                              width: "100%",
                            }}
                          >
                            <ul>
                              {counterpartiesList.map((c) => {
                                if (
                                  this.checkIfCounterpartyNameIsSimilar(
                                    buyerName,
                                    c.name
                                  )
                                ) {
                                  return (
                                    <li
                                      tabIndex={"0"}
                                      className={`item counterparty-item`}
                                      onClick={() => {
                                        let sAddress;
                                        if (c.address !== null)
                                          sAddress = splitAddress(c.address);
                                        let emails;
                                        if (c.email)
                                          emails = this.concatenationMails(
                                            c.email
                                          );
                                        this.setState({
                                          showCounterpartiesList: false,
                                          buyerName: c.name ? c.name : "",
                                          buyerVatId: c.vat_id ? c.vat_id : "",
                                          buyerAddress: sAddress
                                            ? sAddress.address
                                            : "",
                                          buyerPostCode: sAddress
                                            ? sAddress.zipCode
                                            : "",
                                          buyerCity: sAddress
                                            ? sAddress.city
                                            : "",
                                          buyerEmail: emails ? emails : "",
                                          buyerCountryCode: c?.country,
                                          buyerNotFromPoland: c && c.country && c.country !== 'PL'
                                        });
                                      }}
                                    >
                                      {c.name}
                                    </li>
                                  );
                                } else {
                                  return null;
                                }
                              })}
                            </ul>
                          </SimpleBar>
                        </div>
                      ) : showCounterpartiesList ? (
                        <div className="counterparties-list">
                          <SimpleBar
                            style={{
                              height: "auto",
                              maxHeight: "300px",
                              width: "100%",
                            }}
                          >
                            <ul>
                              {counterpartiesList.map((c) => {
                                return (
                                  <li
                                    tabIndex={"0"}
                                    className={`item counterparty-item`}
                                    onClick={() => {
                                      var sAddress;
                                      if (c.address !== null)
                                        sAddress = splitAddress(c.address);
                                      var emails;
                                      if (c.email)
                                        emails = this.concatenationMails(c.email);
                                      this.setState({
                                        showCounterpartiesList: false,
                                        buyerName: c.name ? c.name : "",
                                        buyerVatId: c.vat_id ? c.vat_id : "",
                                        buyerAddress: sAddress
                                          ? sAddress.address
                                          : "",
                                        buyerPostCode: sAddress
                                          ? sAddress.zipCode
                                          : "",
                                        buyerCity: sAddress ? sAddress.city : "",
                                        buyerEmail: emails ? emails : "",
                                        buyerCountryCode: c?.country,
                                      });
                                    }}
                                  >
                                    {c.name}
                                  </li>
                                );
                              })}
                            </ul>
                          </SimpleBar>
                        </div>
                      ) : null
                      }
                    </div>
                    {buyerNotFromPoland ? (
                      <div className="form-group">
                        <input
                          className={``}
                          name="buyerVatId"
                          id={`buyerVatId`}
                          value={buyerVatId}
                          onChange={this.onInputChange}
                        />
                        <label htmlFor="buyerVatId">
                          {this.context.t("Company ID")}
                        </label>
                      </div>
                    ) : (
                      <div className="form-group">
                        <input
                          className={``}
                          name="buyerVatId"
                          id={`buyerVatId`}
                          value={buyerVatId}
                          onChange={this.onInputChange}
                        />
                        <label htmlFor="buyerVatId">
                          {this.context.t("VatID")}
                        </label>
                      </div>
                    )}
                    <div
                      className={`form-group ${buyerNotFromPoland ? "fill-rest" : ""
                        }`}
                    >
                      <input
                        className={``}
                        name="buyerAddress"
                        id={`buyerAddress`}
                        value={buyerAddress}
                        onChange={this.onInputChange}
                      />
                      <label htmlFor="buyerAddress">
                        {this.context.t("Address")}
                      </label>
                    </div>
                    {buyerNotFromPoland ? (
                      <div className="form-group mid ml">
                        <input
                          className={``}
                          name="buyerCountryCode"
                          id={`buyerCountryCode`}
                          value={buyerCountryCode}
                          readOnly={true}
                          onChange={this.onInputChange}
                          onClick={() => this.setState({ showCountriesList: true })}
                        />
                        <label htmlFor="buyerCountryCode">
                          {this.context.t("Country code (ISO 3166-1 alpha-2)")}
                        </label>
                        {showCountriesList ? <CountriesList
                          headerText={this.context.t('Country:')}
                          selectedOption={buyerCountryCode}
                          select={(code) => this.setCountryCode(code)}
                        /> : null}
                        <span className={`expend-icon ${showCountriesList ? "expended" : ""}`} onClick={() => this.setState({ showCountriesList: true })} ></span>
                      </div>
                    ) : null}
                    {!buyerNotFromPoland ? (
                      <>
                        <div className="form-group small">
                          <input
                            className={``}
                            name="buyerPostCode"
                            id={`buyerPostCode`}
                            value={buyerPostCode}
                            onChange={this.onInputChange}
                          />
                          <label htmlFor="buyerPostCode">
                            {this.context.t("ZIP code")}
                          </label>
                        </div>
                        <div className="form-group fill-rest">
                          <input
                            className={``}
                            name="buyerCity"
                            id={`buyerCity`}
                            value={buyerCity}
                            onChange={this.onInputChange}
                          />
                          <label htmlFor="buyerCity">
                            {this.context.t("City ")}
                          </label>
                        </div>
                      </>
                    ) : null}
                    <div
                      className="checkbox-group"
                      onClick={() =>
                        this.setState({ buyerNotFromPoland: !buyerNotFromPoland })
                      }
                    >
                      <div
                        className={`checkbox ${buyerNotFromPoland ? "checked" : ""
                          }`}
                      ></div>
                      <div className="label">
                        {this.context.t("The buyer is not from Poland")}
                      </div>
                    </div>
                  </div>
                </div>
                {/* //--------------- przed korektą --------------------- */}
                <div className="invoice-possitions">
                  <div className="description">
                    {this.context.t("Before correction")}
                  </div>
                  {beforeInvoicePositions.map((p, i) => {
                    return (
                      <div className="possition" key={`invoice-possition-${i}`}>
                        <div className="form-group name">
                          <input
                            className={`before-name disabled`}
                            name={`before-name-${i}`}
                            id={`before-name-${i}`}
                            value={p.name}
                            readOnly={true}
                            cursor="default"
                          />
                          <label className="disabled" htmlFor={`before-name-${i}`} >
                            {this.context.t("Name")}
                          </label>
                        </div>
                        {
                          showBeforePkwiu ? (
                            <div className="form-group pkwiu">
                              <input
                                className={`before-pkwiu disabled`}
                                name={`before-pkwiu-${i}`}
                                id={`before-pkwiu-${i}`}
                                value={p.pkwiu ? p.pkwiu : ""}
                                readOnly={true}
                                cursor="default"
                              />
                              <label className="disabled" htmlFor={`before-p.pkwiu-${i}`}>
                                {this.context.t("PKWiU")}
                              </label>
                            </div>
                          ) : null
                        }
                        {
                          showBeforeGtu ? (
                            <div className="form-group gtu">
                              <input
                                className={`before-GTU disabled`}
                                name={`before-GTU-${i}`}
                                id={`before-GTU-${i}`}
                                value={p.gtu ? p.gtu : ""}
                                readOnly={true}
                              />
                              <label className="disabled" htmlFor={`before-GTU-${i}`}>
                                {this.context.t("GTU")}
                              </label>
                            </div>
                          ) : null
                        }
                        <div className="form-group amount">
                          <input
                            className={`before-amount disabled`}
                            name={`before-amount-${i}`}
                            id={`before-amount-${i}`}
                            value={p.amount.toString()}
                            readOnly={true}
                            cursor="default"
                          />
                          <label
                            className="disabled"
                            htmlFor={`before-amount-${i}`}
                          >
                            {this.context.t("Amount ")}
                          </label>
                        </div>
                        <div className="form-group unit">
                          <input
                            className={`before-unit disabled`}
                            name={`before-unit-${i}`}
                            id={`before-unit-${i}`}
                            value={p.unit}
                            readOnly={true}
                          />
                          <label
                            className="disabled"
                            htmlFor={`before-unit-${i}`}
                          >
                            {this.context.t("Unit")}
                          </label>
                        </div>
                        <div className="form-group netPrice">
                          <input
                            className={`before-netPrice disabled`}
                            name={`before-netPrice-${i}`}
                            id={`before-netPrice-${i}`}
                            value={p.netPrice}
                            readOnly={true}
                          />
                          <label
                            className="disabled"
                            htmlFor={`before-netPrice-${i}`}
                          >
                            {this.context.t("Net price")}
                          </label>
                        </div>
                        {
                          showBeforeDiscount ? (
                            <>
                              <div className="form-group vat">
                                <input
                                  className={`discount disabled`}
                                  value={p.discount}
                                  readOnly={true}
                                />
                                <label htmlFor={`discount-${i}`}>
                                  {`${this.context.t("Discount")} (%)`}
                                </label>
                              </div>
                              <div className="form-group discountPrice">
                                <input
                                  className={`discountPrice disabled`}
                                  value={p.discountPrice}
                                  readOnly={true}
                                />
                                <label>
                                  {this.context.t("Net price after discount")}
                                </label>
                              </div>
                            </>
                          ) : null
                        }
                        <div className="form-group vat">
                          <input
                            className={`before-vat disabled`}
                            name={`before-vat-${i}`}
                            id={`before-vat-${i}`}
                            value={p.vat}
                            readOnly={true}
                          />
                          <label className="disabled" htmlFor={`before-vat-${i}`}>
                            {this.context.t("VAT rate")}
                          </label>
                        </div>
                        <div className="form-group netValue">
                          <input
                            className={`before-netValue disabled`}
                            name={`before-netValue-${i}`}
                            id={`before-netValue-${i}`}
                            value={p.netValue}
                            readOnly={true}
                          />
                          <label
                            className="disabled"
                            htmlFor={`before-netValue-${i}`}
                          >
                            {this.context.t("Net value ")}
                          </label>
                        </div>
                        <div className="form-group grossValue">
                          <input
                            className={`disabled`}
                            name={`before-grossValue-${i} `}
                            id={`before-grossValue-${i}`}
                            value={p.grossValue}
                            readOnly={true}
                          />
                          <label
                            className="disabled"
                            htmlFor={`before-grossValue-${i}`}
                          >
                            {this.context.t("Gross value ")}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {/* //--------------- po korekcie --------------------- */}
                <div className="invoice-possitions">
                  <div className="description">
                    {this.context.t("After correction")}
                  </div>
                  {
                    invoicePositions.map((p, i) => {
                      return (
                        <div className="possition" key={`invoice-possition-${i}`}>
                          <div className="form-group name">
                            <input
                              className={`name`}
                              name={`name-${i}`}
                              id={`name-${i}`}
                              value={p.name}
                              onChange={(e) =>
                                this.onInputChangeInvoicePossition(e, i)
                              }
                              onClick={() => this.setState({ posListToShow: i })}
                            />
                            <label htmlFor={`name-${i}`} onClick={() => this.setState({ posListToShow: i })} >
                              {this.context.t("Name")}
                            </label>

                            {savedDocItems.length > 0 ? (
                              <span
                                className={`expend-icon ${posListToShow === i ? "expended" : ""
                                  }`}
                                onClick={() => {
                                  this.setState({
                                    posListToShow: i,
                                  });
                                }}
                              ></span>
                            ) : null}

                            {posListToShow === i && savedDocItems.length > 0 ? (
                              <div className="sugestions-list doc-items">
                                <ul>
                                  <SimpleBar style={{ maxHeight: "30vh", width: "100%" }}>
                                    {savedDocItems.map((item) => {
                                      // if(item.name.startsWith(p.name)) {
                                      return (
                                        <li onClick={() => this.setItem({ ...item }, i)}>{item.name}</li>
                                      );
                                      // } else {
                                      //     return null
                                      // }
                                    })}
                                  </SimpleBar>
                                </ul>
                              </div>
                            ) : null}
                          </div>
                          {
                            showPkwiu ? (
                              <div className="form-group pkwiu">
                                <input
                                  className={`pkwiu`}
                                  name={`pkwiu-${i}`}
                                  id={`pkwiu-${i}`}
                                  value={p.pkwiu}
                                  onChange={(e) =>
                                    this.onInputChangeInvoicePossition(e, i)
                                  }
                                  onBlur={(e) => this.updateSummary(e)}
                                />
                                <label htmlFor={`pkwiu-${i}`}>
                                  {this.context.t("PKWiU")}
                                </label>
                              </div>
                            ) : null
                          }
                          {
                            showGtu ? (
                              <div className="form-group gtu">
                                <input
                                  className={`gtu`}
                                  name={`gtu-${i}`}
                                  id={`gtu-${i}`}
                                  value={p.gtu}
                                  readOnly={true}
                                  onClick={() => this.setState({ gtuListToShow: i })}
                                />
                                <label htmlFor={`gtu-${i}`} onClick={() => this.setState({ gtuListToShow: i })}>{this.context.t("GTU")}</label>
                                <span className={`expend-icon ${gtuListToShow === i ? "expended" : ""}`} onClick={() => this.setState({ gtuListToShow: i })} ></span>
                                {
                                  gtuListToShow === i ? (
                                    <div className="sugestions-list gtu">
                                      <ul>
                                        {
                                          Object.entries(GTU_CODE).map(([key, value]) => (
                                            <li onClick={() => this.setGtu(`${key === "null" ? "" : key}`, i)}>
                                              {value}
                                            </li>
                                          ))
                                        }
                                      </ul>
                                    </div>
                                  ) : null
                                }
                              </div>
                            ) : null
                          }
                          <div className="form-group amount">
                            <input
                              className={`amount`}
                              name={`amount-${i}`}
                              id={`amount-${i}`}
                              value={p.amount.toString()}
                              onChange={(e) =>
                                this.onInputChangeInvoicePossition(e, i)
                              }
                              onBlur={(e) => this.updateSummary(e)}
                              style={{ fontSize: p.amount.length > 4 ? '12px' : '14px' }}
                            />
                            <label htmlFor={`amount-${i}`}>
                              {this.context.t("Amount ")}
                            </label>
                          </div>
                          <div className="form-group unit">
                            <input
                              className={`unit`}
                              name={`unit-${i}`}
                              id={`unit-${i}`}
                              value={p.unit}
                              readOnly={true}
                              onClick={() => {
                                this.setState({
                                  unitListToShow: i,
                                });
                              }}
                            />
                            <label
                              htmlFor={`unit-${i}`}
                              onClick={() => {
                                this.setState({
                                  unitListToShow: i,
                                });
                              }}
                            >
                              {this.context.t("Unit")}
                            </label>
                            <span
                              className={`expend-icon ${unitListToShow === i ? "expended" : ""
                                }`}
                              onClick={() => {
                                this.setState({
                                  unitListToShow: i,
                                });
                              }}
                            ></span>

                            {unitListToShow === i ? (
                              <div className="sugestions-list">
                                <ul>
                                  <li onClick={() => this.setUnit("szt.", i)}>szt.</li>
                                  <li onClick={() => this.setUnit("os.", i)}>os.</li>
                                  <li onClick={() => this.setUnit("godz.", i)}>godz.</li>
                                  <li onClick={() => this.setUnit("dni", i)}>dni</li>
                                  <li onClick={() => this.setUnit("m-c", i)}>m-c</li>
                                  <li onClick={() => this.setUnit("km", i)}>km</li>
                                  <li onClick={() => this.setUnit("MWh", i)}>MWh</li>
                                  <li onClick={() => this.setUnit("inna", i)}>
                                    inna
                                  </li>
                                </ul>
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group netPrice">
                            <input
                              className={`netPrice`}
                              name={`netPrice-${i}`}
                              id={`netPrice-${i}`}
                              value={p.netPrice}
                              onChange={(e) =>
                                this.onInputChangeInvoicePossition(e, i)
                              }
                              onBlur={(e) => this.updateSummary(e)}
                            />
                            <label htmlFor={`netPrice-${i}`}>
                              {this.context.t("Net price")}
                            </label>
                          </div>

                          {
                            showDiscount ? (
                              <>
                                <div className="form-group discount">
                                  <input
                                    className={`discount`}
                                    name={`discount-${i}`}
                                    id={`discount-${i}`}
                                    value={p.discount}
                                    onChange={(e) => this.onInputChangeInvoicePossition(e, i)}
                                    onBlur={(e) => this.updateSummary(e)}
                                  />
                                  <label htmlFor={`discount-${i}`}>
                                    {`${this.context.t("Discount")} (%)`}
                                  </label>
                                </div>
                                <div className="form-group discountPrice">
                                  <input
                                    className={`discountPrice`}
                                    name={`discountPrice-${i}`}
                                    id={`discountPrice-${i}`}
                                    value={p.discountPrice}
                                    onChange={(e) => this.onInputChangeInvoicePossition(e, i)}
                                    onBlur={(e) => this.updateSummary(e)}
                                  />
                                  <label htmlFor={`discountPrice-${i}`}>
                                    {this.context.t("Net price after discount")}
                                  </label>
                                </div>
                              </>
                            ) : null
                          }
                          <div className="form-group vat">
                            <input
                              className={`vat`}
                              name={`vat-${i}`}
                              id={`vat-${i}`}
                              value={p.vat}
                              readOnly={true}
                              onClick={() => {
                                this.setState({
                                  vatListToShow: i,
                                });
                              }}
                            />
                            <label
                              htmlFor={`vat-${i}`}
                              onClick={() => {
                                this.setState({
                                  vatListToShow: i,
                                });
                              }}
                            >
                              {this.context.t("VAT rate")}
                            </label>
                            <span
                              className={`expend-icon ${vatListToShow === i ? "expended" : ""
                                }`}
                              onClick={() => {
                                this.setState({
                                  vatListToShow: i,
                                });
                              }}
                            ></span>

                            {vatListToShow === i ? (
                              <div className="sugestions-list">
                                <ul>
                                  <li onClick={() => this.setVat("23", i)}>23</li>
                                  <li onClick={() => this.setVat("8", i)}>8</li>
                                  <li onClick={() => this.setVat("5", i)}>5</li>
                                  <li onClick={() => this.setVat("3", i)}>3</li>
                                  <li onClick={() => this.setVat("0", i)}>0</li>
                                  <li onClick={() => this.setVat("zw", i)}>zw</li>
                                  <li onClick={() => this.setVat("np", i)}>np</li>
                                  <li onClick={() => this.setVat("oo", i)}>oo</li>
                                </ul>
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group netValue">
                            <input
                              className={`netValue`}
                              name={`netValue-${i}`}
                              id={`netValue-${i}`}
                              value={p.netValue}
                              onChange={(e) =>
                                this.onInputChangeInvoicePossition(e, i)
                              }
                              onBlur={(e) => this.updateSummary(e)}
                              style={{ fontSize: p.netValue.length > 6 ? '12px' : '14px' }}
                            />
                            <label htmlFor={`netValue-${i}`}>
                              {this.context.t("Net value ")}
                            </label>
                          </div>
                          <div className="form-group grossValue">
                            <input
                              className={``}
                              name={`grossValue-${i}`}
                              id={`grossValue-${i}`}
                              value={p.grossValue}
                              onChange={(e) =>
                                this.onInputChangeInvoicePossition(e, i)
                              }
                              onBlur={(e) => this.updateSummary(e)}
                              style={{ fontSize: p.grossValue.length > 6 ? '12px' : '14px' }}
                            />
                            <label htmlFor={`grossValue-${i}`}>
                              {this.context.t("Gross value ")}
                            </label>
                          </div>

                          {/* {invoicePositions.length > 1 ? (
                          <div
                            className="delete-possition"
                            onClick={() => {
                              this.deleteInvoicePossition(i);
                            }}
                          ></div>
                        ) : null} */}
                        </div>
                      );
                    })
                  }
                  <div className="invoice-items-options">
                    <div className="checkboxes">
                      <div className="checkbox-group" onClick={() => { this.setState({ showPkwiu: !showPkwiu }) }} >
                        <div className={`checkbox ${showPkwiu ? "checked" : ""}`} ></div>
                        <div className="label">{this.context.t("Show PKWiU")}</div>
                      </div>
                      <div className="checkbox-group" onClick={() => { this.setState({ showGtu: !showGtu }) }} >
                        <div className={`checkbox ${showGtu ? "checked" : ""}`} ></div>
                        <div className="label">{this.context.t("Show GTU")}</div>
                      </div>
                      <div className="checkbox-group" onClick={() => { this.setState({ showDiscount: !showDiscount }) }} >
                        <div className={`checkbox ${showDiscount ? "checked" : ""}`} ></div>
                        <div className="label">{this.context.t("Show discount")}%</div>
                      </div>
                    </div>
                    <div className="add-possition" onClick={() => this.addPossition()}>
                      {this.context.t("Add item")}
                    </div>
                  </div>
                </div>
                <div className="summary">
                  {
                    currencyMidPrice && currency !== 'PLN' ? (
                      <div className="currencyMidPriceContainer">
                        <h3>Kurs NBP</h3>
                        <div className="midPrice">{currencyMidPrice}</div>
                        <p>z dnia {currencyEffectiveDate}</p>
                        <div className="edit-day" id="currencyCalendarButton" onClick={() => this.setState({ showCurrencyCalenrad: true })}>
                          {this.context.t('Change day')}
                        </div>
                        {
                          showCurrencyCalenrad ? (
                            <div className="currency-calendar" id="currencyCalendarContainer">
                              <Calendar
                                selectDay={this.selectNewCurrencyDay}
                                selectedDay={{
                                  display: moment(currencyEffectiveDate).format('LL'),
                                  value: moment(currencyEffectiveDate)
                                }}
                                projectId={"none"}
                                startDate={moment(currencyEffectiveDate) || moment()}
                                arrowsOnLeft={true}
                              />
                            </div>
                          ) : null
                        }
                      </div>
                    ) : null
                  }
                  <div>
                    <div className="sum">
                      <div className="label">{this.context.t("Net sum")}</div>
                      <div className="value">{summary.netSum}</div>
                      <div
                        id="currencyContainer"
                        className="currency can-edit"
                        onClick={() =>
                          this.setState({
                            showCurrencyList: !showCurrencyList,
                          })
                        }
                      >
                        {currency}
                        <span
                          className={`expend-icon ${showCurrencyList ? "expended" : ""
                            }`}
                        ></span>

                        {showCurrencyList ? (
                          <div className="sugestions-list">
                            <ul>
                              {
                                currencyList.map(c => {
                                  return (
                                    <li onClick={e => { this.setCurrency(c) }}>
                                      {c}
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="sum">
                      <div className="label">{this.context.t("VAT sum")}</div>
                      <div className="value">{summary.vatSum}</div>
                      <div className="currency">{currency}</div>
                    </div>
                    <div className="sum">
                      <div className="label">{this.context.t("Gross sum")}</div>
                      <div className="value">{summary.grossSum}</div>
                      <div className="currency">{currency}</div>
                    </div>
                  </div>
                </div>
                <div
                  className={`payment-options ${paymentMethod === "transfer" && paymentStatus === "unpaid"
                    ? ""
                    : "not-full"
                    }`}
                >
                  <div id="paymentMethodContainer" className="form-group">
                    <input
                      className={``}
                      name="paymentMethod"
                      id={`paymentMethod`}
                      value={this.context.t(paymentMethod)}
                      readOnly={true}
                      onClick={() =>
                        this.setState({
                          showPaymentMethodList: true,
                        })
                      }
                    />
                    <label
                      htmlFor="paymentMethod"
                      onClick={() =>
                        this.setState({
                          showPaymentMethodList: true,
                        })
                      }
                    >
                      {this.context.t("Payment method")}
                    </label>
                    <span
                      className={`expend-icon ${showPaymentMethodList ? "expended" : ""
                        }`}
                      onClick={() =>
                        this.setState({
                          showPaymentMethodList: !showPaymentMethodList,
                        })
                      }
                    ></span>
                    {showPaymentMethodList ? (
                      <div className="sugestions-list">
                        <ul>
                          {
                            paymentMethodsList.map(method => {
                              return (
                                <li onClick={e => this.setState({ showPaymentMethodList: false, paymentMethod: method.value })}>
                                  {this.context.t(method.title)}
                                </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                    ) : null}
                  </div>
                  {paymentMethod === "transfer" ||
                    paymentMethod === "Transfer" ? (
                    <div id="accountNumberContainer" className="form-group">
                      <input
                        className={``}
                        name="accountNumber"
                        id={`accountNumber`}
                        value={accountNumber}
                        onChange={this.onInputChange}
                      />
                      <label htmlFor="accountNumber">
                        {this.context.t("Account number")}
                      </label>

                      {accountsList.length > 0 ? (
                        <span
                          className={`expend-icon ${showAccountsList ? "expended" : ""
                            }`}
                          onClick={() =>
                            this.setState({
                              showAccountsList: !showAccountsList,
                            })
                          }
                        ></span>
                      ) : null}
                      {showAccountsList ? (
                        <div className="sugestions-list">
                          <ul>
                            {accountsList.map((a) => {
                              return (
                                <li
                                  onClick={() =>
                                    this.setState({
                                      showAccountsList: false,
                                      accountNumber: a.account_number,
                                      bankName: a.bank_name,
                                      bankBIC: a.bic,
                                    })
                                  }
                                >
                                  <span className="account-name">{`${a.name} (${a.currency})`}</span>
                                  {a.account_number}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  <div id="paymentStatusContainer" className="form-group">
                    <input
                      className={``}
                      name="paymentStatus"
                      id={`paymentStatus`}
                      value={this.context.t(paymentStatus)}
                      readOnly={true}
                      onClick={() =>
                        this.setState({
                          showPaymentStatusList: true,
                        })
                      }
                    />
                    <label
                      htmlFor="paymentStatus"
                      onClick={() =>
                        this.setState({
                          showPaymentStatusList: true,
                        })
                      }
                    >
                      {this.context.t("Status")}
                    </label>
                    <span
                      className={`expend-icon ${showPaymentStatusList ? "expended" : ""
                        }`}
                      onClick={() =>
                        this.setState({
                          showPaymentStatusList: !showPaymentStatusList,
                        })
                      }
                    ></span>

                    {showPaymentStatusList ? (
                      <div className="sugestions-list">
                        <ul>
                          <li
                            onClick={() =>
                              this.setState({
                                showPaymentStatusList: false,
                                paymentStatus: "unpaid",
                              })
                            }
                          >
                            {this.context.t("Unpaid ")}
                          </li>
                          <li
                            onClick={() =>
                              this.setState({
                                showPaymentStatusList: false,
                                paymentStatus: "paid",
                              })
                            }
                          >
                            {this.context.t("Paid ")}
                          </li>
                        </ul>
                      </div>
                    ) : null}
                  </div>
                  {paymentStatus === "unpaid" ? (
                    <div id="dueDatePickerContainer" className="form-group">
                      <input
                        className={``}
                        name="dueDate"
                        id={`dueDate`}
                        value={dueDate}
                        readOnly={true}
                        onClick={(e) => this.showDatePicker(e, "dueDate")}
                      />
                      <label
                        htmlFor="dueDate"
                        onClick={(e) => this.showDatePicker(e, "dueDate")}
                      >
                        {this.context.t("Due date")}
                      </label>
                      <span
                        className="calendar-icon"
                        onClick={(e) => this.showDatePicker(e, "dueDate")}
                      ></span>

                      {showDueDateCalendar ? (
                        <div
                          className={`date-picker due-date ${showDueDateCalendar ? "show" : ""
                            }`}
                          onClick={(e) => this.clickOnDatePicker(e, "dueDate")}
                        >
                          <Calendar
                            selectDay={this.selectDueDate}
                            selectedDay={moment(dueDate)}
                            projectId={"none"}
                            startDate={moment(dueDate) || moment()}
                            arrowsOnLeft={false}
                          />
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  <div className="remarksContainer">
                    <div id="reasonForCorrectionContainer" className="form-group">
                      <input
                        className={``}
                        name="reasonForCorrection"
                        id={`reasonForCorrection`}
                        value={this.context.t(reasonForCorrection)}
                        onClick={() =>
                          this.setState({
                            showReasonForCorrectionList:
                              !showReasonForCorrectionList,
                          })
                        }
                        onChange={(e) =>
                          this.setState({
                            reasonForCorrection: e.target.value,
                          })
                        }
                      />
                      <label
                        htmlFor="reasonForCorrection"
                        onClick={() =>
                          this.setState({
                            showReasonForCorrectionList: true,
                          })
                        }
                      >
                        {this.context.t("Reason for correction")}
                      </label>
                      {/* <span
                      className={`expend-icon ${showReasonForCorrectionList ? "expended" : ""
                        }`}
                      onClick={() =>
                        this.setState({
                          showReasonForCorrectionList:
                            !showReasonForCorrectionList,
                        })
                      }
                    ></span> */}

                      {/* {showReasonForCorrectionList ? (
                      <div className="sugestions-list">
                        <ul>
                          <li
                            onClick={() =>
                              this.setState({
                                showReasonForCorrectionList: false,
                                reasonForCorrection: this.context.t(
                                  "Calculation error or other obvious error"
                                ),
                              })
                            }
                          >
                            {this.context.t(
                              "Calculation error or other obvious error"
                            )}
                          </li>
                          <li
                            onClick={() =>
                              this.setState({
                                showReasonForCorrectionList: false,
                                reasonForCorrection: this.context.t(
                                  "Other: e.g. return, discount"
                                ),
                              })
                            }
                          >
                            {this.context.t("Other: e.g. return, discount")}
                          </li>
                        </ul>
                      </div>
                    ) : null} */}
                    </div>
                  </div>
                  <div className="additional-options">
                    <h3>{this.context.t("Additional options:")}</h3>
                    <div
                      className="checkbox-group"
                      onClick={() => this.splitPaymentClick()}
                    >
                      <div
                        className={`checkbox ${splitPayment ? "checked" : ""}`}
                      ></div>
                      <div className="label">{`${this.context.t(
                        "Split payment"
                      )} (split payment)`}</div>
                    </div>
                    <div
                      className="checkbox-group"
                      onClick={() => this.reverseChargeClick()}
                    >
                      <div
                        className={`checkbox ${reverseCharge ? "checked" : ""}`}
                      ></div>
                      <div className="label">{`${this.context.t(
                        "Reverse charge"
                      )} (reverse charge)`}</div>
                    </div>
                    <div
                      className="checkbox-group"
                      onClick={() => this.metodaKasowaClick()}
                    >
                      <div
                        className={`checkbox ${metodaKasowa ? "checked" : ""}`}
                      ></div>
                      <div className="label">{`${this.context.t(
                        "Metoda kasowa"
                      )}`}</div>
                    </div>
                  </div>
                </div>
              </SimpleBar>
              <div className="footer">
                {
                  currentCompany && currentCompany.company && currentCompany.company.ksef_integration_id ? (
                    <div className="send-to-ksef">
                      <div className="checkbox-group" >
                        <div className={`checkbox ${sendToKsef ? "checked" : ""}`} onClick={() => this.setState({ sendToKsef: !sendToKsef })}></div>
                        <div className="label" onClick={() => this.setState({ sendToKsef: !sendToKsef })}>{`${this.context.t("Send to KSeF")}`}</div>
                      </div>
                    </div>
                  ) : null
                }
                <div className="logo">
                  {!logo && !logoURL ? (
                    <div className="no-file">
                      {this.context.t("Add your logo to the company")}
                    </div>
                  ) : logoURL ? (
                    <div className="file">
                      <div className="label">
                        {this.context.t("Company logo on the invoice")}:
                      </div>
                      <img src={logoURL} alt="" />
                    </div>
                  ) : (
                    <div className="file">
                      <div className="label">
                        {this.context.t("Company logo on the invoice")}:
                      </div>
                      <img src={logo.base64} alt="" />
                    </div>
                  )}
                </div>

                <div
                  className="generate"
                  onClick={() => this.generateInvoice()}
                >
                  {this.context.t("Generate correction")}
                </div>
              </div>
            </div>
          </div >
        </div >
      )
    } else {
      return null
    }
  }
}

CorrectionItemForm.contextTypes = {
  t: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
  user: state.User.user,
  userProjects: state.User.userProjects,
  currentProject: state.User.currentProject,
  userCompanies: state.Company.companies,
  currentCompany: state.Company.currentCompany,
  isNewInvoiceNumberPopupOpen: state.Company.isNewInvoiceNumberPopupOpen,
  newInvoiceNumber: state.Company.newInvoiceNumber
});

const mapDispatchToProps = {
  alertWarn: AlertActions.warning,
  getDocumentNumberForNewInvoice: CompanyActions.getDocumentNumberForNewInvoice,
  getSavedDocItems: CompanyActions.getSavedDocItems,
  getPrieviosGeneratedInvoices: CompanyActions.getPrieviosGeneratedInvoices,
  generateInvoice: CompanyActions.generateInvoice,
  editInvoice: CompanyActions.editInvoice,
  checkCorrectInvoiceNumber: CompanyActions.checkCorrectInvoiceNumber,
  closePopupAndDeleteInvoiceNumber: CompanyActions.closePopupAndDeleteInvoiceNumber,
};

export default connect(mapStateToProps, mapDispatchToProps)(CorrectionItemForm);
