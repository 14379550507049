import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import routes from '../helpers/routes'
import { history } from "../helpers/history"

import { UserActions } from '../actions/UserActions'
import '../css/ErrorPage.css'

export class ErrorBoundary extends Component {

    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    componentDidCatch(error, info) {
        if (process.env.NODE_ENV === "production" || process.env.REACT_APP_ENVIRONMENT === "production" || process.env.NODE_ENV === "test" || process.env.REACT_APP_ENVIRONMENT === "test") {
            // Display fallback UI
            this.setState({ hasError: true })
            // You can also log the error to an error reporting service
            // logErrorToMyService(error, info);
            this.props.sendErrorEmail({ stack: info.componentStack })
        }
    }

    //Something went wrong. Refresh page.

    render() {
        const { hasError } = this.state
        console.log(hasError)
        if (hasError) {
            return (
                <div className="error-panel">
                    <div className="error-container">
                        <div className="d-flex">
                            <div id="errorBoundler">
                                {/*<span style={{fontSize: '70px'}}className="icon-meh"></span>*/}
                                <h3 className="text big">{this.context.t('Oops... a server error occurred.')}</h3>
                                <div className="text medium">{this.context.t("We know about it and we act.")}</div>
                                <div className="text">{this.context.t("Please refresh the page and try again. If that doesn't help, try again in about an hour.")}</div>
                                <div className="text">
                                    {this.context.t("You can also send a service request by providing details in the form: ")}
                                    <span className="helpdesk-link" onClick={() => { history.push(routes.panel.help); window.location.reload(false) }}>{this.context.t('Helpdesk')}</span>
                                    {this.context.t(' or by e-mail: ')}
                                    <a href="mailto:support@easydocs.pl">support@easydocs.pl</a>
                                </div>
                                <div className="button">
                                    <div className="button refresh" onClick={() => window.location.reload(false)}>{this.context.t('Refresh page')} </div>
                                    <div className="button home" onClick={() => { history.push(routes.panel.dashboard); window.location.reload(false) }}>{this.context.t('Home page')} </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return this.props.children
    }
}

ErrorBoundary.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
    sendErrorEmail: UserActions.sendErrorEmail
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary)