import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CompanyActions } from "../../actions/CompanyActions";

export class DeleteSaleInvoicePopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { invoiceToBeDeleted } = this.props
        return (
            <div className="delete-invoice-popup">
                <div className="popup-card-container">
                    <div className="popup-card">
                        <div className="header">{this.context.t('Are you sure you want delete this invoice?')}</div>
                        <div className="invoice">
                            <div className="prop">
                                <div className="label">{this.context.t('Buyer')}:</div>
                                <div className="value">{invoiceToBeDeleted.counterparty ? invoiceToBeDeleted.counterparty.name : ""}</div>
                            </div>
                            <div className="prop">
                                <div className="label">{this.context.t('Document number')}:</div>
                                <div className="value">{invoiceToBeDeleted.doc_number}</div>
                            </div>
                        </div>
                        <div className="action-buttons">
                            <div className="btn" onClick={e => this.props.close()}>{this.context.t('Cancel')}</div>
                            <div className="btn delete" onClick={e => this.props.delete()}>{this.context.t('Delete')}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

DeleteSaleInvoicePopup.contextTypes = {
    t: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteSaleInvoicePopup);
