export const ButtonsConsts = {
    OCR_DOWNLOAD_BUTTON: 'OCR_DOWNLOAD_BUTTON'
}

const initialState = {
    ocrDownloadButtonLoading: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ButtonsConsts.OCR_DOWNLOAD_BUTTON:
            return {...state, ...{ocrDownloadButtonLoading: action.loading}}

        default:
            return state
    }
}