import { history } from "../helpers/history"
import routes from "../helpers/routes"
import myFetch from "../helpers/fetch"

import { socket } from "../pages/User/UserPanel"
import moment from "moment"
import randomString from 'random-string'

import { MessageConst } from "../reducers/MessagesReducer"
import { CompanyConst } from "../reducers/CompanyReducer"
import { ReportsConst } from "../reducers/ReportsReducer"
import { EventConst } from "../reducers/EventReducer"
import { FileConst } from "../reducers/FileReducer"
import { UserConst } from "../reducers/UserReducer"

import { AlertActions } from './AlertActions'

export const MessagesActions = {
    addMessage,
    markMessageAsRead,
    markAllAsRead,
    deleteAll,
    getOldNotifications,
    getMyUpcomingPayments,
}

function getOldNotifications(skip = 0) {
    return dispatch => {
        dispatch({ type: MessageConst.WAITING_FOR_OLD_NOTIFICATIONS, loading: true })
        myFetch(`/actions-register/get-mine/${skip}`, { method: 'get' }).then(res => {
            dispatch({ type: MessageConst.WAITING_FOR_OLD_NOTIFICATIONS, loading: false })
            if (res.success) {
                dispatch({ type: MessageConst.GET_OLD_MESSAGES, messages: res.messages, skip: skip, hasOlder: res.hasOlder })
            }
        }).catch(err => {
            handleError(err, dispatch, `/user-projects/my-projects`)
        })
    }
}

function getMyUpcomingPayments() {
    return dispatch => {
        dispatch({ type: MessageConst.WAITING_FOR_UPCOMING_PAYMENTS, loading: true })
        myFetch(`/users/my-upcoming-payments`, { method: 'get' }).then(res => {
            dispatch({ type: MessageConst.WAITING_FOR_UPCOMING_PAYMENTS, loading: false })
            if (res.success) {
                dispatch({ type: MessageConst.GET_UPCOMING_PAYMENTS, upcomingPayments: res.upcomingPayments })
            } else {
                dispatch(AlertActions.warning(res.msg))
            }
        }).catch(err => {
            handleError(err, dispatch, `/user-projects/my-projects`)
        })
    }
}

function addMessage(user, type, project, fileId, projectId, fileName, time, docType, oldProject) {
    return dispatch => {
        let messageId = randomString({
            length: 32,
            numeric: true,
            letters: true,
            special: true,
        })
        messageId += moment().format("YYYYMMDDHHmmssSSS")

        let text = ''

        if (type === 'upload' && project) {
            text = 'Uploaded file '
        } else if (type === 'newPaymentOrder') {
            text = 'Created new payment order '
        } else if (type === 'moveToTrash' && project) {
            text = 'Moved file to trash '
        } else if (type === 'restored' && project) {
            text = 'Restored file from trash '
        } else if (type === 'deletePermanent' && project) {
            text = 'Deleted file '
        } else if (type === 'submited' && project) {
            text = 'Submitted OCR for file '
        } else if (type === 'accepted' && project) {
            text = 'Accepted file '
        } else if (type === 'setDocType' && project) {
            text = 'Set document type as ' + docType.toLowerCase() + ' for file '
        } else if (type === 'setExpenseOwner' && project) {
            text = 'Changed the expense owner for file '
        } else if (type === 'markedAsPaid') {
            text = 'Marked payment of the document '
        } else if (type === 'generatedReport' && project) {
            text = 'Generated new report '
        } else if (type === 'generatedTransfersFile' && project) {
            text = 'Generated new file with bank transfers '
        } else if (type === 'moveFileToProject') {
            text = 'Moved file from binder '
        } else if (type === 'additionalAcceptance') {
            text = 'Accepted file '
        } else {
            text = 'Unknown message '
        }

        let code
        if (project && project.code) {
            code = project.code
        }

        let message = {
            id: messageId,
            user: user,
            project: code,
            time: moment(),
            text: text,
            file: fileName,
            type: type,
            seen: false,
            fileId: fileId,
            projectId: projectId,
            latest: true,
            oldProjectCode: oldProject ? oldProject.code : ''
        }

        dispatch({ type: MessageConst.ADD_MESSAGE, message: message })
    }
}

function markMessageAsRead(id) {
    return dispatch => {
        dispatch({ type: MessageConst.MARK_MESSAGE_READ, id: id })
    }
}

function markAllAsRead() {
    return dispatch => {
        dispatch({ type: MessageConst.MARK_ALL_AS_READ })
    }
}

function deleteAll() {
    return dispatch => {
        dispatch({ type: MessageConst.DELETE_ALL_MESSAGES })
    }
}

function handleError(error, dispatch, path) {
    if (error?.code === 'AUTHORIZATION_REQUIRED' && localStorage.getItem('dashboardProject')) {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        dispatch({ type: UserConst.LOGGED_FALSE })
        dispatch({ type: UserConst.LOGOUT })
        dispatch({ type: EventConst.LOGOUT })
        dispatch({ type: CompanyConst.LOGOUT })
        dispatch({ type: ReportsConst.LOGOUT })
        dispatch({ type: FileConst.LOGOUT })
        dispatch(AlertActions.info("You have been logged out.", 5000))
    } else {
        dispatch(AlertActions.setError(error, path));
        dispatch(AlertActions.danger("There was an internal server error while procesing request. Try again later."));
    }
}
