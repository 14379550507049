import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import TextareaAutosize from 'react-textarea-autosize'

import { FileActions } from '../../actions/FileActions'
import { CompanyActions } from '../../actions/CompanyActions'
import { ReportsActions } from '../../actions/ReportsActions'

import AvailableInOtherPlanLabel from '../AvailableInOtherPlanLabel'
import FileAcceptanceStepsSection from './FileAcceptanceStepsSection'
import TagsList from '../TagsList'
import Calendar from '../Calendar'

import arrayObjectIndexOf from "../../helpers/indexOfObject"

export class CorporateForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showTagsList: false,
        }

        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick);
        window.addEventListener("keydown", this.escFunction, false)
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
        window.removeEventListener("keydown", this.escFunction, false)
    }

    escFunction = e => {
        if (e.keyCode === 27) {
            this.handleClick(e)
        }
    }

    handleClick = (event) => {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({
            })
        } else {
            if (event.keyCode === 27 || (this.state.showTagsList && !this.hasSomeParentTheClass(event.target, 'tags-container'))) {
                this.setState({
                    showTagsList: false
                })
            }
        }
    }

    hasSomeParentTheClass = (element, className) => {
        while (element) {
            if (element.className && element.className.includes(className)) return true

            element = element.parentNode
        }

        return false
    }

    changeInputValue = (e, field, docType, docId) => {
        e.preventDefault()
        this.props.editDocInput(e.target.value, field, docType, docId)
    }

    showTagsList = () => {
        this.setState({
            showTagsList: true
        })
    }

    returnTags = (tags, docId, docType) => {
        this.props.editDocInput(tags, 'tags', docType, docId)
    }

    generateAdditionalAcceptanceFields = () => {
        const { user, pdfPreviewData } = this.props
        if (pdfPreviewData.additionalAcceptances && Array.isArray(pdfPreviewData.additionalAcceptances)) {
            var alreadyAccepted = 0, neededAcceptances = 0
            pdfPreviewData.additionalAcceptances.forEach(acc => {
                if (acc.numberOfRequiredAcceptances) neededAcceptances = acc.numberOfRequiredAcceptances
                if (acc.accepted) alreadyAccepted++
            })
            if (neededAcceptances === 0) neededAcceptances = pdfPreviewData.additionalAcceptances.length
            if (pdfPreviewData.fileInstance?.requires_additional_acceptance) {
                return (
                    pdfPreviewData.additionalAcceptances.map(acc => {
                        return (
                            <div className="doc-prop">
                                <span className="name">{acc.accepted ? this.context.t('Accepted by:') : this.context.t('Requires acceptance:')}</span>
                                <span className="uneditable-prop">
                                    {`${acc.user?.first_name} ${acc.user?.last_name}`}
                                    {
                                        acc.user_id === user.id && !acc.accepted ? (
                                            <>
                                                <span className="mark-as-paid-btn" onClick={() => this.additionaAcceptanceClick()}>{this.context.t('Accept')}</span>
                                            </>
                                        ) : (
                                            null
                                        )
                                    }
                                </span>
                            </div>
                        )
                    })
                )
            } else if (pdfPreviewData.fileInstance?.additional_acceptances_required) {
                return (
                    pdfPreviewData.additionalAcceptances.map(acc => {
                        return (
                            acc.accepted ? (
                                <div className="doc-prop">
                                    <span className="name">{acc.accepted ? this.context.t('Accepted by:') : this.context.t('Requires acceptance:')}</span>
                                    <span className="uneditable-prop">
                                        {`${acc.user?.first_name} ${acc.user?.last_name}`}
                                        {
                                            acc.user_id === user.id && !acc.accepted ? (
                                                <>
                                                    <span className="mark-as-paid-btn" onClick={() => this.additionaAcceptanceClick()}>{this.context.t('Accept')}</span>
                                                </>
                                            ) : (
                                                null
                                            )
                                        }
                                    </span>
                                </div>
                            ) : (
                                null
                            )
                        )
                    })
                )
            }
        } else {
            return null
        }
    }

    additionaAcceptanceClick = () => {
        const { user, pdfPreviewData } = this.props
        this.props.toogleAdditionalAcceptanceForFile(pdfPreviewData.id, pdfPreviewData.projectId, user.id);
        var additionalAcceptances = pdfPreviewData.additionalAcceptances || []
        var userAcceptanceIndex = arrayObjectIndexOf(additionalAcceptances, user.id, 'user_id')
        if (userAcceptanceIndex > -1) {
            additionalAcceptances[userAcceptanceIndex].accepted = true
            this.props.editDocInput(additionalAcceptances, 'additionalAcceptances', pdfPreviewData.docType, pdfPreviewData.docId)
        }
    }

    render() {
        const { showTagsList } = this.state
        const { pdfPreviewData, editMode, tagsList } = this.props
        return (
            <div onClick={e => this.handleClick(e)} ref={this.wrapperRef}>
                <FileAcceptanceStepsSection pdfPreviewData={pdfPreviewData} />

                {this.generateAdditionalAcceptanceFields()}

                {
                    pdfPreviewData.deleted ? (
                        <div className="doc-prop">
                            <span className="name">{this.context.t('Date of permanent removal:')}</span>
                            <span className="uneditable-prop">{moment(pdfPreviewData.deletedDate).add(45, 'days').format('DD-MM-YYYY')}</span>
                        </div>
                    ) : (
                        null
                    )
                }
                <div className="doc-prop tags-container">
                    {/* <AvailableInOtherPlanLabel planName='PRO' labelSize="LABEL-SHADOW" company={this.state.docOwnerCompany} /> */}
                    <span className="name">{this.context.t('Tags:')}</span>
                    <TextareaAutosize
                        className={`${!editMode ? 'read-only' : ''} ${showTagsList ? 'focused' : ''}`}
                        readOnly={!editMode}
                        name="tags"
                        id={`tagsInput-${pdfPreviewData.docId}`}
                        placeholder={this.context.t('e.g. leasing, car')}
                        value={pdfPreviewData.tags}
                        onChange={(e) => this.changeInputValue(e, 'tags', pdfPreviewData.docType, pdfPreviewData.docId)}
                        onFocus={() => this.showTagsList()}
                    />
                    {
                        showTagsList && tagsList && tagsList.length > 0 && editMode ? (
                            <TagsList companyTags={tagsList} selectedTags={pdfPreviewData.tags} returnTags={this.returnTags} docId={pdfPreviewData.docId} docType={pdfPreviewData.docType} />
                        ) : (
                            null
                        )
                    }
                </div>
                <div className="doc-prop issue-date-container">
                    <span className="name">{this.context.t('Date:')}</span>
                    <TextareaAutosize
                        className={`${!editMode ? 'read-only' : ''}`}
                        readOnly={!editMode}
                        name="issueDate"
                        id={`issueDateInput-${pdfPreviewData.docId}`}
                        value={pdfPreviewData.issueDate}
                        onChange={e => this.changeInputValue(e, 'issueDate', pdfPreviewData.docType, pdfPreviewData.docId)}
                        onFocus={e => this.props.showIssueDateDocCalendar(e)}
                    />
                </div>
                <div className="doc-prop">
                    <span className="name">{this.context.t('Description:')}</span>
                    <TextareaAutosize
                        className={`${!editMode ? 'read-only' : ''}`}
                        readOnly={!editMode}
                        name="description"
                        id={`descriptionInput-${pdfPreviewData.docId}`}
                        value={pdfPreviewData.description}
                        onChange={e => this.changeInputValue(e, 'description', pdfPreviewData.docType, pdfPreviewData.docId)}
                    />
                </div>
                <div className="doc-prop">
                    <span className="name">{this.context.t('Document description')}:</span>
                    <TextareaAutosize
                        className={`${!editMode ? 'read-only' : ''}`}
                        readOnly={!editMode}
                        name="doc_description"
                        id={`doc_descriptionInput-${pdfPreviewData.docId}`}
                        value={pdfPreviewData.doc_description}
                        onChange={e => this.changeInputValue(e, 'doc_description', pdfPreviewData.docType, pdfPreviewData.docId)}
                    />
                </div>
            </div>
        )
    }
}

CorporateForm.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    pdfPreviewData: state.File.pdfPreviewData,
    user: state.User.user,
})

const mapDispatchToProps = {
    editDocInput: FileActions.editDocInput,
    toogleAdditionalAcceptanceForFile: FileActions.toogleAdditionalAcceptanceForFile,
}

export default connect(mapStateToProps, mapDispatchToProps)(CorporateForm)