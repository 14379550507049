import store from '../'

var API_URL;
if (process.env.REACT_APP_ENVIRONMENT === "local") {
  API_URL = "http://localhost:4000/api";
} else if (process.env.REACT_APP_ENVIRONMENT === "development") {
  API_URL = "https://test-api.easydocs.pl/api";
} else if (process.env.REACT_APP_ENVIRONMENT === "production") {
  API_URL = "https://app-api.easydocs.pl/api";
} else if (process.env.REACT_APP_ENVIRONMENT === "test") {
  API_URL = "https://test-api.easydocs.pl/api";
} else {
  API_URL = "http://localhost:4000/api";
}

function parseJSON(response) {
  return new Promise((resolve) => {
    if (response.status !== 204) {
      response.json().then((json) =>
        resolve({
          status: response.status,
          ok: response.ok,
          json,
        })
      );
    } else {
      resolve({
        status: response.status,
        ok: response.ok,
      });
    }
  });
}

export default function myFetch(url, requestOptions = {}) {
  return new Promise((resolve, reject) => {
    if (typeof requestOptions.body === "object") {
      requestOptions.body = JSON.stringify(requestOptions.body);
    }

    requestOptions.headers = {
      "currentCompanyId": localStorage.getItem("currentCompany") ? localStorage.getItem("currentCompany") : -1,
      "X-Access-Token": localStorage.getItem("token"),
      "Content-Type": "application/json",
    };

    fetch(`${API_URL}${url}`, requestOptions)
      .then(parseJSON)
      .then((response) => {
        if (response.ok) {
          return resolve(response.json);
        }
        // extract the error from the server's json
        return reject(response.json.error);
      })
      .catch((error) => {
        reject({
          networkError: error.message,
        });
      });
  });
}
