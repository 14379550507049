import React, { Component } from 'react'
import moment from 'moment'
import 'moment-timezone'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import XRegExp from 'xregexp'
import { List, AutoSizer, WindowScroller } from "react-virtualized";

import 'react-activity/dist/react-activity.css'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import ReactTooltip from 'react-tooltip'

import PdfPreview from './PdfPreview'

import myFetch from '../helpers/fetch'

import { AlertActions } from '../actions/AlertActions'
import { FileActions } from '../actions/FileActions'
import arrayObjectIndexOf from '../helpers/indexOfObject'

export class FullTextSearchComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            sentFilesReguest: false,
            searchField: '',
            showResults: false,
            resultsLoading: false,
            noMatchingFiles: false,
            currentOpenedFile: 0,
            filesToDisplay: [],
            searchOption: 'PARTIAL',
            categoryIndex: -1,
        }

        this.filesWrapper = React.createRef()
    }

    componentDidMount() {
    }

    componentDidUpdate() {
    }

    componentWillUnmount() {
    }

    focusFullTextSearch = () => {
        this.setState({
            isFocused: true
        })
    }

    unfocusSearch = () => {
        this.setState({
            isFocused: false,
            showResults: false,
            resultsLoading: false,
            filesToDisplay: [],
            searchField: '',
            noMatchingFiles: false
        })
    }

    showLoading = (show) => {
        if (show) {
            this.setState({
                showResults: true,
                resultsLoading: true,
                noMatchingFiles: false
            })
        } else {
            this.setState({
                showResults: false,
                resultsLoading: false,
                filesToDisplay: [],
                searchField: '',
                noMatchingFiles: false
            })
        }
    }

    searchForFiles = async (text) => {
        if (text && text.length >= 2) {
            try {
                const { searchOption } = this.state
                const res = await myFetch(`/files/get-files-by-text`, {
                    method: 'POST',
                    body: {
                        searchText: text,
                        projectId: this.props.currentProject.id,
                        searchOption: searchOption,
                    }
                })

                if (res.success) {
                    this.setState({
                        searchField: text,
                        resultsLoading: false,
                        filesToDisplay: res.filesToDisplay,
                        displayOrder: res.displayOrder,
                        noMatchingFiles: res.displayOrder.length === 0,
                        categoryIndex: res.displayOrder.length > 0 ? 0 : -1,
                    })

                } else {
                    this.props.alertWarn(this.context.t(res.msg))
                }
            } catch (err) {
                this.props.alertWarn(this.context.t("There was an internal server error while procesing request. Try again later."))
            }
        }
    }

    changeSearchOption = option => {
        this.setState({
            searchOption: option,
            resultsLoading: true,
            filesToDisplay: []
        }, () => {
            this.searchForFiles(this.state.searchField)
        })
    }

    renderPlaceholders = () => {
        var maxHeight = window.innerHeight - 160
        var currentHeight = 0

        var elementsToReturn = []

        while (currentHeight < maxHeight - 80) {
            elementsToReturn.push(<PlaceholderFile />)

            currentHeight += 80
        }
        return elementsToReturn
    }

    drawIcon = (name) => {
        var re = /(?:\.([^.]+))?$/;
        var extension = re.exec(name)[1]

        if (extension === 'pdf' || extension === 'PDF') {
            return <div className="icon pdf-icon"></div>
        } else {
            return <div className="icon image-icon"></div>
        }
    }

    openFilePreview = (path, name, id, status, fileStep, project) => {
        console.log(path, name, id, status, fileStep, project)
        var projectToCheck = this.props.userProjects.find(p => p.id === project)
        this.setState({
            currentOpenedFile: id
        })
        this.props.togglePreviewForFullTextSearch(true)
        if (projectToCheck) {
            this.props.getFileBody(parseInt(project), path, id, status, fileStep)
        } else {
            this.props.getFileBody(parseInt(project), path, id, status, fileStep)
        }
    }

    closePreview = () => {
        this.props.togglePreviewForFullTextSearch(false)
        this.setState({
            currentOpenedFile: 0
        })
    }

    checkNext = () => {
        const { displayOrder, currentOpenedFile } = this.state
        var currentIndex = arrayObjectIndexOf(displayOrder, currentOpenedFile, 'id')
        if (currentIndex > -1) {
            return { index: currentIndex, prev: currentIndex > 0, next: currentIndex < displayOrder.length - 1 }
        } else {
            return { index: currentIndex, prev: false, next: false }
        }
    }

    goToDoc = (index, direction) => {
        const { displayOrder, currentOpenedFile } = this.state

        var nextDoc = null
        if (index >= 0 && index <= displayOrder.length) {
            nextDoc = displayOrder[index]
        }
        if (nextDoc) {
            this.props.getFileBody(nextDoc.projectId, nextDoc.path, nextDoc.id, nextDoc.ocrStatus, nextDoc.acceptanceStep)
            this.setState({
                currentOpenedFile: nextDoc.id
            })
        }
    }

    setListRef = listRef => {
        this.listRef = listRef
    }

    rowRenderer = (style, key, index, parent, width, filesList) => {
        var file = filesList[index]

        if (file) {
            return (
                <div
                    className="file-row"
                    key={key}
                    style={style}
                    onClick={() => this.openFilePreview(file.path, file.name, file.id, file.ocrStatus, file.acceptanceStep, file.projectId)}
                >
                    <div className="row-content-wrapper">
                        {this.drawIcon(file.name)}

                        <div className="text name"> {file.counterpartyName || file.name} </div>
                        <div className="text document-number">{file.docNumber}</div>
                        <div className="text issue-date">{file.issueDate}</div>
                        <div className="text amount-to-pay">{file.amountToPay}</div>
                        <div className={`text status`} data-for={`file-status--${file.id}`} data-tip='show'>
                            <span className={`step-1 ${file.acceptanceStep > 1 ? 'green' : 'yellow'}`}></span>
                            <span className={`step-2 ${file.acceptanceStep > 2 ? 'green' : file.acceptanceStep === 2 ? 'yellow' : 'grey'}`}></span>
                            <span className={`step-3 ${file.ocrStatus === 'DONE' ? 'green' : file.acceptanceStep === 3 ? 'yellow' : 'grey'}`}></span>
                        </div>

                        <ReactTooltip id={`file-status--${file.id}`} place="left" effect="solid" className="default-tooltip status-tooltip">
                            {
                                file.acceptanceStep === 1 ? (
                                    this.context.t('OCR not verified')
                                ) : (
                                    file.acceptanceSteps === 2 ? (
                                        this.context.t('OCR not accepted')
                                    ) : (
                                        file.ocrStatus === 'DONE' ? (
                                            this.context.t('Paid')
                                        ) : (
                                            this.context.t('Not paid')
                                        )
                                    )
                                )
                            }
                        </ReactTooltip>
                    </div>
                </div>
            );
        } else {
            return null
        }
    }


    render() {
        const { isFocused, searchOption, categoryIndex, showResults, resultsLoading, noMatchingFiles, filesToDisplay } = this.state
        const { currentProject, pdfPreviewOpen, actionsPreviewIsOpen, fullTextSearchPreviewIsOpen, previewClickFromChatNotification } = this.props

        return (
            <div className={`full-text-search-field ${isFocused ? 'is-focused' : ''}`}>


                {
                    pdfPreviewOpen && ((!actionsPreviewIsOpen && fullTextSearchPreviewIsOpen) || previewClickFromChatNotification) ? (
                        <PdfPreview location={this.props.location} prevAndNext={this.checkNext()} goToDoc={this.goToDoc} close={this.closePreview} customGoToDocControler={true} />
                    ) : null
                }

                <SearchInput
                    focusFullTextSearch={this.focusFullTextSearch}
                    showLoading={show => this.showLoading(show)}
                    unfocusSearch={this.unfocusSearch}
                    setSearchField={value => this.searchForFiles(value)}
                    projectCode={currentProject?.code}
                    isFocused={isFocused}
                    pdfPreviewOpen={pdfPreviewOpen}
                />

                <div className={`background ${isFocused ? 'show' : ''}`} ></div>
                <div className={`found-files-wrapper ${isFocused ? 'show' : ''}`}>
                    <div className="search-options">
                        <div className={`option ${searchOption === 'PARTIAL' ? 'selected' : ''}`} onClick={() => this.changeSearchOption('PARTIAL')}>
                            {this.context.t('search only by counterparty name, document number, comment or tags')}
                        </div>
                        <div className={`option ${searchOption === 'FULL' ? 'selected' : ''}`} onClick={() => this.changeSearchOption('FULL')}>
                            {this.context.t('search the entire document content')}
                        </div>
                    </div>
                    {
                        noMatchingFiles ? (
                            <div className="no-matching-files-found">
                                <div className="image"></div>
                                <div className="text">{this.context.t("No results were found for your search")}</div>
                            </div>
                        ) : (
                            <>
                                {
                                    !resultsLoading ? (
                                        <div className="found-docs-categories">
                                            {
                                                filesToDisplay.map((dir, i) => {
                                                    if (dir && dir.files && dir.files.length > 0) {
                                                        return (
                                                            <div
                                                                className={`title ${categoryIndex === i ? 'selected' : ''}`}
                                                                onClick={() => this.setState({ categoryIndex: i })}
                                                            >
                                                                {`${dir.projectCode} - ${this.context.t(dir.docsType)}`}
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    ) : null
                                }
                                <div className={`found-files ${showResults ? 'show' : ''}`}>
                                    <div className="file-row header-row">
                                        <div className="name">
                                            {this.context.t('Name')}
                                        </div>

                                        <div className="document-number">
                                            {this.context.t('Document number')}
                                        </div>

                                        <div className="issue-date">
                                            {this.context.t('Issue date')}
                                        </div>

                                        <div className="amount-to-pay">
                                            {this.context.t('Amount to pay')}
                                        </div>

                                        <div className="status">
                                            {
                                                this.context.t('Status')
                                            }
                                        </div>
                                    </div>
                                    {
                                        resultsLoading ? (
                                            <div className="placeholder-files-wrapper">
                                                {this.renderPlaceholders()}
                                            </div>
                                        ) : (
                                            <div className="files-wrapper" ref={this.filesWrapper}>
                                                {
                                                    filesToDisplay.map((dir, i) => {
                                                        if (dir && dir.files && dir.files.length > 0 && i === categoryIndex) {
                                                            return (
                                                                <AutoSizer disableHeight>
                                                                    {({ width }) => (
                                                                        <List
                                                                            ref={this.setListRef}
                                                                            width={width}
                                                                            height={this.filesWrapper?.current?.clientHeight || 100}
                                                                            rowHeight={62}

                                                                            rowCount={dir.files.length}
                                                                            rowGetter={({ index }) => dir.files[index]}
                                                                            rowRenderer={({ style, key, index, parent }) => this.rowRenderer(style, key, index, parent, width, dir.files)}
                                                                            overscanRowCount={2}
                                                                        />
                                                                    )}
                                                                </AutoSizer>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            </>
                        )
                    }
                </div>
            </div >
        )
    }
}

class SearchInput extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchField: '',
            lastSearchEditTime: null
        }
    }

    componentDidMount() {
        window.addEventListener("keydown", this.escFunction, false)
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.escFunction, false)
    }

    escFunction = e => {
        if (e.keyCode === 27) {
            this.hideSearch()
        }
    }

    onInputChange = e => {
        e.preventDefault()

        var { name, value } = e.target
        value = value.replace(XRegExp('[^\\.\\,\\-\\+\\(\\)\\$\\%\\_\\?\\!\\#\\"\\/\\d\\s\\p{Latin}]', 'gm'), '').replace(/\s\s+/gm, ' ')
        this.setState({
            [name]: value,
            lastSearchEditTime: new Date()
        })

        if (value.length >= 2) {
            this.props.showLoading(true)
        } else {
            this.props.showLoading(false)
        }

        setTimeout(() => {
            if (new Date() - this.state.lastSearchEditTime > 730) {
                this.props.setSearchField(value)
            }
        }, 750)
    }

    hideSearch = () => {
        const { pdfPreviewOpen } = this.props
        if (!pdfPreviewOpen) {
            this.props.showLoading(false)
            this.props.unfocusSearch()
            this.setState({
                searchField: ''
            })
        }
    }

    render() {
        const { searchField } = this.state
        const { isFocused, projectCode } = this.props
        return (
            <>
                {
                    isFocused ? (
                        <div className="close-button" onClick={() => this.hideSearch()}></div>
                    ) : (
                        null
                    )
                }
                <input
                    type="text"
                    value={searchField}
                    name="searchField"
                    onChange={e => this.onInputChange(e)}
                    onFocus={() => this.props.focusFullTextSearch()}
                    autoComplete="off"
                    placeholder={
                        projectCode ? (
                            this.context.t('Search for documents in {n} - by a fragment of the content (also finds grammatical variants), name, tags', { n: projectCode })
                        ) : (
                            this.context.t('Search for documents - by a fragment of the content (also finds grammatical variants), name, tags')
                        )
                    }
                />
            </>
        )
    }
}

class PlaceholderFile extends Component {
    render() {
        return (
            <div class="file-row">
                <div class="icon"></div>
                <div className="loading-text name"></div>
                <div className="loading-text document-number"></div>
                <div className="loading-text amount-to-pay"></div>
                <div className="loading-text status"></div>
            </div>
        )
    }
}

SearchInput.contextTypes = {
    t: PropTypes.func
}

FullTextSearchComponent.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
    currentProject: state.User.currentProject,
    userProjects: state.User.userProjects || [],
    uploadedFiles: [...state.File.uploadedFiles],
    pdfPreviewOpen: state.File.pdfPreviewOpen,
    actionsPreviewIsOpen: state.File.actionsPreviewIsOpen,
    fullTextSearchPreviewIsOpen: state.File.fullTextSearchPreviewIsOpen,
    previewClickFromChatNotification: state.File.previewClickFromChatNotification,
})

const mapDispatchToProps = {
    togglePreviewForFullTextSearch: FileActions.togglePreviewForFullTextSearch,
    getFiles: FileActions.getFiles,
    getFileBody: FileActions.getFileData,
    alertWarn: AlertActions.warning,
    alertDanger: AlertActions.danger,
}

export default connect(mapStateToProps, mapDispatchToProps)(FullTextSearchComponent)