export const generateProjectCode = (name) => {
    name = name.replace(/SPÓŁKA KOMANDYTOWA/gi, '').replace(/SPÓŁKA CYWILNA/gi, '').replace(/SPÓŁKA AKCYJNA/gi, '').replace(/SPÓŁKA DORADZTWA PODATKOWEGO/gi, '').replace(/SPÓŁKA PARTNERSKA/gi, '').replace(/SPÓŁKA JAWNA/gi, '').replace(/SP. Z O.O./gi, '').replace(/SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ/gi, '').replace(/SP. Z 0.0./gi, '').replace(/POLSKA/gi, '').replace(/\'/gm, '').replace(/\(/gm, '').replace(/\)/gm, '').replace(' - ', '-').trim()
    var indexOfComma = name.indexOf(',')

    if (indexOfComma > 0) {
        var tempName = name.substring(0, indexOfComma)
        tempName = tempName.trim()

        if ((tempName.match(/\"/gm) || []).length !== 1) {
            name = tempName
        }
    }

    if (name.includes('"')) {
        var newName = ((name.match(/\"(.*)\"/g)) || [])
        newName = newName.find(x => x !== undefined)

        if (newName) {
            newName = newName.replace(/\"/gm, '')
            newName = newName.trim()
            var wordsArray = newName.split(' ')
            if (wordsArray.length === 1) {
                newName = wordsArray[0]
                var indexOfDash = newName.lastIndexOf('-')
                if (indexOfDash > 0 && newName.length > 20) {
                    newName = newName.substring(0, indexOfDash)

                    return newName.substring(0, 20)
                } else {
                    return newName.substring(0, 20)
                }
            } else {
                var code = ''
                wordsArray.forEach(w => {
                    if (w.length > 2 && !w.match(/^\d/)) {
                        code += w[0]
                    } else {
                        code += w
                    }
                })

                return code
            }
        } else {
            return ''
        }
    } else {
        var wordsArray = name.split(' ')
        if (wordsArray.length === 1) {
            newName = wordsArray[0]
            var indexOfDash = newName.lastIndexOf('-')
            if (indexOfDash > 0) {
                newName = newName.substring(0, indexOfDash)

                return newName.substring(0, 20)
            } else {
                return newName.substring(0, 20)
            }
        } else if (wordsArray.length === 2) {
            var part1 = wordsArray[0]
            var part2 = wordsArray[1]

            if (part1.length + part2.length <= 19) {
                return part1 + '-' + part2
            } else {
                if (part1.includes('-')) {
                    return part1
                } else if (part2.includes('-')) {
                    return part2
                } else {
                    return part1 + '-' + part2.substring(0, 19 - part1.length)
                }
            }
        } else {
            var code = ''
            wordsArray.forEach(w => {
                if (w.length > 2 && !w.match(/^\d/)) {
                    code += w[0]
                } else {
                    code += w
                }
            })

            return code
        }
    }
}