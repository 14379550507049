import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import ReactTooltip from 'react-tooltip'

import { UserActions } from '../../../actions/UserActions'
import { AlertActions } from '../../../actions/AlertActions'

export class ProjectsList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            cursourWait: false,
            showDetailsPopup: false,
            selectedPeriodDetails: '',
        }
    }

    componentDidMount() {
    }

    showDetailsPopup = (period) => {
        this.props.getTransactionDetails(period.replace('.', '-'))
        this.setState({
            showDetailsPopup: true,
            selectedPeriodDetails: period,
        })
    }

    hideDetailsPopup = () => {
        this.setState({
            showDetailsPopup: false,
            selectedPeriodDetails: '',
        })
    }

    getSortClass = name => {
        const { easydocsPaymentsSortField, easydocsPaymentsSortOrder } = this.props
        console.log(easydocsPaymentsSortField, easydocsPaymentsSortOrder)
        if (easydocsPaymentsSortField === name) {
            if (easydocsPaymentsSortOrder === "asc")
                return "icon-sort up"
            return "icon-sort down"
        }
        return "icon-sort"
    }

    switchSortingField = (name) => {
        const { easydocsPaymentsSortField, easydocsPaymentsSortOrder } = this.props
        if (easydocsPaymentsSortField === name) {
            if (easydocsPaymentsSortOrder === "asc") {
                this.props.sortAllEDPaymentsForSuperadmin(name, 'desc')
            } else {
                this.props.sortAllEDPaymentsForSuperadmin(name, 'asc')
            }
        } else {
            this.props.sortAllEDPaymentsForSuperadmin(name, 'asc')
        }
    }

    downloadPaymentInvoice = (txId, fileName) => {
        this.setState({
            cursourWait: true
        })

        this.props.downloadEDPaymentInvoice(txId, fileName, () => {
            this.setState({
                cursourWait: false
            })
        })
    }

    detailsPopup = () => {
        return (
            <>
            <div className='popup'>
                <div className="card list">
                    <div className="close-button" onClick={() => this.hideDetailsPopup()}></div>
                    <div className="header">
                        {this.context.t('Payments details: {b}', { b: this.state.selectedPeriodDetails })}
                    </div>
                    <div className='payments-details'>
                        <table >
                            <thead>
                                <tr>
                                    <th onClick={() => this.switchSortingField('created', 'TRANSACTIONS')}>
                                        {this.context.t('Transaction date')}
                                        <span className={this.getSortClass('created', 'TRANSACTIONS')}></span>
                                    </th>
                                    <th onClick={() => this.switchSortingField('company', 'TRANSACTIONS')}>
                                        {this.context.t('Company')}
                                        {/* <span className={this.getSortClass('company', 'TRANSACTIONS')}></span> */}
                                    </th>
                                    <th className='price' onClick={() => this.switchSortingField('net_value', 'TRANSACTIONS')}>
                                        {this.context.t('Net value')}
                                        <span className={this.getSortClass('net_value', 'TRANSACTIONS')}></span>
                                    </th>
                                    <th className='price' onClick={() => this.switchSortingField('amount_decimal', 'TRANSACTIONS')}>
                                        {this.context.t('Gross value')}
                                        <span className={this.getSortClass('amount_decimal', 'TRANSACTIONS')}></span>
                                    </th>
                                    <th>
                                        {this.context.t('Type')}
                                    </th>
                                    <th onClick={() => this.switchSortingField('period_start', 'TRANSACTIONS')}>
                                        {this.context.t('Period')}
                                        <span className={this.getSortClass('period_start', 'TRANSACTIONS')}></span>
                                    </th>
                                    <th>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.easydocsDetailPayments ? (this.props.easydocsDetailPayments.map(tx => {
                                        return(
                                            <tr>
                                                <td>{moment(tx.created).format('YYYY-MM-DD HH:mm')}</td>
                                                <td>{tx.company.name}</td>
                                                <td className="price">{tx.net_value}</td>
                                                <td className="price">{tx.amount_decimal}</td>
                                                <td>{tx.transaction_id ? this.context.t('Card') : this.context.t('Transfer')}</td>
                                                <td>{`${moment(tx.period_start).format('YYYY-MM-DD HH:mm')} - ${moment(tx.period_end).format('YYYY-MM-DD HH:mm')}`}</td>
                                                <td className="download" onClick={() => this.downloadPaymentInvoice(tx.id, tx.file_name)} data-tip="show" data-for={`download-tx-invoice-${tx.id}`}></td>
                                            </tr>
                                        )
                                    })) : null
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                </div>
            </>
        )
    }

    render() {
        const { easydocsPayments, easydocsPaymentsReport, easydocsPaymentsYearsReport, easydocsPaymentsTotal, searchField, easydocsDetailPayments } = this.props
        const { cursourWait } = this.state

        return (
            <>
                {this.state.showDetailsPopup ? this.detailsPopup() : null}
                <div className="years">
                    <div className="year header">
                        <span className="label">{this.context.t('Year')}</span>
                        <span className="amount">{this.context.t('Sales')}</span>
                        <span className="change">{this.context.t('Annual change')}</span>
                    </div>

                    {
                        easydocsPaymentsYearsReport.map(y => {
                            return (
                                <div className="year">
                                    <span className="label">{y.year}</span>
                                    <span className="amount">{parseFloat(y.sum).toFixed(2)}</span>
                                    {
                                        y.change !== 0 ? (
                                            <span className={`change ${parseFloat(y.change) >= 0 ? 'plus' : 'minus'}`}>{y.change > 0 ? `+${y.change}` : y.change}%</span>
                                        ) : (
                                            null
                                        )
                                    }
                                </div>
                            )
                        })
                    }

                    <div className="year total">
                        <span className="label">{this.context.t('Total')}</span>
                        <span className="amount">{parseFloat(easydocsPaymentsTotal).toFixed(2)}</span>
                    </div>
                </div>
                <table className={`sales-report-table ${cursourWait ? 'cursour-wait' : ''}`}>
                    <thead>
                        <tr>
                            <th>
                                {this.context.t('Month')}
                            </th>
                            <th className="price">
                                {this.context.t('Net sales')}
                            </th>
                            <th>
                                {this.context.t('Monthly change')}
                            </th>
                            <th className="price">
                                {this.context.t('Net sum from new clients')}
                            </th>
                            <th className="price">
                                {this.context.t('Net sum from existing clients')}
                            </th>
                            <th className="count">
                                {this.context.t('New clients with subscription count')}
                            </th>
                            <th className="count">
                                {this.context.t('Number of users from new clients with sub')}
                            </th>
                            <th className="count">
                                {this.context.t('Number of users for existing clients')}
                            </th>
                            <th className="count">
                                {this.context.t('Clients count')}
                            </th>
                            <th className="count">
                                {this.context.t('Number of users')}
                            </th>
                            <th className="count">
                                {this.context.t('New clients with active trial period count')}
                            </th>
                            <th className="count">
                                {this.context.t('New clients without active trial period count')}
                            </th>
                            <th className="count"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            easydocsPaymentsReport.map(tx => {
                                return (
                                    <tr>
                                        <td className="month">{tx.month}</td>
                                        <td className="price">{tx.netSum}</td>
                                        <td className={`change ${parseFloat(tx.monthlyChange) >= 0 ? 'plus' : 'minus'}`}>{tx.monthlyChange > 0 ? `+${tx.monthlyChange}` : tx.monthlyChange}%</td>
                                        <td className="price">{tx.netSumFromNewClients}</td>
                                        <td className="price">{tx.netSumFromExistingClients}</td>
                                        <td className="count">{tx.newClientsCount}</td>
                                        <td className="count">{tx.usersCountFromNewClients}</td>
                                        <td className="count">{tx.usersCountFromExistingClients}</td>
                                        <td className="count">{tx.clientsCount}</td>
                                        <td className="count">{tx.usersCount}</td>
                                        <td className="count">{tx.newClientsWithActiveTrial}</td>
                                        <td className="count">{tx.newClientsWithoutActiveTrial}</td>
                                        <td >
                                            <div className="reports" onClick={() => this.showDetailsPopup(tx.month)}>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </>
            
            // <>
            //     {
            //         easydocsPayments.map(tx => {
            //             return(
            //                 <ReactTooltip id={`download-tx-invoice-${tx.id}`} className="default-tooltip tx-status-tooltip" place="left" effect="solid">
            //                     {this.context.t('Download an invoice for a given payment')}
            //                 </ReactTooltip>
            //             )
            //         })
            //     }
            // </>
        )
    }
}

ProjectsList.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    easydocsPayments: state.User.easydocsPayments || [],
    easydocsPaymentsReport: state.User.easydocsPaymentsReport || [],
    easydocsPaymentsYearsReport: state.User.easydocsPaymentsYearsReport || [],
    easydocsPaymentsTotal: state.User.easydocsPaymentsTotal || 0,
    easydocsPaymentsSortField: state.User.easydocsPaymentsSortField,
    easydocsPaymentsSortOrder: state.User.easydocsPaymentsSortOrder,
    easydocsDetailPayments: state.User.easydocsDetailPayments,
})

const mapDispatchToProps = {
    sortAllEDPaymentsForSuperadmin: UserActions.sortAllEDPaymentsForSuperadmin,
    downloadEDPaymentInvoice: UserActions.downloadEDPaymentInvoice,
    alertWarn: AlertActions.warning,
    getTransactionDetails: UserActions.getTransactionDetails,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsList)