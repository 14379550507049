import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import routes from '../helpers/routes'

import { TabsControlerActions } from '../actions/V2_TabsControlerActions'

import { AvailableTabs, SettingsTabsComponents } from "../reducers/V2_TabsReducer"

export class SettingsTabsBar extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        const { currentCompany, user } = this.props

        return (
            <div className="page-header settings">
                <div className="page-name settings">
                    {this.context.t('Settings')}
                </div>
                <div className="tabs settings-tabs">
                    {
                        user.new_easydocs_version ? (
                            <div className={`tab ${window.location.href.includes('/settings/profile') ? 'active' : ''}`} onClick={() => this.props.openNewTab(AvailableTabs.SETTINGS, SettingsTabsComponents.PROFILE)}>
                                <p>{this.context.t('Personal profile')}</p>
                            </div>
                        ) : (
                            <div className={`tab`}>
                                <NavLink className="nav-link" to={routes.panel.settings.profile}>
                                    <span className="nav-icon cog-icon"></span>
                                    <p>{this.context.t('Personal profile')}</p>
                                </NavLink>
                            </div>
                        )
                    }


                    {
                        currentCompany && currentCompany.user_role === 'ADMIN' ? (
                            <>
                                {
                                    user.new_easydocs_version ? (
                                        <div className={`tab ${window.location.href.includes('/settings/company') ? 'active' : ''}`} onClick={() => this.props.openNewTab(AvailableTabs.SETTINGS, SettingsTabsComponents.COMPANY)}>
                                            <p>{this.context.t('Company')}</p>
                                        </div>
                                    ) : (
                                        <div className={`tab`}>
                                            <NavLink className="nav-link" to={routes.panel.settings.company}>
                                                <span className="nav-icon company-icon"></span>
                                                <p>{this.context.t('Company')}</p>
                                            </NavLink>
                                        </div>
                                    )
                                }

                                {
                                    user.new_easydocs_version ? (
                                        <div className={`tab ${window.location.href.includes('/settings/projects') ? 'active' : ''}`} onClick={() => this.props.openNewTab(AvailableTabs.SETTINGS, SettingsTabsComponents.BINDERS)}>
                                            <p>{this.context.t('Binders')}</p>
                                        </div>
                                    ) : (
                                        <div className={`tab`}>
                                            <NavLink className="nav-link" to={routes.panel.settings.projects}>
                                                <span className="nav-icon projects-icon"></span>
                                                <p>{this.context.t('Binders')}</p>
                                            </NavLink>
                                        </div>
                                    )
                                }
                                {
                                    user.new_easydocs_version ? (
                                        <div className={`tab ${window.location.href.includes('/settings/counterparties') ? 'active' : ''}`} onClick={() => this.props.openNewTab(AvailableTabs.SETTINGS, SettingsTabsComponents.COUNTERPARTIES)}>
                                            <p>{this.context.t('Counterparties')}</p>
                                        </div>
                                    ) : (

                                        <div className={`tab`}>
                                            <NavLink className="nav-link" to={routes.panel.settings.counterparties}>
                                                <span className="nav-icon counterparties-icon"></span>
                                                <p>{this.context.t('Counterparties')}</p>
                                            </NavLink>
                                        </div>
                                    )
                                }

                                {
                                    user.new_easydocs_version ? (
                                        <div className={`tab ${window.location.href.includes('/settings/subscription') ? 'active' : ''}`} onClick={() => this.props.openNewTab(AvailableTabs.SETTINGS, SettingsTabsComponents.SUBSCRIPTION)}>
                                            <p>{this.context.t('Subscription')}</p>
                                        </div>
                                    ) : (
                                        <div className={`tab`}>
                                            <NavLink className="nav-link" to={routes.panel.settings.subscription}>
                                                <span className="nav-icon subscription-icon"></span>
                                                <p>{this.context.t('Subscription')}</p>
                                            </NavLink>
                                        </div>
                                    )
                                }
                            </>
                        ) : null
                    }
                </div>
            </div >
        )
    }
}

SettingsTabsBar.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    currentCompany: state.Company.currentCompany,
})

const mapDispatchToProps = {
    openNewTab: TabsControlerActions.openNewTab,
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsTabsBar)





