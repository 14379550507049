import { waProjects } from './wineAvenue.js';
import { aventineProjects } from './aventine.js';
import { notelProjects } from './notel.js';
import { profmProjects } from './profm.js';
import { bajkaPanaKleksaProjects } from './bajkaPanaKleksa.js';
import { impressProjects } from './impressGroup.js';

const allProjectsWithMpk = [
    ...waProjects,
    ...aventineProjects,
    ...notelProjects,
    ...profmProjects,
    ...bajkaPanaKleksaProjects,
    ...impressProjects
]

export default allProjectsWithMpk
