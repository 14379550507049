import { history } from "../helpers/history"
import routes from "../helpers/routes"
import myFetch from "../helpers/fetch"
import moment from 'moment'

import { EventConst } from "../reducers/EventReducer"
import { AlertActions } from './AlertActions'
import { UserConst } from "../reducers/UserReducer"
import { CompanyConst } from "../reducers/CompanyReducer"
import { ReportsConst } from "../reducers/ReportsReducer"
import { FileConst } from "../reducers/FileReducer"

export const EventActions = {
    getEvents,
    getProjectEvents,
    addEvent,
    updateEvent,
    deleteEvent,
    selectDay,
    unselectDay,
    datePickerSelect,
    datePickerUnselect,
    setCurrentMonth,
    getCalendarAuthorizationLink,
    authorizeGoogleCalendar,
    authorizedGoogleCalendarFromSocket,
    revokeGoogleCalendarAccess,
    getGoogleCalendarsList,
    getGoogleCalendarName,
    setDefaultGoogleCalendar,
    createNewGoogleCalendar,
    toggleDashboardCalendarForProject,
}

function getEvents(data) {
    return dispatch => {
        myFetch(`/calendar/get-my-events/?data=${JSON.stringify(data)}`, { method: 'get' }).then(result => {
            dispatch({ type: EventConst.GET_EVENTS, events: result })
        }).catch(err => {
            handleError(err, dispatch, `/calendar/get-my-events/?data=${JSON.stringify(data)}`)
        })
    }
}

function getProjectEvents(data, projectId) {
    return dispatch => {
        dispatch({ type: EventConst.GET_PROJECT_EVENTS_REQUEST, projectId: projectId })
        myFetch(`/calendar/get-my-events/?data=${JSON.stringify(data)}`, { method: 'get' }).then(result => {
            dispatch({ type: EventConst.GET_PROJECT_EVENTS, projectEvents: result, projectId: projectId, count: result.length })
        }).catch(err => {
            handleError(err, dispatch, `/calendar/get-my-events/?data=${JSON.stringify(data)}`)
        })
    }
}

function addEvent(event, project) {
    console.log("event: ")
    console.log(event)
    console.log("new event data: " + moment(event.startDate).format("DD-MM-YYYY HH:mm"))
    return dispatch => {
        myFetch(`/calendar/create-event`, {
            method: 'post', body: {
                event: event
            }
        }).then(res => {
            if (res.success) {
                dispatch({ type: EventConst.ADD_EVENT, events: res.events, project: project })
                dispatch(AlertActions.info("Event successfully added."))
            }
        }).catch(err => {
            handleError(err, dispatch, `/calendar/create-event`)
        })
    }
}

function updateEvent(event, editAllEvents) {
    console.log("new event data: " + moment(event.startDate).format("DD-MM-YYYY HH:mm"))
    console.log(event)
    return dispatch => {
        myFetch(`/calendar/update-event`, {
            method: 'post', body: {
                newEventData: event,
                editAllEvents: editAllEvents,
            }
        }).then(res => {
            if (res.success) {
                dispatch({ type: EventConst.UPDATE_EVENT_SUCCESS, updatedEvents: res.updatedEvents, editedMultiple: editAllEvents })
                dispatch(AlertActions.info("Event successfully updated."))
            }
        }).catch(err => {
            handleError(err, dispatch, `/calendar/update-event`)
        })
    }
}
function deleteEvent(eventId, editAllEvents) {
    return dispatch => {
        myFetch(`/calendar/delete-event`, {
            method: 'post', body: {
                eventId: eventId,
                editAllEvents: editAllEvents,
            }
        }).then(res => {
            if (res.success) {
                dispatch({ type: EventConst.DELETE_EVENT_SUCCESS, deletedEvents: res.deletedEvents, deletedMultiple: editAllEvents })
                dispatch(AlertActions.info("Event successfully deleted."))
            }
        }).catch(err => {
            handleError(err, dispatch, `/calendar/delete-event`)
        })
    }
}

function selectDay(date) {
    return dispatch => {
        dispatch({ type: EventConst.SELECT_DAY, day: { display: moment(date).format('LL'), value: date } })
    }
}

function unselectDay() {
    return dispatch => {
        dispatch({ type: EventConst.SELECT_DAY, day: { display: null, value: null } })
    }
}

function setCurrentMonth(date) {
    return dispatch => {
        dispatch({ type: EventConst.SET_MONTH, month: date })
    }
}

function datePickerSelect(date) {
    return dispatch => {
        dispatch({ type: EventConst.DATE_PICKER_SELECT_DAY, day: { display: moment(date).format('LL'), value: date } })
    }
}

function datePickerUnselect() {
    return dispatch => {
        dispatch({ type: EventConst.DATE_PICKER_SELECT_DAY, day: { display: null, value: null } })
    }
}

function getCalendarAuthorizationLink(id) {
    return dispatch => {
        myFetch(`/users/get-google-authorization-link`, {
            method: 'post', body: {
                userId: id
            }
        }).then(res => {
            if (res.success) {
                window.open(res.authUlr, "_blank");
            } else {
                dispatch(AlertActions.warning(res.message))
            }
        }).catch(err => {
            handleError(err, dispatch, `/users/get-google-authorization-link`)
        })
    }
}

function authorizeGoogleCalendar(code, id, cb) {
    return dispatch => {
        myFetch(`/users/authorize-google-calendar`, {
            method: 'post', body: {
                code: code,
                userId: id
            }
        }).then(res => {
            if (res.success) {
                cb(true)
                dispatch({ type: EventConst.AUTHORIZED_GOOGLE_CALENDAR })
                dispatch({ type: UserConst.UPDATE_USER_PROFILE, user: res.user })
                dispatch({ type: EventConst.GET_GOOGLE_CALENDARS_LIST, calendarsList: res.calendars })
            } else {
                cb(false)
                if (res.reason === 'insufficientPermissions') {
                    dispatch({ type: EventConst.NOT_AUTHORIZED_GOOGLE_CALENDAR, message: "The necessary consents were not given at the authorization stage. Authorization has been revoked and synchronization of EasyDocs with Google Calendar has been canceled.", reason: 'insufficientPermissions' })
                } else {
                    dispatch({ type: EventConst.NOT_AUTHORIZED_GOOGLE_CALENDAR, message: res.message })
                }
            }
        }).catch(err => {
            cb(false)
            handleError(err, dispatch, `/users/authorize-google-calendar`)
        })
    }
}

function authorizedGoogleCalendarFromSocket(user, calendars) {
    return dispatch => {
        dispatch({ type: EventConst.AUTHORIZED_GOOGLE_CALENDAR })
        dispatch({ type: UserConst.UPDATE_USER_PROFILE, user: user })
        dispatch({ type: EventConst.GET_GOOGLE_CALENDARS_LIST, calendarsList: calendars })
    }
}

function revokeGoogleCalendarAccess() {
    return dispatch => {
        myFetch(`/users/revoke-google-calendar-access`, { method: 'put' })
            .then(res => {
                if (res.success) {
                    dispatch({ type: UserConst.UPDATE_USER_PROFILE, user: res.user })
                } else {
                    dispatch(AlertActions.info(res.msg))
                }
            }).catch(err => {
                handleError(err, dispatch, `/users/revoke-google-calendar-access`)
            })
    }
}

function setDefaultGoogleCalendar(calendar) {
    return dispatch => {
        myFetch(`/users/set-default-google-calendar`, {
            method: 'put', body: {
                calendar: calendar,
            }
        })
            .then(res => {
                if (res.success) {
                    dispatch({ type: UserConst.UPDATE_USER_PROFILE, user: res.user })
                } else {
                    dispatch(AlertActions.info(res.msg))
                }
            }).catch(err => {
                handleError(err, dispatch, `/users/set-default-google-calendar`)
            })
    }
}

function createNewGoogleCalendar(newCalendarsName) {
    return dispatch => {
        myFetch(`/users/set-default-google-calendar`, {
            method: 'put', body: {
                newCalendarsName: newCalendarsName
            }
        })
            .then(res => {
                if (res.success) {
                    dispatch({ type: UserConst.UPDATE_USER_PROFILE, user: res.user })
                } else {
                    dispatch(AlertActions.info(res.msg))
                }
            }).catch(err => {
                handleError(err, dispatch, `/users/set-default-google-calendar`)
            })
    }
}

function getGoogleCalendarsList(userId) {
    return dispatch => {
        dispatch({ type: EventConst.SEND_GOOGLE_CALENDAR_LIST_REQUEST })

        myFetch(`/users/get-google-calendars-list/`, { method: 'get' }).then(res => {
            if (res.success) {
                dispatch({ type: EventConst.GET_GOOGLE_CALENDARS_LIST, calendarsList: res.list })
            }
        }).catch(err => {
            handleError(err, dispatch, `/users/get-google-calendars-list`)
        })
    }
}

function getGoogleCalendarName(calendarId) {
    return dispatch => {
        myFetch(`/users/get-google-calendars-name/${calendarId}`, { method: 'get' }).then(res => {
            if (res.success) {
                if (res.calendar) {
                    dispatch({ type: EventConst.GET_GOOGLE_CALENDARS_NAME, calendarName: res.calendar.summary })
                }
            }
        }).catch(err => {
            handleError(err, dispatch, `/users/get-google-calendars-name/${calendarId}`)
        })
    }
}

function toggleDashboardCalendarForProject(projectId, extend) {
    return dispatch => {
        dispatch({ type: EventConst.TOGGLE_DASHBOARD_CALENDAR, projectId: projectId, extend: extend })
    }
}


function handleError(error, dispatch, path) {
    console.log(error)
    if (error?.code === 'AUTHORIZATION_REQUIRED' && localStorage.getItem('dashboardProject')) {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        dispatch({ type: UserConst.LOGGED_FALSE })
        dispatch({ type: UserConst.LOGOUT })
        dispatch({ type: EventConst.LOGOUT })
        dispatch({ type: CompanyConst.LOGOUT })
        dispatch({ type: ReportsConst.LOGOUT })
        dispatch({ type: FileConst.LOGOUT })
        dispatch(AlertActions.info("You have been logged out.", 5000))
    } else {
        dispatch(AlertActions.setError(error, path));
        dispatch(AlertActions.danger("There was an internal server error while procesing request. Try again later."));
    }
}
