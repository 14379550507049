import React, { Component } from 'react'
import { connect } from 'react-redux'
import $ from 'jquery'
import { UserActions } from '../actions/UserActions'
import { AlertActions } from '../actions/AlertActions'
import { Link, Redirect } from 'react-router-dom'
import routes from '../helpers/routes'
import PropTypes from 'prop-types'
import Logo from '../img/easy-docs-logo-big.svg'
import LanguageSwitcher from '../components/LanguageSwitcher'
import ReactTooltip from 'react-tooltip'
import generator from 'generate-password'
import copy from 'copy-to-clipboard';

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/

export class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            password: '',
            password2: '',
            passwordVisible: false,
            password2Visible: false,
            email: '',
            token: '',
        }
    }


    componentDidMount() {
        //ReactGA.pageview(window.location.pathname + window.location.search)
        var params = this.props.location.search
        params = params.substr(1)

        var paramsArray = params.split("&")
        var email = '', token = '';

        paramsArray.forEach(param => {
            var individualParam = param.split("=")
            if (individualParam[0] === "email") {
                email = individualParam[1]
            } else if (individualParam[0] === 'token') {
                token = individualParam[1]
            }
        })

        this.setState({
            email: email.toLowerCase(),
            token: token
        })
    }

    onInputChange = e => {
        e.preventDefault()

        const { name, value } = e.target

        this.setState({ [name]: value })
    }

    hasLowerCase = (str) => {
        return (/[a-z]/.test(str));
    }

    hasUpperCase = (str) => {
        return (/[A-Z]/.test(str));
    }

    hasDigit = (str) => {
        return (/[0-9]/.test(str));
    }

    sendResetPassword = e => {
        e.preventDefault()
        const { setPasswordButtonInactive } = this.props

        if (!setPasswordButtonInactive) {
            const { email, password, password2, token } = this.state

            var valid = true

            if (password.length === 0) {
                valid = false
                this.props.alertWarn(this.context.t('Password is required'))
            }
            else if (password !== password2) {
                valid = false
                this.props.alertWarn(this.context.t('Given passwords don\'t match'))
            }
            else if (password.length < 12) {
                valid = false
                this.props.alertWarn(this.context.t('Passwords must be at least 12 characters long.'))
            }
            else if (!this.hasLowerCase(password) || !this.hasUpperCase(password) || !this.hasDigit(password)) {
                valid = false
                this.props.alertWarn(this.context.t('Password must contain at least one uppercase letter, one lowercase letter and a number.'))
            }

            if (valid) {
                this.props.resetPassword(email, token, password)
            }
        }
    }

    changeVisibility = name => {
        if (name === 'p1') {
            if (this.state.passwordVisible === false) {
                this.refs.password1.type = 'text'
                this.setState({
                    passwordVisible: true
                })
            } else {
                this.refs.password1.type = 'password'
                this.setState({
                    passwordVisible: false
                })
            }
        } else {
            if (this.state.password2Visible === false) {
                this.refs.password2.type = 'text'
                this.setState({
                    password2Visible: true
                })
            } else {
                this.refs.password2.type = 'password'
                this.setState({
                    password2Visible: false
                })
            }
        }
    }

    generatePassowrd = () => {
        var password = generator.generate({
            length: 12,
            numbers: true,
            lowercase: true,
            uppercase: true,
            symbols: false,
            excludeSimilarCharacter: true,
            strict: true,
        });

        this.setState({
            password: password,
            password2: password
        })
    }


    render() {
        const { password, password2, passwordVisible, password2Visible } = this.state

        const { user, setPasswordButtonInactive } = this.props

        if (user !== null) {
            this.props.alertWarn(this.context.t('You can\'t reset password when you are logged in'))
            return <Redirect to={routes.panel.home} />
        }

        return (
            <div className="create-new-password">
                <LanguageSwitcher />
                <div className="header">
                    <div className="logo">
                        <a href={'https://easydocs.pl/'} />
                        <img src={Logo} alt="EasyDocs Logo" />
                    </div>
                    <div className="go-back" onClick={() => this.props.history.goBack()}>{this.context.t('Go back')}</div>
                </div>

                <div className="form-col">
                    <form onSubmit={this.sendResetPassword}>
                        <h1>{this.context.t('Create new password')}</h1>

                        <div className="form-group">
                            <input type="password" name="password" id="password" value={password} onChange={this.onInputChange} placeholder="" ref="password1"></input>
                            <label htmlFor="password">{this.context.t('Password')}</label>
                            {
                                password ? (
                                    <span data-for="copy-password" data-tip="show" className={`copy-to-clipboard`} onClick={e => { copy(password); this.props.alertInfo('Copied to clipboard', 2500) }}>
                                        <div className="info-cloud">
                                            <div className="info-text">{this.context.t('Copy password.')}</div>
                                        </div>
                                    </span>
                                ) : null
                            }
                            <span data-for="generate-password" data-tip="show" className={`password-generator`} onClick={() => this.generatePassowrd()}>
                                <div className="info-cloud">
                                    <div className="info-text">{this.context.t('Generate strong password.')}</div>
                                </div>
                            </span>
                            <span className="info">
                                <div className="info-cloud">
                                    <div className="info-text">{this.context.t('Passwords must be at least 12 character long and contain at least one lowercase, one uppercase character and one digit')}</div>
                                </div>
                            </span>
                            <ReactTooltip id='password1-tooltip' place="top" effect="solid" className="default-tooltip password">
                                <p>{this.context.t("")}</p>
                            </ReactTooltip>
                            <span className={`eye ${passwordVisible ? 'visible' : ''}`} onClick={() => this.changeVisibility('p1')}></span>
                        </div>

                        <div className="form-group">
                            <input type="password" name="password2" id="password2" value={password2} onChange={this.onInputChange} placeholder="" ref="password2" />
                            <label htmlFor="password2">{this.context.t('Confirm password')}</label>
                            <span className={`eye ${password2Visible ? 'visible' : ''}`} onClick={() => this.changeVisibility('p2')}></span>
                        </div>

                        <input type="submit" className={`${setPasswordButtonInactive ? 'inactive' : ''}`} value={this.context.t("Reset password")} />
                    </form>
                </div>
                <div className="img-col">
                    <div className="text">
                        {this.context.t('Easy and convenient way to manage documents')}
                    </div>
                </div>
            </div>
        )
    }
}

Home.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    setPasswordButtonInactive: state.User.setPasswordButtonInactive,
    user: state.User.user
})

const mapDispatchToProps = {
    resetPassword: UserActions.resetPassword,
    alertWarn: AlertActions.warning,
    alertInfo: AlertActions.info,
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)

