import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Spinner } from "react-activity";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import SimpleBar from "simplebar-react";

import { CompanyActions } from "../../actions/CompanyActions";
import { AlertActions } from "../../actions/AlertActions";

import { splitAddress } from "../../helpers/splitAddress";
import paymentMethodsList from '../../helpers/paymentMethodsList.json'

import CountriesList from "../CountriesList";
import InvoicePreview from "./InvoicePreview";
import Calendar from "../Calendar";
import { DOCUMENT_TYPE } from "../../pages/User/SalesPage";

import "../../../src/css/CorrectionBuyerForm.css";

export class CorrectionBuyerForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentNumber: "",
      numberingSeriesId: -1,
      allSeriesDocumentNumbers: [],
      showDocumentNumberSeriesList: false,
      documentLanguage: 'pl',
      documentType: DOCUMENT_TYPE.corrBuyer,
      issueDate: moment().format("YYYY-MM-DD"),
      saleDate: moment().format("YYYY-MM-DD"),
      searchBuyerVatId: "",
      buyerName: "",
      buyerVatId: "",
      buyerAddress: "",
      buyerPostCode: "",
      buyerCity: "",
      buyerCountryCode: "",
      sellerName: "",
      sellerVatId: "",
      sellerAddress: "",
      sellerPostCode: "",
      sellerCity: "",
      issuePlace: "",
      accountNumber: "",
      bankName: "",
      bankBIC: "",
      accountsList: [],
      invoicePositions: [
        {
          name: "",
          amount: 1,
          unit: "szt.",
          netPrice: "",
          vat: "23",
          netValue: "",
          grossValue: "",
        },
      ],
      summary: {
        netSum: 0,
        vatSum: 0,
        grossSum: 0,
      },
      paymentMethod: "transfer",
      dueDate: moment().add(7, "days").format("YYYY-MM-DD"),
      paymentStatus: "unpaid",
      currency: "PLN",

      correctedInvoiceNumber: '',
      correctedIssueDate: null,
      showCorrectedIssueDateCalendar: false,
      correctedSaleDate: null,
      showCorrectedSaleDateCalendar: false,

      savedInvoicePositions: [],
      unitListToShow: -1,
      posListToShow: -1,
      savedDocItems: [],
      savedCounterparties: [],
      invoicesList: [],
      logo: null,
      buyerNotFromPoland: false,
      buyerEmail: "",
      counterpartiesList: [],
      showCounterpartiesList: false,
      showInvoicePreviewPopup: false,
      fileBody: null,
      fileName: null,
      editingExistingInvoice: false,
      editedInvoiceId: null,
      currencyMidPrice: 0,
      showCurrencyMidPrice: false,
      reasonForCorrection: "",
      showReasonForCorrectionList: false,
      correctedInvoiceId: null,
      showCountriesList: false,
      buyerCountryCode: 'PL',
      sendToKsef: false,
    };
  }

  componentDidMount() {
    window.addEventListener("keydown", this.escFunction, false);
    const { currentCompany } = this.props;
    if (currentCompany) {
      this.setData();
    }
  }

  componentDidUpdate(prevProps) {
    console.log(this.props.selectedInvoiceData?.id)
    console.log(prevProps.selectedInvoiceData?.id)
    if (
      this.props.currentProject !== prevProps.currentProject ||
      this.props.currentCompany !== prevProps.currentCompany ||
      this.props.selectedInvoiceData?.id !== prevProps.selectedInvoiceData?.id
    ) {
      this.setData();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.escFunction, false);
  }

  searchVatAPI = () => {
    const { waitingForVatApiReponse, searchBuyerVatId } = this.state;
    if (!waitingForVatApiReponse) {
      if (searchBuyerVatId.length === 10) {
        this.setState({
          waitingForVatApiReponse: true,
        });

        fetch(
          `https://wl-api.mf.gov.pl/api/search/nip/${searchBuyerVatId.replace(
            /[^0-9]/gi,
            ""
          )}?date=${moment().format("YYYY-MM-DD")}`,
          { method: "get" }
        )
          .then((response) => response.json())
          .then((json) => {
            if (json && json.result && json.result.subject) {
              var company = json.result.subject;
              var companyAddress =
                json.result.subject.residenceAddress ||
                json.result.subject.workingAddress;
              var address = "";
              var zipCode = "";
              var city = "";
              if (companyAddress) {
                const sAddress = splitAddress(companyAddress);
                address = sAddress.address;
                zipCode = sAddress.zipCode;
                city = sAddress.city;
              }

              var name = company.name
                .replace(/SPÓŁKA KOMANDYTOWA/gi, "")
                .replace(/SPÓŁKA CYWILNA/gi, "")
                .replace(/SPÓŁKA AKCYJNA/gi, "")
                .replace(/SPÓŁKA DORADZTWA PODATKOWEGO/gi, "")
                .replace(/SPÓŁKA PARTNERSKA/gi, "")
                .replace(/SPÓŁKA JAWNA/gi, "")
                .replace(/SP. Z O.O./gi, "")
                .replace(/SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ/gi, "")
                .replace(/SP. Z 0.0./gi, "")
                .replace(/POLSKA/gi, "")
                .replace(/\'/gm, "")
                .replace(/\(/gm, "")
                .replace(/\)/gm, "")
                .replace(" - ", "-");

              name = name.trim();
              var code = this.generateCode(name);

              this.setState({
                buyerName: company.name,
                buyerVatId: company.nip,
                buyerAddress: address,
                buyerPostCode: zipCode,
                buyerCity: city,
                buyerCountryCode: "PL",
                waitingForVatApiReponse: false,
                showVatIDSearchPopup: false,
              });
            } else {
              this.props.alertWarn(
                this.context.t(
                  "No company with the given VatID was found. Make sure the number you entered is correct"
                )
              );
              this.setState({
                waitingForVatApiReponse: false,
              });
            }
          })
          .catch((err) => {
            this.props.alertWarn(
              this.context.t(
                "An error occurred while searching, please enter the buyer details manually or try again later."
              )
            );
            this.setState({
              waitingForVatApiReponse: false,
              showVatIDSearchPopup: false,
            });
          });
      } else {
        this.props.alertWarn(
          this.context.t("Given tax identification number is incorrect.")
        );
      }
    }
  };

  clickOnDatePicker = (e, name) => {
    if (e.target.className.includes("day-cell")) {
      if (name === "issueDate") {
        this.setState({
          showIssueDateCalendar: false,
        });
      } else if (name === "dueDate") {
        this.setState({
          showDueDateCalendar: false,
        });
      } else if (name === "saleDate") {
        this.setState({
          showSaleDateCalendar: false,
        });
      } else if (name === "correctedIssueDate") {
        this.setState({
          showCorrectedIssueDateCalendar: false,
        });
      } else if (name === "correctedSaleDate") {
        this.setState({
          showCorrectedSaleDateCalendar: false,
        });
      }
    }
  };

  onInputChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    this.setState({ [name]: value });
    if (name === "buyerName") this.setState({ showCounterpartiesList: true });
  };

  showSearchVatIdPopup = () => {
    window.addEventListener("keydown", this.searchOnEnter, false);
    this.setState({
      showVatIDSearchPopup: true,
    });
  };

  upadeSummary = (e) => {
    var { name, value } = e.target;
    var { invoicePositions } = this.state;
    var propName = name.replace(/[^a-zA-Z]/g, "");
    var posIndex = name.replace(`${propName}-`, "");

    if (posIndex > -1 && invoicePositions[posIndex].netPrice) {
      var newNetPrice = parseFloat(invoicePositions[posIndex].netPrice).toFixed(
        2
      ),
        newNetValue = parseFloat(invoicePositions[posIndex].netValue).toFixed(
          2
        ),
        newGrossValue = parseFloat(
          invoicePositions[posIndex].grossValue
        ).toFixed(2),
        newVat = isNaN(parseFloat(invoicePositions[posIndex].vat))
          ? 0.0
          : parseFloat(invoicePositions[posIndex].vat),
        newAmount = invoicePositions[posIndex].amount;

      if (
        propName === "amount" ||
        propName === "vat" ||
        propName === "netPrice"
      ) {
        if (newNetPrice) {
          newNetValue = (
            parseFloat(newNetPrice) * parseFloat(newAmount)
          ).toFixed(2);
          newGrossValue = (
            (parseFloat(newNetValue) * (100 + parseFloat(newVat))) /
            100
          ).toFixed(2);
        }
      } else if (propName === "netValue") {
        newGrossValue = (
          (parseFloat(newNetValue) * (100 + parseFloat(newVat))) /
          100
        ).toFixed(2);
        newNetPrice = (parseFloat(newNetValue) / parseFloat(newAmount)).toFixed(
          2
        );
      } else if (propName === "grossValue") {
        newNetValue = (
          parseFloat(newGrossValue) /
          ((100 + parseFloat(newVat)) / 100)
        ).toFixed(2);
        newNetPrice = (parseFloat(newNetValue) / parseFloat(newAmount)).toFixed(
          2
        );
      }

      invoicePositions[posIndex].amount = newAmount;
      invoicePositions[posIndex].netPrice = newNetPrice;
      invoicePositions[posIndex].netValue = newNetValue;
      invoicePositions[posIndex].grossValue = newGrossValue;

      var summary = {
        netSum: 0,
        vatSum: 0,
        grossSum: 0,
      };

      invoicePositions.map((pos) => {
        if (pos.netValue && pos.grossValue && pos.vat) {
          var vat = isNaN(parseFloat(pos.vat)) ? 0.0 : parseFloat(pos.vat);
          summary.vatSum += parseFloat(pos.netValue) * (vat / 100);
          summary.netSum += parseFloat(pos.netValue);
          summary.grossSum += parseFloat(pos.grossValue);
        }
      });

      this.setState({
        invoicePositions: invoicePositions,
        summary: summary,
      });
    }
  };

  selectDueDate = (date) => {
    this.setState({
      dueDate: moment(date).format("YYYY-MM-DD"),
    });
  };

  selectCorrectedIssueDate = date => {
    this.setState({
      correctedIssueDate: moment(date).format('YYYY-MM-DD')
    })
  }

  selectCorrectedSaleDate = date => {
    this.setState({
      correctedSaleDate: moment(date).format('YYYY-MM-DD')
    })
  }

  setInvoiceNumber = (month, companyId, docType) => {
    const { sellerId, documentType, numberingSeriesId } = this.state
    if (docType) docType = docType.toLowerCase().replace(" ", "-");
    this.props.getDocumentNumberForNewInvoice(
      month,
      companyId || sellerId,
      docType || documentType.toLowerCase().replace(" ", "-"),
      (nextNumbers, noSeriesDefined) => {
        if (nextNumbers && nextNumbers.length > 0) {
          var choosenSeries = null
          if (numberingSeriesId > -1) {
            choosenSeries = nextNumbers.find(s => s.seriesId === numberingSeriesId)
          }
          if (!choosenSeries) choosenSeries = nextNumbers[0]
          this.setState({
            documentNumber: choosenSeries.number,
            numberingSeriesId: choosenSeries.seriesId,
            allSeriesDocumentNumbers: nextNumbers
          });
        } else {
          var docNumber = "1/" + moment().format("MM/YYYY");
          this.setState({
            documentNumber: docNumber,
          });
        }
      }
    );
  };

  hasAncestor = (element, id) => {
    while (element) {
      if (element.id && element.id === id) return true;
      element = element.parentNode;
    }
    return false;
  };

  closeAllSuggestions = (e) => {
    if ((e.keyCode === 27 || !this.hasAncestor(e.target, "documentTypeContainer")) && this.state.showDocumentTypeList) {
      this.setState({
        showDocumentTypeList: false,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "documentNumberContainer")) && this.state.showDocumentNumberSeriesList) {
      this.setState({
        showDocumentNumberSeriesList: false,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "documentLanguageContainer")) && this.state.showDocumentLanguageList) {
      this.setState({
        showDocumentLanguageList: false,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "issueDatePickerContainer")) && this.state.showIssueDateCalendar) {
      this.setState({
        showIssueDateCalendar: false,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "saleDatePickerContainer")) && this.state.showSaleDateCalendar) {
      this.setState({
        showSaleDateCalendar: false,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "correctedIssueDateDatePickerContainer")) && this.state.showCorrectedIssueDateCalendar) {
      this.setState({
        showCorrectedIssueDateCalendar: false,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "correctedSaleDateDatePickerContainer")) && this.state.showCorrectedSaleDateCalendar) {
      this.setState({
        showCorrectedSaleDateCalendar: false,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "dueDatePickerContainer")) && this.state.showDueDateCalendar) {
      this.setState({
        showDueDateCalendar: false,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "paymentMethodContainer")) && this.state.showPaymentMethodList) {
      this.setState({
        showPaymentMethodList: false,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "accountNumberContainer")) && this.state.showAccountsList) {
      this.setState({
        showAccountsList: false,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "paymentStatusContainer")) && this.state.showPaymentStatusList) {
      this.setState({
        showPaymentStatusList: false,
      });
    } else if ((e.keyCode === 27 || !this.hasAncestor(e.target, "currencyContainer")) && this.state.showCurrencyList) {
      this.setState({
        showCurrencyList: false,
      });
    } else if (this.state.unitListToShow > -1) {
      this.setState({
        unitListToShow: -1,
      });
    } else if (this.state.posListToShow > -1) {
      this.setState({
        posListToShow: -1,
      });
    } else if (this.state.vatListToShow > -1) {
      this.setState({
        vatListToShow: -1,
      });
    } else if (
      (e.keyCode === 27 || !this.hasAncestor(e.target, "popup-card")) &&
      this.state.showVatIDSearchPopup
    ) {
      this.hideSearchVatIdPopup();
    } else if (
      e.keyCode === 27 ||
      (!this.hasAncestor(e.target, "item counterparty-item") &&
        this.state.showCounterpartiesList)
    ) {
      this.setState({ showCounterpartiesList: false });
    }
  };

  escFunction = (e) => {
    if (e.keyCode === 27) this.closeAllSuggestions(e);
  };

  setData = () => {
    var company = this.props.currentCompany.company;
    var address = "";
    var zipCode = "";
    var city = "";
    var logoURL = "";
    var logo = "";
    const {
      account_number,
      currency,
      payment_method,
      payment_status,
      issue_date,
      sale_date,
      due_date,
      foreign_receiver,
      counterparty_id,
      docItems,
      split_payment,
      reverse_charge,
      issue_place,
      metoda_kasowa,
      total_gross_value,
      total_net_value,
      total_vat_value,
      id,
      editingExistingInvoice,
      editedInvoiceId,
      doc_number,
      reason_for_correction,
      counterparty,
      document_language,
      numbering_series_id,
    } = this.props.selectedInvoiceData;
    const buyer = company.counterparties.find(
      (buyer) => buyer.id === counterparty_id
    );
    const sAddress = splitAddress(buyer ? buyer.address : "");
    if (company.address) {
      if (/, [0-9]{2}-[0-9]{3}/.test(company.address)) {
        address = company.address.split(",")[0];
        zipCode = company.address.match(/[0-9]{2}-[0-9]{3}/)
          ? company.address.match(/[0-9]{2}-[0-9]{3}/)[0]
          : "";
        city = company.address.replace(address + ", " + zipCode, "").trim();
      }
    }

    var accountNumber = "",
      bankName = "",
      bankBIC = "";

    if (company.banks && company.banks.length > 0) {
      accountNumber = company.banks[0].account_number;
      bankName = company.banks[0].bank_name;
      bankBIC = company.banks[0].bic;
    }

    if (company.logo && company.logo_url) {
      logoURL = company.logo_url;
      logo = company.logo;
    }

    const invoicePositions = docItems.map((item) => {
      return {
        name: item.name,
        amount: parseFloat(item.amount).toFixed(0).toString(),
        unit: item.unit,
        netPrice: parseFloat(item.net_price).toFixed(2).toString(),
        vat: isNaN(parseFloat(item.vat)) ? item.vat : parseFloat(item.vat).toFixed(0).toString(),
        netValue: parseFloat(item.net_value).toFixed(2).toString(),
        grossValue: parseFloat(item.gross_value).toFixed(2).toString(),
        pkwiu: item.pkwiu,
        gtu: item.gtu,
      };
    });

    this.setState({
      numberingSeriesId: numbering_series_id || -1,
      documentLanguage: document_language,
      sellerId: company.id,
      sellerName: company.name,
      sellerVatId: company.vat_id,
      sellerAddress: address,
      sellerPostCode: zipCode,
      sellerCity: city,
      bankName: bankName,
      bankBIC: bankBIC,
      accountsList: company.banks,
      logoURL: logoURL,
      logo: logo,
      counterpartiesList: company.counterparties,
      documentType: DOCUMENT_TYPE.corrBuyer,
      documentNumber: "",
      issuePlace: issue_place,
      buyerName: buyer ? buyer.name : "",
      issueDate: moment().format("YYYY-MM-DD"),
      saleDate: moment().format("YYYY-MM-DD"),
      buyerVatId: buyer ? buyer.vat_id : "",
      buyerAddress: sAddress.address,
      buyerPostCode: sAddress.zipCode,
      buyerCity: sAddress.city,
      buyerCountryCode: buyer ? buyer.country : "",
      buyerEmail: buyer ? buyer.email : "",
      invoicePositions: invoicePositions,
      summary: {
        netSum: total_net_value,
        vatSum: total_vat_value,
        grossSum: total_gross_value,
      },
      correctedInvoiceNumber: doc_number,
      correctedIssueDate: moment(issue_date).format('YYYY-MM-DD'),
      correctedSaleDate: moment(sale_date).format('YYYY-MM-DD'),
      paymentMethod: payment_method,
      accountNumber: account_number,
      dueDate: due_date ? moment(due_date).format("YYYY-MM-DD") : null,
      paymentStatus: payment_status,
      currency,
      buyerNotFromPoland: foreign_receiver,
      splitPayment: split_payment,
      reverseCharge: reverse_charge,
      metodaKasowa: metoda_kasowa,
      editedInvoiceId: editedInvoiceId,
      editingExistingInvoice: editingExistingInvoice,
      correctedInvoiceId: id,
      correctedInvoiceData: this.props.selectedInvoiceData,
      reasonForCorrection: reason_for_correction
        ? reason_for_correction
        : null,
      buyerCountryCode: counterparty?.country,
      sendToKsef: false,
    });

    editingExistingInvoice
      ? this.setState({
        documentNumber: doc_number,
      })
      : this.setInvoiceNumber(
        moment().format("YYYY-MM"),
        company.id,
        DOCUMENT_TYPE.corrBuyer
      );
  };

  validateInvoiceData = () => {
    var valid = true;
    const {
      documentNumber,
      issueDate,
      saleDate,
      sellerName,
      sellerVatId,
      sellerAddress,
      sellerPostCode,
      sellerCity,
      buyerName,
      buyerVatId,
      buyerAddress,
      buyerPostCode,
      buyerCity,
      buyerCountryCode,
      invoicePositions,
      paymentMethod,
      accountNumber,
      dueDate,
      paymentStatus,
      buyerNotFromPoland,
      reasonForCorrection,
    } = this.state;

    if (!documentNumber) {
      valid = false;
      this.props.alertWarn(this.context.t("Document number is required"));
    }

    if (!issueDate) {
      valid = false;
      this.props.alertWarn(this.context.t("Issue date is required"));
    }

    // if(!issuePlace) {
    //     valid = false
    //     this.props.alertWarn(this.context.t('Issue place is required.'))
    // }

    if (!saleDate) {
      valid = false;
      this.props.alertWarn(this.context.t("Sale date is required"));
    }

    if (!sellerName) {
      valid = false;
      this.props.alertWarn(this.context.t("Seller name is required."));
    }

    if (!sellerVatId) {
      valid = false;
      this.props.alertWarn(this.context.t("Seller VatID is required"));
    }

    if (!sellerAddress) {
      valid = false;
      this.props.alertWarn(this.context.t("Seller address is required."));
    } else if (!sellerCity) {
      valid = false;
      this.props.alertWarn(this.context.t("Seller address is required."));
    }

    if (!sellerPostCode) {
      valid = false;
      this.props.alertWarn(this.context.t("Seller ZIP code is required."));
    } else if (!/[0-9]{2}-[0-9]{3}/.test(sellerPostCode)) {
      valid = false;
      this.props.alertWarn(this.context.t("Seller ZIP code is invalid."));
    }

    if (!buyerName) {
      valid = false;
      this.props.alertWarn(this.context.t("Buyer name is required."));
    }

    if (reasonForCorrection === "") {
      valid = false;
      this.props.alertWarn(
        this.context.t("Reason for correction is required.")
      );
    }

    if (!buyerNotFromPoland) {
      if (!buyerPostCode) {
        valid = false;
        this.props.alertWarn(this.context.t("Buyer ZIP code is required."));
      } else if (!/[0-9]{2}-[0-9]{3}/.test(buyerPostCode)) {
        valid = false;
        this.props.alertWarn(this.context.t("Buyer ZIP code is invalid."));
      }
    } else {
      if (!buyerCountryCode) {
        valid = false;
        this.props.alertWarn(this.context.t("Buyer country code is required."));
      } else if (!/[A-Z]{2}/.test(buyerCountryCode)) {
        valid = false;
        this.props.alertWarn(
          this.context.t(
            "Buyer country code is invalid. The country code should consist of two capital letters. Find out more by hovering over the question mark icon."
          )
        );
      }
    }

    invoicePositions.forEach((p) => {
      if (
        !p.name ||
        !p.amount ||
        !p.netPrice ||
        !p.vat ||
        !p.netValue ||
        !p.grossValue
      ) {
        valid = false;
        this.props.alertWarn(this.context.t("Invoice item is incomplete."));
      }
    });

    // paymentMethod, accountNumber, dueDate, paymentStatus

    if (paymentMethod === "transfer" && !accountNumber) {
      valid = false;
      this.props.alertWarn(this.context.t("Account number is required."));
    }

    if (paymentStatus === "unpaid" && !dueDate) {
      valid = false;
      this.props.alertWarn(this.context.t("Due date is required"));
    }
    return valid;
  };

  generateInvoice = () => {
    if (this.validateInvoiceData()) {
      const {currentProject} = this.props
      const {
        documentType,
        documentNumber,
        numberingSeriesId,
        documentLanguage,
        issueDate,
        issuePlace,
        saleDate,
        sellerName,
        sellerVatId,
        sellerAddress,
        sellerPostCode,
        sellerCity,
        sellerId,
        buyerName,
        buyerVatId,
        buyerAddress,
        buyerPostCode,
        buyerCity,
        buyerCountryCode,
        buyerEmail,
        invoicePositions,
        summary,
        correctedInvoiceNumber,
        correctedIssueDate,
        correctedSaleDate,
        paymentMethod,
        accountNumber,
        bankName,
        bankBIC,
        dueDate,
        paymentStatus,
        currency,
        logo,
        buyerNotFromPoland,
        splitPayment,
        reverseCharge,
        metodaKasowa,
        editedInvoiceId,
        editingExistingInvoice,
        reasonForCorrection,
        sendToKsef,
      } = this.state;
      var invoiceData = {
        docType: documentType.toLocaleLowerCase().replace(" ", "-"),
        documentNumber: documentNumber,
        numberingSeriesId: numberingSeriesId,
        documentLanguage: documentLanguage || 'pl',
        issueDate: issueDate,
        issuePlace: issuePlace,
        saleDate: saleDate,
        sellerName: sellerName,
        sellerVatId: sellerVatId,
        sellerAddress: sellerAddress,
        sellerPostCode: sellerPostCode,
        sellerCity: sellerCity,
        buyerName: buyerName,
        buyerVatId: buyerVatId,
        buyerAddress: buyerAddress,
        buyerPostCode: buyerPostCode,
        buyerCity: buyerCity,
        buyerCountryCode: buyerCountryCode,
        buyerEmail: buyerEmail,
        invoicePositions: invoicePositions,
        summary: summary,
        correctedInvoiceNumber: correctedInvoiceNumber,
        correctedIssueDate: correctedIssueDate,
        correctedSaleDate: correctedSaleDate,
        paymentMethod: paymentMethod,
        accountNumber: accountNumber,
        bankName: bankName,
        bankBIC: bankBIC,
        dueDate: dueDate,
        paymentStatus: paymentStatus,
        currency: currency,
        logo: logo,
        buyerNotFromPoland: buyerNotFromPoland,
        splitPayment: splitPayment,
        reverseCharge: reverseCharge,
        metodaKasowa: metodaKasowa,
        reasonForCorrection,
        correctedInvoiceData: this.props.selectedInvoiceData,
        sendToKsef: sendToKsef || false,
      };
      if (editingExistingInvoice) {
        this.props.editInvoice(invoiceData, this.state.sellerId, currentProject.id, editedInvoiceId, (success, fileBody, fileName, id) => {
          if (success) {
            this.setState({
              fileBody,
              fileName,
              showInvoicePreviewPopup: true,
              editedInvoiceId: id,
            });
          }
        });
      } else {
        this.props.generateInvoice(invoiceData, this.state.sellerId, currentProject.id, (success, fileBody, fileName, id, generatedInvoice) => {
          if (success) {
            this.setState({
              fileBody,
              fileName,
              editedInvoiceId: id,
            });
            this.props.openInvoicePreview(fileBody, fileName, generatedInvoice)
            this.props.toggleShowCorrectionBuyerForm();
          }
        });
        this.setState({ editingExistingInvoice: false });
      }
    }
  };

  showDatePicker = (e, name) => {
    if (name === "issueDate") {
      this.setState({
        showIssueDateCalendar: true,
      });
    } else if (name === "dueDate") {
      this.setState({
        showDueDateCalendar: true,
      });
    } else if (name === "saleDate") {
      this.setState({
        showSaleDateCalendar: true,
      });
    }
  };

  showDatePicker = (e, name) => {
    if (name === "issueDate") {
      this.setState({
        showIssueDateCalendar: true,
      });
    } else if (name === "dueDate") {
      this.setState({
        showDueDateCalendar: true,
      });
    } else if (name === "saleDate") {
      this.setState({
        showSaleDateCalendar: true,
      });
    } else if (name === "correctedIssueDate") {
      this.setState({
        showCorrectedIssueDateCalendar: true,
      });
    } else if (name === "correctedSaleDate") {
      this.setState({
        showCorrectedSaleDateCalendar: true,
      });
    }
  };

  selectIssueDate = (date) => {
    this.setState({
      issueDate: moment(date).format("YYYY-MM-DD"),
    });

    if (moment(date).format("YYYY-MM") !== moment().format("YYYY-MM")) {
      this.setInvoiceNumber(
        moment(date).format("YYYY-MM"),
        this.state.sellerId,
        DOCUMENT_TYPE.corrBuyer
      );
    }
  };

  getFullLanguageName = langCode => {
    if (langCode === 'pl') return 'Polish'
    else return 'English'
  }

  setDocNumberSeries = series => {
    this.setState({
      documentNumber: series.number,
      numberingSeriesId: series.seriesId,
      showDocumentNumberSeriesList: false,
    })
  }

  setDocLanguage = langCode => {
    this.setState({
      documentLanguage: langCode,
      showDocumentLanguageList: false
    })
  }

  hideSearchVatIdPopup = () => {
    window.removeEventListener("keydown", this.searchOnEnter, false);
    this.setState({
      showVatIDSearchPopup: false,
      searchBuyerVatId: "",
    });
  };

  searchOnEnter = (e) => {
    if (e.keyCode === 13) this.searchVatAPI();
  };

  foundAtLeastOneSimilarCounterparty = (text) => {
    if (text.length < 2) return false;
    const { counterpartiesList } = this.state;
    var counterpartiesCount = counterpartiesList.length;

    if (!text) {
      return false;
    }

    for (var i = 0; i < counterpartiesCount; i++) {
      if (
        text.length === 1 &&
        counterpartiesList[i].name &&
        counterpartiesList[i].name.toLowerCase().startsWith(text.toLowerCase())
      ) {
        return true;
      } else if (
        text.length > 1 &&
        counterpartiesList[i].name &&
        counterpartiesList[i].name.toLowerCase().includes(text.toLowerCase())
      ) {
        return true;
      }
    }

    return false;
  };

  checkIfCounterpartyNameIsSimilar = (text, name) => {
    if (!text) {
      return false;
    }

    if (
      text.length === 1 &&
      name &&
      name.toLowerCase().startsWith(text.toLowerCase())
    ) {
      return true;
    } else if (
      text.length > 1 &&
      name &&
      name.toLowerCase().includes(text.toLowerCase())
    ) {
      return true;
    }

    return false;
  };

  setEditingMode = () => {
    this.setState({
      editingExistingInvoice: true,
    });
  };

  concatenationMails = (arrayMail) => {
    let concatenatedMails = "";
    arrayMail.map((email, index) => {
      if (arrayMail.length === 1) concatenatedMails = `${email}`;
      if (arrayMail.length > 1) {
        if (index === 0) {
          concatenatedMails = `${email} ,`;
        } else if (index < arrayMail.length - 1) {
          concatenatedMails += `${email} ,`;
        }
        if (index === arrayMail.length - 1) concatenatedMails += `${email}`;
      }
    });
    return concatenatedMails;
  };

  setCountryCode = (code) => {
    this.setState({
      buyerCountryCode: code,
      showCountriesList: false
    })
  }

  getPaymentMethodString = (paymentMethod) => {
    var paymentMethodString = "Transfer";
    if (paymentMethod === "card") paymentMethodString = "Card";
    if (paymentMethod === "cash") paymentMethodString = "Cash";
    if (paymentMethod === "other") paymentMethodString = "Other";
    return this.context.t(paymentMethodString);
  };

  getPaymentStatusString = (paymentStatus) => {
    var paymentStatusString = "Unpaid ";
    if (paymentStatus === "paid") paymentStatusString = "Paid ";
    return this.context.t(paymentStatusString);
  };

  render() {
    const {
      documentNumber,
      allSeriesDocumentNumbers,
      showDocumentNumberSeriesList,
      documentLanguage,
      showDocumentLanguageList,
      issueDate,
      searchBuyerVatId,
      waitingForVatApiReponse,
      buyerName,
      buyerVatId,
      buyerAddress,
      buyerPostCode,
      buyerCity,
      buyerCountryCode,
      showVatIDSearchPopup,
      showIssueDateCalendar,
      buyerNotFromPoland,
      buyerEmail,
      showCounterpartiesList,
      counterpartiesList,
      showInvoicePreviewPopup,
      fileBody,
      fileName,
      editedInvoiceId,
      paymentStatus,
      paymentMethod,
      showPaymentMethodList,
      accountNumber,
      accountsList,
      showAccountsList,
      showPaymentStatusList,
      showDueDateCalendar,
      dueDate,
      reasonForCorrection,
      showReasonForCorrectionList,
      showCountriesList,
      sendToKsef,

      correctedInvoiceNumber,
      correctedIssueDate,
      correctedSaleDate,
      showCorrectedIssueDateCalendar,
      showCorrectedSaleDateCalendar
    } = this.state;
    const {
      user,
      tabIsActive,
      hideInvoiceCreatorOverlay,
      currentCompany,
      toggleShowCorrectionBuyerForm,
    } = this.props;
    const {
      doc_number,
      editingExistingInvoice
    } = this.props.selectedInvoiceData;

    if (tabIsActive || !user.new_easydocs_version) {
      return (
        <div className={`background-container-buyer ${user.new_easydocs_version ? 'new-easydocs-version' : ''}`}>
          <div className="generate-invoide-container" onClick={(e) => this.closeAllSuggestions(e)} >
            {
              showVatIDSearchPopup ? (
                <div className="">
                  <div id="popup-card" className="popup-card">
                    <div className="header">
                      {this.context.t("Enter the contractor's NIP (tax identification number) to download his data from the Central Statistical Office database")}
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="searchBuyerVatId"
                        id="searchBuyerVatId"
                        value={searchBuyerVatId}
                        onChange={this.onInputChange}
                      />
                    </div>
                    <div className="search-btn" onClick={() => this.searchVatAPI()}>
                      {waitingForVatApiReponse ? (
                        <div className="loader">
                          <Spinner size={20} speed={0.8} color={"#ffffff"} />
                        </div>
                      ) : (
                        this.context.t("Search")
                      )}
                    </div>
                  </div>
                </div>
              ) : null
            }
            <SimpleBar style={{
              height: "auto",
              maxHeight: "90vh",
              width: "100%"
            }}>
              <div className="content">
                <div className="header-container">
                  <h2>{this.context.t("Giving correcting invoice")}</h2>
                  <div className="hide-button" onClick={() => toggleShowCorrectionBuyerForm()} ></div>
                </div>

                {
                  <div className="header-row">
                    {editingExistingInvoice ? null : (
                      <div className="form-group">
                        <input
                          className={``}
                          name="documentNumber"
                          id={`documentNumber`}
                          value={doc_number}
                        />
                        <label htmlFor="documentNumber"> {this.context.t("Correcting document number")} </label>
                      </div>
                    )}
                    <div className="form-group" onClick={() => this.setState({ showDocumentNumberSeriesList: !showDocumentNumberSeriesList })}>
                      <input
                        className={``}
                        name="documentNumber"
                        id={`documentNumber`}
                        value={documentNumber}
                        onChange={this.onInputChange}
                      />
                      <label htmlFor="documentNumber" onClick={() => this.setState({ showDocumentNumberSeriesList: true })}>{this.context.t("Document number")}</label>

                      {
                        allSeriesDocumentNumbers.length > 1 ? (
                          <>
                            <span className={`expend-icon ${showDocumentNumberSeriesList ? "expended" : ""}`}></span>
                            {
                              showDocumentNumberSeriesList ? (
                                <div className="sugestions-list down">
                                  <ul>
                                    {
                                      allSeriesDocumentNumbers.map(serise => {
                                        return (
                                          <li onClick={() => this.setDocNumberSeries(serise)}>{serise.number}</li>
                                        )
                                      })
                                    }
                                  </ul>
                                </div>
                              ) : null
                            }
                          </>
                        ) : null
                      }
                    </div>
                    <div id="documentLanguageContainer" className="form-group" onClick={() => this.setState({ showDocumentLanguageList: !showDocumentLanguageList })}>
                      <input
                        className={``}
                        name="documentLanguage"
                        id={`documentLanguage`}
                        value={this.context.t(this.getFullLanguageName(documentLanguage))}
                        readOnly={true}
                      />

                      <label htmlFor="documentLanguageContainer" onClick={() => this.setState({ showDocumentLanguageList: true })}>{this.context.t("Document language")}</label>
                      <span className={`expend-icon ${showDocumentLanguageList ? "expended" : ""}`}></span>

                      {
                        showDocumentLanguageList ? (
                          <div className="sugestions-list down">
                            <ul>
                              <li onClick={() => this.setDocLanguage("pl")}>
                                {this.context.t("Polish")}
                              </li>
                              <li onClick={() => this.setDocLanguage("en")}>
                                {this.context.t("English")}
                              </li>
                            </ul>
                          </div>
                        ) : null
                      }
                    </div>
                    <div id="issueDatePickerContainer" className="form-group">
                      <input
                        className={``}
                        name="issueDate"
                        id={`issueDate`}
                        value={issueDate}
                        onClick={(e) => this.showDatePicker(e, "issueDate")}
                      />
                      <label htmlFor="issueDate" onClick={(e) => this.showDatePicker(e, "issueDate")}>{this.context.t("Issue date")}</label>
                      <span className="calendar-icon" onClick={(e) => this.showDatePicker(e, "issueDate")}></span>
                      {showIssueDateCalendar ? (
                        <div className={`date-picker ${showIssueDateCalendar ? "show" : ""}`} onClick={(e) => this.clickOnDatePicker(e, "issueDate")}>
                          <Calendar
                            selectDay={this.selectIssueDate}
                            selectedDay={moment(issueDate)}
                            unselectDay={() => { }}
                            projectId={"none"}
                            startDate={moment(issueDate) || moment()}
                            arrowsOnLeft={false}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                }

                <div className="seller-and-buyer-row">
                  <div className="buyer">
                    <div className={`get-data ${buyerNotFromPoland ? "hide" : ""}`} onClick={() => this.showSearchVatIdPopup()}>
                      {this.context.t("Load data by VatID")}
                    </div>
                    <div className="form-group">
                      <input
                        className={`no-label`}
                        name="buyerName"
                        id={`buyerName`}
                        value={buyerName}
                        onChange={this.onInputChange}
                        onClick={() => this.setState({ showCounterpartiesList: true })}
                      />
                      <label htmlFor="buyerName">{this.context.t("Name")}</label>
                      <label className="big-label" htmlFor="buyerName">
                        {this.context.t("Buyer")}
                      </label>
                      <span className="search-icon" />

                      {
                        counterpartiesList.length > 0 &&
                          this.foundAtLeastOneSimilarCounterparty(buyerName) &&
                          showCounterpartiesList ? (
                          <div className="counterparties-list">
                            <SimpleBar
                              style={{
                                height: "auto",
                                maxHeight: "300px",
                                width: "100%",
                              }}
                            >
                              <ul>
                                {counterpartiesList.map((c) => {
                                  if (
                                    this.checkIfCounterpartyNameIsSimilar(
                                      buyerName,
                                      c.name
                                    )
                                  ) {
                                    return (
                                      <li
                                        tabIndex={"0"}
                                        className={`item counterparty-item`}
                                        onClick={() => {
                                          var sAddress;
                                          if (c.address !== null)
                                            sAddress = splitAddress(c.address);
                                          var emails;
                                          if (c.email)
                                            emails = this.concatenationMails(c.email);
                                          this.setState({
                                            showCounterpartiesList: false,
                                            buyerName: c.name ? c.name : "",
                                            buyerVatId: c.vat_id ? c.vat_id : "",
                                            buyerAddress: sAddress
                                              ? sAddress.address
                                              : "",
                                            buyerPostCode: sAddress
                                              ? sAddress.zipCode
                                              : "",
                                            buyerCity: sAddress ? sAddress.city : "",
                                            buyerEmail: emails ? emails : "",
                                            buyerCountryCode: c?.country,
                                            buyerNotFromPoland: c && c.country && c.country !== 'PL'
                                          });
                                        }}
                                      >
                                        {c.name}
                                      </li>
                                    );
                                  } else {
                                    return null
                                  }
                                })}
                              </ul>
                            </SimpleBar>
                          </div>
                        ) : showCounterpartiesList ? (
                          <div className="counterparties-list">
                            <SimpleBar
                              style={{
                                height: "auto",
                                maxHeight: "300px",
                                width: "100%",
                              }}
                            >
                              <ul>
                                {counterpartiesList.map((c) => {
                                  return (
                                    <li
                                      tabIndex={"0"}
                                      className={`item counterparty-item`}
                                      onClick={() => {
                                        var sAddress;
                                        if (c.address !== null)
                                          sAddress = splitAddress(c.address);
                                        var emails;
                                        if (c.email)
                                          emails = this.concatenationMails(c.email);
                                        this.setState({
                                          showCounterpartiesList: false,
                                          buyerName: c.name ? c.name : "",
                                          buyerVatId: c.vat_id ? c.vat_id : "",
                                          buyerAddress: sAddress
                                            ? sAddress.address
                                            : "",
                                          buyerPostCode: sAddress
                                            ? sAddress.zipCode
                                            : "",
                                          buyerCity: sAddress ? sAddress.city : "",
                                          buyerEmail: emails ? emails : "",
                                        });
                                      }}
                                    >
                                      {c.name}
                                    </li>
                                  );
                                })}
                              </ul>
                            </SimpleBar>
                          </div>
                        ) : null
                      }
                    </div>
                    {
                      buyerNotFromPoland ? (
                        <div className="form-group">
                          <input
                            className={``}
                            name="buyerVatId"
                            id={`buyerVatId`}
                            value={buyerVatId}
                            onChange={this.onInputChange}
                          />
                          <label htmlFor="buyerVatId">
                            {this.context.t("Company ID")}
                          </label>
                        </div>
                      ) : (
                        <div className="form-group">
                          <input
                            className={``}
                            name="buyerVatId"
                            id={`buyerVatId`}
                            value={buyerVatId}
                            onChange={this.onInputChange}
                          />
                          <label htmlFor="buyerVatId">
                            {this.context.t("VatID")}
                          </label>
                        </div>
                      )
                    }
                    <div className={`form-group ${buyerNotFromPoland ? "fill-rest" : ""}`}>
                      <input
                        className={``}
                        name="buyerAddress"
                        id={`buyerAddress`}
                        value={buyerAddress}
                        onChange={this.onInputChange}
                      />
                      <label htmlFor="buyerAddress">{this.context.t("Address")}</label>
                    </div>
                    {
                      buyerNotFromPoland ? (
                        <div className="form-group mid ml">
                          <input
                            className={``}
                            name="buyerCountryCode"
                            id={`buyerCountryCode`}
                            value={buyerCountryCode}
                            onChange={this.onInputChange}
                            onClick={() => this.setState({ showCountriesList: true })}
                          />
                          <label htmlFor="buyerCountryCode">{this.context.t("Country code (ISO 3166-1 alpha-2)")}</label>
                          {
                            showCountriesList ?
                              <CountriesList
                                headerText={this.context.t('Country:')}
                                selectedOption={buyerCountryCode}
                                select={(code) => this.setCountryCode(code)}
                              /> : null
                          }
                          <span className={`expend-icon ${showCountriesList ? "expended" : ""}`} onClick={() => this.setState({ showCountriesList: true })} ></span>
                        </div>
                      ) : null
                    }
                    {
                      !buyerNotFromPoland ? (
                        <>
                          <div className="form-group small">
                            <input
                              className={``}
                              name="buyerPostCode"
                              id={`buyerPostCode`}
                              value={buyerPostCode}
                              onChange={this.onInputChange}
                            />
                            <label htmlFor="buyerPostCode">
                              {this.context.t("ZIP code")}
                            </label>
                          </div>
                          <div className="form-group fill-rest">
                            <input
                              className={``}
                              name="buyerCity"
                              id={`buyerCity`}
                              value={buyerCity}
                              onChange={this.onInputChange}
                            />
                            <label htmlFor="buyerCity">
                              {this.context.t("City ")}
                            </label>
                          </div>
                        </>
                      ) : null
                    }

                    <div className="checkbox-group" onClick={() => this.setState({ buyerNotFromPoland: !buyerNotFromPoland })} >
                      <div className={`checkbox ${buyerNotFromPoland ? "checked" : ""}`} ></div>
                      <div className="label">
                        {this.context.t("The buyer is not from Poland")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dates-correction-row" >
                  <div className="form-group">
                    <input
                      className={``}
                      name="correctedInvoiceNumber"
                      id={`correctedInvoiceNumber`}
                      value={correctedInvoiceNumber}
                      onChange={this.onInputChange}
                    />
                    <label htmlFor="correctedInvoiceNumber"> {this.context.t("Correct invoice number")} </label>
                  </div>
                  <div id="correctedIssueDateDatePickerContainer" className="form-group">
                    <input
                      className={``}
                      name="correctedIssueDate"
                      id={`correctedIssueDate`}
                      value={correctedIssueDate}
                      readOnly={true}
                      onClick={(e) => this.showDatePicker(e, "correctedIssueDate")}
                    />
                    <label htmlFor="dueDate" onClick={(e) => this.showDatePicker(e, "correctedIssueDate")}>
                      {this.context.t("Correct issue date")}
                    </label>
                    <span className="calendar-icon" onClick={(e) => this.showDatePicker(e, "correctedIssueDate")}></span>

                    {
                      showCorrectedIssueDateCalendar ? (
                        <div className={`date-picker issue-date ${showCorrectedIssueDateCalendar ? "show" : ""}`} onClick={(e) => this.clickOnDatePicker(e, "correctedIssueDate")} >
                          <Calendar
                            selectDay={this.selectCorrectedIssueDate}
                            selectedDay={moment(correctedIssueDate)}
                            unselectDay={() => { }}
                            projectId={"none"}
                            startDate={moment(correctedIssueDate) || moment()}
                            arrowsOnLeft={false}
                          />
                        </div>
                      ) : null
                    }
                  </div>
                  <div id="correctedSaleDateDatePickerContainer" className="form-group">
                    <input
                      className={``}
                      name="correctedSaleDate"
                      id={`correctedSaleDate`}
                      value={correctedSaleDate}
                      readOnly={true}
                      onClick={(e) => this.showDatePicker(e, "correctedSaleDate")}
                    />
                    <label htmlFor="correctedSaleDate" onClick={(e) => this.showDatePicker(e, "correctedSaleDate")}>
                      {this.context.t("Correct sale date")}
                    </label>
                    <span className="calendar-icon" onClick={(e) => this.showDatePicker(e, "correctedSaleDate")}></span>

                    {
                      showCorrectedSaleDateCalendar ? (
                        <div className={`date-picker due-date ${showCorrectedSaleDateCalendar ? "show" : ""}`} onClick={(e) => this.clickOnDatePicker(e, "correctedSaleDate")} >
                          <Calendar
                            selectDay={this.selectCorrectedSaleDate}
                            selectedDay={moment(correctedSaleDate)}
                            unselectDay={() => { }}
                            projectId={"none"}
                            startDate={moment(correctedSaleDate) || moment()}
                            arrowsOnLeft={false}
                          />
                        </div>
                      ) : null
                    }
                  </div>
                </div>
                <div
                  className={`payment-options ${paymentMethod === "transfer" && paymentStatus === "unpaid" ? "" : "not-full"}`} >
                  <div id="paymentMethodContainer" className="form-group">
                    <input
                      className={``}
                      name="paymentMethod"
                      id={`paymentMethod`}
                      value={this.getPaymentMethodString(paymentMethod)}
                      readOnly={true}
                      onClick={() =>
                        this.setState({
                          showPaymentMethodList: true,
                        })
                      }
                    />
                    <label htmlFor="paymentMethod" onClick={() => this.setState({ showPaymentMethodList: true })}>
                      {this.context.t("Payment method")}
                    </label>
                    <span className={`expend-icon ${showPaymentMethodList ? "expended" : ""}`} onClick={() => this.setState({ showPaymentMethodList: !showPaymentMethodList })} ></span>
                    {
                      showPaymentMethodList ? (
                        <div className="sugestions-list">
                          <ul>

                            {
                              paymentMethodsList.map(method => {
                                return (
                                  <li onClick={e => this.setState({ showPaymentMethodList: false, paymentMethod: method.value })}>
                                    {this.context.t(method.title)}
                                  </li>
                                )
                              })
                            }
                          </ul>
                        </div>
                      ) : null
                    }
                  </div>
                  <div id="paymentStatusContainer" className="form-group">
                    <input
                      className={``}
                      name="paymentStatus"
                      id={`paymentStatus`}
                      value={this.getPaymentStatusString(paymentStatus)}
                      readOnly={true}
                      onClick={() =>
                        this.setState({
                          showPaymentStatusList: true,
                        })
                      }
                    />
                    <label htmlFor="paymentStatus" onClick={() => this.setState({ showPaymentStatusList: true })}>
                      {this.context.t("Status")}
                    </label>
                    <span className={`expend-icon ${showPaymentStatusList ? "expended" : ""}`} onClick={() => this.setState({ showPaymentStatusList: !showPaymentStatusList })}></span>

                    {
                      showPaymentStatusList ? (
                        <div className="sugestions-list">
                          <ul>
                            <li onClick={() => this.setState({ showPaymentStatusList: false, paymentStatus: "unpaid" })}>
                              {this.context.t("Unpaid ")}
                            </li>
                            <li onClick={() => this.setState({ showPaymentStatusList: false, paymentStatus: "paid" })}>
                              {this.context.t("Paid ")}
                            </li>
                          </ul>
                        </div>
                      ) : null
                    }
                  </div>
                  {
                    paymentStatus === "unpaid" ? (
                      <div id="dueDatePickerContainer" className="form-group">
                        <input
                          className={``}
                          name="dueDate"
                          id={`dueDate`}
                          value={dueDate}
                          readOnly={true}
                          onClick={(e) => this.showDatePicker(e, "dueDate")}
                        />
                        <label htmlFor="dueDate" onClick={(e) => this.showDatePicker(e, "dueDate")}>
                          {this.context.t("Due date")}
                        </label>
                        <span className="calendar-icon" onClick={(e) => this.showDatePicker(e, "dueDate")}></span>

                        {
                          showDueDateCalendar ? (
                            <div className={`date-picker due-date ${showDueDateCalendar ? "show" : ""}`} onClick={(e) => this.clickOnDatePicker(e, "dueDate")} >
                              <Calendar
                                selectDay={this.selectDueDate}
                                selectedDay={moment(dueDate)}
                                unselectDay={() => { }}
                                projectId={"none"}
                                startDate={moment(dueDate) || moment()}
                                arrowsOnLeft={false}
                              />
                            </div>
                          ) : null
                        }
                      </div>
                    ) : null
                  }
                </div>
                <div className="account-number">
                  {
                    paymentMethod === "transfer" || paymentMethod === "Transfer" ? (
                      <div id="accountNumberContainer" className="form-group">
                        <input
                          className={``}
                          name="accountNumber"
                          id={`accountNumber`}
                          value={accountNumber}
                          onChange={this.onInputChange}
                        />
                        <label htmlFor="accountNumber">
                          {this.context.t("Account number")}
                        </label>

                        {
                          accountsList.length > 0 ? (
                            <span
                              className={`expend-icon ${showAccountsList ? "expended" : ""
                                }`}
                              onClick={() =>
                                this.setState({
                                  showAccountsList: !showAccountsList,
                                })
                              }
                            ></span>
                          ) : null
                        }
                        {
                          showAccountsList ? (
                            <div className="sugestions-list">
                              <ul>
                                {accountsList.map((a) => {
                                  return (
                                    <li
                                      onClick={() =>
                                        this.setState({
                                          showAccountsList: false,
                                          accountNumber: a.account_number,
                                          bankName: a.bank_name,
                                          bankBIC: a.bic,
                                        })
                                      }
                                    >
                                      <span className="account-name">{`${a.name} (${a.currency})`}</span>
                                      {a.account_number}
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          ) : null
                        }
                      </div>
                    ) : null
                  }
                </div>
                <div className="remarksContainer">
                  <div id="reasonForCorrectionContainer" className="form-group">
                    <input
                      className={``}
                      name="reasonForCorrection"
                      id={`reasonForCorrection`}
                      value={this.context.t(
                        reasonForCorrection ? reasonForCorrection : ""
                      )}
                      onClick={() =>
                        this.setState({
                          showReasonForCorrectionList: !showReasonForCorrectionList,
                        })
                      }
                      onChange={(e) =>
                        this.setState({
                          reasonForCorrection: e.target.value,
                        })
                      }
                    />
                    <label
                      htmlFor="reasonForCorrection"
                      onClick={() =>
                        this.setState({
                          showReasonForCorrectionList: true,
                        })
                      }
                    >
                      {this.context.t("Reason for correction")}
                    </label>
                  </div>
                </div>
                <div className="footer">
                  {
                    currentCompany && currentCompany.company && currentCompany.company.ksef_integration_id ? (
                      <div className="send-to-ksef">
                        <div className="checkbox-group" >
                          <div className={`checkbox ${sendToKsef ? "checked" : ""}`} onClick={() => this.setState({ sendToKsef: !sendToKsef })}></div>
                          <div className="label" onClick={() => this.setState({ sendToKsef: !sendToKsef })}>{`${this.context.t("Send to KSeF")}`}</div>
                        </div>
                      </div>
                    ) : null
                  }
                  <div className="generate" onClick={() => this.generateInvoice()}>
                    {this.context.t("Generate correction")}
                  </div>
                </div>
              </div>
            </SimpleBar>
          </div>
        </div >
      )
    } else {
      return null
    }
  }
}

CorrectionBuyerForm.contextTypes = {
  t: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
  user: state.User.user,
  userProjects: state.User.userProjects,
  currentProject: state.User.currentProject,
  userCompanies: state.Company.companies,
  currentCompany: state.Company.currentCompany,
});

const mapDispatchToProps = {
  alertWarn: AlertActions.warning,
  getDocumentNumberForNewInvoice: CompanyActions.getDocumentNumberForNewInvoice,
  getSavedDocItems: CompanyActions.getSavedDocItems,
  getPrieviosGeneratedInvoices: CompanyActions.getPrieviosGeneratedInvoices,
  generateInvoice: CompanyActions.generateInvoice,
  editInvoice: CompanyActions.editInvoice,
};

export default connect(mapStateToProps, mapDispatchToProps)(CorrectionBuyerForm);
