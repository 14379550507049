import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TagsComponent from './TagsComponent';
import OcrPageWaproNettoAccounts from './wapro/OcrPageWaproNettoAccounts'

import { FileActions } from '../../actions/FileActions';
import { AlertActions } from '../../actions/AlertActions';

import gfTagsToAccountsMap from '../../helpers/gfTagsToAccountsMap'

export class CostSplit extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
    }

    onCommentChange = (e, index) => {
        var costSplitAllocation = [...this.props.costSplitAllocation]
        costSplitAllocation[index].comment = e.target.value
        this.props.setCostSplitAllocation(costSplitAllocation)
    }

    onAmountChange = (e, type, index) => {
        var costSplitAllocation = [...this.props.costSplitAllocation]
        var { value } = e.target
        value = value.replace(/[^0-9,.-]/gm, '').replace(',', '.')

        if (type === 'NET') {
            costSplitAllocation[index].netAmount = value
        } else if (type === 'VAT') {
            costSplitAllocation[index].vatAmount = value
        } else if (type === 'GROSS') {
            costSplitAllocation[index].grossAmount = value
        }

        if (['NET', 'VAT'].includes(type) && costSplitAllocation[index].netAmount && costSplitAllocation[index].vatAmount) {
            costSplitAllocation[index].grossAmount = (parseFloat(costSplitAllocation[index].netAmount) + parseFloat(costSplitAllocation[index].vatAmount)).toFixed(2)
        } else if (['GROSS', 'VAT'].includes(type) && costSplitAllocation[index].grossAmount && costSplitAllocation[index].vatAmount) {
            costSplitAllocation[index].netAmount = (parseFloat(costSplitAllocation[index].grossAmount) - parseFloat(costSplitAllocation[index].vatAmount)).toFixed(2)
        }

        this.props.setCostSplitAllocation(costSplitAllocation)
    }

    setTags = (value, index) => {
        var costSplitAllocation = [...this.props.costSplitAllocation]
        costSplitAllocation[index].tags = value

        const { companyId } = this.props
        var companyTagsMapping = gfTagsToAccountsMap[companyId]
        if (companyTagsMapping && companyTagsMapping[value]) {
            costSplitAllocation[index].waproNetAccount = companyTagsMapping[value]
        }
        this.props.setCostSplitAllocation(costSplitAllocation)

        if (costSplitAllocation[index].id > 0 && this.props.fileId) {
            this.props.editCostSplitInstance(this.props.fileId, this.props.ftvId, costSplitAllocation[index].id, costSplitAllocation[index])
        }
    }

    setWaproNetAccount = (value, index) => {
        var costSplitAllocation = [...this.props.costSplitAllocation]
        costSplitAllocation[index].waproNetAccount = value
        this.props.setCostSplitAllocation(costSplitAllocation)

        if (costSplitAllocation[index].id > 0 && this.props.fileId) {
            this.props.editCostSplitInstance(this.props.fileId, this.props.ftvId, costSplitAllocation[index].id, costSplitAllocation[index])
        }
    }

    onFieldBlur = index => {
        var costSplitAllocation = [...this.props.costSplitAllocation]
        if (costSplitAllocation[index].id > 0 && this.props.fileId) {
            this.props.editCostSplitInstance(this.props.fileId, this.props.ftvId, costSplitAllocation[index].id, costSplitAllocation[index])
        }
    }

    addCostSplit = () => {
        var costSplitAllocation = [...this.props.costSplitAllocation]
        costSplitAllocation.push({
            comment: '',
            tags: '',
            netAmount: '',
            vatAmount: '',
            grossAmount: '',
            id: -1
        })
        var newSplitIndex = costSplitAllocation.length - 1

        this.props.setCostSplitAllocation(costSplitAllocation)

        if (this.props.fileId) {
            this.props.addCostSplitInstance(this.props.fileId, this.props.ftvId, null, splitId => {
                if (splitId) {
                    costSplitAllocation[newSplitIndex].id = splitId
                    this.props.setCostSplitAllocation(costSplitAllocation)
                }
            })
        }
    }

    deleteCostSplit = (index) => {
        var costSplitAllocation = [...this.props.costSplitAllocation]
        const splitToDelete = costSplitAllocation[index]
        costSplitAllocation.splice(index, 1)

        this.props.setCostSplitAllocation(costSplitAllocation)

        if (splitToDelete.id > 0 && this.props.fileId) {
            this.props.deleteCostSplitInstance(this.props.fileId, this.props.ftvId, splitToDelete.id)
        }
    }

    showDevideEqually = () => {
        this.setState({
            showDivideEquallyWindow: true,
            divideEquallyInput: ''
        })
    }

    divideEqually = () => {
        const { divideEquallyInput } = this.state
        if (!divideEquallyInput) {
            this.props.alertWarn(this.context.t('You must specify how many parts the cost is to be divided into'))
        } else {
            var divideEquallyNumber = parseFloat(divideEquallyInput)
            if (divideEquallyNumber > 10) {
                this.props.alertWarn(this.context.t('You cannot divide the cost into more than {n} parts', { n: 10 }))
            } else if (divideEquallyNumber < 2) {
                this.props.alertWarn(this.context.t('You must divide it into at least 2 parts'))
            } else {
                const { totalNetAmount, totalGrossAmount } = this.props
                if (totalNetAmount && totalGrossAmount) {
                    var netPart = parseFloat(parseFloat(totalNetAmount) / divideEquallyNumber).toFixed(2)
                    var grossPart = parseFloat(parseFloat(totalGrossAmount) / divideEquallyNumber).toFixed(2)
                    var vatPart = parseFloat(parseFloat(grossPart) - parseFloat(netPart)).toFixed(2)
                    var netDiff = 0, grossDiff = 0
                    if (parseFloat(netPart) * divideEquallyNumber !== parseFloat(totalNetAmount)) {
                        netDiff = parseFloat(totalNetAmount) - parseFloat(netPart) * divideEquallyNumber
                    }
                    if (parseFloat(grossPart) * divideEquallyNumber !== parseFloat(totalGrossAmount)) {
                        grossDiff = parseFloat(totalGrossAmount) - parseFloat(grossPart) * divideEquallyNumber
                    }

                    var costSplitAllocation = [...this.props.costSplitAllocation]
                    for (let i = 0; i < divideEquallyNumber; i++) {
                        if (i === 0) {
                            var firstNetPart = parseFloat(parseFloat(netPart) + parseFloat(netDiff)).toFixed(2)
                            var firtsGrossPart = parseFloat(parseFloat(grossPart) + parseFloat(grossDiff)).toFixed(2)
                            costSplitAllocation[i].netAmount = firstNetPart
                            costSplitAllocation[i].vatAmount = (parseFloat(firtsGrossPart) - parseFloat(firstNetPart)).toFixed(2)
                            costSplitAllocation[i].grossAmount = firtsGrossPart
                            this.props.setCostSplitAllocation(costSplitAllocation)
                            this.props.editCostSplitInstance(this.props.fileId, this.props.ftvId, costSplitAllocation[i].id, costSplitAllocation[i])
                        } else if (costSplitAllocation[i]) {
                            costSplitAllocation[i].netAmount = netPart
                            costSplitAllocation[i].vatAmount = vatPart
                            costSplitAllocation[i].grossAmount = grossPart
                            this.props.setCostSplitAllocation(costSplitAllocation)
                            this.props.editCostSplitInstance(this.props.fileId, this.props.ftvId, costSplitAllocation[i].id, costSplitAllocation[i])
                        } else {
                            costSplitAllocation.push({
                                comment: '',
                                tags: '',
                                netAmount: netPart,
                                vatAmount: vatPart,
                                grossAmount: grossPart,
                                id: -1
                            })
                            this.props.addCostSplitInstance(this.props.fileId, this.props.ftvId, {
                                netAmount: netPart,
                                vatAmount: vatPart,
                                grossAmount: grossPart,
                            }, splitId => {
                                if (splitId) {
                                    costSplitAllocation[i].id = splitId
                                    this.props.setCostSplitAllocation(costSplitAllocation)
                                }
                            })
                        }
                    }

                    if (divideEquallyNumber < costSplitAllocation.length) {
                        var newCostsSplitAllocation = []
                        for (let j = 0; j < costSplitAllocation.length; j++) {
                            if (j >= divideEquallyNumber && costSplitAllocation[j]) {
                                this.props.deleteCostSplitInstance(this.props.fileId, this.props.ftvId, costSplitAllocation[j].id)
                            } else {
                                newCostsSplitAllocation.push(costSplitAllocation[j])
                            }
                        }
                        this.props.setCostSplitAllocation(newCostsSplitAllocation)
                    }

                    this.setState({
                        showDivideEquallyWindow: false,
                        divideEquallyInput: ''
                    })
                } else {
                    this.props.alertWarn(this.context.t('First, enter the net and gross amounts for the entire document'))
                }
            }
        }
    }

    onDivideInputChange = (e) => {
        var { value } = e.target
        value = value.replace(/[^0-9]/gm, '')

        this.setState({
            divideEquallyInput: value
        })
    }

    render() {
        const {
            showDivideEquallyWindow, divideEquallyInput
        } = this.state
        const {
            customTitle,
            costSplitAllocation,
            tagsList, canAddNewTags,
            isInDocPreview, readOnly,
            isAccountantInCurrentCompany, companyId,
            currency, paid
        } = this.props

        return (
            <div className={`cost-split-container ${currency !== 'PLN' ? 'other-currency' : ''} ${paid ? 'paid' : ''}`}>
                {
                    showDivideEquallyWindow ? (
                        <div className="divide-equally-window popup">
                            <div className="popup-card">
                                <h5>{this.context.t('How many parts should the cost be divided into?')}</h5>
                                <input
                                    type="text"
                                    value={divideEquallyInput}
                                    name={`divideEquallyInput`}
                                    onChange={this.onDivideInputChange}
                                />

                                <div className="action-buttons">
                                    <div className="button cancel" onClick={() => this.setState({ showDivideEquallyWindow: false, divideEquallyInput: '' })}>{this.context.t('Cancel')}</div>
                                    <div className="button save" onClick={() => this.divideEqually()}>{this.context.t('Accept')}</div>
                                </div>
                            </div>
                        </div>
                    ) : null
                }
                <div className="cost-split-header">
                    <h4>{this.context.t(customTitle || 'Split payment into cost categories')}:</h4>
                    {
                        !isInDocPreview ? (
                            <>
                                <div className="add-allocation" onClick={() => this.showDevideEqually()}>{this.context.t('Divide into x equal parts')}</div>
                                <div className="add-allocation" onClick={() => this.addCostSplit()}><span>+</span>{this.context.t('Add allocation')}</div>
                            </>
                        ) : null
                    }
                </div>
                {
                    costSplitAllocation.map((cost, i) => {
                        return (
                            <div key={cost.id > 0 ? `const-split-${cost.id}` : `const-split-${i}`} className="cost-split">

                                <div className="split-index">{i + 1}</div>
                                <div className="inputs-wrapper">

                                    <div className="form-group amount">
                                        <label>{this.context.t('Net amount')}</label>
                                        <input
                                            type="text"
                                            value={cost.netAmount}
                                            name={`net-amount-${i}`}
                                            onChange={e => this.onAmountChange(e, 'NET', i)}
                                            onBlur={e => this.onFieldBlur(i)}
                                            readOnly={readOnly}
                                        />
                                    </div>

                                    <div className="form-group amount">
                                        <label>{this.context.t('VAT amount')}</label>
                                        <input
                                            type="text"
                                            value={cost.vatAmount}
                                            name={`vat-amount-${i}`}
                                            onChange={e => this.onAmountChange(e, 'VAT', i)}
                                            onBlur={e => this.onFieldBlur(i)}
                                            readOnly={readOnly}
                                        />
                                    </div>

                                    <div className="form-group amount">
                                        <label>{this.context.t('Gross amount')}</label>
                                        <input
                                            type="text"
                                            className={`tags-container`}
                                            value={cost.grossAmount}
                                            name={`gross-amount-${i}`}
                                            onChange={e => this.onAmountChange(e, 'GROSS', i)}
                                            onBlur={e => this.onFieldBlur(i)}
                                            readOnly={readOnly}
                                        />
                                    </div>

                                    <div className={`form-group comment ${isAccountantInCurrentCompany ? 'full-width' : ''}`}>
                                        <label>{this.context.t('Comment')}</label>
                                        <input
                                            type="text"
                                            value={cost.comment}
                                            name={`net-amount-${i}`}
                                            onChange={e => this.onCommentChange(e, i)}
                                            onBlur={e => this.onFieldBlur(i)}
                                            readOnly={readOnly}
                                        />
                                    </div>

                                    <TagsComponent
                                        currentTags={cost.tags}
                                        singleTag={true}
                                        tagsList={tagsList || []}
                                        canAddNewTags={canAddNewTags}
                                        setTags={value => this.setTags(value, i)}
                                        customPlaceholder={cost.tags ? ' ' : this.context.t('Add tag for this allocation')}
                                        customMinWidth={'50px'}
                                        readOnly={readOnly}
                                    />

                                    {
                                        isAccountantInCurrentCompany ? (
                                            <OcrPageWaproNettoAccounts
                                                selectedOption={cost.waproNetAccount}
                                                select={value => this.setWaproNetAccount(value, i)}
                                                companyId={companyId}
                                            />
                                        ) : null
                                    }
                                </div>

                                {
                                    costSplitAllocation.length > 2 ? (
                                        <div className="delete-cost-split" onClick={() => this.deleteCostSplit(i)}></div>
                                    ) : null
                                }
                            </div>
                        )
                    })
                }

                {
                    !isInDocPreview ? (
                        <div className="cancel-cost-split-button" onClick={() => this.props.splitDocAmount(false)}>{this.context.t('Remove payment splits')}</div>
                    ) : null
                }

            </div>
        )
    }
}

CostSplit.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
    alertWarn: AlertActions.warning,
    addCostSplitInstance: FileActions.addCostSplitInstance,
    deleteCostSplitInstance: FileActions.deleteCostSplitInstance,
    editCostSplitInstance: FileActions.editCostSplitInstance
}

export default connect(mapStateToProps, mapDispatchToProps)(CostSplit)