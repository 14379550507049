import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import NewDocItemPopup from './NewDocItemPopup'
import DeleteDocItemPopup from './DeleteDocItemPopup'

import { CompanyActions } from "../../actions/CompanyActions";
import { AlertActions } from "../../actions/AlertActions";

import "../../../src/css/SalesPage.css";
import arrayObjectIndexOf from "../../helpers/indexOfObject";

export class InvoiceInfoBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemsList: [],
            newItemView: false,
            editItemView: false,
            editedItem: {},
            showDeleteView: false,
            itemToDelete: {},
        };
    }

    componentDidMount() {
        const { currentCompany } = this.props
        if (currentCompany && currentCompany.company_id)
            this.props.getSavedDocItems(currentCompany.company_id, (savedDocItems) => {
                if (savedDocItems) {
                    this.setState({
                        itemsList: savedDocItems,
                    });
                }
            });
    }

    componentDidUpdate(prevProps) {
        const { currentCompany } = this.props
        if (!prevProps.currentCompany && currentCompany && currentCompany.company_id)
            this.props.getSavedDocItems(currentCompany.company_id, (savedDocItems) => {
                if (savedDocItems) {
                    this.setState({
                        itemsList: savedDocItems,
                    });
                }
            });
    }

    addNewItem = () => {
        this.setState({
            newItemView: !this.state.newItemView,
            showMyItems: !this.state.showMyItems
        })
    }

    closeNewItemPopup = () => {
        this.setState({
            newItemView: false,
            editItemView: false,
            editedItem: {},
            showMyItems: true
        })
    }

    addedNewItem = newItem => {
        var newInvoiceItemsList = this.state.itemsList
        newInvoiceItemsList.push(newItem)

        this.setState({
            itemsList: newInvoiceItemsList
        })
    }

    savedChanges = newItem => {
        var newInvoiceItemsList = this.state.itemsList
        var itemIndex = arrayObjectIndexOf(newInvoiceItemsList, newItem.id, 'id')

        if (itemIndex > -1) {
            newInvoiceItemsList[itemIndex] = newItem
        }

        this.setState({
            itemsList: newInvoiceItemsList
        })
    }

    deletedDocsItem = itemId => {
        var newInvoiceItemsList = this.state.itemsList
        newInvoiceItemsList = newInvoiceItemsList.filter(e => e.id !== itemId)

        this.setState({
            itemsList: newInvoiceItemsList
        })
    }

    openDeleteItemPopup = item => {
        this.setState({
            showMyItems: false,
            showDeleteView: true,
            itemToDelete: item
        })
    }

    closeDeleteItemPopup = () => {
        this.setState({
            showMyItems: true,
            showDeleteView: false,
            itemToDelete: {}
        })
    }

    startEditingItem = item => {
        this.setState({
            editItemView: true,
            editedItem: item
        })
    }

    render() {
        const { newItemView, editItemView, editedItem, showDeleteView, itemToDelete, itemsList } = this.state

        if (newItemView) {
            return (
                <NewDocItemPopup
                    close={this.closeNewItemPopup}
                    createdNewItem={this.addedNewItem}
                />
            )
        } else if (editItemView) {
            return (
                <NewDocItemPopup
                    editingExisting={true}
                    editedItem={editedItem}
                    savedChanges={this.savedChanges}
                    close={this.closeNewItemPopup}
                />
            )
        } else if (showDeleteView) {
            return (
                <DeleteDocItemPopup
                    itemToDelete={itemToDelete}
                    deletedDocsItem={this.deletedDocsItem}
                    closeDeleteItemPopup={this.closeDeleteItemPopup}
                />
            )
        } else {
            return (
                <div className="my-items-popup" >
                    <div className="my-items-window">
                        <div className="main-text-items">{this.context.t("Manage your product/service list")}</div>
                        <span className="close-button" onClick={() => this.props.closePopup(false)}></span>
                        <div className="doc-items">
                            <ul>
                                <li className="header">
                                    <div className="name">{this.context.t('Name')}</div>
                                    <div className="net">{this.context.t('Net price')}</div>
                                    <div className="vat">{this.context.t('VAT rate')}</div>
                                    <div className="options-menu"></div>
                                </li>
                                <SimpleBar style={{ maxHeight: "30vh", width: "100%" }} >
                                    {
                                        itemsList.map((item, index) => {
                                            return (
                                                <li className={`${index % 2 === 1 ? 'odd' : ''}`}>
                                                    <div className="name">{item.name}</div>
                                                    <div className="net">{item.netPrice}</div>
                                                    <div className="vat">{item.vatRate}</div>
                                                    <div className="options-menu">
                                                        <span className="option edit" onClick={() => this.startEditingItem(item)}></span>
                                                        <span className="option delete" onClick={() => this.openDeleteItemPopup(item)}></span>
                                                    </div>
                                                </li>
                                            );
                                        })
                                    }
                                </SimpleBar>
                            </ul>
                        </div>
                        <div className="add-new-item" onClick={() => this.addNewItem()}>{this.context.t("Add a new product/service")}</div>
                    </div>
                </div>
            );
        }
    }
}

InvoiceInfoBox.contextTypes = {
    t: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
    currentCompany: state.Company.currentCompany,
});

const mapDispatchToProps = {
    getSavedDocItems: CompanyActions.getSavedDocItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceInfoBox);
