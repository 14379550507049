export const replaceSpecialCharacters = text => {
  const specialCharactersMap = {
    'ą': 'a',
    'à': 'a',
    'á': 'a',
    'â': 'a',
    'ã': 'a',
    'ä': 'a',
    'å': 'a',
    'æ': 'ae',
    'ć': 'c',
    'ç': 'c',
    'ę': 'e',
    'è': 'e',
    'é': 'e',
    'ê': 'e',
    'ë': 'e',
    'ǵ': 'g',
    'ì': 'i',
    'í': 'i',
    'î': 'i',
    'ï': 'i',
    'ł': 'l',
    'ñ': 'n',
    'ń': 'n',
    'ó': 'o',
    'ò': 'o',
    'ó': 'o',
    'ô': 'o',
    'õ': 'o',
    'ö': 'o',
    'ø': 'o',
    'œ': 'oe',
    'ŕ': 'r',
    'ś': 's',
    'ß': 'ss',
    'ù': 'u',
    'ú': 'u',
    'û': 'u',
    'ü': 'u',
    'ý': 'y',
    'ÿ': 'y',
    'ẃ': 'w',
    'ź': 'z',
    'ż': 'z',
    'Ą': 'A',
    'À': 'A',
    'Á': 'A',
    'Â': 'A',
    'Ã': 'A',
    'Ä': 'A',
    'Å': 'A',
    'Æ': 'AE',
    'Ć': 'C',
    'Ç': 'C',
    'Ę': 'E',
    'È': 'E',
    'É': 'E',
    'Ê': 'E',
    'Ë': 'E',
    'Ǵ': 'G',
    'Ì': 'I',
    'Í': 'I',
    'Î': 'I',
    'Ï': 'I',
    'Ł': 'L',
    'Ń': 'N',
    'Ñ': 'N',
    'Ò': 'O',
    'Ó': 'O',
    'Ô': 'O',
    'Õ': 'O',
    'Ö': 'O',
    'Ø': 'O',
    'Œ': 'OE',
    'Ŕ': 'R',
    'Ś': 'S',
    'Ù': 'U',
    'Ú': 'U',
    'Û': 'U',
    'Ü': 'U',
    'Ý': 'Y',
    'Ÿ': 'Y',
    'Ẃ': 'W',
    'Ź': 'Z',
    'Ż': 'Z',
  };

  return text.replace(/[^\w\s]/g, char => specialCharactersMap[char] || char);
}