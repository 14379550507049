import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import OcrPageOriginalDocNumber from './OcrPageOriginalDocNumber'
import OcrPageAgreementsList from './OcrPageAgreementsList'
import OcrPageProformList from './OcrPageProformList'

export class OcrPageLinkDocsPopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            assignToAgreement: false,
            correctionInvoice: false,
            assignToProforma: false,
            selectedAgreement: {},
            selectedOriginalDoc: {},
            selectedProforma: {}
        }
        this.containerRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClick);
        window.addEventListener("keydown", this.escFunction, false)

        const { linkedFiles } = this.props
        this.setState({
            assignToAgreement: linkedFiles.agreementLink.linked,
            selectedAgreement: linkedFiles.agreementLink.file,
            correctionInvoice: linkedFiles.correctionLink.linked,
            selectedOriginalDoc: linkedFiles.correctionLink.file,
            assignToProforma: linkedFiles.proformaLink.linked,
            selectedProforma: linkedFiles.proformaLink.file
        })
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClick);
        window.removeEventListener("keydown", this.escFunction, false)
    }

    escFunction = e => {
        if (e.keyCode === 27) {
            this.handleClick(e)
        }
    }

    handleClick = (event) => {
        if (event.keyCode === 27 || (this.containerRef && !this.containerRef.current.contains(event.target))) {
            this.props.cancel()
        } else {
        }
    }

    toggleAssignToAgreement = (checked) => {
        const { foundDocsFromCounterparty } = this.props
        if (foundDocsFromCounterparty) {
            this.setState({
                assignToAgreement: checked
            })
            if (!checked) {
                this.setState({
                    selectedAgreement: {}
                })
            }
        }
    }

    selectAgreement = agreement => {
        this.setState({
            selectedAgreement: agreement
        })
    }

    toggleCorrectionInvoice = (checked) => {
        const { foundDocsFromCounterparty } = this.props
        if (foundDocsFromCounterparty) {
            this.setState({
                correctionInvoice: checked
            })
            if (!checked) {
                this.setState({
                    selectedOriginalDoc: {}
                })
            }
        }
    }

    selectOriginalDoc = doc => {
        this.setState({
            selectedOriginalDoc: doc
        })
    }

    toggleAssignToProforma = (checked) => {
        const { foundDocsFromCounterparty } = this.props
        if (foundDocsFromCounterparty) {
            this.setState({
                assignToProforma: checked
            })
            if (!checked) {
                this.setState({
                    selectedProforma: {}
                })
            }
        }
    }

    selectProforma = doc => {
        this.setState({
            selectedProforma: doc
        })
    }

    render() {
        const { assignToAgreement, selectedAgreement, correctionInvoice, selectedOriginalDoc, assignToProforma, selectedProforma } = this.state
        const { docsList, agreementsList, counterpartyVatId, foundDocsFromCounterparty } = this.props

        return (
            <div className="link-doc popup">
                <div className="popup-card" ref={this.containerRef}>
                    <h3>{this.context.t('Link this document with others that have already been entered into EasyDocs')}</h3>

                    {
                        !foundDocsFromCounterparty ? (
                            <div className="no-docs-found">
                                {this.context.t('No documents to link found. Make sure that the counterparty\'s NIP is correct and you have at least one document from these counterparty in EasyDocs')}
                            </div>
                        ) : null
                    }

                    <div className={`link-section ${foundDocsFromCounterparty ? '' : 'not-allowed'}`}>
                        <div className="checkbox-group" onClick={() => this.toggleAssignToAgreement(!assignToAgreement)}>
                            <div className={`checkbox ${assignToAgreement ? 'checked' : ''}`}></div>
                            <div className="label">{this.context.t("Assign to agreement")}</div>
                        </div>
                        <OcrPageAgreementsList
                            counterpartyVatId={counterpartyVatId}
                            selectedOptionId={selectedAgreement.id || null}
                            selectedOptionValue={selectedAgreement.description || ''}
                            select={this.selectAgreement}
                            list={agreementsList}
                            active={assignToAgreement}
                        />
                    </div>

                    <div className={`link-section ${foundDocsFromCounterparty ? '' : 'not-allowed'}`}>
                        <div className="checkbox-group" onClick={() => this.toggleCorrectionInvoice(!correctionInvoice)}>
                            <div className={`checkbox ${correctionInvoice ? 'checked' : ''}`}></div>
                            <div className="label">{this.context.t("This document is a correction invoice")}</div>
                        </div>
                        <OcrPageOriginalDocNumber
                            counterpartyVatId={counterpartyVatId}
                            selectedOptionId={selectedOriginalDoc.id || null}
                            selectedOptionValue={selectedOriginalDoc.doc_number || ''}
                            select={this.selectOriginalDoc}
                            list={docsList}
                            active={correctionInvoice}
                        />
                    </div>

                    <div className={`link-section ${foundDocsFromCounterparty ? '' : 'not-allowed'}`}>
                        <div className="checkbox-group" onClick={() => this.toggleAssignToProforma(!assignToProforma)}>
                            <div className={`checkbox ${assignToProforma ? 'checked' : ''}`}></div>
                            <div className="label">{this.context.t("Document was issued on the basis of a proforma")}</div>
                        </div>
                        <OcrPageProformList
                            counterpartyVatId={counterpartyVatId}
                            selectedOptionId={selectedProforma.id || null}
                            selectedOptionValue={selectedProforma.doc_number || ''}
                            select={this.selectProforma}
                            list={docsList}
                            active={assignToProforma}
                        />
                    </div>

                    <div className="action-buttons">
                        <div className="btn cancel" onClick={() => this.props.cancel()}>{this.context.t('Cancel')}</div>
                        <div className="btn save" onClick={() => this.props.save({
                            agreementLink: {
                                linked: assignToAgreement,
                                file: selectedAgreement
                            },
                            correctionLink: {
                                linked: correctionInvoice,
                                file: selectedOriginalDoc
                            },
                            proformaLink: {
                                linked: assignToProforma,
                                file: selectedProforma
                            }
                        })}>{this.context.t('Save')}</div>
                    </div>
                </div>
            </div>
        )
    }
}

OcrPageLinkDocsPopup.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(OcrPageLinkDocsPopup)