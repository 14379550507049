import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import arrayObjectIndexOf from "../../helpers/indexOfObject"

import PdfPreview from '../PdfPreview'
import TransferPreview from '../TransferPreview'

import { AlertActions } from '../../actions/AlertActions'
import { FileActions } from '../../actions/FileActions'
import { TransfersActions } from '../../actions/TransfersActions';

export class FilesPreviewInReports extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }


    checkNext = () => {
        const { docsList, previewFile } = this.props

        var currentIndex = docsList.findIndex(e =>
            (e.id === previewFile.id || e.fileId === previewFile.id) && (!e.type || e.type === previewFile.docType)
        )

        console.log('currentIndex')
        console.log('currentIndex')
        console.log('currentIndex')
        console.log('currentIndex')
        console.log('currentIndex')
        console.log('currentIndex')
        console.log(currentIndex)

        if (
            currentIndex - 1 >= 0 &&
            this.findNextDocMatchingFilterOptions(currentIndex - 1, -1) &&
            currentIndex + 1 < docsList.length &&
            this.findNextDocMatchingFilterOptions(currentIndex + 1, 1)
        ) {
            return { index: currentIndex, prev: true, next: true }
        } else if (
            currentIndex - 1 > 0 &&
            this.findNextDocMatchingFilterOptions(currentIndex - 1, -1)
        ) {
            return { index: currentIndex, prev: true, next: false }
        } else if (
            currentIndex + 1 < docsList.length &&
            this.findNextDocMatchingFilterOptions(currentIndex + 1, 1)
        ) {
            return { index: currentIndex, prev: false, next: true }
        } else {
            return { index: currentIndex, prev: false, next: false }
        }
    }

    findNextDocMatchingFilterOptions = (startIndex, direction) => {
        const { docsList } = this.props

        var nextDoc = docsList[startIndex]
        var nextDocMatchesFilter = this.props.checkIfDocMatchesFilterOptions(nextDoc)

        while (!nextDocMatchesFilter) {
            startIndex += direction
            if (docsList[startIndex]) {
                nextDoc = docsList[startIndex]
                nextDocMatchesFilter = this.props.checkIfDocMatchesFilterOptions(nextDoc)
            } else break
        }

        if (nextDocMatchesFilter) return nextDoc
        else return null

    }

    goToDoc = (index, direction) => {
        const { previewFile } = this.props
        console.log(index, direction)

        var nextDoc = this.findNextDocMatchingFilterOptions(index, direction)
        console.log('goToDoc')
        console.log('goToDoc')
        console.log('goToDoc')
        console.log('goToDoc')
        console.log(nextDoc)

        if (nextDoc) {
            if (nextDoc.type !== 'PAYMENT_ORDER') {
                this.props.getFileBody(nextDoc.projectId, nextDoc.path || nextDoc.filePath, nextDoc.fileId || nextDoc.id, nextDoc.alreadyPaid ? 'DONE' : 'IN_PROGRESS', nextDoc.alreadyPaid ? 4 : 3)
                if (previewFile.docType === 'PAYMENT_ORDER') {
                    this.props.hideTransferPreviewWindow()
                }
            } else {
                const { transfers } = this.props
                var projectTransfers = transfers.find(e => e.project === nextDoc.projectId)
                if (projectTransfers && projectTransfers.transfers) {
                    var transfer = projectTransfers.transfers.find(e => e.id === nextDoc.id)
                    console.log(transfer)
                    if (transfer) {
                        this.props.openTransferPreviewWindow(transfer)
                    } else {
                        this.props.alertWarn(this.context.t('Payment order not found'))
                    }
                } else {
                    this.props.alertWarn(this.context.t('Payment order not found'))
                }
            }

            this.props.setCurrentDoc({
                projectId: nextDoc.projectId,
                file: nextDoc.path || nextDoc.filePath,
                id: nextDoc.id || nextDoc.fileId,
                docType: nextDoc.type
            })
        }
    }

    closePreview = () => {
        this.props.close()
    }

    render() {
        const { previewFile, pdfPreviewOpen, transferPreviewOpen, fullTextSearchPreviewIsOpen } = this.props

        if (!fullTextSearchPreviewIsOpen) {
            if (previewFile.docType !== 'PAYMENT_ORDER') {
                if (pdfPreviewOpen) {
                    return (
                        <PdfPreview location={this.props.location} prevAndNext={this.checkNext()} goToDoc={this.goToDoc} close={this.closePreview} customGoToDocControler={true} />
                    )
                } else {
                    return (null)
                }
            } else {
                if (transferPreviewOpen) {
                    return (
                        <TransferPreview location={this.props.location} prevAndNext={this.checkNext()} goToDoc={this.goToDoc} close={this.closePreview} customGoToDocControler={true} />
                    )
                } else {
                    return (null)
                }
            }
        } else {
            return (null)
        }
    }
}

FilesPreviewInReports.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    pdfPreviewOpen: state.File.pdfPreviewOpen,
    transferPreviewOpen: state.Transfers.transferPreviewOpen,
    transfers: [...state.Transfers.transfers],
    fullTextSearchPreviewIsOpen: state.File.fullTextSearchPreviewIsOpen,
})

const mapDispatchToProps = {
    getFileBody: FileActions.getFileData,
    togglePdfPreview: FileActions.togglePdfPreview,
    openTransferPreviewWindow: TransfersActions.openTransferPreviewWindow,
    hideTransferPreviewWindow: TransfersActions.hideTransferPreviewWindow,
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(FilesPreviewInReports)