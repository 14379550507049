import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { Spinner } from "react-activity";
import ReactTooltip from "react-tooltip";
import SimpleBar from "simplebar-react";

import { UserActions } from "../../actions/UserActions";
import { CompanyActions } from "../../actions/CompanyActions";
import { AlertActions } from "../../actions/AlertActions";
import { ReportsActions } from "../../actions/ReportsActions"
import { FileActions } from "../../actions/FileActions";
import { TabsControlerActions } from '../../actions/V2_TabsControlerActions'
import { AvailableTabs, GenerateInvoiceComponents } from "../../reducers/V2_TabsReducer"


import SalesForm from "../../components/SalesPageComponents/SalesForm";
import InvoiceInfoBox from "../../components/SalesPageComponents/InvoiceInfoBox";
import ImportInvoicePopup from "../../components/SalesPageComponents/ImportInvoicePopup";
import SendEmailBox from "../../components/SalesPageComponents/SendEmailBox";
import UnlockInvoiceBox from "../../components/SalesPageComponents/UnlockInvoiceBox";
import InvoicePreview from "../../components/SalesPageComponents/InvoicePreview";
import DeleteSaleInvoicePopup from "../../components/SalesPageComponents/DeleteSaleInvoicePopup"
import JPK_FAPopupForm from '../../components/SalesPageComponents/JPK_FAPopupForm'
import InvoiceItemsList from "../../components/SalesPageComponents/InvoiceItemsList";
import RecurringInvoicesList from "../../components/SalesPageComponents/RecurringInvoicesList";
import KsefInfoPopup from "../../components/KSeF/KsefInfoPopup"
import Calendar from "../../components/Calendar"

import arrayObjectIndexOf from "../../helpers/indexOfObject";
import { getCurrencyRateFromNBP } from "../../helpers/getCurrencyRateFromNBP";
import isIterable from "../../helpers/isIterable"

import "./../../css/SalesPage.css";
import CorrectionBuyerForm from "../../components/SalesPageComponents/CorrectionBuyerForm";
import CorrectionItemForm from "../../components/SalesPageComponents/CorrectionItemForm";;

export const DOCUMENT_TYPE = {
  invoice: "invoice",
  proforma: "proforma",
  corrBuyer: "corrbuyer",
  corrItems: "corritems",
};

export class SalesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBar: "",
      userHasAccessToCompanySaleInvoices: true,
      showInvoiceCreatorOverlay: false,
      showProjectsList: false,
      invoicesList: [],
      currentDir: "",
      currentDirSummary: {
        docsCount: 0,
        netSum: 0,
        vatSum: 0,
        grossSum: 0
      },
      showInvoiceInfoBox: false,
      invalidDataFromImport: [],
      titleInfoBox: "",
      previewInvoice: null,
      showInvoicePreview: false,
      previewInvoiceId: null,

      showImportLoader: false,
      totalInvoicesToImport: 0,
      showCorrectionBuyerForm: false,
      showCorrectionItemForm: false,
      selectedInvoiceData: null,

      currencyRateOption: "PREVIOUS_DAY",
      excelFileToUpload: {},
      editingData: null,

      showImportCurrencyRateOptions: false,
      showJPK_FAGenerator: false,
      showMyItems: false,
      newItemView: false,
      showDeleteWindow: false,

      documentType: "",
      isInvoiceFromProforma: false,
      isSimilarInvoice: false,
      selectedCheckboxPaid: false,

      showDeleteInvoicePopup: false,
      invoiceToBeDeleted: null,

      showSendInvoiceByEmail: false,
      invoiceToSendData: {},

      showUnlockWindow: false,
      invoiceToUnlock: {},

      showRecurringInvoices: false,
    };
  }

  componentDidMount() {
    if (window.location && window.location.pathname && window.location.pathname.includes('/create')) {
      this.showInvoiceCreatorOverlay()
    }

    const { userProjects, currentProject, userCompanies, currentCompany, user } = this.props;
    if (!userProjects || userProjects.length === 0) {
      this.props.getUserProjects({});
    }
    this.setInvoiceList();
    if (currentCompany && currentProject && currentCompany.company_id === currentProject.company_id)
      this.setSeller();
    else if (currentCompany && currentProject && currentCompany.company_id !== currentProject.company_id) {
      var companyIndex = arrayObjectIndexOf(
        userCompanies,
        currentProject.company_id,
        "company_id"
      );
      if (companyIndex > -1) {
        this.props.setCurrentCompany(userCompanies[companyIndex]);
      }
    }

    if (currentCompany && userProjects && userProjects.length) {
      this.checkIfUserHacAccessToInvoices()
    }

    const { subComponent } = this.props
    if (subComponent === GenerateInvoiceComponents.INVOICE) {
      const { originalInvoice, isEditingInvoice, isInvoiceFromProforma, isSimilarInvoice } = this.props
      this.setState({
        showInvoiceCreatorOverlay: true,
        showCorrectionBuyerForm: false,
        showCorrectionItemForm: false,
        editingData: originalInvoice ? { ...originalInvoice } : null,
        isInvoiceFromProforma: isInvoiceFromProforma,
        isSimilarInvoice: isSimilarInvoice,
        selectedInvoiceData: null
      })
    } else if (subComponent === GenerateInvoiceComponents.BUYER_CORRECTION) {
      const { originalInvoice } = this.props
      this.setState({
        showInvoiceCreatorOverlay: false,
        showCorrectionBuyerForm: true,
        showCorrectionItemForm: false,
        editingData: null,
        isInvoiceFromProforma: false,
        isSimilarInvoice: false,
        selectedInvoiceData: originalInvoice ? { ...originalInvoice } : null
      })
    } else if (subComponent === GenerateInvoiceComponents.ITEMS_CORRECTION) {
      const { originalInvoice } = this.props
      this.setState({
        showInvoiceCreatorOverlay: false,
        showCorrectionBuyerForm: false,
        showCorrectionItemForm: true,
        editingData: null,
        isInvoiceFromProforma: false,
        isSimilarInvoice: false,
        selectedInvoiceData: originalInvoice ? { ...originalInvoice } : null
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentProject !== prevProps.currentProject || this.props.currentCompany !== prevProps.currentCompany) {
      this.setInvoiceList();
      this.setSeller();

      this.checkIfUserHacAccessToInvoices()
    }
    ReactTooltip.rebuild();

    const { subComponent, originalInvoice } = this.props
    if ((subComponent && subComponent !== prevProps.subComponent) || originalInvoice !== prevProps.originalInvoice) {
      if (subComponent === GenerateInvoiceComponents.INVOICE) {
        const { originalInvoice, isEditingInvoice, isInvoiceFromProforma, isSimilarInvoice } = this.props
        if (originalInvoice !== prevProps.originalInvoice) {
          this.setState({
            showInvoiceCreatorOverlay: true,
            showCorrectionBuyerForm: false,
            showCorrectionItemForm: false,
            editingData: originalInvoice ? { ...originalInvoice } : null,
            isInvoiceFromProforma: isInvoiceFromProforma,
            isSimilarInvoice: isSimilarInvoice,
            selectedInvoiceData: null
          })
        }
      } else if (subComponent === GenerateInvoiceComponents.BUYER_CORRECTION) {
        const { originalInvoice } = this.props
        this.setState({
          showInvoiceCreatorOverlay: false,
          showCorrectionBuyerForm: true,
          showCorrectionItemForm: false,
          editingData: null,
          isInvoiceFromProforma: false,
          isSimilarInvoice: false,
          selectedInvoiceData: originalInvoice ? { ...originalInvoice } : null
        })
      } else if (subComponent === GenerateInvoiceComponents.ITEMS_CORRECTION) {
        const { originalInvoice } = this.props
        this.setState({
          showInvoiceCreatorOverlay: false,
          showCorrectionBuyerForm: false,
          showCorrectionItemForm: true,
          editingData: null,
          isInvoiceFromProforma: false,
          isSimilarInvoice: false,
          selectedInvoiceData: originalInvoice ? { ...originalInvoice } : null
        })
      }
    }

    const { showInvoiceCreatorOverlay, showCorrectionBuyerForm, showCorrectionItemForm, showDeleteInvoicePopup } = this.state
    if (
      (prevState.showInvoiceCreatorOverlay && !showInvoiceCreatorOverlay) ||
      (prevState.showCorrectionBuyerForm && !showCorrectionBuyerForm) ||
      (prevState.showCorrectionItemForm && !showCorrectionItemForm) ||
      (prevState.showDeleteInvoicePopup && !showDeleteInvoicePopup)
    ) {
      setTimeout(() => {
        const { invoicesList, currentCompany } = this.props
        const { currentDir } = this.state

        var companyGroup = invoicesList.find(group => group.companyId === currentCompany.company_id)
        if (companyGroup) {
          var month = companyGroup.invoices?.find(month => month.directory === currentDir)
          if (month) this.changeCurrentDir(month.directory, month.docsCount, month.netSum, month.vatSum, month.grossSum)
        }
      }, 500)
    }
  }

  componentWillUnmount() {
    /* window.removeEventListener("keydown", this.escFunction, false); */
  }

  checkIfUserHacAccessToInvoices = () => {
    const { userProjects, currentCompany, user } = this.props;
    if (currentCompany && userProjects && userProjects.length) {
      var companyProject = userProjects.filter(p => p.company_id === currentCompany.company_id)

      var hasAccessToInvoices = false
      companyProject.forEach(p => {
        if (p.acceptance_permissions !== 'USER1') hasAccessToInvoices = true
      })

      this.setState({
        userHasAccessToCompanySaleInvoices: hasAccessToInvoices
      })

      if (hasAccessToInvoices && !user.seen_ksef_sales_popup && currentCompany.company && currentCompany.company.ksef_integration_id) {
        this.setState({
          showKsefInfoPopup: true
        })
      }
    }
  }


  onInputChange = e => {
    e.preventDefault()

    const { name, value } = e.target

    this.setState({ [name]: value })
  }

  setInvoiceList = () => {
    const { currentProject, userCompanies, invoicesList } = this.props;
    var currentCompany = null;
    const companyIndex = arrayObjectIndexOf(
      userCompanies,
      currentProject.company_id,
      "company_id"
    );
    if (companyIndex > -1) {
      currentCompany = userCompanies[companyIndex];
    }
    if (
      currentCompany &&
      this.state.sellerId !== currentCompany.company_id &&
      !invoicesList.find((e) => e.companyId === currentCompany.company_id)
    ) {
      this.props.getPrieviosGeneratedInvoices(currentCompany.company_id);
    }
  };

  toggleProjectsList = () => {
    const { showProjectsList } = this.state;
    this.setState({
      showProjectsList: !showProjectsList,
    });
  };

  closeProjectsList = () => {
    this.setState({
      showProjectsList: false,
    });
  };

  switchViewMode = (e, p, companyId) => {
    e.preventDefault();
    this.props.switchDashboardViewModel(p);
    this.setState({
      showProjectsList: false,
    });

    var { userCompanies, currentCompany } = this.props;

    if (currentCompany.id !== companyId) {
      var companyIndex = arrayObjectIndexOf(
        userCompanies,
        companyId,
        "company_id"
      );
      if (companyIndex > -1) {
        this.props.setCurrentCompany(userCompanies[companyIndex]);
      }
    }
  };

  hasAncestor = (element, id) => {
    while (element) {
      console.log(element)
      if (element.id && element.id === id) return true;
      element = element.parentNode;
    }
    return false;
  }

  openInvoicePreview = (e, invoice) => {
    if (!this.hasAncestor(e.target, 'invoiceOptionsContainer') && ((e.target.className && e.target.className !== "excel-indicator" && !e.target.className.includes('checkbox-paid') && !e.target.className.includes('correction-icon') && !e.target.className.includes('recurring-icon')) || !e.target.className)) {
      this.setState({
        cursorWait: true,
        documentType: invoice.document_type,
      });
      this.props.downloadGeneratedInvoice(
        invoice.id,
        this.state.sellerId,
        (fileName, fileBody) => {
          this.setState({
            fileName,
            fileBody,
            cursorWait: false,
            previewInvoiceId: invoice.id,
            previewInvoice: invoice,
            showInvoicePreview: true
          });
        }
      );
    }
  };

  openInvoicePreviewWithCorrection = (e, invoice) => {
    this.setState({
      cursorWait: true,
      documentType: invoice.document_type,
    });
    this.props.downloadGeneratedInvoice(
      invoice.id,
      this.state.sellerId,
      (fileName, fileBody) => {
        this.setState({
          fileName,
          fileBody,
          cursorWait: false,
          previewInvoiceId: invoice.id,
          previewInvoice: invoice,
          showInvoicePreview: true,
          openPreviewSideBySide: true
        });
      }
    );

    this.props.downloadGeneratedInvoice(
      invoice.corrected_by,
      this.state.sellerId,
      (fileName, fileBody) => {
        this.setState({
          secondPreviewFileId: invoice.corrected_by,
          secondPreviewFileName: fileName,
          secondPreviewFileBody: fileBody
        });
      }
    );
  }

  toggleViewMyItems = show => {
    this.setState({
      showMyItems: show
    })
  }

  toggleViewRecurringInvoices = show => {
    this.setState({
      showRecurringInvoices: show
    })
  }

  openSecondDocPreview = (invoiceId) => {
    this.setState({
      openPreviewSideBySide: true,
      secondPreviewFileId: invoiceId,
      secondPreviewFileName: null,
      secondPreviewFileBody: null
    });
    this.props.downloadGeneratedInvoice(
      invoiceId,
      this.state.sellerId,
      (fileName, fileBody) => {
        this.setState({
          secondPreviewFileId: invoiceId,
          secondPreviewFileName: fileName,
          secondPreviewFileBody: fileBody
        });
      }
    );
  }

  closeSecondDocPreview = () => {
    this.setState({
      openPreviewSideBySide: false,
      secondPreviewFileId: null,
      secondPreviewFileName: null,
      secondPreviewFileBody: null
    });
  }

  openInvoicePreviewAfterCorrectionGeneration = (fileBody, fileName, invoice) => {
    this.setState({
      documentType: invoice.document_type,
      fileName,
      fileBody,
      previewInvoiceId: invoice.id,
      previewInvoice: invoice,
      showInvoicePreview: true,
    });
  }

  generateDuplicate = id => {
    this.setState({
      cursorWait: true,
    });
    this.props.generateDuplicate(id, () => {
      this.setState({
        cursorWait: false,
      })
    })
  }

  downloadInvoice = (id) => {
    this.setState({
      cursorWait: true,
    });
    this.props.downloadGeneratedInvoice(
      id,
      this.state.sellerId,
      (fileName, fileBody) => {
        this.setState({
          cursorWait: false,
        });
        const a = document.createElement("a");
        a.href = fileBody;
        a.download = fileName;
        a.click();
      }
    );
  };

  showInvoiceCreatorOverlay = () => {
    const { user } = this.props
    if (!user.new_easydocs_version) {
      this.setState({
        showInvoiceCreatorOverlay: true,
      })
    } else {
      this.props.openNewTab(AvailableTabs.GENERATE_SALE_INVOICE, GenerateInvoiceComponents.INVOICE)
    }
  };

  hideInvoiceCreatorOverlay = () => {
    this.setState({
      showInvoiceCreatorOverlay: false,
      editingData: null,
      isInvoiceFromProforma: false,
    });
  };

  setEditingModeFromPreview = () => {
    const { previewInvoice } = this.state;
    const { user } = this.props
    if (!user.new_easydocs_version) {
      if (previewInvoice) {
        this.setState({
          showInvoiceCreatorOverlay: true,
          editingData: previewInvoice,
          previewInvoice: null,
          showInvoicePreview: false,
        });
      }
    } else {
      this.props.openNewTab(
        AvailableTabs.GENERATE_SALE_INVOICE,
        GenerateInvoiceComponents.INVOICE,
        {
          originalInvoice: previewInvoice,
          isEditingInvoice: true
        }
      )
    }
  };

  acceptInvoice = invoice => {
    this.props.acceptGeneratedSaleInvoice(
      invoice.id,
      invoice.company_id,
      this.props.user.id
    );
  };

  unlockInvoiceStart = invoice => {
    this.setState({
      showUnlockWindow: true,
      invoiceToUnlock: invoice
    })

  }

  unlockInvoiceCancel = () => {
    this.setState({
      showUnlockWindow: false,
      invoiceToUnlock: {}
    })
  }

  unlockInvoiceConfirm = () => {
    const { invoiceToUnlock } = this.state
    this.props.unlockSaleInvoice(
      invoiceToUnlock.id,
      invoiceToUnlock.company_id,
      this.props.user.id
    );
    this.setState({
      showUnlockWindow: false,
      invoiceToUnlock: {}
    })
  }

  setEditingModeFromOptions = (invoice) => {
    if (!invoice.accepted && !invoice.has_correction && !['SENT', 'ACCEPTED'].includes(invoice.ksef_status)) {
      if (invoice.document_type === DOCUMENT_TYPE.invoice) {
        const { user } = this.props
        if (!user.new_easydocs_version) {
          this.setState({
            showInvoiceCreatorOverlay: true,
            editingData: invoice,
            previewInvoice: null,
            showInvoicePreview: false,
            isInvoiceFromProforma: false,
            isSimilarInvoice: false,
          });
        } else {
          this.props.openNewTab(
            AvailableTabs.GENERATE_SALE_INVOICE,
            GenerateInvoiceComponents.INVOICE,
            {
              originalInvoice: invoice,
              isEditingInvoice: true
            }
          )
        }
      } else if (invoice.document_type === DOCUMENT_TYPE.corrBuyer) {
        this.setState({
          showCorrectionBuyerForm: true,
          editingData: {
            ...invoice,
            editedInvoiceId: invoice.id,
            editingExistingInvoice: true,
          },
          previewInvoice: null,
          showInvoicePreview: false,
          isInvoiceFromProforma: false,
          isSimilarInvoice: false,
        });
      } else if (invoice.document_type === DOCUMENT_TYPE.corrItems) {
        this.setState({
          showCorrectionItemForm: true,
          editingData: invoice,
          previewInvoice: null,
          showInvoicePreview: false,
          isInvoiceFromProforma: false,
          isSimilarInvoice: false,
        });
      }
    } else if (['SENT', 'ACCEPTED'].includes(invoice.ksef_status)) {
      this.props.alertWarn(
        this.context.t("You can not edit invoices that were sent to KSeF")
      );
    } else if (invoice.has_correction) {
      this.props.alertWarn(
        this.context.t("You can not edit corrected invoices")
      );
    } else if (invoice.accepted) {
      this.props.alertWarn(
        this.context.t("You can not edit accepted invoices")
      );
    }
  };

  hideSearchVatIdPopup = () => {
    window.removeEventListener("keydown", this.searchOnEnter, false);
    this.setState({
      showVatIDSearchPopup: false,
      searchBuyerVatId: "",
    });
  };

  hideInvoicePreview = () => {
    this.setState({
      showInvoicePreview: false,
      openPreviewSideBySide: false,
      secondPreviewFileId: null,
      secondPreviewFileName: null,
      secondPreviewFileBody: null
    });
  };

  generateCode = (name) => {
    var indexOfComma = name.indexOf(",");

    if (indexOfComma > 0) {
      var tempName = name.substring(0, indexOfComma);
      tempName = tempName.trim();

      if ((tempName.match(/\"/gm) || []).length !== 1) {
        name = tempName;
      }
    }

    if (name.includes('"')) {
      var newName = name.match(/\"(.*)\"/g) || [];
      newName = newName.find((x) => x !== undefined);

      if (newName) {
        newName = newName.replace(/\"/gm, "");
        newName = newName.trim();
        var wordsArray = newName.split(" ");
        if (wordsArray.length === 1) {
          newName = wordsArray[0];
          var indexOfDash = newName.lastIndexOf("-");
          if (indexOfDash > 0 && newName.length > 20) {
            newName = newName.substring(0, indexOfDash);

            return newName.substring(0, 20);
          } else {
            return newName.substring(0, 20);
          }
        } else {
          var code = "";
          wordsArray.forEach((w) => {
            if (w.length > 2 && !w.match(/^\d/)) {
              code += w[0];
            } else {
              code += w;
            }
          });

          return code;
        }
      } else {
        return "";
      }
    } else {
      var wordsArray = name.split(" ");
      if (wordsArray.length === 1) {
        newName = wordsArray[0];
        var indexOfDash = newName.lastIndexOf("-");
        if (indexOfDash > 0) {
          newName = newName.substring(0, indexOfDash);

          return newName.substring(0, 20);
        } else {
          return newName.substring(0, 20);
        }
      } else if (wordsArray.length === 2) {
        var part1 = wordsArray[0];
        var part2 = wordsArray[1];

        if (part1.length + part2.length <= 19) {
          return part1 + "-" + part2;
        } else {
          if (part1.includes("-")) {
            return part1;
          } else if (part2.includes("-")) {
            return part2;
          } else {
            return part1 + "-" + part2.substring(0, 19 - part1.length);
          }
        }
      } else {
        var code = "";
        wordsArray.forEach((w) => {
          if (w.length > 2 && !w.match(/^\d/)) {
            code += w[0];
          } else {
            code += w;
          }
        });

        return code;
      }
    }
  };

  searchOnEnter = (e) => {
    if (e.keyCode === 13) this.searchVatAPI();
  };

  setSeller = () => {
    const { currentProject, userCompanies } = this.props;

    var currentCompany = null;

    const companyIndex = arrayObjectIndexOf(
      userCompanies,
      currentProject.company_id,
      "company_id"
    );

    if (companyIndex > -1) {
      currentCompany = userCompanies[companyIndex];
    }

    if (currentCompany && this.state.sellerId !== currentCompany.company_id) {
      const company = currentCompany.company;
      this.setState({
        sellerId: company.id,
      });
    }
  };

  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  uploadHandler = async (event) => {
    const currentFile = event.target.files[0];
    if (currentFile) {
      const myResult = {};
      myResult.name = currentFile.name;
      myResult.size = currentFile.size;
      myResult.type = currentFile.type;
      myResult.base64 = await this.blobToBase64(currentFile);
      this.setState({
        excelFileToUpload: myResult,
        showImportCurrencyRateOptions: true,
      });
    }
  };

  uploadExcelFile = () => {
    this.props.importInvoice(
      this.state.excelFileToUpload,
      this.props.currentCompany.company_id,
      this.state.currencyRateOption || 'PREVIOUS_DAY',
      this.state.currencyRateDate || '',
      this.showInfoBox
    );
  };

  showInfoBox = (success, title, invalidData, invoicesCount) => {
    if (success) {
      this.setState({
        excelFileToUpload: {},
        showImportCurrencyRateOptions: false,
        showImportLoader: true,
        totalInvoicesToImport: invoicesCount,
      });
    } else {
      this.setState({
        excelFileToUpload: {},
        showImportCurrencyRateOptions: false,
        invalidDataFromImport: invalidData ? invalidData : [],
        titleInfoBox: title,
        showInvoiceInfoBox: true,
      });
    }
  };

  hideInfoBox = () => {
    this.setState({ showInvoiceInfoBox: false });
  };

  changeCurrentDir = (dir, docsCount = 0, netSum = 0, vatSum = 0, grossSum = 0) => {
    this.setState({
      currentDir: dir,
      currentDirSummary: {
        docsCount,
        netSum,
        vatSum,
        grossSum
      }
    })
  }

  closeImportLoader = () => {
    this.setState({
      showImportLoader: false,
      totalInvoicesToImport: 0,
    });
  };

  toggleShowCorrectionBuyerForm = (invoice) => {
    if (!invoice || !invoice.has_correction) {
      const { user } = this.props
      if (!user.new_easydocs_version) {
        this.setState({
          showCorrectionBuyerForm: !this.state.showCorrectionBuyerForm,
        })
      } else {
        this.props.openNewTab(
          AvailableTabs.GENERATE_SALE_INVOICE,
          GenerateInvoiceComponents.BUYER_CORRECTION,
          {
            originalInvoice: invoice
          }
        )
      }
    } else {
      this.props.alertWarn(this.context.t('You cannot issue another correction of this document'))
    }
  };

  toggleShowCorrectionItemForm = (invoice) => {
    if (!invoice || !invoice.has_correction) {
      const { user } = this.props
      if (!user.new_easydocs_version) {
        this.setState({
          showCorrectionItemForm: !this.state.showCorrectionItemForm,
        })
      } else {
        this.props.openNewTab(
          AvailableTabs.GENERATE_SALE_INVOICE,
          GenerateInvoiceComponents.ITEMS_CORRECTION,
          {
            originalInvoice: invoice
          }
        )
      }
    } else {
      this.props.alertWarn(this.context.t('You cannot issue another correction of this document'))
    }
  }

  toggleDeleteForInvoice = (invoice) => {
    if (!invoice.accepted && !invoice.has_correction && !['SENT', 'ACCEPTED'].includes(invoice.ksef_status)) {
      this.setState({
        showDeleteInvoicePopup: true,
        invoiceToBeDeleted: invoice,
      });
    } else if (['SENT', 'ACCEPTED'].includes(invoice.ksef_status)) {
      this.props.alertWarn(
        this.context.t("You cannot delete invoices that were sent to KSeF")
      );
    } else if (invoice.has_correction) {
      this.props.alertWarn(
        this.context.t("You cannot delete corrected document")
      );
    } else if (invoice.accepted) {
      this.props.alertWarn(
        this.context.t("You can not delete accepted invoices")
      );
    }
  };

  hideDeletePopup = () => {
    this.setState({
      showDeleteInvoicePopup: false,
      invoiceToBeDeleted: null,
    });
  };

  confirmInleteInvoice = () => {
    const { invoiceToBeDeleted } = this.state;
    this.props.deleteGeneratedInvoice(
      invoiceToBeDeleted.id,
      invoiceToBeDeleted.company_id,
      invoiceToBeDeleted.filePath,
      invoiceToBeDeleted.file_id,
      invoiceToBeDeleted.project_id,
      (success) => {
        if (success) {
          this.hideDeletePopup();
        }
      }
    );
  };

  downloadImportExcelFile = (invoiceId) => {
    this.setState({
      cursorWait: true,
    });

    this.props.getInvoiceImportExcelFile(invoiceId, () => {
      this.setState({
        cursorWait: false,
      });
    });
  };

  showCustomCurrencyDateCalendar = () => {
    this.setState({
      showImportCalendar: true
    })
  }

  selectCurrencyDay = date => {
    if (date) {
      this.setState({
        cursorWait: true
      })
      this.setCurrencyMidPrice(moment(date).format("YYYY-MM-DD"))
    }
  }

  setCurrencyMidPrice = async (date) => {
    const eurRes = await getCurrencyRateFromNBP('EUR', date);
    this.setState({
      cursorWait: false,
      showImportCalendar: false,
      currencyRateDate: eurRes.effectiveDate,
      currencyRateOption: 'CUSTOM'
    });
  };

  toggleGenerateJPK_FAPopup = (show) => {
    this.setState({
      showJPK_FAGenerator: show
    })
  }

  issueAnInvoiceHandler = (proformaData) => {
    const { user } = this.props
    if (!user.new_easydocs_version) {
      this.setState({
        editingData: proformaData,
        showInvoiceCreatorOverlay: true,
        isInvoiceFromProforma: true,
        isSimilarInvoice: false,
      })
    } else {
      this.props.openNewTab(
        AvailableTabs.GENERATE_SALE_INVOICE,
        GenerateInvoiceComponents.INVOICE,
        {
          originalInvoice: proformaData,
          isInvoiceFromProforma: true
        }
      )
    }
  }

  issueSimilarInvoiceHandler = (invoiceData) => {
    const { user } = this.props
    if (!user.new_easydocs_version) {
      this.setState({
        editingData: invoiceData,
        showInvoiceCreatorOverlay: true,
        isInvoiceFromProforma: false,
        isSimilarInvoice: true,
      })
    } else {
      this.props.openNewTab(
        AvailableTabs.GENERATE_SALE_INVOICE,
        GenerateInvoiceComponents.INVOICE,
        {
          originalInvoice: invoiceData,
          isSimilarInvoice: true
        }
      )
    }
  }

  getbuyerName = i => {
    if (i.buyer_name) return i.buyer_name
    if (i.counterparty && i.counterparty.name) return i.counterparty.name
    return ''
  }

  renderInvoiceRow = (i, month) => {
    const { currentCompany } = this.props
    return (
      <div className="invoice-row" >
        <div className="name" onClick={(e) => this.openInvoicePreview(e, i)}>{this.getbuyerName(i)}</div>
        <div className="doc-number">
          <span className="text-number">{i.doc_number}</span>
          {
            i.has_correction ? (
              <span className="correction-icon" data-for="correction-tooltip" data-tip="show" onClick={(e) => this.openInvoicePreviewWithCorrection(e, i)}></span>
            ) : (
              i.recurring_invoice ? (
                <span className="recurring-icon" data-for="recurring-tooltip" data-tip="show" onClick={(e) => this.toggleViewRecurringInvoices(true)}></span>
              ) : null
            )
          }
        </div>
        {
          currentCompany.company && currentCompany.company.ksef_integration_id ? (
            <div className="doc-number ksef">
              {this.generateKsefNumberField(i)}
            </div>
          ) : null
        }
        <div className="issue-date" onClick={(e) => this.openInvoicePreview(e, i)}>
          {moment(i.issue_date).format("DD-MM-YYYY")}
          {
            i.generated_from_excel ? (
              <span className="excel-indicator" data-for="excel-tooltip" data-tip="show" onClick={e => this.downloadImportExcelFile(i.id)}></span>
            ) : (
              null
            )
          }
        </div>
        <div className="due-date" onClick={(e) => this.openInvoicePreview(e, i)}>
          {
            i.due_date ? (
              <>
                {moment(i.due_date).format("DD-MM-YYYY")}
                <div data-tip="show" data-for="paid-tooltip" className={`checkbox-paid ${i.payment_status === "paid" ? 'selected' : ''}`} onClick={() => this.props.tooglePaymentForIncomeDoc(i.id, i.file_id, i.project_id, i.payment_status === "paid" ? '' : 'transfer', i.company_id, month)} />
              </>

            ) : null
          }
        </div>
        <div className="price" onClick={(e) => this.openInvoicePreview(e, i)}>{`${this.formatPrice(parseFloat(i.total_net_value))} ${i.currency}`}</div>
        <div className="price" onClick={(e) => this.openInvoicePreview(e, i)}>{`${this.formatPrice(parseFloat(i.total_gross_value))} ${i.currency}`}</div>
        <div className="buttons-container" id="invoiceOptionsContainer">
          <div className="btn icon dropdown">
            <span className="options-icon" />
            <div className="action-drop-list">
              {/* <div className="list-title">{" "}{this.context.t("Manage")}{" "} </div> */}
              {
                i.document_type !== DOCUMENT_TYPE.proforma ? (
                  <>
                    {
                      !i.accepted && !i.has_correction ? (
                        <div className="action-button" onClick={() => this.acceptInvoice(i)} >
                          {this.context.t("Lock for editing")}
                        </div>
                      ) : (
                        i.accepted && !i.has_correction ? (
                          <div className="action-button" onClick={() => this.unlockInvoiceStart(i)} >
                            {this.context.t("Unlock for editing")}
                          </div>
                        ) : null
                      )
                    }

                    {
                      i.document_type !== DOCUMENT_TYPE.corrBuyer && i.document_type !== DOCUMENT_TYPE.corrItems ? (
                        <>
                          <div className={`action-button ${i.accepted || i.has_correction || ['SENT', 'ACCEPTED'].includes(i.ksef_status) ? "inactive" : ""}`} onClick={() => this.setEditingModeFromOptions(i)}>
                            {this.context.t("Edit")}
                          </div>
                          <div className="action-button" onClick={() => this.issueSimilarInvoiceHandler(i)}>
                            {this.context.t("Put out a similar one")}
                          </div>
                        </>
                      ) : null
                    }

                    {
                      i.document_type === DOCUMENT_TYPE.invoice || i.document_type === DOCUMENT_TYPE.corrItems || i.document_type === DOCUMENT_TYPE.corrBuyer ? (
                        <>
                          <div className={`action-button ${i.has_correction ? "inactive" : ""}`} onClick={() => { this.setState({ selectedInvoiceData: i }); this.toggleShowCorrectionBuyerForm(i) }}>
                            {this.context.t("Issue a correction of formal data")}
                          </div>
                          <div className={`action-button ${i.has_correction ? "inactive" : ""}`} onClick={() => { this.setState({ selectedInvoiceData: i }); this.toggleShowCorrectionItemForm(i) }}>{this.context.t("Issue a position correction")}</div>
                        </>
                      ) : null
                    }
                    {
                      i.is_generated ? (
                        <div className="action-button" onClick={() => this.generateDuplicate(i.id)}>{this.context.t("Issue a duplicate")}</div>
                      ) : null
                    }
                    {
                      !i.accepted ? (
                        <>
                          <div className={`action-button order-last ${i.accepted || i.has_correction || ['SENT', 'ACCEPTED'].includes(i.ksef_status) ? "inactive" : ""}`} onClick={() => { this.toggleDeleteForInvoice(i); }} >
                            {this.context.t("Delete")}
                          </div>
                        </>
                      ) : null
                    }
                    <div className={`action-button send`} onClick={() => { this.openSendEmailBox(i); }} >
                      {this.context.t("Send")}
                    </div>
                  </>
                ) : null
              }

              {
                i.document_type === DOCUMENT_TYPE.proforma ? (
                  <div className="action-button" onClick={() => this.issueAnInvoiceHandler(i)}>
                    {this.context.t("Issue an invoice")}
                  </div>
                ) : null
              }
            </div>
          </div>
          <div className="btn icon" onClick={() => this.downloadInvoice(i.id)}>
            <span className="download-icon" />
          </div>
        </div>
        <div className={`sent_by_email ${i.sent_to ? 'sent-icon' : ''}`}>
          {
            i.sent_to ? (
              <div className="sent-info">
                <h5>{this.context.t('Sent to')}:</h5>
                <ul>
                  {
                    i.sent_to.split(',').map(address => {
                      return (
                        <li>{address.trim()}</li>
                      )
                    })
                  }
                </ul>
              </div>
            ) : null
          }
        </div>
      </div>
    )
  }

  generateKsefNumberField = invoice => {
    if (!invoice.ksef_status || invoice.ksef_status === 'NOT_SENT') {
      return (
        <div className="button send-to-ksef" onClick={() => this.sendInvoiceToKSeF(invoice.id, invoice.company_id)}>{this.context.t('Send to KSeF')}</div>
      )
    } else if (invoice.ksef_status === 'SENT') {
      return (
        <div className="ksef-status sent">{this.context.t('Sent to KSeF')}</div>
      )
    } else if (invoice.ksef_status === 'ERROR') {
      return (
        <div className="ksef-status error ">{this.context.t('Error while sending to KSeF')}</div>
      )
    } else if (invoice.ksef_status === 'ACCEPTED') {
      return (
        <div className="ksef-status accepted">{this.context.t('Archivized in KSeF')}</div>
      )
    } else if (invoice.ksef_status === 'REJECTED') {
      return (
        <div className="ksef-status rejected">{this.context.t('Rejestced by KSeF')}</div>
      )
    }
  }

  sendInvoiceToKSeF = (invoiceId, companyId) => {
    const { cursorWait } = this.state
    if (!cursorWait) {
      this.setState({
        cursorWait: true
      })

      this.props.sendInvoiceToKSeF(invoiceId, companyId, () => {
        this.setState({
          cursorWait: false
        })
      })
    }
  }

  matcheSearch = i => {
    var search = this.state.searchBar.toLocaleLowerCase()
    var matches = false
    var buyerName = i.buyer_name, buyerAddress = i.buyer_address, docNumber = i.doc_number

    if (buyerName && buyerName.toLowerCase().includes(search)) matches = true
    if (!matches && buyerAddress && buyerAddress.toLocaleLowerCase().includes(search)) matches = true
    if (!matches && docNumber && docNumber.toLocaleLowerCase().includes(search)) matches = true
    if (!matches && i.docItems && i.docItems.length !== 0) {
      i.docItems.forEach(possition => {
        if (!matches && possition && possition.name && possition.name.toLocaleLowerCase().includes(search)) matches = true
      })
    }

    return matches
  }

  checkIfMonthHasAnyMatches = month => {
    var docsCountInMonth = month.docs.length

    for (var i = 0; i < docsCountInMonth; i++) {
      if (month.docs[i] && this.matcheSearch(month.docs[i])) return true
    }
    return false
  }

  toggleCheckboxPaid = () => {
    this.setState({
      selectedCheckboxPaid: !this.state.selectedCheckboxPaid
    })
  }

  getSortClass = (sortFieldCheck) => {
    const { invoicesSortField, invoiceSortOrder } = this.props

    if (invoicesSortField === sortFieldCheck) {
      if (invoiceSortOrder === "asc")
        return "icon-sort down"
      return "icon-sort up"
    }

    return "icon-sort"
  }

  formatPrice = amount => {
    amount = amount.toFixed(2)
    if (amount.indexOf('.') > -1) {
      var parts = amount.split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      amount = parts.join('.')
    } else {
      amount = amount.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    return amount
  }

  openSendEmailBox = invoiceData => {
    this.setState({
      showSendInvoiceByEmail: true,
      invoiceToSendData: invoiceData
    })
  }

  closeSendEmailBox = () => {
    this.setState({
      showSendInvoiceByEmail: false,
      invoiceToSendData: {}
    })
  }

  toggleKsefInfoPopup = show => {
    this.setState({
      showKsefInfoPopup: show
    })

    const { user } = this.props
    if (!user.seen_ksef_sales_popup && !show) {
      this.props.hideSalesKsefInfo()
    }
  }

  render() {
    const { searchBar, userHasAccessToCompanySaleInvoices, selectedCheckboxPaid, showInvoiceCreatorOverlay, cursorWait, showProjectsList, currentDir, currentDirSummary, showInvoiceInfoBox, invalidDataFromImport, titleInfoBox, showInvoicePreview, fileBody, fileName, secondPreviewFileId, secondPreviewFileName, secondPreviewFileBody, openPreviewSideBySide, previewInvoiceId, previewInvoice, showImportLoader, totalInvoicesToImport, showCorrectionBuyerForm, editingData, showDeleteInvoicePopup, invoiceToBeDeleted, showImportCurrencyRateOptions, currencyRateOption, showImportCalendar, currencyRateDate, eurRate, showJPK_FAGenerator, showCorrectionItemForm, documentType, isInvoiceFromProforma, isSimilarInvoice,
      showMyItems, showSendInvoiceByEmail, invoiceToSendData, showUnlockWindow, invoiceToUnlock, showRecurringInvoices, showKsefInfoPopup } = this.state;
    const { currentProject, loadingScreen, currentCompany, invoicesList, user, tabIsActive, invoicesSortField, invoiceSortOrder } = this.props;
      
    if (user.new_easydocs_version && (showInvoiceCreatorOverlay || showCorrectionBuyerForm || showCorrectionItemForm)) {
      if (showInvoiceCreatorOverlay) {
        return (
          <div className="sales-container">
            <SalesForm
              hideInvoiceCreatorOverlay={this.hideInvoiceCreatorOverlay}
              editingData={editingData}
              isInvoiceFromProforma={isInvoiceFromProforma}
              isSimilarInvoice={isSimilarInvoice}
              tabIsActive={tabIsActive}
            />
          </div>
        )
      } else if (showCorrectionBuyerForm) {
        return (
          <CorrectionBuyerForm
            toggleShowCorrectionBuyerForm={this.toggleShowCorrectionBuyerForm}
            selectedInvoiceData={this.state.selectedInvoiceData}
            changeCurrentDir={this.changeCurrentDir}
            openInvoicePreview={this.openInvoicePreviewAfterCorrectionGeneration}
            tabIsActive={tabIsActive}
          />
        )
      } else if (showCorrectionItemForm) {
        return (
          <CorrectionItemForm
            toggleShowCorrectionItemForm={this.toggleShowCorrectionItemForm}
            selectedInvoiceData={this.state.selectedInvoiceData}
            openInvoicePreview={this.openInvoicePreviewAfterCorrectionGeneration}
            tabIsActive={tabIsActive}
          />
        )
      }
    } else {
      if (tabIsActive || !user.new_easydocs_version) {
        return (
          <>
            {
              loadingScreen ? (
                <div className="loading-screen">
                  <div className="activity-indicator">
                    <Spinner size={50} speed={0.8} color={"#ffffff"} />
                  </div>
                </div>
              ) : null
            }

            {
              showUnlockWindow ? (
                <UnlockInvoiceBox confirm={this.unlockInvoiceConfirm} cancel={this.unlockInvoiceCancel} invoiceData={invoiceToUnlock} />
              ) : null
            }

            {
              showSendInvoiceByEmail ? (
                <SendEmailBox toggleSendEmailBox={this.closeSendEmailBox} email={""} editedInvoiceId={invoiceToSendData.id} invoiceData={invoiceToSendData} />
              ) : null
            }

            {
              showDeleteInvoicePopup ? (
                <DeleteSaleInvoicePopup invoiceToBeDeleted={invoiceToBeDeleted} close={this.hideDeletePopup} delete={this.confirmInleteInvoice} />
              ) : null
            }

            {
              showImportLoader ? (
                <ImportInvoicePopup totalInvoicesCount={totalInvoicesToImport} currentProject={currentProject} close={this.closeImportLoader} />
              ) : null
            }

            {
              showInvoiceInfoBox ? (
                <InvoiceInfoBox title={titleInfoBox} hideInfoBox={this.hideInfoBox} arrayData={invalidDataFromImport} />
              ) : null
            }

            {
              showInvoicePreview ? (
                <InvoicePreview
                  fileBody={fileBody}
                  fileName={fileName}
                  hideInvoiceCreatorOverlay={this.hideInvoiceCreatorOverlay}
                  hideInvoicePreview={this.hideInvoicePreview}
                  editedInvoiceId={previewInvoiceId}
                  setEditingMode={this.setEditingModeFromPreview}
                  invoiceData={previewInvoice}
                  documentType={documentType}
                  showSideBySide={openPreviewSideBySide}
                  secondPreviewFileId={secondPreviewFileId}
                  secondPreviewFileName={secondPreviewFileName}
                  secondPreviewFileBody={secondPreviewFileBody}
                  openSecondDocPreview={this.openSecondDocPreview}
                  closeSecondDocPreview={this.closeSecondDocPreview}
                />
              ) : null
            }

            {
              showJPK_FAGenerator ? (
                <JPK_FAPopupForm
                  currentCompany={currentCompany}
                  close={this.toggleGenerateJPK_FAPopup}
                />
              ) : null
            }

            {
              showImportCurrencyRateOptions ? (
                <div className={`popup excel-currency-rates-options ${cursorWait ? "cursor-wait" : ""}`}>
                  <div className="popup-card">
                    <div className="title">{this.context.t('Exchange rate from the National Bank of Poland for currency invoices to be taken on:')}</div>
                    <div className="options">
                      <div className={`option ${currencyRateOption === 'PREVIOUS_DAY' ? 'selected' : ''}`} onClick={() => this.setState({ currencyRateOption: 'PREVIOUS_DAY' })}>{this.context.t('the previous working day')}</div>
                      <div className={`option ${currencyRateOption === 'PREVIOUS_MONTH' ? 'selected' : ''}`} onClick={() => this.setState({ currencyRateOption: 'PREVIOUS_MONTH' })}>{this.context.t('last working day of the previous month')}</div>
                      <div className={`option ${currencyRateOption === 'CUSTOM' ? 'selected' : ''}`} onClick={() => this.showCustomCurrencyDateCalendar()}>
                        {
                          currencyRateDate ? (
                            `${this.context.t('specific day')}: ${currencyRateDate}`
                          ) : (
                            this.context.t('specific day')
                          )
                        }
                        {
                          showImportCalendar ? (
                            <div className="currency-calendar import" id="currencyCalendarContainer">
                              <Calendar
                                selectDay={this.selectCurrencyDay}
                                unselectDay={this.selectCurrencyDay}
                                selectedDay={{
                                  display: moment(currencyRateDate).format('LL'),
                                  value: moment(currencyRateDate)
                                }}
                                projectId={"none"}
                                startDate={moment(currencyRateDate) || moment()}
                                arrowsOnLeft={true}
                                inactiveFuture={true}
                                inactiveWeekends={true}
                              />
                            </div>
                          ) : (
                            null
                          )
                        }
                      </div>
                    </div>

                    <div className="upload" onClick={() => this.uploadExcelFile()}>{this.context.t('Generate')}</div>
                  </div>
                </div>
              ) : null
            }

            {
              showCorrectionBuyerForm ? (
                <CorrectionBuyerForm
                  toggleShowCorrectionBuyerForm={this.toggleShowCorrectionBuyerForm}
                  selectedInvoiceData={this.state.selectedInvoiceData}
                  changeCurrentDir={this.changeCurrentDir}
                  openInvoicePreview={this.openInvoicePreviewAfterCorrectionGeneration}
                  tabIsActive={true}
                />
              ) : null
            }

            {
              showCorrectionItemForm ? (
                <CorrectionItemForm
                  toggleShowCorrectionItemForm={this.toggleShowCorrectionItemForm}
                  selectedInvoiceData={this.state.selectedInvoiceData}
                  openInvoicePreview={this.openInvoicePreviewAfterCorrectionGeneration}
                  tabIsActive={true}
                />
              ) : null
            }

            {
              showMyItems ? (
                <InvoiceItemsList
                  closePopup={this.toggleViewMyItems}
                />
              ) : null
            }

            {
              showRecurringInvoices ? (
                <RecurringInvoicesList
                  closePopup={this.toggleViewRecurringInvoices}
                />
              ) : null
            }

            {
              showKsefInfoPopup ? (
                <KsefInfoPopup togglePopup={this.toggleKsefInfoPopup} />
              ) : null
            }

            {
              user.new_easydocs_version && (showCorrectionBuyerForm || showCorrectionItemForm) ? (
                null
              ) : (
                !showInvoiceCreatorOverlay ? (
                  <div className={`sales-container ${cursorWait ? "cursor-wait" : ""}`}>
                    <div className="page-header sale">
                      <div className="page-name sale">{this.context.t("Sales")}</div>
                      <div className="search-bar">
                        <input type="text" name="searchBar" value={searchBar} placeholder={this.context.t('Search sale invoices by contractor, document number or items on the invoice')} onChange={this.onInputChange} />
                      </div>
                    </div>

                    <div className="invoices-list-container">
                      <h1> {this.context.t("Generated invoices")}</h1>
                      {
                        userHasAccessToCompanySaleInvoices ? (
                          <div className="button-container">
                            <div className="generate-jpk-fa" onClick={() => this.toggleGenerateJPK_FAPopup(true)}>
                              {this.context.t("Generate JPK_FA")}
                            </div>
                            <div className="my-items" onClick={() => this.toggleViewMyItems(true)}>{this.context.t("My products/services")}</div>
                            {
                              currentCompany.company_id === 1 || currentCompany.company_id === 1104 ? (
                                <div className="import-invoice">
                                  <input type="file" accept=".xlsx, .xls" onChange={this.uploadHandler} id="file-input" />
                                  <label htmlFor="file-input">
                                    {this.context.t("Import invoices")}
                                  </label>
                                </div>
                              ) : null
                            }
                            <div className="my-items" onClick={() => this.toggleViewRecurringInvoices(true)}>
                              {this.context.t("Recurring invoices")}
                            </div>
                            <div className="generate-new" onClick={() => this.showInvoiceCreatorOverlay()}>
                              {this.context.t("New invoice")}
                            </div>
                          </div>
                        ) : null
                      }

                      {
                        userHasAccessToCompanySaleInvoices && currentCompany.company && currentCompany.company.ksef_integration_id ? (
                          <div className="ksef-info-banner">
                            <div className="check-icon"></div>
                            {this.context.t("{f} has KSeF integration", { f: currentCompany.company.name })}
                            <div className="info-icon" onClick={() => this.toggleKsefInfoPopup(true)}></div>
                          </div>
                        ) : null
                      }

                      {
                        currentDir && currentDir.length > 0 ? (
                          <div className="month-header">
                            <div className="path">
                              <div className="folder-icon"></div>
                              <div className="go-back" onClick={() => this.changeCurrentDir('')}></div>
                              <h2>{currentCompany.company.code}</h2>

                              <div className="sum-row">
                                <div className="month">({this.context.t('Month')}:<span className="value">{currentDir})</span></div>
                                <div className="docs-count">({this.context.t('Documents count')}: <span className="value">{currentDirSummary.docsCount})</span></div>
                                <div className="docs-sum">({this.context.t('VAT sum')}: <span className="value">{this.formatPrice(currentDirSummary.vatSum)} PLN)</span></div>
                                <div className="net-sum"><span className="value">{this.formatPrice(currentDirSummary.netSum)} PLN</span></div>
                                <div className="gross-sum"><span className="value">{this.formatPrice(currentDirSummary.grossSum)} PLN</span></div>
                                <div className="empty-filer"></div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="path"></div>
                        )
                      }
                      {
                        invoicesList.length > 0 ? (
                          <div className="invoices-list">
                            <div className="invoice-header">
                              {
                                currentDir || searchBar.length > 2 ? (
                                  <>
                                    <div className="name header-label" onClick={() => this.props.switchSortingFilter('buyer_name')}>
                                      {this.context.t("Counterparty")}
                                      <span className={this.getSortClass('buyer_name')}></span>
                                    </div>
                                    <div className="doc-number header-label" onClick={() => this.props.switchSortingFilter('doc_number')}>
                                      {this.context.t("Document number")}
                                      <span className={this.getSortClass('doc_number')}></span>
                                    </div>
                                    {
                                      currentCompany.company && currentCompany.company.ksef_integration_id ? (
                                        <div className="doc-number ksef header-label" onClick={() => this.props.switchSortingFilter('ksef_doc_number')}>
                                          {this.context.t("KSeF document status")}
                                          <span className={this.getSortClass('ksef_doc_number')}></span>
                                        </div>
                                      ) : null
                                    }
                                    <div className="issue-date header-label" onClick={() => this.props.switchSortingFilter('issue_date')}>
                                      {this.context.t("Issue date")}
                                      <span className={this.getSortClass('issue_date')}></span>
                                    </div>
                                    <div className="due-date header-label" onClick={() => this.props.switchSortingFilter('due_date')}>
                                      {this.context.t("Due date and status")}
                                      <span className={this.getSortClass('due_date')}></span>
                                    </div>
                                    <div className="price header-label" onClick={() => this.props.switchSortingFilter('total_net_value')}>
                                      {this.context.t("Net value")}
                                      <span className={this.getSortClass('total_net_value')}></span>
                                    </div>
                                    <div className="price header-label" onClick={() => this.props.switchSortingFilter('total_gross_value')}>
                                      {this.context.t("Gross value")}
                                      <span className={this.getSortClass('total_gross_value')}></span>
                                    </div>
                                    <div className="buttons-label header-label">
                                      {this.context.t("Actions")}
                                    </div>
                                    <div className="sent-by-email-label header-label" onClick={() => this.props.switchSortingFilter('sent_to')}>
                                      {this.context.t("Status")}
                                      <span className={this.getSortClass('sent_to')}></span>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="name">{this.context.t("Month")}</div>
                                    <div className="count">{this.context.t("Documents count")}</div>
                                    <div className="price">{this.context.t("Net sum")}</div>
                                    <div className="price">{this.context.t("VAT sum")}</div>
                                    <div className="price">{this.context.t("Gross sum")}</div>
                                  </>
                                )
                              }
                            </div>
                            {
                              invoicesList.map(companyGroup => {
                                if (companyGroup.companyId === currentCompany.company_id) {
                                  return companyGroup.invoices.map((month) => {
                                    if (searchBar.length > 2) {
                                      if (this.checkIfMonthHasAnyMatches(month)) {
                                        return (
                                          <>
                                            <div className="search-folder">{month.directory}
                                            </div>
                                            {
                                              month.docs.map((i, index) => {
                                                if (this.matcheSearch(i)) return this.renderInvoiceRow(i, month.directory)
                                                else return null
                                              })
                                            }
                                          </>
                                        )
                                      } else return null
                                    } else if (!currentDir) {
                                      return (
                                        <div className="invoice-row" onClick={() => this.changeCurrentDir(month.directory, month.docsCount, month.netSum, month.vatSum, month.grossSum)} >
                                          <div className="name folder">{month.directory}</div>
                                          <div className="count">{month.docsCount}</div>
                                          <div className="price">{`${this.formatPrice(month.netSum)} PLN`}</div>
                                          <div className="price">{`${this.formatPrice(month.vatSum)} PLN`}</div>
                                          <div className="price">{`${this.formatPrice(month.grossSum)} PLN`}</div>
                                        </div>
                                      )
                                    } else if (month.directory === currentDir) {
                                      return month.docs.map((i, index) => {
                                        return this.renderInvoiceRow(i, month.directory)
                                      })
                                    } else {
                                      return null;
                                    }
                                  })
                                } else {
                                  return null;
                                }
                              })}
                          </div>
                        ) : (
                          <div className="no-invoices">
                            {this.context.t("You don't have any sales invoices yet")}
                          </div>
                        )
                      }
                    </div>

                    <ReactTooltip id="excel-tooltip" place="top" effect="solid" className="default-tooltip pricing">
                      <p>{this.context.t("Invoice imported from an Excel file")}</p>
                    </ReactTooltip>
                    <ReactTooltip id="paid-tooltip" place="top" effect="solid" className="default-tooltip pricing">
                      <p>{this.context.t(" Paid? ")}</p>
                    </ReactTooltip>
                    <ReactTooltip id="correction-tooltip" place="top" effect="solid" className="default-tooltip pricing">
                      <p>{this.context.t("Correction was issued")}</p>
                    </ReactTooltip>
                    <ReactTooltip id="recurring-tooltip" place="top" effect="solid" className="default-tooltip pricing">
                      <p>{this.context.t("Recurring invoice")}</p>
                    </ReactTooltip>
                  </div>
                ) : (
                  <div className="sales-container">
                    <SalesForm
                      hideInvoiceCreatorOverlay={this.hideInvoiceCreatorOverlay}
                      editingData={editingData}
                      isInvoiceFromProforma={isInvoiceFromProforma}
                      isSimilarInvoice={isSimilarInvoice}
                      tabIsActive={true}
                    />
                  </div>
                )
              )
            }
          </>
        )
      } else {
        return null
      }
    }
  }
}

SalesPage.contextTypes = {
  t: PropTypes.func,
}

const mapStateToProps = (state) => ({
  user: state.User.user,
  userProjects: state.User.userProjects,
  currentProject: state.User.currentProject,
  userCompanies: state.Company.companies,
  currentCompany: state.Company.currentCompany || {},
  loadingScreen: state.File.loadingScreen || false,
  invoicesList: isIterable(state.File.loadedInvoices) ? [...state.File.loadedInvoices] : [],
  invoiceSortOrder: state.File.invoiceSortOrder,
  invoicesSortField: state.File.invoicesSortField
});

const mapDispatchToProps = {
  alertWarn: AlertActions.warning,
  getUserProjects: UserActions.getUserProjects,
  switchDashboardViewModel: UserActions.switchDashboardViewModel,
  setCurrentCompany: CompanyActions.setCurrentCompany,
  getSavedDocItems: CompanyActions.getSavedDocItems,
  downloadGeneratedInvoice: CompanyActions.downloadGeneratedInvoice,
  deleteGeneratedInvoice: CompanyActions.deleteGeneratedInvoice,
  acceptGeneratedSaleInvoice: CompanyActions.acceptGeneratedSaleInvoice,
  unlockSaleInvoice: CompanyActions.unlockSaleInvoice,
  getPrieviosGeneratedInvoices: CompanyActions.getPrieviosGeneratedInvoices,
  importInvoice: CompanyActions.importInvoice,
  getInvoiceImportExcelFile: CompanyActions.getInvoiceImportExcelFile,
  sendInvoiceToKSeF: CompanyActions.sendInvoiceToKSeF,
  tooglePaymentForIncomeDoc: ReportsActions.tooglePaymentForIncomeDoc,
  switchSortingFilter: FileActions.switchInvoiceSortingFilter,
  generateDuplicate: CompanyActions.generateDuplicate,
  hideSalesKsefInfo: UserActions.hideSalesKsefInfo,

  openNewTab: TabsControlerActions.openNewTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesPage);
