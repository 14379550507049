import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { AlertActions } from '../actions/AlertActions'
import { UserActions } from '../actions/UserActions'
import TextareaAutosize from 'react-textarea-autosize'
import PropTypes from 'prop-types'

import validateEmail from '../helpers/validateEmail'

import '../css/ReportError.css'
import '../css/new-style.css'
import checkMark from '../img/check-mark.svg'

const ReportError = ({ }, { t }) => {
    const [data, setData] = useState({
        email: '',
        subject: '',
        description: ''
    })
    const [dontShowNextTime, setDontShowNextTime] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const user = useSelector(state => state.User.user);
    const error = useSelector(state => state.Alert.error);

    const dispatch = useDispatch();

    useEffect(() => {
        if (error.message) {
            setData(prevState => ({
                ...prevState,
                subject: error.message
            }))
        }

        document.body.style.overflow = 'hidden'

        return () => {
            document.body.style.overflow = ''
        }
    }, [])

    useEffect(() => {
        setData(prevState => ({
            ...prevState,
            email: user ? user.email : ''
        }))
    }, [user])

    const onInputChange = (e) => {
        e.persist();
        setData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleChecboxChange = () => {
        setDontShowNextTime(prevState => !prevState)
    }

    const handleHideError = () => {
        dispatch(AlertActions.removeError());
        if (dontShowNextTime) {
            localStorage.setItem('edBlockErrorsReport', true);
        }
    }

    const closeModal = () => {
        dispatch(AlertActions.removeError());
    }

    const handleSumbit = async (e) => {
        e.preventDefault();
        const isValidEmail = validateEmail(data.email)
        let requestError = false;

        if (!isValidEmail) {
            dispatch(AlertActions.warning(t('Please enter valid email.')))
            requestError = true;
        }

        if (data.subject.trim().length === 0) {
            dispatch(AlertActions.warning(t('Please enter subject.')))
            requestError = true;
        }

        if (requestError) return

        if (dontShowNextTime) {
            localStorage.setItem('edBlockErrorsReport', true);
        }

        try {
            const errorToShow = { ...error, url: window.location.href };
            delete errorToShow.img;

            dispatch(UserActions.submitTicket({
                email: data.email,
                subject: data.subject,
                type: 'Błąd!',
                description: `
                ${data.description}

                error:
                ${JSON.stringify(errorToShow)}
                `,
                attachments: [{ base64: error.img, name: 'errorImage.png' }]
            }))
            setIsSent(true);
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div className="report-error__container">
            <div className="report-error">
                <div className="report-error__content">
                    <div className="report-error__header">
                        <h4 className="report-error__title">{isSent ? t('Thank you for help') : t('We noticed an error')}</h4>
                        {
                            isSent ? (
                                <>
                                    <p className="report-error__subtitle">{t("We will do our best to fix this problem")}.</p>
                                    <p className="report-error__subtitle">{t("Further conversation regarding the issue will be sent to your e-mail.")}.</p>
                                </>
                            ) : (
                                <p className="report-error__subtitle">{t("You can help us fix it by submitting a report about this error")}.</p>
                            )
                        }
                    </div>
                    {isSent ? (
                        <>
                            <div className="report-error__image">
                                <img src={checkMark} alt="" />
                            </div>
                            <button className="report-error__cancel-btn report-error__cancel-btn--full" onClick={closeModal}>{t('Close')}</button>
                        </>
                    ) : (
                        <form onSubmit={handleSumbit}>
                            <div className="form-group">
                                <input type="text" value={data.email} readOnly={!!user} onChange={onInputChange} name='email' />
                                <label htmlFor="email">E-mail<span style={{ marginLeft: 3, color: '#D44068' }}>*</span></label>
                            </div>
                            <div className="form-group">
                                <input type="text" value={data.subject} onChange={onInputChange} name='subject' />
                                <label htmlFor="subject">{t('Subject')}<span style={{ marginLeft: 3, color: '#D44068' }}>*</span></label>
                            </div>
                            <div className="form-group">
                                <TextareaAutosize style={{ minHeight: 120 }} type="text" name="description" value={data.description} onChange={onInputChange} placeholder={t("Describe how to repeat the error (optional)")} />
                                <label htmlFor="description">{t('Additional information')}</label>
                            </div>
                            <div className="agree-to-terms first" style={{ position: 'relative' }}>
                                <span className={`checkbox ${dontShowNextTime ? 'checked' : ''}`} onClick={handleChecboxChange}></span>
                                <p onClick={handleChecboxChange} style={{ width: 'fit-content' }}>
                                    {t("Don't show again")}
                                </p>
                            </div>
                            <div class="report-error__buttons">
                                <button type="button" className="report-error__cancel-btn" onClick={handleHideError}>{t('Cancel')}</button>
                                <button type="submit" className="report-error__save-btn">{t('Submit')}</button>
                            </div>
                        </form>
                    )}
                </div>
            </div >
        </div >
    );
}

ReportError.contextTypes = {
    t: PropTypes.func
}

export default ReportError;