import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import TextareaAutosize from 'react-textarea-autosize'

export class DropDownList extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    onInputChange = e => {
        e.preventDefault()
        const { name, value } = e.target

        this.setState({ [name]: value })
    }

    checkIfRowMatchesFilter = row => {
        const { filter } = this.state

        var show = false

        if (filter && row.code && row.description) {
            if (row.code.toLowerCase().includes(filter.toLocaleLowerCase())) show = true
            else if (row.description.toLowerCase().includes(filter.toLocaleLowerCase())) show = true
        } else if (filter && row) {
            if (row.toLowerCase().includes(filter.toLocaleLowerCase())) show = true
        } else {
            show = true
        }

        return show
    }

    render() {
        const { list, selectedOption, headerText } = this.props
        const { filter } = this.state
        return (
            <div className={`drop-down-list`}>
                <div className="header">
                    {headerText}
                </div>
                <div className="filter-row">
                    <input type="text" name="filter" value={filter} onChange={this.onInputChange} placeholder={this.context.t('Search')} autoFocus />
                </div>
                <SimpleBar style={{ height: 'auto', maxHeight: '300px', width: '100%' }}>
                    <ul>
                        {
                            list.map((t, i) => {
                                if (this.checkIfRowMatchesFilter(t)) {
                                    return (
                                        <li className={`tag-item ${selectedOption === t.code || selectedOption === t ? 'selected' : ''}`} onClick={() => this.props.select(t)} key={`row-${i}`}>{t.code ? `${t.code} - ${t.description}` : t}</li>
                                    )
                                } else {
                                    return null
                                }
                            })
                        }
                    </ul>
                </SimpleBar>
            </div>
        )
    }
}

DropDownList.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(DropDownList)