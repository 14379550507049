import React from 'react';

const getFieldStyle = (type) => {
    const columnAvailable = {
        display: 'inline-block',
        textAlign: 'center',
        width: 'auto',
        padding: '3px 5px',
        backgroundColor: '#F7F8FA',
        fontSize: '12px',
        borderRadius: '3px',
        webkitBoxShadow: '0px 3px 6px 0px rgba(19,37,65,0.1)',
        mozBoxShadow: '0px 3px 6px 0px rgba(19,37,65,0.1)',
        boxShadow: '0px 3px 6px 0px rgba(19,37,65,0.1)',
    };

    if (type === 'COLUMN_AVAILABLE') {
        return columnAvailable;
    }
}

const DragPreview = ({ item, translate }) => {
    if (item) {
        return (
            <div style={getFieldStyle('COLUMN_AVAILABLE')} key={item.dataKey}>
                <div>{translate(item.title)}</div>
            </div>
        )
    } else {
        return (<div></div>)
    }
};

export default DragPreview;