import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from 'moment'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import Calendar from "../Calendar";

import { AlertActions } from '../../actions/AlertActions'
import { CompanyActions } from '../../actions/CompanyActions'

import departmentsList from '../../helpers/listUrzedow.json'

export class JPK_FAPopupForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cursorWait: false,
            startDate: moment().startOf('month').format('YYYY-MM-DD'),
            showStartDateCalendar: false,
            endDate: moment().format('YYYY-MM-DD'),
            showEndDateCalendar: false,
            department: {},
            showDepartmentsList: false,
            departmentSearchString: ''
        };
    }

    closeAllSuggestions = (e) => {
        if (this.state.showStartDateCalendar && (e.keyCode === 27 || !this.hasAncestor(e.target, "startDatePickerContainer"))) {
            this.setState({
                showStartDateCalendar: false,
            });
        } else if (this.state.showEndDateCalendar && (e.keyCode === 27 || !this.hasAncestor(e.target, "endDatePickerContainer"))) {
            this.setState({
                showEndDateCalendar: false,
            });
        } else if (this.state.showDepartmentsList && (e.keyCode === 27 || !this.hasAncestor(e.target, "departmentContainer"))) {
            this.setState({
                showDepartmentsList: false,
            });
        }
    }

    hasAncestor = (element, id) => {
        while (element) {
            if (element.id && element.id === id) return true;
            element = element.parentNode;
        }
        return false;
    }

    toggleStartDateCalendar = (show) => {
        this.setState({
            showStartDateCalendar: show
        })
    }

    selectStartDate = date => {
        this.setState({
            startDate: moment(date).format('YYYY-MM-DD'),
            showStartDateCalendar: false
        })
    }

    toggleEndDateCalendar = (show) => {
        this.setState({
            showEndDateCalendar: show
        })
    }

    selectEndDate = date => {
        this.setState({
            endDate: moment(date).format('YYYY-MM-DD'),
            showEndDateCalendar: false
        })
    }

    toggleDepartmentsList = show => {
        this.setState({
            showDepartmentsList: show
        })
    }

    onInputChange = e => {
        e.preventDefault()
        const { name, value } = e.target

        this.setState({ [name]: value })
    }

    checkIfRowMatchesFilter = row => {
        const { departmentSearchString } = this.state

        var show = false

        if (departmentSearchString && row) {
            if (row["MIASTO"] && row["MIASTO"].toLowerCase().includes(departmentSearchString.toLowerCase()))
                show = true
            else if (row["WOJEWÓDZTWO"] && row["WOJEWÓDZTWO"].toLowerCase().includes(departmentSearchString.toLowerCase()))
                show = true
            else if (row["NAZWA URZĘDU"] && row["NAZWA URZĘDU"].toLowerCase().includes(departmentSearchString.toLowerCase()))
                show = true
            else show = false
        } else {
            show = true
        }

        return show
    }

    selectDepartment = department => {
        this.setState({
            department: department,
            showDepartmentsList: false
        })
    }

    generateJPK_FA = () => {
        const { startDate, endDate, department } = this.state
        const { currentCompany } = this.props

        var valid = true
        if (!startDate) {
            valid = false
            this.props.alertWarn(this.context.t('Start date is required '))
        } else if (!endDate) {
            valid = false
            this.props.alertWarn(this.context.t('End date is required '))
        } else if (moment(startDate).diff(moment(endDate), 'days', true) > 0) {
            valid = false
            this.props.alertWarn(this.context.t('Start date must be before end date'))
        }

        if (!department || !department["kod jednostki"]) {
            valid = false
            this.props.alertWarn(this.context.t('Department is required'))
        }

        if (valid) {
            this.setState({
                cursorWait: true
            })

            this.props.generateJPK_FA(currentCompany.company_id, startDate, endDate, department["kod jednostki"], success => {
                this.setState({
                    cursorWait: false
                })

                if (success) {
                    this.props.close()
                }
            })
        }
    }

    render() {
        const { cursorWait, startDate, showStartDateCalendar, endDate, showEndDateCalendar, department, showDepartmentsList, departmentSearchString } = this.state
        const { currentCompany } = this.props
        return (
            <div className={`popup jpk-fa-options ${cursorWait ? "cursor-wait" : ""}`} onClick={e => this.closeAllSuggestions(e)}>
                <div className="popup-card">
                    <div className="hide-button" onClick={() => this.props.close()}></div>
                    <div className="small-title">{this.context.t('JPK_FA generator for {n}', { n: currentCompany?.company?.name || '' })}</div>
                    <div className="title">{this.context.t('Select period and department:')}</div>
                    <div className="options">
                        <div className="form-group half" id="startDatePickerContainer">
                            <input type="text" readOnly={true} name="startDate" value={startDate} onFocus={() => this.toggleStartDateCalendar(true)} />
                            <label htmlFor="startDate" onClick={(e) => this.toggleStartDateCalendar(!showStartDateCalendar)} > {this.context.t("From")} </label>
                            <span className="calendar-icon" onClick={(e) => this.toggleStartDateCalendar(!showStartDateCalendar)} ></span>

                            {
                                showStartDateCalendar ? (
                                    <div className="date-picker">
                                        <Calendar
                                            selectDay={this.selectStartDate}
                                            selectedDay={moment(startDate)}
                                            projectId={"none"}
                                            startDate={moment(startDate) || moment()}
                                            arrowsOnLeft={true}
                                        />
                                    </div>
                                ) : null
                            }
                        </div>

                        <div className="form-group half" id="endDatePickerContainer">
                            <input type="text" readOnly={true} name="endDate" value={endDate} onFocus={() => this.toggleEndDateCalendar(true)} />
                            <label htmlFor="endDate" onClick={(e) => this.toggleEndDateCalendar(!showEndDateCalendar)} > {this.context.t("To")} </label>
                            <span className="calendar-icon" onClick={(e) => this.toggleEndDateCalendar(!showEndDateCalendar)} ></span>

                            {
                                showEndDateCalendar ? (
                                    <div className="date-picker">
                                        <Calendar
                                            selectDay={this.selectEndDate}
                                            selectedDay={moment(endDate)}
                                            projectId={"none"}
                                            startDate={moment(endDate) || moment()}
                                            arrowsOnLeft={true}
                                        />
                                    </div>
                                ) : null
                            }
                        </div>

                        <div className="form-group" id="departmentContainer">
                            <input type="text" readOnly={true} name="endDate" value={department["NAZWA URZĘDU"]} onFocus={() => this.toggleDepartmentsList(true)} />
                            <label htmlFor="endDate" onClick={(e) => this.toggleDepartmentsList(!showEndDateCalendar)} > {this.context.t("Department")} </label>

                            {
                                showDepartmentsList ? (
                                    <div className={`drop-down-list`}>
                                        <div className="filter-row">
                                            <input type="text" name="departmentSearchString" value={departmentSearchString} onChange={this.onInputChange} placeholder={this.context.t('Search')} autoFocus />
                                        </div>
                                        <SimpleBar style={{ height: 'auto', maxHeight: '300px', width: '100%' }}>
                                            <ul>
                                                {
                                                    departmentsList.map((d, i) => {
                                                        if (this.checkIfRowMatchesFilter(d)) {
                                                            return (
                                                                <li className={`department ${department["kod jednostki"] === d["kod jednostki"] ? 'selected' : ''}`} onClick={() => this.selectDepartment(d)} key={`row-${i}`}>
                                                                    <div className="name">{d["NAZWA URZĘDU"]}</div>
                                                                    <div className="address">{`${d["ULICA"]}, ${d["KOD"]} ${d["MIASTO"]}`}</div>
                                                                </li>
                                                            )
                                                        } else {
                                                            return null
                                                        }
                                                    })
                                                }
                                            </ul>
                                        </SimpleBar>
                                    </div>
                                ) : null
                            }
                        </div>
                    </div>

                    <div className="save" onClick={() => this.generateJPK_FA()}>{this.context.t('Generate')}</div>
                </div>
            </div>
        );
    }
}

JPK_FAPopupForm.contextTypes = {
    t: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
});

const mapDispatchToProps = {
    alertWarn: AlertActions.warning,
    generateJPK_FA: CompanyActions.generateJPK_FA,
};

export default connect(mapStateToProps, mapDispatchToProps)(JPK_FAPopupForm);
