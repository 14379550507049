import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CompanyActions } from "../../actions/CompanyActions";

export class InvoiceNumberingSeriesCreator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invoiceMask: "",
            correctionMask: "",
            proformaMask: ""
        };
    }

    onInputChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    render() {
        const { invoiceMask, correctionMask, proformaMask } = this.state
        return (
            <div className="delete-invoice-popup numbering-series">
                <div className="popup-card-container">
                    <div className="popup-card">
                        <div className="header">{this.context.t('You do not have any numbering series for revenue invoices defined yet. Add it now to automatically number your invoices.')}</div>
                        <div className="series-info">
                            <p>{this.context.t("You can create your own numbering series consisting of the following components separated by slashes (\"/\") or save the suggested one.")}</p>
                            <p>{this.context.t("MM - means the month number.")}</p>
                            <p>{this.context.t("YYYY or YY - means the year.")}</p>
                            <p>{this.context.t("FVS - can be any letter abbreviation.")}</p>
                            <p>{this.context.t("00 - means the invoice number if you start with a number other than 00, enter the number from which the system should start numbering.")}</p>
                        </div>
                        <div className="numbering-series">
                            <div className="form-group grow margin-right">
                                <input type="text" name="invoiceMask" id="invoiceMask" value={invoiceMask} onChange={this.onInputChange} placeholder="FVS/00/MM/YYYY" />
                                <label htmlFor="invoiceMask">{this.context.t('Invoice')}</label>
                            </div>
                            <div className="form-group grow margin-right">
                                <input type="text" name="correctionMask" id="correctionMask" value={correctionMask} onChange={this.onInputChange} placeholder="KOR/00/MM/YYYY" />
                                <label htmlFor="correctionMask">{this.context.t('Invoice correction')}</label>
                            </div>
                            <div className="form-group grow">
                                <input type="text" name="proformaMask" id="proformaMask" value={proformaMask} onChange={this.onInputChange} placeholder="PROF/00/MM/YYYY" />
                                <label htmlFor="proformaMask">{this.context.t('Proforma')}</label>
                            </div>
                        </div>
                        <div className="action-buttons">
                            <div className="btn" onClick={e => this.props.close()}>{this.context.t('Cancel')}</div>
                            <div className="btn save" onClick={e => this.props.save(invoiceMask || "FVS/00/MM/YYYY", correctionMask || 'KOR/00/MM/YYYY', proformaMask || 'PROF/00/MM/YYYY')}>{this.context.t('Save')}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

InvoiceNumberingSeriesCreator.contextTypes = {
    t: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceNumberingSeriesCreator);
