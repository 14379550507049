import ibanCountryCodeData from './ibanCountryData'

export const checkIfAccountNumberIsValid = (accountNumber) => {
    const writeAlphabetA_Z = () => {
        const letterArray = [];
        for (let j = 0; j < 26; j++) {
            const letter = String.fromCharCode(j + 65);
            letterArray.push(letter);
        }
        return letterArray;
    };

    const alphabetArray = writeAlphabetA_Z();

    const transformLetterToCodeNumber = (accountNumber) => {
        const startIndex = 10
        const accountNumberArray = [...accountNumber]
        const transformAccountNumber = accountNumberArray.map(sign => {
            if (isNaN(sign)) {
                return alphabetArray.findIndex(letter => letter === sign) + startIndex
            } else return sign
        })
        return transformAccountNumber.join('')
    }

    const firsTwoLetters = /^[A-Z]{2}/

    const validIban = (accountNumber) => {
        const countryCode = accountNumber.match(firsTwoLetters)[0]
        const ibanCountryDataIndex = ibanCountryCodeData.findIndex(country => country.code === countryCode)
        if (ibanCountryDataIndex >= 0) {
            const allSpaces = /\s*/gm
            accountNumber = accountNumber.replace(allSpaces, '')
            accountNumber = accountNumber.replace(firsTwoLetters, '')
            if (accountNumber.length === Number(ibanCountryCodeData[ibanCountryDataIndex].length) - 2) {
                accountNumber = transformLetterToCodeNumber(countryCode) + accountNumber

                var part1 = accountNumber.substring(0, 6)
                var part2 = accountNumber.substring(6, accountNumber.length)
                part2 = transformLetterToCodeNumber(part2)

                var numbertToCheck = part2 + part1
                var index = 0
                var currentNumber = 0
                var length = numbertToCheck.length

                while (index < length) {
                    currentNumber *= 10
                    currentNumber += parseInt(numbertToCheck[index])

                    if (currentNumber > 97) {
                        currentNumber %= 97
                    }
                    index++
                }
                if (currentNumber === 1) return true
                else return false
            } else {
                return false
            }
        } else return false
    }

    if (accountNumber) {
        if (firsTwoLetters.test(accountNumber)) {
            return validIban(accountNumber)
        } else {
            accountNumber = 'PL' + accountNumber
            return validIban(accountNumber)
        }
    } else return true
}