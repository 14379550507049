import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Spinner } from 'react-activity'
import { CompanyActions } from "../../actions/CompanyActions";
import { KsefActions } from "../../actions/KsefActions";
import { AlertActions } from "../../actions/AlertActions";
import ReactTooltip from 'react-tooltip'

import "../../../src/css/ksef.css";

export class KsefIntegrationWindow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cursorWait: false,

            waitingForScope: true,
            accessToInvoices: false,
            issuingInvoices: false,
            grantingPermissions: false,
            readingPermissions: false,

            showDisableConfirmation: false
        }
    }

    componentDidMount() {
        const { ksefIf, companyId } = this.props
        this.props.getCurrentKsefScope(ksefIf, companyId, scope => {
            if (scope) {
                this.setState({
                    waitingForScope: false,
                    accessToInvoices: scope.access_to_invoices,
                    issuingInvoices: scope.issuing_invoices,
                    grantingPermissions: scope.granting_permissions,
                    readingPermissions: scope.reading_permissions
                })
            }
        })
    }

    handleClick = (event) => {
    }

    componentWillUnmount() {
        this.updateKsefIntegration()
    }

    onInputChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;

        this.setState({ [name]: value });
    }

    updateKsefIntegration = () => {
        const { accessToInvoices, issuingInvoices, grantingPermissions, readingPermissions } = this.state
        const { ksefIf, companyId } = this.props

        this.props.updateIntegration(ksefIf, companyId, {
            accessToInvoices, issuingInvoices, grantingPermissions, readingPermissions
        })
    }

    disableKsefIntegration = () => {
        const { cursorWait } = this.state
        const { ksefIf, companyId } = this.props
        if (!cursorWait) {
            this.setState({
                cursorWait: true
            })
            this.props.deleteIntegration(ksefIf, companyId, success => {
                if (success) {
                    this.props.close()
                }
            })
        }
    }

    cancelDisablingIntegration = () => {
        const { cursorWait } = this.state
        if (!cursorWait) {
            this.setState({
                showDisableConfirmation: false
            })
        }
    }

    cancel = () => {
        const { cursorWait } = this.state
        if (!cursorWait) this.props.close()
    }

    render() {
        const { cursorWait, waitingForScope, accessToInvoices, issuingInvoices, grantingPermissions, readingPermissions, showDisableConfirmation } = this.state
        return (
            <div className={`ksef-popup ${cursorWait ? 'cursor-wait' : ''} ${showDisableConfirmation ? 'no-background' : ''}`}>
                {
                    showDisableConfirmation ? (
                        <div className="disable-popup">
                            <div className="ksef-window disable-window">
                                <h3>{this.context.t("Do you confirm that you want to disable integration with KSeF?")}</h3>

                                <div className="action-buttons">
                                    <div className="button cancel" onClick={() => this.cancelDisablingIntegration()}>{this.context.t('Cancel')}</div>
                                    <div className="button save" onClick={() => this.disableKsefIntegration()}>{this.context.t('Yes, disable KSeF integration')}</div>
                                </div>
                            </div>
                        </div>
                    ) : null
                }
                <div className="ksef-window">
                    <h3>
                        {this.context.t('KSeF integration edition')}
                        <div className="close-button" onClick={() => this.cancel()}></div>
                    </h3>

                    <div className="step-section">
                        <p className="primary">
                            {this.context.t('Scope of authorization')}
                        </p>
                        <p className="secondary">
                            {this.context.t('The roles assigned in KSeF by an authorized person should be indicated')}
                        </p>

                        <div className="authorization">

                            <div className={`checkbox-group ${waitingForScope ? 'loading-scope' : ''}`}>
                                {
                                    waitingForScope ? (
                                        <div className="activity-indicator">
                                            <Spinner color={'#69B792'} size={13} speed={0.8} />
                                        </div>
                                    ) : (
                                        <div className={`checkbox ${accessToInvoices ? 'checked' : ''}`} onClick={() => this.setState({ accessToInvoices: !accessToInvoices })}></div>
                                    )
                                }
                                <div className="label" onClick={() => this.setState({ accessToInvoices: !accessToInvoices })}>{this.context.t("Access to invoices")}</div>
                            </div>
                            <div className={`checkbox-group ${waitingForScope ? 'loading-scope' : ''}`}>
                                {
                                    waitingForScope ? (
                                        <div className="activity-indicator">
                                            <Spinner color={'#69B792'} size={13} speed={0.8} />
                                        </div>
                                    ) : (
                                        <div className={`checkbox ${issuingInvoices ? 'checked' : ''}`} onClick={() => this.setState({ issuingInvoices: !issuingInvoices })}></div>
                                    )
                                }
                                <div className="label" onClick={() => this.setState({ issuingInvoices: !issuingInvoices })}>{this.context.t("Issuing invoices")}</div>
                            </div>
                            <div className={`checkbox-group ${waitingForScope ? 'loading-scope' : ''}`}>
                                {
                                    waitingForScope ? (
                                        <div className="activity-indicator">
                                            <Spinner color={'#69B792'} size={13} speed={0.8} />
                                        </div>
                                    ) : (
                                        <div className={`checkbox ${grantingPermissions ? 'checked' : ''}`} onClick={() => this.setState({ grantingPermissions: !grantingPermissions })}></div>
                                    )
                                }
                                <div className="label" onClick={() => this.setState({ grantingPermissions: !grantingPermissions })}>{this.context.t("Granting permissions")}</div>
                            </div>
                            <div className={`checkbox-group ${waitingForScope ? 'loading-scope' : ''}`}>
                                {
                                    waitingForScope ? (
                                        <div className="activity-indicator">
                                            <Spinner color={'#69B792'} size={13} speed={0.8} />
                                        </div>
                                    ) : (
                                        <div className={`checkbox ${readingPermissions ? 'checked' : ''}`} onClick={() => this.setState({ readingPermissions: !readingPermissions })}></div>
                                    )
                                }
                                <div className="label" onClick={() => this.setState({ readingPermissions: !readingPermissions })}>{this.context.t("Reading permissions")}</div>
                            </div>
                        </div>
                    </div >

                    <div className="step-section disable">
                        <p className="primary">
                            {this.context.t('Disable integration with KSeF')}
                        </p>
                        <p className="secondary">
                            {this.context.t('After turning off integration, you will not be able to send or download invoices from KSeF')}
                        </p>

                        <div className="action-buttons">
                            <div className="button save" onClick={() => this.setState({ showDisableConfirmation: true })}>{this.context.t('Disable')}</div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

KsefIntegrationWindow.contextTypes = {
    t: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
    currentCompany: state.Company.currentCompany,
});

const mapDispatchToProps = {
    alertWarn: AlertActions.warning,
    getCurrentKsefScope: KsefActions.getCurrentKsefScope,
    updateIntegration: KsefActions.updateIntegration,
    deleteIntegration: KsefActions.deleteIntegration,
};

export default connect(mapStateToProps, mapDispatchToProps)(KsefIntegrationWindow);
