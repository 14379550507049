import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Spinner, Bounce } from 'react-activity'
import 'react-activity/dist/react-activity.css'
import { history } from "../../../helpers/history"
import routes from "../../../helpers/routes"
import moment from 'moment'
import TextareaAutosize from 'react-textarea-autosize'
import ReactGA from 'react-ga';
import throttle from "lodash.throttle"
import ReactTooltip from 'react-tooltip'

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import bankIdentification from '../../../helpers/bankIdentification'
import { replaceSpecialCharacters } from '../../../helpers/textSlugify'
import { vatIdValidate } from '../../../helpers/vatIdValidate'
import { UserActions } from '../../../actions/UserActions'
import { CompanyActions } from '../../../actions/CompanyActions'
import { ReportsActions } from '../../../actions/ReportsActions'
import { AlertActions } from '../../../actions/AlertActions'
import OverlayInstructions from '../../../components/OverlayInstructions'
import SettingsTabsBar from '../../../components/SettingsTabsBar'

import arrayObjectIndexOf from '../../../helpers/indexOfObject';
import { emailRegex } from '../../../helpers/regexList'

import '../../../css/CounterpartiesPage.css'

export class CompanyDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            searchString: '',

            newCounterpartyPopup: false,
            newCounterpartyName: '',
            newCounterpartyEmail: '',
            newCounterpartyCode: '',
            newCounterpartyVatId: '',
            newCounterpartyAddress: '',
            newCounterpartyPaymentDue: '',

            showCounterpartyEditPopup: false,
            counterpartyVatIdToEdit: '',
            counterpartyIdToEdit: -1,
            counterpartyNameToEdit: '',
            counterpartyEmailToEdit: '',
            counterpartyCodeToEdit: '',
            counterpartAddressToEdit: '',
            counterpartCountryToEdit: '',
            counterpartPaymentDueToEdit: '',
            editAllFiles: true,
            counterpartyCodeEdited: false,

            selectedForMerge: [],
            counterpartyNameToMerge: '',
            counterpartyCodeToMerge: '',
            counterpartyVatIdToMerge: '',

            showImportCounterpartiesSummary: false,
            newImportedCounterparties: [],
            notImportedCounterparties: []
        }
    }

    componentDidMount() {
        window.addEventListener("keydown", this.escFunction, false)
        window.addEventListener("resize", throttle(this.setOverlaySize, 100))
        //ReactGA.pageview(window.location.pathname + window.location.search)
        const { userProjects, currentCompany, userCompanies } = this.props
        if (currentCompany && currentCompany.company) {
            var dashboardProjectId = parseInt(localStorage.getItem('dashboardProject')) || -1
            var projectIndex = arrayObjectIndexOf(userProjects, dashboardProjectId, 'id')
            if (projectIndex > -1 && currentCompany.id !== userProjects[projectIndex].company_id) {
                var companyIndex = arrayObjectIndexOf(userCompanies, userProjects[projectIndex].company_id, 'company_id')
                console.log(companyIndex)
                if (companyIndex > -1) {
                    if (userCompanies[companyIndex].user_role === 'ADMIN') {
                        this.props.setCurrentCompany(userCompanies[companyIndex])
                        this.props.getAllCompanyCounterparties(userProjects[projectIndex].company_id)
                    }
                }
            } else {
                this.props.getAllCompanyCounterparties(currentCompany.company.id)
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.currentCompany !== prevProps.currentCompany) {
            this.props.getAllCompanyCounterparties(this.props.currentCompany.company.id)
        }

        if (!this.state.gotOverlayPossitions) {
            const { companySelectionRef, searchBarRef, counterpartiesListRef } = this.refs
            const scrollBarWidth = window.innerWidth - document.body.offsetWidth
            if (companySelectionRef && searchBarRef && counterpartiesListRef) {
                var holesToDraw = []
                var companySelectionBox = companySelectionRef.getBoundingClientRect()
                if (companySelectionBox) {
                    holesToDraw.push({
                        x: companySelectionBox.x + scrollBarWidth,
                        y: companySelectionBox.y,
                        w: companySelectionBox.width,
                        h: companySelectionBox.height
                    })
                }

                var searchBarBox = searchBarRef.getBoundingClientRect()
                if (searchBarBox) {
                    holesToDraw.push({
                        x: searchBarBox.x,
                        y: searchBarBox.y,
                        w: searchBarBox.width + scrollBarWidth,
                        h: searchBarBox.height
                    })
                }

                var counterpartiesListBox = counterpartiesListRef.getBoundingClientRect()
                if (counterpartiesListBox) {
                    holesToDraw.push({
                        x: counterpartiesListBox.x,
                        y: counterpartiesListBox.y,
                        w: counterpartiesListBox.width + scrollBarWidth,
                        h: counterpartiesListBox.height
                    })
                }

                if (holesToDraw.length === 3) {
                    console.log(holesToDraw)
                    this.setState({
                        gotOverlayPossitions: true,
                        overlayPossitions: holesToDraw
                    })
                }
            }
        }

        ReactTooltip.rebuild();
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.escFunction, false)
        window.removeEventListener("resize", throttle(this.setOverlaySize, 100))
    }

    escFunction = (e) => {
        if (e.keyCode === 27) {
            if (this.state.showCompaniesList) {
                this.setState({
                    showCompaniesList: false
                })
            } else if (this.state.showCounterpartyEditPopup) {
                this.setState({
                    showCounterpartyEditPopup: false,
                    counterpartyIdToEdit: -1,
                    counterpartyVatIdToEdit: '',
                    counterpartyNameToEdit: '',
                    counterpartyEmailToEdit: '',
                    counterpartyCodeToEdit: '',
                    counterpartAddressToEdit: '',
                    counterpartCountryToEdit: '',
                    counterpartPaymentDueToEdit: '',
                })
            } else if (this.state.newCounterpartyPopup) {
                this.setState({
                    newCounterpartyPopup: false,
                    newCounterpartyName: '',
                    newCounterpartyEmail: '',
                    newCounterpartyCode: '',
                    newCounterpartyVatId: '',
                    newCounterpartyAddress: '',
                    newCounterpartyPaymentDue: ''
                })
            }
        }
    }

    toggleCompaniesList = () => {
        this.setState({
            showCompaniesList: !this.state.showCompaniesList
        })
    }

    changeCurrentCompany = (e, index) => {
        e.preventDefault()
        console.log(index)

        this.props.setCurrentCompany(this.props.userCompanies[index])

        this.setState({
            currentProject: {},
            showCompaniesList: false,
        })
    }

    setOverlaySize = () => {
        const { companySelectionRef, searchBarRef, counterpartiesListRef } = this.refs
        const scrollBarWidth = window.innerWidth - document.body.offsetWidth
        if (companySelectionRef && searchBarRef && counterpartiesListRef) {
            var holesToDraw = []
            var companySelectionBox = companySelectionRef.getBoundingClientRect()
            if (companySelectionBox) {
                holesToDraw.push({
                    x: companySelectionBox.x + scrollBarWidth,
                    y: companySelectionBox.y,
                    w: companySelectionBox.width,
                    h: companySelectionBox.height
                })
            }

            var searchBarBox = searchBarRef.getBoundingClientRect()
            if (searchBarBox) {
                holesToDraw.push({
                    x: searchBarBox.x,
                    y: searchBarBox.y,
                    w: searchBarBox.width + scrollBarWidth,
                    h: searchBarBox.height
                })
            }

            var counterpartiesListBox = counterpartiesListRef.getBoundingClientRect()
            if (counterpartiesListBox) {
                holesToDraw.push({
                    x: counterpartiesListBox.x,
                    y: counterpartiesListBox.y,
                    w: counterpartiesListBox.width + scrollBarWidth,
                    h: counterpartiesListBox.height
                })
            }

            if (holesToDraw.length === 3) {
                console.log(holesToDraw)
                this.setState({
                    gotOverlayPossitions: true,
                    overlayPossitions: holesToDraw
                })
            }
        }
    }

    showOverlay = () => {
        const { overlayPreferance } = this.props
        this.setOverlaySize()
        overlayPreferance['adminCounterparties'] = true
        this.props.toggleOverlay(overlayPreferance)
    }

    onInputChange = e => {
        const { currentCompany } = this.props
        e.preventDefault()

        var { name, value } = e.target

        if (name === 'counterpartyCodeToEdit') {
            value = value.replace(/[^0-9A-Za-z\-\_]/gm, '')

            const { counterparties } = this.props
            if (counterparties.find(c => c.id === this.state.counterpartyIdToEdit) && counterparties.find(c => c.id === this.state.counterpartyIdToEdit).code !== value) {
                this.setState({
                    counterpartyCodeEdited: true
                })
            } else {
                this.setState({
                    counterpartyCodeEdited: false
                })
            }
        } else if (name === "counterpartPaymentDueToEdit" || name === 'newCounterpartyPaymentDue') {
            value = value.replace(/[^0-9]/gm, '')
        }

        this.setState({ [name]: value })

        if (name === 'newCounterpartyVatId') {
            if (value && value.replace(/[^0-9]/gm).length === 10) {
                if (vatIdValidate(value)) {
                    fetch(`https://wl-api.mf.gov.pl/api/search/nip/${value.replace(/[^0-9]/gi, '')}?date=${moment().format("YYYY-MM-DD")}`, { method: 'get' })
                        .then(response => response.json())
                        .then(json => {
                            console.log(json)
                            if (json && json.result && json.result.subject) {
                                var comapnyName = json.result.subject.name
                                var companyCode = this.generateCode(comapnyName)

                                companyCode = replaceSpecialCharacters(companyCode)

                                this.setState({
                                    newCounterpartyName: comapnyName,
                                    newCounterpartyCode: companyCode,
                                    newCounterpartyAddress: json.result.subject.residenceAddress || json.result.subject.workingAddress
                                })
                            }
                        })
                        .catch(err => {
                            console.log('error when fetching companyName')
                        })
                }
            }
        }
    }

    generateCode = (name) => {
        name = name.replace(/SPÓŁKA KOMANDYTOWA/gi, '').replace(/SPÓŁKA CYWILNA/gi, '').replace(/SPÓŁKA AKCYJNA/gi, '').replace(/SPÓŁKA DORADZTWA PODATKOWEGO/gi, '').replace(/SPÓŁKA PARTNERSKA/gi, '').replace(/SPÓŁKA JAWNA/gi, '').replace(/SP. Z O.O./gi, '').replace(/SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ/gi, '').replace(/SP. Z 0.0./gi, '').replace(/POLSKA/gi, '').replace(/\'/gm, '').replace(/\(/gm, '').replace(/\)/gm, '').replace(' - ', '-').trim()
        var indexOfComma = name.indexOf(',')

        if (indexOfComma > 0) {
            var tempName = name.substring(0, indexOfComma)
            tempName = tempName.trim()

            if ((tempName.match(/\"/gm) || []).length !== 1) {
                name = tempName
            }
        }

        if (name.includes('"')) {
            var newName = ((name.match(/\"(.*)\"/g)) || [])
            newName = newName.find(x => x !== undefined)

            if (newName) {
                newName = newName.replace(/\"/gm, '')
                newName = newName.trim()
                var wordsArray = newName.split(' ')
                if (wordsArray.length === 1) {
                    newName = wordsArray[0]
                    var indexOfDash = newName.lastIndexOf('-')
                    if (indexOfDash > 0 && newName.length > 20) {
                        newName = newName.substring(0, indexOfDash)

                        return newName.substring(0, 20)
                    } else {
                        return newName.substring(0, 20)
                    }
                } else {
                    var code = ''
                    wordsArray.forEach(w => {
                        if (w.length > 2 && !w.match(/^\d/)) {
                            code += w[0]
                        } else {
                            code += w
                        }
                    })

                    return code
                }
            } else {
                return ''
            }
        } else {
            var wordsArray = name.split(' ')
            if (wordsArray.length === 1) {
                newName = wordsArray[0]
                var indexOfDash = newName.lastIndexOf('-')
                if (indexOfDash > 0) {
                    newName = newName.substring(0, indexOfDash)

                    return newName.substring(0, 20)
                } else {
                    return newName.substring(0, 20)
                }
            } else if (wordsArray.length === 2) {
                var part1 = wordsArray[0]
                var part2 = wordsArray[1]

                if (part1.length + part2.length <= 19) {
                    return part1 + '-' + part2
                } else {
                    if (part1.includes('-')) {
                        return part1
                    } else if (part2.includes('-')) {
                        return part2
                    } else {
                        return part1 + '-' + part2.substring(0, 19 - part1.length)
                    }
                }
            } else {
                var code = ''
                wordsArray.forEach(w => {
                    if (w.length > 2 && !w.match(/^\d/)) {
                        code += w[0]
                    } else {
                        code += w
                    }
                })

                return code
            }
        }
    }

    closeAllSugestionLists = e => {
    }

    hasAncestor = (element, id) => {
        while (element) {
            if (element.id && element.id === id) return true

            element = element.parentNode
        }
        return false
    }

    getCounterpartiesSortClass = (sortFieldCheck) => {
        const { counterpartiesSortField, counterpartiesSortOrder } = this.props

        if (counterpartiesSortField === sortFieldCheck) {
            if (counterpartiesSortOrder === "asc")
                return "icon-sort down"
            return "icon-sort up"
        }

        return "icon-sort"
    }

    checkIfMatchesFilter = counteroarty => {
        const { searchString } = this.state

        if (searchString !== '') {
            if (counteroarty.name && counteroarty.name.toLowerCase().includes(searchString.toLowerCase())) {
                return true
            } else if (counteroarty.code && counteroarty.code.toLowerCase().includes(searchString.toLowerCase())) {
                return true
            } else if (counteroarty.vat_id && counteroarty.vat_id.toLowerCase().includes(searchString.toLowerCase())) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }

    openCounterpartyEditPopup = (counterpartyId, counterpartyVatId, counterpartyName, counterpartyCode, counterpartyAddress, counterpatyCountry, counterpartyEmail, counterpartPaymentDue) => {
        this.setState({
            showCounterpartyEditPopup: true,
            counterpartyIdToEdit: counterpartyId,
            counterpartyVatIdToEdit: counterpartyVatId ? counterpartyVatId.trim() : '',
            counterpartyNameToEdit: counterpartyName ? counterpartyName.trim() : '',
            counterpartyEmailToEdit: counterpartyEmail ? counterpartyEmail.join(", ") : '',
            counterpartyCodeToEdit: counterpartyCode ? counterpartyCode.trim() : '',
            counterpartAddressToEdit: counterpartyAddress ? counterpartyAddress.trim() : '',
            counterpartCountryToEdit: counterpatyCountry,
            counterpartPaymentDueToEdit: counterpartPaymentDue ? counterpartPaymentDue : ''
        })
    }

    hideCounterpartyEditPopup = () => {
        this.setState({
            showCounterpartyEditPopup: false,
            counterpartyIdToEdit: -1,
            counterpartyVatIdToEdit: '',
            counterpartyNameToEdit: '',
            counterpartyEmailToEdit: '',
            counterpartyCodeToEdit: '',
            counterpartAddressToEdit: '',
            counterpartCountryToEdit: '',
            counterpartPaymentDueToEdit: ''
        })
    }

    hideNewCounterpartyPopup = () => {
        this.setState({
            newCounterpartyPopup: false,
            newCounterpartyName: '',
            newCounterpartyEmail: '',
            newCounterpartyCode: '',
            newCounterpartyVatId: '',
            newCounterpartyAddress: '',
            newCounterpartyPaymentDue: ''
        })
    }

    addNewCounterparty = () => {
        const { newCounterpartyVatId, newCounterpartyName, newCounterpartyEmail, newCounterpartyCode, newCounterpartyAddress, newCounterpartyPaymentDue } = this.state
        const { currentCompany } = this.props
        var valid = true

        if (newCounterpartyName.length === 0) {
            this.props.alertWarn(this.context.t('Counterparty name is required.'))
            valid = false
        }

        if (newCounterpartyCode.length === 0) {
            this.props.alertWarn(this.context.t('Counterparty code is required.'))
            valid = false
        }

        var emailsToSave = []
        if (newCounterpartyEmail) {
            var emailsArray = newCounterpartyEmail.split(",")
            emailsArray.map(emailAddress => {
                if (emailAddress && !emailRegex.test(emailAddress.trim())) {
                    valid = false
                    this.props.alertWarn(this.context.t('E-mail is not valid.'))
                }
            })
            emailsToSave = emailsArray
        }

        if (valid) {
            this.props.addCounterparty(currentCompany.company_id, newCounterpartyVatId, newCounterpartyName, emailsToSave, newCounterpartyCode, newCounterpartyAddress, "", newCounterpartyPaymentDue, (success) => {
                if (success) {
                    this.setState({
                        newCounterpartyPopup: false,
                        newCounterpartyName: '',
                        newCounterpartyEmail: '',
                        newCounterpartyCode: '',
                        newCounterpartyVatId: '',
                        newCounterpartyAddress: '',
                        newCounterpartyPaymentDue: ''
                    })
                }
            })
        }
    }

    saveCounterpartyChanges = (e) => {
        e.preventDefault()
        const { counterpartyIdToEdit, counterpartyVatIdToEdit, counterpartyNameToEdit, counterpartyEmailToEdit, counterpartyCodeToEdit, counterpartAddressToEdit, counterpartCountryToEdit, counterpartPaymentDueToEdit, editAllFiles, counterpartyCodeEdited } = this.state
        const { currentCompany } = this.props
        var valid = true
        if (counterpartyNameToEdit.length === 0) {
            this.props.alertWarn(this.context.t('Counterparty name is required.'))
            valid = false
        }

        if (counterpartyCodeToEdit.length === 0) {
            this.props.alertWarn(this.context.t('Counterparty code is required.'))
            valid = false
        }

        var emailsToSave = []
        if (counterpartyEmailToEdit) {
            var emailsArray = counterpartyEmailToEdit.split(",")
            emailsArray.map(emailAddress => {
                if (emailAddress && !emailRegex.test(emailAddress.trim())) {
                    valid = false
                    this.props.alertWarn(this.context.t('E-mail is not valid.'))
                }
            })
            emailsToSave = emailsArray
        }

        if (valid) {
            this.props.updateCounterparty(currentCompany.company_id, counterpartyIdToEdit, counterpartyVatIdToEdit, counterpartyNameToEdit, emailsToSave, counterpartyCodeToEdit, counterpartAddressToEdit, counterpartCountryToEdit, counterpartPaymentDueToEdit, editAllFiles && counterpartyCodeEdited, (success) => {
                if (success) {
                    this.setState({
                        showCounterpartyEditPopup: false,
                        counterpartyIdToEdit: -1,
                        counterpartyVatIdToEdit: '',
                        counterpartyNameToEdit: '',
                        counterpartyEmailToEdit: '',
                        counterpartyCodeToEdit: '',
                        counterpartAddressToEdit: '',
                        counterpartCountryToEdit: '',
                        counterpartPaymentDueToEdit: '',
                        editAllFiles: true,
                        counterpartyCodeEdited: false,
                    })
                }
            })
        }
    }

    toggleCounterpartyForMerge = (counterpartyId, name, code, vatId) => {
        var { selectedForMerge, counterpartyNameToMerge, counterpartyCodeToMerge, counterpartyVatIdToMerge } = this.state

        console.log(counterpartyVatIdToMerge)
        console.log(vatId)

        if (selectedForMerge.includes(counterpartyId)) {
            selectedForMerge = selectedForMerge.filter(c => c !== counterpartyId)
        } else {
            selectedForMerge.push(counterpartyId)
        }

        if (counterpartyNameToMerge === '' && name) counterpartyNameToMerge = name
        if (counterpartyCodeToMerge === '' && code) counterpartyCodeToMerge = code
        if (counterpartyVatIdToMerge === '' && vatId) counterpartyVatIdToMerge = vatId

        if (selectedForMerge.length === 0) {
            counterpartyNameToMerge = ''
            counterpartyCodeToMerge = ''
            counterpartyVatIdToMerge = ''
        }

        this.setState({
            selectedForMerge: selectedForMerge,
            counterpartyNameToMerge: counterpartyNameToMerge,
            counterpartyCodeToMerge: counterpartyCodeToMerge,
            counterpartyVatIdToMerge: counterpartyVatIdToMerge
        })
    }

    mergeCounterpartiesClick = () => {
        this.setState({
            showCounterpatyMergePopup: true
        })
    }

    cancelCounterpartiesMerge = () => {
        this.setState({
            showCounterpatyMergePopup: false
        })
    }

    mergeCounterpartiesConfirm = (e) => {
        e.preventDefault()
        var { selectedForMerge, counterpartyNameToMerge, counterpartyCodeToMerge, counterpartyVatIdToMerge } = this.state
        const { currentCompany } = this.props

        var valid = true
        if (counterpartyNameToMerge.length === 0) {
            this.props.alertWarn(this.context.t('Counterparty name is required.'))
            valid = false
        }

        if (counterpartyCodeToMerge.length === 0) {
            this.props.alertWarn(this.context.t('Counterparty code is required.'))
            valid = false
        }

        if (valid) {
            this.props.mergeCounterparties(currentCompany.company_id, selectedForMerge, counterpartyNameToMerge, counterpartyCodeToMerge, counterpartyVatIdToMerge, success => {
                if (success) {
                    this.setState({
                        showCounterpatyMergePopup: false,
                        selectedForMerge: [],
                        counterpartyNameToMerge: '',
                        counterpartyCodeToMerge: '',
                        counterpartyVatIdToMerge: ''
                    })
                }
            })
        }
    }

    blobToBase64(blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    uploadHandler = async (event) => {
        const currentFile = event.target.files[0];
        const myResult = {};
        myResult.name = currentFile.name;
        myResult.size = currentFile.size;
        myResult.type = currentFile.type;
        myResult.base64 = await this.blobToBase64(currentFile);
        this.props.importCounterparties(
            myResult,
            this.props.currentCompany.company_id,
            (newImportedCounterparties, notImportedCounterparties) => {
                this.setState({
                    showImportCounterpartiesSummary: true,
                    newImportedCounterparties: newImportedCounterparties || [],
                    notImportedCounterparties: notImportedCounterparties || []
                })
            }
        );
    };

    closeImportSummary = () => {
        this.setState({
            showImportCounterpartiesSummary: false,
            newImportedCounterparties: [],
            notImportedCounterparties: []
        })
    }

    toggleAddCounterpartyPopup = () => {
        this.setState({
            newCounterpartyPopup: !this.newCounterpartyPopup
        })
    }

    render() {
        const { searchString, showCompaniesList, gotOverlayPossitions, overlayPossitions, showCounterpartyEditPopup, newCounterpartyPopup, newCounterpartyName, newCounterpartyEmail, newCounterpartyCode, newCounterpartyVatId, newCounterpartyAddress, newCounterpartyPaymentDue, counterpartyVatIdToEdit, counterpartyNameToEdit, counterpartyEmailToEdit, counterpartyCodeToEdit, counterpartAddressToEdit, counterpartPaymentDueToEdit, editAllFiles, counterpartyCodeEdited, selectedForMerge, counterpartyNameToMerge, counterpartyCodeToMerge, counterpartyVatIdToMerge, showCounterpatyMergePopup, showImportCounterpartiesSummary, newImportedCounterparties, notImportedCounterparties } = this.state
        const { currentCompany, userCompanies, overlayPreferance, user, tabIsActive, loadingScreen, counterparties } = this.props

        if (tabIsActive || !user.new_easydocs_version) {
            if (currentCompany) {
                return (
                    <div className="counterparties-container" ref="companyContainer" onClick={e => this.closeAllSugestionLists(e)}>

                        <div className='show-overlay' onClick={() => this.showOverlay()} data-for="info-context-overlay" data-tip="show"></div>
                        {
                            gotOverlayPossitions && overlayPreferance['adminCounterparties'] ? (
                                <OverlayInstructions possitions={overlayPossitions} name="adminCounterparties" height={this.refs.companyContainer.getBoundingClientRect().height + 20 + 110} />
                            ) : (
                                null
                            )
                        }

                        {
                            newCounterpartyPopup ? (
                                <div className="popup">
                                    <div className="card">
                                        <form onSubmit={this.saveCounterpartyChanges}>

                                            <div className='form-group'>
                                                <TextareaAutosize
                                                    type="text"
                                                    name="newCounterpartyVatId"
                                                    id="newCounterpartyVatId"
                                                    value={newCounterpartyVatId}
                                                    onChange={e => this.onInputChange(e)}
                                                    autoComplete='off'
                                                    maxRows={5}
                                                />
                                                <label htmlFor="newCounterpartyVatId">{this.context.t('Vat ID')}</label>
                                                <span className="info" data-tip="show" data-for="vatIdTooltip"></span>
                                                <ReactTooltip id="vatIdTooltip" className="default-tooltip info-tooltip" place="bottom" effect="solid">
                                                    <p>{this.context.t('Enter the NIP number and we will complete the remaining data from GUS database.')}</p>
                                                </ReactTooltip>
                                            </div>

                                            <div className='form-group'>
                                                <TextareaAutosize
                                                    type="text"
                                                    name="newCounterpartyName"
                                                    id="newCounterpartyName"
                                                    value={newCounterpartyName}
                                                    onChange={e => this.onInputChange(e)}
                                                    autoComplete='off'
                                                    maxRows={5}
                                                />
                                                <label htmlFor="newCounterpartyName">{this.context.t('Counterparty name')}</label>
                                            </div>

                                            <div className='form-group'>
                                                <input type="text" name="newCounterpartyCode" id="newCounterpartyCode" value={newCounterpartyCode} onChange={e => this.onInputChange(e)} autoComplete='off' />
                                                <label htmlFor="newCounterpartyCode">{this.context.t('Counterparty code')}</label>
                                            </div>

                                            <div className='form-group'>
                                                <TextareaAutosize
                                                    type="text"
                                                    name="newCounterpartyAddress"
                                                    id="newCounterpartyAddress"
                                                    value={newCounterpartyAddress}
                                                    onChange={e => this.onInputChange(e)}
                                                    autoComplete='off'
                                                    maxRows={5}
                                                />
                                                <label htmlFor="newCounterpartyAddress">{this.context.t('Counterparty address')}</label>
                                            </div>

                                            <div className='form-group'>
                                                <TextareaAutosize
                                                    type="text"
                                                    name="newCounterpartyEmail"
                                                    id="newCounterpartyEmail"
                                                    value={newCounterpartyEmail}
                                                    onChange={e => this.onInputChange(e)}
                                                    autoComplete='off'
                                                    maxRows={5}
                                                />
                                                <label htmlFor="newCounterpartyEmail">{this.context.t('Counterparty e-mail')}</label>
                                            </div>


                                            <div className='form-group'>
                                                <input
                                                    type="text"
                                                    name="newCounterpartyPaymentDue"
                                                    id="newCounterpartyPaymentDue"
                                                    value={newCounterpartyPaymentDue}
                                                    onChange={e => this.onInputChange(e)}
                                                    autoComplete='off'
                                                />
                                                <label htmlFor="newCounterpartyPaymentDue">{this.context.t('Payment due')}</label>
                                                <span className="info" data-tip="show" data-for="paymentDueTooltip"></span>
                                                <ReactTooltip id="paymentDueTooltip" className="default-tooltip info-tooltip" place="bottom" effect="solid">
                                                    <p>{this.context.t('When generating a sales invoice for this counterparty, the payment date will automatically change to the entered number of days.')}</p>
                                                </ReactTooltip>
                                            </div>

                                            {
                                                counterpartyCodeEdited ? (
                                                    <div className="checkbox" onClick={() => { this.setState({ editAllFiles: !editAllFiles }) }}>
                                                        <div className={`checkmark ${editAllFiles ? 'checked' : ''}`}></div>
                                                        {this.context.t('Change the names of all documents from this contractor so that they contain the new code')}
                                                    </div>
                                                ) : (
                                                    null
                                                )
                                            }

                                            <div className="action-buttons">
                                                <div className="close-button" onClick={() => { this.hideNewCounterpartyPopup() }}>{this.context.t('Cancel')}</div>
                                                <div className='add-button' onClick={() => { this.addNewCounterparty() }}> {this.context.t('Add')} </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            ) : (
                                null
                            )
                        }

                        {
                            showCounterpartyEditPopup ? (
                                <div className="popup">
                                    <div className="card">
                                        <form onSubmit={this.saveCounterpartyChanges}>

                                            <div className='form-group'>
                                                <TextareaAutosize
                                                    type="text"
                                                    name="counterpartyVatIdToEdit"
                                                    id="counterpartyVatIdToEdit"
                                                    value={counterpartyVatIdToEdit}
                                                    onChange={e => this.onInputChange(e)}
                                                    autoComplete='off'
                                                    maxRows={5}
                                                />
                                                <label htmlFor="counterpartyVatIdToEdit">{this.context.t('Vat ID')}</label>
                                            </div>


                                            <div className='form-group'>
                                                <TextareaAutosize
                                                    type="text"
                                                    name="counterpartyNameToEdit"
                                                    id="counterpartyNameToEdit"
                                                    value={counterpartyNameToEdit}
                                                    onChange={e => this.onInputChange(e)}
                                                    autoComplete='off'
                                                    maxRows={5}
                                                />
                                                <label htmlFor="counterpartyNameToEdit">{this.context.t('Counterparty name')}</label>
                                            </div>

                                            <div className='form-group'>
                                                <input type="text" name="counterpartyCodeToEdit" id="counterpartyCodeToEdit" value={counterpartyCodeToEdit} onChange={e => this.onInputChange(e)} autoComplete='off' />
                                                <label htmlFor="counterpartyCodeToEdit">{this.context.t('Counterparty code')}</label>
                                            </div>

                                            <div className='form-group'>
                                                <TextareaAutosize
                                                    type="text"
                                                    name="counterpartAddressToEdit"
                                                    id="counterpartAddressToEdit"
                                                    value={counterpartAddressToEdit}
                                                    onChange={e => this.onInputChange(e)}
                                                    autoComplete='off'
                                                    maxRows={5}
                                                />
                                                <label htmlFor="counterpartAddressToEdit">{this.context.t('Counterparty address')}</label>
                                            </div>


                                            <div className='form-group'>
                                                <TextareaAutosize
                                                    type="text"
                                                    name="counterpartyEmailToEdit"
                                                    id="counterpartyEmailToEdit"
                                                    value={counterpartyEmailToEdit}
                                                    onChange={e => this.onInputChange(e)}
                                                    autoComplete='off'
                                                    maxRows={5}
                                                />
                                                <label htmlFor="counterpartyEmailToEdit">{this.context.t('Counterparty e-mail')}</label>
                                            </div>

                                            <div className='form-group'>
                                                <input
                                                    type="text"
                                                    name="counterpartPaymentDueToEdit"
                                                    id="counterpartPaymentDueToEdit"
                                                    value={counterpartPaymentDueToEdit}
                                                    onChange={e => this.onInputChange(e)}
                                                    autoComplete='off'
                                                />
                                                <label htmlFor="counterpartPaymentDueToEdit">{this.context.t('Payment due')}</label>
                                                <span className="info" data-tip="show" data-for="paymentDueTooltip"></span>
                                                <ReactTooltip id="paymentDueTooltip" className="default-tooltip info-tooltip" place="bottom" effect="solid">
                                                    <p>{this.context.t('When generating a sales invoice for this counterparty, the payment date will automatically change to the entered number of days.')}</p>
                                                </ReactTooltip>
                                            </div>

                                            {
                                                counterpartyCodeEdited ? (
                                                    <div className="checkbox" onClick={() => { this.setState({ editAllFiles: !editAllFiles }) }}>
                                                        <div className={`checkmark ${editAllFiles ? 'checked' : ''}`}></div>
                                                        {this.context.t('Change the names of all documents from this contractor so that they contain the new code')}
                                                    </div>
                                                ) : (
                                                    null
                                                )
                                            }

                                            <div className="action-buttons">
                                                <div className="close-button" onClick={() => { this.hideCounterpartyEditPopup() }}>{this.context.t('Cancel')}</div>
                                                <input type="submit" value={this.context.t('Save')} />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            ) : (
                                null
                            )
                        }

                        {
                            showImportCounterpartiesSummary ? (
                                <div className="popup">
                                    <div className="card">
                                        <div className="close" onClick={() => this.closeImportSummary()}></div>
                                        <div className="title">{this.context.t('Import summary')}</div>
                                        {
                                            newImportedCounterparties.length > 0 ? (
                                                <div className="counterparties">
                                                    <div className="label">{this.context.t('Imported counterparties:')}</div>
                                                    {
                                                        newImportedCounterparties.map(c => {
                                                            return (
                                                                <div className="counterparty-info">
                                                                    <div className="name">
                                                                        <span className="name">{this.context.t('Name')}:</span>
                                                                        <span className="value">{c.name}</span>
                                                                    </div>
                                                                    <div className="vat-id">
                                                                        <span className="name">{this.context.t('VatID')}:</span>
                                                                        <span className="value">{c.vat_id}</span>
                                                                    </div>
                                                                    <div className="address">
                                                                        <span className="name">{this.context.t('Address')}:</span>
                                                                        <span className="value">{c.address}</span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            ) : null
                                        }


                                        {
                                            notImportedCounterparties.length > 0 ? (
                                                <div className="counterparties">
                                                    <div className="label">{this.context.t('Not imported counterparties:')}</div>
                                                    {
                                                        notImportedCounterparties.map(c => {
                                                            return (
                                                                <div className="counterparty-info">
                                                                    <div className="name">
                                                                        <span className="name">{this.context.t('Name')}:</span>
                                                                        <span className="value">{c.name}</span>
                                                                    </div>
                                                                    <div className="vat-id">
                                                                        <span className="name">{this.context.t('VatID')}:</span>
                                                                        <span className="value">{c.vat_id}</span>
                                                                    </div>
                                                                    <div className="address">
                                                                        <span className="name">{this.context.t('Address')}:</span>
                                                                        <span className="value">{c.address}</span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            ) : null
                        }

                        {
                            showCounterpatyMergePopup ? (
                                <div className="popup">
                                    <div className="card">
                                        <form onSubmit={this.saveCounterpartyChanges}>
                                            <div className="info">
                                                <p>{this.context.t('After this operation, selected counterparties will be merged.')}</p>
                                                <p>{this.context.t('For all documents from these counterparties the company with the new data will be set as seller:')}</p>

                                                <ul>
                                                    <li><span className="label">{this.context.t('Name')}</span>{counterpartyNameToMerge}</li>
                                                    <li><span className="label">{this.context.t('Code')}</span>{counterpartyCodeToMerge}</li>
                                                    <li><span className="label">{this.context.t('Vat ID')}</span>{counterpartyVatIdToMerge}</li>
                                                </ul>
                                            </div>

                                            <div className="action-buttons">
                                                <div className="close-button" onClick={() => { this.cancelCounterpartiesMerge() }}>{this.context.t('Cancel')}</div>
                                                <input type="submit" onClick={(e) => this.mergeCounterpartiesConfirm(e)} value={this.context.t('Confirm')} />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            ) : (
                                null
                            )
                        }

                        {
                            loadingScreen ? (
                                <div className="loading-screen">
                                    <div className="activity-indicator">
                                        <Spinner size={50} speed={0.8} color={"#ffffff"} />
                                    </div>
                                </div>
                            ) : (
                                null
                            )
                        }

                        <SettingsTabsBar />

                        <div className="counterparties-table">
                            <div className="search-bar" ref="searchBarRef">
                                <input type="text" value={searchString} name="searchString" onChange={e => this.setState({ searchString: e.target.value })} placeholder={this.context.t('Search counterparties by name, code or Vat ID')} />

                                {
                                    currentCompany.company_id === 1 ||
                                        currentCompany.company_id === 1104 || user.id === 145 || currentCompany.company_id === 1472 ? (
                                        <div className="import-invoice">
                                            <input type="file" accept=".xlsx, .xls" onChange={this.uploadHandler} id="file-input" />
                                            <label htmlFor="file-input" className='import-file'>
                                                {this.context.t("Import counterparties")}
                                            </label>
                                        </div>
                                    ) : (
                                        ""
                                    )
                                }

                                <div className="add-counterparty" onClick={() => this.toggleAddCounterpartyPopup()} >
                                    {this.context.t('Add counterparty')}
                                </div>

                                <div className="company-switcher" ref="companySelectionRef">
                                    <div className="current" onClick={() => this.toggleCompaniesList()}>
                                        <h2>{currentCompany && currentCompany.company ? currentCompany.company.code : ''}</h2>
                                        <span className={`expend-icon ${showCompaniesList ? 'expended' : ''}`}></span>
                                    </div>
                                    {
                                        showCompaniesList ? (
                                            <div className="companies-list">
                                                <div className="header">
                                                    {this.context.t('Choose company')}
                                                </div>
                                                <SimpleBar style={{ maxHeight: 'calc(75vh - 70px)', width: '100%' }}>
                                                    <ul>
                                                        {
                                                            userCompanies.map((company, i) => {
                                                                if (company.user_role === 'ADMIN') {
                                                                    return (
                                                                        <li className={`${currentCompany.id === company.id ? 'current-selected' : ''}`} onClick={e => this.changeCurrentCompany(e, i)}>
                                                                            {company.company.code}
                                                                        </li>
                                                                    )
                                                                } else {
                                                                    return null
                                                                }
                                                            })
                                                        }
                                                    </ul>
                                                </SimpleBar>
                                            </div>
                                        ) : (
                                            null
                                        )
                                    }
                                </div>
                            </div>

                            {
                                selectedForMerge.length > 0 ? (
                                    <div className="merge-counterparties-row">
                                        <div className="title">
                                            {this.context.t('Counterparties data after merge')}
                                        </div>
                                        <div className='form-group'>
                                            <input type="text" name="counterpartyNameToMerge" id="counterpartyNameToMerge" value={counterpartyNameToMerge} onChange={e => this.onInputChange(e)} autoComplete='off' />
                                            <label htmlFor="counterpartyNameToMerge">{this.context.t('Name')}</label>
                                        </div>

                                        <div className='form-group'>
                                            <input type="text" name="counterpartyCodeToMerge" id="counterpartyCodeToMerge" value={counterpartyCodeToMerge} onChange={e => this.onInputChange(e)} autoComplete='off' />
                                            <label htmlFor="counterpartyCodeToMerge">{this.context.t('Code')}</label>
                                        </div>

                                        <div className='form-group'>
                                            <input type="text" name="counterpartyVatIdToMerge" id="counterpartyVatIdToMerge" value={counterpartyVatIdToMerge} onChange={e => this.onInputChange(e)} autoComplete='off' />
                                            <label htmlFor="counterpartyVatIdToMerge">{this.context.t('Vat ID')}</label>
                                        </div>

                                        <div className='form-group'>
                                            <button onClick={() => this.mergeCounterpartiesClick()}>{this.context.t('Merge')}</button>
                                        </div>
                                    </div>
                                ) : (
                                    null
                                )
                            }

                            {
                                counterparties && counterparties.length ? (
                                    <div className="table-wrapper" ref="counterpartiesListRef">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    {/* <th></th> */}
                                                    <th onClick={() => this.props.switchCounterpartiesSortingFilter('name')}>
                                                        <div>
                                                            <span className="column-name">{this.context.t('Name')}</span>
                                                            <span className={this.getCounterpartiesSortClass('name')}></span>
                                                        </div>
                                                    </th>
                                                    <th onClick={() => this.props.switchCounterpartiesSortingFilter('code')}>
                                                        <div>
                                                            <span className="column-name">{this.context.t('Code')}</span>
                                                            <span className={this.getCounterpartiesSortClass('code')}></span>
                                                        </div>
                                                    </th>
                                                    <th onClick={() => this.props.switchCounterpartiesSortingFilter('vat_id')}>
                                                        <div>
                                                            <span className="column-name">{this.context.t('Vat ID')}</span>
                                                            <span className={this.getCounterpartiesSortClass('vat_id')}></span>
                                                        </div>
                                                    </th>
                                                    <th onClick={() => this.props.switchCounterpartiesSortingFilter('address')}>
                                                        <div>
                                                            <span className="column-name">{this.context.t('Address')}</span>
                                                            <span className={this.getCounterpartiesSortClass('address')}></span>
                                                        </div>
                                                    </th>
                                                    <th onClick={() => this.props.switchCounterpartiesSortingFilter('email')}>
                                                        <div>
                                                            <span className="column-name">{this.context.t('E-mail')}</span>
                                                            <span className={this.getCounterpartiesSortClass('email')}></span>
                                                        </div>
                                                    </th>
                                                    <th onClick={() => this.props.switchCounterpartiesSortingFilter('payment_due')}>
                                                        <div>
                                                            <span className="column-name">{this.context.t('Payment due')}</span>
                                                            <span className={this.getCounterpartiesSortClass('payment_due')}></span>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    counterparties.map((c, index) => {
                                                        if (this.checkIfMatchesFilter(c)) {
                                                            return (
                                                                <tr className={`${index % 2 === 0 ? 'even' : 'odd'}`} key={"counterparty-" + c.id}>
                                                                    <td className='edit' onClick={() => { this.openCounterpartyEditPopup(c.id, c.vat_id, c.name, c.code, c.address, c.country, c.email, c.payment_due) }}></td>
                                                                    <td className="name">{c.name}</td>
                                                                    <td className="code">{c.code}</td>
                                                                    <td className="vat-id">{c.vat_id}</td>
                                                                    <td className="address">{c.address}</td>
                                                                    <td className="email">{c.email ? c.email.join(", ") : ''}</td>
                                                                    <td className="payment_due">{c.payment_due ? `${c.payment_due} ${this.context.t('days')}` : ''}</td>
                                                                </tr>
                                                            )
                                                        } else {
                                                            return null
                                                        }
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <div className="no-counterparties" ref="counterpartiesListRef">
                                        <div className="image"></div>
                                        <div className="text">
                                            {this.context.t('It looks like you don\'t have any contractors yet. They are automatically added when saving documents from new sellers')}
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="activity-indicator">
                        <Bounce size={50} speed={0.8} color={"#fff"} />
                    </div>
                )
            }
        } else {
            return null
        }
    }
}



CompanyDetails.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    currentCompany: state.Company.currentCompany,
    userCompanies: state.Company.companies,
    userProjects: state.User.userProjects,
    counterparties: state.Company.counterparties,
    counterpartiesSortField: state.Company.counterpartiesSortField,
    counterpartiesSortOrder: state.Company.counterpartiesSortOrder,
    user: state.User.user,
    loadingScreen: state.File.loadingScreen,
    overlayPreferance: state.User.overlayPreferance || {},
})

const mapDispatchToProps = {
    updateCounterparty: CompanyActions.updateCounterparty,
    addCounterparty: CompanyActions.addCounterparty,
    mergeCounterparties: CompanyActions.mergeCounterparties,
    importCounterparties: CompanyActions.importCounterparties,
    getDocuments: ReportsActions.getDocuments,
    setCurrentCompany: CompanyActions.setCurrentCompany,
    getAllCompanyCounterparties: CompanyActions.getAllCompanyCounterparties,
    switchCounterpartiesSortingFilter: CompanyActions.switchCounterpartiesSortingFilter,
    alertWarn: AlertActions.warning,
    toggleOverlay: UserActions.toggleOverlay
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails)