import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import ReactTooltip from 'react-tooltip'
import moment from 'moment'

import { CompanyActions } from "../actions/CompanyActions";
import { AlertActions } from "../actions/AlertActions";

import { replaceSpecialCharacters } from '../helpers/textSlugify'
import { vatIdValidate } from '../helpers/vatIdValidate'
import { emailRegex } from '../helpers/regexList'

import CountriesList from "./CountriesList";

import "../css/SalesPage.css";

export class NewCounterpartyPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newCounterpartyVatId: '',
            newCounterpartyName: '',
            newCounterpartyCode: '',
            newCounterpartyAddress: '',
            newCounterpartyCountry: '',
            newCounterpartyEmail: '',
            newCounterpartyPaymentDue: '',

            cursorWait: false,
        };

        this.gtuContainerRef = React.createRef()
        this.unitContainerRef = React.createRef()
        this.vatRateContainerRef = React.createRef()
    }

    componentDidMount() {
        const { editingExisting, editedCounterparty } = this.props
        if (editingExisting) {
            this.setState({
                newCounterpartyVatId: editedCounterparty.vat_id,
                newCounterpartyName: editedCounterparty.name,
                newCounterpartyCode: editedCounterparty.code,
                newCounterpartyAddress: editedCounterparty.address,
                newCounterpartyCountry: editedCounterparty.country,
                newCounterpartyEmail: editedCounterparty.email ? editedCounterparty.email.join(", ") : '',
                newCounterpartyPaymentDue: editedCounterparty.payment_due ? editedCounterparty.payment_due : ''
            })

            if (editedCounterparty.vat_id) {
                var onlyNumbers = editedCounterparty.vat_id.replace(/[^0-9]/gm)
                if (onlyNumbers.length === 10) {
                    this.checkVatAPI(onlyNumbers, true)
                }
            }
        }
    }

    onInputChange = (e) => {
        e.preventDefault();
        var { name, value } = e.target;

        if (name === 'newCounterpartyCode') {
            value = value.replace(/[^0-9A-Za-z\-\_]/gm, '')
        } else if (name === 'newCounterpartyPaymentDue') {
            value = value.replace(/[^0-9]/gm, '')
        }

        this.setState({ [name]: value })

        if (name === 'newCounterpartyVatId') {
            if (value && value.replace(/[^0-9]/gm).length === 10) {
                this.checkVatAPI(value)
            }
        }
    };

    vatIdFieldBlur = () => {
        const { newCounterpartyVatId } = this.state
        var onlyNumbers = newCounterpartyVatId.replace(/[^0-9]/gm)
        if (onlyNumbers.length === 10) {
            this.checkVatAPI(onlyNumbers)
        }
    }

    checkVatAPI = (vatId, checkOnlyAdddress) => {
        if (vatIdValidate(vatId)) {
            fetch(`https://wl-api.mf.gov.pl/api/search/nip/${vatId.replace(/[^0-9]/gi, '')}?date=${moment().format("YYYY-MM-DD")}`, { method: 'get' })
                .then(response => response.json())
                .then(json => {
                    console.log(json)
                    if (json && json.result && json.result.subject) {
                        if (checkOnlyAdddress) {
                            this.setState({
                                newCounterpartyAddress: json.result.subject.residenceAddress || json.result.subject.workingAddress,
                                newCounterpartyCountry: 'PL'
                            })
                        } else {
                            var comapnyName = json.result.subject.name
                            var companyCode = this.generateCode(comapnyName)

                            companyCode = replaceSpecialCharacters(companyCode)
                            this.setState({
                                newCounterpartyName: comapnyName,
                                newCounterpartyCode: companyCode,
                                newCounterpartyAddress: json.result.subject.residenceAddress || json.result.subject.workingAddress,
                                newCounterpartyCountry: 'PL'
                            })
                        }
                    }
                })
                .catch(err => {
                    console.log('error when fetching companyName')
                })
        }
    }

    generateCode = (name) => {
        name = name.replace(/SPÓŁKA KOMANDYTOWA/gi, '').replace(/SPÓŁKA CYWILNA/gi, '').replace(/SPÓŁKA AKCYJNA/gi, '').replace(/SPÓŁKA DORADZTWA PODATKOWEGO/gi, '').replace(/SPÓŁKA PARTNERSKA/gi, '').replace(/SPÓŁKA JAWNA/gi, '').replace(/SP. Z O.O./gi, '').replace(/SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ/gi, '').replace(/SP. Z 0.0./gi, '').replace(/POLSKA/gi, '').replace(/\'/gm, '').replace(/\(/gm, '').replace(/\)/gm, '').replace(' - ', '-').trim()
        var indexOfComma = name.indexOf(',')

        if (indexOfComma > 0) {
            var tempName = name.substring(0, indexOfComma)
            tempName = tempName.trim()

            if ((tempName.match(/\"/gm) || []).length !== 1) {
                name = tempName
            }
        }

        if (name.includes('"')) {
            var newName = ((name.match(/\"(.*)\"/g)) || [])
            newName = newName.find(x => x !== undefined)

            if (newName) {
                newName = newName.replace(/\"/gm, '')
                newName = newName.trim()
                var wordsArray = newName.split(' ')
                if (wordsArray.length === 1) {
                    newName = wordsArray[0]
                    var indexOfDash = newName.lastIndexOf('-')
                    if (indexOfDash > 0 && newName.length > 20) {
                        newName = newName.substring(0, indexOfDash)

                        return newName.substring(0, 20)
                    } else {
                        return newName.substring(0, 20)
                    }
                } else {
                    var code = ''
                    wordsArray.forEach(w => {
                        if (w.length > 2 && !w.match(/^\d/)) {
                            code += w[0]
                        } else {
                            code += w
                        }
                    })

                    return code
                }
            } else {
                return ''
            }
        } else {
            var wordsArray = name.split(' ')
            if (wordsArray.length === 1) {
                newName = wordsArray[0]
                var indexOfDash = newName.lastIndexOf('-')
                if (indexOfDash > 0) {
                    newName = newName.substring(0, indexOfDash)

                    return newName.substring(0, 20)
                } else {
                    return newName.substring(0, 20)
                }
            } else if (wordsArray.length === 2) {
                var part1 = wordsArray[0]
                var part2 = wordsArray[1]

                if (part1.length + part2.length <= 19) {
                    return part1 + '-' + part2
                } else {
                    if (part1.includes('-')) {
                        return part1
                    } else if (part2.includes('-')) {
                        return part2
                    } else {
                        return part1 + '-' + part2.substring(0, 19 - part1.length)
                    }
                }
            } else {
                var code = ''
                wordsArray.forEach(w => {
                    if (w.length > 2 && !w.match(/^\d/)) {
                        code += w[0]
                    } else {
                        code += w
                    }
                })

                return code
            }
        }
    }

    saveCounterparty = () => {
        const { newCounterpartyVatId, newCounterpartyName, newCounterpartyEmail, newCounterpartyCode, newCounterpartyAddress, newCounterpartyCountry, newCounterpartyPaymentDue } = this.state
        const { currentCompany, editingExisting, editedCounterparty } = this.props
        var valid = true

        if (!newCounterpartyName) {
            this.props.alertWarn(this.context.t('Counterparty name is required.'))
            valid = false
        }

        if (!newCounterpartyCode) {
            this.props.alertWarn(this.context.t('Counterparty code is required.'))
            valid = false
        }

        if (!newCounterpartyVatId) {
            this.props.alertWarn(this.context.t('Vat ID is required.'))
            valid = false
        }

        var emailsToSave = []
        if (newCounterpartyEmail) {
            var emailsArray = newCounterpartyEmail.split(",")
            emailsArray.map(emailAddress => {
                if (emailAddress && !emailRegex.test(emailAddress.trim())) {
                    valid = false
                    this.props.alertWarn(this.context.t('E-mail is not valid.'))
                }
            })
            emailsToSave = emailsArray
        }

        if (valid) {
            if (editingExisting) {
                this.props.updateCounterparty(currentCompany.company_id, editedCounterparty.id, newCounterpartyVatId, newCounterpartyName, emailsToSave, newCounterpartyCode, newCounterpartyAddress, newCounterpartyCountry, newCounterpartyPaymentDue, false, (success) => {
                    if (success) {
                        this.props.savedChanges({
                            id: editedCounterparty.id,
                            vat_id: newCounterpartyVatId,
                            name: newCounterpartyName,
                            code: newCounterpartyCode,
                            address: newCounterpartyAddress,
                            country: newCounterpartyCountry,
                            email: newCounterpartyEmail,
                            payment_due: newCounterpartyPaymentDue || ''
                        })
                    }
                })
            } else {
                this.props.addCounterparty(currentCompany.company_id, newCounterpartyVatId, newCounterpartyName, emailsToSave, newCounterpartyCode, newCounterpartyAddress, newCounterpartyCountry, newCounterpartyPaymentDue, (success, newCounterpartyId) => {
                    if (success) {
                        this.props.createdNewItem({
                            id: newCounterpartyId,
                            vat_id: newCounterpartyVatId,
                            name: newCounterpartyName,
                            code: newCounterpartyCode,
                            address: newCounterpartyAddress,
                            country: newCounterpartyCountry,
                            email: newCounterpartyEmail,
                            payment_due: newCounterpartyPaymentDue || ''
                        })
                    }
                })
            }
        }
    }

    setCountryCode = code => {
        this.setState({
            newCounterpartyCountry: code,
            showCountriesList: false,
        })
    }

    render() {
        const { cursorWait, newCounterpartyVatId, newCounterpartyName, newCounterpartyCode, newCounterpartyAddress, newCounterpartyCountry, showCountriesList, newCounterpartyEmail, newCounterpartyPaymentDue } = this.state
        const { editingExisting } = this.props
        return (
            <div className={`new-item-popup ${cursorWait ? 'cursor-wait' : ''}`}>
                <div className="new-item-window">
                    <div className="main-text-item">
                        {
                            editingExisting ? (
                                this.context.t("Edit counterparty")
                            ) : (
                                this.context.t("Add counterparty")
                            )
                        }
                    </div>
                    <div className="add-new-label">
                        <div className="form-group">
                            <input type="text" id="newCounterpartyVatId" autocomplete="off" name="newCounterpartyVatId" value={newCounterpartyVatId} onChange={this.onInputChange} onBlur={this.vatIdFieldBlur} />
                            <label for="newCounterpartyVatId">{this.context.t("Vat ID")}</label>
                            <span className="info" data-tip="show" data-for="vatIdTooltip"></span>
                            <ReactTooltip id="vatIdTooltip" className="default-tooltip info-tooltip" place="bottom" effect="solid">
                                <p>{this.context.t('Enter the NIP number and we will complete the remaining data from GUS database.')}</p>
                            </ReactTooltip>
                        </div>
                        <div className="form-group">
                            <input type="text" id="newCounterpartyName" autocomplete="off" name="newCounterpartyName" value={newCounterpartyName} onChange={this.onInputChange} />
                            <label for="newCounterpartyName">{this.context.t("Counterparty name")}</label>
                        </div>
                        <div className="form-group">
                            <input type="text" id="newCounterpartyCode" autocomplete="off" name="newCounterpartyCode" value={newCounterpartyCode} onChange={this.onInputChange} />
                            <label for="newCounterpartyCode">{this.context.t("Counterparty code")}</label>
                        </div>
                        <div className="form-group address">
                            <input type="text" id="newCounterpartyAddress" autocomplete="off" name="newCounterpartyAddress" value={newCounterpartyAddress} onChange={this.onInputChange} />
                            <label for="newCounterpartyAddress">{this.context.t("Counterparty address")}</label>
                        </div>
                        <div className={`form-group country ${showCountriesList ? 'in-front' : ''}`}>
                            <input type="text" id="newCounterpartyCountry" autocomplete="off" name="newCounterpartyCountry" value={newCounterpartyCountry} onChange={this.onInputChange} onClick={() => this.setState({ showCountriesList: !showCountriesList })} />
                            <label for="newCounterpartyCountry" onClick={() => this.setState({ showCountriesList: !showCountriesList })}>{this.context.t("Counterparty country")}</label>
                            {
                                showCountriesList ?
                                    <CountriesList
                                        headerText={this.context.t('Country:')}
                                        selectedOption={newCounterpartyCountry}
                                        select={(code) => this.setCountryCode(code)}
                                    /> : null
                            }
                            <span className={`expend-icon ${showCountriesList ? "expended" : ""}`} onClick={() => this.setState({ showCountriesList: !showCountriesList })} ></span>
                        </div>
                        <div className="form-group">
                            <input type="text" id="newCounterpartyEmail" autocomplete="off" name="newCounterpartyEmail" value={newCounterpartyEmail} onChange={this.onInputChange} />
                            <label for="newCounterpartyEmail">{this.context.t("Counterparty e-mail")}</label>
                        </div>
                        <div className="form-group">
                            <input type="text" id="newCounterpartyPaymentDue" autocomplete="off" name="newCounterpartyPaymentDue" value={newCounterpartyPaymentDue} onChange={this.onInputChange} />
                            <label for="newCounterpartyPaymentDue">{this.context.t("Payment due")}</label>

                            <span className="info" data-tip="show" data-for="paymentDueTooltip"></span>
                            <ReactTooltip id="paymentDueTooltip" className="default-tooltip info-tooltip" place="bottom" effect="solid">
                                <p>{this.context.t('When generating a sales invoice for this counterparty, the payment date will automatically change to the entered number of days.')}</p>
                            </ReactTooltip>
                        </div>
                    </div>
                    <div className="action-buttons">
                        <div className="cancel-button" onClick={() => this.props.close()}>{this.context.t("Cancel")}</div>
                        <div className="save-button" onClick={() => this.saveCounterparty()}>{this.context.t("Save")}</div>
                    </div>
                </div>
            </div>
        )
    }
}

NewCounterpartyPopup.contextTypes = {
    t: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
    currentCompany: state.Company.currentCompany,
});

const mapDispatchToProps = {
    updateCounterparty: CompanyActions.updateCounterparty,
    addCounterparty: CompanyActions.addCounterparty,
    alertWarn: AlertActions.warning,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCounterpartyPopup);
