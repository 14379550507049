import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import '../css/SubscriptionTransactionPopup.css'
import moment from 'moment'

export class SubscriptionTransactionSuccessPopup extends Component {
    constructor(props) {
        super(props)

		this.state = {}
    }

    render() {
        const {subscriptionEndDate} = this.props
        return (
            <div className="payment-success popup">
                <div className="card">
                    {this.context.t('Thank you for purchasing the EasyDocs subscription. The period of validity of your package has been extended till ')}{moment(subscriptionEndDate).format('YYYY-MM-DD')}{this.context.t(' 23:59:59 Central European Time.')}
                    <div className="finish-button" onClick={() => {this.props.hideAfterPaymentPopup()}}>{this.context.t('Close')}</div>
                </div>
            </div>
        )
    }
}

SubscriptionTransactionSuccessPopup.contextTypes = {
	t: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionTransactionSuccessPopup)