import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { Dots } from 'react-activity'
import 'react-activity/dist/react-activity.css'
import TextareaAutosize from 'react-textarea-autosize'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import axios from "axios"

import Calendar from '../Calendar';
import Calculator from '../Calculator';

import CountriesList from '../CountriesList';

import TagsComponent from './TagsComponent';
import CostSplit from './CostSplit';
import MpkSplitWineAvenue from './MpkSplit/MpkSplitWineAvenue'
import MpkSplitAventine from './MpkSplit/MpkSplitAventine'
import OcrPageWaproMagDocTypes from './wapro/OcrPageWaproMagDocTypes'
import OcrPageWaproFakirDocTypes from './wapro/OcrPageWaproFakirDocTypes'
import OcrPageWaproFakirDocDictionary from './wapro/OcrPageWaproFakirDocDictionary'
import OcrPageWaproNettoAccounts from './wapro/OcrPageWaproNettoAccounts'
import OcrPageWaproDocDescription from './wapro/OcrPageWaproDocDescription'
import OcrPageWaproVatRate from './wapro/OcrPageWaproVatRate'

import OcrPageSymfoniaDocTypes from './symfonia/OcrPageSymfoniaDocTypes'
import OcrPageSymfoniaDocDescription from './symfonia/OcrPageSymfoniaDocDescription'
import OcrPageSymfoniaGrossAccount from './symfonia/OcrPageSymfoniaGrossAccount'
import OcrPageSymfoniaNetAccount from './symfonia/OcrPageSymfoniaNetAccount'
import OcrPageSymfoniaVatAccount from './symfonia/OcrPageSymfoniaVatAccount'
import OcrPageSymfoniaRegisters from './symfonia/OcrPageSymfoniaRegisters'

import ProjectCode from './WL_DevCosts/ProjectCode'
import Technology from './WL_DevCosts/Technology'
import DevExCode from './WL_DevCosts/DevExCode'
import DevExType from './WL_DevCosts/DevExType'
import TransactionDescription from './WL_DevCosts/TransactionDescription'

import OcrPageLinkDocsPopup from './OcrPageLinkDocsPopup'

import '../../css/OcrPage.css'

import parseDate from '../../helpers/dateParser'
import isIterable from "../../helpers/isIterable"
import waproUsers from '../../helpers/waproUsers.json'
import { devCostsProjects } from '../../helpers/DevCosts/devCostsProjects'
import { gfProjects } from '../../helpers/CostSplit/gfProjects'
import { waProjects } from '../../helpers/MpkSplitWineAvenue/waProjects';
import { devCostsUsers } from '../../helpers/DevCosts/devCostsUsers'
import { vatIdValidate } from '../../helpers/vatIdValidate';
import { checkIfAccountNumberIsValid } from '../../helpers/validAccountNumber';

import { CompanyActions } from '../../actions/CompanyActions';
import { FileActions } from '../../actions/FileActions';
import { AlertActions } from '../../actions/AlertActions';

const REQUIRED_FIELDS = ['issueDate', 'sellerName', 'sellerVatId']

export class OcrPageCostsForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            symfoniaDocTypes: [],
            symfoniaAccounts: [],
            accountWhiteListStatus: '',
            checketVatId: '',
            checkedAccountNumber: '',
            checkedAmount: 0,
            sentRequetst: false,

            docsList: [],
            agreementsList: [],
            linkedFiles: {
                agreementLink: {
                    linked: false,
                    file: {}
                },
                correctionLink: {
                    linked: false,
                    file: {}
                },
                proformaLink: {
                    linked: false,
                    file: {}
                }
            },

            userThatLockedDevEx: ''
        }
    }

    componentDidMount() {
        const { user, companyId, currentInvoiceImageData, WL_DevCosts } = this.props
        if (Number.isFinite(companyId) && (companyId === 1607 || user.id === 76 || user.id === 2)) {
            this.props.getCompanySymfoniaFile(companyId, (res) => {
                if (res.success) {
                    this.setState({
                        symfoniaVatRates: res.vatRates,
                        symfoniaRegisters: res.registers,
                        symfoniaDocTypes: res.docTypes,
                        symfoniaAccounts: res.accounts
                    })
                }
            })
        }

        console.log('OcrPageCostsForm componentDidMount')
        this.checkAccountStatusOnWhiteList()

        if (WL_DevCosts?.lockedForDevEx) {
            this.props.getUserThatLockedFileTofDevEx(currentInvoiceImageData.dbId, userName => {
                this.setState({
                    userThatLockedDevEx: userName
                })
            })
        }
    }

    componentDidUpdate(prevProps) {
        const { returnedOCRData, ocrMethod } = this.props
        const { sentRequetst } = this.state
        if (!prevProps.returnedOCRData && returnedOCRData) {
            this.checkAccountStatusOnWhiteList()
        } else if (returnedOCRData && !sentRequetst && (returnedOCRData.props || returnedOCRData.manualProps)) {
            const { checketVatId, checkedAccountNumber, checkedAmount } = this.state
            var ocrProps = []
            if (ocrMethod === 'select') ocrProps = returnedOCRData.props
            else ocrProps = returnedOCRData.manualProps

            var sellerVatId = ocrProps.find(e => e.name === 'sellerVatId')
            var accountNumber = ocrProps.find(e => e.name === 'bankData')
            var price = ocrProps.find(e => e.name === 'price')
            if (price && !price.text) {
                price = ocrProps.find(e => e.name === 'brutto')
            }

            if (sellerVatId && sellerVatId.text && accountNumber && accountNumber.text && price && price.text) {
                var currentVatId = sellerVatId.text.replace(/[^0-9]/g, '')
                var currentAccountNumber = accountNumber.text.replace(/[^0-9]/g, '')
                var priceNumber = parseFloat(price.text)
                if (vatIdValidate(currentVatId) && checkIfAccountNumberIsValid(currentAccountNumber)) {
                    if (currentVatId !== checketVatId || currentAccountNumber !== checkedAccountNumber || priceNumber !== checkedAmount)
                        this.checkAccountStatusOnWhiteList()
                }
            }
        }
    }

    checkAccountStatusOnWhiteList = () => {
        const { ocrMethod, returnedOCRData, currency } = this.props
        const { sentRequetst, checkedAmount } = this.state
        if (currency === 'PLN' && returnedOCRData && !sentRequetst && (returnedOCRData.props || returnedOCRData.manualProps)) {
            var ocrProps = []
            if (ocrMethod === 'select') ocrProps = returnedOCRData.props
            else ocrProps = returnedOCRData.manualProps

            var sellerVatId = ocrProps.find(e => e.name === 'sellerVatId')
            var accountNumber = ocrProps.find(e => e.name === 'bankData')
            if (sellerVatId && sellerVatId.text && vatIdValidate(sellerVatId.text.replace(/[^0-9]/g, '')) && accountNumber && accountNumber.text && checkIfAccountNumberIsValid(accountNumber.text)) {
                var varIdToCheck = sellerVatId.text.replace(/[^0-9]/g, '')
                var accountToCheck = accountNumber.text.replace(/[^0-9]/g, '')
                var price = ocrProps.find(e => e.name === 'price')
                if (price && !price.text) {
                    price = ocrProps.find(e => e.name === 'brutto')
                }

                if (price && price.text) {
                    var priceNumber = parseFloat(price.text)
                    if (priceNumber > 14999) {
                        this.setState({
                            sentRequetst: true
                        })
                        axios.get(`https://wl-api.mf.gov.pl/api/search/nip/${varIdToCheck}?date=${moment().format("YYYY-MM-DD")}`, { timeout: 15000 }).then(response => {
                            if (response && response.data && response.data.result && response.data.result.subject) {
                                var foundCompany = response.data.result.subject
                                if (foundCompany.statusVat === 'Czynny') {
                                    var whiteListAccounts = foundCompany.accountNumbers
                                    if (!whiteListAccounts.includes(accountToCheck)) {
                                        console.log('ACCOUNT NOT FOUND')
                                        this.setState({
                                            accountWhiteListStatus: 'notFound'
                                        })
                                    } else {
                                        console.log('ACCOUNT FOUND')
                                        this.setState({
                                            accountWhiteListStatus: 'found'
                                        })
                                    }
                                }
                            }

                            this.setState({
                                sentRequetst: false,
                                checketVatId: varIdToCheck,
                                checkedAccountNumber: accountToCheck,
                                checkedAmount: priceNumber
                            })
                        }).catch(err => {
                            console.log(err)
                            this.setState({
                                sentRequetst: false,
                                checketVatId: varIdToCheck,
                                checkedAccountNumber: accountToCheck,
                                checkedAmount: priceNumber
                            })
                        })
                    } else if (priceNumber !== checkedAmount) {
                        this.setState({
                            sentRequetst: true,
                            accountWhiteListStatus: 'found',
                            checkedAmount: priceNumber
                        })
                    }
                }
            }
        }
    }

    getClassNameForOcrData = (propName, text, newCounterparty) => {
        const { paymentMethod, currency, paymentType, ocrMethod, dataReadWithOcrFrame } = this.props

        var classes = ''
        var hide = false
        var half = false
        var split3 = false

        if (paymentMethod !== 'not-paid') {
            if (propName === 'dueDate' || propName === 'bankData' || propName === 'price' || propName === 'tax' || propName === 'address' || propName === 'swift' || propName === 'currencyRate') {
                hide = true
            }
            if (propName === 'issueDate' || propName === 'saleDate') {
                half = true
            }
        } else {
            if (propName === 'dueDate' || propName === 'issueDate' || propName === 'saleDate') {
                split3 = true
            } else if (currency !== 'PLN') {
                if (propName === 'bankData' || propName === 'swift') {
                    half = true
                }
            } else if (currency === 'PLN' && (propName === 'address' || propName === 'swift' || propName === 'currencyRate')) {
                hide = true
            }

            if (paymentType !== 'split' && propName === 'tax') {
                hide = true
            }
        }

        if (propName === 'brutto' || propName === 'netto') {
            half = true
        }

        if (currency !== 'PLN') {
            if (propName === 'price') {
                classes += ' show-currency-rate'
            }
        }


        if (newCounterparty) {
            if (propName === 'sellerName') {
                classes += ' show-seller-code'
            }
        } else {
            if (propName === 'sellerCode') {
                hide = true
            }
        }

        if (propName === 'sellerName' && ocrMethod === 'manual') {
            classes += ' counterparties-list-container'
        }

        if (hide) classes += ' hide'
        if (half) classes += ' half'
        if (split3) classes += ' split-3'

        if (dataReadWithOcrFrame.includes(propName)) classes += ' finished-ocr-indication'
        if (REQUIRED_FIELDS.includes(propName) && !text) classes += ' required-field'

        return classes.trim()
    }

    checkDate = (date) => {
        if (date && parseDate(date).match(new RegExp("[0-9]{2}-[0-9]{2}-[0-9]{4}", "i"))) {
            return true
        } else {
            return false
        }
    }

    checkIfVatIdIsValid = (vatId) => {
        if (vatId) {
            var countryCode = ''
            const firstTwoLetter = /^[A-Z]{2}/
            if (firstTwoLetter.test(vatId) && !vatId.startsWith('PL')) {
                countryCode = vatId.slice(0, 2)
            }
            const { returnedOCRData, ocrMethod, currency } = this.props

            if (currency !== 'PLN' && returnedOCRData && (returnedOCRData.props || returnedOCRData.manualProps)) {
                var sellerCountry = {}

                if (ocrMethod === 'select') {
                    sellerCountry = returnedOCRData.props.find(e => e.name === 'countryCode')
                    if (sellerCountry && sellerCountry.text) {
                        countryCode = sellerCountry.text
                    }
                } else {
                    sellerCountry = returnedOCRData.manualProps.find(e => e.name === 'countryCode')
                    if (sellerCountry && sellerCountry.text) {
                        countryCode = sellerCountry.text
                    }
                }
                if (firstTwoLetter.test(vatId) && vatId.substring(0, 2) !== countryCode) return false
                if (!firstTwoLetter.test(vatId) && countryCode === 'DE') vatId = countryCode + vatId
            }

            return vatIdValidate(vatId)
        } else return true
    }

    checkIfAmountToPayIsValid = (amountToPay, otherValues) => {
        if (amountToPay) {
            if (/\.[0-9]{2}/.test(amountToPay) || /\,[0-9]{2}/.test(amountToPay)) {
                var bruttoValue = otherValues.find(e => e.name === 'brutto')
                if (bruttoValue && bruttoValue.text) {
                    if (/\.[0-9]{2}/.test(bruttoValue.text) || /\,[0-9]{2}/.test(bruttoValue.text)) {
                        bruttoValue.text = bruttoValue.text.replace(',', '.')
                        if (parseFloat(amountToPay) > parseFloat(bruttoValue.text)) {
                            return false
                        } else {
                            return true
                        }
                    } else return true
                } else return true
            } else {
                return false
            }
        } else return true
    }

    invalidAmountToPayMessage = (amountToPay, otherValues) => {
        if (amountToPay) {
            var bruttoValue = otherValues.find(e => e.name === 'brutto')
            if (bruttoValue && bruttoValue.text) {
                bruttoValue.text = bruttoValue.text.replace(',', '.')
                if (parseFloat(amountToPay) > parseFloat(bruttoValue.text)) {
                    return '*Amount to pay is greater than the gross amount, please make sure it is correct'
                } else {
                    return ''
                }
            } else return ''
        } else return ''
    }

    checkIfBruttoIsValid = (brutto, otherValues) => {
        if (brutto) {
            if (!/\.[0-9]{2}/.test(brutto) && !/\,[0-9]{2}/.test(brutto)) {
                return false
            } else if (this.props.currency === 'PLN') {
                var nettoValue = otherValues.find(e => e.name === 'netto')
                if (nettoValue && nettoValue.text) {
                    if (/\.[0-9]{2}/.test(nettoValue.text) || /\,[0-9]{2}/.test(nettoValue.text)) {
                        nettoValue.text = nettoValue.text.replace(',', '.')
                        if (parseFloat(brutto) > parseFloat(nettoValue.text) * 1.23 + 0.1) {
                            return false
                        } else {
                            return true
                        }
                    } else return true
                } else return true
            } else return true
        } else return true
    }

    invalidBruttoMessage = (brutto, otherValues) => {
        if (brutto) {
            if (this.props.currency === 'PLN') {
                var nettoValue = otherValues.find(e => e.name === 'netto')
                if (nettoValue && nettoValue.text) {
                    nettoValue.text = nettoValue.text.replace(',', '.')
                    if (parseFloat(brutto) > parseFloat(nettoValue.text) * 1.23 + 0.1) {
                        return '*The gross amount is greater than the net amount increased by 23% VAT, make sure it is correct'
                    } else {
                        return ''
                    }
                } else return ''
            } else return ''
        } else return ''
    }

    polihAccountPritier = account => {
        if (account) {
            var formatedAccount = account.replace(/[^0-9]/gm, '')

            if (formatedAccount.length === 26) {
                formatedAccount = formatedAccount.slice(0, 2) + ' ' + formatedAccount.slice(2, 6) + ' ' + formatedAccount.slice(6, 10) + ' ' + formatedAccount.slice(10, 14) + ' ' + formatedAccount.slice(14, 18) + ' ' + formatedAccount.slice(18, 22) + ' ' + formatedAccount.slice(22, 26)
                if (account.startsWith('PL')) {
                    return 'PL' + formatedAccount
                } else {
                    return formatedAccount
                }
            } else {
                return account
            }
        } else return ''
    }

    foundAtLeastOneSimilarCounterparty = (text) => {
        const { counterpartiesList } = this.props
        var counterpartiesCount = counterpartiesList.length

        if (!text) {
            return false
        }

        for (var i = 0; i < counterpartiesCount; i++) {
            if (text.length === 1 && counterpartiesList[i].name && counterpartiesList[i].name.toLowerCase().startsWith(text.toLowerCase())) {
                return true
            } else if (text.length > 1 && counterpartiesList[i].name && counterpartiesList[i].name.toLowerCase().includes(text.toLowerCase())) {
                return true
            }
        }
        return false
    }

    checkIfCounterpartyNameIsSimilar = (text, name) => {
        if (!text) {
            return false
        }

        if (text.length === 1 && name && name.toLowerCase().startsWith(text.toLowerCase())) {
            return true

        } else if (text.length > 1 && name && name.toLowerCase().includes(text.toLowerCase())) {
            return true
        }

        return false
    }

    blurTextArea = (e, propName, dbId) => {
        this.props.focusOutArea(e, dbId, propName)
    }

    focusTextArea = (e, propName) => {
    }

    setSellerCountryCode = (value) => {
        const { currentInvoiceImageData, ocrMethod } = this.props
        this.props.setSellerCountryCode(value, 'countryCode', ocrMethod, currentInvoiceImageData.dbId)
        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'countryCode', value, ocrMethod === 'select' ? 'costsOCR' : 'costsManual')
    }

    setWaproField = (newData) => {
        console.log(newData)
        this.props.setWaproData({
            ...this.props.waproData,
            ...newData
        })
    }

    setSymfoniaDocType = value => {
        this.props.setSymfoniaData({
            ...this.props.symfoniaData,
            ...{ docType: value }
        })
    }

    setSymfoniaDocDescription = value => {
        this.props.setSymfoniaData({
            ...this.props.symfoniaData,
            ...{ docDescription: value }
        })
    }

    selectSymfoniaVatAccount = value => {
        this.props.setSymfoniaData({
            ...this.props.symfoniaData,
            ...{ vatAccount: value }
        })
    }

    selectSymfoniaNetAccount = value => {
        this.props.setSymfoniaData({
            ...this.props.symfoniaData,
            ...{ netAccount: value }
        })
    }

    selectSymfoniaGrossAccount = value => {
        this.props.setSymfoniaData({
            ...this.props.symfoniaData,
            ...{ grossAccount: value }
        })
    }

    editSymfoniaRegisters = value => {
        this.props.setSymfoniaData({
            ...this.props.symfoniaData,
            ...{ vatRegisters: value }
        })
    }

    WL_setProjectCode = value => {
        this.props.setWL_DevCosts({
            ...this.props.WL_DevCosts,
            ...{ projectCode: value }
        })
        const { currentInvoiceImageData } = this.props
        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'wl_project_code', value, '')
    }

    WL_setTechnology = value => {
        this.props.setWL_DevCosts({
            ...this.props.WL_DevCosts,
            ...{ technology: value }
        })
        const { currentInvoiceImageData } = this.props
        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'wl_technology', value, '')
    }

    WL_setDevExType1 = value => {
        this.props.setWL_DevCosts({
            ...this.props.WL_DevCosts,
            ...{ devExType1: value, devExType2: "" }
        })
        const { currentInvoiceImageData } = this.props
        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'wl_devex_type_1', value, '')
        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'wl_devex_type_2', "", '')
    }

    WL_setDevExType2 = value => {
        this.props.setWL_DevCosts({
            ...this.props.WL_DevCosts,
            ...{ devExType2: value }
        })
        const { currentInvoiceImageData } = this.props
        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'wl_devex_type_2', value, '')
    }

    WL_setTransactionDescriptionPl = value => {
        this.props.setWL_DevCosts({
            ...this.props.WL_DevCosts,
            ...{ transactionDescriptionPl: value }
        })
    }

    WL_setTransactionDescriptionEn = value => {
        this.props.setWL_DevCosts({
            ...this.props.WL_DevCosts,
            ...{ transactionDescriptionEn: value }
        })
    }

    WL_autoSaveTransactionDescriptionPl = value => {
        const { currentInvoiceImageData } = this.props
        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'wl_transaction_description_pl', value, '')
    }

    WL_autoSaveTransactionDescriptionEn = value => {
        const { currentInvoiceImageData } = this.props
        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'wl_transaction_description_en', value, '')
    }

    WL_setLockedForDevEx = value => {
        const { WL_DevCosts, user } = this.props
        if (value || (WL_DevCosts.lockedForDevExBy === user.id && WL_DevCosts.lockedForDevExBy > 0)) {
            this.props.setWL_DevCosts({
                ...WL_DevCosts,
                ...{
                    lockedForDevEx: value,
                    lockedForDevExBy: value ? user.id : null
                }
            })
            const { currentInvoiceImageData } = this.props
            this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'wl_locked_for_devex', value ? "true" : "false", '')
        } else if (!value && WL_DevCosts.lockedForDevExBy !== user.id && WL_DevCosts.lockedForDevExBy > 0) {
            this.props.alertWarn(this.context.t('Only the user who has locked the file while filling out the DevEx fields can unlock it.'))
        }
    }

    toggleDocLinkingPopup = () => {
        const { returnedOCRData, ocrMethod, companyId } = this.props
        var counterpartyVatId = ''
        if (returnedOCRData.props || returnedOCRData.manualProps) {
            if (ocrMethod === 'select' && returnedOCRData.props) counterpartyVatId = (returnedOCRData.props.find(e => e.name === 'sellerVatId') || { text: '' }).text
            else if (returnedOCRData.manualProp) counterpartyVatId = (returnedOCRData.manualProps.find(e => e.name === 'sellerVatId') || { text: '' }).text

            if (counterpartyVatId) {
                this.setState({
                    sendAllDocsRequest: true,
                    gotAllDocsRespons: false,
                    showLinkDocsPopup: true,
                })
                this.props.hideTestPreview()
                this.props.getAllDocsFromCounterparty(companyId, counterpartyVatId, (success, docs, agreements) => {
                    if (success) {
                        this.setState({
                            docsList: docs,
                            agreementsList: agreements,
                            counterpartyVatId: counterpartyVatId,
                            gotAllDocsRespons: true,
                            foundDocsFromCounterparty: docs.length > 0 || agreements.length > 0
                        })
                    }
                })
            } else {
                this.props.alertWarn(this.context.t("You must first fill in the Seller VatID"))
            }
        }
    }

    hideLinkedFilesPopup = () => {
        this.setState({
            showLinkDocsPopup: false
        })
    }

    saveLinkedFiles = linkedFiles => {
        this.setState({
            linkedFiles: linkedFiles,
            showLinkDocsPopup: false
        })
    }

    toggleEmailConfirmation = () => {
        const { user, currentInvoiceImageData, emailToSendPaymentConfirmation } = this.props
        var newPaymentConfirmationEmail = emailToSendPaymentConfirmation || ''
        if (newPaymentConfirmationEmail.includes(user.email)) newPaymentConfirmationEmail = newPaymentConfirmationEmail.replace(user.email, '').replace(',,', ',')
        else newPaymentConfirmationEmail += `,${user.email}`

        newPaymentConfirmationEmail = newPaymentConfirmationEmail.replace(',,', ',').replace(/^,/, '').replace(/,$/, '')

        this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'email_to_send_payment_confirmation', newPaymentConfirmationEmail, '')
        this.props.setEmailToSendPaymentConfirmation(newPaymentConfirmationEmail)
    }

    splitDocAmount = value => {
        this.props.setCostSplit(value)
    }

    render() {
        const { accountWhiteListStatus, showLinkDocsPopup, docsList, agreementsList, counterpartyVatId, linkedFiles, foundDocsFromCounterparty, gotAllDocsRespons } = this.state
        const {
            user,
            currentInvoiceImageData, currentProjectRole, isAccountantInCurrentCompany,
            paymentMethod, currency, currencyRateDate, comment, documentDescription, tags, emailToSendPaymentConfirmation, ocrMethod,
            calcHeight,
            returnedOCRData, processingData, errorFields, acceptedAmounttoPay, focusedTextArea,
            showAccountNumberWarningBeforeSave,
            showTagsList, tagsList, tagsCreationLimitation,
            dataConfirmCheckbox,
            counterpartiesList, showCounterpartiesList,
            posUpDatePicker, posYDatePicker,
            posYCalculator, posUpCalculator,
            costSplit, costSplitAllocation,
            mpkSplit,
        } = this.props


        if (ocrMethod === 'select') {
            return (
                <div id="invoice-content" ref="sideInvoiceBar">
                    {
                        showLinkDocsPopup ? (
                            <OcrPageLinkDocsPopup
                                linkedFiles={linkedFiles}
                                docsList={docsList}
                                agreementsList={agreementsList}
                                counterpartyVatId={counterpartyVatId}
                                foundDocsFromCounterparty={gotAllDocsRespons ? foundDocsFromCounterparty : true}
                                cancel={this.hideLinkedFilesPopup}
                                save={this.saveLinkedFiles}
                            />
                        ) : null
                    }
                    <div className="fixed-height-wrapper" style={{ height: calcHeight }} ref={this.props.setFormRef}>
                        <div className="invoice-content">
                            <SimpleBar style={{ height: calcHeight, width: 'calc(100% + 20px)', marginLeft: '-10px', paddingLeft: '10px', marginRight: '-10px', paddingRight: '10px' }}>
                                {/* <div className="info-text">
                                    {this.context.t('Please verify the correctness of the data in the form below with the data on the document. Enter any changes manually or frame the appropriate information on the document and select the corresponding form field from the menu (available under the right mouse button).')}
                                </div> */}
                                <div className="checkmark" onClick={() => this.toggleEmailConfirmation()}>
                                    {this.context.t('Please send me a confirmation of payment')}
                                    <div className={`checkbox ${emailToSendPaymentConfirmation && emailToSendPaymentConfirmation.includes(user.email) ? 'checked' : ''}`}></div>
                                </div>

                                <div className="checkmark" onClick={(e) => this.props.swithcMode(e)}>
                                    {this.context.t('There is no VAT ID of the seller or the invoice is handwritten? Edit manually')}
                                    <div className={`checkbox ${ocrMethod !== 'select' ? 'checked' : ''}`}></div>
                                </div>
                                {
                                    returnedOCRData && returnedOCRData.props ? (
                                        returnedOCRData.props.map((prop, i) => {

                                            if (prop.name && prop.name !== 'sellerData' && prop.name !== 'buyerData') {
                                                return (
                                                    <div className={`ocr-data ${prop.name} ${this.getClassNameForOcrData(prop.name, prop.text, returnedOCRData.newCounterparty)} ${gfProjects.includes(currentInvoiceImageData.projectId) && !costSplit && prop.name === 'price' ? 'show-cost-split-button' : ''}`} key={i} ref={prop.name + "PropRef"} onClick={e => this.props.showDatePicker(e)}>
                                                        <label className={prop.name} onClick={(e) => this.props.scrollToArea(e, prop.name, prop.possition)}>
                                                            {this.context.t(prop.title)}
                                                            {
                                                                processingData && processingData.find(e => e === prop.name) ? (
                                                                    <span className="loading"><Dots size={9} speed={0.8} color={'#69B792'} /></span>
                                                                ) : null
                                                            }
                                                        </label>
                                                        <TextareaAutosize
                                                            key={`prop-field-${prop.name}-${returnedOCRData.file}`}
                                                            id={`prop-field-${prop.name}`}
                                                            className={`${prop.name} ${errorFields.includes(prop.name) ? 'error' : ''} ${!acceptedAmounttoPay && prop.name === 'price' ? 'price-gray' : ''} ${focusedTextArea === prop.name ? 'focused' : ''}`}
                                                            value={prop.text}
                                                            onChange={(e) => this.props.onInputChange(e, currentInvoiceImageData.dbId, prop.name)}
                                                            onClick={(e) => this.props.scrollToArea(e, prop.name, prop.possition)}
                                                            onBlur={e => { this.blurTextArea(e, prop.name, currentInvoiceImageData.dbId); this.props.autoSaveOcr(currentInvoiceImageData.ftvId, prop.name, prop.text, 'costsOCR') }}
                                                            onFocus={e => this.focusTextArea(e, prop.name)}
                                                            name={prop.name}
                                                            maxRows={prop.name === 'buyerName' ? 1 : 10}
                                                            readOnly={prop.name === 'countryCode'}
                                                        />

                                                        {
                                                            prop.name === 'sellerName' && counterpartiesList.length > 0 && prop.text && this.foundAtLeastOneSimilarCounterparty(prop.text) && showCounterpartiesList ? (
                                                                <div className="counterparties-list">
                                                                    <div className="header">
                                                                        {this.context.t('Your counterparties with similar name:')}
                                                                    </div>
                                                                    <SimpleBar style={{ height: 'auto', maxHeight: '300px', width: '100%' }}>
                                                                        <ul>
                                                                            {
                                                                                counterpartiesList.map(c => {
                                                                                    if (this.checkIfCounterpartyNameIsSimilar(prop.text, c.name)) {
                                                                                        return (
                                                                                            <li tabIndex={"0"} className={`item counterparty-item`} onClick={() => this.props.selectCounterpartOcrData(c, currentInvoiceImageData.dbId)}>{c.name}</li>
                                                                                        )
                                                                                    } else {

                                                                                        return null
                                                                                    }
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </SimpleBar>
                                                                </div>
                                                            ) : (
                                                                null
                                                            )
                                                        }
                                                        {
                                                            prop.name === 'sellerCode' && returnedOCRData.newCounterparty ? (
                                                                <>
                                                                    <div className="help-icon" data-for={`seller-code-tooltip`} data-tip='show'></div>

                                                                    <ReactTooltip id={`seller-code-tooltip`} place="top" effect="solid" className="default-tooltip amount-to-pay">
                                                                        {this.context.t('Seller code is used in the process of naming new documents. You can change it to make it easier for you to identify files by their name itself')}
                                                                    </ReactTooltip>
                                                                </>
                                                            ) : (
                                                                null
                                                            )
                                                        }
                                                        {
                                                            prop.name === 'dueDate' || prop.name === 'issueDate' || prop.name === 'saleDate' || prop.name === 'currencyRate' ? (
                                                                <span className="calendar-icon" onClick={(e) => this.props.scrollToArea(e, prop.name, prop.possition)}></span>
                                                            ) : (
                                                                null
                                                            )
                                                        }
                                                        {
                                                            (prop.name === 'dueDate' && focusedTextArea === 'dueDate') || (prop.name === 'issueDate' && focusedTextArea === 'issueDate') || (prop.name === 'saleDate' && focusedTextArea === 'saleDate') ? (
                                                                <div className={`date-picker ${prop.text ? 'move-up' : ''} ${posUpDatePicker ? 'up' : ''}`} style={{ top: posYDatePicker }}>
                                                                    <Calendar
                                                                        selectDay={this.props.onCalendarSelect}
                                                                        unselectDay={this.props.onCalendarUnselecet}
                                                                        selectedDay={
                                                                            this.checkDate(prop.text) ? {
                                                                                display: moment(parseDate(prop.text), 'DD-MM-YYYY').format('LL'),
                                                                                value: moment(prop.text, 'DD-MM-YYYY')
                                                                            } : {
                                                                                display: null,
                                                                                value: null
                                                                            }
                                                                        }
                                                                        projectId={'none'}
                                                                        startDate={this.checkDate(prop.text) ? moment(parseDate(prop.text), 'DD-MM-YYYY') : moment()}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                null
                                                            )
                                                        }
                                                        {
                                                            (prop.name === 'currencyRate' && focusedTextArea === 'currencyRate') ? (
                                                                <div className={`date-picker ${prop.text ? 'move-up' : ''} ${posUpDatePicker ? 'up' : ''}`} style={{ top: posYDatePicker }}>
                                                                    <Calendar
                                                                        selectDay={this.props.onCalendarSelect}
                                                                        unselectDay={this.props.onCalendarUnselecet}
                                                                        selectedDay={
                                                                            currencyRateDate ? {
                                                                                display: moment(currencyRateDate).format('LL'),
                                                                                value: moment(currencyRateDate)
                                                                            } : {
                                                                                display: null,
                                                                                value: null
                                                                            }
                                                                        }
                                                                        projectId={'none'}
                                                                        startDate={currencyRateDate ? moment(currencyRateDate) : moment()}
                                                                    />
                                                                </div>
                                                            ) : null
                                                        }
                                                        {
                                                            prop.name === 'sellerVatId' && prop.text && !this.checkIfVatIdIsValid(prop.text) ? (
                                                                <div className="incorrect-account-number" ref="incorrescAccountNumberRef">
                                                                    {this.context.t('*This VAT ID is not valid. The correctness of the VAT ID for numbers from Poland is checked on the basis of a checksum')}
                                                                </div>
                                                            ) : (
                                                                null
                                                            )
                                                        }
                                                        {
                                                            !showAccountNumberWarningBeforeSave && prop.name === 'bankData' && prop.text && !checkIfAccountNumberIsValid(prop.text) ? (
                                                                <div className="incorrect-account-number" ref="incorrescAccountNumberRef">
                                                                    {this.context.t('The account number provided does not comply with the IBAN standard.')}
                                                                </div>
                                                            ) : (
                                                                null
                                                            )
                                                        }
                                                        {
                                                            prop.name === 'bankData' && prop.text && accountWhiteListStatus === 'notFound' ? (
                                                                <div className="incorrect-account-number" ref="incorrescAccountNumberRef">
                                                                    {this.context.t('Note: The account is not on the White List of VAT taxpayers! (recipient is an active VAT payer)')}
                                                                </div>
                                                            ) : null
                                                        }
                                                        {
                                                            prop.name === 'bankData' && prop.text && returnedOCRData.defaultData && returnedOCRData.defaultData.sellerAccount && prop.text.replace(/[^0-9]/gm, '') !== returnedOCRData.defaultData.sellerAccount.replace(/[^0-9]/gm, '') ? (
                                                                <div className="incorrect-account-number">
                                                                    <p>{this.context.t('*The account number differs from the last number of this counterparty')}</p>
                                                                    <p>{this.context.t('Account number from the last paid document: ')} {this.polihAccountPritier(returnedOCRData.defaultData.sellerAccount)}</p>
                                                                </div>
                                                            ) : (
                                                                null
                                                            )
                                                        }

                                                        {
                                                            prop.name === 'brutto' && prop.text && !this.checkIfBruttoIsValid(prop.text, returnedOCRData.props) ? (
                                                                <div className="incorrect-account-number">
                                                                    <p>{this.context.t(this.invalidBruttoMessage(prop.text, returnedOCRData.props))}</p>
                                                                </div>
                                                            ) : (
                                                                null
                                                            )
                                                        }
                                                        {
                                                            (prop.name === 'netto' || prop.name === 'brutto' || prop.name === 'price') ? (
                                                                <>
                                                                    <span className="currency-label">{currency}</span>
                                                                    <span className="calculator-icon" onClick={(e) => this.props.showCalculator(e, prop.name)}></span>
                                                                </>
                                                            ) : (
                                                                null
                                                            )
                                                        }
                                                        {
                                                            ((prop.name === 'netto' && focusedTextArea === 'netto') || (prop.name === 'brutto' && focusedTextArea === 'brutto') || (prop.name === 'price' && focusedTextArea === 'price')) ? (
                                                                <Calculator returnValue={this.props.setAmountValue} propName={prop.name} ocrMethod={ocrMethod} fileId={currentInvoiceImageData.dbId} posYCalculator={posYCalculator} posUpCalculator={posUpCalculator} />
                                                            ) : (
                                                                null
                                                            )
                                                        }

                                                        {
                                                            prop.name === 'price' && paymentMethod === 'not-paid' && prop.text && !this.checkIfAmountToPayIsValid(prop.text, returnedOCRData.props) ? (
                                                                <div className="incorrect-account-number">
                                                                    <p>{this.context.t(this.invalidAmountToPayMessage(prop.text, returnedOCRData.props))}</p>
                                                                </div>
                                                            ) : (
                                                                null
                                                            )
                                                        }
                                                        {
                                                            prop.name === 'price' ? (
                                                                <>
                                                                    <div className="help-icon" data-for={`amount-to-pay-tooltip`} data-tip='show'></div>

                                                                    <ReactTooltip id={`amount-to-pay-tooltip`} place="top" effect="solid" className="default-tooltip amount-to-pay">
                                                                        {this.context.t('It is by default equal to gross. If document was partially paid you can select it\'s value or correct it manually. After you verify this field select checkbox below to save this document')}
                                                                    </ReactTooltip>

                                                                    {
                                                                        prop.text && paymentMethod === 'not-paid' && currentProjectRole !== 'USER1' ? (
                                                                            <div className="checkmark" onClick={() => this.props.acceptAmountToPay()}>
                                                                                {this.context.t('Amount to pay is correct')}
                                                                                <div className={`checkbox ${acceptedAmounttoPay ? 'checked' : ''}`}></div>
                                                                            </div>
                                                                        ) : (
                                                                            null
                                                                        )
                                                                    }

                                                                    {
                                                                        gfProjects.includes(currentInvoiceImageData.projectId) && !costSplit ? (
                                                                            <div className="cost-split-button" onClick={() => this.splitDocAmount(true)}>{this.context.t('Split payment into cost categories')}</div>
                                                                        ) : null
                                                                    }


                                                                    {
                                                                        gfProjects.includes(currentInvoiceImageData.projectId) && costSplit ? (
                                                                            <CostSplit
                                                                                fileId={currentInvoiceImageData?.dbId}
                                                                                ftvId={currentInvoiceImageData?.ftvId}
                                                                                totalNetAmount={returnedOCRData.props.find(e => e.name === 'netto')?.text}
                                                                                totalGrossAmount={returnedOCRData.props.find(e => e.name === 'brutto')?.text}
                                                                                costSplitAllocation={costSplitAllocation}
                                                                                setCostSplitAllocation={this.props.setCostSplitAllocation}
                                                                                setCombinedTags={this.props.setTags}
                                                                                tagsList={tagsList}
                                                                                canAddNewTags={tagsCreationLimitation === 'EVERYONE' || tagsCreationLimitation.includes(currentProjectRole)}
                                                                                splitDocAmount={this.splitDocAmount}
                                                                                isAccountantInCurrentCompany={isAccountantInCurrentCompany}
                                                                                companyId={this.props.companyId}
                                                                                currency={currency}
                                                                                paid={paymentMethod !== 'not-paid'}
                                                                            />
                                                                        ) : null
                                                                    }

                                                                    {
                                                                        waProjects.includes(currentInvoiceImageData.projectId) ? (
                                                                            <MpkSplitWineAvenue
                                                                                fileId={currentInvoiceImageData?.dbId}
                                                                                ftvId={currentInvoiceImageData?.ftvId}
                                                                                totalNetAmount={returnedOCRData.props.find(e => e.name === 'netto')?.text}
                                                                                mpkSplitWineAvenue={mpkSplit}
                                                                                setMpkSplit={this.props.setMpkSplit}
                                                                                isAccountantInCurrentCompany={isAccountantInCurrentCompany}
                                                                                companyId={this.props.companyId}
                                                                                currency={currency}
                                                                                paid={paymentMethod !== 'not-paid'}
                                                                            />
                                                                        ) : null
                                                                    }

                                                                    {
                                                                        [13, 633].includes(currentInvoiceImageData.projectId) ? (
                                                                            <MpkSplitAventine
                                                                                fileId={currentInvoiceImageData?.dbId}
                                                                                ftvId={currentInvoiceImageData?.ftvId}
                                                                                totalNetAmount={returnedOCRData.props.find(e => e.name === 'netto')?.text}
                                                                                mpkSplitAventine={mpkSplit}
                                                                                setMpkSplit={this.props.setMpkSplit}
                                                                                isAccountantInCurrentCompany={isAccountantInCurrentCompany}
                                                                                companyId={this.props.companyId}
                                                                                currency={currency}
                                                                                paid={paymentMethod !== 'not-paid'}
                                                                            />
                                                                        ) : null
                                                                    }
                                                                </>
                                                            ) : (
                                                                null
                                                            )
                                                        }

                                                        {
                                                            showAccountNumberWarningBeforeSave && prop.name === 'bankData' && prop.text && !checkIfAccountNumberIsValid(prop.text) ? (
                                                                <div className="incorrect-account-number-save-warning">
                                                                    <div className="close" onClick={() => this.props.hideAccountNumberWarning()}></div>
                                                                    <div className="text">
                                                                        {this.context.t('The account number provided does not comply with the IBAN standard.')}
                                                                    </div>
                                                                    <div className="save" onClick={() => this.props.saveData(linkedFiles, true)}>
                                                                        {this.context.t('Ignore and Save')}
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                null
                                                            )
                                                        }

                                                        {
                                                            prop.name === 'countryCode' ? (
                                                                <>
                                                                    {
                                                                        this.props.showCountriesList ? (
                                                                            <CountriesList
                                                                                headerText={this.context.t('Country:')}
                                                                                selectedOption={prop.text}
                                                                                select={this.setSellerCountryCode}
                                                                            />
                                                                        ) : (
                                                                            null
                                                                        )
                                                                    }
                                                                    <div className="help-icon" data-for={`countryCode-tooltip`} data-tip='show'></div>

                                                                    <ReactTooltip id={`countryCode-tooltip`} place="top" effect="solid" className="default-tooltip amount-to-pay">
                                                                        {this.context.t('Seller address and country code are optional fields. If you do not provide this information, you will not be blae to included this document in file to import currency transfers to your bank')}
                                                                    </ReactTooltip>
                                                                </>
                                                            ) : (
                                                                null
                                                            )
                                                        }
                                                        {
                                                            prop.name === 'swift' ? (
                                                                <>
                                                                    <div className="help-icon" data-for={`swift-tooltip`} data-tip='show'></div>

                                                                    <ReactTooltip id={`swift-tooltip`} place="top" effect="solid" className="default-tooltip amount-to-pay">
                                                                        {this.context.t('SWIFT/BIC code is an optional field. If you do not provide this information, you will not be able to included this document in file to import currency transfers to your bank')}
                                                                    </ReactTooltip>
                                                                </>
                                                            ) : (
                                                                null
                                                            )
                                                        }

                                                        {
                                                            prop.name === 'invoiceNumber' ? (
                                                                <div className="connect-with-other-docs" onClick={() => this.toggleDocLinkingPopup()}>{this.context.t('Link to other documents')}</div>
                                                            ) : (
                                                                null
                                                            )
                                                        }
                                                    </div>
                                                )
                                            } else {
                                                return
                                            }
                                        })
                                    ) : (
                                        null
                                    )
                                }
                                <div className={`ocr-data`}>
                                    <label>{this.context.t('Comment')}</label>
                                    <TextareaAutosize
                                        className={``}
                                        value={comment}
                                        name='comment'
                                        onChange={(e) => this.props.setComment(e)}
                                        maxRows={10}
                                        onBlur={e => this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'comment', comment, '')}
                                    />
                                </div>

                                <div className={`ocr-data`}>
                                    <label>{this.context.t('Document description')}</label>
                                    <TextareaAutosize
                                        className={``}
                                        value={documentDescription}
                                        name='doc_description'
                                        onChange={(e) => this.props.setDocComment(e)}
                                        maxRows={10}
                                        onBlur={e => this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'doc_description', documentDescription, '')}
                                    />
                                </div>



                                {
                                    ![607, 608].includes(currentInvoiceImageData.projectId) ? (
                                        <TagsComponent
                                            currentTags={tags}
                                            tagsList={tagsList}
                                            canAddNewTags={tagsCreationLimitation === 'EVERYONE' || tagsCreationLimitation.includes(currentProjectRole)}
                                            setTags={this.props.setTags}
                                        />
                                    ) : null
                                }

                                {
                                    (waproUsers.includes(user.id) && isAccountantInCurrentCompany) ? (
                                        <div className={`wapro-info-container ${this.props.waproData?.showVatRateSelector ? 'show-vat-rate' : ''}`}>
                                            <div className="header">{this.context.t('Required fields for documents imported to WAPRO Fakir')}</div>
                                            <OcrPageWaproFakirDocDictionary
                                                selectedOption={this.props.waproData?.fakirDictionary || ''}
                                                select={this.setWaproField}
                                            />
                                            <OcrPageWaproMagDocTypes
                                                selectedOption={this.props.waproData?.magType || ''}
                                                select={this.setWaproField}
                                            />
                                            <OcrPageWaproFakirDocTypes
                                                selectedOption={this.props.waproData?.fakirType || ''}
                                                select={this.setWaproField}
                                            />
                                            <OcrPageWaproNettoAccounts
                                                selectedOption={this.props.waproData?.netAccount || ''}
                                                select={this.setWaproField}
                                                companyId={this.props.companyId}
                                            />

                                            {
                                                this.props.waproData?.showVatRateSelector ? (
                                                    <OcrPageWaproVatRate
                                                        selectedOption={this.props.waproData?.vatRate || ''}
                                                        select={this.setWaproField}
                                                    />
                                                ) : null
                                            }
                                            <OcrPageWaproDocDescription
                                                selectedOption={this.props.waproData?.docDescription || ''}
                                                select={this.setWaproField}
                                                companyId={this.props.companyId}
                                            />
                                            <div className="checkmark left" onClick={() => this.setWaproField({'cashAccountingScheme': !this.props.waproData?.cashAccountingScheme})}>
                                                {this.context.t('Metoda kasowa')}
                                                <div className={`checkbox ${this.props.waproData?.cashAccountingScheme ? 'checked' : ''}`}></div>
                                            </div>
                                        </div>
                                    ) : (
                                        null
                                    )
                                }

                                {
                                    this.props.companyId === 1607 || user.id === 2 || user.id === 76 ? (
                                        <div className="wapro-info-container">
                                            <div className="header">{this.context.t('Required fields for documents imported to Symfonia')}</div>
                                            <OcrPageSymfoniaDocTypes
                                                selectedOption={this.props.symfoniaData.docType}
                                                list={this.state.symfoniaDocTypes}
                                                select={this.setSymfoniaDocType}
                                            />
                                            <OcrPageSymfoniaDocDescription
                                                selectedOption={this.props.symfoniaData.docDescription}
                                                select={this.setSymfoniaDocDescription}
                                            />
                                            <OcrPageSymfoniaNetAccount
                                                selectedOption={this.props.symfoniaData.netAccount}
                                                list={this.state.symfoniaAccounts}
                                                select={this.selectSymfoniaNetAccount}
                                            />
                                            <OcrPageSymfoniaVatAccount
                                                selectedOption={this.props.symfoniaData.vatAccount}
                                                list={this.state.symfoniaAccounts}
                                                select={this.selectSymfoniaVatAccount}
                                            />
                                            <OcrPageSymfoniaGrossAccount
                                                selectedOption={this.props.symfoniaData.grossAccount}
                                                list={this.state.symfoniaAccounts}
                                                select={this.selectSymfoniaGrossAccount}
                                            />
                                            <OcrPageSymfoniaRegisters
                                                symfoniaRegisters={this.props.symfoniaData.vatRegisters}
                                                ratesList={this.state.symfoniaVatRates}
                                                registersList={this.state.symfoniaRegisters}
                                                editSymfoniaRegisters={this.editSymfoniaRegisters}
                                            />
                                        </div>
                                    ) : (
                                        null
                                    )
                                }

                                {
                                    devCostsProjects.includes(currentInvoiceImageData.projectId) && devCostsUsers.includes(user.id) ? (
                                        <div className="wapro-info-container">
                                            <div className="header">{this.context.t('Required fields for DevCosts')}</div>
                                            <ProjectCode
                                                projectId={currentInvoiceImageData.projectId}
                                                selectedOption={this.props.WL_DevCosts?.projectCode || ''}
                                                select={this.WL_setProjectCode}
                                            />
                                            <Technology
                                                selectedOption={this.props.WL_DevCosts?.technology || ''}
                                                select={this.WL_setTechnology}
                                            />
                                            <DevExCode
                                                selectedOption={this.props.WL_DevCosts?.devExType1 || ''}
                                                select={this.WL_setDevExType1}
                                            />
                                            <DevExType
                                                selectedOption={this.props.WL_DevCosts?.devExType2 || ''}
                                                devExCode={this.props.WL_DevCosts.devExType1}
                                                select={this.WL_setDevExType2}
                                            />
                                            <TransactionDescription
                                                lang={'PL'}
                                                selectedOption={this.props.WL_DevCosts?.transactionDescriptionPl || ''}
                                                onChange={this.WL_setTransactionDescriptionPl}
                                                autoSave={this.WL_autoSaveTransactionDescriptionPl}
                                            />
                                            {/* <TransactionDescription
                                                lang={'EN'}
                                                selectedOption={this.props.WL_DevCosts?.transactionDescriptionEn || ''}
                                                onChange={this.WL_setTransactionDescriptionEn}
                                                autoSave={this.WL_autoSaveTransactionDescriptionEn}
                                            /> */}
                                            <div className="checkmark left" onClick={() => this.WL_setLockedForDevEx(!this.props.WL_DevCosts?.lockedForDevEx)}>
                                                {this.context.t('Block the possibility of acceptance while filling out the DevEx fields')}
                                                <div className={`checkbox ${this.props.WL_DevCosts?.lockedForDevEx ? 'checked' : ''}`}></div>
                                                {
                                                    this.props.WL_DevCosts?.lockedForDevEx && this.state.userThatLockedDevEx ? (
                                                        <p>{this.context.t('Locked by: {u}', { u: <span className="bold">{this.state.userThatLockedDevEx}</span> })}</p>
                                                    ) : null
                                                }
                                            </div>

                                        </div>
                                    ) : null
                                }
                            </SimpleBar>
                        </div>
                    </div>

                    <div className="action-buttons" ref={this.props.setButtonsRef}>
                        {
                            currentProjectRole !== 'USER1' ? (
                                <div className="checkmark" onClick={() => this.props.confirmData()}>
                                    {this.context.t('I confirm the compliance of the data in the form with the data on the original document')}
                                    <div className={`checkbox ${dataConfirmCheckbox ? 'checked' : ''}`}></div>
                                </div>
                            ) : (
                                null
                            )
                        }

                        {
                            currentProjectRole === 'USER1' ? (
                                <button type="button" className={`button`} onClick={e => this.props.savePartialOcrData(linkedFiles)}>{this.context.t("Send for approval")}</button>
                            ) : (
                                <button type="button" className={`button`} onClick={e => this.props.saveData(linkedFiles)}>{(currentProjectRole === 'USER2' || currentProjectRole === 'USER3') ? this.context.t("Accept and Save") : this.context.t("Send for approval")}</button>
                            )
                        }
                    </div>
                </div>
            )
        } else {
            return (
                <div className="by-hand-wrapper">
                    {
                        showLinkDocsPopup ? (
                            <OcrPageLinkDocsPopup
                                linkedFiles={linkedFiles}
                                docsList={docsList}
                                agreementsList={agreementsList}
                                counterpartyVatId={counterpartyVatId}
                                foundDocsFromCounterparty={gotAllDocsRespons ? foundDocsFromCounterparty : true}
                                cancel={this.hideLinkedFilesPopup}
                                save={this.saveLinkedFiles}
                            />
                        ) : null
                    }
                    <div className="custome-simple-bar-wrapper" ref={this.props.setFormRef}>
                        <SimpleBar style={{ height: calcHeight, width: 'calc(100% + 20px)', marginLeft: '-10px', paddingLeft: '10px', marginRight: '-10px', paddingRight: '10px' }}>
                            {/* <div className="info-text">
                                {this.context.t('Please verify the correctness of the data in the form below with the data on the document. Enter any changes manually or frame the appropriate information on the document and select the corresponding form field from the menu (available under the right mouse button).')}
                            </div> */}
                            <div className="checkmark" onClick={() => this.toggleEmailConfirmation()}>
                                {this.context.t('Please send me a confirmation of payment')}
                                <div className={`checkbox ${emailToSendPaymentConfirmation && emailToSendPaymentConfirmation.includes(user.email) ? 'checked' : ''}`}></div>
                            </div>
                            <div className="checkmark" onClick={(e) => this.props.swithcMode(e)}>
                                {this.context.t('There is no VAT ID of the seller or the invoice is handwritten? Edit manually')}
                                <div className={`checkbox ${ocrMethod !== 'select' ? 'checked' : ''}`}></div>
                            </div>
                            {
                                returnedOCRData && returnedOCRData.manualProps ? (
                                    returnedOCRData.manualProps.map((prop, i) => {
                                        console.log('Wszystkie PROPY Manual:', prop)
                                        if (prop.name && prop.name !== 'sellerData' && prop.name !== 'buyerData') {
                                            return (
                                                <div className={`ocr-data ${prop.name} ${this.getClassNameForOcrData(prop.name, prop.text)}`} key={i} ref={prop.name + "PropRef"} onClick={e => this.props.showDatePicker(e)}>
                                                    <label className={prop.name}>
                                                        {this.context.t(prop.title)}
                                                        {
                                                            processingData && processingData.find(e => e === prop.name) ? (
                                                                <span className="loading"><Dots size={9} speed={0.8} color={'#69B792'} /></span>
                                                            ) : null
                                                        }
                                                    </label>
                                                    <TextareaAutosize
                                                        key={`prop-field-${prop.name}-${returnedOCRData.file}`}
                                                        id={`prop-field-${prop.name}`}
                                                        className={`${prop.name} ${!acceptedAmounttoPay && prop.name === 'price' ? 'price-gray' : ''} ${focusedTextArea === prop.name ? 'focused' : ''}`}
                                                        value={prop.text}
                                                        onChange={(e) => this.props.onInputChangeByHand(e, currentInvoiceImageData.dbId, prop.name)}
                                                        onClick={(e) => this.props.scrollToArea(e, prop.name, null)}
                                                        name={prop.name}
                                                        onBlur={e => { this.blurTextArea(e, prop.name, currentInvoiceImageData.dbId); this.props.autoSaveOcr(currentInvoiceImageData.ftvId, prop.name, prop.text, 'costsManual') }}
                                                        onFocus={e => this.focusTextArea(e, prop.name)}
                                                        maxRows={prop.name === 'buyerName' ? 1 : 10}
                                                        readOnly={prop.name === 'countryCode'}
                                                    />

                                                    {
                                                        prop.name === 'sellerName' && counterpartiesList.length > 0 && prop.text && this.foundAtLeastOneSimilarCounterparty(prop.text) && showCounterpartiesList ? (
                                                            <div className="counterparties-list">
                                                                <div className="header">
                                                                    {this.context.t('Your counterparties with similar name:')}
                                                                </div>
                                                                <SimpleBar style={{ height: 'auto', maxHeight: '300px', width: '100%' }}>
                                                                    <ul>
                                                                        {
                                                                            counterpartiesList.map(c => {
                                                                                if (this.checkIfCounterpartyNameIsSimilar(prop.text, c.name)) {
                                                                                    return (
                                                                                        <li tabIndex={"0"} className={`item counterparty-item`} onClick={() => this.props.selectCounterpart(c, currentInvoiceImageData.dbId)}>{c.name}</li>
                                                                                    )
                                                                                } else {
                                                                                    return null
                                                                                }
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </SimpleBar>
                                                            </div>
                                                        ) : (
                                                            null
                                                        )
                                                    }

                                                    {
                                                        prop.name === 'sellerVatId' ? (
                                                            <>
                                                                <span className="help-icon" data-tip='show' data-for='seller-code-tooltip'></span>

                                                                <ReactTooltip className="default-tooltip seller-code" id='seller-code-tooltip' place="bottom" effect='solid'>
                                                                    <p>{this.context.t('Seller code is used in the process of naming new documents. You can change it to make it easier for you to identify files by their name itself')}</p>
                                                                </ReactTooltip>
                                                            </>
                                                        ) : (
                                                            null
                                                        )
                                                    }

                                                    {
                                                        prop.name === 'dueDate' || prop.name === 'issueDate' || prop.name === 'saleDate' || prop.name === 'currencyRate' ? (
                                                            <span className="calendar-icon" onClick={(e) => this.props.scrollToArea(e, prop.name, null)}></span>
                                                        ) : (
                                                            null
                                                        )
                                                    }

                                                    {
                                                        (prop.name === 'dueDate' && focusedTextArea === 'dueDate') || (prop.name === 'issueDate' && focusedTextArea === 'issueDate') || (prop.name === 'saleDate' && focusedTextArea === 'saleDate') ? (
                                                            <div className={`date-picker ${prop.text ? 'move-up' : ''} ${posUpDatePicker ? 'up' : ''}`} style={{ top: posYDatePicker }}>
                                                                <Calendar
                                                                    selectDay={this.props.onCalendarSelect}
                                                                    unselectDay={this.props.onCalendarUnselecet}
                                                                    selectedDay={
                                                                        this.checkDate(prop.text) ? {
                                                                            display: moment(parseDate(prop.text), 'DD-MM-YYYY').format('LL'),
                                                                            value: moment(prop.text, 'DD-MM-YYYY')
                                                                        } : {
                                                                            display: null,
                                                                            value: null
                                                                        }
                                                                    }
                                                                    projectId={'none'}
                                                                    startDate={this.checkDate(prop.text) ? moment(parseDate(prop.text), 'DD-MM-YYYY') : moment()}
                                                                />
                                                            </div>
                                                        ) : (
                                                            null
                                                        )
                                                    }
                                                    {
                                                        (prop.name === 'currencyRate' && focusedTextArea === 'currencyRate') ? (
                                                            <div className={`date-picker ${prop.text ? 'move-up' : ''} ${posUpDatePicker ? 'up' : ''}`} style={{ top: posYDatePicker }}>
                                                                <Calendar
                                                                    selectDay={this.props.onCalendarSelect}
                                                                    unselectDay={this.props.onCalendarUnselecet}
                                                                    selectedDay={
                                                                        currencyRateDate ? {
                                                                            display: moment(currencyRateDate).format('LL'),
                                                                            value: moment(currencyRateDate)
                                                                        } : {
                                                                            display: null,
                                                                            value: null
                                                                        }
                                                                    }
                                                                    projectId={'none'}
                                                                    startDate={currencyRateDate ? moment(currencyRateDate) : moment()}
                                                                />
                                                            </div>
                                                        ) : null
                                                    }
                                                    {
                                                        !showAccountNumberWarningBeforeSave && prop.name === 'bankData' && prop.text && !checkIfAccountNumberIsValid(prop.text) ? (
                                                            <div className="incorrect-account-number" ref="incorrescAccountNumberRef">
                                                                {this.context.t('The account number provided does not comply with the IBAN standard.')}
                                                            </div>
                                                        ) : (
                                                            null
                                                        )
                                                    }

                                                    {
                                                        prop.name === 'bankData' && prop.text && accountWhiteListStatus === 'notFound' ? (
                                                            <div className="incorrect-account-number" ref="incorrescAccountNumberRef">
                                                                {this.context.t('Account not on white list')}
                                                            </div>
                                                        ) : null
                                                    }

                                                    {
                                                        prop.name === 'bankData' && prop.text && returnedOCRData.defaultData && returnedOCRData.defaultData.sellerAccount && prop.text.replace(/[^0-9]/gm, '') !== returnedOCRData.defaultData.sellerAccount.replace(/[^0-9]/gm, '') ? (
                                                            <div className="incorrect-account-number">
                                                                <p>{this.context.t('*The account number differs from the last number of this counterparty')}</p>
                                                                <p>{this.context.t('Account number from the last paid document: ')} {this.polihAccountPritier(returnedOCRData.defaultData.sellerAccount)}</p>
                                                            </div>
                                                        ) : (
                                                            null
                                                        )
                                                    }

                                                    {
                                                        (prop.name === 'netto' || prop.name === 'brutto' || prop.name === 'price') ? (
                                                            <>
                                                                <span className="currency-label">{currency}</span>
                                                                <span className="calculator-icon" onClick={(e) => this.props.showCalculator(e, prop.name)}></span>
                                                            </>
                                                        ) : (
                                                            null
                                                        )
                                                    }
                                                    {
                                                        (prop.name === 'netto' && focusedTextArea === 'netto') || (prop.name === 'brutto' && focusedTextArea === 'brutto') || (prop.name === 'price' && focusedTextArea === 'price') ? (
                                                            <Calculator returnValue={this.props.setAmountValue} propName={prop.name} ocrMethod={ocrMethod} fileId={currentInvoiceImageData.dbId} posYCalculator={posYCalculator} posUpCalculator={posUpCalculator} />
                                                        ) : (
                                                            null
                                                        )
                                                    }

                                                    {
                                                        prop.name === 'brutto' && prop.text && !this.checkIfBruttoIsValid(prop.text, returnedOCRData.manualProps) ? (
                                                            <div className="incorrect-account-number">
                                                                <p>{this.context.t(this.invalidBruttoMessage(prop.text, returnedOCRData.props))}</p>
                                                            </div>
                                                        ) : (
                                                            null
                                                        )
                                                    }

                                                    {
                                                        prop.name === 'price' && prop.text && !this.checkIfAmountToPayIsValid(prop.text, returnedOCRData.manualProps) ? (
                                                            <div className="incorrect-account-number">
                                                                <p>{this.context.t(this.invalidAmountToPayMessage(prop.text, returnedOCRData.manualProps))}</p>
                                                            </div>
                                                        ) : (
                                                            null
                                                        )
                                                    }

                                                    {
                                                        showAccountNumberWarningBeforeSave && prop.name === 'bankData' && prop.text && !checkIfAccountNumberIsValid(prop.text) ? (
                                                            <div className="incorrect-account-number-save-warning">
                                                                <div className="close" onClick={() => this.props.hideAccountNumberWarning()}></div>
                                                                <div className="text">
                                                                    {this.context.t('The account number provided does not comply with the IBAN standard.')}
                                                                </div>
                                                                <div className="save" onClick={() => this.props.saveData(linkedFiles, true)}>
                                                                    {this.context.t('Ignore and Save')}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            null
                                                        )
                                                    }
                                                    {
                                                        prop.name === 'price' ? (
                                                            <>
                                                                <div className="help-icon" data-for={`amount-to-pay-tooltip`} data-tip='show'></div>

                                                                <ReactTooltip id={`amount-to-pay-tooltip`} place="top" effect="solid" className="default-tooltip amount-to-pay">
                                                                    {this.context.t('It is by default equal to gross. If document was partially paid you can select it\'s value or correct it manually.')}
                                                                </ReactTooltip>
                                                                {
                                                                    prop.text && currentProjectRole !== 'USER1' ? (
                                                                        <div className="checkmark" onClick={() => this.props.acceptAmountToPay()}>
                                                                            {this.context.t('Amount to pay is correct')}
                                                                            <div className={`checkbox ${acceptedAmounttoPay ? 'checked' : ''}`}></div>
                                                                        </div>
                                                                    ) : (
                                                                        null
                                                                    )
                                                                }

                                                                {
                                                                    waProjects.includes(currentInvoiceImageData.projectId) ? (
                                                                        <MpkSplitWineAvenue
                                                                            fileId={currentInvoiceImageData?.dbId}
                                                                            ftvId={currentInvoiceImageData?.ftvId}
                                                                            totalNetAmount={returnedOCRData.props.find(e => e.name === 'netto')?.text}
                                                                            mpkSplitWineAvenue={mpkSplit}
                                                                            setMpkSplit={this.props.setMpkSplit}
                                                                            isAccountantInCurrentCompany={isAccountantInCurrentCompany}
                                                                            companyId={this.props.companyId}
                                                                            currency={currency}
                                                                            paid={paymentMethod !== 'not-paid'}
                                                                        />
                                                                    ) : null
                                                                }

                                                                {
                                                                    [13, 633].includes(currentInvoiceImageData.projectId) ? (
                                                                        <MpkSplitAventine
                                                                            fileId={currentInvoiceImageData?.dbId}
                                                                            ftvId={currentInvoiceImageData?.ftvId}
                                                                            totalNetAmount={returnedOCRData.props.find(e => e.name === 'netto')?.text}
                                                                            mpkSplitAventine={mpkSplit}
                                                                            setMpkSplit={this.props.setMpkSplit}
                                                                            isAccountantInCurrentCompany={isAccountantInCurrentCompany}
                                                                            companyId={this.props.companyId}
                                                                            currency={currency}
                                                                            paid={paymentMethod !== 'not-paid'}
                                                                        />
                                                                    ) : null
                                                                }
                                                            </>
                                                        ) : (
                                                            null
                                                        )
                                                    }
                                                    {
                                                        prop.name === 'countryCode' ? (
                                                            <>
                                                                {
                                                                    this.props.showCountriesList ? (
                                                                        <CountriesList
                                                                            headerText={this.context.t('Country:')}
                                                                            selectedOption={prop.text}
                                                                            select={this.setSellerCountryCode}
                                                                        />
                                                                    ) : (
                                                                        null
                                                                    )
                                                                }
                                                                <div className="help-icon" data-for={`countryCode-tooltip`} data-tip='show'></div>

                                                                <ReactTooltip id={`countryCode-tooltip`} place="top" effect="solid" className="default-tooltip amount-to-pay">
                                                                    {this.context.t('Seller address and country code are optional fields. If you do not provide this information, you will not be blae to included this document in file to import currency transfers to your bank')}
                                                                </ReactTooltip>
                                                            </>
                                                        ) : (
                                                            null
                                                        )
                                                    }
                                                    {
                                                        prop.name === 'invoiceNumber' ? (
                                                            <div className="connect-with-other-docs" onClick={() => this.toggleDocLinkingPopup()}>{this.context.t('Link to other documents')}</div>
                                                        ) : (
                                                            null
                                                        )
                                                    }
                                                </div>
                                            )
                                        } else {
                                            return
                                        }
                                    })
                                ) : (
                                    null
                                )
                            }
                            <div className={`ocr-data`}>
                                <label>{this.context.t('Comment')}</label>
                                <TextareaAutosize
                                    className={``}
                                    value={comment}
                                    name='comment'
                                    onChange={(e) => this.props.setComment(e)}
                                    maxRows={10}
                                    onBlur={e => this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'comment', comment, '')}
                                />
                            </div>
                            <div className={`ocr-data`}>
                                <label>{this.context.t('Document description')}</label>
                                <TextareaAutosize
                                    className={``}
                                    value={documentDescription}
                                    name='doc_description'
                                    onChange={(e) => this.props.setDocComment(e)}
                                    maxRows={10}
                                    onBlur={e => this.props.autoSaveOcr(currentInvoiceImageData.ftvId, 'doc_description', documentDescription, '')}
                                />
                            </div>

                            <TagsComponent
                                currentTags={tags}
                                tagsList={tagsList}
                                canAddNewTags={tagsCreationLimitation === 'EVERYONE' || tagsCreationLimitation.includes(currentProjectRole)}
                                setTags={this.props.setTags}
                            />
                            {
                                (waproUsers.includes(user.id) && isAccountantInCurrentCompany) || (this.props.companyId === 1104 && isAccountantInCurrentCompany) ? (
                                    <div className="wapro-info-container">
                                        <div className="header">{this.context.t('Required fields for documents imported to WAPRO Fakir')}</div>
                                        <OcrPageWaproFakirDocDictionary
                                            selectedOption={this.props.waproData?.fakirDictionary || ''}
                                            select={this.setWaproField}
                                        />
                                        <OcrPageWaproMagDocTypes
                                            selectedOption={this.props.waproData?.magType || ''}
                                            select={this.setWaproField}
                                        />
                                        <OcrPageWaproFakirDocTypes
                                            selectedOption={this.props.waproData?.fakirType || ''}
                                            select={this.setWaproField}
                                        />
                                        <OcrPageWaproNettoAccounts
                                            selectedOption={this.props.waproData?.netAccount || ''}
                                            select={this.setWaproField}
                                            companyId={this.props.companyId}
                                        />

                                        {
                                            this.props.waproData?.showVatRateSelector ? (
                                                <OcrPageWaproVatRate
                                                    selectedOption={this.props.waproData?.vatRate || ''}
                                                    select={this.setWaproField}
                                                />
                                            ) : null
                                        }
                                        <OcrPageWaproDocDescription
                                            selectedOption={this.props.waproData?.docDescription || ''}
                                            select={this.setWaproField}
                                            companyId={this.props.companyId}
                                        />
                                        <div className="checkmark left" onClick={() => this.setWaproField({'cashAccountingScheme': !this.props.waproData?.cashAccountingScheme})}>
                                            {this.context.t('Metoda kasowa')}
                                            <div className={`checkbox ${this.props.waproData?.cashAccountingScheme ? 'checked' : ''}`}></div>
                                        </div>
                                    </div>
                                ) : (
                                    null
                                )
                            }

                            {
                                this.props.companyId === 1607 || user.id === 2 || user.id === 76 ? (
                                    <div className="wapro-info-container">
                                        <div className="header">{this.context.t('Required fields for documents imported to Symfonia')}</div>
                                        <OcrPageSymfoniaDocTypes
                                            selectedOption={this.props.symfoniaData.docType}
                                            list={this.state.symfoniaDocTypes}
                                            select={this.setSymfoniaDocType}
                                        />
                                        <OcrPageSymfoniaDocDescription
                                            selectedOption={this.props.symfoniaData.docDescription}
                                            select={this.setSymfoniaDocDescription}
                                        />
                                        <OcrPageSymfoniaNetAccount
                                            selectedOption={this.props.symfoniaData.netAccount}
                                            list={this.state.symfoniaAccounts}
                                            select={this.selectSymfoniaNetAccount}
                                        />
                                        <OcrPageSymfoniaVatAccount
                                            selectedOption={this.props.symfoniaData.vatAccount}
                                            list={this.state.symfoniaAccounts}
                                            select={this.selectSymfoniaVatAccount}
                                        />
                                        <OcrPageSymfoniaGrossAccount
                                            selectedOption={this.props.symfoniaData.grossAccount}
                                            list={this.state.symfoniaAccounts}
                                            select={this.selectSymfoniaGrossAccount}
                                        />
                                        <OcrPageSymfoniaRegisters
                                            symfoniaRegisters={this.props.symfoniaData.vatRegisters}
                                            ratesList={this.state.symfoniaVatRates}
                                            registersList={this.state.symfoniaRegisters}
                                            editSymfoniaRegisters={this.editSymfoniaRegisters}
                                        />
                                    </div>
                                ) : (
                                    null
                                )
                            }
                            {
                                devCostsProjects.includes(currentInvoiceImageData.projectId) && devCostsUsers.includes(user.id) ? (
                                    <div className="wapro-info-container">
                                        <div className="header">{this.context.t('Required fields for DevCosts')}</div>
                                        <ProjectCode
                                            projectId={currentInvoiceImageData.projectId}
                                            selectedOption={this.props.WL_DevCosts.projectCode}
                                            select={this.WL_setProjectCode}
                                        />
                                        <Technology
                                            selectedOption={this.props.WL_DevCosts.technology}
                                            select={this.WL_setTechnology}
                                        />
                                        <DevExCode
                                            selectedOption={this.props.WL_DevCosts.devExType1}
                                            select={this.WL_setDevExType1}
                                        />
                                        <DevExType
                                            selectedOption={this.props.WL_DevCosts.devExType2}
                                            devExCode={this.props.WL_DevCosts.devExType1}
                                            select={this.WL_setDevExType2}
                                        />
                                        <TransactionDescription
                                            lang={'PL'}
                                            selectedOption={this.props.WL_DevCosts.transactionDescriptionPl}
                                            onChange={this.WL_setTransactionDescriptionPl}
                                            autoSave={this.WL_autoSaveTransactionDescriptionPl}
                                        />
                                        {/* <TransactionDescription
                                            lang={'EN'}
                                            selectedOption={this.props.WL_DevCosts.transactionDescriptionEn}
                                            onChange={this.WL_setTransactionDescriptionEn}
                                            autoSave={this.WL_autoSaveTransactionDescriptionEn}
                                        /> */}
                                        <div className="checkmark left" onClick={() => this.WL_setLockedForDevEx(!this.props.WL_DevCosts?.lockedForDevEx)}>
                                            {this.context.t('Block the possibility of acceptance while filling out the DevEx fields')}
                                            <div className={`checkbox ${this.props.WL_DevCosts?.lockedForDevEx ? 'checked' : ''}`}></div>
                                            {
                                                this.props.WL_DevCosts?.lockedForDevEx && this.state.userThatLockedDevEx ? (
                                                    <p>{this.context.t('Locked by: {u}', { u: <span className="bold">{this.state.userThatLockedDevEx}</span> })}</p>
                                                ) : null
                                            }
                                        </div>
                                    </div>
                                ) : null
                            }
                        </SimpleBar>
                    </div>
                    <div className="action-buttons" ref={this.props.setButtonsRef}>
                        {
                            currentProjectRole !== 'USER1' ? (
                                <div className="checkmark" onClick={() => this.props.confirmData()}>
                                    {this.context.t('I confirm the compliance of the data in the form with the data on the original document')}
                                    <div className={`checkbox ${dataConfirmCheckbox ? 'checked' : ''}`}></div>
                                </div>
                            ) : (
                                null
                            )
                        }
                        {
                            currentProjectRole === 'USER1' ? (
                                <button type="button" className={`button`} onClick={e => this.props.savePartialManualData(linkedFiles)}>{this.context.t("Send for approval")}</button>
                            ) : (
                                <button type="button" className={`button`} onClick={() => this.props.saveData(linkedFiles)}>{(currentProjectRole === 'USER2' || currentProjectRole === 'USER3') ? this.context.t("Accept and Save") : this.context.t("Send for approval")}</button>
                            )
                        }
                    </div>
                </div>
            )
        }
    }
}

OcrPageCostsForm.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    user: state.User.user,
    userCompanies: state.Company.companies,

    returnedOCRData: { ...state.File.returnedOCRData },
    processingData: isIterable(state.File.processingData) ? [...state.File.processingData] : [],
    processedData: isIterable(state.File.processedData) ? [...state.File.processedData] : [],
    dataReadWithOcrFrame: isIterable(state.File.dataReadWithOcrFrame) ? [...state.File.dataReadWithOcrFrame] : []
})

const mapDispatchToProps = {
    alertWarn: AlertActions.warning,
    autoSaveOcr: FileActions.autoSaveOcr,
    getUserThatLockedFileTofDevEx: FileActions.getUserThatLockedFileTofDevEx,
    getCompanySymfoniaFile: CompanyActions.getCompanySymfoniaFile,
    getAllDocsFromCounterparty: CompanyActions.getAllDocsFromCounterparty
}

export default connect(mapStateToProps, mapDispatchToProps)(OcrPageCostsForm)