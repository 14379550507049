import React, { Component } from 'react'
import { useSelector, useDispatch, connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from "moment";
import { Link } from 'react-router-dom'
import { reverse } from 'named-urls'

import { history } from '../../helpers/history'
import routes from '../../helpers/routes'
import arrayObjectIndexOf from '../../helpers/indexOfObject'
import isIterable from "../../helpers/isIterable"

import { AlertActions } from '../../actions/AlertActions'
import { FileActions } from '../../actions/FileActions'
import { UserActions } from '../../actions/UserActions'
import { ChatActions } from '../../actions/ChatAction'

import "../../css/ChatNotificationsPopup.css";
import pdfIcon from '../../img/pdf-icon.svg'

export class DocflowNotificationsPopup extends Component {

    constructor(props) {
        super(props)
        this.state = {
            filePreviewProjectId: null,
            filePreviewFileId: null,
            filePreviewType: null,
            waitingToOpenFilePreview: false,
            waitToOpenPaymentOrderCreator: false,
        }

        this.linkRef = React.createRef()
    }

    componentDidMount() {
        document.body.style.overflow = 'hidden'
    }

    componentDidUpdate(prevProps) {
        const { currentProject, userProjects } = this.props
        const { waitingToOpenFilePreview, waitToOpenPaymentOrderCreator } = this.state
        if (waitingToOpenFilePreview) {
            const { filePreviewProjectId, filePreviewFileId, filePreviewType } = this.state
            if (currentProject && currentProject.id === filePreviewProjectId) {
                if (filePreviewType === 'FILE') {
                    const { uploadedFiles } = this.props
                    console.log(uploadedFiles)
                    if (uploadedFiles) {
                        var projectFiles = uploadedFiles.find(e => e.project === filePreviewProjectId)
                        if (projectFiles && projectFiles.loaded) this.openFilePreviewFromUrl(filePreviewProjectId, filePreviewFileId, filePreviewType)
                    }
                } else if (filePreviewType === 'PAYMENT_ORDER') {
                    const { paymentOrders } = this.props
                    if (paymentOrders) {
                        var projectFiles = paymentOrders.find(e => e.project === filePreviewProjectId)
                        if (projectFiles && projectFiles.loaded) this.openFilePreviewFromUrl(filePreviewProjectId, filePreviewFileId, filePreviewType)
                    }
                }
            } else if (userProjects?.length && userProjects.find(p => p.id === filePreviewProjectId)) {
                this.props.switchDashboardViewModel(filePreviewProjectId)
            } else if (userProjects?.length && !userProjects.find(p => p.id === filePreviewProjectId) && filePreviewType === 'FILE') {
                this.setState({
                    waitingToOpenFilePreview: false
                })
                this.checkAccessToFileInPreview(filePreviewProjectId, filePreviewFileId)
            }
        }
    }

    componentWillUnmount() {
        document.body.style.overflow = ''
    }

    handleNotificationClick = notification => {
        if (notification.fileIsInOcr) {
            this.setState({ dynamicPath: reverse(routes.panel.crop, { projectId: notification.projectId, fileId: notification.fileId }) }, () => {
                if (this.linkRef.current) {
                    this.linkRef.current.click(); // Trigger the click programmatically after setting the path
                    this.props.onClose()
                }
            });
        } else {
            this.openFilePreview(notification.projectId, notification.fileId, 'FILE')
            this.props.onClose()
        }
    }

    openFilePreview = (projectId, fileId, previewType) => {
        const { userProjects } = this.props
        var needToWaitForDataToLoad = false
        var projectIndex = arrayObjectIndexOf(userProjects, projectId, 'id')
        if (projectIndex > -1) {
            if (previewType === 'FILE') {
                const { uploadedFiles } = this.props
                projectIndex = arrayObjectIndexOf(uploadedFiles, projectId, 'project')
                if (projectIndex > -1) {
                    this.props.switchDashboardViewModel(projectId)
                    var fileIndex = arrayObjectIndexOf(uploadedFiles[projectIndex].files, fileId, 'id')
                    if (fileIndex > -1) {
                        var fileToOpen = uploadedFiles[projectIndex].files[fileIndex]
                        this.props.getFileData(parseInt(fileToOpen.project_id), fileToOpen.path, fileToOpen.id, fileToOpen.ocr_status, fileToOpen.acceptance_step, true)
                    } else {
                        if (uploadedFiles[projectIndex].files?.length) {
                            this.checkAccessToFileInPreview(projectId, fileId)
                        } else {
                            needToWaitForDataToLoad = true
                        }
                    }
                } else {
                    needToWaitForDataToLoad = true
                }
            } else if (previewType === 'PAYMENT_ORDER') {
                const { paymentOrders } = this.props
                projectIndex = arrayObjectIndexOf(paymentOrders, projectId, "project")
                if (projectIndex > -1) {
                    this.props.switchDashboardViewModel(projectId)
                    var fileIndex = arrayObjectIndexOf(paymentOrders[projectIndex].transfers, fileId, 'id')
                    if (fileIndex > -1) {
                        var paymentOrderToOpen = paymentOrders[projectIndex].transfers[fileIndex]
                        this.props.openTransferPreviewWindow(paymentOrderToOpen)
                    } else {
                        needToWaitForDataToLoad = true
                    }
                } else {
                    needToWaitForDataToLoad = true
                }
            }
        } else {
            console.log(userProjects?.length)
            if (userProjects?.length) {
                this.checkAccessToFileInPreview(projectId, fileId)
            } else {
                needToWaitForDataToLoad = true
            }
        }

        if (needToWaitForDataToLoad) {
            this.setState({
                waitingToOpenFilePreview: true,
                filePreviewProjectId: projectId,
                filePreviewFileId: fileId,
                filePreviewType: previewType
            })
        } else {
            this.setState({
                waitingToOpenFilePreview: false,
                filePreviewProjectId: null,
                filePreviewFileId: null,
                filePreviewType: null
            })
        }
    }

    checkAccessToFileInPreview = (projectId, fileId) => {
        this.props.checkAccessToFileFromUrl(projectId, fileId, (hasAccess, msg) => {
            if (!hasAccess) {
                this.setState({
                    waitingToOpenFilePreview: false,
                    filePreviewProjectId: null,
                    filePreviewFileId: null,
                    filePreviewType: null
                })
                this.props.alertWarn(msg)
                window.history.replaceState(null, null, "/dashboard")
            } else {
                this.setState({
                    waitingToOpenFilePreview: true,
                    filePreviewProjectId: projectId,
                    filePreviewFileId: fileId,
                    filePreviewType: 'FILE'
                })
            }
        })
    }

    render() {
        const { newDocflowNotifications } = this.props
        return (
            <div className="chat-notifications-popup docflow">
                <div className="popup-card">
                    <div className="header">
                        <h4>{this.context.t("You have new docflow notification")}</h4>
                    </div>
                    <div className="notifications-wrapper">
                        <Link
                            to={this.state.dynamicPath}
                            ref={this.linkRef} // Attach the ref
                            style={{ display: "none" }}
                        ></Link>
                        <div className="list">
                            {
                                newDocflowNotifications.map(notification => (
                                    <div onClick={() => this.handleNotificationClick(notification)} key={notification.fileId} className="file">
                                        <span className="project-code">{`[${notification.projectCode}]`}</span>
                                        <img src={pdfIcon} alt="" />
                                        <span className="file-name">{`${notification.title}`}</span>
                                        <span className="message">{`${this.context.t('Message')}: ${notification.description}`}</span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <button onClick={() => this.props.onClose()} className="button">{this.context.t('Close')}</button>
                </div>
            </div>
        )
    }
}

DocflowNotificationsPopup.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    userProjects: state.User.userProjects,
    currentProject: state.User.currentProject || {},
    userCompanies: state.Company.companies || [],
    currentCompany: state.Company.currentCompany,

    uploadedFiles: isIterable(state.File.uploadedFiles) ? [...state.File.uploadedFiles] : [],
    paymentOrders: isIterable(state.Transfers.transfers) ? [...state.Transfers.transfers] : [],
})

const mapDispatchToProps = {
    switchDashboardViewModel: UserActions.switchDashboardViewModel,

    getFileData: FileActions.getFileData,
    checkAccessToFileFromUrl: FileActions.checkAccessToFileFromUrl,
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(DocflowNotificationsPopup);
