import myFetch from "../helpers/fetch"


import { ProjectCostsConsts } from "../reducers/ProjectCostsReducer"
import { CompanyConst } from "../reducers/CompanyReducer"
import { ReportsConst } from "../reducers/ReportsReducer"
import { EventConst } from "../reducers/EventReducer"
import { FileConst } from "../reducers/FileReducer"
import { UserConst } from "../reducers/UserReducer"
import { AlertActions } from "./AlertActions"

export const ProjectCostsActions = {
    extendProjectCosts,
    getProjectCosts,
}

function extendProjectCosts(projectId, extended) {
    return dispatch => {
        dispatch({ type: ProjectCostsConsts.EXTEND_PROJECT_COSTS, projectId: projectId, extended: extended })
    }
}

function getProjectCosts(projectId) {
    return dispatch => {
        dispatch({ type: ProjectCostsConsts.SEND_PROJECT_COSTS_REQUEST, projectId: projectId })
        myFetch(`/projects/get-costs/${projectId}`, { methid: 'get' }).then(res => {
            if (res.success) {
                dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: res.costs })
            } else {
                dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: {} })
            }
        }).catch(err => {
            dispatch({ type: ProjectCostsConsts.GET_PROJECT_COSTS, projectId: projectId, costs: {} })
            handleError(err, dispatch, `/projects/get-costs/${projectId}`)
        })
    }
}


function handleError(error, dispatch, path) {
    if (error?.code === 'AUTHORIZATION_REQUIRED' && localStorage.getItem('dashboardProject')) {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        dispatch({ type: UserConst.LOGGED_FALSE })
        dispatch({ type: UserConst.LOGOUT })
        dispatch({ type: EventConst.LOGOUT })
        dispatch({ type: CompanyConst.LOGOUT })
        dispatch({ type: ReportsConst.LOGOUT })
        dispatch({ type: FileConst.LOGOUT })
        dispatch(AlertActions.info("You have been logged out.", 5000))
    } else {
        dispatch(AlertActions.setError(error, path));
        dispatch(AlertActions.danger("There was an internal server error while procesing request. Try again later."));
    }
}