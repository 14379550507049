import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import routes from '../helpers/routes';

export const PrivateRoute = ({ component: Component, userData, ...rest }) => (
    <Route {...rest} render={props => {
        if (userData === null) {
            return <Redirect to={{ pathname: routes.login, state: { from: props.location } }} />
        } else {
            var baseUrl = props.location.pathname.split('/')[1]
            console.log(baseUrl)
            console.log(Component)
            switch (baseUrl) {
                case "":
                    return <Component {...props} />
                case "v2":
                    console.log('v2')
                    console.log('v2')
                    console.log('v2')
                    console.log('v2')
                    console.log('v2')
                    return <Component {...props} />
                case "dashboard":
                    return <Component {...props} />
                case "helpdesk":
                    return <Component {...props} />
                case "ocr":
                    if (props.location.pathname.split('/')[2]) {
                        return <Component {...props} />
                    } else {
                        return <Redirect to={{ pathname: routes.panel.dashboard }} />
                    }
                case "docs":
                    return <Component {...props} />
                case "sales":
                    return <Component {...props} />
                case "calendar":
                    return <Component {...props} />
                case "actions":
                    return <Component {...props} />
                case "settings":
                    if (props.location.pathname.split('/')[2] === 'profile') {
                        return <Component {...props} />
                    } else {
                        if (userData.role === "ADMIN" || (userData.role === "SUPERADMIN" && userData.id !== 417) || userData.companyRole === "ADMIN") {
                            return <Component {...props} />
                        } else {
                            return <Redirect to={{ pathname: routes.panel.dashboard }} />
                        }
                    }
                case "superadmin":
                    if (userData.role === "SUPERADMIN" || userData.id === 417) {
                        return <Component {...props} />
                    } else {
                        //return <Component {...props} />
                        return <Redirect to={{ pathname: routes.panel.dashboard }} />
                    }
                default:
                    return <Redirect to={{ pathname: routes.home }} />
            }
        }
    }} />
)

