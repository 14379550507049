import React, { Component } from "react";
import { NavLink, Link, Route, Redirect, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isFirefox } from "react-device-detect";
import ReactTooltip from 'react-tooltip'

import { TabsControlerActions } from '../../actions/V2_TabsControlerActions'

import V2_DashboardComponent from "./V2_Dashboard";
import OcrPage from "../User/OcrPage";
import AllDocs from "../User/FileManagerPage";
import AllSaleInvoices from "../User/SalesPage";
import ActionsTable from "../User/Reports";
import ExportBankTransfers from '../../components/QuickActionsComponents/ExportBankTransfers'
import ExportDocs from '../../components/QuickActionsComponents/ExportDocs'
import ExpensesAssistant from '../../components/QuickActionsComponents/ExpensesAssistant'
import SettingComponent_Profile from "../User/Settings";
import SettingComponent_Company from "../User/Admin/Company";
import SettingComponent_Projects from "../User/Admin/ProjectsManagement/ProjectsManagement";
import SettingComponent_Counterparties from "../User/Admin/CounterpartiesList";
import SettingComponent_Subscription from "../User/Admin/Subscription";
import HelpdeskComponent from "../User/Help";
import SuperadminComponent from "../User/SuperAdmin/Management";
import CreateTransferOrderWindow from '../../components/CreateTransferOrderWindow'

import { AvailableTabs, ActionsTabsComponents, ActionsHistoryTabsComponents, SettingsTabsComponents, SuperadminTabsComponents } from "../../reducers/V2_TabsReducer"


export class TabsController extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

        this.tabWrapperRef = React.createRef()
    }

    componentDidUpdate(prevProps) {
        const { currentProject } = this.props
        if (currentProject?.id !== prevProps.currentProject?.id) {
            this.props.closeTabsAfterPtojectChange()
        }
    }

    returnTabComponent = (tabType, tabComponent, tabIsActive, additionalOptions) => {
        const { location, history, currentProject } = this.props
        switch (tabType) {
            case AvailableTabs.DASHBOARD:
                return <V2_DashboardComponent
                    tabIsActive={tabIsActive}
                    location={location}
                    history={history}
                />
            case AvailableTabs.OCR:
                return <OcrPage
                    tabIsActive={tabIsActive}
                    location={location}
                    history={history}
                    urlProjectId={additionalOptions.projectId}
                    urlFileId={additionalOptions.fileId}
                />
            case AvailableTabs.GENERATE_PAYMENT_ORDER:
                return <CreateTransferOrderWindow
                    tabIsActive={tabIsActive}
                    newEasyDocsVersion={true}
                    projectId={additionalOptions.projectId}
                    companyId={additionalOptions.companyId}
                />
            case AvailableTabs.GENERATE_SALE_INVOICE:
                return <AllSaleInvoices
                    tabIsActive={tabIsActive}
                    subComponent={tabComponent}
                    originalInvoice={additionalOptions.originalInvoice || null}
                    isEditingInvoice={additionalOptions.isEditingInvoice || false}
                    isInvoiceFromProforma={additionalOptions.isInvoiceFromProforma || false}
                    isSimilarInvoice={additionalOptions.isSimilarInvoice || false}
                    location={location}
                    history={history}
                />
            case AvailableTabs.FILE_PREVIEW:
                return 'Document preview'
            case AvailableTabs.ACTIONS_TABLE:
                if (tabComponent === ActionsTabsComponents.ACTIONS)
                    return <ActionsTable
                        tabIsActive={tabIsActive}
                        subComponent={'TABLE'}
                        location={location}
                        history={history}
                    />
                if (tabComponent === ActionsTabsComponents.PAYMRNTS_ASSISTANT)
                    return <ExportBankTransfers
                        tabIsActive={tabIsActive}
                    />
                if (tabComponent === ActionsTabsComponents.ZIP_ASSISTANT)
                    return <ExportDocs
                        tabIsActive={tabIsActive}
                    />
                if (tabComponent === ActionsTabsComponents.EXPENSES_ASSISTANT)
                    return <ExpensesAssistant
                        tabIsActive={tabIsActive}
                    />
            case AvailableTabs.ACTIONS_HISTORY:
                if (tabComponent === ActionsHistoryTabsComponents.GENERATED_EXCELS)
                    return <ActionsTable
                        tabIsActive={tabIsActive}
                        subComponent={'EXCELS'}
                        location={location}
                        history={history}
                    />
                if (tabComponent === ActionsHistoryTabsComponents.GENERATED_TRANSFERS)
                    return <ActionsTable
                        tabIsActive={tabIsActive}
                        subComponent={'TRANSFERS'}
                        location={location}
                        history={history}
                    />
                if (tabComponent === ActionsHistoryTabsComponents.GENERATED_ZIPS)
                    return <ActionsTable
                        tabIsActive={tabIsActive}
                        subComponent={'ZIPS'}
                        location={location}
                        history={history}
                    />
                if (tabComponent === ActionsHistoryTabsComponents.GENERATED_ACCOUNTING_FILES)
                    return <ActionsTable
                        tabIsActive={tabIsActive}
                        subComponent={'ACCOUNTING'}
                        location={location}
                        history={history}
                    />
            case AvailableTabs.ALL_DOCS:
                return <AllDocs
                    tabIsActive={tabIsActive}
                />
            case AvailableTabs.ALL_SALE_INVOICES:
                return <AllSaleInvoices
                    tabIsActive={tabIsActive}
                />
            case AvailableTabs.SETTINGS:
                if (tabComponent === SettingsTabsComponents.PROFILE)
                    return <SettingComponent_Profile
                        tabIsActive={tabIsActive}
                    />
                if (tabComponent === SettingsTabsComponents.COMPANY)
                    return <SettingComponent_Company
                        tabIsActive={tabIsActive}
                    />
                if (tabComponent === SettingsTabsComponents.BINDERS)
                    return <SettingComponent_Projects
                        tabIsActive={tabIsActive}
                    />
                if (tabComponent === SettingsTabsComponents.COUNTERPARTIES)
                    return <SettingComponent_Counterparties
                        tabIsActive={tabIsActive}
                    />
                if (tabComponent === SettingsTabsComponents.SUBSCRIPTION)
                    return <SettingComponent_Subscription
                        tabIsActive={tabIsActive}
                    />
            case AvailableTabs.HELPDESK:
                return <HelpdeskComponent
                    tabIsActive={tabIsActive}
                    location={location}
                />
            case AvailableTabs.SUPERADMIN:
                if (tabComponent === SuperadminTabsComponents.COMPANIES)
                    return <SuperadminComponent
                        tabIsActive={tabIsActive}
                        wrapperRef={this.tabWrapperRef}
                    />
                if (tabComponent === SuperadminTabsComponents.BINDERS) return 'Superadmin: Binders'
                if (tabComponent === SuperadminTabsComponents.USERS) return 'Superadmin: Users'
                if (tabComponent === SuperadminTabsComponents.WHATS_NEW) return 'Superadmin: Whats new?'
                if (tabComponent === SuperadminTabsComponents.EMAILS) return 'Superadmin: Info emails'
                if (tabComponent === SuperadminTabsComponents.PAYMENTS) return 'Superadmin: Payments'
                if (tabComponent === SuperadminTabsComponents.CONVERSIONS) return 'Superadmin: Conversions'
            default:
                return null
        }
    }

    tabClick = index => {
        const { currentTabIndex } = this.props
        if (index !== currentTabIndex) this.props.setCurrentTab(index)
    }

    closeTabClick = (e, index) => {
        e.stopPropagation()
        this.props.closeTab(index)
    }

    render() {
        const {
            tabsWrapperClasses,
            currentTabIndex, allOpenedTabs
        } = this.props
        return (
            <div className={`tabs-wrapper ${tabsWrapperClasses}`} >
                <div className="instruction-number" data-tip='show' data-for='instruction-tooltip-2'>2</div>
                <div className="tabs-bar">
                    {
                        allOpenedTabs.map((tab, index) => {
                            return (
                                <div
                                    className={`tab-title ${index === 0 ? 'dashboard-title' : ''} ${index === currentTabIndex ? 'current-tab' : ''}`}
                                    style={{
                                        maxWidth: index > 0 ?
                                            `calc(${parseFloat(100 / (allOpenedTabs.length - 1)).toFixed(2)}% - ${70 / (allOpenedTabs.length - 1)}px)`
                                            :
                                            '70px'
                                    }}
                                    onClick={() => this.tabClick(index)
                                    }>
                                    <span className="title">{this.context.t(tab.title)}</span>
                                    {
                                        index > 0 ? (
                                            <div className="close-tab" onClick={e => this.closeTabClick(e, index)}></div>
                                        ) : null
                                    }
                                </div>
                            )
                        })
                    }
                    <div className="filler"></div>
                </div>
                <div className={`tabs-container ${isFirefox ? 'firefox-scrollbars' : ''}`} ref={this.tabWrapperRef}>
                    {
                        allOpenedTabs.map((tab, index) => {
                            return (
                                this.returnTabComponent(tab.type, tab.component, index === currentTabIndex, tab.additionalOptions)
                            )
                        })
                    }
                </div>

                <ReactTooltip id='instruction-tooltip-2' className='default-tooltip instruction-tooltip' effect='solid' place='left'>
                    <h5>{this.context.t('Accept/Verify')}</h5>
                    <p>{this.context.t('Verify documents:')}</p>
                    <ul>
                        <li>{this.context.t('OCR will read data from documents for you')}</li>
                        <li>{this.context.t('Check their accuracy')}</li>
                        <li>{this.context.t('EasyDocs will catalog them automatically')}</li>
                    </ul>
                </ReactTooltip>
            </div>
        )
    }
}

TabsController.contextTypes = {
    t: PropTypes.func,
};

const mapStateToProps = (state) => ({
    currentProject: state.User.currentProject,
    currentTabIndex: state.V2_TabsCronroller.currentTabIndex,
    allOpenedTabs: state.V2_TabsCronroller.allOpenedTabs
});

const mapDispatchToProps = {
    setCurrentTab: TabsControlerActions.setCurrentTab,
    closeTab: TabsControlerActions.closeTab,
    closeTabsAfterPtojectChange: TabsControlerActions.closeTabsAfterPtojectChange
}

export default connect(mapStateToProps, mapDispatchToProps)(TabsController)
