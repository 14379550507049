import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { PrivateRoute } from './components/PrivateRoute'
import PropTypes from 'prop-types'
import moment from 'moment'

import { history } from './helpers/history'
import routes from './helpers/routes'
//import './css/style.css'
import './css/new-style.css'

import ReportError from './components/ReportError'
import AlertsComponent from './components/AlertsComponent'
import { UserActions } from './actions/UserActions'
import { AlertActions } from './actions/AlertActions'
import { FileActions } from './actions/FileActions'
import { ReportsActions } from './actions/ReportsActions'

import RegisterPage from './pages/Register'
import NewRegisterPage from './pages/NewRegisterPage'
import NewActivateAccountPage from './pages/NewActivateAccountPage'
import NewAppointmentPage from './pages/NewAppointmentPage'
import PasswordReset from './pages/PasswordReset'
import CreateNewPassword from './pages/CreateNewPassword'
import LoginPage from './pages/Login'
import ChangeEasyGuard from './pages/ChangeEasyGuard'
import CompanyRegisterPage from './pages/CompanyRegister'
import CompleteCompanyRegistration from './pages/CompleteCompanyRegistration'
import GoogleAuthHandler from './pages/GoogleAuthHandler'
import PanelPage from './pages/User/UserPanel'
import ErrorPage from './components/ErrorPage'

import MaintancePage from './pages/Maintance'

import io from 'socket.io-client'
import { CompanyActions } from './actions/CompanyActions'
import { error } from 'jquery'

var serverSocketEndpoint
if (process.env.REACT_APP_ENVIRONMENT === 'local') {
  serverSocketEndpoint = 'http://localhost:4000'
} else if (process.env.REACT_APP_ENVIRONMENT === 'development') {
  serverSocketEndpoint = 'https://test-api.easydocs.pl'
} else if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  serverSocketEndpoint = 'https://app-api.easydocs.pl'
} else if (process.env.REACT_APP_ENVIRONMENT === 'test') {
  serverSocketEndpoint = 'https://test-api.easydocs.pl'
} else {
  serverSocketEndpoint = 'http://localhost:4000'
}
export var socket;

export class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      showErrorPage: false
    }
  }

  componentDidMount() {
    if (process.env.NODE_ENV === "production" || process.env.REACT_APP_ENVIRONMENT === "production" || process.env.NODE_ENV === "development" || process.env.REACT_APP_ENVIRONMENT === "development") {
      window.addEventListener("error", (event) => {
        if (event && event.message && !event.message.includes('ResizeObserver') && !event.message.includes('Script error')) {
          const { userData } = this.props
          this.setState({
            showErrorPage: true
          })

          var errorObject = {
            message: event.message,
            location: window.location.href
          }

          if (userData && userData.email) {
            errorObject.user = userData.email
          }

          this.props.sendErrorEmail(errorObject)
        }
      });
    }

    window.addEventListener('beforeunload', this.checkExcelSession)

    this.props.checkIfLoggedIn()

    if (!localStorage.getItem('language')) {
      localStorage.setItem('language', 'pl')
      moment.locale('pl');
    } else if (localStorage.getItem('language') === 'pl') {
      moment.locale('pl');
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.userData && this.props.userData) {
      socket = io(serverSocketEndpoint)
      socket.on('askForUID', this.socketLogin)
      socket.on('newVersionAvailable', this.newVersionAvailable)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.checkExcelSession)
  }

  checkExcelSession = (event) => {
    const { activeExcelSession, excelSessionDocs } = this.props
    if (activeExcelSession && excelSessionDocs.length) event.returnValue = "Hello world!"
  }


  socketLogin = () => {
    console.log('got socket login request')
    socket.emit('login', { id: this.props.userData.id, version: localStorage.getItem('version') })
  }

  newVersionAvailable = (data) => {
    console.log('show new version available')
    window.location.reload()
  }

  render() {
    const { showErrorPage } = this.state
    const { userData, error } = this.props
    // userData && userData.email.includes('@b2rlaw.com')
    return (
      <>
        <AlertsComponent />
        {error && <ReportError />}

        {
          showErrorPage ? (
            <ErrorPage />
          ) : null
        }

        {/* {
          showCookiesPopup ? (
            <div className="fb-pixel-consent">
              <div className="text">
                {this.context.t('By continuing to use this website, you consent to the use of cookies in accordance with the Privacy Policy available on the website ')}
                <a href="https://easydocs.pl/polityka-prywatnosci/" target="_blank">{this.context.t('EasyDocs Privacy Policy')}</a>
              </div>
              <div className="button">
                <button className="consent-cookies" onClick={e => this.consentCookies()}></button>
              </div>
            </div>
          ) : (
            null
          )
        } */}

        {/* jak wroci IBM to odkomentowac */}
        {/* {
              userData && (userData.email.includes('@b2rlaw.com') || userData.email.includes('@amssystem.pl') || userData.email.includes('@ahmes.pl')) ? ( */}
        <Router history={history}>
          <Switch>
            <Route path={routes.login} component={LoginPage} />
            <Route path={routes.registerCompany} component={NewRegisterPage} />
            <Route path={routes.completeCompanyRegistration} component={CompleteCompanyRegistration} />
            <Route path={routes.activateAccount} component={NewActivateAccountPage} />
            <Route path={routes.appointment} component={NewAppointmentPage} />
            <Route path={routes.passwordReset} component={PasswordReset} />
            <Route path={routes.createNewPassword} component={CreateNewPassword} />
            <Route path={routes.changeEasyGuardDevice} component={ChangeEasyGuard} />
            <Route path={routes.googleAuth} component={GoogleAuthHandler} />
            <PrivateRoute path={routes.panel.home} component={PanelPage} userData={userData} />
            <Redirect to={routes.login} />
          </Switch>
        </Router>
        {/* ) : (
                <Router history={history}>
                  <Switch>
                    <Route path={routes.login} component={MaintancePage} />
                    <Route path={routes.registerCompany} component={MaintancePage} />
                    <Route path={routes.activateAccount} component={MaintancePage} />
                    <Route path={routes.passwordReset} component={MaintancePage} />
                    <Route path={routes.createNewPassword} component={MaintancePage} />
                    <Route path={routes.changeEasyGuardDevice} component={MaintancePage} />
                    <PrivateRoute path={routes.panel.home} component={MaintancePage} userData={userData} />
                    <Redirect to={routes.login} />
                  </Switch>
                </Router>
              )
            } */}

        {/* jak wroci IBM to zakomentowac do lini 133 */}
      </>
    )
  }
}

App.contextTypes = {
  t: PropTypes.func
}

const mapStateToProps = (state) => ({
  userData: state.User.user,
  messagesCount: state.Messages.unreadCount,
  chatNotifications: state.Chat.chatNotifications,
  error: state.Alert.error,

  docsDownloadedForRaport: state.Reports.docsDownloadedForRaport ? [...state.Reports.docsDownloadedForRaport] : [],
  reportsSearch: state.User.reportsSearch,

  activeExcelSession: state.ExcelSession.sessionActive,
  excelSessionDocs: state.ExcelSession.sessionDocs,
})

const mapDispatchToProps = {
  checkIfLoggedIn: UserActions.checkIfLogged,
  setError: AlertActions.setError,
  markProjectsToLoad: FileActions.markProjectsToLoad,
  getDocuments: ReportsActions.getDocuments,
  getFiles: FileActions.getFiles,
  getUserCompanies: CompanyActions.getUserCompanies,
  getUserProjects: UserActions.getUserProjects,

  sendErrorEmail: UserActions.sendErrorEmail
}

export default connect(mapStateToProps, mapDispatchToProps)(App)