import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";


export class NewInvoiceNumberPopUp extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        const { newInvoiceNumber, foundNumberingGap, incorrectMaskForm } = this.props
        return (
            <div className="delete-invoice-popup numbering-series">
                <div className="popup-card-container">
                    <div className="popup-card">
                        <div className="header">
                            {
                                foundNumberingGap ? (
                                    this.context.t('We have found a gap in the numbering of your invoices, we suggest you assign the invoice number below:')
                                ) : 
                                incorrectMaskForm ? (
                                    this.context.t('Invalid data in the invoice number has been detected, which does not match its format. Please use the correct number:')
                                ) : (
                                this.context.t('The specified invoice number is a duplicate. Please use the suggested alternative:')
                                )
                            }
                        </div>
                        <div className="new-invoice-number">
                            <p>{newInvoiceNumber}</p>
                        </div>

                        <div className="action-buttons">
                            <div className="btn" onClick={e => this.props.close()}>{this.context.t('Cancel')}</div>
                            <div className="btn save" onClick={e => this.props.save(newInvoiceNumber)}>{this.context.t('Set invoice number')}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

NewInvoiceNumberPopUp.contextTypes = {
    t: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => ({
    foundNumberingGap: state.Company.foundNumberingGap,
    incorrectMaskForm: state.Company.incorrectMaskForm
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(NewInvoiceNumberPopUp);

