import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import { AlertActions } from '../actions/AlertActions'
import { CompanyActions } from '../actions/CompanyActions'
import routes from '../helpers/routes'
import bankIdentification from '../helpers/bankIdentification'
import arrayObjectIndexOf from '../helpers/indexOfObject.js'

export class CompanyBanks extends Component {

    constructor(props) {
        super(props)
        this.state = {
            choosenBank: '',
            showUnavailable: false,
            makeToday: false,
            bankIndex: -1,
            banks: [],

            markAsPaid: false
        }
    }

    componentDidMount() {
        window.addEventListener("keydown", this.escFunction, false)
        const { company, currency, userCompanies } = this.props
        var banks = company.banks
        var companyIndexInCurrentProps = arrayObjectIndexOf(userCompanies, company.id, 'company_id')
        if (companyIndexInCurrentProps > -1 && userCompanies[companyIndexInCurrentProps].company && userCompanies[companyIndexInCurrentProps].company.banks)
            banks = userCompanies[companyIndexInCurrentProps].company.banks
        var bankIndex = -1, banksCount = banks.length

        for (var i = 0; i < banksCount; i++) {
            if (banks[i].currency === currency) {
                bankIndex = i
                break;
            }
        }

        if (bankIndex === -1) bankIndex = 0

        this.setState({
            bankIndex: bankIndex,
            banks: banks
        })

        if(banks[bankIndex]) this.checkAccountNumber(banks[bankIndex].account_number)

    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.escFunction, false)
    }

    escFunction = (e) => {
        if (e.keyCode === 27) {
            this.props.close()
        }
    }

    checkAccountNumber = (accountNumber) => {
        const { currency } = this.props
        if (accountNumber) {
            var accountNumberOnlyDigits = accountNumber.replace(/\s/gm, '').replace('PL', '')

            if (accountNumberOnlyDigits.length === 26) {
                var bankCode = accountNumberOnlyDigits.substring(2, 6)

                var bank = bankIdentification.find(e => e.code === bankCode)

                if (bank) {
                    if (currency === 'PLN' && !bank.available) {
                        this.setState({
                            showUnavailable: true
                        })
                    } else if (currency !== 'PLN' && !bank.availableInternational) {
                        this.setState({
                            showUnavailable: true
                        })
                    } else {
                        this.setState({
                            showUnavailable: false
                        })
                    }
                } else {
                    this.setState({
                        showUnavailable: true
                    })
                }
            } else {
                this.setState({
                    showUnavailable: true
                })
            }
        } else {
            this.setState({
                showUnavailable: true
            })
        }
    }

    selectBank = i => {
        this.setState({
            bankIndex: i
        })

        this.checkAccountNumber(this.state.banks[i].account_number)
    }

    render() {
        const { company, isAdmin, currency } = this.props
        const { showUnavailable, makeToday, bankIndex, banks, markAsPaid } = this.state
        return (
            <div className="confirm-account-number-modal">
                <div className="card">
                    <span className="close-button" onClick={e => this.props.close(e)}></span>
                    <div className="header">
                        <h2>{this.context.t('Select the account from which the transfers are to be made')}</h2>
                    </div>
                    <div className="body">
                        <SimpleBar style={{ maxHeight: '50vh', width: '100%' }}>
                            <div className={`bank-instance`}>
                                {
                                    banks.map((b, i) => {
                                        return (
                                            <div className={`bank ${i === bankIndex ? 'selected' : ''} ${showUnavailable && i === bankIndex ? 'unavailable' : ''}`} onClick={() => this.selectBank(i)}>
                                                <span className="account">
                                                    <span className="bold">{this.context.t('Bank account name:')} </span>
                                                    {b.name}
                                                </span>
                                                <span className="account">
                                                    <span className="bold">{this.context.t('Account number:')} </span>
                                                    {b.account_number}
                                                </span>
                                                <span className="name">
                                                    <span className="bold">{this.context.t('Bank name:')} </span>
                                                    {b.bank_name}
                                                </span>
                                                <span className="currency">
                                                    <span className="bold">{this.context.t('Currency:')} </span>
                                                    {b.currency}
                                                </span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </SimpleBar>
                        {
                            showUnavailable ? (
                                <div className="bank-not-available-yet">
                                    {
                                        currency === 'PLN' ? (
                                            this.context.t('Generating files with bank transfer is not yet supported for your bank')
                                        ) : (
                                            this.context.t('Generating files with bank transfer in currencies other than PLN is not yet supported for your bank')
                                        )
                                    }
                                    {this.context.t(' Contact us via')}<Link to={routes.panel.help}>{this.context.t(' Helpdesk ')}</Link>{this.context.t('for more informations.')}
                                </div>
                            ) : (
                                <div className="set-due-date">
                                    <div className="group" onClick={() => this.setState({ makeToday: false })}>
                                        <span className={`checkbox ${!makeToday ? 'checked' : ''}`} ></span>
                                        <div className="label">{this.context.t('Set the dates of transfers to the payment dates of documents')}</div>
                                    </div>
                                    <div className="group" onClick={() => this.setState({ makeToday: true })}>
                                        <span className={`checkbox ${makeToday ? 'checked' : ''}`}></span>
                                        <div className="label">{this.context.t('Set transfer dates for today')}</div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    {
                        showUnavailable ? (
                            <div className="footer">
                                <button className="button" onClick={e => this.props.close()}>{this.context.t('Close')}</button>
                            </div>
                        ) : (
                            <div className="footer">
                                <div className="info">
                                    {
                                        currency === 'PLN' ? (
                                            this.context.t('A file with .txt extension and UTF-8 character encoding will be generated. The data will be saved in Elixir (Multicash PLI) format.')
                                        ) : (
                                            this.context.t('A file with .xml extension and UTF-8 character encoding will be generated. The data will be saved in ISO 20022 format.')
                                        )
                                    }
                                </div>

                                <div className="mark-as-paid">
                                    <div className="group" onClick={() => this.setState({ markAsPaid: !markAsPaid })}>
                                        <span className={`checkbox ${markAsPaid ? 'checked' : ''}`} ></span>
                                        <div className="label">{this.context.t('Mark included documents as paid')}</div>
                                    </div>

                                    {
                                        markAsPaid ? (
                                            <div className="warning">{this.context.t('Documents will be marked as paid in EasyDocs, but you need to import the package and accept the transfers at your bank.')}</div>
                                        ) : (
                                            null
                                        )
                                    }
                                </div>

                                <button className="button transparent" onClick={e => this.props.addBank()}>{this.context.t('Add bank account')}</button>
                                <button className="button" onClick={e => this.props.generateFile(makeToday, company.banks[bankIndex].id, markAsPaid)}>{this.context.t('Confirm')}</button>
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }
}

CompanyBanks.contextTypes = {
    t: PropTypes.func
}

const mapStateToProps = (state) => ({
    userCompanies: state.Company.companies
})

const mapDispatchToProps = {
    alertWarn: AlertActions.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyBanks)